import React from "react";
//import "./mandi-state-district-hub-master.styles.css";
import "./mandi-state-district-hub-master.page";
import { canUserEdit,getUserMSRBACMessage } from "../../auth/utils";
import { FeatureCodes } from "../../auth/data";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUserData,selectMSMessageRBAC } from "../../redux/user/user.selectors";

//import CustomSelectSummary from "../../components/custom-select-summary/custom-select-summary.component";

import API from "../../api";
//import { getStatesAPI } from "../../services/generic-apis";

import { Component } from "react";
import {
  Table,
  Spin,
  Input,
  Select,
  Button,
  message,
  Form,
  Modal,
  Radio,
} from "antd";
const { Search } = Input;
const { Option } = Select;

class MandiStateDistrictHubMaster extends Component {
  state = {
    isModalVisible: false,
    isAdd: false,
    mandiList: [],
    stateList: [],
    districtList: [],
    mandiHubDistrictTableData: [],
    currentRecord: null,
    userBranches: null,
    searchText: null,
    searchUserType: 0,
    hubList: [],
    isActive: false,
    isSpinnerOnLoad: false,
    isSubmitted: false,

    newData: {
      hub_id: undefined,
      hub_name: "",
      is_active: true,
      mandi_id: undefined,
      mandi_name: "",
      state_id: undefined,
      state_name: "",
      district_id: undefined,
      district_name: "",
      isEdit: false,
    },
    rowData: undefined,
    selectedStateValue: undefined,
    selectedStateName: "",
    selectedStateList: [],
    selectedBranchValue: undefined,
    selectedBranchName: "",
    selectedBranchList: [],
  };
  componentDidMount() {
    this.setState({ isSpinnerOnLoad: true });
    this.getMandiList();
  }


  getMandiHubDistrictMapping = async () => {
    this.setState({ isSpinnerOnLoad: true });
    // const headers = {
    //   "Content-Type": "application/json",
    //   Authorization: "Bearer " + localStorage.getItem("accessToken"),
    // };

    const params = {
      state_id:
        this.state.selectedStateValue === null ||
        this.state.selectedStateValue === undefined
          ? null
          : this.state.selectedStateValue,
    };

    // this.setState({ isSpinnerOnLoad: true });
    await API.get("mandi_state_district_hub", { params: params })
      .then((resp) => {
        console.log("check response ", resp);
        let modifiedData = resp.data.data.map((item, index) => {
          return {
            ...item,
            isEdit: false,
            key: index,
          };
        });
        this.setState(
          {
            mandiHubDistrictTableData: modifiedData,
          },
          () => this.setState({ isSpinnerOnLoad: false, isAdd: true })
        );
        console.log("mandi table data ", this.state.mandiHubDistrictTableData);
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };

  getStateAPIlist = async (hubId) => {
    this.setState({ isSpinnerOnLoad: true });

    await API.get("/get_states")
      .then((state) => {
        let modifiedData = [...state.data.data];
        modifiedData.push({ state_id: null, state_name: "All" });
        this.setState(
          {
            selectedStateList: modifiedData,
            selectedStateValue: state.data.data[0].state_id,
            selectedStateName: state.data.data[0].state_name,
          },

          () => this.getMandiHubDistrictMapping()
        );
      })

      .catch((err) => {
        this.setState({ isSpinnerOnLoad: false });
        this.getStateAPI();
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
      });
  };

  getStateAPI = async (hubId) => {
    this.setState({ isSpinnerOnLoad: true });
    const params = {
      hub_id: hubId
    };

    await API.get("/get_states",{params:params})
      .then((state) => {
       
        this.setState(
          {
            stateList: state.data.data,
          },

          () => this.getDistrictList(this.state.selectedStateValue)
        );
      })

      .catch((err) => {
        this.setState({ isSpinnerOnLoad: false });
        this.getStateAPI();
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
      });
  };

  getDistrictList = async (stateid) => {
    this.setState({ isSpinnerOnLoad: true });

    const params = {
      state_id: stateid
    };


    await API.get("/get_district",{params:params})
      .then((districtdata) => {
        this.setState(
          {
            districtList: districtdata.data.data,
          },
          () => this.setState({ isSpinnerOnLoad: false })
        );
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };

  getPrimaryHub = async () => {
    this.setState({ isSpinnerOnLoad: true });

    await API.get("/list_hubs")
      .then((hubDataList) => {
        this.setState(
          {
            hubList: hubDataList.data.data,
          },
          () => this.getStateAPIlist()
        );
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };

  getMandiList = async () => {
    this.setState({ isSpinnerOnLoad: true });

    await API.get("/list_mandi")
      .then((mandiData) => {
        this.setState(
          {
            mandiList: mandiData.data.data,
          },
          () => this.getPrimaryHub()
        );
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };

  setTableData = () => {
    if (
      this.state.mandiHubDistrictTableData !== undefined ||
      this.state.mandiHubDistrictTableData.length >= 0
    ) {
      const userTableData = this.state.mandiHubDistrictTableData.map(
        (user, index) => {
          return {
            key: user.key,
            hub_id: user.hub_id,
            hub_name: user.hub_name,
            is_active: user.is_active,
            mandi_hub_map_id: user.mandi_hub_map_id,
            district_name: user.district_name,
            district_id: user.district_id,
            mandi_id: user.mandi_id,
            mandi_name: user.mandi_name,
            state_name: user.state_name,
            state_id: user.state_id,
            isEdit: user.isEdit,
          };
        }
      );
      console.log(userTableData);

      let dataAfterSearch = userTableData;

      if (this.state.searchText) {
        dataAfterSearch = dataAfterSearch.filter(
          (item) =>
            (item.mandi_name !== null &&
              item.mandi_name
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase())) ||
            (item.hub_name !== null &&
              item.hub_name
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase())) ||
            (item.state_name !== null &&
              item.state_name
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase())) ||
            (item.district_name !== null &&
              item.district_name
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase()))
        );
      }
      return dataAfterSearch;
    } else {
      return this.state.mandiHubDistrictTableData;
    }
  };

  submitTableRowData = (row, index, isPopup) => {
    this.setState({ isSpinnerOnLoad: true, isSubmitted: true });
    let warningmessage = "";
    if (
      row.mandi_name === null ||
      row.mandi_name === undefined ||
      row.mandi_name === ""
    ) {
      warningmessage = "Please select Mandi";
    } else if (
      row.hub_name === null ||
      row.hub_name === undefined ||
      row.hub_name === ""
    ) {
      warningmessage = "Please select hub";
    } else if (
      row.state_name === null ||
      row.state_name === undefined ||
      row.state_name === ""
    ) {
      warningmessage = "Please select state";
    } else if (
      row.district_name === null ||
      row.district_name === undefined ||
      row.district_name === ""
    ) {
      warningmessage = "Please select district";
    } else if (
      row.is_active === null ||
      row.is_active === undefined ||
      row.is_active === ""
    ) {
      warningmessage = "Please select the active field";
    }

    if (
      warningmessage === "" ||
      warningmessage === undefined ||
      warningmessage === null
    ) {
      this.setState({ isSpinnerOnLoad: true });
      this.setState({ isSubmitted: true });

      let data = {
        mandi_hub_map_id:row.mandi_hub_map_id,
        mandi_name: row.mandi_name,
          mandi_id: row.mandi_id,
          state_id:row.state_id,
          hub_id:row.hub_id,
          district_id:row.district_id,
        hub_name: row.hub_name,
          is_active: row.is_active,
        district_name: row.district_name,
        state_name: row.state_name,
      };
      // const headers = {
      //   "Content-Type": "application/json",
      //   Authorization: "Bearer " + localStorage.getItem("accessToken"),
      // };

      API.put("/mandi_state_district_hub", data, {})

        .then((response) => {
          if (response.status === 200 && response.data.status) {
            message.info(response.data.message, 5);
            this.setModalVisibility(false);
            this.getMandiHubDistrictMapping();
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
          } else if (!response.data.status) {
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
            message.error(response.data.message, 5);
          } else {
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
            message.error("Please try again", 5);
          }
        })
        .catch((err) => {
          if (err) {
            if (err.response && err.response.status === 400)
              message.error("Something went wrong. Please try again later.", 5);
            else {
              message.error(
                "Something went wrong.. Please try again later.",
                5
              );
            }
          } else {
            message.error("Something went wrong... Please try again later.", 5);
          }
          this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
          // this.setModalVisibility(false);
        });
    } else {
      this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
      message.error(warningmessage, 5);
    }
  };

  // validate = (rowData) => {
  // var alphaNumericRegex = /^[a-zA-Z0-9]*$/;
  // var numberRegex = /^[0-9.]+$/;
  // if (
  //     rowData.mandi_name === null ||
  //     rowData.mandi_name === undefined ||
  //     rowData.mandi_name === ""
  //   ) {
  //     message.error(`Please enter Mandi Name !`);
  //     return false;
  //   }
  //   else if (
  //     rowData.hub_name === null ||
  //     rowData.hub_name === undefined ||
  //     rowData.hub_name === ""
  //   ) {
  //     message.error(`Please enter Hub  Name !`);
  //     return false;
  //   }
  //   else if (
  //     rowData.state_name === null ||
  //     rowData.state_name === undefined ||
  //     rowData.state_name === ""
  //   ) {
  //     message.error(`Please enter state Name !`);
  //     return false;
  //   }

  //   else if (
  //     rowData.district_name === null ||
  //     rowData.district_name === undefined ||
  //     rowData.district_name === ""
  //   ) {
  //     message.error(`Please enter district Name !`);
  //     return false;
  //   }
  //   else if (
  //     rowData.is_active === null ||
  //     rowData.is_active === undefined ||
  //     rowData.is_active === ""
  //   ) {
  //     message.error(`Please enter Hub Id `);
  //     return false;
  //   }
  //   return true;
  // };
  setModalVisibility = (status) => {
    if (status) {
      const nData = {
        hub_id: undefined,
        mandi_hub_map_id: undefined,

        hub_name: "",
        state_id: undefined,
        district_id: undefined,
        district_name: "",
        state_name: "",
        is_active: true,
        mandi_id: undefined,
        mandi_name: "",
        isEdit: false,
      };
      this.setState({ newData: nData,stateList:[],districtList:[] });
    }
    this.setState({ isModalVisible: status });
  };

  TableColumnHeader = (props) => {
    const { title, subtitle } = props;
    return (
      <div className="column-header-container">
        <div
          style={{
            color: "white",
            fontSize: "12px",
            whiteSpace: "nowrap",
            fontWeight: "normal",
            textAlign: "left",
          }}
        >
          {title}
        </div>
        <div
          style={{
            color: "#cbdfff",
            fontSize: "11px",
            fontWeight: "normal",
            textAlign: "left",
          }}
        >
          {subtitle}
        </div>
      </div>
    );
  };

  createFilters = (label) => {
    let filterData = this.state.mandiHubDistrictTableData;

    //#region remove duplicate objects

    let uniqueFilterData = [];

    if (
      label === "is_active" &&
      this.state.mandiHubDistrictTableData &&
      this.state.mandiHubDistrictTableData.length > 0
    ) {
      uniqueFilterData = [
        { text: "Yes", value: 1 },
        { text: "No", value: 0 },
      ];
    } else {
      filterData.map((mainItem) =>
        uniqueFilterData.filter((item) => item[label] === mainItem[label])
          .length > 0
          ? null
          : uniqueFilterData.push(mainItem)
      );

      uniqueFilterData = uniqueFilterData.map((item) => {
        return {
          text: item[label],

          value: item[label],
        };
      });
console.log("value printing before");
      uniqueFilterData.sort((a, b) => a.text.localeCompare(b.text));
    }
    console.log("value coming after");

    return uniqueFilterData;
  };


  handleDropdownChange = (value, row, target, index, optionlabel, isPopup) => {
    let a;

    if (isPopup === false) {
      a = this.state.mandiHubDistrictTableData;

      if (target === "mandi_name") {
        a[row.key].mandi_name = optionlabel;
        a[row.key].mandi_id = value;
      } else if (target === "hub_name") {
        a[row.key].district_name = "";
        a[row.key].state_name = "";
        this.getStateAPI(value);
        a[row.key].hub_id = value;
        a[row.key].hub_name = optionlabel;
      } else if (target === "district_name") {
        a[row.key].district_id = value;
        a[row.key].district_name = optionlabel;
      } else if (target === "state_name") {
        a[row.key].district_name = "";
        a[row.key].state_id = value;
        a[row.key].state_name = optionlabel;
        this.getDistrictList(value);
      } else if (target === "is_active") {
        a[row.key].is_active = Boolean(value);
      }

      this.setState({ mandiHubDistrictTableData: a });
    } else {
      a = this.state.newData;

      if (target === "mandi_name") {
        a.mandi_name = optionlabel;
        a.mandi_id = value;
      } else if (target === "hub_name") {
        a.district_name = "";
        a.state_name = "";
        this.getStateAPI(value);
        a.hub_id = value;
        a.hub_name = optionlabel;
      } else if (target === "district_name") {
        a.district_id = value;
        a.district_name = optionlabel;
      } else if (target === "state_name") {
        a.district_name = "";
        this.getDistrictList(value);
        a.state_id = value;
        a.state_name = optionlabel;
      } else if (target === "is_active") {
        a.is_active = Boolean(value);
      }

      this.setState({ newData: a });
    }
  };

  handleChange = (e, row, index, name, isPopup) => {
    e.target.name = name;

    var alphaNumericRegex = /^[a-zA-Z0-9]*$/;
    var numberRegex = /^[0-9.]+$/;
    let a;

    if (isPopup === false) {
      a = this.state.mandiHubDistrictTableData;

      this.setState({ mandiHubDistrictTableData: a });
    } else {
      a = this.state.newData;
      this.setState({ newData: a });
    }
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  handleEditChange = (row, index) => {
    console.log("user hub id is ", this.props.userData.hub_state_id); //check this afterwards

    this.setState({ rowData: row });
    let tempData = this.state.mandiHubDistrictTableData.map((item) => {
      if (item.mandi_hub_map_id === row.mandi_hub_map_id) {
        return {
          ...item,
          isEdit: true,
        };
      } else {
        return item;
      }
    });
    this.setState({ mandiHubDistrictTableData: tempData });
  };
  handleCancelClick = (rowData, index) => {
    let tempData = this.state.mandiHubDistrictTableData.map((item) => {
      if (item.mandi_hub_map_id === rowData.mandi_hub_map_id) {
        return this.state.rowData;
      } else {
        return item;
      }
    });
    this.setState({ mandiHubDistrictTableData: tempData });
  };

  render() {
    const userTableProps = {
      pagination: false,
      size: "default",
      bordered: true,
      scroll: { x: 200, y: window.innerHeight - 250 },
    };

    const handleStateSelect = (value, props) => {
      this.setState(
        { selectedStateName: props.key, selectedStateValue: value },
        () => this.getMandiHubDistrictMapping()
      );
    };

    return (
      <div
        className="mandi-master-container"
        style={{
          height: window.innerHeight - 85,
          marginTop: "-45px",
        }}
      >
        <div className="top-container">
          <div className="hub-region-filter">
            <span
              style={{
                fontSize: "15px",
                marginTop: "5px",
                marginLeft: "68px",
                fontWeight: "600",
              }}
            >
              State&nbsp;
            </span>{" "}
            <Select
              disabled={!this.state.isAdd}
              showSearch
              optionFilterProp="children"
              className={"select-master-region"}
              mode="single"
              placeholder="Select State"
              value={this.state.selectedStateValue}
              onChange={(value, props) => handleStateSelect(value, props)}
            >
              {this.state.selectedStateList !== null &&
                this.state.selectedStateList.length &&
                this.state.selectedStateList.map((item, idx) => (
                  <Option value={item.state_id} key={item.state_id}>
                    {item.state_name}
                  </Option>
                ))}
            </Select>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row-reverse",
              placeItems: "flex-end",
              marginRight: "25px",
              marginBottom: "10px",
            }}
          >
            <Search
              disabled={!this.state.isAdd}
              placeholder="Search"
              allowClear
              onChange={(e) => this.setState({ searchText: e.target.value })}
              style={{ width: "45%" }}
            />
            <Button
              className="add-master-button"
              style={{
                height: "25px",
                width: "70px",
                backgroundColor: "#0b133d",
                color: "white",
              }}
              disabled={
                !this.state.isAdd ||
                !canUserEdit(
                  this.props.userData,
                  null,
                  FeatureCodes.MS_MANDI_STATE_HUB_EDIT
                )
              }
              onClick={() => this.setModalVisibility(true)}
            >
              &#10010; Add
            </Button>
          </div>
        </div>
        <div
          style={{
            marginLeft: "25px",
            marginTop: "-8px",
            fontSize: "14px",
            color: "red",
            display: "flex",
            float: "left",
          }}
        >
        {getUserMSRBACMessage(this.props.messageAccess,FeatureCodes.MS_MANDI_STATE_HUB_EDIT)}
        </div>

        <div
          style={{
            marginRight: "20px",
            marginTop: "-8px",
            fontSize: "15px",
            color: "#0B133D",
            display: "flex",
            float: "right",
          }}
        >
          Total number of records:&nbsp;
          <b>
            {this.state.mandiHubDistrictTableData && this.setTableData().length}
          </b>
        </div>
        <Spin
          spinning={this.state.isSpinnerOnLoad}
          size={"large"}
          tip="Loading..."
          style={{ alignSelf: "center", zIndex: 9999, marginTop: "20px" }}
        >
          <Modal
            className="add-master-modal"
            title={"Add Mandi hub  and district"}
            onCancel={this.handleCancel}
            footer={false}
            visible={this.state.isModalVisible}
            destroyOnClose={true}
          >
            <div className="master-form-container">
              <Form
                layout="horizontal"
                //   onValuesChange={onFormLayoutChange}
                size={"small"}
                // onSubmit={submitTableRowData(this.state.newData)}
              >
                <div className="form-top-container1">
                  
                  <div
                    className="checkbox-group1"
                    style={{
                      display: "inline-block",
                      width: "38%",
                      marginRight: 40,
                    }}
                  >
                    <Form.Item label="Mandi">
                      <Select
                        showSearch
                        optionFilterProp="children"
                        className="remarks-select"
                        name="mandi_name"
                        defaultValue={this.state.newData.mandi_name}
                        value={this.state.newData.mandi_name}
                        onSelect={(value, option) =>
                          this.handleDropdownChange(
                            value,
                            this.state.newData,
                            "mandi_name",
                            -0,
                            option.props.children,
                            true
                          )
                        }
                      >
                        {this.state.mandiList &&
                          this.state.mandiList.length &&
                          this.state.mandiList.map((hubItem, idx) => (
                            <Option
                              key={hubItem.mandi_id}
                              value={hubItem.mandi_id}
                            >
                              {hubItem.mandi_name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                    </div>
                    <div
                      className="checkbox-group1"
                      style={{
                        display: "inline-block",
                        width: "38%",
                        marginRight: 40,
                      }}
                    >
                      <Form.Item label="Hub">
                        <Select
                          showSearch
                          optionFilterProp="children"
                          className="remarks-select"
                          name="hub_name"
                          defaultValue={this.state.newData.hub_name}
                          value={this.state.newData.hub_name}
                          onSelect={(value, option) =>
                            this.handleDropdownChange(
                              value,
                              this.state.newData,
                              "hub_name",
                              -0,
                              option.props.children,
                              true
                            )
                          }
                        >
                          {this.state.hubList &&
                            this.state.hubList.length &&
                            this.state.hubList.map((hubItem, idx) => (
                              <Option
                                key={hubItem.territory_id}
                                value={hubItem.territory_id}
                              >
                                {hubItem.territory_name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </div>
                    <div
                      className="checkbox-group1"
                      style={{
                        height: "42px",
                        display: "inline-block",
                        width: "60%",
                        marginRight: 40,
                      }}
                    >
                      <Form.Item label="State">
                        <Select
                          showSearch
                          optionFilterProp="children"
                          className="remarks-select"
                          name="state_name"
                          defaultValue={this.state.newData.state_name}
                          value={this.state.newData.state_name}
                          onSelect={(value, option) =>
                            this.handleDropdownChange(
                              value,
                              this.state.newData,
                              "state_name",
                              -0,
                              option.props.children,
                              true
                            )
                          }
                        >
                          {this.state.stateList &&
                            this.state.stateList.length &&
                            this.state.stateList.map((hubItem, idx) => (
                              <Option
                                key={hubItem.state_id}
                                value={hubItem.state_id}
                              >
                                {hubItem.state_name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </div>

                    <div
                      className="checkbox-group1"
                      style={{
                        height: "25px",
                        display: "inline-block",
                        width: "60%",
                        marginRight: 40,
                      }}
                    >
                    <Form.Item label="District">
                      <Select
                        showSearch
                        optionFilterProp="children"
                        className="remarks-select"
                        name="district_name"
                        defaultValue={this.state.newData.district_name}
                        value={this.state.newData.district_name}
                        onSelect={(value, option) =>
                          this.handleDropdownChange(
                            value,
                            this.state.newData,
                            "district_name",
                            -0,
                            option.props.children,
                            true
                          )
                        }
                      >
                        {this.state.districtList &&
                          this.state.districtList.length &&
                          this.state.districtList.map((hubItem, idx) => (
                            <Option
                              key={hubItem.district_id}
                              value={hubItem.district_id}
                            >
                              {hubItem.district_name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                    </div>
                    <div
                      className="checkbox-group1"
                      style={{
                        display: "inline-block",
                        width: "60%",
                        marginRight: 40,
                      }}
                    >
                      <Form.Item label="Active">
                        <Radio.Group
                          name="is_active"
                          value={this.state.newData.is_active ? 1 : 0}
                          onChange={(e, option) =>
                            this.handleDropdownChange(
                              e.target.value,
                              this.state.newData,
                              "is_active",
                              -0,
                              "",
                              true
                            )
                          }
                        >
                          <Radio value={1}>Yes</Radio>
                          <Radio value={0}>No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </div>
                  

                  <div
                    className="form-bottom-container"
                    style={{ marginTop: "10px" }}
                  >
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        disabled={this.state.isSubmitted}
                        onClick={() =>
                          this.submitTableRowData(this.state.newData, 0, true)
                        }
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              </Form>
            </div>
          </Modal>

          <div
            className="table-container1"
            style={{
              maxHeight: "450px",
              width: "100%",
              marginLeft: "auto",
              marginRight: "10px",
            }}
          >
            <Table
              {...userTableProps}
              columns={[
                {
                  title: "Mandi",
                  dataIndex: "mandi_name",
                  key: "mandi_name",
                  width: 125,
                    filters: this.createFilters("mandi_name"),
                  onFilter: (value, record) =>
                    record.mandi_name.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.mandi_name.localeCompare(b.mandi_name),
                  render: (record, rowRecord) => {
                    return (
                      <>
                        <div
                          style={{ marginLeft: "-15px", marginRight: "-10px" }}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            disabled={true}
                            className="remarks-select"
                            name="mandi_name"
                            defaultValue={rowRecord.mandi_name}
                            value={rowRecord.mandi_name}
                            onSelect={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "mandi_name",
                                -0,
                                option.props.children,
                                false
                              )
                            }
                          >
                            {this.state.mandiList &&
                              this.state.mandiList.length &&
                              this.state.mandiList.map((hubItem, idx) => (
                                <Option
                                  key={hubItem.mandi_id}
                                  value={hubItem.mandi_id}
                                >
                                  {hubItem.mandi_name}
                                </Option>
                              ))}
                          </Select>
                        </div>
                      </>
                    );
                  },
                },
                {
                  title: "Hub",
                  dataIndex: "hub_name",
                  key: "hub_name",
                  width: 125,
                    filters: this.createFilters("hub_name"),
                  onFilter: (value, record) => record.hub_name.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.hub_name.localeCompare(b.hub_name),
                  render: (record, rowRecord) => {
                    return (
                      <>
                        <div
                          style={{ marginLeft: "-15px", marginRight: "-10px" }}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            disabled={true}
                            className="remarks-select"
                            name="hub_name"
                            defaultValue={rowRecord.hub_name}
                            value={rowRecord.hub_name}
                            onSelect={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "hub_name",
                                -0,
                                option.props.children,
                                false
                              )
                            }
                          >
                            {this.state.hubList &&
                              this.state.hubList.length &&
                              this.state.hubList.map((hubItem, idx) => (
                                <Option
                                  key={hubItem.territory_id}
                                  value={hubItem.territory_id}
                                >
                                  {hubItem.territory_name}
                                </Option>
                              ))}
                          </Select>
                        </div>
                      </>
                    );
                  },
                },
                {
                  title: "State",
                  dataIndex: "state_name",
                  key: "state_name",
                  width: 125,
                  filters: this.createFilters("state_name"),
                  onFilter: (value, record) => record.state_name.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.state_name.localeCompare(b.state_name),
                  render: (record, rowRecord) => {
                    return (
                      <>
                        <div
                          style={{ marginLeft: "-15px", marginRight: "-10px" }}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            disabled={true}
                            className="remarks-select"
                            name="state_name"
                            defaultValue={rowRecord.state_name}
                            value={rowRecord.state_name}
                            onSelect={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "state_name",
                                -0,
                                option.props.children,
                                false
                              )
                            }
                          >
                            {this.state.stateList &&
                              this.state.stateList.length &&
                              this.state.stateList.map((hubItem, idx) => (
                                <Option
                                  key={hubItem.state_id}
                                  value={hubItem.state_id}
                                >
                                  {hubItem.state_name}
                                </Option>
                              ))}
                          </Select>
                        </div>
                      </>
                    );
                  },
                },

                {
                  title: "District",
                  dataIndex: "district_name",
                  key: "district_name",
                  width: 125,
                    filters: this.createFilters("district_name"),
                  onFilter: (value, record) =>
                    record.district_name.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) =>
                    a.district_name.localeCompare(b.district_name),
                  render: (record, rowRecord) => {
                    return (
                      <>
                        <div
                          style={{ marginLeft: "-15px", marginRight: "-10px" }}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            disabled={true}
                            className="remarks-select"
                            name="district_name"
                            defaultValue={rowRecord.district_name}
                            value={rowRecord.district_name}
                            onSelect={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "district_name",
                                -0,
                                option.props.children,
                                false
                              )
                            }
                          >
                            {this.state.districtList &&
                              this.state.districtList.length &&
                              this.state.districtList.map((hubItem, idx) => (
                                <Option
                                  key={hubItem.district_id}
                                  value={hubItem.district_id}
                                >
                                  {hubItem.district_name}
                                </Option>
                              ))}
                          </Select>
                        </div>
                      </>
                    );
                  },
                },
                {
                  key: "is_active",
                  title: "Active",
                  dataIndex: "is_active",
                  width: 50,
                  //  filters: this.createFilters("is_active"),
                  // filters: [{ text: "Yes", value: 1 }, { text: "No", value: 0 }],
                  onFilter: (value, record) =>
                    record.is_active === Boolean(value),
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div
                          style={{ marginLeft: "-15px", marginRight: "-40px" }}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            disabled={!rowRecord.isEdit}
                            defaultValue={
                              rowRecord.is_active === true ? "Yes" : "No"
                            }
                            value={rowRecord.is_active === true ? "Yes" : "No"}
                            onChange={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "is_active",
                                index,
                                option.props.children,
                                false
                              )
                            }
                            style={{ width: "70%" }}
                          >
                            <Option key={1} value={1}>
                              {"Yes"}
                            </Option>
                            <Option key={0} value={0}>
                              {"No"}
                            </Option>
                          </Select>
                        </div>
                      </>
                    );
                  },
                },
                {
                  title: "Action",
                  dataIndex: "Submit",
                  key: "Submit",
                  width: 120,
                  visibility: false,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ display: "inline-flex", width: "100%" }}>
                          <Button
                            className={
                              !canUserEdit(
                                this.props.userData,
                                null,
                                FeatureCodes.MS_MANDI_STATE_HUB_EDIT
                              )
                                ? "disabledBtnClass"
                                : "btnClass"
                            }
                            disabled={
                              !canUserEdit(
                                this.props.userData,
                                null,
                                FeatureCodes.MS_MANDI_STATE_HUB_EDIT
                              )
                            }
                            type="primary"
                            size={"small"}
                            style={{
                              marginLeft: 5,
                              alignSelf: "center",
                              padding: "0px 0px 2px",
                              width: 70,
                              height: 31,

                              backgroundColor: "#20346a",
                              color: "white",
                              borderRadius: 15,
                            }}
                            block
                            htmlType="submit"
                            onClick={() =>
                              rowRecord.isEdit
                                ? this.submitTableRowData(
                                    rowRecord,
                                    index,
                                    false
                                  )
                                : this.handleEditChange(rowRecord, index)
                            }
                          >
                            {" "}
                            {rowRecord.isEdit ? "Submit" : "Edit"}{" "}
                          </Button>

                          <Button
                            className={
                              !rowRecord.isEdit
                                ? "disabledBtnClass"
                                : "btnClass"
                            }
                            type="primary"
                            size={"small"}
                            style={{
                              marginLeft: 5,
                              alignSelf: "center",
                              width: 70,
                              color: "white",
                              borderRadius: 15,
                              padding: "0px 0px 2px",

                              height: 31,
                              backgroundColor: "#20346a",
                            }}
                            block
                            htmlType="submit"
                            disabled={!rowRecord.isEdit}
                            onClick={() =>
                              this.handleCancelClick(rowRecord, index)
                            }
                          >
                            Cancel
                          </Button>
                        </div>
                      </>
                    );
                  },
                },
              ]}
              dataSource={this.setTableData()}
            />
          </div>
        </Spin>
      </div>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
  messageAccess: selectMSMessageRBAC,
});

export default connect(mapStateToProps)(MandiStateDistrictHubMaster);

// export default MandiStateDistrictHubMaster;
