import React, { Component } from "react";
import "./actual-purchase-information-table.styles.css";

import infoImage from "../../assets/info.svg";

import {
  Table,
  InputNumber,
  Tooltip,
  message,
  Button,
  Select,
  Input,
  Popover,
  Checkbox,
  Badge,
} from "antd";

import ActionItem from "../action-item/action-item.component";
import CompetitorHoverTable from "../competitor-hover-table/competitor-hover-table.component";
import RecommendationHoverTable from "../recommendations-hover-table/recommendations-hover-table.component";
import moment from "moment";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import {
  selectMandiRollUp,
  selectFORRollUp,
  selectFarmerRollUp,
  selectActualHubTableData,
  selectSelectedBranchID,
  selectSelectedRegionID,
  selectSelectedVarietyID,
  selectSelectedHubID,
  selectHubDataPostFOR,
  selectDualRender,
  selectDecisionBranches,
  selectDecisionRegions,
  selectClosureRemarks,
} from "../../redux/buying-decision/buying-decision.selectors";
import { selectAllVarietyInfo } from "../../redux/arrival-entry/arrival-entry.selectors";

import { selectSuggestedData } from "../../redux/buying-recommendation/buying-recommendation.selectors";
import {
  setSuggestedData,
  addSuggestedData,
} from "../../redux/buying-recommendation/buying-recommendation.actions";
import {
  setActualHubTableData,
  setActualHubDataPostFOR,
  setDualRender,
  incrementDualRender,
} from "../../redux/buying-decision/buying-decision.actions";

import { selectUserData } from "../../redux/user/user.selectors";

import API from "../../api";
import { canUserEdit } from "../../auth/utils";
import { FeatureCodes } from "../../auth/data";

const { Option } = Select;
const { TextArea } = Input;
/**
 * @component
 * @description Reusable component to display table header.
 * @property {string} title Title of header
 * @property {string} subtitle Subtitle of header
 * @memberof Approvals
 */
const TableColumnHeader = (props) => {
  const { title, subtitle } = props;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          fontWeight: "400",
          color: "white",
          fontSize: "14px",
          whiteSpace: "nowrap",
          textAlign: "left",
        }}
      >
        {title}
      </div>
      <div
        style={{
          color: "#cbdfff",
          fontSize: "11px",
          fontWeight: "normal",
          textAlign: "left",
        }}
      >
        {subtitle}
      </div>
    </div>
  );
};

/**
 * @component
 * @description Table header component used in buying decision table.
 * @property {string} title column header title.
 * @property {string} subtitle column header subtitle. Used for displaying units.
 * @property {number} value value to be displayed.
 * @property {boolean} isPopup enable popup text.
 * @property {boolean} isPrice true if value is of price type.
 * @property {boolean} isHover display hover text having individual roll-ups.
 * @property {number} mandiValue mandi level roll-up.
 * @property {number} FORValue trader level roll-up.
 * @property {number} farmerValue farmer-level roll-up.
 * @memberof ActualPurchaseInformationTable
 */
const TableNewHeader = (props) => {
  const {
    title,
    subtitle,
    value,
    isPopup,
    isPrice,
    isHover,
    mandiValue,
    FORValue,
    farmerValue,
  } = props;
  if (isHover) {
    return (
      <div className="col-container">
        <Popover
          content={
            <>
              <div>{`Mandi : ${mandiValue} ${isPrice ? "₹/MT" : "MT"}`}</div>
              <div>{`FOR : ${FORValue}  ${isPrice ? "₹/MT" : "MT"}`}</div>
              <div>{`Farmer : ${farmerValue}  ${isPrice ? "₹/MT" : "MT"}`}</div>
            </>
          }
          trigger="hover"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              color: "#BAD2F8",
              fontSize: "12px",
              whiteSpace: "break",
            }}
          >
            {title}
            {isPopup && (
              <Popover
                content={
                  <div>
                    The Arrival Total is the sum of all mandis under this region
                  </div>
                }
                trigger="hover"
                placement="bottom"
              >
                <img
                  style={{
                    width: "10px",
                    height: "10px",
                    margin: "2px 0 5px 5px",
                  }}
                  src={infoImage}
                  alt="info"
                />
              </Popover>
            )}
          </div>
          <div className="bottom-container">
            <h1>{value}</h1>
            <div
              style={{
                color: "#BAD2F8",
                fontSize: "12px",
                fontWeight: "normal",
                // marginLeft: "4px",
                marginTop: "0px",
              }}
            >
              {subtitle}
            </div>
          </div>
        </Popover>
      </div>
    );
  } else {
    //no hover text
    return (
      <div className="col-container">
        {isPopup && (
          <Popover
            content={
              <div>
                The Arrival Total is the sum of all mandis under this region
              </div>
            }
            trigger="hover"
          >
            <img
              style={{ width: "10px", height: "10px", margin: "0 0 5px 0px" }}
              src={infoImage}
              alt="info"
            />
          </Popover>
        )}
        <div
          style={{ color: "#BAD2F8", fontSize: "12px", whiteSpace: "break" }}
        >
          {title}
        </div>
        <div className="bottom-container">
          <h1>{value}</h1>
          <div
            style={{
              color: "#BAD2F8",
              fontSize: "12px",
              fontWeight: "normal",
              // marginLeft: "4px",
              marginTop: "0px",
            }}
          >
            {subtitle}
          </div>
        </div>
      </div>
    );
  }
};

//#endregion

const tableProps = {
  pagination: false,
  size: "default",
  bordered: true,
  scroll: { x: 200, y: window.innerHeight - 450 },
};

let modifiedHubData;

/**
 *
 * @component
 * @description Child component of buying decision planning page.
 */
class ActualPurchaseInformationTable extends Component {
  state = {
    localHubDataForTable: [],
    currentSelectedHub: {},
    loadingOn: false,

    drawerVisible: false,
    noOfChanges: "",
    selectAll: false,
    checkAll: false,
  };

  //#region methods

  // const types used for region level table rollups.
  informationTypes = {
    YESTERDAY_MODAL_PRICE: "YESTERDAY_MODAL_PRICE",
    YESTERDAY_ARRIVALS: "YESTERDAY_ARRIVALS",
    YESTERDAY_TOTAL_BOUGHT: "YESTERDAY_TOTAL_BOUGHT",
    TODAY_MODAL_PRICE: "TODAY_MODAL_PRICE",
    TODAY_ARRIVALS: "TODAY_ARRIVALS",
    TOTAL_SUGGESTED_QUANTITY: "TOTAL_SUGGESTED_QUANTITY",
    TOTAL_LANDING_COST: "TOTAL_LANDING_COST",
  };

  /**
   * @function
   * @description function to calculate region level rollups.
   * @param {string} informationType the type of roll up to be calculated.
   * @memberof ActualPurchaseInformationTable
   */
  calculateRegionLevelInformation = (informationType) => {
    if (this.props.actualHubTableData.region) {
      let hubData = this.props.actualHubTableData.region.hub_details;
      //yesterday data
      const yesterdayTotalArrivals =
        hubData &&
        hubData.reduce((acc, item) => acc + item.yesterday_arrival_qty, 0);

      const yesterdayTotalBoughtQuantity =
        hubData &&
        hubData.reduce((acc, item) => acc + item.yesterday_purchase_qty, 0);

      //today data
      const todayTotalArrivals =
        hubData &&
        hubData.reduce((acc, item) => acc + item.today_arrival_qty, 0);

      ////

      const totalSuggestedQuantity =
        hubData && hubData.reduce((acc, item) => acc + item.suggested_qty, 0);

      switch (informationType) {
        ////////////////////////////////////////////
        case this.informationTypes.YESTERDAY_MODAL_PRICE:
          const yesterdayTotalPrice = hubData
            ? hubData.reduce(
                (acc, item) =>
                  acc + item.yesterday_price * item.yesterday_arrival_qty,
                0
              )
            : 0;

          return yesterdayTotalArrivals === 0
            ? 0
            : Math.round(yesterdayTotalPrice / yesterdayTotalArrivals);

        case this.informationTypes.YESTERDAY_ARRIVALS:
          return Math.round(yesterdayTotalArrivals);
        case this.informationTypes.YESTERDAY_TOTAL_BOUGHT:
          return yesterdayTotalBoughtQuantity === 0
            ? 0
            : Math.round(yesterdayTotalBoughtQuantity);

        /////////////////////////////////////////

        case this.informationTypes.TODAY_MODAL_PRICE:
          const todayTotalPrice = hubData.reduce(
            (acc, item) => acc + item.today_price * item.today_arrival_qty,
            0
          );

          return todayTotalArrivals === 0
            ? 0
            : Math.round(todayTotalPrice / todayTotalArrivals);

        case this.informationTypes.TODAY_ARRIVALS:
          return Math.round(todayTotalArrivals);
        /////////////////////////////////////////////////////////////

        case this.informationTypes.TOTAL_SUGGESTED_QUANTITY:
          return Math.round(totalSuggestedQuantity);

        case this.informationTypes.TOTAL_LANDING_COST:
          const totalLandingCostSum = hubData.reduce(
            (acc, item) => acc + item.suggested_price * item.suggested_qty,
            0
          );

          return totalSuggestedQuantity === 0
            ? 0
            : Math.round(totalLandingCostSum / totalSuggestedQuantity);
        default:
          return "N/A";
      }
    } else return "N/A";
  };

  // failsafe to wait until hubtable data is available for rendering.
  // called inside componentDidMount.
  /**
   * @function
   * @description failsafe to wait until hubtable data is available for rendering.
   * @memberof ActualPurchaseInformationTable
   */
  waitForHubData() {
    if (this.props.actualHubTableData.length !== 0) {
      setActualHubDataPostFOR(
        this.props.actualHubTableData.region &&
          this.props.actualHubTableData.region.hub_details
            .map((item, idx) => {
              if (
                this.props.actualHubTableData.region.hub_details[idx]
                  .buying_decision_id !== null
              ) {
                return {
                  // hub_id: item.hub_id,
                  // buying_decision_id: item.buying_decision_id,
                  // temp_bd_id: item.temp_bd_id,
                  // region_id: item.region_id,
                  // variety_id: item.variety_id,
                  // revised_qty: item.revised_qty,
                  // revised_buy_min_price: item.revised_buy_min_price,
                  // revised_buy_max_price: item.revised_buy_max_price,
                  // remark_id: item.remark_id ? item.remark_id : null,
                  // source_type: item.source_type,
                  // claim_type: item.claim_type,
                  // division: item.division,
                  // hub_name: item.hub_name,
                  // variety_name: item.variety_name
                  // hub_id: item.hub_id,
                  buying_decision_id: item.buying_decision_id,
                  // temp_bd_id: item.temp_bd_id,
                  revised_qty: item.revised_qty,
                  revised_price: item.revised_price,
                  // revised_buy_max_price: item.revised_buy_max_price,
                  remark_id: item.remark_id ? item.remark_id : null,
                  source_type: item.source_type,
                  region_id: item.region_id,
                  variety_id: item.variety_id,
                  claim_type: item.claim_type,
                  division: item.division,
                  hub_name: item.hub_name,
                  ui_purchase_price: null,
                  ui_purchase_qty: null,
                  ui_purchase_remarks: null,
                  variety_name: item.variety_name,
                };
              }
            })
            .filter((item) => item !== undefined)
      );
    } else {
      setTimeout(() => {
        this.waitForHubData();
      }, 100);
    }
  }

  //#endregion

  componentDidMount() {
    this.waitForHubData();
  }

  init = 0;

  render() {
    const ConfigOptions = {
      maxCount: 1,
    };
    message.config(ConfigOptions);

    // this.state.checkAll = (this.props.actualHubTableData && this.props.actualHubTableData.region && this.props.actualHubTableData.region.hub_details.length !== 0 && ((this.props.actualHubTableData && this.props.actualHubTableData.region && this.props.actualHubTableData.region.hub_details && this.props.actualHubTableData.region.hub_details.length && this.props.actualHubTableData.region.hub_details.filter((obj) => obj.checked === true).length) === (this.props.actualHubTableData && this.props.actualHubTableData.region && this.props.actualHubTableData.region.hub_details.length)));

    const sampleData = {
      data: {
        division: "ABD",
        recommendations: [
          {
            name: "A",
            type: "trader",
            quantity: 1000,
            min_price: 1200,
            max_price: 2200,
          },
          {
            name: "B",
            type: "olakka",
            quantity: 3000,
            min_price: 3200,
            max_price: 4200,
          },
        ],
      },
    };

    const {
      userData,
      actualHubTableData,
      remarksList,
      selectedBranchID,
      selectedRegionID,
      selectedVarietyID,
      setActualHubTableData,
      setRemarkHubRowFORPost,
      setActualHubDataPostFOR,
    } = this.props;

    let hubData;

    const user = userData;

    const allRemarks = remarksList.length !== 0 ? remarksList : [];

    const PRICE_TYPES = {
      MANDI_LEVEL: "MANDI_LEVEL",
      FOR_LEVEL: "FOR_LEVEL",
      FARMER_LEVEL: "FARMER_LEVEL",
      COMBINED: "COMBINED",
    };

    //#region all functions in use

    const getTotalQuantity = (label) => {
      let totalQuantity = 0;

      if (
        this.props.actualHubTableData.length !== 0 &&
        this.props.actualHubTableData.region &&
        this.props.actualHubTableData.region.hub_details.length !== 0
      ) {
        totalQuantity = this.props.actualHubTableData.region.hub_details.reduce(
          (acc, item) => acc + item[label],
          0
        );
      }
      return parseInt(totalQuantity);
    };

    const getYesterdayModalPrice = () => {
      let modalPrice = 0;
      let totalPurchaseQuantity = 0;

      if (
        this.props.actualHubTableData.length !== 0 &&
        this.props.actualHubTableData.region &&
        this.props.actualHubTableData.region.hub_details.length !== 0
      ) {
        totalPurchaseQuantity = getTotalQuantity("yesterday_purchase_qty");

        modalPrice =
          totalPurchaseQuantity === 0
            ? 0
            : this.props.actualHubTableData.region.hub_details.reduce(
                (acc, item) =>
                  acc + item.yesterday_purchase_qty * item.yesterday_price,
                0
              ) / totalPurchaseQuantity;
      }
      return parseInt(modalPrice);
    };

    const setSuggestedPriceModalLC = (isMin) => {
      let minSuggestedModalLC = 0;
      // let maxModalLC = 0;

      if (
        this.props.actualHubTableData.length !== 0 &&
        this.props.actualHubTableData.region &&
        this.props.actualHubTableData.region.hub_details.length !== 0
      ) {
        let totalSuggestedQuantity =
          this.props.actualHubTableData.region.hub_details.reduce(
            (acc, item) => acc + item.suggested_qty,
            0
          );

        //   (acc, item) =>
        //     acc + item.suggested_qty * item.suggested_price,
        //   0
        // ), this.props.actualHubTableData.region.hub_details.reduce(
        //   (acc, item) =>
        //     acc + item.suggested_qty * item.suggested_price,
        //   0
        // ) / totalSuggestedQuantity);

        minSuggestedModalLC =
          totalSuggestedQuantity === 0
            ? 0
            : this.props.actualHubTableData.region.hub_details.reduce(
                (acc, item) => acc + item.suggested_qty * item.suggested_price,
                0
              ) / totalSuggestedQuantity;
        // maxModalLC =
        //   totalRevisedQuantity === 0
        //     ? 0
        //     : this.props.actualHubTableData.region.hub_details.reduce(
        //       (acc, item) =>
        //         acc + item.revised_qty * item.revised_buy_max_price,
        //       0
        //     ) / totalRevisedQuantity;

        if (isMin) {
          return Math.round(minSuggestedModalLC);
        }
        // else {
        //   return parseInt(maxModalLC);
        // }
      }

      return 0;
    };
    const setRevisedPriceModalLC = (isMin) => {
      let minRevisedModalLC = 0;
      // let maxModalLC = 0;

      if (
        this.props.actualHubTableData.length !== 0 &&
        this.props.actualHubTableData.region &&
        this.props.actualHubTableData.region.hub_details.length !== 0
      ) {
        let totalRevisedQuantity =
          this.props.actualHubTableData.region.hub_details.reduce(
            (acc, item) => acc + item.revised_qty,
            0
          );
        //   (acc, item) =>
        //     acc + item.revised_qty * item.revised_price,
        //   0
        // ), this.props.actualHubTableData.region.hub_details.reduce(
        //   (acc, item) =>
        //     acc + item.revised_qty * item.revised_price,
        //   0
        // ) / totalRevisedQuantity);

        minRevisedModalLC =
          totalRevisedQuantity === 0
            ? 0
            : this.props.actualHubTableData.region.hub_details.reduce(
                (acc, item) => acc + item.revised_qty * item.revised_price,
                0
              ) / totalRevisedQuantity;
        // maxModalLC =
        //   totalRevisedQuantity === 0
        //     ? 0
        //     : this.props.actualHubTableData.region.hub_details.reduce(
        //       (acc, item) =>
        //         acc + item.revised_qty * item.revised_buy_max_price,
        //       0
        //     ) / totalRevisedQuantity;

        if (isMin) {
          return Math.round(minRevisedModalLC);
        }
        // else {
        //   return parseInt(maxModalLC);
        // }
      }

      return 0;
    };

    const setActualPriceModalLC = (isMin) => {
      let minActualModalLC = 0;
      // let maxModalLC = 0;

      if (
        this.props.actualHubTableData.length !== 0 &&
        this.props.actualHubTableData.region &&
        this.props.actualHubTableData.region.hub_details.length !== 0
      ) {
        let totalActualQuantity =
          this.props.actualHubTableData.region.hub_details.reduce(
            (acc, item) => acc + item.ui_purchase_qty,
            0
          );

        minActualModalLC =
          totalActualQuantity === 0
            ? 0
            : this.props.actualHubTableData.region.hub_details.reduce(
                (acc, item) =>
                  acc + item.ui_purchase_qty * item.ui_purchase_price,
                0
              ) / totalActualQuantity;
        // maxModalLC =
        //   totalRevisedQuantity === 0
        //     ? 0
        //     : this.props.actualHubTableData.region.hub_details.reduce(
        //       (acc, item) =>
        //         acc + item.revised_qty * item.revised_buy_max_price,
        //       0
        //     ) / totalRevisedQuantity;

        if (isMin) {
          return Math.round(minActualModalLC);
        }
        // else {
        //   return parseInt(maxModalLC);
        // }
      }

      return 0;
    };

    const setModalLC = (isMin) => {
      let minModalLC = 0;
      let maxModalLC = 0;

      if (
        this.props.actualHubTableData.length !== 0 &&
        this.props.actualHubTableData.region &&
        this.props.actualHubTableData.region.hub_details.length !== 0
      ) {
        let totalRevisedQuantity =
          this.props.actualHubTableData.region.hub_details.reduce(
            (acc, item) => acc + item.revised_qty,
            0
          );

        minModalLC =
          totalRevisedQuantity === 0
            ? 0
            : this.props.actualHubTableData.region.hub_details.reduce(
                (acc, item) =>
                  acc + item.revised_qty * item.revised_buy_min_price,
                0
              ) / totalRevisedQuantity;
        maxModalLC =
          totalRevisedQuantity === 0
            ? 0
            : this.props.actualHubTableData.region.hub_details.reduce(
                (acc, item) =>
                  acc + item.revised_qty * item.revised_buy_max_price,
                0
              ) / totalRevisedQuantity;

        if (isMin) {
          return parseInt(minModalLC);
        } else {
          return parseInt(maxModalLC);
        }
      }

      return 0;
    };

    //#region functions to set min and max LC at region level
    /**
     * @description Get the minimum range of landing cost
     * @param {string} priceType type of price (Mandi/Trader/Farmer/All)
     * @returns {number} minimum landing cost limit
     * @memberof ActualPurchaseInformationTable
     */
    const setMinimumLC = (priceType) => {
      let combinedMinPrice = Infinity,
        mandisMinPrice = Infinity,
        FORMinPrice = Infinity,
        farmerMinPrice = Infinity;

      if (
        this.props.actualHubTableData.length !== 0 &&
        this.props.actualHubTableData.region &&
        this.props.actualHubTableData.region.hub_details.length !== 0
      ) {
        this.props.actualHubTableData.region.hub_details.map((item) => {
          if (
            item.revised_buy_min_price < combinedMinPrice &&
            item.revised_buy_min_price !== 0
          ) {
            combinedMinPrice = item.revised_buy_min_price;
          }
          if (
            item.revised_buy_min_price < mandisMinPrice &&
            item.source_type === "mandi" &&
            item.revised_buy_min_price !== 0
          ) {
            mandisMinPrice = item.revised_buy_min_price;
          }
          if (
            item.revised_buy_min_price < FORMinPrice &&
            item.source_type === "trader" &&
            item.revised_buy_min_price !== 0
          ) {
            FORMinPrice = item.revised_buy_min_price;
          }
          if (
            item.revised_buy_min_price < farmerMinPrice &&
            item.source_type === "farmer" &&
            item.revised_buy_min_price !== 0
          ) {
            farmerMinPrice = item.revised_buy_min_price;
          }
        });

        switch (priceType) {
          case PRICE_TYPES.MANDI_LEVEL:
            return mandisMinPrice === Infinity ? 0 : Math.round(mandisMinPrice);
          case PRICE_TYPES.FOR_LEVEL:
            return FORMinPrice === Infinity ? 0 : Math.round(FORMinPrice);
          case PRICE_TYPES.FARMER_LEVEL:
            return farmerMinPrice === Infinity ? 0 : Math.round(farmerMinPrice);
          case PRICE_TYPES.COMBINED:
            return combinedMinPrice === Infinity
              ? 0
              : Math.round(combinedMinPrice);

          default:
            break;
        }
      } else {
        return 0;
      }
    };

    /**
     * @description Get the maximum range of landing cost
     * @param {string} priceType type of price (Mandi/Trader/Farmer/All)
     * @returns {number} maximum landing cost limit
     * @memberof ActualPurchaseInformationTable
     */
    const setMaximumLC = (priceType) => {
      let combinedMaxPrice = 0,
        mandisMaxPrice = 0,
        FORMaxPrice = 0,
        farmerMaxPrice = 0;

      if (
        this.props.actualHubTableData.length !== 0 &&
        this.props.actualHubTableData.region &&
        this.props.actualHubTableData.region.hub_details.length !== 0
      ) {
        this.props.actualHubTableData.region.hub_details.map((item) => {
          if (item.revised_buy_max_price > combinedMaxPrice) {
            combinedMaxPrice = item.revised_buy_max_price;
          }
          if (
            item.revised_buy_max_price > mandisMaxPrice &&
            item.source_type === "mandi"
          ) {
            mandisMaxPrice = item.revised_buy_max_price;
          }
          if (
            item.revised_buy_max_price > FORMaxPrice &&
            item.source_type === "trader"
          ) {
            FORMaxPrice = item.revised_buy_max_price;
          }
          if (
            item.revised_buy_max_price > farmerMaxPrice &&
            item.source_type === "farmer"
          ) {
            farmerMaxPrice = item.revised_buy_max_price;
          }
        });

        switch (priceType) {
          case PRICE_TYPES.MANDI_LEVEL:
            return Math.round(mandisMaxPrice);
          case PRICE_TYPES.FOR_LEVEL:
            return Math.round(FORMaxPrice);
          case PRICE_TYPES.FARMER_LEVEL:
            return Math.round(farmerMaxPrice);
          case PRICE_TYPES.COMBINED:
            return Math.round(combinedMaxPrice);

          default:
            break;
        }
      } else {
        return 0;
      }
    };
    //#endregion

    // TODO: maybe needed in the future. use to set the min and max ranges for landing cost.
    //       verify whether to multiply by 10 to factor for quintals to MT - multiplied by 10.
    const getVarietyMinPrice = () => {
      const minPrice = this.props.allVarietyInfo.find(
        (item) => item.variety_id === this.props.selectedVarietyID
      ).variety_price_min;
      return minPrice * 10;
    };
    const getVarietyMaxPrice = () => {
      const maxPrice = this.props.allVarietyInfo.find(
        (item) => item.variety_id === this.props.selectedVarietyID
      ).variety_price_max;
      return maxPrice * 10;
    };

    if (actualHubTableData) {
      if (actualHubTableData.region) {
        this.hubdata = [];
        hubData = actualHubTableData.region.hub_details;

        //save all FOR hubs into redux store
        modifiedHubData =
          actualHubTableData.region.hub_details &&
          actualHubTableData.region.hub_details
            .map((item, idx) => {
              if (
                this.props.actualHubTableData.region.hub_details[idx]
                  .buying_decision_id !== null
              ) {
                return {
                  // hub_id: item.hub_id,
                  // buying_decision_id: item.buying_decision_id,
                  // temp_bd_id: item.temp_bd_id,
                  // region_id: item.region_id,
                  // variety_id: item.variety_id,
                  // revised_qty: item.revised_qty,
                  // revised_buy_min_price: item.revised_buy_min_price,
                  // revised_buy_max_price: item.revised_buy_max_price,
                  // remark_id: item.remark_id ? item.remark_id : null,
                  // source_type: item.source_type,
                  // claim_type: item.claim_type,
                  // division: item.division,
                  // hub_name: item.hub_name,
                  // variety_name: item.variety_name
                  // hub_id: item.hub_id,
                  buying_decision_id: item.buying_decision_id,
                  // temp_bd_id: item.temp_bd_id,
                  revised_qty: item.revised_qty,
                  revised_price: item.revised_price,
                  // revised_buy_max_price: item.revised_buy_max_price,
                  remark_id: item.remark_id ? item.remark_id : null,
                  source_type: item.source_type,
                  region_id: item.region_id,
                  variety_id: item.variety_id,
                  claim_type: item.claim_type,
                  division: item.division,
                  hub_name: item.hub_name,
                  ui_purchase_price: null,
                  ui_purchase_qty: null,
                  ui_purchase_remarks: null,
                  variety_name: item.variety_name,
                };
              }
            })
            .filter((item) => item !== undefined);

        if (this.init < 2) {
          //rendering twice for updating data
          setActualHubDataPostFOR(modifiedHubData);
          this.init++;
        }
      }
    }
    /**
     * @description function to set the hub level table data
     * @memberof ActualPurchaseInformationTable
     */
    const setHubDataForTable = () => {
      // if (hubDataSent[0].competitor_bids === undefined) {
      //   this.setState({ localHubDataForTable: hubDataSent });
      //   // setLocalHubDataForTable(hubDataSent)
      // } else {
      const tableData =
        hubData &&
        hubData.map((hubItem, idx) => {
          const modifiedKey =
            // hubItem.source_type +
            // "_" +
            hubItem.hub_id +
            "_" +
            hubItem.claim_type +
            "_" +
            hubItem.division +
            "_" +
            hubItem.variety_id +
            "_" +
            hubItem.region_id;

          return {
            key: modifiedKey,
            buying_decision_id: hubItem.buying_decision_id,
            // temp_bd_id: hubItem.temp_bd_id,
            data: hubItem,
            hub: hubItem.hub_name,
            date: moment(hubItem.decision_date).format("DD-MM-YYYY"),
            variety: hubItem.variety_name,
            regionID: hubItem.region_id,
            varietyID: hubItem.variety_id,
            decisionDate: hubItem.decision_date,
            ui_purchase_price: hubItem.ui_purchase_price,
            ui_purchase_qty: hubItem.ui_purchase_qty,
            ui_purchase_remarks: hubItem.ui_purchase_remarks,
            //Yesterday's data
            modalPriceYesterday: Math.round(hubItem.yesterday_price),
            arrivalsYesterday: Math.round(hubItem.yesterday_arrival_qty),
            qtyBoughtYesterday:
              hubItem.yesterday_purchase_qty !== null
                ? Math.round(hubItem.yesterday_purchase_qty)
                : "0",

            //Today's data
            modalPriceToday: Math.round(hubItem.today_price),
            arrivalsToday:
              hubItem.today_arrival_qty !== null
                ? Math.round(hubItem.today_arrival_qty)
                : "0",

            suggestedQty: hubItem.suggested_qty
              ? Math.round(hubItem.suggested_qty)
              : 0,
            revisedQty: Math.round(hubItem.revised_qty),

            srp: hubItem.suggested_price
              ? Math.round(hubItem.suggested_price)
              : 0,
            minPlanned: Math.round(hubItem.revised_buy_min_price),
            maxPlanned: Math.round(hubItem.revised_buy_max_price),

            remarkId: Math.round(hubItem.remark_id),

            actions: { hubID: hubItem.hub_id },
            checked: hubItem.checked !== undefined ? hubItem.checked : false,
            edited: hubItem.edited,

            approvals: {
              approval1: hubItem.approval1,
              approval2: hubItem.approval2,
              approver1Name: hubItem.approver1_name,
              approver2Name: hubItem.approver2_name,
              comment1: hubItem.comment1,
              comment2: hubItem.comment2,
            },

            mandiBuying: {
              mandiData: hubItem.mandi_list,
              isMandi: hubItem.source_type === "mandi" ? true : false,
              sourceType: hubItem.source_type,
            },
            // competitors: hubItem.competitor_bids.map((competitor) => {
            //   return {
            //     key: competitor.competitor_bid_id,
            //     competitor_name: competitor.competitor_name,
            //     competitor_min_price: competitor.competitor_min_price,
            //     competitor_max_price: competitor.competitor_max_price,
            //     purchase_plan: competitor.purchase_plan,
            //     name: competitor.mandi_name
            //   };
            // }),
          };
        });
      console.log("first", tableData);
      return tableData;
      // this.setState({ localHubDataForTable: tableData });
      // }
    };

    // hubData && hubData.length ? setHubDataForTable(hubData) : ""

    //#endregion

    return (
      <div className="table-container">
        <div className="actual-hub-table">
          <Table
            key={selectedVarietyID + "_" + selectedRegionID}
            {...tableProps}
            columns={[
              {
                title: "Hub",
                key: "hub1",
                width: 100,
                children: [
                  {
                    title: "",
                    key: "hub2",
                    width: 100,
                    children: [
                      {
                        title: <div style={{ fontSize: "14px" }}>Summary</div>,
                        dataIndex: "hub",
                        key: "hub3",
                        width: 100,
                      },
                    ],
                  },
                ],
              },
              {
                title: "Date",
                key: "date1",
                width: 100,
                children: [
                  {
                    title: "",
                    key: "date2",
                    width: 100,
                    children: [
                      {
                        // title: <div style={{ fontSize: "14px" }}></div>,
                        dataIndex: "date",
                        key: "date3",
                        width: 100,
                      },
                    ],
                  },
                ],
              },
              {
                title: "Variety",
                key: "variety1",
                width: 100,
                children: [
                  {
                    title: "",
                    key: "variety2",
                    width: 100,
                    children: [
                      {
                        // title: <div style={{ fontSize: "14px" }}></div>,
                        dataIndex: "variety",
                        key: "variety3",
                        width: 100,
                      },
                    ],
                  },
                ],
              },
              {
                title: "Claim Type",
                key: "mandiBuying_value",
                width: 90,
                children: [
                  {
                    title: "",
                    key: "mandiBuying_value",
                    width: 90,
                    children: [
                      {
                        dataIndex: "claim_type",
                        key: "claim_type",
                        width: 90,
                        render: (record, rowRecord) => {
                          return (
                            <div>
                              <div>{rowRecord.data.claim_type}</div>
                            </div>
                          );
                        },
                      },
                    ],
                  },
                ],
              },
              // {
              //   title: "Division",
              //   key: "division1",
              //   width: 80,
              //   children: [
              //     {
              //       title: "",
              //       key: "division2",
              //       width: 80,
              //       children: [
              //         {
              //           dataIndex: "srp",
              //           key: "srp_value",
              //           width: 80,
              //           render: (record, rowRecord) => {
              //             return (
              //               <div>
              //                 <div>{rowRecord.data.division}</div>
              //               </div>
              //             );
              //           },
              //         },
              //       ],
              //     },
              //   ],
              // },
              {
                title: "Recommendation",
                children: [
                  {
                    title: <TableNewHeader title="Suggested Quantity" />,
                    key: "suggestedQty",
                    width: 80,

                    children: [
                      {
                        // title: <TableNewHeader value="" />,
                        title: (
                          <TableNewHeader
                            value={Math.round(getTotalQuantity("suggested_qty"))
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          />
                        ),
                        dataIndex: "suggestedQty",
                        key: "suggested_qty",
                        width: 80,
                        render: (record, rowRecord) => {
                          return (
                            <div>
                              <div>{rowRecord.data.suggested_qty}</div>
                            </div>
                          );
                        },
                      },
                    ],
                  },
                  {
                    title: <TableNewHeader title="Suggested Price" />,
                    key: "suggestedQty",
                    width: 80,

                    children: [
                      {
                        // title: <TableNewHeader value="" />,
                        // title: (
                        //   <TableNewHeader
                        //     value={Math.round(getTotalQuantity("suggested_price"))
                        //       .toString()
                        //       .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        //   />
                        // ),
                        title: (
                          <TableNewHeader
                            isPrice
                            value={setSuggestedPriceModalLC(true)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          />
                        ),
                        dataIndex: "suggestedQty",
                        key: "suggested_price",
                        width: 80,
                        render: (record, rowRecord) => {
                          return (
                            <div>
                              <div>{rowRecord.data.suggested_price}</div>
                            </div>
                          );
                        },
                      },
                    ],
                  },
                ],
              },

              {
                title: "Planned",
                children: [
                  {
                    title: <TableNewHeader title="Revised Quantity" />,
                    key: "revisedQty",
                    width: 80,

                    children: [
                      {
                        // title: <TableNewHeader value="" />,
                        title: (
                          <TableNewHeader
                            value={Math.round(getTotalQuantity("revised_qty"))
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          />
                        ),
                        dataIndex: "revisedQty",
                        key: "revised_qty",
                        width: 80,
                        render: (record, rowRecord) => {
                          return (
                            <div>
                              <div>{rowRecord.data.revised_qty}</div>
                            </div>
                          );
                        },
                      },
                    ],
                  },
                  {
                    title: <TableNewHeader title="Revised Price" />,
                    key: "revisedPrice",
                    width: 80,

                    children: [
                      {
                        // title: <TableNewHeader value="" />,
                        title: (
                          <TableNewHeader
                            isPrice
                            value={setRevisedPriceModalLC(true)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          />
                        ),
                        // title: (
                        //   <TableNewHeader
                        //     value={Math.round(getTotalQuantity("revised_price"))
                        //       .toString()
                        //       .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        //   />
                        // ),
                        dataIndex: "revisedPrice",
                        key: "revised_price",
                        width: 80,
                        render: (record, rowRecord) => {
                          return (
                            <div>
                              <div>{rowRecord.data.revised_price}</div>
                            </div>
                          );
                        },
                      },
                    ],
                  },
                ],
              },

              {
                title: "Actuals",
                children: [
                  // {
                  //   title: <TableNewHeader title="Suggested Quantity" />,
                  //   key: "suggestedQty",
                  //   width: 90,

                  //   children: [
                  //     {
                  //       title: <TableNewHeader value="" />,
                  //       dataIndex: "suggestedQty",
                  //       key: "suggested_qty",
                  //       width: 90,
                  //       render: (record, rowRecord) => {
                  //         return (
                  //           <div>
                  //             <div>{rowRecord.data.suggested_qty}</div>
                  //           </div>
                  //         );
                  //       },
                  //     },
                  //   ],
                  // },
                  // {
                  //   title: <TableNewHeader title="Suggested Price" />,
                  //   key: "suggestedQty",
                  //   width: 90,

                  //   children: [
                  //     {
                  //       title: <TableNewHeader value="" />,
                  //       dataIndex: "suggestedQty",
                  //       key: "suggested_price",
                  //       width: 90,
                  //       render: (record, rowRecord) => {
                  //         return (
                  //           <div>
                  //             <div>{rowRecord.data.suggested_price}</div>
                  //           </div>
                  //         );
                  //       },
                  //     },
                  //   ],
                  // },
                  // {
                  //   title: <TableNewHeader title="Revised Quantity" />,
                  //   key: "revisedQty",
                  //   width: 90,

                  //   children: [
                  //     {
                  //       title: <TableNewHeader value="" />,
                  //       dataIndex: "revisedQty",
                  //       key: "revised_qty",
                  //       width: 90,
                  //       render: (record, rowRecord) => {
                  //         return (
                  //           <div>
                  //             <div>{rowRecord.data.revised_qty}</div>
                  //           </div>
                  //         );
                  //       },
                  //     },
                  //   ],
                  // },
                  // {
                  //   title: <TableNewHeader title="Revised Price" />,
                  //   key: "revisedPrice",
                  //   width: 90,

                  //   children: [
                  //     {
                  //       title: <TableNewHeader value="" />,
                  //       dataIndex: "revisedPrice",
                  //       key: "revised_price",
                  //       width: 90,
                  //       render: (record, rowRecord) => {
                  //         return (
                  //           <div>
                  //             <div>{rowRecord.data.revised_price}</div>
                  //           </div>
                  //         );
                  //       },
                  //     },
                  //   ],
                  // },
                  {
                    title: (
                      <TableNewHeader title="Purchase Quantity" subtitle="MT" />
                    ),
                    key: "ui_purchase_qty",
                    width: 80,

                    children: [
                      {
                        title: (
                          <TableNewHeader
                            value={Math.round(
                              getTotalQuantity("ui_purchase_qty")
                            )
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          />
                        ),
                        dataIndex: "ui_purchase_qty",
                        key: "ui_purchase_qty",
                        width: 80,
                        render: (suggestedQty, record) => {
                          // const conditionalProps = record.mandiBuying.isMandi
                          //   ? {
                          //     value:
                          //       record.revisedQty >= 0
                          //         ? record.revisedQty
                          //         : suggestedQty,
                          //   }
                          //   : null;
                          return (
                            <div
                              className={`quantity-container 
                              ${
                                record.mandiBuying.isMandi ||
                                !canUserEdit(
                                  user,
                                  selectedBranchID,
                                  FeatureCodes.BUYING_DECISION_SUBMIT
                                )
                                  ? "disable-field"
                                  : ""
                              }`}
                            >
                              {/* <Tooltip title={`Purchase Quantity: ${record.ui_purchase_qty} MT`}> */}
                              <InputNumber
                                // disabled={
                                //   record.mandiBuying.isMandi ||
                                //   !canUserEdit(
                                //     user,
                                //     selectedBranchID,
                                //     FeatureCodes.BUYING_DECISION_SUBMIT
                                //   )
                                // }
                                // disabled={
                                //   moment(record.decisionDate).format(
                                //     "YYYY-MM-DD"
                                //   ) < moment().format("YYYY-MM-DD")
                                // }
                                value={record.ui_purchase_qty}
                                formatter={(value) =>
                                  `${value}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )
                                }
                                onChange={(value) => {
                                  this.props.setQuantityHubRowFORPost(
                                    record.actions.hubID,
                                    value,
                                    null,
                                    record.mandiBuying.sourceType,
                                    record
                                  );
                                }}
                                min={0}
                                // placeholder={`${record.revisedQty >= 0
                                //   ? record.revisedQty
                                //   : suggestedQty
                                //   }`}
                                // {...conditionalProps}
                                defaultValue={record.ui_purchase_qty}
                              />
                              {/* </Tooltip> */}
                            </div>
                          );
                        },
                      },
                    ],
                  },
                  {
                    title: (
                      <TableNewHeader
                        isPrice
                        title="Purchase Price"
                        subtitle="₹/MT"
                      />
                    ),
                    key: "ui_purchase_price",
                    width: 80,

                    children: [
                      {
                        title: (
                          <TableNewHeader
                            isPrice
                            value={setActualPriceModalLC(true)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          />
                        ),
                        // title: (
                        //   <TableNewHeader
                        //     value={Math.round(getTotalQuantity("ui_purchase_price"))
                        //       .toString()
                        //       .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        //   />
                        // ),
                        dataIndex: "ui_purchase_price",
                        key: "ui_purchase_price",
                        width: 80,
                        render: (quantity, record) => {
                          // const conditionalProps = record.mandiBuying.isMandi
                          //   ? {
                          //     value: quantity,
                          //   }
                          //   : null;
                          return (
                            <div
                              onClick={() => console.log("record : ", record)}
                              className={`quantity-container ${
                                record.mandiBuying.isMandi ||
                                !canUserEdit(
                                  user,
                                  selectedBranchID,
                                  FeatureCodes.BUYING_DECISION_SUBMIT
                                )
                                  ? "disable-field"
                                  : ""
                              }`}
                            >
                              <InputNumber
                                // disabled={
                                //   record.mandiBuying.isMandi ||
                                //   !canUserEdit(
                                //     user,
                                //     selectedBranchID,
                                //     FeatureCodes.BUYING_DECISION_SUBMIT
                                //   )
                                // }
                                // disabled={
                                //   moment(record.decisionDate).format(
                                //     "YYYY-MM-DD"
                                //   ) < moment().format("YYYY-MM-DD")
                                // }
                                value={record.ui_purchase_price}
                                formatter={(value) =>
                                  `${value}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )
                                }
                                onChange={(value) => {
                                  this.props.setTLCHubRowFORPost(
                                    record.actions.hubID,
                                    value,
                                    true,
                                    record.mandiBuying.sourceType,
                                    record
                                  );
                                }}
                                min={0}
                                // {...conditionalProps}
                                defaultValue={record.ui_purchase_price}
                              />
                            </div>
                          );
                        },
                      },
                    ],
                  },

                  {
                    title: <TableNewHeader title="Remarks" />,
                    key: "remarks",
                    width: 150,

                    children: [
                      {
                        title: <TableNewHeader title="" />,
                        dataIndex: "ui_purchase_remarks",
                        key: "remarks_value",
                        width: 150,
                        render: (remarks, record) => (
                          <div
                            className={`select-remarks-actual ${
                              record.mandiBuying.isMandi ||
                              !canUserEdit(
                                user,
                                selectedBranchID,
                                FeatureCodes.BUYING_DECISION_SUBMIT
                              )
                                ? "disable-field"
                                : ""
                            }`}
                          >
                            {/* <TextArea
                              // disabled={
                              //   record.mandiBuying.isMandi ||
                              //   !canUserEdit(
                              //     user,
                              //     selectedBranchID,
                              //     FeatureCodes.BUYING_DECISION_SUBMIT
                              //   )
                              // }
                              disabled={
                                moment(record.decisionDate).format(
                                  "YYYY-MM-DD"
                                ) < moment().format("YYYY-MM-DD")
                              }
                              showCount
                              maxLength={250}
                              // formatter={(value) =>
                              //   `${value}`.replace(
                              //     /\B(?=(\d{3})+(?!\d))/g,
                              //     ","
                              //   )
                              // }
                              onChange={(e) => {
                                this.props.setRemarkHubRowFORPost(
                                  record.actions.hubID,
                                  e.target.value,
                                  record.mandiBuying.sourceType,
                                  record
                                );
                              }}
                              placeholder="Enter remarks"
                              autoSize={{ minRows: 1, maxRows: 2 }}
                              // {...conditionalProps}
                              value={record.ui_purchase_remarks}
                              defaultValue={record.ui_purchase_remarks}
                            /> */}
                            {!record.mandiBuying.isMandi ? (
                              <Select
                                className="remarks-select"
                                // disabled={
                                //   moment(record.decisionDate).format(
                                //     "YYYY-MM-DD"
                                //   ) < moment().format("YYYY-MM-DD")
                                // }
                                defaultValue={
                                  record.ui_purchase_remarks && remarksList
                                    ? remarksList.find(
                                        (item) =>
                                          item.remark_id.toString() ===
                                          record.ui_purchase_remarks.toString()
                                      )?.remark
                                    : "Add a remark"
                                }
                                onSelect={(value) =>
                                  setRemarkHubRowFORPost(
                                    record.actions.hubID,
                                    value,
                                    record.mandiBuying.sourceType,
                                    record
                                  )
                                }
                              >
                                {remarksList &&
                                  remarksList.map((remarkItem, idx) => (
                                    <Option
                                      key={remarkItem.remark_id}
                                      value={remarkItem.remark_id}
                                    >
                                      {remarkItem.remark}
                                    </Option>
                                  ))}
                              </Select>
                            ) : null}
                          </div>
                        ),
                      },
                    ],
                  },
                ],
              },

              // {
              //   title: "Submit",
              //   dataIndex: "actions",
              //   key: "actions",
              //   width: 85,
              //   render: (actions, record) => {
              //     return (
              //       <div
              //         style={{
              //           display: "flex",
              //           flexDirection: "row",
              //           justifyContent: "space-between",
              //         }}
              //       >
              //         <div style={{ whiteSpace: "nowrap" }}>
              //           <div>
              //             {!record.mandiBuying.isMandi && (
              //               <ActionItem
              //                 canUserSubmit={canUserEdit(
              //                   user,
              //                   selectedBranchID,
              //                   FeatureCodes.BUYING_DECISION_SUBMIT
              //                 )}

              //                 postBuyingDecisionHub={
              //                   this.props.postBuyingDecisionHub
              //                 }
              //                 rowRecord={record}
              //                 postHubRowId={record.actions.hubID}
              //                 sourceType={record.mandiBuying.sourceType}
              //                 srp={record.srp}
              //               />
              //             )}
              //           </div>
              //         </div>

              //         <Popover
              //           overlayClassName="competitor-hover-container"
              //           content={
              //             <RecommendationHoverTable isMT record={record} />
              //           }
              //           title={
              //             record.data.recommendations.length !== 0
              //               ? "Recommendations"
              //               : "No Recommendations"
              //           }
              //         >
              //           <img
              //             style={{
              //               width: "10px",
              //               height: "10px",
              //               margin: "2px 0 5px 5px",
              //             }}
              //             src={infoImage}
              //             alt="recommendations"
              //           />
              //         </Popover>
              //       </div>
              //     );
              //   },
              // },
              // {
              //   title: (
              //     <div
              //       style={{
              //         fontSize: "12px",
              //         color: "white",
              //         display: "flex",
              //         flexDirection: "row",
              //         justifyContent: "space-between",
              //         width: "100%",
              //         paddingRight: "13px",
              //       }}
              //     >
              //       <TableColumnHeader title="Action" />
              //       <div
              //         style={{
              //           display: "flex",
              //           justifyContent: "space-around",
              //           paddingLeft: "6px",
              //         }}
              //       >
              //           (this.props.actualHubTableData && this.props.actualHubTableData.region && this.props.actualHubTableData.region.hub_details.length && (this.props.actualHubTableData && this.props.actualHubTableData.region && this.props.actualHubTableData.region.hub_details && this.props.actualHubTableData.region.hub_details.length && this.props.actualHubTableData.region.hub_details.filter((obj) => obj.checked === true).length) === (this.props.actualHubTableData && this.props.actualHubTableData.region && this.props.actualHubTableData.region.hub_details.length)),
              //           "===>",
              //           this.props.actualHubTableData && this.props.actualHubTableData.region && this.props.actualHubTableData.region.hub_details.length,
              //           "==> checkAll",
              //           this.state.checkAll)} */}
              //         <Checkbox
              //           width="10px"
              //           height="10px"
              //           checked={this.state.checkAll}
              //           // { this.state.selectAll && this.state.noOfChanges > 0  }
              //           // {this.props.actualHubTableData && this.props.actualHubTableData.region && this.props.actualHubTableData.region.hub_details && this.props.actualHubTableData.region.hub_details.length && this.props.actualHubTableData.region.hub_details.filter((obj) => obj.checked === true).length == this.props.actualHubTableData && this.props.actualHubTableData.region && this.props.actualHubTableData.region.hub_details.length}
              //           // { this.state.selectAll && this.state.noOfChanges > 0  }
              //           onChange={(e) => {
              //             let count = 0;
              //             // let data = setHubDataForTable();
              //             const modifiedData = this.props.actualHubTableData && this.props.actualHubTableData.region && this.props.actualHubTableData.region.hub_details.length ? this.props.actualHubTableData.region.hub_details.map((item) => {
              //               if (item.edited || (item.version === 0 && item.revisedQty !== 0)
              //                 //   &&
              //                 // user.userName !== item.approver1Name &&
              //                 // user.userName !== item.approver2Name
              //               ) {
              //                 count++;
              //                 return {
              //                   ...item,
              //                   checked: e.target.checked
              //                 }
              //               }else{
              //                 return item;
              //               }
              //             }) : []
              //             // setHubDataForTable(data)
              //             const data = {
              //               region: {
              //                 hub_details: modifiedData,
              //               },
              //             };

              //             setActualHubTableData(data)
              //             this.setState({
              //               localHubDataForTable: modifiedData,
              //               drawerVisible: e.target.checked,
              //               noOfChanges: e.target.checked ? count : 0,
              //               selectAll: e.target.checked,
              //             });
              //           }}
              //         ></Checkbox>
              //       </div>
              //     </div>
              //   ),
              //   key: "action",
              //   dataIndex: "action",
              //   width: 85,
              //   render: (item, row) => {
              //     return (
              //       <div className="approval-table-checkbox">
              //         <Checkbox
              //           disabled={!row.edited && !(row.edited === false && (row.data.version === 0 && row.revisedQty !== 0)
              //             //  || (row.approval1 !== null && row.approval2 !== null) || (row.approval1 === false || row.approval2 === false) || (row.approval1 !== null && user.userName === row.approver1Name) || (row.approval2 !== null && user.userName === row.approver2Name) )
              //              )
              //             }
              //           checked={row.checked}
              //           onChange={(e) => {
              //             // let data = setHubDataForTable();
              //             let count = 0;
              //             const modifiedData = this.props.actualHubTableData.region.hub_details.map((item, index) => {
              //
              //               if (item.temp_bd_id === row.temp_bd_id) {
              //                 item.checked && count++;
              //                 return {
              //                   ...item,
              //                   checked: e.target.checked
              //                 }
              //               } else {
              //                 return { ...item }
              //               }
              //             });
              //             // setHubDataForTable(data)
              //             const data = {
              //               region: {
              //                 hub_details: modifiedData,
              //               },
              //             };

              //             setActualHubTableData(data)
              //             this.setState({
              //               localHubDataForTable: modifiedData,
              //               drawerVisible: count > 0,
              //               noOfChanges: count,
              //             });
              //           }}
              //         />
              //       </div>
              //     );
              //   },
              // },
            ]}
            dataSource={setHubDataForTable()}
            footer={() =>
              setHubDataForTable() !== undefined &&
              setHubDataForTable()?.length !== 0 && (
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    className={"submit-btn"}
                    // onClick={() => <ActionItem
                    //   canUserSubmit={canUserEdit(
                    //     user,
                    //     selectedBranchID,
                    //     FeatureCodes.BUYING_DECISION_SUBMIT
                    //   )}
                    //   key="submitAll"
                    //   // this.props.handleSubmitClick(this.props.actualHubTableData)}
                    //   postBuyingDecisionHub={this.props.handleSubmitClick}
                    //   rowRecord={this.props.actualHubTableData}
                    //   // postHubRowId={record.actions.hubID}
                    //   // sourceType={record.mandiBuying.sourceType}
                    //   // srp={record.srp}
                    // />
                    // }
                    onClick={() =>
                      this.props.handleSubmitClick(
                        this.props.actualHubTableData
                      )
                    }
                  >
                    Submit
                  </Button>
                </div>
              )
            }
            // dataSource={this.state.localHubDataForTable && this.state.localHubDataForTable.length && this.state.localHubDataForTable[0].competitor_bid_id === undefined ? this.state.localHubDataForTable : setHubDataForTable()}
            // dataSource={this.state.localHubDataForTable}
          />
          {/* <div style={{ display: "flex", justifyContent: "flex-end", width: "95%" }} >
            <div>
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
  allVarietyInfo: selectAllVarietyInfo,

  mandiRollUp: selectMandiRollUp,
  FORRollUp: selectFORRollUp,
  farmerRollUp: selectFarmerRollUp,
  actualHubTableData: selectActualHubTableData,
  remarksList: selectClosureRemarks,
  decisionBranches: selectDecisionBranches,
  decisionRegions: selectDecisionRegions,
  selectedBranchID: selectSelectedBranchID,
  selectedRegionID: selectSelectedRegionID,
  selectedVarietyID: selectSelectedVarietyID,
  selectedHubID: selectSelectedHubID,
  hubDataPostFOR: selectHubDataPostFOR,
  dualRender: selectDualRender,

  suggestedData: selectSuggestedData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setActualHubTableData: (data) => dispatch(setActualHubTableData(data)),

    addSuggestedData: (data) => dispatch(addSuggestedData(data)),
    setActualHubDataPostFOR: (data) => dispatch(setActualHubDataPostFOR(data)),
    setDualRender: (value) => dispatch(setDualRender(value)),
    incrementDualRender: () => dispatch(incrementDualRender()),
    setSuggestedData: (data) => dispatch(setSuggestedData(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActualPurchaseInformationTable);
