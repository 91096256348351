import React from "react";
import "./intra-day-report.styles.css";
import moment from "moment";
import editIcon from "../../assets/edit.svg";
import arrowWhite from "../../assets/up_white.svg";
import arrowGreen from "../../assets/green.svg";
import arrowRed from "../../assets/up.svg";

import {
  Table,
  Spin,
  Select,
  Button,
  Modal,
  Input,
  Radio,
  message,
} from "antd";

import API, { baseURL } from "../../api";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { setAuditModalVisibility } from "../../redux/summary/summary.actions";
import { selectIsModalVisible } from "../../redux/summary/summary.selectors";

import { setSummaryVisibility } from "../../redux/arrival-entry/arrival-entry.actions";
import { selectUserData } from "../../redux/user/user.selectors";

import CustomSelectSummary from "../../components/custom-select-summary/custom-select-summary.component";
import Axios from "axios";

const FileDownLoad = require("js-file-download");

const { Option } = Select;
const { Search } = Input;

/**
 * @component
 * @description Reusable component to display table header.
 * @property {string} title Header title
 * @property {string} subtitle Header subtitle
 * @property {string} value Header value
 * @property {boolean} bar True if rounded rectangle exist in header
 * @property {string} diff Deviation
 * @property {string} percent Deviation percentage
 * @property {string} price Price to display
 * @memberof IntraDayReport
 */
const TableNewHeader = (props) => {
  const { title, subtitle, value, bar, diff, percent, price } = props;
  return (
    <div className="col-container">
      <div
        style={{
          color: "#FFFFFF",
          fontWeight: "normal",
          fontSize: "12px",
          whiteSpace: "break",
        }}
      >
        {title}
      </div>
      <div className="bottom-container">
        <h1>{value}</h1>
        <div
          style={{
            color: "#BAD2F8",
            fontSize: "12px",
            fontWeight: "normal",
            marginTop: "0px",
          }}
        >
          {subtitle}
        </div>
      </div>
      {bar && (
        <div
          className="new-bar"
          style={{
            backgroundColor: price
              ? diff > 0
                ? "#d53c3d"
                : "#5bc95a"
              : diff < 0
              ? "#d53c3d"
              : "#5bc95a",

            // backgroundColor: price
            //   ? diff > 0
            //     ? "#d53c3d"
            //     : diff > -40
            //     ? "#e68040"
            //     : "#5bc95a"
            //   : diff < 0
            //   ? "#d53c3d"
            //   : diff < 40
            //   ? "#e68040"
            //   : "#5bc95a",

            marginLeft: "-7px",
            width: "111px",
          }}
        >
          <div style={{ paddingTop: "1px" }}>{`${diff} ${
            percent === Infinity || percent === 0 ? "" : `(${percent}%)`
          } `}</div>
          <div
            style={{
              paddingTop: "1px",
              transform: price
                ? `${diff <= 0 ? "rotate(180deg)" : ""}`
                : `${diff < 0 ? "rotate(180deg)" : ""}`,
            }}
          >
            <img src={arrowWhite} alt="" width="8px"></img>
          </div>
        </div>
      )}
    </div>
  );
};

//#region Table Props
const tableProps = {
  pagination: false,
  size: "default",
  bordered: true,
  scroll: { x: 200, y: window.innerHeight - 320 },
};

const tableIndexProps = {
  pagination: false,
  size: "default",
  bordered: true,
  scroll: { x: 200, y: window.innerHeight - 240 },
};

//#endregion

/**
 *
 * @description Intra day report container in Report page.
 * @component
 */
class IntraDayReport extends React.Component {
  state = {
    indexType: "National Index",
    selectedInterval: "Week Average",
    selectedBranch: "",
    selectedIndex: "",
    selectedHub: "",
    selectedMandi: "",
    selectedVariety: "",
    dropdownData: {
      branches: [],
      indexes: [],
      hubs: [],
      mandis: [],
      varieties: [],
      totalMandis: [],
    },
    defaultIndex: "Select Index",
    defaultHub: "Select Hub",
    defaultMandi: "Select Mandi",
    lastClicked: "",
    selectedIndexType: "",
    selectedBranchVal: "",
    selectedIndexVal: "",
    selectedHubVal: "",
    selectedMandiVal: "",
    selectedVarietyVal: "",
    startDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    mandiIndexTableData: [],
    isSpinnerOnIndex: false,
    isSpinnerOnReport: false,
    isSpinnerOn: false,
    selectedTab: "mandiIndex",
    yesterdayTotalVol: 0,
    todayTotalVol: 0,
    historicTotalVol: 0,
    yesterdayTotalPrice: 0,
    todayTotalPrice: 0,
    weekTotalPrice: 0,
    todayPriceDifference: 0,
    todayPricePercentage: 0,
    todayVolumeDifference: 0,
    todayVolumePercentage: 0,
    selectedVarieties: [],
    selectedBranches: [],

    modalAddIndex: false,
    dataIndexTable: [],
    form: {
      index: 1,
      indexId: "",
      branches: [],
      varieties: [],
      allMandis: [],
      totalMandis: [],
      selectedMandis: [],
      selectedBranch: "Select Branch",
      selectedVariety: [],
      remainingSelectedFiltered: [],
      remainingChooseFiltered: [],
      indexName: "",
    },
    searchSelectedMandiText: "",
    searchChooseMandiText: "",
    modalEdit: false,
    loadMandiSpin: false,
  };

  /**
   * @function
   * @description Handle Top Mandi Index tab selection
   * @memberof IntraDayReport
   */
  selectMandiIndex = () => {
    this.setState({
      selectedTab: "mandiIndex",
    });
  };

  /**
   * @function
   * @description Handle Define Index tab selection
   * @memberof IntraDayReport
   */
  selectDefineIndex = () => {
    this.setState({
      selectedTab: "defineIndex",
    });
  };

  /**
   * @function
   * @description Handle date range filter selection.
   * @param {dateRange} dates
   * @param {string[]} dateStrings Array of strings consisting of selected date range.
   * @memberof IntraDayReport
   */
  onChange = (dates, dateStrings) => {
    this.setState(
      { startDate: dateStrings[0], endDate: dateStrings[1] },
      () => {
        this.GetChartData(
          this.state.startDate,
          this.state.endDate,
          this.state.selectedVarieties
        );
      }
    );
  };

  componentDidMount() {
    //first initialization branch details
    //#region
    this.GetIndexTableData();

    const params = {
      table_type: "reports",
    };

    API.get("/indexes?index_type=national").then((response) => {
      this.setState((prevState) => ({
        ...prevState,
        dropdownData: {
          ...prevState.dropdownData,
          indexes: response.data.data.indexes,
        },
      }));
    });

    API.get("/branches", { params: params }).then((response) => {
      const branchData = response.data.data.branches.map((item) => {
        return {
          branchId: item.territory_id,
          branchName: item.territory_name,
        };
      });

      API.get("/varieties").then((response) => {
        const varietyData = response.data.data.map((item) => {
          return {
            varietyId: item.variety_id,
            varietyName: item.variety_name,
          };
        });

        const mandiParams = { mapping: "mapped" };

        API.get("/mandis", { params: mandiParams }).then((response) => {
          const mandiData = response.data.data.mandis.map((item) => {
            return {
              mandiId: item.mandi_id,
              mandiName: item.mandi_name,
            };
          });

          this.setState((prevState) => ({
            ...prevState,
            dropdownData: {
              ...prevState.dropdownData,
              branches: branchData,
            },
            form: {
              ...prevState.form,
              branches: branchData,
              varieties: varietyData,
              allMandis: mandiData,
            },
          }));
        });
      });
    });
    //#endregion
  }

  /**
   * @function
   * @description Retieve and store Define Index table data from API.
   * @memberof IntraDayReport
   */
  GetIndexTableData() {
    this.setState({ isSpinnerOnIndex: true });

    API.get("/index").then((response) => {
      let data = response.data.data.indexes.map((item) => {
        return {
          key: item.index_id,
          branchId: item.branch_id,
          indexName: item.index_name,
          indexType: item.index_type === "branch" ? "Branch" : "National",
          varieties: item.varieties.map(
            (varietyItem) => varietyItem.variety_name
          ),
          varietyIds: item.varieties.map(
            (varietyItem) => varietyItem.variety_id
          ),
          mandis: item.mandis.map((mandiItem) => mandiItem.mandi_name),
          mandiIds: item.mandis.map((mandiItem) => mandiItem.mandi_id),
        };
      });
      this.setState({ dataIndexTable: data, isSpinnerOnIndex: false });
    });
  }

  /**
   * @function
   * @description Retieve and store Top Mandi Index table data from API.
   * @memberof IntraDayReport
   */
  GetMandiIndexTableData() {
    this.setState({ isSpinnerOnReport: true });

    const tableParams = {
      index_id: this.state.selectedIndex,
      historical_average:
        this.state.selectedInterval === "Week Average" ? "week" : "month",
      branches: this.state.selectedBranches,
    };
    API.get("/reports/intraday_mandi_report", {
      params: tableParams,
    })
      .then((response) => {
        let todayTotalVol = 0,
        yesterdayTotalVol = 0,
        historicTotalVol = 0,
        todaySumProdVol = 0,
        yesterdaySumProdVol = 0,
        historicSumProdVol = 0;
        let dd;
        if (response.data.data) {
          let count = 0;
          dd =
            response.data.data.mandi_arrivals &&
            response.data.data.mandi_arrivals.map((item) => {
              yesterdayTotalVol += item.yesterday_quantity;
              todayTotalVol += item.today_quantity;
              historicTotalVol += item.historical_avg_quantity;
              yesterdaySumProdVol +=
                item.yesterday_quantity * item.yesterday_price;
              todaySumProdVol += item.today_quantity * item.today_price;
              historicSumProdVol +=
                item.historical_avg_quantity * item.historical_avg_price;
              return {
                key: count++,
                mandiName: item.mandi_name,
                volYesterday: Math.round(item.yesterday_quantity),
                volToday: Math.round(item.today_quantity),
                volWeekAvg: Math.round(item.historical_avg_quantity),
                priceYesterday: Math.round(item.yesterday_price),
                priceToday: Math.round(item.today_price),
                priceWeekAvg: Math.round(item.historical_avg_price),
              };
            });
          }
          this.setState({
            mandiIndexTableData: dd || [],
            isSpinnerOnReport: false,
            yesterdayTotalVol:
            response.data.data && response.data.data.mandi_arrivals.length !== 0
                ? Math.round(
                    yesterdayTotalVol / response.data.data.mandi_arrivals.length
                  )
                : 0,
            todayTotalVol:
            response.data.data && response.data.data.mandi_arrivals.length !== 0
                ? Math.round(
                    todayTotalVol / response.data.data.mandi_arrivals.length
                  )
                : 0,
            historicTotalVol:
            response.data.data && response.data.data.mandi_arrivals.length !== 0
                ? Math.round(
                    historicTotalVol / response.data.data.mandi_arrivals.length
                  )
                : 0,

            todayVolumeDifference: todayTotalVol - yesterdayTotalVol,
            todayVolumePercentage:
              (todayTotalVol - yesterdayTotalVol) / yesterdayTotalVol,

            yesterdayTotalPrice:
              yesterdayTotalVol !== 0
                ? Math.round(yesterdaySumProdVol / yesterdayTotalVol)
                : 0,
            todayTotalPrice:
              todayTotalVol !== 0
                ? Math.round(todaySumProdVol / todayTotalVol)
                : 0,
            weekTotalPrice:
              historicTotalVol !== 0
                ? Math.round(historicSumProdVol / historicTotalVol)
                : 0,
            todayPriceDifference:
              (todayTotalVol !== 0
                ? Math.round(todaySumProdVol / todayTotalVol)
                : 0) -
              (yesterdayTotalVol !== 0
                ? Math.round(yesterdaySumProdVol / yesterdayTotalVol)
                : 0),
            todayPricePercentage:
              ((todayTotalVol !== 0
                ? Math.round(todaySumProdVol / todayTotalVol)
                : 0) -
                (yesterdayTotalVol !== 0
                  ? Math.round(yesterdaySumProdVol / yesterdayTotalVol)
                  : 0)) /
              (yesterdayTotalVol !== 0
                ? Math.round(yesterdaySumProdVol / yesterdayTotalVol)
                : 0),
          });
      })
      .catch((err) => {
        console.error(err);
        this.setState({ isSpinnerOnReport: false });
      });
  }

  render() {
    /**
     *
     * @description Handle Index Type filter selection.
     * @param {string} value Selected arrival type.
     * @memberof IntraDayReport
     */
    const handleIndexTypeSelect = (value) => {
      if (value === "National Index") {
        API.get("/indexes?index_type=national").then((response) => {
          this.setState((prevState) => ({
            ...prevState,
            dropdownData: {
              ...prevState.dropdownData,
              indexes: response.data.data.indexes,
            },
            selectedIndex: "",
            yesterdayTotalVol: 0,
            todayTotalVol: 0,
            historicTotalVol: 0,
            yesterdayTotalPrice: 0,
            todayTotalPrice: 0,
            weekTotalPrice: 0,
            todayPricePercentage: 0,
            todayVolumePercentage: 0,
            todayVolumeDifference: 0,
            todayPriceDifference: 0,
          }));
        });
      }
      this.setState((prevState) => ({
        ...prevState,
        selectedIndexType: value,
        selectedIndexVal: "Select Index",
        selectedIndex: "",
        selectedBranchVal: "Select Branch",
        selectedBranch: "",
        indexType: value,
        selectedBranches: [],
        mandiIndexTableData: [],
        dropdownData: {
          ...prevState.dropdownData,
          indexes:
            value === "Branch Index" ? null : this.state.dropdownData.indexes,
        },
        yesterdayTotalVol: 0,
        todayTotalVol: 0,
        historicTotalVol: 0,
        yesterdayTotalPrice: 0,
        todayTotalPrice: 0,
        weekTotalPrice: 0,
        todayPricePercentage: 0,
        todayVolumePercentage: 0,
        todayVolumeDifference: 0,
        todayPriceDifference: 0,
      }));
    };

    /**
     *
     * @description Handle Historic Interval filter selection.
     * @param {string} value Selected arrival type.
     * @memberof IntraDayReport
     */
    const handleIntervalSelect = (value) => {
      this.setState(
        (prevState) => ({
          ...prevState,
          selectedInterval: value,
        }),
        () => this.GetMandiIndexTableData()
      );
    };

    //index handling
    //#region
    //filtering index data to name and key
    let indexId, indexName;
    if (this.state.dropdownData.indexes) {
      indexId = this.state.dropdownData.indexes.map((item) => item.index_id);
      indexName = this.state.dropdownData.indexes.map(
        (item) => item.index_name
      );
    }
    /**
     *
     * @description Handle Index Name filter selection.
     * @param {string} value Selected variety value.
     * @param {object} props Selected variety object (key and value).
     * @memberof IntraDayReport
     */
    const handleIndexSelect = (value, props) => {
      this.setState(
        (prevState) => ({
          ...prevState,
          selectedIndexVal: value,
          selectedIndex: props.key,
        }),
        () => {
          this.GetMandiIndexTableData();
        }
      );
    };
    //#endregion

    //branch handling
    //#region
    //filtering branch data to name and key
    let branchId, branchName;
    if (this.state.dropdownData.branches) {
      branchId = this.state.dropdownData.branches.map((item) => item.branchId);
      branchName = this.state.dropdownData.branches.map(
        (item) => item.branchName
      );
    }
    /**
     *
     * @description Handle branch filter selection.
     * @param {string} value Selected branch value.
     * @param {object} props Selected branch object (key and value).
     * @memberof IntraDayReport
     */
    const handleBranchSelect = (value, props) => {
      const params = { index_type: "branch", branch_id: props.key };
      API.get(`/indexes`, { params: params }).then((response) => {
        this.setState((prevState) => ({
          ...prevState,
          selectedBranch: props.key,
          dropdownData: {
            ...prevState.dropdownData,
            indexes: response.data.data.indexes,
          },
          selectedBranches: [props.key],
          selectedBranchVal: value,
          selectedIndexVal: "Select Index",
          mandiIndexTableData: [],
          yesterdayTotalVol: 0,
          todayTotalVol: 0,
          historicTotalVol: 0,
          yesterdayTotalPrice: 0,
          todayTotalPrice: 0,
          weekTotalPrice: 0,
          todayPricePercentage: 0,
          todayVolumePercentage: 0,
          todayVolumeDifference: 0,
          todayPriceDifference: 0,
        }));
      });
    };
    //#endregion

    //ADDING NEW INDEX
    //#region
    /**
     *
     * @description Handle Radio Button group in Add Index modal
     * @param {event} e
     * @memberof IntraDayReport
     */
    const onChangeIndex = (e) => {
      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          index: e.target.value,
          totalMandis: e.target.value === 2 ? this.state.form.allMandis : [],
          selectedBranch: "Select Branch",
        },
      }));
    };

    /**
     * @description Function to compare objects
     * @param {string} key Mandi Name
     * @param {string} order Ascending or descending
     * @memberof IntraDayReport
     * @returns {number} 0, 1 or -1
     */
    const compareValues = (key, order = "asc") => {
      return function innerSort(a, b) {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
          return 0;
        }
        const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
        const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];
        let comparison = 0;
        if (varA > varB) {
          comparison = 1;
        } else if (varA < varB) {
          comparison = -1;
        }
        return order === "desc" ? comparison * -1 : comparison;
      };
    };

    /**
     * @description Add mandi to the selected mandi list in Add Index modal.
     * @param {string} mandiId Mandi ID
     * @memberof IntraDayReport
     */
    const addMandi = (mandiId) => {
      let temp;
      let newSelected = this.state.form.selectedMandis;
      let tempData = this.state.form.totalMandis.filter((item) => {
        if (item.mandiId === mandiId) {
          temp = item;
        } else return item;
      });
      newSelected.unshift(temp);
      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          totalMandis: tempData,
          selectedMandis: newSelected,
        },
      }));
    };

    /**
     * @description Remove mandi from the selected mandi list in Add Index modal.
     * @param {string} mandiId Mandi ID
     * @memberof IntraDayReport
     */
    const removeMandi = (mandiId) => {
      let temp;
      let newSelected = this.state.form.totalMandis;
      let tempData = this.state.form.selectedMandis.filter((item) => {
        if (item.mandiId === mandiId) {
          temp = item;
        } else return item;
      });
      newSelected.unshift(temp);
      newSelected.sort(compareValues("mandiName"));
      this.setState(
        (prevState) => ({
          ...prevState,
          form: {
            ...prevState.form,
            totalMandis: newSelected,
            selectedMandis: tempData,
          },
        }),
        () => console.log()
      );
    };

    /**
     *
     * @description Handle Choose Branch dropdown in Add Index modal
     * @param {string} value Branch ID
     * @memberof IntraDayReport
     */
    const indexBranchSelect = (value) => {
      this.setState({ loadMandiSpin: true });

      const params = {
        mapping: "mapped",
        branch_id: value,
      };
      API.get("/mandis", { params: params })
        .then((response) => {
          const mandiData = response.data.data.mandis.map((item) => {
            return {
              mandiId: item.mandi_id,
              mandiName: item.mandi_name,
            };
          });
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              selectedBranch: value,
              totalMandis: mandiData,
              selectedMandis: [],
            },
            loadMandiSpin: false,
          }));
        })
        .catch((err) => {
          this.setState({ loadMandiSpin: false });
        });
    };

    /**
     *
     * @description Handle Choose multiple Variety dropdown in Add Index modal
     * @param {string[]} value Variety IDs
     * @memberof IntraDayReport
     */
    const indexVarietySelect = (value) => {
      this.setState((prevState) => ({
        ...prevState,
        form: { ...prevState.form, selectedVariety: value },
      }));
    };

    /**
     *
     * @description Handle onChange of Input Text in Add Index modal
     * @param {event} e onChange event
     * @memberof IntraDayReport
     */
    const inputChange = (e) => {
      let value = e.target.value;
      this.setState((prevState) => ({
        ...prevState,
        form: { ...prevState.form, indexName: value },
      }));
    };

    /**
     *
     * @description Handle Save in Add Index modal to submit new index
     * @memberof IntraDayReport
     */
    const submitIndex = () => {
      if (!this.state.modalEdit) {
        const data = {
          index_name: this.state.form.indexName,
          index_type: this.state.form.index === 1 ? "branch" : "national",
          branch_id:
            this.state.form.index === 1 ? this.state.form.selectedBranch : null,
          mandis: this.state.form.selectedMandis.map((item) => item.mandiId),
          varieties: this.state.form.selectedVariety,
        };

        const validate = () => {
          if (data.index_name === "") {
            Modal.error({ content: "Please enter the Index name !" });
            return false;
          }
          if (data.index_type === "branch") {
            if (data.branch_id === "Select Branch") {
              Modal.error({ content: "Please select a branch !" });
              return false;
            }
          }

          if (data.mandis.length === 0) {
            Modal.error({ content: "Please select at least one mandi !" });
            return false;
          }
          if (data.varieties.length === 0) {
            Modal.error({ content: "Please select at least one variety !" });
            return false;
          }
          return true;
        };
        // console.log("Data to post: ", data);
        if (validate()) {
          API.post("/index", data)
            .then((response) => {
              console.log(response);

              message.success(`Added Index: ${data.index_name}`);

              this.setState(
                (prevState) => ({
                  ...prevState,
                  form: {
                    ...prevState.form,
                    index: 1,
                    totalMandis: [],
                    selectedMandis: [],
                    selectedBranch: "Select Branch",
                    selectedVariety: [],
                    indexName: "",
                  },
                  modalAddIndex: false,
                  modalEdit: false,
                }),
                () => {
                  this.GetIndexTableData();
                  API.get("/indexes?index_type=national").then((response) => {
                    this.setState((prevState) => ({
                      ...prevState,
                      dropdownData: {
                        ...prevState.dropdownData,
                        indexes: response.data.data.indexes,
                      },
                    }));
                  });
                }
              );
            })
            .catch((err) => {
              // console.log(err.response);
              Modal.error({ content: err.response.data.message });
            });
        }
      } else {
        const data = {
          index_id: this.state.form.indexId,
          mandis: this.state.form.selectedMandis.map((item) => item.mandiId),
          varieties: this.state.form.selectedVariety,
        };

        const validate = () => {
          if (data.mandis.length === 0) {
            Modal.error({ content: "Please select at least one mandi !" });
            return false;
          }
          if (data.varieties.length === 0) {
            Modal.error({ content: "Please select at least one variety !" });
            return false;
          }
          return true;
        };
        // console.log("Data to post: ", data);

        if (validate()) {
          API.put("/index", data)
            .then((response) => {
              message.success(`Updated Index: ${this.state.form.indexName}`);

              this.setState(
                (prevState) => ({
                  ...prevState,
                  form: {
                    ...prevState.form,
                    index: 1,
                    totalMandis: [],
                    selectedMandis: [],
                    selectedBranch: "Select Branch",
                    selectedVariety: [],
                    indexName: "",
                  },
                  modalAddIndex: false,
                  modalEdit: false,
                }),
                () => this.GetIndexTableData()
              );
            })
            .catch((err) => {
              message.warning("Could not submit. Retry..");
            });
        }
      }
    };

    /**
     *
     * @description Handle onChange of mandi search textbox in Add Index modal
     * @param {string} value Text
     * @param {type} type Selected or Choose mandi
     * @memberof IntraDayReport
     */
    const onChangeSearch = (value, type) => {
      if (type === "selected") {
        this.setState({ searchSelectedMandiText: value });
      } else {
        this.setState({ searchChooseMandiText: value });
      }
    };

    /**
     * @description Set Selected mandi data list on search in Add Index modal
     * @memberof IntraDayReport
     * @returns {object} Updated mandi list
     */
    const setSelectedMandis = () => {
      const selectedMandis = this.state.form.selectedMandis;
      let dataAfterSearch = selectedMandis;
      if (this.state.searchSelectedMandiText !== "") {
        dataAfterSearch = dataAfterSearch.filter(
          (item) =>
            item.mandiName !== null &&
            item.mandiName
              .toUpperCase()
              .includes(this.state.searchSelectedMandiText.toUpperCase())
        );
      }
      return dataAfterSearch;
    };

    /**
     * @description Set Choose mandi data list on search in Add Index modal
     * @memberof IntraDayReport
     * @returns {object} Updated mandi list
     */
    const setChooseMandis = () => {
      const chooseMandis = this.state.form.totalMandis;
      let dataAfterSearch = chooseMandis;
      if (this.state.searchChooseMandiText !== "") {
        dataAfterSearch = dataAfterSearch.filter(
          (item) =>
            item.mandiName !== null &&
            item.mandiName
              .toUpperCase()
              .includes(this.state.searchChooseMandiText.toUpperCase())
        );
      }
      return dataAfterSearch;
    };

    //#endregion

    //EDIT EXISTING INDEX

    /**
     *
     * @description Function to fetch and display index details in modal for editing when edit button is clicked in table row.
     * @param {string} record Value
     * @param {object} rowRecord Selected row data
     * @memberof IntraDayReport
     */
    const editIndex = (record, rowRecord) => {
      const mandis = rowRecord.mandiIds.map((item, idx) => {
        return { mandiId: item, mandiName: rowRecord.mandis[idx] };
      });

      const params = { mapping: "mapped", branch_id: rowRecord.branchId };

      API.get("/mandis", { params: params }).then((response) => {
        const temp = response.data.data.mandis.map((item) => {
          return {
            mandiId: item.mandi_id,
            mandiName: item.mandi_name,
          };
        });

        let mandiData = temp.filter(
          (item1) =>
            !mandis.some(
              (item2) =>
                item2.mandiId === item1.mandiId &&
                item2.mandiName === item1.mandiName
            )
        );

        this.setState((prevState) => ({
          ...prevState,
          form: {
            ...prevState.form,
            indexId: rowRecord.key,
            index: rowRecord.indexType === "Branch" ? 1 : 2,
            totalMandis: mandiData,
            selectedMandis: mandis,
            selectedBranch: rowRecord.branchId,
            selectedVariety: rowRecord.varietyIds,
            indexName: rowRecord.indexName,
          },
          modalEdit: true,
          modalAddIndex: true,
        }));
      });
    };

    return (
      <div className="intra-day-report">
        <Spin spinning={this.state.isSpinnerOn} tip="Loading...">
          <div
            className="summary"
            style={{
              backgroundColor: "#f7f9fb",
              height: window.innerHeight - 131,
            }}
          >
            {this.state.selectedTab === "mandiIndex" && (
              <div className="download-button-container">
                {
                  <Button
                    onClick={() => {
                      this.setState({ isSpinnerOn: true });
                      const downloadParams = {
                        index_id: this.state.selectedIndex,
                        historical_average:
                          this.state.selectedInterval === "Week Average"
                            ? "week"
                            : "month",
                        index_type:
                          this.state.indexType === "National Index"
                            ? "national"
                            : "branch",
                        branches: this.state.selectedBranches,
                        download: true,
                      };

                      // API.get(`/reports/intraday_mandi_report`, {
                      //   params: downloadParams,
                      // })
                      //   .then((fileresponse) => {
                      //     if (fileresponse.data.status) {

                      //#region file download
                      Axios({
                        url: `${baseURL}/reports/intraday_mandi_report`,
                        method: "GET",
                        params: downloadParams,
                        responseType: "blob",
                      })
                        .then((response) => {
                          if (response.status !== 204) {
                            FileDownLoad(
                              response.data,
                              `Intraday_Mandi_Report_${new Date().toLocaleDateString()}.xlsx`
                            );
                          } else {
                            message.warning("File has no data.");
                          }
                          this.setState({ isSpinnerOn: false });
                        })
                        .catch((err) => {
                          message.error("File cannot be downloaded");
                          this.setState({ isSpinnerOn: false });
                        });
                      //#endregion
                      //   } else {
                      //     message.warning("File has no data");
                      //     this.setState({ isSpinnerOn: false });
                      //   }
                      // })
                      // .catch((err) => {
                      //   message.error("Error downloading file");
                      //   this.setState({ isSpinnerOn: false });
                      // });
                    }}
                    type="primary"
                    shape="round"
                    icon="download"
                    size="large"
                  >
                    <div style={{ float: "right" }}>
                      <div style={{ marginTop: "-7px" }}>Download</div>
                      <div
                        style={{
                          fontSize: "11px",
                          lineHeight: "7px",
                          color: "#b4c8dc",
                        }}
                      >
                        .xlsx report
                      </div>
                    </div>
                  </Button>
                }
              </div>
            )}

            <Modal
              centered
              width="657px"
              className="add-index-modal"
              okText="Save"
              visible={this.state.modalAddIndex}
              title={this.state.modalEdit ? "Edit Index" : "Add Index"}
              maskClosable={false}
              onOk={() => submitIndex()}
              onCancel={() =>
                this.setState((prevState) => ({
                  ...prevState,
                  form: {
                    ...prevState.form,
                    index: 1,
                    totalMandis: [],
                    selectedMandis: [],
                    selectedBranch: "Select Branch",
                    selectedVariety: [],
                    indexName: "",
                  },
                  searchChooseMandiText: "",
                  searchSelectedMandiText: "",
                  modalAddIndex: false,
                  modalEdit: false,
                }))
              }
            >
              <div className="index-form-container">
                <div className="top-container">
                  <div className="name-branch-container">
                    Index Name *
                    <Input
                      value={this.state.form.indexName}
                      disabled={this.state.modalEdit}
                      onChange={inputChange}
                    />
                    {this.state.form.index !== 2 && (
                      <div>
                        Choose Branch
                        <Select
                          disabled={this.state.modalEdit}
                          placeholder="Select Branch"
                          value={this.state.form.selectedBranch}
                          onChange={(value, props) =>
                            indexBranchSelect(value, props)
                          }
                        >
                          {this.state.form.branches.length !== 0 &&
                            this.state.form.branches.map((item) => (
                              <Option key={item.branchId} value={item.branchId}>
                                {item.branchName}
                              </Option>
                            ))}
                        </Select>
                      </div>
                    )}
                  </div>
                  <div className="type-variety-container">
                    Index Type
                    <Radio.Group
                      disabled={this.state.modalEdit}
                      onChange={onChangeIndex}
                      value={this.state.form.index}
                    >
                      <Radio value={1}>Branch Index</Radio>
                      <Radio value={2}>National Index</Radio>
                    </Radio.Group>
                    Choose Varieties
                    <Select
                      maxTagCount={2}
                      showArrow
                      showSearch
                      value={this.state.form.selectedVariety}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      mode="multiple"
                      placeholder="Select varieties"
                      onChange={(value, props) =>
                        indexVarietySelect(value, props)
                      }
                    >
                      {this.state.form.varieties.length !== 0 &&
                        this.state.form.varieties.map((item) => (
                          <Option key={item.varietyId} value={item.varietyId}>
                            {item.varietyName}
                          </Option>
                        ))}
                    </Select>
                  </div>
                </div>
                <div className="bottom-index-container">
                  <div className="total-container">
                    <div className="header-list">Choose Mandi</div>
                    <div className="index-search-container">
                      <Search
                        allowClear
                        value={this.state.searchChooseMandiText}
                        placeholder="Search mandis"
                        onChange={(e) =>
                          onChangeSearch(e.target.value, "choose")
                        }
                        style={{ width: 300 }}
                      />
                    </div>
                    <Spin spinning={this.state.loadMandiSpin} tip="Loading...">
                      <div className="list-container">
                        {this.state.form.totalMandis.length !== 0 &&
                          setChooseMandis().map((item) => {
                            return (
                              <div
                                key={item.mandiId}
                                className="single-item-container"
                              >
                                <div className="item-name">
                                  {item.mandiName}
                                </div>
                                <div
                                  className="index-arrow"
                                  onClick={() => addMandi(item.mandiId)}
                                >
                                  🡒
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </Spin>
                  </div>

                  <div className="total-container">
                    <div className="header-list">Selected Mandi</div>
                    <div className="index-search-container">
                      <Search
                        allowClear
                        value={this.state.searchSelectedMandiText}
                        placeholder="Search mandis"
                        onChange={(e) =>
                          onChangeSearch(e.target.value, "selected")
                        }
                        style={{ width: 300 }}
                      />
                    </div>

                    <div className="list-container">
                      {this.state.form.selectedMandis.length !== 0 &&
                        setSelectedMandis().map((item) => {
                          return (
                            <div
                              key={item.mandiId}
                              className="single-item-container"
                            >
                              <div className="item-name">{item.mandiName}</div>
                              <div
                                className="index-cross"
                                onClick={() => removeMandi(item.mandiId)}
                              >
                                🞪
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </Modal>

            <div className="filter-container">
              <div
                style={{
                  width: `${
                    this.state.selectedTab === "mandiIndex"
                      ? this.state.indexType === "National Index"
                        ? "1035px"
                        : "935px"
                      : "1270px"
                  }`,
                }}
                className="summary-filters"
              >
                <div className="top-btn-container">
                  <div
                    onClick={this.selectMandiIndex}
                    className={`container-btn ${
                      this.state.selectedTab === "mandiIndex"
                        ? "container-btn-active"
                        : ""
                    }`}
                  >
                    Top Mandi index
                  </div>
                  <div
                    onClick={this.selectDefineIndex}
                    className={`container-btn ${
                      this.state.selectedTab !== "mandiIndex"
                        ? "container-btn-active"
                        : ""
                    }`}
                  >
                    Define Index
                  </div>
                </div>

                {this.state.selectedTab === "mandiIndex" && (
                  <div className="filter-select">
                    <div className="filter-select-title">Index Type</div>
                    <CustomSelectSummary
                      connectState
                      placeholder={
                        this.state.selectedIndexType !== ""
                          ? this.state.selectedIndexType
                          : this.state.indexType
                      }
                      handleSelect={handleIndexTypeSelect}
                      list={["Branch Index", "National Index"]}
                    />
                  </div>
                )}

                {this.state.selectedTab === "mandiIndex" &&
                  this.state.indexType === "Branch Index" && (
                    <div className="filter-select">
                      <div className="filter-select-title">Branch</div>
                      <CustomSelectSummary
                        placeholder={
                          this.state.selectedBranchVal === ""
                            ? "Select Branch"
                            : this.state.selectedBranchVal
                        }
                        list={branchName}
                        keyList={branchId}
                        handleSelect={handleBranchSelect}
                      />
                    </div>
                  )}

                {this.state.selectedTab === "mandiIndex" && (
                  <div className="filter-select">
                    <div className="filter-select-title">Index Name</div>

                    <CustomSelectSummary
                      placeholder={
                        this.state.selectedIndexVal === ""
                          ? "Select Index"
                          : this.state.selectedIndexVal
                      }
                      disabled={this.state.dropdownData.indexes == null}
                      list={indexName}
                      keyList={indexId}
                      handleSelect={handleIndexSelect}
                    />
                  </div>
                )}

                {this.state.selectedTab === "mandiIndex" &&
                  this.state.indexType === "National Index" && (
                    <div
                      className="filter-select"
                      style={{
                        height: "55px",
                      }}
                    >
                      <div className="filter-select-title">Branches</div>
                      <div className="multiple-select">
                        <Select
                          showArrow
                          showSearch
                          maxTagCount={2}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          mode="multiple"
                          placeholder="Select branches"
                          disabled={
                            this.state.selectedIndex === "" ||
                            this.state.selectedIndex === "Select Index"
                          }
                          onBlur={(value) => {
                            this.setState({ selectedBranches: value });
                            this.GetMandiIndexTableData();
                          }}
                        >
                          {branchName.map((item, idx) => (
                            <Option
                              value={branchId ? branchId[idx] : idx}
                              key={branchId ? branchId[idx] : idx}
                            >
                              {item}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </div>
                  )}

                {this.state.selectedTab === "mandiIndex" && (
                  <div className="filter-select">
                    <div className="filter-select-title">Historical Average</div>
                    <CustomSelectSummary
                      connectState
                      placeholder={this.state.selectedInterval}
                      handleSelect={handleIntervalSelect}
                      list={["Week Average", "Month Average"]}
                    />
                  </div>
                )}

                {this.state.selectedTab !== "mandiIndex" && (
                  <div className="add-index-btn">
                    <Button
                      onClick={() => {
                        this.setState({ modalAddIndex: true });
                      }}
                    >
                      + Add Index
                    </Button>
                  </div>
                )}
              </div>
            </div>
            <div className="table-container">
              {this.state.selectedTab === "mandiIndex" ? (
                <Spin spinning={this.state.isSpinnerOnReport} tip="Loading...">
                  <div className="mandi-index-report">
                    <Table
                      key="mandiIndex"
                      {...tableProps}
                      columns={[
                        {
                          title: (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <div
                                style={{
                                  color: "white",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  whiteSpace: "break",
                                }}
                              >
                                {"Mandi"}
                              </div>
                            </div>
                          ),
                          key: "mandiName1",
                          width: 90,
                          children: [
                            {
                              title: "",
                              key: "mandiName2",
                              width: 90,
                              children: [
                                {
                                  title: (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        paddingLeft: "5px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          color: "white",
                                          fontSize: "14px",
                                          fontWeight: "400",
                                          whiteSpace: "break",
                                        }}
                                      >
                                        {"Index"}
                                      </div>
                                    </div>
                                  ),
                                  dataIndex: "mandiName",
                                  key: "mandiName",
                                  width: 90,
                                },
                              ],
                            },
                          ],
                        },
                        {
                          title: (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                              }}
                            >
                              <div
                                style={{
                                  color: "#d5e6f8",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  whiteSpace: "break",
                                }}
                              >
                                {"Arrival Volume (MT)"}
                              </div>
                            </div>
                          ),
                          children: [
                            {
                              title: <TableNewHeader title="Yesterday" />,
                              key: "volYesterday1",
                              width: 90,
                              children: [
                                {
                                  title: (
                                    <TableNewHeader
                                      value={this.state.yesterdayTotalVol}
                                    />
                                  ),
                                  dataIndex: "volYesterday",
                                  key: "volYesterday",
                                  width: 90,
                                  sortDirections: ["descend", "ascend"],
                                  sorter: (a, b) =>
                                    a.volYesterday - b.volYesterday,
                                },
                              ],
                            },
                            {
                              title: <TableNewHeader title="Today" />,

                              key: "volToday1",
                              width: 90,
                              children: [
                                {
                                  title: (
                                    <TableNewHeader
                                      value={this.state.todayTotalVol}
                                      bar
                                      diff={Math.round(
                                        this.state.todayVolumeDifference
                                      )}
                                      percent={Math.abs(
                                        Math.round(
                                          (isNaN(
                                            this.state.todayVolumePercentage
                                          )
                                            ? 0
                                            : this.state
                                                .todayVolumePercentage) * 100
                                        )
                                      )}
                                    />
                                  ),

                                  dataIndex: "volToday",
                                  key: "volToday",
                                  width: 90,
                                  sortDirections: ["descend", "ascend"],
                                  sorter: (a, b) => a.volToday - b.volToday,
                                  render: (row, r) => {
                                    const diff = r.volToday - r.volYesterday;
                                    const percent =
                                      r.volYesterday !== 0
                                        ? ((r.volToday - r.volYesterday) /
                                            r.volYesterday) *
                                          100
                                        : 0;
                                    return (
                                      <div>
                                        <div>{r.volToday}</div>

                                        <div
                                          className="new-bar"
                                          style={{
                                            backgroundColor: "#FFFFFF00",
                                            paddingLeft: "0px",
                                            width: "105px",
                                            color:
                                              diff < 0 ? "#d53c3d" : "#5bc95a",
                                          }}
                                        >
                                          <div
                                            style={{ paddingTop: "1px" }}
                                          >{`${diff} ${
                                            percent === 0
                                              ? ""
                                              : `(${Math.round(percent)}%)`
                                          }`}</div>
                                          <div
                                            style={{
                                              paddingTop: "1px",
                                              transform: `${
                                                diff < 0 ? "rotate(180deg)" : ""
                                              }`,
                                            }}
                                          >
                                            <img
                                              src={
                                                diff >= 0
                                                  ? arrowGreen
                                                  : arrowRed
                                              }
                                              alt=""
                                              width="8px"
                                            ></img>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  },
                                },
                              ],
                            },
                            {
                              title: (
                                <TableNewHeader
                                  title={this.state.selectedInterval}
                                />
                              ),
                              key: "volWeekAvg1",
                              width: 90,
                              children: [
                                {
                                  title: (
                                    <TableNewHeader
                                      value={this.state.historicTotalVol}
                                    />
                                  ),
                                  dataIndex: "volWeekAvg",
                                  key: "volWeekAvg",
                                  width: 90,
                                  sortDirections: ["descend", "ascend"],
                                  sorter: (a, b) => a.volWeekAvg - b.volWeekAvg,
                                },
                              ],
                            },
                          ],
                        },
                        {
                          title: (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                              }}
                            >
                              <div
                                style={{
                                  color: "#d5e6f8",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  whiteSpace: "break",
                                }}
                              >
                                {"Modal Price (₹/MT)"}
                              </div>
                            </div>
                          ),
                          children: [
                            {
                              title: <TableNewHeader title="Yesterday" />,
                              key: "priceYesterday1",
                              width: 90,
                              children: [
                                {
                                  title: (
                                    <TableNewHeader
                                      value={this.state.yesterdayTotalPrice}
                                    />
                                  ),
                                  dataIndex: "priceYesterday",
                                  key: "priceYesterday",
                                  width: 90,
                                  sortDirections: ["descend", "ascend"],
                                  sorter: (a, b) =>
                                    a.priceYesterday - b.priceYesterday,
                                },
                              ],
                            },
                            {
                              title: <TableNewHeader title="Today" />,
                              key: "priceToday1",
                              width: 90,
                              children: [
                                {
                                  title: (
                                    <TableNewHeader
                                      value={this.state.todayTotalPrice}
                                      bar
                                      price
                                      diff={Math.round(
                                        this.state.todayPriceDifference
                                      )}
                                      percent={Math.abs(
                                        Math.round(
                                          isNaN(this.state.todayPricePercentage)
                                            ? 0
                                            : this.state.todayPricePercentage *
                                                100
                                        )
                                      )}
                                    />
                                  ),
                                  dataIndex: "priceToday",
                                  key: "priceToday",
                                  width: 90,
                                  sortDirections: ["descend", "ascend"],
                                  sorter: (a, b) => a.priceToday - b.priceToday,
                                  render: (row, r) => {
                                    const diff =
                                      r.priceToday - r.priceYesterday;
                                    const percent =
                                      r.priceYesterday !== 0
                                        ? ((r.priceToday - r.priceYesterday) /
                                            r.priceYesterday) *
                                          100
                                        : 0;
                                    return (
                                      <div>
                                        <div>{r.priceToday}</div>

                                        <div
                                          className="new-bar"
                                          style={{
                                            backgroundColor: "#FFFFFF00",
                                            paddingLeft: "0px",
                                            width: "105px",
                                            color:
                                              diff > 0 ? "#d53c3d" : "#5bc95a",
                                          }}
                                        >
                                          <div
                                            style={{ paddingTop: "1px" }}
                                          >{`${diff} ${
                                            percent === 0
                                              ? ""
                                              : `(${Math.round(percent)}%)`
                                          }`}</div>
                                          <div
                                            style={{
                                              paddingTop: "1px",
                                              transform: `${
                                                diff <= 0
                                                  ? "rotate(180deg)"
                                                  : ""
                                              }`,
                                            }}
                                          >
                                            <img
                                              src={
                                                diff <= 0
                                                  ? arrowGreen
                                                  : arrowRed
                                              }
                                              alt=""
                                              width="8px"
                                            ></img>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  },
                                },
                              ],
                            },
                            {
                              title: (
                                <TableNewHeader
                                  title={this.state.selectedInterval}
                                />
                              ),
                              key: "priceWeekAvg1",
                              width: 90,
                              children: [
                                {
                                  title: (
                                    <TableNewHeader
                                      value={this.state.weekTotalPrice}
                                    />
                                  ),
                                  dataIndex: "priceWeekAvg",
                                  key: "priceWeekAvg",
                                  width: 90,
                                  sortDirections: ["descend", "ascend"],
                                  sorter: (a, b) =>
                                    a.priceWeekAvg - b.priceWeekAvg,
                                },
                              ],
                            },
                          ],
                        },
                      ]}
                      dataSource={this.state.mandiIndexTableData}
                    />
                  </div>
                </Spin>
              ) : (
                <Spin spinning={this.state.isSpinnerOnIndex} tip="Loading...">
                  <div key={2} className="define-index">
                    <Table
                      key="mandiIndex"
                      {...tableIndexProps}
                      columns={[
                        {
                          title: "Index Name",
                          key: "indexName",
                          dataIndex: "indexName",
                          width: 40,
                        },
                        {
                          title: "Index Type",
                          key: "indexType",
                          dataIndex: "indexType",
                          width: 25,
                        },
                        {
                          title: "Varieties",
                          key: "varieties",
                          dataIndex: "varieties",
                          width: 80,
                          render: (value, rowRecord) => {
                            let list = "";
                            rowRecord.varieties.map((item) => {
                              list += `, ${item}`;
                            });
                            return list.substring(2, list.length);
                          },
                        },
                        {
                          title: "Mandis",
                          key: "mandis",
                          dataIndex: "mandis",
                          width: 150,
                          render: (value, rowRecord) => {
                            let list = "";
                            for (
                              var i = 0;
                              i <
                              (rowRecord.mandis.length < 7
                                ? rowRecord.mandis.length
                                : 7);
                              i++
                            ) {
                              list += `, ${rowRecord.mandis[i]}`;
                            }
                            if (rowRecord.mandis.length > 7) {
                              list += ` +${rowRecord.mandis.length - 7}`;
                            }
                            return list.substring(2, list.length);
                          },
                        },
                        {
                          title: "Actions",
                          key: "actions",
                          dataIndex: "actions",
                          width: 25,
                          render: (record, rowRecord) => {
                            return (
                              <div
                                onClick={() => editIndex(record, rowRecord)}
                                className="index-action-container"
                              >
                                <img src={editIcon} alt="" width="13px" />
                                <div
                                  style={{
                                    marginTop: "-2px",
                                    paddingBottom: "1px",
                                  }}
                                >
                                  Edit
                                </div>
                              </div>
                            );
                          },
                        },
                      ]}
                      dataSource={this.state.dataIndexTable}
                    />
                  </div>
                </Spin>
              )}
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  isModalVisible: selectIsModalVisible,
  userData: selectUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setAuditModalVisibility: (visibilityState) =>
      dispatch(setAuditModalVisibility(visibilityState)),
    setSummaryVisibility: (isEnabled) =>
      dispatch(setSummaryVisibility(isEnabled)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IntraDayReport);
