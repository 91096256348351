import React from "react";

import "./local-warehouse-charges.page";
import API from "../../api";
import { getStatesAPI } from "../../services/generic-apis";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUserData,selectMSMessageRBAC } from "../../redux/user/user.selectors";
import { canUserEdit,getUserMSRBACMessage } from "../../auth/utils";
import { FeatureCodes } from "../../auth/data";


import { Component } from "react";
import {
  Table,
  Spin,
  Input,
  Select,
  Button,
  message,
  Form,
  
  Modal,
  
} from "antd";
const { Search } = Input;
const { Option } = Select;



class LocalWarehouseMaster extends Component{
    state={ isModalVisible: false,
      isAdd: false,
      locationList:[],
      freeCapacityflatList:[],
      freeCapacitySiloList:[],
      paidcapacitylist:[],
      costpmtList:[],
      typeList:[],
  
  
      
      localwarehouseTableData:[],
        currentRecord: null,
       userBranches: null,
     searchText: null,
       searchUserType: 0,
      
      isActive:false,
      isSpinnerOnLoad: false,
      isSubmitted: false,
  
    
    newData: {
    cost_pmt: null,
      free_capacity_flat: null,
      free_capacity_silio: null,
      paid_capacity: null,
      territory_name: "",
      isEdit:false,
      location:"",
      territory_id:undefined,
      type:"Factory",
      total_capacity:undefined,
      is_active: true,
    },
    rowData: undefined,
    selectedStateValue: undefined,
    selectedStateName: "",
    selectedStateList: [],
   
    };
  
  componentDidMount() {
    this.setState({ isSpinnerOnLoad: true });
    this.getFactoryList();
  }
  

  getlocalwarehousecost= async () => {
    this.setState({ isSpinnerOnLoad: true });
     await API.get("/local_warehouse_charges")
      .then((resp) => {
        console.log("check response ", resp);
        let modifiedData = resp.data.data.map((item, index) => {
          return {
            ...item,
            isEdit: false,
            key: index,
          };
        });
        this.setState(
          {
            localwarehouseTableData: modifiedData,
          },
          () => this.setState({ isSpinnerOnLoad: false, isAdd: true })
        );
        //console.log("mandi table data ", this.state.mandiHubDistrictTableData);
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };


  getFactoryList = async () => {
    this.setState({ isSpinnerOnLoad: true });

    await API.get("/CDTD_plant_code")
      .then((factorydata) => {
        this.setState(
          {
            locationList: factorydata.data.data,
          },
          () => this.getTypeList()
        );
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };
  getTypeList = async () => {
    this.setState({ isSpinnerOnLoad: true });

    await API.get("/wcharges_storage_type")
      .then((typedata) => {
        let modifiedData = typedata.data.data.map((item, index) => {
          return {
            ...item,
            key: index,
          };
        });
       
        this.setState(
          {
            typeList: modifiedData,
          },
          () => this.getlocalwarehousecost()
        );
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };
  setTableData = () => {
    if (
      this.state.localwarehouseTableData !== undefined ||
      this.state.localwarehouseTableData.length >= 0
    ) {
      const userTableData = this.state.localwarehouseTableData.map(
        (user, index) => {
          return {
            key: user.key,
            cost_pmt: user.cost_pmt,
            free_capacity_flat: user.free_capacity_flat,
            free_capacity_silio: user.free_capacity_silio,
            paid_capacity: user.paid_capacity,
            territory_name: user.territory_name,
            type:"Factory",
            territory_id:user.territory_id,
            total_capacity:user.total_capacity,
            is_active: user.is_active,
           
            isEdit: user.isEdit,
          };
        }
      );
      

      let dataAfterSearch = userTableData;
      if (this.state.searchText) {
        dataAfterSearch = dataAfterSearch.filter(
          (item) =>
            (item.type !== null &&
              item.type
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase())) 
                       

        );
      }
      return dataAfterSearch;
    } else {
      return this.state.localwarehouseTableData;
    }
  };

  submitTableRowData = (row, index, isPopup) => {
    this.setState({ isSpinnerOnLoad: true, isSubmitted: true });
    let warningmessage = "";
     if (
      row.territory_name=== null ||
      row.territory_name === undefined ||
      row.territory_name === ""
    ) {
      warningmessage = "Please select location";
    } 
    

    if (
      warningmessage === "" ||
      warningmessage === undefined ||
      warningmessage === null
    ) {
      this.setState({ isSpinnerOnLoad: true });
      this.setState({ isSubmitted: true });

      let data = {
        
        free_capacity_silio: row.free_capacity_silio,
        free_capacity_flat:row.free_capacity_flat,
         paid_capacity: row.paid_capacity,
        territory_id: row.territory_id,
        cost_pmt:row.cost_pmt,
          is_active: row.is_active === true?1:0,
          total_capacity:row.total_capacity,
        
      };
  

      API.put("/local_warehouse_charges", data,{})

        .then((response) => {
          if (response.status === 200 && response.data.status) {
            message.info(response.data.message, 5);
            this.setModalVisibility(false);
            this.getlocalwarehousecost();
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
          } else if (!response.data.status) {
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
            message.error(response.data.message, 5);
          } else {
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
            message.error("Please try again", 5);
          }
        })
        .catch((err) => {
          if (err) {
            if (err.response && err.response.status === 400)
              message.error("Something went wrong. Please try again later.", 5);
            else {
              message.error(
                "Something went wrong.. Please try again later.",
                5
              );
            }
          } else {
            message.error("Something went wrong... Please try again later.", 5);
          }
          this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
          // this.setModalVisibility(false);
        });
    } else {
      this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
      message.error(warningmessage, 5);
    }
  };

  //   getStatesAPI = async () => {
  //   this.setState({ isSpinnerOnLoad: true });
  //   await API.get("/states")
  // .then((state) => {
  
  //   let modifiedData = [...state.data.data];
  //   modifiedData.push({ state_id: null, state: "All" });
  //   this.setState(
  //     {
  //       selectedStateList: modifiedData,
  //    //   mandiBranchList: state.data.data,
  //       selectedStateValue: state.data.data[0].state_id,
  //       selectedStateName: state.data.data[0].state,
  //     },
  
  //     () => this.getMandiList()
  //   );
  // })
  // .catch((err) => {
  //   this.setState({ isSpinnerOnLoad: false });
  //   this.getStatesAPI();
  //   if (err) {
  //     if (err.response && err.response.status === 400)
  //       message.error("Something went wrong. Please try again later.", 5);
  //     else {
  //       message.error("Something went wrong.. Please try again later.", 5);
  //     }
  //   } else {
  //     message.error("Something went wrong... Please try again later.", 5);
  //   }
  // });
  // };
  setModalVisibility = (status) => {
    if (status) {
      const nData = {
        cost_pmt: null,
        free_capacity_flat: null,
        free_capacity_silio: null,
        paid_capacity: null,
        territory_name: "",
      isEdit:false,
      type:"Factory",
      territory_id:undefined,
      is_active: true,
      total_capacity:undefined,
      
        
      };
  
      this.setState({ newData: nData });
    }
  
    this.setState({ isModalVisible: status });
  };

  TableColumnHeader = (props) => {
    const { title, subtitle } = props;
    return (
      <div className="column-header-container">
        <div
          style={{
            color: "white",
            fontSize: "12px",
            whiteSpace: "nowrap",
            fontWeight: "normal",
            textAlign: "left",
          }}
        >
          {title}
        </div>
        <div
          style={{
            color: "#cbdfff",
            fontSize: "11px",
            fontWeight: "normal",
            textAlign: "left",
            
          }}
        >
          {subtitle}
        </div>
      </div>
    );
  };
  
  
  
  createFilters = (label) => {
    let filterData = this.state.localwarehouseTableData;

    //#region remove duplicate objects

    let uniqueFilterData = [];

    if (
      label === "is_active" &&
      this.state.localwarehouseTableData &&
      this.state.localwarehouseTableData.length > 0
    ) {
      uniqueFilterData = [
        { text: "Yes", value: 1 },
        { text: "No", value: 0 },
      ];
    } else {
      filterData.map((mainItem) =>
        uniqueFilterData.filter((item) => item[label] === mainItem[label])
          .length > 0
          ? null
          : uniqueFilterData.push(mainItem)
      );

      uniqueFilterData = uniqueFilterData.map((item) => {
        return {
          text: item[label],

          value: item[label],
        };
      });

      uniqueFilterData.sort((a, b) => a.text.localeCompare(b.text));
    }

    return uniqueFilterData;
  };

 
  
handleDropdownChange = (value, row, target, index, optionlabel, isPopup) => {
    let a;
  
   if (isPopup === false) {
      a = this.state.localwarehouseTableData;
      if (target === "territory_name") {
        a[row.key].territory_name = optionlabel;
        a[row.key].territory_id= value;
      }
     
      else if (target === "type") {
        a[row.key].type= value;
        a[row.key].type = optionlabel;
      }
      this.setState({ localwarehouseTableData: a });
    } else {
      a = this.state.newData;
  
      if (target === "territory_name") {
        a.territory_name = optionlabel;
        a.territory_id = value;
      }
      
      else if (target === "type") {
        a.type= value;
        a.type = optionlabel;
      }
      this.setState({ newData: a });
    }
  };
  
  handleChange = (e, row, index, name, isPopup) => {
    let a;
    var alphaNumericRegex = /^[a-zA-Z0-9]*$/;
    var numberRegex = /^[0-9.]+$/;
    if (isPopup === false) {
      a = this.state.localwarehouseTableData;

      switch (name) {
        case "cost_pmt":
          if (e.target.value === "") {
            a[row.key].cost_pmt = "";
          } else if (e.target.value.match(numberRegex)) {
            a[row.key].cost_pmt = e.target.value;
          }

          this.setState({ localwarehouseTableData: a });
          return;

          case "free_capacity_flat":
            if (e.target.value === "") {
              a[row.key].free_capacity_flat = "";
            } else if (e.target.value.match(numberRegex)) {
              a[row.key].free_capacity_flat = e.target.value;
            }
  
            this.setState({ localwarehouseTableData: a });
            return;

            case "free_capacity_silio":
              if (e.target.value === "") {
                a[row.key].free_capacity_silio = "";
              } else if (e.target.value.match(numberRegex)) {
                a[row.key].free_capacity_silio = e.target.value;
              }
              
              this.setState({ localwarehouseTableData: a });
              return;
              case "paid_capacity":
                if (e.target.value === "") {
                  a[row.key].paid_capacity = "";
                } else if (e.target.value.match(numberRegex)) {
                  a[row.key].paid_capacity = e.target.value;
                }
      
                this.setState({ localwarehouseTableData: a });
                return;
                case "total_capacity":
                  if (e.target.value === "") {
                    a[row.key].total_capacity = "";
                  } else if (e.target.value.match(numberRegex)) {
                    a[row.key].total_capacity = e.target.value;
                  }
        
                  this.setState({ localwarehouseTableData: a });
                  return;
        default:
          return "";
      }
    } else {
      a = this.state.newData;

      switch (name) {
        case "free_capacity_silio":
          if (e.target.value === "") {
            a.free_capacity_silio = "";
          } else if (e.target.value.match(numberRegex)) {
            a.free_capacity_silio = e.target.value;
          }

          this.setState({ newData: a });
          return;
          case "paid_capacity":
            if (e.target.value === "") {
              a.paid_capacity = "";
            } else if (e.target.value.match(numberRegex)) {
              a.paid_capacity = e.target.value;
            }
  
            this.setState({ newData: a });
            return;
            case "free_capacity_flat":
              if (e.target.value === "") {
                a.free_capacity_flat = "";
              } else if (e.target.value.match(numberRegex)) {
                a.free_capacity_flat = e.target.value;
              }
    
              this.setState({ newData: a });
              return;
              case "cost_pmt":
                if (e.target.value === "") {
                  a.cost_pmt = "";
                } else if (e.target.value.match(numberRegex)) {
                  a.cost_pmt = e.target.value;
                }
      
                this.setState({ newData: a });
                return;
                case "total_capacity":
                if (e.target.value === "") {
                  a.total_capacity = "";
                } else if (e.target.value.match(numberRegex)) {
                  a.total_capacity = e.target.value;
                }
      
                this.setState({ newData: a });
                return;
        default:
          return "";
      }
      // this.setState({ newData: a });
    }
  };
  
  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };
  
  handleEditChange = (row, index) => {
    console.log("user hub id is ", this.props.userData.state_id );//check this afterwards
    
    this.setState({ rowData: row });
    let tempData = this.state.localwarehouseTableData.map((item) => {
  
      if (item.territory_name=== row.territory_name) {
        return {
          ...item,
          isEdit: true
        }
      } else {
        return item;
      }
    })
    this.setState({ localwarehouseTableData: tempData });
  
  };
  
  handleCancelClick = (rowData, index) => {
    let tempData = this.state.localwarehouseTableData.map((item) => {
      if (item.territory_name === rowData.territory_name) {
        return this.state.rowData
      } else {
        return item;
      }
    })
    this.setState({ localwarehouseTableData: tempData });
  };

  render(){
    const userTableProps = {
      pagination: false,
      size: "default",
      bordered: true,
      scroll: { x:200, y: window.innerHeight - 250 },
    };
    // const handleStateSelect = (value, props) => {
    //   this.setState(
    //     { selectedStateName: props.key, selectedhubStateValue: value },
    //     () => this.getStatesAPI()
    //   );
    // };
  
  
  
    /// returning to output,just a dummy data as of now
  
  
  
  return(
    <div // close left
    className="mandi-master-container"
    style={{
      height: window.innerHeight - 85,
      marginTop: "-45px",
    }}>
       <div className="top-container">
       {/* <div className="hub-region-filter">
       <span
                style={{
                  fontSize: "15px",
                  marginTop: "5px",
                  marginLeft: "68px",
                  fontWeight: "600",
                }}
              >
                State&nbsp;
              </span>{" "}
              <Select
                disabled={this.state.isAdd}
                showSearch
                optionFilterProp="children"
                className={"select-master-region"}
                mode="single"
                placeholder="Select State"
                value={this.state.selectedhubStateValue}
                onChange={(value, props) =>
                  handleStateSelect(value, props)
                }
              >
                {this.state.selectedStateList !== null && this.state.selectedStateList.length &&
                  this.state.selectedStateList.map((item, idx) => (
          
                      <Option
                      value={item.hub_state_id}
                      key={item.state}
                    >
                      {item.state}
                    </Option>
                  ))}
              </Select>
  
            </div> */}
  
  
  <div
    style={{
      width: "100%",
      display: "flex",
      flexDirection: "row-reverse",
      placeItems: "flex-end",
      marginRight: "25px",
      marginBottom: "10px",
    }}
  >
    <Search
      disabled={!this.state.isAdd}
      placeholder="Search"
      allowClear
      onChange={(e) => this.setState({ searchText: e.target.value })}
      style={{ width: "25%" }}
    />
     {/* <Button
      className="add-master-button"
      style={{
        height: "25px",
        width: "70px",
        backgroundColor: "#0b133d",
        color: "white",
      }}
      disabled={!this.state.isAdd || !canUserEdit(
        this.props.userData,
        null,
        FeatureCodes.MS_LOCAL_WAREHOUSE_EDIT
      )}
      onClick={() => this.setModalVisibility(true)}
    >
      &#10010; Add
      </Button> */}
  </div>
  </div>
  
  <div>
  <div
  style={{
    marginLeft: "25px",
    marginTop: "-8px",
    fontSize: "14px",
    color: "red",
    display: "flex",
    float: "left",
  }}
  > {getUserMSRBACMessage(this.props.messageAccess,FeatureCodes.MS_LOCAL_WAREHOUSE_EDIT)}
  </div>
  <div
    style={{
      marginRight: "20px",
      marginTop: "-8px",
      fontSize: "15px",
      color: "#0B133D",
      display: "flex",
      float: "right",
    }}
  >Total number of records:&nbsp;
    <b>{this.state.localwarehouseTableData && this.setTableData().length}</b>
  </div>
  </div>
  
  <Spin
    spinning={this.state.isSpinnerOnLoad}
    size={"large"}
    tip="Loading..."
    style={{ alignSelf: "center", zIndex: 9999, marginTop: "20px" }}
  >
     <Modal
      className="add-master-modal"
      title={"Add warehouse details"}
      onCancel={this.handleCancel}
      footer={false}
      visible={this.state.isModalVisible}
      destroyOnClose={true}
    >
      <div className="master-form-container">
        <Form
          layout="horizontal"
          //   onValuesChange={onFormLayoutChange}
          size={"small"}
        // onSubmit={submitTableRowData(this.state.newData)}
        >
          <div className="form-top-container1">
            <div
              className="checkbox-group1"
              style={{
                display: "inline-block",
                width: "38%",
                marginRight: 40,
              }}
            >
               <Form.Item label="Type">
                        <Select
                          showSearch
                          optionFilterProp="children"
                          className="remarks-select"
                          name="type"
                          disabled={true}
                          defaultValue={this.state.newData.type}
                          value={this.state.newData.type}
                          onSelect={(value, option) =>
                            this.handleDropdownChange(
                              value,
                              this.state.newData,
                              "type",
                              -0,
                              option.props.children,
                              true
                            )
                          }
                        >
                          {this.state.typeList &&
                            this.state.typeList.length &&
                            this.state.typeList.map((hubItem, idx) => (
                              <Option
                                key={hubItem.key}
                                value={hubItem.storage}
                              >
                                {hubItem.storage}
                              </Option>
                            ))}
                        </Select>
  
                      </Form.Item>
                    </div>
  
  
                    <div
                      className="checkbox-group1"
                      style={{
                        height: "45px",
                        display: "inline-block",
                        width: "38%",
                        marginRight: 40,
                      }}
                    >
                      <Form.Item label="Location">
                      <Select
                          showSearch
                          optionFilterProp="children"
                          className="remarks-select"
                          name="territory_name"
                          defaultValue={this.state.newData.territory_name}
                          value={this.state.newData.territory_name}
                          onSelect={(value, option) =>
                            this.handleDropdownChange(
                              value,
                              this.state.newData,
                              "territory_name",
                              -0,
                              option.props.children,
                              true
                            )
                          }
                        >
                          {this.state.locationList &&
                            this.state.locationList.length &&
                            this.state.locationList.map((hubItem, idx) => (
                              <Option
                                key={hubItem.factory}
                                value={hubItem.factory}
                              >
                                {hubItem.plant_code} - {hubItem.plant}
                              </Option>
                            ))}
                        </Select>
                       
                      </Form.Item>
                    </div>
  
                    <div
                      className="checkbox-group1"
                      style={{
                        height: "45px",
                        display: "inline-block",
                        width: "38%",
                        marginRight: 40,
                      }}
                    >
                      <Form.Item label="Paid Capacity">
                        <Input
                          type="number"
                          style={{ marginRight: "2px" }}
                          maxLength={100}
                          min="0"
                          value={this.state.newData.paid_capacity}
                          name="paid_capacity"
                          onChange={(e) =>
                            this.handleChange(
                              e,
                              this.state.newData,
                              0,
                              "paid_capacity",
                              true
                            )
                          }
                        />
                      </Form.Item>
                    </div>
  
                    <div
                      className="checkbox-group1"
                      style={{
                        height: "45px",
                        display: "inline-block",
                        width: "38%",
                        marginRight: 40,
                      }}
                    >
                      <Form.Item label="Free Capacity Silio">
                        <Input
                          type="number"
                          style={{ marginRight: "2px" }}
                          maxLength={100}
                          min="0"
                          value={this.state.newData.free_capacity_silio}
                          name="free_capacity_silio"
                          onChange={(e) =>
                            this.handleChange(
                              e,
                              this.state.newData,
                              0,
                              "free_capacity_silio",
                              true
                            )
                          }
                        />
                      </Form.Item>
                    </div>
  
                    <div
                      className="checkbox-group1"
                      style={{
                        height: "45px",
                        display: "inline-block",
                        width: "38%",
                        marginRight: 40,
                      }}
                    >
                      <Form.Item label="Free Capacity Flat">
                        <Input
                          type="number"
                          style={{ marginRight: "2px" }}
                          maxLength={100}
                          min="0"
                          value={this.state.newData.free_capacity_flat}
                          name="free_capacity_flat"
                          onChange={(e) =>
                            this.handleChange(
                              e,
                              this.state.newData,
                              0,
                              "free_capacity_flat",
                              true
                            )
                          }
                        />
                      </Form.Item>
                    </div>
  
                    <div
                      className="checkbox-group1"
                      style={{
                        height: "45px",
                        display: "inline-block",
                        width: "38%",
                        marginRight: 40,
                      }}
                    >
                      <Form.Item label="Cost PMT">
                        <Input
                          type="number"
                          style={{ marginRight: "2px" }}
                          maxLength={100}
                          min="0"
                          value={this.state.newData.cost_pmt}
                          name="cost_pmt"
                          onChange={(e) =>
                            this.handleChange(
                              e,
                              this.state.newData,
                              0,
                              "cost_pmt",
                              true
                            )
                          }
                        />
                      </Form.Item>
                    </div>
  
                
                    
                    </div>
                    <div className="form-bottom-container" style={{ marginTop: "10px" }}>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        disabled={this.state.isSubmitted}
                        onClick={() =>
                          this.submitTableRowData(this.state.newData, 0, true)
                        }
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </div>
            </Modal> 
  
  
   <div className="table-container1" style={{ maxHeight: "400px",  margin: "6px 10px" }}>
    <Table
     {...userTableProps}
     columns ={ [
    {
      title : 'Type',
      dataIndex: 'type',
      key: 'type',
      width: 80,
   // filters: this.createFilters("type"),
      onFilter: (value, record) =>
        record.type.includes(value),
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.type.localeCompare(b.type),
      // render: (record, rowRecord,index) => {
      //   return (
      //     <>
      //       <div
      //         style={{ marginLeft: "-15px"
      //          }}>
      //        <Select
      //           showSearch
      //           optionFilterProp="children"
      //           disabled ={!rowRecord.isEdit}
      //           className="remarks-select"
      //           name="type"
      //           defaultValue={rowRecord.type}
      //           value={rowRecord.state_name}
      //           onSelect={(value, option) =>
      //             this.handleDropdownChange(
      //               value,
      //               rowRecord,
      //               "type",
      //               -0,
      //               option.props.children,
      //               false
      //             )
      //           }
      //         >
      //           {this.state.typeList &&
      //             this.state.typeList.length &&
      //             this.state.typeList.map((hubItem, idx) => (
      //               <Option
      //                 key={hubItem.type}
      //                 value={hubItem.type}
      //               >
      //                 {hubItem.type}
      //               </Option>
      //             ))}
      //         </Select>
            
             
      //       </div>
      //     </>
      //   );
      // },
    },
    {
      title: 'Location',
      dataIndex: 'territory_name',
      key: 'territory_name',
      width: 150,
      filters: this.createFilters("territory_name"),
      onFilter: (value, record) =>
      record.territory_name.includes(value),
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.territory_name - b.territory_name,
      render: (record, rowRecord, index) => {
        return (
          <>
            <div style={{ marginLeft: "-20px" }}>
            <Select
                showSearch
                optionFilterProp="children"
                disabled ={true}
                className="remarks-select"
                name="territory_name"
                defaultValue={rowRecord.territory_name}
                value={rowRecord.territory_name}
                onSelect={(value, option) =>
                  this.handleDropdownChange(
                    value,
                    rowRecord,
                    "territory_name",
                    -0,
                    option.props.children,
                    false
                  )
                }
              >
                {this.state.locationList &&
                  this.state.locationList.length &&
                  this.state.locationList.map((hubItem, idx) => (
                    <Option
                                key={hubItem.factory}
                                value={hubItem.factory}
                              >
                                {hubItem.plant_code} - {hubItem.plant}
                              </Option>
                  ))}
              </Select>
            
          
            </div>
          </>
        );
      },
    },
    {
      title: 'Free Capacity Flat',
      dataIndex: 'free_capacity_flat',
      key: 'free_capacity_flat',
      width: 150,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.free_capacity_flat - b.free_capacity_flat,
      render: (record, rowRecord, index) => {
        return (
          <>
            <div style={{ marginLeft: "-20px" }}>
              <Input
                type ="number"       
                disabled={!rowRecord.isEdit}
                style={{ marginRight: "2px" }}
                 maxLength={100}
                 min="0"
                value={rowRecord.free_capacity_flat}
                name="free_capacity_flat"
                onChange={(e) =>
                  this.handleChange(
                    e,
                    rowRecord,
                    index,
                    "free_capacity_flat",
                    false
                  )
                }
              />
            </div>
          </>
        );
      },
    },
    {
      title: 'Free Capacity Silio',
      dataIndex: 'free_capacity_silio',
      key: 'free_capacity_siilo',
      width: 200,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.free_capacity_silio - b.free_capacity_silio,
      render: (record, rowRecord, index) => {
        return (
          <>
            <div style={{ marginLeft: "-20px" }}>
              <Input
                type="number"
                disabled={!rowRecord.isEdit}
                style={{ marginRight: "2px" }}
                maxLength={100}
                min="0"
                value={rowRecord.free_capacity_silio}
                name="free_capacity_silio"
                onChange={(e) =>
                  this.handleChange(
                    e,
                    rowRecord,
                    index,
                    "free_capacity_silio",
                    false
                  )
                }
              />
            </div>
          </>
        );
      },
    },
    {
      title: 'Paid Capacity',
      dataIndex: 'paid_capacity',
      key: 'paid_capacity',
      width: 150,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.paid_capacity- b.paid_capacity,
      render: (record, rowRecord, index) => {
        return (
          <>
            <div style={{ marginLeft: "-20px" }}>
              <Input
                type="number"
                disabled={!rowRecord.isEdit}
                style={{ marginRight: "2px" }}
                maxLength={100}
                min="0"
                value={rowRecord.paid_capacity}
                name="paid_capacity"
                onChange={(e) =>
                  this.handleChange(
                    e,
                    rowRecord,
                    index,
                    "paid_capacity",
                    false
                  )
                }
              />
            </div>
          </>
        );
      },
    },
    {
      title: 'Cost PMT',
      dataIndex: 'cost_pmt',
      key: 'cost_pmt',
      width: 120,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.cost_pmt - b.cost_pmt,
      render: (record, rowRecord, index) => {
        return (
          <>
            <div style={{ marginLeft: "-20px" }}>
              <Input
                type="number"
                disabled={!rowRecord.isEdit}
                style={{ marginRight: "2px" }}
                maxLength={100}
                min="0"
                value={rowRecord.cost_pmt}
                name="cost_pmt"
                onChange={(e) =>
                  this.handleChange(
                    e,
                    rowRecord,
                    index,
                    "cost_pmt",
                    false
                  )
                }
              />
            </div>
          </>
        );
      },
    },
    {
      title: 'Total Capacity',
      dataIndex: 'total_capacity',
      key: 'total_capacity',
      width: 120,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.total_capacity - b.total_capacity,
      render: (record, rowRecord, index) => {
        return (
          <>
            <div style={{ marginLeft: "-20px" }}>
              <Input
                type="number"
                disabled={!rowRecord.isEdit}
                style={{ marginRight: "2px" }}
                maxLength={100}
                min="0"
                value={rowRecord.total_capacity}
                name="total_capacity"
                onChange={(e) =>
                  this.handleChange(
                    e,
                    rowRecord,
                    index,
                    "total_capacity",
                    false
                  )
                }
              />
            </div>
          </>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "Submit",
      key: "Submit",
      width: 120,
      visibility: false,
      render: (record, rowRecord, index) => {
        return (
          <>
            <div style={{ display: "inline-flex", width: "100%" }}>
              <Button
              className={!canUserEdit(
                this.props.userData,
                null,
                FeatureCodes.MS_LOCAL_WAREHOUSE_EDIT
              ) ? "disabledBtnClass" : "btnClass"}
                disabled={!canUserEdit(
                  this.props.userData,
                  null,
                  FeatureCodes.MS_LOCAL_WAREHOUSE_EDIT
                )}
                type="primary"
                size={"small"}
                style={{
                  marginLeft: 5,
                  alignSelf: "center",
                  padding: "0px 0px 2px",
                  width:70,
                  height: 31,
                  
                  backgroundColor: "#20346a",
                  color: "white",
                  borderRadius: 15,
                }}
                block
                htmlType="submit"
                onClick={() =>
                  rowRecord.isEdit
                    ? this.submitTableRowData(rowRecord, index, false)
                    : this.handleEditChange(rowRecord, index)
                }
              >
                {" "}
                {rowRecord.isEdit ? "Submit" : "Edit"}{" "}
              </Button>
  
  
              <Button
                className={!rowRecord.isEdit ? "disabledBtnClass" : "btnClass"}
                type="primary"
                size={"small"}
                style={{
                  marginLeft: 5,
                  alignSelf: "center",
                  width:70,
                  color: "white",
                  borderRadius:15,
                  padding: "0px 0px 2px",
                  
                  height: 31,
                  backgroundColor: "#20346a",
                }}
                block
                htmlType="submit"
                disabled={!rowRecord.isEdit}
                onClick={() => this.handleCancelClick(rowRecord, index)
                }
              >
                Cancel
              </Button>
  
            </div>
          </>
        );
      },
    },
  ]
   }
   dataSource={this.setTableData()}
   />
  </div>
  
  
  
  </Spin>
  
  </div>
   );
  }
  }
 const mapStateToProps = createStructuredSelector({
     userData: selectUserData,
     messageAccess: selectMSMessageRBAC,
   });
  
   export default connect(mapStateToProps)(LocalWarehouseMaster);
  
  
  //export default WarehouseMaster;
  
  
  
  
