import React from "react";
import "./login.styles.css";

import { Input, message, Form, Icon, Checkbox, Button, Modal } from "antd";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import Logo from "../../assets/logo.png";
import bg from "../../assets/Login_Bg_Pattern.png";

import { SignOutAPI } from "../../auth/utils";
import { withRouter } from "react-router-dom";

import {
  setUserLoggedStatus,
  setAccessToken,
  setRefreshToken,
  setUserRBACDetails,
  setAllUsers,
  setAllBranches,
  setReportData,
} from "../../redux/user/user.actions";

///////////////////////////////////////////////////////////
//#region arrival entry optimization actions and selectors.
import {
  saveAllVarietyInfo,
  setArrivalData,
  setAllMandis,
  setBranches,
  setRegions,
  setHubs,
  setMandis,
  setVarieties,
  setRemainingVarietyList,
  setHubVarieties,
  setRemainingHubVarietyList,
  setBranchID,
  setRegionID,
  setHubID,
  setMandiID,
} from "../../redux/arrival-entry/arrival-entry.actions";
import {
  selectArrivalData,
  selectSummaryScreenVisibility,
  selectBranches,
  selectRegions,
  selectHubs,
  selectMandis,
  selectVarieties,
  selectAllVarietyInfo,
  selectHubVarieties,
  selectBranchID,
  selectRegionID,
  selectHubID,
  selectMandiID,
} from "../../redux/arrival-entry/arrival-entry.selectors";
import { setCurrentArrivalScreen } from "../../redux/map/map.actions";
import { selectCurrentArrivalScreen } from "../../redux/map/map.selectors";
//#endregion
///////////////////////////////////////////////////////////

import {
  setRollUp,
  setRemarks,
  setHubTableData,
  setHubDataPostFOR,
  setDecisionBranches,
  setDecisionRegions,
  setDecisionVarieties,
  setSelectedBranchID,
  setSelectedRegionID,
  setSelectedVarietyID,
} from "../../redux/buying-decision/buying-decision.actions";

import {
  selectHubTableData,
  selectDecisionBranches,
  selectDecisionRegions,
  selectDecisionVarieties,
  selectSelectedBranchID,
  selectSelectedRegionID,
  selectSelectedVarietyID,
} from "../../redux/buying-decision/buying-decision.selectors";

import API from "../../api";
import ForgotPassword from "../../components/forgot-password/forgot-password.component";

/**
 * @description The form component for logging in.
 * @component
 * @memberof App
 */
class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      open: false,
      isLoading: false,
    };
  }

  handleShow = (status) => {
    this.setState({ isLoading: true });
    if (status) {
      API.post(
        "/generate_otp",
        { user_name: this.props.form.getFieldValue("username") },
        {}
      )
        .then((response) => {
          if (response.status) {
            this.setState({ open: status });
          } else {
            message.error("Something went wrong, please try again.");
          }
        })
        .catch((err) => {
          if (err.response) message.error(err.response.data.message);
          else message.error("Something went wrong, please try again.");
        });
    } else this.setState({ open: status });
  };

  postSubmitData = (values, setUserLoggedStatus, setUserRBACDetails) => {
    const userPostData = {
      username: values.username,
      password: values.password,
    };

    API.post("/login", userPostData, {})
      .then((response) => {
        //console.log("USER RESPONSE :", response.data);
        if (response.status) {
          // reset the input fields after successful login
          this.props.form.resetFields();

          //changing the state to rerender app.js to redirect. state is passed to app.js from redux.
          setUserLoggedStatus(true);
          // localStorage.setItem(
          //   "percentage",
          //   response.data.data.min_max_range
          // );
          // rerouting to home.
          localStorage.setItem("isLogged", true);
          // this.props.history.push("/home");

          localStorage.setItem("userName", response.data.data.name);

          localStorage.setItem("accessToken", response.data.data.access_token);
          localStorage.setItem(
            "refreshToken",
            response.data.data.refresh_token
          );
          this.props.setAccessToken(response.data.data.access_token);
          this.props.setRefreshToken(response.data.data.refresh_token);
          //console.log("access token ", response.data.data.access_token );
          localStorage.setItem(
            "accessTokenExpiry",
            response.data.data.access_token_expiry
          );
          localStorage.setItem(
            "refreshToken",
            response.data.data.refresh_token
          );
          localStorage.setItem(
            "refreshTokenExpiry",
            response.data.data.refresh_token_expiry
          );

          localStorage.setItem(
            "logoutTimeStamp",
            Math.round(new Date().getTime() / 1000) +
              response.data.data.refresh_token_expiry
          );

          //#region handling RBAC

          if (
            response.data.data.role_id &&
            response.data.data.user_territories
          ) {
            localStorage.setItem("roleID", response.data.data.role_id);
            localStorage.setItem("user_features", response.data.data.user_features);
            localStorage.setItem(
              "userTerritories",
              response.data.data.user_territories
            );
            localStorage.setItem(
              "location_access",
              response.data.data.location_access.map(
                (item) => item.feature_code
              )
            );
            localStorage.setItem(
              "non_location_access",
              response.data.data.non_location_access.map(
                (item) => item.feature_code
              )
            );

            setUserRBACDetails(
              response.data.data.role_id,
              response.data.data.name,
              response.data.data.user_territories,
              response.data.data.location_access.map(
                (item) => item.feature_code
              ),
              response.data.data.non_location_access.map(
                (item) => item.feature_code
              ),
              response.data.data.user_features
            );
          } else {
            setUserRBACDetails(0, "", [], [], [], []);
          }
          //#endregion
        }
      })
      .catch((err) => {
        if (err) {
          if (err.response) message.error(err.response.data.message);
          else {
            message.error("Server unavailable. Please try again later.");
          }
        } else {
          message.error("Server unavailable. Please try again later.");
        }
      });
  };

  handleSubmit = (e) => {
    const {
      setUserLoggedStatus,
      // history,
      setUserRBACDetails,
    } = this.props;
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      //if validated, call the login post api
      if (!err && values.username && values.password) {
        this.postSubmitData(values, setUserLoggedStatus, setUserRBACDetails);
      } else {
        message.error("Please provide valid login credentials!");
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    // localStorage.clear();

    const ConfigOptions = {
      maxCount: 1,
    };
    message.config(ConfigOptions);

    /**
     *function to submit the login credentials
     *
     * @param {Event} e the event from the form containing the form input values.
     * @memberof App.LoginForm
     */

    return (
      <>
        <Form onSubmit={this.handleSubmit.bind(this)} className="login-form">
          <div
            className="login-main-container"
            style={{ height: window.innerHeight - 25 }}
          >
            <div
              className="background-tile"
              style={{
                backgroundImage: `url(${bg})`,
              }}
            ></div>
            <div className="login-container">
              {/* <div className="login-div">
              <p>LOGIN</p>
            </div> */}

              <div className="login-logo">
                <img alt="logo" width={"100px"} height={"100px"} src={Logo} />
              </div>
              <div className="inputs">
                <Form.Item>
                  {getFieldDecorator("username", {
                    // rules: [
                    //   { required: true, message: "Please input your username!" }
                    // ]
                  })(
                    <Input
                      className="textbox"
                      prefix={
                        <Icon
                          type="user"
                          style={{ color: "rgba(0,0,0,.25)" }}
                        />
                      }
                      placeholder="Username"
                    />
                  )}
                </Form.Item>
                <div className="dots">
                  . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                  . . .
                </div>
                {/* <div className="line"></div> */}
                <Form.Item>
                  {getFieldDecorator("password", {
                    // rules: [
                    //   { required: true, message: "Please input your Password!" }
                    // ]
                  })(
                    <Input.Password
                      className="textbox"
                      prefix={
                        <Icon
                          type="lock"
                          style={{ color: "rgba(0,0,0,.25)" }}
                        />
                      }
                      placeholder="Password"
                    />
                  )}
                </Form.Item>
                {/* <div className="line"></div> */}
              </div>
              <Form.Item
                style={{ margin: 0, padding: 0, lineHeight: "normal" }}
              >
                <div style={{ float: "right" }}>
                  {" "}
                  <p
                    style={{
                      margin: 0,
                      color: "#1890ff",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (this.state.isLoading) return;
                      this.props.form.getFieldValue("username")
                        ? this.handleShow.call(this, true)
                        : message.error(
                            "Please enter your username to reset your password!"
                          );
                    }}
                  >
                    Forgot Password
                  </p>
                </div>
              </Form.Item>
              <Form.Item
                style={{ margin: 0, padding: 0, lineHeight: "normal" }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button button"
                >
                  LOGIN
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
        <ForgotPassword
          parentForm={this.props.form}
          userName={this.props.form.getFieldValue("username")}
          open={this.state.open}
          setClose={() => {
            this.handleShow.call(this, false);
            this.setState({ isLoading: false });
          }}
          submit={(values) =>
            this.postSubmitData(
              values,
              this.props.setUserLoggedStatus,
              this.props.setUserRBACDetails
            )
          }
        />
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  //////////////////////////////////////////////
  //#region arrival entry optimization selectors
});

const mapDispatchToProps = (dispatch) => {
  return {
    setUserLoggedStatus: (status) => dispatch(setUserLoggedStatus(status)),
    setAccessToken: (token) => dispatch(setAccessToken(token)),
    setRefreshToken: (token) => dispatch(setRefreshToken(token)),
    setUserRBACDetails: (
      id,
      name,
      territories,
      locationFeatures,
      nonLocationFeatures,
      userFeatures
    ) =>
      dispatch(
        setUserRBACDetails(
          id,
          name,
          territories,
          locationFeatures,
          nonLocationFeatures,
          userFeatures
        )
      ),
  };
};

const Login = Form.create({ name: "normal_login" })(LoginForm);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
