import React from "react";
import "./volume-buying-plan-state.styles.css";

import { Table, message, Empty, Spin } from "antd";
import CustomSelectWeekly from "../custom-select-weekly/custom-select-weekly.component";
import { useState } from "react";
import { useEffect } from "react";

import { getVolumeBuyingPlanState } from "../../services/generic-apis";

const { Column, ColumnGroup } = Table;

const VolumeBuyingPlanState = (props) => {
  const {} = props;

  const [isLoading, setLoading] = useState(false);
  const [selectedRunMonth, setRunMonth] = useState(undefined);
  const [selectedQCType, setQCType] = useState(undefined);
  const [sumData, setSumData] = useState(undefined);
  const [costData, setCostData] = useState(undefined);

  useEffect(() => {
    setRunMonth(finiteFilters.model_runs[0].id);
    setQCType(finiteFilters.qc_model_runs[0].id);
    getData(finiteFilters.model_runs[0].id, finiteFilters.qc_model_runs[0].id);
  }, []);

  const finiteFilters = {
    model_runs: [
      { id: 2, name: "Feb" },
      { id: 3, name: "March" },
      { id: 4, name: "April" },
      { id: 5, name: "May" },
      { id: 6, name: "June" },
    ],
    qc_model_runs: [
      { id: 1, name: "Model QC" },
      { id: 3, name: "Business QC" },
    ],
  };

  const getData = async (run_month, qc_type) => {
    setLoading(true);

    console.log(
      `getting data for month : ${run_month} and qc_type : ${qc_type}`
    );
    try {
      const [sumResponse, costResponse] = await getVolumeBuyingPlanState(
        run_month,
        qc_type
      );

      setLoading(false);

      if (sumResponse.data.status) {
        setSumData(sumResponse.data.data);
      } else {
        message.error("Could not retrieve data for Sum of Solution table.");
      }

      if (costResponse.data.status) {
        setCostData(costResponse.data.data);
      } else {
        message.error("Could not retrieve data for Buying Cost table.");
      }
      console.log("both responses : ", sumResponse, costResponse);
    } catch (error) {
      setLoading(false);
    }
  };

  const onMonthSelected = (value) => {
    setRunMonth(value);
    getData(value, selectedQCType);
  };

  const onQCSelected = (value) => {
    setQCType(value);
    getData(selectedRunMonth, value);
  };

  const getSumTableData = () => {
    let modifiedSumData = sumData;

    modifiedSumData = modifiedSumData.map((item) => {
      return {
        key: item.state_variety,
        state: item.state_variety,
        feb: item.Feb,
        march: item.Mar,
        april: item.Apr,
        may: item.May,
        june: item.Jun,
        post_season: item.post_season,
        grand_total: item.total_vol,
      };
    });

    const totalRow = {
      key: "Total",
      state: "Total",
      feb: modifiedSumData.reduce((acc, item) => acc + item.feb || 0, 0),
      march: modifiedSumData.reduce((acc, item) => acc + item.march || 0, 0),
      april: modifiedSumData.reduce((acc, item) => acc + item.april || 0, 0),
      may: modifiedSumData.reduce((acc, item) => acc + item.may || 0, 0),
      june: modifiedSumData.reduce((acc, item) => acc + item.june || 0, 0),
      post_season: modifiedSumData.reduce(
        (acc, item) => acc + item.post_season || 0,
        0
      ),
      grand_total: modifiedSumData.reduce(
        (acc, item) => acc + item.grand_total || 0,
        0
      ),
    };

    console.log("sum table data : ", [...modifiedSumData, totalRow]);
    return [...modifiedSumData, totalRow];
  };
  const getCostTableData = () => {
    let modifiedSumData = costData;

    modifiedSumData = modifiedSumData.map((item) => {
      return {
        key: item.state_variety,
        state: item.state_variety,
        feb: item.Feb,
        march: item.Mar,
        april: item.Apr,
        may: item.May,
        june: item.Jun,
        post_season: item.post_season,
      };
    });

    const totalRow = {
      key: "Total",
      state: "Total",
      feb: modifiedSumData.reduce((acc, item) => acc + item.feb || 0, 0),
      march: modifiedSumData.reduce((acc, item) => acc + item.march || 0, 0),
      april: modifiedSumData.reduce((acc, item) => acc + item.april || 0, 0),
      may: modifiedSumData.reduce((acc, item) => acc + item.may || 0, 0),
      june: modifiedSumData.reduce((acc, item) => acc + item.june || 0, 0),
      post_season: modifiedSumData.reduce(
        (acc, item) => acc + item.post_season || 0,
        0
      ),
    };

    console.log("cost table data : ", [...modifiedSumData, totalRow]);
    return [...modifiedSumData, totalRow];
  };

  return (
    <Spin spinning={isLoading}>
      <div className="volume-buying-plan-state-container">
        <div className="filters box-shadow-card">
          <div
            className="temporal-select center box-shadow-card"
            style={{ height: "70px" }}
          >
            <div className="temporal-select-title">Select Run Month</div>
            <CustomSelectWeekly
              list={finiteFilters.model_runs.map((item) => {
                return {
                  name: item.name,
                  id: item.id,
                };
              })}
              value={selectedRunMonth}
              onSelect={onMonthSelected}
              //   placeholder={`Select ${setSelectText()}`}
            />
          </div>
          <div
            className="temporal-select center box-shadow-card"
            style={{ height: "70px" }}
          >
            <div className="temporal-select-title">Select QC Type</div>
            <CustomSelectWeekly
              list={finiteFilters.qc_model_runs.map((item) => {
                return {
                  name: item.name,
                  id: item.id,
                };
              })}
              value={selectedQCType}
              onSelect={onQCSelected}
              //   placeholder={`Select ${setSelectText()}`}
            />
          </div>
        </div>
        {sumData ? (
          <div
            style={{ marginBottom: "20px" }}
            className="normal-table box-shadow-card"
          >
            <Table
              rowClassName={(record, index) =>
                record.state === "Total" ? "total-row" : ""
              }
              pagination={false}
              dataSource={getSumTableData()}
            >
              <ColumnGroup title="Sum of Solution">
                <Column
                  className="column"
                  title="State"
                  dataIndex="state"
                  key="state"
                />
                <Column
                  className="column"
                  title="February"
                  dataIndex="feb"
                  key="feb"
                />
                <Column
                  className="column"
                  title="March"
                  dataIndex="march"
                  key="march"
                />
                <Column
                  className="column"
                  title="April"
                  dataIndex="april"
                  key="april"
                />
                <Column
                  className="column"
                  title="May"
                  dataIndex="may"
                  key="may"
                />
                <Column
                  className="column"
                  title="June"
                  dataIndex="june"
                  key="june"
                />
                <Column
                  className="column"
                  title="Post Season"
                  dataIndex="post_season"
                  key="post_season"
                />
                <Column
                  className="column"
                  title="Grand Total"
                  dataIndex="grand_total"
                  key="grand_total"
                />
              </ColumnGroup>
            </Table>
          </div>
        ) : (
          <Empty
            style={{ margin: "10px" }}
            description="No data available for this table."
          />
        )}

        {costData ? (
          <div className="normal-table box-shadow-card">
            <Table
              rowClassName={(record, index) =>
                record.state === "Total" ? "total-row" : ""
              }
              pagination={false}
              dataSource={getCostTableData()}
            >
              <ColumnGroup title="Buying Cost">
                <Column
                  className="column"
                  title="State"
                  dataIndex="state"
                  key="state"
                />
                <Column
                  className="column"
                  title="February"
                  dataIndex="feb"
                  key="feb"
                />
                <Column
                  className="column"
                  title="March"
                  dataIndex="march"
                  key="march"
                />
                <Column
                  className="column"
                  title="April"
                  dataIndex="april"
                  key="april"
                />
                <Column
                  className="column"
                  title="May"
                  dataIndex="may"
                  key="may"
                />
                <Column
                  className="column"
                  title="June"
                  dataIndex="june"
                  key="june"
                />
                <Column
                  className="column"
                  title="Post Season"
                  dataIndex="post_season"
                  key="post_season"
                />
              </ColumnGroup>
            </Table>
          </div>
        ) : (
          <Empty
            style={{ margin: "10px" }}
            description="No data available for this table."
          />
        )}
      </div>
    </Spin>
  );
};

export default VolumeBuyingPlanState;
