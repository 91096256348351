import React from "react";
import "./master-screen-render.styles.css";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectMSMessageRBAC } from "../../redux/user/user.selectors";

import MandiMaster from "../../pages/mandi-master/mandi-master.page";
import HubMaster from "../../pages/hub-master/hub-master.page";
import MandiHubMappingMaster from "../../pages/mandi-hub-mapping/mandi-hub-mapping.page";
import CompetitorMaster from "../../pages/competitor-master/competitor-master.page";
import FavoriteMandiMaster from "../../pages/favorite-mandi-master/favorite-mandi-master.page";
import RegionMaster from "../../pages/region-master/region-master.page";
import BranchMaster from "../../pages/branch-master/branch-master.page";
import FactoryMaster from "../../pages/factory-master/factory-master.page";
import WarehouseMaster from"../../pages/warehouse-cost-master/warehouse-cost.page";
import CostBuildupSahayakMaster from "../../pages/cost-buildup-sahayak-master/cost-buildup-sahayak.page";
import RoleMaster from "../../pages/role-master/role-master.page";
import RoleFeatureMaster from "../../pages/role-feature-mapping-master/role-feature-mapping-master.page";
import FeatureMaster from "../../pages/feature-master/feature-master.page";
import LocalWarehouseMaster from"../../pages/local-warehouse-charges-master/local-warehouse-charges.page";
import VarietyMaster from "../../pages/variety-master/variety-master.page";
import VarietyRegionMaster from "../../pages/variety-region-master/variety-region-master.page";
import HolidayMaster from "../../pages/holiday-master/holiday-master.page";
import MandiStateDistrictHubMaster from "../../pages/mandi-state-district-hub-master/mandi-state-district-hub-master.page";
import ConsolidatedCostMaster from "../../pages/consolidated-cost-buildup-master/consolidated-cost-buildup.page";
import POCorrectionMaster from "../../pages/po-price-correction-master/po-price-correction.page";
import AnualFactoryConsumptionMaster from "../../pages/annual-factory-consumption-master/annual-factory-consumption-master.page";
import InflationMaster from "../../pages/inflation-factor-master/inflation-factor.page";
import LoadingUnloadingCostMaster from "../../pages/loading-unloading-cost-master/loading-unloading-cost-master.page";
import DestinationBranchMapMaster from "../../pages/destination-branch-map-master/destination-branch-map-master.page";
import HubFactoryFrieghtMaster from "../../pages/hub-factory-frieght-master/hub-factory-frieght-master.page";
import CostBuildUpFarmer from "../../pages/cost-buildup-farmer-master/cost-buildup-farmer-master.page";
import StateDistrictMarketVarietyMaster from "../../pages/state-district-market-variety-master/state-district-market-variety.page";
import AllFarmerBuyingMaster from "../../pages/all-farmer-buying-master/all-farmer-buying-master.page";
import AcreageMaster from "../../pages/acreage-master/acreage-master.page";
import ContainerDTDFreightMaster from "../../pages/container-DTD-freight-master/container-DTD-freight-master.page";
import FarmerBuyingPercentileMaster from "../../pages/farmer-buying-percentile-master/farmer-buying-percentile-master.page";
import CostBuildupSahayakBiharMaster from "../../pages/cost-buildup-sahayak-bihar-master/cost-buildup-sahayak-bihar-master.page";

const MasterScreenRender = (props) => {
  const { selectedFilterName ,messageAccess} = props;
  console.log("access mesage in render ", messageAccess);
  switch (selectedFilterName) {
    case "Mandi":
      return <MandiMaster />;
    case "Hub":
      return <HubMaster />;
    case "Mandi Hub Freight":
      return <MandiHubMappingMaster />;
      case "Cost Build Up Farmer":
      return <CostBuildUpFarmer/>
    case "Competitor":
      return <CompetitorMaster />;
    case "Favorite Mandi":
      return <FavoriteMandiMaster />;
    case "Branch":
      return <BranchMaster />;
    case "Region":
      return <RegionMaster />;
      case "Warehouse Cost":
        return <WarehouseMaster />;
       case "Local Warehouse Charges":
         return <LocalWarehouseMaster />; 
       case "Factory":
      return <FactoryMaster />;
      case "Cost Buildup Sahayak Gujarat":
       return <CostBuildupSahayakMaster />
       case "Cost Buildup Sahayak Bihar":
        return <CostBuildupSahayakBiharMaster />
      case "Mandi State District Hub":
       return <MandiStateDistrictHubMaster />;
      //  case "State Market District Variety":
      //   return <StateDistrictMarketVarietyMaster />;
    case "Role":
      return <RoleMaster />;
    case "Role Feature Mapping":
      return <RoleFeatureMaster />;
    // case "Feature":
    //   return <FeatureMaster />;
    case "Variety":
      return <VarietyMaster />;
    case "Variety Region Mapping":
      return <VarietyRegionMaster />;
    case "Cost Build Up Mandi":
      return <ConsolidatedCostMaster />;
    case "Holiday":
      return <HolidayMaster />;
    case "PO Price Correction":
      return <POCorrectionMaster />;
    case "Destination Branch Map":
      return <DestinationBranchMapMaster />;
    case "Annual factory Consumption Plan":
      return <AnualFactoryConsumptionMaster />;
    case "Hub Factory Freight Mapping":
      return <HubFactoryFrieghtMaster />;
    case "Inflation Factor":
      return <InflationMaster />;
    case "Loading Unloading Costs":
      return <LoadingUnloadingCostMaster />;
    case "All Farmer Buying":
      return <AllFarmerBuyingMaster />;
    case "Acreage":
      return <AcreageMaster name={"acreage"} messageAccess={messageAccess}/>;
    case "Container DTD Freight":
      return <ContainerDTDFreightMaster />;
      case "Farmer Buying Percentile":
        return <FarmerBuyingPercentileMaster />;
    default:
      return <AcreageMaster />;
  }
};

const mapStateToProps = createStructuredSelector({
  messageAccess: selectMSMessageRBAC,
});

export default connect(mapStateToProps, null)( MasterScreenRender);
