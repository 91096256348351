import React from "react";
import "./alert-management-form.styles.css";
import { Component } from "react";

import {
  Form,
  Radio,
  Input,
  Select,
  Switch,
  InputNumber,
  Button,
  message,
  Checkbox,
  DatePicker,
} from "antd";

import API from "../../api";

import moment from "moment";

import { connect } from "react-redux";

import { setAllUsers } from "../../redux/user/user.actions";


/**
 * @component
 * @description The add/edit form component used in Alerts tab
 * @property {object[]} allAlerts all the alerts stored in the redux store.
 * @property {function} setAlerts reference to the action that sets the alerts in redux.
 * @property {object[]} alertTypeList the list of alert types available
 * @property {object[]} targetGroupList the list of all user groups that can be targeted. available from redux.
 * @property {object[]} alertBehaviourList the list of alert behaviours available.
 * @property {object[]} communicationModeList types of communication modes possible.
 * @property {object[]} currentTargetGroup the target groups for the selected alert (will be an empty array for adding alert)
 * @property {object} currentRecord current selected alert row object (will be null for adding alert)
 * @property {boolean} isAdd true for adding alert, false for editing alert.
 * @property {function} setModalVisibility function to hide the add/edit alert popup
 */
class AlertsForm extends Component {
  state = { isSubmitButtonClicked: false };
  render() {
    const {
      allAlerts,
      setAlerts,
      alertTypeList,
      targetGroupList,
      alertBehaviourList,
      communicationModeList,
      currentTargetGroup,
      currentRecord,
      isAdd,
      setModalVisibility,
      //////////////////////////
    } = this.props;

    const { getFieldDecorator } = this.props.form;

    /**
     * @function
     * @description validate the submission and add/edit alert by calling corresponsing APIs.
     * Also adds/modifies the alert object in the redux store.
     * @param {event} e the form submission event
     * @memberof AlertsForm
     */
    const handleSubmit = (e) => {
      e.preventDefault();
      this.props.form.validateFields((err, values) => {
        if (!err) {

          this.setState({ isSubmitButtonClicked: true });

          const targetGroupsToSend = values.targetGroup.map((item) => {
            return {
              group_id: targetGroupList.find((group) => group.name === item).id,
            };
          });



          const dataToSend = {
            expiry_date: moment(values.expiryDate, "YYYY-MM-DD").format(
              "YYYY-MM-DD"
            ),
            target_groups: targetGroupsToSend,
            communication_modes: values.communicationMode.map((mode) => {
              return {
                comm_id: mode,
              };
            }),
            alert_behaviour_id: values.alertBehaviour,
            alert_type_id: values.alertType,
            description: values.description,
          };


          //#region api to create or update user

          if (isAdd) {
            //#region api to add alert , then add to redux.
            API.post("/user/alerts", dataToSend)
              .then((createAlertResponse) => {
                if (createAlertResponse.data.status) {
                  //#region add new alert to redux

                  let modifiedAlerts = [
                    ...allAlerts,
                    createAlertResponse.data.data,
                  ];
                  setAlerts(modifiedAlerts);

                  //#endregion

                  setModalVisibility(false, false, null);
                  this.props.form.resetFields();
                  this.setState({ isSubmitButtonClicked: false });
                } else {
                  this.setState({ isSubmitButtonClicked: false });
                  message.warning(
                    "Could not create new alert. Please try again"
                  );
                }
              })
              .catch((err) => {
                this.setState({ isSubmitButtonClicked: false });
                message.warning(
                  "Could not create new alert. Please check your network connectivity."
                );
              });

            //#endregion
          } else {
            //#region api to modify alert , then modify in redux.

            API.put(
              `/user/alerts/${currentRecord.allData.user_alert_id}`,
              dataToSend,
              {

              }
            )
              .then((updateAlertResponse) => {
                if (updateAlertResponse.data.status) {
                  //#region update redux state
                  let modifiedAlerts = this.props.allAlerts.map((alert) => {
                    if (
                      alert.user_alert_id ===
                      currentRecord.allData.user_alert_id
                    ) {
                      return updateAlertResponse.data.data;
                    }
                    return alert;
                  });

                  this.props.setAlerts(modifiedAlerts);
                  //#endregion

                  setModalVisibility(false, false, null);
                  this.props.form.resetFields();
                  this.setState({ isSubmitButtonClicked: false });
                } else {
                  this.setState({ isSubmitButtonClicked: false });
                  message.warning(
                    "Could not create new alert. Please try again"
                  );
                }
              })
              .catch((err) => {
                this.setState({ isSubmitButtonClicked: false });
                message.warning(
                  "Could not create new alert. Please check your network connectivity."
                );
              });
            //#endregion
          }

          //#endregion
        } else {
          // this.props.form.resetFields();
          // setModalVisibility(false, false, null);
        }
      });
    };

    return (
      <div className="alert-form-container">
        <Form
          layout="horizontal"
          //   onValuesChange={onFormLayoutChange}
          size={"small"}
          onSubmit={handleSubmit}
        >
          <div className="form-top-container">
            <div className="form-top-left">
              <Form.Item>
                <div style={{ width: "90%", margin: "4px 0 0 0 " }}>
                  Alert Type
                </div>
                {getFieldDecorator("alertType", {
                  initialValue: currentRecord
                    ? currentRecord.alertType.id
                    : alertTypeList[0].id,
                  rules: [
                    {
                      required: true,
                      message: "Please select alert type !",
                    },
                  ],
                })(
                  <Select
                    disabled={this.state.isSubmitButtonClicked}
                    // showSearch
                    showArrow
                    // mode="multiple"
                    placeholder="Select Alert Type"
                  >
                    {alertTypeList.map((alertType, idx) => (
                      <Select.Option key={alertType.id} value={alertType.id}>
                        {alertType.name}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>

              <Form.Item>
                <div style={{ width: "90%", margin: "30px 0 -24px 4px" }}>
                  Target
                </div>
                {getFieldDecorator("targetGroup", {
                  initialValue: currentTargetGroup ? currentTargetGroup : [],
                  rules: [
                    {
                      required: true,
                      message: "Please enter target group/groups !",
                    },
                  ],
                })(
                  <Select
                    disabled={this.state.isSubmitButtonClicked}
                    showSearch
                    showArrow
                    mode="multiple"
                    placeholder="Select Target Groups"
                  >
                    {targetGroupList.map((item, idx) => (
                      <Select.Option key={item.id} value={item.name}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>

              <Form.Item>
                <div style={{ width: "90%", margin: "24px 0 -5px 8px" }}>
                  Expiry Date
                </div>
                {getFieldDecorator("expiryDate", {
                  initialValue: currentRecord
                    ? moment(currentRecord.expiryDate)
                    : null,
                  rules: [
                    {
                      required: true,
                      message: "Please select the alert duration !",
                    },
                  ],
                })(
                  <DatePicker
                    disabled={this.state.isSubmitButtonClicked}
                    disabledDate={(current) =>
                      current < moment().subtract(1, "days").endOf("day")
                    }
                  />
                )}
              </Form.Item>
            </div>

            <div className="form-top-right">
              <Form.Item>
                <div style={{ width: "90%", margin: "0 0 0 0px" }}>
                  Alert Behavior
                </div>
                <div className="form-radio-list">
                  {getFieldDecorator("alertBehaviour", {
                    initialValue: currentRecord
                      ? currentRecord.alertBehaviour.value
                      : 1,

                    rules: [
                      {
                        required: true,
                        message: "Please select the alert behavior !",
                      },
                    ],
                  })(
                    <Radio.Group
                      disabled={this.state.isSubmitButtonClicked}
                      options={alertBehaviourList}
                      onChange={(value) => console.log(value)}
                    />
                  )}
                </div>
              </Form.Item>

              <Form.Item>
                <div style={{ width: "90%", margin: "5px 0 -12px 0" }}>
                  Communication Mode
                </div>
                <div className="form-radio-list">
                  {getFieldDecorator("communicationMode", {
                    initialValue: currentRecord
                      ? currentRecord.communicationMode.map(
                        (mode) => mode.value
                      )
                      : [1],

                    rules: [
                      {
                        required: true,
                        message: "Please select the communication modes !",
                      },
                    ],
                  })(
                    <Checkbox.Group
                      disabled={this.state.isSubmitButtonClicked}
                      options={communicationModeList}
                      onChange={(checked) => console.log(checked)}
                    />
                  )}
                </div>
              </Form.Item>

              <Form.Item>
                <div style={{ width: "90%", margin: "5px 0 0 0px" }}>
                  Alert Description
                </div>
                <div>
                  {getFieldDecorator("description", {
                    initialValue:
                      currentRecord !== null ? currentRecord.description : "",
                    rules: [
                      {
                        required: true,
                        message: "Please enter the alert description !",
                      },
                    ],
                  })(
                    <Input.TextArea
                      disabled={this.state.isSubmitButtonClicked}
                      className="alert-text-area"
                      allowClear
                      rows={4}
                    // autoSize={{ minRows: 1, maxRows: 4 }}
                    />
                  )}
                </div>
              </Form.Item>
            </div>
          </div>

          <div className="form-bottom-container">
            <Form.Item>
              <Button
                disabled={this.state.isSubmitButtonClicked}
                className="submit-button"
                type="primary"
                htmlType="submit"
              >
                Submit
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    );
  }
}

const AlertsManagementForm = Form.create({ name: "alerts_management_form" })(
  AlertsForm
);

const mapDispatchToProps = (dispatch) => {
  return {
    setAllUsers: (data) => dispatch(setAllUsers(data)),
  };
};

export default connect(null, mapDispatchToProps)(AlertsManagementForm);
