import React, { Component } from "react";
import "./qc-monthly-varieties-graph.styles.css";

import { Line } from "react-chartjs-2";

import { getRandomColor } from "../../auth/utils";
import { Empty } from "antd";

class QCMonthlyVarietiesGraph extends Component {
  render() {
    const {
      data,
      height,
      width,
      title,
      qcScore,
      qcLimit,
      scoreHidden,
    } = this.props;
    let legendInformation = [];

    const chartOptions = {
      responsive: false,
      spanGaps: true,
      maintainAspectRatio: true,
      legend: {
        display: false,
      },
      //   hover: {
      //     mode: null,
      //   },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            let dataset = data["datasets"][tooltipItem.datasetIndex];
            let value = dataset["data"][tooltipItem["index"]];

            return `${dataset.label} - Price :${value} ₹/MT`;
          },
        },
        label: true,
        intersect: false,
        mode: "nearest",
        backgroundColor: "white",
        titleFontColor: "#313131",
        borderColor: "#9d9d9d",
        borderWidth: 1,
        bodyFontColor: "#575757",
        displayColors: false,
      },
      elements: {
        line: {
          tension: 0,
        },
      },
      label: true,
      intersect: false,
      mode: "nearest",
      backgroundColor: "white",
      titleFontColor: "#313131",
      borderColor: "#9d9d9d",
      borderWidth: 1,
      bodyFontColor: "#575757",
      displayColors: false,
      scales: {
        xAxes: [
          {
            id: "Time",
            ticks: {
              maxRotation: 0,
            },
            scaleLabel: {
              display: true,
              labelString: "Time",
            },
          },
        ],
        yAxes: [
          {
            id: "price/volume",
            //   ticks: { beginAtZero: true },
            scaleLabel: {
              display: true,
              labelString: "Projected Price (₹/MT)",
            },
          },
        ],
      },
    };

    const getPriceDataSets = () => {
      legendInformation = [];
      let dataSets = [];

      data.data_points.map((item, idx) => {
        let lineColor = getRandomColor(idx);
        legendInformation.push({
          name: item.name,
          color: lineColor,
        });

        let pairSet = [
          {
            type: "line",
            label: item.name,
            borderColor: lineColor,
            borderWidth: 2,
            fill: false,
            data: item.price_values,
          },
        ];
        dataSets.push(...pairSet);
        //
      });

      return dataSets;
    };

    const chartData = data
      ? {
          labels: data.x_axis_labels,
          datasets: getPriceDataSets(),
        }
      : {
          labels: [],
          datasets: [],
        };

    return (
      <div
        style={{ width: width ? `${width + 100}px` : "100%" }}
        className="fixed-state-variety-projection-container"
      >
        {data ? (
          <div className="projection-graph-container">
            <div
              onClick={() => {
                console.log("chartdata", chartData);
              }}
              className="graph-info"
            >
              <div className="graph-title">
                <>
                  {title ? title : "QC Rules - " + data.title}
                  {scoreHidden ? null : (
                    <div className="info-box ">
                      <div
                        className="mini-circle box-shadow-bordered"
                        style={{
                          background: qcScore
                            ? qcScore < qcLimit
                              ? "#fe8c00"
                              : "#73de73"
                            : "grey",
                        }}
                      >
                        {/* {qcScore ? qcScore : "NA"} */}
                      </div>
                    </div>
                  )}
                </>
              </div>
              <div className="legend-container">
                {legendInformation.map((legend) => (
                  <div key={legend.name} className="legend-box">
                    <div
                      className="legend-fill"
                      style={{
                        background: legend.color,
                      }}
                    />
                    <div className="legend-title">{legend.name}</div>
                  </div>
                ))}
              </div>
              <div className="graph">
                {/* <div>
            {isVolume ? "Projected Volume (MT)" : "Projected Price (₹/MT)"}
          </div> */}
                <Line
                  id="projection-line-chart"
                  height={height ? height : 230}
                  width={width ? width : 1200}
                  data={chartData}
                  options={chartOptions}
                />
              </div>
            </div>
          </div>
        ) : (
          <Empty />
        )}
      </div>
    );
  }
}

export default QCMonthlyVarietiesGraph;
