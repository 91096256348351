import React from "react";
import { Tabs, Button, Drawer } from "antd";
import "antd/dist/antd.css";
import "./main-content.styles.css";
import Logo from "../../assets/logo.jpg";
import Burger from "../../assets/Hamburger.svg";

import BuyingRecomandationContainer from "../../pages/buying-recomandation-container/buying-recomandation-container";
import BuyingDecisionContainer from "../../pages/buying-decision-container/buying-decision-container.page";
import Home from "../../pages/home/home.page";
import ArrivalsEntry from "../../pages/arrivals-entry/arrivals-entry.page";
import Summary from "../../pages/summary/summary.page";
import VarietalSubstitution from "../../pages/varietal-substitution/varietal-substitution.page";
import Report from "../../pages/report/report.page";
import ForecastTabs from "../buying-recommendation-tabs/buying-recommendation-tabs-component";
import AdminTabs from "../admin-tabs/admin-tabs.component";
import MasterTabs from "../master-screens/master-screens.component";
import CompetitionAnalysis from "../../pages/market-analysis/market-analysis.page";

import WeatherWidget from "../weather-widget/weather-widget.component";

import MenuItemGroup from "../menu-item-selector-group/menu-item-selector-group.component";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Link, withRouter, Switch, Route } from "react-router-dom";

import { collapseSidemenu } from "../../redux/side-menu/side-menu.actions";

import { setSummaryVisibility } from "../../redux/arrival-entry/arrival-entry.actions";
import { setCurrentArrivalScreen } from "../../redux/map/map.actions";
import {
  setUserLoggedStatus,
  setAccessToken,
  setRefreshToken
} from "../../redux/user/user.actions";

import { selectUserData } from "../../redux/user/user.selectors";

import { canUserEdit } from "../../auth/utils";
import { FeatureCodes } from "../../auth/data";

import { Component } from "react";

import { SignOutAPI } from "../../auth/utils";

const { TabPane } = Tabs;

/**
 *@component
 *@description Parent component of all tabs
 *@memberof App.MainLayout
 */
class MainContent extends Component {
  state = {
    currentTab: "4",
    menuVisible: false,
  };

  changeKeyOnlyOnSelectedTab = (tabkey) => {
    if (tabkey === this.state.currentTab) {
      return tabkey + 1;
    } else return tabkey;
  };

  handleLogoutButton = async () => {

    SignOutAPI();

    await localStorage.clear();
    this.props.setAccessToken(null);
    this.props.setRefreshToken(null);
    this.props.setUserLoggedStatus(false);
    // this.props.history.push("/login");

  }
  render() {
    const {
      collapseSidemenu,
      history,
      setSummaryVisibility,
      setCurrentArrivalScreen,
      setUserLoggedStatus,
      clickDisable,
    } = this.props;

    /**
     * @function
     * @description Closes the menu
     * @memberof App.MainLayout.MainContent
     */
    const onClose = () => {
      this.setState({ menuVisible: false });
    };

    /**
     * @function
     * @description Opens the menu
     * @memberof App.MainLayout.MainContent
     */
    const openMenu = () => {
      this.setState({ menuVisible: true });
    };

    // Tab extra contents
    /**
     * @component
     * @description Additional components displayed in the tab.
     * @memberof App.MainLayout.MainContent
     */
    const tabExtraContent = (
      <div className="toolbar-extra">
        <Drawer
          placement={"right"}
          closable={true}
          onClose={onClose}
          visible={this.state.menuVisible}
        >
          <MenuItemGroup />
        </Drawer>
        <div className="logout-container">
          {window.location.hostname.includes("qa") ?
            // {window.location.port !== "8080" ? 
            <div className="differentation-button-container">
              {
                <Button
                  type="primary"
                  shape="round"
                  size="large"
                >
                  <div style={{ float: "right" }}>
                    <div style={{ marginTop: "-7px" }}>UAT Environment</div>
                  </div>
                </Button>
              }
            </div>
            : ""
          }
          <WeatherWidget key={new Date()} />
          <Button
            onClick={this.handleLogoutButton.bind(this)}
          >
            Logout
          </Button>
        </div>
        <div className="burger-menu" onClick={openMenu}>
          <img src={Burger} alt="" style={{ width: "20px", heigh: "20px" }} />
        </div>
      </div>
    );

    return (
      <Tabs
        className={`main-content ${this.state.menuVisible ? "main-content-blur" : ""
          }`}
        defaultActiveKey="1"
        onChange={(value) => {
          setSummaryVisibility(false);
          this.setState({ currentTab: value });
        }}
        tabBarExtraContent={tabExtraContent}
      >
        <TabPane
          key="1000"
          disabled
          tab={
            <div
              style={{
                height: "20px",
                width: "50px",
                marginTop: "-20px",
                marginLeft: "-12px",
              }}
            >
              <img
                alt="logo"
                style={{
                  cursor: "auto",
                  width: "42px",
                  height: "42px",
                  marginTop: "-5px",
                  marginLeft: "10px",
                }}
                src={Logo}
              ></img>
            </div>
          }
        />
        <TabPane tab="Home" key="1">
          <Home />
        </TabPane>
        <TabPane tab="Buying Recommendation" key="2">
          <BuyingRecomandationContainer key={this.state.currentTab} />
        </TabPane>
        <TabPane tab="Arrivals" key="3">
          <ArrivalsEntry key={this.state.currentTab} />
        </TabPane>
        <TabPane tab="Market Snapshot" key="4">
          <CompetitionAnalysis />
        </TabPane>
        <TabPane tab="Buying Decision" key="5">
          <BuyingDecisionContainer key={this.state.currentTab} />
        </TabPane>
        <TabPane tab="Arrival Summary" key="6">
          <Summary isTab key={this.state.currentTab} />
        </TabPane>
         {/*{<TabPane tab="Varietal Substitution" key="10">
          <VarietalSubstitution />
        </TabPane>}*/}
        {canUserEdit(
          this.props.userData,
          null,
          FeatureCodes.REPORTS_PURCHASE_EFFICIENCY
        ) ||
          canUserEdit(
            this.props.userData,
            null,
            FeatureCodes.REPORTS_CONSOLIDATED
          ) ||
          canUserEdit(
            this.props.userData,
            null,
            FeatureCodes.REPORTS_INTRA_DAY_MANDI
          ) ||
          canUserEdit(
            this.props.userData,
            null,
            FeatureCodes.REPORTS_PRICE_TREND
          ) ||
          canUserEdit(
            this.props.userData,
            null,
            FeatureCodes.REPORTS_USER_ADOPTION
          ) ? (
          <TabPane tab="Report" key="7">
            <Report />
          </TabPane>
        ) : null}

        {canUserEdit(this.props.userData, null, FeatureCodes.ADMIN_ACCESS) ||
          canUserEdit(
            this.props.userData,
            null,
            FeatureCodes.ALERTS_PUBLISHING
          ) ||
          canUserEdit(this.props.userData, null, FeatureCodes.ALERTS_GROUP) ? (
          <TabPane tab="Admin" key="8">
            <AdminTabs />
          </TabPane>
        ) : null}
        {/*{this.props.userData.roleID === 12 && <TabPane tab="Master" key="11">*/}
        {canUserEdit(
          this.props.userData,
          null,
          FeatureCodes.MASTER_SCREEN_VIEW
        )?(
        <TabPane tab="Master" key="11">
          <MasterTabs />
        </TabPane>
        ):null
        }
        {canUserEdit(
          this.props.userData,
          null,
          FeatureCodes.WEEKLY_MODEL_EDIT
        ) ||
          canUserEdit(
            this.props.userData,
            null,
            FeatureCodes.WEEKLY_MODEL_VIEW
          ) ||
          canUserEdit(
            this.props.userData,
            null,
            FeatureCodes.MONTHLY_MODEL_FORECAST_EDIT
          ) ||
          canUserEdit(
            this.props.userData,
            null,
            FeatureCodes.MONTHLY_MODEL_FORECAST_VIEW
          ) ||
          canUserEdit(
            this.props.userData,
            null,
            FeatureCodes.MONTHLY_CONSTRAINTS
          ) ? (
          <TabPane tab="Forecast" key="9">
            <ForecastTabs />
          </TabPane>
        ) : null}
      </Tabs>
    );
    function callback(key) {
    }
  }
}

const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    collapseSidemenu: () => dispatch(collapseSidemenu()),

    setAccessToken: (token) => dispatch(setAccessToken(token)),
    setRefreshToken: (token) => dispatch(setRefreshToken(token)),

    setSummaryVisibility: (isEnabled) =>
      dispatch(setSummaryVisibility(isEnabled)),
    setCurrentArrivalScreen: (screenName) =>
      dispatch(setCurrentArrivalScreen(screenName)),
    setUserLoggedStatus: (status) => dispatch(setUserLoggedStatus(status)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MainContent)
);
