import React from "react";
import "./annual-factory-consumption-master.page";
import { Component } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUserData,selectMSMessageRBAC } from "../../redux/user/user.selectors";
import { canUserEdit,getUserMSRBACMessage } from "../../auth/utils";
import { FeatureCodes } from "../../auth/data";
import {
  Table,
  Spin,
  Input,
  Select,
  Button,
  message,
  Form,
  Modal,
  Radio,
} from "antd";

import API from "../../api";
const { Search } = Input;
const { Option } = Select;
/**
 * @description Component for user management tab
 *
 * @component
 */
class AnualFactoryConsumptionMaster extends Component {
  state = {
    isModalVisible: false,
    isAdd: false,
    currentRecord: null,
    userBranches: null,
    searchText: null,
    searchUserType: 0,
    varietyList: [],
    mandiRegionList: [],
    monthList: [],
    factoryList: [],
    hubBranchList: [],
    isSpinnerOnLoad: false,
    isSubmitted: false,
    newData: {
      is_active: true,
      Annual_Consumption_ID: undefined,
      Crushing_Plan_In_MT: undefined,
      Factory_Name: "",
      Variety: "",
      month_id: undefined,
      month_name: "",
      variety_id: undefined,
      variety_name: "",
      isEdit: false,
    },
    rowData: undefined,
    selectedRegionValue: undefined,
    selectedRegionName: "",
    selectedBranchValue: undefined,
    selectedBranchName: "",
    selectedBranchList: [],
    selectedRegionList: [],
    testvalue: undefined,
    holidayTableData: [],
  };
  componentDidMount() {
    this.setState({ isSpinnerOnLoad: true });
    //    this.getAnualFactoryConsumptionMaster();
    this.getvarietyList();
    //  this.getMonthList();
    //  this.getFactoryList();
    // this.getHubRegion();
    // this.getHubBranch();
  }

  getvarietyList = async () => {
    this.setState({ isSpinnerOnLoad: true });
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    };
    //TODO: change api
    await API.get("/varieties", { headers: headers })
      .then((roleData) => {
        this.setState(
          {
            varietyList: roleData.data.data.filter(
              (value, index, array) =>
                array.findIndex(
                  (obj) => obj.variety_id === value.variety_id
                ) === index
            ),
          },
          () => this.getMonthList()
        );
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400) message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };

  getMonthList = async () => {
    this.setState({ isSpinnerOnLoad: true });
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    };

    await API.get("/list_month", { headers: headers })
      .then((hubList) => {
        this.setState({
          monthList: hubList.data.data,
          isSpinnerOnLoad: false,
        },
          () => this.getFactoryList()
        );
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };

  getFactoryList = async () => {
    this.setState({ isSpinnerOnLoad: true });
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    };

    await API.get("/list_factory", { headers: headers })
      .then((hubList) => {
        this.setState({
          factoryList: hubList.data.data,
          isSpinnerOnLoad: false,
        },
          () => this.getAnualFactoryConsumptionMaster()
        );
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };

  getAnualFactoryConsumptionMaster = async () => {
    this.setState({ isSpinnerOnLoad: true });
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    };

    await API.get("/factory_con_plan", { headers: headers })
      .then((varietyResponse) => {

        varietyResponse.data.data.map((dataDetailItem, index) => {
          dataDetailItem.key = index;
          dataDetailItem.isEdit = false;
        });
        this.setState(
          {
            holidayTableData: varietyResponse.data.data,
            // InflationMasterTableData:[],
          },
          () => this.setState({ isSpinnerOnLoad: false, isAdd: true })
        );

      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400) message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };

  setTableData = () => {
    if (this.state.holidayTableData !== undefined || this.state.holidayTableData.length >= 0) {
      const userTableData = this.state.holidayTableData.map((user, index) => {
        return {
          key: user.key,
          Annual_Consumption_ID: user.Annual_Consumption_ID,
          Crushing_Plan_In_MT: user.Crushing_Plan_In_MT,
          Factory_Name: user.Factory_Name,
          Variety: user.Variety,
          month_id: user.month_id,
          month_name: user.month_name,
          variety_id: user.variety_id,
          variety_name: user.variety_name,
          isEdit: user.isEdit,
        };
      });

      let dataAfterSearch = userTableData;

      if (this.state.searchText) {
        dataAfterSearch = dataAfterSearch.filter(
          (item) =>
            (item.month_name !== null &&
              item.month_name
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase())) ||
            (item.Factory_Name !== null &&
              item.Factory_Name
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase())) ||
          (item.variety_name !== null &&
            item.variety_name
              .toLowerCase()
              .includes(this.state.searchText.toLowerCase())) 
        );
      }

      return dataAfterSearch;
    }
    else {
      return this.state.holidayTableData;
    }
  };

  submitTableRowData = (row, index, isPopup) => {
    this.setState({ isSpinnerOnLoad: true, isSubmitted: true });
    let warningmessage = "";
    if (row.month_name === null || row.month_name === undefined || row.month_name === "") {
      warningmessage = "Please select Month";
    } else if (row.Factory_Name === null || row.Factory_Name === undefined || row.Factory_Name === "") {
      warningmessage = "Please select Factory";
    } else if (row.variety_name === null || row.variety_name === undefined || row.variety_name === "") {
      warningmessage = "Please select Variety";
    } else if (row.Crushing_Plan_In_MT === null || row.Crushing_Plan_In_MT === undefined ||
      row.Crushing_Plan_In_MT === "" || row.Crushing_Plan_In_MT === 0) {
      warningmessage = "Please enter Crushing Plan(MT)";
    }

    if (
      warningmessage === "" ||
      warningmessage === undefined ||
      warningmessage === null
    ) {
      this.setState({ isSpinnerOnLoad: true });
      this.setState({ isSubmitted: true })
      let data = {

        Annual_Consumption_ID: row.Annual_Consumption_ID,
        Crushing_Plan_In_MT: row.Crushing_Plan_In_MT,
        Factory_Name: row.Factory_Name,
        Variety: row.Variety,
        Month: row.month_id,
        month_name: row.month_name,
        variety_id: row.variety_id,
        variety_name: row.variety_name,
      };

      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      };

      API.put("/factory_con_plan", data, {
        headers: headers,
      })
        .then((response) => {
          if (response.status === 200 && response.data.status) {
            message.info(response.data.message, 5);
            this.setModalVisibility(false);
            this.getAnualFactoryConsumptionMaster();
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
          } else if (!response.data.status) {
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
            message.error(response.data.message, 5);
            // this.setModalVisibility(false);
          } else {
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
            message.error("Please try again", 5);
          }
        })
        .catch((err) => {
          if (err) {
            if (err.response && err.response.status === 400) message.error("Something went wrong. Please try again later.", 5);
            else {
              message.error("Something went wrong.. Please try again later.", 5);
            }
          } else {
            message.error("Something went wrong... Please try again later.", 5);
          }
          this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
          // this.setModalVisibility(false);
        });
    } else {
      this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
      message.error(warningmessage, 5);
    }
  };

  setModalVisibility = (status) => {
    if (status) {
      const nData = {
        is_active: true,
        Annual_Consumption_ID: undefined,
        Crushing_Plan_In_MT: undefined,
        Factory_Name: "",
        Variety: "",
        month_id: undefined,
        month_name: "",
        variety_id: undefined,
        variety_name: "",
        isEdit: false,
      };

      this.setState({ newData: nData });
    }

    this.setState({ isModalVisible: status });
  };

  TableColumnHeader = (props) => {
    const { title, subtitle } = props;
    return (
      <div className="column-header-container">
        <div
          style={{
            color: "white",
            fontSize: "12px",
            whiteSpace: "nowrap",
            fontWeight: "normal",
            textAlign: "left",
          }}
        >
          {title}
        </div>
        <div
          style={{
            color: "#cbdfff",
            fontSize: "11px",
            fontWeight: "normal",
            textAlign: "left",
            // subtitle && title.length < subtitle.length ? "left" : "center"
          }}
        >
          {subtitle}
        </div>
      </div>
    );
  };

  createFilters = (label) => {
    let filterData = this.state.holidayTableData;
    //#region remove duplicate objects
    let uniqueFilterData = [];

    filterData.map((mainItem) =>
      uniqueFilterData.filter((item) => item[label] === mainItem[label])
        .length > 0
        ? null
        : uniqueFilterData.push(mainItem)
    );

    uniqueFilterData = uniqueFilterData.map((item) => {
      return {
        text: item[label],
        value: item[label],
      };
    });


    uniqueFilterData.sort((a, b) => a.text.localeCompare(b.text));
    //#endregion

    return uniqueFilterData;
  };

  handleDropdownChange = (value, row, target, index, optionlabel, isPopup) => {
    let a;

    if (isPopup === false) {
      a = this.state.holidayTableData;

      if (target === "is_active") {
        a[row.key].is_active = Boolean(value);
      } else if (target === "month_name") {
        a[row.key].month_name = optionlabel;
        a[row.key].month_id = value;
      } else if (target === "Factory_Name") {
        a[row.key].Factory_Name = optionlabel;
        a[row.key].Factory_id = value;
      } else if (target === "variety_name") {
        a[row.key].variety_name = optionlabel;
        a[row.key].variety_id = value;
        a[row.key].Variety = value;
      }
      this.setState({ holidayTableData: a });
    } else {
      a = this.state.newData;

      if (target === "is_active") {
        a.is_active = Boolean(value);
      } else if (target === "month_name") {
        a.month_name = optionlabel;
        a.month_id = value;
      } else if (target === "Factory_Name") {
        a.Factory_Name = optionlabel;
        a.Factory_id = value;
      } else if (target === "variety_name") {
        a.variety_name = optionlabel;
        a.variety_id = value;
        a.Variety = value;
      }
      this.setState({ newData: a });
    }
  };

  handleChange = (e, row, index, name, isPopup) => {
    e.target.name = name;

    var alphaNumericRegex = /^[a-zA-Z0-9]*$/;
    var numberRegex = /^[0-9.]+$/;
    let a;

    if (isPopup === false) {
      a = this.state.holidayTableData;

      if (name === "Crushing_Plan_In_MT") {
        a[row.key].Crushing_Plan_In_MT = e.target.value;
      }

      this.setState({ holidayTableData: a });
    } else {
      a = this.state.newData;

      if (name === "Crushing_Plan_In_MT") {
        a.Crushing_Plan_In_MT = e.target.value;
      }

      this.setState({ newData: a });
    }
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  handleEditChange = (row, index) => {
    console.log("user role id is ", this.props.userData.roleID );
    
    this.setState({ rowData: row });
    let tempData = this.state.holidayTableData.map((item) => {

      if (item.key === row.key) {
        return {
          ...item,
          isEdit: true
        }
      } else {
        return item;
      }
    })
    this.setState({ holidayTableData: tempData });
  
  };

  handleCancelClick = (rowData, index) => {
    let tempData = this.state.holidayTableData.map((item) => {
      if (item.key === rowData.key) {
        return this.state.rowData
      } else {
        return item;
      }
    })
    this.setState({ holidayTableData: tempData });
  };


  render() {
    const userTableProps = {
      pagination: false,
      size: "default",
      bordered: true,
      scroll: { x: 200, y: window.innerHeight - 250 },
    };

    return (
      <div
        className="mandi-master-container"
        style={{
          height: window.innerHeight - 85,
          marginTop: "-45px",
        }}
      >
        <div className="top-container">

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row-reverse",
              placeItems: "flex-end",
              marginRight: "25px",
              marginBottom: "10px",
            }}
          >
            <Search
              disabled={!this.state.isAdd}
              placeholder="Search"
              allowClear
              onChange={(e) => this.setState({ searchText: e.target.value })}
              style={{ width: "25%" }}
            />
            <Button
              className="add-master-button"
              style={{
                height: "25px",
                width: "70px",
                backgroundColor: "#0b133d",
                color: "white",
              }}
              disabled={!this.state.isAdd || !canUserEdit(
                this.props.userData,
                null,
                FeatureCodes.MS_ANNUAL_FAC_CONS_EDIT
              )}
              onClick={() => this.setModalVisibility(true)}
            >
              &#10010; Add
            </Button>
          </div>
        </div>

        <div>
        <div
        style={{
          marginLeft: "25px",
          marginTop: "-8px",
          fontSize: "14px",
          color: "red",
          display: "flex",
          float: "left",
        }}
      > {getUserMSRBACMessage(this.props.messageAccess,FeatureCodes.MS_ANNUAL_FAC_CONS_EDIT)}
      </div>
        <div
          style={{
            marginRight: "20px",
            marginTop: "-8px",
            fontSize: "15px",
            color: "#0B133D",
            display: "flex",
            float: "right",
          }}
        >Total number of records:&nbsp;
          <b>{this.state.holidayTableData && this.setTableData().length}</b>
        </div>
        </div>
        
        <Spin
          spinning={this.state.isSpinnerOnLoad}
          size={"large"}
          tip="Loading..."
          style={{ alignSelf: "center", zIndex: 9999, marginTop: "20px" }}
        >
          <Modal
            className="add-master-modal"
            title={"Add Annual Factory Consumption"}
            onCancel={this.handleCancel}
            footer={false}
            visible={this.state.isModalVisible}
            destroyOnClose={true}
          >
            <div className="master-form-container">
              <Form
                layout="horizontal"
                //   onValuesChange={onFormLayoutChange}
                size={"small"}
              // onSubmit={submitTableRowData(this.state.newData)}
              >
                <div className="form-top-container1">
                  <div
                    className="checkbox-group1"
                    style={{
                      display: "inline-block",
                      width: "60%",
                      marginRight: 40,
                    }}
                  >
                    <Form.Item label="Month">
                      <Select
                        showSearch
                        optionFilterProp="children"
                        className="remarks-select"
                        name="month"
                        defaultValue={this.state.newData.month_name}
                        value={this.state.newData.month_name}
                        onSelect={(value, option) =>
                          this.handleDropdownChange(
                            value,
                            this.state.newData,
                            "month_name",
                            -0,
                            option.props.children,
                            true
                          )
                        }
                      >
                        {this.state.monthList &&
                          this.state.monthList.length &&
                          this.state.monthList.map((hubItem, idx) => (
                            <Option
                              key={hubItem.month_id}
                              value={hubItem.month_id}
                            >
                              {hubItem.month_name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </div>

                  <div
                    className="checkbox-group1"
                    style={{
                      display: "inline-block",
                      width: "60%",
                      marginRight: 40,
                    }}
                  >
                    <Form.Item label="Factory">
                      <Select
                        showSearch
                        optionFilterProp="children"
                        className="remarks-select"
                        name="Factory_Name"
                        defaultValue={this.state.newData.Factory_Name}
                        value={this.state.newData.Factory_Name}
                        onSelect={(value, option) =>
                          this.handleDropdownChange(
                            value,
                            this.state.newData,
                            "Factory_Name",
                            -0,
                            option.props.children,
                            true
                          )
                        }
                      >
                        {this.state.factoryList &&
                          this.state.factoryList.length &&
                          this.state.factoryList.map((hubItem, idx) => (
                            <Option
                              key={hubItem.factory_id}
                              value={hubItem.factory_id}
                            >
                              {hubItem.factory_name}
                            </Option>
                          ))}
                      </Select>

                    </Form.Item>
                  </div>

                  <div
                    className="checkbox-group1"
                    style={{
                      height: "45px",
                      display: "inline-block",
                      width: "60%",
                      marginRight: 40,
                    }}
                  >
                    <Form.Item label="Variety">
                      <Select
                        showSearch
                        optionFilterProp="children"
                        className="remarks-select"
                        name="variety_name"
                        defaultValue={this.state.newData.variety_name}
                        value={this.state.newData.variety_name}
                        onSelect={(value, option) =>
                          this.handleDropdownChange(
                            value,
                            this.state.newData,
                            "variety_name",
                            -0,
                            option.props.children,
                            true
                          )
                        }
                      >
                        {this.state.varietyList &&
                          this.state.varietyList.length &&
                          this.state.varietyList.map((hubItem, idx) => (
                            <Option
                              key={hubItem.variety_id}
                              value={hubItem.variety_description}
                            >
                              {hubItem.variety_name}
                            </Option>
                          ))}
                      </Select>

                    </Form.Item>
                  </div>

                  <div
                    className="checkbox-group1"
                    style={{
                      height: "45px",
                      display: "inline-block",
                      width: "60%",
                      marginRight: 40,
                    }}
                  >
                    <Form.Item label="Crushing Plan(MT)">
                      <Input
                        type="number"
                        style={{ marginRight: "2px" }}
                        maxLength={100}
                        min="0"
                        value={this.state.newData.Crushing_Plan_In_MT}
                        name="Crushing_Plan_In_MT"
                        onChange={(e) =>
                          this.handleChange(
                            e,
                            this.state.newData,
                            0,
                            "Crushing_Plan_In_MT",
                            true
                          )
                        }
                      />
                    </Form.Item>
                  </div>

                </div>

                <div className="form-bottom-container" style={{ marginTop: "10px" }}>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={this.state.isSubmitted}
                      onClick={() =>
                        this.submitTableRowData(this.state.newData, 0, true)
                      }
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </Modal>


          <div className="table-container1" style={{ maxHeight: "400px", width: "99%", margin: "6px 0px" }}>
            <Table
              {...userTableProps}
              columns={[

                {
                  title: "Month",
                  dataIndex: "month_name",
                  key: "month_name",
                  width: 125,
                  filters: this.createFilters("month_name"),
                  onFilter: (value, record) =>
                    record.month_name.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.month_name.localeCompare(b.month_name),
                  render: (record, rowRecord) => {
                    return (
                      <>
                        <div
                          style={{ marginLeft: "-15px", marginRight: "-10px" }}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            disabled={true}//{!rowRecord.isEdit}
                            className="remarks-select"
                            name="month_name"
                            defaultValue={rowRecord.month_name}
                            value={rowRecord.month_name}
                            onSelect={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "month_name",
                                -0,
                                option.props.children,
                                false
                              )
                            }
                          >
                            {this.state.monthList &&
                              this.state.monthList.length &&
                              this.state.monthList.map((hubItem, idx) => (
                                <Option
                                  key={hubItem.month_id}
                                  value={hubItem.month_id}
                                >
                                  {hubItem.month_name}
                                </Option>
                              ))}
                          </Select>
                        </div>
                      </>
                    );
                  },
                },
                {
                  title: "Factory",
                  dataIndex: "Factory_Name",
                  key: "Factory_Name",
                  width: 125,
                  filters: this.createFilters("Factory_Name"),
                  onFilter: (value, record) =>
                    record.Factory_Name.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.Factory_Name.localeCompare(b.Factory_Name),
                  render: (record, rowRecord) => {
                    return (
                      <>
                        <div
                          style={{ marginLeft: "-15px", marginRight: "-10px" }}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            disabled={true}//{!rowRecord.isEdit}
                            className="remarks-select"
                            name="Factory_Name"
                            defaultValue={rowRecord.Factory_Name}
                            value={rowRecord.Factory_Name}
                            onSelect={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "Factory_Name",
                                -0,
                                option.props.children,
                                false
                              )
                            }
                          >
                            {this.state.factoryList &&
                              this.state.factoryList.length &&
                              this.state.factoryList.map((hubItem, idx) => (
                                <Option
                                  key={hubItem.factory_id}
                                  value={hubItem.factory_id}
                                >
                                  {hubItem.factory_name}
                                </Option>
                              ))}
                          </Select>
                        </div>
                      </>
                    );
                  },
                },
                {
                  title: "Variety",
                  dataIndex: "variety_name",
                  key: "variety_name",
                  width: 125,
                  filters: this.createFilters("variety_name"),
                  onFilter: (value, record) =>
                    record.variety_name.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.variety_name.localeCompare(b.variety_name),
                  render: (record, rowRecord) => {
                    return (
                      <>
                        <div
                          style={{ marginLeft: "-15px", marginRight: "-10px" }}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            disabled={true}//{!rowRecord.isEdit}
                            className="remarks-select"
                            name="variety_name"
                            defaultValue={rowRecord.variety_name}
                            value={rowRecord.variety_name}
                            onSelect={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "variety_name",
                                -0,
                                option.props.children,
                                false
                              )
                            }
                          >
                            {this.state.varietyList &&
                              this.state.varietyList.length &&
                              this.state.varietyList.map((hubItem, idx) => (
                                <Option
                                  key={hubItem.variety_id}
                                  value={hubItem.variety_description}
                                >
                                  {hubItem.variety_name}
                                </Option>
                              ))}
                          </Select>
                        </div>
                      </>
                    );
                  },
                },
                {
                  key: "Crushing_Plan_In_MT",
                  title: "Crushing Plan(MT)",
                  dataIndex: "Crushing_Plan_In_MT",
                  width: 150,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.Crushing_Plan_In_MT - b.Crushing_Plan_In_MT,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            type="number"
                            disabled={!rowRecord.isEdit}
                            style={{ marginRight: "2px" }}
                            maxLength={100}
                            min="0"
                            value={rowRecord.Crushing_Plan_In_MT}
                            name="Crushing_Plan_In_MT"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "Crushing_Plan_In_MT",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },
                {
                  title: "Action",
                  dataIndex: "Submit",
                  key: "Submit",
                  width: 80,
                  visibility: false,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ display: "inline-flex", width: "100%" }}>
                          <Button
                          className={!canUserEdit(
                            this.props.userData,
                            null,
                            FeatureCodes.MS_ANNUAL_FAC_CONS_EDIT
                          ) ? "disabledBtnClass" : "btnClass"}
                            disabled={!canUserEdit(
                              this.props.userData,
                              null,
                              FeatureCodes.MS_ANNUAL_FAC_CONS_EDIT
                            )}
                            type="primary"
                            size={"small"}
                            style={{
                              marginLeft: 5,
                              alignSelf: "center",
                              padding: "0px 0px 2px",
                              height: 31,
                              backgroundColor: "#20346a",
                              color: "white",
                              borderRadius: 15,
                            }}
                            block
                            htmlType="submit"
                            onClick={() =>
                              rowRecord.isEdit
                                ? this.submitTableRowData(rowRecord, index, false)
                                : this.handleEditChange(rowRecord, index)
                            }
                          >
                            {" "}
                            {rowRecord.isEdit ? "Submit" : "Edit"}{" "}
                          </Button>


                          <Button
                            className={!rowRecord.isEdit ? "disabledBtnClass" : "btnClass"}
                            type="primary"
                            size={"small"}
                            style={{
                              marginLeft: 5,
                              alignSelf: "center",
                              color: "white",
                              borderRadius: 15,
                              padding: "0px 0px 2px",
                              height: 31,
                              backgroundColor: "#20346a",
                            }}
                            block
                            htmlType="submit"
                            disabled={!rowRecord.isEdit}
                            onClick={() => this.handleCancelClick(rowRecord, index)
                            }
                          >
                            Cancel
                          </Button>

                        </div>
                      </>
                    );
                  },
                },
              ]}
              dataSource={this.setTableData()}
            />
          </div>

         

        </Spin>
       
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
  messageAccess: selectMSMessageRBAC,
});

export default connect(mapStateToProps)(AnualFactoryConsumptionMaster);

// export default AnualFactoryConsumptionMaster;
