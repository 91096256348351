import React from "react";
import "./home.styles.css";

import { Switch, message, Spin } from "antd";

// import WheatField from "../../assets/wheatfield.jpg";
import DashboardMap from "../../components/dashboard-map/dashboard-map.component";
import { Component } from "react";

import API from "../../api";
import moment from "moment";

import { setColor } from "../../components/dashboard-map-marker/dashboard-map-marker.component";
import { LoadScript, LoadScriptNext } from "@react-google-maps/api";

/**
 * @description the home dashboard component.
 * displays the geographical representation of mandis and traders and their
 * current state along with a summary of each branch.
 *
 * @component
 */
class Home extends Component {
  state = {
    isSpinnerEnabled: false,
    isSubSpinnerEnabled: false,
    isMandiSelected: true,
    branchData: [],
    markerData: [],
  };

  setSpinner = (status, isMain = true) => {
    isMain
      ? this.setState({ isSpinnerEnabled: status })
      : this.setState({ isSubSpinnerEnabled: status });
  };

  // used for getting data for the map
  getDataForMap = (isMandi) => {
    this.setSpinner(true);
    this.setSpinner(true, false);

    //#region get data for map
    // useEffect(() => {
    // localStorage.removeItem("activeParams");
    // localStorage.removeItem("upcomingParams");
    // localStorage.removeItem("myBidsParams");
    // localStorage.removeItem("myWinsParams");
    // localStorage.removeItem("activeAuctionID");
    // localStorage.removeItem("tradeLimitParams");
    // getBranchVarietyFn();
    // history.push("/login");
    // }, []);

    API.get(`/dashboard?arrival_type=${isMandi ? "mandi" : "FOR"}`)
      .then((markerResponse) => {
        if (markerResponse.data.status) {
          this.setState({ markerData: markerResponse.data.data.map_dashboard });
        } else {
          this.setState({ isMandiSelected: !isMandi });
          message.warning(
            "Could not retrieve the map information. Please try again."
          );
        }
        this.setSpinner(false);
      })
      .catch((err) => {
        this.setSpinner(false);
        this.setState({ isMandiSelected: !isMandi });
        message.error(
          "Could not retrieve the map information. Please check your network connectivity."
        );
      });

    //#endregion

    //#region get data for branches
    const currentDate = moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD");
    API.get(
      `/reports/user_adoption?arrival_type=${isMandi ? "mandi" : "FOR"
      }&start_date=${currentDate}&end_date=${currentDate}&user_branches=${true}`,

    )
      .then((branchResponse) => {
        if (branchResponse.data.status) {
          this.setState({
            branchData: branchResponse.data.data.arrival_user_adoption,
          });
        } else {
          this.setState({ isMandiSelected: !isMandi });
          message.warning(
            "Could not retrive the branch information. Please try again."
          );
        }
        this.setSpinner(false, false);
      })
      .catch((err) => {
        this.setSpinner(false, false);
        this.setState({ isMandiSelected: !isMandi });
        message.error(
          "Could not retrieve the branch information. Please check your network connectivity."
        );
      });

    //#endregion
  };

  waitUntilLogin = () => {
    if (localStorage.getItem("accessToken") != null) {
      this.getDataForMap(true);
    } else {
      setTimeout(() => this.waitUntilLogin(), 100);
    }
  };

  componentDidMount() {
    this.waitUntilLogin();
  }

  render() {
    const handleSelection = (isMandi) => {
      this.setState({ isMandiSelected: isMandi });
      this.getDataForMap(isMandi);
    };

    const BranchItem = ({ name, percentage, color }) => {
      return (
        <div className="branch-item">
          <div className="left">
            <div className="round" style={{ background: `${color}` }} />
            <div className="name">{name}</div>
          </div>
          <div className="value">{(percentage * 100).toFixed(2)}&nbsp;%</div>
        </div>
      );
    };

    return (
      <Spin spinning={this.state.isSpinnerEnabled} size="large">
        <div className="dashboard">
          <div className="branch-info">
            <Spin spinning={this.state.isSubSpinnerEnabled}>
              <div className="branches">
                {this.state.branchData.map((item) => (
                  <BranchItem
                    key={item.id}
                    name={item.name}
                    percentage={item.arrival_user_adoption}
                    color={setColor(item.name)}
                  />
                ))}
              </div>
            </Spin>

            <div className="switch-container home">
              <div className="switch">
                <div className="tag">Mandi</div>
                <Switch
                  checked={!this.state.isMandiSelected} // checked true --> FOR (we default to mandi, hence the ! symbol)
                  onChange={(e) => handleSelection(!e)}
                />
                <div className="tag">FOR</div>
              </div>
            </div>
          </div>

          <div className="dashboard-map-container">
            <DashboardMap
              isMandi={this.state.isMandiSelected}
              markers={this.state.markerData}
              centerPosition={{ lat: 24.228942, lng: 78.877741 }}
              zoomValue={6}
            />
          </div>
        </div>
      </Spin>
    );
  }
}

export default Home;
