import React from "react";
import "./weekly-projection-display-card.styles.css";

/**
 * @component
 * @description Card component to display the weekly projection data.
 * @property {string} title the heading of the card.
 * @property {number} price the projected price for the week.
 * @property {number} minPrice the projected minimum price range limit for the week.
 * @property {number} maxPrice the projected maximum price range limit for the week.
 * @property {number} allocationPercentage the projected percentage allocation for the week.
 */
const WeeklyDisplayCard = (props) => {
  const { title, price, minPrice, maxPrice, allocationPercentage } = props;
  return (
    <div className="weekly-display-card-container">
      <div className="title">{title}</div>
      <div className="bottom-line" />
      <div className="display-info">{`${price} ₹/MT`}</div>
      <div className="display-info">{`${minPrice} ₹/MT - ${maxPrice} ₹/MT`}</div>
      <div className="display-info">{`${allocationPercentage}%`}</div>
    </div>
  );
};

export default WeeklyDisplayCard;
