import React from "react";
import "./monthly-volume.styles.css";
import { Component } from "react";

import { Button, Modal, message, Spin,Select } from "antd";

import moment from "moment";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectAllBranches } from "../../redux/user/user.selectors";
import { selectAllVarietyInfo } from "../../redux/arrival-entry/arrival-entry.selectors";

import { selectUserData } from "../../redux/user/user.selectors";

import TemporalExpandableTable from "../../components/temporal-expandable-table/temporal-expandable-table.component";
import CustomSelectTemporal from "../../components/custom-select-temporal/custom-select-temporal.component";

import API from "../../api";
import { canUserEdit } from "../../auth/utils";
import { FeatureCodes } from "../../auth/data";
const { Option } = Select;
// import { getVarietyMaxPrice, getVarietyMinPrice } from "../../auth/utils";

/**
 * @component
 * @description The monthly volume tab component. Contains the monthly forecast values and options to override.
 */
class MonthlyVolume extends Component {
  state = {
    isLoading: false,
    isConstrainedSelected: true,
    isModalVisible: false,
    filters: [],
    temporalData: [],
    currentParams: null,
    userInfo: null,
    yearList:[],
    selectedYearValue:undefined,
    selectedMonthID: undefined,
    selectedrunCount:undefined,
  };

  setLoadingStatus = (status) => {
    this.setState({ isLoading: status });
  };

  setSpinnerStatus = (status) => {
    this.setState({ isLoading: status });
  };

  componentDidMount() {
    //#region call the api to get the filter values.
    this.setLoadingStatus(true);
    this.getYearList();

    API.get("/recommendations/monthly/volume",)
      .then((filterResponse) => {
        if (filterResponse.data.status) {
          this.setLoadingStatus(false);
          if (filterResponse.data.data.filter.length !== 0) {
            this.setState({
              filters: filterResponse.data.data.filter,
              selectedMonthID: filterResponse.data.data.filter[0].month,
              selectedrunCount: filterResponse.data.data.filter[0].run_cnt,
           
            });

            //#region setting the first temporal data

            //set the current params as first filter params.

            this.setState({
              currentParams: {
                month: filterResponse.data.data.filter[0].month,
                run_cnt: filterResponse.data.data.filter[0].run_cnt,
                type: "C",
              },
            });

            this.setState({ userInfo: filterResponse.data.data.user_info });

            if (filterResponse.data.data.temporal_volume_data.length !== 0) {
              const modifiedTemporalData = filterResponse.data.data.temporal_volume_data.map(
                (variety) => {
                  return {
                    ...variety,
                    branches: variety.branches.map((branch) => {
                      return {
                        key: variety.variety_id + "_" + branch.branch_id,
                        branch_id: branch.branch_id,

                        variety_id: variety.variety_id,
                        branch: branch.state,
                        feb: {
                          month_id: 2,
                          current: branch.current.feb,
                          planned: branch.planned.feb,
                          suggested: branch.suggested.feb,
                        },
                        march: {
                          month_id: 3,
                          current: branch.current.march,
                          planned: branch.planned.march,
                          suggested: branch.suggested.march,
                        },
                        april: {
                          month_id: 4,
                          current: branch.current.april,
                          planned: branch.planned.april,
                          suggested: branch.suggested.april,
                        },
                        may: {
                          month_id: 5,
                          current: branch.current.may,
                          planned: branch.planned.may,
                          suggested: branch.suggested.may,
                        },
                        june: {
                          month_id: 6,
                          current: branch.current.june,
                          planned: branch.planned.june,
                          suggested: branch.suggested.june,
                        },
                        post_season: {
                          month_id: 7,
                          current: branch.current.post_season,
                          planned: branch.planned.post_season,
                          suggested: branch.suggested.post_season,
                        },
                        remark: {
                          current: branch.current.remarks,
                          planned: branch.planned.remarks,
                          // suggested: branch.suggested.remarks,
                        },
                        is_scheduled: {
                          current: branch.current.is_scheduled,
                          planned: branch.planned.is_scheduled,
                          // suggested: branch.suggested.is_scheduled,
                        },
                        mvc_id: {
                          current: branch.current.mvc_id,
                          planned: branch.planned.mvc_id,
                          // suggested: branch.suggested.mvc_id,
                        },
                        action: {
                          isEditable: false,
                        },
                      };
                    }),
                  };
                }
              );


              this.setState({ temporalData: modifiedTemporalData });
            } else {
              message.warning("No temporal model data available.");
            }

            //#endregion
          } else {
            this.setLoadingStatus(false);
            message.error("No Runs available");
          }
        } else {
          this.setLoadingStatus(false);
          message.error("No Runs available");
        }
      })
      .catch((err) => {
        message.error(
          "Could not retrieve model run details. Please check your network connectivity."
        );
      });

    //#endregion
  }

  getYearList = async () => {
    this.setSpinnerStatus(true);
    console.log("year list called ",);
    API.get("/recommendations/monthly/year")
    .then((filterResponse) => {
      if (filterResponse.data.status) {
        this.setSpinnerStatus(false);
        
          this.setState({
            yearList: filterResponse.data.data,
            selectedYearValue:filterResponse.data.data[0].year_id,
          });
        console.log("year list ", this.state.yearList, filterResponse.data.data);
      
      } else {
        this.setSpinnerStatus(false);
      }
    })
    .catch((err) => {
      this.setSpinnerStatus(false);
      message.error(
        "Could not retrieve model year. Please check your network connectivity."
      );
    });
  }

  render() {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    /**
     * @description Submit the overriden data for the selected model run.
     * @param {number} variety_id selected variety id.
     * @param {number} branch_id  selected branch id.
     * @memberof MonthlyVolume
     */
    const onTickButtonClicked = (variety_id, branch_id) => {
      let selectedRow = this.state.temporalData
        .find((variety) => variety.variety_id === variety_id)
        .branches.find((branch) => branch.branch_id === branch_id);

      //#region api to post the selected row. if successful, update the row and change edit status



      const dataToSend = {
        branch_id: branch_id,
        mvc_id: selectedRow.mvc_id.current,
        variety_id: variety_id,
        feb: selectedRow.feb.planned,
        march: selectedRow.march.planned,
        april: selectedRow.april.planned,
        may: selectedRow.may.planned,
        june: selectedRow.june.planned,
        post_season: selectedRow.post_season.planned,
        remarks: selectedRow.remark.planned,
      };

 
      const validatePost = () => {
        if (!dataToSend.remarks || !dataToSend.remarks.trim()) {
          message.error("Please enter a remark to submit.");
          return false;
        }

        return true;
      };

      if (validatePost()) {
        this.setLoadingStatus(true);

        let modifiedDataToSend = dataToSend;

        let modified = {};

        Object.keys(modifiedDataToSend).map((key) => {
          switch (key) {
            case "feb":
              if (modifiedDataToSend[key] !== selectedRow[key].current) {
                modified = { ...modified, 2: modifiedDataToSend[key] };
              }
              break;
            case "march":
              if (modifiedDataToSend[key] !== selectedRow[key].current) {
                modified = { ...modified, 3: modifiedDataToSend[key] };
              }
              break;
            case "april":
              if (modifiedDataToSend[key] !== selectedRow[key].current) {
                modified = { ...modified, 4: modifiedDataToSend[key] };
              }
              break;
            case "may":
              if (modifiedDataToSend[key] !== selectedRow[key].current) {
                modified = { ...modified, 5: modifiedDataToSend[key] };
              }
              break;
            case "june":
              if (modifiedDataToSend[key] !== selectedRow[key].current) {
                modified = { ...modified, 6: modifiedDataToSend[key] };
              }
              break;
            case "post_season":
              if (modifiedDataToSend[key] !== selectedRow[key].current) {
                modified = { ...modified, 7: modifiedDataToSend[key] };
              }
              break;

            default:
              break;
          }
        });

        modifiedDataToSend = {
          branch: dataToSend.branch_id,
          run_month: this.state.selectedMonthID,
          remarks: dataToSend.remarks,
          variety: dataToSend.variety_id,
          modified: modified,
        };

  
        API.post("/recommendations/monthly/volume", modifiedDataToSend)
          .then((postResponse) => {
            if (postResponse.data.status) {
              this.setLoadingStatus(false);
  
              // save planned into current and make editable false.
              saveRow(
                variety_id,
                branch_id,
                postResponse.data.data.mvc_id,
                postResponse.data.data.remarks
              );

              message.success("Submitted Successfully.");
            } else {
              this.setLoadingStatus(false);
              message.warning(postResponse.data.message);
            }
          })
          .catch((err) => {
            this.setLoadingStatus(false);
             message.error(
              "Could not submit the row. Please check your network connectivity"
            );
          });
      }

      //#endregion
    };

    //change edit status of the row.
    /**
     * @description Change the edit status of a table row.
     * @param {number} variety_id selected variety id.
     * @param {number} branch_id  selected branch id.
     * @param {boolean} status the editable status of the current row.
     * @memberof MonthlyVolume
     */
    const changeEditStatus = (variety_id, branch_id, status) => {
      let tempTemporalData = this.state.temporalData;

      tempTemporalData = tempTemporalData.map((item) => {
        if (item.variety_id === variety_id) {
          return {
            ...item,
            branches: item.branches.map((branch) => {
              if (branch.branch_id === branch_id) {
                return {
                  ...branch,
                  action: { ...branch.action, isEditable: status },
                };
              }
              return branch;
            }),
          };
        }
        return item;
      });

      this.setState({ temporalData: tempTemporalData });
    };

    /**
     * @description reset the row from planned to current value and set edit to false.
     * @param {number} variety_id selected variety id.
     * @param {number} branch_id  selected branch id.
     * @memberof MonthlyVolume
     */
    const resetRow = (variety_id, branch_id) => {
      const tempData = this.state.temporalData;

      let resettedTemporalData = tempData.map((item) => {
        if (item.variety_id === variety_id) {
          return {
            ...item,
            branches: item.branches.map((branch) => {
              if (branch.branch_id === branch_id) {
                return {
                  ...branch,
                  feb: { ...branch.feb, planned: branch.feb.current },
                  march: {
                    ...branch.march,
                    planned: branch.march.current,
                  },
                  april: {
                    ...branch.april,
                    planned: branch.april.current,
                  },
                  may: { ...branch.may, planned: branch.may.current },
                  june: {
                    ...branch.june,
                    planned: branch.june.current,
                  },
                  post_season: {
                    ...branch.post_season,
                    planned: branch.post_season.current,
                  },
                  remark: { ...branch.remark, planned: branch.remark.current },
                  is_scheduled: {
                    ...branch.is_scheduled,
                    planned: branch.is_scheduled.current,
                  },
                  mvc_id: { ...branch.mvc_id, planned: branch.mvc_id.current },

                  action: { ...branch.action, isEditable: false },
                };
              }
              return branch;
            }),
          };
        }
        return item;
      });

   
      this.setState(
        (prevState) => ({ ...prevState, temporalData: resettedTemporalData }),
        () => console.log()
      );
    };

    /**
     * @description save the row to current from planned value and set edit to false.
     * @param {number} variety_id selected variety id.
     * @param {number} branch_id  selected branch id.
     * @memberof MonthlyVolume
     */
    const saveRow = (variety_id, branch_id, mvc_id, remark) => {
      const tempData = this.state.temporalData;

      let resettedTemporalData = tempData.map((item) => {
        if (item.variety_id === variety_id) {
          return {
            ...item,
            branches: item.branches.map((branch) => {
              if (branch.branch_id === branch_id) {
                return {
                  ...branch,
                  feb: { ...branch.feb, current: branch.feb.planned },
                  march: {
                    ...branch.march,
                    current: branch.march.planned,
                  },
                  april: {
                    ...branch.april,
                    current: branch.april.planned,
                  },
                  may: { ...branch.may, current: branch.may.planned },
                  june: {
                    ...branch.june,
                    current: branch.june.planned,
                  },
                  post_season: {
                    ...branch.post_season,
                    current: branch.post_season.planned,
                  },
                  remark: { ...branch.remark, current: remark },
                  is_scheduled: {
                    ...branch.is_scheduled,
                    current: branch.is_scheduled.planned,
                  },
                  mvc_id: { ...branch.mvc_id, current: mvc_id },

                  action: { ...branch.action, isEditable: false },
                };
              }
              return branch;
            }),
          };
        }
        return item;
      });

      console.log("RESETTED DATA : ", resettedTemporalData);

      this.setState(
        (prevState) => ({ ...prevState, temporalData: resettedTemporalData }),
        () => console.log("UPDATED:", this.state.temporalData)
      );
    };

    /**
     * @description store the planned value on onBlur into state.
     * @param {any} modifiedValue the changed value from the table
     * @param {number} variety_id selected variety id
     * @param {number} branch_id selected branch id
     * @param {string} month_name selected month name
     * @memberof MonthlyVolume
     */
    const onValueModified = (
      modifiedValue,
      variety_id,
      branch_id,
      month_name
    ) => {
      if (month_name) {
        // this a month value. change in the local state.
        // console.log(
        //   `value : ${modifiedValue} for variety_id : ${variety_id} for branch_id : ${branch_id} for month : ${month_name}`
        // );

        let tempTemporalData = this.state.temporalData;

        tempTemporalData = tempTemporalData.map((item) => {
          if (item.variety_id === variety_id) {
            return {
              ...item,
              branches: item.branches.map((branch) => {
                if (branch.branch_id === branch_id) {
                  return {
                    ...branch,
                    [month_name]: {
                      ...branch[month_name],
                      planned: modifiedValue ? parseInt(modifiedValue) : 0,
                    },
                  };
                }
                return branch;
              }),
            };
          }
          return item;
        });

        this.setState({ temporalData: tempTemporalData });
      } else {
        // this is a remark.change in the local state.
        // console.log(
        //   `Remark : ${modifiedValue} for variety_id : ${variety_id} for branch_id : ${branch_id} `
        // );

        let tempTemporalData = this.state.temporalData;

        tempTemporalData = tempTemporalData.map((item) => {
          if (item.variety_id === variety_id) {
            return {
              ...item,
              branches: item.branches.map((branch) => {
                if (branch.branch_id === branch_id) {
                  return {
                    ...branch,
                    remark: {
                      ...branch.remark,
                      planned: modifiedValue,
                    },
                  };
                }
                return branch;
              }),
            };
          }
          return item;
        });

        this.setState({ temporalData: tempTemporalData });
      }
    };

    //function to call api on select change.
    /**
     * @description Get the temporal monthly modal data for the selected model run.
     * @param {object} params parameters to be sent along with the get api.
     * @memberof MonthlyVolume
     */
    const getTemporalData = (params,year) => {
      this.setLoadingStatus(true);
      console.log("params :", params);
      this.setState({ selectedMonthID: params.month });
      this.setState({ selectedrunCount: params.run_cnt });
      
      //#region calling api to get the correntponding temporal data

      const temporalParams = {month:params.month,
        run_cnt:params.run_cnt,
        year:this.state.selectedYearValue};
      API.get("/recommendations/monthly/volume", {

        params: temporalParams,
      })
        .then((temporalResponse) => {
          if (temporalResponse.data.status) {
            this.setLoadingStatus(false);
            console.log(
              "Temporal data response : ",
              temporalResponse.data.data.temporal_volume_data
            );
            this.setState({
              filters: temporalResponse.data.data.filter,
            });
            if(temporalResponse.data.data.filter.length !== 0){
              this.setState({
                selectedMonthID: temporalResponse.data.data.filter[0].month,
                selectedrunCount: temporalResponse.data.data.filter[0].run_cnt,
              });
            }

            this.setState({ userInfo: temporalResponse.data.data.user_info });

            if (temporalResponse.data.data.temporal_volume_data.length !== 0) {
              const modifiedTemporalData = temporalResponse.data.data.temporal_volume_data.map(
                (variety) => {
                  return {
                    ...variety,

                    branches: variety.branches.map((branch) => {
                      return {
                        key: variety.variety_id + "_" + branch.branch_id,
                        branch_id: branch.branch_id,

                        variety_id: variety.variety_id,
                        branch: branch.state,
                        feb: {
                          month_id: 2,
                          current: branch.current.feb,
                          planned: branch.planned.feb,
                          suggested: branch.suggested.feb,
                        },
                        march: {
                          month_id: 3,
                          current: branch.current.march,
                          planned: branch.planned.march,
                          suggested: branch.suggested.march,
                        },
                        april: {
                          month_id: 4,
                          current: branch.current.april,
                          planned: branch.planned.april,
                          suggested: branch.suggested.april,
                        },
                        may: {
                          month_id: 5,
                          current: branch.current.may,
                          planned: branch.planned.may,
                          suggested: branch.suggested.may,
                        },
                        june: {
                          month_id: 6,
                          current: branch.current.june,
                          planned: branch.planned.june,
                          suggested: branch.suggested.june,
                        },
                        post_season: {
                          month_id: 7,
                          current: branch.current.post_season,
                          planned: branch.planned.post_season,
                          suggested: branch.suggested.post_season,
                        },
                        remark: {
                          current: branch.current.remarks,
                          planned: branch.planned.remarks,
                          // suggested: branch.suggested.remarks,
                        },
                        is_scheduled: {
                          current: branch.current.is_scheduled,
                          planned: branch.planned.is_scheduled,
                          // suggested: branch.suggested.is_scheduled,
                        },
                        mvc_id: {
                          current: branch.current.mvc_id,
                          planned: branch.planned.mvc_id,
                          // suggested: branch.suggested.mvc_id,
                        },
                        action: {
                          isEditable: false,
                        },
                      };
                    }),
                  };
                }
              );

              console.log("DATA :@$%#$%#@$%3 : ", modifiedTemporalData);

              this.setState({ temporalData: modifiedTemporalData });

              this.setState({
                currentParams: { month: params.month, run_cnt: params.run_cnt },
              });
            } else {
              this.setState({ temporalData: [] });
              message.warning("No temporal model data available.");
            }
          } else {
            this.setLoadingStatus(false);
            message.warning("No temporal model data available.");
          }
        })
        .catch((err) => {
          this.setLoadingStatus(false);
          console.log("ERROR GETTING TEMPORAL DATA : ERROR : ", err);
          message.error(
            "Could not retrieve temporal model data. Please check your network connectivity."
          );
        });
      //#endregion
    };

    /**
     * @description Generate the values for the model run filter dropdown.
     * @param {number} monthCount
     * @param {number} runCount
     * @returns string to display in the filter dropdown
     * @memberof MonthlyVolume
     */
    const setFilterName = (monthCount, runCount) => {
      return months[monthCount - 1] + "-" + runCount;
    };

    /**
     * @description Create the objects to use as input to populate the filter dropdown.
     * @returns array of objects containing filter data
     * @memberof MonthlyVolume
     */
    const setFilters = () => {
      const modifiedFilters = this.state.filters.map((item) => {
        return {
          ...item,
          key: item.month + "_" + item.run_cnt,
          name: setFilterName(item.month, item.run_cnt),
        };
      });
      return modifiedFilters;
    };

    /**
     * @description Get the monthly volume data on selecting constrained/unconstrained.
     * @param {boolean} isConstrained whether the monthly volume to display is constrained.
     * @memberof MonthlyVolume
     */
    const setTemporalVolume = (isConstrained) => {
      // console.log("Getting data for params : ", this.state.currentParams);

      let params = {
        ...this.state.currentParams,
        type: isConstrained ? "C" : "U",
      };
      getTemporalData(params);

      this.setState({ isConstrainedSelected: isConstrained });
    };

    /**
     * @description schedule button will be disabled if atleast one row is being edited or is_schedulable.
     * @returns true should the schedule button can be clicked.
     * @memberof MonthlyPrice
     */
    const isScheduleButtonEnabled = () => {
      if (!this.state.isConstrainedSelected) {
        return false;
      }

      if (
        !canUserEdit(
          this.props.userData,
          null,
          FeatureCodes.MONTHLY_MODEL_FORECAST_EDIT
        )
      ) {
        return false;
      }

      let editables = [],
        schedulables = [];

      this.state.temporalData.map((item) => {
        item.branches.map((branch) => {
          console.log("branch :", branch);
          editables.push(branch.action.isEditable);
          schedulables.push(branch.is_scheduled.planned);
        });
      });
      console.log(
        "is schedule button enabled : ",
        !editables.includes(true) && !schedulables.includes(true)
      );

      return !editables.includes(true) && !schedulables.includes(true);
    };

    //this will open the modal
    /**
     * @description Open/Close the confirmation modal
     * @param {boolean} status visibility status on the confirmation modal.
     * @memberof MonthlyPrice
     */
    const onScheduleButtonClicked = (status) => {
      setModalVisibility(true);
    };

    const setModalVisibility = (status) => {
      this.setState({ isModalVisible: status });
    };

    /**
     * @description Check if the current model run has been scheduled or not.
     * @returns true if the current model run has been scheduled.
     * @memberof MonthlyPrice
     */
    const isScheduled = () => {
      let schedulables = [];
      this.state.temporalData.map((item) => {
        item.branches.map((branch) => {
          schedulables.push(branch.is_scheduled.planned);
        });
      });

      return schedulables.includes(true);
    };

    /**
     * @description Set the scheduled status for a selected model run
     * @param {boolean} status the status of sheduling to be set for the model run.
     * @memberof MonthlyPrice
     */
    const setScheduleStatus = (status) => {
      const modifiedTemporalData = this.state.temporalData.map((item) => {
        return {
          ...item,
          branches: item.branches.map((branch) => {
            return {
              ...branch,
              is_scheduled: { ...branch.is_scheduled, planned: status },
            };
          }),
        };
      });

      console.log("Scheduled modified data : ", modifiedTemporalData);

      this.setState({ temporalData: modifiedTemporalData });
    };

    //FIXME:
    /**
     * @description  submit the scheduling confirmation by calling the api.
     * @memberof MonthlyPrice
     */
    const onModalSubmit = () => {
      console.log("Now we can finally submit the schedule.");
      this.setSpinnerStatus(true);
      //#region api call for schedule api

      const dataToSend = {
        run_month: this.state.selectedMonthID,
      };

      /////////////////

      // this.setSpinnerStatus(false);
      // // set all is_scheduled to true
      // setScheduleStatus(true);
      // setModalVisibility(false);

      // return;
      //////////////////

      API.put("/recommendations/monthly/volume", dataToSend)
        .then((scheduleResponse) => {
          if (scheduleResponse.data.status) {
            this.setSpinnerStatus(false);
            console.log(scheduleResponse.data.data);
            // set all is_scheduled to true
            setScheduleStatus(true);

            message.success("The data has been scheduled successfully.");
          } else {
            this.setSpinnerStatus(false);
            console.log("error puting schedule api");
            message.error("Cannot schedule the modified values");
          }
        })
        .catch((err) => {
          this.setSpinnerStatus(false);
          console.log("ERROR PUTING SCHEDULED API : ERROR : ", err);
          message.error(
            "Cannot submit the schedule.Please check your network connectivity."
          );
        });

      //#endregion

      setModalVisibility(false);
    };

    const handleYearSelect = (value, props) => {
      const paramss={month:this.state.selectedMonthID,run_cnt:this.state.selectedrunCount}
      this.setState(
        { selectedYearValue: value },
        () => getTemporalData(paramss,value)
      );
    };

    return (
      <Spin spinning={this.state.isLoading}>
        <div className="monthly-price-container">
          <Modal
            className="temporal-modal"
            title={<div style={{ color: "white" }}>Confirmation</div>}
            visible={this.state.isModalVisible}
            onOk={() => onModalSubmit()}
            onCancel={() => setModalVisibility(false)}
            cancelButtonProps={{
              style: { backgroundColor: "#cbcbcb !important" },
            }}
          >
            <div style={{ color: "black" }}>
              This will freeze the volume for this month. Do you want to proceed
              ?
              {/* You are about to submit the overrides to schedule for monthly
              volume model run. This will be the final submission. After this,
              you will not be able to make any further changes. Are you sure you
              want to submit ? */}
            </div>
          </Modal>

          <div className="top-container wider">
            {/* Override button */}
            {this.state.temporalData.length !== 0 && (
              <div
                className={`${isScheduleButtonEnabled()
                  ? "schedule-button"
                  : "schedule-button-disabled"
                  } `}
                onClick={() => onScheduleButtonClicked()}
              >
                <div style={{ margin: "4px 0 0 0 " }}>
                  {" "}
                  {`${isScheduled()
                    ? "Final Override Confirmed"
                    : "Final Override Confirmation"
                    }`}
                </div>
                {/* <div style={{ fontSize: "12px" }}>For monthly volume run</div> */}
              </div>
            )}

            {/* Volume type buttons */}
            {this.state.temporalData.length !== 0 && (
              <div className="volume-button-container">
                <Button
                  className={`${this.state.isConstrainedSelected
                    ? "volume-button-disabled"
                    : "volume-button"
                    }`}
                  onClick={() => setTemporalVolume(false)}
                >
                  Unconstrained Volume
                </Button>
                <Button
                  className={`${this.state.isConstrainedSelected
                    ? "volume-button"
                    : "volume-button-disabled"
                    }`}
                  onClick={() => setTemporalVolume(true)}
                >
                  Constrained Volume
                </Button>
              </div>
            )}

            {/* filter */}
            <div style={{display:"flex"}}>
            <div className="temporal-select">
              <div className="temporal-select-title">Model Run</div>
              <CustomSelectTemporal
                isPrice={false}
                isConstrained={this.state.isConstrainedSelected}
                list={setFilters()}
                onSelect={getTemporalData}
                connectState
              />
             
            </div>
          {/*
            <div className="temporal-select" style={{marginLeft:"10px"}}>
            <div className="temporal-select-title">Model Year</div>
            <Select
            onChange={(value, props) =>
              handleYearSelect(value)
            }
            value={this.state.selectedYearValue}
          >
          {console.log("year list map ", this.state.yearList)}
            {this.state.yearList.map((item, idx) => (
              <Option value={item.year_id} key={item.year_id}>
                {item.year_id}
              </Option>
            ))}
          </Select>
 </div>*/}
            </div>
            {this.state.userInfo && this.state.userInfo.is_finalised === 1 && (
              <div className="user-info box-shadow-card-pressed">
                <div className="title-generic" style={{ fontSize: "12px" }}>
                  Finalized by : {this.state.userInfo.user_name}
                </div>
                <div className="title-generic" style={{ fontSize: "12px" }}>
                  @ &nbsp; {this.state.userInfo.date_time}
                  {/* {moment(this.state.userInfo.data_time, "DD-MM-YYYY").format(
                    "DD-MM-YYYY"
                  ) +
                    "    " +
                    new Date().toLocaleTimeString()} */}
                </div>
              </div>
            )}
          </div>

          <div
            style={{ height: window.innerHeight - 210 }}
            className="table-list-container"
          >
            {this.state.temporalData.length !== 0 && (
              <TemporalExpandableTable
                isDisabled={
                  !canUserEdit(
                    this.props.userData,
                    null,
                    FeatureCodes.MONTHLY_MODEL_FORECAST_EDIT
                  )
                }
                isConstrained={this.state.isConstrainedSelected}
                isPrice={true}
                resetRow={resetRow}
                onTickButtonClicked={onTickButtonClicked}
                changeEditStatus={changeEditStatus}
                onValueModified={onValueModified}
                data={this.state.temporalData}
                title="Volume (in MT)"
              />
            )}
          </div>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  allBranches: selectAllBranches,
  allVarietyInfo: selectAllVarietyInfo,
  userData: selectUserData,
});

export default connect(mapStateToProps, null)(MonthlyVolume);
