import React from "react";
import "./cost-buildup-sahayak.page";
import API from "../../api";
import { getStatesAPI } from "../../services/generic-apis";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUserData,selectMSMessageRBAC } from "../../redux/user/user.selectors";
import { canUserEdit ,getUserMSRBACMessage} from "../../auth/utils";
import { FeatureCodes } from "../../auth/data";

import { Component } from "react";
import { Table, Spin, Input, Select, Button, message, Form, Modal ,Radio} from "antd";
const { Search } = Input;
const { Option } = Select;

class CostBuildupSahayakMaster extends Component {
  state = {
    isModalVisible: false,
    isAdd: false,

    locationList: [],
    varietyList: [],
    variantList: [],
    mandi_cessList: [],
    gunnycostimpactList: [],
    commissionList: [],
    sahayakcommissionList: [],
    CostBuildupSahayakTableData: [],
    currentRecord: null,
    userBranches: null,
    searchText: null,
    searchUserType: 0,

    isActive: false,
    isSpinnerOnLoad: false,
    isSubmitted: false,

    newData: {
      commission_inpercentage: null,
      gunny_cost_impact: null,

      mandi_cess_inpercentage: null,
      mandi_id: undefined,
      mandi_name: "",
      sahayak_commission: null,
      varient: "",
      variety_id: undefined,
      location: "",
      cbus_pk_id: undefined,
      variety_name: "",

      isEdit: false,

      is_active: true,
    },
    rowData: undefined,
    selectedStateValue: undefined,
    selectedStateName: "",
    selectedStateList: [],
  };

  componentDidMount() {
    this.setState({ isSpinnerOnLoad: true });
    this.getPrimaryHub();
  }

  getcostsahayak = async () => {
    this.setState({ isSpinnerOnLoad: true });
    await API.get("/cost_buildup_sahayak")
      .then((resp) => {
        
        let modifiedData = resp.data.data.map((item, index) => {
          return {
            ...item,
            isEdit: false,
            key: index,
          };
        });
        this.setState(
          {
            CostBuildupSahayakTableData: modifiedData,
          },
          () => this.setState({ isSpinnerOnLoad: false, isAdd: true })
        );
        //console.log("mandi table data ", this.state.mandiHubDistrictTableData);
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };

  getVariantList = async () => {
    this.setState({ isSpinnerOnLoad: true });
    await API.get("/cbus_variant")
      .then((typedata) => {
       

        this.setState(
          {
            variantList: typedata.data.data,
          },
          () => this.getcostsahayak()
        );
      })

      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };
  getvarietyList = async () => {
    this.setState({ isSpinnerOnLoad: true });

    await API.get("/list_variety")
      .then((roleData) => {
        this.setState(
          {
            varietyList: roleData.data.data,
          },
          () => this.getVariantList()
        );
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };
  getPrimaryHub = async () => {
    this.setState({ isSpinnerOnLoad: true });

    await API.get("/list_mandi")
      .then((typedata) => {
        console.log("mandi ", typedata);
        this.setState(
          {
            locationList: typedata.data.data,
          },
          () => this.getvarietyList()
        );
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };

  // getStatesAPI = async () => {
  //   this.setState({ isSpinnerOnLoad: true });
  //   await API.get("/states")
  // .then((state) => {

  //   let modifiedData = [...state.data.data];
  //   modifiedData.push({ hub_state_id: null, Hub_State: "All" });
  //   this.setState(
  //     {
  //       selectedStateList: modifiedData,
  //    //   mandiBranchList: state.data.data,
  //       selectedStateValue: state.data.data[0].hub_state_id,
  //       selectedStateName: state.data.data[0].Hub_State
  //     },

  //     () => this.getMandiList()
  //   );
  // })

  // .catch((err) => {
  //   this.setState({ isSpinnerOnLoad: false });
  //   this.getStatesAPI();
  //   if (err) {
  //     if (err.response && err.response.status === 400)
  //       message.error("Something went wrong. Please try again later.", 5);
  //     else {
  //       message.error("Something went wrong.. Please try again later.", 5);
  //     }
  //   } else {
  //     message.error("Something went wrong... Please try again later.", 5);
  //   }
  // });
  // };

  setTableData = () => {
    if (
      this.state.CostBuildupSahayakTableData !== undefined ||
      this.state.CostBuildupSahayakTableData.length >= 0
    ) {
      const userTableData = this.state.CostBuildupSahayakTableData.map(
        (user, index) => {
          return {
            key:user.key,
            commission_inpercentage: user.commission_inpercentage,
            gunny_cost_impact: user.gunny_cost_impact,

            mandi_cess_inpercentage: user.mandi_cess_inpercentage,
            mandi_id: user.mandi_id,
            mandi_name: user.mandi_name,
            sahayak_commission: user.sahayak_commission,
            varient: user.varient,
            variety_id: user.variety_id,
            variety_name: user.variety_name,
            cbus_pk_id: user.cbus_pk_id,
            is_active: user.is_active,
            location: user.location,

            isEdit: user.isEdit,
          };
        }
      );

      let dataAfterSearch = userTableData;

      if (this.state.searchText) {
        dataAfterSearch = dataAfterSearch.filter(
          (item) =>
            (item.location !== null &&
              item.location
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase())) ||
                (item.variety_name !== null &&
                  item.variety_name
                    .toLowerCase()
                    .includes(this.state.searchText.toLowerCase()))  ||
            (item.varient !== null &&
              item.varient
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase())) 
        );
      }
      return dataAfterSearch;
    } else {
      return this.state.CostBuildupSahayakTableData;
    }
  };

  submitTableRowData = (row, index, isPopup) => {
    this.setState({ isSpinnerOnLoad: true, isSubmitted: true });
    let warningmessage = "";
    if (
      row.location === null ||
      row.location === undefined ||
      row.location === ""
    ) {
      warningmessage = "Please select Location";
    } else if (
      row.variety_name === null ||
      row.variety_name === undefined ||
      row.variety_name === ""
    ) {
      warningmessage = "Please select Variety";
    } else if (
      row.varient === null ||
      row.varient === undefined ||
      row.varient === ""
    ) {
      warningmessage = "Please select Variant";
    } else if (
      row.mandi_cess_inpercentage === null ||
      row.mandi_cess_inpercentage === undefined ||
      row.mandi_cess_inpercentage === ""
    ) {
      warningmessage = "Please enter Mandi Cess";
    } else if (
      row.gunny_cost_impact === null ||
      row.gunny_cost_impact === undefined ||
      row.gunny_cost_impact === ""
    ) {
      warningmessage = "Please enter Gunny Cost";
    } else if (
      row.commission_inpercentage === null ||
      row.commission_inpercentage === undefined ||
      row.commission_inpercentage === ""
    ) {
      warningmessage = "Please enter Commission";
    }else if (
      row.sahayak_commission === null ||
      row.sahayak_commission === undefined ||
      row.sahayak_commission === ""
    ) {
      warningmessage = "Please enter Sahayak Commission";
    }

    if (
      warningmessage === "" ||
      warningmessage === undefined ||
      warningmessage === null
    ) {
      this.setState({ isSpinnerOnLoad: true });
      this.setState({ isSubmitted: true });

      let data = {
        commission_inpercentage: row.commission_inpercentage,
        gunny_cost_impact: row.gunny_cost_impact,
        mandi_cess_inpercentage: row.mandi_cess_inpercentage,
        sahayak_commission: row.sahayak_commission,
        varient: row.varient,
        variety_id: row.variety_id,
        cbus_pk_id: row.cbus_pk_id,
        is_active: row.is_active ,
        mandi_id: row.mandi_id,
      };

      // const headers = {
      //   "Content-Type": "application/json",
      //   Authorization: "Bearer " + localStorage.getItem("accessToken"),
      // };

      API.put("/cost_buildup_sahayak", data, {})

        .then((response) => {
          if (response.status === 200 && response.data.status) {
            message.info(response.data.message, 5);
            this.setModalVisibility(false);
            this.getcostsahayak();
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
          } else if (!response.data.status) {
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
            message.error(response.data.message, 5);
          } else {
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
            message.error("Please try again", 5);
          }
        })
        .catch((err) => {
          if (err) {
            if (err.response && err.response.status === 400)
              message.error("Something went wrong. Please try again later.", 5);
            else {
              message.error(
                "Something went wrong.. Please try again later.",
                5
              );
            }
          } else {
            message.error("Something went wrong... Please try again later.", 5);
          }
          this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
          // this.setModalVisibility(false);
        });
    } else {
      this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
      message.error(warningmessage, 5);
    }
  };

  setModalVisibility = (status) => {
    if (status) {
      const nData = {
        commission_inpercentage: null,
        gunny_cost_impact: null,

        mandi_cess_inpercentage: null,
        mandi_id: undefined,
        mandi_name: "",
        variety_name: "",

        sahayak_commission: null,
        varient: "",
        variety_id: undefined,

        cbus_pk_id: undefined,

        location: "",

        isEdit: false,

        is_active: true,
      };

      this.setState({ newData: nData });
    }

    this.setState({ isModalVisible: status });
  };
  TableColumnHeader = (props) => {
    const { title, subtitle } = props;
    return (
      <div className="column-header-container">
        <div
          style={{
            color: "white",
            fontSize: "12px",
            whiteSpace: "nowrap",
            fontWeight: "normal",
            textAlign: "left",
          }}
        >
          {title}
        </div>
        <div
          style={{
            color: "#cbdfff",
            fontSize: "11px",
            fontWeight: "normal",
            textAlign: "left",
          }}
        >
          {subtitle}
        </div>
      </div>
    );
  };

  createFilters = (label) => {
    let filterData = this.state.CostBuildupSahayakTableData;

    //#region remove duplicate objects

    let uniqueFilterData = [];

    if (
      label === "is_active" &&
      this.state.CostBuildupSahayakTableData &&
      this.state.CostBuildupSahayakTableData.length > 0
    ) {
      uniqueFilterData = [
        { text: "Yes", value: 1 },
        { text: "No", value: 0 },
      ];
    } else {
      filterData.map((mainItem) =>
        uniqueFilterData.filter((item) => item[label] === mainItem[label])
          .length > 0
          ? null
          : uniqueFilterData.push(mainItem)
      );

      uniqueFilterData = uniqueFilterData.map((item) => {
        return {
          text: item[label],

          value: item[label],
        };
      });

      uniqueFilterData.sort((a, b) => a.text.localeCompare(b.text));
    }

    return uniqueFilterData;
  };

  handleDropdownChange = (value, row, target, index, optionlabel, isPopup) => {
    let a;

    if (isPopup === false) {
      a = this.state.CostBuildupSahayakTableData;

      if (target === "is_active") {
        a[row.key].is_active = value;
      } else if (target === "location") {
        a[row.key].location = optionlabel;
        a[row.key].mandi_id = value;
      } else if (target === "variety_name") {
        a[row.key].variety_id = value;
        a[row.key].variety_name = optionlabel;
      } else if (target === "varient") {
        a[row.key].varient = value;
        a[row.key].varient = optionlabel;
      }

      this.setState({ CostBuildupSahayakTableData: a });
    } else {
      a = this.state.newData;

      if (target === "is_active") {
        a.is_active = value;
      } else if (target === "varient") {
        a.varient = optionlabel;
        a.varient = value;
      } else if (target === "location") {
        a.location = optionlabel;
        a.mandi_id = value;
      } else if (target === "variety_name") {
        a.variety_id = value;
        a.variety_name = optionlabel;
      }

      this.setState({ newData: a });
    }
  };

  handleChange = (e, row, index, name, isPopup) => {
    let a;
    var alphaNumericRegex = /^[a-zA-Z0-9]*$/;
    var numberRegex = /^[0-9.]+$/;
    if (isPopup === false) {
      a = this.state.CostBuildupSahayakTableData;

      switch (name) {
        case "mandi_cess_inpercentage":
          if (e.target.value === "") {
            a[row.key].mandi_cess_inpercentage = "";
          } else if (e.target.value.match(numberRegex)) {
            a[row.key].mandi_cess_inpercentage = e.target.value;
          }

          this.setState({ CostBuildupSahayakTableData: a });
          return;

        case "commission_inpercentage":
          if (e.target.value === "") {
            a[row.key].commission_inpercentage = "";
          } else if (e.target.value.match(numberRegex)) {
            a[row.key].commission_inpercentage = e.target.value;
          }

          this.setState({ CostBuildupSahayakTableDatat: a });
          return;

        case "gunny_cost_impact":
          if (e.target.value === "") {
            a[row.key].gunny_cost_impact = "";
          } else if (e.target.value.match(numberRegex)) {
            a[row.key].gunny_cost_impact = e.target.value;
          }

          this.setState({ CostBuildupSahayakTableData: a });
          return;
        case "sahayak_commission":
          if (e.target.value === "") {
            a[row.key].sahayak_commission = "";
          } else if (e.target.value.match(numberRegex)) {
            a[row.key].sahayak_commission = e.target.value;
          }

          this.setState({ CostBuildupSahayakTableData: a });
          return;

        default:
          return "";
      }
    } else {
      a = this.state.newData;

      switch (name) {
        case "mandi_cess_inpercentage":
          if (e.target.value === "") {
            a.mandi_cess_inpercentage = "";
          } else if (e.target.value.match(numberRegex)) {
            a.mandi_cess_inpercentage = e.target.value;
          }

          this.setState({ newData: a });
          return;
        case "commission_inpercentage":
          if (e.target.value === "") {
            a.commission_inpercentage = "";
          } else if (e.target.value.match(numberRegex)) {
            a.commission_inpercentage = e.target.value;
          }

          this.setState({ newData: a });
          return;
        case "gunny_cost_impact":
          if (e.target.value === "") {
            a.gunny_cost_impact = "";
          } else if (e.target.value.match(numberRegex)) {
            a.gunny_cost_impact = e.target.value;
          }

          this.setState({ newData: a });
          return;
        case "sahayak_commission":
          if (e.target.value === "") {
            a.sahayak_commission = "";
          } else if (e.target.value.match(numberRegex)) {
            a.sahayak_commission = e.target.value;
          }

          this.setState({ newData: a });
          return;
        default:
          return "";
      }
      // this.setState({ newData: a });
    }
  };
  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  handleEditChange = (row, index) => {
    // console.log("user hub id is ", this.props.userData.hub_state_id );//check this afterwards

    this.setState({ rowData: row });
    let tempData = this.state.CostBuildupSahayakTableData.map((item) => {
      if (item.cbus_pk_id === row.cbus_pk_id) {
        return {
          ...item,
          isEdit: true,
        };
      } else {
        return item;
      }
    });
    this.setState({ CostBuildupSahayakTableData: tempData });
  };

  handleCancelClick = (rowData, index) => {
    let tempData = this.state.CostBuildupSahayakTableData.map((item) => {
      if (item.cbus_pk_id === rowData.cbus_pk_id) {
        return this.state.rowData;
      } else {
        return item;
      }
    });
    this.setState({ CostBuildupSahayakTableData: tempData });
  };
  render() {
    const userTableProps = {
      pagination: false,
      size: "default",
      bordered: true,
      scroll: { x: 200, y: window.innerHeight - 250 },
    };
    // const handleStateSelect = (value, props) => {
    //   this.setState(
    //     { selectedStateName: props.key, selectedhubStateValue: value },
    //     () => this.getStatesAPI()
    //   );
    // };

    /// returning to output,just a dummy data as of now

    return (
      <div // close left
        className="mandi-master-container"
        style={{
          height: window.innerHeight - 85,
          marginTop: "-45px",
        }}
      >
        <div className="top-container">
          {/* <div className="hub-region-filter">
       <span
                style={{
                  fontSize: "15px",
                  marginTop: "5px",
                  marginLeft: "68px",
                  fontWeight: "600",
                }}
              >
                State&nbsp;
              </span>{" "}
              <Select
                disabled={this.state.isAdd}
                showSearch
                optionFilterProp="children"
                className={"select-master-region"}
                mode="single"
                placeholder="Select State"
                value={this.state.selectedhubStateValue}
                onChange={(value, props) =>
                  handleStateSelect(value, props)
                }
              >
                {this.state.selectedhubStateList !== null && this.state.selectedhubStateList.length &&
                  this.state.selectedhubStateList.map((item, idx) => (
          
                      <Option
                      value={item.hub_state_id}
                      key={item.Hub_State}
                    >
                      {item.Hub_State}
                    </Option>
                  ))}
              </Select>
  
            </div> */}

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row-reverse",
              placeItems: "flex-end",
              marginRight: "25px",
              marginBottom: "10px",
            }}
          >
            <Search
              disabled={!this.state.isAdd}
              placeholder="Search"
              allowClear
              onChange={(e) => this.setState({ searchText: e.target.value })}
              style={{ width: "25%" }}
            />
            <Button
              className="add-master-button"
              style={{
                height: "25px",
                width: "70px",
                backgroundColor: "#0b133d",
                color: "white",
              }}
              disabled={
                !this.state.isAdd ||
                !canUserEdit(
                  this.props.userData,
                  null,
                  FeatureCodes.MS_COST_SAHAYAK_GUJRAT_EDIT
                )
              }
              onClick={() => this.setModalVisibility(true)}
            >
              &#10010; Add
            </Button>
          </div>
        </div>

        <div>
          <div
            style={{
              marginLeft: "25px",
              marginTop: "-8px",
              fontSize: "14px",
              color: "red",
              display: "flex",
              float: "left",
            }}
          >
          {getUserMSRBACMessage(this.props.messageAccess,FeatureCodes.MS_COST_SAHAYAK_GUJRAT_EDIT)}
          </div>
          <div
            style={{
              marginRight: "20px",
              marginTop: "-8px",
              fontSize: "15px",
              color: "#0B133D",
              display: "flex",
              float: "right",
            }}
          >
            Total number of records:&nbsp;
            <b>
              {this.state.CostBuildupSahayakTableData &&
                this.setTableData().length}
            </b>
          </div>
        </div>

        <Spin
          spinning={this.state.isSpinnerOnLoad}
          size={"large"}
          tip="Loading..."
          style={{ alignSelf: "center", zIndex: 9999, marginTop: "20px" }}
        >
          <Modal
            className="add-master-modal"
            title={"Add Cost Build Up Sahayak Gujarat"}
            onCancel={this.handleCancel}
            footer={false}
            visible={this.state.isModalVisible}
            destroyOnClose={true}
          >
            <div className="master-form-container">
              <Form
                layout="horizontal"
                //   onValuesChange={onFormLayoutChange}
                size={"small"}
                // onSubmit={submitTableRowData(this.state.newData)}
              >
                <div className="form-top-container1">
                  <div
                    className="checkbox-group1"
                    style={{
                      display: "inline-block",
                      width: "38%",
                      marginRight: 40,
                    }}
                  >
                    <Form.Item label="Location">
                      <Select
                        showSearch
                        optionFilterProp="children"
                        className="remarks-select"
                        name="location"
                        defaultValue={this.state.newData.location}
                        value={this.state.newData.location}
                        onSelect={(value, option) =>
                          this.handleDropdownChange(
                            value,
                            this.state.newData,
                            "location",
                            -0,
                            option.props.children,
                            true
                          )
                        }
                      >
                        {this.state.locationList &&
                          this.state.locationList.length &&
                          this.state.locationList.map((hubItem, idx) => (
                            <Option
                              key={hubItem.mandi_id}
                              value={hubItem.mandi_id}
                            >
                              {hubItem.mandi_name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </div>

                  <div
                    className="checkbox-group1"
                    style={{
                      height: "45px",
                      display: "inline-block",
                      width: "38%",
                      marginRight: 40,
                    }}
                  >
                    <Form.Item label="Variety">
                      <Select
                        showSearch
                        optionFilterProp="children"
                        className="remarks-select"
                        name="variety_name"
                        defaultValue={this.state.newData.variety_name}
                        value={this.state.newData.variety_name}
                        onSelect={(value, option) =>
                          this.handleDropdownChange(
                            value,
                            this.state.newData,
                            "variety_name",
                            -0,
                            option.props.children,
                            true
                          )
                        }
                      >
                        {this.state.varietyList &&
                          this.state.varietyList.length &&
                          this.state.varietyList.map((hubItem, idx) => (
                            <Option
                              key={hubItem.variety_id}
                              value={hubItem.variety_id}
                            >
                              {hubItem.variety_name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </div>

                  <div
                    className="checkbox-group1"
                    style={{
                      height: "45px",
                      display: "inline-block",
                      width: "38%",
                      marginRight: 40,
                    }}
                  >
                    <Form.Item label="Variant">
                      <Select
                        showSearch
                        optionFilterProp="children"
                        className="remarks-select"
                        name="varient"
                        defaultValue={this.state.newData.varient}
                        value={this.state.newData.varient}
                        onSelect={(value, option) =>
                          this.handleDropdownChange(
                            value,
                            this.state.newData,
                            "varient",
                            -0,
                            option.props.children,
                            true
                          )
                        }
                      >
                        {this.state.variantList &&
                          this.state.variantList.length &&
                          this.state.variantList.map((hubItem, idx) => (
                            <Option key={hubItem.key} value={hubItem.variant}>
                              {hubItem.variant}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </div>

                  <div
                    className="checkbox-group1"
                    style={{
                      height: "45px",
                      display: "inline-block",
                      width: "38%",
                      marginRight: 40,
                    }}
                  >
                    <Form.Item label="Mandi Cess(%)">
                      <Input
                        type="number"
                        style={{ marginRight: "2px" }}
                        maxLength={100}
                        min="0"
                        value={this.state.newData.mandi_cess_inpercentage}
                        name="mandi_cess_inpercentage"
                        onChange={(e) =>
                          this.handleChange(
                            e,
                            this.state.newData,
                            0,
                            "mandi_cess_inpercentage",
                            true
                          )
                        }
                      />
                    </Form.Item>
                  </div>

                  <div
                    className="checkbox-group1"
                    style={{
                      height: "45px",
                      display: "inline-block",
                      width: "38%",
                      marginRight: 40,
                    }}
                  >
                    <Form.Item label="Gunny cost impact">
                      <Input
                        type="number"
                        style={{ marginRight: "2px" }}
                        maxLength={100}
                        min="0"
                        value={this.state.newData.gunny_cost_impact}
                        name="gunny_cost_impact"
                        onChange={(e) =>
                          this.handleChange(
                            e,
                            this.state.newData,
                            0,
                            "gunny_cost_impact",
                            true
                          )
                        }
                      />
                    </Form.Item>
                  </div>

                  <div
                    className="checkbox-group1"
                    style={{
                      height: "45px",
                      display: "inline-block",
                      width: "38%",
                      marginRight: 40,
                    }}
                  >
                    <Form.Item label="Commission(%)">
                      <Input
                        type="number"
                        style={{ marginRight: "2px" }}
                        maxLength={100}
                        min="0"
                        value={this.state.newData.commission_inpercentage}
                        name="commission_inpercentage"
                        onChange={(e) =>
                          this.handleChange(
                            e,
                            this.state.newData,
                            0,
                            "commission_inpercentage",
                            true
                          )
                        }
                      />
                    </Form.Item>
                  </div>

                  <div
                    className="checkbox-group1"
                    style={{
                      display: "inline-block",
                      width: "38%",
                      marginRight: 40,
                    }}
                  >
                    <Form.Item label="Sahayak Commission">
                      <Input
                        type="number"
                        style={{ marginRight: "2px" }}
                        maxLength={100}
                        min="0"
                        value={this.state.newData.sahayak_commission}
                        name="sahayak_commission"
                        onChange={(e) =>
                          this.handleChange(
                            e,
                            this.state.newData,
                            0,
                            "sahayak_commission",
                            true
                          )
                        }
                      />
                    </Form.Item>
                  </div>
                
                  <div
                  className="form-top-container"
                  style={{
                    display: "inline-block",
                    width: "41%",
                    marginRight: 7,
                  }}
                >
                  <Form.Item label="Active">
                    <Radio.Group
                      name="is_active"
                      value={this.state.newData.is_active ? 1 : 0}
                      onChange={(e, option) =>
                        this.handleDropdownChange(
                          e.target.value,
                          this.state.newData,
                          "is_active",
                          -0,
                          "",
                          true
                        )
                      }
                    >
                      <Radio value={1}>Yes</Radio>
                      <Radio value={0}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </div>
              
                  </div>
                <div
                  className="form-bottom-container"
                  style={{ marginTop: "10px" }}
                >
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={this.state.isSubmitted}
                      onClick={() =>
                        this.submitTableRowData(this.state.newData, 0, true)
                      }
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </Modal>

          <div
            className="table-container1"
            style={{ maxHeight: "400px", margin: "6px 10px" }}
          >
            <Table
              {...userTableProps}
              columns={[
                {
                  title: "Location",
                  dataIndex: "location",
                  key: "location",
                  width: 120,
                  filters: this.createFilters("location"),
                  onFilter: (value, record) => record.location.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.location.localeCompare(b.location),
                  render: (record, rowRecord) => {
                    return (
                      <>
                        <div
                          style={{ marginLeft: "-15px", marginRight: "-10px" }}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            disabled={true} //{!rowRecord.isEdit}
                            className="remarks-select"
                            name="location"
                            defaultValue={rowRecord.location}
                            value={rowRecord.location}
                            onSelect={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "location",
                                -0,
                                option.props.children,
                                false
                              )
                            }
                          >
                            {this.state.locationList &&
                              this.state.locationList.length &&
                              this.state.locationList.map((hubItem, idx) => (
                                <Option
                                  key={hubItem.mandi_id}
                                  value={hubItem.mandi_id}
                                >
                                  {hubItem.mandi_name}
                                </Option>
                              ))}
                          </Select>
                        </div>
                      </>
                    );
                  },
                },
                {
                  title: "Variety",
                  dataIndex: "variety_name",
                  key: "variety_name",
                  width: 120,
                  filters: this.createFilters("variety_name"),
                  onFilter: (value, record) =>
                    record.variety_name.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.variety_name - b.variety_name,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Select
                            showSearch
                            optionFilterProp="children"
                            disabled={true} //{!rowRecord.isEdit}
                            className="remarks-select"
                            name="variety_name"
                            defaultValue={rowRecord.variety_name}
                            value={rowRecord.variety_name}
                            onSelect={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "variety_name",
                                -0,
                                option.props.children,
                                false
                              )
                            }
                          >
                            {this.state.varietyList &&
                              this.state.varietyList.length &&
                              this.state.varietyList.map((hubItem, idx) => (
                                <Option
                                  key={hubItem.variety_id}
                                  value={hubItem.variety_id}
                                >
                                  {hubItem.variety_name}
                                </Option>
                              ))}
                          </Select>
                        </div>
                      </>
                    );
                  },
                },
                {
                  title: "Variant",
                  dataIndex: "varient",
                  key: "varient",
                  width: 120,
                  filters: this.createFilters("varient"),
                  onFilter: (value, record) => record.varient.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.varient - b.varient,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Select
                            showSearch
                            optionFilterProp="children"
                            disabled={true} //{!rowRecord.isEdit}
                            className="remarks-select"
                            name="varient"
                            defaultValue={rowRecord.varient}
                            value={rowRecord.varient}
                            onSelect={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "varient",
                                -0,
                                option.props.children,
                                false
                              )
                            }
                          >
                            {this.state.variantList &&
                              this.state.variantList.length &&
                              this.state.variantList.map((hubItem, idx) => (
                                <Option
                                  key={hubItem.varient}
                                  value={hubItem.varient}
                                >
                                  {hubItem.varient}
                                </Option>
                              ))}
                          </Select>
                        </div>
                      </>
                    );
                  },
                },
                {
                  title: "Mandi Cess(%)",
                  dataIndex: "mandi_cess_inpercentage",
                  key: "mandi_cess_inpercentage",
                  width: 120,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) =>
                    a.mandi_cess_inpercentage - b.mandi_cess_inpercentage,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            type="number"
                            disabled={!rowRecord.isEdit}
                            style={{ marginRight: "2px" }}
                            maxLength={100}
                            min="0"
                            value={rowRecord.mandi_cess_inpercentage}
                            name="mandi_cess_inpercentage"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "mandi_cess_inpercentage",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },
                {
                  title: "Gunny Cost Impact",
                  dataIndex: "gunny_cost_impact",
                  key: "gunny_cost_impact",
                  width: 120,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.gunny_cost_impact - b.gunny_cost_impact,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            type="number"
                            disabled={!rowRecord.isEdit}
                            style={{ marginRight: "2px" }}
                            maxLength={100}
                            min="0"
                            value={rowRecord.gunny_cost_impact}
                            name="gunny_cost_impact"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "gunny_cost_impact",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },
                {
                  title: "Commission(%)",
                  dataIndex: "commission_inpercentage",
                  key: "commission_inpercentage",
                  width: 100,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) =>
                    a.commission_inpercentage - b.commission_inpercentage,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            type="number"
                            disabled={!rowRecord.isEdit}
                            style={{ marginRight: "2px" }}
                            maxLength={100}
                            min="0"
                            value={rowRecord.commission_inpercentage}
                            name="commission_inpercentage"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "commission_inpercentage",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },
                {
                  title: "Sahayak Commission",
                  dataIndex: "sahayak_commission",
                  key: "sahayak_commission",
                  width: 150,

                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) =>
                    a.sahayak_commission.localeCompare(b.sahayak_commission),
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div
                          style={{ marginLeft: "-15px", marginRight: "-10px" }}
                        >
                          <Input
                            type="number"
                            disabled={!rowRecord.isEdit}
                            style={{ marginRight: "2px" }}
                            maxLength={100}
                            min="0"
                            value={rowRecord.sahayak_commission}
                            name="sahayak_commission"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "sahayak_commission",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },
                {
                  key: "is_active",
                  title: "Active",
                  dataIndex: "is_active",
                  width: 85,
                  filters: this.createFilters("is_active"),
                  // filters: [{ text: "Yes", value: 1 }, { text: "No", value: 0 }],
                  onFilter: (value, record) =>
                    record.is_active === value,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-15px", marginRight: "-40px" }}>
                          <Select
                            showSearch
                            optionFilterProp="children"
                            disabled={!rowRecord.isEdit}
                            defaultValue={
                              rowRecord.is_active === 1 ? "Yes" : "No"
                            }
                            value={
                              rowRecord.is_active === 1 ? "Yes" : "No"
                            }
                            onChange={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "is_active",
                                index,
                                option.props.children,
                                false
                              )
                            }
                            style={{ width: "70%" }}
                          >
                            <Option key={1} value={1}>
                              {"Yes"}
                            </Option>
                            <Option key={0} value={0}>
                              {"No"}
                            </Option>
                          </Select>
                        </div>
                      </>
                    );
                  },
                },
                {
                  title: "Action",
                  dataIndex: "Submit",
                  key: "Submit",
                  width: 120,
                  visibility: false,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ display: "inline-flex", width: "100%" }}>
                          <Button
                            className={
                              !canUserEdit(
                                this.props.userData,
                                null,
                                FeatureCodes.MS_COST_SAHAYAK_GUJRAT_EDIT
                              )
                                ? "disabledBtnClass"
                                : "btnClass"
                            }
                            disabled={
                              !canUserEdit(
                                this.props.userData,
                                null,
                                FeatureCodes.MS_COST_SAHAYAK_GUJRAT_EDIT
                              )
                            }
                            type="primary"
                            size={"small"}
                            style={{
                              marginLeft: 5,
                              alignSelf: "center",
                              padding: "0px 0px 2px",
                              width: 70,
                              height: 31,

                              backgroundColor: "#20346a",
                              color: "white",
                              borderRadius: 15,
                            }}
                            block
                            htmlType="submit"
                            onClick={() =>
                              rowRecord.isEdit
                                ? this.submitTableRowData(
                                    rowRecord,
                                    index,
                                    false
                                  )
                                : this.handleEditChange(rowRecord, index)
                            }
                          >
                            {" "}
                            {rowRecord.isEdit ? "Submit" : "Edit"}{" "}
                          </Button>

                          <Button
                            className={
                              !rowRecord.isEdit
                                ? "disabledBtnClass"
                                : "btnClass"
                            }
                            type="primary"
                            size={"small"}
                            style={{
                              marginLeft: 5,
                              alignSelf: "center",
                              width: 70,
                              color: "white",
                              borderRadius: 15,
                              padding: "0px 0px 2px",

                              height: 31,
                              backgroundColor: "#20346a",
                            }}
                            block
                            htmlType="submit"
                            disabled={!rowRecord.isEdit}
                            onClick={() =>
                              this.handleCancelClick(rowRecord, index)
                            }
                          >
                            Cancel
                          </Button>
                        </div>
                      </>
                    );
                  },
                },
              ]}
              dataSource={this.setTableData()}
            />
          </div>
        </Spin>
      </div>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
  messageAccess: selectMSMessageRBAC,
});

export default connect(mapStateToProps)(CostBuildupSahayakMaster);

//export default CostBuildUpFarmer;
