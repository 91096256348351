import React from "react";
import "./arrivals-competitor-item.styles.css";

import priceIcon from "../../assets/Wt_Price.svg";
import quantityIcon from "../../assets/Wt_Quantity.svg";
import compIcon from "../../assets/comp_name.svg";

import CustomIcon from "../custom-icon/custom-icon.component";
import NumericInput from "../numeric-input/numeric-input.component";
import CustomDropdown from "../custom-dropdown/custom-dropdown.component";

import { Input, Select, message } from "antd";

/**
 *
 * @description Competitor details container inside each arrival entry card component.
 * @component
 * @extends {React.Component}
 */
class ArrivalsCompetitorItem extends React.Component {
  state = {
    comp1: true,
    comp2: false,
    comp3: false,
    comp4: false,
    comp5: false,
    competitorCount: 1,
    clickCount: 2,
    comp1Name: "",
    comp2Name: "",
    comp3Name: "",
    comp4Name: "",
    comp5Name: "",
  };
  componentWillMount() {
    this.waitForCompetitorList();
  }

  /**
   * @function
   * @description Wait to recieve competitor data from parent component.
   * @memberof ArrivalsCompetitorItem
   */
  waitForCompetitorList() {
    if (this.props.competitorData && this.props.competitorData.length !== 0) {
      let count = 0;
      this.props.competitorData.map((item) => {
        if (item.competitor_name !== "") {
          count++;
        }
      });

      this.setState({
        comp1: count === 0 ? true : false,
        competitorCount: count === 0 ? 1 : count,
        clickCount: count === 0 ? 2 : count + 1,
      });
      return;
    } else {
      setTimeout(() => {
        this.waitForCompetitorList();
      }, 100);
    }
  }

  render() {
    const {
      setParentState,
      competitorData,
      varietyMinRange,
      varietyMaxRange,
      competitorsList
    } = this.props;

    message.config({
      maxCount: 1,
    });

    return (
      <div>
        <div className="icon-container-comp">
          <div
            className="plus-icon-comp"
            onClick={() => {
              if (this.state.competitorCount >= 5) {
                message.warning("Maximum 5 competitors allowed", 1);
              }
              this.setState(
                (prevState) => ({
                  competitorCount: prevState.competitorCount + 1,
                }),
                () => console.log("count is : ", this.state.competitorCount)
              );
            }}
          >
            +
          </div>
        </div>
        <div className="competitor-multiple-container">
          {/* 1st */}
          <div
            key="1"
            className="competitor-item-container"
            style={{
              display: `${this.state.competitorCount > 0 ? "flex" : "none"}`,
              marginTop: "12px",
            }}
          >
            <div className="single-comp-container">
              <div className="competitor-name-input">
                <CustomIcon className="side-icon" image={compIcon} />
                {/* <Input
                  defaultValue={
                    competitorData && competitorData.length >= 0
                      ? competitorData[0].competitor_name
                      : ""
                  }
                  type="text"
                  placeholder="ENTER COMPETITOR NAME"
                  onChange={(e) => {
                    this.setState({ comp1Name: e.target.value });
                    setParentState(
                      "competitor",
                      "competitor_name",
                      e.target.value,
                      0
                    );
                  }}
                  style={{ display: `${this.state.comp1 ? "unset" : "none"}` }}
                /> */}
                <Select
                  defaultValue={
                    competitorData && competitorData.length >= 0
                    ? competitorData[0].competitor_name ===""?"SELECT COMPETITOR NAME":competitorData[0].competitor_name
                    : "SELECT COMPETITOR NAME"
                  }
                  // disabled={this.state.isSubmitted}
                  showSearch
                  showArrow
                  //  mode="multiple"
                  placeholder="SELECT COMPETITOR NAME"
                  onSelect={(value) => {
                    this.setState({ comp1Name: value });
                    setParentState(
                      "competitor",
                      "competitor_name",
                      value,
                      0
                    );
                  }}
                  style={{ display: `${this.state.comp1 ? "unset" : "none"}` }}
                >
                  {competitorsList.map((competitor, idx) => (
                    <Select.Option key={competitor.competitor_id} value={competitor.competitor_name}>
                      {competitor.competitor_name}
                    </Select.Option>
                  ))}
                </Select>
                <div
                  className="static-name-comp"
                  onClick={() =>
                    this.setState((prevState) => ({
                      comp1: !prevState.comp1,
                    }))
                  }
                  style={{ display: `${this.state.comp1 ? "none" : "unset"}` }}
                >
                  {competitorData &&
                    competitorData.length > 0 &&
                    competitorData[0].competitor_name !== ""
                    ? competitorData[0].competitor_name
                    : this.state.comp1Name !== ""
                      ? this.state.comp1Name
                      : "SELECT COMPETITOR"}
                </div>
              </div>

              <div
                onClick={() =>
                  this.setState((prevState) => ({ comp1: !prevState.comp1 }))
                }
                className="competitor-expand-btn"
                style={{
                  display: "flex",
                  justifyContent: `${!this.state.comp1 ? "space-between" : "flex-end"
                    }`,
                  transform: `${this.state.comp1 ? "rotateZ(-90deg)" : ""}`,
                }}
              >
                ‹
              </div>
            </div>

            <div style={{ display: `${this.state.comp1 ? "unset" : "none"}` }}>
              <div className="icon-title-container">
                <CustomIcon className="side-icon" image={priceIcon} />
                <h5 className="icon-text">Competitor Price</h5>
              </div>
              <div className="competitor-min-max">
                <div className="min-max-field-container">
                  <div className="super-text-left">Min</div>

                  <NumericInput
                    minRange={varietyMinRange}
                    maxRange={varietyMaxRange - 1}
                    defaultValue={
                      competitorData && competitorData.length > 0
                        ? competitorData[0].competitor_min_price
                        : ""
                    }
                    unitText={"₹/Qntl"}
                    setParentState={setParentState}
                    typeVariety="competitor"
                    nameVariety="competitor_min_price"
                    index={0}
                  />
                </div>
                <h4 style={{ color: "#1a3062" }}>to</h4>
                <div className="min-max-field-container">
                  <div className="super-text-left">Max</div>
                  <NumericInput
                    minRange={varietyMinRange + 1}
                    maxRange={varietyMaxRange}
                    defaultValue={
                      competitorData && competitorData.length > 0
                        ? competitorData[0].competitor_max_price
                        : ""
                    }
                    unitText={"₹/Qntl"}
                    setParentState={setParentState}
                    typeVariety="competitor"
                    nameVariety="competitor_max_price"
                    index={0}
                  />
                </div>
              </div>
              <div className="purchase-plan-container">
                <div className="icon-title-container">
                  <CustomIcon className="side-icon" image={quantityIcon} />
                  <h5 className="icon-text">Purchase Plan</h5>
                </div>
                <div className="purchase-plan-input">
                  <NumericInput
                    defaultValue={
                      competitorData && competitorData.length > 0
                        ? competitorData[0].purchase_plan
                        : ""
                    }
                    unitText={"Qntl"}
                    setParentState={setParentState}
                    typeVariety="competitor"
                    nameVariety="purchase_plan"
                    index={0}
                  />
                </div>
              </div>
              <div></div>
            </div>
          </div>

          {/* 2nd */}
          <div
            key="2"
            className="competitor-item-container"
            style={{
              display: `${this.state.competitorCount > 1 ? "flex" : "none"}`,
            }}
          >
            <div className="single-comp-container">
              <div className="competitor-name-input">
                <CustomIcon className="side-icon" image={compIcon} />
                {/* <Input
                  defaultValue={
                    competitorData && competitorData.length > 1
                      ? competitorData[1].competitor_name
                      : ""
                  }
                  type="text"
                  placeholder="ENTER COMPETITOR NAME"
                  onChange={(e) => {
                    this.setState({ comp2Name: e.target.value });
                    setParentState(
                      "competitor",
                      "competitor_name",
                      e.target.value,
                      1
                    );
                  }}
                  style={{ display: `${this.state.comp2 ? "unset" : "none"}` }}
                /> */}
                <Select
                  defaultValue={
                    competitorData && competitorData.length > 1
                      ? competitorData[1].competitor_name
                      : "SELECT COMPETITOR NAME"
                  }
                  // disabled={this.state.isSubmitted}
                  showSearch
                  showArrow
                  //  mode="multiple"
                  placeholder="SELECT COMPETITOR NAME"
                  onSelect={(value) => {
                    this.setState({ comp2Name: value });
                    setParentState(
                      "competitor",
                      "competitor_name",
                      value,
                      1
                    );
                  }}
                  style={{ display: `${this.state.comp2 ? "unset" : "none"}` }}
                >
                  {competitorsList.map((competitor, idx) => (
                    <Select.Option key={competitor.competitor_id} value={competitor.competitor_name}>
                      {competitor.competitor_name}
                    </Select.Option>
                  ))}
                </Select>
                <div
                  className="static-name-comp"
                  onClick={() =>
                    this.setState((prevState) => ({
                      comp2: !prevState.comp2,
                    }))
                  }
                  style={{ display: `${this.state.comp2 ? "none" : "unset"}` }}
                >
                  {competitorData &&
                    competitorData.length > 1 &&
                    competitorData[1].competitor_name !== ""
                    ? competitorData[1].competitor_name
                    : this.state.comp2Name !== ""
                      ? this.state.comp2Name
                      : "SELECT COMPETITOR"}
                </div>
              </div>

              <div
                onClick={() =>
                  this.setState((prevState) => ({ comp2: !prevState.comp2 }))
                }
                className="competitor-expand-btn"
                style={{
                  display: "flex",
                  justifyContent: `${!this.state.comp2 ? "space-between" : "flex-end"
                    }`,
                  transform: `${this.state.comp2 ? "rotateZ(-90deg)" : ""}`,
                }}
              >
                {this.state.comp2 ? "‹" : "‹"}
              </div>
            </div>

            <div style={{ display: `${this.state.comp2 ? "unset" : "none"}` }}>
              <div className="icon-title-container">
                <CustomIcon className="side-icon" image={priceIcon} />
                <h5 className="icon-text">Competitor Price</h5>
              </div>
              <div className="competitor-min-max">
                <div className="min-max-field-container">
                  <div className="super-text-left">Min</div>

                  <NumericInput
                    minRange={varietyMinRange}
                    maxRange={varietyMaxRange - 1}
                    defaultValue={
                      competitorData && competitorData.length > 1
                        ? competitorData[1].competitor_min_price
                        : ""
                    }
                    unitText={"₹/Qntl"}
                    setParentState={setParentState}
                    typeVariety="competitor"
                    nameVariety="competitor_min_price"
                    index={1}
                  />
                </div>
                <h4 style={{ color: "#1a3062" }}>to</h4>
                <div className="min-max-field-container">
                  <div className="super-text-left">Max</div>
                  <NumericInput
                    minRange={varietyMinRange + 1}
                    maxRange={varietyMaxRange}
                    defaultValue={
                      competitorData && competitorData.length > 1
                        ? competitorData[1].competitor_max_price
                        : ""
                    }
                    unitText={"₹/Qntl"}
                    setParentState={setParentState}
                    typeVariety="competitor"
                    nameVariety="competitor_max_price"
                    index={1}
                  />
                </div>
              </div>
              <div className="purchase-plan-container">
                <div className="icon-title-container">
                  <CustomIcon className="side-icon" image={quantityIcon} />
                  <h5 className="icon-text">Purchase Plan</h5>
                </div>
                <div className="purchase-plan-input">
                  <NumericInput
                    defaultValue={
                      competitorData && competitorData.length > 1
                        ? competitorData[1].purchase_plan
                        : ""
                    }
                    unitText={"Qntl"}
                    setParentState={setParentState}
                    typeVariety="competitor"
                    nameVariety="purchase_plan"
                    index={1}
                  />
                </div>
              </div>
              <div></div>
            </div>
          </div>

          {/* 3rd */}
          <div
            key="3"
            className="competitor-item-container"
            style={{
              display: `${this.state.competitorCount > 2 ? "flex" : "none"}`,
            }}
          >
            <div className="single-comp-container">
              <div className="competitor-name-input">
                <CustomIcon className="side-icon" image={compIcon} />
                {/* <Input
                  defaultValue={
                    competitorData && competitorData.length > 2
                      ? competitorData[2].competitor_name
                      : ""
                  }
                  type="text"
                  placeholder="ENTER COMPETITOR NAME"
                  onChange={(e) => {
                    this.setState({ comp3Name: e.target.value });
                    setParentState(
                      "competitor",
                      "competitor_name",
                      e.target.value,
                      2
                    );
                  }}
                  style={{ display: `${this.state.comp3 ? "unset" : "none"}` }}
                /> */}
                <Select
                  defaultValue={
                    competitorData && competitorData.length > 2
                      ? competitorData[2].competitor_name
                      : "SELECT COMPETITOR NAME"
                  }
                  // disabled={this.state.isSubmitted}
                  showSearch
                  showArrow
                  //  mode="multiple"
                  placeholder="SELECT COMPETITOR NAME"
                  onSelect={(value) => {
                    this.setState({ comp3Name: value });
                    setParentState(
                      "competitor",
                      "competitor_name",
                      value,
                      2
                    );
                  }}
                  style={{ display: `${this.state.comp3 ? "unset" : "none"}` }}
                >
                  {competitorsList.map((competitor, idx) => (
                    <Select.Option key={competitor.competitor_id} value={competitor.competitor_name}>
                      {competitor.competitor_name}
                    </Select.Option>
                  ))}
                </Select>
                <div
                  className="static-name-comp"
                  onClick={() =>
                    this.setState((prevState) => ({
                      comp3: !prevState.comp3,
                    }))
                  }
                  style={{ display: `${this.state.comp3 ? "none" : "unset"}` }}
                >
                  {competitorData &&
                    competitorData.length > 2 &&
                    competitorData[2].competitor_name !== ""
                    ? competitorData[2].competitor_name
                    : this.state.comp3Name !== ""
                      ? this.state.comp3Name
                      : "SELECT COMPETITOR"}
                </div>
              </div>

              <div
                onClick={() =>
                  this.setState((prevState) => ({ comp3: !prevState.comp3 }))
                }
                className="competitor-expand-btn"
                style={{
                  display: "flex",
                  justifyContent: `${!this.state.comp3 ? "space-between" : "flex-end"
                    }`,
                  transform: `${this.state.comp3 ? "rotateZ(-90deg)" : ""}`,
                }}
              >
                {this.state.comp3 ? "‹" : "‹"}
              </div>
            </div>

            <div style={{ display: `${this.state.comp3 ? "unset" : "none"}` }}>
              <div className="icon-title-container">
                <CustomIcon className="side-icon" image={priceIcon} />
                <h5 className="icon-text">Competitor Price</h5>
              </div>
              <div className="competitor-min-max">
                <div className="min-max-field-container">
                  <div className="super-text-left">Min</div>

                  <NumericInput
                    minRange={varietyMinRange}
                    maxRange={varietyMaxRange - 1}
                    defaultValue={
                      competitorData && competitorData.length > 2
                        ? competitorData[2].competitor_min_price
                        : ""
                    }
                    unitText={"₹/Qntl"}
                    setParentState={setParentState}
                    typeVariety="competitor"
                    nameVariety="competitor_min_price"
                    index={2}
                  />
                </div>
                <h4 style={{ color: "#1a3062" }}>to</h4>
                <div className="min-max-field-container">
                  <div className="super-text-left">Max</div>
                  <NumericInput
                    minRange={varietyMinRange + 1}
                    maxRange={varietyMaxRange}
                    defaultValue={
                      competitorData && competitorData.length > 2
                        ? competitorData[2].competitor_max_price
                        : ""
                    }
                    unitText={"₹/Qntl"}
                    setParentState={setParentState}
                    typeVariety="competitor"
                    nameVariety="competitor_max_price"
                    index={2}
                  />
                </div>
              </div>
              <div className="purchase-plan-container">
                <div className="icon-title-container">
                  <CustomIcon className="side-icon" image={quantityIcon} />
                  <h5 className="icon-text">Purchase Plan</h5>
                </div>
                <div className="purchase-plan-input">
                  <NumericInput
                    defaultValue={
                      competitorData && competitorData.length > 2
                        ? competitorData[2].purchase_plan
                        : ""
                    }
                    unitText={"Qntl"}
                    setParentState={setParentState}
                    typeVariety="competitor"
                    nameVariety="purchase_plan"
                    index={2}
                  />
                </div>
              </div>
              <div></div>
            </div>
          </div>

          {/* 4th */}
          <div
            key="4"
            className="competitor-item-container"
            style={{
              display: `${this.state.competitorCount > 3 ? "flex" : "none"}`,
            }}
          >
            <div className="single-comp-container">
              <div className="competitor-name-input">
                <CustomIcon className="side-icon" image={compIcon} />
                {/* <Input
                  defaultValue={
                    competitorData && competitorData.length > 3
                      ? competitorData[3].competitor_name
                      : ""
                  }
                  type="text"
                  placeholder="ENTER COMPETITOR NAME"
                  onChange={(e) => {
                    this.setState({ comp4Name: e.target.value });
                    setParentState(
                      "competitor",
                      "competitor_name",
                      e.target.value,
                      3
                    );
                  }}
                  style={{ display: `${this.state.comp4 ? "unset" : "none"}` }}
                /> */}
                <Select
                  defaultValue={
                    competitorData && competitorData.length > 3
                      ? competitorData[3].competitor_name
                      : "SELECT COMPETITOR NAME"
                  }
                  // disabled={this.state.isSubmitted}
                  showSearch
                  showArrow
                  //  mode="multiple"
                  placeholder="SELECT COMPETITOR NAME"
                  onSelect={(value) => {
                    this.setState({ comp4Name: value });
                    setParentState(
                      "competitor",
                      "competitor_name",
                      value,
                      3
                    );
                  }}
                  style={{ display: `${this.state.comp4 ? "unset" : "none"}` }}
                >
                  {competitorsList.map((competitor, idx) => (
                    <Select.Option key={competitor.competitor_id} value={competitor.competitor_name}>
                      {competitor.competitor_name}
                    </Select.Option>
                  ))}
                </Select>
                <div
                  className="static-name-comp"
                  onClick={() =>
                    this.setState((prevState) => ({
                      comp4: !prevState.comp4,
                    }))
                  }
                  style={{ display: `${this.state.comp4 ? "none" : "unset"}` }}
                >
                  {competitorData &&
                    competitorData.length > 3 &&
                    competitorData[3].competitor_name !== ""
                    ? competitorData[3].competitor_name
                    : this.state.comp4Name !== ""
                      ? this.state.comp4Name
                      : "SELECT COMPETITOR"}
                </div>
              </div>

              <div
                onClick={() =>
                  this.setState((prevState) => ({ comp4: !prevState.comp4 }))
                }
                className="competitor-expand-btn"
                style={{
                  display: "flex",
                  justifyContent: `${!this.state.comp4 ? "space-between" : "flex-end"
                    }`,
                  transform: `${this.state.comp4 ? "rotateZ(-90deg)" : ""}`,
                }}
              >
                {this.state.comp4 ? "‹" : "‹"}
              </div>
            </div>

            <div style={{ display: `${this.state.comp4 ? "unset" : "none"}` }}>
              <div className="icon-title-container">
                <CustomIcon className="side-icon" image={priceIcon} />
                <h5 className="icon-text">Competitor Price</h5>
              </div>
              <div className="competitor-min-max">
                <div className="min-max-field-container">
                  <div className="super-text-left">Min</div>

                  <NumericInput
                    minRange={varietyMinRange}
                    maxRange={varietyMaxRange - 1}
                    defaultValue={
                      competitorData && competitorData.length > 3
                        ? competitorData[3].competitor_min_price
                        : ""
                    }
                    unitText={"₹/Qntl"}
                    setParentState={setParentState}
                    typeVariety="competitor"
                    nameVariety="competitor_min_price"
                    index={3}
                  />
                </div>
                <h4 style={{ color: "#1a3062" }}>to</h4>
                <div className="min-max-field-container">
                  <div className="super-text-left">Max</div>
                  <NumericInput
                    minRange={varietyMinRange + 1}
                    maxRange={varietyMaxRange}
                    defaultValue={
                      competitorData && competitorData.length > 3
                        ? competitorData[3].competitor_max_price
                        : ""
                    }
                    unitText={"₹/Qntl"}
                    setParentState={setParentState}
                    typeVariety="competitor"
                    nameVariety="competitor_max_price"
                    index={3}
                  />
                </div>
              </div>
              <div className="purchase-plan-container">
                <div className="icon-title-container">
                  <CustomIcon className="side-icon" image={quantityIcon} />
                  <h5 className="icon-text">Purchase Plan</h5>
                </div>
                <div className="purchase-plan-input">
                  <NumericInput
                    defaultValue={
                      competitorData && competitorData.length > 3
                        ? competitorData[3].purchase_plan
                        : ""
                    }
                    unitText={"Qntl"}
                    setParentState={setParentState}
                    typeVariety="competitor"
                    nameVariety="purchase_plan"
                    index={3}
                  />
                </div>
              </div>
              <div></div>
            </div>
          </div>

          {/* 5th */}
          <div
            key="5"
            className="competitor-item-container"
            style={{
              display: `${this.state.competitorCount > 4 ? "flex" : "none"}`,
            }}
          >
            <div className="single-comp-container">
              <div className="competitor-name-input">
                <CustomIcon className="side-icon" image={compIcon} />

                {/* <Input
                  defaultValue={
                    competitorData && competitorData.length > 4
                      ? competitorData[4].competitor_name
                      : ""
                  }
                  type="text"
                  placeholder="ENTER COMPETITOR NAME"
                  onChange={(e) => {
                    this.setState({ comp5Name: e.target.value });
                    setParentState(
                      "competitor",
                      "competitor_name",
                      e.target.value,
                      4
                    );
                  }}
                  style={{ display: `${this.state.comp5 ? "unset" : "none"}` }}
                /> */}
                <Select
                  defaultValue={
                    competitorData && competitorData.length > 4
                      ? competitorData[4].competitor_name
                      : "SELECT COMPETITOR NAME"
                  }
                  // disabled={this.state.isSubmitted}
                  showSearch
                  showArrow
                  //  mode="multiple"
                  placeholder="SELECT COMPETITOR NAME"
                  onSelect={(value) => {
                    this.setState({ comp5Name: value });
                    setParentState(
                      "competitor",
                      "competitor_name",
                      value,
                      4
                    );
                  }}
                  style={{ display: `${this.state.comp5 ? "unset" : "none"}` }}
                >
                  {competitorsList.map((competitor, idx) => (
                    <Select.Option key={competitor.competitor_id} value={competitor.competitor_name}>
                      {competitor.competitor_name}
                    </Select.Option>
                  ))}
                </Select>
                <div
                  className="static-name-comp"
                  onClick={() =>
                    this.setState((prevState) => ({
                      comp5: !prevState.comp5,
                    }))
                  }
                  style={{ display: `${this.state.comp5 ? "none" : "unset"}` }}
                >
                  {competitorData &&
                    competitorData.length > 4 &&
                    competitorData[4].competitor_name !== ""
                    ? competitorData[4].competitor_name
                    : this.state.comp5Name !== ""
                      ? this.state.comp5Name
                      : "SELECT COMPETITOR"}
                </div>
              </div>

              <div
                onClick={() =>
                  this.setState((prevState) => ({ comp5: !prevState.comp5 }))
                }
                className="competitor-expand-btn"
                style={{
                  display: "flex",
                  justifyContent: `${!this.state.comp5 ? "space-between" : "flex-end"
                    }`,
                  transform: `${this.state.comp5 ? "rotateZ(-90deg)" : ""}`,
                }}
              >
                {this.state.comp5 ? "‹" : "‹"}
              </div>
            </div>

            <div style={{ display: `${this.state.comp5 ? "unset" : "none"}` }}>
              <div className="icon-title-container">
                <CustomIcon className="side-icon" image={priceIcon} />
                <h5 className="icon-text">Competitor Price</h5>
              </div>
              <div className="competitor-min-max">
                <div className="min-max-field-container">
                  <div className="super-text-left">Min</div>

                  <NumericInput
                    minRange={varietyMinRange}
                    maxRange={varietyMaxRange - 1}
                    defaultValue={
                      competitorData && competitorData.length > 4
                        ? competitorData[4].competitor_min_price
                        : ""
                    }
                    unitText={"₹/Qntl"}
                    setParentState={setParentState}
                    typeVariety="competitor"
                    nameVariety="competitor_min_price"
                    index={4}
                  />
                </div>
                <h4 style={{ color: "#1a3062" }}>to</h4>
                <div className="min-max-field-container">
                  <div className="super-text-left">Max</div>
                  <NumericInput
                    minRange={varietyMinRange + 1}
                    maxRange={varietyMaxRange}
                    defaultValue={
                      competitorData && competitorData.length > 4
                        ? competitorData[4].competitor_max_price
                        : ""
                    }
                    unitText={"₹/Qntl"}
                    setParentState={setParentState}
                    typeVariety="competitor"
                    nameVariety="competitor_max_price"
                    index={4}
                  />
                </div>
              </div>
              <div className="purchase-plan-container">
                <div className="icon-title-container">
                  <CustomIcon className="side-icon" image={quantityIcon} />
                  <h5 className="icon-text">Purchase Plan</h5>
                </div>
                <div className="purchase-plan-input">
                  <NumericInput
                    defaultValue={
                      competitorData && competitorData.length > 4
                        ? competitorData[4].purchase_plan
                        : ""
                    }
                    unitText={"Qntl"}
                    setParentState={setParentState}
                    typeVariety="competitor"
                    nameVariety="purchase_plan"
                    index={4}
                  />
                </div>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ArrivalsCompetitorItem;
