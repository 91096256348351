import React from "react";
import "./download-report.styles.css";
import { Component } from "react";

import API from "../../api";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { setSuggestedABDData } from "../../redux/buying-recommendation/buying-recommendation.actions";
import { selectSuggestedABDData } from "../../redux/buying-recommendation/buying-recommendation.selectors";
import { selectUserData } from "../../redux/user/user.selectors";

import {
  Table,
  Spin,
  message,
  Button,
  Checkbox,
  Input,
  Typography,
  Row,
  Col,
} from "antd";
const { Search } = Input;

const reportTableProps = {
  pagination: false,
  size: "default",
  bordered: true,
  scroll: { x: 150, y: window.innerHeight - 325 },
};

/**
 * @component
 * @description Reusable component to display table header.
 * @property {*} props
 * @memberof DownloadReport
 */
const TableColumnHeader = (props) => {
  const { title, subtitle } = props;
  return (
    <div className="column-header-container">
      <div
        style={{
          color: "white",
          fontSize: "12px",
          whiteSpace: "nowrap",
          fontWeight: "normal",
          textAlign: "left",
        }}
      >
        {title}
      </div>
      <div
        style={{
          color: "#cbdfff",
          fontSize: "11px",
          fontWeight: "normal",
          textAlign: "left",
          // subtitle && title.length < subtitle.length ? "left" : "center"
        }}
      >
        {subtitle}
      </div>
    </div>
  );
};

/**
 *
 * @classdesc The Buying reccomendation tab component.
 * @description Displays a table containing the daily output from the modal
 * @component
 */
class ABDBuyingRecomandation extends Component {
  state = {
    isSpinnerOnTable: true,
    date: "",
    time: "",
    showEditedRecords: false,
    factorySearchTerm: "",
    hubSearchTerm: "",
    nameSearchTerm: "",
    filteredInfo: [],
  };

  getSourceName = (id) => {
    switch (id) {
      case 1:
        return "Mandi";
      case 2:
        return "Trader";
      case 3:
        return "Farmer";
      default:
        return "";
        break;
    }
  };

  /**
   * @function
   * @description Wait until the user logs in so that a valid access token is generated
   * to call the get api for daily recommentation data.
   * @memberof DownloadReport
   */
  waitUntilLogin = () => {
    if (localStorage.getItem("accessToken") != null) {
      this.setState({ isSpinnerOnTable: true });

      API.get("/abd_recommendations")
        .then((response) => {
          // let count = 0;
          const modifiedRecommendationData = response.data.data.data_list.map(
            (item) => {
              return {
                key: item.buying_decision_id,
                //date: item.date,
                buyingDecisionId: item.buying_decision_id,
                branch: item.branch ? item.branch : "",
                region: item.region ? item.region : "",
                route: item.route ? item.route : "",
                hub: item.hub,
                factory_name: item.factory_name,
                factory_state: item.factory_state,
                factory_unique_key: item.factory_unique_key,
                hub_unique_key: item.hub_unique_key,
                factory_buying: item.factory_buying,
                buyerType: this.getSourceName(item.source_type),
                buyerName: item.mandi,
                variety: item.variety,
                suggestedQty: item.suggested_quantity
                  ? Math.round(item.suggested_quantity)
                  : 0,
                price: item.suggested_price
                  ? Math.round(item.suggested_price)
                  : 0,
                plannedQty:
                  item.planned_quantity !== ""
                    ? Math.round(item.planned_quantity)
                    : "",
                plannedPrice:
                  item.planned_price !== ""
                    ? Math.round(item.planned_price)
                    : "",
                // isChanged: true
              };
            }
          );

          const dateTime = new Date(response.data.data.datetime);

          const time = new Date(
            dateTime.getTime() + dateTime.getTimezoneOffset() * 60000
          ).toLocaleTimeString();

          this.setState({
            isSpinnerOnTable: false,
            date: dateTime,
            time: time,
          });

          this.props.setSuggestedABDData(modifiedRecommendationData);
        })
        .catch((err) => {
          message.warning(
            "Server is not responding at the moment. Please refresh the page"
          );
          this.setState({ isSpinnerOnTable: false });
        });
    } else {
      setTimeout(() => this.waitUntilLogin(), 100);
    }
  };

  componentDidMount() {
    this.waitUntilLogin();
  }

  render() {
    const ConfigOptions = {
      maxCount: 1,
    };
    message.config(ConfigOptions);

    /**
     * @function
     * @description Retrieves the daily reccomented data from redux store and assigns it as the
     * data source for the table to display
     * @memberof DownloadReport
     */
    const setRecommendationTableData = () => {
      let tableData = this.props.suggestedABDData;

      let modifiedTableData;

      if (!this.state.showEditedRecords) {
        modifiedTableData = tableData;
      } else {
        modifiedTableData = tableData.filter(
          (item) =>
            item.plannedQty !== item.suggestedQty ||
            item.plannedPrice !== item.price
        );
      }

      return modifiedTableData;
    };

    /**
     *
     * @function
     * @description Generates the filter objects required for table on clicking the table header.
     * @param {string} label the column key name for which filtering is needed.
     * @returns an object for filtering corresponding to each row.
     * @memberof DownloadReport
     */
    const createFilters = (label) => {
      let filterData = this.props.suggestedABDData;
      let tempData = [];
      //#region remove duplicate objects
      let uniqueFilterData = [];
      tempData = filterData.filter((item) => {
        if (
          (!this.state.filteredInfo.branch ||
            this.state.filteredInfo.branch.length === 0 ||
            this.state.filteredInfo.branch.includes(item.branch)) &&
          (!this.state.filteredInfo.factory_name ||
            this.state.filteredInfo.factory_name.length === 0 ||
            this.state.filteredInfo.factory_name.includes(item.factory_name)) &&
          (!this.state.filteredInfo.factory_state ||
            this.state.filteredInfo.factory_state.length === 0 ||
            this.state.filteredInfo.factory_state.includes(
              item.factory_state
            )) &&
          (!this.state.filteredInfo.route ||
            this.state.filteredInfo.route.length === 0 ||
            this.state.filteredInfo.route.includes(item.route)) &&
          (!this.state.filteredInfo.hub ||
            this.state.filteredInfo.hub.length === 0 ||
            this.state.filteredInfo.hub.includes(item.hub)) &&
          (!this.state.filteredInfo.variety ||
            this.state.filteredInfo.variety.length === 0 ||
            this.state.filteredInfo.variety.includes(item.variety)) &&
          (!this.state.filteredInfo.buyerType ||
            this.state.filteredInfo.buyerType.length === 0 ||
            this.state.filteredInfo.buyerType.includes(item.buyerType)) &&
          (!this.state.filteredInfo.buyerName ||
            this.state.filteredInfo.buyerName.length === 0 ||
            this.state.filteredInfo.buyerName.includes(item.buyerName))
        ) {
          return item;
        }
      });

      filterData =
        tempData.length > 0 &&
        label !== "branch" &&
        label !== "variety" &&
        label !== "buyerType"
          ? tempData
          : filterData;
      filterData.map((mainItem) =>
        uniqueFilterData.filter((item) => item[label] === mainItem[label])
          .length > 0
          ? null
          : uniqueFilterData.push(mainItem)
      );

      uniqueFilterData = uniqueFilterData
        .map((item) => {
          return {
            text: item[label],
            value: item[label],
          };
        })
        .sort((a, b) => a.text.localeCompare(b.text));

      //#endregion

      return uniqueFilterData;
    };

    //#region

    /**
     * @function
     * @description Displays the date and time string as required by the UI.
     * @param {datetime} date the datetime object of the latest model run
     * @returns A formatted string containing the model run date information.
     * @memberof DownloadReport
     */
    const setDateString = (date) => {
      if (date) {
        var day, month, formattedDate;
        switch (date.getDate()) {
          case 1:
            day = "1st";
            break;
          case 21:
            day = "21st";
            break;
          case 31:
            day = "31st";
            break;
          case 2:
            day = "2nd";
            break;
          case 22:
            day = "22nd";
            break;
          case 3:
            day = "3rd";
            break;
          case 23:
            day = "23rd";
            break;
          default:
            day = `${date.getDate()}th`;
        }
        switch (date.getMonth() + 1) {
          case 1:
            month = " January";
            break;
          case 2:
            month = " February";
            break;
          case 3:
            month = " March";
            break;
          case 4:
            month = " April";
            break;
          case 5:
            month = " May";
            break;
          case 6:
            month = " June";
            break;
          case 7:
            month = " July";
            break;
          case 8:
            month = " August";
            break;
          case 9:
            month = " September";
            break;
          case 10:
            month = " October";
            break;
          case 11:
            month = " November";
            break;
          case 12:
            month = " December";
            break;
          default:
        }

        formattedDate = `${day} ${month} ${date.getFullYear()}`;

        return formattedDate;
      }
    };
    const handleChange = (pagination, filters, sorter) => {
      this.setState({ filteredInfo: filters });
    };
    return (
      <div
        className="download-report-container"
        style={{ height: window.innerHeight - 83 }}
      >
        <div className="time-container">
          {this.props.suggestedABDData.length !== 0 && (
            <>
              <div className="top-left-container">
                <div className="top-run-date">
                  {setDateString(this.state.date)}
                </div>
                <div className="sync-time">
                  {this.state.time && ` Last Sync: ${this.state.time}`}
                </div>
                {/* <div className="mandi-info ">{`|  ${editedRecordCount} ${
                  editedRecordCount > 1 ? "Records" : "Record"
                } Edited`}</div> */}
              </div>

              <div className="top-right-container">
                <Button
                  type="primary"
                  shape="round"
                  size="large"
                  ghost
                  onClick={() => {
                    this.setState({ filteredInfo: [] });
                  }}
                >
                  Reset Filters
                </Button>
              </div>
            </>
          )}
        </div>

        <div
          className="table-container audit-table"
          style={{ overflow: "hidden", height: "300px" }}
        >
          <Spin spinning={this.state.isSpinnerOnTable} tip="Loading...">
            <Table
              // rowClassName={(record, idx) =>
              //   record.plannedQty != record.suggestedQty ||
              //   record.plannedPrice != record.price
              //     ? // && record.buyerType === "mandi"
              //       "changed-row"
              //     : ""
              // }
              {...reportTableProps}
              onChange={handleChange}
              columns={[
                {
                  title: <TableColumnHeader title="Branch" />,
                  dataIndex: "branch",
                  key: "branch",
                  filteredValue: this.state.filteredInfo.branch || null,
                  filters: createFilters("branch"),
                  onFilter: (value, record) => record.branch.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.branch.localeCompare(b.branch),
                  width: 80,
                },
                {
                  title: <TableColumnHeader title="Destination Name" />,
                  dataIndex: "factory_name",
                  key: "factory_name",
                  filteredValue: this.state.filteredInfo.factory_name || null,
                  filters: createFilters("factory_name"),
                  onFilter: (value, record) =>
                    record.factory_name.includes(value),
                  filterDropdown: ({
                    setSelectedKeys,
                    selectedKeys,
                    filters,
                    confirm,
                    clearFilters,
                  }) => {
                    return (
                      <div style={{ padding: 8 }}>
                        <Search
                          placeholder={"Search"}
                          onSearch={(value) =>
                            this.setState({
                              factorySearchTerm: value,
                            })
                          }
                          enterButton
                          style={{
                            width: 180,
                            marginBottom: 8,
                            display: "flex",
                          }}
                          allowClear
                        />
                        <Checkbox.Group
                          value={selectedKeys}
                          onChange={(e) => {
                            setSelectedKeys(e ? e : []);
                          }}
                          style={{
                            height: "40vh",
                            width: "180px",
                            whiteSpace: "nowrap",
                            overflow: "auto",
                          }}
                        >
                          <Row>
                            {filters &&
                              filters.map((e) => {
                                if (
                                  e.text
                                    .toString()
                                    .toLowerCase()
                                    .includes(
                                      this.state.factorySearchTerm
                                        .toString()
                                        .toLowerCase()
                                    )
                                )
                                  return (
                                    <Col span={24} key={e.value}>
                                      <Checkbox value={e.value}>
                                        <Typography.Paragraph
                                          style={{
                                            display: "inline-block",
                                            margin: 0,
                                            verticalAlign: "bottom",
                                            width: 130,
                                          }}
                                          ellipsis
                                        >
                                          {e.text}
                                        </Typography.Paragraph>
                                      </Checkbox>
                                    </Col>
                                  );
                              })}
                          </Row>
                        </Checkbox.Group>
                        <div>
                          <Button type="primary" onClick={() => confirm()}>
                            Search
                          </Button>
                          <Button
                            type="danger"
                            onClick={() => {
                              clearFilters();
                              this.setState({
                                factorySearchTerm: "",
                              });
                            }}
                            style={{
                              borderLeftColor: "inherit",
                              left: "10%",
                            }}
                          >
                            Reset
                          </Button>
                        </div>
                      </div>
                    );
                  },
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) =>
                    a.factory_name.localeCompare(b.factory_name),
                  width: 105,
                },
                {
                  title: <TableColumnHeader title="Destination State" />,
                  dataIndex: "factory_state",
                  key: "factory_state",
                  filteredValue: this.state.filteredInfo.factory_state || null,
                  filters: createFilters("factory_state"),
                  onFilter: (value, record) =>
                    record.factory_state.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) =>
                    a.factory_state.localeCompare(b.factory_state),
                  width: 101,
                },

                // {
                //   title: <TableColumnHeader title="Region" />,
                //   dataIndex: "region",
                //   key: "region",
                //   filters: createFilters("region"),
                //   onFilter: (value, record) => record.region.includes(value),
                //   sortDirections: ["descend", "ascend"],
                //   sorter: (a, b) => a.region.localeCompare(b.region),
                //   width: 80,
                // },
                {
                  title: <TableColumnHeader title="Route" />,
                  dataIndex: "route",
                  key: "route",
                  filteredValue: this.state.filteredInfo.route || null,
                  filters: createFilters("route"),
                  onFilter: (value, record) => record.route.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.route.localeCompare(b.route),
                  width: 80,
                },
                {
                  title: <TableColumnHeader title="Source Hub" />,
                  dataIndex: "hub",
                  key: "hub",
                  filteredValue: this.state.filteredInfo.hub || null,
                  filters: createFilters("hub"),
                  onFilter: (value, record) => record.hub.includes(value),
                  filterDropdown: ({
                    setSelectedKeys,
                    selectedKeys,
                    filters,
                    confirm,
                    clearFilters,
                  }) => {
                    return (
                      <div style={{ padding: 8 }}>
                        <Search
                          placeholder={"Search"}
                          onSearch={(value) =>
                            this.setState({
                              hubSearchTerm: value,
                            })
                          }
                          enterButton
                          style={{
                            width: 180,
                            marginBottom: 8,
                            display: "flex",
                          }}
                          allowClear
                        />
                        <Checkbox.Group
                          value={selectedKeys}
                          onChange={(e) => {
                            setSelectedKeys(e ? e : []);
                          }}
                          style={{
                            height: "40vh",
                            width: "180px",
                            whiteSpace: "nowrap",
                            overflow: "auto",
                          }}
                        >
                          <Row>
                            {filters &&
                              filters.map((e) => {
                                if (
                                  e.text
                                    .toString()
                                    .toLowerCase()
                                    .includes(
                                      this.state.hubSearchTerm
                                        .toString()
                                        .toLowerCase()
                                    )
                                )
                                  return (
                                    <Col span={24} key={e.value}>
                                      <Checkbox value={e.value}>
                                        <Typography.Paragraph
                                          style={{
                                            display: "inline-block",
                                            margin: 0,
                                            verticalAlign: "bottom",
                                            width: 130,
                                          }}
                                          ellipsis
                                        >
                                          {e.text}
                                        </Typography.Paragraph>
                                      </Checkbox>
                                    </Col>
                                  );
                              })}
                          </Row>
                        </Checkbox.Group>
                        <div>
                          <Button type="primary" onClick={() => confirm()}>
                            Search
                          </Button>
                          <Button
                            type="danger"
                            onClick={() => {
                              clearFilters();
                              this.setState({
                                hubSearchTerm: "",
                              });
                            }}
                            style={{
                              borderLeftColor: "inherit",
                              left: "10%",
                            }}
                          >
                            Reset
                          </Button>
                        </div>
                      </div>
                    );
                  },
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.hub.localeCompare(b.hub),
                  width: 100,
                },
                {
                  title: <TableColumnHeader title="Variety" />,
                  dataIndex: "variety",
                  key: "variety",
                  filteredValue: this.state.filteredInfo.variety || null,
                  filters: createFilters("variety"),
                  onFilter: (value, record) => record.variety.includes(value),
                  // defaultSortOrder: "descend",
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.variety.localeCompare(b.variety),
                  width: 75,
                },
                {
                  title: (
                    <TableColumnHeader
                      title="Type"
                      subtitle="(Mandi/Trader/  Farmer)"
                    />
                  ),
                  dataIndex: "buyerType",
                  key: "buyerType",
                  filteredValue: this.state.filteredInfo.buyerType || null,
                  filters: createFilters("buyerType"),
                  onFilter: (value, record) => record.buyerType.includes(value),
                  width: 75,
                  // render: (value, record) => <div>{getSourceName(value)}</div>,
                },
                {
                  title: (
                    <TableColumnHeader
                      title="Source Name"
                      subtitle="(Mandi/Trader/   Farmer)"
                    />
                  ),
                  dataIndex: "buyerName",
                  key: "buyerName",
                  filteredValue: this.state.filteredInfo.buyerName || null,
                  filters: createFilters("buyerName"),
                  onFilter: (value, record) => record.buyerName.includes(value),
                  filterDropdown: ({
                    setSelectedKeys,
                    selectedKeys,
                    filters,
                    confirm,
                    clearFilters,
                  }) => {
                    return (
                      <div style={{ padding: 8 }}>
                        <Search
                          placeholder={"Search"}
                          onSearch={(value) =>
                            this.setState({
                              nameSearchTerm: value,
                            })
                          }
                          enterButton
                          style={{
                            width: 180,
                            marginBottom: 8,
                            display: "flex",
                          }}
                          allowClear
                        />
                        <Checkbox.Group
                          value={selectedKeys}
                          onChange={(e) => {
                            setSelectedKeys(e ? e : []);
                          }}
                          style={{
                            height: "40vh",
                            width: "180px",
                            whiteSpace: "nowrap",
                            overflow: "auto",
                          }}
                        >
                          <Row>
                            {filters &&
                              filters.map((e) => {
                                if (
                                  e.text
                                    .toString()
                                    .toLowerCase()
                                    .includes(
                                      this.state.nameSearchTerm
                                        .toString()
                                        .toLowerCase()
                                    )
                                )
                                  return (
                                    <Col span={24} key={e.value}>
                                      <Checkbox value={e.value}>
                                        <Typography.Paragraph
                                          style={{
                                            display: "inline-block",
                                            margin: 0,
                                            verticalAlign: "bottom",
                                            width: 130,
                                          }}
                                          ellipsis
                                        >
                                          {e.text}
                                        </Typography.Paragraph>
                                      </Checkbox>
                                    </Col>
                                  );
                              })}
                          </Row>
                        </Checkbox.Group>
                        <div>
                          <Button type="primary" onClick={() => confirm()}>
                            Search
                          </Button>
                          <Button
                            type="danger"
                            onClick={() => {
                              clearFilters();
                              this.setState({
                                nameSearchTerm: "",
                              });
                            }}
                            style={{
                              borderLeftColor: "inherit",
                              left: "10%",
                            }}
                          >
                            Reset
                          </Button>
                        </div>
                      </div>
                    );
                  },
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.buyerName.localeCompare(b.buyerName),
                  width: 95,
                },
                {
                  title: (
                    <div
                      style={{
                        fontSize: "12px",
                        // margin: "8px 0px 0px 60px",
                        marginTop: "8px",
                        fontWeight: "normal",
                      }}
                    >
                      Quantity
                      <span
                        style={{
                          fontSize: "11px",
                          fontWeight: "normal",
                          color: "rgb(203, 223, 255)",
                        }}
                      >
                        &nbsp; (MT)
                      </span>
                    </div>
                  ),

                  children: [
                    {
                      title: "Suggested",
                      dataIndex: "suggestedQty",
                      key: "suggestedQty",
                      sortDirections: ["descend", "ascend"],
                      sorter: (a, b) => a.suggestedQty - b.suggestedQty,
                      width: 80,
                    },
                    // {
                    //   title: "Planned",
                    //   dataIndex: "plannedQty",
                    //   key: "plannedQty",
                    //   sortDirections: ["descend", "ascend"],
                    //   sorter: (a, b) => a.plannedQty - b.plannedQty,
                    //   width: 60,
                    // },
                  ],
                },

                {
                  title: (
                    <div
                      style={{
                        fontSize: "12px",
                        // margin: "8px 0px 0px 40px",
                        marginTop: "8px",
                        fontWeight: "normal",
                      }}
                    >
                      PO Price
                      <span
                        style={{
                          fontSize: "11px",
                          fontWeight: "normal",
                          color: "rgb(203, 223, 255)",
                        }}
                      >
                        &nbsp; (₹/MT)
                      </span>
                    </div>
                  ),
                  children: [
                    {
                      title: "Suggested",
                      dataIndex: "price",
                      key: "price",
                      sortDirections: ["descend", "ascend"],
                      sorter: (a, b) => a.price - b.price,
                      width: 80,
                    },
                    // {
                    //   title: "Planned",
                    //   dataIndex: "plannedPrice",
                    //   key: "plannedPrice",
                    //   sortDirections: ["descend", "ascend"],
                    //   sorter: (a, b) => a.plannedPrice - b.plannedPrice,
                    //   width: 60,
                    // },
                  ],
                },
              ]}
              dataSource={setRecommendationTableData()}
              //dataSource={this.state.recommendationData}
            />
          </Spin>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  suggestedABDData: selectSuggestedABDData,
  userData: selectUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setSuggestedABDData: (data) => dispatch(setSuggestedABDData(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ABDBuyingRecomandation);
