import React from "react";
import "./loading-unloading-cost-master.styles.css";
import { Component } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUserData,selectMSMessageRBAC } from "../../redux/user/user.selectors";
import { canUserEdit,getUserMSRBACMessage } from "../../auth/utils";
import { FeatureCodes } from "../../auth/data";
import {
  Table,
  Spin,
  Input,
  Select,
  Button,
  message,
  Form,
  Modal,
  Radio,
} from "antd";

import API from "../../api";
const { Search } = Input;
const { Option } = Select;
/**
 * @description Component for user management tab
 *
 * @component
 */
class LoadingUnloadingCostMaster extends Component {
  state = {
    isModalVisible: false,
    isAdd: false,
    currentRecord: null,
    userBranches: null,
    searchText: null,
    searchUserType: 0,
    LoadingUnloadingCostMasterTableData: [],
    varietyList: [],
    mandiRegionList: [],
    stateList: [],
    hubRegionList: [],
    hubBranchList: [],
    isSpinnerOnLoad: false,
    isSubmitted: false,
    newData: {
      Loading_Unloading_Cost_ID: undefined,
      State: "",
      Loading_Charges_PerMT: undefined,
      Unloading_Chagres_PerMT: undefined,
      isEdit: false,
    },
    rowData: undefined,
    selectedRegionValue: undefined,
    selectedRegionName: "",
    selectedBranchValue: undefined,
    selectedBranchName: "",
    selectedBranchList: [],
    selectedRegionList: [],
  };
  componentDidMount() {
    this.setState({ isSpinnerOnLoad: true });
    this.getLoadingUnloadingCostMaster();
    this.getvarietyList();
    this.getStateList();
    // this.getPrimaryHub();
    // this.getHubRegion();
    // this.getHubBranch();
  }

  getvarietyList = async () => {
    this.setState({ isSpinnerOnLoad: true });

    //TODO: change api
    await API.get("/get_states")
      .then((stateLis) => {
        this.setState(
          {
            varietyList: stateLis.data.data,
          }
        );
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400) message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };

  getStateList = async () => {
    this.setState({ isSpinnerOnLoad: true });


    await API.get("/list_branch")
      .then((hubList) => {
        this.setState({
          stateList: hubList.data.data,
          isSpinnerOnLoad: false,
        });
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };


  getLoadingUnloadingCostMaster = async () => {
    this.setState({ isSpinnerOnLoad: true });


    await API.get("/loading_unloading_cost")
      .then((varietyResponse) => {

        varietyResponse.data.data.map((dataDetailItem, index) => {
          dataDetailItem.key = index;
          dataDetailItem.isEdit = false;
          dataDetailItem.Unloading_Chagres_PerMT = dataDetailItem.Unloading_Charges_PerMT;
        });
        this.setState(
          {
            LoadingUnloadingCostMasterTableData: varietyResponse.data.data,
            // InflationMasterTableData:[],
          },
          () => this.setState({ isSpinnerOnLoad: false, isAdd: true })
        );

      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400) message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };

  getLoadingUnloadingCostMaster11 = async () => {
    this.setState({ isSpinnerOnLoad: true });


    await API.get("/loading_unloading_cost")
      .then((varietyResponse) => {

        varietyResponse.data.data.map((dataDetailItem, index) => {
          dataDetailItem.key = index;
          dataDetailItem.isEdit = false;
        });
        // this.setState(
        //   {
        //     LoadingUnloadingCostMasterTableData: varietyResponse.data.data,
        //     // LoadingUnloadingCostMasterTableData:[],
        //   },
        //   () => this.setState({ isSpinnerOnLoad: false, isAdd: true })
        // );

      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400) message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };

  setTableData = () => {
    if (this.state.LoadingUnloadingCostMasterTableData !== undefined || this.state.LoadingUnloadingCostMasterTableData.length >= 0) {
      const userTableData = this.state.LoadingUnloadingCostMasterTableData.map((user, index) => {
        return {
          key: user.key,
          Loading_Unloading_Cost_ID: user.Loading_Unloading_Cost_ID,
          State: user.State,
          Loading_Charges_PerMT: user.Loading_Charges_PerMT,
          Unloading_Chagres_PerMT: user.Unloading_Chagres_PerMT,
          isEdit: user.isEdit,
        };
      });

      let dataAfterSearch = userTableData;

      if (this.state.searchText) {
        dataAfterSearch = dataAfterSearch.filter(
          (item) =>
            (item.State !== null &&
              item.State
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase())) 
            // (item.competitor_description !== null &&
            //   item.competitor_description
            //     .toLowerCase()
            //     .includes(this.state.searchText.toLowerCase()))
          // (item.hub_name !== null &&
          //   item.hub_name
          //     .toLowerCase()
          //     .includes(this.state.searchText.toLowerCase())) 
        );
      }
      return dataAfterSearch;
    }
    else {
      return this.state.LoadingUnloadingCostMasterTableData;
    }
  };

  submitTableRowData = (row, index, isPopup) => {
    this.setState({ isSpinnerOnLoad: true, isSubmitted: true });
    let warningmessage = "";
    if (row.State === null || row.State === undefined || row.State === "") {
      warningmessage = "Please select State";
    } else if (row.Loading_Charges_PerMT === null || row.Loading_Charges_PerMT === undefined ||
      row.Loading_Charges_PerMT === "" || row.Loading_Charges_PerMT === 0) {
      warningmessage = "Please enter Loading Charges";
    } else if (row.Unloading_Chagres_PerMT === null || row.Unloading_Chagres_PerMT === undefined ||
      row.Unloading_Chagres_PerMT === "" || row.Unloading_Chagres_PerMT === 0) {
      warningmessage = "Please enter Unloading Charges";
    }

    if (
      warningmessage === "" ||
      warningmessage === undefined ||
      warningmessage === null
    ) {
      this.setState({ isSpinnerOnLoad: true });
      this.setState({ isSubmitted: true })
      let data = {
        Loading_Unloading_Cost_ID: row.Loading_Unloading_Cost_ID,
        State: row.State,
        Loading_Charges_PerMT: row.Loading_Charges_PerMT,
        Unloading_Chagres_PerMT: row.Unloading_Chagres_PerMT,
      };



      API.put("/loading_unloading_cost", data, {

      })
        .then((response) => {
          if (response.status === 200 && response.data.status) {
            message.info(response.data.message, 5);
            this.setModalVisibility(false);
            this.getLoadingUnloadingCostMaster();
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
          } else if (!response.data.status) {
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
            message.error(response.data.message, 5);
            // this.setModalVisibility(false);
          } else {
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
            message.error("Please try again", 5);
          }
        })
        .catch((err) => {
          if (err) {
            if (err.response && err.response.status === 400) message.error("Something went wrong. Please try again later.", 5);
            else {
              message.error("Something went wrong.. Please try again later.", 5);
            }
          } else {
            message.error("Something went wrong... Please try again later.", 5);
          }
          this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
          // this.setModalVisibility(false);
        });
    } else {
      this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
      message.error(warningmessage, 5);
    }
  };

  setModalVisibility = (status) => {
    if (status) {
      const nData = {

        Loading_Unloading_Cost_ID: undefined,
        State: "",
        Loading_Charges_PerMT: undefined,
        Unloading_Chagres_PerMT: undefined,
        isEdit: false,
      };

      this.setState({ newData: nData });
    }

    this.setState({ isModalVisible: status });
  };

  TableColumnHeader = (props) => {
    const { title, subtitle } = props;
    return (
      <div className="column-header-container">
        <div
          style={{
            color: "white",
            fontSize: "12px",
            whiteSpace: "nowrap",
            fontWeight: "normal",
            textAlign: "left",
          }}
        >
          {title}
        </div>
        <div
          style={{
            color: "#cbdfff",
            fontSize: "11px",
            fontWeight: "normal",
            textAlign: "left",
            // subtitle && title.length < subtitle.length ? "left" : "center"
          }}
        >
          {subtitle}
        </div>
      </div>
    );
  };

  createFilters = (label) => {
    let filterData = this.state.LoadingUnloadingCostMasterTableData;
    //#region remove duplicate objects
    let uniqueFilterData = [];

    filterData.map((mainItem) =>
      uniqueFilterData.filter((item) => item[label] === mainItem[label])
        .length > 0
        ? null
        : uniqueFilterData.push(mainItem)
    );

    uniqueFilterData = uniqueFilterData.map((item) => {
      return {
        text: item[label],
        value: item[label],
      };
    });

    uniqueFilterData.sort((a, b) => a.text.localeCompare(b.text));
    //#endregion

    return uniqueFilterData;
  };

  handleDropdownChange = (value, row, target, index, optionlabel, isPopup) => {
    let a;

    if (isPopup === false) {
      a = this.state.LoadingUnloadingCostMasterTableData;

      if (target === "is_active") {
        a[row.key].is_active = Boolean(value);
      } else if (target === "State") {
        a[row.key].State = optionlabel;
        a[row.key].branch_id = value;
      }
      this.setState({ LoadingUnloadingCostMasterTableData: a });
    } else {
      a = this.state.newData;

      if (target === "is_active") {
        a.is_active = Boolean(value);
      } else if (target === "State") {
        a.State = optionlabel;
        a.branch_id = value;
      }
      this.setState({ newData: a });
    }
  };

  handleChange = (e, row, index, name, isPopup) => {
    e.target.name = name;

    var alphaNumericRegex = /^[a-zA-Z0-9]*$/;
    var numberRegex = /^[0-9.]+$/;

    let a;
    if (isPopup === false) {
      a = this.state.LoadingUnloadingCostMasterTableData;

      if (name === "Loading_Charges_PerMT") {
        a[row.key].Loading_Charges_PerMT = e.target.value;
      }
      else if (name === "Unloading_Chagres_PerMT") {
        a[row.key].Unloading_Chagres_PerMT = e.target.value;
      }

      this.setState({ LoadingUnloadingCostMasterTableData: a });
    } else {
      a = this.state.newData;

      if (name === "Loading_Charges_PerMT") {
        a.Loading_Charges_PerMT = e.target.value;
      }
      else if (name === "Unloading_Chagres_PerMT") {
        a.Unloading_Chagres_PerMT = e.target.value;
      }

      this.setState({ newData: a });
    }
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  handleEditChange = (row, index) => {
    this.setState({ rowData: row });
    let tempData = this.state.LoadingUnloadingCostMasterTableData.map((item) => {

      if (item.key === row.key) {
        return {
          ...item,
          isEdit: true
        }
      } else {
        return item;
      }
    })
    this.setState({ LoadingUnloadingCostMasterTableData: tempData });
  };

  handleCancelClick = (rowData, index) => {
    let tempData = this.state.LoadingUnloadingCostMasterTableData.map((item) => {
      if (item.key === rowData.key) {
        return this.state.rowData
      } else {
        return item;
      }
    })
    this.setState({ LoadingUnloadingCostMasterTableData: tempData });
  };


  render() {
    const userTableProps = {
      pagination: false,
      size: "default",
      bordered: true,
      scroll: { x: 200, y: window.innerHeight - 250 },
    };

    return (
      <div
        className="mandi-master-container"
        style={{
          height: window.innerHeight - 85,
          marginTop: "-45px",
        }}
      >
        <div className="top-container">

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row-reverse",
              placeItems: "flex-end",
              marginRight: "25px",
              marginBottom: "10px",
            }}
          >
            <Search
              disabled={!this.state.isAdd}
              placeholder="Search"
              allowClear
              onChange={(e) => this.setState({ searchText: e.target.value })}
              style={{ width: "25%" }}
            />
            <Button
              className="add-master-button"
              style={{
                height: "25px",
                width: "70px",
                backgroundColor: "#0b133d",
                color: "white",
              }}
              disabled={!canUserEdit(
                this.props.userData,
                null,
                FeatureCodes.MS_LOADING_UNLOAD_COST_EDIT
              ) || !this.state.isAdd}
              onClick={() => this.setModalVisibility(true)}
            >
              &#10010; Add
            </Button>
          </div>
        </div>

        <div>
        <div
        style={{
          marginLeft: "25px",
          marginTop: "-8px",
          fontSize: "14px",
          color: "red",
          display: "flex",
          float: "left",
        }}
      > {getUserMSRBACMessage(this.props.messageAccess,FeatureCodes.MS_LOADING_UNLOAD_COST_EDIT)}
      </div>
        <div
          style={{
            marginRight: "20px",
            marginTop: "-8px",
            fontSize: "15px",
            color: "#0B133D",
            display: "flex",
            float: "right",
          }}
        >Total number of records:&nbsp;
          <b>{this.state.LoadingUnloadingCostMasterTableData && this.setTableData().length}</b>
        </div>
        </div>

        <Spin
          spinning={this.state.isSpinnerOnLoad}
          size={"large"}
          tip="Loading..."
          style={{ alignSelf: "center", zIndex: 9999, marginTop: "20px" }}
        >
          <Modal
            className="add-master-modal"
            title={"Add Loading Unloading Cost"}
            onCancel={this.handleCancel}
            footer={false}
            visible={this.state.isModalVisible}
            destroyOnClose={true}
          >
            <div className="master-form-container">
              <Form
                layout="horizontal"
                //   onValuesChange={onFormLayoutChange}
                size={"small"}
              // onSubmit={submitTableRowData(this.state.newData)}
              >
                <div className="form-top-container1">
                  <div
                    className="checkbox-group1"
                    style={{
                      display: "inline-block",
                      width: "60%",
                      marginRight: 40,
                    }}
                  >
                    <Form.Item label="State">
                      <Select
                        showSearch
                        optionFilterProp="children"
                        className="remarks-select"
                        name="State"
                        defaultValue={this.state.newData.State}
                        value={this.state.newData.State}
                        onSelect={(value, option) =>
                          this.handleDropdownChange(
                            value,
                            this.state.newData,
                            "State",
                            -0,
                            option.props.children,
                            true
                          )
                        }
                      >
                        {this.state.stateList &&
                          this.state.stateList.length &&
                          this.state.stateList.map((hubItem, idx) => (
                            <Option
                              key={hubItem.branch_id}
                              value={hubItem.branch_id}
                            >
                              {hubItem.state}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </div>

                  <div
                    className="checkbox-group1"
                    style={{
                      display: "inline-block",
                      width: "60%",
                      marginRight: 40,
                    }}
                  >
                    <Form.Item label="Loading Charges(MT)">
                      <Input
                        type="number"
                        style={{ marginRight: "2px" }}
                        maxLength={100}
                        min="0"
                        value={this.state.newData.Loading_Charges_PerMT}
                        name="Loading_Charges_PerMT"
                        onChange={(e) =>
                          this.handleChange(
                            e,
                            this.state.newData,
                            0,
                            "Loading_Charges_PerMT",
                            true
                          )
                        }
                      />
                    </Form.Item>
                  </div>

                  <div
                    className="checkbox-group1"
                    style={{
                      height: "45px",
                      display: "inline-block",
                      width: "60%",
                      marginRight: 40,
                    }}
                  >
                    <Form.Item label="Unloading Charges(MT)">
                      <Input
                        type="number"
                        style={{ marginRight: "2px" }}
                        maxLength={100}
                        min="0"
                        value={this.state.newData.Unloading_Chagres_PerMT}
                        name="Unloading_Chagres_PerMT"
                        onChange={(e) =>
                          this.handleChange(
                            e,
                            this.state.newData,
                            0,
                            "Unloading_Chagres_PerMT",
                            true
                          )
                        }
                      />
                    </Form.Item>
                  </div>

                </div>

                <div className="form-bottom-container" style={{ marginTop: "10px" }}>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={this.state.isSubmitted}
                      onClick={() =>
                        this.submitTableRowData(this.state.newData, 0, true)
                      }
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </Modal>


          <div className="table-container1" style={{ maxHeight: "450px", width: "99%", margin: "6px 0px" }}>
            <Table
              {...userTableProps}
              columns={[
                {
                  title: "State",
                  dataIndex: "State",
                  key: "State",
                  width: 125,
                  filters: this.createFilters("State"),
                  onFilter: (value, record) =>
                    record.State.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.State.localeCompare(b.State),
                  render: (record, rowRecord) => {
                    return (
                      <>
                        <div
                          style={{ marginLeft: "-15px", marginRight: "-10px" }}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            disabled={true}//{!rowRecord.isEdit}
                            className="remarks-select"
                            name="State"
                            defaultValue={rowRecord.State}
                            value={rowRecord.State}
                            onSelect={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "State",
                                -0,
                                option.props.children,
                                false
                              )
                            }
                          >
                            {this.state.stateList &&
                              this.state.stateList.length &&
                              this.state.stateList.map((hubItem, idx) => (
                                <Option
                                  key={hubItem.branch_id}
                                  value={hubItem.branch_id}
                                >
                                  {hubItem.state}
                                </Option>
                              ))}
                          </Select>
                        </div>
                      </>
                    );
                  },
                },

                {
                  key: "Loading_Charges_PerMT",
                  title: "Loading Charges(MT)",
                  dataIndex: "Loading_Charges_PerMT",
                  width: 150,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.Unloading_Chagres_PerMT - b.Unloading_Chagres_PerMT,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            type="number"
                            disabled={!rowRecord.isEdit}
                            style={{ marginRight: "2px" }}
                            maxLength={100}
                            min="0"
                            value={rowRecord.Loading_Charges_PerMT}
                            name="Loading_Charges_PerMT"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "Loading_Charges_PerMT",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },
                {
                  key: "Unloading_Chagres_PerMT",
                  title: "Unloading Charges(MT)",
                  dataIndex: "Unloading_Chagres_PerMT",
                  width: 150,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.Unloading_Chagres_PerMT - b.Unloading_Chagres_PerMT,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            type="number"
                            disabled={!rowRecord.isEdit}
                            style={{ marginRight: "2px" }}
                            maxLength={100}
                            min="0"
                            value={rowRecord.Unloading_Chagres_PerMT}
                            name="Unloading_Chagres_PerMT"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "Unloading_Chagres_PerMT",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },
                {
                  title: "Action",
                  dataIndex: "Submit",
                  key: "Submit",
                  width: 80,
                  visibility: false,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ display: "inline-flex", width: "100%" }}>
                          <Button
                          className={!canUserEdit(
                            this.props.userData,
                            null,
                            FeatureCodes.MS_LOADING_UNLOAD_COST_EDIT
                          ) ? "disabledBtnClass" : "btnClass"}
                          disabled={!canUserEdit(
                            this.props.userData,
                            null,
                            FeatureCodes.MS_LOADING_UNLOAD_COST_EDIT
                          )}
                            type="primary"
                            size={"small"}
                            style={{
                              marginLeft: 5,
                              alignSelf: "center",
                              padding: "0px 0px 2px",
                              height: 31,
                              backgroundColor: "#20346a",
                              color: "white",
                              borderRadius: 15,
                            }}
                            block
                            htmlType="submit"
                            onClick={() =>
                              rowRecord.isEdit
                                ? this.submitTableRowData(rowRecord, index, false)
                                : this.handleEditChange(rowRecord, index)
                            }
                          >
                            {" "}
                            {rowRecord.isEdit ? "Submit" : "Edit"}{" "}
                          </Button>


                          <Button
                            className={!rowRecord.isEdit ? "disabledBtnClass" : "btnClass"}
                            type="primary"
                            size={"small"}
                            style={{
                              marginLeft: 5,
                              alignSelf: "center",
                              color: "white",
                              borderRadius: 15,
                              padding: "0px 0px 2px",
                              height: 31,
                              backgroundColor: "#20346a",
                            }}
                            block
                            htmlType="submit"
                            disabled={!rowRecord.isEdit}
                            onClick={() => this.handleCancelClick(rowRecord, index)
                            }
                          >
                            Cancel
                          </Button>

                        </div>
                      </>
                    );
                  },
                },
              ]}
              dataSource={this.setTableData()}
            />
          </div>

        </Spin>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
  messageAccess: selectMSMessageRBAC,
});
export default connect(mapStateToProps)(LoadingUnloadingCostMaster);
//export default LoadingUnloadingCostMaster;
