import React from "react";
import "./weekly-override-card.styles.css";

import { InputNumber, Popover } from "antd";

/**
 * @component
 * @description Card component to display and override the weekly allocation.
 * @property {string} title the title of the card.  
 * @property {number} allocationPercentage percentage allocation for the week.  
 * @property {number} allocationQuantity  allocation quantity for the week.  
 * @property {number} allocationBudget  weekly budget allocation.  
 * @property {boolean} disabled is the card non-editable.  
 * @property {boolean} isTotal is the card displaying total sums of each rows.  
 * @property {function} onValueChanged callback on modifying the input fields.  
 * @property {string} type reference to the week being changed.  
 * @property {number} reference reference to the initial percentage allocation provided
 *  by the model.  
 * @property {number} current current value of the percentage allocation.  
 */
const WeeklyOverrideCard = (props) => {
  const {
    title,
    allocationPercentage,
    allocationQuantity,
    allocationBudget,
    disabled,
    isTotal,
    onValueChanged,
    type,
    reference,
    current,
  } = props;
  return (
    <div className="weekly-override-card-container">
      <div className="title">{title}</div>
      <div className="bottom-line" />
      <Popover
        content={
          isTotal
            ? "Total should be 100% on overriding"
            : `Suggested : ${reference}%`
        }
      >
        <InputNumber
          className={
            reference !== current
              ? "modified-input display-info"
              : "display-info"
          }
          onBlur={(e) => {
            console.log("value", e.target.value);
            if (onValueChanged) {
              let percentage = e.target.value.replace("%", "")
                ? e.target.value.replace("%", "")
                : "0%";
              console.log("percentage :", percentage);
              onValueChanged(parseFloat(percentage.replace("%", "")), type);
            }
          }}
          disabled={disabled}
          value={allocationPercentage}
          min={0}
          max={isTotal ? Infinity : 100}
          formatter={(value) => `${value}%`}
        />
      </Popover>

      <div className="display-info">{`${allocationQuantity} MT`}</div>
      <div className="display-info">{`${allocationBudget} ₹`}</div>
    </div>
  );
};

export default WeeklyOverrideCard;
