import React, { Component } from "react";
import "./arrival-card-value-slider.styles.css";

import CustomIcon from "../custom-icon/custom-icon.component";

import { Card, InputNumber } from "antd";

/**
 *
 * @component
 * @description Component for setting the min and max arrival prices
 * @property {image} image the image displayed on the right side.
 * @property {string} title the type of price (modal price/naked grain price).
 * @property {string} unitText the unit to be displayed.
 * @property {number} defaultValue default value to be displayed
 * @property {number} minRange minimum limit of input.
 * @property {number} maxRange maximum limit of input.
 * @property {function} setParentState change the value for [typeVariety]:{[namevariety] :'value'} in the parent state.
 *
 */
class ArrivalCardValueSlider extends Component {
  render() {
    const {
      image,
      title,
      unitText,
      setParentState,
      typeVariety,
      nameVariety,
      defaultValue,
      minRange,
      maxRange,
    } = this.props;

    return (
      <div className="item-container">
        <Card>
          <div className="value-slider-container">
            <div className="value-slider-component">
              <div className="icon-title-container">
                <CustomIcon className="side-icon" image={image} />
                <h5 className="icon-text">{title}</h5>
              </div>
              <div className="input-field-container">
                <InputNumber
                  key={1}
                  min={minRange ? minRange : 0}
                  max={maxRange ? maxRange : Infinity}
                  defaultValue={defaultValue}
                  onChange={(value) =>
                    setParentState(typeVariety, nameVariety, value)
                  }
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                />
                <div className="right-side-element">{unitText}</div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    );
  }
}

export default ArrivalCardValueSlider;
