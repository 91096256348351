import React, { Component } from "react";
import "./tabular-forecasts-page.styles.css";

import { ReactComponent as IconTrend } from "../../assets/Report-Trend.svg";

import { Tabs } from "antd";
import FixedStateMultipleVarietyProjections from "../../components/fixed-state-variety-projections/fixed-state-variety-projections.component";
import GraphicalVisualizationForModelRuns from "../model-runs-graph-visualization/model-runs-graph-visualization.component";
import VolumeBuyingPlanState from "../../components/volume-buying-plan-state/volume-buying-plan-state.component";
import TabularBuyingQuantities from "../../components/buying-quantity-tabular-predictions/buying-quantity-tabular-predictions.component";
import VolumeBuyingPlanStateFactory from "../../components/volume-buying-plan-state-factory/volume-buying-plan-state-factory.component";
import TabularStateCost from "../../components/tabular-state-cost/tabular-state-cost.component";

const { TabPane } = Tabs;

const TabularForecastPage = () => {
  // state = { tabKey: 0 };

  return (
    <div className="report-tabs">
      <Tabs
        // onChange={() => this.setState({ tabKey: Date.now() + "" })}
        // onChange={value => console.log(value)}
        animated={false}
        className={`main-content 
          `}
        defaultActiveKey="1"
      >
        <TabPane
          tab={
            <div className="tab-title-container">
              <div>
                <IconTrend className="tab-title-image" />
              </div>
              Volume Buying Plan - State
            </div>
          }
          key="1"
        >
          <VolumeBuyingPlanState />
        </TabPane>
        <TabPane
          tab={
            <div className="tab-title-container">
              <div>
                <IconTrend className="tab-title-image" />
              </div>
              State-variety Buying Quantities
            </div>
          }
          key="2"
        >
          <TabularBuyingQuantities />
        </TabPane>
        <TabPane
          tab={
            <div className="tab-title-container">
              <div>
                <IconTrend className="tab-title-image" />
              </div>
              Volume Buying Plan - State Factory
            </div>
          }
          key="3"
        >
          <VolumeBuyingPlanStateFactory />
        </TabPane>
        {/* <TabPane
          tab={
            <div className="tab-title-container">
              <div>
                <IconTrend className="tab-title-image" />
              </div>
              Cost - State
            </div>
          }
          key="4"
        >
          <TabularStateCost /> // FIXME: this component has code copied from the VolumeBuyingPlanState component. need to be modified or the tab removed.
        </TabPane> */}
      </Tabs>
    </div>
  );
};

export default TabularForecastPage;
