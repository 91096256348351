import React from "react";
import "./buying-quantity-tabular-predictions.styles.css";

import { Table, message, Empty, Spin } from "antd";
import CustomSelectWeekly from "../custom-select-weekly/custom-select-weekly.component";
import { useState } from "react";
import { useEffect } from "react";

import { getBuyingQuantities } from "../../services/generic-apis";

const { Column, ColumnGroup } = Table;

const TabularBuyingQuantities = (props) => {
  const {} = props;

  const [isLoading, setLoading] = useState(false);
  const [selectedRunMonth, setRunMonth] = useState(undefined);
  const [selectedQCType, setQCType] = useState(undefined);
  const [nestedData, setNestedData] = useState(undefined);
  const [percentageData, setPercentageData] = useState(undefined);

  useEffect(() => {
    setRunMonth(finiteFilters.model_runs[0].id);
    setQCType(finiteFilters.qc_model_runs[0].id);
    getData(finiteFilters.model_runs[0].id, finiteFilters.qc_model_runs[0].id);
  }, []);

  const finiteFilters = {
    model_runs: [
      { id: 2, name: "Feb" },
      { id: 3, name: "March" },
      { id: 4, name: "April" },
      { id: 5, name: "May" },
      { id: 6, name: "June" },
    ],
    qc_model_runs: [
      { id: 1, name: "Model QC" },
      { id: 3, name: "Business QC" },
    ],
  };

  const getData = async (run_month, qc_type) => {
    setLoading(true);

    try {
      const [nestedResponse, percentageResponse] = await getBuyingQuantities(
        run_month,
        qc_type
      );

      setLoading(false);

      if (nestedResponse.data.status) {
        setNestedData(nestedResponse.data.data);
      } else {
        message.error("Could not retrieve data for nested table.");
      }

      if (percentageResponse.data.status) {
        setPercentageData(percentageResponse.data.data);
      } else {
        message.error("Could not retrieve data for percentage table.");
      }

    } catch (error) {
      setLoading(false);
    }
  };

  const onMonthSelected = (value) => {
    setRunMonth(value);
    getData(value, selectedQCType);
  };

  const onQCSelected = (value) => {
    setQCType(value);
    getData(selectedRunMonth, value);
  };

  const getNestedTableData = () => {
    let modifiedNestedData = nestedData;

    modifiedNestedData = modifiedNestedData.map((item) => {
      return {
        key: item.state_variety,
        state: item.state_variety,
        feb: item.Feb,
        march: item.Mar,
        april: item.Apr,
        may: item.May,
        june: item.Jun,
        post_season: item.post_season,
        grand_total: item.total_vol,
        children: item.varieties.map((item) => {
          return {
            key: item.state_variety + "_" + item.state,
            state: item.state_variety,
            feb: item.Feb,
            march: item.Mar,
            april: item.Apr,
            may: item.May,
            june: item.Jun,
            post_season: item.post_season,
            grand_total: item.total_vol,
          };
        }),
      };
    });

    const totalRow = {
      key: "Total",
      state: "Total",
      feb: modifiedNestedData.reduce((acc, item) => acc + item.feb || 0, 0),
      march: modifiedNestedData.reduce((acc, item) => acc + item.march || 0, 0),
      april: modifiedNestedData.reduce((acc, item) => acc + item.april || 0, 0),
      may: modifiedNestedData.reduce((acc, item) => acc + item.may || 0, 0),
      june: modifiedNestedData.reduce((acc, item) => acc + item.june || 0, 0),
      post_season: modifiedNestedData.reduce(
        (acc, item) => acc + item.post_season || 0,
        0
      ),
      grand_total: modifiedNestedData.reduce(
        (acc, item) => acc + item.grand_total || 0,
        0
      ),
    };
    return [...modifiedNestedData, totalRow];
  };
  const getPercentageTableData = () => {
    let modifiedPercentageData = percentageData;

    modifiedPercentageData = modifiedPercentageData.map((item) => {
      return {
        key: item.state,
        state: item.state,
        feb: item.Feb,
        march: item.Mar,
        april: item.Apr,
        may: item.May,
        june: item.Jun,
        post_season: item.post_season,
        grand_total: item.total_vol,
      };
    });

    const totalRow = {
      key: "Total",
      state: "Total",
      feb: Math.round(
        modifiedPercentageData.reduce((acc, item) => acc + item.feb || 0, 0)
      ),
      march: Math.round(
        modifiedPercentageData.reduce((acc, item) => acc + item.march || 0, 0)
      ),
      april: Math.round(
        modifiedPercentageData.reduce((acc, item) => acc + item.april || 0, 0)
      ),
      may: Math.round(
        modifiedPercentageData.reduce((acc, item) => acc + item.may || 0, 0)
      ),
      june: Math.round(
        modifiedPercentageData.reduce((acc, item) => acc + item.june || 0, 0)
      ),
      post_season: Math.round(
        modifiedPercentageData.reduce((acc, item) => acc + item.post_season || 0, 0)
      ),
      grand_total: Math.round(
        modifiedPercentageData.reduce((acc, item) => acc + item.grand_total || 0, 0)
      ),
    };

    return [...modifiedPercentageData, totalRow];
  };

  return (
    <Spin spinning={isLoading}>
      <div className="volume-buying-plan-state-container">
        <div className="filters box-shadow-card">
          <div
            className="temporal-select center box-shadow-card"
            style={{ height: "70px" }}
          >
            <div className="temporal-select-title">Select Run Month</div>
            <CustomSelectWeekly
              list={finiteFilters.model_runs.map((item) => {
                return {
                  name: item.name,
                  id: item.id,
                };
              })}
              value={selectedRunMonth}
              onSelect={onMonthSelected}
              //   placeholder={`Select ${setSelectText()}`}
            />
          </div>
          <div
            className="temporal-select center box-shadow-card"
            style={{ height: "70px" }}
          >
            <div className="temporal-select-title">Select QC Type</div>
            <CustomSelectWeekly
              list={finiteFilters.qc_model_runs.map((item) => {
                return {
                  name: item.name,
                  id: item.id,
                };
              })}
              value={selectedQCType}
              onSelect={onQCSelected}
              //   placeholder={`Select ${setSelectText()}`}
            />
          </div>
        </div>
        {nestedData ? (
          <div
            style={{ marginBottom: "20px" }}
            className="normal-table box-shadow-card"
          >
            <Table
              rowClassName={(record, index) =>
                record.state === "Total" ? "total-row" : ""
              }
              pagination={false}
              dataSource={getNestedTableData()}
            >
              <ColumnGroup title="Buying Quantity">
                <Column
                  className="column"
                  title="State"
                  dataIndex="state"
                  key="state"
                />
                <Column
                  className="column"
                  title="February"
                  dataIndex="feb"
                  key="feb"
                />
                <Column
                  className="column"
                  title="March"
                  dataIndex="march"
                  key="march"
                />
                <Column
                  className="column"
                  title="April"
                  dataIndex="april"
                  key="april"
                />
                <Column
                  className="column"
                  title="May"
                  dataIndex="may"
                  key="may"
                />
                <Column
                  className="column"
                  title="June"
                  dataIndex="june"
                  key="june"
                />
                <Column
                  className="column"
                  title="Post Season"
                  dataIndex="post_season"
                  key="post_season"
                />
                <Column
                  className="column"
                  title="Grand Total"
                  dataIndex="grand_total"
                  key="grand_total"
                />
              </ColumnGroup>
            </Table>
          </div>
        ) : (
          <Empty
            style={{ margin: "10px" }}
            description="No data available for this table."
          />
        )}

        {percentageData ? (
          <div className="normal-table box-shadow-card">
            <Table
              rowClassName={(record, index) =>
                record.state === "Total" ? "total-row" : ""
              }
              pagination={false}
              dataSource={getPercentageTableData()}
            >
              <ColumnGroup title="Buying Quantity Percentage">
                <Column
                  className="column"
                  title="State"
                  dataIndex="state"
                  key="state"
                />
                <Column
                  className="column"
                  title="February"
                  dataIndex="feb"
                  key="feb"
                />
                <Column
                  className="column"
                  title="March"
                  dataIndex="march"
                  key="march"
                />
                <Column
                  className="column"
                  title="April"
                  dataIndex="april"
                  key="april"
                />
                <Column
                  className="column"
                  title="May"
                  dataIndex="may"
                  key="may"
                />
                <Column
                  className="column"
                  title="June"
                  dataIndex="june"
                  key="june"
                />
                <Column
                  className="column"
                  title="Post Season"
                  dataIndex="post_season"
                  key="post_season"
                />
                <Column
                  className="column"
                  title="Grand Total"
                  dataIndex="grand_total"
                  key="grand_total"
                />
              </ColumnGroup>
            </Table>
          </div>
        ) : (
          <Empty
            style={{ margin: "10px" }}
            description="No data available for this table."
          />
        )}
      </div>
    </Spin>
  );
};

export default TabularBuyingQuantities;
