import React, { Component } from "react";
import { Tabs } from "antd";
import Approvals from "../../components/approvals/approvals.component";
import Planned from "../buying-decisioning/buying-decisioning.page";
import ActualPurchasing from "../actual-purchasing/actual-purchasing.page";
import History from "../../components/history/history.component";

// icons
import { ReactComponent as IconApproval } from "../../assets/Approval.svg";
import { ReactComponent as IconPlanned } from "../../assets/Planned.svg";
import { ReactComponent as IconHistory } from "../../assets/history.svg";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectUserData } from "../../redux/user/user.selectors";

import { canUserEdit } from "../../auth/utils";
import { FeatureCodes } from "../../auth/data";

const { TabPane } = Tabs;

/**
 * @description Parent component containing tab view of planning and approval of buying decision.
 * @component
 */
class BuyingDecisionContainer extends Component {
  state = { tabKey: 0 };
  render() {
    return (
      <div className="report-tabs">
        <Tabs
          onChange={() => this.setState({ tabKey: Date.now() + "" })}
          animated={false}
          className={`main-content`}
          defaultActiveKey="12"
        >
          {canUserEdit(
            this.props.userData,
            null,
            FeatureCodes.BUYING_DECISION_APPROVE
          ) ? (
            <TabPane
              key={this.state.tabKey}
              // forceRender
              tab={
                <div className="tab-title-container">
                  <div>
                    <IconApproval className="tab-title-image" />
                  </div>
                  Approvals
                </div>
              }
              // key="11"
            >
              <Approvals />
            </TabPane>
          ) : null}

          <TabPane
            // forceRender
            tab={
              <div className="tab-title-container">
                <div>
                  <IconPlanned className="tab-title-image" />
                </div>
                Planned
              </div>
            }
            key="12"
          >
            <Planned />
          </TabPane>

          <TabPane
            // forceRender
            tab={
              <div className="tab-title-container">
                <div>
                  <IconPlanned className="tab-title-image" />
                </div>
                Day Closure
              </div>
            }
            key="14"
          >
            <ActualPurchasing />
          </TabPane>

          <TabPane
            // forceRender
            tab={
              <div className="tab-title-container">
                <div>
                  <IconHistory className="tab-title-image" />
                </div>
                History
              </div>
            }
            key="13"
          >
            <History />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
});

export default connect(mapStateToProps, null)(BuyingDecisionContainer);
