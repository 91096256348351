export const FeatureCodes = {
  RECOMMENDATIONS_VIEW: "TBRVR",
  RECOMMENDATIONS_DOWNLOAD: "TBRDL",

  ARRIVALS_VIEW: "TARVA",
  ARRIVALS_ENTRY: "TAREA",
  ARRIVALS_CLOSE_MANDI: "TARCM",

  BUYING_DECISION_VIEW: "TBDVD",
  BUYING_DECISION_SUBMIT: "TBDES",
  BUYING_DECISION_APPROVE: "TBDDA",

  ARRIVAL_SUMMARY_VIEW: "TASVS",
  ARRIVAL_SUMMARY_EDIT: "TASMP",
  ARRIVAL_SUMMARY_DOWNLOAD: "TASDA",

  REPORTS_USER_ADOPTION: "RUSAD",
  REPORTS_PRICE_TREND: "RPVTR",
  REPORTS_CONSOLIDATED: "RCSUM",
  REPORTS_INTRA_DAY_MANDI: "RINDM",
  REPORTS_PURCHASE_EFFICIENCY: "RPUEF",

  ADMIN_ACCESS: "ADMIN",

  MONTHLY_PRICE_UPDATE: "MTHPU",
  MONTHLY_VOLUME_UPDATE: "MTHVU",

  WEEKLY_MODEL_VIEW: "WEEKV",
  WEEKLY_MODEL_EDIT: "WEEKE",
  MONTHLY_MODEL_FORECAST_VIEW: "MNTHV",
  MONTHLY_MODEL_FORECAST_EDIT: "MNTHE",

  MONTHLY_CONSTRAINTS:'MCONS',

  ALERTS_GROUP: "ALTGC",
  ALERTS_PUBLISHING: "ALTPL",

  COMPETITOR_ANALYSIS: "TCAER",

  MASTER_SCREEN_VIEW:"MVIEW",
  MASTER_SCREEN_OPS_EDIT:"MOPED",
  MASTER_SCREEN_BR_EDIT:"MBRED",
  MASTER_SCREEN_HUB_BUY_EDIT:"MHBUYE",

  MS_ACREAGE_EDIT :"MACRE",
  MS_ALL_FARMER_EDIT:"MAFBE",
  MS_ANNUAL_FAC_CONS_EDIT:"MAFCE",
  MS_BRANCH_EDIT:"MBRNE",
  MS_COMPETITOR_EDIT:"MCOME",
  MS_COST_BUILDUP_FARMER_EDIT:"MCBFE",
  MS_COST_BUILDUP_MANDI_EDIT:"MCBME",
  MS_COST_SAHAYAK_BIHAR_EDIT:"MCBBE",
  MS_COST_SAHAYAK_GUJRAT_EDIT:"MCBGE",
  MS_CONTAINER_DTD_F_EDIT:"MDTDE",
  MS_DESTINATION_BRANCH_EDIT:"MDBME",
  MS_FACOTRY_EDIT:"MFCTE",
  MS_FARMER_BUY_PERCENT_EDIT:"MFBPE",
  MS_FAVORITE_MANDI_EDIT:"MFMNE",
  MS_HUB_EDIT:"MHUBE",
  MS_HUB_FACTORY_FRIEGHT_EDIT:"MHFFE",
  MS_HOLIDAY_EDIT:"MHOLE",
  MS_INFLATION_FACTOR_EDIT:"MINFE",
  MS_LOADING_UNLOAD_COST_EDIT:"MLUCE",
  MS_LOCAL_WAREHOUSE_EDIT:"MLWHE",
  MS_MANDI_EDIT:"MMNDE",
  MS_MANDI_HUB_F_EDIT:"MMHFE",
  MS_MANDI_STATE_HUB_EDIT:"MMDSE",
  MS_PO_PRICE_CORREC_EDIT:"MPPCE",
  MS_REGION_EDIT:"MREGE",
  MS_ROLE_EDIT:"MROLE",
  MS_ROLE_FEATURE_MAP_EDIT:"MRFME",
  MS_VARIETY_EDIT:"MVARE",
  MS_VARIETY_REGION_MAP_EDIT:"MVRME",
  MS_WAREHOUSE_COST_EDIT:"MWHCE",

};
