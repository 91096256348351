import React from "react";

import { Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectLoginStatus } from "../../redux/user/user.selectors";


import { setUserLoggedStatus } from "../../redux/user/user.actions";
/**
 * @component
 * @description Custom route component based off on react-router that 
 * conditionally allows access to a specific component.
 * @property {ReactComponent} Component Component to which routing is kept authenticated.
 */
const PrivateRoute = props => {
  const { Component, isLogged,clickDisable, ...restProps } = props;
  return (
    <Route
      {...restProps}
      render={props =>
        isLogged ? (
          <Component clickDisable={clickDisable}/>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
}; const mapStateToProps = createStructuredSelector({
  isLogged: selectLoginStatus,

});

const mapDispatchToProps = (dispatch) => {
  return {
    setUserLoggedStatus: (status) => dispatch(setUserLoggedStatus(status)),

  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PrivateRoute));

