import React from "react";
import "antd/dist/antd.css";
import "./custom-dropdown.styles.css";
import { Select } from "antd";


const { Option } = Select;

/**
 * @function
 * @description set default value of the quality
 * @param {string} qualityValue the quality of the arrival (Good/Average/Poor)
 * @memberof CustomDropdown
 */
const setDefaultValue = qualityValue => {
  switch (qualityValue) {
    case "Good":
      return 0;
    case "Average":
      return 1;
    case "Poor":
      return 2;
    default:
      return 1;
  }
};

/**
 * @component
 * @description Dropdown component customised for use in arrival entry cards
 * @property {string[]} list items to be displayed in dropdown.
 * @property {number} defaultValue default value to be displayed
 * @property {string} typeVariety key of object in the parent state.
 * @property {string} nameVariety key of typeVariety object to be modified.
 * @property {function} setParentState change the value for [typeVariety]:{[namevariety] :'value'} in the parent state.
 */
const CustomDropdown = ({
  valueReturn,
  list,
  setParentState,
  typeVariety,
  nameVariety,
  defaultValue
}) => (
  <div>
    <Select
      defaultValue={list[setDefaultValue(defaultValue)]}
      onChange={value => {
        if (typeVariety !== "") {
          setParentState
            ? setParentState(typeVariety, nameVariety, value)
            : console.log("");
        }
      }}
    >
      {list.map((item, idx) => (
        <Option value={item} key={idx}>
          {item}
        </Option>
      ))}
    </Select>
    <div className="underline"></div>
  </div>
);

export default CustomDropdown;
