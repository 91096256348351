import React from "react";
import "./holiday-master.page";
import { Component } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUserData ,selectMSMessageRBAC} from "../../redux/user/user.selectors";
import { canUserEdit,getUserMSRBACMessage } from "../../auth/utils";
import { FeatureCodes } from "../../auth/data";
import {
  Table,
  Spin,
  Input,
  Select,
  Button,
  message,
  Form,
  Modal,
  Radio,
  DatePicker,
} from "antd";

import moment from "moment";
import API from "../../api";
import Item from "antd/lib/list/Item";
const { Search } = Input;
const { Option } = Select;
/**
 * @description Component for user management tab
 *
 * @component
 */
class HolidayMaster extends Component {
  state = {
    isModalVisible: false,
    isAdd: false,
    currentRecord: null,
    userStates: null,
    userBranches: null,
    searchText: null,
    searchUserType: 0,
    holidayTableData: [],
    mandiList: [],
    stateList: [],
    branchList: [],
    mandiRegionList: [],
    hubList: [],
    hubRegionList: [],
    hubBranchList: [],
    isSpinnerOnLoad: false,
    isSubmitted: false,
    newData: {
      is_active: true,
      branch: undefined,
      holiday_date: "",
      holiday_id: undefined,
      holiday_reason: "",
      isEdit: false,
      branch_name:""
    },
    rowData: undefined,
    selectedBranchValue: undefined,
    selectedBranchName: "",
    selectedBranchList: [],
    selectedBranches: [],
    selectedReasonValue: 1,
    selectedReasonName: "Others",
    selectedYear: moment().year,
    selectedYearName: moment().year,
    selectedYearList: [],
    selectedReasonList: [
      { id: 1, reason: "Others" },
      { id: 2, reason: "Sunday" },
      { id: 3, reason: "All" }
    ],
  };
  componentDidMount() {
    this.setState({ isSpinnerOnLoad: true });

    this.getBranches();
  }

  getHolidayMaster = async () => {
    this.setState({ isSpinnerOnLoad: true });


    const params = {
      branch_id:
      this.state.selectedBranchValue === null || this.state.selectedBranchValue === undefined
        ? null
        : [this.state.selectedBranchValue],
      // state_id: isselect ? selectedstate : [selectedstate],
      // this.state.selectedBranches === null || this.state.selectedBranches === undefined
      // ? (this.state.branchList === null || this.state.branchList === undefined ? null :this.state.branchList[0])
      // : this.state.selectedBranches,
      holiday_reason_type:
        this.state.selectedReasonValue === null || this.state.selectedReasonValue === undefined
          ? 1
          : this.state.selectedReasonValue,
      // holiday_reason_date :
      // this.state.selectedYear === null|| this.state.selectedYear ===undefined
      // ? moment().year
      // : [this.state.selectedYear],
    };
    await API.get("/master_holiday", { params: params })
      .then((varietyResponse) => {
        varietyResponse.data.data.map((dataDetailItem, index) => {
          dataDetailItem.key = index;
          dataDetailItem.isEdit = false;
        });
        this.setState(
          {
            holidayTableData: varietyResponse.data.data,
          },
          () => this.setState({ isSpinnerOnLoad: false, isAdd: true })
        );
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };

  getBranches = async () => {
    this.setState({ isSpinnerOnLoad: true });


    await API.get("/list_branch")
      .then((varietyResponse) => {

       // this.setState({ selectedStates: varietyResponse.data.data[0].state_id });
        this.setState(
          {
            selectedBranchList: varietyResponse.data.data,
            selectedBranchValue : varietyResponse.data.data[0].branch_id,
            selectedBranchName : varietyResponse.data.data[0].branch_name,
          },
          () => this.getHolidayMaster()
        );
      })

    await API.get("/master_branch")
      .then((varietyResponse) => {

       // this.setState({ selectedBranches: varietyResponse.data.data[0].territory_id });
        this.setState(
          {
            branchList: varietyResponse.data.data,

          },
          () => this.getHolidayMaster()
        );
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };

  setTableData = () => {

    if (
      this.state.holidayTableData !== undefined ||
      this.state.holidayTableData.length >= 0
    ) {
      const userTableData = this.state.holidayTableData.map((user, index) => {
        return {
          key: user.key,
          is_active: user.is_active,
          holiday_date: user.holiday_date,
          holiday_id: user.holiday_id,
          holiday_reason: user.holiday_reason,
          isEdit: user.isEdit,
          branch:user.branch_id,
          branch_name:user.branch_name,
        };
      });

      let dataAfterSearch = userTableData;

      if (this.state.searchText) {
        dataAfterSearch = dataAfterSearch.filter(
          (item) =>
            item.holiday_reason !== null &&
            item.holiday_reason
              .toLowerCase()
              .includes(this.state.searchText.toLowerCase())
        );
      }
      //   else{
      //       dataAfterSearch =  dataAfterSearch.filter((x)=>x.holiday_reason.toLowerCase() !== "sunday");
      //   }

      return dataAfterSearch;
    } else {
      return this.state.holidayTableData;
    }
  };

  submitTableRowData = (row, index, isPopup) => {
    this.setState({ isSpinnerOnLoad: true, isSubmitted: true });

    let warningmessage = "";
    if (
      row.holiday_date === null ||
      row.holiday_date === undefined ||
      row.holiday_date === ""
    ) {
      warningmessage = "Please enter Holiday Date";
    } else if (
      row.branch === null ||
      row.branch === undefined ||
      row.branch === ""
    ) {
      warningmessage = "Please select Branch";
    } else if (
      row.holiday_reason === null ||
      row.holiday_reason === undefined ||
      row.holiday_reason === ""
    ) {
      warningmessage = "Please enter Holiday Reason";
    }

    if (
      warningmessage === "" ||
      warningmessage === undefined ||
      warningmessage === null
    ) {
      this.setState({ isSpinnerOnLoad: true });
      this.setState({ isSubmitted: true });

      let data = {
        is_active: row.is_active ? 1 : 0,
        holiday_date:
          row.holiday_date === null ||
            row.holiday_date === undefined ||
            row.holiday_date === ""
            ? null
            : moment(row.holiday_date).format("YYYY-MM-DD"),
        holiday_id: row.holiday_id,
        holiday_reason: row.holiday_reason,
        branch_id: row.branch,
        branch_name:row.branch_name,
      };



      API.put("/master_holiday", data, {

      })
        .then((response) => {
          if (response.status === 200 && response.data.status) {
            message.info(response.data.message, 5);
            this.setModalVisibility(false);
            this.getHolidayMaster();
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
          } else if (!response.data.status) {
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
            message.error(response.data.message, 5);
            // this.setModalVisibility(false);
          } else {
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
            message.error("Please try again", 5);
          }
        })
        .catch((err) => {
          if (err) {
            if (err.response && err.response.status === 400)
              message.error("Something went wrong. Please try again later.", 5);
            else {
              message.error(
                "Something went wrong.. Please try again later.",
                5
              );
            }
          } else {
            message.error("Something went wrong... Please try again later.", 5);
          }
          this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
          // this.setModalVisibility(false);
        });
    } else {
      this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
      message.error(warningmessage, 5);
    }
  };

  setModalVisibility = (status) => {
    if (status) {
      const nData = {
        is_active: true,
        holiday_date: "",
        holiday_id: undefined,
        holiday_reason: "",
        branch_name:"",
        isEdit: false,
        branch:undefined,
      };

      this.setState({selectedBranches:[], newData: nData });
    }

    this.setState({ isModalVisible: status });
  };

  TableColumnHeader = (props) => {
    const { title, subtitle } = props;
    return (
      <div className="column-header-container">
        <div
          style={{
            color: "white",
            fontSize: "12px",
            whiteSpace: "nowrap",
            fontWeight: "normal",
            textAlign: "left",
          }}
        >
          {title}
        </div>
        <div
          style={{
            color: "#cbdfff",
            fontSize: "11px",
            fontWeight: "normal",
            textAlign: "left",
            // subtitle && title.length < subtitle.length ? "left" : "center"
          }}
        >
          {subtitle}
        </div>
      </div>
    );
  };

  createFilters = (label) => {
    let filterData = this.state.holidayTableData;
    //#region remove duplicate objects
    let uniqueFilterData = [];

    if (
      label === "is_active" &&
      this.state.holidayTableData &&
      this.state.holidayTableData.length > 0
    ) {
      uniqueFilterData = [
        { text: "Yes", value: 1 },
        { text: "No", value: 0 },
      ];
    } else {
      filterData.map((mainItem) =>
        uniqueFilterData.filter((item) => item[label] === mainItem[label])
          .length > 0
          ? null
          : uniqueFilterData.push(mainItem)
      );

      uniqueFilterData = uniqueFilterData.map((item) => {
        return {
          text: item[label],
          value: item[label],
        };
      });

      uniqueFilterData.sort((a, b) => a.text.localeCompare(b.text));
    }

    return uniqueFilterData;
  };

  handleDropdownChange = (value, row, target, index, optionlabel, isPopup) => {
    let a;

    if (isPopup === false) {
      a = this.state.holidayTableData;

      if (target === "is_active") {
        a[row.key].is_active = Boolean(value);
      }
      // this.setState({ holidayTableData: a });
      // }
      // a = this.state.newData;

      else if (target === "state") {
        a.is_active = Boolean(value);
        // a.state = optionlabel;
        // a.state_id = value;
        a[row.key].state = optionlabel;
        a[row.key].state_id = value;
      }
      this.setState({ holidayTableData: a });
    } else {
      a = this.state.newData;

      if (target === "is_active") {
        a.is_active = Boolean(value);
      }
      else if (target === "state") {
        a.state = optionlabel;
        a.state_id = value;
      } else if (target === "branch") {
        a.branch = value;
        a.branch_name = optionlabel;
      }
      this.setState({ newData: a });
    }
  };

  handleChange = (e, row, index, name, isPopup) => {
    var alphaNumericRegex = /^[a-zA-Z0-9]*$/;
    var numberRegex = /^[0-9.]+$/;

    let a;
    if (isPopup === false) {
      a = this.state.holidayTableData;

      if (name === "holiday_date") {
        a[row.key].holiday_date = e;
      } else if (name === "holiday_reason") {
        a[row.key].holiday_reason = e.target.value;
      }

      this.setState({ holidayTableData: a });
    } else {
      a = this.state.newData;

      if (name === "holiday_date") {
        a.holiday_date = e;
      } else if (name === "holiday_reason") {
        a.holiday_reason = e.target.value;
      }

      this.setState({ newData: a });
    }
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  handleEditChange = (row, index) => {
    this.setState({ rowData: row });
    let tempData = this.state.holidayTableData.map((item) => {
      if (item.key === row.key) {
        return {
          ...item,
          isEdit: true,
        };
      } else {
        return item;
      }
    });
    this.setState({ holidayTableData: tempData });
  };

  handleCancelClick = (rowData, index) => {
    let tempData = this.state.holidayTableData.map((item) => {
      if (item.key === rowData.key) {
        return this.state.rowData;
      } else {
        return item;
      }
    });
    this.setState({ holidayTableData: tempData });
  };

  handlestateSelect = (value, props) => {
    // this.GetVariety(this.props.selectedBranchID, props.key);
    // this.props.setSelectedbranchID(
    //   props.key
    // );
    this.setState((prevState) => ({
      ...prevState,
      selectedStates: value,
    }));
    this.getHolidayMaster();
  };
  handlebranchSelected = (value, props) => {
    // this.GetVariety(this.props.selectedBranchID, props.key);
    // this.props.setSelectedbranchID(
    //   props.key
    // );
    console.log("sekected branch ", value,[value],this.state.selectedBranches);
    this.setState((prevState) => ({
      ...prevState,
      selectedBranches: [value],
    }));
    
    console.log("sekected branch1111 ", value,this.state.selectedBranches);
  };

  
  render() {
    const userTableProps = {
      pagination: false,
      size: "default",
      bordered: true,
      scroll: { x: 200, y: window.innerHeight - 250 },
    };

    const handleReasonSelect = (value, props) => {
      this.setState(
        { selectedReasonName: props.key, selectedReasonValue: value },
        () => this.getHolidayMaster()
      );
    };

    const handleBranchSelect = (value, props) => {
      this.setState(
        { selectedBranchName: props.key, selectedBranchValue: value  },
        () => this.getHolidayMaster()
      );
    };

    const onChange = (date, dateString) => {
      this.setState(
        { selectedYear: dateString },
        () => this.getHolidayMaster(this.state.selectedStates, this.state.selectedBranches, true)
      );
    }

    const disabledDate = (current) => {
      // Can not select days before today and today
      return current && current < moment().endOf('day');
    }
let branches=[];
    const onBranchSelected = (row, value, isPopup) => {
      if (isPopup === true) {
        console.log("onbranch ", row,value,isPopup);
        let a = this.state.newData;
    
        a['branch'] = a['branch'] ? a['branch'] : [];
        a.branch.push(value);
    this.setState({newData: a});
        this.setState({ newData: a });
        console.log("onbranch111 ", a);
      }
      else {
        let tempData = this.state.holidayTableData.map((item) => {
          if (item.hub_id === row.hub_id) {
            return {
              ...item,
              branch: value.length ? value : undefined,
            };
          } else {
            return item;
          }
        });
        this.setState({ holidayTableData: tempData });
      }
    };

    return (
      <div
        className="mandi-master-container"
        style={{
          height: window.innerHeight - 85,
          marginTop: "-45px",
        }}
      >
        <div className="top-container">
          <div className="hub-region-filter">

            <span
              style={{
                fontSize: "15px",
                marginTop: "5px",
                fontWeight: "600",
                marginLeft: "50px",
              }}
            >
              Holiday&nbsp;
            </span>{" "}
            <Select
              showSearch
              optionFilterProp="children"
              className={"select-master-region"}
              mode="single"
              placeholder="Select Holiday"
              value={this.state.selectedReasonValue}
              onChange={(value, props) => handleReasonSelect(value, props)}
            >
              {this.state.selectedReasonList !== null &&
                this.state.selectedReasonList !== undefined &&
                this.state.selectedReasonList.length &&
                this.state.selectedReasonList.map((item, idx) => (
                  <Option value={item.id} key={item.reason}>
                    {item.reason}
                  </Option>
                ))}
            </Select>

            <span
              style={{
                fontSize: "15px",
                marginTop: "5px",
                fontWeight: "600",
                marginLeft: "50px",
              }}
            >
              Branch&nbsp;
            </span>{" "}
            <Select
            showSearch
            optionFilterProp="children"
            className={"select-master-region"}
            mode="single"
            placeholder="Select Branch"
            value={this.state.selectedBranchValue}
            onChange={(value, props) => handleBranchSelect(value, props)}
          >
          {this.state.selectedBranchList !== null &&
            this.state.selectedBranchList !== undefined &&
            this.state.selectedBranchList.length &&
            this.state.selectedBranchList.map((Item, idx) => (

              <Option
                key={Item.branch_id}
                value={Item.branch_id}
              >
                {Item.branch_name}
              </Option>
              ))}
          </Select>

            {/* <Select
              maxTagCount={2}
              showArrow
              showSearch
              className={"select-region"}
              // disabled={
              //   this.state.isSpinnerOn ||
              //   this.state.dropdownData.varieties == null
              // }
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              mode="multiple"
              allowClear={true}
              placeholder="Select regions"
              value={this.state.selectedBranches}
              onChange={(value, props) =>
                this.handlebranchSelect(value, props)
              }

            >
              {this.state.branchList !== null && this.state.branchList.length &&
                this.state.branchList.map((item, idx) => (
                  <Option
                    value={item.territory_id}
                    key={item.territory_id}
                  >
                    {item.territory_name}
                  </Option>
                ))}
            </Select> */}

            {/* <CustomDropdownChooseRegion
          key="1000"
          defaultValue={this.state.selectedReasonName}
          selectType="region"
          handleSelect={handleRegionSelect}
          placeholder={
            this.state.primaryHubList.length !== 0 &&
            this.state.mandiRegionList.filter(
              (item) => item.region_id == this.state.selectedReasonValue
            ).length !== 0
              ? this.state.mandiRegionList.filter(
                  (item) => item.region_id == this.state.selectedReasonValue
                )[0].region_name
              : "Select Region"
          }
          list={this.state.mandiRegionList}.
        />*/}
          </div>

          {/*<div>
<DatePicker
name="selectedYear"
mode="year"
maxDate={new Date(`12/31/${new Date().getFullYear()}`)}
allowClear={true}
disabledDate={(current) =>
  current > new Date(`1/1/${new Date().getFullYear()+1}`)
}
placeholder="Year"
onChange={(value, date) =>
  this.handleChange(
    date,
    this.state.newData,
    -0,
    "selectedYear",
    true
  )
}
/>
</div>*/}

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row-reverse",
              placeItems: "flex-end",
              marginRight: "25px",
              marginBottom: "10px",
            }}
          >
            <Search
              disabled={!this.state.isAdd}
              placeholder="Search"
              allowClear
              onChange={(e) => this.setState({ searchText: e.target.value })}
              style={{ width: "25%" }}
            />
            <Button
              className="add-master-button"
              style={{
                height: "25px",
                width: "70px",
                backgroundColor: "#0b133d",
                color: "white",
              }}
                 disabled={
                  !canUserEdit(
                    this.props.userData,
                    null,
                    FeatureCodes.MS_HOLIDAY_EDIT
                  ) || !this.state.isAdd}
              onClick={() => this.setModalVisibility(true)}
            >
              &#10010; Add
            </Button>
          </div>
        </div>

        <div>
        <div
        style={{
          marginLeft: "25px",
          marginTop: "-8px",
          fontSize: "14px",
          color: "red",
          display: "flex",
          float: "left",
        }}
      > {getUserMSRBACMessage(this.props.messageAccess,FeatureCodes.MS_HOLIDAY_EDIT)}
      </div>
        <div
          style={{
            marginRight: "20px",
            marginTop: "-8px",
            fontSize: "15px",
            color: "#0B133D",
            display: "flex",
            float: "right",
          }}
        >
          Total number of records:&nbsp;
          <b>{this.state.holidayTableData && this.setTableData().length}</b>
        </div>
        </div>

        <Spin
          spinning={this.state.isSpinnerOnLoad}
          size={"large"}
          tip="Loading..."
          style={{ alignSelf: "center", zIndex: 9999, marginTop: "20px" }}
        >
          <Modal
            className="add-master-modal"
            title={"Add Holiday Reason"}
            onCancel={this.handleCancel}
            footer={false}
            visible={this.state.isModalVisible}
            destroyOnClose={true}
          >
            <div className="master-form-container">
              <Form
                layout="horizontal"
                //   onValuesChange={onFormLayoutChange}
                size={"small"}
              // onSubmit={submitTableRowData(this.state.newData)}
              >
                <div>
                  <div
                    className="checkbox-group1"
                    style={{ display: "inline-block", marginRight: 10 }}
                  >
                    Holiday Date :{" "}
                    <DatePicker
                      name="holiday_date"

                      maxDate={new Date(`12/31/${new Date().getFullYear()}`)}
                      allowClear={true}
                      disabledDate={(current) =>
                        current > new Date(`1/1/${new Date().getFullYear() + 1}`)
                      }
                      format="YYYY-MM-DD"
                      placeholder="Date"
                      defaultValue={
                        this.state.newData.holiday_date === null ||
                          this.state.newData.holiday_date === "" ||
                          this.state.newData.holiday_date === undefined
                          ? null
                          : moment(
                            this.state.newData.holiday_date,
                            "YYYY-MM-DD"
                          )
                      }
                      // value={
                      //   this.state.newData.Start_Effective_Date !== null || this.state.newData.Start_Effective_Date !== "" || this.state.newData.Start_Effective_Date !== undefined
                      //     ? moment(this.state.newData.Start_Effective_Date, "YYYY-MM-DD")
                      //     : undefined
                      // }
                      onChange={(value, date) =>
                        this.handleChange(
                          date,
                          this.state.newData,
                          -0,
                          "holiday_date",
                          true
                        )
                      }
                    />
                  </div>

                  <div>
                    <div
                      className="form-top-container"
                      style={{
                        display: "inline-block",
                        width: "33%",
                        fontSize: "15px",
                        marginRight: 7,
                      }}
                    >
                      <Form.Item label="Branch">
                      <Select
                      showSearch
                      optionFilterProp="children"
                      className="remarks-select"
                      name="branch"
                      defaultValue={this.state.newData.branch}
                      value={this.state.newData.branch}
                      onSelect={(value, option) =>
                        this.handleDropdownChange(
                          value,
                          this.state.newData,
                          "branch",
                          -0,
                          option.props.children,
                          true
                        )
                      }
                    >
                      {this.state.branchList &&
                        this.state.branchList.length &&
                        this.state.branchList.map((hubItem, idx) => (
                          <Option
                            key={hubItem.territory_id}
                            value={hubItem.territory_id}
                          >
                            {hubItem.territory_name}
                          </Option>
                        ))}
                    </Select>
                 
                    {/*}  <Select
                      maxTagCount={2}
                      showArrow
                      showSearch
                      className={"select-region"}
                      // disabled={
                      //   this.state.isSpinnerOn ||
                      //   this.state.dropdownData.varieties == null
                      // }
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      mode="multiple"
                      allowClear={true}
                      placeholder="Select Branch"
                          required
                          name="branch"
                          value={this.state.newData.branch}
                          onSelect={(value, option) =>
                            onBranchSelected(this.state.newData,value,true)
                          }
                        >
                      {this.state.branchList !== null && this.state.branchList.length &&
                        this.state.branchList.map((item, idx) => (
                          <Option
                            value={item.territory_id}
                            key={item.territory_id}
                          >
                            {item.territory_name}
                          </Option>
                        ))}
                    </Select>
                        */}
                      
                        </Form.Item>
                    </div>

                  </div>

                  {/* <div>
                    <div
                      className="form-top-container"
                      style={{
                        display: "inline-block",
                        width: "33%",
                        marginRight: 7,
                      }}
                    >
                      <Form.Item label="State">
                  <Select
              maxTagCount={2}
              showArrow
              showSearch
              className={"select-region"}
              // disabled={
              //   this.state.isSpinnerOn ||
              //   this.state.dropdownData.varieties == null
              // }
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              mode="multiple"
              allowClear={true}
              placeholder="Select regions"
              value={this.state.selectedBranches}
              onChange={(value, props) =>
                this.handlebranchSelect(value, props)
              }

            >
              {this.state.branchList !== null && this.state.branchList.length &&
                this.state.branchList.map((item, idx) => (
                  <Option
                    value={item.territory_id}
                    key={item.territory_id}
                  >
                    {item.territory_name}
                  </Option>
                ))}
            </Select> */}


                  <div
                    className="checkbox-group1"
                    style={{
                      display: "inline-block",
                      width: "60%",
                      marginRight: 40,
                    }}
                  >
                    <Form.Item label="Holiday Reason:">
                      <Input
                        style={{ marginRight: "2px" }}
                        maxLength={100}
                        min="0"
                        value={this.state.newData.holiday_reason}
                        name="holiday_reason"
                        onChange={(e) =>
                          this.handleChange(
                            e,
                            this.state.newData,
                            -0,
                            "holiday_reason",
                            true
                          )
                        }
                      />
                    </Form.Item>
                  </div>

                  <div
                    className="form-top-container"
                    style={{
                      display: "inline-block",
                      width: "33%",
                      marginRight: 7,
                    }}
                  >
                    <Form.Item label="Active">
                      <Radio.Group
                        name="is_active"
                        value={this.state.newData.is_active ? 1 : 0}
                        onChange={(e, option) =>
                          this.handleDropdownChange(
                            e.target.value,
                            this.state.newData,
                            "is_active",
                            -0,
                            "",
                            true
                          )
                        }
                      >
                        <Radio value={1}>Yes</Radio>
                        <Radio value={0}>No</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                </div>
                <div className="form-bottom-container">
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={this.state.isSubmitted}
                      onClick={() =>
                        this.submitTableRowData(this.state.newData, 0, true)
                      }
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </Modal>

          <div
            className="table-container1"
            style={{ maxHeight: "450px", width: "99%", margin: "6px 0px" }}
          >
            <Table
              {...userTableProps}
              columns={[
                {
                  key: "holiday_date",
                  title: "Holiday Date",
                  dataIndex: "holiday_date",
                  width: 60,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) =>
                    a.holiday_date.localeCompare(b.holiday_date),
                  render: (record, rowRecord, index) => {
                    return (
                      <DatePicker
                        name="holiday_date"
                        maxDate={new Date(`12/31/${new Date().getFullYear()}`)}
                        allowClear={true}
                        placeholder="Holiday Date"
                        disabled={!rowRecord.isEdit}
                        style={{ marginLeft: "-20px" }}
                        className="date-picker"
                        format="YYYY-MM-DD"
                        disabledDate={(current) =>
                          current > new Date(`1/1/${new Date().getFullYear() + 1}`)
                        }
                        value={
                          rowRecord.holiday_date === null ||
                            rowRecord.holiday_date === undefined
                            ? undefined
                            : moment(rowRecord.holiday_date, "YYYY-MM-DD")
                        }
                        onChange={(value, date) =>
                          this.handleChange(
                            date,
                            rowRecord,
                            index,
                            "holiday_date",
                            false
                          )
                        }
                      />
                    );
                  },
                },
                {
                  key: "holiday_reason",
                  title: "Holiday Reason",
                  dataIndex: "holiday_reason",
                  width: 140,
                  //   filters: this.createFilters("holiday_reason"),
                  //   onFilter: (value, record) =>
                  //   record.holiday_reason.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) =>
                    a.holiday_reason.localeCompare(b.holiday_reason),
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div
                          style={{
                            marginLeft: "-15px",
                            marginRight: "-10px",
                            width: "90%",
                          }}
                        >
                          <Input
                            style={{ marginRight: "2px" }}
                            maxLength={100}
                            min="0"
                            disabled={!rowRecord.isEdit}
                            value={rowRecord.holiday_reason}
                            name="holiday_reason"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                -0,
                                "holiday_reason",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },
                {
                  key: "is_active",
                  title: "Active",
                  dataIndex: "is_active",
                  width: 70,
                  filters: this.createFilters("is_active"),
                  // filters: [{ text: "Yes", value: 1 }, { text: "No", value: 0 }],
                  onFilter: (value, record) =>
                    record.is_active === Boolean(value),
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div
                          style={{ marginLeft: "-15px", marginRight: "-10px" }}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            disabled={!rowRecord.isEdit}
                            defaultValue={
                              rowRecord.is_active === true ? "Yes" : "No"
                            }
                            value={rowRecord.is_active === true ? "Yes" : "No"}
                            onChange={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "is_active",
                                index,
                                option.props.children,
                                false
                              )
                            }
                            style={{ width: "70%" }}
                          >
                            <Option key={1} value={1}>
                              {"Yes"}
                            </Option>
                            <Option key={0} value={0}>
                              {"No"}
                            </Option>
                          </Select>
                        </div>
                      </>
                    );
                  },
                },
                {
                  title: "Action",
                  dataIndex: "Submit",
                  key: "Submit",
                  width: 90,
                  visibility: false,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ display: "inline-flex", width: "100%" }}>
                          <Button
                          className={!canUserEdit(
                            this.props.userData,
                            null,
                            FeatureCodes.MS_HOLIDAY_EDIT
                          ) ? "disabledBtnClass" : "btnClass"}
                          disabled={!canUserEdit(
                            this.props.userData,
                            null,
                            FeatureCodes.MS_HOLIDAY_EDIT
                          )}
                            type="primary"
                            size={"small"}
                            style={{
                              marginLeft: 5,
                              alignSelf: "center",
                              padding: "0px 0px 2px",
                              height: 31,
                              backgroundColor: "#20346a",
                              color: "white",
                              borderRadius: 15,
                            }}
                            block
                            htmlType="submit"
                            onClick={() =>
                              rowRecord.isEdit
                                ? this.submitTableRowData(
                                  rowRecord,
                                  index,
                                  false
                                )
                                : this.handleEditChange(rowRecord, index)
                            }
                          >
                            {" "}
                            {rowRecord.isEdit ? "Submit" : "Edit"}{" "}
                          </Button>

                          <Button
                            className={
                              !rowRecord.isEdit
                                ? "disabledBtnClass"
                                : "btnClass"
                            }
                            type="primary"
                            size={"small"}
                            style={{
                              marginLeft: 5,
                              alignSelf: "center",
                              color: "white",
                              borderRadius: 15,
                              padding: "0px 0px 2px",
                              height: 31,
                              backgroundColor: "#20346a",
                            }}
                            block
                            htmlType="submit"
                            disabled={!rowRecord.isEdit}
                            onClick={() =>
                              this.handleCancelClick(rowRecord, index)
                            }
                          >
                            Cancel
                          </Button>
                        </div>
                      </>
                    );
                  },
                },
              ]}
              dataSource={this.setTableData()}
            />
          </div>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
  messageAccess: selectMSMessageRBAC,
});
export default connect(mapStateToProps)(HolidayMaster);
//export default HolidayMaster;
