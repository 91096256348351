import React from "react";
import "./download-report.styles.css";
import { Component } from "react";

import API, { baseURL } from "../../api";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { setAbdSohData } from "../../redux/buying-recommendation/buying-recommendation.actions";
import { selectSohAbdData } from "../../redux/buying-recommendation/buying-recommendation.selectors";
import { selectUserData } from "../../redux/user/user.selectors";

import { Table, Spin, message, Progress, Button } from "antd";
import Axios from "axios";
const FileDownLoad = require("js-file-download");

const reportTableProps = {
  pagination: false,
  size: "default",
  bordered: true,
  // scroll: { x: 150, y: window.innerHeight - 325 },
  scroll: { x: window.innerHeight - 750, y: window.innerHeight - 325 },
};

/**
 * @component
 * @description Reusable component to display table header.
 * @property {*} props
 * @memberof DownloadReport
 */
const TableColumnHeader = (props) => {
  const { title, subtitle } = props;
  return (
    <div className="column-header-container">
      <div
        style={{
          color: "white",
          fontSize: "12px",
          whiteSpace: "nowrap",
          fontWeight: "bold",
          textAlign: "left",
        }}
      >
        {title}
      </div>
      <div
        style={{
          color: "#cbdfff",
          fontSize: "11px",
          fontWeight: "normal",
          textAlign: "left",
        }}
      >
        {subtitle}
      </div>
    </div>
  );
};

/**
 *
 * @classdesc The Buying reccomendation tab component.
 * @description Displays a table containing the daily output from the modal
 * @component
 */
class AbdSohRecomandation extends Component {
  state = {
    isSpinnerOnTable: true,
    date: "",
    time: "",
    showEditedRecords: false,
    filteredInfo: [],
  };

  getSourceName = (id) => {
    switch (id) {
      case 1:
        return "Mandi";
      case 2:
        return "Trader";
      case 3:
        return "Farmer";
      default:
        return "";
        break;
    }
  };

  /**
   * @function
   * @description Wait until the user logs in so that a valid access token is generated
   * to call the get api for daily recommentation data.
   * @memberof DownloadReport
   */
  waitUntilLogin = () => {
    if (localStorage.getItem("accessToken") != null) {
      this.setState({ isSpinnerOnTable: true });

      API.get("/get_abd_soh")
        .then((response) => {
          const modifiedRecommendationData = response.data.data.data_list.map((item) => {
            return {
              key: item.id,
              buyingDecisionId: item.id,
              route: item.route ? item.route : "",
              demand: item.demand,
              destination: item.destination,
              destination_id: item.destination_id,
              flc_permt: item.flc_permt,
              h_f_freight_permt:
                item.h_f_freight_permt !== ""
                  ? Math.round(item.h_f_freight_permt)
                  : "",
              loading_charges_permt:
                item.loading_charges_permt !== ""
                  ? Math.round(item.loading_charges_permt)
                  : "",
              soh_price:
                item.soh_price !== "" ? Math.round(item.soh_price) : "",
              soh_stock:
                item.soh_stock !== "" ? Math.round(item.soh_stock) : "",
              source: item.source,
              source_id: item.source_id,
              source_state: item.source_state,
              state_id: item.state_id,
              suggested_cost: item.suggested_cost,
              suggested_quantity: item.suggested_quantity,
              transport_specific_cost:
                item.transport_specific_cost !== ""
                  ? Math.round(item.transport_specific_cost)
                  : "",
              variety: item.variety,
              variety_id: item.variety_id,
            };
          });

          const dateTime = new Date(response.data.data.datetime);

          const time = new Date(
            dateTime.getTime() + dateTime.getTimezoneOffset() * 60000
          ).toLocaleTimeString();

          this.setState({
            isSpinnerOnTable: false,
            date: dateTime,
            time: time,
          });

          this.props.setAbdSohData(modifiedRecommendationData);
        })
        .catch((err) => {
          message.warning(
            "Server is not responding at the moment. Please refresh the page"
          );
          this.setState({ isSpinnerOnTable: false });
        });
    } else {
      setTimeout(() => this.waitUntilLogin(), 100);
    }
  };

  componentDidMount() {
    this.waitUntilLogin();
  }

  render() {
    const ConfigOptions = {
      maxCount: 1,
    };
    message.config(ConfigOptions);

    /**
     * @function
     * @description Retrieves the daily reccomented data from redux store and assigns it as the
     * data source for the table to display
     * @memberof DownloadReport
     */
    const setRecommendationTableData = () => {
      let tableData = this.props.sohAbdData;

      let modifiedTableData;

      if (!this.state.showEditedRecords) {
        modifiedTableData = tableData;
      } else {
        modifiedTableData = tableData.filter(
          (item) =>
            item.plannedQty !== item.suggestedQty ||
            item.plannedPrice !== item.price
        );
      }

      return modifiedTableData;
    };

    /**
     *
     * @function
     * @description Generates the filter objects required for table on clicking the table header.
     * @param {string} label the column key name for which filtering is needed.
     * @returns an object for filtering corresponding to each row.
     * @memberof DownloadReport
     */
    const createFilters = (label) => {
      let filterData = this.props.sohAbdData;
      let tempData = [];
      //#region remove duplicate objects
      let uniqueFilterData = [];
      tempData = filterData.filter((item) => {
        if (
          (!this.state.filteredInfo.source ||
            this.state.filteredInfo.source.length === 0 ||
            this.state.filteredInfo.source.includes(item.source)) &&
          (!this.state.filteredInfo.source_state ||
            this.state.filteredInfo.source_state.length === 0 ||
            this.state.filteredInfo.source_state.includes(item.source_state)) &&
          (!this.state.filteredInfo.destination ||
            this.state.filteredInfo.destination.length === 0 ||
            this.state.filteredInfo.destination.includes(item.destination)) &&
          (!this.state.filteredInfo.route ||
            this.state.filteredInfo.route.length === 0 ||
            this.state.filteredInfo.route.includes(item.route)) &&
          (!this.state.filteredInfo.variety ||
            this.state.filteredInfo.variety.length === 0 ||
            this.state.filteredInfo.variety.includes(item.variety))
        ) {
          return item;
        }
      });

      filterData =
        tempData.length > 0 && label !== "source" && label !== "variety"
          ? tempData
          : filterData;

      filterData.map((mainItem) =>
        uniqueFilterData.filter((item) => item[label] === mainItem[label])
          .length > 0
          ? null
          : uniqueFilterData.push(mainItem)
      );

      uniqueFilterData = uniqueFilterData
        .map((item) => {
          return {
            text: item[label],
            value: item[label],
          };
        })
        .sort((a, b) => a.text.localeCompare(b.text));

      //#endregion

      return uniqueFilterData;
    };

    //#region

    /**
     * @function
     * @description Displays the date and time string as required by the UI.
     * @param {datetime} date the datetime object of the latest model run
     * @returns A formatted string containing the model run date information.
     * @memberof DownloadReport
     */
    const setDateString = (date) => {
      if (date) {
        var day, month, formattedDate;
        switch (date.getDate()) {
          case 1:
            day = "1st";
            break;
          case 21:
            day = "21st";
            break;
          case 31:
            day = "31st";
            break;
          case 2:
            day = "2nd";
            break;
          case 22:
            day = "22nd";
            break;
          case 3:
            day = "3rd";
            break;
          case 23:
            day = "23rd";
            break;
          default:
            day = `${date.getDate()}th`;
        }
        switch (date.getMonth() + 1) {
          case 1:
            month = " January";
            break;
          case 2:
            month = " February";
            break;
          case 3:
            month = " March";
            break;
          case 4:
            month = " April";
            break;
          case 5:
            month = " May";
            break;
          case 6:
            month = " June";
            break;
          case 7:
            month = " July";
            break;
          case 8:
            month = " August";
            break;
          case 9:
            month = " September";
            break;
          case 10:
            month = " October";
            break;
          case 11:
            month = " November";
            break;
          case 12:
            month = " December";
            break;
          default:
        }

        formattedDate = `${day} ${month} ${date.getFullYear()}`;

        return formattedDate;
      }
    };
    const handleChange = (pagination, filters, sorter) => {
      this.setState({ filteredInfo: filters });
    };
    return (
      <div
        className="download-report-container"
        style={{ height: window.innerHeight - 83 }}
      >
        <div className="download-button-container">
          {this.state.ProgressClicked && (
            <Progress
              type="circle"
              percent={this.state.downloaddata}
              width={37}
              strokeColor={{ "0%": "#108ee9", "100%": "#87d068" }}
              style={{
                paddingRight: "5px",
              }}
              trailColor="#003285"
            />
          )}
          {
            <Button
              onClick={() => {
                this.setState({ isSpinnerOnProgress: true });
                this.setState({ downloaddata: 0 });
                this.setState({ ProgressClicked: true });
                console.log("getadbsoh");

                Axios({
                  url: `${baseURL}/get_abd_soh`,
                  method: "GET",
                  params: { download: true },
                  responseType: "blob",
                  onDownloadProgress: (progressEvent) => {
                    let progress = Math.round(
                      (progressEvent.loaded * 100) / progressEvent.total
                    );
                    this.setState({ downloaddata: progress });
                  },
                })
                  .then((response) => {
                    if (response.status !== 204) {
                      FileDownLoad(
                        response.data,
                        `SOH_Report_${new Date().toLocaleDateString()}.xlsx`
                      );
                      this.setState({ isSpinnerOnProgress: false });
                      setTimeout(
                        function () {
                          this.setState({ ProgressClicked: false });
                        }.bind(this),
                        5000
                      );
                    } else {
                      message.warning("File has no data.");
                      this.setState({
                        isSpinnerOnProgress: false,
                        ProgressClicked: false,
                      });
                    }
                  })
                  .catch((err) => {
                    message.error("File cannot be downloaded");
                    this.setState({
                      isSpinnerOnProgress: false,
                      ProgressClicked: false,
                    });
                  });
              }}
              type="primary"
              shape="round"
              icon="download"
              size="large"
            >
              <div style={{ float: "right" }}>
                <div style={{ marginTop: "-7px" }}>Download</div>
                <div
                  style={{
                    fontSize: "11px",
                    lineHeight: "7px",
                    color: "#b4c8dc",
                  }}
                >
                  .xlsx report
                </div>
              </div>
            </Button>
          }
        </div>
        <div className="time-container">
          {this.props.sohAbdData.length !== 0 && (
            <>
              <div className="top-left-container">
                <div className="top-run-date">
                  {setDateString(this.state.date)}
                </div>
                <div className="sync-time">
                  {this.state.time && ` Last Sync: ${this.state.time}`}
                </div>
              </div>

              <div className="top-right-container">
                {/* <Checkbox
                  onChange={(e) => {
                    this.setState({ showEditedRecords: e.target.checked });
                  }}
                >
                  Show Edited Records Only
                </Checkbox> */}
              </div>
            </>
          )}
        </div>

        <div
          className="table-container audit-table"
          style={{ overflow: "hidden", height: "300px" }}
        >
          <Spin spinning={this.state.isSpinnerOnTable} tip="Loading...">
            <Table
              {...reportTableProps}
              onChange={handleChange}
              columns={[
                {
                  title: <TableColumnHeader title="Source" />,
                  dataIndex: "source",
                  key: "source",
                  filters: createFilters("source"),
                  onFilter: (value, record) => record.source.includes(value),
                  sortDirections: ["ascend", "descend"],
                  sorter: (a, b) => a.source.localeCompare(b.source),
                  width: 130,
                },
                {
                  title: <TableColumnHeader title="Source State" />,
                  dataIndex: "source_state",
                  key: "source_state",
                  filters: createFilters("source_state"),
                  onFilter: (value, record) =>
                    record.source_state.includes(value),
                  sortDirections: ["ascend", "descend"],
                  sorter: (a, b) =>
                    a.source_state.localeCompare(b.source_state),
                  width: 160,
                },
                {
                  title: <TableColumnHeader title="Destination" />,
                  dataIndex: "destination",
                  key: "destination",
                  sortDirections: ["ascend", "descend"],
                  sorter: (a, b) => a.destination.localeCompare(b.destination),
                  filters: createFilters("destination"),
                  onFilter: (value, record) =>
                    record.destination.includes(value),
                  width: 140,
                },
                {
                  title: <TableColumnHeader title="Route" />,
                  dataIndex: "route",
                  key: "route",
                  sorter: (a, b) => a.route.localeCompare(b.route),
                  filters: createFilters("route"),
                  onFilter: (value, record) => record.route.includes(value),
                  width: 110,
                },
                {
                  title: <TableColumnHeader title="Variety" />,
                  dataIndex: "variety",
                  key: "variety",
                  sorter: (a, b) => a.variety.localeCompare(b.variety),
                  filters: createFilters("variety"),
                  onFilter: (value, record) => record.variety.includes(value),
                  width: 120,
                },
                {
                  title: (
                    <div
                      style={{
                        fontSize: "12px",
                        marginTop: "8px",
                        fontWeight: "bold",
                      }}
                    >
                      Demand
                      <span
                        style={{
                          fontSize: "11px",
                          fontWeight: "normal",
                          color: "rgb(203, 223, 255)",
                        }}
                      >
                        &nbsp; (MT)
                      </span>
                    </div>
                  ),
                  dataIndex: "demand",
                  key: "demand",
                  sortDirections: ["ascend", "descend"],
                  sorter: (a, b) => a.demand - b.demand,
                  width: 120,
                },
                {
                  title: <TableColumnHeader title="SOH Stock" />,
                  dataIndex: "soh_stock",
                  key: "soh_stock",
                  width: 110,
                },
                {
                  title: (
                    <div
                      style={{
                        fontSize: "12px",
                        marginTop: "8px",
                        fontWeight: "bold",
                      }}
                    >
                      SOH Price
                      <span
                        style={{
                          fontSize: "11px",
                          fontWeight: "normal",
                          color: "rgb(203, 223, 255)",
                        }}
                      >
                        &nbsp; (₹/MT)
                      </span>
                    </div>
                  ),
                  dataIndex: "soh_price",
                  key: "soh_price",
                  width: 100,
                },
                {
                  title: (
                    <div
                      style={{
                        fontSize: "12px",
                        marginTop: "8px",
                        fontWeight: "bold",
                      }}
                    >
                      HF Freight
                      <span
                        style={{
                          fontSize: "11px",
                          fontWeight: "normal",
                          color: "rgb(203, 223, 255)",
                        }}
                      >
                        &nbsp; (₹/MT)
                      </span>
                    </div>
                  ),
                  dataIndex: "h_f_freight_permt",
                  key: "h_f_freight_permt",
                  width: 80,
                },
                {
                  title: (
                    <div
                      style={{
                        fontSize: "12px",
                        marginTop: "8px",
                        fontWeight: "bold",
                      }}
                    >
                      Loading Charges
                      <span
                        style={{
                          fontSize: "11px",
                          fontWeight: "normal",
                          color: "rgb(203, 223, 255)",
                        }}
                      >
                        &nbsp; (₹/MT)
                      </span>
                    </div>
                  ),
                  dataIndex: "loading_charges_permt",
                  key: "loading_charges_permt",
                  width: 90,
                },
                {
                  title: (
                    <div
                      style={{
                        fontSize: "12px",
                        marginTop: "8px",
                        fontWeight: "bold",
                      }}
                    >
                      Landed cost
                      <span
                        style={{
                          fontSize: "11px",
                          fontWeight: "normal",
                          color: "rgb(203, 223, 255)",
                        }}
                      >
                        &nbsp; (₹/MT)
                      </span>
                    </div>
                  ),
                  dataIndex: "flc_permt",
                  key: "flc_permt",
                  width: 80,
                },
                /* {
                  title: (
                    <div
                      style={{
                        fontSize: "12px",
                        // margin: "8px 0px 0px 60px",
                        marginTop: "8px",
                        fontWeight: "bold",
                      }}
                    >
                      Quantity
                      <span
                        style={{
                          fontSize: "11px",
                          fontWeight: "normal",
                          color: "rgb(203, 223, 255)",
                        }}
                      >
                        &nbsp; (MT)
                      </span>
                    </div>
                  ),

                  children: [
                    {
                      title: "Suggested",
                      dataIndex: "suggested_quantity",
                      key: "suggested_quantity",
                      sortDirections: ["descend", "ascend"],
                      sorter: (a, b) =>
                        a.suggested_quantity - b.suggested_quantity,
                      // width: 80,
                    },
                  ],
                },

                {
                  title: (
                    <div
                      style={{
                        fontSize: "12px",
                        // margin: "8px 0px 0px 40px",
                        marginTop: "8px",
                        fontWeight: "bold",
                      }}
                    >
                      PO Price
                      <span
                        style={{
                          fontSize: "11px",
                          fontWeight: "normal",
                          color: "rgb(203, 223, 255)",
                        }}
                      >
                        &nbsp; (₹/MT)
                      </span>
                    </div>
                  ),
                  children: [
                    {
                      title: "Suggested",
                      dataIndex: "suggested_cost",
                      key: "suggested_cost",
                      sortDirections: ["descend", "ascend"],
                      sorter: (a, b) => a.suggested_cost - b.suggested_cost,
                      // width: 80,
                    },
                  ],
                }, */
              ]}
              dataSource={setRecommendationTableData()}
            />
          </Spin>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  sohAbdData: selectSohAbdData,
  userData: selectUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setAbdSohData: (data) => dispatch(setAbdSohData(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AbdSohRecomandation);
