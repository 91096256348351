import { WeatherActionTypes } from "./weather.types";

const INITIAL_STATE = {
  weather: {},
  lon: 0,
  lat: 0
};

const weatherReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case WeatherActionTypes.ADD_WEATHER_DATA:
      let modifiedWeatherData = { ...state.weather, ...action.payload };
      return { ...state, weather: modifiedWeatherData };
    case WeatherActionTypes.ADD_COORDINATES: return {
      ...state,
      lon: action.payload.longitude,
      lat: action.payload.latitude
    }
    default:
      return { ...state };
  }
};

export default weatherReducer;
