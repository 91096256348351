import React from "react";
import "./user-group-form.styles.css";
import { Component } from "react";

import {
  Form,
  Radio,
  Input,
  Select,
  Switch,
  InputNumber,
  Button,
  message,
} from "antd";

import API from "../../api";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { setAllUsers } from "../../redux/user/user.actions";

/**
 * @component
 * @description Form component to add/edit user groups.
 * @property {boolean} isAdd set true for adding a new user group, false for editing an existing one.
 * @property {object} currentRecord current selected user group record.
 * @property {string[]} currentMembers the current members in the selected user group.
 * @property {function} setModalVisibility enable/disable the visibility of the form modal.
 */
class UserGroup extends Component {
  state = { isSubmitButtonClicked: false };
  render() {
    console.log("rendering form", this.props.currentRecord);

    const {

      allUsers,
      isAdd,
      currentRecord,
      currentMembers,
      setModalVisibility,
    } = this.props;

    const { getFieldDecorator } = this.props.form;

    /**
     *
     * @description function handling the api calls and redux store modification on
     *  submission of add/edit user groups.
     * @param {event} e the form submission event 
     * containing the values to be sent.
     * @memberof UserGroup
     */
    const handleSubmit = (e) => {
      e.preventDefault();
      this.props.form.validateFields((err, values) => {
        if (!err) {
          console.log(values);

          this.setState({ isSubmitButtonClicked: true });

          const selectedMembers = values.members
            .map((member) => {
              return this.props.allUsers.find((user) => user.name === member);
            })
            .map((item) => {
              return {
                user_id: item.user_id,
              };
            });


          //#region api to create or update user

          if (isAdd) {
            //#region api to add user group and update state
            const dataToSend = {
              group_name: values.name,
              members: selectedMembers,
            };
            API.post("/user/groups", dataToSend)
              .then((groupResponse) => {
                console.log("post response :", groupResponse);
                if (groupResponse.status) {
                  //#region add group to local state
                  let newGroup = {
                    ...groupResponse.data.data,
                    members: groupResponse.data.data.members.map((member) => {
                      return this.props.allUsers.find(
                        (user) => user.user_id === member.user_id
                      );
                    }),
                  };
                  let modifiedUserGroupData = [
                    ...this.props.userGroupData,
                    newGroup,
                  ];
                  console.log("new group", modifiedUserGroupData);

                  this.props.setUserGroupData(modifiedUserGroupData);

                  //#endregion

                  setModalVisibility(false, false, null);
                  this.props.form.resetFields();
                  this.setState({ isSubmitButtonClicked: false });
                } else {
                  this.setState({ isSubmitButtonClicked: false });
                  message.warning(
                    "Could not create user group. Please try again."
                  );
                  console.log("could not send post api to create user group");
                }
              })
              .catch((err) => {
                this.setState({ isSubmitButtonClicked: false });
                message.error(
                  "Could not create user group. Please check your network connectivity."
                );
                console.log(
                  "could not send post api to create user group , ERROR :",
                  err
                );
              });

            //#endregion
          } else {
            //#region api to update user and update state.

            const dataToSend = {
              group_name: values.name,
              members: selectedMembers,
            };

            API.put(`/user/groups/${currentRecord.group_id}`, dataToSend
            )
              .then((userResponse) => {
                if (userResponse.status) {
                  console.log("updated response : ", userResponse.data.data);

                  //#region update redux state
                  let modifiedUserGroupData = this.props.userGroupData.map(
                    (group) => {
                      if (group.group_id === currentRecord.group_id) {
                        return userResponse.data.data;
                      }
                      return group;
                    }
                  );

                  this.props.setUserGroupData(modifiedUserGroupData);
                  //#endregion

                  setModalVisibility(false, false, null);
                  this.props.form.resetFields();
                  this.setState({ isSubmitButtonClicked: false });
                } else {
                  this.setState({ isSubmitButtonClicked: false });
                  message.warning(
                    "Could not update user group. Please try again."
                  );
                  console.log("could not send put api to create user group");
                }
              })
              .catch((err) => {
                this.setState({ isSubmitButtonClicked: false });
                message.error(
                  "Could not update user group. Please check your network connectivity."
                );
                console.log(
                  "could not send put api to create user group , ERROR :",
                  err
                );
              });

            //#endregion
          }

          //#endregion
        } else {
          console.log("Invalid entry");
        }
      });
    };

    return (
      <div className="user-group-form-container">
        <Form
          layout="horizontal"
          //   onValuesChange={onFormLayoutChange}
          size={"small"}
          onSubmit={handleSubmit}
        >
          <div className="form-top-container">
            <div className="form-top-left">
              <Form.Item label="Group Name">
                {getFieldDecorator("name", {
                  initialValue:
                    currentRecord !== null ? currentRecord.group_name : "",
                  rules: [
                    {
                      required: true,
                      message: "Please enter group name!",
                    },
                  ],
                })(
                  <Input
                    disabled={this.state.isSubmitButtonClicked}
                    placeholder="Enter name"
                  />
                )}
              </Form.Item>
              <Form.Item label="Members">
                {getFieldDecorator("members", {
                  initialValue: currentMembers ? currentMembers : [], //[allUsers[0].name],
                  rules: [
                    {
                      required: true,
                      message: "Please select users !",
                    },
                  ],
                })(
                  <Select
                    disabled={this.state.isSubmitButtonClicked}
                    className="user-group-select"
                    showArrow
                    mode="multiple"
                    placeholder="Select members"
                  >
                    {allUsers.map((user, idx) => (
                      <Select.Option key={user.user_name} value={user.name}>
                        <div className="select-user-group-container">
                          {user.name}
                          <div className="select-info">
                            <div className="user-info-role">
                              {user.role.role_name}
                            </div>
                            <div className="user-info-branch">
                              {user.territories[0]?.territory_name}
                            </div>
                          </div>
                        </div>
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </div>
          </div>

          <div className="form-bottom-container">
            <Form.Item>
              <Button
                disabled={this.state.isSubmitButtonClicked}
                type="primary"
                htmlType="submit"
              >
                Submit
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    );
  }
}

const UserGroupForm = Form.create({ name: "user_group_form" })(UserGroup);

const mapDispatchToProps = (dispatch) => {
  return {
    setAllUsers: (data) => dispatch(setAllUsers(data)),
  };
};

export default connect(null, mapDispatchToProps)(UserGroupForm);
