import React, { Component } from "react";
import "./projection-reports-container.styles.css";

import { ReactComponent as IconTrend } from "../../assets/Report-Trend.svg";

import { Tabs } from "antd";
import FixedStateMultipleVarietyProjections from "../../components/fixed-state-variety-projections/fixed-state-variety-projections.component";
import GraphicalVisualizationForModelRuns from "../model-runs-graph-visualization/model-runs-graph-visualization.component";

const { TabPane } = Tabs;

class ProjectionReportsContainer extends Component {
  // state = { tabKey: 0 };
  render() {
    return (
      <div className="report-tabs">
        <Tabs
          // onChange={() => this.setState({ tabKey: Date.now() + "" })}
          // onChange={value => console.log(value)}
          animated={false}
          className={`main-content 
          `}
          defaultActiveKey="1"
        >
          <TabPane
            tab={
              <div className="tab-title-container">
                <div>
                  <IconTrend className="tab-title-image" />
                </div>
                State-wise Price & Volume
              </div>
            }
            key="1"
          >
            <FixedStateMultipleVarietyProjections fixedState />
          </TabPane>
          <TabPane
            tab={
              <div className="tab-title-container">
                <div>
                  <IconTrend className="tab-title-image" />
                </div>
                Variety-wise Price & Volume
              </div>
            }
            key="2"
          >
            <FixedStateMultipleVarietyProjections />
          </TabPane>
          <TabPane
            tab={
              <div className="tab-title-container">
                <div>
                  <IconTrend className="tab-title-image" />
                </div>
                Volume Visualization - Monthly Model
              </div>
            }
            key="3"
          >
            <GraphicalVisualizationForModelRuns />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default ProjectionReportsContainer;
