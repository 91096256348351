import React from "react";
import "./menu-item-selector-group.styles.css";

import AlertItem from "../alert-item/alert-item.component";
import LatestItem from "../latest-item/latest-item.component";
import WishListList from "../edit-wishlist-list/edit-wishlist-list.component";

import { SignOutAPI } from "../../auth/utils";

import { Tabs, Button } from "antd";

import editUserImage from "../../assets/edit-user.svg";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { setUserLoggedStatus } from "../../redux/user/user.actions";
import {
  selectAllMandis,
  selectAllVarietyInfo,
} from "../../redux/arrival-entry/arrival-entry.selectors";

import { setInterestedWatchList } from "../../redux/alerts/alerts.actions";
import {
  selectDisplayedAlerts,
  selectDisplayedWatchlist,
} from "../../redux/alerts/alerts.selectors";

import API from "../../api";

const { TabPane } = Tabs;

/**
 * @component
 * @description The side menu parent component. cotnains both alerts and watchlist tabs inside.
 * 
 */
class MenuItemSelectorGroup extends React.Component {
  state = {
    isEdit: false,
  };

  componentDidMount() {
    // console.log("SIDE MENU MOUNTED");
    //#region to get the interested watchlist items and save to redux


    API.get("/user/varieties")
      .then((interestedWatchlistResponse) => {

        if (interestedWatchlistResponse.data.status) {
          this.props.setInterestedWatchList(
            interestedWatchlistResponse.data.data
          );
        } else {
        }
      })
      .catch((err) => {
      });

    //#endregion
  }

  render() {
    const setEditStatus = (status) => {
      this.setState({ isEdit: status });
    };

    return (
      <div className="selector-group">

        <Tabs className="selector-item" defaultActiveKey="1">
          <TabPane className="tab-pane" size="small" tab="Watchlist" key="1">
            <div
              className="latest-tab"
              style={{ height: window.innerHeight - 120 }}
            >
              {this.state.isEdit ? (
                <>
                  <div className="top-button-container">
                    <div className="edit-title">Edit Watchlist</div>
                    <div onClick={() => setEditStatus(false)} className="back">
                      Back
                    </div>
                  </div>

                  <WishListList />
                </>
              ) : (
                <>
                  <div className="top-button-container">
                    <Button
                      onClick={() => setEditStatus(true)}
                      className="edit-button"
                    >
                      <img
                        style={{
                          width: "15px",
                          height: "15px",
                          margin: "4px 5px 0 -6px",
                        }}
                        alt="edit"
                        src={editUserImage}
                      />
                      <h5 style={{ margin: "3px 0 0 0" }}>Edit</h5>
                    </Button>
                  </div>
                  {this.props.displayedWatchlist.map((watchlistItem, idx) => (
                    <LatestItem
                      // highMandi is the bottom one and lowMandi is the upper one in the UI.
                      // named so because it is higher in profitability and not according to position in UI.
                      // thus the minimum goes to high mandi since lower price is profitable
                      // and the maximum goes to low mandi since higher price is costly to the end user.
                      key={idx}
                      isUp={watchlistItem.increase_in_price}
                      mandiName={watchlistItem.latest.mandi}
                      varietyName={watchlistItem.variety_name}
                      lowMandi={watchlistItem.maximum.mandi}
                      lowMandiPrice={watchlistItem.maximum.price}
                      highMandi={watchlistItem.minimum.mandi}
                      highMandiPrice={watchlistItem.minimum.price}
                      price={watchlistItem.latest.price}
                    />
                  ))}
                </>
              )}
            </div>
          </TabPane>
          <TabPane className="tab-pane" size="small" tab="Alerts" key="2">
            <div
              className="alerts-tab"
              style={{ height: window.innerHeight - 120 }}
            >
              {this.props.displayedAlerts.map((alert, idx) => (
                <AlertItem
                  key={idx}
                  type={alert.user_alert_type}
                  title={alert.user_alert_behaviour}
                  url={alert.news_url}
                  description={alert.description}
                  dateTime={new Date(alert.date_posted)}
                />
              ))}
            </div>
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  displayedAlerts: selectDisplayedAlerts,
  displayedWatchlist: selectDisplayedWatchlist,
  allMandis: selectAllMandis,
  allVarietyInfo: selectAllVarietyInfo,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setUserLoggedStatus: (status) => dispatch(setUserLoggedStatus(status)),

    setInterestedWatchList: (data) => dispatch(setInterestedWatchList(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuItemSelectorGroup);
