import React from "react";
import "./weekly-quality-modal-card.styles.css";

/**
 * @component
 * @description Component to display the quality check.
 * @property {number} qcScore the quality check score.
 * @property {string} title the title to display.
 * @property {string} description a short note of the quality check.
 * @property {image} image the example image to be displayed for illustration.
 */
const QualityCheckCard = (props) => {
  const { qcScore, title, description, image, GraphComponent } = props;
  return (
    <div className="quality-check-modal-card-container">
      <div className="quality-check-modal-card">
        <div className="title">{title}</div>
        <div className="description">{description}</div>
        <div className="score">
          <div className="round">
            <div className="value">{qcScore}</div>
          </div>
        </div>

        {/* <img className="qc-image" alt="example" src={image} /> */}
      </div>
      <div className="graph-container">
        <GraphComponent />
      </div>
    </div>
  );
};

export default QualityCheckCard;
