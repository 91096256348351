import React from "react";
import "./download-report.styles.css";
import { Component } from "react";

import API, { baseURL } from "../../api";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { setSuggestedRakeData } from "../../redux/buying-recommendation/buying-recommendation.actions";
import { selectSuggestedRakeData } from "../../redux/buying-recommendation/buying-recommendation.selectors";
import { selectUserData } from "../../redux/user/user.selectors";

import { Table, Button, Spin, message, Modal, Checkbox } from "antd";
import Axios from "axios";

const FileDownLoad = require("js-file-download");

const reportTableProps = {
  pagination: false,
  size: "default",
  bordered: true,
  scroll: { x: 150, y: window.innerHeight - 215 },
};

/**
 * @component
 * @description Reusable component to display table header.
 * @property {*} props
 * @memberof DownloadReport
 */
const TableColumnHeader = (props) => {
  const { title, subtitle } = props;
  return (
    <div className="column-header-container">
      <div
        style={{
          color: "white",
          fontSize: "12px",
          whiteSpace: "nowrap",
          fontWeight: "normal",
          textAlign: "left",
        }}
      >
        {title}
      </div>
      <div
        style={{
          color: "#cbdfff",
          fontSize: "11px",
          fontWeight: "normal",
          textAlign: "left",
          // subtitle && title.length < subtitle.length ? "left" : "center"
        }}
      >
        {subtitle}
      </div>
    </div>
  );
};

/**
 *
 * @classdesc The Buying reccomendation tab component.
 * @description Displays a table containing the daily output from the modal
 * @component
 */
class RakeRecomandation extends Component {
  state = {
    isSpinnerOnTable: true,
    isSpinnerOn: false,
    startDate: "",
    endDate: "",
    showEditedRecords: false,
  };

  getSourceName = (id) => {
    switch (id) {
      case 1:
        return "Mandi";
      case 2:
        return "Trader";
      case 3:
        return "Farmer";
      default:
        return "";
        break;
    }
  };

  /**
   * @function
   * @description Wait until the user logs in so that a valid access token is generated
   * to call the get api for daily recommentation data.
   * @memberof DownloadReport
   */
  waitUntilLogin = () => {
    // if (localStorage.getItem("accessToken") != null) {
    this.setState({ isSpinnerOnTable: true });

    API.get("/rake_recommendation")
      .then((response) => {
        const modifiedRecommendationData = response.data.data.data_list.map(
          (item) => {
            return {
              factory_unique_key: item.factory_unique_key,
              hub_unique_key: item.hub_unique_key,
            };
          }
        );

        const startDate = new Date(response.data.data.data_list[0].start_date);
        const endDate = new Date(response.data.data.data_list[0].end_date);

        this.setState({
          isSpinnerOnTable: false,
          startDate: startDate,
          endDate: endDate,
        });

        this.props.setSuggestedRakeData(modifiedRecommendationData);
      })
      .catch((err) => {
        message.warning(
          "Server is not responding at the moment. Please refresh the page"
        );
        this.setState({ isSpinnerOnTable: false });
      });
  };

  componentDidMount() {
    this.waitUntilLogin();
  }

  render() {
    const ConfigOptions = {
      maxCount: 1,
    };
    message.config(ConfigOptions);

    /**
     * @function
     * @description Retrieves the daily reccomented data from redux store and assigns it as the
     * data source for the table to display
     * @memberof DownloadReport
     */
    const setRecommendationTableData = () => {
      let tableData = this.props.suggestedRakeData;

      let modifiedTableData;

      if (!this.state.showEditedRecords) {
        modifiedTableData = tableData;
      } else {
        modifiedTableData = tableData.filter(
          (item) =>
            item.plannedQty !== item.suggestedQty ||
            item.plannedPrice !== item.price
          // && item.buyerType === "mandi"
        );
      }

      return modifiedTableData;
    };

    /**
     *
     * @function
     * @description Generates the filter objects required for table on clicking the table header.
     * @param {string} label the column key name for which filtering is needed.
     * @returns an object for filtering corresponding to each row.
     * @memberof DownloadReport
     */
    const createFilters = (label) => {
      let filterData = this.props.suggestedRakeData;
      //#region remove duplicate objects
      let uniqueFilterData = [];

      filterData.map((mainItem) =>
        uniqueFilterData.filter((item) => item[label] === mainItem[label])
          .length > 0
          ? null
          : uniqueFilterData.push(mainItem)
      );

      uniqueFilterData = uniqueFilterData.map((item) => {
        return {
          text: item[label],
          value: item[label],
        };
      });

      //#endregion

      return uniqueFilterData;
    };

    //#region

    /**
     * @function
     * @description Displays the date and time string as required by the UI.
     * @param {datetime} date the datetime object of the latest model run
     * @returns A formatted string containing the model run date information.
     * @memberof DownloadReport
     */
    const setDateString = (date) => {
      if (date) {
        var day, month, formattedDate;
        switch (date.getDate()) {
          case 1:
            day = "1st";
            break;
          case 21:
            day = "21st";
            break;
          case 31:
            day = "31st";
            break;
          case 2:
            day = "2nd";
            break;
          case 22:
            day = "22nd";
            break;
          case 3:
            day = "3rd";
            break;
          case 23:
            day = "23rd";
            break;
          default:
            day = `${date.getDate()}th`;
        }
        switch (date.getMonth() + 1) {
          case 1:
            month = " January";
            break;
          case 2:
            month = " February";
            break;
          case 3:
            month = " March";
            break;
          case 4:
            month = " April";
            break;
          case 5:
            month = " May";
            break;
          case 6:
            month = " June";
            break;
          case 7:
            month = " July";
            break;
          case 8:
            month = " August";
            break;
          case 9:
            month = " September";
            break;
          case 10:
            month = " October";
            break;
          case 11:
            month = " November";
            break;
          case 12:
            month = " December";
            break;
          default:
        }

        formattedDate = `${day} ${month} ${date.getFullYear()}`;

        return formattedDate;
      }
    };
    //#endregion

    return (
      <div
        className="download-report-container"
        style={{ height: window.innerHeight - 83 }}
      >
        <Spin spinning={this.state.isSpinnerOn} tip="Loading...">
          \
          <div className="download-button-container">
            {
              <Button
                onClick={() => {
                  this.setState({ isSpinnerOn: true });
                  // API.get("/download_excel")
                  //   .then((fileresponse) => {
                  //     if (fileresponse.data.status) {
                  //#region file download
                  Axios({
                    url: `${baseURL}/download_excel"`,
                    method: "GET",
                    responseType: "blob",
                  })
                    .then((response) => {
                      if (response.status !== 204) {
                        FileDownLoad(
                          response.data,
                          `Report_${new Date().toLocaleDateString()}.xlsx`
                        );
                      } else {
                        message.warning("File has no data.");
                      }
                      this.setState({ isSpinnerOn: false });
                    })
                    .catch((err) => {
                      message.error("File cannot be downloaded");
                      this.setState({ isSpinnerOn: false });
                    });
                  //#endregion
                  //   } else {
                  //     message.warning("File has no data");
                  //     this.setState({ isSpinnerOn: false });
                  //   }
                  // })
                  // .catch((err) => {
                  //   message.error("Error downloading file");
                  //   this.setState({ isSpinnerOn: false });
                  // });
                }}
                type="primary"
                shape="round"
                icon="download"
                size="large"
              >
                <div style={{ float: "right" }}>
                  <div style={{ marginTop: "-7px" }}>Download</div>
                  <div
                    style={{
                      fontSize: "11px",
                      lineHeight: "7px",
                      color: "#b4c8dc",
                    }}
                  >
                    .xlsx report
                  </div>
                </div>
              </Button>
            }
          </div>
          <div className="time-container">
            {this.props.suggestedRakeData.length !== 0 && (
              <>
                <div className="top-left-container">
                  <div className="top-run-date">
                    {setDateString(this.state.startDate)}
                  </div>
                  <div className="top-run-date">-</div>
                  <div className="top-run-date">
                    {setDateString(this.state.endDate)}
                  </div>

                  {/* <div className="mandi-info ">{`|  ${editedRecordCount} ${
                  editedRecordCount > 1 ? "Records" : "Record"
                } Edited`}</div> */}
                </div>

                <div className="top-right-container">
                  {/* <Checkbox
                  onChange={(e) => {
                    this.setState({ showEditedRecords: e.target.checked });
                  }}
                >
                  Show Edited Records Only
                </Checkbox> */}
                </div>
              </>
            )}
          </div>
          <div className="table-container audit-table">
            <Spin spinning={this.state.isSpinnerOnTable} tip="Loading...">
              <Table
                // rowClassName={(record, idx) =>
                //   record.plannedQty != record.suggestedQty ||
                //   record.plannedPrice != record.price
                //     ? // && record.buyerType === "mandi"
                //       "changed-row"
                //     : ""
                // }
                {...reportTableProps}
                columns={[
                  {
                    title: <TableColumnHeader title="Factory Unique Key" />,
                    dataIndex: "factory_unique_key",
                    key: "factory_unique_key",
                    filters: createFilters("factory_unique_key"),
                    onFilter: (value, record) =>
                      record.factory_unique_key.includes(value),
                    sortDirections: ["descend", "ascend"],
                    sorter: (a, b) =>
                      a.factory_unique_key.localeCompare(b.factory_unique_key),
                    width: 100,
                  },

                  {
                    title: <TableColumnHeader title="Hub Unique Key" />,
                    dataIndex: "hub_unique_key",
                    key: "hub_unique_key",
                    filters: createFilters("hub_unique_key"),
                    onFilter: (value, record) =>
                      record.hub_unique_key.includes(value),
                    sortDirections: ["descend", "ascend"],
                    sorter: (a, b) =>
                      a.hub_unique_key.localeCompare(b.hub_unique_key),
                    width: 80,
                  },
                ]}
                dataSource={setRecommendationTableData()}
                //dataSource={this.state.recommendationData}
              />
            </Spin>
          </div>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  suggestedRakeData: selectSuggestedRakeData,
  userData: selectUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setSuggestedRakeData: (data) => dispatch(setSuggestedRakeData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RakeRecomandation);
