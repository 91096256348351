import { AlertsActionTypes } from "./alerts.types";

export const setAlerts = (data) => {
  return {
    type: AlertsActionTypes.SET_ALERTS_DATA,
    payload: data,
  };
};

export const addAlerts = (data) => {
  return {
    type: AlertsActionTypes.ADD_ALERTS_DATA,
    payload: data,
  };
};

export const setUserGroupData = (data) => {
  return {
    type: AlertsActionTypes.SET_USER_GROUP_DATA,
    payload: data,
  };
};

export const setNews = (data) => {
  return {
    type: AlertsActionTypes.SET_NEWS_DATA,
    payload: data,
  };
};

export const setInterestedWatchList = (data) => {
  return {
    type: AlertsActionTypes.SET_INTERESTED_WATCHLIST,
    payload: data,
  };
};

export const addInterestedWatchlistItem = (item) => {
  return {
    type: AlertsActionTypes.ADD_INTERESTED_WATCHLIST_ITEM,
    payload: item,
  };
};

export const setDisplayedAlerts = (data) => {
  return {
    type: AlertsActionTypes.SET_DISPLAYED_ALERTS,
    payload: data,
  };
};

export const setDisplayedWatchlist = (data) => {
  return {
    type: AlertsActionTypes.SET_DISPLAYED_WATCHLIST,
    payload: data,
  };
};
