import React from "react";
import "./volume-buying-plan-state-factory.styles.css";

import { Table, message, Empty, Spin } from "antd";
import CustomSelectWeekly from "../custom-select-weekly/custom-select-weekly.component";
import { useState } from "react";
import { useEffect } from "react";

import { getVolumeBuyingPlanStateFactory } from "../../services/generic-apis";

const { Column, ColumnGroup } = Table;

const VolumeBuyingPlanStateFactory = (props) => {
  const {} = props;

  const [isLoading, setLoading] = useState(false);
  const [selectedRunMonth, setRunMonth] = useState(undefined);
  const [selectedQCType, setQCType] = useState(undefined);
  const [nestedDataTop, setNestedDataTop] = useState(undefined);
  const [normalData, setNormalData] = useState(undefined);
  const [nestedDataBottom, setNestedDataBottom] = useState(undefined);

  useEffect(() => {
    setRunMonth(finiteFilters.model_runs[0].id);
    setQCType(finiteFilters.qc_model_runs[0].id);
    getData(finiteFilters.model_runs[0].id, finiteFilters.qc_model_runs[0].id);
  }, []);

  const finiteFilters = {
    model_runs: [
      { id: 2, name: "Feb" },
      { id: 3, name: "March" },
      { id: 4, name: "April" },
      { id: 5, name: "May" },
      { id: 6, name: "June" },
    ],
    qc_model_runs: [
      { id: 1, name: "Model QC" },
      { id: 3, name: "Business QC" },
    ],
  };

  const getData = async (run_month, qc_type) => {
    setLoading(true);

    console.log(
      `getting data for month : ${run_month} and qc_type : ${qc_type}`
    );
    try {
      const [
        nestedResponseTop,
        normalResponse,
        nestedResponseBottom,
      ] = await getVolumeBuyingPlanStateFactory(run_month, qc_type);

      setLoading(false);

      // top data
      if (nestedResponseTop.data.status) {
        setNestedDataTop(nestedResponseTop.data.data);
      } else {
        message.error("Could not retrieve data for nested table.");
      }

      // middle data
      if (normalResponse.data.status) {
        setNormalData(normalResponse.data.data);
      } else {
        message.error("Could not retrieve data for percentage table.");
      }

      // bottom data
      if (nestedResponseBottom.data.status) {
        console.log("bottom data", nestedResponseBottom.data.data);
        setNestedDataBottom(nestedResponseBottom.data.data);
      } else {
        message.error("Could not retrieve data for nested table.");
      }

      console.log(
        "all responses : ",
        nestedResponseTop,
        normalResponse,
        nestedResponseBottom
      );
    } catch (error) {
      setLoading(false);
    }
  };

  const onMonthSelected = (value) => {
    setRunMonth(value);
    getData(value, selectedQCType);
  };

  const onQCSelected = (value) => {
    setQCType(value);
    getData(selectedRunMonth, value);
  };

  const getNestedTopTableData = () => {
    let modifiedNestedData = nestedDataTop;

    modifiedNestedData = modifiedNestedData.map((item) => {
      return {
        key: item.Variety,
        state: item.Variety,
        bihar: item.bihar ? item.bihar : 0,
        delhi: item.delhi ? item.delhi : 0,
        gujarat: item.gujarat ? item.gujarat : 0,
        madhyapradesh: item.madhya_pradesh ? item.madhya_pradesh : 0,
        maharashtra: item.maharashtra ? item.maharashtra : 0,
        rajasthan: item.rajasthan ? item.rajasthan : 0,
        uttarpradesh: item.uttar_pradesh ? item.uttar_pradesh : 0,
        grand_total: item.total_vol,
        children: item.Factories.map((item) => {
          return {
            key: item.Factory + "_" + item.Variety,
            state: item.Factory,
            bihar: item.bihar ? item.bihar : 0,
            delhi: item.delhi ? item.delhi : 0,
            gujarat: item.gujarat ? item.gujarat : 0,
            madhyapradesh: item.madhya_pradesh ? item.madhya_pradesh : 0,
            maharashtra: item.maharashtra ? item.maharashtra : 0,
            rajasthan: item.rajasthan ? item.rajasthan : 0,
            uttarpradesh: item.uttar_pradesh ? item.uttar_pradesh : 0,
            grand_total: item.total_vol,
          };
        }),
      };
    });

    const totalRow = {
      key: "Total",
      state: "Total",
      bihar: modifiedNestedData.reduce(
        (acc, item) => acc + (item.bihar ? item.bihar : 0),
        0
      ),
      delhi: modifiedNestedData.reduce(
        (acc, item) => acc + (item.delhi ? item.delhi : 0),
        0
      ),
      gujarat: modifiedNestedData.reduce(
        (acc, item) => acc + (item.gujarat ? item.gujarat : 0),
        0
      ),
      madhyapradesh: modifiedNestedData.reduce(
        (acc, item) => acc + (item.madhyapradesh ? item.madhyapradesh : 0),
        0
      ),
      maharashtra: modifiedNestedData.reduce(
        (acc, item) => acc + (item.maharashtra ? item.maharashtra : 0),
        0
      ),
      rajasthan: modifiedNestedData.reduce(
        (acc, item) => acc + (item.rajasthan ? item.rajasthan : 0),
        0
      ),
      uttarpradesh: modifiedNestedData.reduce(
        (acc, item) => acc + (item.uttarpradesh ? item.uttarpradesh : 0),
        0
      ),
      grand_total: modifiedNestedData.reduce(
        (acc, item) => acc + item.grand_total || 0,
        0
      ),
    };

    console.log("sum table data : ", [...modifiedNestedData, totalRow]);
    return [...modifiedNestedData, totalRow];
  };
  const getNestedBottomTableData = () => {
    let modifiedNestedData = nestedDataBottom;

    modifiedNestedData = modifiedNestedData.map((item) => {
      return {
        key: item.state_variety,
        state: item.state_variety,
        bihar: item.bihar ? item.bihar : 0,
        delhi: item.delhi ? item.delhi : 0,
        gujarat: item.gujarat ? item.gujarat : 0,
        madhyapradesh: item.madhya_pradesh ? item.madhya_pradesh : 0,
        maharashtra: item.maharashtra ? item.maharashtra : 0,
        rajasthan: item.rajasthan ? item.rajasthan : 0,
        uttarpradesh: item.uttar_pradesh ? item.uttar_pradesh : 0,
        grand_total: item.total_cost,
        children: item.Factories.map((item) => {
          return {
            key: item.Factory + "_" + item.Variety,
            state: item.Factory,
            bihar: item.bihar ? item.bihar : 0,
            delhi: item.delhi ? item.delhi : 0,
            gujarat: item.gujarat ? item.gujarat : 0,
            madhyapradesh: item.madhya_pradesh ? item.madhya_pradesh : 0,
            maharashtra: item.maharashtra ? item.maharashtra : 0,
            rajasthan: item.rajasthan ? item.rajasthan : 0,
            uttarpradesh: item.uttar_pradesh ? item.uttar_pradesh : 0,
            grand_total: item.total_cost,
          };
        }),
      };
    });
    const totalRow = {
      key: "Total",
      state: "Total",
      bihar: modifiedNestedData.reduce(
        (acc, item) => acc + (item.bihar ? item.bihar : 0),
        0
      ),
      delhi: modifiedNestedData.reduce(
        (acc, item) => acc + (item.delhi ? item.delhi : 0),
        0
      ),
      gujarat: modifiedNestedData.reduce(
        (acc, item) => acc + (item.gujarat ? item.gujarat : 0),
        0
      ),
      madhyapradesh: modifiedNestedData.reduce(
        (acc, item) => acc + (item.madhyapradesh ? item.madhyapradesh : 0),
        0
      ),
      maharashtra: modifiedNestedData.reduce(
        (acc, item) => acc + (item.maharashtra ? item.maharashtra : 0),
        0
      ),
      rajasthan: modifiedNestedData.reduce(
        (acc, item) => acc + (item.rajasthan ? item.rajasthan : 0),
        0
      ),
      uttarpradesh: modifiedNestedData.reduce(
        (acc, item) => acc + (item.uttarpradesh ? item.uttarpradesh : 0),
        0
      ),
      grand_total: modifiedNestedData.reduce(
        (acc, item) => acc + item.grand_total || 0,
        0
      ),
    };

    console.log("sum table data : ", [...modifiedNestedData, totalRow]);
    return [...modifiedNestedData, totalRow];
  };
  const getNormalTableData = () => {
    let modifiedNormalData = normalData;

    modifiedNormalData = modifiedNormalData.map((item) => {
      return {
        key: item.Factory,
        state: item.Factory,
        bihar: item.bihar,
        delhi: item.delhi,
        gujarat: item.gujarat,
        madhyapradesh: item.madhya_pradesh,
        maharashtra: item.maharashtra,
        rajasthan: item.rajasthan,
        uttarpradesh: item.uttar_pradesh,
        grand_total: item.total_IF,
      };
    });

    const totalRow = {
      key: "Total",
      state: "Total",
      bihar: modifiedNormalData.reduce((acc, item) => acc + item.bihar || 0, 0),
      delhi: modifiedNormalData.reduce((acc, item) => acc + item.delhi || 0, 0),
      gujarat: modifiedNormalData.reduce((acc, item) => acc + item.gujarat || 0, 0),
      madhyapradesh: modifiedNormalData.reduce(
        (acc, item) => acc + item.madhyapradesh || 0,
        0
      ),
      maharashtra: modifiedNormalData.reduce(
        (acc, item) => acc + item.maharashtra || 0,
        0
      ),
      rajasthan: modifiedNormalData.reduce(
        (acc, item) => acc + item.rajasthan || 0,
        0
      ),
      uttarpradesh: modifiedNormalData.reduce(
        (acc, item) => acc + item.uttarpradesh || 0,
        0
      ),
      grand_total: modifiedNormalData.reduce(
        (acc, item) => acc + item.grand_total || 0,
        0
      ),
    };

    console.log("middle table data : ", [...modifiedNormalData, totalRow]);
    return [...modifiedNormalData, totalRow];
  };

  return (
    <Spin spinning={isLoading}>
      <div className="volume-buying-plan-state-container">
        <div className="filters box-shadow-card">
          <div
            className="temporal-select center box-shadow-card"
            style={{ height: "70px" }}
          >
            <div className="temporal-select-title">Select Run Month</div>
            <CustomSelectWeekly
              list={finiteFilters.model_runs.map((item) => {
                return {
                  name: item.name,
                  id: item.id,
                };
              })}
              value={selectedRunMonth}
              onSelect={onMonthSelected}
              //   placeholder={`Select ${setSelectText()}`}
            />
          </div>
          <div
            className="temporal-select center box-shadow-card"
            style={{ height: "70px" }}
          >
            <div className="temporal-select-title">Select QC Type</div>
            <CustomSelectWeekly
              list={finiteFilters.qc_model_runs.map((item) => {
                return {
                  name: item.name,
                  id: item.id,
                };
              })}
              value={selectedQCType}
              onSelect={onQCSelected}
              //   placeholder={`Select ${setSelectText()}`}
            />
          </div>
        </div>
        {nestedDataTop ? (
          <div
            style={{ marginBottom: "20px" }}
            className="normal-table box-shadow-card"
          >
            <Table
              rowClassName={(record, index) =>
                record.state === "Total" ? "total-row" : ""
              }
              pagination={false}
              dataSource={getNestedTopTableData()}
            >
              <ColumnGroup title="Volume Buying Plan - Variety State">
                <Column
                  className="column"
                  title="Variety"
                  dataIndex="state"
                  key="state"
                />
                <Column
                  className="column"
                  title="Bihar"
                  dataIndex="bihar"
                  key="bihar"
                />
                <Column
                  className="column"
                  title="Delhi"
                  dataIndex="delhi"
                  key="delhi"
                />
                <Column
                  className="column"
                  title="Gujarat"
                  dataIndex="gujarat"
                  key="gujarat"
                />
                <Column
                  className="column"
                  title="Madhya Pradesh"
                  dataIndex="madhyapradesh"
                  key="madhyapradesh"
                />
                <Column
                  className="column"
                  title="Maharashtra"
                  dataIndex="maharashtra"
                  key="maharashtra"
                />
                <Column
                  className="column"
                  title="Uttar Pradesh"
                  dataIndex="uttarpradesh"
                  key="uttarpradesh"
                />
                <Column
                  className="column"
                  title="Rajasthan "
                  dataIndex="rajasthan"
                  key="rajasthan"
                />
                <Column
                  className="column"
                  title="Grand Total"
                  dataIndex="grand_total"
                  key="grand_total"
                />
              </ColumnGroup>
            </Table>
          </div>
        ) : (
          <Empty
            style={{ margin: "10px" }}
            description="No data available for this table."
          />
        )}

        {normalData ? (
          <div className="normal-table box-shadow-card">
            <Table
              rowClassName={(record, index) =>
                record.state === "Total" ? "total-row" : ""
              }
              pagination={false}
              scroll={{ y: 500 }}
              dataSource={getNormalTableData()}
            >
              <ColumnGroup title="Indicative freight">
                <Column
                  className="column"
                  title="Factory"
                  dataIndex="state"
                  key="state"
                />
                <Column
                  className="column"
                  title="Bihar"
                  dataIndex="bihar"
                  key="bihar"
                />
                <Column
                  className="column"
                  title="Delhi"
                  dataIndex="delhi"
                  key="delhi"
                />
                <Column
                  className="column"
                  title="Gujarat"
                  dataIndex="gujarat"
                  key="gujarat"
                />
                <Column
                  className="column"
                  title="Madhya Pradesh"
                  dataIndex="madhyapradesh"
                  key="madhyapradesh"
                />
                <Column
                  className="column"
                  title="Maharashtra"
                  dataIndex="maharashtra"
                  key="maharashtra"
                />
                <Column
                  className="column"
                  title="Uttar Pradesh"
                  dataIndex="uttarpradesh"
                  key="uttarpradesh"
                />
                <Column
                  className="column"
                  title="Rajasthan "
                  dataIndex="rajasthan"
                  key="rajasthan"
                />
                <Column
                  className="column"
                  title="Grand Total"
                  dataIndex="grand_total"
                  key="grand_total"
                />
              </ColumnGroup>
            </Table>
          </div>
        ) : (
          <Empty
            style={{ margin: "10px" }}
            description="No data available for this table."
          />
        )}

        {nestedDataBottom ? (
          <div
            style={{ marginBottom: "45px" }}
            className="normal-table box-shadow-card"
          >
            <Table
              rowClassName={(record, index) =>
                record.state === "Total" ? "total-row" : ""
              }
              pagination={false}
              dataSource={getNestedBottomTableData()}
            >
              <ColumnGroup title="Sum of Landed Costs">
                <Column
                  className="column"
                  title="Variety"
                  dataIndex="state"
                  key="state"
                />
                <Column
                  className="column"
                  title="Bihar"
                  dataIndex="bihar"
                  key="bihar"
                />
                <Column
                  className="column"
                  title="Delhi"
                  dataIndex="delhi"
                  key="delhi"
                />
                <Column
                  className="column"
                  title="Gujarat"
                  dataIndex="gujarat"
                  key="gujarat"
                />
                <Column
                  className="column"
                  title="Madhya Pradesh"
                  dataIndex="madhyapradesh"
                  key="madhyapradesh"
                />
                <Column
                  className="column"
                  title="Maharashtra"
                  dataIndex="maharashtra"
                  key="maharashtra"
                />
                <Column
                  className="column"
                  title="Uttar Pradesh"
                  dataIndex="uttarpradesh"
                  key="uttarpradesh"
                />
                <Column
                  className="column"
                  title="Rajasthan "
                  dataIndex="rajasthan"
                  key="rajasthan"
                />
                <Column
                  className="column"
                  title="Grand Total"
                  dataIndex="grand_total"
                  key="grand_total"
                />
              </ColumnGroup>
            </Table>
          </div>
        ) : (
          <Empty
            style={{ margin: "10px 0 45px 0px" }}
            description="No data available for this table."
          />
        )}
      </div>
    </Spin>
  );
};

export default VolumeBuyingPlanStateFactory;
