import React from "react";
import "./buying-decision-form.styles.css";
import { Component } from "react";

import {
  Form,
  Radio,
  Input,
  Select,
  Switch,
  InputNumber,
  Button,
  message,
} from "antd";

import API from "../../api";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { setAllUsers } from "../../redux/user/user.actions";

/**
 * @component
 * @description Form component to handle buying decision submissions.
 * @property {object} currentRecord the current row record.
 * @property {object[]} claimTypeList list of clain types.
 * @property {object[]} divisionList list of divisions.
 * @property {function} onSubmit callback on form submission.
 * @property {function} setSubmitModalVisibility function to enable/disable the modal form visibility.
 */
class BuyingDecisionForm extends Component {
  state = { isSubmitButtonClicked: false };
  render() {
    const {
      currentRecord,
      claimTypeList,
      divisionList,
      onSubmit,
      setSubmitModalVisibility,
      isMandiLevel,
    } = this.props;

    const { getFieldDecorator } = this.props.form;

    /**
     *
     * @description function handling the api calls and redux store modification on
     *  submission of buying plans.
     * @param {event} e the form submission event
     * containing the values to be sent.
     * @memberof BuyingDecisionForm
     */
    const handleSubmit = (e) => {
      e.preventDefault();
      this.props.form.validateFields((err, values) => {
        if (!err) {
          this.setState({ isSubmitButtonClicked: true });
          onSubmit(values.claimType, values.division);
          this.setState({ isSubmitButtonClicked: false });
          setSubmitModalVisibility(false);
        } else {

        }
      });
    };

    return (
      <div className="user-group-form-container">
        <Form layout="horizontal" size={"small"} onSubmit={handleSubmit}>
          <div className="form-top-container">
            <div className="form-top-left">
              <Form.Item>
                <div style={{ width: "90%", margin: "4px 0 0 0 " }}>
                  Claim Type
                </div>
                {getFieldDecorator("claimType", {
                  initialValue: currentRecord
                    ? isMandiLevel ? 'No Claim' : currentRecord.claim_type
                    : claimTypeList[0].name,
                  rules: [
                    {
                      required: true,
                      message: "Please select claim type !",
                    },
                  ],
                })(
                  <Select
                    disabled={this.state.isSubmitButtonClicked || isMandiLevel}
                    // showSearch
                    showArrow
                    // mode="multiple"
                    placeholder="Select Claim Type"
                  >
                    {claimTypeList.map((claimType, idx) => (
                      <Select.Option key={claimType.id} value={claimType.name}>
                        {claimType.name}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item>
                <div style={{ width: "90%", margin: "4px 0 0 0 " }}>
                  Division
                </div>
                {getFieldDecorator("division", {
                  initialValue: currentRecord
                    ? currentRecord.division
                    : divisionList[0].name,
                  rules: [
                    {
                      required: true,
                      message: "Please select division !",
                    },
                  ],
                })(
                  <Select
                    disabled={this.state.isSubmitButtonClicked}
                    // showSearch
                    showArrow
                    // mode="multiple"
                    placeholder="Select Division"
                  >
                    {divisionList.map((division, idx) => (
                      <Select.Option key={division.id} value={division.name}>
                        {division.name}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </div>
          </div>

          <div className="form-bottom-container">
            <Form.Item>
              <Button
                disabled={this.state.isSubmitButtonClicked}
                type="primary"
                htmlType="submit"
              >
                Submit
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    );
  }
}

const BuyingSubmissionForm = Form.create({ name: "buying_decision_form" })(
  BuyingDecisionForm
);

const mapDispatchToProps = (dispatch) => {
  return {
    setAllUsers: (data) => dispatch(setAllUsers(data)),
  };
};

export default connect(null, mapDispatchToProps)(BuyingSubmissionForm);
