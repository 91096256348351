import React from "react";
import "./add-new-variety-card.styles.css";

import plusImage from "../../assets/plus.svg";

/**
 *
 * @component
 * @description Add variety card component in arrivals entry screen
 * @property {string} currentArrivalScreen FOR/Mandi/Farmer
 * @property {event} onClick Handle click event
 */
const AddNewVarietyCard = (props) => {
  const { onClick, currentArrivalScreen, hubID, hubs,mandis ,mandiID} = props;

  /**
   *
   * @description Set height of card according to the buyer type.
   * @param {string} currentArrivalScreen FOR/Mandi/Farmer
   * @returns Height
   */
  const setHeight = (currentArrivalScreen) => {
    switch (currentArrivalScreen) {
      case "Mandi":
        return "439px";
      case "FOR":
        return "411px";
      case "Farmer":
        return "195px";
      default:
        return "439px";
    }
  };

  return (
    <div
      style={{ height: `${setHeight(currentArrivalScreen)}` }}
      className={`add-variety-container ${
        //currentArrivalScreen ==="FOR"?
        hubs && hubs.length && hubs.filter((item) => item.hub_id === hubID)[0] &&
         hubs.filter((item) => item.hub_id === hubID)[0].hub_status === "open"
          ? "add-variety-container"
          : "add-variety-container-disabled"
      // :  mandis && mandis.length && mandis.filter((item) => item.mandi_id === mandiID)[0] &&
      // mandis.filter((item) => item.mandi_id === mandiID)[0].mandi_status === "open"
      //  ? "add-variety-container"
      //  : "add-variety-container-disabled"
      } `}
    >
      <img
        onClick={() => onClick()}
        className="add-image"
        alt="add variety"
        src={plusImage}
      />
      <div className="add-text">Add New Variety</div>
    </div>
  );
};

export default AddNewVarietyCard;
