import React from "react";
import "./destination-branch-map-master.styles.css";
import { Component } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUserData,selectMSMessageRBAC } from "../../redux/user/user.selectors";
import { canUserEdit,getUserMSRBACMessage } from "../../auth/utils";
import { FeatureCodes } from "../../auth/data";
import {
  Table,
  Spin,
  Input,
  Select,
  Button,
  message,
  Form,
  Modal,
  Radio,
} from "antd";

import API from "../../api";
const { Search } = Input;
const { Option } = Select;
/**
 * @description Component for user management tab
 *
 * @component
 */
class DestinationBranchMapMaster extends Component {
  state = {
    isModalVisible: false,
    isAdd: false,
    currentRecord: null,
    userBranches: null,
    searchText: null,
    searchUserType: 0,
    DestinationBranchTableData: [],
    branchList: [],
    mandiRegionList: [],
    monthList: [],
    factoryList: [],
    hubBranchList: [],
    isSpinnerOnLoad: false,
    isSubmitted: false,
    newData: {
      is_active: true,
      branch_id: undefined,
      branch_name: "",
      destination_branch_id: undefined,
      destination_id: undefined,
      destination_name: "",
      isEdit: false,
    },
    rowData: undefined,
    selectedRegionValue: undefined,
    selectedRegionName: "",
    selectedBranchValue: undefined,
    selectedBranchName: "",
    selectedBranchList: [],
    selectedRegionList: [],
  };
  componentDidMount() {
    this.setState({ isSpinnerOnLoad: true });

    this.getBranchList();
    this.getFactoryList();
    // this.getHubRegion();
    // this.getHubBranch();
  }

  getBranchList = async () => {
    this.setState({ isSpinnerOnLoad: true });

    //TODO: change api
    await API.get("/list_branch")
      .then((roleData) => {
        this.setState(
          {
            branchList: roleData.data.data,
            selectedBranchValue: roleData.data.data[0].branch_id
          },
          () => this.getDestinationBranchMapMaster()
        );
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400) message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };


  getFactoryList = async () => {
    this.setState({ isSpinnerOnLoad: true });


    await API.get("/list_factory")
      .then((hubList) => {
        this.setState({
          factoryList: hubList.data.data,
          isSpinnerOnLoad: false,
        });
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };

  getDestinationBranchMapMaster = async () => {
    this.setState({ isSpinnerOnLoad: true });


    const params = {
      branch_id:
        this.state.selectedBranchValue === null || this.state.selectedBranchValue === undefined
          ? null
          : [this.state.selectedBranchValue],
      // region_id:
      //   this.state.selectedRegionValue === null
      //     ? null
      //     : [this.state.selectedRegionValue],
    };
    await API.get("/destination_branch_map", { params: params })
      .then((varietyResponse) => {

        varietyResponse.data.data && varietyResponse.data.data.map((dataDetailItem, index) => {
          dataDetailItem.key = index;
          dataDetailItem.isEdit = false;
        });
        this.setState(
          {
            DestinationBranchTableData: varietyResponse.data.data,
            // InflationMasterTableData:[],
          },
          () => this.setState({ isSpinnerOnLoad: false, isAdd: true })
        );

      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400) message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };

  setTableData = () => {
    if (this.state.DestinationBranchTableData !== undefined || this.state.DestinationBranchTableData.length >= 0) {
      const userTableData = this.state.DestinationBranchTableData.map((user, index) => {
        return {
          key: user.key,
          is_active: user.is_active,
          branch_id: user.branch_id,
          branch_name: user.branch_name,
          destination_branch_id: user.destination_branch_id,
          destination_id: user.destination_id,
          destination_name: user.destination_name,
          isEdit: user.isEdit,
        };
      });

      let dataAfterSearch = userTableData;

      if (this.state.searchText) {
        dataAfterSearch = dataAfterSearch.filter(
          (item) =>
            (item.branch_name !== null &&
              item.branch_name
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase())) ||
            (item.destination_name !== null &&
              item.destination_name
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase()))
          // (item.hub_name !== null &&
          //   item.hub_name
          //     .toLowerCase()
          //     .includes(this.state.searchText.toLowerCase())) 
        );
      }

      return dataAfterSearch;
    }
    else {
      return this.state.DestinationBranchTableData;
    }
  };

  submitTableRowData = (row, index, isPopup) => {
    this.setState({ isSpinnerOnLoad: true, isSubmitted: true });
    let warningmessage = "";
    if (row.branch_name === null || row.branch_name === undefined || row.branch_name === "") {
      warningmessage = "Please select Branch";
    } else if (row.destination_name === null || row.destination_name === undefined || row.destination_name === "") {
      warningmessage = "Please select Factory";
    }

    if (
      warningmessage === "" ||
      warningmessage === undefined ||
      warningmessage === null
    ) {
      this.setState({ isSpinnerOnLoad: true });
      this.setState({ isSubmitted: true })
      let data = {

        branch_id: row.branch_id,
        destination_branch_id: row.destination_branch_id,
        destination_id: row.destination_id,
        is_active: row.is_active === true ? 1 : 0,
      };



      API.put("/destination_branch_map", data,)
        .then((response) => {
          if (response.status === 200 && response.data.status) {
            message.info(response.data.message, 5);
            this.setModalVisibility(false);
            this.getDestinationBranchMapMaster();
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
          } else if (!response.data.status) {
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
            message.error(response.data.message, 5);
            // this.setModalVisibility(false);
          } else {
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
            message.error("Please try again", 5);
          }
        })
        .catch((err) => {
          if (err) {
            if (err.response && err.response.status === 400) message.error("Something went wrong. Please try again later.", 5);
            else {
              message.error("Something went wrong.. Please try again later.", 5);
            }
          } else {
            message.error("Something went wrong... Please try again later.", 5);
          }
          this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
          // this.setModalVisibility(false);
        });
    } else {
      this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
      message.error(warningmessage, 5);
    }
  };

  setModalVisibility = (status) => {
    if (status) {
      const nData = {
        is_active: true,
        branch_id: undefined,
        branch_name: "",
        destination_branch_id: undefined,
        destination_id: undefined,
        destination_name: "",
        isEdit: false,
      };

      this.setState({ newData: nData });
    }

    this.setState({ isModalVisible: status });
  };

  TableColumnHeader = (props) => {
    const { title, subtitle } = props;
    return (
      <div className="column-header-container">
        <div
          style={{
            color: "white",
            fontSize: "12px",
            whiteSpace: "nowrap",
            fontWeight: "normal",
            textAlign: "left",
          }}
        >
          {title}
        </div>
        <div
          style={{
            color: "#cbdfff",
            fontSize: "11px",
            fontWeight: "normal",
            textAlign: "left",
            // subtitle && title.length < subtitle.length ? "left" : "center"
          }}
        >
          {subtitle}
        </div>
      </div>
    );
  };

  createFilters = (label) => {
    let filterData = this.state.DestinationBranchTableData;
    //#region remove duplicate objects
    let uniqueFilterData = [];

    if (label === "is_active" && this.state.DestinationBranchTableData && this.state.DestinationBranchTableData.length > 0) {
      uniqueFilterData = [{ text: "Yes", value: 1 }, { text: "No", value: 0 }]
    }
    else {
      filterData.map((mainItem) =>
        uniqueFilterData.filter((item) => item[label] === mainItem[label])
          .length > 0
          ? null
          : uniqueFilterData.push(mainItem)
      );

      uniqueFilterData = uniqueFilterData.map((item) => {
        return {
          text: item[label],
          value: item[label],
        };

      });

      uniqueFilterData.sort((a, b) => a.text.localeCompare(b.text))
    }

    return uniqueFilterData;
  };

  handleDropdownChange = (value, row, target, index, optionlabel, isPopup) => {
    let a;

    if (isPopup === false) {
      a = this.state.DestinationBranchTableData;

      if (target === "is_active") {
        a[row.key].is_active = value === 1 ? true : false;
      } else if (target === "branch_name") {
        a[row.key].branch_name = optionlabel;
        a[row.key].branch_id = value;
      } else if (target === "destination_name") {
        a[row.key].destination_name = optionlabel;
        a[row.key].destination_id = value;
      }
      this.setState({ DestinationBranchTableData: a });
    } else {
      a = this.state.newData;

      if (target === "is_active") {
        a.is_active = value === 1 ? true : false;
      } else if (target === "branch_name") {
        a.branch_name = optionlabel;
        a.branch_id = value;
      } else if (target === "destination_name") {
        a.destination_name = optionlabel;
        a.destination_id = value;
      }

      this.setState({ newData: a });
    }
  };

  handleChange = (e, row, index, name, isPopup) => {
    e.target.name = name;

    var alphaNumericRegex = /^[a-zA-Z0-9]*$/;
    var numberRegex = /^[0-9.]+$/;
    let a;

    if (isPopup === false) {
      a = this.state.DestinationBranchTableData;

      if (name === "Crushing_Plan_In_MT") {
        a[row.key].Crushing_Plan_In_MT = e.target.value;
      }

      this.setState({ DestinationBranchTableData: a });
    } else {
      a = this.state.newData;

      if (name === "Crushing_Plan_In_MT") {
        a.loading_charges = e.target.value;
      }

      this.setState({ newData: a });
    }
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  handleEditChange = (row, index) => {
    this.setState({ rowData: row });
    let tempData = this.state.DestinationBranchTableData.map((item) => {

      if (item.key === row.key) {
        return {
          ...item,
          isEdit: true
        }
      } else {
        return item;
      }
    })
    this.setState({ DestinationBranchTableData: tempData });
  };

  handleCancelClick = (rowData, index) => {
    let tempData = this.state.DestinationBranchTableData.map((item) => {
      if (item.key === rowData.key) {
        return this.state.rowData
      } else {
        return item;
      }
    })
    this.setState({ DestinationBranchTableData: tempData });
  };


  render() {
    const userTableProps = {
      pagination: false,
      size: "default",
      bordered: true,
      scroll: { x: 200, y: window.innerHeight - 250 },
    };

    const handleBranchSelect = (value, props) => {
      this.setState(
        { selectedBranchName: props.key, selectedBranchValue: value },
        () => this.getDestinationBranchMapMaster()
      );
    };

    return (
      <div
        className="mandi-master-container"
        style={{
          height: window.innerHeight - 85,
          marginTop: "-45px",
        }}
      >
        <div className="top-container">
          <div className="hub-region-filter">
            <span
              style={{
                fontSize: "15px",
                marginTop: "5px",
                fontWeight: "600",
                marginLeft: "50px",
              }}
            >
              Branch&nbsp;
            </span>{" "}
            <Select
              disabled={!this.state.isAdd}
              showSearch
              optionFilterProp="children"
              className={"select-master-region"}
              mode="single"
              placeholder="Select Branch"
              value={this.state.selectedBranchValue}
              onChange={(value, props) =>
                handleBranchSelect(value, props)
              }
            >
              {this.state.branchList !== null && this.state.branchList.length &&
                this.state.branchList.map((item, idx) => (
                  <Option
                    value={item.branch_id}
                    key={item.branch_id}
                  >
                    {item.branch_name}
                  </Option>
                ))}
            </Select>

          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row-reverse",
              placeItems: "flex-end",
              marginRight: "25px",
              marginBottom: "10px",
            }}
          >
            <Search
              disabled={!this.state.isAdd}
              placeholder="Search"
              allowClear
              onChange={(e) => this.setState({ searchText: e.target.value })}
              style={{ width: "25%" }}
            />
            <Button
              className="add-master-button"
              style={{
                height: "25px",
                width: "70px",
                backgroundColor: "#0b133d",
                color: "white",
              }}
              disabled={!canUserEdit(
                this.props.userData,
                null,
                FeatureCodes.MS_DESTINATION_BRANCH_EDIT
              ) || !this.state.isAdd}
              onClick={() => this.setModalVisibility(true)}
            >
              &#10010; Add
            </Button>
          </div>
        </div>

        <div>
        <div
        style={{
          marginLeft: "25px",
          marginTop: "-8px",
          fontSize: "14px",
          color: "red",
          display: "flex",
          float: "left",
        }}
      > {getUserMSRBACMessage(this.props.messageAccess,FeatureCodes.MS_DESTINATION_BRANCH_EDIT)}
      </div>
        <div
          style={{
            marginRight: "20px",
            marginTop: "-8px",
            fontSize: "15px",
            color: "#0B133D",
            display: "flex",
            float: "right",
          }}
        >Total number of records:&nbsp;
          <b>{this.state.DestinationBranchTableData && this.setTableData().length}</b>
        </div>
        </div>

        <Spin
          spinning={this.state.isSpinnerOnLoad}
          size={"large"}
          tip="Loading..."
          style={{ alignSelf: "center", zIndex: 9999, marginTop: "20px" }}
        >
          <Modal
            className="add-master-modal"
            title={"Add Annual Factory Consumption"}
            onCancel={this.handleCancel}
            footer={false}
            visible={this.state.isModalVisible}
            destroyOnClose={true}
          >
            <div className="master-form-container">
              <Form
                layout="horizontal"
                //   onValuesChange={onFormLayoutChange}
                size={"small"}
              // onSubmit={submitTableRowData(this.state.newData)}
              >
                <div className="form-top-container1">
                  <div
                    className="checkbox-group1"
                    style={{
                      display: "inline-block",
                      width: "60%",
                      marginRight: 40,
                    }}
                  >
                    <Form.Item label="Factory">
                      <Select
                        showSearch
                        optionFilterProp="children"
                        className="remarks-select"
                        name="destination_name"
                        defaultValue={this.state.newData.destination_name}
                        value={this.state.newData.destination_name}
                        onSelect={(value, option) =>
                          this.handleDropdownChange(
                            value,
                            this.state.newData,
                            "destination_name",
                            -0,
                            option.props.children,
                            true
                          )
                        }
                      >
                        {this.state.factoryList &&
                          this.state.factoryList.length &&
                          this.state.factoryList.map((hubItem, idx) => (
                            <Option
                              key={hubItem.factory_id}
                              value={hubItem.factory_id}
                            >
                              {hubItem.factory_name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </div>

                  <div
                    className="checkbox-group1"
                    style={{
                      display: "inline-block",
                      width: "60%",
                      marginRight: 40,
                    }}
                  >
                    <Form.Item label="Branch">
                      <Select
                        showSearch
                        optionFilterProp="children"
                        className="remarks-select"
                        name="branch_name"
                        defaultValue={this.state.newData.branch_name}
                        value={this.state.newData.branch_name}
                        onSelect={(value, option) =>
                          this.handleDropdownChange(
                            value,
                            this.state.newData,
                            "branch_name",
                            -0,
                            option.props.children,
                            true
                          )
                        }
                      >
                        {this.state.branchList &&
                          this.state.branchList.length &&
                          this.state.branchList.map((hubItem, idx) => (
                            <Option
                              key={hubItem.branch_id}
                              value={hubItem.branch_id}
                            >
                              {hubItem.branch_name}
                            </Option>
                          ))}
                      </Select>

                    </Form.Item>
                  </div>


                  <div
                    className="form-top-container"
                    style={{
                      display: "inline-block",
                      width: "33%",
                      marginRight: 7,
                    }}
                  >
                    <Form.Item label="Active">
                      <Radio.Group
                        name="is_active"
                        value={this.state.newData.is_active ? 1 : 0}
                        onChange={(e, option) =>
                          this.handleDropdownChange(
                            e.target.value,
                            this.state.newData,
                            "is_active",
                            -0,
                            "",
                            true
                          )
                        }
                      >
                        <Radio value={1}>Yes</Radio>
                        <Radio value={0}>No</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                </div>

                <div className="form-bottom-container" style={{ marginTop: "10px" }}>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={this.state.isSubmitted}
                      onClick={() =>
                        this.submitTableRowData(this.state.newData, 0, true)
                      }
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </Modal>


          <div className="table-container1" style={{ maxHeight: "450px", width: "99%", margin: "6px 0px" }}>
            <Table
              {...userTableProps}
              columns={[

                {
                  title: "Factory",
                  dataIndex: "destination_name",
                  key: "destination_name",
                  width: 125,
                  filters: this.createFilters("destination_name"),
                  onFilter: (value, record) =>
                    record.destination_name.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.destination_name.localeCompare(b.destination_name),
                  render: (record, rowRecord) => {
                    return (
                      <>
                        <div
                          style={{ marginLeft: "-15px", marginRight: "-10px" }}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            disabled={true}//{!rowRecord.isEdit}
                            className="remarks-select"
                            name="destination_name"
                            defaultValue={rowRecord.destination_name}
                            value={rowRecord.destination_name}
                            onSelect={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "destination_name",
                                -0,
                                option.props.children,
                                false
                              )
                            }
                          >
                            {this.state.factoryList &&
                              this.state.factoryList.length &&
                              this.state.factoryList.map((hubItem, idx) => (
                                <Option
                                  key={hubItem.factory_id}
                                  value={hubItem.factory_id}
                                >
                                  {hubItem.factory_name}
                                </Option>
                              ))}
                          </Select>
                        </div>
                      </>
                    );
                  },
                },
                {
                  title: "Branch",
                  dataIndex: "branch_name",
                  key: "branch_name",
                  width: 125,
                  filters: this.createFilters("branch_name"),
                  onFilter: (value, record) =>
                    record.branch_name.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.branch_name.localeCompare(b.branch_name),
                  render: (record, rowRecord) => {
                    return (
                      <>
                        <div
                          style={{ marginLeft: "-15px", marginRight: "-10px" }}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            disabled={true}//{!rowRecord.isEdit}
                            className="remarks-select"
                            name="branch_name"
                            defaultValue={rowRecord.branch_name}
                            value={rowRecord.branch_name}
                            onSelect={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "branch_name",
                                -0,
                                option.props.children,
                                false
                              )
                            }
                          >
                            {this.state.branchList &&
                              this.state.branchList.length &&
                              this.state.branchList.map((hubItem, idx) => (
                                <Option
                                  key={hubItem.branch_id}
                                  value={hubItem.branch_id}
                                >
                                  {hubItem.branch_name}
                                </Option>
                              ))}
                          </Select>
                        </div>
                      </>
                    );
                  },
                },

                {
                  key: "is_active",
                  title: "Active",
                  dataIndex: "is_active",
                  width: 100,
                  filters: this.createFilters("is_active"),
                  // filters: [{ text: "Yes", value: 1 }, { text: "No", value: 0 }],
                  onFilter: (value, record) =>
                    record.is_active === Boolean(value),
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div
                          style={{ marginLeft: "-15px", marginRight: "-10px" }}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            disabled={!rowRecord.isEdit}
                            defaultValue={
                              rowRecord.is_active === true ? "Yes" : "No"
                            }
                            value={rowRecord.is_active === true ? "Yes" : "No"}
                            onChange={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "is_active",
                                index,
                                option.props.children,
                                false
                              )
                            }
                            style={{ width: "70%" }}
                          >
                            <Option key={1} value={1}>
                              {"Yes"}
                            </Option>
                            <Option key={0} value={0}>
                              {"No"}
                            </Option>
                          </Select>
                        </div>
                      </>
                    );
                  },
                },
                {
                  title: "Action",
                  dataIndex: "Submit",
                  key: "Submit",
                  width: 80,
                  visibility: false,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ display: "inline-flex", width: "100%" }}>
                          <Button
                          className={!canUserEdit(
                            this.props.userData,
                            null,
                            FeatureCodes.MS_DESTINATION_BRANCH_EDIT
                          ) ? "disabledBtnClass" : "btnClass"}
                          disabled={!canUserEdit(
                            this.props.userData,
                            null,
                            FeatureCodes.MS_DESTINATION_BRANCH_EDIT
                          )}
                            type="primary"
                            size={"small"}
                            style={{
                              marginLeft: 5,
                              alignSelf: "center",
                              padding: "0px 0px 2px",
                              height: 31,
                              backgroundColor: "#20346a",
                              color: "white",
                              borderRadius: 15,
                            }}
                            block
                            htmlType="submit"
                            onClick={() =>
                              rowRecord.isEdit
                                ? this.submitTableRowData(rowRecord, index, false)
                                : this.handleEditChange(rowRecord, index)
                            }
                          >
                            {" "}
                            {rowRecord.isEdit ? "Submit" : "Edit"}{" "}
                          </Button>


                          <Button
                            className={!rowRecord.isEdit ? "disabledBtnClass" : "btnClass"}
                            type="primary"
                            size={"small"}
                            style={{
                              marginLeft: 5,
                              alignSelf: "center",
                              color: "white",
                              borderRadius: 15,
                              padding: "0px 0px 2px",
                              height: 31,
                              backgroundColor: "#20346a",
                            }}
                            block
                            htmlType="submit"
                            disabled={!rowRecord.isEdit}
                            onClick={() => this.handleCancelClick(rowRecord, index)
                            }
                          >
                            Cancel
                          </Button>
                        </div>
                      </>
                    );
                  },
                },
              ]}
              dataSource={this.setTableData()}
            />
          </div>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
  messageAccess: selectMSMessageRBAC,
});
export default connect(mapStateToProps)(DestinationBranchMapMaster);
//export default DestinationBranchMapMaster;
