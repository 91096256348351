import { ArrivalActionTypes } from "./arrival-entry.types";

export const setSummaryVisibility = (isEnabled) => {
  return {
    type: ArrivalActionTypes.SET_SUMMARY_VISIBILITY,
    payload: isEnabled,
  };
};

export const setArrivalData = (data) => {
  return {
    type: ArrivalActionTypes.SET_ARRIVAL_DATA,
    payload: data,
  };
};

export const setAllMandis = (data) => {
  return {
    type: ArrivalActionTypes.SET_ALL_MANDIS,
    payload: data,
  };
};

export const setBranches = (branches) => {
  return {
    type: ArrivalActionTypes.SET_BRANCHES,
    payload: branches,
  };
};

export const setRegions = (regions) => {
  return {
    type: ArrivalActionTypes.SET_REGIONS,
    payload: regions,
  };
};

export const setHubs = (hubs) => {
  return {
    type: ArrivalActionTypes.SET_HUBS,
    payload: hubs,
  };
};

export const setMandis = (mandis) => {
  return {
    type: ArrivalActionTypes.SET_MANDIS,
    payload: mandis,
  };
};

export const saveAllVarietyInfo = (allVarietyInfo) => {
  return {
    type: ArrivalActionTypes.SAVE_ALL_VARIETY_INFO,
    payload: allVarietyInfo,
  };
};

//#region variety handling for mandis

export const setVarieties = (varieties) => {
  return {
    type: ArrivalActionTypes.SET_VARIETIES,
    payload: varieties,
  };
};

export const addVariety = (variety) => {
  return {
    type: ArrivalActionTypes.ADD_VARIETIES,
    payload: variety,
  };
};

export const setRemainingVarietyList = (data) => {
  return {
    type: ArrivalActionTypes.SET_REMAINING_VARIETY_LIST,
    payload: data,
  };
};

//#endregion

//#region variety handling for FOR/Farmer at hub level

export const setHubVarieties = (varieties) => {
  return {
    type: ArrivalActionTypes.SET_HUB_VARIETIES,
    payload: varieties,
  };
};

export const addHubVariety = (variety) => {
  return {
    type: ArrivalActionTypes.ADD_HUB_VARIETIES,
    payload: variety,
  };
};

export const setRemainingHubVarietyList = (data) => {
  return {
    type: ArrivalActionTypes.SET_REMAINING_HUB_VARIETY_LIST,
    payload: data,
  };
};

//#endregion

export const setBranchID = (id) => {
  return {
    type: ArrivalActionTypes.SET_BRANCH_ID,
    payload: id,
  };
};

export const setRegionID = (id) => {
  return {
    type: ArrivalActionTypes.SET_REGION_ID,
    payload: id,
  };
};

export const setHubID = (id) => {
  return {
    type: ArrivalActionTypes.SET_HUB_ID,
    payload: id,
  };
};

export const setMandiID = (id) => {
  return {
    type: ArrivalActionTypes.SET_MANDI_ID,
    payload: id,
  };
};
