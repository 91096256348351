import { BuyingRecommendationActionTypes } from "./buying-recommendation.types";

export const setSuggestedData = (data) => {
  return {
    type: BuyingRecommendationActionTypes.SET_SUGGESTED_DATA,
    payload: data,
  };
};
export const setSuggestedHubData = (data) => {
  return {
    type: BuyingRecommendationActionTypes.SET_SUGGESTED_HUB_DATA,
    payload: data,
  };
};

export const setSuggestedDirectFactData = (data) => {
  return {
    type: BuyingRecommendationActionTypes.SET_DIRECT_FACTORY_SUGGESTED_DATA,
    payload: data,
  };
};
export const setSuggestedABDData = (data) => {
  return {
    type: BuyingRecommendationActionTypes.SET_ABD_SUGGESTED_DATA,
    payload: data,
  };
};

export const setSuggestedRakeData = (data) => {
  return {
    type: BuyingRecommendationActionTypes.SET_RAKE_SUGGESTED_DATA,
    payload: data,
  };
};

export const addSuggestedData = (data) => {
  return {
    type: BuyingRecommendationActionTypes.ADD_SUGGESTED_DATA,
    payload: data,
  };
};
export const setAbdSohData = (data) => {
  return {
    type: BuyingRecommendationActionTypes.SET_ABD_SOH_DATA,
    payload: data,
  };
};
