import React  from "react";
import "./warehouse-cost.page";
import API from "../../api";
import { getStatesAPI } from "../../services/generic-apis";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUserData,selectMSMessageRBAC } from "../../redux/user/user.selectors";
import { canUserEdit,getUserMSRBACMessage } from "../../auth/utils";
import { FeatureCodes } from "../../auth/data";


import { Component } from "react";
import {
  Table,
  Spin,
  Input,
  Select,
  Button,
  message,
  Form,
  
  Modal,
  
} from "antd";
const { Search } = Input;
const { Option } = Select;



class WarehouseMaster extends Component{
    state={ isModalVisible: false,
      isAdd: false,
      
      stateList: [],
      monthList:[],
      whmList:[],
      wcpmList:[],
      warehousingCostList:[],
      amList:[],
      typeList:[],
  
  
      
      warehouseTableData:[],
        currentRecord: null,
  userBranches: null,
  searchText: null,
  searchUserType: 0,
      
      isActive:false,
      isSpinnerOnLoad: false,
      isSubmitted: false,
  
    
    newData: {
    additional_moistured:null, 
      is_active: false,
      month: "",
      state_id:undefined,
      state_name: "",
      type: "",
      warehouse_handling_months: null,
      warehousing_cost: null,
      warehousing_cost_per_month: null,
      wc_pkid: undefined,

      isEdit:false,
     
      
    },
    rowData: undefined,
    selectedStateValue: undefined,
    selectedStateName: "",
    selectedStateList: [],
   
    };
  
  componentDidMount() {
    this.setState({ isSpinnerOnLoad: true });
     this.getStateAPI();
   //  this.getwarehousecost();
  }
  
  getMonths = async () => {
    this.setState({ isSpinnerOnLoad: true });

    await API.get("/list_month")
      .then((monthdata) => {
        this.setState(
          {
            monthList: monthdata.data.data,
          },
          () => this.getType()
        );
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };
  getType = async () => {
    this.setState({ isSpinnerOnLoad: true });

    await API.get("/wcost_type")
      .then((typedata) => {
        let modifiedData = typedata.data.data.map((item, index) => {
          return {
            ...item,
            key: index,
          };
        });
       
        this.setState(
          {
            typeList: modifiedData,
          },
          () => this.getwarehousecost()
        );
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };


    getStateAPI = async () => {
    this.setState({ isSpinnerOnLoad: true });
    await API.get("/get_states")
  .then((state) => {
  
    let modifiedData = [...state.data.data];
    modifiedData.push({ state_id: null, state_name: "All" });
    this.setState(
      {
        selectedStateList: modifiedData,
        stateList: state.data.data,
         selectedStateValue: state.data.data[0].state_id,
        selectedStateName: state.data.data[0].state_name,
      },
  
      () => this.getMonths()
    );
  })
  .catch((err) => {
    this.setState({ isSpinnerOnLoad: false });
    this.getStateAPI();
    if (err) {
      if (err.response && err.response.status === 400)
        message.error("Something went wrong. Please try again later.", 5);
      else {
        message.error("Something went wrong.. Please try again later.", 5);
      }
    } else {
      message.error("Something went wrong... Please try again later.", 5);
    }
  });
  };
  getwarehousecost= async () => {
    this.setState({ isSpinnerOnLoad: true });
    // const headers = {
    //   "Content-Type": "application/json",
    //   Authorization: "Bearer " + localStorage.getItem("accessToken"),
    // };

    const params = {
      state_id:
        this.state.selectedStateValue === null ||
        this.state.selectedStateValue === undefined
          ? null
          : this.state.selectedStateValue,
    };

    // this.setState({ isSpinnerOnLoad: true });
    await API.get("/warehouse_cost", { params: params })
      .then((resp) => {
        console.log("check response ", resp);
        let modifiedData = resp.data.data.map((item, index) => {
          return {
            ...item,
            isEdit: false,
            key: index,
          };
        });
        this.setState(
          {
            warehouseTableData: modifiedData,
          },
          () => this.setState({ isSpinnerOnLoad: false, isAdd: true })
        );
        //console.log("mandi table data ", this.state.mandiHubDistrictTableData);
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };
  setTableData = () => {
    if (
      this.state.warehouseTableData !== undefined ||
      this.state.warehouseTableData.length >= 0
    ) {
      const userTableData = this.state.warehouseTableData.map(
        (user, index) => {
          return {
            key: user.key,
            
            
            is_active: user.is_active,
            additional_moistured:user.additional_moistured, 
            
            month: user.month,
            state_id:user.state_id,
            state_name: user.state_name,
            type: user.type,
            warehouse_handling_months: user.warehouse_handling_months,
            warehousing_cost: user.warehousing_cost,
            warehousing_cost_per_month: user.warehousing_cost_per_month,
            wc_pkid: user.wc_pkid,
            isEdit: user.isEdit,
          };
        }
      );
      

      let dataAfterSearch = userTableData;

      if (this.state.searchText) {
        dataAfterSearch = dataAfterSearch.filter(
          (item) =>
            (item.state_name !== null &&
              item.state_name
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase())) ||
            (item.month !== null &&
              item.month
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase())) ||
            (item.type !== null &&
              item.type
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase()))

        );
      }
      return dataAfterSearch;
    } else {
      return this.state.warehouseTableData;
    }
  };


  submitTableRowData = (row, index, isPopup) => {
    this.setState({ isSpinnerOnLoad: true, isSubmitted: true });
    let warningmessage = "";
    if (
      row.month === null ||
      row.month === undefined ||
      row.month === ""
    ) {
      warningmessage = "Please select Month";
    }  else if (
      row.state_name === null ||
      row.state_name === undefined ||
      row.state_name === ""
    ) {
      warningmessage = "Please select state";
    } else if (
      row.additional_moistured === null ||
      row.additional_moistured=== undefined ||
      row.additional_moistured === ""
    ) {
      warningmessage = "Please select additional moisturised";
    } else if (
      row.warehouse_handling_months === null ||
      row.warehouse_handling_months === undefined ||
      row.warehouse_handling_months === ""
    ) {
      warningmessage = "Please select the warehouse handling months";
    }
    else if (
      row.warehousing_cost === null ||
      row.warehousing_cost === undefined ||
      row.warehousing_cost === ""
    ) {
      warningmessage = "Please select the warehousing cost";
    }
    else if (
      row.warehousing_cost_per_month === null ||
      row.warehousing_cost_per_month === undefined ||
      row.warehousing_cost_per_month === ""
    ) {
      warningmessage = "Please select the warehousing cost per month";
    }


    if (
      warningmessage === "" ||
      warningmessage === undefined ||
      warningmessage === null
    ) {
      this.setState({ isSpinnerOnLoad: true });
      this.setState({ isSubmitted: true });

      let data = {
        
       wc_pkid:row.wc_pkid,
       month: row.month,
       state_id:row.state_id,
       type: row.type,
       additional_moistured:row.additional_moistured,
       warehouse_handling_months: row.warehouse_handling_months,
       warehousing_cost: row.warehousing_cost,
       warehousing_cost_per_month: row.warehousing_cost_per_month,

        
          is_active: row.is_active,
        
        
      };
      // const headers = {
      //   "Content-Type": "application/json",
      //   Authorization: "Bearer " + localStorage.getItem("accessToken"),
      // };

      API.put("/warehouse_cost", data,{} )

        .then((response) => {
          if (response.status === 200 && response.data.status) {
            message.info(response.data.message, 5);
            this.setModalVisibility(false);
            this.getwarehousecost();
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
          } else if (!response.data.status) {
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
            message.error(response.data.message, 5);
          } else {
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
            message.error("Please try again", 5);
          }
        })
        .catch((err) => {
          if (err) {
            if (err.response && err.response.status === 400)
              message.error("Something went wrong. Please try again later.", 5);
            else {
              message.error(
                "Something went wrong.. Please try again later.",
                5
              );
            }
          } else {
            message.error("Something went wrong... Please try again later.", 5);
          }
          this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
          // this.setModalVisibility(false);
        });
    } else {
      this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
      message.error(warningmessage, 5);
    }
  };

  setModalVisibility = (status) => {
    if (status) {
      const nData = {
        additional_moistured:null, 
      is_active: true,
      month: "",
      state_id:undefined,
      state_name: "",
      type: "",
      warehouse_handling_months: null,
      warehousing_cost: null,
      warehousing_cost_per_month: null,
      wc_pkid: undefined,

   
      
        isEdit:false,
       
        
      };
  
      this.setState({ newData: nData });
    }
  
    this.setState({ isModalVisible: status });
  };
  TableColumnHeader = (props) => {
    const { title, subtitle } = props;
    return (
      <div className="column-header-container">
        <div
          style={{
            color: "white",
            fontSize: "12px",
            whiteSpace: "nowrap",
            fontWeight: "normal",
            textAlign: "left",
          }}
        >
          {title}
        </div>
        <div
          style={{
            color: "#cbdfff",
            fontSize: "11px",
            fontWeight: "normal",
            textAlign: "left",
            
          }}
        >
          {subtitle}
        </div>
      </div>
    );
  };
  
  createFilters = (label) => {
    let filterData = this.state.warehouseTableData;

    //#region remove duplicate objects

    let uniqueFilterData = [];

    if (
      label === "is_active" &&
      this.state.warehouseTableData &&
      this.state.warehouseTableData.length > 0
    ) {
      uniqueFilterData = [
        { text: "Yes", value: 1 },
        { text: "No", value: 0 },
      ];
    } else {
      filterData.map((mainItem) =>
        uniqueFilterData.filter((item) => item[label] === mainItem[label])
          .length > 0
          ? null
          : uniqueFilterData.push(mainItem)
      );

      uniqueFilterData = uniqueFilterData.map((item) => {
        return {
          text: item[label],

          value: item[label],
        };
      });

      uniqueFilterData.sort((a, b) => a.text.localeCompare(b.text));
    }

    return uniqueFilterData;
  };

  
  
 
  
handleDropdownChange = (value, row, target, index, optionlabel, isPopup) => {
    let a;
  
    if (isPopup === false) {
      a = this.state.warehouseTableData;
      if (target === "state_name") {
        a[row.key].state_name = optionlabel;
        a[row.key].state_id = value;
      }
      else if (target === "month") {
        a[row.key].month= value;
        a[row.key].month = optionlabel;
      }
      else if (target === "type") {
        a[row.key].type= value;
        a[row.key].type = optionlabel;
      }
      this.setState({ warehouseTableData: a });
    } else {
      a = this.state.newData;
  
      if (target === "state_name") {
        a.state_name = optionlabel;
        a.state_id = value;
      }
      else if (target === "month") {
        a.month= value;
        a.month = optionlabel;
      }
      else if (target === "type") {
        a.type= value;
        a.type = optionlabel;
      }
      this.setState({ newData: a });
    }
  };
  
  handleChange = (e, row, index, name, isPopup) => {
    let a;
    var alphaNumericRegex = /^[a-zA-Z0-9]*$/;
    var numberRegex = /^[0-9.]+$/;
    if (isPopup === false) {
      a = this.state.warehouseTableData;

      switch (name) {
        case "warehousing_cost_per_month":
          if (e.target.value === "") {
            a[row.key].warehousing_cost_per_month = "";
          } else if (e.target.value.match(numberRegex)) {
            a[row.key].warehousing_cost_per_month = e.target.value;
          }

          this.setState({ warehouseTableData: a });
          return;

          case "warehouse_handling_months":
            if (e.target.value === "") {
              a[row.key].warehouse_handling_months = "";
            } else if (e.target.value.match(numberRegex)) {
              a[row.key].warehouse_handling_months = e.target.value;
            }
  
            this.setState({ warehouseTableData: a });
            return;

            case "warehousing_cost":
              if (e.target.value === "") {
                a[row.key].warehousing_cost = "";
              } else if (e.target.value.match(numberRegex)) {
                a[row.key].warehousing_cost = e.target.value;
              }
    
              this.setState({ warehouseTableData: a });
              return;
              case "additional_moistured":
                if (e.target.value === "") {
                  a[row.key].additional_moistured = "";
                } else if (e.target.value.match(numberRegex)) {
                  a[row.key].additional_moistured = e.target.value;
                }
      
                this.setState({ warehouseTableData: a });
                return;

            
          
        default:
          return "";
      }
    } else {
      a = this.state.newData;

      switch (name) {
        case "warehousing_cost":
          if (e.target.value === "") {
            a.warehousing_cost = "";
          } else if (e.target.value.match(numberRegex)) {
            a.warehousing_cost = e.target.value;
          }

          this.setState({ newData: a });
          return;
          case "warehousing_cost_per_month":
            if (e.target.value === "") {
              a.warehousing_cost_per_month = "";
            } else if (e.target.value.match(numberRegex)) {
              a.warehousing_cost_per_month = e.target.value;
            }
  
            this.setState({ newData: a });
            return;
            case "warehouse_handling_months":
              if (e.target.value === "") {
                a.warehouse_handling_months = "";
              } else if (e.target.value.match(numberRegex)) {
                a.warehouse_handling_months = e.target.value;
              }
    
              this.setState({ newData: a });
              return;
              case "additional_moistured":
                if (e.target.value === "") {
                  a.additional_moistured = "";
                } else if (e.target.value.match(numberRegex)) {
                  a.additional_moistured = e.target.value;
                }
      
                this.setState({ newData: a });
                return;
        default:
          return "";
      }
      // this.setState({ newData: a });
    }
  };
  
  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };
  
  handleEditChange = (row, index) => {
 //   console.log("user hub id is ", this.props.userData.state_id );//check this afterwards
    
    this.setState({ rowData: row });
    let tempData = this.state.warehouseTableData.map((item) => {
  
      if (item.wc_pkid === row.wc_pkid) {
        return {
          ...item,
          isEdit: true
        }
      } else {
        return item;
      }
    })
    this.setState({ warehouseTableData: tempData });
  
  };
  
  handleCancelClick = (rowData, index) => {
    let tempData = this.state.warehouseTableData.map((item) => {
      if (item.wc_pkid=== rowData.wc_pkid) {
        return this.state.rowData
      } else {
        return item;
      }
    })
    this.setState({ warehouseTableData: tempData });
  };

  render(){
    const userTableProps = {
      pagination: false,
      size: "default",
      bordered: true,
      scroll: { x:200, y: window.innerHeight - 250 },
    };
    const handleStateSelect = (value, props) => {
      this.setState(
        { selectedStateName: props.key, selectedStateValue: value },
        () => this.getwarehousecost()
      );
    };
  
  
  
    /// returning to output,just a dummy data as of now
  
  
  
  return(
    <div // close left
    className="mandi-master-container"
    style={{
      height: window.innerHeight - 85,
      marginTop: "-45px",
    }}>
       <div className="top-container">
       <div className="hub-region-filter">
       <span
                style={{
                  fontSize: "15px",
                  marginTop: "5px",
                  marginLeft: "68px",
                  fontWeight: "600",
                }}
              >
                State&nbsp;
              </span>{" "}
              <Select
                disabled={!this.state.isAdd}
                showSearch
                optionFilterProp="children"
                className={"select-master-region"}
                mode="single"
                placeholder="Select State"
                value={this.state.selectedStateValue}
                onChange={(value, props) =>
                  handleStateSelect(value, props)
                }
              >
                {this.state.selectedStateList !== null && this.state.selectedStateList.length &&
                  this.state.selectedStateList.map((item, idx) => (
          
                      <Option
                      value={item.state_id}
                      key={item.state_id}
                    >
                      {item.state_name}
                    </Option>
                  ))}
              </Select>
  
            </div>
  
  
  <div
    style={{
      width: "100%",
      display: "flex",
      flexDirection: "row-reverse",
      placeItems: "flex-end",
      marginRight: "25px",
      marginBottom: "10px",
    }}
  >
    <Search
      disabled={!this.state.isAdd}
      placeholder="Search"
      allowClear
      onChange={(e) => this.setState({ searchText: e.target.value })}
      style={{ width: "25%" }}
    />
     <Button
      className="add-master-button"
      style={{
        height: "25px",
        width: "70px",
        backgroundColor: "#0b133d",
        color: "white",
      }}
      disabled={!this.state.isAdd || !canUserEdit(
        this.props.userData,
        null,
        FeatureCodes.MS_WAREHOUSE_COST_EDIT
      )}
      onClick={() => this.setModalVisibility(true)}
    >
      &#10010; Add
    </Button> 
  </div>
  </div>
  
  <div>
  <div
  style={{
    marginLeft: "25px",
    marginTop: "-8px",
    fontSize: "14px",
    color: "red",
    display: "flex",
    float: "left",
  }}
  > {getUserMSRBACMessage(this.props.messageAccess,FeatureCodes.MS_WAREHOUSE_COST_EDIT)}
  </div>
  <div
    style={{
      marginRight: "20px",
      marginTop: "-8px",
      fontSize: "15px",
      color: "#0B133D",
      display: "flex",
      float: "right",
    }}
  >Total number of records:&nbsp;
    <b>{this.state.warehouseTableData && this.setTableData().length}</b>
  </div>
  </div>
  
  <Spin
    spinning={this.state.isSpinnerOnLoad}
    size={"large"}
    tip="Loading..."
    style={{ alignSelf: "center", zIndex: 9999, marginTop: "20px" }}
  >
     <Modal
      className="add-master-modal"
      title={"Add warehouse details"}
      onCancel={this.handleCancel}
      footer={false}
      visible={this.state.isModalVisible}
      destroyOnClose={true}
    >
      <div className="master-form-container">
        <Form
          layout="horizontal"
          //   onValuesChange={onFormLayoutChange}
          size={"small"}
        // onSubmit={submitTableRowData(this.state.newData)}
        >
          <div className="form-top-container1">
            <div
              className="checkbox-group1"
              style={{
                display: "inline-block",
                width: "41%",//60
                marginRight: 40,
              }}
            >
               <Form.Item label="State">
                        <Select
                          showSearch
                          optionFilterProp="children"
                          className="remarks-select"
                          name="state_name"
                          defaultValue={this.state.newData.state_name}
                          value={this.state.newData.state_name}
                          onSelect={(value, option) =>
                            this.handleDropdownChange(
                              value,
                              this.state.newData,
                              "state_name",
                              -0,
                              option.props.children,
                              true
                            )
                          }
                        >
                          {this.state.stateList &&
                            this.state.stateList.length &&
                            this.state.stateList.map((hubItem, idx) => (
                              <Option
                                key={hubItem.state_id}
                                value={hubItem.state_id}
                              >
                                {hubItem.state_name}
                              </Option>
                            ))}
                        </Select>
  
                      </Form.Item>
                    </div>
  
  
                    <div
                      className="checkbox-group1"
                      style={{
                        height: "45px",
                        display: "inline-block",
                        width: "41%",
                        marginRight: 40,
                      }}
                    >
                      <Form.Item label="Month">
                      <Select
                          showSearch
                          optionFilterProp="children"
                          className="remarks-select"
                          name="month"
                          defaultValue={this.state.newData.month}
                          value={this.state.newData.month}
                          onSelect={(value, option) =>
                            this.handleDropdownChange(
                              value,
                              this.state.newData,
                              "month",
                              -0,
                              option.props.children,
                              true
                            )
                          }
                        >
                          {this.state.monthList &&
                            this.state.monthList.length &&
                            this.state.monthList.map((hubItem, idx) => (
                              <Option
                                key={hubItem.month_id}
                                value={hubItem.month_id}
                              >
                                {hubItem.month_name}
                              </Option>
                            ))}
                        </Select>
  
                       
                      </Form.Item>
                    </div>
  
                   {/*  // <div
                    //   className="checkbox-group1"
                    //   style={{
                    //     height: "45px",
                    //     display: "inline-block",
                    //     width: "41%",
                    //     marginRight: 40,
                    //   }}
                    // >
                    //   <Form.Item label="Type">
                    //   <Select
                    //       showSearch
                    //       optionFilterProp="children"
                    //       className="remarks-select"
                    //       name="type"
                    //       defaultValue={this.state.newData.type}
                    //       value={this.state.newData.type}
                    //       onSelect={(value, option) =>
                    //         this.handleDropdownChange(
                    //           value,
                    //           this.state.newData,
                    //           "type",
                    //           -0,
                    //           option.props.children,
                    //           true
                    //         )
                    //       }
                    //     >
                    //        {console.log("type list ", this.state.typeList)}
                    //       {this.state.typeList &&
                    //         this.state.typeList.length &&
                    //         this.state.typeList.map((hubItem, idx) => (
                    //           <Option
                    //             key={hubItem.key}
                    //             value={hubItem.type}
                    //           >
                    //             {hubItem.type}
                    //           </Option>
                    //         ))}
                    //     </Select>
  
                    //   </Form.Item>
                    // </div>
                    */}
  
                    <div
                      className="checkbox-group1"
                      style={{
                        height: "45px",
                        display: "inline-block",
                        width: "41%",
                        marginRight: 40,
                      }}
                    >
                      <Form.Item label="Warehousing Cost">
                        <Input
                          type="number"
                          style={{ marginRight: "2px" }}
                          maxLength={100}
                          min="0"
                          value={this.state.newData.warehousing_cost}
                          name="warehousing_cost"
                          onChange={(e) =>
                            this.handleChange(
                              e,
                              this.state.newData,
                              0,
                              "warehousing_cost",
                              true
                            )
                          }
                        />
                      </Form.Item>
                    </div>
  
                    <div
                      className="checkbox-group1"
                      style={{
                        height: "45px",
                        display: "inline-block",
                        width: "41%",
                        marginRight: 40,
                      }}
                    >
                      <Form.Item label="Warehouse handling months">
                        <Input
                          type="number"
                          style={{ marginRight: "2px" }}
                          maxLength={100}
                          min="0"
                          value={this.state.newData.warehouse_handling_months}
                          name="warehouse_handling_months"
                          onChange={(e) =>
                            this.handleChange(
                              e,
                              this.state.newData,
                              0,
                              "warehouse_handling_months",
                              true
                            )
                          }
                        />
                      </Form.Item>
                    </div>
  
                  
                    <div
              className="checkbox-group1"
              style={{
                display: "inline-block",
                width: "41%",
                marginRight: 40,
              }}
            >
                    <Form.Item label="Additional Moistured(%)">
               <Input
                          type="number"
                          style={{ marginRight: "2px" }}
                          maxLength={100}
                          min="0"
                          value={this.state.newData.additional_moistured}
                          name="additional_moistured"
                          onChange={(e) =>
                            this.handleChange(
                              e,
                              this.state.newData,
                              0,
                              "additional_moistured",
                              true
                            )
                          }
                        />
                       
  
                      </Form.Item>
                    </div>

                    <div
              className="checkbox-group1"
              style={{
                display: "inline-block",
                width: "41%",
                marginRight: 40,
              }}
            >
               <Form.Item label="Warehousing Cost per month(Rs/ton)">
               <Input
                          type="number"
                          style={{ marginRight: "2px" }}
                          maxLength={100}
                          min="0"
                          value={this.state.newData.warehousing_cost_per_month}
                          name="warehousing_cost_per_month"
                          onChange={(e) =>
                            this.handleChange(
                              e,
                              this.state.newData,
                              0,
                              "warehousing_cost_per_month",
                              true
                            )
                          }
                        />
                       
  
                      </Form.Item>
                    </div>
                    
                    </div>
                    <div className="form-bottom-container" style={{ marginTop: "10px" }}>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        disabled={this.state.isSubmitted}
                        onClick={() =>
                          this.submitTableRowData(this.state.newData, 0, true)
                        }
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </div>
            </Modal> 
  
  
   <div className="table-container1" style={{ maxHeight: "400px",  margin: "6px 10px" }}>
    <Table
     {...userTableProps}
     columns ={ [
    {
      title : 'State',
      dataIndex: 'state_name',
      key: 'state_id',
      width: 150,
      filters: this.createFilters("state_name"),
      onFilter: (value, record) =>
        record.state_name.includes(value),
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.state_name.localeCompare(b.state_name),
      render: (record, rowRecord) => {
        return (
          <>
            <div
              style={{ marginLeft: "-15px", marginRight: "-10px" }}
            >
              <Select
                showSearch
                optionFilterProp="children"
                disabled = {true}//{!rowRecord.isEdit}
                className="remarks-select"
                name="state_name"
                defaultValue={rowRecord.state_name}
                value={rowRecord.state_name}
                onSelect={(value, option) =>
                  this.handleDropdownChange(
                    value,
                    rowRecord,
                    "state_name",
                    -0,
                    option.props.children,
                    false
                  )
                }
              >
                {this.state.stateList &&
                  this.state.stateList.length &&
                  this.state.stateList.map((hubItem, idx) => (
                    <Option
                      key={hubItem.state_id}
                      value={hubItem.state_id}
                    >
                      {hubItem.state_name}
                    </Option>
                  ))}
              </Select>
            </div>
          </>
        );
      },
    },
    {
      title: 'Month',
      dataIndex: 'month',
      key: 'month',
      width: 150,
      filters: this.createFilters("month"),
      onFilter: (value, record) =>
        record.month.includes(value),
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.month- b.month,
      render: (record, rowRecord, index) => {
        return (
          <>
            <div style={{ marginLeft: "-20px" }}>
            <Select
                showSearch
                optionFilterProp="children"
                disabled={true}//{!rowRecord.isEdit}
                className="remarks-select"
                name="month"
                defaultValue={rowRecord.month}
                value={rowRecord.month}
                onSelect={(value, option) =>
                  this.handleDropdownChange(
                    value,
                    rowRecord,
                    "month",
                    -0,
                    option.props.children,
                    false
                  )
                }
              >
                {this.state.monthList &&
                  this.state.monthList.length &&
                  this.state.monthList.map((hubItem, idx) => (
                    <Option
                      key={hubItem.month_id}
                      value={hubItem.month_id}
                    >
                      {hubItem.month_name}
                    </Option>
                  ))}
              </Select>
            
            </div>
          </>
        );
      },
    },
    // {
    //   title: 'Type',
    //   dataIndex: 'type',
    //   key: 'type',
    //   width: 120,
    //   filters: this.createFilters('type'),
    //   onFilter: (value, record) =>
    //     record.type.includes(value),
    //   sortDirections: ["descend", "ascend"],
    //   sorter: (a, b) => a.type.localeCompare(b.type),
    //   render: (record, rowRecord) => {
    //     return (
    //       <>
    //         <div
    //           style={{ marginLeft: "-15px", marginRight: "-10px" }}
    //         >
    //           <Select
    //             showSearch
    //             optionFilterProp="children"
    //             disabled={true}//{!rowRecord.isEdit}
    //             className="remarks-select"
    //             name="type"
    //             defaultValue={rowRecord.type}
    //             value={rowRecord.type}
    //             onSelect={(value, option) =>
    //               this.handleDropdownChange(
    //                 value,
    //                 rowRecord,
    //                 "type",
    //                 -0,
    //                 option.props.children,
    //                 false
    //               )
    //             }
    //           >
    //             {this.state.typeList &&
    //               this.state.typeList.length &&
    //               this.state.typeList.map((hubItem, idx) => (
    //                 <Option
    //                   key={hubItem.type}
    //                   value={hubItem.type}
    //                 >
    //                   {hubItem.type}
    //                 </Option>
    //               ))}
    //           </Select>
    //         </div>
    //       </>
    //     );
    //   },
    // },
    {
      title: 'Warehouse handling months',
      dataIndex: 'warehouse_handling_months',
      key: 'warehouse_handling_months',
      width: 120,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.warehouse_handling_months - b.warehouse_handling_months,
      render: (record, rowRecord, index) => {
        return (
          <>
            <div style={{ marginLeft: "-20px" }}>
              <Input
                type="number"
                disabled={!rowRecord.isEdit}
                style={{ marginRight: "2px" }}
                maxLength={100}
                min="0"
                value={rowRecord.warehouse_handling_months}
                name="warehouse_handling_months"
                onChange={(e) =>
                  this.handleChange(
                    e,
                    rowRecord,
                    index,
                    "warehouse_handling_months",
                    false
                  )
                }
              />
            </div>
          </>
        );
      },
    },
    {
      title: 'Warehousing Cost per month(Rs/ton)',
      dataIndex: 'warehousing_cost_per_month(Rs/ton)',
      key: 'warehousing_cost_per_month',
      width: 120,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.warehousing_cost_per_month - b.warehousing_cost_per_month,
      render: (record, rowRecord, index) => {
        return (
          <>
            <div style={{ marginLeft: "-20px" }}>
              <Input
                type="number"
                disabled={!rowRecord.isEdit}
                style={{ marginRight: "2px" }}
                maxLength={100}
                min="0"
                value={rowRecord.warehousing_cost_per_month}
                name="warehousing_cost_permonth"
                onChange={(e) =>
                  this.handleChange(
                    e,
                    rowRecord,
                    index,
                    "warehousing_cost_per_month",
                    false
                  )
                }
              />
            </div>
          </>
        );
      },
    },
    {
      title: 'Warehousing Cost(Rs/ton)',
      dataIndex: 'warehousing_cost',
      key: 'warehousing_cost',
      width: 120,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.warehousing_cost - b.warehousing_cost,
      render: (record, rowRecord, index) => {
        return (
          <>
            <div style={{ marginLeft: "-20px" }}>
              <Input
                type="number"
                disabled={!rowRecord.isEdit}
                style={{ marginRight: "2px" }}
                maxLength={100}
                min="0"
                value={rowRecord.warehousing_cost}
                name="warehousing_cost"
                onChange={(e) =>
                  this.handleChange(
                    e,
                    rowRecord,
                    index,
                    "warehousing_cost",
                    false
                  )
                }
              />
            </div>
          </>
        );
      },
    },
    {
      title: 'Additional moistured(%)',
      dataIndex: 'additional_moistured',
      key: 'additional_moistured',
      width: 120,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.additional_moistured - b.additional_moistured,
      render: (record, rowRecord, index) => {
        return (
          <>
            <div style={{ marginLeft: "-20px" }}>
              <Input
                type="number"
                disabled={!rowRecord.isEdit}
                style={{ marginRight: "2px" }}
                maxLength={100}
                min="0"
                value={rowRecord.additional_moistured}
                name="additional_moistured"
                onChange={(e) =>
                  this.handleChange(
                    e,
                    rowRecord,
                    index,
                    "additional_moistured",
                    false
                  )
                }
              />
            </div>
          </>
        );
      },
    },
   
  
    {
      title: "Action",
      dataIndex: "Submit",
      key: "Submit",
      width: 120,
      visibility: false,
      render: (record, rowRecord, index) => {
        return (
          <>
            <div style={{ display: "inline-flex", width: "100%" }}>
              <Button
              className={!canUserEdit(
                this.props.userData,
                null,
                FeatureCodes.MS_WAREHOUSE_COST_EDIT
              ) ? "disabledBtnClass" : "btnClass"}
                disabled={!canUserEdit(
                  this.props.userData,
                  null,
                  FeatureCodes.MS_WAREHOUSE_COST_EDIT
                )}
                type="primary"
                size={"small"}
                style={{
                  marginLeft: 5,
                  alignSelf: "center",
                  padding: "0px 0px 2px",
                  width:70,
                  height: 31,
                  
                  backgroundColor: "#20346a",
                  color: "white",
                  borderRadius: 15,
                }}
                block
                htmlType="submit"
                onClick={() =>
                  rowRecord.isEdit
                    ? this.submitTableRowData(rowRecord, index, false)
                    : this.handleEditChange(rowRecord, index)
                }
              >
                {" "}
                {rowRecord.isEdit ? "Submit" : "Edit"}{" "}
              </Button>
  
  
              <Button
                className={!rowRecord.isEdit ? "disabledBtnClass" : "btnClass"}
                type="primary"
                size={"small"}
                style={{
                  marginLeft: 5,
                  alignSelf: "center",
                  width:70,
                  color: "white",
                  borderRadius:15,
                  padding: "0px 0px 2px",
                  
                  height: 31,
                  backgroundColor: "#20346a",
                }}
                block
                htmlType="submit"
                disabled={!rowRecord.isEdit}
                onClick={() => this.handleCancelClick(rowRecord, index)
                }
              >
                Cancel
              </Button>
  
            </div>
          </>
        );
      },
    },
  ]
   }
   dataSource={this.setTableData()}
   />
  </div>
  
  
  
  </Spin>
  
  </div>
   );
  }
  }
 const mapStateToProps = createStructuredSelector({
     userData: selectUserData,
     messageAccess: selectMSMessageRBAC,
   });
  
   export default connect(mapStateToProps)(WarehouseMaster);
  
  
  //export default WarehouseMaster;
  
  
  
  