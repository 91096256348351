import React, { Component } from "react";
import "./map-item.styles.css";

import { Dropdown, Menu } from "antd";

import { Map, Marker, Popup, TileLayer, SVGOverlay } from "react-leaflet";

// import MapMarker from "../map-marker-item/map-marker.component";
import MapMarker from "../google-map-marker-item/google-map-marker.component";
import MapContextMenu from "../map-context-menu/map-context-menu.component";
import {
  selectMandis,
  selectArrivalData,
  selectBranchID,
  selectHubID,
} from "../../redux/arrival-entry/arrival-entry.selectors";
import { setMandis } from "../../redux/arrival-entry/arrival-entry.actions";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectCurrentArrivalScreen } from "../../redux/map/map.selectors";
import { GoogleMap, LoadScriptNext } from "@react-google-maps/api";

const { Item } = Menu;

/**
 * @component
 * @description The map item component which displays the map in the arrival entry page.
 * @property {latlong} centerPosition the center position of the map.
 * @property {object[]} markers the array of mandi records in the selected hub.
 * @property {string} currentArrivalScreen current visible screen in arival entry page (Mandi/FOR/Farmer).
 * @property {function} handleSelectChange callback on clicking a map marker corresponding to a mandi.
 */
class MapItem extends Component {
  state = {
    mandiMarkers: [],
    currentScreen: "",
  };
  componentDidMount() {}

  shouldComponentUpdate() {
    if (
      this.props.arrivalData &&
      this.props.branchID &&
      this.props.hubID &&
      this.props.arrivalData !== undefined &&
      this.props.arrivalData !== null
    ) {
      if (this.props.markers !== this.props.arrivalData) {
        if (this.currentScreen === "Mandi") {
          let val =
            this.props.arrivalData.branch_list.find(
              (item) => item.branch_id === this.props.branchID
            ).hub_list &&
            this.props.arrivalData.branch_list
              .find((item) => item.branch_id === this.props.branchID)
              .hub_list.find((vals) => vals.hub_id === this.props.hubID)
              .mandi_list;

          // let val = this.props.arrivalData.branch_list[0].map((item)=>{
          //   if(item.branch_id === this.props.branchID){
          //     return item.hub_list.find((vals)=>vals.hub_id === this.props.hubID).mandi_list;
          //   }
          //   })

          if (val !== undefined) {
            let val1 = val.filter((item) => item !== undefined);
            // let vval1 = val1.find((item) => item.hub_id === this.props.hubID)
            // let val2 = val1.map((item1)=>{
            //   console.log("values hubs1 ",item1, item1.hub_id,this.props.hubID);
            //   if(item1.hub_id === this.props.hubID){
            //     console.log("values hubs ", item1);
            //     return item1.mandi_list;
            //   }
            //   })

            //   let val3;
            //   if(val2 !== undefined){
            //     val3 = val2.filter((item)=>item!== undefined);
            //   }

            this.mandiMarkers = val1;
          }
        } else {
          let val = this.props.arrivalData.branch_list.find(
            (item) => item.branch_id === this.props.branchID
          ).hub_list;

          // let val = this.props.arrivalData.branch_list[0].map((item)=>{
          //   if(item.branch_id === this.props.branchID){
          //     return item.hub_list.find((vals)=>vals.hub_id === this.props.hubID).mandi_list;
          //   }
          //   })

          if (val !== undefined) {
            let val1 = val.filter((item) => item !== undefined);
            this.mandiMarkers = val1;
          }
        }
        // this.mandiMarkers=this.props.arrivalData.branch_list
        //     .find((item) => item.branch_id === this.props.branchID)
        //     .hub_list.find((item) => item.hub_id === this.props.hubID).mandi_list

        this.forceUpdate();
        this.setState({});
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  render() {
    //this.mandiMarkers = this.mandiMarkers===undefined?this.props.markers:this.mandiMarkers;
    this.mandiMarkers = this.props.markers;
    this.currentScreen = this.props.currentArrivalScreen;
    const {
      setMandis,
      mandis,
      centerPosition,
      markers,
      handleSelectChange,
      zoomValue,
      currentArrivalScreen,
      getTerritories,
    } = this.props;

    const southWest = [8.4, 68.1];
    const northEast = [32.4, 97.4];
    const bounds = [southWest, northEast];

    return (
      // {currentArrivalScreen === "Mandi" ? (
      // this is the map for mandi screen
      //   <Map
      //     key="1"
      //     center={centerPosition ? centerPosition : [12.981749, 77.606891]}
      //     zoom={8}
      //     doubleClickZoom={true}
      //     scrollWheelZoom={true}
      //     dragging={true}
      //     className="map-item"
      //     animate={true}
      //     minZoom={4.5}
      //     maxBounds={bounds}
      //     maxBoundsViscosity={1.0}
      //   >
      //     <TileLayer
      //       url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
      //       //url="https://api.mapbox.com/styles/v1/mapbox/light-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw"
      //       //"https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png" //https://{s}.tile.osm.org/{z}/{x}/{y}.png"
      //       attribution='<a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      //     />

      //    {this.mandiMarkers.map((mandi, idx) => (

      //       <MapContextMenu
      //         mandiStatus={mandi.mandi_status}
      //         currentArrivalScreen={currentArrivalScreen}
      //         mandi={mandi}
      //         key={mandi.mandi_id}
      //         getTerritories={getTerritories}
      //       >
      //         <div>

      //           {mandi.mandi_latitude && mandi.mandi_longitude ? (
      //             <MapMarker
      //               key={mandi.mandi_id}
      //               currentArrivalScreen={currentArrivalScreen}
      //               position={[mandi.mandi_latitude, mandi.mandi_longitude]}
      //               mandiName={mandi.mandi_name}
      //               mandiNumber={idx + 1}
      //               markerMandiID={mandi.mandi_id}
      //               mandiStatus={mandi.mandi_status ? mandi.mandi_status : "open"}
      //               handleSelectChange={handleSelectChange}
      //             />
      //           ) : null}
      //         </div>
      //       </MapContextMenu>
      //     ))}
      //   </Map>
      // ) : (
      //     //this is the map for FOR and Farmer
      //     <Map
      //       key="2"
      //       center={centerPosition ? centerPosition : [12.981749, 77.606891]} //[16.2929531, 80.4303788]
      //       zoom={8}
      //       doubleClickZoom={true}
      //       scrollWheelZoom={true}
      //       dragging={true}
      //       className="map-item"
      //       animate={true}
      //       minZoom={4.5}
      //       maxBounds={bounds}
      //       maxBoundsViscosity={1.0}
      //     >
      //       <TileLayer
      //         url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png" //"https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png" //https://{s}.tile.osm.org/{z}/{x}/{y}.png"
      //         //url="https://api.mapbox.com/styles/v1/mapbox/light-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw"
      //         attribution='<a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      //       />
      //       {markers.map((hub, idx) => (

      //         <MapContextMenu
      //           mandiStatus={hub.hub_status ? hub.hub_status : "open"}
      //           mandi={hub}
      //           currentArrivalScreen={currentArrivalScreen}
      //           key={hub.hub_id}
      //         >
      //           <div>
      //             {/* {console.log("Hub data ========>", hub)} */}
      //             {hub.hub_latitude && hub.hub_longitude ? (
      //               <MapMarker
      //                 key={hub.hub_id}
      //                 currentArrivalScreen={currentArrivalScreen}
      //                 position={[hub.hub_latitude, hub.hub_longitude]}
      //                 mandiName={hub.hub_name}
      //                 mandiNumber={idx + 1}
      //                 markerMandiID={hub.hub_id}
      //                 mandiStatus={hub.hub_status ? hub.hub_status : "open"}
      //                 handleSelectChange={handleSelectChange}
      //               />
      //             ) : null}
      //           </div>
      //         </MapContextMenu>
      //       ))}
      //     </Map>
      //   );

      <GoogleMap
        mapContainerStyle={{
          width: "100%",
          // height: "87vh",
        }}
        center={
          centerPosition ? centerPosition : { lat: 12.981749, lng: 77.606891 }
        }
        options={{
          fullscreenControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          styles: [
            {
              elementType: "labels.icon",
              stylers: [{ visibility: "off" }],
            },
            {
              featureType: "administrative.land_parcel",
              stylers: [{ visibility: "off" }],
            },
            {
              featureType: "administrative",
              elementType: "labels",
              stylers: [{ visibility: "off" }],
            },
            {
              featureType: "poi",
              elementType: "labels",
              stylers: [{ visibility: "off" }],
            },
            {
              featureType: "road",
              elementType: "labels",
              stylers: [{ visibility: "off" }],
            },
            {
              featureType: "transit",
              elementType: "labels",
              stylers: [{ visibility: "off" }],
            },
            {
              featureType: "water",
              elementType: "labels",
              stylers: [{ visibility: "off" }],
            },
            {
              featureType: "landscape",
              elementType: "labels",
              stylers: [{ visibility: "off" }],
            },
          ],
        }}
        zoom={8}
      >
        {currentArrivalScreen === "Mandi"
          ? this.mandiMarkers.map((mandi, idx) => (
              <MapContextMenu
                mandiStatus={mandi.mandi_status}
                currentArrivalScreen={currentArrivalScreen}
                mandi={mandi}
                key={mandi.mandi_id}
                getTerritories={getTerritories}
              >
                <div>
                  {mandi.mandi_latitude && mandi.mandi_longitude ? (
                    <MapMarker
                      key={mandi.mandi_id}
                      currentArrivalScreen={currentArrivalScreen}
                      position={{
                        lat: mandi.mandi_latitude,
                        lng: mandi.mandi_longitude,
                      }}
                      mandiName={mandi.mandi_name}
                      mandiNumber={idx + 1}
                      markerMandiID={mandi.mandi_id}
                      mandiStatus={
                        mandi.mandi_status ? mandi.mandi_status : "open"
                      }
                      handleSelectChange={handleSelectChange}
                    />
                  ) : null}
                </div>
              </MapContextMenu>
            ))
          : markers.map((hub, idx) => (
              <MapContextMenu
                mandiStatus={hub.hub_status ? hub.hub_status : "open"}
                mandi={hub}
                currentArrivalScreen={currentArrivalScreen}
                key={hub.hub_id}
              >
                <div>
                  {hub.hub_latitude && hub.hub_longitude ? (
                    <MapMarker
                      key={hub.hub_id}
                      currentArrivalScreen={currentArrivalScreen}
                      position={{
                        lat: hub.hub_latitude,
                        lng: hub.hub_longitude,
                      }}
                      mandiName={hub.hub_name}
                      mandiNumber={idx + 1}
                      markerMandiID={hub.hub_id}
                      mandiStatus={hub.hub_status ? hub.hub_status : "open"}
                      handleSelectChange={handleSelectChange}
                    />
                  ) : null}
                </div>
              </MapContextMenu>
            ))}
      </GoogleMap>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  currentArrivalScreen: selectCurrentArrivalScreen,
  arrivalData: selectArrivalData,
  mandis: selectMandis,
  branchID: selectBranchID,
  hubID: selectHubID,
});

export default connect(mapStateToProps, null)(MapItem);
