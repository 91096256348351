import React, { Component } from "react";
import "./action-item.styles.css";

import ActionTickImage from "../../assets/action_tick.svg";
import CircleImage from "../../assets/circle.svg";
import Approve1 from "../../assets/approval/approve1.png";
import Approve2 from "../../assets/approval/approve2.png";
import Reject1 from "../../assets/approval/reject1.png";
import Reject2 from "../../assets/approval/reject2.png";
import Pending from "../../assets/approval/pending.png";
import Approve1Reject1 from "../../assets/approval/approve1reject2.png";
import Comment from "../../assets/approval/comment.svg";
import NotSuggested from "../../assets/approval/not_suggested.svg";

import { Icon, Menu, Dropdown, message, Popover, Modal, Spin } from "antd";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectUserData } from "../../redux/user/user.selectors";
import { selectSelectedBranchID } from "../../redux/buying-decision/buying-decision.selectors";

import { canUserEdit } from "../../auth/utils";
import { FeatureCodes } from "../../auth/data";

import BuyingSubmissionForm from "../../components/buying-decision-form/buying-decision-form.component";

/**
 * @component
 * @description Component for submitting buying plans and closing/opening mandis in buying decision planning table.
 */
class ActionItem extends Component {
  state = {
    clicked: false,
    isSubmitModalVisible: false,
    isHubLevel: false,
    isSubmitted: false,
  };

  render() {
    const {
      canUserSubmit,
      isActionEnabled,
      mandiID,
      mandiStatus,
      rowRecord,
      changeMandiStatus,
      sendDataMandi,
      postBuyingDecisionHub,
      srp,
      sourceType,
      userData,
    } = this.props;

    const claimTypeList = [
      {
        name: "Claim",
        id: 1,
      },
      {
        name: "No Claim",
        id: 2,
      },
    ];

    const divisionList = [
      {
        name: "ABD",
        id: 1,
      },
      {
        name: "FBD",
        id: 2,
      },
      {
        name: "NCDEX",
        id: 3,
      },
    ];

    const setSubmittedStatus = (status) => {
      this.setState({ isSubmitted: status });
    };

    /**
     * @function
     * @description Send FOR and mandi plan on click of the button.
     * @param {string} claimType type of claim during submission.
     * @param {string} division division to which the submission needs to take place.
     * @memberof ActionItem
     */
    const handleClick = (claimType, division, rowRecord) => {
      this.setState({ clicked: true });

      if (this.props.postBuyingDecisionHub) {
        const hubPostStatus = postBuyingDecisionHub(
          this.props.postHubRowId,
          srp,
          sourceType,
          claimType,
          division,
          (status) => setSubmittedStatus(status),
          rowRecord
        );

        this.setState({ clicked: false });
      }

      if (this.props.sendDataMandi) {
        if (mandiStatus === "open") {
          if (this.props.sendDataMandi) {
            const mandiPostStatus = sendDataMandi(
              rowRecord.actions.mandiID,
              rowRecord.srp,
              rowRecord,
              claimType,
              division
            );
            // this.setState({ clicked: mandiPostStatus });
          }
        } else {
          if (!rowRecord.mandi)
            message.warning(
              "Please select a mandi from the dropdown to submit"
            );
          else
            message.error("This mandi is closed. Reopen to submit data again");
        }
      }
      this.setState({ clicked: false });
    };

    /**
     * @function
     * @description Sets curresponding button styles according to the approval status
     * @param {object} approvals Object containing approval details
     * @memberof ActionItem
     * @returns Image
     */
    const setImage = (approvals) => {
      var image;
      if (!rowRecord.edited) {
        let count =
          (approvals.approval1 === null) + (approvals.approval2 === null);
        if (count === 2) {
          //#region no approvals yet
          image = Pending;
          if (
            rowRecord.revisedQty === 0 &&
            rowRecord.maxPlanned === 0 &&
            rowRecord.minPlanned === 0
          ) {
            image = NotSuggested;
          }
          if (rowRecord.data.version === 0 && rowRecord.revisedQty !== 0) {
            image = CircleImage;
          }
          //#endregion
        } else if (count === 1) {
          image =
            approvals.approval1 || approvals.approval2 ? Approve1 : Reject1;
        } else {
          if (approvals.approval1) {
            image = approvals.approval2 ? Approve2 : Approve1Reject1;
          } else {
            image = approvals.approval2 ? Approve1Reject1 : Reject2;
          }
        }
        image = this.state.clicked ? ActionTickImage : image;
      } else {
        image = CircleImage;
      }
      // return this.state.clicked ? ActionTickImage : CircleImage;
      return image;
    };

    return (
      <div className={`action-item-container  }`}>
        <Spin className="action-spinner" spinning={this.state.isSubmitted}>
          <div

            style={{ display: "flex" }}
            onClick={() => {
              (rowRecord.edited ||
                (rowRecord.data.version === 0 && rowRecord.revisedQty !== 0)) &&
                handleClick(rowRecord.data.claim_type, rowRecord.data.division, rowRecord);
            }}
            className={`action-button ${canUserSubmit ? '' : 'action-button-disabled'} `}
          >
            <Popover
              overlayClassName="popover-appoval"
              content={
                <div>
                  {rowRecord.edited ||
                    (rowRecord.data.version === 0 &&
                      rowRecord.revisedQty !== 0) ? (
                    <div>Submit</div>
                  ) : rowRecord.approvals.approval1 === null &&
                    rowRecord.approvals.approval2 === null ? (
                    rowRecord.revisedQty === 0 &&
                      rowRecord.maxPlanned === 0 &&
                      rowRecord.minPlanned === 0 ? (
                      <div>Not suggested</div>
                    ) : (
                      <div>Pending</div>
                    )
                  ) : (
                    <div>
                      <div className="line-one">
                        {rowRecord.approvals.approval1 !== null
                          ? rowRecord.approvals.approval1 === true
                            ? "Approved by : "
                            : `Rejected by     : `
                          : ""}
                        {rowRecord.approvals.approver1Name === userData.userName
                          ? "You"
                          : rowRecord.approvals.approver1Name}
                      </div>
                      <div className="line-two">
                        {rowRecord.approvals.approval2 !== null
                          ? rowRecord.approvals.approval2 === true
                            ? "Approved by : "
                            : `Rejected by     : `
                          : ""}
                        {rowRecord.approvals.approver2Name === userData.userName
                          ? "You"
                          : rowRecord.approvals.approver2Name}
                      </div>
                    </div>
                  )}
                </div>
              }
              trigger="hover"
            >
              <img alt="action" src={setImage(rowRecord.approvals)} />
              &nbsp;&nbsp;&nbsp;
            </Popover>
          </div>

          {(rowRecord.approvals.approval1 === false ||
            rowRecord.approvals.approval2 === false) && (
              <Popover
                overlayClassName="popover-appoval"
                content={
                  <div>
                    <div>Comments:</div>
                    {rowRecord.approvals.comment1 !== null && (
                      <div>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-
                        {rowRecord.approvals.comment1}
                      </div>
                    )}
                    {rowRecord.approvals.comment2 !== null && (
                      <div>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-
                        {rowRecord.approvals.comment2}
                      </div>
                    )}
                  </div>
                }
                trigger="click"
              >
                <div className="comment-reject">
                  <img
                    style={{ width: "24px", height: "24px" }}
                    src={Comment}
                    alt=""
                  />
                </div>
              </Popover>
            )}
          {isActionEnabled ? (
            <div
              className="context-menu"
              style={{
                marginLeft: `${rowRecord.approvals.approval1 === false ||
                  rowRecord.approvals.approval2 === false
                  ? ""
                  : "25px"
                  }`,
              }}
            >
              {mandiStatus === "open" ? (
                <Dropdown
                  disabled={
                    !canUserEdit(
                      this.props.userData,
                      this.props.selectedBranchID,
                      FeatureCodes.ARRIVALS_CLOSE_MANDI
                    )
                  }
                  overlay={
                    <Menu>
                      <Menu.Item
                        onClick={() => {
                          if (mandiID) {
                            changeMandiStatus &&
                              changeMandiStatus(rowRecord, "closed");
                          }
                        }}
                        key="1"
                      >
                        Set Mandi Closed
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={["click"]}
                >
                  <Icon type="more" />
                </Dropdown>
              ) : (
                <Dropdown
                  disabled={
                    !canUserEdit(
                      this.props.userData,
                      this.props.selectedBranchID,
                      FeatureCodes.ARRIVALS_CLOSE_MANDI
                    )
                  }
                  overlay={
                    <Menu>
                      <Menu.Item
                        onClick={() => {
                          if (mandiID) {
                            changeMandiStatus &&
                              changeMandiStatus(rowRecord, "open");
                          }
                        }}
                        key="1"
                      >
                        Set Mandi Open
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={["click"]}
                >
                  <Icon type="more" />
                </Dropdown>
              )}
            </div>
          ) : null}
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
  selectedBranchID: selectSelectedBranchID,
});

export default connect(mapStateToProps, null)(ActionItem);
