import React from "react";
import "./edit-wishlist-item.styles.css";
import { Component } from "react";

import { Select, message } from "antd";

import closeImage from "../../assets/close.svg";
import mandiCloseImage from "../../assets/blue-close.svg";
import addImage from "../../assets/filled-plus.svg";
import tickImage from "../../assets/tick.svg";
import API from "../../api";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectInterestedWatchList } from "../../redux/alerts/alerts.selectors";
import { setInterestedWatchList } from "../../redux/alerts/alerts.actions";

const { Option } = Select;

/**
 *
 * @component 
 * @description The edit watchlist component used in the side menu, under watchlist edit feature.
 * @property {object} item object corresponding to a watchlist.
 * @property {object[]} allMandis list of all mandi objects from the redux store.
 */
class EditWishListItem extends Component {
  state = {
    isAddMandiButtonClicked: false,
    selectedMandiName: null,
  };

  render() {
    const { item, allMandis } = this.props;

    //#region set available mandis in the dropdown
    const currentMandiIdList = item.variety_mandi_list.map(
      (item) => item.mandi_id
    );
    const allMandiIdList = allMandis.map((item) => item.mandi_id);
    //remove current mandis from all mandis
    const dropdownMandiIdList = allMandiIdList.filter(
      (responseItem) => !currentMandiIdList.includes(responseItem)
    );

    const dropdownMandis = allMandis.filter((mandi) =>
      dropdownMandiIdList.includes(mandi.mandi_id)
    );


    //#endregion

    /**
     * @description Delete a variety from the watchlist
     * @memberof EditWishListItem
     */
    const deleteVariety = () => {
      //#region call api to delete the variety item and remove it from redux


      const datatoDelete = {
        interested_mandi: item.variety_mandi_list.map((mandi) => {
          return {
            mandi_id: mandi.mandi_id,
          };
        }),
      };

      API.delete(`/user/varieties/${item.user_variety_id}`, {

        data: datatoDelete,
      })
        .then((deleteVarietyResponse) => {
          if (deleteVarietyResponse.status) {
            //#region delete the record from redux.
            let currentWachList = this.props.interestedWatchList;
            let modifiedWatchList = currentWachList.filter(
              (watchListItem) =>
                watchListItem.user_variety_id !== item.user_variety_id
            );

            this.props.setInterestedWatchList(modifiedWatchList);

            //#endregion
          } else {
            message.warning("Could not delete variety.Please try again");
          }
        })
        .catch((err) => {
          message.error(
            "Could not delete the variety. Please check your network connectivity."
          );
        });

      //#endregion
    };

    /**
     * @description Delete a mandi from the watchlist being edited.
     * @param {object} selectedMandi the current selected mandi.
     * @memberof EditWishListItem
     */
    const deleteMandi = (selectedMandi) => {
      //#region api call to update the watchlist and update the redux store.
      const modifiedMandiList = item.variety_mandi_list.filter(
        (mandi) => mandi.mandi_id !== selectedMandi.mandi_id
      );


      const dataToDelete = {
        variety_id: item.variety_id,
        variety_mandi_list: modifiedMandiList.map((mandi) => {
          return {
            mandi_id: mandi.mandi_id,
          };
        }),
      };


      API.put(`/user/varieties/${item.user_variety_id}`, dataToDelete, {

      })
        .then((updateVarietyResponse) => {
          if (updateVarietyResponse.status) {
            //#region update the redux store

            let modifiedWatchList = this.props.interestedWatchList.map(
              (watchListItem) => {
                if (watchListItem.user_variety_id === item.user_variety_id) {
                  return {
                    ...watchListItem,
                    variety_mandi_list: modifiedMandiList,
                  };
                }
                return watchListItem;
              }
            );

            this.props.setInterestedWatchList(modifiedWatchList);
            //#endregion
          } else {
            message.warning("Could not modify the watchlist.Please try again.");
          }
        })
        .catch((err) => {
          message.error(
            "Could not update the watchlist. Please check your network connectivity."
          );
        });

      //#endregion
    };

    /**
     * @description Add a mandi to the watchlist being edited.
     * @memberof EditWishListItem
     */
    const addMandi = () => {
      if (!this.state.selectedMandiName) {
        message.error("Please select a mandi");
        return;
      }

      const mandiToAdd = allMandis.find(
        (mandi) => mandi.mandi_name === this.state.selectedMandiName
      );

      let modifiedMandiList = [...item.variety_mandi_list, mandiToAdd];
      //#region to call update wathclist api and update redux data.

      const dataToSend = {
        variety_id: item.variety_id,
        variety_mandi_list: modifiedMandiList.map((mandi) => {
          return {
            mandi_id: mandi.mandi_id,
          };
        }),
      };


      API.put(`/user/varieties/${item.user_variety_id}`, dataToSend, {
      })
        .then((updateWatchListResponse) => {
          if (updateWatchListResponse.status) {
            //#region update the redux store.

            let modifiedWatchList = this.props.interestedWatchList.map(
              (watchListItem) => {
                if (watchListItem.user_variety_id === item.user_variety_id) {
                  return {
                    ...watchListItem,
                    variety_mandi_list: modifiedMandiList,
                  };
                }
                return watchListItem;
              }
            );

            this.props.setInterestedWatchList(modifiedWatchList);

            //#endregion
          } else {
            message.warning("Could not modify the watchlist.Please try again.");
          }
        })
        .catch((err) => {
          message.error(
            "Could not update the watchlist. Please check your network connectivity."
          );
        });

      //#endregion

      this.setState({ isAddMandiButtonClicked: false });
      this.setState({ selectedMandiName: null });
    };

    return (
      <div className="edit-wishlist-item-container">
        <div className="variety-items">
          <div className="variety-name">{item.variety_name}</div>
          <img
            className="close-variety-image"
            src={closeImage}
            alt="close"
            onClick={() => deleteVariety()}
          />
        </div>
        <div className="mandi-container">
          <div className="mandi-list-items">
            {item.variety_mandi_list.map((mandi) => (
              <div key={mandi.mandi_id} className="mandi-item-container">
                <div className="mandi-item">
                  <div className="mandi-name">{mandi.mandi_name}</div>
                  <img
                    className="remove-mandi-image"
                    src={mandiCloseImage}
                    alt="add"
                    onClick={() => deleteMandi(mandi)}
                  />
                </div>
                <div className="mandi-item-line" />
              </div>
            ))}

            {this.state.isAddMandiButtonClicked ? (
              <div className="mandi-item-container">
                <div className="mandi-item">
                  <Select
                    className="mandi-select"
                    showArrow
                    showSearch
                    defaultValue="Select Mandi"
                    onChange={(value) =>
                      this.setState({ selectedMandiName: value })
                    }
                  >
                    {dropdownMandis.map((mandi) => (
                      <Option key={mandi.mandi_id} value={mandi.mandi_name}>
                        {mandi.mandi_name}
                      </Option>
                    ))}
                  </Select>

                  <div className="mandi-button-container">
                    <img
                      onClick={() =>
                        this.setState({ isAddMandiButtonClicked: false })
                      }
                      className="remove-mandi-image"
                      src={closeImage}
                      alt="close"
                    />
                    <img
                      className="remove-mandi-image"
                      src={tickImage}
                      alt="add"
                      onClick={() => addMandi()}
                    />
                  </div>
                </div>
                <div className="mandi-item-line" />
              </div>
            ) : null}
          </div>

          <div
            className="add-mandi-button"
            onClick={() => this.setState({ isAddMandiButtonClicked: true })}
          >
            <img className="add-image" src={addImage} alt="add" />
            <div className="add-mandi-text">Add Mandi</div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  interestedWatchList: selectInterestedWatchList,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setInterestedWatchList: (data) => dispatch(setInterestedWatchList(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditWishListItem);
