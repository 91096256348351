import React, { Component } from "react";
import API from "../../api";
import "./choose-hub.styles.css";

import { getWeather } from "../../auth/utils";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { Button, Checkbox, Modal, Select, message } from "antd";

import CustomDropdownChooseRegion from "../custom-dropdown-choose-region/custom-dropdown-choose-region.component";
import AddHubForm from "../add-hub-form/add-hub-form.component";

import {
  setRollUp,
  setAvailableHubs,
  setAvailableMandis,
  setHubTableData,
  setDecisionBranches,
  setDecisionRegions,
  setDecisionVarieties,
  setSelectedBranchID,
  setSelectedRegionID,
  setSelectedVarietyID,
  setDualRender,
} from "../../redux/buying-decision/buying-decision.actions";

import {
  selectAvailableHubs,
  selectHubTableData,
  selectDecisionBranches,
  selectDecisionRegions,
  selectDecisionVarieties,
  selectSelectedBranchID,
  selectSelectedRegionID,
  selectSelectedVarietyID,
} from "../../redux/buying-decision/buying-decision.selectors";

import { selectUserData } from "../../redux/user/user.selectors";

import { canUserEdit } from "../../auth/utils";
import { FeatureCodes } from "../../auth/data";
import Axios from "axios";

const FileDownLoad = require("js-file-download");

const { Option } = Select;

/**
 * @component
 * @description Child component of buying decision planning component.
 * Contains the filters for selecting Branch,Region and variety to get the data.
 * Also contains the add hub button.
 */
class ChooseHub extends Component {
  state = {
    isModalVisible: false,
    selectedRegions: [],
    selectedVarieties: [],
    selectedBranchName: "",
    selectedBranchID: undefined,
  };

  waitForRegionVarietyData() {
    this.setState({ selectedBranchID: this.props.selectedBranchID });
    if (
      this.props.decisionRegions.length !== 0 &&
      this.props.decisionVarieties.length !== 0 &&
      this.props.selectedRegionID
    ) {
      this.setState(
        {
          // selectedRegions: [this.props.decisionRegions.filter((item) => item.region_id == this.props.selectedRegionID).length && this.props.decisionRegions.filter((item) => item.region_id == this.props.selectedRegionID)[0].region_id],
          // selectedVarieties: [this.props.decisionVarieties.filter((item) => item.variety_id == this.props.selectedVarietyID).length && this.props.decisionVarieties.filter((item) => item.variety_id == this.props.selectedVarietyID)[0].variety_id]

          // Below commented line to select for first region from the list

          // selectedRegions: [this.props.decisionRegions.filter((item) => item.region_id == this.props.selectedRegionID).length && this.props.decisionRegions.filter((item) => item.region_id == this.props.selectedRegionID)[0].region_id ? this.props.decisionRegions.filter((item) => item.region_id == this.props.selectedRegionID)[0].region_id : this.props.decisionRegions[0].region_id],

          // below lines are to select all the regions by defualt

          selectedRegions:
            this.props.decisionRegions && this.props.decisionRegions.length
              ? this.props.decisionRegions.map((id) => id.region_id)
              : [],
          selectedBranchID: this.props.selectedBranchID,
          selectedVarieties: [
            this.props.decisionVarieties.filter(
              (item) => item.variety_id === this.props.selectedRegionID
            ).length &&
            this.props.decisionVarieties.filter(
              (item) => item.variety_id === this.props.selectedRegionID
            )[0].variety_id
              ? this.props.decisionVarieties.filter(
                  (item) => item.variety_id === this.props.selectedRegionID
                )[0].variety_id
              : this.props.decisionVarieties[0].variety_id,
          ],
        },
        () => this.getHubTableData()
      );
    } else {
      setTimeout(() => {
        this.waitForRegionVarietyData();
      }, 100);
    }
  }
  // componentDidUpdate(prevState) {
  //   if (prevState.selectedBranchID !== this.state.selectedBranchID) {
  //     console.log("did update ", prevState.selectedBranchID,this.state.selectedBranchID,this.props.selectedBranchID)
  //     this.GetRegion(this.state.selectedBranchID);
  //   }
  //   console.log("component did m Update executed ", this.props.selectedBranchID,
  //   "state branch "+ this.state.selectedBranchID,"prev state " +prevState.selectedBranchID);
  // }

  componentDidMount() {
    this.waitForRegionVarietyData();
  }

  /**
   * @function
   * @description Get the regions for a corresponding branch.
   * @param {number} branchId the id of the branch for which regions needs to be retrieved.
   * @memberof ChooseHub
   */
  GetRegion = (branchId) => {
    this.props.spinnerOn(true);
    const params = {
      table_type: "transaction",
      branch_id: branchId,
      mapping: "mapped",
    };
    // API.get(`/branches/${branchId}/regions/`, { headers: this.headers })
    API.get(`/regions`, { params: params })
      .then((regionResponse) => {
        this.props.spinnerOn(false);
        this.props.setDecisionRegions(regionResponse.data.data.regions);
        this.props.setSelectedRegionID(
          regionResponse.data.data.regions[0].region_id
        );
        this.setState((prevState) => ({
          ...prevState,
          // selectedRegions: regionResponse.data.data.regions[0].region_id, // for setting it to first region
          selectedRegions: regionResponse.data.data.regions.map(
            (id) => id.region_id
          ), // for setting it to all regions
        }));

        this.GetVariety(
          branchId,
          // regionResponse.data.data.regions[0].region_id // for setting it to first region
          regionResponse.data.data.regions.map((id) => id.region_id) // for setting it to first region
        );
      })
      .catch((err) => {
        this.props.spinnerOn(false);
      });
  };

  /**
   * @function
   * @description Get the varieties for a corresponding branch and variety.
   * @param {number} branchId the id of the branch for which data needs to be retrieved.
   * @param {number} regionId the id of the region for which data needs to be retrieved.
   * @memberof ChooseHub
   */
  GetVariety = (branchId, regionId) => {
    this.props.spinnerOn(true);
    // const VARIETY_END_POINT = `/branches/${branchId}/regions/${this.props.selectedRegionID}/`;
    const VARIETY_END_POINT = `/varieties`;

    const params = {
      table_type: "transaction",
      mapping: "mapped",
      region_id: regionId,
    };
    API.get(VARIETY_END_POINT, {
      params: params,
    })
      .then((varietyResponse) => {
        this.props.spinnerOn(false);

        this.props.setDecisionVarieties(
          varietyResponse.data.data
            ? varietyResponse.data.data.filter(
                (value, index, array) =>
                  array.findIndex(
                    (obj) => obj.variety_id === value.variety_id
                  ) === index
              )
            : []
        );

        this.props.setSelectedVarietyID(
          varietyResponse.data.data
            ? varietyResponse.data.data[0].variety_id
            : ""
        );
        this.setState((prevState) => ({
          ...prevState,
          selectedVarieties:
            this.state.selectedVarieties ||
            varietyResponse.data.data[0].variety_id,
        }));
        // this.setState((prevState) => ({
        //   ...prevState,
        //   selectedVarieties: selectedVarieties && selectedVarieties.length
        //     ? selectedVarieties : varietyResponse.data.data[0].variety_name
        // }));

        this.getHubTableData(varietyResponse.data.data[0].variety_id);
      })
      .catch((err) => {
        this.props.spinnerOn(false);
      });
  };

  /**
   * @function
   * @description Get the buying decision data for a corresponding variety.
   * @param {number} variety_id the id of the variety for which data needs to be retrieved.
   * @memberof ChooseHub
   */
  getHubTableData = (variety_id) => {
    this.props.setHubTableData({});

    this.props.setFORData([]);
    this.props.spinnerOn(true);
    const { spinnerOn } = this.props;
    //#region api call for getting buying decision data
    const params = {
      branch_id: this.props.selectedBranchID,
      region_id: this.state.selectedRegions.length
        ? this.state.selectedRegions
        : [this.state.selectedRegions],
      variety_id: this.state.selectedVarieties.length
        ? this.state.selectedVarieties
        : [this.state.selectedVarieties],
    };
    const HUB_DATA_END_POINT = `/buying_decision_planned`;
    API.get(HUB_DATA_END_POINT, { params: params })
      // const HUB_DATA_END_POINT = `/branches/${this.props.selectedBranchID}/regions/${this.props.selectedRegionID}/variety/${variety_id}`;
      // API.get(HUB_DATA_END_POINT, { headers: this.headers,})
      .then((buyingResponse) => {
        spinnerOn(false);

        if (buyingResponse.data) {
          // console.log("saving buying decision data to store");
          this.props.setSelectedData([
            this.state.selectedRegions.length
              ? this.state.selectedRegions
              : [this.state.selectedRegions],
            this.state.selectedVarieties.length
              ? this.state.selectedVarieties
              : [this.state.selectedVarieties],
          ]);
          const modifiedHubDataStructure = {
            region: {
              hub_details: buyingResponse.data.data.hub_list.map(
                (item, idx) => {
                  return { ...item, edited: false }; // , index: idx
                }
              ),
            },
          };
          this.props.setHubTableData(modifiedHubDataStructure);

          // this.props.setRollUp(
          //   buyingResponse.data.data.mandi_roll_up,
          //   buyingResponse.data.data.for_roll_up,
          //   buyingResponse.data.data.farmer_roll_up
          // );
          this.props.setRollUp({}, buyingResponse.data.data.for_roll_up, {});

          // // stores hubs with FOR in the buying decision local state for post.
          this.props.setFORData(this.props.hubTableData);
          this.props.spinnerOn(false);
          // //
        }
      })
      .catch((err) => {
        this.props.setHubTableData({});

        //
        this.props.setFORData([]);
        this.props.spinnerOn(false);
      });

    //#endregion
    //
  };

  render() {
    const { spinnerOn } = this.props;
    const currentHubs =
      this.props.hubTableData && this.props.hubTableData.region
        ? this.props.hubTableData.region.hub_details
        : [];

    //multiple variety handling
    //#region
    //filtering variety data to name and key
    let varietyIds, varietyNames;
    if (this.props.decisionVarieties) {
      varietyIds = this.props.decisionVarieties
        ? this.props.decisionVarieties.map((item) => item.variety_id)
        : "";
      varietyNames = this.props.decisionVarieties.map(
        (item) => item.variety_name
      );
    }

    //region handling
    //#region
    //filtering regions data to name and key
    let regionIds, regionName;
    if (this.props.decisionRegions) {
      regionIds = this.props.decisionRegions
        ? this.props.decisionRegions.map((item) => item.region_id)
        : "";
      regionName = this.props.decisionRegions.map((item) => item.region_name);
    }

    /**
     * @description Enable/disable the add hub modal visibility.
     * @param {boolean} status visibility state of the add hub modal
     * @memberof ChooseHub
     */
    const setModalVisibility = (status) => {
      // if modal needs to be visible, call api to get the new hubs data, then make it visible.
      // else simply hide the modal without calling the api.
      if (status) {
        spinnerOn(true);
        //#region call api to get hubs that can be added.

        // const params = {
        //   branch_id: this.props.selectedBranchID,
        //   // regions: ,
        //   // varities: ,
        // };
        const params = {
          branch_id: this.props.selectedBranchID,
          region_id: this.state.selectedRegions.length
            ? this.state.selectedRegions
            : [this.state.selectedRegions],
          variety_id: this.state.selectedVarieties.length
            ? this.state.selectedVarieties
            : [this.state.selectedVarieties],
        };
        // const GET_NEW_HUBS_END_POINT = `/branches/${this.props.selectedBranchID}/regions/${this.props.selectedRegionID}/variety/${this.props.selectedVarietyID}/hubs`;
        const GET_NEW_HUBS_END_POINT = `/buying_decision_planned/non_recommended_hubs_list`;
        // ?branch_id=7&region_id[]=21&variety_id[]=7&region_id[]=22
        API.get(GET_NEW_HUBS_END_POINT, { params: params })

          // const GET_NEW_HUBS_END_POINT = `/buying_decision_planned`;
          // API.get(GET_NEW_HUBS_END_POINT, { headers: hubHeaders, params: params })
          .then((hubResponse) => {
            if (hubResponse.status) {
              spinnerOn(false);
              console.log(
                "NEW HUBS THAT CAN BE ADDED : ",
                hubResponse.data.data.hub_list
              );

              if (hubResponse.data.data.hub_list.length !== 0) {
                this.props.setAvailableHubs(hubResponse.data.data.hub_list);
                this.setState({ isModalVisible: true });
              }
              // if hub list is a null array, it means that no more hubs can be added.
              // in that case we can hide the modal and display a warning that no more hubs can be added.
              else {
                message.warning("All the hubs have been added to this list.");
                this.setState({ isModalVisible: false });
              }
            } else {
              spinnerOn(false);
              message.error("Could not get hub details.Please try again...");
              this.setState({ isModalVisible: false });
            }
          })
          .catch((err) => {
            spinnerOn(false);
            message.error(
              "Could not get hub details.Please check your network..."
            );
          });

        //#endregion
      } else {
        this.setState({ isModalVisible: status });
      }
    };

    /**
     * @description Get the data on selecting a new branch.
     * @param {string} value selected branch
     * @param {object} props selection output object from the Select component.
     * @memberof ChooseHub
     */
    const handleBranchSelect = (value, props) => {
      this.setState({ selectedBranchName: value, selectedBranchID: props.key });
      this.GetRegion(props.key);
      this.props.setSelectedBranchID(props.key);
    };

    /**
     * @description Get the data on selecting a new region.
     * @param {string} value selected region
     * @param {object} props selection output object from the Select component.
     * @memberof ChooseHub
     */
    const handleRegionSelect = (value, props) => {
      this.GetVariety(this.props.selectedBranchID, props.key);
      this.props.setSelectedRegionID(
        value === "ALL" ? "10000000000" : props.key
      );
      this.setState((prevState) => ({
        ...prevState,
        selectedRegions: value,
      }));
    };

    /**
     * @description Get the data on selecting a new variety.
     * @param {string} value selected variety
     * @param {object} props selection output object from the Select component.
     * @memberof ChooseHub
     */
    const handleVarietySelect = (value, props) => {
      this.props.setSelectedVarietyID(props.key);
      this.setState(
        (prevState) => ({
          ...prevState,
          selectedVarieties: value,
        }),
        () => this.getHubTableData(props.key)
      );
    };

    //To compare with selected dropdowns
    let regions = [],
      varieties = [];

    return (
      <div className="choose-hub-container">
        <Modal
          className="add-hub-modal"
          title={"Add Hub"}
          visible={this.state.isModalVisible}
          destroyOnClose={true}
          onOk={() => {
            setModalVisibility(false);
          }}
          onCancel={() => {
            this.props.setAvailableMandis([]);
            this.props.setAvailableHubs([]);
            setModalVisibility(false);
          }}
        >
          <AddHubForm
            spinnerOn={this.props.spinnerOn}
            currentVarietyId={this.props.selectedVarietyID}
            newHubs={this.props.availableHubs}
            currentHubs={currentHubs}
            selectedRegions={
              this.state.selectedRegions.length
                ? this.state.selectedRegions
                : [this.state.selectedRegions]
            }
            selectedVarieties={
              this.state.selectedVarieties.length
                ? this.state.selectedVarieties
                : [this.state.selectedVarieties]
            }
            // selectedRegions={this.state.selectedRegions}
            // selectedVarieties={this.state.selectedVarieties}
            setModalVisibility={setModalVisibility}
            selectedRegionID={this.props.selectedRegionID}
            selectedBranchID={this.props.selectedBranchID}
          />
        </Modal>
        <div
          className={`add-hub-button-container ${
            canUserEdit(
              this.props.userData,
              this.props.selectedBranchID,
              FeatureCodes.BUYING_DECISION_SUBMIT
            )
              ? ""
              : "add-hub-disabled"
          }  `}
        >
          <Button
            className="add-hub-button"
            onClick={() => setModalVisibility(true)}
          >
            &#10010; Add Hub
          </Button>
        </div>

        <div className="hub-selector">
          {/* <h1>Create your buying plan in&nbsp;</h1>{" "} */}
          <h1>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;For Branch&nbsp;</h1>{" "}
          <CustomDropdownChooseRegion
            key="1"
            selectType="branch"
            handleSelect={handleBranchSelect}
            placeholder={
              this.props.decisionBranches.length !== 0 &&
              this.props.decisionBranches.filter(
                (item) =>
                  item.territory_id === parseInt(this.state.selectedBranchID)
              ).length !== 0
                ? this.props.decisionBranches.filter(
                    (item) =>
                      item.territory_id ===
                      parseInt(this.state.selectedBranchID)
                  )[0].territory_name
                : ""
            }
            list={this.props.decisionBranches}
          />
          <h1>&nbsp;Regions&nbsp;</h1>
          {/* <CustomDropdownChooseRegion
            key="2"
            selectType="region"
            handleSelect={handleRegionSelect}
            placeholder={
              this.props.selectedRegionID == "10000000000"
                ? "ALL"
                : this.props.decisionRegions.length !== 0 &&
                  this.props.decisionRegions.filter(
                    (item) => item.region_id == this.props.selectedRegionID
                  ).length !== 0
                ? this.props.decisionRegions.filter(
                    (item) => item.region_id == this.props.selectedRegionID
                  )[0].region_name
                : ""
            }
            list={this.props.decisionRegions}
          /> */}
          <Select
            maxTagCount={2}
            showArrow
            showSearch
            className={"select-region"}
            // disabled={
            //   this.state.isSpinnerOn ||
            //   this.state.dropdownData.varieties == null
            // }
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            mode="multiple"
            allowClear={true}
            placeholder="Select regions"
            value={this.state.selectedRegions}
            onChange={(value, props) => handleRegionSelect(value, props)}
            onFocus={() => {
              regions = this.state.selectedRegions;
            }}
            // onBlur={(value) => {
            //   this.setDataForTable(
            //     this.state.selectedRegions,
            //     this.state.selectedBranch,
            //     this.state.selectedHubs,
            //     this.state.selectedClaimType,
            //     this.state.selectedDivisions,
            //     this.state.startDate,
            //     this.state.endDate
            //   );
            // }}
          >
            {this.props.decisionRegions !== null &&
              this.props.decisionRegions.length &&
              this.props.decisionRegions.map((item, idx) => (
                <Option
                  value={regionIds ? regionIds[idx] : idx}
                  key={regionIds ? regionIds[idx] : idx}
                >
                  {item.region_name}
                </Option>
              ))}
          </Select>
          <h1>&nbsp;Varieties&nbsp;</h1>{" "}
          {/* <CustomDropdownChooseRegion
            key="3"
            selectType="variety"
            handleSelect={handleVarietySelect}
            placeholder={
              this.props.decisionVarieties.length !== 0 &&
                this.props.decisionVarieties.filter(
                  (item) => item.variety_id == this.props.selectedVarietyID
                ).length !== 0
                ? this.props.decisionVarieties.filter(
                  (item) => item.variety_id == this.props.selectedVarietyID
                )[0].variety_name
                : ""
            }
            list={this.props.decisionVarieties}
          /> */}
          <Select
            maxTagCount={2}
            showArrow
            showSearch
            // disabled={
            //   this.state.isSpinnerOn ||
            //   this.state.dropdownData.varieties == null
            // }
            className={"select-varieties"}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            mode="multiple"
            placeholder="Select varieties"
            value={this.state.selectedVarieties}
            onChange={(value, props) => handleVarietySelect(value, props)}
            onFocus={() => {
              varieties = this.state.selectedVarieties;
            }}
            // onBlur={(value) => {
            //   this.setDataForTable(
            //     this.state.selectedVarieties,
            //     this.state.selectedBranch,
            //     this.state.selectedHubs,
            //     this.state.selectedClaimType,
            //     this.state.selectedDivisions,
            //     this.state.startDate,
            //     this.state.endDate
            //   );
            // }}
          >
            {this.props.decisionVarieties !== null &&
              this.props.decisionVarieties.length &&
              this.props.decisionVarieties.map((item, idx) => (
                <Option
                  value={varietyIds ? varietyIds[idx] : idx}
                  key={varietyIds ? varietyIds[idx] : idx}
                >
                  {item.variety_name}
                </Option>
              ))}
          </Select>
        </div>
        {/* {
          <>
            <Button
              onClick={() => {
                const fileHeaders = {
                  Authorization:
                    "Bearer " + localStorage.getItem("accessToken"),
                };
                API.get(
                  `/buying_decision_download?draft=1&branch_id=${this.props.selectedBranchID}`,
                  { headers: fileHeaders }
                )
                  .then((fileresponse) => {
                    console.log(
                      "FILE RESPONSE :",
                      `./${fileresponse.data.data}`
                    );
                    if (fileresponse.data.status) {
                      //#region file download
                      Axios({
                        url: `./${fileresponse.data.data}`,
                        method: "GET",
                       responseType: "blob",
                      })
                        .then((response) => {
                          console.log(response.data.status);

                          FileDownLoad(
                            response.data,
                            `${fileresponse.data.data}`
                          );
                        })
                        .catch((err) => {
                          message.error("File cannot be downloaded");
                          console.log(
                            "Cannot download file ERROR : ",
                            err.response
                          );
                        });
                      //#endregion
                    } else {
                      console.log(
                        "File has no data, status returned is false."
                      );
                      message.warning("File has no data");
                    }
                  })
                  .catch((err) => {
                    message.error("Error downloading file");
                    console.log("Error getting file, ERROR :", err.response);
                  });
              }}
              type="primary"
              shape="round"
              icon="download"
              size="large"
              // style={{ marginRight: "5px", marginBottom: "5px", display: "block" }}
              style={{ marginRight: "5px" }}
            >
              <div>
                <div style={{ marginTop: "-7px" }}>Draft</div>
                <div
                  style={{
                    fontSize: "11px",
                    lineHeight: "7px",
                    color: "#b4c8dc",
                  }}
                >
                  .xlsx report
                </div>
              </div>
            </Button>

            <Button
              onClick={() => {
                const fileHeaders = {
                  Authorization:
                    "Bearer " + localStorage.getItem("accessToken"),
                };
                API.get(
                  `/buying_decision_download?branch_id=${this.props.selectedBranchID}`,
                  { headers: fileHeaders }
                )
                  .then((fileresponse) => {
                    console.log(
                      "FILE RESPONSE :",
                      `./${fileresponse.data.data}`
                    );
                    if (fileresponse.data.status) {
                      //#region file download
                      Axios({
                        url: `./${fileresponse.data.data}`,
                        method: "GET",
                       responseType: "blob",
                      })
                        .then((response) => {
                          console.log(response.data.status);

                          FileDownLoad(
                            response.data,
                            `${fileresponse.data.data}`
                          );
                        })
                        .catch((err) => {
                          message.error("File cannot be downloaded");
                          console.log(
                            "Cannot download file ERROR : ",
                            err.response
                          );
                        });
                      //#endregion
                    } else {
                      console.log(
                        "File has no data, status returned is false."
                      );
                      message.warning("File has no data");
                    }
                  })
                  .catch((err) => {
                    message.error("Error downloading file");
                    console.log("Error getting file, ERROR :", err.response);
                  });
              }}
              type="primary"
              shape="round"
              icon="download"
              size="large"
            // style={{ display: "block" }}
            >
              <div>
                <div style={{ marginTop: "-7px" }}>Send to SAP</div>
                <div
                  style={{
                    fontSize: "11px",
                    lineHeight: "7px",
                    color: "#b4c8dc",
                  }}
                >
                  .xlsx report
                  </div>
              </div>
            </Button>
          </>
        } */}
        <Checkbox
          // checked={false}
          className="approval-checkbox"
          onChange={(e) => console.log(e.target.checked)}
        >
          Show Approval Awaited Only
        </Checkbox>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
  availableHubs: selectAvailableHubs,
  hubTableData: selectHubTableData,
  decisionBranches: selectDecisionBranches,
  decisionRegions: selectDecisionRegions,
  decisionVarieties: selectDecisionVarieties,
  selectedBranchID: selectSelectedBranchID,
  selectedRegionID: selectSelectedRegionID,
  selectedVarietyID: selectSelectedVarietyID,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setRollUp: (mandiData, FORData, farmerData) =>
      dispatch(setRollUp(mandiData, FORData, farmerData)),
    setAvailableHubs: (hubs) => dispatch(setAvailableHubs(hubs)),
    setAvailableMandis: (mandis) => dispatch(setAvailableMandis(mandis)),

    setHubTableData: (data) => dispatch(setHubTableData(data)),
    setDecisionBranches: (data) => dispatch(setDecisionBranches(data)),
    setDecisionRegions: (data) => dispatch(setDecisionRegions(data)),
    setDecisionVarieties: (data) => dispatch(setDecisionVarieties(data)),
    setSelectedBranchID: (id) => dispatch(setSelectedBranchID(id)),
    setSelectedRegionID: (id) => dispatch(setSelectedRegionID(id)),
    setSelectedVarietyID: (id) => dispatch(setSelectedVarietyID(id)),
    setDualRender: (value) => dispatch(setDualRender(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChooseHub);
