import React from "react";
import "./mandi-master.styles.css";
import { Component } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUserData ,selectMSMessageRBAC} from "../../redux/user/user.selectors";
import { canUserEdit,getUserMSRBACMessage } from "../../auth/utils";
import { FeatureCodes } from "../../auth/data";
import {
  Table,
  Spin,
  Input,
  Select,
  Button,
  message,
  Form,
  Modal,
  DatePicker,
  Radio,
} from "antd";
import moment from "moment";
import CustomDropdownChooseRegion from "../../components/custom-dropdown-choose-region/custom-dropdown-choose-region.component";

import API from "../../api";

const { Search } = Input;
const { Option } = Select;
/**
 * @description Component for user management tab
 *
 * @component
 */
class MandiMaster extends Component {
  state = {
    isModalVisible: false,
    isAdd: false,
    currentRecord: null,
    userBranches: null,
    searchText: null,
    searchUserType: 0,
    mandiTableData: [],
    primaryHubList: [],
    hubRegionList: [],
    mandiRegionList: [],
    mandiBranchList: [],
    isSpinnerOnLoad: false,
    isSubmitted: false,
    newData: {
      End_Effective_Date: null,
      Mandi_Min_Buying_MT: null,
      Start_Effective_Date: null,
      Surplus_Factor: null,
      branch_id: null,
      branch_name: "",
      branch_sap_code: "",
      hub_id: null,
      hub_latitude: null,
      hub_longitude: null,
      hub_name: "",
      hub_region_id: null,
      hub_sap_code: "",
      is_active: true,
      is_main_mandi: true,
      is_priority_mandi: true,
      mandi_latitude: null,
      mandi_longitude: null,
      mandi_name: "",
      mandi_sap_code: "",
      region_id: null,
      region_name: "",
      state_name: "",
    },
    rowData: undefined,
    selectedRegionValue: undefined,
    selectedRegionName: "",
    selectedBranchValue: undefined,
    selectedBranchname: "",
    selectedBranchList: [],
    selectedRegionList: [],
    recordCount: 0,
  };
  componentDidMount() {
    this.setState({ isSpinnerOnLoad: true });
    this.getPrimaryHub();
  }

  getMasterMandi = async () => {
    this.setState({ isSpinnerOnLoad: true });

    const params = {
      region_id:
        this.state.selectedRegionValue === null
          ? null
          : [this.state.selectedRegionValue],
    };

    await API.get("/master_mandi", { params: params })
      .then((varietyResponse) => {

        let modifiedData = varietyResponse.data.data.map((item, index) => {
          return {
            ...item,
            isEdit: false,
            key: index,
          };
        });
        this.setState(
          {
            mandiTableData: modifiedData,
          },
          () => this.setState({ isSpinnerOnLoad: false, isAdd: true })
        );
        // } else {
        //   this.setState({
        //     mandiTableData: varietyResponse.data.data,
        //   }, () => this.setState({ isSpinnerOnLoad: false, isAdd: true })
        //   );
        // }
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };

  getPrimaryHub = async () => {
    this.setState({ isSpinnerOnLoad: true });


    await API.get("/list_hubs")
      .then((hubList) => {

        let modifiedData = [...hubList.data.data];
        modifiedData.push({ territory_id: null, territory_name: "All" });

        this.getMandiRegion();
        this.setState({
          selectedBranchList: modifiedData,
          primaryHubList: hubList.data.data,
          selectedBranchValue: hubList.data.data[0].territory_id,
          selectedBranchName: hubList.data.data[0].territory_name,
        });

      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };

  getMandiRegion = async () => {
    this.setState({ isSpinnerOnLoad: true });


    await API.get("/list_region")
      .then((mandiRegion) => {
        let modifiedData = [...mandiRegion.data.data];
        modifiedData.push({ region_id: null, region_name: "All" });

        this.setState({
          selectedRegionValue: mandiRegion.data.data[0].region_id,
          selectedRegionName: mandiRegion.data.data[0].region_name,
        });
        this.setState(
          {
            selectedRegionList: modifiedData,
            mandiRegionList: mandiRegion.data.data,
          },
          () => this.getMasterMandi()
        );
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };

  getHubRegion = async () => {
    this.setState({ isSpinnerOnLoad: true });


    await API.get("/list_mandi")
      .then((hubRegion) => {

        this.setState(
          {
            hubRegionList: hubRegion.data.data,
          },
          () => this.setState({ isSpinnerOnLoad: false })
        );
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };

  getMandiBranch = async () => {
    this.setState({ isSpinnerOnLoad: true });


    await API.get("/list_branch")
      .then((mandiBranch) => {
        this.setState(
          {
            mandiBranchList: mandiBranch.data.data,
          },
          () => this.setState({ isSpinnerOnLoad: false })
        );
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };

  setTableData = () => {
    if (
      this.state.mandiTableData !== undefined ||
      this.state.mandiTableData.length >= 0
    ) {
      let userTableData = this.state.mandiTableData.map((user, index) => {
        return {
          key: user.key,
          region_id: user.region_id,
          mandi_name: user.mandi_name,
          Start_Effective_Date: user.Start_Effective_Date,
          Surplus_Factor: user.Surplus_Factor,
          is_active: user.is_active,
          is_main_mandi: user.is_main_mandi,
          is_priority_mandi: user.is_priority_mandi,
          main_mandi: user.main_mandi,
          mandi_latitude: user.mandi_latitude,
          mandi_longitude: user.mandi_longitude,
          mandi_sap_code: user.mandi_sap_code,
          End_Effective_Date: user.End_Effective_Date,
          Mandi_Min_Buying_MT: user.Mandi_Min_Buying_MT,
          Mandi_State: user.Mandi_State,
          branch_id: user.branch_id,
          branch_name: user.branch_name,
          branch_sap_code: user.branch_sap_code,
          hub_id: user.hub_id,
          hub_latitude: user.hub_latitude,
          hub_longitude: user.hub_longitude,
          hub_name: user.hub_name,
          hub_region: user.hub_region,
          hub_region_id: user.hub_region_id,
          hub_sap_code: user.hub_sap_code,
          mandi_id: user.mandi_id,
          region_name: user.region_name,
          state_name: user.state_name,
          isEdit: user.isEdit,
        };
      });

      let dataAfterSearch = userTableData;

      if (this.state.searchText) {
        dataAfterSearch = dataAfterSearch.filter(
          (item) =>
            (item.mandi_name !== null &&
              item.mandi_name
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase())) ||
            (item.region_name !== null &&
              item.region_name
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase())) ||
            (item.hub_name !== null &&
              item.hub_name
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase())) ||
            (item.branch_name !== null &&
              item.branch_name
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase()))
        );
      }

      return dataAfterSearch;
    } else {
      return this.state.mandiTableData;
    }
  };

  TableColumnHeader = (props) => {
    const { title, subtitle } = props;
    return (
      <div className="column-header-container">
        <div
          style={{
            color: "white",
            fontSize: "12px",
            whiteSpace: "nowrap",
            fontWeight: "normal",
            textAlign: "left",
          }}
        >
          {title}
        </div>
        <div
          style={{
            color: "#cbdfff",
            fontSize: "11px",
            fontWeight: "normal",
            textAlign: "left",
            // subtitle && title.length < subtitle.length ? "left" : "center"
          }}
        >
          {subtitle}
        </div>
      </div>
    );
  };

  createFilters = (label) => {
    let filterData = this.state.mandiTableData;
    //#region remove duplicate objects
    let uniqueFilterData = [];

    if (label === "is_active" && this.state.mandiTableData && this.state.mandiTableData.length > 0) {
      uniqueFilterData = [{ text: "Yes", value: 1 }, { text: "No", value: 0 }]
    }
    else {
      filterData.map((mainItem) =>
        uniqueFilterData.filter((item) => item[label] === mainItem[label])
          .length > 0
          ? null
          : uniqueFilterData.push(mainItem)
      );

      uniqueFilterData = uniqueFilterData.map((item) => {
        return {
          text: item[label],
          value: item[label],
        };

      }).filter((item) => item.text !== null && item.text !== undefined);

      uniqueFilterData.sort((a, b) => a.text.localeCompare(b.text))
    }

    return uniqueFilterData;
  };

  submitTableRowData = (row) => {
    if (this.validate(row)) {
      this.setState({ isSubmitted: true });

      this.setState({ isSpinnerOnLoad: true });

      let params = {
        mandi_id: row.mandi_id,
        mandi_name: row.mandi_name,
        mandi_latitude:
          row.mandi_latitude === "" || row.mandi_latitude === undefined
            ? null
            : row.mandi_latitude,
        mandi_longitude:
          row.mandi_longitude === "" || row.mandi_longitude === undefined
            ? null
            : row.mandi_longitude,
        is_main_mandi: row.is_main_mandi,
        is_priority_mandi: row.is_priority_mandi,
        is_active: row.is_active,
        mandi_sap_code: row.mandi_sap_code === "" ? null : row.mandi_sap_code,
        region_id: row.region_id,
        branch_id: row.branch_id,
        primary_hub_id: row.hub_id,
        hub_name: row.hub_name,
        main_mandi:
          row.main_mandi !== "" || row.main_mandi !== undefined
            ? row.main_mandi
            : null,
        Mandi_Min_Buying_MT:
          row.Mandi_Min_Buying_MT === "" ||
            row.Mandi_Min_Buying_MT === undefined
            ? null
            : row.Mandi_Min_Buying_MT,
        Surplus_Factor:
          row.Surplus_Factor === "" || row.Surplus_Factor === undefined
            ? null
            : row.Surplus_Factor,
        Start_Effective_Date:
          row.Start_Effective_Date === null ||
            row.Start_Effective_Date === undefined ||
            row.Start_Effective_Date === ""
            ? null
            : moment(row.Start_Effective_Date).format("YYYY-MM-DD"),
        End_Effective_Date:
          row.End_Effective_Date === null ||
            row.End_Effective_Date === undefined ||
            row.End_Effective_Date === ""
            ? null
            : moment(row.End_Effective_Date).format("YYYY-MM-DD"),
      };
      API.put("/master_mandi", params, {

      })
        .then((response) => {
          if (response.status === 200 && response.data.status) {
            message.success(response.data.message, 5);
            this.setModalVisibility(false);
            this.getMasterMandi();
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
          } else if (!response.data.status) {
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
            message.error(response.data.message, 5);
            // this.setModalVisibility(false);
          } else {
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
            message.error("Please try again", 5);
          }
        })
        .catch((err) => {
          if (err) {
            if (err.response && err.response.status === 400)
              message.error("Something went wrong. Please try again later.", 5);
            else {
              message.error(
                "Something went wrong.. Please try again later.",
                5
              );
            }
          } else {
            message.error("Something went wrong... Please try again later.", 5);
          }
          this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
          // this.setModalVisibility(false);
        });
    }
  };
  validate = (rowData) => {

    // if (rowData.Mandi_Min_Buying_MT === null || rowData.Mandi_Min_Buying_MT === undefined || rowData.Mandi_Min_Buying_MT === "") {
    //   message.error(`Please enter the Minimum buying price for ${rowData.mandi_name},${rowData.hub_name}, ${rowData.region_name} and ${rowData.hub_region_name} !`,5);
    //   return false;
    // }
    var alphaNumericRegex = /^[a-zA-Z0-9]*$/;
    var numberRegex = /^[0-9.]+$/;

    // if (!rowData.Mandi_Min_Buying_MT.match(numberRegex)) {
    //   message.error(`Please enter valid Minimum buying price for ${rowData.mandi_name},${rowData.hub_name}, ${rowData.region_name} and ${rowData.hub_region_name} !`, 5);
    //   return false;
    // }

    if (
      rowData.mandi_name === null ||
      rowData.mandi_name === undefined ||
      rowData.mandi_name === ""
    ) {
      message.error(`Please enter Mandi Name !`, 5);
      return false;
    } else if (rowData.Mandi_Min_Buying_MT < 0) {
      message.error(
        `Minimum buying price cannot be less than 0 for ${rowData.mandi_name},${rowData.hub_name}, ${rowData.region_name} and ${rowData.hub_region_name} !`,
        5
      );
      return false;
    } else if (
      rowData.hub_name === null ||
      rowData.hub_name === undefined ||
      rowData.hub_name === ""
    ) {
      message.error(`Please Select Primary Hub !`, 5);
      return false;
    } else if (
      rowData.region_name === null ||
      rowData.region_name === undefined ||
      rowData.region_name === ""
    ) {
      message.error(`Please Select Mandi region !`, 5);
      return false;
    }
    else if (rowData.mandi_sap_code === null || rowData.mandi_sap_code === undefined || rowData.mandi_sap_code === "") {
      message.error(`Please enter the SAP code`, 5);
      return false;
    }
    // else if (rowData.Surplus_Factor === null || rowData.Surplus_Factor === undefined || rowData.Surplus_Factor === "") {
    //   message.error(`Please enter the Surplus Factor for ${rowData.mandi_name},${rowData.hub_name}, ${rowData.region_name} and ${rowData.hub_region_name} !`,5);
    //   return false;
    // }

    // else if (!rowData.Surplus_Factor.match(numberRegex)) {
    //   message.error(`Please enter valid Surplus Factor for ${rowData.mandi_name},${rowData.hub_name}, ${rowData.region_name} and ${rowData.hub_region_name} !`, 5);
    //   return false;
    // }
    else if (rowData.Surplus_Factor < 0) {
      message.error(
        `Surplus Factor cannot be less than 0 for ${rowData.mandi_name},${rowData.hub_name}, ${rowData.region_name} and ${rowData.hub_region_name} !`,
        5
      );
      return false;
    }

    // else if (rowData.mandi_sap_code === null || rowData.mandi_sap_code === undefined || rowData.mandi_sap_code === "") {
    //   message.error(`Please enter the SAP code for ${rowData.mandi_name},${rowData.hub_name}, ${rowData.region_name} and ${rowData.hub_region_name} !`, 5);
    //   return false;
    // }

    // else if (!rowData.mandi_sap_code.match(alphaNumericRegex)) {
    //   message.error(`Please enter Alpha Numeric SAP code for ${rowData.mandi_name},${rowData.hub_name}, ${rowData.region_name} and ${rowData.hub_region_name} !`, 5);
    //   return false;
    // }

    // else if (!rowData.mandi_latitude.match(numberRegex)) {
    //   message.error(`Please enter valid Mandi Latitude for ${rowData.mandi_name},${rowData.hub_name}, ${rowData.region_name} and ${rowData.hub_region_name} !`, 5);
    //   return false;
    // }
    else if (rowData.mandi_latitude < 0) {
      message.error(
        `Mandi Latitude cannot be less than 0 for ${rowData.mandi_name},${rowData.hub_name}, ${rowData.region_name} and ${rowData.hub_region_name} !`,
        5
      );
      return false;
    }

    // else if (!rowData.mandi_longitude.match(numberRegex)) {
    //   message.error(`Please enter valid Mandi Longitude for ${rowData.mandi_name},${rowData.hub_name}, ${rowData.region_name} and ${rowData.hub_region_name} !`, 5);
    //   return false;
    // }
    else if (rowData.mandi_longitude < 0) {
      message.error(
        `Mandi Longitude cannot be less than 0 for ${rowData.mandi_name},${rowData.hub_name}, ${rowData.region_name} and ${rowData.hub_region_name} !`,
        5
      );
      return false;
    }

    // else if (
    //   rowData.revised_min_price >= rowData.revised_max_price
    // ) {
    //   message.error(
    //     `The maximum price must be greater than the minimum price for ${rowData.hub_name}, ${rowData.variety_name}, ${rowData.claim_name} and ${rowData.division_name} !`
    //   ,5);
    //   return false;
    // }
    return true;
  };

  setModalVisibility = (status) => {
    if (status) {
      const nData = {
        End_Effective_Date: null,
        Mandi_Min_Buying_MT: null,
        Start_Effective_Date: null,
        Surplus_Factor: null,
        branch_id: null,
        branch_name: "",
        branch_sap_code: "",
        hub_id: null,
        hub_latitude: null,
        hub_longitude: null,
        hub_name: "",
        hub_region: "",
        hub_region_id: null,
        hub_sap_code: "",
        is_active: true,
        is_main_mandi: true,
        is_priority_mandi: true,
        mandi_latitude: null,
        mandi_longitude: null,
        mandi_name: "",
        mandi_sap_code: "",
        region_id: null,
        region_name: "",
        state_name: "",
        isEdit: false,
      };

      this.setState({ newData: nData });
    }

    this.setState({ isModalVisible: status });
  };

  handleDropdownChange = (value, row, target, index, optionlabel, isPopup) => {

    const specificHub = this.state.primaryHubList.filter((item) => {
      if (item.territory_id === value) {
        return item;
      }
    });

    let a;

    if (isPopup === false) {

      a = this.state.mandiTableData;

      if (target === "is_active") {
        a[row.key].is_active = Boolean(value);
      } else if (target === "is_priority_mandi") {
        a[row.key].is_priority_mandi = Boolean(value);
      } else if (target === "is_main_mandi") {
        a[row.key].is_main_mandi = Boolean(value);
      } else if (target === "hub_name") {
        a[row.key].hub_name = specificHub[0].territory_name;
        a[row.key].hub_id = specificHub[0].territory_id;
        a[row.key].hub_region = specificHub[0].hub_region_name;
        a[row.key].hub_region_id = specificHub[0].hub_region_id;
        a[row.key].branch_name = specificHub[0].parent_name;
        a[row.key].branch_id = specificHub[0].parent_id;
      } else if (target === "region_name") {

        a[row.key].region_name = optionlabel;
        a[row.key].region_id = value;
        row.region_name = optionlabel;
        row.region_id = value;
      }
      // else if (target === "branch_name") {
      //   a[row.key].branch_name = optionlabel;
      //   a[row.key].branch_id = value;
      // }

      this.setState({ mandiTableData: a });
    } else {
      a = this.state.newData;

      if (target === "is_active") {
        a.is_active = Boolean(value);
      } else if (target === "is_priority_mandi") {
        a.is_priority_mandi = Boolean(value);
      } else if (target === "is_main_mandi") {
        a.is_main_mandi = Boolean(value);
      } else if (target === "hub_name") {
        a.hub_name = specificHub[0].territory_name;
        a.hub_id = specificHub[0].territory_id;
        a.hub_region = specificHub[0].hub_region_name;
        a.hub_region_id = specificHub[0].hub_region_id;
        a.branch_name = specificHub[0].parent_name;
        a.branch_id = specificHub[0].parent_id;
      } else if (target === "region_name") {
        a.region_name = optionlabel;
        a.region_id = value;
      }
      // else if (target === "branch_name") {
      //   a.branch_name = optionlabel;
      //   a.branch_id = value;
      // }
      this.setState({ newData: a });
    }
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  handleChange = (e, row, index, name, isPopup) => {
    // let name = name;


    let a;
    var alphaNumericRegex = /^[a-zA-Z0-9]*$/;
    var numberRegex = /^[0-9.]+$/;
    if (isPopup === false) {

      a = this.state.mandiTableData;

      switch (name) {
        case "mandi_name":
          a[row.key].mandi_name = e.target.value;
          this.setState({ mandiTableData: a });
          return;
        case "Mandi_Min_Buying_MT":
          if (e.target.value === "") {
            a[row.key].Mandi_Min_Buying_MT = "";
          } else if (e.target.value.match(numberRegex)) {
            a[row.key].Mandi_Min_Buying_MT = e.target.value;
          }

          this.setState({ mandiTableData: a });
          return;
        case "Surplus_Factor":
          if (e.target.value === "") {
            a[row.key].Surplus_Factor = "";
          } else if (e.target.value.match(numberRegex)) {
            a[row.key].Surplus_Factor = e.target.value;
          }

          this.setState({ mandiTableData: a });
          return;
        case "mandi_sap_code":
          if (e.target.value === "") {
            a[row.key].mandi_sap_code = "";
          } else if (e.target.value.match(alphaNumericRegex)) {
            a[row.key].mandi_sap_code = e.target.value.toUpperCase();
          }

          this.setState({ mandiTableData: a });
          return;
        case "mandi_latitude":
          if (e.target.value === "") {
            a[row.key].mandi_latitude = "";
          } else if (e.target.value.match(numberRegex)) {
            a[row.key].mandi_latitude = e.target.value;
          }

          this.setState({ mandiTableData: a });
          return;
        case "mandi_longitude":
          if (e.target.value === "") {
            a[row.key].mandi_longitude = "";
          } else if (e.target.value.match(numberRegex)) {
            a[row.key].mandi_longitude = e.target.value;
          }

          this.setState({ mandiTableData: a });
          return;
        case "Start_Effective_Date":
          if (
            !moment(e, "YYYY-MM-DD").isSameOrBefore(
              moment(a[row.key].End_Effective_Date)
            ) &&
            e !== ""
          ) {
            a[row.key].Start_Effective_Date = e;
            a[row.key].End_Effective_Date = e;
          } else {
            a[row.key].Start_Effective_Date = undefined;
          }

          this.setState({ mandiTableData: a });
          return;
        case "End_Effective_Date":
          if (e === "") {
            a[row.key].End_Effective_Date = undefined;
          } else {
            a[row.key].End_Effective_Date = e;
          }

          this.setState({ mandiTableData: a });
          return;
        default:
          return "";
      }

      // if (name === "mandi_name") {
      //   a[row.key].mandi_name = e.target.value;
      // }
      // else if (name === "Mandi_Min_Buying_MT") {
      //   if (e.target.value === "") {
      //     a[row.key].Mandi_Min_Buying_MT = "";
      //   }
      //   else if (e.target.value.match(numberRegex)) {
      //     a[row.key].Mandi_Min_Buying_MT = e.target.value;
      //   }
      // }
      // else if (name === "Surplus_Factor") {
      //   if (e.target.value === "") {
      //     a[row.key].Surplus_Factor = "";
      //   }
      //   else if (e.target.value.match(numberRegex)) {
      //     a[row.key].Surplus_Factor = e.target.value;
      //   }
      // }
      // else if (name === "mandi_sap_code") {
      //   if (e.target.value === "") {
      //     a[row.key].mandi_sap_code = "";
      //   }
      //   else if (e.target.value.match(alphaNumericRegex)) {
      //     a[row.key].mandi_sap_code = e.target.value.toUpperCase();
      //   }
      // }
      // else if (name === "mandi_latitude") {
      //   if (e.target.value === "") {
      //     a[row.key].mandi_latitude = "";
      //   }
      //   else if (e.target.value.match(numberRegex)) {
      //     a[row.key].mandi_latitude = e.target.value;
      //   }
      // }
      // else if (name === "mandi_longitude") {
      //   if (e.target.value === "") {
      //     a[row.key].mandi_longitude = "";
      //   }
      //   else if (e.target.value.match(numberRegex)) {
      //     a[row.key].mandi_longitude = e.target.value;
      //   }
      // }
      // else if (name === "Start_Effective_Date") {
      //   if (!moment(e, "YYYY-MM-DD").isSameOrBefore(moment(a[row.key].End_Effective_Date)) && e !== "") {
      //     a[row.key].Start_Effective_Date = e;
      //     a[row.key].End_Effective_Date = e;
      //   }
      //   else {
      //     a[row.key].Start_Effective_Date = undefined;
      //   }
      // }
      // else if (name === "End_Effective_Date") {
      //   if (e === "") {
      //     a[row.key].End_Effective_Date = undefined;
      //   }
      //   else {
      //     a[row.key].End_Effective_Date = e;
      //   }
      // }
      // this.setState({ mandiTableData: a });
    } else {
      a = this.state.newData;

      // if (name === "mandi_name") {
      //   a.mandi_name = e.target.value;
      // }
      // else if (name === "Mandi_Min_Buying_MT") {
      //   if (e.target.value === "") {
      //     a.Mandi_Min_Buying_MT = "";
      //   }
      //   else if (e.target.value.match(numberRegex)) {
      //     a.Mandi_Min_Buying_MT = e.target.value;
      //   }
      // }
      // else if (name === "Surplus_Factor") {
      //   if (e.target.value === "") {
      //     a.Surplus_Factor = "";
      //   }
      //   else if (e.target.value.match(numberRegex)) {
      //     a.Surplus_Factor = e.target.value;
      //   }
      // }
      // else if (name === "mandi_sap_code") {
      //   if (e.target.value === "") {
      //     a.mandi_sap_code = "";
      //   }
      //   else if (e.target.value.match(alphaNumericRegex)) {
      //     a.mandi_sap_code = e.target.value;
      //   }
      // }
      // else if (name === "mandi_latitude") {
      //   if (e.target.value === "") {
      //     a.mandi_latitude = "";
      //   }
      //   else if (e.target.value.match(numberRegex)) {
      //     a.mandi_latitude = e.target.value;
      //   }
      // }
      // else if (name === "mandi_longitude") {
      //   if (e.target.value === "") {
      //     a.mandi_longitude = "";
      //   }
      //   else if (e.target.value.match(numberRegex)) {
      //     a.mandi_longitude = e.target.value;
      //   }
      // }
      // else if (name === "Start_Effective_Date") {
      //   if (!moment(e, "YYYY-MM-DD").isSameOrBefore(moment(a.End_Effective_Date))) {
      //     a.Start_Effective_Date = e;
      //     a.End_Effective_Date = e;
      //   }
      //   else {
      //     a.Start_Effective_Date = e;
      //   }
      // }
      // else if (name === "End_Effective_Date") {
      //   // if (e.target.value === "") {
      //   //   a.mandi_longitude = "";
      //   // }
      //   // else if (e.target.value.match(numberRegex)) {
      //   a.End_Effective_Date = e;
      //   // }
      // }

      switch (name) {
        case "mandi_name":
          a.mandi_name = e.target.value;
          this.setState({ newData: a });
          return;
        case "Mandi_Min_Buying_MT":
          if (e.target.value === "") {
            a.Mandi_Min_Buying_MT = "";
          } else if (e.target.value.match(numberRegex)) {
            a.Mandi_Min_Buying_MT = e.target.value;
          }

          this.setState({ newData: a });
          return;
        case "Surplus_Factor":
          if (e.target.value === "") {
            a.Surplus_Factor = "";
          } else if (e.target.value.match(numberRegex)) {
            a.Surplus_Factor = e.target.value;
          }

          this.setState({ newData: a });
          return;
        case "mandi_sap_code":
          if (e.target.value === "") {
            a.mandi_sap_code = "";
          } else if (e.target.value.match(alphaNumericRegex)) {
            a.mandi_sap_code = e.target.value.toUpperCase();
          }

          this.setState({ newData: a });
          return;
        case "mandi_latitude":
          if (e.target.value === "") {
            a.mandi_latitude = "";
          } else if (e.target.value.match(numberRegex)) {
            a.mandi_latitude = e.target.value;
          }

          this.setState({ newData: a });
          return;
        case "mandi_longitude":
          if (e.target.value === "") {
            a.mandi_longitude = "";
          } else if (e.target.value.match(numberRegex)) {
            a.mandi_longitude = e.target.value;
          }

          this.setState({ newData: a });
          return;
        case "Start_Effective_Date":
          if (
            !moment(e, "YYYY-MM-DD").isSameOrBefore(
              moment(a[row.key].End_Effective_Date)
            ) &&
            e !== ""
          ) {
            a.Start_Effective_Date = e;
            a.End_Effective_Date = e;
          } else {
            a.Start_Effective_Date = undefined;
          }

          this.setState({ newData: a });
          return;
        case "End_Effective_Date":
          if (e === "") {
            a.End_Effective_Date = undefined;
          } else {
            a.End_Effective_Date = e;
          }

          this.setState({ newData: a });
          return;
        default:
          return "";
      }
      // this.setState({ newData: a });
    }
  };

  disabledDate = (current, rowData, text) => {
    // Can not select days before today and today
    // return current && current > moment().endOf("day");
    // if (text !== "new")
    return (
      current && current > moment(rowData.Start_Effective_Date, "YYYY-MM-DD")
    );
    // else
    //   return current && current > newData.Start_Effective_Date;
  };

  handleEditClick = (rowData, index) => {
    // let temp = this.state.mandiTableData;
    // temp[rowData.key].isEdit = true;
// if(this.canUserEdit(rowData,rowData.branch_id))
// {
    let tempData = this.state.mandiTableData.map((item) => {
      if (item.mandi_id === rowData.mandi_id) {
        return {
          ...item,
          isEdit: true
        }
      } else {
        return {
          ...item,
          isEdit: false
        };
      }
    })
    this.setState({ mandiTableData: tempData });
    // this.setState({ mandiTableData: temp });
    this.setState({ rowData: rowData });
  // }
  // else{
  //   message.info("User can edit only their respective branches",3);
  // }
  };

  handleCancelClick = (rowData, index) => {
    let tempData = this.state.mandiTableData.map((item) => {
      if (item.mandi_id === rowData.mandi_id) {
        return this.state.rowData;
      } else {
        return item;
      }
    });
    this.setState({ mandiTableData: tempData });
  };

  //  canUserEdit1 = (user, branchID) => {
  //    console.log("user data , ", this.props.userData, this.props.userData.userTerritories,
  //    this.props.userData.userTerritories.includes(parseInt(branchID)));
  //   //  var territories =  this.props.userData.userTerritories.split(",").map((item) => parseInt(item));
  //   //  console.log("user data 111122233 ",territories);
  //   if (this.props.userData.userTerritories.includes(parseInt(branchID))) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  // canEnableEditOption = ()=>{
  //    if(canUserEdit(this.props.userData,this.props.selectedBranchID,FeatureCodes.MS_MANDI_EDIT)){
  //      return true;
  //    }
  //    else{
  //      return false;
  //    }
  // };

  render() {
    const handleBranchSelect = (value, props) => {
      this.setState(
        { selectedBranchName: props.key, selectedBranchValue: value },
        () => this.getMasterMandi()
      );
    };

    const handleRegionSelect = (value, props) => {
      this.setState(
        { selectedRegionName: props.key, selectedRegionValue: value },
        () => this.getMasterMandi()
      );
    };

    const userTableProps = {
      pagination: false,
      size: "default",
      bordered: true,
      scroll: { x: 200, y: window.innerHeight - 250 },
    };

    return (
      <div
        className="mandi-master-container"
        style={{ height: window.innerHeight - 85, marginTop: "-45px" }}
      >
        <div className="top-container">
          <div className="hub-region-filter">
            {/*} <span
              style={{
                fontSize: "15px",
                marginTop: "5px",
                fontWeight: "600",
              }}
            >
              Hub&nbsp;
            </span>{" "}
            <Select
              disabled={!this.state.isAdd}
              showSearch
              optionFilterProp="children"
              className={"select-master-region"}
              mode="single"
              placeholder="Select Hub"
              value={this.state.selectedBranchValue}
              onChange={(value, props) => handleBranchSelect(value, props)}
            >
              {this.state.selectedBranchList !== null &&
                this.state.selectedBranchList.length &&
                this.state.selectedBranchList.map((item, idx) => (
                  <Option value={item.territory_id} key={item.territory_name}>
                    {item.territory_name}
                  </Option>
                ))}
                </Select>
            {/*<CustomDropdownChooseRegion
              key="1000"
              defaultValue={this.state.selectedBranchname}
              selectType="branch"
              handleSelect={handleBranchSelect}
              placeholder={
                this.state.primaryHubList.length !== 0 &&
                this.state.primaryHubList.filter(
                  (item) => item.territory_id == this.state.selectedBranchValue
                ).length !== 0
                  ? this.state.primaryHubList.filter(
                      (item) =>
                        item.territory_id == this.state.selectedBranchValue
                    )[0].territory_name
                  : "Select Hub"
              }
              list={this.state.primaryHubList}
            /> */}
            <span
              style={{
                fontSize: "15px",
                marginTop: "5px",
                fontWeight: "600",
                marginLeft: "50px",
              }}
            >
              Region&nbsp;
            </span>{" "}
            <Select
              disabled={!this.state.isAdd}
              showSearch
              optionFilterProp="children"
              className={"select-master-region"}
              mode="single"
              placeholder="Select Region"
              value={this.state.selectedRegionValue}
              onChange={(value, props) => handleRegionSelect(value, props)}
            >
              {this.state.selectedRegionList !== null &&
                this.state.selectedRegionList.length &&
                this.state.selectedRegionList.map((item, idx) => (
                  <Option value={item.region_id} key={item.region_name}>
                    {item.region_name}
                  </Option>
                ))}
            </Select>
            {/* <CustomDropdownChooseRegion
              key="1000"
              defaultValue={this.state.selectedRegionName}
              selectType="region"
              handleSelect={handleRegionSelect}
              placeholder={
                this.state.primaryHubList.length !== 0 &&
                this.state.mandiRegionList.filter(
                  (item) => item.region_id == this.state.selectedRegionValue
                ).length !== 0
                  ? this.state.mandiRegionList.filter(
                      (item) => item.region_id == this.state.selectedRegionValue
                    )[0].region_name
                  : "Select Region"
              }
              list={this.state.mandiRegionList}
            />*/}
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row-reverse",
              placeItems: "flex-end",
              marginRight: "25px",
              marginBottom: "10px",
            }}
          >
            <Search
              disabled={!this.state.isAdd}
              placeholder="Search"
              allowClear
              onChange={(e) => this.setState({ searchText: e.target.value })}
              style={{ width: "45%" }}
            />
            <Button
              className="add-master-button"
              style={{
                height: "25px",
                width: "70px",
                backgroundColor: "#0b133d",
                color: "white",
              }}
              disabled={!canUserEdit(
                this.props.userData,
                this.selectedBranchValue,
                FeatureCodes.MS_MANDI_EDIT
              ) || !this.state.isAdd}
              onClick={() => this.setModalVisibility(true)}
            >
              &#10010; Add
            </Button>
          </div>
        </div>

        <div>
        <div
        style={{
          marginLeft: "25px",
          marginTop: "-8px",
          fontSize: "14px",
          color: "red",
          display: "flex",
          float: "left",
        }}
      > {getUserMSRBACMessage(this.props.messageAccess,FeatureCodes.MS_MANDI_EDIT)}
      </div>
        <div
          style={{
            marginRight: "20px",
            marginTop: "-8px",
            fontSize: "15px",
            color: "#0B133D",
            display: "flex",
            float: "right",
          }}
        >
          Total number of records:&nbsp;
          <b>{this.state.mandiTableData && this.setTableData().length}</b>
        </div>
        </div>

        <Spin
          spinning={this.state.isSpinnerOnLoad}
          size={"large"}
          tip="Loading..."
          style={{ alignSelf: "center", zIndex: 9999, marginTop: "20px" }}
        >
          <Modal
            className="add-master-modal"
            title={"Add Mandi"}
            footer={false}
            // style={{
            //   textAlign: "center",
            //   height: "400px",
            // }}
            // visible={true}
            visible={this.state.isModalVisible}
            destroyOnClose={true}
            onCancel={this.handleCancel}
          >
            <div className="master-form-container">
              <Form
                layout="horizontal"
                //   onValuesChange={onFormLayoutChange}
                size={"small"}
              // onSubmit={submitTableRowData(this.state.newData)}
              >
                <div>
                  <div
                    className="checkbox-group1"
                    style={{
                      display: "inline-block",
                      width: "30%",
                      marginRight: 7,
                    }}
                  >
                    <Form.Item label="Mandi Name">
                      <Input
                        style={{ marginRight: "2px" }}
                        maxLength={100}
                        required
                        value={this.state.newData.mandi_name}
                        name="mandi_name"
                        onChange={(e) =>
                          this.handleChange(
                            e,
                            this.state.newData,
                            -0,
                            "mandi_name",
                            true
                          )
                        }
                      />
                    </Form.Item>
                  </div>

                  <div
                    className="form-top-container"
                    style={{
                      display: "inline-block",
                      width: "33%",
                      marginRight: 7,
                    }}
                  >
                    <Form.Item label="Select Primary Hub">
                      <Select
                        // className="remarks-select"
                        showSearch
                        optionFilterProp="children"
                        name="hub_name"
                        required
                        value={this.state.newData.hub_name}
                        onSelect={(value, option) =>
                          this.handleDropdownChange(
                            value,
                            this.state.newData,
                            "hub_name",
                            -0,
                            option.props.children,
                            true
                          )
                        }
                      >
                        {this.state.primaryHubList &&
                          this.state.primaryHubList.length &&
                          this.state.primaryHubList.map((hubItem, idx) => (
                            <Option
                              key={hubItem.territory_id}
                              value={hubItem.territory_id}
                            >
                              {hubItem.territory_name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </div>
                  <div
                    className="checkbox-group1"
                    style={{ display: "inline-block", width: "33%" }}
                  >
                    <Form.Item label="Select Mandi Region">
                      <Select
                        // className="remarks-select"
                        showSearch
                        optionFilterProp="children"
                        name="region_name"
                        required
                        value={this.state.newData.region_name}
                        onSelect={(value, option) =>
                          this.handleDropdownChange(
                            value,
                            this.state.newData,
                            "region_name",
                            -0,
                            option.props.children,
                            true
                          )
                        }
                      >
                        {this.state.mandiRegionList &&
                          this.state.mandiRegionList.length &&
                          this.state.mandiRegionList.map(
                            (mandiRegionItem, idx) => (
                              <Option
                                key={mandiRegionItem.region_id}
                                value={mandiRegionItem.region_id}
                              >
                                {mandiRegionItem.region_name}
                              </Option>
                            )
                          )}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                {this.state.newData.hub_region !== "" &&
                  this.state.newData.branch_name !== "" ? (
                  <div>
                    <div
                      className="left"
                      style={{
                        display: "inline-block",
                        marginRight: 10,
                        width: "45%",
                      }}
                    >
                      <span style={{ color: "black" }}>Hub Region:</span>{" "}
                      <b>{this.state.newData.hub_region}</b>
                    </div>
                    <div
                      className="right"
                      style={{ display: "inline-block", width: "45%" }}
                    >
                      <span style={{ color: "black" }}>Hub Branch:</span>{" "}
                      <b>{this.state.newData.branch_name}</b>
                    </div>
                  </div>
                ) : null}
                <hr />
                <div>
                  <div
                    className="checkbox-group1"
                    style={{
                      display: "inline-block",
                      width: "30%",
                      marginRight: 10,
                    }}
                  >
                    <Form.Item label="Minimum Buying (MT)">
                      <Input
                        style={{ marginRight: "2px" }}
                        maxLength={100}
                        value={this.state.newData.Mandi_Min_Buying_MT}
                        name="Mandi_Min_Buying_MT"
                        onChange={(e) =>
                          this.handleChange(
                            e,
                            this.state.newData,
                            -0,
                            "Mandi_Min_Buying_MT"
                          )
                        }
                      />
                    </Form.Item>
                  </div>

                  <div
                    className="checkbox-group1"
                    style={{
                      display: "inline-block",
                      width: "30%",
                      marginRight: 10,
                    }}
                  >
                    <Form.Item label="Surplus Factor">
                      <Input
                        style={{ marginRight: "2px" }}
                        maxLength={100}
                        value={this.state.newData.Surplus_Factor}
                        name="Surplus_Factor"
                        onChange={(e) =>
                          this.handleChange(
                            e,
                            this.state.newData,
                            -0,
                            "Surplus_Factor",
                            true
                          )
                        }
                      />
                    </Form.Item>
                  </div>

                  <div
                    className="checkbox-group1"
                    style={{ display: "inline-block", width: "30%" }}
                  >
                    <Form.Item label="SAP Code">
                      <Input
                        style={{ marginRight: "2px" }}
                        maxLength={100}
                        value={this.state.newData.mandi_sap_code}
                        name="mandi_sap_code"
                        onChange={(e) =>
                          this.handleChange(
                            e,
                            this.state.newData,
                            -0,
                            "mandi_sap_code",
                            true
                          )
                        }
                      />
                    </Form.Item>
                  </div>
                </div>
                <div>
                  <div
                    className="checkbox-group1"
                    style={{ display: "inline-block", marginRight: 40 }}
                  >
                    <Form.Item
                      label="Mandi Latitude"
                      style={{ display: "inline-block", marginRight: 10 }}
                    >
                      <Input
                        style={{ marginRight: "2px" }}
                        maxLength={100}
                        value={this.state.newData.mandi_latitude}
                        name="mandi_latitude"
                        onChange={(e) =>
                          this.handleChange(
                            e,
                            this.state.newData,
                            -0,
                            "mandi_latitude",
                            true
                          )
                        }
                      />
                    </Form.Item>
                  </div>

                  <div
                    className="checkbox-group1"
                    style={{ display: "inline-block" }}
                  >
                    <Form.Item
                      label="Mandi Longitude"
                      style={{ display: "inline-block" }}
                    >
                      <Input
                        style={{ marginRight: "2px" }}
                        maxLength={100}
                        value={this.state.newData.mandi_longitude}
                        name="mandi_longitude"
                        onChange={(e) =>
                          this.handleChange(
                            e,
                            this.state.newData,
                            -0,
                            "mandi_longitude",
                            true
                          )
                        }
                      />
                    </Form.Item>
                  </div>
                </div>
                {/*<div>
                //   <div className="checkbox-group1" style={{ display: "inline-block", marginRight: 10 }}>
                    Start Effective Date : <DatePicker
                //       name="Start_Effective_Date"
                //       allowClear={true}
                //       // disabled={
                //       //   !canEnableTextBox("1", statutoryDetails.registered_VAT)
                //       // }
                //       format="YYYY-MM-DD"
                //       placeholder="Start Date"
                //       defaultValue={this.state.newData.Start_Effective_Date === null || this.state.newData.Start_Effective_Date === undefined ? undefined : moment(this.state.newData.Start_Effective_Date, "YYYY-MM-DD")}
                //       // value={
                //       //   this.state.newData.Start_Effective_Date !== null || this.state.newData.Start_Effective_Date !== "" || this.state.newData.Start_Effective_Date !== undefined
                //       //     ? moment(this.state.newData.Start_Effective_Date, "YYYY-MM-DD")
                //       //     : undefined
                //       // }
                //       onChange={(value, date) => this.handleChange(date, this.state.newData, -0, "Start_Effective_Date", true)
                //       }
                //     // disabledDate={disabledDate}
                //     />
                //   </div>
                //   <div className="checkbox-group1" style={{ display: "inline-block", }}>
                //     End Effective Date: <DatePicker
                //       name="End_Effective_Date"
                //       allowClear={true}
                //       placeholder="End Date"
                //       // disabled={
                //       //   !canEnableTextBox("1", statutoryDetails.registered_VAT)
                //       // }
                //       defaultValue={this.state.newData.End_Effective_Date === null || this.state.newData.End_Effective_Date === undefined ? undefined : moment(this.state.newData.End_Effective_Date, "YYYY-MM-DD")}
                //       // value={
                //       //   this.state.newData.End_Effective_Date !== null || this.state.newData.End_Effective_Date !== "" || this.state.newData.End_Effective_Date !== undefined
                //       //     ? moment(this.state.newData.End_Effective_Date, "YYYY-MM-DD")
                //       //     : undefined
                //       // }
                //       onChange={(value, date) => this.handleChange(date, this.state.newData, -0, "End_Effective_Date", true)
                //       }
                //       // disabledDate={this.disabledDate(this.state.newData, "new")}
                //       disabledDate={d => !d || d.isSameOrBefore(this.state.newData.Start_Effective_Date)}
                //     />
                //   </div>
              // </div>*/}
                <div
                  className="checkbox-group1"
                  style={{ display: "inline-block", marginRight: 40 }}
                >
                  <Form.Item label="Mandi Priority">
                    {/* <Select
                      value={
                        this.state.newData.is_priority_mandi === true ? "Yes" : "No"
                      }
                      showSearch
                    optionFilterProp="children"
                      onChange={(value, option) =>
                        this.handleDropdownChange(
                          value,
                          this.state.newData,
                          "is_priority_mandi",
                          -0,
                          option.props.children,
                          true
                        )
                      }
                      style={{
                        width: "100px", background: "white",
                        borderColor: "gray",
                        borderWidth: "thin",
                        fontWeight: "400",
                        fontSize: "medium"
                      }}
                    >
                      <Option key={1} value={1} style={{
                        fontWeight: "400",
                        fontSize: "medium"
                      }}>
                        {"Yes"}
                      </Option>
                      <Option key={0} value={0}>
                        {"No"}
                      </Option>
                    </Select> */}
                    <Radio.Group
                      name="is_priority_mandi"
                      value={this.state.newData.is_priority_mandi ? 1 : 0}
                      required
                      onChange={(e, option) =>
                        this.handleDropdownChange(
                          e.target.value,
                          this.state.newData,
                          "is_priority_mandi",
                          -0,
                          "",
                          true
                        )
                      }
                    >
                      <Radio value={1}>Yes</Radio>
                      <Radio value={0}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </div>

                <div
                  className="checkbox-group1"
                  style={{ display: "inline-block", marginRight: 40 }}
                >
                  <Form.Item label="Main Mandi">
                    {/* <Select
                      value={
                        this.state.newData.is_main_mandi === true ? "Yes" : "No"
                      }
                      showSearch
                    optionFilterProp="children"
                      onChange={(value, option) =>
                        this.handleDropdownChange(
                          value,
                          this.state.newData,
                          "is_main_mandi",
                          -0,
                          option.props.children,
                          true
                        )
                      }
                      style={{
                        width: "100px", background: "white",
                        borderColor: "gray",
                        borderWidth: "thin",
                        fontWeight: "400",
                        fontSize: "medium"
                      }}
                    >
                      <Option key={1} value={1}>
                        {"Yes"}
                      </Option>
                      <Option key={0} value={0}>
                        {"No"}
                      </Option>
                    </Select> */}
                    <Radio.Group
                      name="is_main_mandi"
                      value={this.state.newData.is_main_mandi ? 1 : 0}
                      required
                      onChange={(e, option) =>
                        this.handleDropdownChange(
                          e.target.value,
                          this.state.newData,
                          "is_main_mandi",
                          -0,
                          "",
                          true
                        )
                      }
                    >
                      <Radio value={1}>Yes</Radio>
                      <Radio value={0}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </div>

                <div
                  className="checkbox-group1"
                  style={{ display: "inline-block" }}
                >
                  <Form.Item label="Active">
                    {/* <Select
                      value={
                        this.state.newData.is_active === true ? "Yes" : "No"
                      }
                      showSearch
                    optionFilterProp="children"
                      onChange={(value, option) =>
                        this.handleDropdownChange(
                          value,
                          this.state.newData,
                          "is_active",
                          -0,
                          option.props.children,
                          true
                        )
                      }
                      style={{
                        width: "100px", background: "white",
                        borderColor: "gray",
                        borderWidth: "thin",
                        fontWeight: "400",
                        fontSize: "medium"
                      }}
                    >
                      <Option key={1} value={1}>
                        {"Yes"}
                      </Option>
                      <Option key={0} value={0}>
                        {"No"}
                      </Option>
                    </Select> */}
                    <Radio.Group
                      name="is_active"
                      required
                      value={this.state.newData.is_active ? 1 : 0}
                      onChange={(e, option) =>
                        this.handleDropdownChange(
                          e.target.value,
                          this.state.newData,
                          "is_active",
                          -0,
                          "",
                          true
                        )
                      }
                    >
                      <Radio value={1}>Yes</Radio>
                      <Radio value={0}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </div>

                <div className="form-bottom-container">
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={this.state.isSubmitted}
                      onClick={() => {
                        // this.setState({isSubmitted:false})
                        this.submitTableRowData(this.state.newData);
                      }}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </Modal>

          <div
            className="table-container1"
            style={{ maxHeight: "450px", width: "99%", margin: "6px 0px" }}
          >
            <Table
              {...userTableProps}
              columns={[
                {
                  // fixed: true,
                  key: "mandi_name",
                  title: "Name",
                  dataIndex: "mandi_name",
                  width: 155,
                  filters: this.createFilters("mandi_name"),
                  onFilter: (value, record) =>
                    record.mandi_name.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.mandi_name.localeCompare(b.mandi_name),
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            style={{ marginRight: "2px" }}
                            maxLength={100}
                            disabled={!rowRecord.isEdit}
                            // disabled={true}
                            value={rowRecord.mandi_name}
                            name="mandi_name"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "mandi_name",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },
                {
                  title: "Primary Hub",
                  dataIndex: "hub_name",
                  key: "hub_name",
                  width: 160,
                  filters: this.createFilters("hub_name"),
                  onFilter: (value, record) => record.hub_name.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.hub_name.localeCompare(b.hub_name),
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div
                          style={{ marginLeft: "-15px", marginRight: "-10px" }}
                        >
                          <Select
                            className="remarks-select"
                            showSearch
                            optionFilterProp="children"
                            disabled={!rowRecord.isEdit}
                            name="hub_name"
                            defaultValue={rowRecord.hub_name}
                            value={rowRecord.hub_name}
                            onSelect={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "hub_name",
                                index,
                                option.props.children,
                                false
                              )
                            }
                          >
                            {this.state.primaryHubList &&
                              this.state.primaryHubList.length &&
                              this.state.primaryHubList.map((hubItem, idx) => (
                                <Option
                                  key={hubItem.territory_id}
                                  value={hubItem.territory_id}
                                >
                                  {hubItem.territory_name}
                                </Option>
                              ))}
                          </Select>
                        </div>
                      </>
                    );
                  },
                },
                {
                  title: "Mandi Region",
                  dataIndex: "region_name",
                  key: "mandi_region_name",
                  width: 160,
                  filters: this.createFilters("region_name"),
                  onFilter: (value, record) =>
                    record.region_name.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.region_name.localeCompare(b.region_name),
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div
                          style={{ marginLeft: "-15px", marginRight: "-10px" }}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            disabled={!rowRecord.isEdit}
                            className="remarks-select"
                            name="region_name"
                            defaultValue={rowRecord.region_name}
                            value={rowRecord.region_name}
                            onSelect={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "region_name",
                                index,
                                option.props.children,
                                false
                              )
                            }
                          >
                            {this.state.mandiRegionList &&
                              this.state.mandiRegionList.length &&
                              this.state.mandiRegionList.map(
                                (mandiRegionItem, idx) => (
                                  <Option
                                    key={mandiRegionItem.region_id}
                                    value={mandiRegionItem.region_id}
                                  >
                                    {mandiRegionItem.region_name}
                                  </Option>
                                )
                              )}
                          </Select>
                        </div>
                      </>
                    );
                  },
                },

                {
                  key: "Mandi_Min_Buying_MT",
                  title: "Min Buying (MT)",
                  dataIndex: "Mandi_Min_Buying_MT",
                  width: 100,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) =>
                    a.Mandi_Min_Buying_MT - b.Mandi_Min_Buying_MT,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            style={{ marginRight: "2px" }}
                            disabled={!rowRecord.isEdit}
                            maxLength={100}
                            value={rowRecord.Mandi_Min_Buying_MT}
                            name="Mandi_Min_Buying_MT"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "Mandi_Min_Buying_MT",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },
                {
                  key: "Surplus_Factor",
                  title: "Surplus Factor",
                  dataIndex: "Surplus_Factor",
                  width: 100,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.Surplus_Factor - b.Surplus_Factor,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            style={{ marginRight: "2px" }}
                            disabled={!rowRecord.isEdit}
                            maxLength={100}
                            value={rowRecord.Surplus_Factor}
                            name="Surplus_Factor"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "Surplus_Factor",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },
                {
                  key: "mandi_sap_code",
                  title: "SAP Code",
                  dataIndex: "mandi_sap_code",
                  width: 90,
                  // sortDirections: ["descend", "ascend"],
                  // sorter: (a, b) => a.mandi_sap_code.toLowerCase() - b.mandi_sap_code.toLowerCase(),
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            style={{ marginRight: "2px" }}
                            disabled={!rowRecord.isEdit}
                            maxLength={100}
                            value={rowRecord.mandi_sap_code}
                            name="mandi_sap_code"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "mandi_sap_code",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },
                {
                  key: "mandi_latitude",
                  title: "Latitude",
                  dataIndex: "mandi_latitude",
                  width: 100,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.mandi_latitude - b.mandi_latitude,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            style={{ marginRight: "2px" }}
                            disabled={!rowRecord.isEdit}
                            maxLength={100}
                            value={rowRecord.mandi_latitude}
                            name="mandi_latitude"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "mandi_latitude",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },
                {
                  key: "mandi_longitude",
                  title: "Longitude",
                  dataIndex: "mandi_longitude",
                  width: 110,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.mandi_longitude - b.mandi_longitude,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            style={{ marginRight: "2px" }}
                            disabled={!rowRecord.isEdit}
                            maxLength={100}
                            value={rowRecord.mandi_longitude}
                            name="mandi_longitude"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "mandi_longitude",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },
                {
                  key: "is_priority_mandi",
                  title: "Priority Mandi",
                  dataIndex: "is_priority_mandi",
                  width: 80,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div
                          className={
                            rowRecord.is_priority_mandi === true
                              ? "priorityYes"
                              : "priorityNo"
                          }
                          style={{ marginLeft: "-15px", marginRight: "-10px" }}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            disabled={!rowRecord.isEdit}
                            defaultValue={
                              rowRecord.is_priority_mandi === true
                                ? "Yes"
                                : "No"
                            }
                            value={
                              rowRecord.is_priority_mandi === true
                                ? "Yes"
                                : "No"
                            }
                            onChange={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "is_priority_mandi",
                                index,
                                option.props.children,
                                false
                              )
                            }
                          >
                            <Option key={1} value={1}>
                              {"Yes"}
                            </Option>
                            <Option key={0} value={0}>
                              {"No"}
                            </Option>
                          </Select>
                        </div>
                      </>
                    );
                  },
                },
                {
                  key: "is_main_mandi",
                  title: "Main Mandi",
                  dataIndex: "is_main_mandi",
                  width: 80,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div
                          style={{ marginLeft: "-15px", marginRight: "-10px" }}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            disabled={!rowRecord.isEdit}
                            defaultValue={
                              rowRecord.is_main_mandi === true ? "Yes" : "No"
                            }
                            value={
                              rowRecord.is_main_mandi === true ? "Yes" : "No"
                            }
                            onChange={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "is_main_mandi",
                                index,
                                option.props.children,
                                false
                              )
                            }
                          >
                            <Option key={1} value={1}>
                              {"Yes"}
                            </Option>
                            <Option key={0} value={0}>
                              {"No"}
                            </Option>
                          </Select>
                        </div>
                      </>
                    );
                  },
                },
                // {
                //   key: "Start_Effective_Date",
                //   title: "Start Date",
                //   dataIndex: "Start_Effective_Date",
                //   width: 120,
                //   //               sortDirections: ["descend", "ascend"],
                //   // sorter: (a, b) => a.Start_Effective_Date - b.Start_Effective_Date,
                //   render: (record, rowRecord, index) => {
                //     return (
                //       <DatePicker
                //         name="Start_Effective_Date"
                //         allowClear={true}
                //         placeholder="Start Date"
                //         disabled={!rowRecord.isEdit}
                //         style={{ marginLeft: "-20px" }}
                //         className="date-picker"
                //         format="YYYY-MM-DD"
                //         defaultValue={rowRecord.Start_Effective_Date === null || rowRecord.Start_Effective_Date === undefined ? undefined : moment(rowRecord.Start_Effective_Date, "YYYY-MM-DD")}
                //         // value={
                //         //   (rowRecord.Start_Effective_Date !== null || rowRecord.Start_Effective_Date !== "" || rowRecord.Start_Effective_Date !== undefined)
                //         //     && moment(rowRecord.Start_Effective_Date, "YYYY-MM-DD")
                //         // }
                //         onChange={(value, date) => this.handleChange(date, rowRecord, index, "Start_Effective_Date", false)
                //           // setStatutoryDetails({ ...rowRecord, Start_Effective_Date: date })
                //         }
                //       // disabledDate={disabledDate}
                //       />
                //     );
                //   },
                // },
                // {
                //   key: "End_Effective_Date",
                //   title: "End Date",
                //   dataIndex: "End_Effective_Date",
                //   width: 120,
                //   render: (record, rowRecord, index) => {
                //     return (
                //       <DatePicker
                //         name="End_Effective_Date"
                //         allowClear={true}
                //         placeholder="End Date"
                //         disabled={!rowRecord.isEdit}
                //         style={{ marginLeft: "-20px" }}
                //         className="date-picker"
                //         format="YYYY-MM-DD"
                //         defaultValue={rowRecord.End_Effective_Date === null || rowRecord.End_Effective_Date === undefined ? undefined : moment(rowRecord.End_Effective_Date, "YYYY-MM-DD")}

                //         // value={
                //         //   rowRecord.End_Effective_Date !== null || rowRecord.End_Effective_Date !== "" || rowRecord.End_Effective_Date !== undefined
                //         //     ? moment(rowRecord.End_Effective_Date, "YYYY-MM-DD")
                //         //     : undefined
                //         // }
                //         onChange={(value, date) => this.handleChange(date, rowRecord, index, "End_Effective_Date", false)
                //           // setStatutoryDetails({ ...rowRecord, End_Effective_Date: date })
                //         }
                //         disabledDate={d => !d || d.isSameOrBefore(rowRecord.Start_Effective_Date)}
                //       // disabledDate={this.disabledDate(rowRecord, "old")}
                //       />
                //     );
                //   },
                // },
                {
                  title: "Hub Region",
                  dataIndex: "hub_region",
                  key: "hub_region_name",
                  width: 140,
                  filters: this.createFilters("hub_region"),
                  onFilter: (value, record) =>
                    record.hub_region.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.hub_region.localeCompare(b.hub_region),
                  // render: (record, rowRecord) => {
                  //   return (
                  //     <>
                  //       <div style={{ marginLeft: "-15px", marginRight: "-10px" }}>
                  //         <Select
                  //           showSearch
                  // optionFilterProp="children"
                  // className="remarks-select"
                  //           // disabled={
                  //           //   record.mandiBuying.isMandi ||
                  //           //   !canUserEdit(
                  //           //     user,
                  //           //     selectedBranchID,
                  //           //     FeatureCodes.BUYING_DECISION_SUBMIT
                  //           //   )
                  //           // }
                  //           name="region_name"
                  //           defaultValue={rowRecord.region_name
                  //             // record.ui_purchase_remarks
                  //             //   ?
                  //             // allRemarks.find(
                  //             //   (item) =>
                  //             //     item.remark_id === record.ui_purchase_remarks
                  //             // ).remark
                  //             // : "Add a remark"
                  //           }
                  //           onSelect={(value) => this.handleChange(value, rowRecord)
                  //             // setRemarkHubRowFORPost(
                  //             //   record.actions.hubID,
                  //             //   value,
                  //             //   record.mandiBuying.sourceType,
                  //             //   record
                  //             // )
                  //           }
                  //         >
                  //           {this.state.mandiRegionList && this.state.mandiRegionList.length && this.state.mandiRegionList.map((hubRegionItem, idx) => (
                  //             <Option
                  //               key={hubRegionItem.region_id}
                  //               value={hubRegionItem.region_id}
                  //             >
                  //               {hubRegionItem.region_name}
                  //             </Option>
                  //           ))}
                  //         </Select>
                  //       </div>
                  //     </>
                  //   );
                  // },
                },
                {
                  title: "Hub Branch",
                  dataIndex: "branch_name",
                  key: "branch_name",
                  width: 130,
                  filters: this.createFilters("branch_name"),
                  onFilter: (value, record) =>
                    record.branch_name.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.branch_name.localeCompare(b.branch_name),
                  // render: (record, rowRecord, index) => {
                  //   return (
                  //     <>
                  //       <div style={{ marginLeft: "-15px", marginRight: "-10px" }}>
                  //         <Select
                  //            showSearch
                  // optionFilterProp="children"
                  // className="remarks-select"

                  //           name="branch_name"
                  //           defaultValue={rowRecord.branch_name

                  //           }
                  //           onSelect={(value, option) => this.handleDropdownChange(value, rowRecord, "branch_name", index, option.props.children, false)

                  //           }
                  //         >
                  //           {this.state.mandiBranchList && this.state.mandiBranchList.length && this.state.mandiBranchList.map((branchItem, idx) => (
                  //             <Option
                  //               key={branchItem.branch_id}
                  //               value={branchItem.branch_id}
                  //             >
                  //               {branchItem.branch_name}
                  //             </Option>
                  //           ))}
                  //         </Select>
                  //       </div>
                  //     </>
                  //   );
                  // },
                },
                {
                  key: "is_active",
                  title: "Active",
                  dataIndex: "is_active",
                  width: 90,
                  filters: this.createFilters("is_active"),
                  //  filters: [{ text: "Yes", value: 1 }, { text: "No", value: 0 }],
                  onFilter: (value, record) =>
                    record.is_active === Boolean(value),
                  // sortDirections: ["descend", "ascend"],
                  // sorter: (a, b) => a.is_active.localeCompare(b.is_active),
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div
                          style={{ marginLeft: "-15px", marginRight: "-10px" }}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            disabled={!rowRecord.isEdit}
                            defaultValue={
                              rowRecord.is_active === true ? "Yes" : "No"
                            }
                            value={rowRecord.is_active === true ? "Yes" : "No"}
                            onChange={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "is_active",
                                index,
                                option.props.children,
                                false
                              )
                            }
                          >
                            <Option key={1} value={1}>
                              {"Yes"}
                            </Option>
                            <Option key={0} value={0}>
                              {"No"}
                            </Option>
                          </Select>
                        </div>
                      </>
                    );
                  },
                },
                {
                  title: "Action",
                  dataIndex: "Submit",
                  key: "Submit",
                  width: 140,
                  render: (record, rowRecord, index) => {
                    console.log("selected branch ",this.props.userData,rowRecord.branch_id);
                    return (
                      <>
                        <div style={{ display: "inline-flex", width: "100%" }}>
                          <Button
                          className={!canUserEdit(
                            this.props.userData,
                            rowRecord.branch_id,
                            FeatureCodes.MS_MANDI_EDIT
                          )? "disabledBtnClass" : "btnClass"}
                          disabled={!canUserEdit(
                            this.props.userData,
                            rowRecord.branch_id,
                            FeatureCodes.MS_MANDI_EDIT
                          )}
                            type="primary"
                            size={"small"}
                            style={{
                              marginLeft: 5,
                              alignSelf: "center",
                              color: "white",
                              borderRadius: 15,
                              padding: "0px 0px 2px",
                              height: 31,
                              backgroundColor: "#20346a",
                            }}
                            block
                            htmlType="submit"
                            // disabled={this.state.isSubmitted}
                            onClick={() =>
                              rowRecord.isEdit
                                ? this.submitTableRowData(rowRecord)
                                : this.handleEditClick(rowRecord, index)
                            }
                          >
                            {rowRecord.isEdit ? "Submit" : "Edit"}
                          </Button>

                          <Button
                            className={
                              !rowRecord.isEdit
                                ? "disabledBtnClass"
                                : "btnClass"
                            }
                            type="primary"
                            size={"small"}
                            style={{
                              marginLeft: 5,
                              alignSelf: "center",
                              color: "white",
                              borderRadius: 15,
                              padding: "0px 0px 2px",
                              height: 31,
                              backgroundColor: "#20346a",
                            }}
                            block
                            htmlType="submit"
                            disabled={!rowRecord.isEdit}
                            onClick={() =>
                              this.handleCancelClick(rowRecord, index)
                            }
                          >
                            Cancel
                          </Button>
                        </div>
                      </>
                    );
                  },
                },
              ]}
              dataSource={this.setTableData()}
            />
          </div>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
  messageAccess: selectMSMessageRBAC,
});
export default connect(mapStateToProps)(MandiMaster);
//export default MandiMaster;
