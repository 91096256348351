import React, { Component } from "react";
import "./new-variety-select.styles.css";

import { Select } from "antd";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectAllVarietyInfo } from "../../redux/arrival-entry/arrival-entry.selectors";

const { Option } = Select;

/**
 * @description Custom select component used while adding new variety card in arrivals entry page.
 * @component
 */
class NewVarietySelect extends Component {
  state = {
    disabled: false,
  };
  render() {
    const {
      list,
      onSelectNewVariety,
      allVarietyInfo,
      tempVarietyID,
    } = this.props;

    console.log("$$$$$$$$$$$ :", tempVarietyID);

    /**
     *
     * @description Handle variety selection in new variety card.
     * @param {string} varietyName
     * @memberof NewVarietySelect
     */
    const handleChange = (varietyName) => {
      //get selected variety info from allvarietyinfo in store, to retrieve its id
      const selectedVarietyInfo = allVarietyInfo.filter(
        (item) => item.variety_name === varietyName
      );

      console.log("SELECTED ID:", selectedVarietyInfo[0].variety_id);

      onSelectNewVariety(tempVarietyID, selectedVarietyInfo[0], varietyName);
      this.setState({ disabled: true });
    };

    return (
      <Select
        disabled={this.state.disabled}
        className="new-variety-select"
        defaultValue="SELECT VARIETY"
        onChange={(value) => handleChange(value)}
      >
        {list.map((item, idx) => (
          <Option className="select-text" value={item} key={idx}>
            {item}
          </Option>
        ))}
      </Select>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  allVarietyInfo: selectAllVarietyInfo,
});

export default connect(mapStateToProps, null)(NewVarietySelect);
