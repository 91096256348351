import React from "react";
import "./mandi-hub-mapping.styles.css";
import { Component } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUserData,selectMSMessageRBAC } from "../../redux/user/user.selectors";
import { canUserEdit,getUserMSRBACMessage } from "../../auth/utils";
import { FeatureCodes } from "../../auth/data";
import {
  Table,
  Spin,
  Input,
  Select,
  Button,
  message,
  Form,
  Modal,
  Radio,
} from "antd";

import API from "../../api";
const { Search } = Input;
const { Option } = Select;
/**
 * @description Component for user management tab
 *
 * @component
 */
class MandiHubMappingMaster extends Component {
  state = {
    isModalVisible: false,
    isAdd: false,
    currentRecord: null,
    userBranches: null,
    searchText: null,
    searchUserType: 0,
    mandiHubTableData: [],
    mandiList: [],
    mandiRegionList: [],
    hubList: [],
    hubRegionList: [],
    hubBranchList: [],
    isSpinnerOnLoad: false,
    isSubmitted: false,
    newData: {
      M_H_Freight: null,
      branch_id: undefined,
      branch_name: "",
      branch_sap_code: "",
      hub_id: undefined,
      hub_latitude: null,
      hub_longitude: null,
      hub_name: "",
      hub_region_id: undefined,
      hub_region_name: "",
      hub_sap_code: "",
      pk_mmh_id: undefined,
      is_active: true,
      mandi_id: undefined,
      mandi_latitude: null,
      mandi_longitude: null,
      mandi_name: "",
      mandi_sap_code: "",
      region_id: undefined,
      region_name: "",
      state_name: "",
      isEdit: false,
    },
    rowData: undefined,
    selectedBranchValue: undefined,
    selectedBranchName: "",
    selectedBranchList: [],
  };
  componentDidMount() {
    this.setState({ isSpinnerOnLoad: true });
    this.getMandiBranch();
  }

  getMasterMandiHubMapping = async () => {
    this.setState({ isSpinnerOnLoad: true });


    const params = {
      branch_id:
        this.state.selectedBranchValue === null
          ? null
          : [this.state.selectedBranchValue],
    };

    await API.get("/master_mandi_hub_mapping", { params: params })
      .then((varietyResponse) => {

        varietyResponse.data.data.map((dataDetailItem, index) => {
          dataDetailItem.key = index;
          dataDetailItem.isEdit = false;
        });
        this.setState(
          {
            mandiHubTableData: varietyResponse.data.data,
          },
          () => this.setState({ isSpinnerOnLoad: false, isAdd: true })
        );

      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400) message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };

  getMandiBranch = async () => {
    this.setState({ isSpinnerOnLoad: true });


    await API.get("/list_branch")
      .then((mandiBranch) => {

        let modifiedData = [...mandiBranch.data.data];
        modifiedData.push({ branch_id: null, branch_name: "All" });
        this.setState(
          {
            selectedBranchList: modifiedData,
            mandiBranchList: mandiBranch.data.data,
            selectedBranchValue: mandiBranch.data.data[0].branch_id,
            selectedBranchName: mandiBranch.data.data[0].branch_name,
          },

          () => this.getMandiList()
        );
      })
      .catch((err) => {
        this.setState({ isSpinnerOnLoad: false });
        this.getMandiBranch();
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
      });
  };

  getMandiList = async () => {
    this.setState({ isSpinnerOnLoad: true });


    await API.get("/list_mandi")
      .then((mandiData) => {
        this.setState(
          {
            mandiList: mandiData.data.data,
          },
          () => this.getPrimaryHub()
        );
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400) message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };

  getMandiRegion = async () => {
    this.setState({ isSpinnerOnLoad: true });


    await API.get("/list_region")
      .then((mandiRegion) => {
        let modifiedData = [...mandiRegion.data.data];
        modifiedData.push({ region_id: null, region_name: "All" });
        this.setState(
          {
            selectedRegionList: modifiedData,
            mandiRegionList: mandiRegion.data.data,
            selectedRegionValue: mandiRegion.data.data[0].region_id,
            selectedRegionName: mandiRegion.data.data[0].region_name
          }, () => this.getMasterMandiHubMapping()
        );
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400) message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };

  getPrimaryHub = async () => {
    this.setState({ isSpinnerOnLoad: true });


    await API.get("/list_hubs")
      .then((hubDataList) => {
        this.setState(
          {
            hubList: hubDataList.data.data,

          }, () => this.getMandiRegion()
        );
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400) message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };


  setTableData = () => {
    if (this.state.mandiHubTableData !== undefined || this.state.mandiHubTableData.length >= 0) {
      const userTableData = this.state.mandiHubTableData.map((user, index) => {
        return {
          key: user.key,
          M_H_Freight: user.M_H_Freight,
          branch_id: user.branch_id,
          branch_name: user.branch_name,
          branch_sap_code: user.branch_sap_code,
          hub_id: user.hub_id,
          hub_latitude: user.hub_latitude,
          hub_longitude: user.hub_longitude,
          hub_name: user.hub_name,
          hub_region_id: user.hub_region_id,
          hub_region_name: user.hub_region_name,
          hub_sap_code: user.hub_sap_code,
          is_active: user.is_active,
          mandi_id: user.mandi_id,
          pk_mmh_id: user.pk_mmh_id,
          mandi_latitude: user.mandi_latitude,
          mandi_longitude: user.mandi_longitude,
          mandi_name: user.mandi_name,
          mandi_sap_code: user.mandi_sap_code,
          region_id: user.region_id,
          region_name: user.region_name,
          state_name: user.state_name,
          isEdit: user.isEdit,
        };
      });

      let dataAfterSearch = userTableData;

      if (this.state.searchText) {
        dataAfterSearch = dataAfterSearch.filter(
          (item) =>
            (item.mandi_name !== null &&
              item.mandi_name
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase())) ||
            (item.region_name !== null &&
              item.region_name
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase())) ||
            (item.hub_name !== null &&
              item.hub_name
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase())) ||
            (item.hub_region_name !== null &&
              item.hub_region_name
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase())) ||
            (item.branch_name !== null &&
              item.branch_name
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase()))
        );
      }

      return dataAfterSearch;
    }
    else {
      return this.state.mandiHubTableData;
    }
  };

  submitTableRowData = (row, index, isPopup) => {
    this.setState({ isSpinnerOnLoad: true, isSubmitted: true });
    let warningmessage = "";
    if (row.mandi_name === null || row.mandi_name === undefined || row.mandi_name === "") {
      warningmessage = "Please select Mandi";
    } else if (row.hub_name === null || row.hub_name === undefined || row.hub_name === "") {
      warningmessage = "Please select Hub";
    } else if (isPopup) {
      if (row.M_H_Freight === "0") {
        warningmessage = "Mandi hub freight should be greater than zero";
      }
      else if (row.M_H_Freight === null ||
        row.M_H_Freight === undefined ||
        row.M_H_Freight === "") {
        warningmessage = "Please enter mandi hub freight";
      }
    } else if (row.M_H_Freight === null ||
      row.M_H_Freight === undefined || row.M_H_Freight === "") {
      warningmessage = "Please enter mandi hub freight";
    }

    if (
      warningmessage === "" ||
      warningmessage === undefined ||
      warningmessage === null
    ) {
      this.setState({ isSpinnerOnLoad: true });
      this.setState({ isSubmitted: true })
      let data = {
        pk_mmh_id: row.pk_mmh_id,
        M_H_Freight: row.M_H_Freight,
        hub_id: row.hub_id,
        is_active: row.is_active,
        mandi_id: row.mandi_id,
        mandi_sap_code: row.mandi_sap_code,
      };


      API.put("/master_mandi_hub_mapping", data)
        .then((response) => {
          if (response.status === 200 && response.data.status) {
            message.info(response.data.message, 5);
            this.setModalVisibility(false);
            this.getMasterMandiHubMapping();
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
          } else if (!response.data.status) {
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
            message.error(response.data.message, 5);
            // this.setModalVisibility(false);
          } else {
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
            message.error("Please try again", 5);
          }
        })
        .catch((err) => {
          if (err) {
            if (err.response && err.response.status === 400) message.error("Something went wrong. Please try again later.", 5);
            else {
              message.error("Something went wrong.. Please try again later.", 5);
            }
          } else {
            message.error("Something went wrong... Please try again later.", 5);
          }
          this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
          // this.setModalVisibility(false);
        });
    } else {
      this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
      message.error(warningmessage, 5);
    }
  };

  setModalVisibility = (status) => {
    if (status) {
      const nData = {
        M_H_Freight: "",
        branch_id: undefined,
        branch_name: "",
        branch_sap_code: "",
        hub_id: undefined,
        hub_latitude: undefined,
        hub_longitude: undefined,
        hub_name: "",
        hub_region_id: undefined,
        hub_region_name: "",
        hub_sap_code: "",
        is_active: true,
        mandi_id: undefined,
        mandi_latitude: undefined,
        mandi_longitude: undefined,
        mandi_name: "",
        mandi_sap_code: "",
        region_id: undefined,
        region_name: "",
        state_name: "",
      };

      this.setState({ newData: nData });
    }

    this.setState({ isModalVisible: status });
  };

  TableColumnHeader = (props) => {
    const { title, subtitle } = props;
    return (
      <div className="column-header-container">
        <div
          style={{
            color: "white",
            fontSize: "12px",
            whiteSpace: "nowrap",
            fontWeight: "normal",
            textAlign: "left",
          }}
        >
          {title}
        </div>
        <div
          style={{
            color: "#cbdfff",
            fontSize: "11px",
            fontWeight: "normal",
            textAlign: "left",
            // subtitle && title.length < subtitle.length ? "left" : "center"
          }}
        >
          {subtitle}
        </div>
      </div>
    );
  };

  createFilters = (label) => {
    let filterData = this.state.mandiHubTableData;
    //#region remove duplicate objects
    let uniqueFilterData = [];

    if (label === "is_active" && this.state.mandiHubTableData && this.state.mandiHubTableData.length > 0) {
      uniqueFilterData = [{ text: "Yes", value: 1 }, { text: "No", value: 0 }]
    }
    else {
      filterData.map((mainItem) =>
        uniqueFilterData.filter((item) => item[label] === mainItem[label])
          .length > 0
          ? null
          : uniqueFilterData.push(mainItem)
      );

      uniqueFilterData = uniqueFilterData.map((item) => {
        return {
          text: item[label],
          value: item[label],
        };

      });

      uniqueFilterData.sort((a, b) => a.text.localeCompare(b.text))
    }

    return uniqueFilterData;
  };

  handleDropdownChange = (
    value,
    row,
    target,
    index,
    optionlabel,
    isPopup
  ) => {
    let specLabel, specID, branchLabel, branchid;
    if (target === "mandi_name") {
      this.state.mandiList.map((item) => {
        if (item.mandi_id === value) {
          specLabel = item.mandi_region_name;
          specID = item.mandi_region_id;
        }
      });
    }

    if (target === "hub_name") {
      this.state.hubList.map((item) => {
        if (item.territory_id === value) {
          specLabel = item.hub_region_name;
          specID = item.hub_region_id;
          branchLabel = item.parent_name;
          branchid = item.parent_id;
        }
      });
    }

    let a;
    if (isPopup === false) {
      a = this.state.mandiHubTableData;

      if (target === "is_active") {
        a[row.key].is_active = Boolean(value);
      } else if (target === "hub_name") {
        a[row.key].hub_name = optionlabel;
        a[row.key].hub_id = value;
        a[row.key].branch_id = branchid;
        a[row.key].branch_name = branchLabel;
        a[row.key].hub_region_id = specID;
        a[row.key].hub_region_name = specLabel;
      } else if (target === "region_name") {
        a[row.key].region_name = optionlabel;
        a[row.key].region_id = value;
      } else if (target === "branch_name") {
        a[row.key].branch_name = optionlabel;
        a[row.key].branch_id = value;
      } else if (target === "mandi_name") {
        a[row.key].mandi_name = optionlabel;
        a[row.key].mandi_id = value;
        a[row.key].region_name = specLabel;
        a[row.key].region_id = specID;
      } else if (target === "hub_region_name") {
        a[row.key].hub_region_name = optionlabel;
        a[row.key].hub_region_id = value;
      }

      this.setState({ mandiHubTableData: a });
    } else {
      a = this.state.newData;

      if (target === "is_active") {
        a.is_active = Boolean(value);
      } else if (target === "hub_name") {
        a.hub_name = optionlabel;
        a.hub_id = value;
        a.branch_id = branchid;
        a.branch_name = branchLabel;
        a.hub_region_id = specID;
        a.hub_region_name = specLabel;
      } else if (target === "region_name") {
        a.region_name = optionlabel;
        a.region_id = value;
      } else if (target === "branch_name") {
        a.branch_name = optionlabel;
        a.branch_id = value;
      } else if (target === "mandi_name") {
        a.mandi_name = optionlabel;
        a.mandi_id = value;
        a.region_name = specLabel;
        a.region_id = specID;
      } else if (target === "hub_region_name") {
        a.hub_region_name = optionlabel;
        a.hub_region_id = value;
      }

      this.setState({ newData: a });
    }
  };

  handleChange = (e, row, index, name, isPopup) => {
    e.target.name = name;

    var alphaNumericRegex = /^[a-zA-Z0-9]*$/;
    var numberRegex = /^[0-9.]+$/;
    let a;

    if (isPopup === false) {
      a = this.state.mandiHubTableData;
      //   if(item.key === row.key){
      //     return item;
      //   }
      // });

      if (e.target.name === "M_H_Freight") {
        if (e.target.value === "") {
          a[row.key].M_H_Freight = "";
        } else if (e.target.value.match(numberRegex)) {
          a[row.key].M_H_Freight = e.target.value;
        }
      }

      this.setState({ mandiHubTableData: a });
    } else {
      a = this.state.newData;

      if (
        e.target.name === "M_H_Freight") {
        if (e.target.value === "") {
          a.M_H_Freight = "";
        } else if (e.target.value.match(numberRegex)) {
          a.M_H_Freight = e.target.value;
        }
      }

      this.setState({ newData: a });
    }
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  handleEditChange = (row, index) => {
    if(this.canUserEdit(row,row.branch_id))
{
    this.setState({ rowData: row });
    let tempData = this.state.mandiHubTableData.map((item) => {

      if (item.key === row.key) {
        return {
          ...item,
          isEdit: true
        }
      } else {
        return item;
      }
    })
    this.setState({ mandiHubTableData: tempData });
  }
  else{
    message.info("User can edit only their respective branches",1);
  }
  };

  handleCancelClick = (rowData, index) => {
    let tempData = this.state.mandiHubTableData.map((item) => {
      if (item.key === rowData.key) {
        return this.state.rowData
      } else {
        return item;
      }
    })
    this.setState({ mandiHubTableData: tempData });
  };

  canUserEdit = (user, branchID) => {
    console.log("user data , ",user,branchID, this.props.userData, this.props.userData.userTerritories,
    this.props.userData.userTerritories.includes(parseInt(branchID)));
   //  var territories =  this.props.userData.userTerritories.split(",").map((item) => parseInt(item));
   //  console.log("user data 111122233 ",territories);
   if (this.props.userData.userTerritories.includes(parseInt(branchID))) {
     return true;
   } else {
     return false;
   }
 };


  render() {
    const userTableProps = {
      pagination: false,
      size: "default",
      bordered: true,
      scroll: { x: 200, y: window.innerHeight - 250 },
    };

    const handleBranchSelect = (value, props) => {
      this.setState(
        { selectedBranchName: props.key, selectedBranchValue: value },
        () => this.getMasterMandiHubMapping()
      );
    };

    const handleRegionSelect = (value, props) => {
      this.setState(
        { selectedRegionName: props.key, selectedRegionValue: value },
        () => this.getMasterMandiHubMapping()
      );
    };

    return (
      <div
        className="mandi-master-container"
        style={{
          height: window.innerHeight - 85,
          marginTop: "-45px",
        }}
      >
        <div className="top-container">
          <div className="hub-region-filter">


            {/*<span
      style={{
        fontSize: "15px",
        marginTop: "5px",
        fontWeight: "600",
        marginLeft: "30px",
      }}
    >
     Region&nbsp;
    </span>{" "}
    <Select
    disabled={!this.state.isAdd}
    showSearch
    optionFilterProp="children"
    className={"select-master-region"}
    mode="single"
    placeholder="Select Region"
    value={this.state.selectedRegionValue}
    onChange={(value, props) =>
      handleRegionSelect(value, props)
    }
  >
    {this.state.selectedRegionList !== null && this.state.selectedRegionList.length &&
      this.state.selectedRegionList.map((item, idx) => (
        <Option
          value={item.region_id}
          key={item.region_name}
        >
          {item.region_name}
        </Option>
      ))}
      </Select>*/}


            <span
              style={{
                fontSize: "15px",
                marginTop: "5px",
                marginLeft: "50px",
                fontWeight: "600",
              }}
            >
              Branch&nbsp;
            </span>{" "}
            <Select
              disabled={!this.state.isAdd}
              showSearch
              optionFilterProp="children"
              className={"select-master-region"}
              mode="single"
              placeholder="Select Branch"
              value={this.state.selectedBranchValue}
              onChange={(value, props) =>
                handleBranchSelect(value, props)
              }
            >
              {this.state.selectedBranchList !== null && this.state.selectedBranchList.length &&
                this.state.selectedBranchList.map((item, idx) => (
                  <Option
                    value={item.branch_id}
                    key={item.branch_name}
                  >
                    {item.branch_name}
                  </Option>
                ))}
            </Select>

          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row-reverse",
              placeItems: "flex-end",
              marginRight: "25px",
              marginBottom: "10px",
            }}
          >
            <Search
              disabled={!this.state.isAdd}
              placeholder="Search"
              allowClear
              onChange={(e) => this.setState({ searchText: e.target.value })}
              style={{ width: "45%" }}
            />
            <Button
              className="add-master-button"
              style={{
                height: "25px",
                width: "70px",
                backgroundColor: "#0b133d",
                color: "white",
              }}
              disabled={!canUserEdit(
                this.props.userData,
                this.selectedBranchValue,
                FeatureCodes.MS_MANDI_HUB_F_EDIT
              ) || !this.state.isAdd}
              onClick={() => this.setModalVisibility(true)}
            >
              &#10010; Add
            </Button>
          </div>
        </div>

        <div>
        <div
        style={{
          marginLeft: "25px",
          marginTop: "-8px",
          fontSize: "14px",
          color: "red",
          display: "flex",
          float: "left",
        }}
      > {getUserMSRBACMessage(this.props.messageAccess,FeatureCodes.MS_MANDI_HUB_F_EDIT)}
      </div>
        <div
          style={{
            marginRight: "20px",
            marginTop: "-8px",
            fontSize: "15px",
            color: "#0B133D",
            display: "flex",
            float: "right",
          }}
        >Total number of records:&nbsp;
          <b>{this.state.mandiHubTableData && this.setTableData().length}</b>
        </div>
        </div>

        <Spin
          spinning={this.state.isSpinnerOnLoad}
          size={"large"}
          tip="Loading..."
          style={{ alignSelf: "center", zIndex: 9999, marginTop: "20px" }}
        >
          <Modal
            className="add-master-modal"
            title={"Add Mandi hub freight"}
            onCancel={this.handleCancel}
            footer={false}
            visible={this.state.isModalVisible}
            destroyOnClose={true}
          >
            <div className="master-form-container">
              <Form
                layout="horizontal"
                //   onValuesChange={onFormLayoutChange}
                size={"small"}
              // onSubmit={submitTableRowData(this.state.newData)}
              >
                <div className="form-top-container1">
                  <div>
                    <div
                      className="form-top-container"
                      style={{
                        display: "inline-block",
                        width: "33%",
                        marginRight: 7,
                      }}
                    >
                      <Form.Item label="Mandi">
                        <Select
                          showSearch
                          optionFilterProp="children"
                          className="remarks-select1"
                          name="mandi_name"
                          defaultValue={this.state.newData.mandi_name}
                          value={this.state.newData.mandi_name}
                          onSelect={(value, option) =>
                            this.handleDropdownChange(
                              value,
                              this.state.newData,
                              "mandi_name",
                              -0,
                              option.props.children,
                              true
                            )
                          }
                        >
                          {this.state.mandiList &&
                            this.state.mandiList.length &&
                            this.state.mandiList.map((hubItem, idx) => (
                              <Option
                                key={hubItem.mandi_id}
                                value={hubItem.mandi_id}
                              >
                                {hubItem.mandi_name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </div>

                    {this.state.newData.region_name ? <div
                      className="left"
                      style={{
                        display: "inline-block",
                        marginRight: 10,
                        width: "45%",
                      }}
                    >
                      <span style={{ display: "block", color: "black" }}>Mandi Region: </span>
                      <span style={{ display: "block" }}><b>{this.state.newData.region_name}</b></span>
                    </div> : null}
                  </div>
                  <div>
                    <div
                      className="form-top-container"
                      style={{
                        display: "inline-block",
                        width: "33%",
                        marginRight: 7,
                      }}
                    >
                      <Form.Item label="Hub">
                        <Select
                          showSearch
                          optionFilterProp="children"
                          className="remarks-select"
                          name="hub_name"
                          defaultValue={this.state.newData.hub_name}
                          value={this.state.newData.hub_name}
                          onSelect={(value, option) =>
                            this.handleDropdownChange(
                              value,
                              this.state.newData,
                              "hub_name",
                              -0,
                              option.props.children,
                              true
                            )
                          }
                        >
                          {this.state.hubList &&
                            this.state.hubList.length &&
                            this.state.hubList.map((hubItem, idx) => (
                              <Option
                                key={hubItem.territory_id}
                                value={hubItem.territory_id}
                              >
                                {hubItem.territory_name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </div>

                    {this.state.newData.hub_region_name !== "" && this.state.newData.branch_name ? <> <div
                      className="left"
                      style={{
                        display: "inline-block",
                        marginRight: 10,
                        width: "30%",
                      }}
                    >
                      <span style={{ display: "block", color: "black" }}>Hub Region: </span>
                      <span style={{ display: "block" }}><b>{this.state.newData.hub_region_name}</b></span>
                    </div>
                      <div
                        className="left"
                        style={{
                          display: "inline-block",
                          marginRight: 10,
                          width: "30%",
                        }}
                      >
                        <span style={{ display: "block", color: "black" }}>Hub Branch:</span>
                        <span style={{ display: "block" }}><b>{this.state.newData.branch_name}</b></span>
                      </div>
                    </>
                      : null}
                  </div>
                  <hr />
                  <div>
                    <div
                      className="checkbox-group1"
                      style={{
                        display: "inline-block",
                        width: "45%",
                        marginRight: 40,
                      }}
                    >
                      <Form.Item label="Mandi Hub Freight(₹/MT)">
                        <Input
                          style={{ marginRight: "2px" }}
                          maxLength={100}
                          min="0"
                          value={this.state.newData.M_H_Freight}
                          name="M_H_Freight"
                          onChange={(e) =>
                            this.handleChange(
                              e,
                              this.state.newData,
                              -0,
                              "M_H_Freight",
                              true
                            )
                          }
                        />
                      </Form.Item>
                    </div>

                    <div
                      className="form-top-container"
                      style={{
                        display: "inline-block",
                        width: "40%",
                        marginRight: 7,
                      }}
                    >
                      <Form.Item label="Active">
                        <Radio.Group
                          name="is_active"
                          value={this.state.newData.is_active ? 1 : 0}
                          onChange={(e, option) =>
                            this.handleDropdownChange(
                              e.target.value,
                              this.state.newData,
                              "is_active",
                              -0,
                              "",
                              true
                            )
                          }
                        >
                          <Radio value={1}>Yes</Radio>
                          <Radio value={0}>No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </div>
                  </div>
                </div>

                <div className="form-bottom-container">
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={this.state.isSubmitted}
                      onClick={() =>
                        this.submitTableRowData(this.state.newData, 0, true)
                      }
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </Modal>


          <div className="table-container1" style={{ maxHeight: "450px", width: "99%", margin: "6px 0px" }}>
            <Table
              {...userTableProps}
              columns={[
                {
                  title: "Mandi",
                  dataIndex: "mandi_name",
                  key: "mandi_name",
                  width: 90,
                  filters: this.createFilters("mandi_name"),
                  onFilter: (value, record) =>
                    record.mandi_name.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.mandi_name.localeCompare(b.mandi_name),
                  //   render: (record, rowRecord) => {
                  //     return (
                  //       <>
                  //         <div>
                  //           <Select
                  //             className="remarks-select"
                  //             name="mandi_name"
                  //             defaultValue={rowRecord.mandi_name
                  //             }
                  //             onSelect={(value,option) => handleDropdownChange(value,rowRecord,"Mandi_name",-0,option.props.children,false)

                  // }
                  //           >
                  //             {this.state.mandiList && this.state.mandiList.length && this.state.mandiList.map((hubItem, idx) => (
                  //               <Option
                  //                 key={hubItem.mandi_id}
                  //                 value={hubItem.mandi_id}
                  //               >
                  //                 {hubItem.mandi_name}
                  //               </Option>
                  //             ))}
                  //           </Select>
                  //         </div>
                  //       </>
                  //     );
                  //   },
                },
                {
                  title: "Mandi Region",
                  dataIndex: "region_name",
                  key: "region_name",
                  width: 80,
                  filters: this.createFilters("region_name"),
                  onFilter: (value, record) =>
                    record.region_name.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.region_name.localeCompare(b.region_name),
                  //   render: (record, rowRecord) => {
                  //     return (
                  //       <>
                  //         <div>
                  //           <Select
                  //             className="remarks-select"
                  //             name="region_name"
                  //             defaultValue={rowRecord.region_name
                  //             }
                  //             onSelect={(value,option) => handleDropdownChange(value,rowRecord,"region_name",-0,option.props.children,false)

                  // }
                  //           >
                  //             {this.state.mandiRegionList && this.state.mandiRegionList.length && this.state.mandiRegionList.map((hubItem, idx) => (
                  //               <Option
                  //                 key={hubItem.region_id}
                  //                 value={hubItem.region_id}
                  //               >
                  //                 {hubItem.region_name}
                  //               </Option>
                  //             ))}
                  //           </Select>
                  //         </div>
                  //       </>
                  //     );
                  //   },
                },
                {
                  title: "Hub",
                  dataIndex: "hub_name",
                  key: "hub_name",
                  width: 80,
                  filters: this.createFilters("hub_name"),
                  onFilter: (value, record) => record.hub_name.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.hub_name.localeCompare(b.hub_name),
                  // render: (record, rowRecord) => {
                  //   return (
                  //     <>
                  //       <div>
                  //         <Select
                  //           className="remarks-select"
                  //           name="hub_name"
                  //           defaultValue={rowRecord.hub_name
                  //           }
                  //           onSelect={(value,option) => handleDropdownChange(value,rowRecord,"hub_name",-0,option.props.children,false)

                  //         }
                  //         >
                  //           {this.state.hubList && this.state.hubList.length && this.state.hubList.map((hubItem, idx) => (
                  //             <Option
                  //               key={hubItem.territory_id}
                  //               value={hubItem.territory_id}
                  //             >
                  //               {hubItem.territory_name}
                  //             </Option>
                  //           ))}
                  //         </Select>
                  //       </div>
                  //     </>
                  //   );
                  // },
                },
                {
                  title: "Hub Region",
                  dataIndex: "hub_region_name",
                  key: "hub_region_name",
                  width: 80,
                  filters: this.createFilters("hub_region_name"),
                  onFilter: (value, record) =>
                    record.hub_region_name.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.hub_region_name.localeCompare(b.hub_region_name),
                  //   render: (record, rowRecord) => {
                  //     return (
                  //       <>
                  //         <div>
                  //           <Select
                  //             className="remarks-select"
                  //             name="hub_region_name"
                  //             defaultValue={rowRecord.hub_region_name
                  //             }
                  //             onSelect={(value,option) => handleDropdownChange(value,rowRecord,"hub_region_name",-0,option.props.children,false)

                  // }
                  //           >
                  //             {this.state.mandiRegionList && this.state.mandiRegionList.length && this.state.mandiRegionList.map((hubItem, idx) => (
                  //               <Option
                  //                 key={hubItem.region_id}
                  //                 value={hubItem.region_id}
                  //               >
                  //                 {hubItem.region_name}
                  //               </Option>
                  //             ))}
                  //           </Select>
                  //         </div>
                  //       </>
                  //     );
                  //   },
                },

                {
                  title: "Hub Branch",
                  dataIndex: "branch_name",
                  key: "branch_name",
                  width: 80,
                  filters: this.createFilters("branch_name"),
                  onFilter: (value, record) =>
                    record.branch_name.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.branch_name.localeCompare(b.branch_name),
                  //   render: (record, rowRecord) => {
                  //     return (
                  //       <>
                  //         <div>
                  //           <Select
                  //             className="remarks-select"
                  //             name="branch_name"
                  //             defaultValue={rowRecord.branch_name
                  //             }
                  //             onSelect={(value,option) => handleDropdownChange(value,rowRecord,"branch_name",-0,option.props.children,false)

                  // }
                  //           >
                  //             {this.state.hubBranchList && this.state.hubBranchList.length && this.state.hubBranchList.map((hubItem, idx) => (
                  //               <Option
                  //                 key={hubItem.branch_id}
                  //                 value={hubItem.branch_id}
                  //               >
                  //                 {hubItem.branch_name}
                  //               </Option>
                  //             ))}
                  //           </Select>
                  //         </div>
                  //       </>
                  //     );
                  //   },
                },
                {
                  key: "M_H_Freight",
                  title: "Mandi Hub Freight(₹/MT)",
                  dataIndex: "M_H_Freight",
                  width: 80,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.M_H_Freight - b.M_H_Freight,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            disabled={!rowRecord.isEdit}
                            style={{ marginRight: "2px" }}
                            maxLength={100}
                            min="0"
                            value={rowRecord.M_H_Freight}
                            name="M_H_Freight"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "M_H_Freight",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },
                {
                  key: "is_active",
                  title: "Active",
                  dataIndex: "is_active",
                  width: 50,
                  filters: this.createFilters("is_active"),
                  // filters: [{ text: "Yes", value: 1 }, { text: "No", value: 0 }],
                  onFilter: (value, record) =>
                    record.is_active === Boolean(value),
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-15px", marginRight: "-40px" }}>
                          <Select
                            showSearch
                            optionFilterProp="children"
                            disabled={!rowRecord.isEdit}
                            defaultValue={
                              rowRecord.is_active === true ? "Yes" : "No"
                            }
                            value={
                              rowRecord.is_active === true ? "Yes" : "No"
                            }
                            onChange={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "is_active",
                                index,
                                option.props.children,
                                false
                              )
                            }
                            style={{ width: "70%" }}
                          >
                            <Option key={1} value={1}>
                              {"Yes"}
                            </Option>
                            <Option key={0} value={0}>
                              {"No"}
                            </Option>
                          </Select>
                        </div>
                      </>
                    );
                  },
                },

                {
                  title: "Action",
                  dataIndex: "Submit",
                  key: "Submit",
                  width: 120,
                  visibility: false,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ display: "inline-flex", width: "100%" }}>
                          <Button
                          className={!canUserEdit(
                            this.props.userData,
                            rowRecord.branch_id,
                            FeatureCodes.MS_MANDI_HUB_F_EDIT
                          ) ? "disabledBtnClass" : "btnClass"}
                            disabled={!canUserEdit(
                              this.props.userData,
                              rowRecord.branch_id,
                              FeatureCodes.MS_MANDI_HUB_F_EDIT
                            )}
                            type="primary"
                            size={"small"}
                            style={{
                              marginLeft: 5,
                              alignSelf: "center",
                              padding: "0px 0px 2px",
                              height: 31,
                              backgroundColor: "#20346a",
                              color: "white",
                              borderRadius: 15,
                            }}
                            block
                            htmlType="submit"
                            onClick={() =>
                              rowRecord.isEdit
                                ? this.submitTableRowData(rowRecord, index, false)
                                : this.handleEditChange(rowRecord, index)
                            }
                          >
                            {" "}
                            {rowRecord.isEdit ? "Submit" : "Edit"}{" "}
                          </Button>


                          <Button
                            className={!rowRecord.isEdit ? "disabledBtnClass" : "btnClass"}
                            type="primary"
                            size={"small"}
                            style={{
                              marginLeft: 5,
                              alignSelf: "center",
                              color: "white",
                              borderRadius: 15,
                              padding: "0px 0px 2px",
                              height: 31,
                              backgroundColor: "#20346a",
                            }}
                            block
                            htmlType="submit"
                            disabled={!rowRecord.isEdit}
                            onClick={() => this.handleCancelClick(rowRecord, index)
                            }
                          >
                            Cancel
                          </Button>

                        </div>
                      </>
                    );
                  },
                },
              ]}
              dataSource={this.setTableData()}
            />
          </div>

        </Spin>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
  messageAccess: selectMSMessageRBAC,
});
export default connect(mapStateToProps)(MandiHubMappingMaster);
//export default MandiHubMappingMaster;
