import "./chart-price-trend.styles.css";

import React, { Component } from "react";
import "../../pages/summary/summary.styles.css";
import moment from "moment";

import { DatePicker, Spin, Select } from "antd";

import API from "../../api";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import CombinedChart from "../combined-chart/combined-chart.component.jsx";

import { setAuditModalVisibility } from "../../redux/summary/summary.actions";
import { selectIsModalVisible } from "../../redux/summary/summary.selectors";

import { setSummaryVisibility } from "../../redux/arrival-entry/arrival-entry.actions";

import CustomSelectSummary from "../../components/custom-select-summary/custom-select-summary.component";

const { RangePicker } = DatePicker;
const { Option } = Select;
/**
 *
 * @description Price trend chart container in Report page.
 * @component
 * @extends {Component}
 */
class PriceTrendChart extends Component {
  state = {
    buyerType: "Mandi",
    selectedVariety: "",
    dropdownData: {
      branches: [],
      regions: [],
      hubs: [],
      mandis: [],
      varieties: [],
    },
    lastClicked: "",
    selectedBuyerType: "",
    selectedVarietyVal: "",
    startDate: moment().subtract(30, "days").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    isSpinnerOn: true,
    dataCombinedChart: {
      labels: [0],
      datasets: [
        {
          label: "ModalPrice",
          data: [0],
          type: "line",
          borderColor: "#f74c7a",
          backgroundColor: "#f74c7a",
          fill: false,
          yAxisID: "line",
        },
        {
          label: "Purchased",
          data: [0],

          backgroundColor: "#0251fa",
          fill: false,
          borderColor: "#0251fa",
        },
      ],
    },
    allBranchSelected: false,
    selectedMandis: [],
    selectedHubs: [],
    selectedBranches: [],
    selectedRegions: [],
    selectedNames: [],
  };

  COLORS = ["#5ebaf4", "#f74c7a", "#0251fa", "#a863fa"];
  /**
   * @function
   * @description Retieve and store trend chart data from API.
   * @param {string} arrivalType Arrival type (Mandi/FOR)
   * @param {string} branchId Branch ID
   * @param {string} regionId Region ID
   * @param {string} hubId Hub ID
   * @param {string} mandiId Mandi ID
   * @param {string[]} mandiIds Mandi IDs
   * @param {string} varietyId Variety ID
   * @param {string} startDate Start date of date range
   * @param {string} endDate End date of date range
   * @memberof PriceTrendChart
   */
  setDataForLineChart = (
    arrivalType,
    branchId,
    regionId,
    hubId,
    mandiIds,
    varietyId,
    startDate,
    endDate
  ) => {
    const tableParams = {
      arrival_type: arrivalType,
      branch_id: branchId,
      region_id: regionId,
      hub_id: hubId,
      mandi_id: mandiIds,
      variety_id: varietyId,
      start_date: startDate,
      end_date: endDate,
    };
    this.setState((prevState) => ({ ...prevState, isSpinnerOn: true }));
    // console.log("Selected time range: ", startDate, " - ", endDate);

    API.get(`/reports/trend_report`, { params: tableParams })
      .then((response) => {

        const chartLabel = response.data.data.trend_results.map((item) =>
          item.date.toString().substring(5, 11)
        );
        let name = response.data.data.trend_results[0].territories.map(
          (item) => item.territory_name
        );

        let chartPrice = [
          { chartModalPrice: [] },
          { chartModalPrice: [] },
          { chartModalPrice: [] },
          { chartModalPrice: [] },
        ];
        let chartVolume = [
          { chartVol: [] },
          { chartVol: [] },
          { chartVol: [] },
          { chartVol: [] },
        ];
        response.data.data.trend_results.map((item) => {
          for (var i = 0; i < item.territories.length; i++) {
            // if (item.territories[i].length !== 0) {
            Math.round(item.territories[i].price) !== 0
              ? chartPrice[i].chartModalPrice.push(
                Math.round(item.territories[i].price)
              )
              : chartPrice[i].chartModalPrice.push(null);
            Math.round(item.territories[i].volume) !== 0
              ? chartVolume[i].chartVol.push(
                Math.round(item.territories[i].volume)
              )
              : chartVolume[i].chartVol.push(null);
            name[i] = item.territories[i].territory_name;
          }
        });
        var longest =
          name.length !== 0
            ? name.reduce(function (a, b) {
              return a.length > b.length ? a : b;
            }).length
            : 0;

        for (var i = 0; i < name.length; i++) {
          var concatString = "".padEnd(
            (name[i].length === longest ? 2 : 2.6) * longest - name[i].length,
            " "
          );
          name[i] = name[i].concat(concatString);
        }

        let dataCombined = {
          labels: chartLabel.length !== 0 ? chartLabel : [0],
          datasets: [
            {
              name: name.length > 0 ? name[0] : "",
              label: "Modal Price",
              data: chartPrice.length > 0 ? chartPrice[0].chartModalPrice : [0],
              type: "line",
              borderColor: "#5ebaf4",
              borderWidth: 2,
              backgroundColor: "#5ebaf4",
              pointBorderColor: "#ffffff00",
              pointBackgroundColor: "#5ebaf400",
              lineWidth: 100,
              fill: false,
              yAxisID: "line",
            },
            {
              name: name.length > 1 ? name[1] : "",
              label: "Modal Price",
              data: chartPrice.length > 1 ? chartPrice[1].chartModalPrice : [0],
              type: "line",
              borderColor: "#f74c7a",
              borderWidth: 2,
              backgroundColor: "#f74c7a",
              pointBorderColor: "#ffffff00",
              pointBackgroundColor: "#f74c7a00",
              fill: false,
              yAxisID: "line",
            },
            {
              name: name.length > 2 ? name[2] : "",
              label: "Modal Price",
              data: chartPrice.length > 2 ? chartPrice[2].chartModalPrice : [0],
              type: "line",
              borderColor: "#0251fa",
              borderWidth: 2,
              backgroundColor: "#0251fa",
              pointBorderColor: "#ffffff00",
              pointBackgroundColor: "#0251fa00",
              fill: false,
              yAxisID: "line",
            },
            {
              name: name.length > 3 ? name[3] : "",
              label: "Modal Price",
              data: chartPrice.length > 3 ? chartPrice[3].chartModalPrice : [0],
              type: "line",
              borderColor: "#a863fa",
              borderWidth: 2,
              backgroundColor: "#a863fa",
              pointBorderColor: "#ffffff00",
              pointBackgroundColor: "#a863fa00",
              fill: false,
              yAxisID: "line",
            },

            {
              name: name.length > 0 ? name[0] : "",
              label: "Volume",
              data: chartVolume.length > 0 ? chartVolume[0].chartVol : [0],
              backgroundColor: "#5ebaf4",
              fill: false,
              borderColor: "#5ebaf4",
            },
            {
              name: name.length > 1 ? name[1] : "",
              label: "Volume",
              data: chartVolume.length > 1 ? chartVolume[1].chartVol : [0],
              backgroundColor: "#f74c7a",
              fill: false,
              borderColor: "#f74c7a",
            },
            {
              name: name.length > 2 ? name[2] : "",
              label: "Volume",
              data: chartVolume.length > 2 ? chartVolume[2].chartVol : [0],

              backgroundColor: "#0251fa",
              fill: false,
              borderColor: "#0251fa",
            },
            {
              name: name.length > 3 ? name[3] : "",
              label: "Volume",
              data: chartVolume.length > 3 ? chartVolume[3].chartVol : [0],
              backgroundColor: "#a863fa",
              fill: false,
              borderColor: "#a863fa",
            },
          ],
        };
        // }
        this.setState((prevState) => ({
          ...prevState,
          dataCombinedChart: dataCombined,
          selectedNames: name,
          isSpinnerOn: false,
        }));
      })
      .catch((err) => {

      });
  };

  /**
   * @function
   * @description Handle date filter selection.
   * @param {dateRange} dates
   * @param {string[]} dateStrings Array of strings consisting of selected date range.
   * @memberof PriceTrendChart
   */
  onChange = (dates, dateStrings) => {
    this.setState(
      { startDate: dateStrings[0], endDate: dateStrings[1] },
      () => {
        this.setDataForLineChart(
          this.state.buyerType,
          this.state.selectedBranches,
          this.state.selectedRegions,
          this.state.selectedHubs,
          this.state.selectedMandis,
          this.state.selectedVariety,
          this.state.startDate,
          this.state.endDate
        );
      }
    );
  };

  componentDidMount() {

    const params = {
      table_type: "reports",
    };
    API.get("/branches", { params: params })
      .then((response) => {
        API.get("/varieties")
          .then((varietyResponse) => {
            this.setState({
              dropdownData: {
                branches: response.data.data.branches,
                regions: null,
                hubs: null,
                mandis: null,
                varieties: varietyResponse.data.data.filter(
                  (value, index, array) =>
                    array.findIndex((obj) => obj.variety_id === value.variety_id) ===
                    index
                ),
              },
              isSpinnerOnTable: false,
            });
          })

        this.setDataForLineChart(
          this.state.buyerType,
          this.state.selectedBranch,
          this.state.selectedRegion,
          this.state.selectedHub,
          this.state.selectedMandis,
          this.state.selectedVariety,
          this.state.startDate,
          this.state.endDate
        );
        this.setHubs();
      })

  }

  //API header


  /**
   * @function
   * @description Generic function to set hubs in filter
   * @memberof PriceTrendChart
   */
  setHubs = () => {

    const params = {
      table_type: "reports",
      branch_id: this.state.selectedBranches.filter(
        (item) => item !== "All Branches"
      ),
      mapping: "primary",
    };
    API.get("/hubs", {

      params: params,
    })
      .then((response) => {
        this.setState(
          (prevState) => ({
            ...prevState,
            dropdownData: {
              ...prevState.dropdownData,
              hubs: response.data.data.hubs,
            },
            selectedHubs: [],
          }),
          () => {
            this.setRegions();
          }
        );
      })

  };

  /**
   * @function
   * @description Generic function to set regions in filter
   * @memberof PriceTrendChart
   */
  setRegions = () => {
    const params = {
      table_type: "reports",
      mapping: "primary",
      branch_id: this.state.selectedBranches.filter(
        (item) => item !== "All Branches"
      ),
      hub_id: this.state.selectedHubs,
    };
    API.get("/regions", {

      params: params,
    })
      .then((response) => {

        this.setState(
          (prevState) => ({
            ...prevState,
            dropdownData: {
              ...prevState.dropdownData,
              regions: response.data.data.regions,
            },
            selectedRegions: [],
          }),
          () => {
            this.setMandis();
          }
        );
      })
  };

  /**
   * @function
   * @description Generic function to set mandis in filter
   * @memberof PriceTrendChart
   */
  setMandis = () => {
    const params = {
      table_type: "reports",
      mapping: "primary",
      branch_id: this.state.selectedBranches.filter(
        (item) => item !== "All Branches"
      ),
      hub_id: this.state.selectedHubs,
      region_id: this.state.selectedRegions,
    };
    API.get("/mandis", {

      params: params,
    })
      .then((response) => {
        this.setState((prevState) => ({
          ...prevState,
          dropdownData: {
            ...prevState.dropdownData,
            mandis: response.data.data.mandis,
          },
          selectedMandis: [],
        }));
      })

  };

  render() {
    //type handling
    //#region
    /**
     *
     * @description Handle type filter selection.
     * @param {string} value Selected arrival type.
     * @memberof PriceTrendChart
     */
    const handleTypeSelect = (value) => {
      this.setState(
        (prevState) => ({
          ...prevState,
          selectedBuyerType: value,
          buyerType: value,
          dropdownData: {
            ...prevState.dropdownData,
            hubs:
              this.state.lastClicked !== "BranchAll"
                ? this.state.dropdownData.hubs
                : null,

            mandis: null,
          },
          selectedHubs: [],
          selectedMandis: [],
          selectedRegions: [],
        }),
        () =>
          this.setDataForLineChart(
            value,
            this.state.selectedBranches.filter(
              (item) => item !== "All Branches"
            ),
            value === "FOR" ? null : this.state.selectedRegions,
            this.state.selectedHubs,
            value === "FOR" ? null : this.state.selectedMandis,
            this.state.selectedVariety,
            this.state.startDate !== ""
              ? this.state.startDate
              : moment().format("YYYY-MM-DD"),
            this.state.endDate !== ""
              ? this.state.endDate
              : moment().format("YYYY-MM-DD")
          )
      );
    };
    //#endregion

    //variety handling
    //#region
    //filtering variety data to name and key
    let varietyId, varietyName;
    if (this.state.dropdownData.varieties) {
      varietyId = this.state.dropdownData.varieties.map(
        (item) => item.variety_id
      );
      varietyName = this.state.dropdownData.varieties.map(
        (item) => item.variety_name
      );
    }

    /**
     *
     * @description Handle variety filter selection.
     * @param {string} value Selected variety value.
     * @param {object} props Selected variety object (key and value).
     * @memberof PriceTrendChart
     */
    const handleVarietySelect = (value, props) => {
      // console.log("Selected version: ", value);
      this.setState(
        (prevState) => ({
          ...prevState,
          selectedVarietyVal: value,
          selectedVariety: value === "All" ? "" : props.key,
        }),
        () =>
          this.setDataForLineChart(
            this.state.buyerType,
            this.state.selectedBranches.filter(
              (item) => item !== "All Branches"
            ),
            this.state.selectedRegions,
            this.state.selectedHubs,
            this.state.selectedMandis,
            this.state.selectedVarietyVal === "All" ? "" : props.key,
            this.state.startDate !== ""
              ? this.state.startDate
              : moment().format("YYYY-MM-DD"),
            this.state.endDate !== ""
              ? this.state.endDate
              : moment().format("YYYY-MM-DD")
          )
      );
    };
    //#endregion

    //multiple branch handling
    //#region
    //filtering hub data to name and key
    let branchIds, branchNames;
    if (this.state.dropdownData.branches) {
      branchIds = this.state.dropdownData.branches
        ? this.state.dropdownData.branches.map((item) => item.territory_id)
        : "";
      branchNames = this.state.dropdownData.branches.map(
        (item) => item.territory_name
      );
    }

    /**
     *
     * @description Handle branch filter selection.
     * @param {string} value Selected branch value.
     * @param {object} props Selected branch object (key and value).
     * @memberof PriceTrendChart
     */
    const handleMultipleBranchSelect = (value, props) => {
      let allSelected = value.find((item) => item === "All Branches");
      this.setState((prevState) => ({
        ...prevState,
        selectedBranches: allSelected
          ? ["All Branches"]
          : value.filter((item) => item !== "All Branches").length <= 4
            ? value
            : this.state.selectedBranches,
        selectedHubs: [],
        selectedRegions: [],
        selectedMandis: [],
        allBranchSelected: allSelected,
      }));
    };
    //#endregion

    //multiple hub handling
    //#region
    //filtering hub data to name and key
    let hubIds, hubNames;
    if (this.state.dropdownData.hubs) {
      hubIds = this.state.dropdownData.hubs
        ? this.state.dropdownData.hubs.map((item) => item.territory_id)
        : "";
      hubNames = this.state.dropdownData.hubs.map(
        (item) => item.territory_name
      );
    }

    /**
     *
     * @description Handle hub filter selection.
     * @param {string} value Selected hub value.
     * @param {object} props Selected hub object (key and value).
     * @memberof PriceTrendChart
     */
    const handleMultipleHubSelect = (value, props) => {
      this.setState((prevState) => ({
        ...prevState,
        selectedHubs: value.length <= 4 ? value : this.state.selectedHubs,

        selectedRegions: [],
        selectedMandis: [],
      }));
    };
    //#endregion

    //multiple region handling
    //#region
    //filtering hub data to name and key
    let regionIds, regionNames;
    if (this.state.dropdownData.regions) {
      regionIds = this.state.dropdownData.regions
        ? this.state.dropdownData.regions.map((item) => item.region_id)
        : "";
      regionNames = this.state.dropdownData.regions.map(
        (item) => item.region_name
      );
    }

    /**
     *
     * @description Handle multiple region filter selection.
     * @param {string} value Selected region value.
     * @param {object} props Selected region object (key and value).
     * @memberof PriceTrendChart
     */
    const handleMultipleRegionSelect = (value, props) => {
      this.setState((prevState) => ({
        ...prevState,
        selectedRegions: value.length <= 4 ? value : this.state.selectedRegions,

        selectedMandis: [],
      }));
    };
    //#endregion

    //multiple mandi handling
    //#region
    //filtering mandi data to name and key
    let mandiId, mandiName;
    if (this.state.dropdownData.mandis) {
      mandiId = this.state.dropdownData.mandis.map((item) => item.mandi_id);
      mandiName = this.state.dropdownData.mandis.map((item) => item.mandi_name);
    }

    /**
     *
     * @description Handle mandi filter selection.
     * @param {string} value Selected mandi value.
     * @param {object} props Selected mandi object (key and value).
     * @memberof Summary
     */
    const handleMandiSelect = (value, props) => {
      this.setState((prevState) => ({
        ...prevState,
        selectedMandis: value.length <= 4 ? value : this.state.selectedMandis,
        lastClicked: value === "All" ? "MandiAll" : "Mandi",
      }));
    };
    //#endregion

    //To compare with selected dropdowns
    let branches = [],
      hubs = [],
      regions = [],
      mandis = [];

    //Price trend chart options
    const combinedChartOptions = {
      responsive: true,
      spanGaps: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      tooltips: {
        // bodyAlign: "center",
        intersect: false,
        // enabled: false,
        titleFontSize: 12,
        titleAlign: "center",

        // bodyAlign: "left",

        callbacks: {
          title: function (tooltipItem, data) {
            return tooltipItem[0].label;
          },
          afterBody: function (tooltipItem, data) {
            var dataset = data["datasets"][0];
            var dataset4 = data["datasets"][4];

            if (dataset.name === "ALL   ")
              return `Arrival Qty      Modal Price\n${dataset4["data"][tooltipItem[0].index]
                }              ${dataset["data"][tooltipItem[0].index]}`;
            else {
              var final =
                "                     Arrival Qty             Modal Price\n\n";
              if (data["datasets"].length !== 0) {
                let len = data["datasets"].length / 2;
                for (var i = 0; i < len; i++) {
                  final +=
                    data["datasets"][i].name !== ""
                      ? `${data["datasets"][i].name}${data["datasets"][i + len]["data"][
                        tooltipItem[0].index
                      ] !== null
                        ? data["datasets"][i + len]["data"][
                        tooltipItem[0].index
                        ]
                        : "     "
                      }                         ${data["datasets"][i]["data"][tooltipItem[0].index] !==
                        null
                        ? data["datasets"][i]["data"][tooltipItem[0].index]
                        : "     "
                      }\n`
                      : "";
                }
              }

              // var final = [["zaid", "       pp    "], "Ebrahim"];
              return final;
              // return <p>hi</p>;
            }
          },
          label: function (tooltipItem, data) {
            return "";
          },
        },
        mode: "index",
        backgroundColor: "white",
        titleFontColor: "#313131",
        borderColor: "#9d9d9d",
        borderWidth: 1,
        bodyFontColor: "#575757",
        displayColors: false,
      },
      hover: {
        mode: "nearest",
        intersect: true,
      },
      elements: {
        line: {
          tension: 0,
        },
      },
      barValueSpacing: 200,
      scales: {
        xAxes: [
          {
            stacked: false,
            barPercentage: 1,
            ticks: {
              maxRotation: 0,
            },
          },
        ],
        yAxes: [
          {
            // stacked: true,

            id: "volume",
            ticks: { beginAtZero: true },
            scaleLabel: {
              display: true,
              labelString: "Volume",
            },
          },
          {
            id: "line",
            position: "right",
            gridLines: {
              display: false,
            },
            scaleLabel: {
              display: true,
              labelString: "Modal Price",
            },
          }, //{ position: "right" }
        ],
      },
    };

    return (
      <div
        className="user-adoption-container"
        style={{ height: window.innerHeight - 131 }}
      >
        <div className="summary">
          <div
            className="filter-container"
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            <div
              className="summary-filters"
              style={{
                width: `${this.state.buyerType === "Mandi" ? "1357px" : "1080px"
                  }`,
              }}
            >
              <div className="filter-select">
                <div className="filter-select-title">Date Range</div>
                <RangePicker
                  defaultValue={[moment().subtract(30, "days"), moment()]}
                  ranges={{
                    "Past 7 days": [moment().subtract(7, "days"), moment()],
                    "This Month": [moment().startOf("month"), moment()],
                    "Last Month": [
                      moment()
                        .startOf("month")
                        .subtract(1, "days")
                        .startOf("month"),
                      moment().startOf("month").subtract(1, "days"),
                    ],
                    "Past 30 days": [moment().subtract(30, "days"), moment()],
                  }}
                  onChange={this.onChange}
                />
              </div>

              <div className="filter-select">
                <div className="filter-select-title">Type</div>
                <CustomSelectSummary
                  connectState
                  placeholder={
                    this.state.selectedBuyerType !== ""
                      ? this.state.selectedBuyerType
                      : this.state.buyerType
                  }
                  handleSelect={handleTypeSelect}
                  list={["Mandi", "FOR"]}
                />
              </div>
              <div className="filter-select">
                <div className="filter-select-title">Variety</div>
                <CustomSelectSummary
                  placeholder={
                    this.state.selectedVarietyVal === ""
                      ? "Select Variety"
                      : this.state.selectedVarietyVal
                  }
                  allEnabled
                  disabled={false}
                  list={varietyName}
                  keyList={varietyId}
                  handleSelect={handleVarietySelect}
                />
              </div>
              <div
                className="filter-select"
                style={{
                  height: "57px",
                }}
              >
                <div className="filter-select-title">Branches</div>
                <div className="multiple-select">
                  <Select
                    maxTagCount={2}
                    showArrow
                    showSearch
                    disabled={
                      this.state.isSpinnerOn ||
                      this.state.dropdownData.branches == null
                    }
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    mode="multiple"
                    placeholder="Select branches"
                    value={this.state.selectedBranches}
                    onChange={(value, props) =>
                      handleMultipleBranchSelect(value, props)
                    }
                    onFocus={() => {
                      branches = this.state.selectedBranches;
                    }}
                    onBlur={(value) => {
                      if (value.length === 0) {
                        this.setHubs();
                        this.setDataForLineChart(
                          this.state.buyerType,
                          value.filter((item) => item !== "All Branches"),
                          this.state.selectedRegion,
                          this.state.selectedHubs,
                          null,
                          this.state.selectedVariety,
                          this.state.startDate !== ""
                            ? this.state.startDate
                            : moment().format("YYYY-MM-DD"),
                          this.state.endDate !== ""
                            ? this.state.endDate
                            : moment().format("YYYY-MM-DD")
                        );
                      }
                      if (JSON.stringify(value) !== JSON.stringify(branches)) {
                        this.setHubs();
                        this.setDataForLineChart(
                          this.state.buyerType,
                          value.filter((item) => item !== "All Branches"),
                          this.state.selectedRegion,
                          this.state.selectedHubs,
                          null,
                          this.state.selectedVariety,
                          this.state.startDate !== ""
                            ? this.state.startDate
                            : moment().format("YYYY-MM-DD"),
                          this.state.endDate !== ""
                            ? this.state.endDate
                            : moment().format("YYYY-MM-DD")
                        );
                      }
                    }}
                  >
                    <Option value={"All Branches"} key={"allBranches"}>
                      All Branches
                    </Option>
                    {this.state.dropdownData.branches !== null &&
                      branchNames.map((item, idx) => (
                        <Option
                          disabled={this.state.allBranchSelected}
                          value={branchIds ? branchIds[idx] : idx}
                          key={branchIds ? branchIds[idx] : idx}
                        >
                          {item}
                        </Option>
                      ))}
                  </Select>
                </div>
              </div>

              <div
                className="filter-select"
                style={{
                  height: "57px",
                }}
              >
                <div className="filter-select-title">Hubs</div>
                <div className="multiple-select">
                  <Select
                    maxTagCount={2}
                    showArrow
                    showSearch
                    disabled={
                      this.state.isSpinnerOn ||
                      this.state.dropdownData.hubs == null
                    }
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    mode="multiple"
                    placeholder="Select hubs"
                    value={this.state.selectedHubs}
                    onChange={(value, props) =>
                      handleMultipleHubSelect(value, props)
                    }
                    onFocus={() => {
                      hubs = this.state.selectedHubs;
                    }}
                    onBlur={(value) => {
                      if (value.length === 0) {
                        this.setRegions();
                        this.setDataForLineChart(
                          this.state.buyerType,
                          this.state.selectedBranches.filter(
                            (item) => item !== "All Branches"
                          ),
                          this.state.selectedRegions,
                          value,
                          null,
                          this.state.selectedVariety,
                          this.state.startDate !== ""
                            ? this.state.startDate
                            : moment().format("YYYY-MM-DD"),
                          this.state.endDate !== ""
                            ? this.state.endDate
                            : moment().format("YYYY-MM-DD")
                        );
                      }
                      if (JSON.stringify(value) !== JSON.stringify(hubs)) {
                        this.setRegions();
                        this.setDataForLineChart(
                          this.state.buyerType,
                          this.state.selectedBranches.filter(
                            (item) => item !== "All Branches"
                          ),
                          this.state.selectedRegions,
                          value,
                          null,
                          this.state.selectedVariety,
                          this.state.startDate !== ""
                            ? this.state.startDate
                            : moment().format("YYYY-MM-DD"),
                          this.state.endDate !== ""
                            ? this.state.endDate
                            : moment().format("YYYY-MM-DD")
                        );
                      }
                    }}
                  >
                    {this.state.dropdownData.hubs !== null &&
                      hubNames.map((item, idx) => (
                        <Option
                          value={hubIds ? hubIds[idx] : idx}
                          key={hubIds ? hubIds[idx] : idx}
                        >
                          {item}
                        </Option>
                      ))}
                  </Select>
                </div>
              </div>

              {this.state.buyerType === "Mandi" && (
                <div
                  className="filter-select"
                  style={{
                    height: "57px",
                  }}
                >
                  <div className="filter-select-title">Regions</div>
                  <div className="multiple-select">
                    <Select
                      maxTagCount={2}
                      showArrow
                      showSearch
                      disabled={
                        this.state.isSpinnerOn ||
                        this.state.dropdownData.regions == null
                      }
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      mode="multiple"
                      placeholder="Select Regions"
                      value={this.state.selectedRegions}
                      onChange={(value, props) =>
                        handleMultipleRegionSelect(value, props)
                      }
                      onFocus={() => {
                        regions = this.state.selectedRegions;
                      }}
                      onBlur={(value) => {
                        if (value.length === 0) {
                          this.setMandis();
                          this.setDataForLineChart(
                            this.state.buyerType,
                            this.state.selectedBranches.filter(
                              (item) => item !== "All Branches"
                            ),
                            value,
                            this.state.selectedHubs,
                            null,
                            this.state.selectedVariety,
                            this.state.startDate !== ""
                              ? this.state.startDate
                              : moment().format("YYYY-MM-DD"),
                            this.state.endDate !== ""
                              ? this.state.endDate
                              : moment().format("YYYY-MM-DD")
                          );
                        }
                        if (JSON.stringify(value) !== JSON.stringify(regions)) {
                          this.setMandis();
                          this.setDataForLineChart(
                            this.state.buyerType,
                            this.state.selectedBranches.filter(
                              (item) => item !== "All Branches"
                            ),
                            value,
                            this.state.selectedHubs,
                            null,
                            this.state.selectedVariety,
                            this.state.startDate !== ""
                              ? this.state.startDate
                              : moment().format("YYYY-MM-DD"),
                            this.state.endDate !== ""
                              ? this.state.endDate
                              : moment().format("YYYY-MM-DD")
                          );
                        }
                      }}
                    >
                      {this.state.dropdownData.regions !== null &&
                        regionNames.map((item, idx) => (
                          <Option
                            value={regionIds ? regionIds[idx] : idx}
                            key={regionIds ? regionIds[idx] : idx}
                          >
                            {item}
                          </Option>
                        ))}
                    </Select>
                  </div>
                </div>
              )}
              {this.state.buyerType === "Mandi" && (
                <div
                  className="filter-select"
                  style={{
                    height: "57px",
                  }}
                >
                  <div className="filter-select-title">Mandis</div>
                  <div className="multiple-select">
                    <Select
                      maxTagCount={2}
                      showArrow
                      showSearch
                      disabled={
                        this.state.isSpinnerOn ||
                        this.state.dropdownData.mandis == null
                      }
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      mode="multiple"
                      placeholder="Select Mandis"
                      value={this.state.selectedMandis}
                      onChange={(value, props) =>
                        handleMandiSelect(value, props)
                      }
                      onFocus={() => {
                        mandis = this.state.selectedMandis;
                      }}
                      onBlur={(value) => {
                        if (value.length === 0) {
                          this.setDataForLineChart(
                            this.state.buyerType,
                            this.state.selectedBranches.filter(
                              (item) => item !== "All Branches"
                            ),
                            this.state.selectedRegions,
                            this.state.selectedHubs,
                            value,
                            this.state.selectedVariety,
                            this.state.startDate !== ""
                              ? this.state.startDate
                              : moment().format("YYYY-MM-DD"),
                            this.state.endDate !== ""
                              ? this.state.endDate
                              : moment().format("YYYY-MM-DD")
                          );
                        }
                        if (JSON.stringify(value) !== JSON.stringify(mandis)) {
                          this.setDataForLineChart(
                            this.state.buyerType,
                            this.state.selectedBranches.filter(
                              (item) => item !== "All Branches"
                            ),
                            this.state.selectedRegions,
                            this.state.selectedHubs,
                            value,
                            this.state.selectedVariety,
                            this.state.startDate !== ""
                              ? this.state.startDate
                              : moment().format("YYYY-MM-DD"),
                            this.state.endDate !== ""
                              ? this.state.endDate
                              : moment().format("YYYY-MM-DD")
                          );
                        }
                      }}
                    >
                      {this.state.dropdownData.mandis !== null &&
                        mandiName.map((item, idx) => (
                          <Option
                            value={mandiId ? mandiId[idx] : idx}
                            key={mandiId ? mandiId[idx] : idx}
                          >
                            {item}
                          </Option>
                        ))}
                    </Select>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <Spin spinning={this.state.isSpinnerOn} tip="Loading...">
          <div style={{ display: "default" }} className="price-trend-container">
            <div className="price-trend-chart">
              <div className="legend">
                <div style={{ fontWeight: "700", color: "#313131" }}>
                  Price Volume Chart
                </div>
                <div className="legend-details">
                  {this.state.selectedNames.length !== 0 &&
                    this.state.selectedNames[0] !== "ALL   " &&
                    this.state.selectedNames.map((item, idx) => (
                      <div key={idx} style={{ display: "flex" }}>
                        <div
                          className="box"
                          style={{
                            backgroundColor: this.COLORS[idx],
                          }}
                        ></div>
                        <div className="box-label">{item}</div>
                      </div>
                    ))}
                </div>
              </div>
              <div className="chart-container">
                <CombinedChart
                  data={this.state.dataCombinedChart}
                  options={combinedChartOptions}
                />
              </div>
              <div className="legend-line-bar-main-container">
                <div className="legend-line-bar-container">
                  <div className="bar-legend"></div>
                  <div className="leg-name">Volume</div>
                  <div className="line-legend">
                    <div className="circle-in-line"></div>
                  </div>
                  <div className="leg-name">Modal Price</div>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  isModalVisible: selectIsModalVisible,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setAuditModalVisibility: (visibilityState) =>
      dispatch(setAuditModalVisibility(visibilityState)),
    setSummaryVisibility: (isEnabled) =>
      dispatch(setSummaryVisibility(isEnabled)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PriceTrendChart);
