import React, { useState, useEffect } from "react";
import "./qc-input.styles.css";
import QCModelInputForm from "../../components/qc-input-form/qc-input-form.component";
import CustomSelectWeekly from "../../components/custom-select-weekly/custom-select-weekly.component";
import QCExpandableTable from "../../components/qc-expandable-table/qc-expandable-table.component";
import { Button, message, Spin, Empty, Modal } from "antd";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectUserData } from "../../redux/user/user.selectors";

import API, { baseURL } from "../../api";

import moment from "moment";
import Axios from "axios";
import { canUserEdit } from "../../auth/utils";
import { FeatureCodes } from "../../auth/data";

const FileDownLoad = require("js-file-download");

const QCInputPage = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [monthFilters, setMonthFilters] = useState([]);
  const [selectedMonthID, setSelectedMonthID] = useState(undefined);
  const [selectedQCID, setSelectedQCID] = useState(undefined);
  const [formData, setFormData] = useState(null);

  const [modelData, setModelData] = useState([]);

  const sampleData = [
    {
      branches: [
        {
          branch: "Bhopal",
          branch_id: 2,
          current: {
            april: 17115,
            feb: 20372,
            is_scheduled: false,
            june: 18156,
            march: 17290,
            may: 17732,
            mpc_id: 51,
            post_season: 18197,
            remarks: null,
          },
          planned: {
            april: 17115,
            feb: 20372,
            is_scheduled: false,
            june: 18156,
            march: 17290,
            may: 17732,
            mpc_id: 51,
            post_season: 18197,
            remarks: null,
          },
          state: "Madhya Pradesh",
          suggested: {
            april: 17115,
            feb: 20372,
            june: 18156,
            march: 17290,
            may: 17732,
            post_season: 18197,
          },
        },
        {
          branch: "Ahmedabad",
          branch_id: 5,
          current: {
            april: 0,
            feb: 0,
            is_scheduled: false,
            june: 0,
            march: 0,
            may: 0,
            mpc_id: 48,
            post_season: 0,
            remarks: null,
          },
          planned: {
            april: 0,
            feb: 0,
            is_scheduled: false,
            june: 0,
            march: 0,
            may: 0,
            mpc_id: 48,
            post_season: 0,
            remarks: null,
          },
          state: "Gujarat",
          suggested: {
            april: 0,
            feb: 0,
            june: 0,
            march: 0,
            may: 0,
            post_season: 0,
          },
        },
        {
          branch: "Jaipur",
          branch_id: 6,
          current: {
            april: 17866,
            feb: 21345,
            is_scheduled: false,
            june: 18989,
            march: 19133,
            may: 18666,
            mpc_id: 56,
            post_season: 20622,
            remarks: null,
          },
          planned: {
            april: 17866,
            feb: 21345,
            is_scheduled: false,
            june: 18989,
            march: 19133,
            may: 18666,
            mpc_id: 56,
            post_season: 20622,
            remarks: null,
          },
          state: "Rajasthan",
          suggested: {
            april: 17866,
            feb: 21345,
            june: 18989,
            march: 19133,
            may: 18666,
            post_season: 20622,
          },
        },
      ],
      variety: "W RAJ",
      variety_id: 1,
    },
    {
      branches: [
        {
          branch: "Bhopal",
          branch_id: 2,
          current: {
            april: 18366,
            feb: 21125,
            is_scheduled: false,
            june: 18924,
            march: 18575,
            may: 18854,
            mpc_id: 52,
            post_season: 19784,
            remarks: null,
          },
          planned: {
            april: 18366,
            feb: 21125,
            is_scheduled: false,
            june: 18924,
            march: 18575,
            may: 18854,
            mpc_id: 52,
            post_season: 19784,
            remarks: null,
          },
          state: "Madhya Pradesh",
          suggested: {
            april: 18366,
            feb: 21125,
            june: 18924,
            march: 18575,
            may: 18854,
            post_season: 19784,
          },
        },
        {
          branch: "Ahmedabad",
          branch_id: 5,
          current: {
            april: 18222,
            feb: 22594,
            is_scheduled: false,
            june: 19977,
            march: 18465,
            may: 19508,
            mpc_id: 49,
            post_season: 0,
            remarks: null,
          },
          planned: {
            april: 18222,
            feb: 22594,
            is_scheduled: false,
            june: 19977,
            march: 18465,
            may: 19508,
            mpc_id: 49,
            post_season: 0,
            remarks: null,
          },
          state: "Gujarat",
          suggested: {
            april: 18222,
            feb: 22594,
            june: 19977,
            march: 18465,
            may: 19508,
            post_season: 0,
          },
        },
        {
          branch: "Jaipur",
          branch_id: 6,
          current: {
            april: 18232,
            feb: 22832,
            is_scheduled: false,
            june: 19825,
            march: 19216,
            may: 19290,
            mpc_id: 57,
            post_season: 20831,
            remarks: null,
          },
          planned: {
            april: 18232,
            feb: 22832,
            is_scheduled: false,
            june: 19825,
            march: 19216,
            may: 19290,
            mpc_id: 57,
            post_season: 20831,
            remarks: null,
          },
          state: "Rajasthan",
          suggested: {
            april: 18232,
            feb: 22832,
            june: 19825,
            march: 19216,
            may: 19290,
            post_season: 20831,
          },
        },
        {
          branch: "Lucknow",
          branch_id: 7,
          current: {
            april: 0,
            feb: 0,
            is_scheduled: false,
            june: 0,
            march: 0,
            may: 0,
            mpc_id: 59,
            post_season: 0,
            remarks: null,
          },
          planned: {
            april: 0,
            feb: 0,
            is_scheduled: false,
            june: 0,
            march: 0,
            may: 0,
            mpc_id: 59,
            post_season: 0,
            remarks: null,
          },
          state: "Uttar Pradesh",
          suggested: {
            april: 0,
            feb: 0,
            june: 0,
            march: 0,
            may: 0,
            post_season: 0,
          },
        },
      ],
      variety: "LOKWAN",
      variety_id: 2,
    },
    {
      branches: [
        {
          branch: "Bhopal",
          branch_id: 2,
          current: {
            april: 22812,
            feb: 0,
            is_scheduled: false,
            june: 23016,
            march: 23700,
            may: 23495,
            mpc_id: 53,
            post_season: 25913,
            remarks: null,
          },
          planned: {
            april: 22812,
            feb: 0,
            is_scheduled: false,
            june: 23016,
            march: 23700,
            may: 23495,
            mpc_id: 53,
            post_season: 25913,
            remarks: null,
          },
          state: "Madhya Pradesh",
          suggested: {
            april: 22812,
            feb: 0,
            june: 23016,
            march: 23700,
            may: 23495,
            post_season: 25913,
          },
        },
      ],
      variety: "SHARBATI",
      variety_id: 3,
    },
    {
      branches: [
        {
          branch: "Bhopal",
          branch_id: 2,
          current: {
            april: 17103,
            feb: 20268,
            is_scheduled: false,
            june: 18237,
            march: 17895,
            may: 17869,
            mpc_id: 54,
            post_season: 20302,
            remarks: null,
          },
          planned: {
            april: 17103,
            feb: 20268,
            is_scheduled: false,
            june: 18237,
            march: 17895,
            may: 17869,
            mpc_id: 54,
            post_season: 20302,
            remarks: null,
          },
          state: "Madhya Pradesh",
          suggested: {
            april: 17103,
            feb: 20268,
            june: 18237,
            march: 17895,
            may: 17869,
            post_season: 20302,
          },
        },
        {
          branch: "Ahmedabad",
          branch_id: 5,
          current: {
            april: 17943,
            feb: 23931,
            is_scheduled: false,
            june: 17717,
            march: 19190,
            may: 18831,
            mpc_id: 50,
            post_season: 20328,
            remarks: null,
          },
          planned: {
            april: 17943,
            feb: 23931,
            is_scheduled: false,
            june: 17717,
            march: 19190,
            may: 18831,
            mpc_id: 50,
            post_season: 20328,
            remarks: null,
          },
          state: "Gujarat",
          suggested: {
            april: 17943,
            feb: 23931,
            june: 17717,
            march: 19190,
            may: 18831,
            post_season: 20328,
          },
        },
        {
          branch: "Jaipur",
          branch_id: 6,
          current: {
            april: 17314,
            feb: 20393,
            is_scheduled: false,
            june: 19155,
            march: 19300,
            may: 18344,
            mpc_id: 58,
            post_season: 20702,
            remarks: null,
          },
          planned: {
            april: 17314,
            feb: 20393,
            is_scheduled: false,
            june: 19155,
            march: 19300,
            may: 18344,
            mpc_id: 58,
            post_season: 20702,
            remarks: null,
          },
          state: "Rajasthan",
          suggested: {
            april: 17314,
            feb: 20393,
            june: 19155,
            march: 19300,
            may: 18344,
            post_season: 20702,
          },
        },
        {
          branch: "Nagpur",
          branch_id: 8,
          current: {
            april: 18505,
            feb: 0,
            is_scheduled: false,
            june: 17217,
            march: 0,
            may: 19018,
            mpc_id: 55,
            post_season: 0,
            remarks: null,
          },
          planned: {
            april: 18505,
            feb: 0,
            is_scheduled: false,
            june: 17217,
            march: 0,
            may: 19018,
            mpc_id: 55,
            post_season: 0,
            remarks: null,
          },
          state: "Maharastra",
          suggested: {
            april: 18505,
            feb: 0,
            june: 17217,
            march: 0,
            may: 19018,
            post_season: 0,
          },
        },
      ],
      variety: "MILL QUALITY",
      variety_id: 5,
    },
    {
      branches: [
        {
          branch: "Patna",
          branch_id: 3,
          current: {
            april: 17875,
            feb: 18268,
            is_scheduled: false,
            june: 16311,
            march: 18219,
            may: 18128,
            mpc_id: 46,
            post_season: 19117,
            remarks: null,
          },
          planned: {
            april: 17875,
            feb: 18268,
            is_scheduled: false,
            june: 16311,
            march: 18219,
            may: 18128,
            mpc_id: 46,
            post_season: 19117,
            remarks: null,
          },
          state: "Bihar",
          suggested: {
            april: 17875,
            feb: 18268,
            june: 16311,
            march: 18219,
            may: 18128,
            post_season: 19117,
          },
        },
        {
          branch: "Delhi",
          branch_id: 4,
          current: {
            april: 19654,
            feb: 22848,
            is_scheduled: false,
            june: 20186,
            march: 0,
            may: 19760,
            mpc_id: 47,
            post_season: 0,
            remarks: null,
          },
          planned: {
            april: 19654,
            feb: 22848,
            is_scheduled: false,
            june: 20186,
            march: 0,
            may: 19760,
            mpc_id: 47,
            post_season: 0,
            remarks: null,
          },
          state: "Delhi",
          suggested: {
            april: 19654,
            feb: 22848,
            june: 20186,
            march: 0,
            may: 19760,
            post_season: 0,
          },
        },
        {
          branch: "Lucknow",
          branch_id: 7,
          current: {
            april: 18011,
            feb: 18890,
            is_scheduled: false,
            june: 17679,
            march: 18792,
            may: 17877,
            mpc_id: 60,
            post_season: 19855,
            remarks: null,
          },
          planned: {
            april: 18011,
            feb: 18890,
            is_scheduled: false,
            june: 17679,
            march: 18792,
            may: 17877,
            mpc_id: 60,
            post_season: 19855,
            remarks: null,
          },
          state: "Uttar Pradesh",
          suggested: {
            april: 18011,
            feb: 18890,
            june: 17679,
            march: 18792,
            may: 17877,
            post_season: 19855,
          },
        },
      ],
      variety: "DARA",
      variety_id: 7,
    },
  ];

  const modifiedSampleData = sampleData.map((variety) => {
    return {
      ...variety,

      branches: variety.branches.map((branch) => {
        return {
          key: variety.variety_id + "_" + branch.branch_id,
          branch_id: branch.branch_id,
          variety_id: variety.variety_id,
          branch: branch.state,
          feb: {
            data: {
              modal_price: 10000,
              min: 2102.22,
              min_min: 12000,
              average: 2030,
              average_plus: 4030,
              max: 20330,
              max_max: 30220,
              modal_price_1: 20304,
              average_modal_price: 20301,
              minimum_buying: 30220,
              maximum_buying: 12000,
              total_cost: 3029300,
              factory_demand_reduced: 10299,
            },
          },
          march: {
            data: {
              modal_price: 10000,
              min: 2102.22,
              min_min: 12000,
              average: 2030,
              average_plus: 4030,
              max: 20330,
              max_max: 30220,
              modal_price_1: 20304,
              average_modal_price: 20301,
              minimum_buying: 30220,
              maximum_buying: 12000,
              total_cost: 3029300,
              factory_demand_reduced: 10299,
            },
          },
          april: {
            data: {
              modal_price: 10000,
              min: 2102.22,
              min_min: 12000,
              average: 2030,
              average_plus: 4030,
              max: 20330,
              max_max: 30220,
              modal_price_1: 20304,
              average_modal_price: 20301,
              minimum_buying: 30220,
              maximum_buying: 12000,
              total_cost: 3029300,
              factory_demand_reduced: 10299,
            },
          },
          may: {
            data: {
              modal_price: 10000,
              min: 2102.22,
              min_min: 12000,
              average: 2030,
              average_plus: 4030,
              max: 20330,
              max_max: 30220,
              modal_price_1: 20304,
              average_modal_price: 20301,
              minimum_buying: 30220,
              maximum_buying: 12000,
              total_cost: 3029300,
              factory_demand_reduced: 10299,
            },
          },
          june: {
            data: {
              modal_price: 10000,
              min: 2102.22,
              min_min: 12000,
              average: 2030,
              average_plus: 4030,
              max: 20330,
              max_max: 30220,
              modal_price_1: 20304,
              average_modal_price: 20301,
              minimum_buying: 30220,
              maximum_buying: 12000,
              total_cost: 3029300,
              factory_demand_reduced: 10299,
            },
          },
          post_season: {
            data: {
              modal_price: 10000,
              min: 2102.22,
              min_min: 12000,
              average: 2030,
              average_plus: 4030,
              max: 20330,
              max_max: 30220,
              modal_price_1: 20304,
              average_modal_price: 20301,
              minimum_buying: 30220,
              maximum_buying: 12000,
              total_cost: 3029300,
              factory_demand_reduced: 10299,
            },
          },

          colored_row: false,
        };
      }),
    };
  });

  //#region Hardcoded data

  const emptyFormData = {
    input_constraint_id: null,
    arrivals_max_factor: null,
    arrivals_max_max_factor: null,
    average_limit: null,
    average_plus_limit: null,
    buying_factor_Max_Max: null,
    buying_factor_Min_Min: null,
    demand_constraint: true,
    impact_assessment: false,
    max_buying: null,
    max_limit: null,
    max_max_limit: null,
    penalty_factor_reduction: null,
    scale_factor_for_arrivals: null,
    threshold_for_arrivals: null,
  };

  const monthFiltersDummy = [
    {
      id: 2,
      name: "Feb",
    },
    {
      id: 3,
      name: "Mar",
    },
    {
      id: 4,
      name: "Apr",
    },
    {
      id: 5,
      name: "May",
    },
    {
      id: 6,
      name: "June",
    },
    {
      id: 7,
      name: "Post Season",
    },
  ];
  const QCFilters = [
    {
      id: 3,
      name: "Business",
    },
    {
      id: 1,
      name: "Model",
    },
  ];
  //#endregion

  useEffect(() => {
    // TODO: call api to get month filters
    setMonthFilters(monthFiltersDummy);
    setSelectedMonthID(monthFiltersDummy[0].id);
    setSelectedQCID(QCFilters[0].id);

    getData(monthFiltersDummy[0].id, QCFilters[0].id);
    setModelData(modifiedSampleData);
  }, []);

  const { userData } = props;

  const countDown = () => {
    let secondsToGo = 5;
    const modal = Modal.success({
      title: "The constraints have been submitted to the model.",
      content: `Please wait for a few minutes before downloading the model output excel sheet.`,
    });
    const timer = setInterval(() => {
      secondsToGo -= 1;
      // modal.update({
      //   content: `This modal will be destroyed after ${secondsToGo} second.`,
      // });
    }, 1000);
    setTimeout(() => {
      clearInterval(timer);
      modal.destroy();
    }, secondsToGo * 1000);
  };

  const onMonthSelected = (month_id) => {
    setSelectedMonthID(month_id);
    getData(month_id, selectedQCID);
  };
  const onQCSelected = (qc_id) => {
    setSelectedQCID(qc_id);

    getData(selectedMonthID, qc_id);
  };

  const getData = (month_id, qc_id) => {
    setIsLoading(true);

    API.get(
      `/display_input_QC_constraints?info_source_type=${qc_id}&run_month=${month_id}`
    )
      .then((formResponse) => {
        if (formResponse.data.status) {
          const formDataModified =
            formResponse.data.data !== null
              ? {
                  input_constraint_id:
                    formResponse.data.data.input_constraint_id,
                  arrivals_max_factor:
                    formResponse.data.data.arrivals_max_factor,
                  arrivals_max_max_factor:
                    formResponse.data.data.arrivals_max_max_factor,
                  average_limit: formResponse.data.data.average_limit,
                  average_plus_limit: formResponse.data.data.average_plus_limit,
                  buying_factor_Max_Max:
                    formResponse.data.data.buying_factor_Max_Max,
                  buying_factor_Min_Min:
                    formResponse.data.data.buying_factor_Min_Min,
                  demand_constraint: formResponse.data.data.demand_constraint,
                  impact_assessment: formResponse.data.data.impact_assessment,
                  max_buying: formResponse.data.data.max_buying,
                  max_limit: formResponse.data.data.max_limit,
                  max_max_limit: formResponse.data.data.max_max_limit,
                  penalty_factor_reduction:
                    formResponse.data.data.penalty_factor_reduction,
                  scale_factor_for_arrivals:
                    formResponse.data.data.scale_factor_for_arrivals,
                  threshold_for_arrivals:
                    formResponse.data.data.threshold_for_arrivals,
                }
              : emptyFormData;

          setFormData(formDataModified);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          message.warning(
            "Could not retrieve previous data for form. Please try again."
          );
        }
      })
      .catch((err) => {
        setIsLoading(false);
        message.error(
          "Could not retrieve previous data for form. Please check your network connectivity."
        );
      });
  };

  const clearFormData = () => {
    setFormData(emptyFormData);
  };

  const postData = (formOutput) => {
    setIsLoading(true);

    const dataToSend = {
      arrivals_max_factor: formOutput.arrivals_max_factor,
      arrivals_max_max_factor: formOutput.arrivals_max_max_factor,
      average_limit: formOutput.average_limit,
      average_plus_limit: formOutput.average_plus_limit,
      buying_factor_Max_Max: formOutput.buying_factor_Max_Max,
      buying_factor_Min_Min: formOutput.buying_factor_Min_Min,
      demand_constraint: formOutput.demand_constraint,
      impact_assessment: formOutput.impact_assessment,
      max_buying: formOutput.max_buying,
      max_limit: formOutput.max_limit,
      max_max_limit: formOutput.max_max_limit,
      penalty_factor_reduction: formOutput.penalty_factor_reduction,
      scale_factor_for_arrivals: formOutput.scale_factor_for_arrivals,
      threshold_for_arrivals: formOutput.threshold_for_arrivals,
      info_source_type: selectedQCID,
      run_month: selectedMonthID,
    };

    API.post(`/input_QC_constraint`, dataToSend)
      .then((postResponse) => {
        if (postResponse.data.status) {
          message.success(
            "Data sent to model successfully. Response : ",
            postResponse.data.data
          );
          countDown();

          // save data to local state to reflect in the form.
          setFormData({
            input_constraint_id: postResponse.data.data.input_constraint_id,
            arrivals_max_factor: dataToSend.arrivals_max_factor,
            arrivals_max_max_factor: dataToSend.arrivals_max_max_factor,
            average_limit: dataToSend.average_limit,
            average_plus_limit: dataToSend.average_plus_limit,
            buying_factor_Max_Max: dataToSend.buying_factor_Max_Max,
            buying_factor_Min_Min: dataToSend.buying_factor_Min_Min,
            demand_constraint: dataToSend.demand_constraint,
            impact_assessment: dataToSend.impact_assessment,
            max_buying: dataToSend.max_buying,
            max_limit: dataToSend.max_limit,
            max_max_limit: dataToSend.max_max_limit,
            penalty_factor_reduction: dataToSend.penalty_factor_reduction,
            scale_factor_for_arrivals: dataToSend.scale_factor_for_arrivals,
            threshold_for_arrivals: dataToSend.threshold_for_arrivals,
          });

          setIsLoading(false);
        } else {
          setIsLoading(false);
          message.warning("Failed to send data to model. Please try again.");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        message.error(
          "Failed to send data to model. Please check your network connectivity."
        );
      });
  };

  const downloadFile = () => {
    setIsLoading(true);

    const fileParams = {
      input_constraint_id: formData.input_constraint_id,
    };
    // API.get(`/constraint/finalconstraint`, {

    //   params: fileParams,
    // })
    //   .then((fileresponse) => {
    //     if (fileresponse.data.status) {
    //#region file download
    Axios({
      url: `${baseURL}/constraint/finalconstraint`,
      method: "GET",
      params: fileParams,
      responseType: "blob"
    })
      .then((response) => {
        if (response.status !== 204) {
          FileDownLoad(
            response.data,
            `Constraints_Report_${new Date().toLocaleDateString()}.xlsx`
          );
          setIsLoading(false);
        } else {
          message.warning("File has no data.");
          this.setState({
            isSpinnerOnProgress: false,
            ProgressClicked: false,
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        message.error("File cannot be downloaded");
      });
    //#endregion
    //   } else {
    //     setIsLoading(false);
    //     message.warning("Model is Still running. Please try again later.");
    //   }
    // })
    // .catch((err) => {
    //   setIsLoading(false);
    //   message.warning("File has no data. Please try again later.");
    // });
  };

  return (
    <Spin spinning={isLoading}>
      <div className="qc-input-page-container">
        <div className="filters box-shadow-card">
          <div
            className="temporal-select center box-shadow-card"
            style={{ height: "70px" }}
          >
            <div className="temporal-select-title">Select Run Month</div>
            <CustomSelectWeekly
              list={monthFilters.map((item) => {
                return {
                  name: item.name,
                  id: item.id,
                };
              })}
              value={selectedMonthID}
              onSelect={onMonthSelected}
              //   placeholder={`Select ${setSelectText()}`}
            />
          </div>
          <div
            className="temporal-select center box-shadow-card"
            style={{ height: "70px" }}
          >
            <div className="temporal-select-title">Select QC Type</div>
            <CustomSelectWeekly
              list={QCFilters.map((item) => {
                return {
                  name: item.name,
                  id: item.id,
                };
              })}
              value={selectedQCID}
              onSelect={onQCSelected}
              //   placeholder={`Select ${setSelectText()}`}
            />
          </div>
        </div>
        {formData !== null ? (
          <QCModelInputForm
            key={Date.now()}
            data={formData}
            clearFormData={clearFormData}
            postData={postData}
          />
        ) : (
          <Empty description="Could not retrieve previous information for this QC type and Run Month." />
        )}

        <div
          className="filters box-shadow-card"
          style={{ marginBottom: "35px", borderRadius: "30px 30px 30px 30px" }}
        >
          <Button
            onClick={() => downloadFile()}
            className="refresh-button box-shadow-hover-heavy grow"
          >
            Download Constraints Output
          </Button>
        </div>
        {/* <QCExpandableTable data={modelData} /> */}
      </div>
    </Spin>
  );
};

const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
});

export default connect(mapStateToProps, null)(QCInputPage);
