import React, { Component } from "react";
import { Tabs, Switch } from "antd";

// icons
import { ReactComponent as IconPlanned } from "../../assets/Planned.svg";
// import { connect } from "react-redux";
// import { createStructuredSelector } from "reselect";

// import { selectUserData } from "../../redux/user/user.selectors";

// import { canUserEdit } from "../../auth/utils";
// import { FeatureCodes } from "../../auth/data";
import DownloadReport from "../../pages/download-report/download-report.page";
import DirectFactoryDownloadReport from "./direct-factory-buying.page";
import RakeDownloadReport from "./rake-recomandation-buying.page";
import AbdRecomandationBuyingPage from "./abd-recomandation-buying.page";
import AbdDownloadReportPage from "../abd-download-report/abd-download-report.page";
import AbdSohPage from "./abd-soh.page";
import { selectUserData } from "../../redux/user/user.selectors";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";

const { TabPane } = Tabs;

class BuyingRecomandationContainer extends Component {
  state = { tabKey: 0, switchKey: false, switchKeyPeriod: false };
  render() {
    return (
      <div className="report-tabs" style={{ height: "90vh" }}>
        <div className="switch-period">
          <span className="tag">Daily</span>
          <Switch
            checked={this.state.switchKeyPeriod}
            onChange={(e) => this.setState({ switchKeyPeriod: e })}
            style={{ background: "#1a3062" }}
          />
          <span className="tag">Weekly</span>
        </div>
        <Tabs
          onChange={() => this.setState({ tabKey: Date.now() + "" })}
          animated={false}
          className={`main-content`}
          tabBarExtraContent={
            this.props.userData.userFeatures.includes("ABD") && (
              <div className="abdswitch">
                <span className="tag">FBD</span>
                <Switch
                  checked={this.state.switchKey}
                  onChange={(e) => this.setState({ switchKey: e })}
                  style={{ background: "#1a3062" }}
                />
                <span className="tag">ABD</span>
              </div>
            )
          }
          defaultActiveKey="12"
        >
          {this.state.switchKey === false && (
            <TabPane
              // forceRender
              tab={
                <div className="tab-title-container">
                  <div>
                    <IconPlanned className="tab-title-image" />
                  </div>
                  Hub Recommendation
                </div>
              }
              key="12"
            >
              <DownloadReport isDaily={this.state.switchKeyPeriod} />
            </TabPane>
          )}
          {this.state.switchKey === false && (
            <TabPane
              // forceRenderH
              tab={
                <div className="tab-title-container">
                  <div>
                    <IconPlanned className="tab-title-image" />
                  </div>
                  Direct Factory Buying
                </div>
              }
              key="14"
            >
              <DirectFactoryDownloadReport
                isDaily={this.state.switchKeyPeriod}
              />
            </TabPane>
          )}
          {this.state.switchKey === true && (
            <TabPane
              // forceRender
              tab={
                <div className="tab-title-container">
                  <div>
                    <IconPlanned className="tab-title-image" />
                  </div>
                  Hub Recommendation
                </div>
              }
              key="13"
            >
              <AbdDownloadReportPage />
            </TabPane>
          )}
          {this.state.switchKey === true && (
            <TabPane
              tab={
                <div className="tab-title-container">
                  <div>
                    <IconPlanned className="tab-title-image" />
                  </div>
                  Direct Destination Buying
                </div>
              }
              key="15"
            >
              <AbdRecomandationBuyingPage />
            </TabPane>
          )}
          {this.state.switchKey === true && (
            <TabPane
              tab={
                <div className="tab-title-container">
                  <div>
                    <IconPlanned className="tab-title-image" />
                  </div>
                  SOH
                </div>
              }
              key="16"
            >
              <AbdSohPage />
            </TabPane>
          )}
          {/*
                    <TabPane
                        // forceRender
                        tab={
                            <div className="tab-title-container">
                                <div>
                                    <IconPlanned className="tab-title-image" />
                                </div>
                                Rake Recommendation
                            </div>
                        }
                        key="13"
                    >
                        <RakeDownloadReport />
                    </TabPane>
                    */}
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
});

export default connect(mapStateToProps, null)(BuyingRecomandationContainer);
// export default BuyingRecomandationContainer;
