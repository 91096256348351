import React from "react";
import "./cost-buildup-farmer-master.page";
import API from "../../api";
import { getStatesAPI } from "../../services/generic-apis";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUserData,selectMSMessageRBAC } from "../../redux/user/user.selectors";
import { canUserEdit,getUserMSRBACMessage } from "../../auth/utils";
import { FeatureCodes } from "../../auth/data";

import { Component } from "react";
import { Table, Spin, Input, Select, Button, message, Form, Modal ,Radio} from "antd";
const { Search } = Input;
const { Option } = Select;

class CostBuildUpFarmer extends Component {
  state = {
    isModalVisible: false,
    isAdd: false,

    hubStateList: [],
    samyojakComissionList: [],
    sanchlakComissionList: [],
    mandiFeeList: [],
    gunnyBagsList: [],
    unloadingCostList: [],
    hubUniqueKeyList: [],

    CostBuildUpFarmerTableData: [],
    currentRecord: null,
    userBranches: null,
    searchText: null,
    searchUserType: 0,

    is_active: false,
    isSpinnerOnLoad: false,
    isSubmitted: false,

    newData: {
      unloading_cost: null,
      state_id: undefined,
      state_name: "",
      gunny_bag: "",
      samyojak_commission: null,
      sanchalak_commission: null,
      mandi_fee: null,
      hub_name: "",
      isEdit: false,
      cbf_pk_id: undefined,
      is_active: true,
    },
    rowData: undefined,
    selectedhubStateValue: undefined,
    selectedhubStateName: "",
    selectedhubStateList: [],
  };

  componentDidMount() {
    this.setState({ isSpinnerOnLoad: true });
    this.getStateAPI();
  }
  getMasterCostBuildupMapping = async () => {
    this.setState({ isSpinnerOnLoad: true });

    const params = {
      state_id:
        this.state.selectedhubStateValue === null ||
        this.state.selectedhubStateValue === undefined
          ? null
          : this.state.selectedhubStateValue,
    };

    await API.get("/cost_buildup_farmer", { params: params })
      .then((varietyResponse) => {
        let modifiedData = varietyResponse.data.data.map((item, index) => {
          return {
            ...item,
            isEdit: false,
            key: index,
          };
        });
        this.setState(
          {
            CostBuildUpFarmerTableData: modifiedData,
          },
          () => this.setState({ isSpinnerOnLoad: false, isAdd: true })
        );
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };
  getPrimaryHub = async () => {
    this.setState({ isSpinnerOnLoad: true });

    await API.get("/list_hubs")
      .then((hubDataList) => {
        this.setState(
          {
            hubUniqueKeyList: hubDataList.data.data,
          },
          () => this.getMasterCostBuildupMapping()
        );
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };

  getStateAPI = async (hubId) => {
    this.setState({ isSpinnerOnLoad: true });

    const params = {
      is_cost_buildup_farmer:true,
    };

    await API.get("/get_states", { params: params })
      .then((state) => {
        let modifiedData = [...state.data.data];
        modifiedData.push({ state_id: null, state_name: "All" });
        this.setState(
          {
            selectedhubStateList: modifiedData,
            selectedhubStateValue: state.data.data[0].state_id,
            selectedhubStateName: state.data.data[0].state_name,
          },

          () => this.getPrimaryHub()
        );
      })

      .catch((err) => {
        this.setState({ isSpinnerOnLoad: false });
        this.getStateAPI();
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
      });
  };

  getStateHubAPI = async (hubId) => {
    this.setState({ isSpinnerOnLoad: true });
    const params = {
      hub_id: hubId,
      is_cost_buildup_farmer:true,
    };

    await API.get("/get_states", { params: params })
      .then((state) => {
        this.setState(
          {
            hubStateList: state.data.data,
          },

          () => this.setState({ isSpinnerOnLoad: false })
        );
      })

      .catch((err) => {
        this.setState({ isSpinnerOnLoad: false });
        this.getStateHubAPI();
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
      });
  };

  setTableData = () => {
    if (
      this.state.CostBuildUpFarmerTableData !== undefined ||
      this.state.CostBuildUpFarmerTableData.length >= 0
    ) {
      const userTableData = this.state.CostBuildUpFarmerTableData.map(
        (user, index) => {
          return {
            key: user.key,
            samyojak_commission: user.samyojak_commission,
            sanchalak_commission: user.sanchalak_commission,
            mandi_fee: user.mandi_fee,
            hub_name: user.hub_name,
            isEdit: user.isEdit,
            cbf_pk_id: user.cbf_pk_id,
            is_active: user.is_active,
            unloading_cost: user.unloading_cost,
            state_id: user.state_id,
            state_name: user.state_name,
            gunny_bag: user.gunny_bag,
          };
        }
      );

      let dataAfterSearch = userTableData;

      if (this.state.searchText) {
        dataAfterSearch = dataAfterSearch.filter(
          (item) =>
            (item.state_name !== null &&
              item.state_name
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase())) ||
            (item.hub_name !== null &&
              item.hub_name
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase()))
        );
      }

      return dataAfterSearch;
    } else {
      return this.state.CostBuildUpFarmerTableData;
    }
  };

  submitTableRowData = (row, index, isPopup) => {
    this.setState({ isSpinnerOnLoad: true, isSubmitted: true });
    let warningmessage = "";
    if (
      row.hub_name === null ||
      row.hub_name === undefined ||
      row.hub_name === ""
    ) {
      warningmessage = "Please select a Hub";
    } else if (
      row.state_name === null ||
      row.state_name === undefined ||
      row.state_name === ""
    ) {
      warningmessage = "Please select a State";
    } else if (
      row.samyojak_commission === null ||
      row.samyojak_commission === undefined ||
      row.samyojak_commission === ""
    ) {
      warningmessage = "Please select Samyojak Commission";
    } else if (
      row.sanchalak_commission === null ||
      row.sanchalak_commission === undefined ||
      row.sanchalak_commission === ""
    ) {
      warningmessage = "Please select Sanchalak Commission";
    } else if (
      row.mandi_fee === null ||
      row.mandi_fee === undefined ||
      row.mandi_fee === ""
    ) {
      warningmessage = "Please select Mandi fee";
    } else if (
      row.gunny_bag === null ||
      row.gunny_bag === undefined ||
      row.gunny_bag === ""
    ) {
      warningmessage = "Please select Gunny bags";
    } else if (
      row.unloading_cost === null ||
      row.unloading_cost === undefined ||
      row.unloading_cost === ""
    ) {
      warningmessage = "Please select Unloading cost";
    }

    if (
      warningmessage === "" ||
      warningmessage === undefined ||
      warningmessage === null
    ) {
      this.setState({ isSpinnerOnLoad: true });
      this.setState({ isSubmitted: true });
      let data = {
        cbf_pk_id: row.cbf_pk_id,
        state_id: row.state_id,
        unloading_cost: row.unloading_cost,
        mandi_fee: row.mandi_fee,
        is_active: row.is_active ,
        hub_name: row.hub_name,
        gunny_bag: row.gunny_bag,
        samyojak_commission: row.samyojak_commission,
        sanchalak_commission: row.sanchalak_commission,
      };

      API.put("/cost_buildup_farmer", data, {})
        .then((response) => {
          if (response.status === 200 && response.data.status) {
            message.info(response.data.message, 5);
            this.setModalVisibility(false);
            this.getMasterCostBuildupMapping();
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
          } else if (!response.data.status) {
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
            message.error(response.data.message, 5);
            // this.setModalVisibility(false);
          } else {
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
            message.error("Please try again", 5);
          }
        })
        .catch((err) => {
          if (err) {
            if (err.response && err.response.status === 400)
              message.error("Something went wrong. Please try again later.", 5);
            else {
              message.error(
                "Something went wrong.. Please try again later.",
                5
              );
            }
          } else {
            message.error("Something went wrong... Please try again later.", 5);
          }
          this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
          // this.setModalVisibility(false);
        });
    } else {
      this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
      message.error(warningmessage, 5);
    }
  };
  setModalVisibility = (status) => {
    if (status) {
      const nData = {
        unloading_cost: null,
        //   Unloading_Cost_id:undefined,
        state_id: undefined,
        state_name: "",

        //  gunny_bags_id: undefined,
        gunny_bag: "",
        samyojak_commission: null,
        //  Samyojak_Commission_id:undefined,
        //  Sanchalak_Commission_id:undefined,
        sanchalak_commission: null,
        mandi_fee: null,
        //  mandi_fee_id:undefined,
        hub_name: "",
        isEdit: false,

        is_active: true,
      };

      this.setState({ newData: nData });
    }

    this.setState({ isModalVisible: status });
  };
  TableColumnHeader = (props) => {
    const { title, subtitle } = props;
    return (
      <div className="column-header-container">
        <div
          style={{
            color: "white",
            fontSize: "12px",
            whiteSpace: "nowrap",
            fontWeight: "normal",
            textAlign: "left",
          }}
        >
          {title}
        </div>
        <div
          style={{
            color: "#cbdfff",
            fontSize: "11px",
            fontWeight: "normal",
            textAlign: "left",
          }}
        >
          {subtitle}
        </div>
      </div>
    );
  };

  createFilters = (label) => {
    let filterData = this.state.CostBuildUpFarmerTableData;

    //#region remove duplicate objects

    let uniqueFilterData = [];

    if (
      label === "is_active" &&
      this.state.CostBuildUpFarmerTableData &&
      this.state.CostBuildUpFarmerTableData.length > 0
    ) {
      uniqueFilterData = [
        { text: "Yes", value: 1 },
        { text: "No", value: 0 },
      ];
    } else {
      filterData.map((mainItem) =>
        uniqueFilterData.filter((item) => item[label] === mainItem[label])
          .length > 0
          ? null
          : uniqueFilterData.push(mainItem)
      );

      uniqueFilterData = uniqueFilterData.map((item) => {
        return {
          text: item[label],

          value: item[label],
        };
      });

      uniqueFilterData.sort((a, b) => a.text.localeCompare(b.text));
    }

    return uniqueFilterData;
  };

  handleDropdownChange = (value, row, target, index, optionlabel, isPopup) => {
    let a;

    if (isPopup === false) {
      a = this.state.CostBuildUpFarmerTableData;
      
      if (target === "is_active") {
        a[row.key].is_active = value;
      } else if (target === "state_name") {
        a[row.key].state_name = optionlabel;
        a[row.key].state_id = value;
      } else if (target === "hub_name") {
        a[row.key].state_name = "";
        this.getStateHubAPI(value);
        //a[row.key].hub_id= value;
        a[row.key].hub_name = optionlabel;
      }
      this.setState({ CostBuildUpFarmerTableData: a });
    } else {
      a = this.state.newData;

      if (target === "is_active") {
        a.is_active = value;
      } else if (target === "state_name") {
        a.state_name = optionlabel;
        a.state_id = value;
      } else if (target === "hub_name") {
        a.state_name = "";
        this.getStateHubAPI(value);
        //a.hub_id= value;
        a.hub_name = optionlabel;
      }
      this.setState({ newData: a });
    }
  };

  handleChange = (e, row, index, name, isPopup) => {
    let a;
    var alphaNumericRegex = /^[a-zA-Z0-9]*$/;
    var numberRegex = /^[0-9.]+$/;
    if (isPopup === false) {
      a = this.state.CostBuildUpFarmerTableData;

      switch (name) {
        case "samyojak_commission":
          if (e.target.value === "") {
            a[row.key].samyojak_commission = "";
          } else if (e.target.value.match(numberRegex)) {
            a[row.key].samyojak_commission = e.target.value;
          }

          this.setState({ CostBuildUpFarmerTableData: a });
          return;
        case "mandi_fee":
          if (e.target.value === "") {
            a[row.key].mandi_fee = "";
          } else if (e.target.value.match(numberRegex)) {
            a[row.key].mandi_fee = e.target.value;
          }

          this.setState({ CostBuildUpFarmerTableData: a });
          return;
        case "sanchalak_commission":
          if (e.target.value === "") {
            a[row.key].sanchalak_commission = "";
          } else if (e.target.value.match(numberRegex)) {
            a[row.key].sanchalak_commission = e.target.value;
          }

          this.setState({ CostBuildUpFarmerTableData: a });
          return;
        case "gunny_bag":
          if (e.target.value === "") {
            a[row.key].gunny_bag = "";
          } else if (e.target.value.match(numberRegex)) {
            a[row.key].gunny_bag = e.target.value;
          }

          this.setState({ CostBuildUpFarmerTableData: a });
          return;
        case "unloading_cost":
          if (e.target.value === "") {
            a[row.key].unloading_cost = "";
          } else if (e.target.value.match(alphaNumericRegex)) {
            a[row.key].unloading_cost = e.target.value.toUpperCase();
          }

          this.setState({ CostBuildUpFarmerTableData: a });
          return;

        default:
          return "";
      }
    } else {
      a = this.state.newData;

      switch (name) {
        case "samyojak_commission":
          if (e.target.value === "") {
            a.samyojak_commission = "";
          } else if (e.target.value.match(numberRegex)) {
            a.samyojak_commission = e.target.value;
          }

          this.setState({ newData: a });
          return;
        case "mandi_fee":
          if (e.target.value === "") {
            a.mandi_fee = "";
          } else if (e.target.value.match(numberRegex)) {
            a.mandi_fee = e.target.value;
          }

          this.setState({ newData: a });
          return;
        case "sanchalak_commission":
          if (e.target.value === "") {
            a.sanchalak_commission = "";
          } else if (e.target.value.match(numberRegex)) {
            a.sanchalak_commission = e.target.value;
          }

          this.setState({ newData: a });
          return;
        case "gunny_bag":
          if (e.target.value === "") {
            a.gunny_bag = "";
          } else if (e.target.value.match(numberRegex)) {
            a.gunny_bag = e.target.value;
          }

          this.setState({ newData: a });
          return;
        case "unloading_cost":
          if (e.target.value === "") {
            a.unloading_cost = "";
          } else if (e.target.value.match(alphaNumericRegex)) {
            a.unloading_cost = e.target.value.toUpperCase();
          }

          this.setState({ newData: a });
          return;

        default:
          return "";
      }
      // this.setState({ newData: a });
    }
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  handleEditChange = (row, index) => {
    this.setState({ rowData: row });
    let tempData = this.state.CostBuildUpFarmerTableData.map((item) => {
      if (item.cbf_pk_id === row.cbf_pk_id) {
        return {
          ...item,
          isEdit: true,
        };
      } else {
        return item;
      }
    });
    this.setState({ CostBuildUpFarmerTableData: tempData });
  };

  handleCancelClick = (rowData, index) => {
    console.log(this.state_id);

    let tempData = this.state.CostBuildUpFarmerTableData.map((item) => {
      if (item.cbf_pk_id === rowData.cbf_pk_id) {
        return this.state.rowData;
      } else {
        return item;
      }
    });
    this.setState({ CostBuildUpFarmerTableData: tempData });
  };

  render() {
    const userTableProps = {
      pagination: false,
      size: "default",
      bordered: true,
      scroll: { x: 200, y: window.innerHeight - 250 },
    };
    const handleStateSelect = (value, props) => {
      this.setState(
        { selectedhubStateName: props.key, selectedhubStateValue: value },
        () => this.getMasterCostBuildupMapping()
      );
    };

    return (
      <div
        className="mandi-master-container"
        style={{
          height: window.innerHeight - 85,
          marginTop: "-45px",
        }}
      >
        <div className="top-container">
          <div className="hub-region-filter">
            <span
              style={{
                fontSize: "15px",
                marginTop: "5px",
                marginLeft: "68px",
                fontWeight: "600",
              }}
            >
              State&nbsp;
            </span>{" "}
            <Select
              disabled={!this.state.isAdd}
              showSearch
              optionFilterProp="children"
              className={"select-master-region"}
              mode="single"
              placeholder="Select State"
              value={this.state.selectedhubStateValue}
              onChange={(value, props) => handleStateSelect(value, props)}
            >
              {this.state.selectedhubStateList !== null &&
                this.state.selectedhubStateList.length &&
                this.state.selectedhubStateList.map((item, idx) => (
                  <Option value={item.state_id} key={item.state_name}>
                    {item.state_name}
                  </Option>
                ))}
            </Select>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row-reverse",
              placeItems: "flex-end",
              marginRight: "25px",
              marginBottom: "10px",
            }}
          >
            <Search
              disabled={!this.state.isAdd}
              placeholder="Search"
              allowClear
              onChange={(e) => this.setState({ searchText: e.target.value })}
              style={{ width: "25%" }}
            />
            <Button
              className="add-master-button"
              style={{
                height: "25px",
                width: "70px",
                backgroundColor: "#0b133d",
                color: "white",
              }}
              disabled={
                !this.state.isAdd ||
                !canUserEdit(
                  this.props.userData,
                  null,
                  FeatureCodes.MS_COST_BUILDUP_FARMER_EDIT
                )
              }
              onClick={() => this.setModalVisibility(true)}
            >
              &#10010; Add
            </Button>
          </div>
        </div>

        <div>
          <div
            style={{
              marginLeft: "25px",
              marginTop: "-8px",
              fontSize: "14px",
              color: "red",
              display: "flex",
              float: "left",
            }}
          >
          {getUserMSRBACMessage(this.props.messageAccess,FeatureCodes.MS_COST_BUILDUP_FARMER_EDIT)}
          </div>
          <div
            style={{
              marginRight: "20px",
              marginTop: "-8px",
              fontSize: "15px",
              color: "#0B133D",
              display: "flex",
              float: "right",
            }}
          >
            Total number of records:&nbsp;
            <b>
              {this.state.CostBuildUpFarmerTableData &&
                this.setTableData().length}
            </b>
          </div>
        </div>

        <Spin
          spinning={this.state.isSpinnerOnLoad}
          size={"large"}
          tip="Loading..."
          style={{ alignSelf: "center", zIndex: 9999, marginTop: "20px" }}
        >
          <Modal
            className="add-master-modal"
            title={"Add Cost Build Up Farmer data"}
            onCancel={this.handleCancel}
            footer={false}
            visible={this.state.isModalVisible}
            destroyOnClose={true}
          >
            <div className="master-form-container">
              <Form
                layout="horizontal"
                //   onValuesChange={onFormLayoutChange}
                size={"small"}
                // onSubmit={submitTableRowData(this.state.newData)}
              >
                <div className="form-top-container1">
                  <div
                    className="checkbox-group1"
                    style={{
                      display: "inline-block",
                      width: "38%",
                      marginRight: 40,
                    }}
                  >
                    <Form.Item label="Hub">
                      <Select
                        showSearch
                        optionFilterProp="children"
                        className="remarks-select"
                        name="hub_name"
                        defaultValue={this.state.newData.hub_name}
                        value={this.state.newData.hub_name}
                        onSelect={(value, option) =>
                          this.handleDropdownChange(
                            value,
                            this.state.newData,
                            "hub_name",
                            -0,
                            option.props.children,
                            true
                          )
                        }
                      >
                        {this.state.hubUniqueKeyList &&
                          this.state.hubUniqueKeyList.length &&
                          this.state.hubUniqueKeyList.map((hubItem, idx) => (
                            <Option
                              key={hubItem.territory_id}
                              value={hubItem.territory_id}
                            >
                              {hubItem.territory_name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </div>

                  <div
                    className="checkbox-group1"
                    style={{
                      display: "inline-block",
                      width: "38%",
                      marginRight: 40,
                    }}
                  >
                    <Form.Item label="State">
                      <Select
                        showSearch
                        optionFilterProp="children"
                        className="remarks-select"
                        name="state_name"
                        defaultValue={this.state.newData.state_name}
                        value={this.state.newData.state_name}
                        onSelect={(value, option) =>
                          this.handleDropdownChange(
                            value,
                            this.state.newData,
                            "state_name",
                            -0,
                            option.props.children,
                            true
                          )
                        }
                      >
                        {this.state.hubStateList &&
                          this.state.hubStateList.length &&
                          this.state.hubStateList.map((hubItem, idx) => (
                            <Option
                              key={hubItem.state_id}
                              value={hubItem.state_id}
                            >
                              {hubItem.state_name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </div>

                  <div
                    className="checkbox-group1"
                    style={{
                      height: "45px",
                      display: "inline-block",
                      width: "38%",
                      marginRight: 40,
                    }}
                  >
                    <Form.Item label="Samyojak Commission">
                      <Input
                        type="number"
                        style={{ marginRight: "2px" }}
                        maxLength={100}
                        min="0"
                        value={this.state.newData.samyojak_commission}
                        name="samyojak_commission"
                        onChange={(e) =>
                          this.handleChange(
                            e,
                            this.state.newData,
                            0,
                            "samyojak_commission",
                            true
                          )
                        }
                      />
                    </Form.Item>
                  </div>

                  <div
                    className="checkbox-group1"
                    style={{
                      height: "45px",
                      display: "inline-block",
                      width: "38%",
                      marginRight: 40,
                    }}
                  >
                    <Form.Item label="Sanchalak Commission(%)">
                      <Input
                        type="number"
                        style={{ marginRight: "2px" }}
                        maxLength={100}
                        min="0"
                        value={this.state.newData.sanchalak_commission}
                        name="sanchalak_commission"
                        onChange={(e) =>
                          this.handleChange(
                            e,
                            this.state.newData,
                            0,
                            "sanchalak_commission",
                            true
                          )
                        }
                      />
                    </Form.Item>
                  </div>

                  <div
                    className="checkbox-group1"
                    style={{
                      height: "45px",
                      display: "inline-block",
                      width: "38%",
                      marginRight: 40,
                    }}
                  >
                    <Form.Item label="Mandi Fee(%)">
                      <Input
                        type="number"
                        style={{ marginRight: "2px" }}
                        maxLength={100}
                        min="0"
                        value={this.state.newData.mandi_fee}
                        name="mandi_fee"
                        onChange={(e) =>
                          this.handleChange(
                            e,
                            this.state.newData,
                            0,
                            "mandi_fee",
                            true
                          )
                        }
                      />
                    </Form.Item>
                  </div>

                  <div
                    className="checkbox-group1"
                    style={{
                      height: "45px",
                      display: "inline-block",
                      width: "38%",
                      marginRight: 40,
                    }}
                  >
                    <Form.Item label=" Gunny Bag">
                      <Input
                        type="number"
                        style={{ marginRight: "2px" }}
                        maxLength={100}
                        min="0"
                        value={this.state.newData.gunny_bag}
                        name="gunny_bag"
                        onChange={(e) =>
                          this.handleChange(
                            e,
                            this.state.newData,
                            0,
                            "gunny_bag",
                            true
                          )
                        }
                      />
                    </Form.Item>
                  </div>

                  <div
                    className="checkbox-group1"
                    style={{
                      height: "45px",
                      display: "inline-block",
                      width: "38%",
                      marginRight: 40,
                    }}
                  >
                    <Form.Item label="Unloading Cost">
                      <Input
                        type="number"
                        style={{ marginRight: "2px" }}
                        maxLength={100}
                        min="0"
                        value={this.state.newData.unloading_cost}
                        name="unloading_cost"
                        onChange={(e) =>
                          this.handleChange(
                            e,
                            this.state.newData,
                            0,
                            "unloading_cost",
                            true
                          )
                        }
                      />
                    </Form.Item>
                  </div>
                
                  <div
                  className="form-top-container"
                  style={{
                    display: "inline-block",
                    width: "41%",
                    marginRight: 7,
                  }}
                >
                  <Form.Item label="Active">
                    <Radio.Group
                      name="is_active"
                      value={this.state.newData.is_active ? 1 : 0}
                      onChange={(e, option) =>
                        this.handleDropdownChange(
                          e.target.value,
                          this.state.newData,
                          "is_active",
                          -0,
                          "",
                          true
                        )
                      }
                    >
                      <Radio value={1}>Yes</Radio>
                      <Radio value={0}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </div>
              
                  </div>
                <div
                  className="form-bottom-container"
                  style={{ marginTop: "10px" }}
                >
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={this.state.isSubmitted}
                      onClick={() =>
                        this.submitTableRowData(this.state.newData, 0, true)
                      }
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </Modal>

          <div
            className="table-container1"
            style={{ maxHeight: "400px", margin: "6px 0px" }}
          >
            <Table
              {...userTableProps}
              columns={[
                {
                  title: "State",
                  dataIndex: "state_name",
                  key: "state_id",
                  width: 130,
                  filters: this.createFilters("state_name"),
                  onFilter: (value, record) =>
                    record.state_name.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.state_name.localeCompare(b.state_name),
                  render: (record, rowRecord) => {
                    return (
                      <>
                        <div
                          style={{ marginLeft: "-15px", marginRight: "-10px" }}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            disabled={true} //{!rowRecord.isEdit}
                            className="remarks-select"
                            name="Hub_State"
                            defaultValue={rowRecord.state_name}
                            value={rowRecord.state_name}
                            onSelect={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "state_name",
                                -0,
                                option.props.children,
                                false
                              )
                            }
                          >
                            {this.state.hubStateList &&
                              this.state.hubStateList.length &&
                              this.state.hubStateList.map((hubItem, idx) => (
                                <Option
                                  key={hubItem.state_id}
                                  value={hubItem.state_id}
                                >
                                  {hubItem.state_name}
                                </Option>
                              ))}
                          </Select>
                        </div>
                      </>
                    );
                  },
                },
                {
                  title: "Hub",
                  dataIndex: "hub_name",
                  key: "hub_name",
                  width: 120,
                  filters: this.createFilters("hub_name"),
                  onFilter: (value, record) => record.hub_name.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.hub_name.localeCompare(b.hub_name),
                  render: (record, rowRecord) => {
                    return (
                      <>
                        <div
                          style={{ marginLeft: "-15px", marginRight: "-10px" }}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            disabled={true} //{!rowRecord.isEdit}
                            className="remarks-select"
                            name="hub_name"
                            defaultValue={rowRecord.hub_name}
                            value={rowRecord.hub_name}
                            onSelect={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "hub_name",
                                -0,
                                option.props.children,
                                false
                              )
                            }
                          >
                            {this.state.hubUniqueKeyList &&
                              this.state.hubUniqueKeyList.length &&
                              this.state.hubUniqueKeyList.map(
                                (hubItem, idx) => (
                                  <Option
                                    key={hubItem.territory_id}
                                    value={hubItem.territory_id}
                                  >
                                    {hubItem.territory_name}
                                  </Option>
                                )
                              )}
                          </Select>
                        </div>
                      </>
                    );
                  },
                },
                {
                  title: "Samyojak Commission",
                  dataIndex: "samyojak_commission",
                  key: "samyojak_commission",
                  width: 150,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) =>
                    a.samyojak_commission - b.samyojak_commission,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            type="number"
                            disabled={!rowRecord.isEdit}
                            style={{ marginRight: "2px" }}
                            maxLength={100}
                            min="0"
                            value={rowRecord.samyojak_commission}
                            name="samyojak_commission"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "samyojak_commission",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },

                {
                  title: "Sanchalak Commission(%)",
                  dataIndex: "sanchalak_commission",
                  key: "sanchalak_commission",
                  width: 150,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) =>
                    a.sanchalak_commission - b.sanchalak_commission,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            type="number"
                            disabled={!rowRecord.isEdit}
                            style={{ marginRight: "2px" }}
                            maxLength={100}
                            min="0"
                            value={rowRecord.sanchalak_commission}
                            name="sanchalak_commission"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "sanchalak_commission",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },
                {
                  title: "Mandi Fee(%)",
                  dataIndex: "mandi_fee",
                  key: "mandi_fee",
                  width: 80,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.mandi_fee - b.mandi_fee,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            type="number"
                            disabled={!rowRecord.isEdit}
                            style={{ marginRight: "2px" }}
                            maxLength={100}
                            min="0"
                            value={rowRecord.mandi_fee}
                            name="mandi_fee"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "mandi_fee",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },
                {
                  title: "Gunny Bags",
                  dataIndex: "gunny_bag",
                  key: "gunny_bag",
                  width: 80,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.gunny_bag - b.gunny_bag,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            type="number"
                            disabled={!rowRecord.isEdit}
                            style={{ marginRight: "2px" }}
                            maxLength={100}
                            min="0"
                            value={rowRecord.gunny_bag}
                            name="gunny_bag"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "gunny_bag",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },
                {
                  title: "Unloading Cost",
                  dataIndex: "unloading_cost",
                  key: "unloading_cost",
                  width: 80,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.unloading_cost - b.unloading_cost,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            type="number"
                            disabled={!rowRecord.isEdit}
                            style={{ marginRight: "2px" }}
                            maxLength={100}
                            min="0"
                            value={rowRecord.unloading_cost}
                            name="unloading_cost"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "unloading_cost",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },
                {
                  key: "is_active",
                  title: "Active",
                  dataIndex: "is_active",
                  width: 85,
                  filters: this.createFilters("is_active"),
                  // filters: [{ text: "Yes", value: 1 }, { text: "No", value: 0 }],
                  onFilter: (value, record) =>
                    record.is_active === value,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-15px", marginRight: "-40px" }}>
                          <Select
                            showSearch
                            optionFilterProp="children"
                            disabled={!rowRecord.isEdit}
                            defaultValue={
                              rowRecord.is_active === 1 ? "Yes" : "No"
                            }
                            value={
                              rowRecord.is_active === 1 ? "Yes" : "No"
                            }
                            onChange={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "is_active",
                                index,
                                option.props.children,
                                false
                              )
                            }
                            style={{ width: "70%" }}
                          >
                            <Option key={1} value={1}>
                              {"Yes"}
                            </Option>
                            <Option key={0} value={0}>
                              {"No"}
                            </Option>
                          </Select>
                        </div>
                      </>
                    );
                  },
                },
                {
                  title: "Action",
                  dataIndex: "Submit",
                  key: "Submit",
                  width: 120,
                  visibility: false,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ display: "inline-flex", width: "100%" }}>
                          <Button
                            className={
                              !canUserEdit(
                                this.props.userData,
                                null,
                                FeatureCodes.MS_COST_BUILDUP_FARMER_EDIT
                              )
                                ? "disabledBtnClass"
                                : "btnClass"
                            }
                            disabled={
                              !canUserEdit(
                                this.props.userData,
                                null,
                                FeatureCodes.MS_COST_BUILDUP_FARMER_EDIT
                              )
                            }
                            type="primary"
                            size={"small"}
                            style={{
                              marginLeft: 5,
                              alignSelf: "center",
                              padding: "0px 0px 2px",
                              width: 70,
                              height: 31,

                              backgroundColor: "#20346a",
                              color: "white",
                              borderRadius: 15,
                            }}
                            block
                            htmlType="submit"
                            onClick={() =>
                              rowRecord.isEdit
                                ? this.submitTableRowData(
                                    rowRecord,
                                    index,
                                    false
                                  )
                                : this.handleEditChange(rowRecord, index)
                            }
                          >
                            {" "}
                            {rowRecord.isEdit ? "Submit" : "Edit"}{" "}
                          </Button>

                          <Button
                            className={
                              !rowRecord.isEdit
                                ? "disabledBtnClass"
                                : "btnClass"
                            }
                            type="primary"
                            size={"small"}
                            style={{
                              marginLeft: 5,
                              alignSelf: "center",
                              width: 70,
                              color: "white",
                              borderRadius: 15,
                              padding: "0px 0px 2px",

                              height: 31,
                              backgroundColor: "#20346a",
                            }}
                            block
                            htmlType="submit"
                            disabled={!rowRecord.isEdit}
                            onClick={() =>
                              this.handleCancelClick(rowRecord, index)
                            }
                          >
                            Cancel
                          </Button>
                        </div>
                      </>
                    );
                  },
                },
              ]}
              dataSource={this.setTableData()}
            />
          </div>
        </Spin>
      </div>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
  messageAccess: selectMSMessageRBAC,
});

export default connect(mapStateToProps)(CostBuildUpFarmer);

//export default CostBuildUpFarmer;
