import Axios from "axios";
import store from "../redux/store";
import { setAccessToken, setRefreshToken, setUserLoggedStatus } from "../redux/user/user.actions";
import { SignOutAPI } from "../auth/utils";



//Logout function after refresh token expired
const logoutFn = async () => {
  try {
    store.dispatch(setUserLoggedStatus(false));
    localStorage.clear();
  } catch (error) {

  }
};

// const axiosInstance = Axios.create({
//   baseURL: baseURL,
// });

//Set token as axios common header
export function setUserToken(token: string | undefined) {
  if (token) {
    Axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete Axios.defaults.headers.common["Authorization"];
  }
}


Axios.interceptors.response.use(
  (res) => {
    const originalConfig = res.config;

    if (originalConfig.url === `/login` || originalConfig.url === `/refresh`) {
      setUserToken(res.data.data.access_token);
    }

    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    // if (originalConfig?.url?.indexOf("api.openweathermap.org") !== -1) {
    //   delete Axios.defaults.headers.common["Authorization"];
    //   // return Axios(originalConfig);
    // }

    if (
      originalConfig.url !== `/login` &&
      originalConfig.url !== `/logout` &&
      err.response
    ) {
      const userdata = store.getState().user;
      // Access Token was expired
      if (
        ((err.response.status === 401 || err.response.status === 422) &&
          originalConfig.url === `/refresh`) || !userdata.isLogged
      ) {

        await logoutFn();
        try {
          await SignOutAPI();
        } catch (_err) {
          console.log(_err);
        }

        return Promise.reject(err);
      }
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;


        try {
          let refreshToken = userdata.refreshToken;
          if (!refreshToken) {
            refreshToken = await localStorage.getItem('refreshToken');
          }

          setUserToken(refreshToken);
          const rs = await Axios.post('/refresh');
          //set user to acces token
          setUserToken(rs.data.data.access_token);
          //set user data for authenticaton
          store.dispatch(
            setAccessToken(rs.data.data.access_token)
          );
          store.dispatch(
            setRefreshToken(rs.data.data.refresh_token)
          );
          store.dispatch(setUserLoggedStatus(true));
          localStorage.setItem("refreshToken", rs.data.data.refresh_token);
          localStorage.setItem("accessToken", rs.data.data.access_token);
          Axios.defaults.headers.common["Authorization"] = `Bearer ${rs.data.data.access_token}`;
          return Axios(originalConfig);
        } catch (_error) {
          setUserToken(userdata.accessToken);
          return Promise.reject(_error);
        }
      }
      if (originalConfig.url === `/refresh`) {
        setUserToken(userdata.accessToken);
      }
    }

    return Promise.reject(err);
  }
);
