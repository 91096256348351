import React from "react";
import "./edit-wishlist-list.styles.css";
import { Component } from "react";

import EditWishListItem from "../edit-wishlist-item/edit-wishlist-item.component";

import addImage from "../../assets/filled-plus.svg";
import tickImage from "../../assets/tick.svg";
import closeImage from "../../assets/close.svg";

import { Button, Select, message } from "antd";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import {
  selectAllMandis,
  selectAllVarietyInfo,
} from "../../redux/arrival-entry/arrival-entry.selectors";

import { selectInterestedWatchList } from "../../redux/alerts/alerts.selectors";
import { addInterestedWatchlistItem } from "../../redux/alerts/alerts.actions";

import api from "../../api";

const { Option } = Select;

/**
 * @component
 * @description The list component displaying the watchlists 
 */
class WishListList extends Component {
  state = {
    isAddVarietyButtonClicked: false,
    selectedVarietyId: null,
  };
  render() {
    //#region to display only available varieties
    let dropdownVarieties = [];

    const currentVarietyIdList = this.props.interestedWatchList.map(
      (item) => item.variety_id
    );
    const allVarietyIdList = this.props.allVarietyInfo.map(
      (item) => item.variety_id
    );
    //remove current varieties from all varieties
    const dropdownMandiIdList = allVarietyIdList.filter(
      (responseItem) => !currentVarietyIdList.includes(responseItem)
    );

    dropdownVarieties = this.props.allVarietyInfo.filter((variety) =>
      dropdownMandiIdList.includes(variety.variety_id)
    );

    //#endregion

    /**
     * @description Add a user interested variety to the watchlist.
     * @memberof WishListList
     */
    const addUserInterestedVariety = () => {
      if (!this.state.selectedVarietyId) {
        message.error("Please select a variety from the dropdown");
        return;
      }
      //#region api to add user interested variety and add to redux

      let dataToSend = {
        variety_id: this.state.selectedVarietyId,
        variety_mandi_list: [],
      };

      api
        .post("/user/varieties", dataToSend)
        .then((varietyResponse) => {
          if (varietyResponse.status) {
            //add to redux
            this.props.addInterestedWatchlistItem(varietyResponse.data.data);
          } else {
            message.error("Could not save.Please try again");
          }
        })
        .catch((err) => {
          message.error(
            "Could not save new variety.Please check your internet connection"
          );
        });

      //#endregion
      this.setState({ isAddVarietyButtonClicked: false });
      this.setState({ selectedVarietyId: null });
    };

    return (
      <div className="wishlist-list">
        {dropdownVarieties.length !== 0 ? (
          <div
            className="add-variety-button"
            onClick={() => this.setState({ isAddVarietyButtonClicked: true })}
          >
            <img className="add-image" src={addImage} alt="add" />
            <div className="add-variety-text">Add Variety</div>
          </div>
        ) : null}

        {this.state.isAddVarietyButtonClicked ? (
          <div className="add-new-variety-container">
            <Select
              className="variety-select"
              showArrow
              showSearch
              // defaultValue={dropdownVarieties[0].variety_id}
              // placeholder="Select Variety"
              defaultValue="Select Variety"
              onChange={(value) => this.setState({ selectedVarietyId: value })}
            >
              {dropdownVarieties.map((variety) => (
                <Option key={variety.variety_id} value={variety.variety_id}>
                  {variety.variety_name}
                </Option>
              ))}
            </Select>

            <div className="variety-button-container">
              <img
                onClick={() =>
                  this.setState({ isAddVarietyButtonClicked: false })
                }
                className="add-variety-image"
                src={closeImage}
                alt="close"
              />
              <img
                className="add-variety-image"
                src={tickImage}
                alt="add"
                onClick={() => addUserInterestedVariety()}
              />
            </div>
          </div>
        ) : null}

        {this.props.interestedWatchList.map((interestedItem) => (
          <EditWishListItem
            key={interestedItem.user_variety_id}
            item={interestedItem}
            allMandis={this.props.allMandis}
          />
        ))}
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  allVarietyInfo: selectAllVarietyInfo,
  allMandis: selectAllMandis,
  interestedWatchList: selectInterestedWatchList,
});

const mapDispatchToProps = (dispatch) => {
  return {
    addInterestedWatchlistItem: (item) =>
      dispatch(addInterestedWatchlistItem(item)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WishListList);
