import React, { useReducer } from "react";
import "./all-farmer-buying-master.styles.css";
import { Component } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUserData,selectMSMessageRBAC } from "../../redux/user/user.selectors";
import { canUserEdit,getUserMSRBACMessage } from "../../auth/utils";
import { FeatureCodes } from "../../auth/data";
import {
  Table,
  Spin,
  Input,
  Select,
  Button,
  message,
  Form,
  Modal,
  Radio,
} from "antd";

import API from "../../api";
const { Search } = Input;
const { Option } = Select;
/**
 * @description Component for user management tab
 *
 * @component
 */
class AllFarmerBuyingMaster extends Component {
  state = {
    isModalVisible: false,
    isAdd: false,
    currentRecord: null,
    userBranches: null,
    searchText: null,
    searchUserType: 0,
    branchList: [],
    stateList: [],
    hubList: [],
    isSpinnerOnLoad: false,
    isSubmitted: false,
    newData: {
      is_active: true,
      branch_name: "",
      branch_id: undefined,
      state_name: "",
      state_id: undefined,
      hub_name: "",
      hub_id: undefined,
      fbd_fbd_farmer_qty: undefined,
      daily_farmer_buying_in_mt: undefined,
      isEdit: false,
    },
    rowData: undefined,
    selectedRegionValue: undefined,
    selectedRegionName: "",
    selectedBranchValue: undefined,
    selectedBranchName: "",
    selectedBranchList: [],
    selectedRegionList: [],
    testvalue: undefined,
    AllFarmerBuyTableData: [],
  };
  componentDidMount() {
    this.setState({ isSpinnerOnLoad: true });
    this.getbranchList();
  }

  getStates = async () => {
   this.setState({ isSpinnerOnLoad: true });


      await API.get("/get_states")
        .then((stateLis) => {
         
          this.setState({
            stateList: stateLis.data.data,
          },
          () => this.getAllFarmerBuyingMaster());
        })
        .catch((err) => {
          if (err) {
            if (err.response && err.response.status === 400)
              message.error("Something went wrong. Please try again later.", 5);
            else {
              message.error("Something went wrong.. Please try again later.", 5);
            }
          } else {
            message.error("Something went wrong... Please try again later.", 5);
          }
          this.setState({ isSpinnerOnLoad: false });
        });
  };

  getbranchList = async () => {
    this.setState({ isSpinnerOnLoad: true });


    await API.get("/list_branch")
      .then((mandiBranch) => {

        let modifiedData = [...mandiBranch.data.data];
        modifiedData.push({ branch_id: null, branch_name: "All" });
        this.setState(
          {
            selectedHubList: modifiedData,
            selectedBranchList: mandiBranch.data.data,
            selectedBranchValue: mandiBranch.data.data[0].branch_id,
            selectedBranchName: mandiBranch.data.data[0].branch_name,
          },

          () => this.getHubList()
        );
      })
      .catch((err) => {
        this.setState({ isSpinnerOnLoad: false });
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
      });
  };

  getHubList = async () => {
    this.setState({ isSpinnerOnLoad: true });


    await API.get("/list_hubs")
      .then((mandiBranch) => {
        // let modifiedData = [...mandiBranch.data.data];
        // modifiedData.push({ branch_id: null, branch_name: "All" });
        const filterhub = mandiBranch.data.data.filter((hubItem) => hubItem.parent_id === this.state.selectedBranchValue);
        this.setState(
          {
            primaryHubList: mandiBranch.data.data,
            filteredHubList: filterhub,
            selectedHubValue: filterhub[0] ? filterhub[0].territory_id : 0,
            selectedHubName: filterhub[0] ? filterhub[0].territory_name : 0,
          },

          () => this.getStates()
        );
      })
      .catch((err) => {
        this.setState({ isSpinnerOnLoad: false });
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
      });
  };

  getAllFarmerBuyingMaster = async () => {
    console.log("farmer buying master ", );
    this.setState({ isSpinnerOnLoad: true });
    // const headers = {
    //   "Content-Type": "application/json",
    //   Authorization: "Bearer " + localStorage.getItem("accessToken"),
    // };

   // await API.get("/all_hub_farmer_buying", { headers: headers })
    await API.get("/all_hub_farmer_buying")
      .then((varietyResponse) => {

        varietyResponse.data.data.map((dataDetailItem, index) => {
          dataDetailItem.key = index;
          dataDetailItem.isEdit = false;
        });
      
        this.setState(
          {
            AllFarmerBuyTableData: varietyResponse.data.data,
            // InflationMasterTableData:[],
          },
          () => this.setState({ isSpinnerOnLoad: false, isAdd: true })
        );

      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400) message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };

  setTableData = () => {
   
    if (this.state.AllFarmerBuyTableData !== undefined || this.state.AllFarmerBuyTableData.length >= 0) {
      const userTableData = this.state.AllFarmerBuyTableData.map((user, index) => {
        return {
          key: user.key,
          branch_name: user.branch_name,
          branch_id: user.branch_id,
          state_name: user.state_name,
          state_id: user.state_id,
          hub_name: user.hub_name,
          hub_id: user.hub_id,
          fbd_farmer_qty: user.fbd_farmer_qty,
          daily_farmer_buying_in_mt: user.daily_farmer_buying_in_mt,
          isEdit: user.isEdit,
        };
      });

      let dataAfterSearch = userTableData;

      if (this.state.searchText) {
        dataAfterSearch = dataAfterSearch.filter(
          (item) =>
            (item.branch_name !== null &&
              item.branch_name
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase())) ||
            (item.hub_name !== null &&
              item.hub_name
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase())) ||
          (item.state_name !== null &&
            item.state_name
              .toLowerCase()
              .includes(this.state.searchText.toLowerCase())) 
        );
      }

      return dataAfterSearch;

    }
    else {
      console.log("set table data2 ", this.state.AllFarmerBuyTableData);
      return this.state.AllFarmerBuyTableData;
    }
  };

  submitTableRowData = (row, index, isPopup) => {
    console.log("row ", row);
    this.setState({ isSpinnerOnLoad: true, isSubmitted: true });
    let warningmessage = "";
    if (row.branch_name === null || row.branch_name === undefined || row.branch_name === "") {
      warningmessage = "Please select Branch";
    } else if (row.state_name === null || row.state_name === undefined || row.state_name === "") {
      warningmessage = "Please select State";
    } else if (row.hub_name === null || row.hub_name === undefined || row.hub_name === "") {
      warningmessage = "Please select Hub";
    } else if (row.fbd_farmer_qty === null || row.fbd_farmer_qty === undefined ||
      row.fbd_farmer_qty === "" || row.fbd_farmer_qty === 0) {
      warningmessage = "Please enter FBD Farmer Buying";
    }else if (row.daily_farmer_buying_in_mt === null || row.daily_farmer_buying_in_mt === undefined ||
        row.daily_farmer_buying_in_mt === "" || row.daily_farmer_buying_in_mt === 0) {
        warningmessage = "Please enter Daily Farmer Buying(MT)";
      }

    if (
      warningmessage === "" ||
      warningmessage === undefined ||
      warningmessage === null
    ) {
      this.setState({ isSpinnerOnLoad: true });
      this.setState({ isSubmitted: true })
      let data = {
        branch_name: row.branch_name,
        branch_id: row.branch_id,
        state_name: row.state,
        state_id: row.state_id,
        hub_name: row.hub_name,
        hub_id: row.hub_id,
        fbd_farmer_qty: row.fbd_farmer_qty,
        daily_farmer_buying_in_mt: row.daily_farmer_buying_in_mt,
      };

      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      };

      API.put("/all_hub_farmer_buying", data, {
        headers: headers,
      })
        .then((response) => {
          if (response.status === 200 && response.data.status) {
            message.info(response.data.message, 5);
            this.setModalVisibility(false);
            this.getAllFarmerBuyingMaster();
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
          } else if (!response.data.status) {
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
            message.error(response.data.message, 5);
            // this.setModalVisibility(false);
          } else {
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
            message.error("Please try again", 5);
          }
        })
        .catch((err) => {
          if (err) {
            if (err.response && err.response.status === 400) message.error("Something went wrong. Please try again later.", 5);
            else {
              message.error("Something went wrong.. Please try again later.", 5);
            }
          } else {
            message.error("Something went wrong... Please try again later.", 5);
          }
          this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
          // this.setModalVisibility(false);
        });
    } else {
      this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
      message.error(warningmessage, 5);
    }
  };

  setModalVisibility = (status) => {
    if (status) {
      const nData = {
        is_active: true,
        branch_name: "",
      branch_id: undefined,
      state_name: "",
      state_id: undefined,
      hub_name: "",
      hub_id: undefined,
      fbd_farmer_qty: undefined,
      daily_farmer_buying_in_mt: undefined,
        isEdit: false,
      };

      this.setState({ newData: nData });
    }

    this.setState({ isModalVisible: status });
  };

  TableColumnHeader = (props) => {
    const { title, subtitle } = props;
    return (
      <div className="column-header-container">
        <div
          style={{
            color: "white",
            fontSize: "12px",
            whiteSpace: "nowrap",
            fontWeight: "normal",
            textAlign: "left",
          }}
        >
          {title}
        </div>
        <div
          style={{
            color: "#cbdfff",
            fontSize: "11px",
            fontWeight: "normal",
            textAlign: "left",
            // subtitle && title.length < subtitle.length ? "left" : "center"
          }}
        >
          {subtitle}
        </div>
      </div>
    );
  };

  createFilters = (label) => {
    console.log("create filters" , this.state.AllFarmerBuyTableData);
    let filterData = this.state.AllFarmerBuyTableData;
    //#region remove duplicate objects
    let uniqueFilterData = [];

    //if(this.state.BranchTableData)

    if (label === "is_active" && this.state.AllFarmerBuyTableData && this.state.AllFarmerBuyTableData.length > 0) {
      uniqueFilterData = [{ text: "Yes", value: 1 }, { text: "No", value: 0 }]
    }
    else {
      filterData.map((mainItem) =>
        uniqueFilterData.filter((item) => item[label] === mainItem[label])
          .length > 0
          ? null
          : uniqueFilterData.push(mainItem)
      );

      uniqueFilterData = uniqueFilterData.map((item) => {
        return {
          text: item[label],
          value: item[label],
        };

      });

      uniqueFilterData.sort((a, b) => a.text.localeCompare(b.text))
    }

    return uniqueFilterData;
  };

  handleDropdownChange = (value, row, target, index, optionlabel, isPopup) => {
    let a;

    if (isPopup === false) {
      a = this.state.AllFarmerBuyTableData;

      if (target === "is_active") {
        a[row.key].is_active = Boolean(value);
      } else if (target === "branch_name") {
        a[row.key].branch_name = optionlabel;
        a[row.key].branch_id = value;
      } else if (target === "state_name") {
        a[row.key].state_name = optionlabel;
        a[row.key].state_id = value;
      } else if (target === "hub_name") {
        a[row.key].hub_name = optionlabel;
        a[row.key].hub_id = value;
      }
      this.setState({ AllFarmerBuyTableData: a });
    } else {
      a = this.state.newData;

      if (target === "is_active") {
        a.is_active = Boolean(value);
      } else if (target === "branch_name") {
        a.branch_name = optionlabel;
        a.branch_id = value;
      } else if (target === "state_name") {
        a.state_name = optionlabel;
        a.state_id = value;
      } else if (target === "hub_name") {
        a.hub_name = optionlabel;
        a.hub_id = value;
      }
      this.setState({ newData: a });
    }
  };

  handleChange = (e, row, index, name, isPopup) => {
    e.target.name = name;

    var alphaNumericRegex = /^[a-zA-Z0-9]*$/;
    var numberRegex = /^[0-9.]+$/;
    let a;

    if (isPopup === false) {
      a = this.state.AllFarmerBuyTableData;

      if (name === "fbd_farmer_qty") {
        a[row.key].fbd_farmer_qty = e.target.value;
      } else  if (name === "daily_farmer_buying_in_mt") {
        a[row.key].daily_farmer_buying_in_mt = e.target.value;
      }

      this.setState({ AllFarmerBuyTableData: a });
    } else {
      a = this.state.newData;

      if (name === "fbd_farmer_qty") {
        a.fbd_farmer_qty = e.target.value;
      } else if (name === "daily_farmer_buying_in_mt") {
        a.daily_farmer_buying_in_mt = e.target.value;
      }

      this.setState({ newData: a });
    }
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  handleEditChange = (row, index) => {
    console.log("user role id is ", this.props.userData.roleID );
    
    this.setState({ rowData: row });
    let tempData = this.state.AllFarmerBuyTableData.map((item) => {

      if (item.key === row.key) {
        return {
          ...item,
          isEdit: true
        }
      } else {
        return item;
      }
    })
    this.setState({ AllFarmerBuyTableData: tempData });
  
  };

  handleCancelClick = (rowData, index) => {
    let tempData = this.state.AllFarmerBuyTableData.map((item) => {
      if (item.key === rowData.key) {
        return this.state.rowData
      } else {
        return item;
      }
    })
    this.setState({ AllFarmerBuyTableData: tempData });
  };


  render() {
    const userTableProps = {
      pagination: false,
      size: "default",
      bordered: true,
      scroll: { x: 200, y: window.innerHeight - 250 },
    };

    return (
      <div
        className="mandi-master-container"
        style={{
          height: window.innerHeight - 85,
          marginTop: "-45px",
        }}
      >
        <div className="top-container">

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row-reverse",
              placeItems: "flex-end",
              marginRight: "25px",
              marginBottom: "10px",
            }}
          >
            <Search
              disabled={!this.state.isAdd}
              placeholder="Search"
              allowClear
              onChange={(e) => this.setState({ searchText: e.target.value })}
              style={{ width: "25%" }}
            />
         {/* <Button
              className="add-master-button"
              style={{
                height: "25px",
                width: "70px",
                backgroundColor: "#0b133d",
                color: "white",
              }}
              disabled={!this.state.isAdd || !canUserEdit(
                this.props.userData,
                null,
                FeatureCodes.MASTER_SCREEN_OPS_EDIT
              )}
              onClick={() => this.setModalVisibility(true)}
            >
              &#10010; Add
              </Button> */}
          </div>
        </div>

        <div>
        <div
        style={{
          marginLeft: "25px",
          marginTop: "-8px",
          fontSize: "14px",
          color: "red",
          display: "flex",
          float: "left",
        }}
      > {getUserMSRBACMessage(this.props.messageAccess,FeatureCodes.MS_ALL_FARMER_EDIT)}
      </div>
        <div
          style={{
            marginRight: "20px",
            marginTop: "-8px",
            fontSize: "15px",
            color: "#0B133D",
            display: "flex",
            float: "right",
          }}
        >Total number of records:&nbsp;
          <b>{this.state.AllFarmerBuyTableData && this.setTableData().length}</b>
        </div>
        </div>
        
        <Spin
          spinning={this.state.isSpinnerOnLoad}
          size={"large"}
          tip="Loading..."
          style={{ alignSelf: "center", zIndex: 9999, marginTop: "20px" }}
        >
          <Modal
            className="add-master-modal"
            title={"Add All Farmer Buying"}
            onCancel={this.handleCancel}
            footer={false}
            visible={this.state.isModalVisible}
            destroyOnClose={true}
          >
            <div className="master-form-container">
              <Form
                layout="horizontal"
                //   onValuesChange={onFormLayoutChange}
                size={"small"}
              // onSubmit={submitTableRowData(this.state.newData)}
              >
                <div className="form-top-container1">
                  <div
                    className="checkbox-group1"
                    style={{
                      display: "inline-block",
                      width: "40%",
                      marginRight: 40,
                    }}
                  >
                    <Form.Item label="Branch">
                      <Select
                        showSearch
                        optionFilterProp="children"
                        className="remarks-select"
                        name="branch_name"
                        defaultValue={this.state.newData.branch_name}
                        value={this.state.newData.branch_name}
                        onSelect={(value, option) =>
                          this.handleDropdownChange(
                            value,
                            this.state.newData,
                            "branch_name",
                            -0,
                            option.props.children,
                            true
                          )
                        }
                      >
                        {this.state.selectedBranchList &&
                          this.state.selectedBranchList.length &&
                          this.state.selectedBranchList.map((hubItem, idx) => (
                            <Option
                              key={hubItem.branch_id}
                              value={hubItem.branch_id}
                            >
                              {hubItem.branch_name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </div>

                  <div
                    className="checkbox-group1"
                    style={{
                      display: "inline-block",
                      width: "40%",
                      marginRight: 40,
                    }}
                  >
                    <Form.Item label="State">
                      <Select
                        showSearch
                        optionFilterProp="children"
                        className="remarks-select"
                        name="state_name"
                        defaultValue={this.state.newData.state_name}
                        value={this.state.newData.state_name}
                        onSelect={(value, option) =>
                          this.handleDropdownChange(
                            value,
                            this.state.newData,
                            "state_name",
                            -0,
                            option.props.children,
                            true
                          )
                        }
                      >
                        {this.state.stateList &&
                          this.state.stateList.length &&
                          this.state.stateList.map((hubItem, idx) => (
                            <Option
                              key={hubItem.state_id}
                              value={hubItem.state_id}
                            >
                              {hubItem.state_name}
                            </Option>
                          ))}
                      </Select>

                    </Form.Item>
                  </div>

                  <div
                    className="checkbox-group1"
                    style={{
                      height: "45px",
                      display: "inline-block",
                      width: "40%",
                      marginRight: 40,
                    }}
                  >
                    <Form.Item label="Hub">
                      <Select
                        showSearch
                        optionFilterProp="children"
                        className="remarks-select"
                        name="hub_name"
                        defaultValue={this.state.newData.hub_name}
                        value={this.state.newData.hub_name}
                        onSelect={(value, option) =>
                          this.handleDropdownChange(
                            value,
                            this.state.newData,
                            "hub_name",
                            -0,
                            option.props.children,
                            true
                          )
                        }
                      >
                        {this.state.primaryHubList &&
                          this.state.primaryHubList.length &&
                          this.state.primaryHubList.map((hubItem, idx) => (
                            <Option
                              key={hubItem.territory_id}
                              value={hubItem.territory_id}
                            >
                              {hubItem.territory_name}
                            </Option>
                          ))}
                      </Select>

                    </Form.Item>
                  </div>

                  <div
                    className="checkbox-group1"
                    style={{
                      height: "45px",
                      display: "inline-block",
                      width: "40%",
                      marginRight: 40,
                    }}
                  >
                    <Form.Item label="Farmer Buying Qty(MT)">
                      <Input
                        type="number"
                        style={{ marginRight: "2px" }}
                        maxLength={100}
                        min="0"
                        value={this.state.newData.fbd_farmer_qty}
                        name="fbd_farmer_qty"
                        onChange={(e) =>
                          this.handleChange(
                            e,
                            this.state.newData,
                            0,
                            "fbd_farmer_qty",
                            true
                          )
                        }
                      />
                    </Form.Item>
                  </div>

                  <div
                  className="checkbox-group1"
                  style={{
                    height: "45px",
                    display: "inline-block",
                    width: "45%",
                    marginRight: 40,
                  }}
                >
                  <Form.Item label="Daily Farmer Buying(MT)">
                    <Input
                      type="number"
                      style={{ marginRight: "2px" }}
                      maxLength={100}
                      min="0"
                      value={this.state.newData.daily_farmer_buying_in_mt}
                      name="daily_farmer_buying_in_mt"
                      onChange={(e) =>
                        this.handleChange(
                          e,
                          this.state.newData,
                          0,
                          "daily_farmer_buying_in_mt",
                          true
                        )
                      }
                    />
                  </Form.Item>
                </div>

                </div>

                <div className="form-bottom-container" style={{ marginTop: "10px" }}>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={this.state.isSubmitted}
                      onClick={() =>
                        this.submitTableRowData(this.state.newData, 0, true)
                      }
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </Modal>


          <div className="table-container1" style={{ maxHeight: "400px", width: "99%", margin: "6px 0px" }}>
            <Table
              {...userTableProps}
              columns={[

                {
                  title: "Branch",
                  dataIndex: "branch_name",
                  key: "branch_name",
                  width: 125,
                  filters: this.createFilters("branch_name"),
                  onFilter: (value, record) =>
                    record.branch_name.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.branch_name.localeCompare(b.branch_name),
                  render: (record, rowRecord) => {
                    return (
                      <>
                        <div
                          style={{ marginLeft: "-15px", marginRight: "-10px" }}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            disabled={true}//{!rowRecord.isEdit}
                            className="remarks-select"
                            name="branch_name"
                            defaultValue={rowRecord.branch_name}
                            value={rowRecord.branch_name}
                            onSelect={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "branch_name",
                                -0,
                                option.props.children,
                                false
                              )
                            }
                          >
                            {this.state.selectedBranchList &&
                              this.state.selectedBranchList.length &&
                              this.state.selectedBranchList.map((hubItem, idx) => (
                                <Option
                                  key={hubItem.branch_id}
                                  value={hubItem.branch_id}
                                >
                                  {hubItem.branch_name}
                                </Option>
                              ))}
                          </Select>
                        </div>
                      </>
                    );
                  },
                },
                {
                  title: "State",
                  dataIndex: "state_name",
                  key: "state_name",
                  width: 125,
                  filters: this.createFilters("state_name"),
                  onFilter: (value, record) =>
                    record.state_name.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.state_name.localeCompare(b.state_name),
                  render: (record, rowRecord) => {
                    return (
                      <>
                        <div
                          style={{ marginLeft: "-15px", marginRight: "-10px" }}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            disabled={true}//{!rowRecord.isEdit}
                            className="remarks-select"
                            name="state_name"
                            defaultValue={rowRecord.state_name}
                            value={rowRecord.state_name}
                            onSelect={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "state_name",
                                -0,
                                option.props.children,
                                false
                              )
                            }
                          >
                            {this.state.stateList &&
                              this.state.stateList.length &&
                              this.state.stateList.map((hubItem, idx) => (
                                <Option
                                  key={hubItem.state_id}
                                  value={hubItem.state_id}
                                >
                                  {hubItem.state_name}
                                </Option>
                              ))}
                          </Select>
                        </div>
                      </>
                    );
                  },
                },
                {
                  title: "Hub",
                  dataIndex: "hub_name",
                  key: "hub_name",
                  width: 125,
                  filters: this.createFilters("hub_name"),
                  onFilter: (value, record) =>
                    record.hub_name.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.hub_name.localeCompare(b.hub_name),
                  render: (record, rowRecord) => {
                    return (
                      <>
                        <div
                          style={{ marginLeft: "-15px", marginRight: "-10px" }}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            disabled={true}//{!rowRecord.isEdit}
                            className="remarks-select"
                            name="hub_name"
                            defaultValue={rowRecord.hub_name}
                            value={rowRecord.hub_name}
                            onSelect={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "hub_name",
                                -0,
                                option.props.children,
                                false
                              )
                            }
                          >
                            {this.state.primaryHubList &&
                              this.state.primaryHubList.length &&
                              this.state.primaryHubList.map((hubItem, idx) => (
                                <Option
                                  key={hubItem.territory_id}
                                  value={hubItem.territory_id}
                                >
                                  {hubItem.territory_name}
                                </Option>
                              ))}
                          </Select>
                        </div>
                      </>
                    );
                  },
                },
                {
                  key: "fbd_farmer_qty",
                  title: "FBD Farmer Qty",
                  dataIndex: "fbd_farmer_qty",
                  width: 150,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.fbd_farmer_qty - b.fbd_farmer_qty,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            type="number"
                            disabled={!rowRecord.isEdit}
                            style={{ marginRight: "2px" }}
                            maxLength={100}
                            min="0"
                            value={rowRecord.fbd_farmer_qty}
                            name="fbd_farmer_qty"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "fbd_farmer_qty",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },
                {
                    key: "daily_farmer_buying_in_mt",
                    title: "Daily Farmer Buying(MT)",
                    dataIndex: "daily_farmer_buying_in_mt",
                    width: 150,
                    sortDirections: ["descend", "ascend"],
                    sorter: (a, b) => a.daily_farmer_buying_in_mt - b.daily_farmer_buying_in_mt,
                    render: (record, rowRecord, index) => {
                      return (
                        <>
                          <div style={{ marginLeft: "-20px" }}>
                            <Input
                              type="number"
                              disabled={!rowRecord.isEdit}
                              style={{ marginRight: "2px" }}
                              maxLength={100}
                              min="0"
                              value={rowRecord.daily_farmer_buying_in_mt}
                              name="daily_farmer_buying_in_mt"
                              onChange={(e) =>
                                this.handleChange(
                                  e,
                                  rowRecord,
                                  index,
                                  "daily_farmer_buying_in_mt",
                                  false
                                )
                              }
                            />
                          </div>
                        </>
                      );
                    },
                  },
                {
                  title: "Action",
                  dataIndex: "Submit",
                  key: "Submit",
                  width: 80,
                  visibility: false,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ display: "inline-flex", width: "100%" }}>
                          <Button
                          className={!canUserEdit(
                            this.props.userData,
                            null,
                            FeatureCodes.MS_ALL_FARMER_EDIT
                          ) ? "disabledBtnClass" : "btnClass"}
                            disabled={!canUserEdit(
                              this.props.userData,
                              null,
                              FeatureCodes.MS_ALL_FARMER_EDIT
                            )}
                            type="primary"
                            size={"small"}
                            style={{
                              marginLeft: 5,
                              alignSelf: "center",
                              padding: "0px 0px 2px",
                              height: 31,
                              backgroundColor: "#20346a",
                              color: "white",
                              borderRadius: 15,
                            }}
                            block
                            htmlType="submit"
                            onClick={() =>
                              rowRecord.isEdit
                                ? this.submitTableRowData(rowRecord, index, false)
                                : this.handleEditChange(rowRecord, index)
                            }
                          >
                            {" "}
                            {rowRecord.isEdit ? "Submit" : "Edit"}{" "}
                          </Button>


                          <Button
                            className={!rowRecord.isEdit ? "disabledBtnClass" : "btnClass"}
                            type="primary"
                            size={"small"}
                            style={{
                              marginLeft: 5,
                              alignSelf: "center",
                              color: "white",
                              borderRadius: 15,
                              padding: "0px 0px 2px",
                              height: 31,
                              backgroundColor: "#20346a",
                            }}
                            block
                            htmlType="submit"
                            disabled={!rowRecord.isEdit}
                            onClick={() => this.handleCancelClick(rowRecord, index)
                            }
                          >
                            Cancel
                          </Button>

                        </div>
                      </>
                    );
                  },
                },
              ]}
              dataSource={this.setTableData()}
            />
          </div>

         

        </Spin>
       
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
  messageAccess: selectMSMessageRBAC,
});

export default connect(mapStateToProps)(AllFarmerBuyingMaster);

// export default AllFarmerBuyingMaster;
