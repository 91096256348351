import React from "react";
import "./qc-input-form.styles.css";

import { Form, Input, InputNumber, Button, Checkbox, Modal } from "antd";

class QCInputForm extends React.Component {
  state = {
    isImpactAssessment: false,
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.data.impact_assessment !== this.props.data.impact_assessment
    ) {
      this.setState({ isImpactAssessment: this.props.data.impact_assessment });
    }
  }

  render() {
    const { data, clearFormData, postData } = this.props;
    const { getFieldDecorator } = this.props.form;

    console.log(data);

    const handleSubmit = (e) => {
      e.preventDefault();
      this.props.form.validateFields((err, values) => {
        if (!err) {
          console.log("form values : ", values);
          postData(values);
        }
      });
    };

    const onImpactAssessmentChanged = (value) => {
      this.setState({ isImpactAssessment: value });
    };

    const handleReset = () => {
      clearFormData();
      // this.props.form.resetFields();
    };

    return (
      <div className="qc-input-form-container box-shadow-card ">
        <div className="row title">Quality Check Input Form</div>
        <Form onSubmit={handleSubmit}>
          <div className="row">
            <div className="column top">
              <Form.Item label="Buying Factor - Max Max">
                {getFieldDecorator("buying_factor_Max_Max", {
                  initialValue: data.buying_factor_Max_Max,
                  rules: [{ required: true, message: "Please enter a value!" }],
                })(
                  <InputNumber
                    min={0}
                    // value={data?.buying_factor_Max_Max}
                    placeholder="Buying Factor - Max Max"
                  />
                )}
              </Form.Item>
              <Form.Item label="Buying Factor - Min Min">
                {getFieldDecorator("buying_factor_Min_Min", {
                  initialValue: data.buying_factor_Min_Min,
                  rules: [{ required: true, message: "Please enter a value!" }],
                })(
                  <InputNumber
                    min={0}
                    // value={data?.buying_factor_Min_Min}
                    placeholder="Buying Factor - Min Min"
                  />
                )}
              </Form.Item>
              <Form.Item label="Arrivals - Max">
                {getFieldDecorator("arrivals_max_factor", {
                  initialValue: data.arrivals_max_factor,
                  rules: [{ required: true, message: "Please enter a value!" }],
                })(
                  <InputNumber
                    min={0}
                    // value={data?.arrivals_max_factor}
                    placeholder="Arrivals - Max"
                  />
                )}
              </Form.Item>
            </div>
            <div className="column top">
              <Form.Item label="Arrivals - Max Max">
                {getFieldDecorator("arrivals_max_max_factor", {
                  initialValue: data.arrivals_max_max_factor,
                  rules: [{ required: true, message: "Please enter a value!" }],
                })(
                  <InputNumber
                    min={0}
                    // value={data?.arrivals_max_max_factor}
                    placeholder="Arrivals - Max Max"
                  />
                )}
              </Form.Item>

              <Form.Item label="Limit - Max Max">
                {getFieldDecorator("max_max_limit", {
                  initialValue: data.max_max_limit,
                  rules: [{ required: true, message: "Please enter a value!" }],
                })(
                  <InputNumber
                    min={0}
                    // value={data?.max_max_limit}
                    placeholder="Limit - Max Max"
                  />
                )}
              </Form.Item>
              <Form.Item label="Limit - Max">
                {getFieldDecorator("max_limit", {
                  initialValue: data.max_limit,
                  rules: [{ required: true, message: "Please enter a value!" }],
                })(
                  <InputNumber
                    min={0}
                    // value={data?.max_limit}
                    placeholder="Limit - Max"
                  />
                )}
              </Form.Item>
            </div>
            <div className="column top">
              <Form.Item label="Limit - Average Plus">
                {getFieldDecorator("average_plus_limit", {
                  initialValue: data.average_plus_limit,
                  rules: [{ required: true, message: "Please enter a value!" }],
                })(
                  <InputNumber
                    min={0}
                    // value={data?.average_plus_limit}
                    placeholder="Limit - Average Plus"
                  />
                )}
              </Form.Item>
              <Form.Item label="Limit - Average">
                {getFieldDecorator("average_limit", {
                  initialValue: data.average_limit,
                  rules: [{ required: true, message: "Please enter a value!" }],
                })(
                  <InputNumber
                    min={0}
                    // value={data?.average_limit}
                    placeholder="Limit - Average"
                  />
                )}
              </Form.Item>
              <Form.Item label="Penalty Reduction Factor">
                {getFieldDecorator("penalty_factor_reduction", {
                  initialValue: data.penalty_factor_reduction,
                  rules: [{ required: true, message: "Please enter a value!" }],
                })(
                  <InputNumber
                    min={0}
                    // value={data?.penalty_factor_reduction}
                    placeholder="Penalty Reduction Factor"
                  />
                )}
              </Form.Item>
            </div>

            <div className="column top">
              <Form.Item label="Threshold For Arrivals">
                {getFieldDecorator("threshold_for_arrivals", {
                  initialValue: data.threshold_for_arrivals,
                  rules: [
                    {
                      required: this.state.isImpactAssessment,
                      message: "Please enter a value!",
                    },
                  ],
                })(
                  <InputNumber
                    min={0}
                    // value={data?.threshold_for_arrivals}
                    disabled={!this.state.isImpactAssessment}
                    placeholder="Threshold For Arrivals"
                  />
                )}
              </Form.Item>
              <Form.Item label="Maximum Buying">
                {getFieldDecorator("max_buying", {
                  initialValue: data.max_buying,
                  rules: [
                    {
                      required: this.state.isImpactAssessment,
                      message: "Please enter a value!",
                    },
                  ],
                })(
                  <InputNumber
                    min={0}
                    // value={data?.max_buying}
                    disabled={!this.state.isImpactAssessment}
                    placeholder="Maximum Buying"
                  />
                )}
              </Form.Item>
              <Form.Item label="Scale Factor For Arrivals">
                {getFieldDecorator("scale_factor_for_arrivals", {
                  initialValue: data.scale_factor_for_arrivals,
                  rules: [
                    {
                      required: this.state.isImpactAssessment,
                      message: "Please enter a value!",
                    },
                  ],
                })(
                  <InputNumber
                    min={0}
                    // value={data?.scale_factor_for_arrivals}
                    disabled={!this.state.isImpactAssessment}
                    placeholder="Scale Factor For Arrivals"
                  />
                )}
              </Form.Item>
            </div>
          </div>
          <div className="column top">
            <div className="row">
              <Form.Item>
                {getFieldDecorator("demand_constraint", {
                  valuePropName: "checked",
                  initialValue: data.demand_constraint,
                })(<Checkbox>Demand Constraint</Checkbox>)}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("impact_assessment", {
                  valuePropName: "checked",
                  initialValue: false, //data.impact_assessment,
                })(
                  <Checkbox
                    onChange={(e) =>
                      onImpactAssessmentChanged(e.target.checked)
                    }
                  >
                    Impact Assessment
                  </Checkbox>
                )}
              </Form.Item>
            </div>
          </div>

          <div className="column">
            <div className="row">
              <Form.Item>
                <Button className="submit-button clear" onClick={handleReset}>
                  Clear
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="submit-button"
                >
                  Submit
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

const QCModelInputForm = Form.create({ name: "normal_login" })(QCInputForm);

export default QCModelInputForm;
