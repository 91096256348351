import React from "react";
import "./weekly-quality-check-display.styles.css";

import check1Image from "../../assets/weekly/1nobg.png";
import check2Image from "../../assets/weekly/2nobg.png";
import check3Image from "../../assets/weekly/3nobg.png";
import check4Image from "../../assets/weekly/4nobg.png";
import check5Image from "../../assets/weekly/5nobg.png";

import QualityCheckCard from "../weekly-quality-modal-card/weekly-quality-modal-card.component";
import { useState } from "react";
import { Spin, message, Empty } from "antd";
import { getWeeklyGraphData } from "../../services/generic-apis";
import { useEffect } from "react";
import { Line, Bar, HorizontalBar } from "react-chartjs-2";

const GraphQ1 = (props) => {
  const {
    graphData: { W1_pred, W2_pred, W3_pred, month_1, month_2, month_3 },
  } = props;
  const data = {
    labels: ["W1", "W2", "W3", "M1", "M2", "M3"],
    datasets: [
      {
        label: "Weeks",
        data: [W1_pred, W2_pred, W3_pred, null, null, null],
        backgroundColor: "#09b008",
      },
      {
        label: "Months",
        data: [null, null, null, month_1, month_2, month_3],
        backgroundColor: "#0079bf",
      },
    ],
  };
  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };
  return (
    <div>
      <Bar width={800} height={200} data={data} options={options} />
    </div>
  );
};

const GraphQ2 = (props) => {
  const {
    graphData: { W0_1, W0 },
  } = props;
  const data = {
    labels: ["W0-1", "W0"],
    datasets: [
      {
        label: "Weeks",
        data: [Math.abs(W0_1), Math.abs(W0)],
        backgroundColor: "#0079bf",
      },
    ],
  };
  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };
  return (
    <div>
      <Bar width={800} height={200} data={data} options={options} />
    </div>
  );
};

const GraphQ3 = (props) => {
  const {
    graphData: { p1, p2, p3 },
  } = props;
  const data = {
    labels: ["W1", "W2", "W3"],
    datasets: [
      {
        label: "Weeks",
        data: [p1, p2, p3],
        backgroundColor: "#0079bf",
        borderColor: "#0079bf",
        fill: false,
      },
    ],
  };
  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };
  return (
    <div>
      <Line width={750} height={200} data={data} options={options} />
    </div>
  );
};
const GraphQ4 = (props) => {
  const {
    graphData: { W0_1, W0 },
  } = props;
  const data = {
    labels: ["Accuracy"],
    datasets: [
      {
        label: "Week 0-1",
        data: [W0_1],
        backgroundColor: "#09b008",
      },
      {
        label: "Week 0",
        data: [W0_1],
        backgroundColor: "#0079bf",
      },
    ],
  };
  const options = {
    scales: {
      xAxes: [
        {
          id: "x-axis",
          stacked: true,
          ticks: {
            maxRotation: 0,
          },
          scaleLabel: {
            display: true,
          },
        },
      ],
      yAxes: [
        {
          id: "y-axis",
          stacked: true,
          ticks: { beginAtZero: true, display: false },
          scaleLabel: {
            display: false,
            labelString: "Volume (MT)",
          },
        },
      ],
    },
  };
  return (
    <div>
      <HorizontalBar width={800} height={200} data={data} options={options} />
    </div>
  );
};
const GraphQ5 = (props) => {
  const { graphData } = props;
  const data = {
    labels: [graphData[0].week_start, graphData[1].week_start],
    datasets: [
      {
        label: "Observation 1",
        data: [graphData[0].obs1, graphData[1].obs1],
        backgroundColor: "#09b008",
        borderColor: "#09b008",
        fill: false,
      },
      {
        label: "Observation 2",
        data: [graphData[0].obs2, graphData[1].obs2],
        backgroundColor: "#0079bf",
        borderColor: "#0079bf",
        fill: false,
      },
    ],
  };
  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };
  return (
    <div>
      <Line width={750} height={200} data={data} options={options} />
    </div>
  );
};

/**
 * @component
 * @description the container component for displaying the different quality checks.
 * @property {object} scores the quality check scores to be displayed.
 * @property {function} setConfidenceColor function to change the background
 * color of the total score display area.
 */
const QualityCheckDisplay = (props) => {
  const {
    setModalVisibility,
    setConfidenceColor,
    scores,
    stateID,
    varietyID,
    weekRun,
  } = props;

  const [graphData, setGraphData] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    console.log("params :", stateID, varietyID, weekRun);
    getWeeklyGraphValues();
  }, []);

  const getWeeklyGraphValues = async () => {
    setIsLoading(true);

    try {
      const graphResponse = await getWeeklyGraphData(
        stateID,
        varietyID,
        weekRun
      );
      if (graphResponse.data.status) {
        console.log("graph data : ", graphResponse.data.data);
        setIsLoading(false);
        setGraphData(graphResponse.data.data);
      } else {
        setIsLoading(false);
        message.warning(
          "Could not retrieve graph data for weekly.Please refresh the page"
        );
      }
    } catch (error) {
      setIsLoading(false);
      message.error(
        "Could not retrieve graph data for weekly.Please check your network connectivity."
      );
    }
  };

  console.log("scores:", scores);
  return (
    <Spin spinning={isLoading}>
      {graphData ? (
        <div className="weekly-quality-check-container">
          <QualityCheckCard
            qcScore={scores.qc1_monthly_trend}
            title="QC Check 1"
            description="Consistency with monthly trends"
            image={check1Image}
            GraphComponent={() => <GraphQ1 graphData={graphData.qc1[0]} />}
          />
          <QualityCheckCard
            qcScore={scores.qc2_error_2weeks}
            title="QC Check 2"
            description="Error in previous 2 week forecasts"
            image={check2Image}
            GraphComponent={() => <GraphQ2 graphData={graphData.qc2} />}
          />
          <QualityCheckCard
            qcScore={scores.qc3_inflection}
            title="QC Check 3"
            description="Inflection point in price forecast"
            image={check3Image}
            GraphComponent={() => <GraphQ3 graphData={graphData.qc3[0]} />}
          />
          <QualityCheckCard
            qcScore={scores.qc4_across_sv}
            title="QC Check 4"
            description="Performance across SV weekly models"
            image={check4Image}
            GraphComponent={() => <GraphQ4 graphData={graphData.qc4} />}
          />
          <QualityCheckCard
            qcScore={scores.qc5_direction_2weeks}
            title="QC Check 5"
            description="Consistent trends direction across weeks"
            image={check5Image}
            GraphComponent={() => <GraphQ5 graphData={graphData.qc5} />}
          />
          <div className="total-info-container">
            <div className="title">Total Score</div>
            <div className="score">
              <div
                className="round"
                style={{
                  backgroundColor: `${setConfidenceColor(
                    scores.total_confidence
                  )}`,
                }}
              >
                <div className="value">{scores.total_confidence}</div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Empty description="No graph data available." />
      )}
    </Spin>
  );
};

export default QualityCheckDisplay;
