import { WeatherActionTypes } from "./weather.types";

import { createSelector } from "reselect";

const selectWeather = (state) => state.weather;

export const selectWeatherData = createSelector(
  [selectWeather],
  (weather) => weather.weather
);

export const selectLatitude = createSelector(
  [selectWeather],
  (weather) => weather.lat
);

export const selectLongitude = createSelector(
  [selectWeather],
  (weather) => weather.lon
);
