import { MapActionTypes } from "./map.types";

export const setCurrentArrivalScreen = screenName => {
  return {
    type: MapActionTypes.SET_CURRENT_SCREEN,
    payload: screenName
  };
};

export const setMapMandiSelectValues = (stateName, stateValue) => {
  return {
    type: MapActionTypes.SET_MAP_MANDI_SELECT_VALUES,
    payload: stateValue,
    payloadType: stateName
  };
};

export const setMandiRegion = name => {
  return {
    type: MapActionTypes.SET_MANDI_REGION,
    payload: name
  };
};

export const setMandiHub = name => {
  return {
    type: MapActionTypes.SET_MANDI_HUB,
    payload: name
  };
};

export const setMandiMandi = name => {
  return {
    type: MapActionTypes.SET_MANDI_MANDI,
    payload: name
  };
};

export const setMapFORSelectValues = (stateName, stateValue) => {
  return {
    type: MapActionTypes.SET_MAP_FOR_SELECT_VALUES,
    payload: stateValue,
    payloadType: stateName
  };
};

export const setFORRegion = name => {
  return {
    type: MapActionTypes.SET_FOR_REGION,
    payload: name
  };
};

export const setFORHub = name => {
  return {
    type: MapActionTypes.SET_FOR_HUB,
    payload: name
  };
};

export const setFarmerRegion = name => {
  return {
    type: MapActionTypes.SET_FARMER_REGION,
    payload: name
  };
};

export const setFarmerHub = name => {
  return {
    type: MapActionTypes.SET_FARMER_HUB,
    payload: name
  };
};
