import "./chart-user-adoption.styles.css";
import { Doughnut, Line } from "react-chartjs-2";
import Chart from "chart.js";

import React from "react";
import "../../pages/summary/summary.styles.css";
import moment from "moment";

import { DatePicker, Button, Spin, message, Progress } from "antd";

import API, { baseURL } from "../../api";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { setAuditModalVisibility } from "../../redux/summary/summary.actions";
import { selectIsModalVisible } from "../../redux/summary/summary.selectors";

import { setSummaryVisibility } from "../../redux/arrival-entry/arrival-entry.actions";

import CustomSelectSummary from "../../components/custom-select-summary/custom-select-summary.component";
import Axios from "axios";

const FileDownLoad = require("js-file-download");
const { RangePicker } = DatePicker;

//Set chart custom options (Set text inside doughnut)
var originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
  draw: function () {
    originalDoughnutDraw.apply(this, arguments);

    var chart = this.chart.chart;
    var ctx = chart.ctx;
    var width = chart.width;
    var height = chart.height;

    var fontSize = (height / 11).toFixed(2);
    ctx.font = `bold ${fontSize}px 'Helvetica Neue', Helvetica, Arial, sans-serif`;
    ctx.fillStyle = "#3f3f3f";
    ctx.textBaseline = "middle";
    var typeText = chart.config.data.typeText;
    var text = chart.config.data.text,
      textX = Math.round((width - ctx.measureText(text).width) / 2) + 3,
      textY = height / 2 - 7;
    if (text) ctx.fillText(`${text}`, textX, textY);

    ctx.font = `bold ${
      fontSize / 2.1
    }px 'Helvetica Neue', Helvetica, Arial, sans-serif`;
    ctx.fillStyle = "#808080";
    ctx.textBaseline = "middle";
    var textX2 = Math.round((width - ctx.measureText("Utilizing").width) / 3);
    if (text) ctx.fillText(`${typeText} Arrival Entered`, textX2, textY + 20);
  },
});

//Default chart to show while loading actual data
const defaultTrendGraph = {
  datasets: [
    {
      label: "Entered",
      data: [],
      backgroundColor: "#1ca6ed",
      pointBorderColor: "#0251fa00",
      pointBackgroundColor: "#0251fa00",
      borderColor: "#0251fa00",
    },
  ],
  labels: [],
};

/**
 *
 * @description User adoption chart container in Report page.
 * @component
 * @extends {React.Component}
 */
class UserAdoptionChart extends React.Component {
  state = {
    buyerType: "Mandi",
    selectedTerritoryIndex: 0,
    selectedTerritoryType: "Branch",
    selectedBranch: "",
    selectedRegion: "",
    selectedHub: "",
    selectedMandi: "",
    selectedVariety: "",
    dropdownData: {
      branches: [],
      regions: [],
      hubs: [],
      mandis: [],
      varieties: [],
    },
    defaultRegion: "Select Region",
    defaultHub: "Select Hub",
    defaultMandi: "Select Mandi",
    lastClicked: "",
    selectedBuyerType: "",
    selectedBranchVal: "",
    selectedRegionVal: "",
    selectedHubVal: "",
    selectedMandiVal: "",
    selectedVarietyVal: "",
    gradient: "white",
    selectedTerritoryId: null,
    startDateTrend: moment().subtract(30, "days").format("YYYY-MM-DD"),
    endDateTrend: moment().format("YYYY-MM-DD"),

    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    mandiTableData: [],
    mandiModalTableData: [],
    selectedRowKey: "",
    spinnerOn: true,
    chartArrivalName: [],
    chartArrivalPercent: [],
    singleArrivalDataList: [],
    selectedTab: "arrival",
    spinnerForTrend: false,
    isSpinnerOn: false,
    isSpinnerOnProgress: false,
    ProgressClicked: false,
    trendChartData: defaultTrendGraph,

    dataForTrendChart: {
      labels: [0],
      datasets: [
        {
          data: [0],
          backgroundColor: "#0251fa",
          fill: false,
          borderColor: "#0251fa",
          label: "Volume",
        },
      ],
    },
    dataArrivals: {
      datasets: [
        {
          data: [0, 100],
          backgroundColor: ["#0251fa", "#f4f5f9"],
        },
      ],
      labels: ["Submitted", "Not submitted"],
    },
  };
  //Default chart to show while loading actual data
  defaultArrival = {
    datasets: [
      {
        data: [0, 100],
        backgroundColor: ["#0251fa", "#f4f5f9"],
      },
    ],
    labels: ["Submitted", "Not submitted"],
    text: "0.00%",
  };

  /**
   * @function
   * @description Retrieve and set data in user adoption trend chart
   * @param {string} startDate Start date of date range
   * @param {string} endDate End date of date range
   * @param {string} branchId Branch ID
   * @param {string} hubId Hub ID
   * @param {string} mandiId Mandi ID
   * @param {string} arrivalType Arrival type (Mandi/FOR)
   * @memberof UserAdoptionChart
   */
  setTrendChart = (
    startDate,
    endDate,
    branchId,
    hubId,
    mandiId,
    arrivalType
  ) => {
    const chartParams = {
      arrival_type: arrivalType,
      start_date: startDate,
      end_date: endDate,
      branch_id:
        this.state.selectedTerritoryType === "Branch" ? branchId : null,
      hub_id: this.state.selectedTerritoryType === "Hub" ? hubId : null,
      mandi_id: this.state.selectedTerritoryType === "Mandi" ? mandiId : null,
    };

    this.setState({ spinnerForTrend: true });

    API.get("/reports/user_adoption/trend", {
      params: chartParams,
    }).then((response) => {
      const chartLabel = response.data.data.arrival_user_adoption.map((item) =>
        item.date.toString().substring(5, 11)
      );
      const chartPercentage = response.data.data.arrival_user_adoption.map(
        (item) => (parseFloat(item.rate) * 100).toFixed(2)
      );

      var c = document.getElementById("trendChart");
      var ctx;
      var gradientStroke;
      if (c != null) {
        ctx = c.getContext("2d");
        gradientStroke = ctx.createLinearGradient(0, 500, 0, 100);
        gradientStroke.addColorStop(
          0,
          this.state.selectedTab === "arrival" ? "#1ca6ed90" : "#ff1b5a90"
        );
        gradientStroke.addColorStop(
          1,
          this.state.selectedTab === "arrival" ? "#85d8f290" : "#fb8aa990"
        );
        // gradientStroke.addColorStop(1, "#afe6f7");
      }
      let data = {
        labels: chartLabel.length !== 0 ? chartLabel : [0],
        datasets: [
          {
            label: "Entered",
            data: chartPercentage.length !== 0 ? chartPercentage : [0],
            backgroundColor: gradientStroke,
            pointBorderColor: "#0251fa00",
            pointBackgroundColor: "#0251fa00",
            borderColor: "#0251fa00",
          },
        ],
      };
      this.setState({ trendChartData: data, spinnerForTrend: false });
    });
  };

  /**
   *
   * @description Retrieve and set data in user adoption doughnut chart
   * @param {string} startDate Start date of date range
   * @param {string} endDate End date of date range
   * @param {string} branchId Branch ID
   * @param {string} regionId Region ID
   * @param {string} hubId Hub ID
   * @param {string} mandiId Mandi ID
   * @param {string} type Arrival type (Mandi/FOR)
   * @memberof UserAdoptionChart
   */
  setChartInState = (
    startDate,
    endDate,
    branchId,
    regionId,
    hubId,
    mandiId,
    type
  ) => {
    const chartParams = {
      start_date: startDate,
      end_date: endDate,
      branch_id: branchId,
      region_id: regionId,
      hub_id: hubId,
      arrival_type: type,
      mandi_id: mandiId,
      user_branches: true,
    };

    this.setState({ spinnerOn: true });

    API.get("/reports/user_adoption", { params: chartParams }).then(
      (response) => {
        const arrivalResult = response.data.data.arrival_user_adoption;

        let arrivalId = arrivalResult.map((item) => item.id);
        let arrivalNames = arrivalResult.map((item) => item.name);
        let totalFilled = 0,
          totalOpen = 0;
        let arrivalPercent = arrivalResult.map((item) => {
          totalFilled += item.filled;
          totalOpen += item.total;
          return item.filled / item.total;
        });
        let arrivalFilled = arrivalResult.map((item) => item.filled);
        let arrivalOpen = arrivalResult.map((item) => item.total);

        let arrivalCompletedStatus = arrivalResult.map(
          (item) => `${item.filled}/${item.total}`
        );

        const arrivalAvg = (totalFilled / totalOpen) * 100;
        let dataArrival = {
          datasets: [
            {
              data: [
                parseFloat(arrivalAvg).toFixed(2),
                (100 - parseFloat(arrivalAvg)).toFixed(2),
              ],
              backgroundColor: ["#0251fa", "#eeeeee"],
            },
          ],
          labels: ["Submitted", "Not submitted"],
          text: `${totalFilled}/${totalOpen}`,
          typeText: this.state.buyerType === "Mandi" ? "Mandi" : "Hub",
          // text: `${parseFloat(arrivalAvg).toFixed(2)}%`
        };

        let combinedArrivalDataList = [];
        for (var i = 0; i < arrivalNames.length; i++) {
          combinedArrivalDataList.push({
            name: arrivalNames[i],
            percent: arrivalPercent[i],
            id: arrivalId[i],
            filled: arrivalFilled[i],
            open: arrivalOpen[i],
            level: arrivalResult[i].level,
          });
        }

        this.setState(
          {
            chartArrivalName: arrivalNames !== [] ? arrivalNames : [],
            chartArrivalPercent: arrivalPercent !== [] ? arrivalPercent : [],
            chartCompletedStatus:
              arrivalCompletedStatus !== [] ? arrivalCompletedStatus : [],
            singleArrivalDataList:
              combinedArrivalDataList.length !== 0
                ? combinedArrivalDataList
                : [],
            dataArrivals:
              response.data.data.arrival_user_adoption.length !== 0
                ? dataArrival
                : this.defaultArrival,

            spinnerOn: false,
            selectedTerritoryIndex:
              arrivalResult[0].level !== "variety" ? 0 : null,
            selectedTerritoryId: arrivalId[0],
          },
          () =>
            this.setTrendChart(
              this.state.startDateTrend,
              this.state.endDateTrend,
              arrivalId[0],
              arrivalId[0],
              arrivalId[0],
              this.state.buyerType
            )
        );
      }
    );
  };

  /**
   * @function
   * @description Handle date range filter selection in user adoption trend chart.
   * @param {dateRange} dates
   * @param {string[]} dateStrings Array of strings consisting of selected date range.
   * @memberof UserAdoptionChart
   */
  onChangeRange = (dates, dateStrings) => {
    // this.setState(
    //   {
    //     startDateTrend: dateStrings[0], //[0],
    //     endDateTrend: dateStrings[1],
    //   },
    //   () =>
    //     this.setTrendChart(
    //       this.state.startDateTrend,
    //       this.state.endDateTrend,
    //       this.state.selectedTerritoryId,
    //       this.state.selectedTerritoryId,
    //       this.state.selectedTerritoryId,
    //       this.state.buyerType
    //     )
    // );
    if (dateStrings[1] !== dateStrings[0]) {
      this.setState(
        {
          startDateTrend: dateStrings[0], //[0],
          endDateTrend: dateStrings[1],
        },
        () => {
          // if ((moment(dateStrings[1], "DD-MM-YYYY").diff(moment(dateStrings[0], "DD-MM-YYYY"), 'days') / 365) > 1) {
          this.setTrendChart(
            this.state.startDateTrend,
            this.state.endDateTrend,
            this.state.selectedTerritoryId,
            this.state.selectedTerritoryId,
            this.state.selectedTerritoryId,
            this.state.buyerType
          );
        }
      );
    } else {
      this.setState(
        {
          startDateTrend: this.state.startDateTrend,
          endDateTrend: this.state.endDateTrend,
        },
        () =>
          message.error(
            "There should be a gap of 1 day at least between start and end dates."
          )
      );
    }
    // }
    // );
  };

  /**
   * @function
   * @description Handle single date filter selection in doughnut.
   * @param {dateRange} dates
   * @param {string[]} dateStrings Array of strings consisting of selected date range.
   * @memberof UserAdoptionChart
   */
  onChange = (dates, dateStrings) => {
    this.setState(
      {
        startDate: dateStrings,
        endDate: dateStrings,
      },
      () =>
        this.setChartInState(
          dateStrings,
          dateStrings,
          this.state.selectedBranch,
          this.state.selectedRegion,
          this.state.selectedHub,
          this.state.selectedMandi,
          this.state.buyerType
        )
    );
  };

  componentDidMount() {
    this.setState(
      (prevState) => ({
        ...prevState,
        spinnerOn: false,
      }),
      () =>
        this.setChartInState(
          moment().format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD"),
          null,
          null,
          null,
          null,
          this.state.buyerType
        )
    );
  }

  //For table data

  render() {
    //Type handling not required
    //type handling
    //#region
    //switching table based on types
    /**
     *
     * @description Handle type filter selection.
     * @param {string} value Selected arrival type.
     * @memberof UserAdoptionChart
     */
    const handleTypeSelect = (value) => {
      this.setState(
        (prevState) => ({
          ...prevState,
          selectedBuyerType: value,
          buyerType: value,
        }),
        () => {
          this.setChartInState(
            this.state.startDate,
            this.state.endDate,
            this.state.selectedBranch,
            null,
            null,
            this.state.selectedMandi,
            this.state.buyerType
          );
        }
      );
    };
    //#endregion

    //branch handling
    //#region
    /**
     *
     * @description Handle branch filter selection.
     * @param {string} value Selected branch value.
     * @param {object} props Selected branch object (key and value).
     * @memberof UserAdoptionChart
     */
    const handleBranchSelect = (value, props) => {
      this.setState({ spinnerOn: true });
      this.setState(
        (prevState) => ({
          ...prevState,
          selectedBranch: value === "All" ? "" : props.key,

          lastClicked: value === "All" ? "BranchAll" : "Branch",
          selectedBranchVal: value,
          selectedRegionVal: this.state.defaultRegion,
          selectedHubVal: this.state.defaultHub,
          selectedMandiVal: this.state.defaultMandi,
          selectedTerritoryType: value === "All" ? "Branch" : "Hub",
          spinnerOn: false,
        }),
        () => {
          this.setChartInState(
            this.state.startDate,
            this.state.endDate,
            this.state.selectedBranch,
            null,
            null,
            this.state.selectedMandi,
            this.state.buyerType
          );
        }
      );
    };
    //#endregion

    //hub handling
    //#region
    /**
     *
     * @description Handle hub filter selection.
     * @param {string} value Selected hub value.
     * @param {object} props Selected hub object (key and value).
     * @memberof UserAdoptionChart
     */
    const handleHubSelect = (value, props) => {
      this.setState(
        (prevState) => ({
          ...prevState,
          selectedHub: value === "All" ? "" : props.key,

          lastClicked: value === "All" ? "HubAll" : "Hub",
          selectedHubVal: value,
          selectedMandiVal: this.state.defaultMandi,
          selectedTerritoryType: value === "All" ? "Hub" : "Mandi",
          spinnerOn: false,
        }),
        () =>
          this.setChartInState(
            this.state.startDate,
            this.state.endDate,
            this.state.selectedBranch,
            this.state.selectedRegion,
            this.state.selectedHub,
            this.state.selectedMandi,
            this.state.buyerType
          )
      );
    };
    //#endregion

    //Mandi handling not required
    //mandi handling
    //#region
    /**
     *
     * @description Handle mandi filter selection.
     * @param {string} value Selected mandi value.
     * @param {object} props Selected mandi object (key and value).
     * @memberof UserAdoptionChart
     */
    const handleMandiSelect = (value, props) => {
      this.setState(
        (prevState) => ({
          ...prevState,
          selectedMandi: value === "All" ? "" : props.key,

          lastClicked: value === "All" ? "MandiAll" : "Mandi",
          selectedMandiVal: value,
          selectedTerritoryType: value === "All" ? "Mandi" : "Variety",
          spinnerOn: false,
        }),
        () => {
          this.setChartInState(
            this.state.startDate,
            this.state.endDate,
            this.state.selectedBranch,
            null,
            this.state.selectedHub,
            this.state.selectedMandi,
            this.state.buyerType
          );
        }
      );
    };
    //#endregion

    const defaultGraph = {
      datasets: [
        {
          label: "Entered",
          data: [],
          backgroundColor: "#1ca6ed",
          pointBorderColor: "#0251fa00",
          pointBackgroundColor: "#0251fa00",
          borderColor: "#0251fa00",
        },
      ],
      labels: [],
    };

    /**
     * @description Function to handle back button click in doughnut chart container.
     * @memberof UserAdoptionChart
     */
    const backButtonClicked = () => {
      this.setState({ trendChartData: defaultGraph });
      switch (this.state.selectedTerritoryType) {
        case "Hub":
          handleBranchSelect("All", "");
          break;
        case "Mandi":
          handleHubSelect("All", "");
          break;
        case "Variety":
          handleMandiSelect("All", "");
          break;
        default:
          break;
      }
    };

    /**
     * @description Function to handle drilldown through hierarchy.
     * @param {string} value Selected value
     * @param {object} props Selected value properties (key:value)
     * @memberof UserAdoptionChart
     */
    const handleDrilldown = (value, props) => {
      switch (this.state.selectedTerritoryType) {
        case "Branch":
          handleBranchSelect(value, props);
          break;
        case "Hub":
          if (this.state.buyerType === "Mandi") handleHubSelect(value, props);
          break;
        case "Mandi":
          handleMandiSelect(value, props);
          break;
        default:
          break;
      }
      if (this.state.selectedTerritoryType === "Branch")
        handleBranchSelect(value, props);
    };

    /**
     *
     * @description Fetch and render trend chart on single click on territory
     * @param {number} index Selected territory Index
     * @param {number} id Selected territory ID
     * @memberof UserAdoptionChart
     */
    const handleTrendSelection = (index, id) => {
      this.setState({
        selectedTerritoryIndex: index,
        selectedTerritoryId: id,
      });
      //TODO: Call API for getting User adoption trend graph
      this.setTrendChart(
        this.state.startDateTrend,
        this.state.endDateTrend,
        this.state.selectedTerritoryType === "Branch" ? id : null,
        this.state.selectedTerritoryType === "Hub" ? id : null,
        this.state.selectedTerritoryType === "Mandi" ? id : null,
        this.state.buyerType
      );
    };

    /**
     *
     * @description Set current territory type
     * @returns {string} Current territiry
     * @memberof UserAdoptionChart
     */
    const setSelectedType = () => {
      switch (this.state.lastClicked) {
        case "BranchAll":
          return "Branches";
        case "Branch":
        // case "RegionAll":
        //   return `Regions in ${this.state.selectedBranchVal}`;
        // case "Region":
        case "HubAll":
          return `Hubs in ${this.state.selectedBranchVal}`;
        case "Hub":
        case "MandiAll":
          return `Mandis in ${this.state.selectedHubVal}`;
        case "Mandi":
          return `Varieties in ${this.state.selectedMandiVal}`;
        default:
          return "Branches";
      }
    };

    //Main Doughnut options
    const options = {
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
      hover: {
        mode: null,
      },
      cutoutPercentage: 65,
      elements: {
        arc: { borderWidth: 0 },
      },
    };
    //Small doughnut options
    const options2 = {
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
      hover: {
        mode: null,
      },
      cutoutPercentage: 50,
      elements: { arc: { borderWidth: 0 } },
    };
    //Trend chart options
    const optionsTrend = {
      responsive: true,
      legend: {
        display: false,
      },
      hover: {
        mode: null,
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            return "";
          },
          afterLabel: function (tooltipItem, data) {
            var dataset = data["datasets"][0];
            var percent = dataset["data"][tooltipItem["index"]];

            return `Entered: ${percent} %`;
          },
        },
        label: false,
        intersect: false,
        mode: "nearest",
        backgroundColor: "white",
        titleFontColor: "#313131",
        borderColor: "#9d9d9d",
        borderWidth: 1,
        bodyFontColor: "#575757",
        displayColors: false,
      },
      elements: {
        line: {
          tension: 0,
        },
      },
      hover: {
        mode: "nearest",
        intersect: true,
      },
      scales: {
        xAxes: [
          {
            ticks: {
              maxRotation: 0, // angle in degrees
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              steps: 10,
              stepValue: 5,
              max: 100,
            },
          },
        ],
      },
    };

    return (
      <div
        className="user-adoption-container"
        style={{ height: window.innerHeight - 131 }}
      >
        <Spin spinning={this.state.isSpinnerOn} tip="Loading...">
          <Spin
            spinning={this.state.isSpinnerOnProgress}
            tip="Please wait while the file is being Downloaded..."
          >
            <div className="summary">
              <div
                className="download-button-container"
                style={{ bottom: "6%" }}
              >
                {this.state.ProgressClicked && (
                  <Progress
                    type="circle"
                    percent={this.state.downloaddata}
                    width={37}
                    strokeColor={{ "0%": "#108ee9", "100%": "#87d068" }}
                    style={{
                      paddingRight: "5px",
                    }}
                    trailColor="#003285"
                  />
                )}
                {
                  <Button
                    onClick={() => {
                      this.setState({ isSpinnerOnProgress: true });
                      this.setState({ downloaddata: 0 });
                      this.setState({ ProgressClicked: true });
                      const downloadParams = {
                        start_date: this.state.startDateTrend,
                        end_date: this.state.endDateTrend,
                        arrival_type:
                          this.state.buyerType === "FOR" ? "trader" : "mandi",
                      };

                      // API.get(`/reports/user_adoption_download`, {
                      //   params: downloadParams,
                      // })
                      //   .then((fileresponse) => {
                      //     if (fileresponse.data.status) {

                      //#region file download
                      Axios({
                        url: `${baseURL}/reports/user_adoption_download`,
                        method: "GET",
                        params: downloadParams,
                        responseType: "blob",
                        onDownloadProgress: (progressEvent) => {
                          let progress = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                          );
                          this.setState({ downloaddata: progress });
                        },
                      })
                        .then((response) => {
                          if (response.status !== 204) {
                            FileDownLoad(
                              response.data,
                              `User_Adoption_Report_${new Date().toLocaleDateString()}.xlsx`
                            );
                            //this.setState({isSpinnerOn:false});
                            this.setState({ isSpinnerOnProgress: false });
                            setTimeout(
                              function () {
                                this.setState({ ProgressClicked: false });
                              }.bind(this),
                              5000
                            );
                          } else {
                            message.warning("File has no data.");
                            this.setState({
                              isSpinnerOnProgress: false,
                              ProgressClicked: false,
                            });
                          }
                        })
                        .catch((err) => {
                          message.error("File cannot be downloaded");
                          this.setState({
                            isSpinnerOnProgress: false,
                            ProgressClicked: false,
                          });
                        });
                      //#endregion
                      //   } else {
                      //     message.warning("File has no data");
                      //     this.setState({isSpinnerOnProgress:false ,ProgressClicked:false});
                      //   }
                      // })
                      // .catch((err) => {
                      //   message.error("Error downloading file");
                      //   this.setState({isSpinnerOnProgress:false ,ProgressClicked:false});
                      // });
                    }}
                    type="primary"
                    shape="round"
                    icon="download"
                    size="large"
                  >
                    <div style={{ float: "right" }}>
                      <div style={{ marginTop: "-7px" }}>Download</div>
                      <div
                        style={{
                          fontSize: "11px",
                          lineHeight: "7px",
                          color: "#b4c8dc",
                        }}
                      >
                        .xlsx report
                      </div>
                    </div>
                  </Button>
                }
              </div>

              <div
                className="filter-container"
                style={{
                  display: "flex",
                  marginBottom: "13px",
                  marginLeft: "22px",
                }}
              >
                <div
                  className="summary-filters"
                  style={{
                    width: "596px",
                    padding: "11px 13px 14px 228px",
                  }}
                >
                  <div className="filter-select">
                    <div className="filter-select-title">Type</div>
                    <CustomSelectSummary
                      connectState
                      placeholder={
                        this.state.selectedBuyerType !== ""
                          ? this.state.selectedBuyerType
                          : this.state.buyerType
                      }
                      handleSelect={handleTypeSelect}
                      list={
                        this.state.selectedTerritoryType === "Branch" ||
                        this.state.selectedTerritoryType === "Hub"
                          ? ["Mandi", "FOR"]
                          : ["Mandi"]
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <Spin spinning={this.state.spinnerOn}>
              <div className="chart-container">
                <div key={1} className="user-adoption-chart">
                  <h1
                    style={{
                      fontSize: "20px",
                      marginTop: "0px",
                      marginBottom: "15px",
                    }}
                  >
                    Arrivals
                  </h1>
                  <h5
                    style={{
                      color: "#b7b8bc",
                      marginTop: "-17px",
                      marginBottom: "15px",
                    }}
                  >
                    Software utilization for arrivals
                  </h5>
                  <div key={2} className="single-select">
                    <div className="date-single">
                      <DatePicker
                        defaultValue={moment()}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>

                  <div className="legend">
                    <div
                      className="box"
                      style={{
                        backgroundColor:
                          this.state.dataArrivals !== null
                            ? this.state.dataArrivals.datasets[0]
                                .backgroundColor[0]
                            : "",
                      }}
                    ></div>
                    <div className="box-label">
                      {this.state.dataArrivals !== null
                        ? this.state.dataArrivals.labels[0]
                        : ""}
                    </div>
                    <div
                      className="box"
                      style={{
                        backgroundColor:
                          this.state.dataArrivals !== null
                            ? this.state.dataArrivals.datasets[0]
                                .backgroundColor[1]
                            : "",
                      }}
                    ></div>
                    <div className="box-label">
                      {this.state.dataArrivals !== null
                        ? this.state.dataArrivals.labels[1]
                        : ""}
                    </div>
                  </div>
                  {/* <div className="charts" style={{ width: window.innerWidth - 65 }}> */}

                  <div className="all-branches">
                    <div className="doughnut">
                      <Doughnut
                        height={100}
                        width={100}
                        data={this.state.dataArrivals}
                        options={options}
                      />
                    </div>
                    {this.state.dataArrivals ? (
                      <div className="branch-details">
                        <div
                          style={{
                            color: "#262626",
                            marginRight: `${
                              this.state.selectedTerritoryType === "Branch"
                                ? "-46px"
                                : "-12px"
                            }`,
                            position: "absolute",
                            marginTop: "-35px",
                            width: "242px",
                            display: "flex",
                            direction: "ltr",
                          }}
                        >
                          <div>
                            {this.state.selectedTerritoryType !== "Branch" && (
                              <Button onClick={() => backButtonClicked()}>
                                <div
                                  style={{
                                    marginLeft: "-1px",
                                    height: "28px",
                                    marginTop: "-10px",
                                  }}
                                >
                                  ←
                                </div>
                              </Button>
                            )}
                          </div>
                          <h1>{setSelectedType()}</h1>
                        </div>
                        {this.state.singleArrivalDataList.map((item, idx) => {
                          return (
                            <div
                              onDoubleClick={() => {
                                handleDrilldown(item.name, { key: item.id });
                              }}
                              onClick={() => {
                                handleTrendSelection(idx, item.id);
                              }}
                              key={idx}
                              className={`total-single-detail-container ${
                                idx === this.state.selectedTerritoryIndex
                                  ? "selected-branch"
                                  : ""
                              }`}
                              style={{
                                pointerEvents: `${
                                  this.state.selectedTerritoryType === "Variety"
                                    ? "none"
                                    : "all"
                                }`,
                              }}
                            >
                              <div className="branch-name">
                                <div className="doughnut-in-name">
                                  <Doughnut
                                    width={100}
                                    heigh={100}
                                    data={{
                                      datasets: [
                                        {
                                          data: [
                                            item.percent * 100,
                                            100 - item.percent * 100,
                                          ],
                                          backgroundColor: [
                                            "#0251fa",
                                            idx ===
                                            this.state.selectedTerritoryIndex
                                              ? "#ffffff"
                                              : "#f4f5f9",
                                          ],
                                        },
                                      ],
                                      labels: ["Submitted", "Not submitted"],
                                    }}
                                    options={options2}
                                  />
                                </div>
                                <p
                                  style={{
                                    color: "#262626",
                                    marginLeft: "7px",
                                    marginTop: "7px",
                                  }}
                                >
                                  {item.name}
                                </p>
                              </div>
                              <div className="branch-percent">
                                <p
                                  style={{
                                    fontWeight: "500",
                                    color: "#828282",
                                    marginTop: "7px",
                                  }}
                                >
                                  {`${item.filled}/${item.open}`}
                                </p>
                                {idx === this.state.selectedTerritoryIndex && (
                                  <div
                                    style={{
                                      fontSize: "23px",
                                      marginLeft: "9px",
                                      marginTop: "-2px",
                                    }}
                                  >
                                    🢒
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <Spin spinning={this.state.spinnerForTrend}>
                  <div
                    key={3}
                    className="adoption-trend-chart"
                    style={{
                      opacity: `${
                        this.state.selectedTerritoryType === "Variety" ? 0.5 : 1
                      }`,
                      pointerEvents: `${
                        this.state.selectedTerritoryType === "Variety"
                          ? "none"
                          : "all"
                      }`,
                    }}
                  >
                    <div className="trend-chart-top-container">
                      <div style={{ fontWeight: "600" }}>Adoption Trend %</div>

                      <div className="filter-select">
                        <div className="filter-select-title">Date Range</div>
                        <RangePicker
                          defaultValue={[
                            moment().subtract(30, "days"),
                            moment(),
                          ]}
                          ranges={{
                            "Past 7 days": [
                              moment().subtract(7, "days"),
                              moment(),
                            ],
                            "This Month": [moment().startOf("month"), moment()],
                            "Last Month": [
                              moment()
                                .startOf("month")
                                .subtract(1, "days")
                                .startOf("month"),
                              moment().startOf("month").subtract(1, "days"),
                            ],
                            "Past 30 days": [
                              moment().subtract(30, "days"),
                              moment(),
                            ],
                          }}
                          onChange={this.onChangeRange}
                        />
                      </div>
                      <div></div>
                    </div>

                    <Line
                      id="trendChart"
                      data={this.state.trendChartData}
                      height={185}
                      width={400}
                      options={optionsTrend}
                    />
                  </div>
                </Spin>
              </div>
            </Spin>
          </Spin>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  isModalVisible: selectIsModalVisible,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setAuditModalVisibility: (visibilityState) =>
      dispatch(setAuditModalVisibility(visibilityState)),
    setSummaryVisibility: (isEnabled) =>
      dispatch(setSummaryVisibility(isEnabled)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserAdoptionChart);
