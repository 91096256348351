import React from "react";
import "./map-marker.styles.css";

import { createPortal } from "react-dom";
import { DivIcon, marker } from "leaflet";
import * as RL from "react-leaflet";
import { MapLayer } from "react-leaflet";
import { difference } from "lodash";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import {
  selectBranchID,
  selectRegionID,
  selectHubID,
  selectMandiID,
} from "../../redux/arrival-entry/arrival-entry.selectors";

import { Badge, Icon, Dropdown, Menu } from "antd";
import { Marker, Popup, Tooltip } from "react-leaflet";

import closedImage from "../../assets/close.svg";
import noArrivalsImage from "../../assets/no-entry.svg";
import tickImage from "../../assets/tick.svg";

export const CustomMarker = RL.withLeaflet(
  class extends MapLayer {
    leafletElement;
    contextValue;

    createLeafletElement(props) {
      const { map, layerContainer, position, ...rest } = props;

      // when not providing className, the element's background is a white square
      // when not providing iconSize, the element will be 12x12 pixels
      const icon = new DivIcon({ ...rest, className: "", iconSize: undefined });

      const el = marker(position, { icon: icon, ...rest });
      this.contextValue = { ...props.leaflet, popupContainer: el };
      return el;
    }

    updateLeafletElement(fromProps, toProps) {
      const {
        position: fromPosition,
        zIndexOffset: fromZIndexOffset,
        opacity: fromOpacity,
        draggable: fromDraggable,
        className: fromClassName,
      } = fromProps;
      const {
        position: toPosition,
        zIndexOffset: toZIndexOffset,
        toOpacity,
        draggable: toDraggable,
        className: toClassName,
      } = toProps;

      if (toPosition !== fromPosition) {
        this.leafletElement.setLatLng(toPosition);
      }
      if (toZIndexOffset !== fromZIndexOffset) {
        this.leafletElement.setZIndexOffset(toZIndexOffset);
      }
      if (toOpacity !== fromOpacity) {
        this.leafletElement.setOpacity(toOpacity);
      }
      if (toDraggable !== fromDraggable) {
        if (toDraggable) {
          this.leafletElement.dragging.enable();
        } else {
          this.leafletElement.dragging.disable();
        }
      }
      if (toClassName !== fromClassName) {
        const fromClasses = fromClassName.split(" ");
        const toClasses = toClassName.split(" ");
        this.leafletElement._icon.classList.remove(
          ...difference(fromClasses, toClasses)
        );
        this.leafletElement._icon.classList.add(
          ...difference(toClasses, fromClasses)
        );
      }
    }

    componentWillMount() {
      if (super.componentWillMount) {
        super.componentWillMount();
      }
      this.leafletElement = this.createLeafletElement(this.props);
      this.leafletElement.on("add", () => this.forceUpdate());
    }

    componentDidUpdate(fromProps) {
      this.updateLeafletElement(fromProps, this.props);
    }

    render() {
      const { children } = this.props;
      const container = this.leafletElement._icon;

      if (!container) {
        return null;
      }

      const portal = createPortal(children, container);

      const LeafletProvider = RL.LeafletProvider;

      return children == null ||
        portal == null ||
        this.contextValue == null ? null : (
        <LeafletProvider value={this.contextValue}>{portal}</LeafletProvider>
      );
    }
  }
);

//////////////////////////

const setImage = (status) => {
  switch (status) {
    case "open":
      return null;
    case "closed":
      return closedImage;
    case "no arrivals":
      return noArrivalsImage;
    case "completed":
      return tickImage;
    default:
      return null;
  }
};

/**
 * @component
 * @description the mandi marker component in the map.
 * @property {latlong} position the latitude and longitude of the mandi
 * @property {number} mandiNumber the number to display on the marker
 * @property {string} mandiStatus the territory status of the mandi
 * @property {string} mandiName the name of the mandi
 * @property {number} markerMandiID the id of the mandi
 * @property {function} handleSelectChange callback on clicking the mandi marker
 *
 */
const MapMarker = (props) => {
  const {
    position,
    mandiNumber,
    mandiStatus,
    mandiName,
    currentArrivalScreen,
    handleSelectChange,
    mandiID,
    hubID,
    markerMandiID,
  } = props;

  return (
    <CustomMarker className="map-marker-item" position={position}>
      <Tooltip className="map-marker-tooltip">{mandiName}</Tooltip>
      <div
        className={` map-marker-content ${markerMandiID === (currentArrivalScreen === "Mandi" ? mandiID : hubID) ? "selected-marker" : ""
          }`}
        onClick={() => {
          handleSelectChange(mandiName, currentArrivalScreen === "Mandi" ? 4 : 3);
        }}
      >
      {console.log("mandi marker ", props,mandiNumber)}
        <div className="map-marker-text">{mandiNumber}</div>
        <Badge
          className="map-marker-icon"
          count={
            mandiStatus === "closed" ? (
              <img
                className="map-marker-icon-image"
                alt="marker"
                src={setImage(mandiStatus)}
              />
            ) : mandiStatus === "no arrivals" ? (
              <img
                className="map-marker-icon-image"
                alt="marker"
                src={setImage(mandiStatus)}
              />
            ) : null
          }
        />
      </div>
    </CustomMarker>
  );
};

const mapStateToProps = createStructuredSelector({
  branchID: selectBranchID,
  regionID: selectRegionID,
  hubID: selectHubID,
  mandiID: selectMandiID,
});

export default connect(mapStateToProps)(MapMarker);
