import React, { useEffect, useState } from "react";
import "./varietal-substitution.styles.css";
import { Table, Modal, Spin, Drawer, Button, Input, message } from "antd";
import CustomSelectSummary from "../../components/custom-select-summary/custom-select-summary.component";
import API, { baseURL } from "../../api";

import Axios from "axios";
import { string } from "prop-types";

const FileDownLoad = require("js-file-download");
const TableColumnHeader = (props) => {
  const { title, subtitle, marginTop, marginLeft } = props;

  return (
    <div
      style={{
        marginTop: marginTop,
        marginLeft: marginLeft,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          color: "white",
          fontSize: "12px",
          whiteSpace: "nowrap",
          textAlign: "left",
        }}
      >
        {title}
      </div>
      <div
        style={{
          color: "#cbdfff",
          fontSize: "11px",
          fontWeight: "normal",
          textAlign: "left",
        }}
      >
        {subtitle}
      </div>
    </div>
  );
};

//#region Summary Table Props
const tableProps = {
  pagination: false,
  size: "default",
  bordered: true,
  marginTop: "10px",
  scroll: { x: 1000, y: window.innerHeight - 240 },
};

class VarietalSubstitution extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      varietalData: [],
      branches: [],
      varieties: [],
      varietalTransferData: [],
      selectedVarietyVal: "",
      selectedVariety: "",
      selectedBranchVal: "",
      selectedBranch: "",
      varietalBranches: [],
      varietalVarieties: [],
      isSubmitted: false,
      data: [],
      filteredTransferData: [],
      currentRowID: "",
      warningMessage: "",
      isSpinnerOnTable: false,
      isSpinnerOnModal: false,
      isTableVisible: false,
      isSpinnerOn: false,
      state: "",
      variety: "",
      surplusDeficit: "",
      actualVolume: "",
    };
  }

  getBranchAndVarities = async () => {
    //To get branch and varieties
    await API.get("/branches").then((response) => {
      this.setState(
        {
          branches: response.data.data.branches,
        },
        () => this.getVarities()
      );
    });
  };

  getVarities = async () => {
    await API.get("/varieties").then((varietyResponse) => {
      this.setState(
        {
          varieties: varietyResponse.data.data,
          // isSpinnerOn: false
        },
        () => this.getvarietaldata()
      );
    });
  };

  getvarietaldata = async () => {
    await API.get("/varietal_substitution").then((varietyResponse) => {
      if (varietyResponse.data.status) {
        let modifiedData = varietyResponse.data.data.map((dataItem) => {
          //  dataItem.surplusDeficit = dataItem.actual_volume - dataItem.planned_volume;
          let a = dataItem.varietal_substitution_hdr_pk;
          dataItem.varietal_substitution_hdr_pk = a;
          this.setState({ isSubmitted: dataItem.is_submitted });
          //  dataItem.variety_name = "";
          //  dataItem.territory_name = "";

          //   if (this.state.varieties) {
          //       this.state.varieties.map((item) => {
          //      if (item.variety_id === dataItem.variety_id) {
          //        dataItem.variety_name = item.variety_name;
          //      }
          //    });
          //  }

          //  if (this.state.branches) {
          //     this.state.branches.map((branchItem) => {
          //      if (branchItem.territory_id === dataItem.branch_id) {
          //        dataItem.territory_name = branchItem.territory_name;
          //      }
          //    });
          //  }

          return dataItem;
        });
        this.setState({
          varietalData: modifiedData,
          isSpinnerOnTable: false,
        });
      }
    });
  };

  getVarietalTransferData = async () => {
    //To get branch and varieties
    await API.get("/varietal_substitution_dtl").then(
      (varietyDetailResponse) => {
        if (varietyDetailResponse.data.status) {
          varietyDetailResponse.data.data.map((dataDetailItem) => {
            let a = dataDetailItem.varietal_substitution_hdr_pk;
            dataDetailItem.varietal_substitution_hdr_pk = a;
          });

          this.setState({
            varietalTransferData: varietyDetailResponse.data.data,
          });
        }
      }
    );
  };

  componentDidMount() {
    this.getVarities();
    this.getVarietalTransferData();
  }

  render() {
    const handleCancel = () => {
      this.setState({ isModalVisible: false });
      this.setState({ isSubmitModalVisible: false });
    };

    const handleChange = (e, row, index) => {
      let a = this.state.filteredTransferData;
      if (e.target.name === "quantity") {
        a[index].quantity = Number(e.target.value);
      } else {
        a[index].remarks = e.target.value;
      }

      this.setState({ filteredTransferData: a });
    };

    const calculativeFields = () => {
      let warningmessage = "";
      let actualVol = 0;
      this.state.filteredTransferData.map((item) => {
        actualVol = actualVol + item.quantity;

        if (
          item.to_branch_id === 0 ||
          item.to_branch_id === undefined ||
          item.to_branch_id === null ||
          item.territory_name === "All" ||
          item.to_branch_id === "" ||
          item.to_branch_id === string.empty
        ) {
          warningmessage = "Please select state !";
          return false;
        } else if (
          item.to_variety_id === 0 ||
          item.to_variety_id === undefined ||
          item.to_variety_id === null ||
          item.variety_name === "All" ||
          item.to_variety_id === "" ||
          item.to_variety_id === string.empty
        ) {
          warningmessage = "Please select variety !";
          return false;
        } else if (
          item.quantity === 0 ||
          item.quantity === undefined ||
          item.quantity === null ||
          item.quantity === "" ||
          item.quantity === string.empty
        ) {
          warningmessage = "Quantity can not be empty !";
          return false;
        } else if (
          item.remarks === "" ||
          item.remarks === undefined ||
          item.remarks === null ||
          item.remarks === string.empty
        ) {
          warningmessage = "Remarks can not be empty !";
          return false;
        }
      });

      if (actualVol <= this.state.actualVolume) {
        if (
          warningmessage === "" ||
          warningmessage === undefined ||
          warningmessage === null ||
          warningmessage === string.empty
        ) {
          const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          };

          this.setState({
            varietalTransferData: this.state.filteredTransferData,
          });

          this.state.filteredTransferData.map((item) => {
            if (
              item.varietal_substitution_hdr_pk === 0 ||
              item.varietal_substitution_hdr_pk === "" ||
              item.varietal_substitution_hdr_pk === undefined ||
              item.varietal_substitution_hdr_pk === null
            )
              item.varietal_substitution_hdr_pk = this.state.currentRowID;
          });

          API.put(
            "/varietal_substitution_dtl",
            this.state.filteredTransferData,
            {
              headers: headers,
            }
          )
            .then((response) => {
              if (response.data.status) {
                message.info("Data updated successfully");
                setModalVisibility(false);
                this.getvarietaldata();
                this.getVarietalTransferData();
              } else {
                message.error("Please try again");
              }
            })
            .catch((err) => {
              message.error("Data Exception");
              setModalVisibility(false);
            });

          setModalVisibility(false);
        } else {
          message.error(warningmessage);
        }
      } else {
        message.error("Quantity cannot be more than Actual Volume !");
      }

      this.setState({ isSpinnerOnModal: false });
    };

    const setDataForTable = async () => {
      await this.getBranchAndVarities();
      return this.state.varietalData;
    };

    const getVarietalBranchesVarieties = async (
      variety_id,
      rowRecord,
      status
    ) => {
      const params = {
        variety_id: variety_id,
      };

      //To get branch and varieties
      await API.get("/varietal_branch_varieties", {
        params: params,
      }).then((response) => {
        if (response.data.data) {
          response.data.data.map((dataDetailItem) => {
            // if (this.state.varieties) {
            //   this.state.varieties.map((detailItem) => {
            //     if (detailItem.variety_id === dataDetailItem.to_variety_id) {
            //       dataDetailItem.variety_name = detailItem.variety_name;
            //     }
            //   });
            // }

            // if (this.state.branches) {
            //   this.state.branches.map((detailItem) => {
            //     if (detailItem.territory_id === dataDetailItem.to_branch_id) {
            dataDetailItem.territory_name = dataDetailItem.state;
            //     }
            //   });
            // }
          });

          this.setState(
            {
              varietalBranches: response.data.data,
            },
            () => {
              let varietaldata = this.state.varietalTransferData.map(
                (object) => ({ ...object })
              );

              this.state.filteredTransferData = varietaldata.filter(
                (item) =>
                  item.varietal_substitution_hdr_pk ===
                  rowRecord.varietal_substitution_hdr_pk
              );

              if (this.state.filteredTransferData.length > 0) {
                this.state.filteredTransferData.map((item) => {
                  if (
                    item.varietal_substitution_hdr_pk === null ||
                    item.varietal_substitution_hdr_pk === "" ||
                    item.varietal_substitution_hdr_pk === 0 ||
                    item.varietal_substitution_hdr_pk === undefined
                  ) {
                    item.varietal_substitution_hdr_pk =
                      rowRecord.varietal_substitution_hdr_pk;
                  }

                  if (this.state.varieties) {
                    this.state.varieties.map((detailItem) => {
                      if (detailItem.variety_id === item.to_variety_id) {
                        let val = detailItem.variety_name;
                        item.selectedVarietiesVal = val;
                      }
                    });
                  }

                  if (this.state.varietalBranches) {
                    this.state.varietalBranches.map((branchDetailItem) => {
                      getUniqueVarities(branchDetailItem.to_branch_id);
                      item.varietiesBranch = names;
                      item.varietiesid = varietyids;

                      if (
                        branchDetailItem.to_branch_id === item.to_branch_id &&
                        branchDetailItem.to_variety_id === item.to_variety_id
                      ) {
                        item.surplus_deficit = branchDetailItem.surplus_deficit;
                      }
                      if (branchDetailItem.to_branch_id === item.to_branch_id) {
                        let val = branchDetailItem.state;
                        item.selectedBranchesVal = val;
                      }
                    });
                  }

                  // getUniqueVarities(item.to_branch_id);
                  // item.varietiesBranch = names;
                  // item.varietiesid = varietyids;
                  // console.log("item varietes ",item.varietiesBranch );
                  // console.log("item varietes ",item.varietiesid );
                });
              } else {
                handleAdd();
              }

              this.setState({ isSpinnerOnModal: false });
              this.setState({ isModalVisible: status });
            }
          );
        }
      });
    };

    const setModalVisibility = (status, rowRecord) => {
      this.setState({ isSpinnerOnModal: true });

      if (status) {
        this.setState({
          currentRowID: rowRecord.varietal_substitution_hdr_pk,
          actualVolume: rowRecord.actual_volume,
        });

        this.setState({ state: rowRecord.state });
        this.setState({ variety: rowRecord.variety_name });
        this.setState({ surplusDeficit: rowRecord.surplus_deficit });
        getVarietalBranchesVarieties(rowRecord.variety_id, rowRecord, status);
      } else {
        this.setState({ isSpinnerOnModal: false });
        this.setState({ isModalVisible: status });
      }
    };

    const setSubmitModalVisibility = (status) => {
      this.setState({ isSubmitModalVisible: status });
    };

    const submitVarietalSubstitution = () => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      };

      let is_submitted = { is_submitted: 1 };

      API.put("/varietal_substitution_dtl", is_submitted, {
        headers: headers,
      })
        .then((response) => {
          if (response.data.status) {
            message.info("Submited Successfully");
            this.setState({ isSubmitted: true });
            setSubmitModalVisibility(false);
          } else {
            this.setState({ isSubmitted: false });
            message.error("Please try again");

            setSubmitModalVisibility(false);
          }
        })
        .catch((err) => {
          this.setState({ isSubmitted: false });
          setSubmitModalVisibility(true);
        });
    };

    function getUnique(array, key) {
      if (typeof key !== "function") {
        const property = key;
        key = function (item) {
          return item[property];
        };
      }
      return Array.from(
        array
          .reduce(function (map, item) {
            const k = key(item);
            if (!map.has(k)) map.set(k, item);
            return map;
          }, new Map())
          .values()
      );
    }

    let varietyId, varietyName;
    if (this.state.varietalBranches) {
      varietyId = this.state.varietalBranches.map((item) => item.to_variety_id);
      varietyName = this.state.varietalBranches.map(
        (item) => item.variety_name
      );
    }

    //filtering branch data to name and key
    let branchId, branchName;
    if (this.state.varietalBranches) {
      let items = this.state.varietalBranches;
      items = getUnique(items, "state");

      branchId = items.map((item) => item.to_branch_id);
      branchName = items.map((item) => item.state);
    }

    const handleVarietySelect = (value, index) => {
      let varietyid, surplus, branchid;
      let a = this.state.filteredTransferData;
      branchid = a[index].to_branch_id;

      this.state.varietalBranches.map((item) => {
        if (item.variety_name === value) {
          varietyid = item.to_variety_id;
        }
      });

      this.state.varietalBranches.map((item) => {
        if (
          item.to_branch_id === branchid &&
          item.to_variety_id === varietyid
        ) {
          surplus = item.surplus_deficit;
        }
      });

      a[index].selectedVarietiesVal = value;
      a[index].to_variety_id = varietyid;
      a[index].surplus_deficit = surplus;

      this.setState({
        filteredTransferData: a,
      });
    };

    const handleBranchSelect = (value, index) => {
      let branchid;
      this.state.varietalBranches.map((item) => {
        if (item.state === value) branchid = item.to_branch_id;
      });
      getUniqueVarities(branchid);
      let a = this.state.filteredTransferData;
      a[index].selectedBranchesVal = value;
      a[index].selectedVarietiesVal = "";
      a[index].to_branch_id = branchid;
      a[index].varietiesBranch = names;
      a[index].varietiesid = varietyids;
      a[index].selectedBranchid = varietyids;
      a[index].SelectedVarietyid = varietyids;

      this.setState({
        filteredTransferData: a,
      });
    };

    let names, varietyids;
    const getUniqueVarities = (branchid) => {
      let itemms = [];
      itemms = this.state.varietalBranches.filter(
        (item) => item.to_branch_id === branchid
      );
      itemms = getUnique(itemms, "variety_name");

      names = itemms.map((item) => {
        return item.variety_name;
      });

      varietyids = itemms.map((item) => {
        return item.variety_id;
      });
    };

    const handleAdd = () => {
      const { count, filteredTransferData } = this.state;
      const newData = {
        key: count,
        quantity: "",
        remarks: "",
        to_branch_id: 0,
        to_variety_id: 0,
        selectedBranchesVal: "",
        selectedVarietiesVal: "",
        varietal_substitution_dtl_pk: "",
        varietal_substitution_hdr_pk: this.state.currentRowID,
      };
      this.setState({
        filteredTransferData: [...this.state.filteredTransferData, newData],
      });
    };

    return (
      <div
        className="varietal"
        style={{ height: window.innerHeight - 150, marginTop: "5px" }}
      >
        <Spin
          spinning={this.state.isSpinnerOnModal}
          size={"large"}
          tip="Loading..."
          style={{ alignSelf: "center" }}
        >
          <Modal
            title="Transfer To"
            footer={false}
            visible={this.state.isModalVisible}
            onCancel={handleCancel}
            className="varaital-modal"
            style={{
              textAlign: "center",
              height: "400px",
            }}
            width={900}
          >
            <div>
              <div>
                {" "}
                <span
                  style={{
                    color: "rgb(32, 52, 106)",
                    fontWeight: "bold",
                    paddingRight: "30px",
                  }}
                >
                  Transfer From :{" "}
                </span>
                <span
                  style={{ color: "rgb(32, 52, 106)", paddingRight: "30px" }}
                >
                  State : {this.state.state}
                </span>
                <span
                  style={{ color: "rgb(32, 52, 106)", paddingRight: "30px" }}
                >
                  Variety : {this.state.variety}{" "}
                </span>
                <span
                  style={{ color: "rgb(32, 52, 106)", paddingRight: "30px" }}
                >
                  {this.state.surplus_deficit < 0
                    ? "Deficit (MT)"
                    : "Surplus (MT)"}{" "}
                  : {this.state.surplusDeficit}
                </span>
              </div>
              <Button
                onClick={(e) => {
                  handleAdd();
                }}
                type="primary"
                disabled={this.state.isSubmitted}
                style={{
                  marginTop: "5px",
                  marginLeft: "15px",
                  marginBottom: "10px",
                  padding: "2px 8px !important",
                  backgroundColor: "#20346a",
                  width: "fit-content !important",
                  borderRadius: "20px",
                  color: "white",
                  float: "right",
                }}
              >
                Add
              </Button>
            </div>

            <div
              className="table-container varietal-audit-table"
              style={{ marginBottom: 20 }}
            >
              <div className="hub-form-container">
                <div
                  className="row"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "2px",
                    height: "35px",
                    background: "rgb(32, 52, 106)",
                    color: "white",
                  }}
                >
                  <div
                    className="unit"
                    style={{ fontWeight: "bold", width: "40%" }}
                  >
                    State
                  </div>
                  <div
                    className="unit"
                    style={{
                      fontWeight: "bold",
                      width: "40%",
                    }}
                  >
                    Variety
                  </div>
                  <div style={{ fontWeight: "bold", width: "35%" }}>
                    Deficit (MT)
                  </div>
                  <div style={{ fontWeight: "bold", width: "50%" }}>
                    Quantity (MT)
                  </div>
                  <div style={{ fontWeight: "bold", width: "60%" }}>
                    Remarks
                  </div>
                </div>

                <div
                  className="form-top-container"
                  style={{ overflowY: "auto", maxHeight: "260px" }}
                >
                  {this.state.filteredTransferData.map((row, index) => (
                    <div
                      className="row"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <CustomSelectSummary
                        disabled={this.state.isSubmitted}
                        isVarietalSubstitution={true}
                        connectState
                        placeholder={row.selectedBranchesVal}
                        list={branchName}
                        keyList={branchId}
                        handleSelect={(val) => handleBranchSelect(val, index)}
                      />
                      <CustomSelectSummary
                        disabled={
                          this.state.isSubmitted ||
                          row.selectedBranchesVal === "" ||
                          row.selectedBranchesVal === undefined
                        }
                        isVarietalSubstitution={true}
                        connectState
                        placeholder={row.selectedVarietiesVal}
                        handleSelect={(val) => handleVarietySelect(val, index)}
                        list={row.varietiesBranch}
                        keyList={row.varietiesid}
                      />
                      <Input
                        className={
                          row.surplus_deficit <= 0 ? "deficitRow" : "surplusRow"
                        }
                        style={{ width: "30%", color: "black" }}
                        // style={this.state.surplus_deficit <=0 ? {color:"red", width:"30%"} : {color:"green", width:"30%"}}
                        disabled={true}
                        type="number"
                        key={"varietal_substitution_dtl_pk"}
                        value={row.surplus_deficit}
                        name={
                          this.state.surplus_deficit <= 0
                            ? "Deficit (MT)"
                            : "Surplus (MT)"
                        }
                      />
                      <Input
                        style={{ width: "30%" }}
                        maxLength={10}
                        min="0"
                        disabled={this.state.isSubmitted}
                        type="number"
                        key={"varietal_substitution_dtl_pk"}
                        required={true}
                        value={row.quantity}
                        name="quantity"
                        onChange={(e) => handleChange(e, row, index)}
                      />
                      <Input
                        disabled={this.state.isSubmitted}
                        style={{ width: "50%", marginRight: "2px" }}
                        maxLength={100}
                        required={true}
                        value={row.remarks}
                        name="remarks"
                        onChange={(e) => handleChange(e, row, index)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="btn-container">
              <Button
                onClick={(e) => {
                  calculativeFields();
                }}
                disabled={this.state.isSubmitted}
                className="btn-container"
                style={{
                  padding: "2px 8px !important",
                  width: "fit-content !important",
                  backgroundColor: "rgb(32, 52, 106)",
                  borderRadius: "20px",
                  color: "white",
                  position: "absolute",
                  bottom: "2%",
                  right: "3.5%",
                }}
              >
                {" "}
                Save{" "}
              </Button>
            </div>
          </Modal>
        </Spin>

        <Spin spinning={this.state.isSpinnerOn} tip="Loading...">
          <div
            className="download-button-container"
            style={{
              display: `${this.state.favouriteChecked ? "none" : "unset"}`,
            }}
          >
            {
              <Button
                onClick={() => {
                  this.setState({ isSpinnerOn: true });

                  // API.get(`/varietal_substitution_view_download`)
                  //   .then((fileresponse) => {
                  //     if (fileresponse.data.status) {

                  //#region file download
                  Axios({
                    url: `${baseURL}/varietal_substitution_view_download`,
                    method: "GET",
                    responseType: "blob",
                  })
                    .then((response) => {
                      if (response.status !== 204) {
                        FileDownLoad(
                          response.data,
                          `Varietal_Substitution_View_Report_${new Date().toLocaleDateString()}.xlsx`
                        );
                      } else {
                        message.warning("File has no data.");
                      }
                      this.setState({ isSpinnerOn: false });
                    })
                    .catch((err) => {
                      message.error("File cannot be downloaded");
                      this.setState({ isSpinnerOn: false });
                    });
                  //#endregion
                  //   } else {
                  //     message.warning("File has no data");
                  //     this.setState({ isSpinnerOn: false });
                  //   }
                  // })
                  // .catch((err) => {
                  //   message.error("Error downloading file");
                  //   this.setState({ isSpinnerOn: false });
                  // });
                }}
                type="primary"
                shape="round"
                icon="download"
                size="large"
              >
                <div style={{ float: "right" }}>
                  <div style={{ marginTop: "-7px" }}>Download</div>
                  <div
                    style={{
                      fontSize: "11px",
                      lineHeight: "7px",
                      color: "#b4c8dc",
                    }}
                  >
                    .xlsx report
                  </div>
                </div>
              </Button>
            }
          </div>
        </Spin>
        <Drawer
          height="max-content"
          placement="bottom"
          closable={false}
          mask={false}
          // destroyOnClose
          onClose={this.onDrawerClose}
          visible={true}
        >
          <div className="drawer-container"></div>
        </Drawer>
        <div
          className="filter-container"
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginBottom: "5px",
          }}
        >
          <div className="table-container varietal-audit-table">
            <Spin
              spinning={this.state.isSpinnerOnTable}
              size={"large"}
              tip="Loading..."
            >
              <Table
                // visible = {this.state.isTableVisible}
                {...tableProps}
                scroll={{ x: 0, y: 300 }}
                columns={[
                  {
                    title: <TableColumnHeader title="State" />,
                    dataIndex: "state",
                    key: "state",
                    width: 120,
                  },
                  {
                    title: <TableColumnHeader title="Variety" />,
                    dataIndex: "variety_name",
                    key: "variety_name",
                    width: 115,
                  },
                  {
                    title: "Monthly Demand at Start of Month (MT)",
                    dataIndex: "monthly_demand_start",
                    key: "monthly_demand_start",
                    width: 110,
                  },
                  {
                    title: "Planned Volume (MT)",
                    dataIndex: "planned_volume",
                    key: "planned_volume",
                    width: 80,
                  },
                  {
                    title: "Actual Volume (PO) (MT)",
                    key: "actual_volume",
                    dataIndex: "actual_volume",
                    width: 95,
                  },
                  {
                    title: "Actual Price (₹/MT) (Avg)",
                    dataIndex: "actual_price",
                    key: "actual_price",
                    width: 95,
                  },
                  {
                    title: "Surplus/Deficit (MT)",
                    key: "surplus_deficit",
                    dataIndex: "surplus_deficit",
                    width: 95,
                    render: (record, rowRecord) => {
                      // {console.log("isEdit ", rowRecord, record)}
                      return (
                        <>
                          <div
                            className={
                              rowRecord.surplus_deficit <= 0
                                ? "deficitRow"
                                : "surplusRow"
                            }
                          >
                            {rowRecord.surplus_deficit}
                          </div>
                        </>
                      );
                    },
                  },
                  {
                    title: "Transfer To",
                    dataIndex: "transferTo",
                    key: "transferTo",
                    width: 90,
                    cell: ({ cell, row }) => <button>Edit</button>,

                    render: (record, rowRecord) => {
                      // {console.log("isEdit ", rowRecord, record)}
                      return (
                        <>
                          <div disabled={this.state.isSpinnerOnTable}>
                            <Button
                              disabled={rowRecord.surplus_deficit <= 0}
                              className={
                                rowRecord.surplus_deficit <= 0
                                  ? "disable-edit-btn"
                                  : "edit-btn"
                              }
                              type="primary"
                              size={"small"}
                              style={{
                                marginLeft: 5,
                                alignSelf: "center",
                                padding: 0,
                                color: "white",
                                borderRadius: 15,
                              }}
                              block
                              htmlType="submit"
                              onClick={() =>
                                setModalVisibility(true, rowRecord)
                              }
                            >
                              {" "}
                              Edit{" "}
                            </Button>
                          </div>
                        </>
                      );
                    },
                  },
                  {
                    title: "Current Residual Monthly Demand (MT)",
                    key: "monthly_demand_after",
                    dataIndex: "monthly_demand_after",
                    width: 110,
                  },
                  {
                    title: "Transferred In (MT)",
                    key: "transferred_in",
                    dataIndex: "transferred_in",
                    width: 90,
                  },
                  {
                    title: "Transferred Out (MT)",
                    key: "transferred_out",
                    dataIndex: "transferred_out",
                    width: 90,
                  },
                ]}
                //dataSource={this.state.data}
                dataSource={this.state.varietalData}
                ref={(el) => {
                  this.messagesEnd = el;
                }}
                //   dataSource={this.state.mandiModalTableData}
              />
            </Spin>
          </div>
        </div>

        <Modal
          title="Confirm!"
          classname="submit-modal"
          visible={this.state.isSubmitModalVisible}
          footer={false}
          onCancel={handleCancel}
          width={700}
          // style={{ textAlign: "center"  , height:"200px", width: "400px"}}
        >
          {
            <>
              <p style={{ color: "#262626" }}>
                Are you sure you want to finalize the data? You will not be able
                to modify the data once submitted.
              </p>
              <div className="btn-container-freeze">
                <Button
                  onClick={(e) => {
                    submitVarietalSubstitution();
                  }}
                  className="btn-container"
                  style={{
                    marginLeft: "15px",
                    padding: "2px 8px !important",
                    backgroundColor: "#20346a",
                    width: "fit-content !important",
                    borderRadius: "20px",
                    color: "white",
                    float: "right",
                  }}
                >
                  {" "}
                  Freeze{" "}
                </Button>
              </div>
            </>
          }
        </Modal>

        <div className="varaital-submit-btn">
          <Button
            disabled={this.state.isSubmitted}
            className="login-btn"
            type="primary"
            size={"large"}
            style={{
              alignSelf: "center",
              width: "80px",
              padding: 0,
              borderRadius: 20,
              backgroundColor: this.state.isSubmitted ? "grey" : "white",
              color: this.state.isSubmitted ? "white" : "#20346a",
            }}
            block
            htmlType="submit"
            onClick={(e) => {
              setSubmitModalVisibility(true);
            }}
          >
            Freeze
          </Button>
        </div>
      </div>
    );
  }
}

export default VarietalSubstitution;
