import React from "react";
import ".//branch-master.styles.css";
import { Component } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUserData ,selectMSMessageRBAC} from "../../redux/user/user.selectors";
import { canUserEdit ,getUserMSRBACMessage} from "../../auth/utils";
import { FeatureCodes } from "../../auth/data";
import {
  Table,
  Spin,
  Input,
  Select,
  Button,
  message,
  Form,
  Modal,
  Radio,
} from "antd";

import API from "../../api";
const { Search } = Input;
const { Option } = Select;
/**
 * @description Component for user management tab
 *
 * @component
 */
class BranchMaster extends Component {
  state = {
    isModalVisible: false,
    isAdd: false,
    currentRecord: null,
    userBranches: null,
    searchText: null,
    searchUserType: 0,
    BranchTableData: [],
    stateList: [],
    mandiRegionList: [],
    hubList: [],
    hubRegionList: [],
    hubBranchList: [],
    isSpinnerOnLoad: false,
    isSubmitted: false,
    newData: {
      is_active: true,
      state: "",
      territory_id: undefined,
      territory_name: "",
      territory_sap_code: "",
      factor:undefined,
      isEdit: false,
    },
    rowData: undefined,
    selectedStateValue: undefined,
    selectedStateName: "",
    selectedStateList: [],
  };
  componentDidMount() {
    this.setState({ isSpinnerOnLoad: true });
    this.getBranchMaster();
    this.getStates();
  }

  getBranchMaster = async () => {
    this.setState({ isSpinnerOnLoad: true });

    // const params = {
    //     state_id:
    //       this.state.selectedStateValue === null
    //         ? null
    //         : [this.state.selectedStateValue],

    //   };

    await API.get("/master_branch")
      .then((varietyResponse) => {

        varietyResponse.data.data.map((dataDetailItem, index) => {
          dataDetailItem.key = index;
          dataDetailItem.isEdit = false;
        });
        this.setState(
          {
            BranchTableData: varietyResponse.data.data,
            // BranchTableData:[]
          },
          () => this.setState({ isSpinnerOnLoad: false, isAdd: true })
        );

      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400) message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };

  getStates = async () => {
    if (this.state.stateList === undefined || this.state.stateList === null || this.state.stateList === [] || this.state.stateList.length <= 0) {
      this.setState({ isSpinnerOnLoad: true });


      await API.get("/get_states")
        .then((stateLis) => {
          // let modifiedData = [...stateList.data.data];
          // modifiedData.push({ state_id: null, state_name: "All" });


          this.setState({
            //   selectedStateList: modifiedData,
            stateList: stateLis.data.data,
            //   selectedBranchValue: stateList.data.data[0].territory_id,
            //   selectedBranchName: stateList.data.data[0].territory_name,
          });
        })
        .catch((err) => {
          if (err) {
            if (err.response && err.response.status === 400)
              message.error("Something went wrong. Please try again later.", 5);
            else {
              message.error("Something went wrong.. Please try again later.", 5);
            }
          } else {
            message.error("Something went wrong... Please try again later.", 5);
          }
          this.setState({ isSpinnerOnLoad: false });
        });
    }
  };

  setTableData = () => {
    if (this.state.BranchTableData !== undefined || this.state.BranchTableData.length >= 0) {
      const userTableData = this.state.BranchTableData.map((user, index) => {
        return {
          key: user.key,
          is_active: user.is_active,
          state: user.state,
          territory_id: user.territory_id,
          territory_name: user.territory_name,
          territory_sap_code: user.territory_sap_code,
          factor:user.factor,
          isEdit: user.isEdit,
        };
      });

      let dataAfterSearch = userTableData;

      if (this.state.searchText) {
        dataAfterSearch = dataAfterSearch.filter(
          (item) =>
            (item.territory_name !== null &&
              item.territory_name
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase())) ||
            (item.state !== null &&
              item.state
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase()))
          // (item.hub_name !== null &&
          //   item.hub_name
          //     .toLowerCase()
          //     .includes(this.state.searchText.toLowerCase())) 
        );
      }

      return dataAfterSearch;
    }
    else {
      return this.state.BranchTableData;
    }
  };

  submitTableRowData = (row, index, isPopup) => {
    this.setState({ isSpinnerOnLoad: true, isSubmitted: true });
    let warningmessage = "";
    if (row.territory_name === null || row.territory_name === undefined || row.territory_name === "") {
      warningmessage = "Please enter branch";
    }
    else if (row.state === null || row.state === undefined || row.state === "") {
      warningmessage = "Please select state";
    }

    if (
      warningmessage === "" ||
      warningmessage === undefined ||
      warningmessage === null
    ) {
      this.setState({ isSpinnerOnLoad: true });
      this.setState({ isSubmitted: true })
      let data = {

        is_active: row.is_active ? 1 : 0,
        state: row.state,
        state_id: row.state_id,
        territory_id: row.territory_id,
        territory_name: row.territory_name,
        factor:row.factor,
        territory_sap_code: row.territory_sap_code === "" || row.territory_sap_code === undefined
          ? null
          : row.territory_sap_code,
      };


      //TODO: change api
      API.put("/master_branch", data)
        .then((response) => {
          if (response.status === 200 && response.data.status) {
            message.info(response.data.message, 5);
            this.setModalVisibility(false);
            this.getBranchMaster();
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
          } else if (!response.data.status) {
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
            message.error(response.data.message, 5);
            // this.setModalVisibility(false);
          } else {
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
            message.error("Please try again", 5);
          }
        })
        .catch((err) => {
          if (err) {
            if (err.response && err.response.status === 400) message.error("Something went wrong. Please try again later.", 5);
            else {
              message.error("Something went wrong.. Please try again later.", 5);
            }
          } else {
            message.error("Something went wrong... Please try again later.", 5);
          }
          this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
          // this.setModalVisibility(false);
        });
    } else {
      this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
      message.error(warningmessage, 5);
    }
  };

  setModalVisibility = (status) => {
    if (status) {
      const nData = {
        is_active: true,
        state: "",
        territory_id: undefined,
        territory_name: "",
        territory_sap_code: "",
        factor:undefined,
        isEdit: false,
      };

      this.setState({ newData: nData });
    }

    this.setState({ isModalVisible: status });
  };

  TableColumnHeader = (props) => {
    const { title, subtitle } = props;
    return (
      <div className="column-header-container">
        <div
          style={{
            color: "white",
            fontSize: "12px",
            whiteSpace: "nowrap",
            fontWeight: "normal",
            textAlign: "left",
          }}
        >
          {title}
        </div>
        <div
          style={{
            color: "#cbdfff",
            fontSize: "11px",
            fontWeight: "normal",
            textAlign: "left",
            // subtitle && title.length < subtitle.length ? "left" : "center"
          }}
        >
          {subtitle}
        </div>
      </div>
    );
  };

  createFilters = (label) => {
    let filterData = this.state.BranchTableData;
    //#region remove duplicate objects
    let uniqueFilterData = [];

    if (label === "is_active" && this.state.BranchTableData && this.state.BranchTableData.length > 0) {
      uniqueFilterData = [{ text: "Yes", value: 1 }, { text: "No", value: 0 }]
    }
    else {
      filterData.map((mainItem) =>
        uniqueFilterData.filter((item) => item[label] === mainItem[label])
          .length > 0
          ? null
          : uniqueFilterData.push(mainItem)
      );

      uniqueFilterData = uniqueFilterData.map((item) => {
        return {
          text: item[label],
          value: item[label],
        };

      });

      uniqueFilterData.sort((a, b) => a.text.localeCompare(b.text))
    }

    return uniqueFilterData;
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  handleEditChange = (row, index) => {
    this.setState({ rowData: row });
    let tempData = this.state.BranchTableData.map((item) => {

      if (item.key === row.key) {
        return {
          ...item,
          isEdit: true
        }
      } else {
        return item;
      }
    })
    this.setState({ BranchTableData: tempData });
  };

  handleCancelClick = (rowData, index) => {
    let tempData = this.state.BranchTableData.map((item) => {
      if (item.key === rowData.key) {
        return this.state.rowData
      } else {
        return item;
      }
    })
    this.setState({ BranchTableData: tempData });
  };


  handleChange = (e, row, index, name, isPopup) => {

    let a;
    var alphaNumericRegex = /^[a-zA-Z0-9]*$/;
    var numberRegex = /^[0-9.]+$/;
    if (isPopup === false) {
      a = this.state.BranchTableData;

      switch (name) {
        case "territory_name":
          a[row.key].territory_name = e.target.value;
          this.setState({ BranchTableData: a });
          return;
        case "territory_sap_code":
          if (e.target.value === "") {
            a[row.key].territory_sap_code = "";
          } else if (e.target.value.match(alphaNumericRegex)) {
            a[row.key].territory_sap_code = e.target.value.toUpperCase();
          }

          this.setState({ BranchTableData: a });
          return;
        case "territory_latitude":
          if (e.target.value === "") {
            a[row.key].territory_latitude = "";
          } else if (e.target.value.match(numberRegex)) {
            a[row.key].territory_latitude = e.target.value;
          }

          this.setState({ BranchTableData: a });
          return;
        case "territory_longitude":
          if (e.target.value === "") {
            a[row.key].territory_longitude = "";
          } else if (e.target.value.match(numberRegex)) {
            a[row.key].territory_longitude = e.target.value;
          }

          this.setState({ BranchTableData: a });
          return;
          case "factor":
            if (e.target.value === "") {
              a[row.key].factor = "";
            } else if (e.target.value.match(numberRegex)) {
              a[row.key].factor = e.target.value;
            }
  
            this.setState({ BranchTableData: a });
            return;
        default:
          return "";
      }

    } else {
      a = this.state.newData;

      switch (name) {
        case "territory_name":
          a.territory_name = e.target.value;
          this.setState({ newData: a });
          return;
        case "territory_sap_code":
          if (e.target.value === "") {
            a.territory_sap_code = "";
          } else if (e.target.value.match(alphaNumericRegex)) {
            a.territory_sap_code = e.target.value.toUpperCase();
          }

          this.setState({ newData: a });
          return;
        case "territory_latitude":
          if (e.target.value === "") {
            a.territory_latitude = "";
          } else if (e.target.value.match(numberRegex)) {
            a.territory_latitude = e.target.value;
          }

          this.setState({ newData: a });
          return;
        case "territory_longitude":
          if (e.target.value === "") {
            a.territory_longitude = "";
          } else if (e.target.value.match(numberRegex)) {
            a.territory_longitude = e.target.value;
          }

          this.setState({ newData: a });
          return;
        case "factor":
          if (e.target.value === "") {
            a.factor = "";
          } else if (e.target.value.match(numberRegex)) {
            a.factor = e.target.value;
          }

          this.setState({ newData: a });
          return;
        default:
          return "";
      }
    }
  };

  handleDropdownChange = (value, row, target, index, optionlabel, isPopup) => {
    let a;

    if (isPopup === false) {
      a = this.state.BranchTableData;

      if (target === "is_active") {
        a[row.key].is_active = Boolean(value);
      }
      else if (target === "state") {
        a[row.key].state = optionlabel;
        a[row.key].state_id = value;
      }

      this.setState({ BranchTableData: a });
    } else {
      a = this.state.newData;

      if (target === "is_active") {
        a.is_active = Boolean(value);
      }
      else if (target === "state") {
        a.state = optionlabel;
        a.state_id = value;
      }

      this.setState({ newData: a });
    }
  };

  render() {
    const userTableProps = {
      pagination: false,
      size: "default",
      bordered: true,
      scroll: { x: 200, y: window.innerHeight - 250 },
    };

    const handleStateSelect = (value, props) => {
      this.setState(
        { selectedStateName: props.key, selectedStateValue: value },
        () => this.getBranchMaster()
      );
    };

    return (
      <div
        className="mandi-master-container"
        style={{
          height: window.innerHeight - 85,
          marginTop: "-45px",
        }}
      >
        <div className="top-container">
          {/*
      <div className="hub-region-filter">
            <span
              style={{
                fontSize: "15px",
                marginTop: "5px",
                fontWeight: "600",
              }}
            >
              &nbsp;
            </span>{" "}
            <Select
              disabled={!this.state.isAdd}
              showSearch
              optionFilterProp="children"
              className={"select-master-region"}
              mode="single"
              placeholder="Select State"
              value={this.state.selectedstateValue}
              onChange={(value, props) => handleStateSelect(value, props)}
            >
              {this.state.selectedStateList !== null &&
                this.state.selectedStateList.length &&
                this.state.selectedStateList.map((item, idx) => (
                  <Option value={item.state_id} key={item.state_name}>
                    {item.state_name}
                  </Option>
                ))}
            </Select>
          </div>
                */}

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row-reverse",
              placeItems: "flex-end",
              marginRight: "25px",
              marginBottom: "10px",
            }}
          >
            <Search
              disabled={!this.state.isAdd}
              placeholder="Search"
              allowClear
              onChange={(e) => this.setState({ searchText: e.target.value })}
              style={{ width: "25%" }}
            />
            <Button
              className="add-master-button"
              style={{
                height: "25px",
                width: "70px",
                backgroundColor: "#0b133d",
                color: "white",
              }}
              disabled={!canUserEdit(
                            this.props.userData,
                            null,
                            FeatureCodes.MS_BRANCH_EDIT
                          ) || !this.state.isAdd}
              onClick={() => this.setModalVisibility(true)}
            >
              &#10010; Add
            </Button>
          </div>
        </div>

        <div>
        <div
        style={{
          marginLeft: "25px",
          marginTop: "-8px",
          fontSize: "14px",
          color: "red",
          display: "flex",
          float: "left",
        }}
      > {getUserMSRBACMessage(this.props.messageAccess,FeatureCodes.MS_BRANCH_EDIT)}
      </div>
        <div
          style={{
            marginRight: "20px",
            marginTop: "-8px",
            fontSize: "15px",
            color: "#0B133D",
            display: "flex",
            float: "right",
          }}
        >Total number of records:&nbsp;
          <b>{this.state.BranchTableData && this.setTableData().length}</b>
        </div>
</div>

        <Spin
          spinning={this.state.isSpinnerOnLoad}
          size={"large"}
          tip="Loading..."
          style={{ alignSelf: "center", zIndex: 9999, marginTop: "20px" }}
        >
          <Modal
            className="add-master-modal"
            title={"Add Branch"}
            onCancel={this.handleCancel}
            footer={false}
            visible={this.state.isModalVisible}
            destroyOnClose={true}
          >
            <div className="master-form-container">
              <Form
                layout="horizontal"
                //   onValuesChange={onFormLayoutChange}
                size={"small"}
              // onSubmit={submitTableRowData(this.state.newData)}
              >
                <div className="form-top-container1">
                  <div>
                    <div
                      className="checkbox-group1"
                      style={{
                        display: "inline-block",
                        width: "45%",
                        marginRight: 40,
                      }}
                    >
                      <Form.Item label="Branch">
                        <Input
                          style={{ marginRight: "2px" }}
                          maxLength={100}
                          min="0"
                          value={this.state.newData.territory_name}
                          name="territory_name"
                          onChange={(e) =>
                            this.handleChange(
                              e,
                              this.state.newData,
                              -0,
                              "territory_name",
                              true
                            )
                          }
                        />
                      </Form.Item>
                    </div>

                    <div
                      className="form-top-container"
                      style={{
                        display: "inline-block",
                        width: "33%",
                        marginRight: 7,
                      }}
                    >
                      <Form.Item label="State">
                        <Select
                          // className="remarks-select"
                          showSearch
                          optionFilterProp="children"
                          name="state"
                          required
                          value={this.state.newData.state}
                          onSelect={(value, option) =>
                            this.handleDropdownChange(
                              value,
                              this.state.newData,
                              "state",
                              -0,
                              option.props.children,
                              true
                            )
                          }
                        >
                          {this.state.stateList &&
                            this.state.stateList.length &&
                            this.state.stateList.map((hubItem, idx) => (
                              <Option
                                key={hubItem.state_id}
                                value={hubItem.state_id}
                              >
                                {hubItem.state_name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </div>

                  </div>
                  <hr />

                  <div>


                    <div
                      className="checkbox-group1"
                      style={{
                        display: "inline-block",
                        width: "41%",
                        marginRight: 40,
                      }}
                    >
                      <Form.Item label="Sap Code">
                        <Input
                          style={{ marginRight: "2px" }}
                          maxLength={100}
                          min="0"
                          value={this.state.newData.territory_sap_code}
                          name="territory_sap_code"
                          onChange={(e) =>
                            this.handleChange(
                              e,
                              this.state.newData,
                              -0,
                              "territory_sap_code",
                              true
                            )
                          }
                        />
                      </Form.Item>
                    </div>

                    <div
                    className="checkbox-group1"
                    style={{
                      display: "inline-block",
                      width: "41%",
                      marginRight: 40,
                    }}
                  >
                    <Form.Item label="Area Factor">
                      <Input
                        style={{ marginRight: "2px" }}
                        maxLength={100}
                        min="0"
                        value={this.state.newData.factor}
                        name="factor"
                        onChange={(e) =>
                          this.handleChange(
                            e,
                            this.state.newData,
                            -0,
                            "factor",
                            true
                          )
                        }
                      />
                    </Form.Item>
                  </div>


                    <div
                      className="form-top-container"
                      style={{
                        display: "inline-block",
                        width: "40%",
                        marginRight: 7,
                      }}
                    >
                      <Form.Item label="Active">
                        <Radio.Group
                          name="is_active"
                          value={this.state.newData.is_active ? 1 : 0}
                          onChange={(e, option) =>
                            this.handleDropdownChange(
                              e.target.value,
                              this.state.newData,
                              "is_active",
                              -0,
                              "",
                              true
                            )
                          }
                        >
                          <Radio value={1}>Yes</Radio>
                          <Radio value={0}>No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </div>
                  </div>
                </div>

                <div className="form-bottom-container">
                  <Form.Item>
                    <Button
                      style={{ marginTop: "20px" }}
                      type="primary"
                      htmlType="submit"
                      disabled={this.state.isSubmitted}
                      onClick={() =>
                        this.submitTableRowData(this.state.newData, 0, true)
                      }
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </Modal>


          <div className="table-container1" style={{ maxHeight: "450px", width: "99%", margin: "6px 0px" }}>
            <Table
              {...userTableProps}
              columns={[
                {
                  key: "territory_name",
                  title: "Branch",
                  dataIndex: "territory_name",
                  width: 120,
                  filters: this.createFilters("territory_name"),
                  onFilter: (value, record) => record.territory_name.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.territory_name.localeCompare(b.territory_name),
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            disabled={!rowRecord.isEdit}
                            style={{ marginRight: "2px" }}
                            maxLength={100}
                            min="0"
                            value={rowRecord.territory_name}
                            name="territory_name"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "territory_name",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },

                {
                  title: "State",
                  dataIndex: "state",
                  key: "state",
                  width: 110,
                  // filters: this.createFilters("state"),
                  // onFilter: (value, record) =>
                  //   record.state.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.state.localeCompare(b.state),
                  render: (record, rowRecord) => {
                    return (
                      <>
                        <div
                          style={{ marginLeft: "-15px", marginRight: "-10px" }}
                        >
                          <Select
                            disabled={!rowRecord.isEdit}
                            // className="remarks-select"
                            showSearch
                            optionFilterProp="children"
                            name="state"
                            required
                            value={rowRecord.state}
                            onSelect={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "state",
                                -0,
                                option.props.children,
                                false
                              )
                            }
                          >
                            {this.state.stateList &&
                              this.state.stateList.length &&
                              this.state.stateList.map((hubItem, idx) => (
                                <Option
                                  key={hubItem.state_id}
                                  value={hubItem.state_id}
                                >
                                  {hubItem.state_name}
                                </Option>
                              ))}
                          </Select>

                        </div>
                      </>
                    );
                  },
                },
                {
                  key: "territory_sap_code",
                  title: "Sap Code",
                  dataIndex: "territory_sap_code",
                  width: 80,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.territory_sap_code - b.territory_sap_code,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            disabled={!rowRecord.isEdit}
                            style={{ marginRight: "2px" }}
                            maxLength={100}
                            min="0"
                            value={rowRecord.territory_sap_code}
                            name="territory_sap_code"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "territory_sap_code",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },
                {
                  key: "factor",
                  title: "Area Factor",
                  dataIndex: "factor",
                  width: 80,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.factor - b.factor,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            disabled={!rowRecord.isEdit}
                            style={{ marginRight: "2px" }}
                            maxLength={100}
                            min="0"
                            value={rowRecord.factor}
                            name="factor"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "factor",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },
                {
                  key: "is_active",
                  title: "Active",
                  dataIndex: "is_active",
                  width: 50,
                  filters: this.createFilters("is_active"),
                  // filters: [{ text: "Yes", value: 1 }, { text: "No", value: 0 }],
                  onFilter: (value, record) =>
                    record.is_active === Boolean(value),
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-15px", marginRight: "-40px" }}>
                          <Select
                            showSearch
                            optionFilterProp="children"
                            disabled={!rowRecord.isEdit}
                            defaultValue={
                              rowRecord.is_active === true ? "Yes" : "No"
                            }
                            value={
                              rowRecord.is_active === true ? "Yes" : "No"
                            }
                            onChange={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "is_active",
                                index,
                                option.props.children,
                                false
                              )
                            }
                            style={{ width: "70%" }}
                          >
                            <Option key={1} value={1}>
                              {"Yes"}
                            </Option>
                            <Option key={0} value={0}>
                              {"No"}
                            </Option>
                          </Select>
                        </div>
                      </>
                    );
                  },
                },

                {
                  title: "Action",
                  dataIndex: "Submit",
                  key: "Submit",
                  width: 120,
                  visibility: false,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ display: "inline-flex", width: "100%" }}>
                          <Button
                            className={!canUserEdit(
                              this.props.userData,
                              null,
                              FeatureCodes.MS_BRANCH_EDIT
                            ) ? "disabledBtnClass" : "btnClass"}
                            disabled={!canUserEdit(
                              this.props.userData,
                              null,
                              FeatureCodes.MS_BRANCH_EDIT
                            )}
                            type="primary"
                            size={"small"}
                            style={{
                              marginLeft: 5,
                              alignSelf: "center",
                              padding: "0px 0px 2px",
                              height: 31,
                              backgroundColor: "#20346a",
                              color: "white",
                              borderRadius: 15,
                            }}
                            block
                            htmlType="submit"
                            onClick={() =>
                              rowRecord.isEdit
                                ? this.submitTableRowData(rowRecord, index, false)
                                : this.handleEditChange(rowRecord, index)
                            }
                          >
                            {" "}
                            {rowRecord.isEdit ? "Submit" : "Edit"}{" "}
                          </Button>


                          <Button
                            className={!rowRecord.isEdit ? "disabledBtnClass" : "btnClass"}
                            type="primary"
                            size={"small"}
                            style={{
                              marginLeft: 5,
                              alignSelf: "center",
                              color: "white",
                              borderRadius: 15,
                              padding: "0px 0px 2px",
                              height: 31,
                              backgroundColor: "#20346a",
                            }}
                            block
                            htmlType="submit"
                            disabled={!rowRecord.isEdit}
                            onClick={() => this.handleCancelClick(rowRecord, index)
                            }
                          >
                            Cancel
                          </Button>
                        </div>
                      </>
                    );
                  },
                },
              ]}
              dataSource={this.setTableData()}
            />
          </div>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
  messageAccess: selectMSMessageRBAC,
});
export default connect(mapStateToProps)(BranchMaster)
//export default BranchMaster;
