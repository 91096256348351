import React from "react";
import "./actual-purchasing.styles.css";
import WithoutChooseHub from "../../components/without-choose-hub/without-choose-hub.component";
import ActualPurchaseInformationTable from "../../components/actual-purchase-information-table/actual-purchase-information-table.component";
import { Component } from "react";
import { Spin, message, Button, Progress } from "antd";

import moment from "moment";
import Axios from "axios";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  selectHubDataPostFOR,
  selectActualHubTableData,
  selectAvailableHubs,
  selectDecisionBranches,
  selectDecisionRegions,
  selectDecisionVarieties,
  selectSelectedBranchID,
  selectSelectedRegionID,
  selectSelectedVarietyID,
} from "../../redux/buying-decision/buying-decision.selectors";

import { selectAllVarietyInfo } from "../../redux/arrival-entry/arrival-entry.selectors";

import { selectSuggestedData } from "../../redux/buying-recommendation/buying-recommendation.selectors";
import {
  setSuggestedData,
  addSuggestedData,
} from "../../redux/buying-recommendation/buying-recommendation.actions";

import {
  setActualHubTableData,
  setActualHubDataPostFOR,
} from "../../redux/buying-decision/buying-decision.actions";

import API, { baseURL } from "../../api";

const FileDownLoad = require("js-file-download");

/**
 *
 * @description Buying decision 'planned' component.
 * @component
 * @memberof BuyingDecisionContainer
 *
 */
class ActualPurchasing extends Component {
  state = {
    isSpinnerOn: true,
    isSpinnerOnProgress: false,
    currentID: null,
    selectedRegionsData: [],
    selectedVarietiesData: [],
    ProgressClicked: false,
    startDate: "",
    endDate: "",
    hubData: [],
  };

  /**
   * @function
   * @description Stored FOR data to send in redux.
   * @param {object} data Hub table data.
   * @memberof BuyingDecisionContainer.ActualPurchasing
   */
  setActualFORData = (data) => {
    this.spinnerOn(true);
    const forData =
      data.region &&
      data.region.hub_details
        .map((item, idx) => {
          if (
            this.props.actualHubTableData.region.hub_details[idx]
              .source_type !== "mandi"
          ) {
            return {
              hub_id: item.hub_id,
              buying_decision_id: item.buying_decision_id,
              temp_bd_id: item.temp_bd_id,
              revised_qty: item.revised_qty,
              revised_buy_min_price: item.revised_buy_min_price,
              revised_buy_max_price: item.revised_buy_max_price,
              remark_id: item.remark_id ? item.remark_id : null,
              region_id: item.region_id,
              variety_id: item.variety_id,
              source_type: item.source_type,
              claim_type: item.claim_type,
              division: item.division,
              hub_name: item.hub_name,
              variety_name: item.variety_name,
            };
          }
        })
        .filter((item) => item !== undefined);
    //
    this.props.setActualHubDataPostFOR(forData);
    //

    this.setState((prevState) => ({
      ...prevState,
      // FORData: forData,
      isSpinnerOn: false,
    }));
  };

  componentDidMount() {
    this.setActualFORData(this.props.actualHubTableData);
  }

  /**
   * @function
   * @description Toggle loading spinner (on/off).
   * @memberof BuyingDecisionContainer.BuyingDecisioning
   */
  spinnerOn = (value) => {
    this.setState({ isSpinnerOn: value });
  };
  setSelectedData = (data) => {
    this.setState({
      selectedRegionsData: data[0],
      selectedVarietiesData: data[1],
      startDate: data[2],
      endDate: data[3],
    });
  };
  count = 0;

  /**
   * @function
   * @description Get the buying decision data for a corresponding variety.
   * @param {number} variety_id the id of the variety for which data needs to be retrieved.
   * @memberof ChooseHub
   */
  getActualHubTableData = async () => {
    // this.props.setActualHubTableData({});
    this.setActualFORData([]);
    this.spinnerOn(true);
    this.setState({ hubData: [] });

    //#region api call for getting buying decision data
    const params = {
      branch_id: this.props.selectedBranchID,
      region_id: this.state.selectedRegionsData,
      variety_id: this.state.selectedVarietiesData,
      start_date:
        this.state.startDate !== ""
          ? this.state.startDate
          : moment().format("YYYY-MM-DD"),
      end_date:
        this.state.endDate !== ""
          ? this.state.endDate
          : moment().format("YYYY-MM-DD"),
    };
    const HUB_DATA_END_POINT = `/buying_decision_actual`;
    await API.get(HUB_DATA_END_POINT, {
      headers: {
        "Content-Type": "application/json",
      },
      params: params,
    })
      // const HUB_DATA_END_POINT = `/branches/${this.props.selectedBranchID}/regions/${this.props.selectedRegionID}/variety/${variety_id}`;
      // API.get(HUB_DATA_END_POINT, { headers: this.headers,})
      .then((buyingResponse) => {
        this.spinnerOn(false);

        if (buyingResponse.data) {
          // this.props.setSelectedData([this.state.selectedRegions.length ? this.state.selectedRegions : [this.state.selectedRegions], this.state.selectedVarieties.length ? this.state.selectedVarieties : [this.state.selectedVarieties]])
          const modifiedHubDataStructure = {
            region: {
              hub_details: buyingResponse.data.data.hub_list.map(
                (item, idx) => {
                  return { ...item, edited: false, checked: false }; // , index: idx
                }
              ),
            },
          };
          this.props.setActualHubTableData(modifiedHubDataStructure);
          this.setState({ hubData: modifiedHubDataStructure });

          // this.props.setRollUp(
          //   buyingResponse.data.data.mandi_roll_up,
          //   buyingResponse.data.data.for_roll_up,
          //   buyingResponse.data.data.farmer_roll_up
          // );
          this.props.setRollUp({}, buyingResponse.data.data.for_roll_up, {});

          // // stores hubs with FOR in the buying decision local state for post.
          this.setActualFORData(this.props.actualHubTableData);
          this.spinnerOn(false);
          // //
        }
      })
      .catch((err) => {
        this.props.setActualHubTableData({});

        //
        this.setActualFORData([]);
        this.spinnerOn(false);
      });

    //#endregion
    //
  };

  render() {
    //this is redundant. below two functions are also present in mandiinformationtable.
    //maybe needed in the future. use to set the min and max ranges for landing cost.
    //verify whether to multiply by 10 to factor for quintels to MT - multiplied by 10.
    const getVarietyMinPrice = () => {
      const minPrice =
        this.props.allVarietyInfo.find(
          (item) => item.variety_id === this.props.selectedVarietyID
        ) &&
        this.props.allVarietyInfo.find(
          (item) => item.variety_id === this.props.selectedVarietyID
        ).variety_price_min;
      return minPrice * 10;
    };
    const getVarietyMaxPrice = () => {
      const maxPrice =
        this.props.allVarietyInfo.find(
          (item) => item.variety_id === this.props.selectedVarietyID
        ) &&
        this.props.allVarietyInfo.find(
          (item) => item.variety_id === this.props.selectedVarietyID
        ).variety_price_max;
      return maxPrice * 10;
    };

    // percentageLimit

    /**
     *
     * @description Update the store if remark is added.
     * @param {string} clickedKey
     * @param {string} value
     * @param {string} sourceType
     * @memberof BuyingDecisionContainer.BuyingDecisioning
     */
    const setRemarkHubRowFORPost = (clickedKey, value, sourceType, record) => {
      let updateHubRedux = this.props.actualHubTableData.region.hub_details.map(
        (hubItem) => {
          if (
            hubItem.hub_id === clickedKey &&
            hubItem.buying_decision_id === record.data.buying_decision_id &&
            hubItem.claim_type === record.data.claim_type &&
            hubItem.division === record.data.division &&
            hubItem.variety_name === record.data.variety_name
          ) {
            return {
              ...hubItem,
              edited: true,
              ui_purchase_remarks: value,
              checked: true,
            };
          }
          return hubItem;
        }
      );

      let dataModified = {
        region: {
          hub_details: updateHubRedux,
        },
      };

      this.props.setActualHubTableData(dataModified);

      let data = this.props.hubDataPostFOR;
      //this.state.FORData;

      if (value != null) {
        data = data.map((item) => {
          if (
            item.hub_id === clickedKey &&
            item.buying_decision_id === record.data.buying_decision_id &&
            item.claim_type === record.data.claim_type &&
            item.division === record.data.division &&
            item.variety_name === record.data.variety_name
          ) {
            return { ...item, ui_purchase_remarks: value };
          }
          return item;
        });
      }

      this.props.setActualHubDataPostFOR(data);
    };

    /**
     *
     * @description Update the store if quantity is modified
     * @param {number} clickedKey
     * @param {string} quantity
     * @param {string} tlc
     * @param {string} sourceType
     * @memberof BuyingDecisionContainer.BuyingDecisioning
     */
    const setQuantityHubRowFORPost = (
      clickedKey,
      quantity,
      tlc,
      sourceType,
      record
    ) => {
      let updateHubRedux = this.props.actualHubTableData.region.hub_details.map(
        (hubItem) => {
          if (
            hubItem.hub_id === clickedKey &&
            hubItem.buying_decision_id === record.data.buying_decision_id &&
            hubItem.claim_type === record.data.claim_type &&
            hubItem.division === record.data.division &&
            hubItem.variety_name === record.data.variety_name &&
            quantity === 0
          ) {
            return {
              ...hubItem,
              edited: true,
              ui_purchase_qty: quantity,
              ui_purchase_price: 0,
              checked: true,
            };
          } else if (
            hubItem.hub_id === clickedKey &&
            hubItem.buying_decision_id === record.data.buying_decision_id &&
            hubItem.claim_type === record.data.claim_type &&
            hubItem.division === record.data.division &&
            hubItem.variety_name === record.data.variety_name
          ) {
            return {
              ...hubItem,
              edited: true,
              ui_purchase_qty: quantity,
              checked: true,
            };
          }
          return hubItem;
        }
      );

      let dataModified = {
        region: {
          hub_details: updateHubRedux,
        },
      };

      this.props.setActualHubTableData(dataModified);

      let data = this.props.hubDataPostFOR;

      if (quantity != null) {
        if (quantity === 0) {
          data = data.map((item) => {
            if (
              item.hub_id === clickedKey &&
              item.buying_decision_id === record.data.buying_decision_id &&
              item.claim_type === record.data.claim_type &&
              item.division === record.data.division &&
              item.variety_name === record.data.variety_name
            ) {
              return {
                ...item,
                ui_purchase_qty: quantity,
                ui_purchase_price: 0,
              };
            }
            return item;
          });
        } else {
          data = data.map((item) => {
            if (
              item.hub_id === clickedKey &&
              item.buying_decision_id === record.data.buying_decision_id &&
              item.claim_type === record.data.claim_type &&
              item.division === record.data.division &&
              item.variety_name === record.data.variety_name
            ) {
              return { ...item, ui_purchase_qty: quantity };
            }
            return item;
          });
        }
      }

      this.props.setActualHubDataPostFOR(data);
    };

    /**
     *
     * @description Update the store if landing cost is modified
     * @param {*} clickedKey
     * @param {*} tlc
     * @param {*} isMin
     * @param {*} sourceType
     * @memberof BuyingDecisionContainer.BuyingDecisioning
     */
    const setTLCHubRowFORPost = (
      clickedKey,
      tlc,
      isMin,
      sourceType,
      record
    ) => {
      // let data = this.state.FORData;
      let data = this.props.hubDataPostFOR;

      let updateHubRedux = this.props.actualHubTableData.region.hub_details.map(
        (hubItem) => {
          //below commented is to update only edited key

          // if (
          //   hubItem.hub_id === clickedKey &&
          //   hubItem.claim_type === record.data.claim_type &&
          //   hubItem.division === record.data.division &&
          //   hubItem.source_type === sourceType &&
          //   hubItem.region_id === record.data.region_id &&
          //   hubItem.variety_id === record.data.variety_id
          // ) {
          //   return { ...hubItem, edited: true };
          // }
          // return hubItem;

          //below code is to update edited key along with min and max revised prices

          if (isMin) {
            if (tlc != null) {
              if (
                hubItem.hub_id === clickedKey &&
                hubItem.buying_decision_id === record.data.buying_decision_id &&
                hubItem.claim_type === record.data.claim_type &&
                hubItem.division === record.data.division &&
                hubItem.variety_name === record.data.variety_name &&
                record.data.ui_purchase_qty !== 0
              ) {
                return {
                  ...hubItem,
                  ui_purchase_price: tlc,
                  edited: true,
                  checked: true,
                };
              }
              return hubItem;
            } else {
              return hubItem;
            }
          }
          // else {
          //   if (tlc != null) {
          //     if (
          //       hubItem.hub_id === clickedKey &&
          //       hubItem.claim_type === record.data.claim_type &&
          //       hubItem.division === record.data.division &&
          //       hubItem.source_type === sourceType &&
          //       hubItem.region_id === record.data.region_id &&
          //       hubItem.variety_id === record.data.variety_id
          //     ) {
          //       return { ...hubItem, revised_buy_max_price: tlc, edited: true, checked: true };
          //     }
          //     return hubItem;
          //   } else {
          //     return hubItem;
          //   }
          // }
        }
      );

      let dataModified = {
        region: {
          hub_details: updateHubRedux,
        },
      };

      this.props.setActualHubTableData(dataModified);

      if (isMin) {
        if (tlc != null) {
          data = data.map((item) => {
            if (
              item.hub_id === clickedKey &&
              item.buying_decision_id === record.data.buying_decision_id &&
              item.claim_type === record.data.claim_type &&
              item.division === record.data.division &&
              item.variety_name === record.data.variety_name &&
              record.data.ui_purchase_qty !== 0
            ) {
              return { ...item, ui_purchase_price: tlc };
            }
            return item;
          });
        }
      }
      // else {
      //   if (tlc != null) {
      //     data = data.map((item) => {
      //       if (
      //         item.hub_id === clickedKey &&
      //         item.claim_type === record.data.claim_type &&
      //         item.division === record.data.division &&
      //         item.source_type === sourceType &&
      //         item.region_id === record.data.region_id &&
      //         item.variety_id === record.data.variety_id
      //       ) {
      //         return { ...item, revised_buy_max_price: tlc };
      //       }
      //       return item;
      //     });
      //   }
      // }

      this.props.setActualHubDataPostFOR(data);

      // this.setState({ FORData: data }, () =>
      // );
    };

    const getDivisionID = (divisionName) => {
      switch (divisionName) {
        case "ABD":
          return 1;
        case "FBD":
          return 2;
        case "NCDEX":
          return 3;
        default:
          return 1;
      }
    };
    const getClaimID = (claimName) => {
      switch (claimName) {
        case "Claim":
          return 1;
        case "No Claim":
          return 2;
        default:
          return 1;
      }
    };

    const handleSubmitClick = (data) => {
      // console.log("clickedTableRow --------------->", data, "=====================> ", this.state.selectedVarietiesData, this.state.selectedRegionsData, "this.props.hubDataPostFOR", this.props.hubDataPostFOR);
      this.spinnerOn(true);
      this.setState({ hubData: [] });
      const {
        selectedBranchID,
        selectedRegionID,
        selectedVarietyID,
        setActualHubTableData,
      } = this.props;
      let refresh = false;
      // setSubmittedStatus(true);

      const headers = {
        "Content-Type": "application/json",
      };
      // console.log("clickedTableRow --------------->", clickedTableRow, "=====================> ", rowRecord);

      const params = {
        branch_id: this.props.selectedBranchID,
        region_id: this.state.selectedRegionsData,
        variety_id: this.state.selectedVarietiesData,
        start_date:
          this.state.startDate !== ""
            ? this.state.startDate
            : moment().format("YYYY-MM-DD"),
        end_date:
          this.state.endDate !== ""
            ? this.state.endDate
            : moment().format("YYYY-MM-DD"),
      };
      const PRICING_POST_URL = `/buying_decision_actual`;
      // const PRICING_POST_URL = `/branches/${selectedBranchID}/regions/${rowRecord.regionID}/variety/${rowRecord.varietyID}`;

      let dataToSend = data.region.hub_details
        .map((element) => {
          // console.log("this.props.hubDataPostFOR --------------->", element);
          if (element.edited) return element;
          else return undefined;
        })
        .filter((item) => item !== undefined);

      // #region if dataToSend is having a null buying decision id,
      // it is a newly added hub. we need to populate data from the actualHubTableData(newly added hubs will be present at this time)
      //in redux and modify the posting structure accordingly.
      ////   // dataToSend = dataToSend.map((item, index) => {
      //   if (item.buying_decision_id !== null) {
      //     console.log("Selected hub from hubdatapostFOR : ", item);
      //     return {
      //       buying_decision_id: item.buying_decision_id,
      //       purchase_qty: item.ui_purchase_qty,
      //       purchase_price: item.ui_purchase_price,
      //       purchase_remarks: item.ui_purchase_remarks
      //     }
      //   }
      // })

      //here add any further feature requirement keys.
      // dataToSend = {
      //   ...dataToSend,
      //   download: 0,
      //   claim_type: claimType,
      //   division: division,
      //   price_uom: "RS/MT",
      // };

      //#endregion
      // console.log("Data to send For ALLLLLLLLLLLLLLLLL : ", dataToSend);

      // // this is the actual data being sent. add keys here, referencing dataToSend.
      // let modifiedDataToSend = {
      //   "purchase_details": dataToSend
      // }

      const validatePost = () => {
        let retVAl = true;
        if (
          dataToSend === null ||
          dataToSend === undefined ||
          dataToSend === "" ||
          dataToSend.length === 0
        ) {
          message.error("No Changes performed on the data !");
          retVAl = false;
        }

        // dataToSend.map(item => {
        for (let i = 0; i < dataToSend.length; i++) {
          let item = dataToSend[i];

          if (
            item.ui_purchase_qty === null ||
            item.ui_purchase_qty === undefined ||
            item.ui_purchase_qty === ""
          ) {
            message.error(
              `Please enter the quantity to be purchased for ${item.hub_name}, ${item.variety_name}, ${item.claim_type} and ${item.division} !`
            );
            retVAl = false;
          } else if (item.ui_purchase_qty !== 0 && !item.ui_purchase_price) {
            message.error(
              `Purchase price cannot be zero/empty for ${item.hub_name}, ${item.variety_name}, ${item.claim_type} and ${item.division} !`
            );
            retVAl = false;
          }

          // else if (!item.revised_min_price) {
          //   message.error(`Please enter the minimum price for${item.hub_name}, ${item.variety_name}, ${item.claim_type} and ${item.division} !`);
          //   retVAl = false;
          // }

          // else if (item.revised_max_price === 0) {
          //   message.error(`Maximum price cannot be zero for${item.hub_name}, ${item.variety_name}, ${item.claim_type} and ${item.division} !`);
          //   retVAl = false;
          // }

          // else if (!item.revised_max_price) {
          //   message.error(`Please enter the maximum price for${item.hub_name}, ${item.variety_name}, ${item.claim_type} and ${item.division} !`);
          //   retVAl = false;
          // }

          // else if (
          //   item.revised_min_price >= item.revised_max_price
          // ) {
          //   message.error(
          //     `The maximum price must be greater than the minimum price for${item.hub_name}, ${item.variety_name}, ${item.claim_type} and ${item.division} !`
          //   );
          //   retVAl = false;
          // }

          // else if (
          //   item.revised_min_price < getVarietyMinPrice() ||
          //   item.revised_max_price > getVarietyMaxPrice()
          // ) {
          //   // if (!item.remark_id) {
          //   //   message.error(
          //   //     "Please enter remark since landing cost must be between " +
          //   //       getVarietyMinPrice() +
          //   //       " ₹/MT and " +
          //   //       getVarietyMaxPrice() +
          //   //       " ₹/MT"
          //   //   );
          //   //retVAl = false
          //   // }
          //   message.error(
          //     `The landing cost must be between ${getVarietyMinPrice()} and ${getVarietyMaxPrice()} for${item.hub_name}, ${item.variety_name}, ${item.claim_type} and ${item.division} !`
          //   );
          //   retVAl = false;
          // }

          // // if (
          // //   item.revised_min_price < srp - srp * percentage - 1 ||
          // //   item.revised_max_price > srp + srp * percentage + 1
          // // )
          // // if (
          // //   item.revised_max_price - item.revised_min_price >
          // //   Math.round(srp * percentage) + 1
          // // ) {
          // //   if (!item.remark_id) {
          // //     message.error(
          // //       srp === 0
          // //         ? "Please add remark since you are not suggested to buy from this trader"
          // //         : "Please add remark since minimum and maximum cannot differ more than " +
          // //             (Math.round(srp * percentage) + 1) +
          // //             "₹/MT"
          // //     );
          // //     retVAl = false;
          // //   }
          // // }
          else if (
            !item.ui_purchase_remarks
            //  && (item.suggested_price == 0 || item.suggested_price === "" || item.suggested_price === null || item.suggested_price === undefined)
          ) {
            message.error(
              `Please add remark for ${item.hub_name}, ${item.variety_name}, ${item.claim_type} and ${item.division} !`
            );
            retVAl = false;
          }

          // else if (2 * 100 * ((item.revised_max_price - item.revised_min_price) / (item.revised_max_price + item.revised_min_price)) > percentage) {
          //   message.error(
          //     `Minimum and maximum cannot differ more than ${percentage}% for${item.hub_name}, ${item.variety_name}, ${item.claim_type} and ${item.division} !`
          //   );
          //   retVAl = false;
          // }

          // console.log(
          //   "limit :",
          //   2 * 100 * ((item.revised_max_price - item.revised_min_price) / (item.revised_max_price + item.revised_min_price)), retVAl
          // );

          if (!retVAl) {
            break;
          }
        }
        // })
        return retVAl;
      };

      if (validatePost()) {
        // dataToSend = dataToSend.map((item, index) => {
        //   if (item.buying_decision_id !== null) {
        //     console.log("Selected hub from hubdatapostFOR : ", item);
        //     return {
        //       buying_decision_id: item.buying_decision_id,
        //       purchase_qty: item.ui_purchase_qty,
        //       purchase_price: item.ui_purchase_price,
        //       purchase_remarks: item.ui_purchase_remarks
        //     }
        //   }
        // })

        // this is the actual data being sent. add keys here, referencing dataToSend.
        let modifiedDataToSend = {
          purchase_details: dataToSend.map((item, index) => {
            if (item.buying_decision_id !== null) {
              return {
                buying_decision_id: item.buying_decision_id,
                purchase_qty: item.ui_purchase_qty,
                purchase_price: item.ui_purchase_price,
                purchase_remarks: item.ui_purchase_remarks,
              };
            }
          }),
        };

        // console.log("IF ================> ");

        // API.get(PRICING_POST_URL, { headers: this.headers, params: params })
        API.put(PRICING_POST_URL, modifiedDataToSend, {
          headers: headers,
          params: params,
        })
          .then((response) => {
            if (response.data.status) {
              // hub level data retention(DONE: but not required at the moment)
              // #region Data retention local
              refresh = response.data.status;
              let updateHubDetails =
                this.props.actualHubTableData.region.hub_details;

              // if hub is having a buying decision id - for existing hubs-modify data.
              response.data.data.map((respItem, index) => {
                // console.log("Resp OBJ Interation", index, respItem);
                if (respItem.buying_decision_id !== null) {
                  // console.log("saving already existing hub info.");
                  updateHubDetails =
                    this.props.actualHubTableData.region.hub_details.map(
                      (item, index) => {
                        // console.log("Compare data ==============> ", item.buying_decision_id === respItem.buying_decision_id, item.buying_decision_id, respItem.buying_decision_id);
                        if (
                          item.buying_decision_id ===
                          respItem.buying_decision_id
                        ) {
                          // && item.source_type !== "mandi" && item.buying_decision_id === respItem.buying_decision_id &&
                          return {
                            ...item,
                            checked: false,
                            edited: false,
                            buying_decision_id: respItem.buying_decision_id,
                          };
                        } else {
                          return { ...item, checked: false };
                        }
                      }
                    );

                  const forData = this.props.hubDataPostFOR.map((item, idx) => {
                    if (item.temp_bd_id === dataToSend.temp_bd_id) {
                      return {
                        ...item,
                        checked: false,
                        edited: false,
                        buying_decision_id: respItem.buying_decision_id,
                      };
                    } else {
                      return item;
                    }
                  });

                  this.props.setActualHubDataPostFOR(forData);
                }
                // else {
                //   updateHubDetails = this.props.actualHubTableData.region.hub_details.map((item, index) => {
                //     console.log("BD NULLLLLLLLLLLLLLLL Compare data ==============> ", item.temp_bd_id, item.temp_bd_id === respItem.temp_bd_id, respItem.temp_bd_id);
                //     if (item.temp_bd_id == respItem.temp_bd_id) {
                //       return {
                //         ...item,
                //         checked: false,
                //         edited: false,
                //         approval1: null,
                //         approval2: null,
                //         approver1_name: null,
                //         approver2_name: null,
                //         comment1: null,
                //         comment2: null,
                //         version: respItem.version,
                //         buying_decision_id: respItem.buying_decision_id
                //       }
                //     }
                //     else {
                //       return { ...item };
                //     }
                //   });

                //   //#region set hubFORdata in redux

                //   const forData = this.props.hubDataPostFOR.map((item, idx) => {
                //     if (item.temp_bd_id == dataToSend.temp_bd_id) {
                //       return {
                //         ...item,
                //         buying_decision_id: respItem.buying_decision_id,
                //         temp_bd_id: respItem.temp_bd_id,
                //         checked: false,
                //         edited: false,
                //         approval1: null,
                //         approval2: null,
                //         approver1_name: null,
                //         approver2_name: null,
                //         comment1: null,
                //         comment2: null,
                //       };
                //     } else {
                //       return { ...item };
                //     }
                //   });

                //   console.log("FoR Data After Submition ===============> ", forData);
                //   this.props.setActualHubDataPostFOR(forData);

                //   //#endregion
                // }
                // console.log("updateHubDetails Data After Submition ===============> ", updateHubDetails);

                const data = {
                  region: {
                    hub_details: updateHubDetails,
                  },
                };

                this.setState({ hubData: data });
                setActualHubTableData(data);
              });
              //#endregion

              //#region Data updation local for recommendation //TODO: this has been commented out.

              // // if buying decision id is present for the hub. ie- existing hub.modify recomendation data.
              // if (dataToSend.buying_decision_id !== null) {
              //   let updateHubs = this.props.suggestedData.map((item) => {
              //     if (
              //       item.buyingDecisionId === dataToSend.buying_decision_id &&
              //       item.buyerType == dataToSend.source_type
              //     ) {
              //       return {
              //         ...item,
              //         plannedPrice: Math.round(
              //           (dataToSend.revised_buy_max_price +
              //             dataToSend.revised_buy_min_price) /
              //             2
              //         ),
              //         plannedQty: Math.round(dataToSend.revised_qty),
              //       };
              //     }
              //     return item;
              //   });

              //   this.props.setSuggestedData(updateHubs);
              // }
              // // if buying decision id is null, its a new hub and needs to be added to recommendation
              // else {
              //   //#region add redux recommendation data

              //   console.log("Entered new hub list item");
              //   let newSuggestedEntry = {
              //     key: response.data.data.buying_decision_id,
              //     buyingDecisionId: response.data.data.buying_decision_id,
              //     branch: dataToSend.branch_name,
              //     region: dataToSend.region_name,
              //     hub: dataToSend.hub_name,
              //     buyerType: dataToSend.source_type,
              //     buyerName: dataToSend.source_type,
              //     variety: dataToSend.variety_name,
              //     suggestedQty: 0,
              //     price: 0,
              //     plannedQty: dataToSend.revised_qty,
              //     plannedPrice: Math.round(
              //       (dataToSend.revised_buy_min_price +
              //         dataToSend.revised_buy_max_price) /
              //         2
              //     ),
              //   };

              //   this.props.addSuggestedData(newSuggestedEntry);
              //   //#endregion
              // }

              //#endregion
              // this.getActualHubTableData();                 // uncomment this to get the refreshed data but not working as expected...
              message.success(response.data.message);
              this.spinnerOn(false);
              // return true;
              // setSubmittedStatus(false);
            } else {
              this.spinnerOn(false);
              // setSubmittedStatus(false);
              message.error("Cannot submit more than 4 times.");
              // postStatus = false;
            }
          })
          .catch((err) => {
            this.spinnerOn(false);
            // setSubmittedStatus(false);
            message.error("Could not submit. Please check your network.");
            // postStatus = false;
          });
        // return true;
        this.spinnerOn(false);
      } else {
        // console.log("ELSE ================> ");
        // setSubmittedStatus(false);
        this.spinnerOn(false);
        return false;
      }
      // return postStatus;
      this.spinnerOn(false);
    };
    /**
     *
     * @description Post buying decision of FOR
     * @param {string} clickedTableRow
     * @param {string} srp
     * @param {string} source_type
     * @param {string} rowRecord
     * @memberof BuyingDecisionContainer.BuyingDecisioning
     */
    // const postBuyingDecisionHub = (
    //   clickedTableRow,
    //   srp,
    //   sourceType,
    //   claimType,
    //   division,
    //   setSubmittedStatus,
    //   rowRecord
    // ) => {
    //   const {
    //     selectedBranchID,
    //     selectedRegionID,
    //     selectedVarietyID,
    //     setActualHubTableData,
    //   } = this.props;

    //   setSubmittedStatus(true);
    //   // rowRecord = [rowRecord];
    //   const headers = {
    //     "Content-Type": "application/json",
    //     Authorization: "Bearer " + localStorage.getItem("accessToken"),
    //   };
    //   console.log("clickedTableRow --------------->", clickedTableRow, "=====================> ", rowRecord);

    //   const params = {
    //     branch_id: this.props.selectedBranchID,
    //     region_id: [rowRecord.regionID],
    //     variety_id: [rowRecord.varietyID],
    //   };
    //   const PRICING_POST_URL = `/buying_decision_planned`;
    //   // const PRICING_POST_URL = `/branches/${selectedBranchID}/regions/${rowRecord.regionID}/variety/${rowRecord.varietyID}`;
    //   console.log("hub posting url endpoint :", PRICING_POST_URL);
    //   // console.log("DATA HUB BEFORE SENDING : ", this.state.FORData);

    //   let dataToSend = this.props.hubDataPostFOR.find((element) => {
    //     console.log("this.props.hubDataPostFOR --------------->", element, "this.props.hubDataPostFOR =====================> ", rowRecord);

    //     if (
    //       clickedTableRow === element.hub_id &&
    //       element.claim_type === claimType &&
    //       element.division === division &&
    //       element.source_type === sourceType &&
    //       element.region_id === rowRecord.regionID &&
    //       element.variety_id === rowRecord.varietyID
    //     )
    //       return element;
    //   });

    //   console.log("table data", this.props.hubDataPostFOR);
    //   console.log(
    //     "data to send",
    //     clickedTableRow,
    //     sourceType,
    //     claimType,
    //     division,
    //     "dataToSend", dataToSend
    //   );

    //   //#region if dataToSend is having a null buying decision id,
    //   // it is a newly added hub. we need to populate data from the actualHubTableData(newly added hubs will be present at this time)
    //   //in redux and modify the posting structure accordingly.
    //   if (dataToSend.buying_decision_id === null) {
    //     //find the selected hub all info from actualHubTableData.
    //     const selectedHubInformation = this.props.actualHubTableData.region.hub_details.find(
    //       (item) =>
    //         item.hub_id === clickedTableRow &&
    //         item.claim_type === claimType &&
    //         item.division === division &&
    //         item.source_type === sourceType &&
    //         item.region_id === rowRecord.regionID &&
    //         item.variety_id === rowRecord.varietyID
    //     );

    //     console.log("Selected Source Type : ", sourceType);

    //     const selectedHub = this.props.hubDataPostFOR.find(
    //       (item) =>
    //         item.hub_id === clickedTableRow &&
    //         item.claim_type === claimType &&
    //         item.division === division &&
    //         item.source_type === sourceType &&
    //         item.region_id === rowRecord.regionID &&
    //         item.variety_id === rowRecord.varietyID,
    //     );

    //     console.log("Selected hub from hubdatapostFOR : ", selectedHub);

    //     dataToSend = {
    //       buying_decision_id: null,
    //       temp_bd_id: rowRecord.temp_bd_id,
    //       //this data is from hubDataPostFOR in redux
    //       revised_qty: selectedHub.revised_qty,
    //       revised_buy_min_price: selectedHub.revised_buy_min_price,
    //       revised_buy_max_price: selectedHub.revised_buy_max_price,
    //       remark_id: selectedHub.remark_id,

    //       // this data is from actualHubTableData in redux
    //       hub_id: selectedHubInformation.hub_id,
    //       hub_name: selectedHubInformation.hub_name,
    //       source_type: selectedHubInformation.source_type,
    //       source_name: selectedHubInformation.source_type,
    //       today_arrival_qty: selectedHubInformation.today_arrival_qty,
    //       today_price: selectedHubInformation.today_price,
    //       yesterday_arrival_qty: selectedHubInformation.yesterday_arrival_qty,
    //       yesterday_price: selectedHubInformation.yesterday_price,
    //       yesterday_purchase_qty: selectedHubInformation.yesterday_purchase_qty,

    //       // this data is from various other buying decision redux state variables.
    //       //OK , here I am pretty sure you dont need to send id and name for the same thing, but hey, whatever makes it easier for them.
    //       // variety_id: this.props.selectedVarietyID,
    //       variety_name: this.props.decisionVarieties.find(
    //         (item) => item.variety_id === this.props.selectedVarietyID
    //       ) && this.props.decisionVarieties.find(
    //         (item) => item.variety_id === this.props.selectedVarietyID
    //       ).variety_name,
    //       region_id: rowRecord.regionID,
    //       variety_id: rowRecord.varietyID,
    //       region_name: rowRecord.region_name,
    //       // region_name: this.props.decisionRegions.find(
    //       // (item) => item.region_id == this.props.selectedRegionID
    //       // ) && this.props.decisionRegions.find(
    //       // (item) => item.region_id == this.props.selectedRegionID
    //       // ).region_name,
    //       branch_id: this.props.selectedBranchID,
    //       branch_name: this.props.decisionBranches.find(
    //         (item) => item.territory_id == this.props.selectedBranchID
    //       ) && this.props.decisionBranches.find(
    //         (item) => item.territory_id == this.props.selectedBranchID
    //       ).territory_name,

    //       //these are defaulted to 0 since it is a new hub entry.
    //       suggested_price: 0,
    //       suggested_qty: 0,

    //       //////////////////
    //     };
    //   }

    //   //here add any further feature requirement keys.
    //   dataToSend = {
    //     ...dataToSend,
    //     download: 0,
    //     claim_type: claimType,
    //     division: division,
    //     price_uom: "RS/MT",
    //   };

    //   //#endregion
    //   console.log("Data to send : ", dataToSend);

    //   // this is the actual data being sent. add keys here, referencing dataToSend.
    //   let modifiedDataToSend = {
    //     "bd_plans": [{
    //       bd_id: dataToSend.buying_decision_id,
    //       temp_bd_id: dataToSend.temp_bd_id,
    //       branch_id: dataToSend.branch_id,
    //       region_id: dataToSend.region_id,
    //       hub_id: dataToSend.hub_id,
    //       variety_id: dataToSend.variety_id,
    //       division: getDivisionID(dataToSend.division),
    //       claim_type: getClaimID(dataToSend.claim_type),
    //       suggested_price: dataToSend.suggested_price,
    //       suggested_qty: dataToSend.suggested_qty,
    //       revised_qty: dataToSend.revised_qty,
    //       revised_min_price: dataToSend.revised_buy_min_price,
    //       revised_max_price: dataToSend.revised_buy_max_price,
    //       prev_day_purchase_qty: dataToSend.yesterday_purchase_qty,
    //       prev_day_purchase_price: dataToSend.yesterday_price,
    //       tdy_arrival_qty: dataToSend.today_arrival_qty,
    //       tdy_modal_price: dataToSend.today_price,
    //       remark_id: dataToSend.remark_id,
    //     }]
    //   }

    //   const validatePost = () => {
    //     if (
    //       dataToSend === null ||
    //       dataToSend === undefined ||
    //       dataToSend === ""
    //     ) {
    //       message.error("No data is present !");
    //       return false;
    //     }

    //     if (dataToSend.revised_qty === null || dataToSend.revised_qty === undefined || dataToSend.revised_qty === "") {
    //       message.error(`Please enter the quantity to be purchased for ${dataToSend.hub_name}, ${dataToSend.variety_name}, ${dataToSend.claim_type} !`);
    //       return false;
    //     }

    //     if (dataToSend.revised_buy_min_price == 0) {
    //       message.error(`Minimum price cannot be zero for ${dataToSend.hub_name}, ${dataToSend.variety_name}, ${dataToSend.claim_type} !`);
    //       return false;
    //     }

    //     if (!dataToSend.revised_buy_min_price) {
    //       message.error(`Please enter the minimum price for ${dataToSend.hub_name}, ${dataToSend.variety_name}, ${dataToSend.claim_type} !`);
    //       return false;
    //     }

    //     if (dataToSend.revised_buy_max_price == 0) {
    //       message.error(`Maximum price cannot be zero for ${dataToSend.hub_name}, ${dataToSend.variety_name}, ${dataToSend.claim_type} !`);
    //       return false;
    //     }

    //     if (!dataToSend.revised_buy_max_price) {
    //       message.error(`Please enter the maximum price for ${dataToSend.hub_name}, ${dataToSend.variety_name}, ${dataToSend.claim_type} !`);
    //       return false;
    //     }

    //     if (
    //       dataToSend.revised_buy_min_price >= dataToSend.revised_buy_max_price
    //     ) {
    //       message.error(
    //         `The maximum price must be greater than the minimum price for ${dataToSend.hub_name}, ${dataToSend.variety_name}, ${dataToSend.claim_type} !`
    //       );
    //       return false;
    //     }

    //     if (
    //       dataToSend.revised_buy_min_price < getVarietyMinPrice() ||
    //       dataToSend.revised_buy_max_price > getVarietyMaxPrice()
    //     ) {
    //       // if (!dataToSend.remark_id) {
    //       //   message.error(
    //       //     "Please enter remark since landing cost must be between " +
    //       //       getVarietyMinPrice() +
    //       //       " ₹/MT and " +
    //       //       getVarietyMaxPrice() +
    //       //       " ₹/MT"
    //       //   );
    //       //return false
    //       // }
    //       message.error(
    //         `The landing cost must be between ${getVarietyMinPrice()} and ${getVarietyMaxPrice()} for ${dataToSend.hub_name}, ${dataToSend.variety_name}, ${dataToSend.claim_type} !`
    //       );
    //       return false;
    //     }

    //     // if (
    //     //   dataToSend.revised_buy_min_price < srp - srp * percentage - 1 ||
    //     //   dataToSend.revised_buy_max_price > srp + srp * percentage + 1
    //     // )
    //     // if (
    //     //   dataToSend.revised_buy_max_price - dataToSend.revised_buy_min_price >
    //     //   Math.round(srp * percentage) + 1
    //     // ) {
    //     //   if (!dataToSend.remark_id) {
    //     //     message.error(
    //     //       srp === 0
    //     //         ? "Please add remark since you are not suggested to buy from this trader"
    //     //         : "Please add remark since minimum and maximum cannot differ more than " +
    //     //             (Math.round(srp * percentage) + 1) +
    //     //             "₹/MT"
    //     //     );
    //     //     return false;
    //     //   }
    //     // }

    //     if (!dataToSend.remark_id && srp === 0) {
    //       message.error(
    //         `Please add remark since you are not suggested to buy from this trader for ${dataToSend.hub_name}, ${dataToSend.variety_name}, ${dataToSend.claim_type} !`
    //       );
    //       return false;
    //     }

    //     console.log(
    //       "limit :",
    //       2 * 100 * ((dataToSend.revised_buy_max_price - dataToSend.revised_buy_min_price) / (dataToSend.revised_buy_max_price + dataToSend.revised_buy_min_price))
    //     );

    //     if (2 * 100 * ((dataToSend.revised_buy_max_price - dataToSend.revised_buy_min_price) / (dataToSend.revised_buy_max_price + dataToSend.revised_buy_min_price)) > percentage) {
    //       message.error(
    //         `Minimum and maximum cannot differ more than ${percentage}% for ${dataToSend.hub_name}, ${dataToSend.variety_name}, ${dataToSend.claim_type} !`
    //       );
    //       return false;
    //     }
    //     return true;
    //   };

    //   if (validatePost()) {
    //     console.log("Data to send : ------>", modifiedDataToSend);
    //     // API.get(PRICING_POST_URL, { headers: this.headers, params: params })
    //     API.post(PRICING_POST_URL, modifiedDataToSend, {
    //       headers: headers, params: params
    //     })
    //       .then((response) => {
    //         console.log("HUB POST RESPONSE :", response.data);
    //         if (response.data.status) {
    //           // hub level data retention(DONE: but not required at the moment)
    //           // #region Data retention local

    //           let updateHubDetails = this.props.actualHubTableData.region.hub_details;

    //           // if hub is having a buying decision id - for existing hubs-modify data.
    //           if (dataToSend.buying_decision_id !== null) {
    //             console.log("saving already existing hub info.");
    //             updateHubDetails = this.props.actualHubTableData.region.hub_details.map(
    //               (item, index) => {
    //                 if (
    //                   item.buying_decision_id ===
    //                   dataToSend.buying_decision_id &&
    //                   item.claim_type === claimType &&
    //                   item.division === division &&
    //                   item.region_id === dataToSend.region_id &&
    //                   item.variety_id === dataToSend.variety_id &&
    //                   item.source_type !== "mandi"
    //                 ) {
    //                   item.temp_bd_id = dataToSend.temp_bd_id;
    //                   item.revised_buy_min_price = dataToSend.revised_buy_min_price;
    //                   item.revised_buy_max_price = dataToSend.revised_buy_max_price;
    //                   item.revised_qty = dataToSend.revised_qty;
    //                   item.checked = false;
    //                   item.edited = false;
    //                   item.approval1 = null;
    //                   item.approval2 = null;
    //                   item.approver1_name = null;
    //                   item.approver2_name = null;
    //                   item.comment1 = null;
    //                   item.comment2 = null;

    //                   // extra features
    //                   item.claim_type = claimType;
    //                   item.division = division;
    //                   item.version = response.data.data[0].version;
    //                   item.region_id = dataToSend.region_id;
    //                   item.variety_id = dataToSend.variety_id;
    //                 }
    //                 return item;
    //               }
    //             );
    //           } else {
    //             console.log("Saving new hubs buying decision id.");
    //             updateHubDetails = this.props.actualHubTableData.region.hub_details.map(
    //               (item, index) => {
    //                 if (
    //                   item.hub_id === dataToSend.hub_id &&
    //                   item.claim_type === claimType &&
    //                   item.division === division &&
    //                   item.region_id === dataToSend.region_id &&
    //                   item.variety_id === dataToSend.variety_id &&
    //                   item.source_type === dataToSend.source_type
    //                 ) {
    //                   item.temp_bd_id = dataToSend.temp_bd_id;
    //                   item.buying_decision_id = response.data.data[0].buying_decision_id;
    //                   item.revised_buy_min_price = dataToSend.revised_buy_min_price;
    //                   item.revised_buy_max_price = dataToSend.revised_buy_max_price;
    //                   item.revised_qty = dataToSend.revised_qty;
    //                   item.edited = false;
    //                   item.approval1 = null;
    //                   item.approval2 = null;
    //                   item.approver1_name = null;
    //                   item.approver2_name = null;
    //                   item.comment1 = null;
    //                   item.comment2 = null;

    //                   // extra features
    //                   item.claim_type = claimType;
    //                   item.division = division;
    //                   item.version = response.data.data[0].version;
    //                   item.region_id = dataToSend.region_id;
    //                   item.variety_id = dataToSend.variety_id;
    //                 }
    //                 return { ...item, checked: false }
    //               }
    //             );

    //             //#region set hubFORdata in redux

    //             const forData = this.props.hubDataPostFOR.map((item, idx) => {
    //               if (
    //                 item.hub_id == dataToSend.hub_id &&
    //                 item.claim_type === claimType &&
    //                 item.division === division &&
    //                 item.source_type === dataToSend.source_type &&
    //                 item.region_id === dataToSend.region_id &&
    //                 item.variety_id === dataToSend.variety_id
    //               ) {
    //                 return {
    //                   hub_id: dataToSend.hub_id,
    //                   buying_decision_id: response.data.data[0].buying_decision_id,
    //                   temp_bd_id: dataToSend.temp_bd_id,
    //                   revised_qty: dataToSend.revised_qty,
    //                   revised_buy_min_price: dataToSend.revised_buy_min_price,
    //                   revised_buy_max_price: dataToSend.revised_buy_max_price,
    //                   remark_id: dataToSend.remark_id,
    //                   source_type: dataToSend.source_type,
    //                   region_id: dataToSend.region_id,
    //                   variety_id: dataToSend.variety_id,
    //                   edited: false,
    //                   approval1: null,
    //                   approval2: null,
    //                   approver1_name: null,
    //                   approver2_name: null,
    //                   comment1: null,
    //                   comment2: null,
    //                   claim_type: dataToSend.claim_type,
    //                   division: dataToSend.division,
    //                   hub_name: dataToSend.hub_name,
    //                   variety_name: dataToSend.variety_name
    //                 };
    //               } else {
    //                 return { ...item };
    //               }
    //             });

    //             this.props.setActualHubDataPostFOR(forData);

    //             //#endregion
    //           }

    //           const data = {
    //             region: {
    //               hub_details: updateHubDetails,
    //             },
    //           };

    //           setActualHubTableData(data);

    //           //#endregion

    //           //#region Data updation local for recommendation //TODO: this has been commented out.

    //           // // if buying decision id is present for the hub. ie- existing hub.modify recomendation data.
    //           // if (dataToSend.buying_decision_id !== null) {
    //           //   let updateHubs = this.props.suggestedData.map((item) => {
    //           //     if (
    //           //       item.buyingDecisionId === dataToSend.buying_decision_id &&
    //           //       item.buyerType == dataToSend.source_type
    //           //     ) {
    //           //       return {
    //           //         ...item,
    //           //         plannedPrice: Math.round(
    //           //           (dataToSend.revised_buy_max_price +
    //           //             dataToSend.revised_buy_min_price) /
    //           //             2
    //           //         ),
    //           //         plannedQty: Math.round(dataToSend.revised_qty),
    //           //       };
    //           //     }
    //           //     return item;
    //           //   });

    //           //   this.props.setSuggestedData(updateHubs);
    //           // }
    //           // // if buying decision id is null, its a new hub and needs to be added to recommendation
    //           // else {
    //           //   //#region add redux recommendation data

    //           //   console.log("Entered new hub list item");
    //           //   let newSuggestedEntry = {
    //           //     key: response.data.data.buying_decision_id,
    //           //     buyingDecisionId: response.data.data.buying_decision_id,
    //           //     branch: dataToSend.branch_name,
    //           //     region: dataToSend.region_name,
    //           //     hub: dataToSend.hub_name,
    //           //     buyerType: dataToSend.source_type,
    //           //     buyerName: dataToSend.source_type,
    //           //     variety: dataToSend.variety_name,
    //           //     suggestedQty: 0,
    //           //     price: 0,
    //           //     plannedQty: dataToSend.revised_qty,
    //           //     plannedPrice: Math.round(
    //           //       (dataToSend.revised_buy_min_price +
    //           //         dataToSend.revised_buy_max_price) /
    //           //         2
    //           //     ),
    //           //   };

    //           //   this.props.addSuggestedData(newSuggestedEntry);
    //           //   //#endregion
    //           // }

    //           //#endregion

    //           message.success(response.data.message);

    //           // return true;
    //           setSubmittedStatus(false);
    //         } else {
    //           this.spinnerOn(false);
    //           setSubmittedStatus(false);
    //           message.error("Cannot submit more than 4 times.");
    //           // postStatus = false;
    //         }
    //       })
    //       .catch((err) => {
    //         this.spinnerOn(false);
    //         setSubmittedStatus(false);
    //         message.error("Could not submit. Please check your network.");
    //         console.log("ERROR IN POSTING HUB DATA ; ERROR :", err.response);
    //         // postStatus = false;
    //       });
    //     return true;
    //   } else {
    //     setSubmittedStatus(false);
    //     return false;
    //   }
    //   // return postStatus;
    // };

    return (
      <Spin spinning={this.state.isSpinnerOn} tip="Loading...">
        <Spin
          spinning={this.state.isSpinnerOnProgress}
          tip="Please wait while the file is being Downloaded..."
        >
          <div
            className="buying-decisioning-container"
            style={{
              height: window.innerHeight - 170,
              overflowY: "auto",
              overflowX: "hidden",
            }} // 165
          >
            <WithoutChooseHub
              spinnerOn={this.spinnerOn}
              setActualFORData={this.setActualFORData}
              //spinnerOn={this.spinnerOn}
              // FORdata={this.state.FORData}
              FORdata={this.props.hubDataPostFOR}
              setSelectedData={this.setSelectedData}
              key="actual-purchase"
            />

            <ActualPurchaseInformationTable
              setQuantityHubRowFORPost={setQuantityHubRowFORPost}
              // setHubData={setHubData}
              // hubInfo={this.state.hubData}
              setTLCHubRowFORPost={setTLCHubRowFORPost}
              FORData={this.props.hubDataPostFOR}
              // FORData={this.state.FORData}
              handleSubmitClick={handleSubmitClick}
              // postBuyingDecisionHub={postBuyingDecisionHub}
              setRemarkHubRowFORPost={setRemarkHubRowFORPost}
              spinnerOn={this.spinnerOn}
            />

            <div className="download-button-container">
              {this.state.ProgressClicked && (
                <Progress
                  type="circle"
                  percent={this.state.downloaddata}
                  width={37}
                  strokeColor={{ "0%": "#108ee9", "100%": "#87d068" }}
                  style={{
                    paddingRight: "5px",
                  }}
                  trailColor="#003285"
                />
              )}
              {
                <>
                  <Button
                    onClick={() => {
                      this.setState({ isSpinnerOnProgress: true });
                      this.setState({ downloaddata: 0 });
                      this.setState({ ProgressClicked: true });
                      const params = {
                        branch_id: this.props.selectedBranchID,
                        region_id: this.state.selectedRegionsData.length
                          ? this.state.selectedRegionsData
                          : [this.state.selectedRegionsData],
                        variety_id: this.state.selectedVarietiesData.length
                          ? this.state.selectedVarietiesData
                          : [this.state.selectedVarietiesData],
                        start_date:
                          this.state.startDate !== ""
                            ? this.state.startDate
                            : moment().format("YYYY-MM-DD"),
                        end_date:
                          this.state.endDate !== ""
                            ? this.state.endDate
                            : moment().format("YYYY-MM-DD"),
                        download: true,
                      };
                      // API.get(
                      //   `/buying_decision_actual`,
                      //   { params: params }
                      // )
                      //   .then((fileresponse) => {
                      //     // console.log(
                      //     //   "FILE RESPONSE :",
                      //     //   `./${fileresponse.data.data}`
                      //     // );
                      //     if (fileresponse.data.status) {
                      //#region file download
                      Axios({
                        url: `${baseURL}/buying_decision_actual`,
                        method: "GET",
                        params: params,
                        responseType: "blob",
                        onDownloadProgress: (progressEvent) => {
                          let progress = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                          );
                          this.setState({ downloaddata: progress });
                        },
                      })
                        .then((response) => {
                          // console.log(response.data.status);
                          if (response.status !== 204) {
                            FileDownLoad(
                              response.data,
                              `Actual_Buying_Decision_Report_${new Date().toLocaleDateString()}.xlsx`
                            );
                            this.setState({ isSpinnerOnProgress: false });
                            setTimeout(
                              function () {
                                this.setState({ ProgressClicked: false });
                              }.bind(this),
                              5000
                            );
                          } else {
                            message.warning("File has no data.");
                            this.setState({
                              isSpinnerOnProgress: false,
                              ProgressClicked: false,
                            });
                          }
                        })
                        .catch((err) => {
                          message.error("File cannot be downloaded");
                          this.setState({
                            isSpinnerOnProgress: false,
                            ProgressClicked: false,
                          });
                          // console.log(
                          //   "Cannot download file ERROR : ",
                          //   err.response
                          // );
                        });
                      //#endregion
                      //   } else {
                      //     // console.log(
                      //     //   "File has no data, status returned is false."
                      //     // );
                      //     message.warning("File has no data");
                      //     this.setState({ isSpinnerOnProgress: false, ProgressClicked:false });
                      //   }
                      // })
                      // .catch((err) => {
                      //   message.error("Error downloading file");
                      //   this.setState({ isSpinnerOnProgress: false, ProgressClicked:false });
                      //   // console.log("Error getting file, ERROR :", err.response);
                      // });
                    }}
                    type="primary"
                    shape="round"
                    icon="download"
                    size="large"
                    style={{ display: "flex" }}
                  >
                    <div>
                      <div style={{}}>Download</div>
                      <div
                        style={{
                          fontSize: "11px",
                          lineHeight: "7px",
                          color: "#b4c8dc",
                        }}
                      >
                        .xlsx report
                      </div>
                    </div>
                  </Button>
                  {/*    <Button
                  onClick={() => {
                    const fileHeaders = {
                      Authorization:
                        "Bearer " + localStorage.getItem("accessToken"),
                    };
                    API.get(
                      `/buying_decision_download?branch_id=${this.props.selectedBranchID}`,
                      { headers: fileHeaders }
                    )
                      .then((fileresponse) => {
                        console.log(
                          "FILE RESPONSE :",
                          `./${fileresponse.data.data}`
                        );
                        if (fileresponse.data.status) {
                          //#region file download
                          Axios({
                            url: `./${fileresponse.data.data}`,
                            method: "GET",
                           responseType: "blob",
                          })
                            .then((response) => {
                              console.log(response.data.status);

                              FileDownLoad(
                                response.data,
                                `${fileresponse.data.data}`
                              );
                            })
                            .catch((err) => {
                              message.error("File cannot be downloaded");
                              console.log(
                                "Cannot download file ERROR : ",
                                err.response
                              );
                            });
                          //#endregion
                        } else {
                          console.log(
                            "File has no data, status returned is false."
                          );
                          message.warning("File has no data");
                        }
                      })
                      .catch((err) => {
                        message.error("Error downloading file");
                        console.log("Error getting file, ERROR :", err.response);
                      });
                  }}
                  type="primary"
                  shape="round"
                  icon="download"
                  size="large"
                // style={{ display: "block" }}
                >
                  <div style={{ float: "right" }}>
                    <div style={{ marginTop: "-7px" }}>Send to SAP</div>
                    <div
                      style={{
                        fontSize: "11px",
                        lineHeight: "7px",
                        color: "#b4c8dc",
                      }}
                    >
                      .xlsx report
                  </div>
                  </div>
                </Button> */}
                </>
              }
            </div>
          </div>
        </Spin>
      </Spin>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setActualHubTableData: (data) => dispatch(setActualHubTableData(data)),
    setActualHubDataPostFOR: (data) => dispatch(setActualHubDataPostFOR(data)),
    setSuggestedData: (data) => dispatch(setSuggestedData(data)),
    addSuggestedData: (data) => dispatch(addSuggestedData(data)),
  };
};

const mapStateToProps = createStructuredSelector({
  availableHubs: selectAvailableHubs,
  allVarietyInfo: selectAllVarietyInfo,
  hubDataPostFOR: selectHubDataPostFOR,
  actualHubTableData: selectActualHubTableData,

  decisionBranches: selectDecisionBranches,
  decisionRegions: selectDecisionRegions,
  decisionVarieties: selectDecisionVarieties,

  selectedBranchID: selectSelectedBranchID,
  selectedRegionID: selectSelectedRegionID,
  selectedVarietyID: selectSelectedVarietyID,

  suggestedData: selectSuggestedData,
});

export default connect(mapStateToProps, mapDispatchToProps)(ActualPurchasing);
