import React from "react";
import "./user-groups.styles.css";
import { Component } from "react";

import { Table, Modal, Switch, Button, Input, Select, message } from "antd";
import editUserImage from "../../assets/edit-user.svg";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { setAllUsers } from "../../redux/user/user.actions";
import {
  selectAllUsers,
  selectAllBranches,
} from "../../redux/user/user.selectors";

import { setUserGroupData } from "../../redux/alerts/alerts.actions";
import { selectUserGroupData } from "../../redux/alerts/alerts.selectors";

import UserGroupForm from "../../components/user-group-form/user-group-form.component";

import API from "../../api";

const { Search } = Input;
const { Option } = Select;

/**
 * @description Parent component of user group tab
 * @component
 */
class UserGroups extends Component {
  state = {
    isModalVisible: false,
    isAdd: false,
    currentRecord: null,
  };

  /**
   * @function
   * @description Call API to get the user group data
   * @memberof UserGroups
   */
  getUserGroupData = () => {
    //#region api to get usergroups

    API.get("/user/groups")
      .then((groupResponse) => {
        if (groupResponse.status) {

          const modifiedUserGroupData = groupResponse.data.data.map((group) => {
            return {
              ...group,
              members: group.members.map((member) => {
                return this.props.allUsers.find(
                  (user) => user.user_id === member.user_id
                );
              }),
            };
          });


          this.props.setUserGroupData(modifiedUserGroupData);
          // this.setState({ userGroupData: modifiedUserGroupData });
        } else {
          message.warning(
            "Could not retrieve user groups information. Please try again"
          );
        }
      })
      .catch((err) => {
        message.error(
          "Could not retrieve user group information. Please check your network connectivity."
        );
      });

    //#endregion
  };

  /**
   * @function
   * @description Wait untill all the user data is loaded. This prevents null exceptions from occuring by
   * awaiting the api to get the user group data.
   * @memberof UserGroups
   */
  waitForUserGroupData() {
    // console.log("LoG: ", this.props.branches);
    if (this.props.allUsers.length !== 0) {
      //   console.log("ITERATION: ", 1);
      this.getUserGroupData();
    } else {
      setTimeout(() => {
        this.waitForUserGroupData();
      }, 100);
    }
  }

  componentDidMount() {
    this.waitForUserGroupData();
  }
  render() {
    const userTableProps = {
      pagination: false,
      size: "default",
      bordered: true,
      scroll: { x: 200, y: window.innerHeight - 192 },
    };

    // set the user table data
    /**
     *@function
     *@description Load the user group data into the table once it has been fetched.
     *@memberof UserGroups
     */
    const setUserTableData = () => {


      // if all users are not loaded before component is being forcerendered, it will hit a null exception.
      //  hence the check.
      if (
        this.props.userGroupData.length !== 0 &&
        this.props.allUsers.length !== 0
      ) {
        const userTableData = this.props.userGroupData.map((group) => {
          return {
            key: group.group_id,
            userData: group,
            name: group.group_name,

            members: group.members.map((member) => member.name),

            actions: {
              canEdit: true,
            },
          };
        });

        return userTableData;
      } else return [];
    };

    // set the edit/add user modal visibility
    /**
     * @function
     * @description set the add/edit popup modal visibility
     * @param {boolean} status required visibility state of the modal
     * @param {boolean} isAdd true : add user group, false : edit user group
     * @param {Object} record current selected user group. null if creating a new group.
     * @memberof UserGroups
     */
    const setModalVisibility = (status, isAdd, record) => {
      this.setState((prevState) => ({
        ...prevState,
        currentRecord: record,
        isAdd: isAdd,
        isModalVisible: status,
      }));
    };

    return (
      <div
        className="user-group-container"
        style={{ height: window.innerHeight - 85, overflow: "scroll" }}
      >
        <Modal
          width={300}
          className="user-management-modal"
          title={this.state.isAdd ? "Add Group" : "Edit Group"}
          visible={this.state.isModalVisible}
          destroyOnClose={true}
          onOk={() => {
            setModalVisibility(false, false, null);
          }}
          onCancel={() => setModalVisibility(false, false, null)}
        >
          <UserGroupForm
            setUserGroupData={this.props.setUserGroupData}
            userGroupData={this.props.userGroupData}
            allUsers={this.props.allUsers}
            setModalVisibility={setModalVisibility}
            currentRecord={this.state.currentRecord}
            currentMembers={
              this.state.currentRecord
                ? this.state.currentRecord.members.map((item) => item.name)
                : null
            }
            isAdd={this.state.isAdd}
          />
        </Modal>
        <div className="top-container">
          <Button
            onClick={() => setModalVisibility(true, true, null)}
            className="add-user-button"
          >
            + Add Group
          </Button>
        </div>

        {this.props.allUsers.length > 0 && (
          //  this.props.userGroupData.length > 0 &&
          <>
            <div className="table-container">
              <Table
                {...userTableProps}
                columns={[
                  {
                    key: "name",
                    title: "Group Name",
                    dataIndex: "name",
                    width: 180,
                  },
                  {
                    key: "members",
                    title: "Group Members",
                    dataIndex: "members",
                    render: (members, record) => members.toString(),
                  },

                  {
                    key: "actions",
                    title: "Actions",
                    dataIndex: "actions",
                    width: 100,
                    render: (actions, record) => (
                      <div className="user-action-container">
                        <Button
                          onClick={() =>
                            setModalVisibility(true, false, record.userData)
                          }
                          className="edit-button"
                        >
                          <img
                            style={{
                              width: "15px",
                              height: "15px",
                              margin: "4px 5px 0 -6px",
                            }}
                            alt="edit"
                            src={editUserImage}
                          />
                          <h5 style={{ margin: "1px 0 0 0" }}>Edit</h5>
                        </Button>
                      </div>
                    ),
                  },
                ]}
                dataSource={setUserTableData()}
              />
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  allUsers: selectAllUsers,
  userGroupData: selectUserGroupData,
  allBranches: selectAllBranches,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setAllUsers: (data) => dispatch(setAllUsers(data)),
    setUserGroupData: (data) => dispatch(setUserGroupData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserGroups);
