import React from "react";
import "./custom-select-arrival.styles.css";

import { Select, DatePicker } from "antd";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import {
  setBranchID,
  setRegionID,
  setHubID,
  setMandiID
} from "../../redux/arrival-entry/arrival-entry.actions";

import {
  selectMandis,
  selectBranches,
  selectHubs,
  selectRegions,
  selectBranchID,
  selectRegionID,
  selectHubID,
  selectMandiID
} from "../../redux/arrival-entry/arrival-entry.selectors";

const { Option } = Select;
/**
 *
 * @component
 * @description Dropdown component used in Arrival entry tab in the territory dropdowns.
 * @property {string[]} list the dropdown items
 * @property {number} territoryTypeID territoty id corresponding to the type of dropdown
 * @property {function} handleSelectChange  callback on selecting an item 
 * @property {function} [onSelect] additional callback on selecting an item 
 */
class CustomSelectArrival extends React.Component {
  render() {
    const {
      list,
      onSelect,
      territoryTypeID,
      handleSelectChange,
      branches,
      hubs,
      mandis,

      branchID,
      hubID,
      mandiID,


    } = this.props;

    let currentSelectedTerritoryName;
    let currentIndex;
    if (territoryTypeID === 1) {
      const currentBranch = branches.filter(
        item => item.branch_id === branchID
      );
      if (currentBranch.length !== 0) {
        currentSelectedTerritoryName = currentBranch[0].branch_name;
        currentIndex = list.findIndex(
          item => item === currentSelectedTerritoryName
        );
      } else {
        currentIndex = 0;
      }
    }

    /**
     * @description Set the default value as the selected items index.if nothing is selected,
     * the first item in the list will be the default.
     * @returns the index of the item in dropdown to be displayed.
     * @memberof CustomSelectArrival
     */
    const setDefaultValueIndex = () => {
      let currentSelectedTerritoryName;
      let currentIndex;

      switch (territoryTypeID) {
        case 1: // its a branch
          const currentBranch = branches.filter(
            item => item.branch_id === branchID
          );
          if (currentBranch.length !== 0) {
            currentSelectedTerritoryName = currentBranch[0].branch_name;
            currentIndex = list.findIndex(
              item => item === currentSelectedTerritoryName
            );
          } else {
            currentIndex = 0;
          }
          break;
        // case 2: // its a region // THIS IS KEPT INCASE THE CLIENT REQUIRES THE REGION TO BE INTEGRATED AGAIN.
        //   const currentRegion = regions.filter(
        //     item => item.territory_id === regionID
        //   );
        //   if (currentRegion.length !== 0) {
        //     currentSelectedTerritoryName = currentRegion[0].territory_name;
        //     currentIndex = list.findIndex(
        //       item => item === currentSelectedTerritoryName
        //     );
        //   } else {
        //     currentIndex = 0;
        //   }
        //   break;
        case 3: // its a hub
          const currentHub = hubs.filter(item => item.hub_id === hubID);
          if (currentHub.length !== 0) {
            currentSelectedTerritoryName = currentHub[0].hub_name;
            currentIndex = list.findIndex(
              item => item === currentSelectedTerritoryName
            );
          } else {
            currentIndex = 0;
          }
          break;
        case 4: // its a mandi
          const currentMandi = mandis.filter(item => item.mandi_id === mandiID);
          if (currentMandi.length !== 0) {
            currentSelectedTerritoryName = currentMandi[0].mandi_name;
            currentIndex = list.findIndex(
              item => item === currentSelectedTerritoryName
            );
          } else {
            currentIndex = 0;
          }
          break;

        default:
          currentIndex = 0;
          break;
      }

      return currentIndex;
    };

    /**
     *
     * @description function call on selecting an item
     * @param {string} value name of the selected item
     * @memberof CustomSelectArrival
     */
    const handleSelect = value => {
      console.log(value);
      handleSelectChange(value, territoryTypeID);
      onSelect ? onSelect(value) : console.log("");
    };



    return (
      <Select
        showSearch
        value={list[setDefaultValueIndex()]} //this was defaultvalue.
        onSelect={value => handleSelect(value)}
      >
        {list.map((item, idx) => (
          <Option value={item} key={idx}>
            {item}
          </Option>
        ))}
      </Select>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  branchID: selectBranchID,
  regionID: selectRegionID,
  hubID: selectHubID,
  mandiID: selectMandiID,
  mandis: selectMandis,
  branches: selectBranches,
  hubs: selectHubs,
  regions: selectRegions
});

const mapDispatchToProps = dispatch => {
  return {
    setBranchID: id => dispatch(setBranchID(id)),
    setRegionID: id => dispatch(setRegionID(id)),
    setHubID: id => dispatch(setHubID(id)),
    setMandiID: id => dispatch(setMandiID(id))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomSelectArrival);
