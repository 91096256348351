import React from "react";
import "./qc-rules-monthly-tabs.styles.css";

import { ReactComponent as IconTrend } from "../../assets/Report-Trend.svg";

import { Tabs } from "antd";
import QCMonthlyVarieties from "../../components/qc-monthly-varieties/qc-monthly-varieties.component";
import QCMonthlyModelRuns from "../../components/qc-monthly-model-runs/qc-monthly-model-runs.component";

const { TabPane } = Tabs;

const QCRulesMonthlyTabs = (props) => {
  const {} = props;

  return (
    <div className="report-tabs">
      <Tabs
        // onChange={() => this.setState({ tabKey: Date.now() + "" })}
        // onChange={value => console.log(value)}
        animated={false}
        className={`main-content 
          `}
        defaultActiveKey="2"
      >
        <TabPane
          tab={
            <div className="tab-title-container">
              <div>
                <IconTrend className="tab-title-image" />
              </div>
              Varieties
            </div>
          }
          key="1"
        >
          <QCMonthlyVarieties />
        </TabPane>
        <TabPane
          tab={
            <div className="tab-title-container">
              <div>
                <IconTrend className="tab-title-image" />
              </div>
              Model Runs
            </div>
          }
          key="2"
        >
          <QCMonthlyModelRuns />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default QCRulesMonthlyTabs;
