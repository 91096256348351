import React from "react";
import "./custom-select-weekly.styles.css";

import { Select } from "antd";

const { Option } = Select;
/**
 * @component
 * @description Dropdown component used in weekly allocation tab.
 * @property {object[]} list list of objects for populating the dropdown.
 * @property {string} placeholder placeholder to be displayed if list is empty.
 * @property {number} value id for understanding whether dropdwon is for state or for variety.
 * @property {function} onSelect callback on selecting from dropdown.
 */
class CustomSelectWeekly extends React.Component {
  state = {
    currentFilterValue: null,
  };
  render() {
    const {
      list,
      placeholder,
      value,
      onSelect,
      isMultiple,
      values,
    } = this.props;

    /**
     * @description handling the selction event on the dropdown
     * @param {number} value id for understanding whether dropdwon is for state or for variety.
     * @memberof CustomSelectWeekly
     */
    const handleSelect = (value) => {
      if (!isMultiple) {
        onSelect(value);
      }
    };

    const handleChange = (values) => {
      if (isMultiple) {
        onSelect(values);
      }
    };

    return (
      <Select
        placeholder={placeholder}
        value={list.length !== 0 ? (isMultiple ? values : value) : "Loading..."}
        onSelect={(value) => handleSelect(value)}
        onChange={(values) => handleChange(values)}
        mode={isMultiple ? "multiple" : "default"}
      >
        {list.map((item, idx) => (
          <Option value={item.id} key={item.id}>
            {item.name}
          </Option>
        ))}
      </Select>
    );
  }
}

export default CustomSelectWeekly;
