import React, { Component } from "react";
import "./dashboard-map.styles.css";
import "leaflet/dist/leaflet.css";
import { Map, Marker, Popup, TileLayer, SVGOverlay } from "react-leaflet";

import DashboardMapMarker from "../dashboard-map-marker/dashboard-map-marker.component";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { GoogleMap, LoadScript } from "@react-google-maps/api";

/**
 * @component
 * @description The map item component which displays the map in the arrival entry page.
 * @property {latlong} centerPosition the center position of the map.
 * @property {object[]} markers the array of mandi records in the selected hub.
 * @property {string} currentArrivalScreen current visible screen in arival entry page (Mandi/FOR/Farmer).
 * @property {function} handleSelectChange callback on clicking a map marker corresponding to a mandi.
 */
class DashboardMap extends Component {
  render() {
    const { centerPosition, markers, isMandi, zoomValue } = this.props;
    const southWest = [8.4, 68.1];
    const northEast = [32.4, 97.4];
    const bounds = [southWest, northEast];
    return (
      // this is the map for mandi screen
      // <Map
      //   key="2"
      //   center={centerPosition ? centerPosition : [12.981749, 77.606891]}
      //   zoom={zoomValue ? zoomValue : 5}
      //   doubleClickZoom={true}
      //   scrollWheelZoom={true}
      //   dragging={true}
      //   className="map-item"
      //   animate={true}
      //   minZoom={4.5}
      //   maxBounds={bounds}
      //   maxBoundsViscosity={1.0}
      // >
      //   <TileLayer
      //     url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png" //"https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png" //https://{s}.tile.osm.org/{z}/{x}/{y}.png"
      //     //Below url is of the New Map
      //     //url="https://api.mapbox.com/styles/v1/mapbox/light-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw"
      //     attribution='<a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      //   />
      //   {markers.map((item, idx) =>
      //     item.territory_latitude && item.territory_longitude ? (
      //       <DashboardMapMarker
      //         key={`${isMandi ? "mandi_" : "FOR_"}` + item.territory_id}
      //         position={[item.territory_latitude, item.territory_longitude]}
      //         status={item.live_status ? item.live_status : "open"}
      //         entryStatus={item.entry_status}
      //         name={item.territory_name}
      //         branchName={item.branch_name}
      //         mandiNumber={idx + 1}
      //         isFactory={item.is_factory}
      //       />
      //     ) : null // console.log("mandi/hub without co-ordinates :", item)
      //   )}
      // </Map>
      // <LoadScript googleMapsApiKey={process.env.REACT_APP_MAP_KEY}>
        <GoogleMap
          mapContainerStyle={{
            width: "100%",
            height: "87vh",
          }}
          center={
            centerPosition ? centerPosition : { lat: 12.981749, lng: 77.606891 }
          }
          options={{
            fullscreenControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            styles: [
              {
                elementType: "labels.icon",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "administrative.land_parcel",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "administrative",
                elementType: "labels",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "poi",
                elementType: "labels",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "road",
                elementType: "labels",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "transit",
                elementType: "labels",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "water",
                elementType: "labels",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "landscape",
                elementType: "labels",
                stylers: [{ visibility: "off" }],
              },
            ],
          }}
          zoom={zoomValue ? zoomValue : 5}
          // onLoad={map => {
          //   const bounds = new window.google.maps.Marker();
          //   bounds.setPosition(new window.google.maps.LatLng(12.981749, 77.606891));
          // }}
        >
          {markers &&
            markers.map((item, index) => {
              return (
                item.territory_latitude &&
                item.territory_longitude && (
                  <DashboardMapMarker
                    key={`${isMandi ? "mandi_" : "FOR_"}` + item.territory_id}
                    position={{
                      lat: item.territory_latitude,
                      lng: item.territory_longitude,
                    }}
                    status={item.live_status ? item.live_status : "open"}
                    entryStatus={item.entry_status}
                    name={item.territory_name}
                    branchName={item.branch_name}
                    mandiNumber={index + 1}
                    isFactory={item.is_factory}
                  />
                )
              );
            })}
        </GoogleMap>
      // </LoadScript>
    );
  }
}

const mapStateToProps = createStructuredSelector({});

export default connect(mapStateToProps, null)(DashboardMap);
