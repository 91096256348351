import React from "react";
import "antd/dist/antd.css";
import "./custom-dropdown-choose-region.styles.css";
import { Select } from "antd";

const { Option } = Select;

/**
 * @function
 * @description Get the name of selected Branch/Region/Variety
 * @param {object} item  object from the list corresponding to selected option
 * @param {string} selectType type of the dropdown
 * @returns name of the selected item
 * @memberof CustomDropdown
 */
const setValue = (item, selectType) => {
  switch (selectType.toLowerCase()) {
    case "branch":
      return item.territory_name;
    case "region":
      return item.region_name;
    case "variety":
      return item.variety_name;
      case "hub":
        return item.parent_name;
    default:
      break;
  }
};

/**
 * @function
 * @description Get the id of the selected Branch/Region/Variety
 * @param {object} item object from the list corresponding to selected option
 * @param {string} selectType type of the dropdown
 * @returns id of the selected item
 * @memberof CustomDropdown
 */
const setUniqueID = (item, selectType) => {
  switch (selectType.toLowerCase()) {
    case "branch":
      return item.territory_id;
    case "region":
      return item.region_id;
    case "variety":
      return item.variety_id;
      case "hub":
        return item.parent_id;
    default:
      break;
  }
};

/**
 * @component
 * @description Dropdown component used in choose hub component.
 * @param {string} placeholder place holder string displayed if no data is present 
 * @param {object[]} list list of objects to be populated in the dropdown
 * @param {function} handleSelect callback on selection from dropdown 
 * @param {string} selectType dropdown type name (Branch/Region/Variety) 
 */
const CustomDropdown = ({ placeholder, list, handleSelect, selectType, enableMultiple }) => {
  return (
    <div>
      <Select
        mode={enableMultiple ? "multiple" : "single"}
        showSearch
        value={placeholder}
        onChange={(item, props) => handleSelect(item, props)}
      >

        {list &&
          list.length !== 0 &&
          list.map((item, idx) => {
            return (
              <Option
                value={
                  setValue(item, selectType)
                }
                key={
                  setUniqueID(item, selectType)
                }
              >
                {setValue(item, selectType)}
              </Option>
            );
          })}
      </Select>
      <div className="underline"></div>
    </div>
  );
};

export default CustomDropdown;
