import React, { Component } from "react";
import "./efficiency-report.styles.css";
import moment from "moment";
import Arrow from "../../assets/up.svg";
import Green from "../../assets/green.svg";

import { Table, DatePicker, Spin, message, Button } from "antd";

import API, { baseURL } from "../../api";

import { canUserEdit, isBeforeTwoDays } from "../../auth/utils";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { setAuditModalVisibility } from "../../redux/summary/summary.actions";
import { selectIsModalVisible } from "../../redux/summary/summary.selectors";

import { setSummaryVisibility } from "../../redux/arrival-entry/arrival-entry.actions";
import { selectUserData } from "../../redux/user/user.selectors";

import CustomSelectSummary from "../../components/custom-select-summary/custom-select-summary.component";

import Axios from "axios";

const FileDownLoad = require("js-file-download");
const { RangePicker } = DatePicker;

/**
 * @component
 * @description Reusable component to display table header.
 * @property {string} title Header title
 * @property {string} subtitle Header subtitle
 * @property {string} value Header value
 * @property {boolean} hasArrow True if arrow exists
 * @property {string} arrowDirection Arrow direction
 * @property {string} arrowColor Arrow color
 * @memberof EfficiencyReport
 */
const TableNewHeader = (props) => {
  const { title, subtitle, value, hasArrow, arrowDirection, arrowColor } =
    props;
  return (
    <div className="col-container">
      <div
        style={{
          color: "#FFFFFF",
          fontWeight: "normal",
          fontSize: "12px",
          whiteSpace: "break",
        }}
      >
        {title}
      </div>
      <div
        className="bottom-container"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          {value != null ? (
            <h1>{isNaN(value) || value === -100 ? 0 : value}</h1>
          ) : (
            <h1></h1>
          )}
          <div
            style={{
              color: "#BAD2F8",
              fontSize: "12px",
              fontWeight: "normal",
              marginLeft: `${subtitle === "%" ? "4px" : "0px"}`,
              marginTop: "0px",
            }}
          >
            {subtitle}
          </div>
        </div>
        {hasArrow ? (
          <div style={{ marginTop: "-7px", marginRight: "2px" }}>
            <img
              alt=""
              className="arrow"
              src={arrowColor === "green" ? Green : Arrow}
              style={{
                transform: `${
                  arrowDirection === "down" ? "rotate(180deg)" : ""
                }`,
              }}
            />
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

//#endregion

//#region Table Props
const tableProps = {
  pagination: false,
  size: "default",
  bordered: true,
  scroll: { x: 200, y: window.innerHeight - 310 },
};
//#endregion
/**
 *
 * @description Efficiency report container in Report page.
 * @component
 */
class EfficiencyReport extends React.Component {
  state = {
    // buyerType: "Mandi",
    selectedBranch: "",
    selectedHub: "",
    selectedMandi: "",
    selectedVariety: "",
    dropdownData: {
      branches: [],
      regions: [],
      hubs: [],
      mandis: [],
      varieties: [],
    },
    defaultHub: "Select Hub",
    defaultMandi: "Select Mandi",
    lastClicked: "",
    selectedClaimType: "",
    selectedClaimTypeVal: "",
    selectedDivision: "",
    selectedDivisionVal: "",
    selectedBranchVal: "",
    selectedHubVal: "",
    selectedMandiVal: "",
    selectedVarietyVal: "",
    startDate: "",
    endDate: "",
    singleDate: "",
    deviationTableData: [],
    efficiencyTableData: [],
    isSpinnerOn: true,
    isSpinnerOnEfficiency: true,
    selectedTable: "deviation",
    totalTargetSumVol: 0,
    totalPurchasedSumVol: 0,
    totalTargetSumVolMTD: 0,
    totalPurchasedSumVolMTD: 0,
    totalTargetHLC: 0,
    totalPurchasedHLC: 0,
    totalTargetHLCMTD: 0,
    totalPurchasedHLCMTD: 0,
    totalHLCEfficiency: 0,
    totalHLCEfficiencyMTD: 0,
    //deviation
    totalSumTVol: 0,
    totalSumPVol: 0,
    totalTPrice: 0,
    totalPPrice: 0,
    priceDeviation: 0,
    volumeDeviation: 0,
    overallDeviaton: 0,
  };

  /**
   * @function
   * @description Handle Efficiency in LC tab selection
   * @memberof EfficiencyReport
   */
  selectEfficiency = () => {
    this.setState({
      selectedTable: "efficiency",
    });
  };

  /**
   * @function
   * @description Handle Deviation from recommendation tab selection
   * @memberof EfficiencyReport
   */
  selectDeviation = () => {
    this.setState({
      selectedTable: "deviation",
    });
  };

  /**
   * @function
   * @description Handle single date filter selection.
   * @param {dateRange} dates
   * @param {string} dateString Selected date string.
   * @memberof EfficiencyReport
   */
  onChangeSingleDate = (dates, dateString) => {
    this.setState({ singleDate: dateString }, () => {
      this.GetTableDataEfficiency(
        dateString,
        this.state.selectedVariety,
        this.state.selectedClaimType,
        this.state.selectedDivision
      );
    });
  };

  /**
   * @function
   * @description Handle date range filter selection.
   * @param {dateRange} dates
   * @param {string[]} dateStrings Array of strings consisting of selected date range.
   * @memberof EfficiencyReport
   */
  onChange = (dates, dateStrings) => {
    this.setState({ startDate: dateStrings[0], endDate: dateStrings[1] }, () =>
      this.GetTableDataDeviation(
        this.state.startDate,
        this.state.endDate,
        this.state.buyerType,
        this.state.selectedBranch,
        this.state.selectedHub,
        this.state.selectedMandi,
        this.state.selectedVariety
      )
    );
  };

  componentDidMount() {
    //first initialization branch details
    //#region

    const params = {
      table_type: "reports",
    };
    API.get("/branches", { params: params }).then((response) => {
      API.get("/varieties").then((varietyResponse) => {
        this.setState(
          {
            dropdownData: {
              branches: response.data.data.branches,
              hubs: null,
              mandis: null,
              varieties: varietyResponse.data.data.filter(
                (value, index, array) =>
                  array.findIndex(
                    (obj) => obj.variety_id === value.variety_id
                  ) === index
              ),
            },
            // isSpinnerOn: false
          },
          () => {
            this.GetTableDataDeviation(
              this.state.startDate !== ""
                ? this.state.startDate
                : moment().format("YYYY-MM-DD"),
              this.state.endDate !== ""
                ? this.state.endDate
                : moment().format("YYYY-MM-DD")
            );
            this.GetTableDataEfficiency(
              moment().format("YYYY-MM-DD"),
              this.state.selectedVariety
            );
          }
        );
      });
    });
    //#endregion
  }

  //Download Report onClick

  /**
   * @function
   * @description Function to download report
   * @memberof EfficiencyReport
   */
  downloadReport() {
    this.setState({ isSpinnerOn: true });
    let params;
    let END_POINT;
    let name;
    if (this.state.selectedTable === "deviation") {
      params = {
        download: true,
        start_date: this.state.startDate,
        end_date: this.state.endDate,
        claim_type: this.state.selectedClaimType,
        division: this.state.selectedDivision,
        source_type: this.state.buyerType,
        branch_id: this.state.selectedBranch,
        hub_id: this.state.selectedHub,
        variety_id: this.state.selectedVariety,
      };
      END_POINT = "/reports/efficiency_reports/recommendation_deviation";
      name = "Recommendation_Deviation_Report";
    } else {
      params = {
        download: true,
        date: this.state.singleDate,
        variety_id: this.state.selectedVariety,
        claim_type: this.state.selectedClaimType,
        division: this.state.selectedDivision,
      };
      END_POINT = "/reports/efficiency_reports/efficiency_in_landed_cost";
      name = "Efficiency_In_Landed_Cost_Report";
    }

    // API.get(END_POINT, {

    //   params: params,
    // })
    //   .then((fileresponse) => {
    //     if (fileresponse.data.status) {

    //#region file download
    Axios({
      url: `${baseURL}/${END_POINT}`,
      method: "GET",
      params: params,
      responseType: "blob",
    })
      .then((response) => {
        if (response.status !== 204) {
          FileDownLoad(
            response.data,
            `${name}_${new Date().toLocaleDateString()}.xlsx`
          );
        } else {
          message.warning("File has no data.");
        }
        this.setState({ isSpinnerOn: false });
      })
      .catch((err) => {
        message.error("File cannot be downloaded");
        this.setState({ isSpinnerOn: false });
      });
    //#endregion
    //   } else {
    //     message.warning("File has no data");
    //     this.setState({ isSpinnerOn: false });
    //   }
    // })
    // .catch((err) => {
    //   message.error("Error downloading file");
    //   this.setState({ isSpinnerOn: false });
    // });
  }

  //deviation table data retrieval function
  //#region
  /**
   * @function
   * @description Retieve and store Deviation from recommendation table data from API.
   * @param {string} startDate Start date of date range
   * @param {string} endDate End date of date range
   * @param {string} sourceType Arrival type (Mandi/FOR)
   * @param {string} branchId Branch ID
   * @param {string} hubId Hub ID
   * @param {string} mandiId Mandi ID
   * @param {string} varietyId Variety ID
   * @memberof EfficiencyReport
   */
  GetTableDataDeviation(
    startDate,
    endDate,
    claimType,
    division,
    branchId,
    hubId,
    varietyId
  ) {
    this.setState((prevState) => ({ ...prevState, isSpinnerOn: true }));

    const tableParams = {
      claim_type: claimType,
      division: division,
      branch_id: branchId,
      hub_id: hubId,
      variety_id: varietyId,
      start_date: startDate,
      end_date: endDate,
    };
    let sumTVol = 0,
      sumPVol = 0,
      sumProdTPrice = 0,
      sumProdPPrice = 0;
    API.get("/reports/efficiency_reports/recommendation_deviation", {
      params: tableParams,
    })
      .then((response) => {
        // if (response.data.data !== null) {

        const dd =
          response.data.data.recommendation_deviation &&
          response.data.data.recommendation_deviation.map((item) => {
            sumTVol += item.suggested_qty;
            sumPVol += item.purchased_qty;
            sumProdTPrice += item.suggested_price * item.suggested_qty;
            sumProdPPrice += item.purchased_price * item.purchased_qty;
            // console.log("Single item : ", item);
            return {
              key: item.buying_decision_id,
              date: item.decision_date,
              variety: item.variety_name,
              // buyingLocation: item.source_name,
              hub: item.hub_name,
              targetPrice: Math.round(item.suggested_price),
              // targetPrice: item.suggested_price.toFixed(2),
              targetVolume: Math.round(item.suggested_qty),
              purchasedPrice:
                item.purchased_price !== null
                  ? Math.round(item.purchased_price)
                  : 0,
              purchasedVolume:
                item.purchased_qty !== null
                  ? Math.round(item.purchased_qty)
                  : 0,
              priceDeviation: item.price_deviation,
              // -0.05,
              // item.suggested_price != null
              //   ? (item.purchased_price - item.suggested_price) /
              //   item.suggested_price
              //   : 0,
              volumeDeviation: item.volume_deviation,
              // 0.05,
              // item.suggested_qty != null
              //   ? (item.purchased_qty - item.suggested_qty) /
              //   item.suggested_qty
              //   : 0,
              overallDeviation: item.overall_deviation,
              // item.suggested_price * item.suggested_qty != null
              //   ? (item.suggested_price * item.suggested_qty -
              //     item.purchased_price * item.purchased_qty) /
              //   (item.suggested_price * item.suggested_qty)
              //   : 0,
            };
          });
        console.log("DATA_SOURCE", dd);

        this.setState({
          deviationTableData: dd,
          isSpinnerOn: false,
          totalSumTVol: sumTVol,
          totalSumPVol: sumPVol,
          totalTPrice: sumTVol !== 0 ? sumProdTPrice / sumTVol : 0,
          totalPPrice: sumPVol !== 0 ? sumProdPPrice / sumPVol : 0,
          priceDeviation:
            sumTVol !== 0
              ? (((sumPVol !== 0 ? sumProdPPrice / sumPVol : 0) -
                  (sumTVol !== 0 ? sumProdTPrice / sumTVol : 0)) /
                  (sumTVol !== 0 ? sumProdTPrice / sumTVol : 0)) *
                100
              : 0,
          volumeDeviation:
            sumTVol !== 0 ? ((sumPVol - sumTVol) / sumTVol) * 100 : 0,
          overallDeviation:
            (sumTVol !== 0 ? sumProdTPrice / sumTVol : 0) * sumTVol !== 0
              ? (((sumTVol !== 0 ? sumProdTPrice / sumTVol : 0) * sumTVol -
                  (sumPVol !== 0 ? sumProdPPrice / sumPVol : 0) * sumPVol) /
                  ((sumTVol !== 0 ? sumProdTPrice / sumTVol : 0) * sumTVol)) *
                100
              : 0,
        });
      })
      .catch((err) => {
        this.setState({ isSpinnerOn: false });
      });
  }
  //#endregion

  totalTargetSumVol = 0;
  totalPurchasedSumVol = 0;
  totalTargetSumVolMTD = 0;
  totalPurchasedSumVolMTD = 0;
  totalSumProdTargetHLC = 0;
  totalSumProdPurchasedHLC = 0;
  totalSumProdTargetHLCMTD = 0;
  totalSumProdPurchasedHLCMTD = 0;

  //efficiency table data retrieval function
  //#region
  /**
   * @description Retieve and store Efficiency in LC table data from API.
   * @param {string} date
   * @param {string} varietyId
   * @memberof EfficiencyReport
   */
  GetTableDataEfficiency(date, varietyId, claimType, division) {
    this.setState({ isSpinnerOnEfficiency: true });
    const tableParams = {
      date: date,
      variety_id: varietyId,
      claim_type: claimType,
      division: division,
    };

    this.setState({});

    API.get(`/reports/efficiency_reports/efficiency_in_landed_cost`, {
      params: tableParams,
    })
      .then((response) => {
        let count = 0;
        this.totalTargetSumVol = 0;
        this.totalPurchasedSumVol = 0;
        this.totalTargetSumVolMTD = 0;
        this.totalPurchasedSumVolMTD = 0;
        this.totalSumProdTargetHLC = 0;
        this.totalSumProdPurchasedHLC = 0;
        this.totalSumProdTargetHLCMTD = 0;
        this.totalSumProdPurchasedHLCMTD = 0;

        const dd =
          response.data.data &&
          response.data.data.efficiency_in_LC.map((item) => {
            let targetSumVol = 0,
              purchasedSumVol = 0,
              targetSumVolMTD = 0,
              purchasedSumVolMTD = 0,
              sumProdTargetHLC = 0,
              sumProdPurchasedHLC = 0,
              sumProdTargetHLCMTD = 0,
              sumProdPurchasedHLCMTD = 0;
            return {
              children: item.efficiency_in_LC.map((mandiItem) => {
                targetSumVol += mandiItem.target_volume;
                this.totalTargetSumVol += mandiItem.target_volume;
                purchasedSumVol += mandiItem.purchased_volume;
                this.totalPurchasedSumVol += mandiItem.purchased_volume;
                targetSumVolMTD += mandiItem.target_volume_MTD;
                this.totalTargetSumVolMTD += mandiItem.target_volume_MTD;
                purchasedSumVolMTD += mandiItem.purchased_volume_MTD;
                this.totalPurchasedSumVolMTD += mandiItem.purchased_volume_MTD;
                sumProdTargetHLC +=
                  mandiItem.target_volume * mandiItem.target_HLC;
                this.totalSumProdTargetHLC +=
                  mandiItem.target_volume * mandiItem.target_HLC;
                sumProdPurchasedHLC +=
                  mandiItem.purchased_volume * mandiItem.purchased_price;
                this.totalSumProdPurchasedHLC +=
                  mandiItem.purchased_volume * mandiItem.purchased_price;
                sumProdTargetHLCMTD +=
                  mandiItem.target_volume_MTD * mandiItem.target_HLC_MTD;
                this.totalSumProdTargetHLCMTD +=
                  mandiItem.target_volume_MTD * mandiItem.target_HLC_MTD;
                sumProdPurchasedHLCMTD +=
                  mandiItem.purchased_volume_MTD * mandiItem.purchased_HLC_MTD;
                this.totalSumProdPurchasedHLCMTD +=
                  mandiItem.purchased_volume_MTD * mandiItem.purchased_HLC_MTD;

                return {
                  key: count++,
                  buyingLocation: mandiItem.hub_name,
                  targetVolume: Math.round(mandiItem.target_volume),
                  purchasedVolume:
                    mandiItem.purchased_volume !== null
                      ? Math.round(mandiItem.purchased_volume)
                      : 0,
                  targetHlc:
                    mandiItem.target_HLC !== null
                      ? Math.round(mandiItem.target_HLC)
                      : 0,
                  purchasedHlc:
                    mandiItem.purchased_price !== null
                      ? Math.round(mandiItem.purchased_price)
                      : 0,
                  hlcEfficiency:
                    mandiItem.target_HLC !== 0
                      ? (mandiItem.purchased_price - mandiItem.target_HLC) /
                        mandiItem.target_HLC
                      : 0,
                  targetVolumeMTD: Math.round(mandiItem.target_volume_MTD),
                  purchasedVolumeMTD:
                    mandiItem.purchased_volume_MTD !== null
                      ? Math.round(mandiItem.purchased_volume_MTD)
                      : 0,
                  targetHlcMTD:
                    mandiItem.target_HLC_MTD !== null
                      ? Math.round(mandiItem.target_HLC_MTD)
                      : 0,
                  purchasedHlcMTD:
                    mandiItem.purchased_HLC_MTD !== null
                      ? Math.round(mandiItem.purchased_HLC_MTD)
                      : 0,
                  hlcEfficiencyMTD:
                    mandiItem.target_HLC_MTD !== 0
                      ? (mandiItem.purchased_HLC_MTD -
                          mandiItem.target_HLC_MTD) /
                        mandiItem.target_HLC_MTD
                      : 0,
                };
              }),
              key: count++,
              buyingLocation: item.branch_name,
              targetVolume: Math.round(targetSumVol),
              purchasedVolume: Math.round(purchasedSumVol),
              targetHlc:
                targetSumVol !== 0
                  ? Math.round(sumProdTargetHLC / targetSumVol)
                  : 0,
              purchasedHlc:
                purchasedSumVol !== 0
                  ? Math.round(sumProdPurchasedHLC / purchasedSumVol)
                  : 0,
              hlcEfficiency:
                targetSumVol !== 0
                  ? ((purchasedSumVol !== 0
                      ? sumProdPurchasedHLC / purchasedSumVol
                      : 0) -
                      (targetSumVol !== 0
                        ? sumProdTargetHLC / targetSumVol
                        : 0)) /
                    (targetSumVol !== 0 ? sumProdTargetHLC / targetSumVol : 0)
                  : 0,
              targetVolumeMTD: Math.round(targetSumVolMTD),
              purchasedVolumeMTD: Math.round(purchasedSumVolMTD),
              targetHlcMTD:
                targetSumVolMTD !== 0
                  ? Math.round(sumProdTargetHLCMTD / targetSumVolMTD)
                  : 0,
              purchasedHlcMTD:
                purchasedSumVolMTD !== 0
                  ? Math.round(sumProdPurchasedHLCMTD / purchasedSumVolMTD)
                  : 0,
              hlcEfficiencyMTD:
                targetSumVolMTD !== 0
                  ? ((purchasedSumVolMTD !== 0
                      ? sumProdPurchasedHLCMTD / purchasedSumVolMTD
                      : 0) -
                      (targetSumVolMTD !== 0
                        ? sumProdTargetHLCMTD / targetSumVolMTD
                        : 0)) /
                    (targetSumVolMTD !== 0
                      ? sumProdTargetHLCMTD / targetSumVolMTD
                      : 0)
                  : 0,
            };
          });

        this.setState(
          {
            efficiencyTableData: dd,
            isSpinnerOnEfficiency: false,
            totalTargetSumVol: this.totalTargetSumVol,
            totalPurchasedSumVol: this.totalPurchasedSumVol,
            totalTargetSumVolMTD: this.totalTargetSumVolMTD,
            totalPurchasedSumVolMTD: this.totalPurchasedSumVolMTD,
            totalTargetHLC:
              this.totalTargetSumVol !== 0
                ? this.totalSumProdTargetHLC / this.totalTargetSumVol
                : 0,
            totalPurchasedHLC:
              this.totalPurchasedSumVol !== 0
                ? this.totalSumProdPurchasedHLC / this.totalPurchasedSumVol
                : 0,
            totalTargetHLCMTD:
              this.totalTargetSumVolMTD !== 0
                ? this.totalSumProdTargetHLCMTD / this.totalTargetSumVolMTD
                : 0,
            totalPurchasedHLCMTD:
              this.totalPurchasedSumVolMTD !== 0
                ? this.totalSumProdPurchasedHLCMTD /
                  this.totalPurchasedSumVolMTD
                : 0,
            totalHLCEfficiency:
              this.totalTargetSumVol !== 0
                ? (((this.totalPurchasedSumVol !== 0
                    ? this.totalSumProdPurchasedHLC / this.totalPurchasedSumVol
                    : 0) -
                    (this.totalTargetSumVol !== 0
                      ? this.totalSumProdTargetHLC / this.totalTargetSumVol
                      : 0)) /
                    (this.totalTargetSumVol !== 0
                      ? this.totalSumProdTargetHLC / this.totalTargetSumVol
                      : 0)) *
                  100
                : 0,
            totalHLCEfficiencyMTD:
              this.totalTargetSumVolMTD !== 0
                ? (((this.totalPurchasedSumVolMTD !== 0
                    ? this.totalSumProdPurchasedHLCMTD /
                      this.totalPurchasedSumVolMTD
                    : 0) -
                    (this.totalTargetSumVolMTD !== 0
                      ? this.totalSumProdTargetHLCMTD /
                        this.totalTargetSumVolMTD
                      : 0)) /
                    (this.totalTargetSumVolMTD !== 0
                      ? this.totalSumProdTargetHLCMTD /
                        this.totalTargetSumVolMTD
                      : 0)) *
                  100
                : 0,
          },
          () => console.log()
        );
      })
      .catch((err) => {
        this.setState({ isSpinnerOn: false });
      });
  }
  //#endregion

  render() {
    //type handling
    //#region
    /**
     *
     * @description Handle type filter selection.
     * @param {string} value Selected arrival type.
     * @memberof EfficiencyReport
     */
    const handleClaimTypeSelect = (value, props) => {
      this.setState(
        (prevState) => ({
          ...prevState,
          selectedClaimTypeVal: value,
          selectedClaimType: props.key,
        }),
        () => {
          this.GetTableDataDeviation(
            this.state.startDate !== ""
              ? this.state.startDate
              : moment().format("YYYY-MM-DD"),
            this.state.endDate !== ""
              ? this.state.endDate
              : moment().format("YYYY-MM-DD"),
            props.key,
            this.state.selectedDivision,
            this.state.selectedBranch,
            this.state.selectedHub,
            this.state.selectedVariety
          );

          this.GetTableDataEfficiency(
            this.state.singleDate !== ""
              ? this.state.singleDate
              : moment().format("YYYY-MM-DD"),
            this.state.selectedVariety,
            this.state.selectedClaimType,
            this.state.selectedDivision
          );
        }
      );
    };
    //#endregion

    //division handling
    //#region
    /**
     *
     * @description Handle division filter selection.
     * @param {string} value Selected division.
     * @memberof EfficiencyReport
     */
    const handleDivisionSelect = (value, props) => {
      this.setState(
        (prevState) => ({
          ...prevState,
          selectedDivisionVal: value,
          selectedDivision: props.key,
        }),
        () => {
          this.GetTableDataDeviation(
            this.state.startDate !== ""
              ? this.state.startDate
              : moment().format("YYYY-MM-DD"),
            this.state.endDate !== ""
              ? this.state.endDate
              : moment().format("YYYY-MM-DD"),
            this.state.selectedClaimType,
            this.state.selectedDivision,
            this.state.selectedBranch,
            this.state.selectedHub,
            this.state.selectedVariety
          );
          this.GetTableDataEfficiency(
            this.state.singleDate !== ""
              ? this.state.singleDate
              : moment().format("YYYY-MM-DD"),
            this.state.selectedVariety,
            this.state.selectedClaimType,
            this.state.selectedDivision
          );
        }
      );
    };
    //#endregion

    //variety handling
    //#region
    //filtering variety data to name and key
    let varietyId, varietyName;
    if (this.state.dropdownData.varieties) {
      varietyId = this.state.dropdownData.varieties.map(
        (item) => item.variety_id
      );
      varietyName = this.state.dropdownData.varieties.map(
        (item) => item.variety_name
      );
    }

    /**
     *
     * @description Handle variety filter selection.
     * @param {string} value Selected variety value.
     * @param {object} props Selected variety object (key and value).
     * @memberof EfficiencyReport
     */
    const handleVarietySelect = (value, props) => {
      this.setState(
        (prevState) => ({
          ...prevState,
          selectedVarietyVal: value,
          selectedVariety: value === "All" ? "" : props.key,
        }),
        () => {
          this.GetTableDataDeviation(
            this.state.startDate !== ""
              ? this.state.startDate
              : moment().format("YYYY-MM-DD"),
            this.state.endDate !== ""
              ? this.state.endDate
              : moment().format("YYYY-MM-DD"),
            this.state.selectedClaimType,
            this.state.selectedDivision,
            this.state.selectedBranch,
            this.state.selectedHub,
            this.state.selectedVarietyVal === "All" ? null : props.key
          );

          this.GetTableDataEfficiency(
            this.state.singleDate !== ""
              ? this.state.singleDate
              : moment().format("YYYY-MM-DD"),
            this.state.selectedVarietyVal === "All" ? null : props.key,
            this.state.selectedClaimType,
            this.state.selectedDivision
          );
        }
      );
    };
    //#endregion

    //branch handling
    //#region
    //filtering branch data to name and key
    let branchId, branchName;
    if (this.state.dropdownData.branches) {
      branchId = this.state.dropdownData.branches.map(
        (item) => item.territory_id
      );
      branchName = this.state.dropdownData.branches.map(
        (item) => item.territory_name
      );
    }

    /**
     *
     * @description Handle branch filter selection.
     * @param {string} value Selected branch value.
     * @param {object} props Selected branch object (key and value).
     * @memberof EfficiencyReport
     */
    const handleBranchSelect = (value, props) => {
      const params = {
        table_type: "reports",
        branch_id: props.key,
        mapping: "mapped",
      };
      API.get(`/hubs`, { params: params }).then((response) => {
        //console.log("The response", response.data.data);
        this.setState(
          (prevState) => ({
            ...prevState,
            selectedBranch: value === "All" ? "" : props.key,
            selectedHub: "",
            dropdownData: {
              ...prevState.dropdownData,
              hubs: value === "All" ? [] : response.data.data.hubs,
              mandis: null,
            },
            lastClicked: value === "All" ? "BranchAll" : "Branch",
            selectedBranchVal: value,
            selectedHubVal: this.state.defaultHub,
            selectedMandiVal: this.state.defaultMandi,
          }),
          () =>
            this.GetTableDataDeviation(
              this.state.startDate !== ""
                ? this.state.startDate
                : moment().format("YYYY-MM-DD"),
              this.state.endDate !== ""
                ? this.state.endDate
                : moment().format("YYYY-MM-DD"),
              this.state.selectedClaimType,
              this.state.selectedDivision,
              value === "All" ? "" : props.key,
              null,
              this.state.selectedVariety
            )
        );
      });
    };
    //#endregion

    //hub handling
    //#region
    //filtering hub data to name and key
    let hubId, hubName;
    if (this.state.dropdownData.hubs) {
      hubId = this.state.dropdownData.hubs.map((item) => item.territory_id);
      hubName = this.state.dropdownData.hubs.map((item) => item.territory_name);
    }

    /**
     *
     * @description Handle hub filter selection.
     * @param {string} value Selected hub value.
     * @param {object} props Selected hub object (key and value).
     * @memberof ConsolidatedChart
     */
    const handleHubSelect = (value, props) => {
      const params = {
        table_type: "transaction/reports",
        mapping: "mapped",
        hub_id: props.key,
      };
      API.get(`/mandis`, {
        params: params,
      }).then((response) => {
        this.setState(
          (prevState) => ({
            ...prevState,
            selectedHub: value === "All" ? "" : props.key,
            dropdownData: {
              ...prevState.dropdownData,
              mandis: value === "All" ? null : response.data.data.mandis,
            },
            lastClicked: value === "All" ? "HubAll" : "Hub",
            selectedHubVal: value,
            selectedMandiVal: this.state.defaultMandi,
          }),
          () =>
            this.GetTableDataDeviation(
              this.state.startDate !== ""
                ? this.state.startDate
                : moment().format("YYYY-MM-DD"),
              this.state.endDate !== ""
                ? this.state.endDate
                : moment().format("YYYY-MM-DD"),
              this.state.selectedClaimType,
              this.state.selectedDivision,
              this.state.selectedBranch,
              value === "All" ? null : props.key,
              this.state.selectedVariety
            )
        );
      });
    };
    //#endregion

    return (
      <div className="efficiency-report">
        <div
          className="summary"
          style={{
            backgroundColor: "#f7f9fb",
            height: window.innerHeight - 131,
          }}
        >
          <div className="download-button-container">
            <Button
              onClick={() => this.downloadReport()}
              type="primary"
              shape="round"
              icon="download"
              size="large"
            >
              <div style={{ float: "right" }}>
                <div style={{ marginTop: "-7px" }}>Download</div>
                <div
                  style={{
                    fontSize: "11px",
                    lineHeight: "7px",
                    color: "#b4c8dc",
                  }}
                >
                  .xlsx report
                </div>
              </div>
            </Button>
          </div>

          <div className="filter-container">
            <div
              style={{
                width: `${
                  this.state.selectedTable === "deviation" ? "1365px" : "1018px"
                }`,
              }}
              className="summary-filters"
            >
              <div className="top-btn-container">
                <div
                  onClick={this.selectDeviation}
                  className={`container-btn ${
                    this.state.selectedTable === "deviation"
                      ? "container-btn-active"
                      : ""
                  }`}
                >
                  Deviation from recommendation
                </div>
                <div
                  onClick={this.selectEfficiency}
                  className={`container-btn ${
                    this.state.selectedTable !== "deviation"
                      ? "container-btn-active"
                      : ""
                  }`}
                >
                  Efficiency in LC
                </div>
              </div>

              {this.state.selectedTable === "deviation" ? (
                <div key={1} className="filter-select">
                  <div className="filter-select-title">Date Range</div>
                  <RangePicker
                    defaultValue={
                      this.state.startDate === ""
                        ? [moment(), moment()]
                        : [
                            moment(this.state.startDate, "YYYY-MM-DD"),
                            moment(this.state.endDate, "YYYY-MM-DD"),
                          ]
                    }
                    ranges={{
                      Today: [moment(), moment()],
                      Yesterday: [
                        moment().subtract(1, "days"),
                        moment().subtract(1, "days"),
                      ],
                      "Past 7 days": [moment().subtract(7, "days"), moment()],
                      "This Month": [moment().startOf("month"), moment()],
                      "Last Month": [
                        moment()
                          .startOf("month")
                          .subtract(1, "days")
                          .startOf("month"),
                        moment().startOf("month").subtract(1, "days"),
                      ],
                    }}
                    onChange={this.onChange}
                  />
                </div>
              ) : (
                <div key={2} className="filter-select">
                  <div className="filter-select-title">Date</div>
                  <div className="date-simple">
                    <DatePicker
                      defaultValue={
                        this.state.singleDate === ""
                          ? moment()
                          : moment(this.state.singleDate, "YYYY-MM-DD")
                      }
                      onChange={this.onChangeSingleDate}
                    />
                  </div>
                </div>
              )}

              <div className="filter-select">
                <div className="filter-select-title">Variety</div>

                <CustomSelectSummary
                  placeholder={
                    this.state.selectedVarietyVal === ""
                      ? "Select Variety"
                      : this.state.selectedVarietyVal
                  }
                  allEnabled
                  disabled={false}
                  list={varietyName}
                  keyList={varietyId}
                  handleSelect={handleVarietySelect}
                />
              </div>

              {/* {this.state.selectedTable === "deviation" && (
                <div className="filter-select">
                  <div className="filter-select-title">Buying Channel</div>
                  <CustomSelectSummary
                    //onSelect={setBuyerType}
                    connectState
                    placeholder={
                      this.state.selectedBuyerType !== ""
                        ? this.state.selectedBuyerType
                        : this.state.buyerType
                    }
                    handleSelect={handleTypeSelect}
                    list={["Mandi", "Trader", "Farmer"]}
                  />
                </div>
              )} */}

              {this.state.selectedTable === "deviation" && (
                <div className="filter-select">
                  <div className="filter-select-title">Branch</div>
                  <CustomSelectSummary
                    placeholder={
                      this.state.lastClicked === ""
                        ? "Select Branch"
                        : this.state.lastClicked === "BranchAll"
                        ? "All"
                        : this.state.selectedBranchVal
                    }
                    allEnabled
                    disabled={this.state.dropdownData.branches == null}
                    list={branchName}
                    keyList={branchId}
                    handleSelect={handleBranchSelect}
                  />
                </div>
              )}

              {this.state.selectedTable === "deviation" && (
                <div className="filter-select">
                  <div className="filter-select-title">Destination Hub</div>
                  <CustomSelectSummary
                    placeholder={
                      this.state.lastClicked === ""
                        ? "Select Hub"
                        : this.state.lastClicked === "HubAll"
                        ? "All"
                        : this.state.selectedHubVal
                    }
                    allEnabled
                    list={hubName}
                    keyList={hubId}
                    handleSelect={handleHubSelect}
                    disabled={
                      this.state.dropdownData.hubs == null ||
                      this.state.dropdownData.hubs.length === 0
                    }
                  />
                </div>
              )}
              <div className="filter-select">
                <div className="filter-select-title">Claim Type</div>
                <CustomSelectSummary
                  //onSelect={setBuyerType}
                  connectState
                  placeholder={
                    this.state.selectedClaimTypeVal === ""
                      ? "Select Claim Type"
                      : this.state.selectedClaimTypeVal
                  }
                  handleSelect={handleClaimTypeSelect}
                  list={["All", "Claim", "No Claim"]}
                  keyList={["", 1, 2]}
                />
              </div>
              <div className="filter-select">
                <div className="filter-select-title">Division</div>
                <CustomSelectSummary
                  connectState
                  placeholder={
                    this.state.selectedDivisionVal === ""
                      ? "Select Division"
                      : this.state.selectedDivisionVal
                  }
                  handleSelect={handleDivisionSelect}
                  list={["All", "ABD", "FBD", "NCDEX"]}
                  keyList={["", 1, 2, 3]}
                />
              </div>
            </div>
          </div>
          <div className="table-container">
            <Spin
              spinning={
                this.state.isSpinnerOn || this.state.isSpinnerOnEfficiency
              }
              tip="Loading..."
            >
              {this.state.selectedTable === "deviation" ? (
                <div className="deviation-table-container">
                  <Table
                    key="deviation"
                    {...tableProps}
                    columns={[
                      {
                        title: "Date",
                        key: "date1",
                        width: 90,
                        children: [
                          {
                            title: "",
                            key: "date2",
                            width: 90,
                            children: [
                              {
                                title: "",
                                dataIndex: "date",
                                key: "date",
                                width: 90,
                              },
                            ],
                          },
                        ],
                      },
                      {
                        title: "Variety",
                        key: "variety1",
                        width: 90,
                        children: [
                          {
                            title: "",
                            key: "variety2",
                            width: 90,
                            children: [
                              {
                                title: "",
                                dataIndex: "variety",
                                key: "variety",
                                width: 90,
                              },
                            ],
                          },
                        ],
                      },
                      {
                        title: "Hub",
                        key: "hub1",
                        width: 90,
                        children: [
                          {
                            title: "",
                            key: "hub2",
                            width: 90,
                            children: [
                              {
                                title: "",
                                dataIndex: "hub",
                                key: "hub",
                                width: 90,
                              },
                            ],
                          },
                        ],
                      },
                      {
                        title: "Model",
                        children: [
                          /* {
                            title: (
                              <TableNewHeader
                                title="Target Price"
                                subtitle="₹/MT"
                              />
                            ),
                            key: "targetPrice1",
                            width: 90,
                            children: [
                              {
                                title: (
                                  <TableNewHeader
                                    value={Math.round(this.state.totalTPrice)}
                                  />
                                ),
                                dataIndex: "targetPrice",
                                key: "targetPrice",
                                width: 90,
                              },
                            ],
                          }, */
                          {
                            title: (
                              <TableNewHeader
                                title="Recommended Volume"
                                subtitle="MT"
                              />
                            ),
                            key: "targetVolume1",
                            width: 90,
                            children: [
                              {
                                title: (
                                  <TableNewHeader
                                    value={Math.round(this.state.totalSumTVol)}
                                  />
                                ),
                                dataIndex: "targetVolume",
                                key: "targetVolume",
                                width: 90,
                              },
                            ],
                          },
                        ],
                      },
                      {
                        title: "Actual",
                        children: [
                          {
                            title: (
                              <TableNewHeader
                                title="Purchased Price"
                                subtitle="₹/MT"
                              />
                            ),
                            key: "purchasedPrice1",
                            width: 90,
                            children: [
                              {
                                title: (
                                  <TableNewHeader
                                    value={Math.round(this.state.totalPPrice)}
                                  />
                                ),
                                dataIndex: "purchasedPrice",
                                key: "purchasedPrice",
                                width: 90,
                              },
                            ],
                          },
                          {
                            title: (
                              <TableNewHeader
                                title="Purchased Volume"
                                subtitle="MT"
                              />
                            ),
                            key: "purchasedVolume1",
                            width: 90,
                            children: [
                              {
                                title: (
                                  <TableNewHeader
                                    value={Math.round(this.state.totalSumPVol)}
                                  />
                                ),
                                dataIndex: "purchasedVolume",
                                key: "purchasedVolume",
                                width: 90,
                              },
                            ],
                          },
                        ],
                      },
                      {
                        title: "Deviation",
                        children: [
                          // {
                          //   title: <TableNewHeader title="Price Deviation" />,
                          //   key: "priceDeviation1",
                          //   width: 90,

                          //   children: [
                          //     {
                          //       title: (
                          //         <TableNewHeader
                          //           value={Math.abs(
                          //             Math.round(this.state.priceDeviation)
                          //           )}
                          //           subtitle="%"
                          //           hasArrow={false}
                          //           arrowColor={
                          //             this.state.priceDeviation < 0
                          //               ? "green"
                          //               : "red"
                          //           }
                          //           arrowDirection={
                          //             this.state.priceDeviation < 0
                          //               ? "down"
                          //               : "up"
                          //           }
                          //         />
                          //       ),
                          //       dataIndex: "priceDeviation",
                          //       key: "priceDeviation",
                          //       width: 90,
                          //       render: (data, rowRecord) => {
                          //         return {
                          //           props: {
                          //             style: {
                          //               backgroundColor:
                          //                 data >= 0.015 || data <= -0.015
                          //                   ? `rgb(255, 171, 72,${
                          //                       isNaN(data)
                          //                         ? 0
                          //                         : Math.abs(
                          //                             parseFloat(
                          //                               data / 100
                          //                             ).toFixed(2)
                          //                           )
                          //                     })`
                          //                   : "white",
                          //             },
                          //           },
                          //           children: (
                          //             <div className={`percent-col`}>
                          //               <div>
                          //                 {Math.round(data)}
                          //                 {/*}  {Math.abs(
                          //                   isNaN(data)
                          //                     ? 0
                          //                     : Math.round(data * 100)
                          //               )}*/}
                          //                 %
                          //               </div>
                          //             </div>
                          //           ),
                          //         };
                          //       },
                          //     },
                          //   ],
                          // },
                          {
                            title: <TableNewHeader title="Volume Deviation" />,
                            key: "volumeDeviation1",
                            width: 90,

                            children: [
                              {
                                title: (
                                  <TableNewHeader
                                    value={Math.abs(
                                      Math.round(this.state.volumeDeviation)
                                    )}
                                    subtitle="%"
                                    hasArrow={false}
                                    arrowColor={
                                      this.state.volumeDeviation < 0
                                        ? "red"
                                        : "green"
                                    }
                                    arrowDirection={
                                      this.state.volumeDeviation < 0
                                        ? "down"
                                        : "up"
                                    }
                                  />
                                ),
                                dataIndex: "volumeDeviation",
                                key: "volumeDeviation",
                                width: 90,
                                render: (data, rowRecord) => {
                                  return {
                                    props: {
                                      style: {
                                        backgroundColor:
                                          data >= 0.015 || data <= -0.015
                                            ? `rgb(255, 171, 72,${
                                                isNaN(data)
                                                  ? 0
                                                  : Math.abs(
                                                      parseFloat(
                                                        data / 100
                                                      ).toFixed(2)
                                                    )
                                              })`
                                            : "white",
                                      },
                                    },
                                    children: (
                                      <div className={`percent-col`}>
                                        <div>
                                          {Math.round(data)}
                                          {/*{Math.abs(
                                            isNaN(data)
                                              ? 0
                                              : Math.round(data * 100)
                                          )}*/}
                                          %
                                        </div>
                                      </div>
                                    ),
                                  };
                                },
                              },
                            ],
                          },
                          // {
                          //   title: <TableNewHeader title="Overall Deviation" />,
                          //   key: "overallDeviation1",
                          //   width: 90,

                          //   children: [
                          //     {
                          //       title: (
                          //         <TableNewHeader
                          //           value={Math.abs(
                          //             Math.round(this.state.overallDeviation)
                          //           )}
                          //           subtitle="%"
                          //         />
                          //       ),
                          //       dataIndex: "overallDeviation",
                          //       key: "overallDeviation",
                          //       width: 90,
                          //       render: (data, rowRecord) => {
                          //         return {
                          //           props: {
                          //             style: {
                          //               backgroundColor:
                          //                 data >= 0.015 || data <= -0.015
                          //                   ? `rgb(255, 171, 72,${
                          //                       isNaN(data)
                          //                         ? 0
                          //                         : Math.abs(
                          //                             parseFloat(
                          //                               data / 100
                          //                             ).toFixed(2)
                          //                           )
                          //                     })`
                          //                   : "white",
                          //             },
                          //           },
                          //           children: (
                          //             <div className={`percent-col `}>
                          //               <div>
                          //                 {Math.round(data)}
                          //                 {/*{Math.abs(
                          //                   isNaN(data)
                          //                     ? 0
                          //                     : Math.round(data * 100)
                          //               )}*/}
                          //                 %
                          //               </div>
                          //               <div></div>
                          //             </div>
                          //           ),
                          //         };
                          //       },
                          //     },
                          //   ],
                          // },
                        ],
                      },
                    ]}
                    dataSource={this.state.deviationTableData}
                  />
                </div>
              ) : (
                <div className="efficiency-table-container">
                  <Table
                    key="efficiency"
                    {...tableProps}
                    columns={[
                      {
                        title: "Buying Location",
                        key: "buyingLocation1",
                        width: 200,
                        children: [
                          {
                            title: "",
                            key: "buyingLocation2",
                            width: 200,
                            children: [
                              {
                                title: "",
                                dataIndex: "buyingLocation",
                                key: "buyingLocation",
                                width: 200,
                              },
                            ],
                          },
                        ],
                      },

                      {
                        title: "Selected date",
                        children: [
                          {
                            title: (
                              <TableNewHeader
                                title="Target Volume"
                                subtitle="MT"
                              />
                            ),
                            key: "targetVolume1",
                            width: 90,
                            children: [
                              {
                                title: (
                                  <TableNewHeader
                                    value={Math.round(
                                      this.state.totalTargetSumVol
                                    )}
                                  />
                                ),
                                dataIndex: "targetVolume",
                                key: "targetVolume",
                                width: 90,
                              },
                            ],
                          },
                          {
                            title: (
                              <TableNewHeader
                                title="Purchased Volume"
                                subtitle="MT"
                              />
                            ),
                            key: "purchasedVolume1",
                            width: 100,
                            children: [
                              {
                                title: (
                                  <TableNewHeader
                                    value={Math.round(
                                      this.state.totalPurchasedSumVol
                                    )}
                                  />
                                ),
                                dataIndex: "purchasedVolume",
                                key: "purchasedVolume",
                                width: 100,
                              },
                            ],
                          },
                          {
                            title: (
                              <TableNewHeader
                                title="Target HLC"
                                subtitle="₹/MT"
                              />
                            ),
                            key: "targetHlc1",
                            width: 90,
                            children: [
                              {
                                title: (
                                  <TableNewHeader
                                    value={Math.round(
                                      this.state.totalTargetHLC
                                    )}
                                  />
                                ),
                                dataIndex: "targetHlc",
                                key: "targetHlc",
                                width: 90,
                              },
                            ],
                          },
                          {
                            title: (
                              <TableNewHeader
                                title="Purchased HLC"
                                subtitle="₹/MT"
                              />
                            ),
                            key: "purchasedHlc1",
                            width: 90,
                            children: [
                              {
                                title: (
                                  <TableNewHeader
                                    value={Math.round(
                                      this.state.totalPurchasedHLC
                                    )}
                                  />
                                ),
                                dataIndex: "purchasedHlc",
                                key: "purchasedHlc",
                                width: 90,
                              },
                            ],
                          },
                          {
                            title: <TableNewHeader title="HLC Efficiency" />,
                            key: "hlcEfficiency1",
                            width: 90,
                            children: [
                              {
                                title: (
                                  <TableNewHeader
                                    value={
                                      // Math.abs(
                                      Math.round(this.state.totalHLCEfficiency)
                                      // )
                                    }
                                    subtitle="%"
                                    hasArrow={
                                      this.state.totalHLCEfficiency !== 0 &&
                                      Math.round(
                                        this.state.totalHLCEfficiency
                                      ) !== -100
                                        ? true
                                        : false
                                    }
                                    arrowDirection={
                                      this.state.totalHLCEfficiency <= 0
                                        ? "up"
                                        : "down"
                                    }
                                    arrowColor={
                                      this.state.totalHLCEfficiency <= 0
                                        ? "green"
                                        : "red"
                                    }
                                  />
                                ),
                                dataIndex: "hlcEfficiency",
                                key: "hlcEfficiency",
                                width: 90,
                                render: (data, rowRecord) => {
                                  return {
                                    props: {
                                      className: `${
                                        data <= 0 || isNaN(data) ? "green" : ""
                                      }  ${data > 0 ? "red" : ""}`,
                                    },
                                    children: (
                                      <div className={`percent-col `}>
                                        <div>
                                          {isNaN(data) || data === -1
                                            ? 0
                                            : Math.round(data * 100)}
                                          %
                                        </div>

                                        {data < 0 && data !== -1 && (
                                          <img
                                            alt=""
                                            className="arrow"
                                            src={Green}
                                          />
                                        )}
                                        {data > 0 && (
                                          <img
                                            alt=""
                                            className="arrow"
                                            src={Arrow}
                                            style={{
                                              transform: "rotate(180deg)",
                                            }}
                                          />
                                        )}
                                        {data === 0 ||
                                          (data === -1 && <div></div>)}
                                      </div>
                                    ),
                                  };
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        title: "MTD",
                        children: [
                          {
                            title: (
                              <TableNewHeader
                                title="Target Volume"
                                subtitle="MT"
                              />
                            ),
                            key: "targetVolumeMTD1",
                            width: 90,
                            children: [
                              {
                                title: (
                                  <TableNewHeader
                                    value={Math.round(
                                      this.state.totalTargetSumVolMTD
                                    )}
                                  />
                                ),
                                dataIndex: "targetVolumeMTD",
                                key: "targetVolumeMTD",
                                width: 90,
                              },
                            ],
                          },
                          {
                            title: (
                              <TableNewHeader
                                title="Purchased Volume"
                                subtitle="MT"
                              />
                            ),
                            key: "purchasedVolumeMTD1",
                            width: 100,
                            children: [
                              {
                                title: (
                                  <TableNewHeader
                                    value={Math.round(
                                      this.state.totalPurchasedSumVolMTD
                                    )}
                                  />
                                ),
                                dataIndex: "purchasedVolumeMTD",
                                key: "purchasedVolumeMTD",
                                width: 100,
                              },
                            ],
                          },
                          {
                            title: (
                              <TableNewHeader
                                title="Target HLC"
                                subtitle="₹/MT"
                              />
                            ),
                            key: "targetHlcMTD1",
                            width: 90,
                            children: [
                              {
                                title: (
                                  <TableNewHeader
                                    value={Math.round(
                                      this.state.totalTargetHLCMTD
                                    )}
                                  />
                                ),
                                dataIndex: "targetHlcMTD",
                                key: "targetHlcMTD",
                                width: 90,
                              },
                            ],
                          },
                          {
                            title: (
                              <TableNewHeader
                                title="Purchased HLC"
                                subtitle="₹/MT"
                              />
                            ),
                            key: "purchasedHlcMTD1",
                            width: 90,
                            children: [
                              {
                                title: (
                                  <TableNewHeader
                                    value={Math.round(
                                      this.state.totalPurchasedHLCMTD
                                    )}
                                  />
                                ),
                                dataIndex: "purchasedHlcMTD",
                                key: "purchasedHlcMTD",
                                width: 90,
                              },
                            ],
                          },
                          {
                            title: <TableNewHeader title="HLC Efficiency" />,
                            key: "hlcEfficiencyMTD1",
                            width: 90,
                            children: [
                              {
                                title: (
                                  <TableNewHeader
                                    value={Math.round(
                                      this.state.totalHLCEfficiencyMTD
                                    )}
                                    subtitle="%"
                                    hasArrow={
                                      this.state.totalHLCEfficiencyMTD !== 0 &&
                                      Math.round(
                                        this.state.totalHLCEfficiencyMTD
                                      ) !== -100
                                        ? true
                                        : false
                                    }
                                    arrowDirection={
                                      this.state.totalHLCEfficiencyMTD <= 0
                                        ? "up"
                                        : "down"
                                    }
                                    arrowColor={
                                      this.state.totalHLCEfficiencyMTD <= 0
                                        ? "green"
                                        : "red"
                                    }
                                  />
                                ),
                                dataIndex: "hlcEfficiencyMTD",
                                key: "hlcEfficiencyMTD",
                                width: 90,
                                render: (data, rowRecord) => {
                                  return {
                                    props: {
                                      className: `${
                                        data <= 0 || isNaN(data) ? "green" : ""
                                      } ${data > 0 ? "red" : ""}`,
                                    },
                                    children: (
                                      <div className={`percent-col `}>
                                        <div>
                                          {isNaN(data) || data === -1
                                            ? 0
                                            : Math.round(data * 100)}
                                          %
                                        </div>

                                        {data < 0 && data !== -1 && (
                                          <img
                                            alt=""
                                            className="arrow"
                                            src={Green}
                                          />
                                        )}
                                        {data > 0 && (
                                          <img
                                            alt=""
                                            className="arrow"
                                            src={Arrow}
                                            style={{
                                              transform: "rotate(180deg)",
                                            }}
                                          />
                                        )}
                                        {data === 0 ||
                                          (data === -1 && <div></div>)}
                                      </div>
                                    ),
                                  };
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ]}
                    dataSource={this.state.efficiencyTableData}
                    expandRowByClick
                  />
                </div>
              )}
            </Spin>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  isModalVisible: selectIsModalVisible,
  userData: selectUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setAuditModalVisibility: (visibilityState) =>
      dispatch(setAuditModalVisibility(visibilityState)),
    setSummaryVisibility: (isEnabled) =>
      dispatch(setSummaryVisibility(isEnabled)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EfficiencyReport);
