import React from "react";
import "./news-form.styles.css";
import { Component } from "react";

import { Form, DatePicker, Input, Button, message } from "antd";

import API from "../../api";

import { connect } from "react-redux";

import moment from "moment";

import { setAllUsers } from "../../redux/user/user.actions";

/**
 * @component 
 * @description the form component to add/edit the news
 * @property {function} setModalVisibility function to enable/disable the visibility of the modal containing the form.
 * @property {object} currentRecord the current selected news object for editing (null during adding news)
 * @property {boolean} isAdd true if adding a news.
 */
class NewsHandler extends Component {
  state = {
    isSubmitButtonClicked: false,
  };

  render() {
    console.log("rendering form", this.props.currentRecord);

    const {
      setModalVisibility,
      currentRecord,
      isAdd,
    } = this.props;

    const { getFieldDecorator } = this.props.form;

    /**
     * @description Handles the submission of add/edit news form.
     * @param {event} e the form submit event containing the news url,expiry date and description.
     * @memberof NewsHandler
     */
    const handleSubmit = (e) => {
      e.preventDefault();

      this.props.form.validateFields((err, values) => {
        if (!err) {
          console.log(values);

          this.setState({ isSubmitButtonClicked: true });



          // #region api to create or update user
          if (isAdd) {
            //#region api to add user group and update state
            const dataToSend = {
              news_url: values.url,
              expiry_date: moment(values.expiryDate, "YYYY-MM-DD").format(
                "YYYY-MM-DD"
              ),
              news_text: values.description,
            };
            API.post("/user/news", dataToSend)
              .then((newsResponse) => {
                console.log("post response :", newsResponse);
                if (newsResponse.status) {
                  //#region add news to redux

                  let modifiedNewsData = [
                    ...this.props.allNews,
                    newsResponse.data.data,
                  ];
                  console.log("new group", modifiedNewsData);

                  this.props.setNews(modifiedNewsData);

                  //#endregion

                  setModalVisibility(false, false, null);
                  this.props.form.resetFields();
                  this.setState({ isSubmitButtonClicked: false });
                } else {
                  this.setState({ isSubmitButtonClicked: false });
                  message.warning("Could not create news. Please try again.");
                  console.log("could not send post api to create news");
                }
              })
              .catch((err) => {
                this.setState({ isSubmitButtonClicked: false });
                message.error(
                  "Could not create news. Please check your network connectivity."
                );
                console.log(
                  "could not send post api to create news , ERROR :",
                  err
                );
              });

            //#endregion
          } else {
            //#region api to update user and update state.

            const dataToSend = {
              news_url: values.url,
              expiry_date: moment(values.expiryDate, "YYYY-MM-DD").format(
                "YYYY-MM-DD"
              ),
              news_text: values.description,
            };

            API.put(`/user/news/${currentRecord.news_id}`, dataToSend)
              .then((newsResponse) => {
                if (newsResponse.status) {

                  //#region update redux state
                  let modifiedNewsData = this.props.allNews.map((news) => {
                    if (news.news_id === currentRecord.news_id) {
                      return newsResponse.data.data;
                    }
                    return news;
                  });

                  this.props.setNews(modifiedNewsData);
                  //#endregion

                  setModalVisibility(false, false, null);
                  this.props.form.resetFields();
                  this.setState({ isSubmitButtonClicked: false });
                } else {
                  this.setState({ isSubmitButtonClicked: false });
                  message.warning("Could not update news. Please try again.");
                  console.log("could not send put api to update news");
                }
              })
              .catch((err) => {
                this.setState({ isSubmitButtonClicked: false });
                message.error(
                  "Could not update news. Please check your network connectivity."
                );
                console.log(
                  "could not send put api to update news , ERROR :",
                  err
                );
              });

            //#endregion
          }

          //#endregion
        } else {
          console.log("Invalid entry");
        }
      });
    };

    return (
      <div className="user-group-form-container">
        <Form
          layout="horizontal"
          //   onValuesChange={onFormLayoutChange}
          size={"small"}
          onSubmit={handleSubmit}
        >
          <div className="form-top-container">
            <div className="form-top-left">
              <Form.Item label="News URL">
                {getFieldDecorator("url", {
                  initialValue:
                    currentRecord !== null ? currentRecord.news_url : "",
                  rules: [
                    {
                      required: true,
                      message: "Please enter news URL !",
                    },
                  ],
                })(
                  <Input
                    disabled={this.state.isSubmitButtonClicked}
                    placeholder="Enter news URL"
                  />
                )}
              </Form.Item>

              <Form.Item label="News">
                <div>
                  {getFieldDecorator("description", {
                    initialValue:
                      currentRecord !== null ? currentRecord.news_text : "",
                    rules: [
                      {
                        required: true,
                        message: "Please enter the news text !",
                      },
                    ],
                  })(
                    <Input.TextArea
                      disabled={this.state.isSubmitButtonClicked}
                      className="alert-text-area"
                      allowClear
                      rows={4}
                      autoSize={{ minRows: 4, maxRows: 6 }}
                    />
                  )}
                </div>
              </Form.Item>
              <Form.Item label="Expiry Date">
                {getFieldDecorator("expiryDate", {
                  initialValue: currentRecord
                    ? moment(currentRecord.expiry_date)
                    : null,
                  rules: [
                    {
                      required: true,
                      message: "Please select the news expiry date!",
                    },
                  ],
                })(
                  <DatePicker
                    disabled={this.state.isSubmitButtonClicked}
                    disabledDate={(current) =>
                      current < moment().subtract(1, "days").endOf("day")
                    }
                  />
                )}
              </Form.Item>
            </div>
          </div>

          <div className="form-bottom-container">
            <Form.Item>
              <Button
                disabled={this.state.isSubmitButtonClicked}
                type="primary"
                htmlType="submit"
              >
                Submit
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    );
  }
}

const NewsForm = Form.create({ name: "news_form" })(NewsHandler);

const mapDispatchToProps = (dispatch) => {
  return {
    setAllUsers: (data) => dispatch(setAllUsers(data)),
  };
};

export default connect(null, mapDispatchToProps)(NewsForm);
