import React from "react";
import "./favorite-mandi-master.page";
import { Component } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUserData,selectMSMessageRBAC } from "../../redux/user/user.selectors";
import { canUserEdit,getUserMSRBACMessage } from "../../auth/utils";
import { FeatureCodes } from "../../auth/data";
import {
  Table,
  Spin,
  Input,
  Select,
  Button,
  message,
  Form,
  Modal,
  Radio,
} from "antd";

import API from "../../api";
const { Search } = Input;
const { Option } = Select;
/**
 * @description Component for user management tab
 *
 * @component
 */
class VarietyRegionMaster extends Component {
  state = {
    isModalVisible: false,
    isAdd: false,
    currentRecord: null,
    userBranches: null,
    searchText: null,
    searchUserType: 0,
    favoriteMandiTableData: [],
    mandiList: [],
    mandimandiList: [],
    branchList: [],
    hubmandiList: [],
    hubBranchList: [],
    isSpinnerOnLoad: false,
    isSubmitted: false,
    newData: {
      branch_id: undefined,
      branch_name: "",
      fav_id: undefined,
      mandi_id: undefined,
      isEdit: false,
      mandi_name: "",
    },
    rowData: undefined,
    selectedRoleValue: undefined,
    selectedRoleName: "",
    selectedbranchList: [],
  };
  componentDidMount() {
    this.setState({ isSpinnerOnLoad: true });
    this.getbranchList();
    this.getmandiList();
  }

  getbranchList = async () => {
    this.setState({ isSpinnerOnLoad: true });

    //TODO: change api
    await API.get("/list_branch")
      .then((roleData) => {
        this.setState(
          {
            branchList: roleData.data.data,
          },
          () => this.getFavoriteMandiMaster()
        );
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400) message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };

  getmandiList = async () => {

    //TODO: change api
    await API.get("/list_mandi")
      .then((roleData) => {
        this.setState(
          {
            mandiList: roleData.data.data,
          },
        );
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400) message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };

  getFavoriteMandiMaster = async () => {
    this.setState({ isSpinnerOnLoad: true });


    await API.get("/master_favourite_mandis")
      .then((varietyResponse) => {

        varietyResponse.data.data.map((dataDetailItem, index) => {
          dataDetailItem.key = index;
          dataDetailItem.isEdit = false;
        });
        this.setState(
          {
            // favoriteMandiTableData: [],
            favoriteMandiTableData: varietyResponse.data.data,
          },
          () => this.setState({ isSpinnerOnLoad: false, isAdd: true })
        );

      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400) message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };

  setTableData = () => {
    if (this.state.favoriteMandiTableData !== undefined || this.state.favoriteMandiTableData.length >= 0) {
      const userTableData = this.state.favoriteMandiTableData.map((user, index) => {
        return {
          key: user.key,
          branch_id: user.branch_id,
          branch_name: user.branch_name,
          fav_id: user.fav_id,
          mandi_id: user.mandi_id,
          isEdit: user.isEdit,
          mandi_name: user.mandi_name,
        };
      });

      let dataAfterSearch = userTableData;

      if (this.state.searchText) {
        dataAfterSearch = dataAfterSearch.filter(
          (item) =>
            (item.mandi_name !== null &&
              item.mandi_name
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase())) ||
            (item.branch_name !== null &&
              item.branch_name
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase()))
          // (item.hub_name !== null &&
          //   item.hub_name
          //     .toLowerCase()
          //     .includes(this.state.searchText.toLowerCase())) 
        );
      }

      return dataAfterSearch;
    }
    else {
      return this.state.favoriteMandiTableData;
    }
  };

  submitTableRowData = (row, index, isPopup) => {
    this.setState({ isSpinnerOnLoad: true, isSubmitted: true });

    let warningmessage = "";
    if (row.mandi_name === null || row.mandi_name === undefined || row.mandi_name === "") {
      warningmessage = "Please select mandi";
    }
    else if (row.branch_name === null || row.branch_name === undefined || row.branch_name === "") {
      warningmessage = "Please select branch";
    }

    if (
      warningmessage === "" ||
      warningmessage === undefined ||
      warningmessage === null
    ) {
      this.setState({ isSpinnerOnLoad: true });
      this.setState({ isSubmitted: true })
      let data = {
        branch_id: row.branch_id,
        fav_id: row.fav_id,
        mandi_id: row.mandi_id,
      };



      API.put("/master_favourite_mandis", data,)
        .then((response) => {
          if (response.status === 200 && response.data.status) {
            message.info(response.data.message, 5);
            this.setModalVisibility(false);
            this.getFavoriteMandiMaster();
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
          } else if (!response.data.status) {
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
            message.error(response.data.message, 5);
            // this.setModalVisibility(false);
          } else {
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
            message.error("Please try again", 5);
          }
        })
        .catch((err) => {
          if (err) {
            if (err.response && err.response.status === 400) message.error("Something went wrong. Please try again later.", 5);
            else {
              message.error("Something went wrong.. Please try again later.", 5);
            }
          } else {
            message.error("Something went wrong... Please try again later.", 5);
          }
          this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
          // this.setModalVisibility(false);
        });
    } else {
      this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
      message.error(warningmessage, 5);
    }
  };

  setModalVisibility = (status) => {
    if (status) {
      const nData = {
        branch_id: undefined,
        branch_name: "",
        fav_id: undefined,
        mandi_id: undefined,
        isEdit: false,
        mandi_name: "",
      };

      this.setState({ newData: nData });
    }

    this.setState({ isModalVisible: status });
  };

  TableColumnHeader = (props) => {
    const { title, subtitle } = props;
    return (
      <div className="column-header-container">
        <div
          style={{
            color: "white",
            fontSize: "12px",
            whiteSpace: "nowrap",
            fontWeight: "normal",
            textAlign: "left",
          }}
        >
          {title}
        </div>
        <div
          style={{
            color: "#cbdfff",
            fontSize: "11px",
            fontWeight: "normal",
            textAlign: "left",
            // subtitle && title.length < subtitle.length ? "left" : "center"
          }}
        >
          {subtitle}
        </div>
      </div>
    );
  };

  createFilters = (label) => {
    let filterData = this.state.favoriteMandiTableData;
    //#region remove duplicate objects
    let uniqueFilterData = [];

    if (
      label === "is_active" &&
      this.state.favoriteMandiTableData &&
      this.state.favoriteMandiTableData.length > 0
    ) {
      uniqueFilterData = [
        { text: "Yes", value: 1 },
        { text: "No", value: 0 },
      ];
    } else {
      filterData.map((mainItem) =>
        uniqueFilterData.filter((item) => item[label] === mainItem[label])
          .length > 0
          ? null
          : uniqueFilterData.push(mainItem)
      );

      uniqueFilterData = uniqueFilterData.map((item) => {
        return {
          text: item[label],
          value: item[label],
        };
      });

      uniqueFilterData.sort((a, b) => a.text.localeCompare(b.text));
    }

    return uniqueFilterData;
  };

  handleChange = (e, row, index, name, isPopup) => {
    e.target.name = name;

    var alphaNumericRegex = /^[a-zA-Z0-9]*$/;
    var numberRegex = /^[0-9.]+$/;
    let a;

    if (isPopup === false) {
      a = this.state.favoriteMandiTableData;

      this.setState({ favoriteMandiTableData: a });
    } else {
      a = this.state.newData;
      this.setState({ newData: a });
    }
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  handleEditChange = (row, index) => {
    this.setState({ rowData: row });
    let tempData = this.state.favoriteMandiTableData.map((item) => {

      if (item.key === row.key) {
        return {
          ...item,
          isEdit: true
        }
      } else {
        return item;
      }
    })
    this.setState({ favoriteMandiTableData: tempData });
  };

  handleCancelClick = (rowData, index) => {
    let tempData = this.state.favoriteMandiTableData.map((item) => {
      if (item.key === rowData.key) {
        return this.state.rowData
      } else {
        return item;
      }
    })
    this.setState({ favoriteMandiTableData: tempData });
  };

  handleDropdownChange = (value, row, target, index, optionlabel, isPopup) => {
    let a;

    if (isPopup === false) {
      a = this.state.favoriteMandiTableData;

      if (target === "mandi_name") {
        a[row.key].mandi_name = optionlabel;
        a[row.key].mandi_id = value;
      }
      else if (target === "branch_name") {
        a[row.key].branch_id = value;
        a[row.key].branch_name = optionlabel;
      }

      this.setState({ favoriteMandiTableData: a });
    } else {
      a = this.state.newData;

      if (target === "mandi_name") {
        a.mandi_name = optionlabel;
        a.mandi_id = value;
      }
      else if (target === "branch_name") {
        a.branch_id = value;
        a.branch_name = optionlabel;
      }

      this.setState({ newData: a });
    }
  };

  render() {
    const userTableProps = {
      pagination: false,
      size: "default",
      bordered: true,
      scroll: { x: 200, y: window.innerHeight - 250 },
    };

    const handleRoleSelect = (value, props) => {
      this.setState(
        { selectedRoleName: props.key, selectedRoleValue: value },
        () => this.getFavoriteMandiMaster()
      );
    };

    return (
      <div
        className="mandi-master-container"
        style={{
          height: window.innerHeight - 85,
          marginTop: "-45px",
        }}
      >
        <div className="top-container">
          {/*
      <div className="hub-region-filter">

      
      <span
      style={{
        fontSize: "15px",
        marginTop: "5px",
        fontWeight: "600",
        marginLeft: "30px",
      }}
    >
    Region&nbsp;
    </span>{" "}
    <Select
    disabled={!this.state.isAdd}
    showSearch
    optionFilterProp="children"
    className={"select-master-region"}
    mode="single"
    placeholder="Select Region"
    value={this.state.selectedRegionValue}
    onChange={(value, props) =>
      handleRoleSelect(value, props)
    }
  >
    {this.state.selectedbranchList !== null && this.state.selectedbranchList.length &&
      this.state.selectedbranchList.map((item, idx) => (
        <Option
          value={item.role_id}
          key={item.mandi_name}
        >
          {item.mandi_name}
        </Option>
      ))}
  </Select>

      </div>
      */}

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row-reverse",
              placeItems: "flex-end",
              marginRight: "25px",
              marginBottom: "10px",
            }}
          >
            <Search
              disabled={!this.state.isAdd}
              placeholder="Search"
              allowClear
              onChange={(e) => this.setState({ searchText: e.target.value })}
              style={{ width: "25%" }}
            />
            <Button
              className="add-master-button"
              style={{
                height: "25px",
                width: "70px",
                backgroundColor: "#0b133d",
                color: "white",
              }}
              disabled={!canUserEdit(
                this.props.userData,
                null,
                FeatureCodes.MS_FAVORITE_MANDI_EDIT
              ) || !this.state.isAdd}
              onClick={() => this.setModalVisibility(true)}
            >
              &#10010; Add
            </Button>
          </div>
        </div>

        <div>
        <div
        style={{
          marginLeft: "25px",
          marginTop: "-8px",
          fontSize: "14px",
          color: "red",
          display: "flex",
          float: "left",
        }}
      > {getUserMSRBACMessage(this.props.messageAccess,FeatureCodes.MS_FAVORITE_MANDI_EDIT)}
      </div>
        <div
          style={{
            marginRight: "20px",
            marginTop: "-8px",
            fontSize: "15px",
            color: "#0B133D",
            display: "flex",
            float: "right",
          }}
        >Total number of records:&nbsp;
          <b>{this.state.favoriteMandiTableData && this.setTableData().length}</b>
        </div>
        </div>

        <Spin
          spinning={this.state.isSpinnerOnLoad}
          size={"large"}
          tip="Loading..."
          style={{ alignSelf: "center", zIndex: 9999, marginTop: "20px" }}
        >
          <Modal
            className="add-master-modal"
            title={"Add Favorite Mandi"}
            onCancel={this.handleCancel}
            footer={false}
            visible={this.state.isModalVisible}
            destroyOnClose={true}
          >
            <div className="master-form-container">
              <Form
                layout="horizontal"
                //   onValuesChange={onFormLayoutChange}
                size={"small"}
              // onSubmit={submitTableRowData(this.state.newData)}
              >
                <div className="form-top-container1">
                  <div
                    className="checkbox-group1"
                    style={{
                      display: "inline-block",
                      width: "55%",
                      marginRight: 40,
                    }}
                  >
                    <Form.Item label="Mandi">
                      <Select
                        showSearch
                        optionFilterProp="children"
                        className="remarks-select"
                        name="mandi_name"
                        defaultValue={this.state.newData.mandi_name}
                        value={this.state.newData.mandi_name}
                        onSelect={(value, option) =>
                          this.handleDropdownChange(
                            value,
                            this.state.newData,
                            "mandi_name",
                            -0,
                            option.props.children,
                            true
                          )
                        }
                      >
                        {this.state.mandiList &&
                          this.state.mandiList.length &&
                          this.state.mandiList.map((hubItem, idx) => (
                            <Option
                              key={hubItem.mandi_id}
                              value={hubItem.mandi_id}
                            >
                              {hubItem.mandi_name}
                            </Option>
                          ))}
                      </Select>

                    </Form.Item>
                  </div>
                  <div
                    className="checkbox-group1"
                    style={{
                      display: "inline-block",
                      width: "55%",
                      marginRight: 40,
                    }}
                  >
                    <Form.Item label="Branch">
                      <Select
                        showSearch
                        optionFilterProp="children"
                        className="remarks-select"
                        name="branch_name"
                        defaultValue={this.state.newData.branch_name}
                        value={this.state.newData.branch_name}
                        onSelect={(value, option) =>
                          this.handleDropdownChange(
                            value,
                            this.state.newData,
                            "branch_name",
                            -0,
                            option.props.children,
                            true
                          )
                        }
                      >
                        {this.state.branchList &&
                          this.state.branchList.length &&
                          this.state.branchList.map((hubItem, idx) => (
                            <Option
                              key={hubItem.branch_id}
                              value={hubItem.branch_id}
                            >
                              {hubItem.branch_name}
                            </Option>
                          ))}
                      </Select>

                    </Form.Item>

                  </div>
                </div>

                <div className="form-bottom-container">
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={this.state.isSubmitted}
                      onClick={() =>
                        this.submitTableRowData(this.state.newData, 0, true)
                      }
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </Modal>


          <div className="table-container1" style={{ maxHeight: "450px", width: "99%", margin: "6px 0px" }}>
            <Table
              {...userTableProps}
              columns={[
                {
                  title: "Mandi",
                  dataIndex: "mandi_name",
                  key: "mandi_name",
                  width: 130,
                  filters: this.createFilters("mandi_name"),
                  onFilter: (value, record) => record.mandi_name.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.mandi_name.localeCompare(b.mandi_name),
                  // render: (record, rowRecord, index) => {
                  //   return (
                  //     <>
                  //       <div
                  //         style={{ marginLeft: "-15px", marginRight: "-10px" }}
                  //       >
                  //         <Select
                  //           className="remarks-select"
                  //           showSearch
                  //           optionFilterProp="children"
                  //           disabled={!rowRecord.isEdit}
                  //           name="mandi_name"
                  //           defaultValue={rowRecord.mandi_name}
                  //           value={rowRecord.mandi_name}
                  //           onSelect={(value, option) =>
                  //             this.handleDropdownChange(
                  //               value,
                  //               rowRecord,
                  //               "mandi_name",
                  //               index,
                  //               option.props.children,
                  //               false
                  //             )
                  //           }
                  //         >
                  //           {this.state.mandiList &&
                  //             this.state.mandiList.length &&
                  //             this.state.mandiList.map((hubItem, idx) => (
                  //               <Option
                  //                 key={hubItem.mandi_id}
                  //                 value={hubItem.mandi_id}
                  //               >
                  //                 {hubItem.mandi_name}
                  //               </Option>
                  //             ))}
                  //         </Select>
                  //       </div>
                  //     </>
                  //   );
                  // },
                },
                {
                  key: "branch_name",
                  title: "Branch",
                  dataIndex: "branch_name",
                  width: 130,
                  filters: this.createFilters("branch_name"),
                  onFilter: (value, record) => record.branch_name.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.branch_name.localeCompare(b.branch_name),
                  // render: (record, rowRecord, index) => {
                  //     return (
                  //       <>
                  //         <div
                  //           style={{ marginLeft: "-15px", marginRight: "-10px" }}
                  //         >
                  //           <Select
                  //             className="remarks-select"
                  //             showSearch
                  //             optionFilterProp="children"
                  //             disabled={!rowRecord.isEdit}
                  //             name="branch_name"
                  //             defaultValue={rowRecord.branch_name}
                  //             value={rowRecord.branch_name}
                  //             onSelect={(value, option) =>
                  //               this.handleDropdownChange(
                  //                 value,
                  //                 rowRecord,
                  //                 "branch_name",
                  //                 index,
                  //                 option.props.children,
                  //                 false
                  //               )
                  //             }
                  //           >
                  //             {this.state.branchList &&
                  //               this.state.branchList.length &&
                  //               this.state.branchList.map((hubItem, idx) => (
                  //                 <Option
                  //                   key={hubItem.branch_id}
                  //                   value={hubItem.branch_id}
                  //                 >
                  //                   {hubItem.branch_name}
                  //                 </Option>
                  //               ))}
                  //           </Select>
                  //         </div>
                  //       </>
                  //     );
                  //   },
                },
                // {
                //   title: "Action",
                //   dataIndex: "Submit",
                //   key: "Submit",
                //   width: 90,
                //   visibility: false,
                //   render: (record, rowRecord, index) => {
                //     return (
                //       <>
                //         <div style={{ display: "inline-flex", width: "100%" }}>
                //           <Button
                //             className={
                //               rowRecord.surplus_deficit <= 0
                //                 ? "disable-edit-btn"
                //                 : "edit-btn"
                //             }
                //             type="primary"
                //             size={"small"}
                //             style={{
                //               marginLeft: 5,
                //               alignSelf: "center",
                //               padding: "0px 0px 2px",
                //               height: 31,
                //               backgroundColor: "#20346a",
                //               color: "white",
                //               borderRadius: 15,
                //             }}
                //             block
                //             htmlType="submit"
                //             onClick={() =>
                //               rowRecord.isEdit
                //                 ? this.submitTableRowData(rowRecord, index, false)
                //                 : this.handleEditChange(rowRecord, index)
                //             }
                //           >
                //             {" "}
                //             {rowRecord.isEdit ? "Submit" : "Edit"}{" "}
                //           </Button>


                //           <Button
                //             className={!rowRecord.isEdit ? "disabledBtnClass" : "btnClass"}
                //             type="primary"
                //             size={"small"}
                //             style={{
                //               marginLeft: 5,
                //               alignSelf: "center",
                //               color: "white",
                //               borderRadius: 15,
                //               padding: "0px 0px 2px",
                //               height: 31,
                //               backgroundColor: "#20346a",
                //             }}
                //             block
                //             htmlType="submit"
                //             disabled={!rowRecord.isEdit}
                //             onClick={() => this.handleCancelClick(rowRecord, index)
                //             }
                //           >
                //             Cancel
                //         </Button>

                //         </div>
                //       </>
                //     );
                //   },
                // },
              ]}
              dataSource={this.setTableData()}
            />
          </div>

        </Spin>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
  messageAccess: selectMSMessageRBAC,
});
export default connect(mapStateToProps)(VarietyRegionMaster);
//export default VarietyRegionMaster;
