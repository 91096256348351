import React, { Component } from "react";
import "./weekly-volume.styles.css";

import { Button, Modal, Empty, message, Spin, Input, Popover,Select } from "antd";
import { Line } from "react-chartjs-2";

import CustomSelectWeekly from "../../components/custom-select-weekly/custom-select-weekly.component";
import WeeklyDisplayCard from "../../components/weekly-projection-display-card/weekly-projection-display-card.component";
import WeeklyOverrideCard from "../../components/weekly-override-card/weekly-override-card.component";
import QualityCheckDisplay from "../../components/weekly-quality-check-display/weekly-quality-check-display.component";
import CustomSelectTemporal from "../../components/custom-select-temporal/custom-select-temporal.component";

import API from "../../api";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectUserData } from "../../redux/user/user.selectors";
import { canUserEdit } from "../../auth/utils";
import { FeatureCodes } from "../../auth/data";

const { Option } = Select;
/**
 *
 * @description Parent component for displaying the weekly information tab
 * @component
 */
class WeeklyVolume extends Component {
  state = {
    isOverrideClicked: false,
    isModalVisible: false,
    isFinalModalVisible: false,
    states: [],
    varieties: [],
    weeklyData: null,
    selectedStateID: null,
    selectedVarietyID: null,
    isLoading: false,
    selectedYearValue:null,
    yearList:[],
    selectedMonthID:null,
  };

  //#region assistive methods for weekly data api

  /**
   * @function
   * @description Set the loading spinner visibility.
   * @param {boolean} status Spinner visibility status to be set
   * @memberof WeeklyVolume
   */
  setSpinnerStatus = (status) => {
    
    this.setState({ isLoading: status });
  };

  getYearList = async () => {
    this.setSpinnerStatus(true);
    console.log("year list called ",);
    API.get("/recommendations/monthly/year")
    .then((filterResponse) => {
      if (filterResponse.data.status) {
        this.setSpinnerStatus(false);
        
          this.setState({
            yearList: filterResponse.data.data,
            selectedYearValue:filterResponse.data.data[0].year_id,
          });
        console.log("year list ", this.state.yearList, filterResponse.data.data);
      
      } else {
        this.setSpinnerStatus(false);
      }
    })
    .catch((err) => {
      this.setSpinnerStatus(false);
      message.error(
        "Could not retrieve model year. Please check your network connectivity."
      );
    });
  }

  /**
   * @function
   * @description Update the screen on changing the state
   * @param {number} state_id  selected state id
   * @memberof WeeklyVolume
   */
  handleStateChange = (state_id) => {
    const selectedState = this.state.states.find(
      (item) => item.state_id === state_id
    );
    this.setState(
      {
        selectedStateID: selectedState.state_id,
        varieties: selectedState.state_varieties,
        selectedVarietyID: selectedState.state_varieties[0].variety_id,
      },
      () =>
        this.getWeeklyData(
          selectedState.state_id,
          selectedState.state_varieties[0].variety_id
        )
    );
  };

  /**
   * @function
   * @description Update the screen on changing the variety
   * @param {number} variety_id  selected variety id
   * @memberof WeeklyVolume
   */
  handleVarietyChange = (variety_id) => {
    this.setState({ selectedVarietyID: variety_id }, () =>
      this.getWeeklyData(this.state.selectedStateID, variety_id)
    );
  };

  /**
   * @function
   * @description Get the weekly prediction data for the selected state variety combination.
   * @param {number} state_id selected state id
   * @param {number} variety_id selected variety id
   * @memberof WeeklyVolume
   */
  getWeeklyData = (state_id, variety_id) => {

    // reset override button state on changing o another state variety combination.
    this.setState({ isOverrideClicked: false });

    //#region GET weekly data
    this.setSpinnerStatus(true);
    console.log("selected month id logs ",this.state.selectedMonthID)
    if(this.state.selectedMonthID === null){
        this.setState({selectedMonthID: 2})
    }

    API.get(
      `/recommendations/weekly?state_id=${state_id}&variety_id=${variety_id}&year=${this.state.selectedYearValue}&month=${this.state.selectedMonthID === null?this.monthFiltersDummy[0]:this.state.selectedMonthID}`,

    )
      .then((weeklyResponse) => {
        if (weeklyResponse.data.status) {
          this.setState({
            weeklyData: weeklyResponse.data.data,
          });
        } else {
          message.warning("Could not retrieve weekly data.Please try again.");
        }
        this.setSpinnerStatus(false);
      })
      .catch((err) => {
        this.setSpinnerStatus(false);
        message.error(
          "Could not retrieve weekly data.Please check your network connectivity."
        );
      });
    //#endregion
  };

  //#endregion

  componentDidMount() {
    //#region API to get the filter data

    this.getYearList();

    API.get("/weekly/states")
      .then((filterResponse) => {
        if (filterResponse.data.status) {
          this.setState({
            states: filterResponse.data.data,
            varieties: filterResponse.data.data[0].state_varieties,
            selectedStateID: filterResponse.data.data[0].state_id,
            selectedVarietyID:
              filterResponse.data.data[0].state_varieties[0].variety_id,
          });

          this.getWeeklyData(
            filterResponse.data.data[0].state_id,
            filterResponse.data.data[0].state_varieties[0].variety_id
          );
        } else {
          message.warning(
            "Could not retrieve weekly filters.Please try again."
          );
        }
      })
      .catch((err) => {
        message.error(
          "Could not retrieve weekly filters. Please check your network connection."
        );
      });
    //#endregion
  }


  render() {
    const chartOptions = {
      responsive: false,
      spanGaps: true,
      maintainAspectRatio: false,
      legend: {
        display: true,
      },
      tooltips: {
        callbacks: {
          label: (tooltipItem, data) => {
            return "";
          },
          afterLabel: (tooltipItem, data) => {
            var dataset = data["datasets"][0];
            var price = dataset["data"][tooltipItem["index"]];
            return `Projected Price: ${price} ₹/MT `;
          },
        },
        label: true,
        intersect: false,
        mode: "nearest",
        backgroundColor: "white",
        titleFontColor: "#313131",
        borderColor: "#9d9d9d",
        borderWidth: 1,
        bodyFontColor: "#575757",
        displayColors: false,
        scales: {
          xAxes: [
            {
              id: "weeks",
              ticks: {
                maxRotation: 0,
              },
              scaleLabel: {
                display: true,
                labelString: "Weeks",
              },
            },
          ],
          yAxes: [
            {
              id: "price",
              //   ticks: { beginAtZero: true },
              scaleLabel: {
                display: true,
                labelString: "Projected Price",
              },
            },
          ],
        },
      },
    };

    //#region function definitions

    /**
     *@description Finalise the override.disables the table and override buttons
     *@memberof WeeklyVolume
     */
    const onFinalise = () => {

      const dataToPut = {
        pk_id: this.state.weeklyData.pk_id,
      };

      API.put("/recommendations/weekly", dataToPut)
        .then((finaliseResponse) => {
          if (finaliseResponse.data.status) {
            setConfirmationField(1);
            this.setState({ isFinalModalVisible: false });
          } else {
            message.warning(
              "Could not finalise the override. Please try again."
            );
          }
        })
        .catch((err) => {
          message.error(
            "Could not finalise the override. Please check your network connectivity."
          );
        });
    };

    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const setFilterName = (monthCount, runCount) => {
      return months[monthCount - 1] + "-" + runCount;
    };

  const setFilters = () => {
    const modifiedFilters = this.state.filters.map((item) => {
      return {
        ...item,
        key: item.month + "_" + item.run_cnt,
        name: setFilterName(item.month, item.run_cnt),
      };
    });
    return modifiedFilters;
  };
    
  const handleYearSelect = (value, props) => {
    this.setState(
      { selectedYearValue: value },
      () => this.getWeeklyData(this.state.selectedStateID,this.state.selectedVarietyID)
    );
  };

    /**
     * @description changes the confirmation state locally.
     * @param {number} value 0 for enabling the editing. 1 for confirming; ie disabling the fields.
     * @memberof WeeklyVolume
     */
    const setConfirmationField = (value) => {
      this.setState((prevState) => ({
        ...prevState,
        weeklyData: { ...prevState.weeklyData, confirmation: value },
      }));
    };

    /**
     *@description Submit the overriden allocation data.
     *@memberof WeeklyVolume
     */
    const onSubmitClicked = () => {
      //#region post api to submit override data.

      const validatePost = () => {
        if (
          this.state.weeklyData.final_allocation.user_w1_allocation +
          this.state.weeklyData.final_allocation.user_w2_allocation +
          this.state.weeklyData.final_allocation.user_w3_allocation !==
          100
        ) {
          message.warning(
            "The final allocation percentages should sum up to exactly 100% !"
          );
          return false;
        }

        if (
          !this.state.weeklyData.final_allocation.remark ||
          !this.state.weeklyData.final_allocation.remark.trim() ||
          this.state.weeklyData.final_allocation.remark.length === 0
        ) {
          message.warning("Please enter the Business QC log !");
          return false;
        }

        return true;
      };



      const dataToSend = {
        state_id: this.state.selectedStateID,
        variety_id: this.state.selectedVarietyID,
        run_date: this.state.weeklyData.week_run,
        user_w1_allocation: this.state.weeklyData.final_allocation
          .user_w1_allocation,
        user_w2_allocation: this.state.weeklyData.final_allocation
          .user_w2_allocation,
        user_w3_allocation: this.state.weeklyData.final_allocation
          .user_w3_allocation,

        remark: this.state.weeklyData.final_allocation.remark,
        confirmation: 0,
      };

      if (validatePost()) {
        this.setSpinnerStatus(true);
        API.post("/recommendations/weekly", dataToSend

        )
          .then((overrideResponse) => {
            if (overrideResponse.data.status) {
              //data overriden. change in local state.
              this.setState((prevState) => ({
                ...prevState,
                weeklyData: {
                  ...prevState.weeklyData,
                  final_allocation: {
                    ...prevState.weeklyData.final_allocation,
                    prev_w1_allocation: this.state.weeklyData.final_allocation
                      .user_w1_allocation,
                    prev_w2_allocation: this.state.weeklyData.final_allocation
                      .user_w2_allocation,
                    prev_w3_allocation: this.state.weeklyData.final_allocation
                      .user_w3_allocation,
                  },
                  pk_id: overrideResponse.data.data.pk_id,
                },
              }));

              this.setState({ isOverrideClicked: false });
              message.success(
                "Final allocation has been overriden successfully."
              );
              this.setSpinnerStatus(false);
            } else {
              this.setSpinnerStatus(false);
              message.warning(
                "Could not override the allocation.Please try again."
              );
            }
          })
          .catch((err) => {
            this.setSpinnerStatus(false);
            message.error(
              "Could not override the allocation. Please check your network connectivity."
            );

          });
      }

      //#endregion
    };

    /**
     *@description Cancel the edited data and reset to previous values.
     *@memberof WeeklyVolume
     */
    const onCancelClicked = () => {
      //reset data.
      this.setState((prevState) => ({
        ...prevState,
        weeklyData: {
          ...prevState.weeklyData,
          final_allocation: {
            ...prevState.weeklyData.final_allocation,
            user_w1_allocation: this.state.weeklyData.final_allocation
              .prev_w1_allocation,
            user_w2_allocation: this.state.weeklyData.final_allocation
              .prev_w2_allocation,
            user_w3_allocation: this.state.weeklyData.final_allocation
              .prev_w3_allocation,
            // remark: "",
          },
        },
      }));

      this.setState({ isOverrideClicked: false });
    };

    /**
     *@description Display the submit and cancel button. Enables the fields for input.
     *@memberof WeeklyVolume
     */
    const onOverrideClicked = () => {
      this.setState({ isOverrideClicked: true });
    };

    /**
     * @description Display/Hides the QC modal
     * @param {boolean} status QC modal visibility: setting true will display the modal.
     * @memberof WeeklyVolume
     */
    const setModalVisibility = (status) => {
      this.setState({ isModalVisible: status });
    };

    /**
     * @description Display/Hides the confirmation modal
     * @param {boolean} status Confirmation modal visibility: setting true will display the modal.
     * @memberof WeeklyVolume
     */
    const setFinalModalVisibility = (status) => {
      this.setState({ isFinalModalVisible: status });
    };

    /**
     * @description Set the color for the total score display round image
     * @param {number} score the total confidence score
     * @returns color code corresponding to the total confidence score
     * @memberof WeeklyVolume
     */
    const setConfidenceColor = (score) => {
      const minScore = 0,
        middleScore = 1,
        maxScore = 2;

      if (minScore < score && score <= middleScore) return "#73de73";
      else if (middleScore < score && score <= maxScore) return "#fbfa71";
      else if (score > maxScore) return "#F44336";
      else return "white";
    };

    /**
     * @description Returns a string mentioning the duration of model run.
     * @param {Date} runDate starting date
     * @param {Date} endDate ending date
     * @returns string to display the duration in the UI
     * @memberof WeeklyVolume
     */
    const setDuration = (runDate, endDate) => {
      //use the bottom snippet if needed later
      //const startDate = new Date(runDate).toLocaleDateString();
      //const stopDate = new Date(endDate).toLocaleDateString();
      // return `From ${runDate} to ${endDate}`;
      return (
        <>
          <div style={{ textAlign: "center" }}>From</div>
          <div style={{ textAlign: "center" }}>{`${runDate}`}</div>
          <div style={{ textAlign: "center" }}>To</div>
          <div style={{ textAlign: "center" }}>{`${endDate}`}</div>
        </>
      );
    };

    const monthFiltersDummy = [
      {
        id: 2,
        name: "Feb",
      },
      {
        id: 3,
        name: "Mar",
      },
      {
        id: 4,
        name: "Apr",
      },
      {
        id: 5,
        name: "May",
      },
      {
        id: 6,
        name: "June",
      },
      {
        id: 7,
        name: "Post Season",
      },
    ];

    /**
     * @description Update the local state on changing the override values
     * @param {any} value changed value from the input field
     * @param {string} type key of the weekly data object to be modified in the local state
     * @memberof WeeklyVolume
     */
    const onWeeklyPercentageModified = (value, type) => {
      this.setState((prevState) => ({
        ...prevState,
        weeklyData: {
          ...prevState.weeklyData,
          final_allocation: {
            ...prevState.weeklyData.final_allocation,
            [type]: value,
          },
        },
      }));
    };

    /**
     *
     * @description Sum the entered values and return the total percentage.
     * @returns sum total allocation percentage at the moment
     * @memberof WeeklyVolume
     */
    const setTotalAllocationPercentage = () => {
      return (
        this.state.weeklyData.final_allocation.user_w1_allocation +
        this.state.weeklyData.final_allocation.user_w2_allocation +
        this.state.weeklyData.final_allocation.user_w3_allocation
      );
    };

    /**
     * @description Sum the allocation quantities and return the total
     * @returns total allocated quantity at the moment
     * @memberof WeeklyVolume
     */
    const setTotalAllocationQuantity = () => {
      return Math.round(
        (this.state.weeklyData.target_qty *
          (this.state.weeklyData.final_allocation.user_w1_allocation +
            this.state.weeklyData.final_allocation.user_w2_allocation +
            this.state.weeklyData.final_allocation.user_w3_allocation)) /
        100
      );
    };

    /**
     * @description Sum of each weeks' budget
     * @returns Total weekly budget at the moment
     * @memberof WeeklyVolume
     */
    const setTotalAllocationBudget = () => {
      return Math.round(
        this.state.weeklyData.predictions.w1.w1_price *
        ((this.state.weeklyData.target_qty *
          this.state.weeklyData.final_allocation.user_w1_allocation) /
          100) +
        this.state.weeklyData.predictions.w2.w2_price *
        ((this.state.weeklyData.target_qty *
          this.state.weeklyData.final_allocation.user_w2_allocation) /
          100) +
        this.state.weeklyData.predictions.w3.w3_price *
        ((this.state.weeklyData.target_qty *
          this.state.weeklyData.final_allocation.user_w3_allocation) /
          100)
      );
    };

    const onMonthSelected = (month_id) => {
      this.setState({selectedMonthID :month_id});
      this.getWeeklyData(this.state.selectedStateID,this.state.selectedVarietyID);
    };

    //#endregion

    return (
      <Spin spinning={this.state.isLoading}>
        <div className="weekly-volume-container">
          <Modal
            className="temporal-modal"
            title={<div style={{ color: "white" }}>Confirmation</div>}
            visible={this.state.isFinalModalVisible}
            onOk={() => onFinalise()}
            onCancel={() => setFinalModalVisibility(false)}
            cancelButtonProps={{
              style: { backgroundColor: "#cbcbcb !important" },
            }}
          >
            <div style={{ color: "black" }}>
              You are about to submit the overrides to schedule for weekly model
              run. This will be the final submission. After this, you will not
              be able to make any further changes. Are you sure you want to
              submit ?
            </div>
          </Modal>
          <div className="top-container">
            <div className="filters">
              <div className="temporal-select">
                <div className="temporal-select-title">Select State</div>
                <CustomSelectWeekly
                  list={this.state.states.map((item) => {
                    return {
                      name: item.state_name,
                      id: item.state_id,
                    };
                  })}
                  value={this.state.selectedStateID}
                  onSelect={this.handleStateChange}
                  placeholder="Select State"
                />
              </div>
              <div className="temporal-select">
                <div className="temporal-select-title">Select Variety</div>
                <CustomSelectWeekly
                  list={this.state.varieties.map((item) => {
                    return {
                      name: item.variety_name,
                      id: item.variety_id,
                    };
                  })}
                  value={this.state.selectedVarietyID}
                  onSelect={this.handleVarietyChange}
                  placeholder="Select Variety"
                />
              </div>
{/*}
              <div className="temporal-select">
              <div className="temporal-select-title">Model Run</div>
              <CustomSelectWeekly
              list={monthFiltersDummy.map((item) => {
                return {
                  name: item.name,
                  id: item.id,
                };
              })}
              value={this.state.selectedMonthID}
              onSelect={onMonthSelected}
            //   placeholder={`Select ${setSelectText()}`}
            />
             
                </div>
 

              <div className="temporal-select" style={{marginLeft:"10px"}}>
            <div className="temporal-select-title">Model Year</div>
            <Select
            onChange={(value, props) =>
              handleYearSelect(value)
            }
            value={this.state.selectedYearValue}
          >
          {console.log("year list map ", this.state.yearList)}
            {this.state.yearList.map((item, idx) => (
              <Option value={item.year_id} key={item.year_id}>
                {item.year_id}
              </Option>
            ))}
          </Select>
            </div>*/}
            </div>
            <div className="total-target-container">
              <div className="title">Total Buying Target</div>
              <div className="info">
                {this.state.weeklyData ? (
                  this.state.weeklyData.target_qty ? (
                    this.state.weeklyData.target_qty + " MT"
                  ) : (
                    <div>&nbsp;&nbsp;-&nbsp;&nbsp;</div>
                  )
                ) : (
                  "Loading.."
                )}
              </div>
            </div>
          </div>
          {this.state.weeklyData ? (
            <>
              <Modal
                width={1350}
                className="user-management-modal weekly-modal"
                title={
                  this.state.selectedStateID && this.state.selectedVarietyID
                    ? `QC scores in ${this.state.states.find(
                      (item) => item.state_id === this.state.selectedStateID
                    ).state_name
                    } for ${this.state.varieties.find(
                      (item) =>
                        item.variety_id === this.state.selectedVarietyID
                    ).variety_name
                    }`
                    : "QC details"
                }
                visible={this.state.isModalVisible}
                destroyOnClose={true}
                onOk={() => {
                  setModalVisibility(false);
                }}
                onCancel={() => setModalVisibility(false)}
              >
                <QualityCheckDisplay
                  setModalVisibility={setModalVisibility}
                  setConfidenceColor={setConfidenceColor}
                  scores={this.state.weeklyData.confidence}
                  stateID={this.state.weeklyData.state_id}
                  varietyID={this.state.weeklyData.variety_id}
                  weekRun={this.state.weeklyData.week_run}
                />
              </Modal>

              <div className="middle-container">
                <div className="projection-container">
                  <div className="projection-period">
                    {setDuration(
                      this.state.weeklyData.week_run,
                      this.state.weeklyData.end_date
                    )}
                  </div>
                  <div className="row-titles">
                    <div>Projected Prices</div>
                    <div>Projected Price band</div>
                    <Popover content="Model Recommended Weekly Allocation">
                      <div>Model Allocation</div>
                    </Popover>
                  </div>
                  <WeeklyDisplayCard
                    title="Week 1"
                    price={this.state.weeklyData.predictions["w1"].w1_price}
                    minPrice={
                      this.state.weeklyData.predictions["w1"].w1_min_price
                    }
                    maxPrice={
                      this.state.weeklyData.predictions["w1"].w1_max_price
                    }
                    allocationPercentage={
                      this.state.weeklyData.predictions["w1"].w1_allocation
                    }
                  />
                  <WeeklyDisplayCard
                    title="Week 2"
                    price={this.state.weeklyData.predictions["w2"].w2_price}
                    minPrice={
                      this.state.weeklyData.predictions["w2"].w2_min_price
                    }
                    maxPrice={
                      this.state.weeklyData.predictions["w2"].w2_max_price
                    }
                    allocationPercentage={
                      this.state.weeklyData.predictions["w2"].w2_allocation
                    }
                  />
                  <WeeklyDisplayCard
                    title="Week 3"
                    price={this.state.weeklyData.predictions["w3"].w3_price}
                    minPrice={
                      this.state.weeklyData.predictions["w3"].w3_min_price
                    }
                    maxPrice={
                      this.state.weeklyData.predictions["w3"].w3_max_price
                    }
                    allocationPercentage={
                      this.state.weeklyData.predictions["w3"].w3_allocation
                    }
                  />
                </div>
                <div className="qc-details">
                  <div className="qc-title">Confidence Score</div>
                  {this.state.weeklyData.confidence.total_confidence ? (
                    <div
                      className="round"
                      style={{
                        backgroundColor: `${setConfidenceColor(
                          this.state.weeklyData.confidence.total_confidence
                        )}`,
                      }}
                    >
                      <div className="score">
                        {this.state.weeklyData.confidence.total_confidence}
                      </div>
                    </div>
                  ) : (
                    <div className="no-score">No QC score available</div>
                  )}

                  <Button
                    className={`${this.state.weeklyData.confidence.total_confidence
                      ? ""
                      : "disabled-button"
                      }`}
                    disabled={
                      !this.state.weeklyData.confidence.total_confidence
                    }
                    onClick={() => setModalVisibility(true)}
                  >
                    Click for QC details
                  </Button>
                </div>
              </div>
              <div className="graph-container">
                <div className="graph-info">
                  <div className="graph-title">Projected Trend Graph</div>
                  <div className="graph">
                    <div>Projected Price (₹/MT)</div>
                    <Line
                      id="allocation-line-chart"
                      height={230}
                      width={1000}
                      // ref="weekly-line-chart"
                      options={chartOptions}
                      data={{
                        labels: ["W0", "W1", "W2", "W3"],
                        datasets: [
                          {
                            label: "Projected Price",
                            fill: false,
                            lineTension: 0.1,
                            backgroundColor: "rgba(75,192,192,0.4)",
                            borderColor: "rgba(75,192,192,1)",
                            borderCapStyle: "butt",
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: "miter",
                            pointBorderColor: "rgba(75,192,192,1)",
                            pointBackgroundColor: "#fff",
                            pointBorderWidth: 1,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: "rgba(75,192,192,1)",
                            pointHoverBorderColor: "rgba(220,220,220,1)",
                            pointHoverBorderWidth: 2,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            data: [
                              this.state.weeklyData.predictions.w0.w0_price,
                              this.state.weeklyData.predictions.w1.w1_price,
                              this.state.weeklyData.predictions.w2.w2_price,
                              this.state.weeklyData.predictions.w3.w3_price,
                            ],
                          },
                        ],
                      }}
                    />
                  </div>
                </div>
              </div>
              {canUserEdit(
                this.props.userData,
                null,
                FeatureCodes.WEEKLY_MODEL_EDIT
              ) ? (
                this.state.weeklyData.target_qty ? (
                  <div className="bottom-container">
                    <div className="override-container">
                      {this.state.isOverrideClicked ? (
                        <div className="button-container">
                          <div
                            className="cancel-button"
                            onClick={() => onCancelClicked()}
                          >
                            Cancel
                          </div>
                          <div
                            className="submit-button"
                            onClick={() => onSubmitClicked()}
                          >
                            Submit
                          </div>
                        </div>
                      ) : (
                        <div className="button-container-override">
                          <div
                            // className="finalise-button"
                            className={`${this.state.weeklyData.confirmation
                              ? "finalise-button disabled-button"
                              : "finalise-button"
                              }`}
                            onClick={() => setFinalModalVisibility(true)}
                          >
                            {this.state.weeklyData.confirmation
                              ? "Override Finalised"
                              : "Finalise Override"}
                          </div>
                          <div
                            // className="override-button"
                            className={`${this.state.weeklyData.confirmation
                              ? "override-button disabled-button"
                              : "override-button"
                              }`}
                            onClick={() => onOverrideClicked()}
                          >
                            Override Allocation
                          </div>
                        </div>
                      )}
                      <div className="override-details-data">
                        <div className="override-details">
                          <div className="row-titles">
                            <Popover content="Final Allocation to be implemented (%)">
                              <div>Final Allocation Percentage (%)</div>
                            </Popover>
                            <Popover content="Final Allocation to be implemented (MT)">
                              <div>Final Allocation Quantity (MT)</div>
                            </Popover>
                            <div>Weekly Budget (₹)</div>
                          </div>

                          <WeeklyOverrideCard
                            disabled={!this.state.isOverrideClicked}
                            title="Week 1"
                            type="user_w1_allocation"
                            onValueChanged={onWeeklyPercentageModified}
                            allocationPercentage={
                              this.state.weeklyData.final_allocation
                                .user_w1_allocation
                            }
                            allocationQuantity={Math.round(
                              (this.state.weeklyData.target_qty *
                                this.state.weeklyData.final_allocation
                                  .user_w1_allocation) /
                              100
                            )}
                            allocationBudget={Math.round(
                              (this.state.weeklyData.predictions.w1.w1_price *
                                (this.state.weeklyData.target_qty *
                                  this.state.weeklyData.final_allocation
                                    .user_w1_allocation)) /
                              100
                            )}
                            reference={
                              this.state.weeklyData.final_allocation
                                .w1_allocation
                            }
                            current={
                              this.state.weeklyData.final_allocation
                                .user_w1_allocation
                            }
                          />
                          <WeeklyOverrideCard
                            disabled={!this.state.isOverrideClicked}
                            title="Week 2"
                            type="user_w2_allocation"
                            onValueChanged={onWeeklyPercentageModified}
                            allocationPercentage={
                              this.state.weeklyData.final_allocation
                                .user_w2_allocation
                            }
                            allocationQuantity={Math.round(
                              (this.state.weeklyData.target_qty *
                                this.state.weeklyData.final_allocation
                                  .user_w2_allocation) /
                              100
                            )}
                            allocationBudget={Math.round(
                              (this.state.weeklyData.predictions.w2.w2_price *
                                (this.state.weeklyData.target_qty *
                                  this.state.weeklyData.final_allocation
                                    .user_w2_allocation)) /
                              100
                            )}
                            reference={
                              this.state.weeklyData.final_allocation
                                .w2_allocation
                            }
                            current={
                              this.state.weeklyData.final_allocation
                                .user_w2_allocation
                            }
                          />
                          <WeeklyOverrideCard
                            disabled={!this.state.isOverrideClicked}
                            title="Week 3"
                            type="user_w3_allocation"
                            onValueChanged={onWeeklyPercentageModified}
                            allocationPercentage={
                              this.state.weeklyData.final_allocation
                                .user_w3_allocation
                            }
                            allocationQuantity={Math.round(
                              (this.state.weeklyData.target_qty *
                                this.state.weeklyData.final_allocation
                                  .user_w3_allocation) /
                              100
                            )}
                            allocationBudget={Math.round(
                              (this.state.weeklyData.predictions.w3.w3_price *
                                (this.state.weeklyData.target_qty *
                                  this.state.weeklyData.final_allocation
                                    .user_w3_allocation)) /
                              100
                            )}
                            reference={
                              this.state.weeklyData.final_allocation
                                .w3_allocation
                            }
                            current={
                              this.state.weeklyData.final_allocation
                                .user_w3_allocation
                            }
                          />
                          {/* this is for displaying total */}
                          <WeeklyOverrideCard
                            isTotal
                            disabled
                            title="Total"
                            allocationPercentage={setTotalAllocationPercentage()}
                            allocationQuantity={setTotalAllocationQuantity()}
                            allocationBudget={setTotalAllocationBudget()}
                          />
                        </div>
                        <div className="error-log-container">
                          <div className="error-log-title">Business QC Log</div>
                          <div className="error-log-entry">
                            <Input.TextArea
                              allowClear
                              autoSize={{ minRows: 1, maxRows: 5 }}
                              key="remark"
                              onChange={(e) =>
                                onWeeklyPercentageModified(
                                  e.target.value,
                                  "remark"
                                )
                              }
                              disabled={!this.state.isOverrideClicked}
                              value={
                                this.state.weeklyData.final_allocation.remark
                              }
                              placeholder="Please enter the error log"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <Empty
                    className="empty-box"
                    image={Empty.PRESENTED_IMAGE_DEFAULT}
                    description={
                      <span>
                        The demand has been met for this state-variety
                        combination.
                      </span>
                    }
                  />
                )
              ) : (
                <Empty
                  className="empty-box"
                  image={Empty.PRESENTED_IMAGE_DEFAULT}
                  description={
                    <span>
                      You do not have permission to edit the weekly data.
                    </span>
                  }
                />
              )}
            </>
          ) : (
            // if weekly data is empty
            <Empty
              className="empty-box"
              image={Empty.PRESENTED_IMAGE_DEFAULT}
              description={<span>No Data available.</span>}
            />
          )}
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
});

export default connect(mapStateToProps, null)(WeeklyVolume);
