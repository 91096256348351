import React, { Component } from "react";
import "./arrival-category-list-item-farmer.styles.css";
import moment from "moment";

import priceIcon from "../../assets/Gr_Price.svg";
import quantityIcon from "../../assets/Gr_Quantity.svg";
import editIcon from "../../assets/edit.svg";
import closeIcon from "../../assets/close.svg";

import ArrivalCardValueSlider from "../arrival-card-value-slider/arrival-card-value-slider.component";
import QualityValueSlider from "../quality-value-slider/quality-value-slider.component";
import NewVarietySelect from "../new-variety-select/new-variety-select.component";

import { Card, message, Button, Modal, Input } from "antd";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectUserData } from "../../redux/user/user.selectors";

import {
  setBranches,
  setRegions,
  setHubs,
  setMandis,
  setBranchID,
  setRegionID,
  setHubID,
  setMandiID,
} from "../../redux/arrival-entry/arrival-entry.actions";
import {
  selectSummaryScreenVisibility,
  selectBranches,
  selectRegions,
  selectHubs,
  selectMandis,
  selectBranchID,
  selectRegionID,
  selectHubID,
  selectMandiID,
} from "../../redux/arrival-entry/arrival-entry.selectors";

import { selectCurrentArrivalScreen } from "../../redux/map/map.selectors";

import API from "../../api";

import { DeleteDataFromCard } from "../../pages/arrivals-entry/arrivals-entry.page";

const { TextArea } = Input;

/**
 *
 * @description Single card item in Arrivals entry page for Farmer.
 * @component
 * @extends {Component}
 */
class ArrivalCategoryListItemFarmer extends Component {
  modalCardSliderProps = {
    image: priceIcon,
    title: "Naked grain",
    unitText: "₹/Qntl",
  };
  quantityCardSliderProps = {
    image: quantityIcon,
    title: "Arrivals Quantity",
    unitText: "Qntl",
  };
  state = {
    isEnable: true,
    main: {
      Price: "",
      MinPrice: "",
      MaxPrice: "",
      PriceType: "WLC",
      ArrivalsQuantity: "",
      Quality: "Average",
      Remarks: "",
    },
  };

  componentDidMount() {
    let isData =
      this.props.cardData !== null
        ? this.props.cardData.length !== 0
          ? true
          : false
        : false;
    if (isData && this.state.isEnable) {
      this.setState({ isEnable: false });
      isData = false;
    }
  }

  render() {
    const {
      varietyName,
      varietyMinRange,
      varietyMaxRange,
      varietyID,
      branchID,
      regionID,
      hubID,
      mandiID,
      currentScreen,
      cardData,
      remainingVarietyList,
      onSelectNewVariety,
      getPreviousData,
    } = this.props;

    //#region  previous data handling

    const isPreviousDataPresent =
      cardData !== null ? (cardData.length !== 0 ? true : false) : false;

    //#endregion

    /**
     * @description Function to handle submission click which validate and post arrival entry.
     * @memberof ArrivalCategoryListItemFarmer
     */
    const success = () => {

      const getPriceType = (currentScreen) => {
        switch (currentScreen) {
          case "Mandi":
            return "Modal Price";
          case "FOR":
            return "TLC";
          case "Farmer":
            return "Naked Grain Price";
          default:
            break;
        }
      };

      const postData = {
        arrival_entries: [
          {
            crop_arrival_id:(this.props.cardData!== undefined && this.props.cardData!== null&& this.props.cardData!== [] && this.props.cardData.length !== 0)?this.props.cardData[0].crop_arrival_id:null,
            branch_id: parseInt(branchID),
            region_id: parseInt(regionID),
            hub_id: parseInt(hubID),
            mandi_id: parseInt(mandiID),
            //Arrival date
            arrival_date: moment().format("YYYY-MM-DD"),
            arrival_type: currentScreen,
            variety_id: parseInt(varietyID),
            price_type: getPriceType(currentScreen),
            price: parseInt(this.state.main.Price),
            min_price: null,
            max_price: null,
            arrivals_quantity: parseInt(this.state.main.ArrivalsQuantity),
            quality: this.state.main.Quality,
            good_quality: null,
            average_quality: null,
            poor_quality: null,
            remark: this.state.main.Remarks,
          },
        ],
      };


      const checkForValidation = () => {
        if (isNaN(postData.arrival_entries[0].variety_id)) {
          Modal.error({ content: "Please select a variety !" });
          return false;
        }

        if (!this.state.main.Price) {
          Modal.error({ content: "Please enter the naked grain price !" });
          return false;
        }

        if (!this.state.main.Quality) {
          Modal.error({
            content: "Please select the quality !",
          });
          return false;
        }
        return true;
      };

      if (checkForValidation()) {
        this.setState({ isEnable: false });
        API.post("/arrivals", postData)
          .then((response) => {
            if (response.data.status) {
              message.success("Data saved !", 2.5);
              getPreviousData();
            } else {
              message.error("Please try again");
              this.setState({ isEnable: true });
            }
          })
          .catch((err) => {
            this.setState({ isEnable: true });
          });
      }
    };

    /**
     *
     * @description Change the value for [typeVariety]:{[namevariety] :'value'} in the parent state.
     * @param {string} typeVariety Parent object in state (main/competitor)
     * @param {string} nameVariety Key in parent object
     * @param {string} val Value of curesponding key
     * @memberof ArrivalCategoryListItemFarmer
     */
    const setParentState = (typeVariety, nameVariety, val) => {
      this.setState((prevState) => ({
        ...prevState,
        [typeVariety]: { ...prevState[typeVariety], [nameVariety]: val },
      }));
    };

    /**
     * @description Enable card item for editing.
     * @memberof ArrivalCategoryListItemFarmer
     */
    const onEditClicked = () => {
      const data =
        cardData.length !== 0
          ? {
            main: {
              Price: cardData[0].price,
              // MinPrice: cardData[0].min_price,
              // MaxPrice: cardData[0].max_price,
              ArrivalsQuantity: cardData[0].quantity,
              Quality: cardData[0].quality,
              Remarks: cardData[0].remark,
            },
          }
          : this.state;

      this.setState((prevState) => ({
        ...prevState,
        isEnable: true,
        main: data.main,
      }));
    };

    /**
     * @description Clears the existing data from the card.
     * @memberof ArrivalCategoryListItemMandi
     */
    const onDeleteClicked = () => {
      setModalVisibility(false);
      // call delete data api, then
      DeleteDataFromCard(
        branchID,
        hubID,
        null,
        varietyID,
        false,
        false,
        getPreviousData,
        (status) => { }
      );
    };

    const setModalVisibility = (status) => {
      this.setState({ isModalVisible: status });
    };

    return (
      <>
        <Modal
          className="temporal-modal"
          title={<div style={{ color: "white" }}>Confirmation</div>}
          visible={this.state.isModalVisible}
          onOk={() => onDeleteClicked()}
          onCancel={() => setModalVisibility(false)}
          cancelButtonProps={{
            style: { backgroundColor: "#cbcbcb !important" },
          }}
        >
          <div style={{ color: "black" }}>
            You are about to clear the entries for this variety for today. Are
            you sure you want to submit ?
          </div>
        </Modal>
        {isPreviousDataPresent && (
          <div className="main-card-container">
            <div className="arrival-card-title">
              <div>{varietyName}</div>
              {this.state.isEnable ? null : (
                <div className="button-container">
                  <div
                    className="edit-button"
                    style={{
                      color: "#d7443e",
                      // opacity: "0",
                      // pointerEvents: "none",
                    }}
                    onClick={() => setModalVisibility(true)}
                  >
                    <img alt="delete" className="edit-icon" src={closeIcon} />
                    Delete
                  </div>
                  <div className="edit-button" onClick={() => onEditClicked()}>
                    <img alt="edit" className="edit-icon" src={editIcon} />
                    Edit
                  </div>
                </div>
              )}
            </div>

            <div
              className={`arrival-card-container ${this.state.isEnable
                ? "arrival-card-container"
                : "arrival-card-container-disabled"
                }`}
            >
              <Card>
                <ArrivalCardValueSlider
                  minRange={varietyMinRange}
                  maxRange={varietyMaxRange}
                  defaultValue={isPreviousDataPresent ? cardData[0].price : ""}
                  typeVariety="main"
                  nameVariety="Price"
                  setParentState={setParentState}
                  {...this.modalCardSliderProps}
                />

                <ArrivalCardValueSlider
                  defaultValue={
                    isPreviousDataPresent ? cardData[0].quantity : ""
                  }
                  typeVariety="main"
                  nameVariety="ArrivalsQuantity"
                  setParentState={setParentState}
                  {...this.quantityCardSliderProps}
                />
                <QualityValueSlider
                  quality={
                    isPreviousDataPresent ? cardData[0].quality : "Average"
                  }
                  qualityGood={
                    isPreviousDataPresent ? cardData[0].good_quality : 0
                  }
                  qualityAverage={
                    isPreviousDataPresent ? cardData[0].average_quality : 100
                  }
                  qualityPoor={
                    isPreviousDataPresent ? cardData[0].poor_quality : 0
                  }
                  setParentState={setParentState}
                  typeVariety="main"
                  nameVariety="Quality"
                  isDropdown
                />
                <div className="textarea-container">
                  Remarks
                  <TextArea
                    rows={4}
                    autoSize={{ minRows: 4, maxRows: 4 }}
                    showCount
                    maxLength={100}
                    placeholder="Enter your remarks..."
                    defaultValue={
                      isPreviousDataPresent ? cardData[0].remark : ""
                    }
                    onChange={(e) => {
                      e.persist();
                      this.setState((prevState) => ({
                        ...prevState,
                        main: {
                          ...prevState.main,
                          Remarks: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </Card>
              <Button onClick={success}>SUBMIT</Button>
            </div>
          </div>
        )}
        {!isPreviousDataPresent && (
          <div className="main-card-container">
            <div className="arrival-card-title">
              {varietyName ? (
                <div>{varietyName}</div>
              ) : (
                <NewVarietySelect
                  onSelectNewVariety={onSelectNewVariety}
                  list={remainingVarietyList}
                  tempVarietyID={varietyID}
                />
              )}
              {this.state.isEnable ? null : (
                <div className="button-container">
                  <div
                    className="edit-button"
                    style={{ color: "#d7443e" }}
                    onClick={() => setModalVisibility(true)}
                  >
                    <img alt="delete" className="edit-icon" src={closeIcon} />
                    Delete
                  </div>
                  <div className="edit-button" onClick={() => onEditClicked()}>
                    <img alt="edit" className="edit-icon" src={editIcon} />
                    Edit
                  </div>
                </div>
              )}
            </div>

            <div
              className={`arrival-card-container ${this.state.isEnable
                ? "arrival-card-container"
                : "arrival-card-container-disabled"
                }`}
            >
              <Card>
                <ArrivalCardValueSlider
                  minRange={varietyMinRange}
                  maxRange={varietyMaxRange}
                  defaultValue={""}
                  typeVariety="main"
                  nameVariety="Price"
                  setParentState={setParentState}
                  {...this.modalCardSliderProps}
                />

                <ArrivalCardValueSlider
                  defaultValue={""}
                  typeVariety="main"
                  nameVariety="ArrivalsQuantity"
                  setParentState={setParentState}
                  {...this.quantityCardSliderProps}
                />
                <QualityValueSlider
                  quality={"Average"}
                  qualityGood={0}
                  qualityAverage={100}
                  qualityPoor={0}
                  setParentState={setParentState}
                  typeVariety="main"
                  nameVariety="Quality"
                  isDropdown
                />
                <div className="textarea-container">
                  Remarks
                  <TextArea
                    rows={4}
                    autoSize={{ minRows: 4, maxRows: 4 }}
                    showCount
                    maxLength={100}
                    placeholder="Enter your remarks..."
                    value={this.state.main.Remarks}
                    onChange={(e) => {
                      e.persist();
                      this.setState(
                        (prevState) => ({
                          ...prevState,
                          main: {
                            ...prevState.main,
                            Remarks: e.target.value,
                          },
                        }),
                        () => console.log("Remarks: ", this.state.main.Remarks)
                      );
                    }}
                  />
                </div>
              </Card>
              <Button onClick={success}>SUBMIT</Button>
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setBranches: (branches) => dispatch(setBranches(branches)),
    setRegions: (regions) => dispatch(setRegions(regions)),
    setHubs: (hubs) => dispatch(setHubs(hubs)),
    setMandis: (mandis) => dispatch(setMandis(mandis)),
    setBranchID: (id) => dispatch(setBranchID(id)),
    setRegionID: (id) => dispatch(setRegionID(id)),
    setHubID: (id) => dispatch(setHubID(id)),
    setMandiID: (id) => dispatch(setMandiID(id)),
  };
};

const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
  currentScreen: selectCurrentArrivalScreen,
  summaryIsEnabled: selectSummaryScreenVisibility,
  branches: selectBranches,
  regions: selectRegions,
  hubs: selectHubs,
  mandis: selectMandis,
  branchID: selectBranchID,
  regionID: selectRegionID,
  hubID: selectHubID,
  mandiID: selectMandiID,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArrivalCategoryListItemFarmer);
