import { createStore, applyMiddleware, compose } from "redux";
import { logger } from "redux-logger";

import rootReducer from "./root-reducer";

let middlewares = [logger];

if (process.env.NODE_ENV === "production") {
  // middlewares = [];
}

// const store = createStore(rootReducer, applyMiddleware(...middlewares)  );
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer /* preloadedState, */,
  composeEnhancers(applyMiddleware(...middlewares))
);
export default store;
