import React from "react";
import "./latest-item.styles.css";

import upImage from "../../assets/up.svg";
import downImage from "../../assets/green.svg";


/**
 * @component
 * @description The watchlist item displayed in the side menu under watchlists
 * @property {boolean} isUp whether the trend is up.
 * @property {string} mandiName user interested mandi.
 * @property {string} varietyName user interested variety.
 * @property {number} price price in the user interested mandi.
 * @property {string} lowMandi name of mandi having lower profitability, ie,higher price.
 * @property {string} highMandi name of mandi having higher profitability, ie,lower price.
 * @property {number} lowMandiPrice price in the low mandi.
 * @property {number} highMandiPrice price in the high mandi.
 */
const LatestItem = (props) => {
  const {
    isUp,
    mandiName,
    varietyName,
    lowMandi,
    highMandi,
    lowMandiPrice,
    highMandiPrice,
    price,
  } = props;
  return (
    <div className="latest-container">
      <div className="info-left">
        <img
          className={`${isUp ? "trend-image" : "trend-image rotate-down"}`}
          src={isUp ? upImage : downImage}
          alt="alert"
        />
        <div className="main-info">
          <div className="mandi">{mandiName}</div>
          <div className="variety">{varietyName}</div>
        </div>
      </div>
      <div className="info-right">
        <div className="low-mandi">{`${lowMandi} ${lowMandiPrice} ₹/Qntl`}</div>
        <div className="rate">{`${price} ₹/Qntl`}</div>
        <div className="high-mandi">{`${highMandi} ${highMandiPrice} ₹/Qntl`}</div>
      </div>
    </div>
  );
};

export default LatestItem;
