import { createSelector } from "reselect";

const selectUser = (state) => state.user;

export const selectLoginStatus = createSelector(
  [selectUser],
  (user) => user.isLogged
);

export const selectAccessToken = createSelector(
  [selectUser],
  (user) => user.accessToken
);

export const selectUserData = createSelector([selectUser], (user) => ({
  roleID: user.userRoleID,
  userName: user.userName,
  userTerritories: user.userTerritories,
  locationFeatures: user.locationFeatures,
  nonLocationFeatures: user.nonLocationFeatures,
  userFeatures: user.userFeatures,
}));

export const selectAllUsers = createSelector(
  [selectUser],
  (user) => user.allUsers
);

export const selectAllBranches = createSelector(
  [selectUser],
  (user) => user.allBranches
);
export const selectUserRoleData = createSelector(
  [selectUser],
   (user)=> user.userRoleData
);
export const selectallReportData = createSelector(
  [selectUser],
   (user)=> user.reportData
);

export const selectMSMessageRBAC = createSelector(
  [selectUser],
  (user) => user.masterScreenRBACMsg
);
