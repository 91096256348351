import { createSelector } from "reselect";

const selectArrival = (state) => state.arrival;

export const selectArrivalData = createSelector(
  [selectArrival],
  (arrival) => arrival.arrivalData
);

export const selectAllMandis = createSelector(
  [selectArrival],
  (arrival) => arrival.allMandis
);

export const selectSummaryScreenVisibility = createSelector(
  [selectArrival],
  (arrival) => arrival.summaryVisibility
);

export const selectBranches = createSelector(
  [selectArrival],
  (arrival) => arrival.branches
);

export const selectRegions = createSelector(
  [selectArrival],
  (arrival) => arrival.regions
);

export const selectHubs = createSelector(
  [selectArrival],
  (arrival) => arrival.hubs
);

export const selectMandis = createSelector(
  [selectArrival],
  (arrival) => arrival.mandis
);

export const selectVarieties = createSelector(
  [selectArrival],
  (arrival) => arrival.varieties
);

export const selectRemainingVarietyList = createSelector(
  [selectArrival],
  (arrival) => arrival.remainingVarietyList
);

export const selectHubVarieties = createSelector(
  [selectArrival],
  (arrival) => arrival.hubVarieties
);

export const selectRemainingHubVarietyList = createSelector(
  [selectArrival],
  (arrival) => arrival.remainingHubVarietyList
);

export const selectAllVarietyInfo = createSelector(
  [selectArrival],
  (arrival) => arrival.allVarietyInfo
);

export const selectBranchID = createSelector(
  [selectArrival],
  (arrival) => arrival.branchID
);

export const selectRegionID = createSelector(
  [selectArrival],
  (arrival) => arrival.regionID
);

export const selectHubID = createSelector(
  [selectArrival],
  (arrival) => arrival.hubID
);

export const selectMandiID = createSelector(
  [selectArrival],
  (arrival) => arrival.mandiID
);
