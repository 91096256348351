import React from "react";
import "./acreage-master.styles.css";
import { Component } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUserData,selectMSMessageRBAC } from "../../redux/user/user.selectors";
import { canUserEdit ,getUserMSRBACMessage} from "../../auth/utils";
import { FeatureCodes } from "../../auth/data";
// import CustomFilterMultiselect from "../../components/custom-multi-select/custom-multi-select.component";

import {
  Table,
  Spin,
  Input,
  Select,
  Button,
  message,
  Modal,
  Form,
  Radio,
} from "antd";

import CustomSelectSummary from "../../components/custom-select-summary/custom-select-summary.component";

import API from "../../api";

const { Search } = Input;
const { Option } = Select;

class AcreageMaster extends Component {
 
  state = {
    isModalVisible: false,
    isAdd: false,
    currentRecord: null,
    userBranches: null,
    searchText: null,
    searchUserType: 0,
    AcreageTableData: [],
    isSubmitted: false,
    primaryHubList: [],
    filteredHubList: [],
    hubRegionList: [],
    buyNoBuyList: [],
    mandiBranchList: [],
    isSpinnerOnLoad: false,
    newData: {
      acreage_pkid:undefined,
      branch_id: undefined,
      branch_name: "",
      hub_id:undefined,
      hub_name:"",
      variety_id:undefined,
      variety_name:"",
      district_hub_variety_acreage:undefined,
      total_hub_acreage:undefined,
      split:undefined,
      state_id:undefined,
      branch_state:"",
      isEdit: false,
      is_active:true,
    },
    rowData: undefined,
    selectedVarietyValue: undefined,
    selectedVarietyName: "",
    selectedHubList: [],
    varietyList: [],
    stateList: [],
    selectedStateValue: undefined,
    selectedStatename: "",
    selectedBranchList: [],
    MessageForAccess:"",
  };


  componentDidMount() {
    this.setState({ isSpinnerOnLoad: true });
     this.getHubList();
  }

getAcreageMaster = async () => {
    console.log("farmer buying master ", );
    this.setState({ isSpinnerOnLoad: true });
 
    const params = {
      variety_id:
        this.state.selectedVarietyValue === null ||
        this.state.selectedVarietyValue === undefined
          ? null
          : this.state.selectedVarietyValue,
          state_id:
        this.state.selectedStateValue === null ||
        this.state.selectedStateValue === undefined
          ? null
          : this.state.selectedStateValue
    };

    await API.get("/acreage",{params : params})
      .then((varietyResponse) => {

        varietyResponse.data.data.map((dataDetailItem, index) => {
          dataDetailItem.key = index;
          dataDetailItem.isEdit = false;
        });
      console.log("master acreage111 ", varietyResponse.data.data);
        this.setState(
          {
            AcreageTableData: varietyResponse.data.data,
            // InflationMasterTableData:[],
          },
          () => this.setState({ isSpinnerOnLoad: false, isAdd: true })
        );
        console.log("master acreage ", varietyResponse.data.data);
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400) message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };

  getStateAPI = async (hubId) => {
    this.setState({ isSpinnerOnLoad: true });
    const params = {
      hub_id: hubId
    };

    await API.get("/get_states",{params:params})
      .then((state) => {
        let modifiedData = [...state.data.data];
        modifiedData.push({ state_id: null, state_name: "All" });
        this.setState(
          {
            selectedStateList: modifiedData,
            stateList: state.data.data,
            //  mandiBranchList: state.data.data,
            selectedStateValue: state.data.data[0].state_id,
            selectedStateName: state.data.data[0].state_name,
          },
          ()=>this.setState({ isSpinnerOnLoad: false })
        );
      })

      .catch((err) => {
        this.setState({ isSpinnerOnLoad: false });
        this.getStateAPI();
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
      });
  };

  
  getStateList = async () => {
    this.setState({ isSpinnerOnLoad: true });

    await API.get("/master_branch")
      .then((mandiBranch) => {


        this.setState(
          {
            stateList: mandiBranch.data.data,
            selectedStateValue:  mandiBranch.data.data[0].state_id,
            selectedStateName: mandiBranch.data.data[0].state_name ,
          },

          () => this.getAcreageMaster()
        );
      })
      .catch((err) => {
        this.setState({ isSpinnerOnLoad: false });
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
      });
  };

  getvarietyList = async () => {
    this.setState({ isSpinnerOnLoad: true });

    //TODO: change api
    await API.get("/list_variety")
      .then((roleData) => {
        this.setState(
          {
            varietyList: roleData.data.data,
             selectedVarietyValue:  roleData.data.data[0].variety_id ,
            selectedVarietyName:  roleData.data.data[0].variety_name ,
          },
          () => this.getAcreageMaster()
        );
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400) message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };

  getHubList = async () => {
    this.setState({ isSpinnerOnLoad: true });


    await API.get("/list_hubs")
      .then((mandiBranch) => {
        // let modifiedData = [...mandiBranch.data.data];
        // modifiedData.push({ branch_id: null, branch_name: "All" });
        const filterhub = mandiBranch.data.data.filter((hubItem) => hubItem.parent_id === this.state.selectedBranchValue);
        this.setState(
          {
            primaryHubList: mandiBranch.data.data,
            filteredHubList: filterhub,
            selectedHubValue: filterhub[0] ? filterhub[0].territory_id : 0,
            selectedHubName: filterhub[0] ? filterhub[0].territory_name : 0,
          },

          () => this.getvarietyList()
        );
      })
      .catch((err) => {
        this.setState({ isSpinnerOnLoad: false });
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
      });
  };

  setTableData = () => {
    if (
      this.state.AcreageTableData !== undefined &&
      this.state.AcreageTableData.length > 0
    ) {

      const userTableData = this.state.AcreageTableData.map((user, index) => {
        return {
          key: user.key,
          is_active:user.is_active,
          acreage_pkid:user.acreage_pkid,
          branch_id: user.branch_id,
          branch_name: user.branch_name,
          hub_id:user.hub_id,
      hub_name:user.hub_name,
      variety_id:user.variety_id,
      variety_name:user.variety_name,
      district_hub_variety_acreage:user.district_hub_variety_acreage,
      total_hub_acreage:user.total_hub_acreage,
      split:user.split,
      state_id:user.state_id,
      branch_state:user.branch_state,
          isEdit: user.isEdit,
        };

    
      });


      let dataAfterSearch = userTableData;

      if (this.state.searchText) {
        dataAfterSearch = dataAfterSearch.filter(
          (item) =>
            (item.hub_name !== null &&
              item.hub_name
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase())) ||
            (item.variety_name !== null &&
              item.variety_name
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase())) ||
            (item.branch_state !== null &&
              item.branch_state
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase()))
        );
      }

      return dataAfterSearch;
    } else {
      return this.state.AcreageTableData;
    }
  };

  submitTableRowData = (row, isPopup) => {
    this.setState({ isSpinnerOnLoad: true, isSubmitted: true });
    let warningmessage = "";
    if (
      row.hub_name === null ||
      row.hub_name === undefined ||
      row.hub_name === ""
    ) {
      warningmessage = "Please Select hub";
    } else if (
      row.variety_name === null ||
      row.variety_name === undefined ||
      row.variety_name === ""
    ) {
      warningmessage = "Please select Variety";
    } else if (
      row.branch_state === null ||
      row.branch_state === undefined ||
      row.branch_state === ""
    ) {
      warningmessage = "Please select State";
    } 
    // else if (
    //   row.district_hub_variety_acreage === null ||
    //   row.district_hub_variety_acreage === undefined ||
    //   row.district_hub_variety_acreage === "" ||
    //   row.district_hub_variety_acreage === 0
    // ) {
    //   warningmessage = "Please enter District Hub Variety Acreage";
    // } else if (
    //     row.total_hub_acreage === null ||
    //     row.total_hub_acreage === undefined ||
    //     row.total_hub_acreage === "" ||
    //     row.total_hub_acreage === 0
    //   ) {
    //     warningmessage = "Please enter Total Hub Acreage";
    //   } else if (
    //     row.split === null ||
    //     row.split === undefined ||
    //     row.split === "" ||
    //     row.split === 0
    //   ) {
    //     warningmessage = "Please enter Split";
    //   }

    if (
      warningmessage === "" ||
      warningmessage === undefined ||
      warningmessage === null
    ) {
      this.setState({ isSubmitted: true });
      this.setState({ isSpinnerOnLoad: true });
      console.log("is active ", row.is_active);
      let data = {
        acreage_pkid:row.acreage_pkid,
        branch_id: row.branch_id,
        hub_id:row.hub_id,
      variety_id:row.variety_id,
      variety_name:row.variety_name,
      district_hub_variety_acreage:row.district_hub_variety_acreage,
      total_hub_acreage:row.total_hub_acreage,
      split:row.split,
      is_active:row.is_active,
      };



      API.put("/acreage", data, {

      })
        .then((response) => {

          if (response.status === 200 && response.data.status) {
            this.setModalVisibility(false);
            message.info(response.data.message, 5);
            this.getAcreageMaster();
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
          } else if (!response.data.status) {
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
            message.error(response.data.message, 5);
            // this.setModalVisibility(false);
          } else {
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
            message.error("Please try again", 5);
          }
        })
        .catch((err) => {
          if (err) {
            if (err.response && err.response.status === 400)
              message.error("Something went wrong. Please try again later.", 5);
            else {
              message.error(
                "Something went wrong.. Please try again later.",
                5
              );
            }
          } else {
            message.error("Something went wrong... Please try again later.", 5);
          }
          this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
          // this.setModalVisibility(false);
        });
    } else {
      this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
      message.error(warningmessage, 5);
    }
  };

  TableColumnHeader = (props) => {
    const { title, subtitle } = props;
    return (
      <div className="column-header-container">
        <div
          style={{
            color: "white",
            fontSize: "12px",
            whiteSpace: "nowrap",
            fontWeight: "normal",
            textAlign: "left",
          }}
        >
          {title}
        </div>
        <div
          style={{
            color: "#cbdfff",
            fontSize: "11px",
            fontWeight: "normal",
            textAlign: "left",
            // subtitle && title.length < subtitle.length ? "left" : "center"
          }}
        >
          {subtitle}
        </div>
      </div>
    );
  };

  createFilters = (label) => {
    let filterData = this.state.AcreageTableData;
    //#region remove duplicate objects
    let uniqueFilterData = [];

    if (
      label === "is_active" &&
      this.state.AcreageTableData &&
      this.state.AcreageTableData.length > 0
    ) {
      uniqueFilterData = [
        { text: "Yes", value: 1 },
        { text: "No", value: 0 },
      ];
    } else {
      filterData.map((mainItem) =>
        uniqueFilterData.filter((item) => item[label] === mainItem[label])
          .length > 0
          ? null
          : uniqueFilterData.push(mainItem)
      );

      uniqueFilterData = uniqueFilterData.map((item) => {
        return {
          text: item[label],
          value: item[label],
        };
      });

      uniqueFilterData.sort((a, b) => a.text.localeCompare(b.text));
    }

    return uniqueFilterData;
  };

  setModalVisibility = (status) => {
    if (status) {
      const nData = {
        acreage_pkid:undefined,
        branch_id: undefined,
        branch_name: "",
        hub_id:undefined,
      hub_name:"",
      variety_id:undefined,
      variety_name:"",
      district_hub_variety_acreage:undefined,
      total_hub_acreage:undefined,
      split:undefined,
      state_id:undefined,
      branch_state:"",
        isEdit: false,
        is_active:true,
      };

      this.setState({ newData: nData });
    }

    this.setState({ isModalVisible: status });
  };

  handleChange = (e, row, index, name, isPopup) => {
    e.target.name = name;

    var alphaNumericRegex = /^[a-zA-Z0-9]*$/;
    var numberRegex = /^[0-9.]+$/;
    let a;

    if (isPopup === false) {
      a = this.state.AcreageTableData;

      if (e.target.name === "split") {
        a[row.key].split = e.target.value;
      }
      if (e.target.name === "district_hub_variety_acreage") {
        a[row.key].district_hub_variety_acreage = e.target.value;
      }
      if (e.target.name === "total_hub_acreage") {
        a[row.key].total_hub_acreage = e.target.value;
      }
     

      this.setState({ AcreageTableData: a });
    } else {
      a = this.state.newData;

      if (e.target.name === "split") {
        a.split = e.target.value;

      } else if (e.target.name === "district_hub_variety_acreage") {
        a.district_hub_variety_acreage = e.target.value;
      } else if (e.target.name === "total_hub_acreage") {
        a.total_hub_acreage = e.target.value;
      }

      this.setState({ newData: a });
    }
  };

  handleDropdownChange = (value, row, target, index, optionlabel, isPopup) => {

    let branchLabel, branchid;
    if (target === "hub_region") {
    }

    let a;
    if (isPopup === false) {
      a = this.state.AcreageTableData;

      if (target === "is_active") {
        a[row.key].is_active = Boolean(value);
      } else if (target === "hub_name") {
        a[row.key].branch_state = "";
        this.getStateAPI(value);
        a[row.key].hub_name = optionlabel;
        a[row.key].hub_id = value;
      } else if (target === "variety_name") {
        a[row.key].variety_id = value;
        a[row.key].variety_name = optionlabel;
      } else if (target === "branch_state") {
        a[row.key].branch_state = optionlabel;
         a[row.key].branch_id = value;
      }

      this.setState({ AcreageTableData: a });

    } else {
      a = this.state.newData;

      if (target === "is_active") {
        a.is_active = Boolean(value);
      } else if (target === "hub_name") {
        a.branch_state = "";
        this.getStateAPI(value);
        a.hub_name = optionlabel;
        a.hub_id = value;
      } else if (target === "variety_name") {
        a.variety_id = value;
        a.variety_name = optionlabel;
      } else if (target === "branch_state") {
        a.branch_state = optionlabel;
        a.branch_id = value;
      }
      this.setState({ newData: a });
    }
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  handleEditChange = (row, index) => {
    //     if(this.canUserEdit(row,row.branch_id))
    // {
        this.setState({ rowData: row });
        let tempData = this.state.AcreageTableData.map((item) => {
          if (item.hub_id === row.hub_id) {
            return {
              ...item,
              isEdit: true,
            };
          } else {
            return item;
          }
        });
        console.log("edit ", tempData);
        this.setState({ AcreageTableData: tempData });
        console.log("edit1 ", this.state.AcreageTableData);
        // setTableData();
      // }
      // else{
      //   message.info("User can edit only their respective branches",1);
      // }
      };

  handleCancelClick = (rowData, index) => {
    let tempData = this.state.AcreageTableData.map((item) => {
      if (item.hub_id === rowData.hub_id) {
        return this.state.rowData;
      } else {
        return item;
      }
    });
    this.setState({ AcreageTableData: tempData });

    // let a = this.state.AcreageTableData;
    // a[index]= this.state.rowData;
    // a[index].isEdit = false;
    // this.setState({ AcreageTableData: a });
  };

  render() {
  
    const userTableProps = {
      pagination: false,
      size: "default",
      bordered: true,
      scroll: { x: 200, y: window.innerHeight - 250 },
    };

    const handleStateSelect = (value, props) => {
      this.setState(
        { selectedStateName: props.key, selectedStateValue: value },
        () => this.getAcreageMaster()
      );
    };

    const handleVarietySelect = (value, props) => {
      this.setState(
        { selectedVarietyName: props.key, selectedVarietyValue: value },
        () => this.getAcreageMaster()
      );
    };

    return (
      <div
        className="hub-master-container"
        style={{
          height: window.innerHeight - 85,
          marginTop: "-45px",
        }}
      >
        <div className="top-container">
        <div className="hub-region-filter">
        <span
        style={{
          fontSize: "15px",
          marginTop: "5px",
          fontWeight: "600",
          marginLeft: "50px",
        }}
      >
        Variety&nbsp;
      </span>{" "}
      <Select
        showSearch
        optionFilterProp="children"
        className={"select-master-region"}
        mode="single"
        placeholder="Select Variety"
        value={this.state.selectedVarietyValue}
        onChange={(value, props) => handleVarietySelect(value, props)}
      >
        {this.state.varietyList !== null &&
          this.state.varietyList !== undefined &&
          this.state.varietyList.length &&
          this.state.varietyList.map((item, idx) => (
            <Option value={item.variety_id} key={item.variety_id}>
              {item.variety_name}
            </Option>
          ))}
      </Select>

     {/*} <span
        style={{
          fontSize: "15px",
          marginTop: "5px",
          fontWeight: "600",
          marginLeft: "50px",
        }}
      >
        State&nbsp;
      </span>{" "}
      <Select
      showSearch
      optionFilterProp="children"
      className={"select-master-region"}
      mode="single"
      placeholder="Select State"
      value={this.state.selectedStateValue}
      onChange={(value, props) => handleStateSelect(value, props)}
    >
      {this.state.stateList !== null &&
        this.state.stateList !== undefined &&
        this.state.stateList.length &&
        this.state.stateList.map((item, idx) => (
          <Option value={item.state_id} key={item.state_id}>
            {item.state_name}
          </Option>
        ))}
    </Select>
        */}
      </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row-reverse",
              placeItems: "flex-end",
              marginRight: "25px",
              marginBottom: "10px",
            }}
          >
            <Search
              disabled={!this.state.isAdd}
              placeholder="Search"
              allowClear
              onChange={(e) => this.setState({ searchText: e.target.value })}
              style={{ width: "45%" }}
            />
      <Button
              className="add-master-button"
              style={{
                height: "25px",
                width: "70px",
                backgroundColor: "#0b133d",
                color: "white",
              }}
              disabled={!canUserEdit(
                this.props.userData,
                null,
                FeatureCodes.MS_ACREAGE_EDIT
              ) || !this.state.isAdd}
              onClick={() => this.setModalVisibility(true)}
            >
              &#10010; Add
              </Button> 
          </div>
        </div>

        <div>
        <div
        style={{
          marginLeft: "25px",
          marginTop: "-8px",
          fontSize: "14px",
          color: "red",
          display: "flex",
          float: "left",
        }}
      >
      {getUserMSRBACMessage(this.props.messageAccess,FeatureCodes.MS_ACREAGE_EDIT)}
      </div>
        <div
          style={{
            marginRight: "20px",
            marginTop: "-8px",
            fontSize: "15px",
            color: "#0B133D",
            display: "flex",
            float: "right",
          }}
        >Total number of records:&nbsp;
          <b>{this.state.AcreageTableData && this.setTableData().length}</b>
        </div>
        </div>

        <Spin
          spinning={this.state.isSpinnerOnLoad}
          size={"large"}
          tip="Loading..."
          style={{ alignSelf: "center", zIndex: 9999, marginTop: "20px" }}
        >
          <Modal
            className="add-master-modal"
            title={"Add Acreage"}
            onCancel={this.handleCancel}
            footer={false}
            visible={this.state.isModalVisible}
            destroyOnClose={true}
          >
            <div className="master-form-container">
              <Form
                layout="horizontal"
                //   onValuesChange={onFormLayoutChange}
                size={"small"}
              // onSubmit={submitTableRowData(this.state.newData)}
              >
                <div className="form-top-container">
                  <div>
                    <div
                      className="checkbox-group1"
                      style={{
                        display: "inline-block",
                        width: "42%",
                        marginRight: 7,
                      }}
                    >
                      <Form.Item label="Hub">
                        <Select
                          showSearch
                          optionFilterProp="children"
                          className="remarks-select"
                          name="hub_name"
                          defaultValue={this.state.newData.hub_name}
                          value={this.state.newData.hub_name}
                          onSelect={(value, option) =>
                            this.handleDropdownChange(
                              value,
                              this.state.newData,
                              "hub_name",
                              -0,
                              option.props.children,
                              true
                            )
                          }
                        >
                          {this.state.primaryHubList &&
                            this.state.primaryHubList.length &&
                            this.state.primaryHubList.map((hubItem, idx) => (
                              <Option
                                key={hubItem.territory_id}
                                value={hubItem.territory_id}
                              >
                                {hubItem.territory_name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </div>

                    <div
                    className="form-top-container"
                    style={{
                      display: "inline-block",
                      width: "42%",
                      marginRight: 7,
                    }}
                  >
                    <Form.Item label="Variety">
                      <Select
                        showSearch
                        optionFilterProp="children"
                        className="remarks-select"
                        name="variety_name"
                        defaultValue={this.state.newData.variety_name}
                        value={this.state.newData.variety_name}
                        onSelect={(value, option) =>
                          this.handleDropdownChange(
                            value,
                            this.state.newData,
                            "variety_name",
                            -0,
                            option.props.children,
                            true
                          )
                        }
                      >
                        {this.state.varietyList &&
                          this.state.varietyList.length &&
                          this.state.varietyList.map((hubItem, idx) => (
                            <Option
                              key={hubItem.variety_id}
                              value={hubItem.variety_id}
                            >
                              {hubItem.variety_name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </div>

                    <div
                      className="form-top-container"
                      style={{
                        display: "inline-block",
                        width: "42%",
                        marginRight: 7,
                      }}
                    >
                      <Form.Item label="State">
                        <Select
                          showSearch
                          optionFilterProp="children"
                          className="remarks-select"
                          name="branch_state"
                          defaultValue={this.state.newData.branch_state}
                          value={this.state.newData.branch_state}
                          onSelect={(value, option) =>
                            this.handleDropdownChange(
                              value,
                              this.state.newData,
                              "branch_state",
                              -0,
                              option.props.children,
                              true
                            )
                          }
                        >
                          {this.state.stateList &&
                            this.state.stateList.length &&
                            this.state.stateList.map((hubItem, idx) => (
                              <Option
                                key={hubItem.state_id}
                                value={hubItem.state_id}
                              >
                                {hubItem.state_name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </div>

                
                
                  <div
                    className="checkbox-group1"
                    style={{
                      display: "inline-block",
                      width: "42%",
                      marginRight: 7,
                    }}
                  >
                    <Form.Item label="District Hub Variety Acreage">
                      <Input
                        style={{ marginRight: "2px" }}
                        maxLength={100}
                        value={this.state.newData.district_hub_variety_acreage}
                        name="district_hub_variety_acreage"
                        onChange={(e) =>
                          this.handleChange(
                            e,
                            this.state.newData,
                            -0,
                            "district_hub_variety_acreage",
                            true
                          )
                        }
                      />
                    </Form.Item>
                  </div>
                  </div>
                  <div
                  className="checkbox-group1"
                  style={{
                    display: "inline-block",
                    width: "42%",
                    marginRight: 7,
                  }}
                >
                  <Form.Item label="Total Hub Acreage">
                    <Input
                      style={{ marginRight: "2px" }}
                      maxLength={100}
                      value={this.state.newData.total_hub_acreage}
                      name="total_hub_acreage"
                      onChange={(e) =>
                        this.handleChange(
                          e,
                          this.state.newData,
                          -0,
                          "total_hub_acreage",
                          true
                        )
                      }
                    />
                  </Form.Item>
                </div>


                <div
                className="checkbox-group1"
                style={{
                  display: "inline-block",
                  width: "42%",
                  marginRight: 7,
                }}
              >
                <Form.Item label="Split">
                  <Input
                    style={{ marginRight: "2px" }}
                    maxLength={100}
                    value={this.state.newData.split}
                    name="split"
                    onChange={(e) =>
                      this.handleChange(
                        e,
                        this.state.newData,
                        -0,
                        "split",
                        true
                      )
                    }
                  />
                </Form.Item>
              </div>

              <div
              className="form-top-container"
              style={{
                display: "inline-block",
                width: "33%",
                marginRight: 7,
              }}
            >
              <Form.Item label="Active">
                <Radio.Group
                  name="is_active"
                  value={this.state.newData.is_active ? 1 : 0}
                  onChange={(e, option) =>
                    this.handleDropdownChange(
                      e.target.value,
                      this.state.newData,
                      "is_active",
                      -0,
                      "",
                      true
                    )
                  }
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          
                </div>

                <div className="form-bottom-container">
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={this.state.isSubmitted}
                      onClick={() =>
                        this.submitTableRowData(this.state.newData)
                      }
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </Modal>
          <div
            className="table-container"
            style={{ maxHeight: "450px", margin: "6px 0px" }}
          >
            <Table
              {...userTableProps}
              columns={[
                {
                  title: "Hub",
                  dataIndex: "hub_name",
                  key: "hub_name",
                  width: 125,
                  filters: this.createFilters("hub_name"),
                  onFilter: (value, record) =>
                    record.hub_name.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.hub_name.localeCompare(b.hub_name),
                  render: (record, rowRecord) => {
                    return (
                      <>
                        <div
                          style={{ marginLeft: "-15px", marginRight: "-10px" }}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            disabled={true}
                            className="remarks-select"
                            name="hub_name"
                            defaultValue={rowRecord.hub_name}
                            value={rowRecord.hub_name}
                            onSelect={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "hub_name",
                                -0,
                                option.props.children,
                                false
                              )
                            }
                          >
                            {this.state.primaryHubList &&
                              this.state.primaryHubList.length &&
                              this.state.primaryHubList.map((hubItem, idx) => (
                                <Option
                                  key={hubItem.territory_id}
                                  value={hubItem.territory_id}
                                >
                                  {hubItem.territory_name}
                                </Option>
                              ))}
                          </Select>
                        </div>
                      </>
                    );
                  },
                },
                {
                  title: "Variety",
                  dataIndex: "variety_name",
                  key: "variety_name",
                  width: 125,
                  filters: this.createFilters("variety_name"),
                  onFilter: (value, record) =>
                    record.variety_name.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.variety_name.localeCompare(b.variety_name),
                  render: (record, rowRecord) => {
                    return (
                      <>
                        <div
                          style={{ marginLeft: "-15px", marginRight: "-10px" }}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            disabled={true}
                            className="remarks-select"
                            name="variety_name"
                            defaultValue={rowRecord.variety_name}
                            value={rowRecord.variety_name}
                            onSelect={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "variety_name",
                                -0,
                                option.props.children,
                                false
                              )
                            }
                          >
                            {this.state.varietyList &&
                              this.state.varietyList.length &&
                              this.state.varietyList.map((hubItem, idx) => (
                                <Option
                                  key={hubItem.variety_id}
                                  value={hubItem.variety_id}
                                >
                                  {hubItem.variety_name}
                                </Option>
                              ))}
                          </Select>
                        </div>
                      </>
                    );
                  },
                },
                {
                  title: "State",
                  dataIndex: "branch_state",
                  key: "branch_state",
                  width: 125,
                  filters: this.createFilters("branch_state"),
                  onFilter: (value, record) =>
                    record.branch_state.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.branch_state.localeCompare(b.branch_state),
                  render: (record, rowRecord) => {
                   
                    return (
                      <>
                        <div
                          style={{ marginLeft: "-15px", marginRight: "-10px" }}
                        >
                          <Select
                            showSearch
                            disabled={true}
                            optionFilterProp="children"
                            className="remarks-select"
                            name="branch_state"
                            defaultValue={rowRecord.branch_state}
                            value={rowRecord.branch_state}
                            onSelect={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "branch_state",
                                -0,
                                option.props.children,
                                false
                              )
                            }
                          >
                            {this.state.stateList &&
                              this.state.stateList.length &&
                              this.state.stateList.map((hubItem, idx) => (
                                <Option
                                  key={hubItem.state_id}
                                  value={hubItem.state_id}
                                >
                                  {hubItem.state_name}
                                </Option>
                              ))}
                          </Select>
                        </div>
                      </>
                    );
                  },
                },
                {
                  key: "district_hub_variety_acreage",
                  title: "District Hub Variety Acreage",
                  dataIndex: "district_hub_variety_acreage",
                  width: 100,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.district_hub_variety_acreage - b.district_hub_variety_acreage,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            disabled={!rowRecord.isEdit}
                            style={{ marginRight: "2px" }}
                            maxLength={100}
                            value={rowRecord.district_hub_variety_acreage}
                            name="district_hub_variety_acreage"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "district_hub_variety_acreage",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },
                {
                  key: "total_hub_acreage",
                  title: "Total Hub Acreage",
                  dataIndex: "total_hub_acreage",
                  width: 100,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.total_hub_acreage - b.total_hub_acreage,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            disabled={!rowRecord.isEdit}
                            style={{ marginRight: "2px" }}
                            maxLength={100}
                            value={rowRecord.total_hub_acreage}
                            name="total_hub_acreage"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "total_hub_acreage",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },
                {
                  key: "split",
                  title: "Split",
                  dataIndex: "split",
                  width: 100,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.split - b.split,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            disabled={!rowRecord.isEdit}
                            style={{ marginRight: "2px" }}
                            maxLength={100}
                            value={rowRecord.split}
                            name="split"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "split",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },
                {
                  key: "is_active",
                  title: "Active",
                  dataIndex: "is_active",
                  width: 85,
                  filters: this.createFilters("is_active"),
                  // filters: [{ text: "Yes", value: 1 }, { text: "No", value: 0 }],
                  onFilter: (value, record) => record.is_active === value,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div
                          style={{ marginLeft: "-15px", marginRight: "-40px" }}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            disabled={!rowRecord.isEdit}
                            defaultValue={
                              rowRecord.is_active === 1 ? "Yes" : "No"
                            }
                            value={rowRecord.is_active === 1 ? "Yes" : "No"}
                            onChange={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "is_active",
                                index,
                                option.props.children,
                                false
                              )
                            }
                            style={{ width: "70%" }}
                          >
                            <Option key={1} value={1}>
                              {"Yes"}
                            </Option>
                            <Option key={0} value={0}>
                              {"No"}
                            </Option>
                          </Select>
                        </div>
                      </>
                    );
                  },
                },
                {
                  title: "Action",
                  dataIndex: "Submit",
                  key: "Submit",
                  width: 140,

                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ display: "inline-flex", width: "100%" }}>
                          <Button
                          className={!canUserEdit(
                            this.props.userData,
                            null,
                            FeatureCodes.MS_ACREAGE_EDIT
                          ) ? "disabledBtnClass" : "btnClass"}
                          disabled={!canUserEdit(
                            this.props.userData,
                            null,
                            FeatureCodes.MS_ACREAGE_EDIT
                          )}
                            type="primary"
                            size={"small"}
                            style={{
                              marginLeft: 5,
                              alignSelf: "center",
                              color: "white",
                              borderRadius: 15,
                              padding: "0px 0px 2px",
                              height: 31,
                              backgroundColor: "#20346a",
                            }}
                            block
                            htmlType="submit"
                            onClick={() =>

                              rowRecord.isEdit
                                ? this.submitTableRowData(
                                  rowRecord,
                                  index,
                                  false
                                )
                                : this.handleEditChange(rowRecord, index)
                            }
                          >
                            {" "}
                            {rowRecord.isEdit ? "Submit" : "Edit"}{" "}
                          </Button>

                          <Button
                            className={
                              !rowRecord.isEdit
                                ? "disabledBtnClass"
                                : "btnClass"
                            }
                            type="primary"
                            size={"small"}
                            style={{
                              marginLeft: 5,
                              alignSelf: "center",
                              color: "white",
                              borderRadius: 15,
                              padding: "0px 0px 2px",
                              height: 31,
                              backgroundColor: "#20346a",
                            }}
                            block
                            htmlType="submit"
                            disabled={!rowRecord.isEdit}
                            onClick={() =>
                              this.handleCancelClick(rowRecord, index)
                            }
                          >
                            Cancel
                          </Button>
                        </div>
                      </>
                    );
                  },
                },
              ]}
              dataSource={this.setTableData()}
            />
          </div>


        </Spin>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
  messageAccess: selectMSMessageRBAC,
});
export default connect(mapStateToProps)(AcreageMaster);
//export default AcreageMaster;
