import React, { Component } from "react";
import "./report.styles.css";
// import { Doughnut, Bar, Line, Pie } from "react-chartjs-2";
import { Tabs, Icon } from "antd";
import UserAdoptionChart from "../../components/chart-user-adoption/chart-user-adoption.component";
import PriceTrend from "../../components/chart-price-trend/chart-price-trend.component";
import ConsolidatedChart from "../../components/chart-consolidated/chart-consolidated.component";
import EfficiencyReport from "../../components/efficiency-report/efficiency-report.component";
import IntraDayReport from "../../components/intra-day-report/intra-day-report.component";
// icons
import { ReactComponent as IconAdoption } from "../../assets/Report-Adoption.svg";
import { ReactComponent as IconBuying } from "../../assets/Report-Buying.svg";
import { ReactComponent as IconConsolidated } from "../../assets/Report-Consolidated.svg";
import { ReactComponent as IconEfficiency } from "../../assets/Report-Efficiency.svg";
import { ReactComponent as IconIntraDay } from "../../assets/Report-IntraDay.svg";
import { ReactComponent as IconTrend } from "../../assets/Report-Trend.svg";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectUserData } from "../../redux/user/user.selectors";

import { canUserEdit } from "../../auth/utils";
import { FeatureCodes } from "../../auth/data";

const { TabPane } = Tabs;

/**
 *
 * @description Component containing tab view of various reports.
 * @component
 */
class Report extends Component {
  state = { tabKey: 0 };
  render() {
    return (
      <div className="report-tabs">
        <Tabs
          animated={false}
          className={`main-content 
        `}
          defaultActiveKey="1"
        >
          {canUserEdit(
            this.props.userData,
            null,
            FeatureCodes.REPORTS_USER_ADOPTION
          ) ? (
            <TabPane
              forceRender
              tab={
                <div className="tab-title-container">
                  <div>
                    <IconAdoption className="tab-title-image" />
                  </div>
                  User Adoption
                </div>
              }
              key="11"
            >
              <UserAdoptionChart />
            </TabPane>
          ) : null}

          {canUserEdit(
            this.props.userData,
            null,
            FeatureCodes.REPORTS_PRICE_TREND
          ) ? (
            <TabPane
              forceRender
              tab={
                <div className="tab-title-container">
                  <div>
                    <IconTrend className="tab-title-image" />
                  </div>
                  Trend Report
                </div>
              }
              key="12"
            >
              <PriceTrend key={this.state.tabKey} />
            </TabPane>
          ) : null}

          {canUserEdit(
            this.props.userData,
            null,
            FeatureCodes.REPORTS_CONSOLIDATED
          ) ? (
            <TabPane
              forceRender
              tab={
                <div className="tab-title-container">
                  <div>
                    <IconConsolidated className="tab-title-image" />
                  </div>
                  Consolidated
                </div>
              }
              key="13"
            >
              <ConsolidatedChart />
            </TabPane>
          ) : null}

          {canUserEdit(
            this.props.userData,
            null,
            FeatureCodes.REPORTS_PURCHASE_EFFICIENCY
          ) ? (
            <TabPane
              forceRender
              tab={
                <div className="tab-title-container">
                  <div>
                    <IconEfficiency className="tab-title-image" />
                  </div>
                  Efficiency Reports
                </div>
              }
              key="14"
            >
              <EfficiencyReport />
            </TabPane>
          ) : null}

          {/* {canUserEdit(
            this.props.userData,
            null,
            FeatureCodes.REPORTS_INTRA_DAY_MANDI
          ) ? (
            <TabPane
              // key={this.state.tabKey + 1}
              forceRender
              tab={
                <div className="tab-title-container">
                  <div>
                    <IconIntraDay className="tab-title-image" />
                  </div>
                  Intra Day Reports
                </div>
              }
              key="15"
            >
              <IntraDayReport key={this.state.tabKey + 1} />
            </TabPane>
          ) : null} */}
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
});

export default connect(mapStateToProps, null)(Report);
