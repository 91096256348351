import React from "react";
import "./model-run-visualization-graph.styles.css";

import { Bar } from "react-chartjs-2";
import { getRandomColor } from "../../auth/utils";

const ModelRunGraph = (props) => {
  const {
    labels,
    datasets,
    xLabel,
    width,
    height,
    yLabel,
    isVolume,
    maintainAspectRatio,
    isQC,
    isPercentage,
  } = props;

  const PREM_NPREM_COLORS = {
    BLUE: "#2196f3",
    GREEN: "#73de73",
  };
  const COLOR_GREENS = ["#069005", "#09b008", "#0df00b", "#50f74f", "6ff96e"];
  const COLOR_BLUES = [
    "#094c72",
    "#055a8c",
    "#026aa7",
    "#0079bf",
    "#298fca",
    "#5ba4cf",
    "#8bbdd9",
    "#bcd9ea",
  ];

  let legendInformation = [];

  const barChartOptions = {
    responsive: false,
    spanGaps: true,
    maintainAspectRatio: maintainAspectRatio ? maintainAspectRatio : false,
    legend: {
      display: false,
    },

    label: true,
    intersect: false,
    mode: "nearest",
    backgroundColor: "white",
    titleFontColor: "#313131",
    borderColor: "#9d9d9d",
    borderWidth: 1,
    bodyFontColor: "#575757",
    displayColors: false,
    scales: {
      xAxes: [
        {
          id: "Time",
          stacked: true,
          ticks: {
            maxRotation: 0,
          },
          scaleLabel: {
            display: true,
            labelString: "Time",
          },
        },
      ],
      yAxes: [
        {
          id: "visualization",
          stacked: true,
          ticks: { beginAtZero: true },
          scaleLabel: {
            display: true,
            labelString: yLabel ? yLabel : "Volume (MT)",
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        // label: function (tooltipItem, data) {
        //   return "";
        // },
        label: function (tooltipItem, data) {
          var dataset = data["datasets"][0];
          var percent = dataset["data"][tooltipItem["index"]];

          if (isPercentage) return `${dataset["label"]} : ${percent} %`;
          else return `${dataset["label"]} : ${percent} MT`;
        },
      },
      label: false,
      intersect: false,
      mode: "nearest",
      backgroundColor: "white",
      titleFontColor: "#313131",
      borderColor: "#9d9d9d",
      borderWidth: 1,
      bodyFontColor: "#575757",
      displayColors: false,
    },
    // scales: {
    //   yAxes: [
    //     {
    //       ticks: {
    //         beginAtZero: true,
    //         min: 0,
    //       },
    //     },
    //   ],
    //   xAxes: [
    //     {
    //       ticks: {
    //         beginAtZero: true,
    //       },
    //     },
    //   ],
    // },
  };

  const getQCColor = (label, idx) => {
    const greenColors = COLOR_GREENS;
    const blueColors = COLOR_BLUES;

    if (
      label.toLowerCase().includes("dara") ||
      label.toLowerCase().includes("mill")
    ) {
      let color = blueColors.pop();
      return color ? color : "grey"; //light blue
    } else {
      let color = greenColors.pop();
      return color ? color : "black"; //green
    }
  };

  const getData = () => {
    let data = null;

    data = {
      labels: labels,
      datasets: datasets.map((item, idx) => {
        return {
          label: item.label,
          data: item.data,
          backgroundColor: isVolume
            ? getRandomColor(idx)
            : getQCColor(item.label, idx),
        };
      }),
    };

    return data;
  };

  const getLegendInformation = () => {
    legendInformation = [];

    if (isQC) {
      legendInformation.push(
        { name: "Non Premium", color: PREM_NPREM_COLORS.BLUE },
        { name: "Premium", color: PREM_NPREM_COLORS.GREEN }
      );
      return legendInformation;
    } else {
      const data = getData();
      data.datasets.map((item, idx) => {
        let lineColor = getRandomColor(idx);
        legendInformation.push({
          name: item.label,
          color: lineColor,
        });
      });
      return legendInformation;
    }
  };

  const conditionalProps = maintainAspectRatio
    ? {}
    : {
      height: height ? height : 330,
      width: width ? width : 1000,
    };

  return (
    <div className="visualization-graph-container box-shadow-bordered">
      <div className="bar-graph ">
        <div className="bar-graph-title">
          {xLabel + `${isVolume ? " (in MT)" : ""}`}
        </div>

        <div className="legend-container">
          {getLegendInformation().map((legend) => (
            <div key={legend.name} className="legend-box">
              <div
                className="legend-fill"
                style={{
                  background: legend.color,
                }}
              />
              <div className="legend-title">{legend.name}</div>
            </div>
          ))}
        </div>

        <Bar
          id="projection-bar-chart"
          data={getData()}
          options={barChartOptions}
          {...conditionalProps}
        />
      </div>
    </div>
  );
};

export default ModelRunGraph;
