import React from "react";
import "./layout.styles.css";

import MainContent from "../main-content/main-content.component";

import { Layout, Menu, Icon } from "antd";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectAccessToken } from "../../redux/user/user.selectors";
import {
  setDisplayedAlerts,
  setDisplayedWatchlist,
} from "../../redux/alerts/alerts.actions";

import socketIOClient from "socket.io-client";
import { baseURL } from "../../api";
import { LoadScriptNext } from "@react-google-maps/api";

const { Header, Sider, Content, Footer } = Layout;

/**
 *@description Main Layout Component
 *@component
 *@memberof App
 */
class MainLayout extends React.Component {
  /**
   *
   * @function
   * @description Establish socket.io connection for getting alerts and watchlist data.
   * @memberof App.MainLayout
   */
  // establishSocketConnection = () => {
  //   //#region here we set up the socket connection to subscribe the alerts and watchlist

  //   const socketURL = baseURL + "/alerts";

  //   const socket = socketIOClient(socketURL, {
  //     // transports: ['websocket'],
  //     query: { Authorization: this.props.accessToken },
  //   });

  //   socket.emit("connect");

  //   socket.on("alert_response", (alertResponse) => {
  //     if (alertResponse.status) {
  //       this.props.setDisplayedAlerts(alertResponse.data);
  //     }
  //   });

  //   socket.on("watchlist_response", (watchlistResponse) => {

  //     if (watchlistResponse.status) {
  //       this.props.setDisplayedWatchlist(watchlistResponse.data);
  //     }
  //   });

  //   //#endregion
  // };

  /**
   * @function
   * @description Wait until the accessToken is available to establish the socket connection.
   * @memberof App.MainLayout
   */
  waitForAccessToken() {
    if (this.props.accessToken) {
      this.establishSocketConnection();
    } else {
      setTimeout(() => {
        this.waitForAccessToken();
      }, 100);
    }
  }

  componentDidMount() {
    // this.waitForAccessToken();
  }

  // componentWillUnmount() {
  //   console.log("here we will disconnect from socket subscriptions.");
  //   const socketURL = baseURL + "/alerts";
  //   console.log("access token :", this.props.accessToken);

  //   const socket = socketIOClient(socketURL, {
  //     query: { Authorization: this.props.accessToken },
  //   });

  //   socket.emit("disconnect");
  // }

  render() {
    const{clickDisable}=this.props;
    return (
      <Layout className="layout">
        <Layout>
          <Content className="main-content">
            <LoadScriptNext googleMapsApiKey={process.env.REACT_APP_MAP_KEY}>
              <MainContent clickDisable={clickDisable} />
            </LoadScriptNext>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  accessToken: selectAccessToken,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setDisplayedAlerts: (data) => dispatch(setDisplayedAlerts(data)),
    setDisplayedWatchlist: (data) => dispatch(setDisplayedWatchlist(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
