// import API from "../api";

import moment from "moment";
import API from "axios";

import store from "../redux/store";

// import { SignOutAPI } from "../../auth/utils";

export const SignOutAPI = () => {
  // const logoutHeader = {
  //   "Content-Type": "application/json",
  //   Authorization: "Bearer " + localStorage.get("accessToken"),

  // };

  API.delete("/logout")
    .then((response) => {
      if (response.data.status) {
        localStorage.clear();
      }
    })
    .catch((err) => console.log(err.response));
};

//  RBAC  return true if a user can edit. user is the userdata stored in redux.branch id is the current
//        selected branch id which is different for arrival and buying decision.available in corresponding selectors.
export const canUserEdit = (user, branchID, featureCode) => {
  //in cases where branchID is independent, or not present, check if the feature is present anywhere.
  if (!branchID) {
    return (
      user.locationFeatures.includes(featureCode) ||
      user.nonLocationFeatures.includes(featureCode)
    );
  }

  //if branchID is given, and branch id is a user branch, check if feature is present in location features.
  // else check in non location features.

  const canEdit = user.userTerritories.includes(parseInt(branchID))
    ? user.locationFeatures.includes(featureCode)
    : user.nonLocationFeatures.includes(featureCode);
  return canEdit;
};

export const getUserMSRBACMessage = (featureMsgList, featureCode) => {
  console.log(
    "getuserMsgRabc Message ",
    featureMsgList,
    featureCode,
    typeof featureCode
  );
  let featureItem;
  featureMsgList.map((item) => {
    if (item.feature_code === featureCode) {
      featureItem = item.label;
    }
  });

  console.log("getuserMsgRabc Message1 ", featureItem);
  return featureItem;
};

//  RBAC  return true if a user can approve. user is the userdata stored in redux.branch id is the current
//        selected branch id which is different for arrival and buying decision.available in corresponding selectors.
export const canUserApprove = (user, branchID) => {
  if (user.roleID === 3 && user.userTerritories.includes(parseInt(branchID))) {
    return true;
  } else {
    return false;
  }
};

// not currently RBAC - return true if the current date is within the next two days.
export const isBeforeTwoDays = (enteredDate) => {
  console.log(
    "isbefore two days ",
    enteredDate,
    moment(enteredDate).isBefore(moment().subtract(2, "days"))
  );

  return moment(enteredDate).isBefore(
    moment().subtract(2, "days"),
    "DD-MM-YYYY"
  );
};

export const getVarietyMinPrice = (allVarietyInfo, varietyName, isMT) => {
  if (allVarietyInfo.length !== 0) {
    let minPrice = allVarietyInfo.find(
      (item) => item.variety_name === varietyName
    ).variety_price_min;

    minPrice = isMT ? minPrice * 10 : minPrice;
    return minPrice;
  } else return 0;
};
export const getVarietyMaxPrice = (allVarietyInfo, varietyName, isMT) => {
  if (allVarietyInfo.length !== 0) {
    let maxPrice = allVarietyInfo.find(
      (item) => item.variety_name === varietyName
    ).variety_price_max;

    maxPrice = isMT ? maxPrice * 10 : maxPrice;
    return maxPrice;
  } else return 1000000;
};

// Weather data setting
export const getWeather = (branchId, decisionBranches, setWeatherData) => {
  // let geoLocation = location.loc.split(",");
  // const { decisionBranches } = this.props;
  const branch =
    decisionBranches != null &&
    decisionBranches
      // eslint-disable-next-line array-callback-return
      .map((item) => {
        if (item.territory_id === branchId) {
          return item;
        }
      })
      .filter((item) => item !== undefined);

  var api = new URL("https://api.openweathermap.org/data/2.5/weather");

  // const params = {
  //   q: branch.length !== 0 ? branch[0].territory_name : "Guntur",
  //   units: "metric",
  //   appid: "15d6ded5d2fc92ca536705312036cf1e",

  // };
  let data;
  navigator.permissions
    .query({ name: "geolocation" })
    .then((result) => {
      if (result.state === "granted") {
        api.searchParams.append("lat", store.getState().weather.lat);
        api.searchParams.append("lon", store.getState().weather.lon);
      } else if (result.state === "prompt") {
        api.searchParams.append(
          "q",
          branch.length !== 0 ? branch[0].territory_name : "Guntur"
        );
      } else if (result.state === "denied") {
        api.searchParams.append(
          "q",
          branch.length !== 0 ? branch[0].territory_name : "Guntur"
        );
      }
      api.searchParams.append("units", "metric");
  api.searchParams.append("appid", "15d6ded5d2fc92ca536705312036cf1e");

  fetch(api, { method: "GET", headers: { Accept: "application/json" } })
    .then((response) => response.json())
    .then((response) => {
      //update design state
      let weatherId = response.weather[0].id;

      let weatherDesign = {
        bgUrl: "",
        icon: "",
      };

      //thundersorm
      if (weatherId >= 200 && weatherId < 300) {
        weatherDesign = {
          bgUrl:
            "http://cdn.makeuseof.com/wp-content/uploads/2011/09/AndroidLiveWallpaper11.png?92a7a3",
          icon: "stormy",
        };
      }
      //drizzle
      if (weatherId >= 300 && weatherId < 400) {
        weatherDesign = {
          bgUrl:
            "http://wakefieldnaturalists.org/wp-content/uploads/RAINBOW.jpg",
          icon: "rainbow",
        };
      }
      //rain
      if (weatherId >= 500 && weatherId < 600) {
        weatherDesign = {
          bgUrl: "http://il2.picdn.net/shutterstock/videos/7345270/thumb/1.jpg",
          icon: "rainy",
        };
      }
      //snow
      if (weatherId >= 600 && weatherId < 700) {
        weatherDesign = {
          bgUrl: "https://il2.picdn.net/shutterstock/videos/265888/thumb/1.jpg",
          icon: "snowy",
        };
      }
      //atmosphere
      /*if(weatherId >= 700 && weatherId <800) {
            
          }*/

      //clear
      if (weatherId === 800) {
        //clear day
        weatherDesign = {
          bgUrl:
            "http://images.gmanews.tv/v3/webpics/v3/2015/04/640_2015_04_19_17_28_31.jpg",
          icon: "sunny",
        };

        let time = new Date().getHours();
        let night = false;

        if (time < 6 || time > 18) {
          night = true;
        }

        //clear nigh
        if (night) {
          weatherDesign = {
            bgUrl:
              "https://d7bmbwiglir4w.cloudfront.net/sites/default/files/imagecache/inline/blog/BenCanales5_ImnahaNights.jpg",
            icon: "starry",
          };
        }
      }

      //clouds && atmosphere
      if (
        (weatherId >= 801 && weatherId < 900) ||
        (weatherId >= 700 && weatherId < 800)
      ) {
        weatherDesign = {
          bgUrl:
            "http://photovide.com/wp-content/uploads/2012/10/Cloudy-Weather-01.jpg",
          icon: "cloudy",
        };
      }

      data = {
        locationName: response.name,
        id: response.id,
        // locationName: response.data.name + " | " + response.data.sys.country,
        weather: {
          main: response.main,
          description: response.weather[0],
          windSpeed: response.wind.speed,
          clouds: response.clouds.all,
        },
        weatherDesign: weatherDesign,
      };

      // this.props.setWeatherData(data);

      setWeatherData(data);
      //update state
      // this.setState(
      //   {
      //     loading: false,
      //     locationName: response.data.name + " | " + response.data.sys.country,
      //     weather: {
      //       main: response.data.main,
      //       description: response.data.weather[0],
      //       windSpeed: response.data.wind.speed,
      //       clouds: response.data.clouds.all,
      //     },
      //     weatherDesign: weatherDesign,
      //   },
      //   () => console.log("State changed", this.state)
      // );
    })
    .catch((err) => {
      console.log("Error getting weather !", err);
    });
    })
    .catch((error) => {
      console.error("Error checking location access:", error);
    });
};

const colorList = [
  "#FF0336",
  "#009688",
  "#2196F3",
  "#f775a1", //light pink
  "#FFEB3B",
  "#FF5722",
  "#4CAF50",
  "#3F51B5",
  "#9C27B0",
  "#757575",
];

export const getRandomColor = (idx) => {
  // var letters = "0123456789ABCDEF";
  // var color = "#";
  // for (var i = 0; i < 6; i++) {
  //   color += letters[Math.floor(Math.random() * 16)];
  // }
  // return color;
  return idx <= 10 ? colorList[idx] : "#000";
};

const MAP_MY_INDIA_REST_API_KEY = "4set6qcqyawv6gwmelh3y5n9gwrxgrcv";

export default MAP_MY_INDIA_REST_API_KEY;
