import React from "react";
import "./forgot-password.styles.css";
import { Button, Form, Icon, Input, Modal, Typography, message } from "antd";
import API from "../../api";

class ForgotPasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDirty: false,
    };
  }

  componentDidUpdate(prevProps) {
    !this.props.open && this.props.form.resetFields();
  }

  passwordValidator = (rule, value, callback) => {
    if (value && value.trim().length < 8) {
      callback("Password must be at least 8 characters.");
    } else if (value && !value.match(/[a-z]/)) {
      callback("Password must contain at least one lowercase letter.");
    } else if (value && !value.match(/[A-Z]/)) {
      callback("Password must contain at least one uppercase letter.");
    } else if (value && !value.match(/[0-9]/)) {
      callback("Password must contain at least one digit.");
    } else if (value && !value.match(/[^a-zA-Z0-9]/)) {
      callback("Password must contain at least one special character.");
    } else callback();
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        API.post(
          "validate_otp",
          {
            user_name: this.props.userName,
            otp: values.otp,
            new_password: values.newPassword,
          },
          {}
        )
          .then((response) => {
            if (response.status) {
              this.props.parentForm.setFields({
                username: { value: this.props.userName, errors: "" },
              });
              this.props.parentForm.setFields({
                password: { value: values.newPassword, errors: "" },
              });
              this.props.submit({
                username: this.props.userName,
                password: values.newPassword,
              });
              this.handleClose();
            } else {
              message.error("Something went wrong, please try again.");
            }
          })
          .catch((err) => {
            if (err.response) message.error(err.response.data.message);
            else message.error("Something went wrong, please try again.");
          });
      }
    });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("newPassword")) {
      callback("Please input similar new and confirm passwords!");
    } else callback();
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirmPassword"], { force: true });
    }
    this.passwordValidator(rule, value, callback);
  };

  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  handleClose = () => this.props.setClose();

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Modal
        title={<div style={{ textAlign: "center" }}>Forgot Password</div>}
        visible={this.props.open}
        keyboard={false}
        maskClosable={false}
        footer={null}
        onCancel={this.handleClose}
        centered
        width={330}
      >
        <Typography.Text strong>
          Please check your Email for OTP
        </Typography.Text>
        <Form
          className="modal-input"
          onSubmit={this.handleSubmit}
          autoComplete="off"
        >
          <Form.Item>
            {getFieldDecorator("otp", {
              rules: [
                {
                  required: true,
                  message: "Please input One Time Password (OTP)!",
                },
                {
                  pattern: /^[0-9]{6}$/,
                  message: "Please input proper One Time Password!",
                },
              ],
            })(
              <Input
                size="large"
                prefix={
                  <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder="OTP Number"
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("newPassword", {
              rules: [
                {
                  required: true,
                  message: "Please input your new password!",
                },
                {
                  validator: this.validateToNextPassword,
                },
              ],
            })(
              <Input.Password
                size="large"
                prefix={
                  <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder="New Password"
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("confirmPassword", {
              rules: [
                {
                  required: true,
                  message: "Please input your confirm password!",
                },
                {
                  validator: this.compareToFirstPassword,
                },
              ],
            })(
              <Input.Password
                size="large"
                onBlur={this.handleConfirmBlur}
                prefix={
                  <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder="Confirm Password"
              />
            )}
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Update Password
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

const ForgotPassword = Form.create({ name: "forgot_password" })(
  ForgotPasswordForm
);

export default ForgotPassword;
