export const AlertsActionTypes = {
  //for alerts tab
  SET_ALERTS_DATA: "SET_ALERTS_DATA",
  ADD_ALERTS_DATA: "ADD_ALERTS_DATA",

  //for sidemenu alerts and watchlist
  SET_DISPLAYED_ALERTS: "SET_DISPLAYED_ALERTS",
  SET_DISPLAYED_WATCHLIST: "SET_DISPLAYED_WATCHLIST",

  //for user groups tab
  SET_USER_GROUP_DATA: "SET_USER_GROUP_DATA",

  // for edit watchlist option in side menu
  SET_INTERESTED_WATCHLIST: "SET_INTERESTED_WATCHLIST",
  ADD_INTERESTED_WATCHLIST_ITEM: "ADD_INTERESTED_WATCHLIST_ITEM",

  //for news tab
  SET_NEWS_DATA: "SET_NEWS_DATA",
};
