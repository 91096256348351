import { combineReducers } from "redux";

import userReducer from "./user/user.reducer";
import sideMenuReducer from "./side-menu/side-menu.reducer";
import mapReducer from "./map/map.reducer";
import summaryReducer from "./summary/summary.reducer";
import arrivalEntryReducer from "./arrival-entry/arrival-entry.reducer";
import buyingDecisionReducer from "./buying-decision/buying-decision.reducer";
import recommendationReducer from "./buying-recommendation/buying-recommendation.reducer";
import alertsReducer from "./alerts/alerts.reducer";
import weatherReducer from "./weather/weather.reducer";

export default combineReducers({
  user: userReducer,
  menu: sideMenuReducer,
  map: mapReducer,
  summary: summaryReducer,
  arrival: arrivalEntryReducer,
  buyingDecision: buyingDecisionReducer,
  recommendation: recommendationReducer,
  alerts: alertsReducer,
  weather: weatherReducer,
});
