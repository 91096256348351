import React, { Component } from "react";
// import { Doughnut, Bar, Line, Pie } from "react-chartjs-2";
import { Tabs, Icon } from "antd";

import UserManagement from "../../pages/user-management/user-management.page";
import Alerts from "../../pages/alerts/alerts.page";
import UserGroups from "../../pages/user-groups/user-groups.page";
import NewsTab from "../../pages/news/news.page";

// icons
import { ReactComponent as IconBuyingRecommendation } from "../../assets/buyingRecommendation.svg";
import { ReactComponent as IconMonthlyPrice } from "../../assets/monthlyPrice.svg";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectUserData } from "../../redux/user/user.selectors";

import { canUserEdit } from "../../auth/utils";
import { FeatureCodes } from "../../auth/data";

const { TabPane } = Tabs;

/**
 * @component
 * @description The admin tab parent component.
 * Contains user management, alerts, user groups and news as sub-tabs
 */
class AdminTabs extends Component {
  state = { tabKey: 0 };
  render() {
    return (
      <div className="report-tabs" style={{maxHeight:"599px"}}>
        <Tabs
          //   onChange={() => this.setState({ tabKey: Date.now() + "" })}
          // onChange={value => console.log(value)}
          animated={false}
          className={`main-content 
        `}
          defaultActiveKey="1"
        >
          {canUserEdit(this.props.userData, null, FeatureCodes.ADMIN_ACCESS) ? (
            <TabPane
              key="21"
              forceRender
              tab={
                <div className="tab-title-container">
                  <div>
                    <IconBuyingRecommendation className="tab-title-image" />
                  </div>
                  User Management
                </div>
              }
            >
              <UserManagement />
            </TabPane>
          ) : null}

          {canUserEdit(
            this.props.userData,
            null,
            FeatureCodes.ALERTS_PUBLISHING
          ) ? (
            <TabPane
              forceRender
              tab={
                <div className="tab-title-container">
                  <div>
                    <IconMonthlyPrice className="tab-title-image" />
                  </div>
                  Alerts
                </div>
              }
              key="22"
            >
              <Alerts />
            </TabPane>
          ) : null}

          {canUserEdit(this.props.userData, null, FeatureCodes.ALERTS_GROUP) ? (
            <TabPane
              forceRender
              tab={
                <div className="tab-title-container">
                  <div>
                    <IconMonthlyPrice className="tab-title-image" />
                  </div>
                  User Groups
                </div>
              }
              key="23"
            >
              <UserGroups />
            </TabPane>
          ) : null}

          {canUserEdit(this.props.userData, null, FeatureCodes.ALERTS_GROUP) ? (
            <TabPane
              forceRender
              tab={
                <div className="tab-title-container">
                  <div>
                    <IconMonthlyPrice className="tab-title-image" />
                  </div>
                  News
                </div>
              }
              key="24"
            >
              <NewsTab />
            </TabPane>
          ) : null}
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
});

export default connect(mapStateToProps, null)(AdminTabs);
