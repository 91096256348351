import { SideMenuActionTypes } from "./side-menu.types";

export const collapseSidemenu = () => {
  return {
    type: SideMenuActionTypes.COLLAPSE_MENU
  };
};

export const setMenuCollapseState =(status)=>{
    return({
        type:SideMenuActionTypes.SET_COLLAPSE_STATE,
        payload:status
    })
}


