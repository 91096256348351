import React from "react";
import "./news.styles.css";
import { Component } from "react";

import { Table, Modal, Switch, Button, Input, Select, message } from "antd";
import editUserImage from "../../assets/edit-user.svg";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { setNews } from "../../redux/alerts/alerts.actions";
import { selectAllNews } from "../../redux/alerts/alerts.selectors";

import NewsForm from "../../components/news-form/news-form.component";

import API from "../../api";

import moment from "moment";

/**
 * @description News Tab Component
 *
 * @component
 */
class NewsTab extends Component {
  state = {
    isModalVisible: false,
    isAdd: false,
    currentRecord: null,
  };

  componentDidMount() {
    //#region api to get news

    API.get("/user/news")
      .then((newsResponse) => {
        if (newsResponse.status) {

          this.props.setNews(newsResponse.data.data);
        } else {
          message.warning("Could not retrieve news. Please try.");
        }
      })
      .catch((err) => {
        message.error(
          "Could not retrieve news. Please check your network connectivity."
        );
      });

    //#endregion
  }
  render() {
    const userTableProps = {
      pagination: false,
      size: "default",
      bordered: true,
      scroll: { x: 200, y: window.innerHeight - 214 },
    };

    // set the user table data
    /**
     * @function
     * @description Retrieve news from redux store and assign as the data source of the table.
     * @memberof NewsTab
     */
    const setUserTableData = () => {

      const newsTableData = this.props.allNews.map((news) => {
        return {
          key: news.news_id,
          expiryDate: news.expiry_date,
          newsURL: news.news_url,
          userData: news,
          description: news.news_text,
        };
      });

      return newsTableData;
    };

    // set the edit/add news modal visibility
    /**
     * @description set the add/edit popup modal visibility
     * @param {boolean} status required visibility state of the modal
     * @param {boolean} isAdd true : add news, false : edit news
     * @param {Object} record current news record
     * @memberof NewsTab
     */
    const setModalVisibility = (status, isAdd, record) => {
      this.setState((prevState) => ({
        ...prevState,
        currentRecord: record,
        isAdd: isAdd,
        isModalVisible: status,
      }));
    };

    return (
      <div
        className="user-group-container"
        style={{ height: window.innerHeight - 85, overflow: "scroll" }}
      >
        <Modal
          width={500}
          className="user-management-modal"
          title={this.state.isAdd ? "Add News" : "Edit News"}
          visible={this.state.isModalVisible}
          destroyOnClose={true}
          onOk={() => {
            setModalVisibility(false, false, null);
          }}
          onCancel={() => setModalVisibility(false, false, null)}
        >
          <NewsForm
            setNews={this.props.setNews}
            allNews={this.props.allNews}
            setModalVisibility={setModalVisibility}
            currentRecord={this.state.currentRecord}
            isAdd={this.state.isAdd}
          />
        </Modal>
        <div className="top-container">
          <Button
            onClick={() => setModalVisibility(true, true, null)}
            className="add-user-button"
          >
            + Add News
          </Button>
        </div>
        <div className="table-container">
          <Table
            {...userTableProps}
            columns={[
              {
                key: "expiryDate",
                title: "Expiry Date",
                dataIndex: "expiryDate",
                width: 50,
                // render: (expiryDate, record) =>
                //   moment(expiryDate, "DD-MM-YYYY").format("DD-MM-YYYY"),
              },
              {
                key: "newsURL",
                title: "URL",
                dataIndex: "newsURL",
                width: 150,
              },
              {
                key: "description",
                title: "Description",
                dataIndex: "description",
                width: 150,
                render: (description, record) => {
                  return (
                    <Input.TextArea
                      allowClear
                      autoSize={{ minRows: 1, maxRows: 5 }}
                      // key="remark"

                      disabled={true}
                      value={description}
                    />
                  );
                },
              },

              {
                key: "actions",
                title: "Actions",
                dataIndex: "actions",
                width: 50,
                render: (actions, record) => (
                  <div className="user-action-container">
                    <Button
                      onClick={() =>
                        setModalVisibility(true, false, record.userData)
                      }
                      className="edit-button"
                    >
                      <img
                        style={{
                          width: "15px",
                          height: "15px",
                          margin: "4px 5px 0 -6px",
                        }}
                        alt="edit"
                        src={editUserImage}
                      />
                      <h5 style={{ margin: "1px 0 0 0" }}>Edit</h5>
                    </Button>
                  </div>
                ),
              },
            ]}
            dataSource={setUserTableData()}
          />
        </div>
        )
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  allNews: selectAllNews,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setNews: (data) => dispatch(setNews(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsTab);
