import React from "react";
import "react-open-weather/lib/css/ReactWeather.css";
import "./weather-widget.styles.css";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Tooltip } from "antd";

import { getWeather } from "../../auth/utils";

import { selectWeatherData } from "../../redux/weather/weather.selectors";

import { setWeather } from "../../redux/weather/weather.actions";

import {
  selectSelectedBranchID,
  selectDecisionBranches,
} from "../../redux/buying-decision/buying-decision.selectors";
import { selectAllBranches } from "../../redux/user/user.selectors";

/**
 * @description Display weather data on top tab bar.
 * @component
 * @memberof WeatherWidget
 */
class WeatherDisplay extends React.Component {
  constructor(props) {
    super(props);
    let cDg = props.weather != null ? Math.round(props.weather.main.temp) : 0;
    let fDg = Math.round((cDg * 9) / 5 + 32);

    const date = new Date();

    this.state = {
      date: date.toJSON().slice(0, 10),
      time: date.getHours() + ":" + date.getMinutes(),
      celciusDeg: cDg,
      fahrenheitDeg: fDg,
    };
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  render() {
    return this.props.weatherDesign == null ? (
      ""
    ) : (
      <a
        href={`https://openweathermap.org/city/${this.props.locationId}`}
        target="_blank"
      >
        <Tooltip
          overlayClassName="forecast-tooltip"
          placement="bottom"
          title={"View more"}
        >
          <div className="main-w-body-container">
            <div className="w-left-container">
              <div>{this.props.locationName}</div>
              <div className="w-icon-container">
                {this.props.weatherDesign == null ? (
                  ""
                ) : (
                  <img
                    style={{
                      width: "40px",
                      height: "40px",
                      marginTop: "-5px",
                    }}
                    src={
                      process.env.PUBLIC_URL +
                      `/weather-icons/${this.props.weather.description.icon}.svg`
                    }
                    alt=""
                  />
                )}
              </div>
              <div className="w-temperature">
                {this.props.weather != null
                  ? Math.round(this.props.weather.main.temp)
                  : 0}
                °C
              </div>
              <div className="w-conditions">
                {this.props.weather != null &&
                  this.props.weather.description.main}
              </div>
            </div>
          </div>
        </Tooltip>
      </a>
    );
  }
}

/**
 * @description Weather widget container parent component
 * @component
 */
class WeatherWidget extends React.Component {
  state = {
    apiKey: "0bdc713d703508cec45de4f1c5d908fd",
    loading: true,
    locationName: "Loading...",
    showSettings: false,
    weatherUnits: "C",
    weatherDesign: {
      bgUrl: "",
      icon: "",
    },
    weather: {},
    currentBranch: this.props.selectedBranchID,
  };

  componentDidMount() {
    this.waitForWeatherData();
  }

  /**
   * @function
   * @description Wait to recieve weather data
   * @memberof WeatherWidget
   */
  waitForWeatherData() {
    if (this.props.allBranches.length !== 0) {
      getWeather(
        this.props.allBranches[0].territory_id,
        this.props.allBranches,
        this.props.setWeatherData
      );
    } else {
      setTimeout(() => {
        this.waitForWeatherData();
      }, 100);
    }
  }

  render() {
    return (
      <div className="weather-container card">
        <div className="body">
          <WeatherDisplay
            locationName={this.props.weatherData.locationName}
            locationId={this.props.weatherData.id}
            weather={this.props.weatherData.weather}
            weatherUnits={this.props.weatherData.weatherUnits}
            weatherDesign={this.props.weatherData.weatherDesign}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  decisionBranches: selectDecisionBranches,
  selectedBranchID: selectSelectedBranchID,
  allBranches: selectAllBranches,
  weatherData: selectWeatherData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setWeatherData: (value) => dispatch(setWeather(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WeatherWidget);
