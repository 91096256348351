import weatherReducer from "./weather.reducer";
import { WeatherActionTypes } from "./weather.types";

export const setWeather = (data) => {
  return {
    type: WeatherActionTypes.ADD_WEATHER_DATA,
    payload: data,
  };
};

export const setCoordinates = (data) => {
  return {
    type : WeatherActionTypes.ADD_COORDINATES,
    payload: data
  }
}
