import { UserActionTypes } from "./user.types";

export const setUserRoleData = (data) => {
  return {
    type: UserActionTypes.SET_USER_ROLE,
    payload: data,
  };
};


export const setUserLoggedStatus = (status) => {
  return {
    type: UserActionTypes.USER_LOGGED_STATUS,
    payload: status,
  };
};

export const setAccessToken = (accessToken) => {
  return {
    type: UserActionTypes.SET_ACCESS_TOKEN,
    payload: accessToken,
  };
};

export const setRefreshToken = (refreshToken) => {
  return {
    type: UserActionTypes.SET_REFRESH_TOKEN,
    payload: refreshToken,
  };
};

export const setUserRBACDetails = (
  roleID,
  userName,
  userTerritories,
  locationFeatures,
  nonLocationFeatures,
  userFeatures,
) => {
  return {
    type: UserActionTypes.SET_USER_RBAC_DETAILS,
    roleID: roleID,
    userName: userName,
    userTerritories: userTerritories,
    locationFeatures: locationFeatures,
    nonLocationFeatures: nonLocationFeatures,
    userFeatures: userFeatures,
  };
};

export const setMasterScreenRBACMessage = (data) => {
  return {
    type: UserActionTypes.SET_USER_RBAC_MS_MESSAGE,
    payload: data,
  };
};

export const setAllUsers = (data) => {
  return {
    type: UserActionTypes.SET_ALL_USERS,
    payload: data,
  };
};

export const setAllBranches = (branches) => {
  return {
    type: UserActionTypes.SET_ALL_BRANCHES,
    payload: branches,
  };
};
export const setReportData = (data) => {
  return {
    type: UserActionTypes.SET_REPORT,
    payload: data,
  };
};
