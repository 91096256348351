import { BuyingRecommendationActionTypes } from "./buying-recommendation.types";

const INITIAL_STATE = {
  suggestedData: [],
  suggestedDirectFactData: [],
  suggestedRakeData: [],
  suggestedABDData: [],
  suggestedHubData: [],
  sohAbdData: [],
};

const recommendationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BuyingRecommendationActionTypes.SET_SUGGESTED_DATA:
      return { ...state, suggestedData: action.payload };

    case BuyingRecommendationActionTypes.SET_RAKE_SUGGESTED_DATA:
      return { ...state, suggestedRakeData: action.payload };

    case BuyingRecommendationActionTypes.SET_DIRECT_FACTORY_SUGGESTED_DATA:
      return { ...state, suggestedDirectFactData: action.payload };

    case BuyingRecommendationActionTypes.SET_ABD_SUGGESTED_DATA:
      return { ...state, suggestedABDData: action.payload };

    case BuyingRecommendationActionTypes.SET_SUGGESTED_HUB_DATA:
      return { ...state, suggestedHubData: action.payload };

    case BuyingRecommendationActionTypes.SET_ABD_SOH_DATA:
      return { ...state, sohAbdData: action.payload };

    case BuyingRecommendationActionTypes.ADD_SUGGESTED_DATA:
      let updatedData = [...state.suggestedData, action.payload];
      return { ...state, suggestedData: updatedData };

    default:
      return state;
  }
};

export default recommendationReducer;
