import { AlertsActionTypes } from "./alerts.types";

const INITIAL_STATE = {
  allAlerts: [],
  allNews: [],
  userGroupData: [],
  interestedWatchlist: [],

  displayedAlerts: [],
  displayedWatchlist: [],
};

const alertsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AlertsActionTypes.SET_ALERTS_DATA:
      return { ...state, allAlerts: action.payload };

    case AlertsActionTypes.ADD_ALERTS_DATA:
      let modifiedAlertsData = [...state.allAlerts, ...action.payload];
      return { ...state, allAlerts: modifiedAlertsData };

    case AlertsActionTypes.SET_USER_GROUP_DATA:
      return { ...state, userGroupData: action.payload };

    case AlertsActionTypes.SET_NEWS_DATA:
      return { ...state, allNews: action.payload };

   
    case AlertsActionTypes.SET_INTERESTED_WATCHLIST:
      return { ...state, interestedWatchlist: action.payload };

    case AlertsActionTypes.ADD_INTERESTED_WATCHLIST_ITEM:
      let modifiedWatchlist = [...state.interestedWatchlist, action.payload];
      return { ...state, interestedWatchlist: modifiedWatchlist };

    case AlertsActionTypes.SET_DISPLAYED_ALERTS:
      return { ...state, displayedAlerts: action.payload };
    case AlertsActionTypes.SET_DISPLAYED_WATCHLIST:
      return { ...state, displayedWatchlist: action.payload };

    default:
      return { ...state };
  }
};

export default alertsReducer;
