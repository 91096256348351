import React from "react";
import "./hub-master.styles.css";
import { Component } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUserData,selectMSMessageRBAC } from "../../redux/user/user.selectors";
import { canUserEdit ,getUserMSRBACMessage} from "../../auth/utils";
import { FeatureCodes } from "../../auth/data";
// import CustomFilterMultiselect from "../../components/custom-multi-select/custom-multi-select.component";

import {
  Table,
  Spin,
  Input,
  Select,
  Button,
  message,
  Modal,
  Form,
  Radio,
} from "antd";

import CustomSelectSummary from "../../components/custom-select-summary/custom-select-summary.component";

import API from "../../api";

const { Search } = Input;
const { Option } = Select;

class HubMaster extends Component {
  state = {
    isModalVisible: false,
    isAdd: false,
    currentRecord: null,
    userBranches: null,
    searchText: null,
    searchUserType: 0,
    hubTableData: [],
    isSubmitted: false,
    primaryHubList: [],
    hubRegionList: [],
    buyNoBuyList: [],
    mandiBranchList: [],
    isSpinnerOnLoad: false,
    newData: {
      Hub_unloading_capacity: null,
      hub_id: undefined,
      hub_name: "",
      is_active: true,
      parent_id: undefined,
      region_id: undefined,
      state: "",
      storage_capacity: null,
      hub_local_freight: null,
      territory_latitude: null,
      territory_longitude: undefined,
      territory_name: "",
      territory_sap_code: "",
      territory_type_id: undefined,
      hub_region: "",
      branch_name: "",
      isEdit: false,
      buy: true,
      buy_remarks: "",
      mandi_buy: undefined,
      trader_buy: undefined,
      farmer_buy: undefined,
      hub_category: [],
    },
    rowData: undefined,
    selectedBranchValue: undefined,
    selectedBranchName: "",
    selectedBranchList: [],
    testList: []
  };

  componentDidMount() {
    this.setState({ isSpinnerOnLoad: true });
    this.getMandiBranch();
    this.getHubRegion();
    this.getnobuylist();
  }

  getMasterHub = async () => {
    this.setState({ isSpinnerOnLoad: true });
    // const headers = {
    //   "Content-Type": "application/json",
    //   Authorization: "Bearer " + localStorage.getItem("accessToken"),
    // };
    const params = {
      hub_branch_id:
        this.state.selectedBranchValue === null
          ? null
          : [this.state.selectedBranchValue],
    };

    await API.get("/master_hub", { params: params })
      .then((varietyResponse) => {
        let value, value1;
        varietyResponse.data.data.map((dataDetailItem, index) => {
          dataDetailItem.isEdit = false;
          dataDetailItem.key = index;
          // dataDetailItem.hub_category=[dataDetailItem.farmer_buy!==null? dataDetailItem.farmer_buy:0,dataDetailItem.mandi_buy,dataDetailItem.trader_buy];

        });
        this.setState({
          hubTableData: varietyResponse.data.data,
          isSpinnerOnLoad: false,
          isAdd: true,
          testList: varietyResponse.data.data[0].hub_category
        });

      })
      .catch((err) => {
        if (err) {

          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };

  getnobuylist = async () => {
    // const headers = {
    //   "Content-Type": "application/json",
    //   Authorization: "Bearer " + localStorage.getItem("accessToken"),
    // };

    await API.get("/parameter_for_nobuy")
      .then((hubRegion) => {
        // let modifiedData = [...hubRegion.data.data];
        // modifiedData.push({ region_id: null, region_name: "All" });
        let modifiedData = [];
        hubRegion.data.data.map((item, index) => {
          modifiedData.push({ id: index, param_name: item[0] });
        });

        this.setState(
          {
            buyNoBuyList: modifiedData,
          },
        );
      })
      .catch((err) => {
        this.setState({ isSpinnerOnLoad: false });
        if (err) {

          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
      });
  };

  getHubRegion = async () => {
    // const headers = {
    //   "Content-Type": "application/json",
    //   Authorization: "Bearer " + localStorage.getItem("accessToken"),
    // };

    await API.get("/list_region")
      .then((hubRegion) => {
        let modifiedData = [...hubRegion.data.data];
        modifiedData.push({ region_id: null, region_name: "All" });

        this.setState(
          {
            selectedRegionList: modifiedData,
            hubRegionList: hubRegion.data.data,
            selectedRegionValue: hubRegion.data.data[0].region_id,
            selectedRegionName: hubRegion.data.data[0].region_name
          },
        );
      })
      .catch((err) => {
        this.setState({ isSpinnerOnLoad: false });
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
      });
  };

  getMandiBranch = async () => {
    this.setState({ isSpinnerOnLoad: true });
    // const headers = {
    //   "Content-Type": "application/json",
    //   Authorization: "Bearer " + localStorage.getItem("accessToken"),
    // };

    await API.get("/list_branch")
      .then((mandiBranch) => {

        let modifiedData = [...mandiBranch.data.data];
        modifiedData.push({ branch_id: null, branch_name: "All" });
        this.setState(
          {
            selectedBranchList: modifiedData,
            mandiBranchList: mandiBranch.data.data,
            selectedBranchValue: mandiBranch.data.data[0].branch_id,
            selectedBranchName: mandiBranch.data.data[0].branch_name,
          },

          () => this.getMasterHub()
        );
      })
      .catch((err) => {
        this.setState({ isSpinnerOnLoad: false });
        this.getMandiBranch();
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
      });
  };

  setTableData = () => {
    if (
      this.state.hubTableData !== undefined &&
      this.state.hubTableData.length > 0
    ) {

      const userTableData = this.state.hubTableData.map((user, index) => {
        return {
          key: user.key,
          Hub_unloading_capacity: user.Hub_unloading_capacity,
          is_active: user.is_active,
          parent_id: user.parent_id,
          territory_id: user.territory_id,
          hub_local_freight: user.hub_local_freight,
          territory_latitude: user.territory_latitude,
          territory_longitude: user.territory_longitude,
          territory_name: user.territory_name,
          territory_sap_code: user.territory_sap_code,
          territory_type_id: user.territory_type_id,
          hub_id: user.hub_id,
          hub_name: user.hub_name,
          region_id: user.region_id,
          state: user.state,
          storage_capacity: user.storage_capacity,
          hub_region: user.hub_region,
          branch_name: user.branch_name,
          isEdit: user.isEdit,
          buy: user.buy,
          buy_remarks: user.buy_remarks,
          mandi_buy: user.mandi_buy,
          trader_buy: user.trader_buy,
          farmer_buy: user.farmer_buy,
          hub_category: user.hub_category,
        };
      });


      let dataAfterSearch = userTableData;

      if (this.state.searchText) {
        dataAfterSearch = dataAfterSearch.filter(
          (item) =>
            (item.hub_name !== null &&
              item.hub_name
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase())) ||
            (item.hub_region !== null &&
              item.hub_region
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase())) ||
            (item.branch_name !== null &&
              item.branch_name
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase()))
        );
      }

      return dataAfterSearch;
    } else {
      return this.state.hubTableData;
    }
  };

  submitTableRowData = (row, isPopup) => {
    this.setState({ isSpinnerOnLoad: true, isSubmitted: true });
    let warningmessage = "";
    if (
      row.hub_name === null ||
      row.hub_name === undefined ||
      row.hub_name === ""
    ) {
      warningmessage = "Please enter Hub";
    } else if (
      row.hub_region === null ||
      row.hub_region === undefined ||
      row.hub_region === ""
    ) {
      warningmessage = "Please select Hub Region";
    } else if (
      row.storage_capacity === null ||
      row.storage_capacity === undefined ||
      row.storage_capacity === "" ||
      row.storage_capacity === 0
    ) {
      warningmessage = "Please enter Storage Capacity";
    } else if (
      row.Hub_unloading_capacity === null ||
      row.Hub_unloading_capacity === undefined ||
      row.Hub_unloading_capacity === "" ||
      row.Hub_unloading_capacity === 0
    ) {
      warningmessage = "Please enter Unloading Capacity";
    }
    else if (row.territory_sap_code === null || row.territory_sap_code === undefined || row.territory_sap_code === "") {
      warningmessage = "Please enter the SAP code";
    }
    else if ((row.buy === false || row.buy === 0) && (row.buy_remarks === null ||
      row.buy_remarks === undefined ||
      row.buy_remarks === ""
    )) {
      warningmessage = "Please select Buy Remarks";
    }
    else if (row.hub_category === null || row.hub_category === undefined || row.hub_category === []
    ) {
      warningmessage = "Please select Hub Category";
    }
    else if (row.mandi_buy === false && row.trader_buy === false && row.farmer_buy === false
    ) {
      warningmessage = "Please select Hub Ctegory";
    }
    // else if (row.buy_remarks === null || row.buy_remarks === undefined
    // ) {
    //   warningmessage = "Buy Remarks input should be mandatory";
    // }

    if (
      warningmessage === "" ||
      warningmessage === undefined ||
      warningmessage === null
    ) {
      this.setState({ isSubmitted: true });
      this.setState({ isSpinnerOnLoad: true });
      row.farmer_buy = 0;
      row.mandi_buy = 0;
      row.trader_buy = 0;
      row.hub_category.map((item) => {
        if (item === 1) { row.farmer_buy = 1; }

        if (item === 2) { row.mandi_buy = 1; }

        if (item === 3) { row.trader_buy = 1; }
      })

      let data = {
        hub_id: row.hub_id,
        hub_name: row.hub_name,
        branch_id: row.parent_id,
        region_id: row.region_id,
        territory_latitude:
          row.territory_latitude === "" || row.territory_latitude === undefined
            ? null
            : row.territory_latitude,
        territory_longitude:
          row.territory_longitude === "" ||
            row.territory_longitude === undefined
            ? null
            : row.territory_longitude,
        is_active: row.is_active,
        hub_sap_code:
          row.territory_sap_code === "" || row.territory_sap_code === undefined
            ? null
            : row.territory_sap_code,
        storage_capacity:
          row.storage_capacity === "" || row.storage_capacity === undefined
            ? null
            : row.storage_capacity,
        Hub_unloading_capacity: row.Hub_unloading_capacity,
        hub_local_freight: row.hub_local_freight === "" || row.hub_local_freight === undefined
          ? null
          : row.hub_local_freight,
        buy: row.buy,
        buy_remarks: row.buy_remarks,
        farmer_buy: row.farmer_buy,
        mandi_buy: row.mandi_buy,
        trader_buy: row.trader_buy,
      };
      // const headers = {
      //   "Content-Type": "application/json",
      //   Authorization: "Bearer " + localStorage.getItem("accessToken"),
      // };

      API.put("/master_hub", data)

        .then((response) => {

          if (response.status === 200 && response.data.status) {

            this.setModalVisibility(false);
            message.info(response.data.message, 5);
            this.getMasterHub();
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
          } else if (!response.data.status) {
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
            message.error(response.data.message, 5);
            // this.setModalVisibility(false);
          } else {
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
            message.error("Please try again", 5);
          }
        })
        .catch((err) => {
          if (err) {
            if (err.response && err.response.status === 400)
              message.error("Something went wrong. Please try again later.", 5);
            else {
              message.error(
                "Something went wrong.. Please try again later.",
                5
              );
            }
          } else {
            message.error("Something went wrong... Please try again later.", 5);
          }
          this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
          // this.setModalVisibility(false);
        });
    } else {
      this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
      message.error(warningmessage, 5);
    }
  };

  TableColumnHeader = (props) => {
    const { title, subtitle } = props;
    return (
      <div className="column-header-container">
        <div
          style={{
            color: "white",
            fontSize: "12px",
            whiteSpace: "nowrap",
            fontWeight: "normal",
            textAlign: "left",
          }}
        >
          {title}
        </div>
        <div
          style={{
            color: "#cbdfff",
            fontSize: "11px",
            fontWeight: "normal",
            textAlign: "left",
            // subtitle && title.length < subtitle.length ? "left" : "center"
          }}
        >
          {subtitle}
        </div>
      </div>
    );
  };

  createFilters = (label) => {
    let filterData = this.state.hubTableData;
    //#region remove duplicate objects
    let uniqueFilterData = [];

    if (label === "is_active" && this.state.hubTableData && this.state.hubTableData.length > 0) {
      uniqueFilterData = [{ text: "Yes", value: 1 }, { text: "No", value: 0 }]
    }
    else if (label === "buy" && this.state.hubTableData && this.state.hubTableData.length > 0) {
      uniqueFilterData = [{ text: "Buy", value: 1 }, { text: "No Buy", value: 0 }]
    }
    else {
      filterData.map((mainItem) =>
        uniqueFilterData.filter((item) => item[label] === mainItem[label])
          .length > 0
          ? null
          : uniqueFilterData.push(mainItem)
      );

      uniqueFilterData = uniqueFilterData.map((item) => {
        return {
          text: item[label],
          value: item[label],
        };

      });

      uniqueFilterData.sort((a, b) => a.text.localeCompare(b.text))
    }

    return uniqueFilterData;
  };

  setModalVisibility = (status) => {
    if (status) {
      const nData = {
        Hub_unloading_capacity: null,
        hub_id: undefined,
        hub_name: "",
        is_active: true,
        parent_id: undefined,
        region_id: undefined,
        state: "",
        storage_capacity: null,
        hub_local_freight: null,
        territory_latitude: null,
        territory_longitude: null,
        territory_name: "",
        territory_sap_code: null,
        territory_type_id: undefined,
        hub_region: "",
        branch_name: "",
        buy: true,
        buy_remarks: "",
        mandi_buy: undefined,
        trader_buy: undefined,
        farmer_buy: undefined,
        hub_category: undefined,
      };

      this.setState({ newData: nData });
    }

    this.setState({ isModalVisible: status });
  };

  handleChange = (e, row, index, name, isPopup) => {
    e.target.name = name;

    var alphaNumericRegex = /^[a-zA-Z0-9]*$/;
    var numberRegex = /^[0-9.]+$/;
    var onlyNumberRegex = /^[0-9]+$/;

    let a;
    if (isPopup === false) {
      a = this.state.hubTableData;

      if (e.target.name === "territory_longitude") {
        if (e.target.value === "") {
          a[row.key].territory_longitude = "";
        } else if (e.target.value.match(numberRegex)) {
          a[row.key].territory_longitude = e.target.value;
        }
      } else if (e.target.name === "territory_latitude") {
        if (e.target.value === "") {
          a[row.key].territory_latitude = "";
        } else if (e.target.value.match(numberRegex)) {
          a[row.key].territory_latitude = e.target.value;
        }
      } else if (e.target.name === "hub_local_freight") {

        if (e.target.value === "") {
          a[row.key].hub_local_freight = "";
        } else if (e.target.value.match(onlyNumberRegex)) {
          a[row.key].hub_local_freight = e.target.value;
        }

      } else if (e.target.name === "storage_capacity") {
        if (e.target.value === "") {
          a[row.key].storage_capacity = "";
        } else if (e.target.value.match(numberRegex)) {
          a[row.key].storage_capacity = e.target.value;
        }
      } else if (e.target.name === "Hub_unloading_capacity") {
        if (e.target.value === "") {
          a[row.key].Hub_unloading_capacity = "";
        } else if (e.target.value.match(numberRegex)) {
          a[row.key].Hub_unloading_capacity = e.target.value;
        }
      }
      else if (e.target.name === "hub_name") {
        a[row.key].hub_name = e.target.value;
      } else if (e.target.name === "territory_sap_code") {
        if (e.target.value === "") {
          a[row.key].territory_sap_code = "";
        } else if (e.target.value.match(alphaNumericRegex)) {
          a[row.key].territory_sap_code = e.target.value.toUpperCase();
        }
      } 


      this.setState({ hubTableData: a });
    } else {
      a = this.state.newData;

      if (e.target.name === "territory_longitude") {
        if (e.target.value === "") {
          a.territory_longitude = "";
        } else if (e.target.value.match(numberRegex)) {
          a.territory_longitude = e.target.value;
        }
      } else if (e.target.name === "territory_latitude") {
        if (e.target.value === "") {
          a.territory_latitude = "";
        } else if (e.target.value.match(numberRegex)) {
          a.territory_latitude = e.target.value;
        }
      } else if (e.target.name === "storage_capacity") {
        if (e.target.value === "") {
          a.storage_capacity = "";
        } else if (e.target.value.match(numberRegex)) {
          a.storage_capacity = e.target.value;
        }
      } else if (e.target.name === "Hub_unloading_capacity") {
        if (e.target.value === "") {
          a.Hub_unloading_capacity = "";
        } else if (e.target.value.match(numberRegex)) {
          a.Hub_unloading_capacity = e.target.value;
        }
      }
      else if (e.target.name === "hub_name") {
        a.hub_name = e.target.value;
      } else if (e.target.name === "territory_sap_code") {
        if (e.target.value === "") {
          a.territory_sap_code = "";
        } else if (e.target.value.match(alphaNumericRegex)) {
          a.territory_sap_code = e.target.value.toUpperCase();
        }
      } else if (e.target.name === "hub_local_freight") {

        if (e.target.value === "") {
          a.hub_local_freight = "";
        } else if (e.target.value.match(onlyNumberRegex)) {
          a.hub_local_freight = e.target.value;
        }
      }

      this.setState({ newData: a });
    }
  };

  handleDropdownChange = (value, row, target, index, optionlabel, isPopup) => {
    let branchLabel, branchid;
    if (target === "hub_region") {
    }

    let a;
    if (isPopup === false) {
      a = this.state.hubTableData;

      if (target === "is_active") {
        a[row.key].is_active = Boolean(value);
      } else if (target === "buy") {
        a[row.key].buy = value;
      } else if (target === "hub_region") {
        a[row.key].hub_region = optionlabel;
        a[row.key].region_id = value;
      } else if (target === "branch_name") {
        a[row.key].parent_id = value;
        a[row.key].branch_name = optionlabel;
      } else if (target === "branch_region") {
        a[row.key].branch_region = optionlabel;
        // a[index].hub_id = value;
      } else if (target === "buy_remarks") {
        a[row.key].buy_remarks = optionlabel;
      } else if (target === "mandi_buy") {
        a[row.key].mandi_buy = value;
      } else if (target === "trader_buy") {
        a[row.key].trader_buy = value;
      } else if (target === "farmer_buy") {
        a[row.key].farmer_buy = value;
      }

      this.setState({ hubTableData: a });

    } else {
      a = this.state.newData;

      if (target === "is_active") {
        a.is_active = Boolean(value);
      } else if (target === "buy") {
        a.buy = value;
      } else if (target === "hub_region") {
        a.hub_region = optionlabel;
        a.region_id = value;
        a.parent_id = branchid;
        a.branch_name = branchLabel;
      } else if (target === "branch_name") {
        a.parent_id = value;
        a.branch_name = optionlabel;
      } else if (target === "branch_region") {
        a.branch_region = optionlabel;
        // a[index].hub_id = value;
      } else if (target === "buy_remarks") {
        a.buy_remarks = optionlabel;
        // a[index].hub_id = value;
      } else if (target === "mandi_buy") {
        a.mandi_buy = value;
      } else if (target === "trader_buy") {
        a.trader_buy = value;
      } else if (target === "farmer_buy") {
        a.farmer_buy = value;
      }
      this.setState({ newData: a });
    }
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  handleEditChange = (row, index) => {
//     if(this.canUserEdit(row,row.branch_id))
// {
    this.setState({ rowData: row });
    let tempData = this.state.hubTableData.map((item) => {
      if (item.hub_id === row.hub_id) {
        return {
          ...item,
          isEdit: true,
        };
      } else {
        return item;
      }
    });
    this.setState({ hubTableData: tempData });
    // setTableData();
  // }
  // else{
  //   message.info("User can edit only their respective branches",1);
  // }
  };

  handleCancelClick = (rowData, index) => {
    let tempData = this.state.hubTableData.map((item) => {
      if (item.hub_id === rowData.hub_id) {
        return this.state.rowData;
      } else {
        return item;
      }
    });
    this.setState({ hubTableData: tempData });

    // let a = this.state.hubTableData;
    // a[index]= this.state.rowData;
    // a[index].isEdit = false;
    // this.setState({ hubTableData: a });
  };

  canUserEdit = (user, branchID) => {
    console.log("user data , ",user,branchID, this.props.userData, this.props.userData.userTerritories,
    this.props.userData.userTerritories.includes(parseInt(branchID)));
   //  var territories =  this.props.userData.userTerritories.split(",").map((item) => parseInt(item));
   //  console.log("user data 111122233 ",territories);
   if(this.props.userData.roleID !== 10 || this.props.userData.roleID !== 2)
   {
   if (this.props.userData.userTerritories.includes(parseInt(branchID))) {
     return true;
   } else {
     return false;
   }
   }
  else{
    return true;
  }
 };

  render() {
    const userTableProps = {
      pagination: false,
      size: "default",
      bordered: true,
      scroll: { x: 200, y: window.innerHeight - 250 },
    };

    const handleRegionSelect = (value, props) => {
      this.setState(
        { selectedBranchName: props.key, selectedBranchValue: value },
        () => this.getMasterHub()
      );
    };

    const handleRegionSelect111 = (value, props) => {
      // this.GetVariety(this.props.selectedBranchID, props.key);
      // this.props.setSelectedRegionID(
      //   value === "ALL" ? "10000000000" : props.key
      // );
      this.setState((prevState) => ({
        ...prevState,
        testList: value,
      }));
    };

    const onCategorySelected = (row, value, isPopup) => {
      if (isPopup === true) {
        let a = this.state.newData;
        a.hub_category = value.length ? value : undefined;

        this.setState({ newData: a });
      }
      else {
        let tempData = this.state.hubTableData.map((item) => {
          if (item.hub_id === row.hub_id) {
            return {
              ...item,
              hub_category: value.length ? value : undefined,
            };
          } else {
            return item;
          }
        });
        this.setState({ hubTableData: tempData });
      }
    };

    const categoryList = [
      { id: 1, name: 'Farmer' },
      { id: 2, name: 'Mandi' },
      { id: 3, name: 'Trader' }
    ];

    return (
      <div
        className="hub-master-container"
        style={{
          height: window.innerHeight - 85,
          marginTop: "-45px",
        }}
      >
        <div className="top-container">
          <div className="hub-region-filter">
            <span
              style={{
                fontSize: "15px",
                marginTop: "5px",
                fontWeight: "600",
                marginLeft: "50px",
              }}
            >
              Branch&nbsp;
            </span>{" "}
            <Select
              disabled={!this.state.isAdd}
              showSearch
              optionFilterProp="children"
              className={"select-master-region"}
              mode="single"
              placeholder="Select Branch"
              value={this.state.selectedBranchValue}
              onChange={(value, props) =>
                handleRegionSelect(value, props)
              }
            >
              {this.state.selectedBranchList !== null && this.state.selectedBranchList.length &&
                this.state.selectedBranchList.map((item, idx) => (
                  <Option
                    value={item.branch_id}
                    key={item.branch_name}
                  >
                    {item.branch_name}
                  </Option>
                ))}
            </Select>

          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row-reverse",
              placeItems: "flex-end",
              marginRight: "25px",
              marginBottom: "10px",
            }}
          >
            <Search
              disabled={!this.state.isAdd}
              placeholder="Search"
              allowClear
              onChange={(e) => this.setState({ searchText: e.target.value })}
              style={{ width: "45%" }}
            />
            <Button
              className="add-master-button"
              style={{
                height: "25px",
                width: "70px",
                backgroundColor: "#0b133d",
                color: "white",
              }}
              disabled={!canUserEdit(
                this.props.userData,
                null,
                FeatureCodes.MS_HUB_EDIT
              )  || !this.state.isAdd}
              onClick={() => this.setModalVisibility(true)}
            >
              &#10010; Add
            </Button>
          </div>
        </div>

        <div>
        <div
        style={{
          marginLeft: "25px",
          marginTop: "-8px",
          fontSize: "14px",
          color: "red",
          display: "flex",
          float: "left",
        }}
      > {getUserMSRBACMessage(this.props.messageAccess,FeatureCodes.MS_HUB_EDIT)}
      </div>
        <div
          style={{
            marginRight: "20px",
            marginTop: "-8px",
            fontSize: "15px",
            color: "#0B133D",
            display: "flex",
            float: "right",
          }}
        >Total number of records:&nbsp;
          <b>{this.state.hubTableData && this.setTableData().length}</b>
        </div>
        </div>

        <Spin
          spinning={this.state.isSpinnerOnLoad}
          size={"large"}
          tip="Loading..."
          style={{ alignSelf: "center", zIndex: 9999, marginTop: "20px" }}
        >
          <Modal
            className="add-master-modal"
            title={"Add Hub"}
            onCancel={this.handleCancel}
            footer={false}
            visible={this.state.isModalVisible}
            destroyOnClose={true}
          >
            <div className="master-form-container">
              <Form
                layout="horizontal"
                //   onValuesChange={onFormLayoutChange}
                size={"small"}
              // onSubmit={submitTableRowData(this.state.newData)}
              >
                <div className="form-top-container">
                  <div>
                    <div
                      className="checkbox-group1"
                      style={{
                        display: "inline-block",
                        width: "30%",
                        marginRight: 7,
                      }}
                    >
                      <Form.Item label="Hub">
                        <Input
                          style={{ marginRight: "2px" }}
                          maxLength={100}
                          value={this.state.newData.hub_name}
                          name="hub_name"
                          onChange={(e) =>
                            this.handleChange(
                              e,
                              this.state.newData,
                              -0,
                              "hub_name",
                              true
                            )
                          }
                        />
                      </Form.Item>
                    </div>

                    <div
                      className="form-top-container"
                      style={{
                        display: "inline-block",
                        width: "33%",
                        marginRight: 7,
                      }}
                    >
                      <Form.Item label="Region">
                        <Select
                          showSearch
                          optionFilterProp="children"
                          className="remarks-select"
                          name="hub_region"
                          defaultValue={this.state.newData.hub_region}
                          value={this.state.newData.hub_region}
                          onSelect={(value, option) =>
                            this.handleDropdownChange(
                              value,
                              this.state.newData,
                              "hub_region",
                              -0,
                              option.props.children,
                              true
                            )
                          }
                        >
                          {this.state.hubRegionList &&
                            this.state.hubRegionList.length &&
                            this.state.hubRegionList.map((hubItem, idx) => (
                              <Option
                                key={hubItem.region_id}
                                value={hubItem.region_id}
                              >
                                {hubItem.region_name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </div>

                    <div
                      className="form-top-container"
                      style={{
                        display: "inline-block",
                        width: "30%",
                        marginRight: 7,
                      }}
                    >
                      <Form.Item label="Branch">
                        <Select
                          showSearch
                          optionFilterProp="children"
                          className="remarks-select"
                          name="branch_name"
                          defaultValue={this.state.newData.branch_name}
                          value={this.state.newData.branch_name}
                          onSelect={(value, option) =>
                            this.handleDropdownChange(
                              value,
                              this.state.newData,
                              "branch_name",
                              -0,
                              option.props.children,
                              true
                            )
                          }
                        >
                          {this.state.mandiBranchList &&
                            this.state.mandiBranchList.length &&
                            this.state.mandiBranchList.map((hubItem, idx) => (
                              <Option
                                key={hubItem.branch_id}
                                value={hubItem.branch_id}
                              >
                                {hubItem.branch_name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </div>

                    {/*} {this.state.newData.branch_name !== "" ? (
                      <div
                        className="left"
                        style={{
                          display: "inline-block",
                          width: "30%",
                          marginLeft: 10,
                        }}
                      >
                        <span style={{ display: "block", color: "black" }}>
                          Branch:
                        </span>
                        <span style={{ display: "block" }}>
                          <b>{this.state.newData.branch_name}</b>
                        </span>
                      </div>
                      ) : null}*/}
                  </div>
                  <hr />
                  <div
                    className="checkbox-group1"
                    style={{
                      display: "inline-block",
                      width: "30%",
                      marginRight: 7,
                    }}
                  >
                    <Form.Item label="SAP Code">
                      <Input
                        style={{ marginRight: "2px" }}
                        maxLength={100}
                        value={this.state.newData.territory_sap_code}
                        name="territory_sap_code"
                        onChange={(e) =>
                          this.handleChange(
                            e,
                            this.state.newData,
                            -0,
                            "territory_sap_code",
                            true
                          )
                        }
                      />
                    </Form.Item>
                  </div>

                  <div
                    className="checkbox-group1"
                    style={{
                      display: "inline-block",
                      width: "30%",
                      marginRight: 7,
                    }}
                  >
                    <Form.Item label="Storage Capacity (MT)">
                      <Input
                        style={{ marginRight: "2px" }}
                        maxLength={100}
                        value={this.state.newData.storage_capacity}
                        name="storage_capacity"
                        onChange={(e) =>
                          this.handleChange(
                            e,
                            this.state.newData,
                            -0,
                            "storage_capacity",
                            true
                          )
                        }
                      />
                    </Form.Item>
                  </div>

                  <div
                    className="checkbox-group1"
                    style={{
                      display: "inline-block",
                      width: "30%",
                      marginRight: 7,
                    }}
                  >
                    <Form.Item label="Unloading Capacity (MT)">
                      <Input
                        style={{ marginRight: "2px" }}
                        maxLength={100}
                        value={this.state.newData.Hub_unloading_capacity}
                        name="Hub_unloading_capacity"
                        onChange={(e) =>
                          this.handleChange(
                            e,
                            this.state.newData,
                            -0,
                            "Hub_unloading_capacity",
                            true
                          )
                        }
                      />
                    </Form.Item>
                  </div>

                  <div
                    className="checkbox-group1"
                    style={{
                      display: "inline-block",
                      width: "30%",
                      marginRight: 7,
                    }}
                  >
                    <Form.Item label="Local Freight(₹/MT)">
                      <Input
                        // style={{ marginRight: "2px" }}
                        maxLength={100}
                        value={this.state.newData.hub_local_freight}
                        name="hub_local_freight"
                        onChange={(e) =>
                          this.handleChange(
                            e,
                            this.state.newData,
                            -0,
                            "hub_local_freight",
                            true
                          )
                        }
                      />
                    </Form.Item>
                  </div>

                  <div
                    className="checkbox-group1"
                    style={{
                      display: "inline-block",
                      width: "30%",
                      marginRight: 7,
                    }}
                  >
                    <Form.Item label="Latitude">
                      <Input
                        style={{ marginRight: "2px" }}
                        maxLength={100}
                        value={this.state.newData.territory_latitude}
                        name="territory_latitude"
                        onChange={(e) =>
                          this.handleChange(
                            e,
                            this.state.newData,
                            -0,
                            "territory_latitude",
                            true
                          )
                        }
                      />
                    </Form.Item>
                  </div>

                  <div
                    className="checkbox-group1"
                    style={{
                      display: "inline-block",
                      width: "30%",
                      marginRight: 7,
                    }}
                  >
                    <Form.Item label="Longitude">
                      <Input
                        style={{ marginRight: "2px" }}
                        maxLength={100}
                        value={this.state.newData.territory_longitude}
                        name="territory_longitude"
                        onChange={(e) =>
                          this.handleChange(
                            e,
                            this.state.newData,
                            -0,
                            "territory_longitude",
                            true
                          )
                        }
                      />
                    </Form.Item>
                  </div>

                  <div
                    className="form-top-container"
                    style={{
                      display: "inline-block",
                      width: "33%",
                      marginRight: 7,
                    }}
                  >
                    <Form.Item label="Active">
                      <Radio.Group
                        name="is_active"
                        value={this.state.newData.is_active ? 1 : 0}
                        onChange={(e, option) =>
                          this.handleDropdownChange(
                            e.target.value,
                            this.state.newData,
                            "is_active",
                            -0,
                            "",
                            true
                          )
                        }
                      >
                        <Radio value={1}>Yes</Radio>
                        <Radio value={0}>No</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </div>


                  <div
                    className="form-top-container"
                    style={{
                      display: "inline-block",
                      width: "63%",
                      marginRight: 7,
                    }}
                  >
                    <Form.Item label="Hub Category">
                      <Select
                        style={{ width: "110px", height: "35px" }}
                        maxTagCount={3}
                        showArrow
                        showSearch
                        className={"select-region"}
                        mode="multiple"
                        allowClear={true}
                        placeholder="Select regions"
                        value={this.state.newData.hub_category}
                        onChange={(value, props) =>
                          onCategorySelected(this.state.newData, value, true)
                        }

                      >
                        {categoryList !== null && categoryList.length &&
                          categoryList.map((item, idx) => (
                            <Option
                              value={item.id}
                              key={item.id}
                            >
                              {item.name}
                            </Option>
                          ))}
                      </Select>


                    </Form.Item>
                  </div>


                </div>

                <div className="form-bottom-container">
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={this.state.isSubmitted}
                      onClick={() =>
                        this.submitTableRowData(this.state.newData)
                      }
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </Modal>
          <div
            className="table-container"
            style={{ maxHeight: "450px", margin: "6px 0px" }}
          >
            <Table
              {...userTableProps}
              columns={[
                {
                  // fixed: true,
                  title: "Hub",
                  dataIndex: "hub_name",
                  key: "hub_name",
                  width: 155,
                  filters: this.createFilters("hub_name"),
                  onFilter: (value, record) => record.hub_name.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.hub_name.localeCompare(b.hub_name),
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                          disabled={!rowRecord.isEdit}
                            style={{ marginRight: "2px" }}
                            maxLength={100}
                            value={rowRecord.hub_name}
                            name="hub_name"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "hub_name",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },

                {
                  title: "Region",
                  dataIndex: "hub_region",
                  key: "hub_region",
                  width: 125,
                  filters: this.createFilters("hub_region"),
                  onFilter: (value, record) =>
                    record.hub_region.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.hub_region.localeCompare(b.hub_region),
                  render: (record, rowRecord) => {
                    return (
                      <>
                        <div
                          style={{ marginLeft: "-15px", marginRight: "-10px" }}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            disabled={!rowRecord.isEdit }
                            className="remarks-select"
                            name="hub_region"
                            defaultValue={rowRecord.hub_region}
                            value={rowRecord.hub_region}
                            onSelect={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "hub_region",
                                -0,
                                option.props.children,
                                false
                              )
                            }
                          >
                            {this.state.hubRegionList &&
                              this.state.hubRegionList.length &&
                              this.state.hubRegionList.map((hubItem, idx) => (
                                <Option
                                  key={hubItem.region_id}
                                  value={hubItem.region_id}
                                >
                                  {hubItem.region_name}
                                </Option>
                              ))}
                          </Select>
                        </div>
                      </>
                    );
                  },
                },
                {
                  title: "Branch",
                  dataIndex: "branch_name",
                  key: "branch_name",
                  width: 125,
                  filters: this.createFilters("branch_name"),
                  onFilter: (value, record) =>
                    record.branch_name.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.branch_name.localeCompare(b.branch_name),
                  render: (record, rowRecord) => {
                    return (
                      <>
                        <div
                          style={{ marginLeft: "-15px", marginRight: "-10px" }}
                        >
                          <Select
                            showSearch
                            disabled={!rowRecord.isEdit}
                            optionFilterProp="children"
                            className="remarks-select"
                            name="branch_name"
                            defaultValue={rowRecord.branch_name}
                            value={rowRecord.branch_name}
                            onSelect={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "branch_name",
                                -0,
                                option.props.children,
                                false
                              )
                            }
                          >
                            {this.state.mandiBranchList &&
                              this.state.mandiBranchList.length &&
                              this.state.mandiBranchList.map((hubItem, idx) => (
                                <Option
                                  key={hubItem.branch_id}
                                  value={hubItem.branch_id}
                                >
                                  {hubItem.branch_name}
                                </Option>
                              ))}
                          </Select>
                        </div>
                      </>
                    );
                  },
                },
                {
                  key: "territory_sap_code",
                  title: "SAP Code",
                  dataIndex: "territory_sap_code",
                  width: 100,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.territory_sap_code - b.territory_sap_code,
                  // sorter: (a, b) => a.territory_sap_code.localeCompare(b.territory_sap_code),
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                          disabled={!rowRecord.isEdit }
                            style={{ marginRight: "2px" }}
                            maxLength={100}
                            value={rowRecord.territory_sap_code}
                            name="territory_sap_code"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "territory_sap_code",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },
                {
                  key: "storage_capacity",
                  title: "Storage Capacity(MT)",
                  dataIndex: "storage_capacity",
                  width: 110,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.storage_capacity - b.storage_capacity,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                          disabled={!rowRecord.isEdit}
                            style={{ marginRight: "2px" }}
                            maxLength={100}
                            value={rowRecord.storage_capacity}
                            name="storage_capacity"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "storage_capacity",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },
                {
                  key: "Hub_unloading_capacity",
                  title: "Unloading Capacity(MT)",
                  dataIndex: "Hub_unloading_capacity",
                  width: 110,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) =>
                    a.Hub_unloading_capacity - b.Hub_unloading_capacity,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                          disabled={!rowRecord.isEdit}
                            style={{ marginRight: "2px" }}
                            maxLength={100}
                            value={rowRecord.Hub_unloading_capacity}
                            name="Hub_unloading_capacity"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "Hub_unloading_capacity",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },
                {
                  key: "hub_local_freight",
                  title: "Local Freight(₹/MT)",
                  dataIndex: "hub_local_freight",
                  width: 110,
                  sorter: (a, b) =>
                    a.hub_local_freight - b.hub_local_freight,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                          disabled={!rowRecord.isEdit }
                            style={{ marginRight: "2px" }}
                            maxLength={100}
                            value={rowRecord.hub_local_freight}
                            name="hub_local_freight"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "hub_local_freight",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },

                {
                  key: "territory_latitude",
                  title: "Latitude",
                  dataIndex: "territory_latitude",
                  width: 100,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.territory_latitude - b.territory_latitude,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                          disabled={!rowRecord.isEdit }
                            style={{ marginRight: "2px" }}
                            maxLength={100}
                            value={rowRecord.territory_latitude}
                            name="territory_latitude"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "territory_latitude",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },
                {
                  key: "territory_longitude",
                  title: "Longitude",
                  dataIndex: "territory_longitude",
                  width: 100,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) =>
                    a.territory_longitude - b.territory_longitude,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                          disabled={!rowRecord.isEdit}
                            style={{ marginRight: "2px" }}
                            maxLength={100}
                            value={rowRecord.territory_longitude}
                            name="territory_longitude"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "territory_longitude",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },
                {
                  key: "is_active",
                  title: "Active",
                  dataIndex: "is_active",
                  width: 100,
                  filters: this.createFilters("is_active"),
                  // filters: [{ text: "Yes", value: 1 }, { text: "No", value: 0 }],
                  onFilter: (value, record) =>
                    record.is_active === Boolean(value),
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div
                          style={{ marginLeft: "-15px", marginRight: "-10px" }}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            disabled={!rowRecord.isEdit}
                            defaultValue={
                              rowRecord.is_active === true ? "Yes" : "No"
                            }
                            value={rowRecord.is_active === true ? "Yes" : "No"}
                            onChange={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "is_active",
                                index,
                                option.props.children,
                                false
                              )
                            }
                            style={{ width: "70%" }}
                          >
                            <Option key={1} value={1}>
                              {"Yes"}
                            </Option>
                            <Option key={0} value={0}>
                              {"No"}
                            </Option>
                          </Select>
                        </div>
                      </>
                    );
                  },
                },
                {
                  key: "hub_category",
                  title: "Hub Category",
                  dataIndex: "hub_category",
                  width: 280,
                  // filters: this.createFilters("buy"),
                  // filters: [{ text: "Yes", value: 1 }, { text: "No", value: 0 }],
                  // onFilter: (value, record) =>
                  // record.buy === value,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div
                          style={{ width: "75%", marginLeft: "-15px", marginRight: "-10px", marginTop: "10px" }}
                        >
                          <div className="multiple-select">
                            <Select
                              maxTagCount={3}
                              showArrow
                              showSearch
                              className={"select-region"}
                              disabled={!rowRecord.isEdit }//|| this.props.userData.roleID !== 12 }
                              mode="multiple"
                              allowClear={true}
                              placeholder="Select regions"
                              value={rowRecord.hub_category}
                              onChange={(value, props) =>
                                onCategorySelected(rowRecord, value, false)
                              }

                            >
                              {categoryList !== null && categoryList.length &&
                                categoryList.map((item, idx) => (
                                  <Option
                                    value={item.id}
                                    key={item.id}
                                  >
                                    {item.name}
                                  </Option>
                                ))}
                            </Select>

                          </div>
                        </div>
                      </>
                    );
                  },
                },
                {
                  key: "buy",
                  title: "Buy",
                  dataIndex: "buy",
                  width: 120,
                  filters: this.createFilters("buy"),
                  // filters: [{ text: "Yes", value: 1 }, { text: "No", value: 0 }],
                  onFilter: (value, record) =>
                    record.buy === value,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div
                          style={{ marginLeft: "-15px", marginRight: "-10px" }}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            disabled={!rowRecord.isEdit || !canUserEdit(
                              this.props.userData,
                              null,
                              FeatureCodes.MASTER_SCREEN_HUB_BUY_EDIT
                            ) }
                            //&& (!canUserEdit(this.props.userData,rowRecord.territory_id,FeatureCodes.MASTER_SCREEN_BR_EDIT) || 
                              //!canUserEdit(this.props.userData,null,FeatureCodes.MASTER_SCREEN_OPS_EDIT) )
                              //}
                            defaultValue={
                              rowRecord.buy === 1 ? "Buy" : "No Buy"
                            }
                            value={rowRecord.buy === 1 ? "Buy" : "No Buy"}
                            onChange={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "buy",
                                index,
                                option.props.children,
                                false
                              )
                            }
                            style={{ width: "70%" }}
                          >
                            <Option key={1} value={1}>
                              {"Buy"}
                            </Option>
                            <Option key={0} value={0}>
                              {"No Buy"}
                            </Option>
                          </Select>
                        </div>
                      </>
                    );
                  },
                },
                {
                  title: "Buy Remarks",
                  dataIndex: "buy_remarks",
                  key: "buy_remarks",
                  width: 220,
                  render: (record, rowRecord) => {
                    return (
                      <>
                        <div
                          style={{ marginLeft: "-15px", marginRight: "-10px" }}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            disabled={(!rowRecord.isEdit || rowRecord.buy === 1) }//|| (this.props.userData.roleID !== 12 && this.props.userData.roleID !== 10 && this.props.userData.roleID !== 2)}
                            className="remarks-select"
                            name="buy_remarks"
                            defaultValue={rowRecord.buy_remarks}
                            value={rowRecord.buy_remarks}
                            onSelect={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "buy_remarks",
                                -0,
                                option.props.children,
                                false
                              )
                            }
                          >
                            {this.state.buyNoBuyList &&
                              this.state.buyNoBuyList.length &&
                              this.state.buyNoBuyList.map((hubItem, idx) => (

                                <Option
                                  key={hubItem.id}
                                  value={hubItem.id}
                                >
                                  {hubItem.param_name}
                                </Option>
                              ))}
                          </Select>
                        </div>
                      </>
                    );
                  },
                },
                {
                  title: "Action",
                  dataIndex: "Submit",
                  key: "Submit",
                  width: 140,

                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ display: "inline-flex", width: "100%" }}>
                          <Button
                          className={!canUserEdit(
                            this.props.userData,
                            null,
                            FeatureCodes.MS_HUB_EDIT
                          ) ? "disabledBtnClass" : "btnClass"}
                          // {!canUserEdit(this.props.userData,rowRecord.territory_id,FeatureCodes.MASTER_SCREEN_BR_EDIT) && 
                          //   !canUserEdit(this.props.userData,null,FeatureCodes.MASTER_SCREEN_OPS_EDIT) 
                          //   ? "disabledBtnClass" : "btnClass"}
                          disabled={!canUserEdit(
                            this.props.userData,
                            null,
                            FeatureCodes.MS_HUB_EDIT
                          )}
                            type="primary"
                            size={"small"}
                            style={{
                              marginLeft: 5,
                              alignSelf: "center",
                              color: "white",
                              borderRadius: 15,
                              padding: "0px 0px 2px",
                              height: 31,
                              backgroundColor: "#20346a",
                            }}
                            block
                            htmlType="submit"
                            onClick={() =>
                              rowRecord.isEdit
                                ? this.submitTableRowData(
                                  rowRecord,
                                  index,
                                  false
                                )
                                : this.handleEditChange(rowRecord, index)
                            }
                          >
                            {" "}
                            {rowRecord.isEdit ? "Submit" : "Edit"}{" "}
                          </Button>

                          <Button
                            className={
                              !rowRecord.isEdit
                                ? "disabledBtnClass"
                                : "btnClass"
                            }
                            type="primary"
                            size={"small"}
                            style={{
                              marginLeft: 5,
                              alignSelf: "center",
                              color: "white",
                              borderRadius: 15,
                              padding: "0px 0px 2px",
                              height: 31,
                              backgroundColor: "#20346a",
                            }}
                            block
                            htmlType="submit"
                            disabled={!rowRecord.isEdit}
                            onClick={() =>
                              this.handleCancelClick(rowRecord, index)
                            }
                          >
                            Cancel
                          </Button>
                        </div>
                      </>
                    );
                  },
                },
              ]}
              dataSource={this.setTableData()}
            />
          </div>


        </Spin>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
  messageAccess: selectMSMessageRBAC,
});
export default connect(mapStateToProps)(HubMaster);
//export default HubMaster;
