import React, { useState } from "react";
import "./google-map-marker.styles.css";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import {
  selectBranchID,
  selectRegionID,
  selectHubID,
  selectMandiID,
} from "../../redux/arrival-entry/arrival-entry.selectors";

import { Badge, Icon, Dropdown, Menu } from "antd";
import { Marker, Popup, Tooltip } from "react-leaflet";
import closedImage from "../../assets/close.svg";
import noArrivalsImage from "../../assets/no-entry.svg";
import tickImage from "../../assets/tick.svg";
import { OverlayView, InfoWindowF } from "@react-google-maps/api";

//////////////////////////

const setImage = (status) => {
  switch (status) {
    case "open":
      return null;
    case "closed":
      return closedImage;
    case "no arrivals":
      return noArrivalsImage;
    case "completed":
      return tickImage;
    default:
      return null;
  }
};

/**
 * @component
 * @description the mandi marker component in the map.
 * @property {latlong} position the latitude and longitude of the mandi
 * @property {number} mandiNumber the number to display on the marker
 * @property {string} mandiStatus the territory status of the mandi
 * @property {string} mandiName the name of the mandi
 * @property {number} markerMandiID the id of the mandi
 * @property {function} handleSelectChange callback on clicking the mandi marker
 *
 */
const MapMarker = (props) => {
  const {
    position,
    mandiNumber,
    mandiStatus,
    mandiName,
    currentArrivalScreen,
    handleSelectChange,
    mandiID,
    hubID,
    markerMandiID,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  return (
    // <CustomMarker className="map-marker-item" position={position}>
    //   <Tooltip className="map-marker-tooltip">{mandiName}</Tooltip>
    //   <div
    //     className={` map-marker-content ${
    //       markerMandiID === (currentArrivalScreen === "Mandi" ? mandiID : hubID)
    //         ? "selected-marker"
    //         : ""
    //     }`}
    //     onClick={() => {
    //       handleSelectChange(
    //         mandiName,
    //         currentArrivalScreen === "Mandi" ? 4 : 3
    //       );
    //     }}
    //   >
    //     {console.log("mandi marker ", props, mandiNumber)}
    //     <div className="map-marker-text">{mandiNumber}</div>
    //     <Badge
    //       className="map-marker-icon"
    //       count={
    //         mandiStatus === "closed" ? (
    //           <img
    //             className="map-marker-icon-image"
    //             alt="marker"
    //             src={setImage(mandiStatus)}
    //           />
    //         ) : mandiStatus === "no arrivals" ? (
    //           <img
    //             className="map-marker-icon-image"
    //             alt="marker"
    //             src={setImage(mandiStatus)}
    //           />
    //         ) : null
    //       }
    //     />
    //   </div>
    // </CustomMarker>
    <>
      {/* <MarkerF
        position={position}
        icon={{
          url:
            markerMandiID ===
            (currentArrivalScreen === "Mandi" ? mandiID : hubID)
              ? `data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"><circle cx="15" cy="15" r="12" fill="Orange" /><text x="15" y="20" font-size="16" font-family="Arial" font-weight="bold" text-anchor="middle" fill="MidnightBlue">${mandiNumber}</text></svg>`
              : `data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"><circle cx="15" cy="15" r="12" fill="white" /><text x="15" y="20" font-size="16" font-family="Arial" font-weight="bold" text-anchor="middle" fill="MidnightBlue">${mandiNumber}</text></svg>`,
          scaledSize: new window.google.maps.Size(30, 30),
        }}
        onClick={() => {
          handleSelectChange(
            mandiName,
            currentArrivalScreen === "Mandi" ? 4 : 3
          );
        }}
        // onRightClick={}
      /> */}
      {isOpen && (
        <InfoWindowF
          position={position}
          options={{ pixelOffset: new window.google.maps.Size(28, -20) }}
        >
          <>
            <div style={{ fontWeight: "bold" }}>{`${mandiName}`}</div>
          </>
        </InfoWindowF>
      )}
      <OverlayView
        position={position}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      >
        <Badge
          className="map-marker-icon"
          count={
            mandiStatus === "closed" ? (
              <img
                className="map-marker-icon-image"
                alt="marker"
                src={setImage(mandiStatus)}
              />
            ) : mandiStatus === "no arrivals" ? (
              <img
                className="map-marker-icon-image"
                alt="marker"
                src={setImage(mandiStatus)}
              />
            ) : null
          }
          onMouseOver={() => {
            setIsOpen(true);
          }}
          onMouseOut={() => {
            setIsOpen(false);
          }}
        >
          <div
            className={`map-marker-content ${
              markerMandiID ===
              (currentArrivalScreen === "Mandi" ? mandiID : hubID)
                ? "selected-marker"
                : ""
            }`}
            onClick={() => {
              handleSelectChange(
                mandiName,
                currentArrivalScreen === "Mandi" ? 4 : 3
              );
            }}
          >
            {mandiNumber}
          </div>
        </Badge>
      </OverlayView>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  branchID: selectBranchID,
  regionID: selectRegionID,
  hubID: selectHubID,
  mandiID: selectMandiID,
});

export default connect(mapStateToProps)(MapMarker);
