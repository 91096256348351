export const ArrivalActionTypes = {
  SET_ARRIVAL_DATA: "SET_ARRIVAL_DATA",

  SET_ALL_MANDIS:'SET_ALL_MANDIS',

  SET_SUMMARY_VISIBILITY: "SET_SUMMARY_VISIBILITY",
  SET_BRANCHES: "SET_BRANCHES",
  SET_REGIONS: "SET_REGIONS",
  SET_HUBS: "SET_HUBS",
  SET_MANDIS: "SET_MANDIS",

  SET_VARIETIES: "SET_VARIETIES",
  SET_REMAINING_VARIETY_LIST: "SET_REMAINING_VARIETY_LIST",
  ADD_VARIETIES: "ADD_VARIETIES",

  SET_HUB_VARIETIES: "SET_HUB_VARIETIES",
  SET_REMAINING_HUB_VARIETY_LIST: "SET_REMAINING_HUB_VARIETY_LIST",
  ADD_HUB_VARIETIES: "ADD_HUB_VARIETIES",

  SAVE_ALL_VARIETY_INFO: "SAVE_ALL_VARIETY_INFO",
  SET_BRANCH_ID: "SET_BRANCH_ID",
  SET_REGION_ID: "SET_REGION_ID",
  SET_HUB_ID: "SET_HUB_ID",
  SET_MANDI_ID: "SET_MANDI_ID"
};
