import React from "react";
import "./custom-icon.styles.css";

/**
 * @component
 * @description Component used to display customized icons in arrival entry cards.
 * @property {image} image image to be displayed in the icon
 * 
 */
const CustomIcon = props => {
  const { toggle, image, isCollapsed } = props;
  return (
    <div
      className={` ${
        toggle ? `icon-background icon-container` : `icon-container`
      }   `}
      style={isCollapsed ? { justifyContent: "flex-start" } : {}}
    >
      <div className="trigger" onClick={toggle}>
        <img
          alt="icon"
          src={image}
          className={`menu-icon ${toggle ? "toggle-icon" : ""}`}
        />
      </div>
    </div>
  );
};

export default CustomIcon;
