import React from "react";
import "./monthly-price.styles.css";
import { Component } from "react";

import { Modal, message, Spin,DatePicker,Select } from "antd";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectAllBranches } from "../../redux/user/user.selectors";
import { selectAllVarietyInfo } from "../../redux/arrival-entry/arrival-entry.selectors";

import { selectUserData } from "../../redux/user/user.selectors";

import TemporalExpandableTable from "../../components/temporal-expandable-table/temporal-expandable-table.component";
import CustomSelectTemporal from "../../components/custom-select-temporal/custom-select-temporal.component";

import API from "../../api";
import {
  getVarietyMaxPrice,
  getVarietyMinPrice,
  canUserEdit,
} from "../../auth/utils";
import { FeatureCodes } from "../../auth/data";
const { RangePicker } = DatePicker;
const { Option } = Select;
/**
 * @component
 * @description The monthly price tab component. Contains the monthly forecast values and options to override.
 */
class MonthlyPrice extends Component {
  state = {
    isLoading: false,
    isModalVisible: false,
    filters: [],
    temporalData: [],
    selectedMonthID: undefined,
    selectedrunCount:undefined,
    qcThresholds: null,
    yearList:[],
    selectedYearValue:undefined,
  };

  setSpinnerStatus = (status) => {
    this.setState({ isLoading: status });
  };

  setQCThresholds = (thresholdValues) => {
    const qcThresholds = {
      qc_model_comparison: thresholdValues.find(
        (item) => item.qc_type === "qc_model_comparison"
      ).qc_score,
      qc_prem_vs_nonprem: thresholdValues.find(
        (item) => item.qc_type === "qc_prem_vs_nonprem"
      ).qc_score,
      qc_state_trend: thresholdValues.find(
        (item) => item.qc_type === "qc_state_trend"
      ).qc_score,
      qc_variety_trend: thresholdValues.find(
        (item) => item.qc_type === "qc_variety_trend"
      ).qc_score,
    };
    this.setState({
      qcThresholds: qcThresholds,
    });
  };

  componentDidMount() {
    this.setSpinnerStatus(true);
    //#region call the api to get the filter values.
    this.getYearList();

    API.get("/recommendations/monthly/price")
      .then((filterResponse) => {
        if (filterResponse.data.status) {
          this.setSpinnerStatus(false);
          if (filterResponse.data.data.filter.length !== 0) {
            this.setState({
              filters: filterResponse.data.data.filter,
              selectedMonthID: filterResponse.data.data.filter[0].month,
              selectedrunCount: filterResponse.data.data.filter[0].run_cnt,
            });

            this.setQCThresholds(filterResponse.data.data.qc_threshold);

            //#region setting the first temporal data

            if (filterResponse.data.data.temporal_price_data.length !== 0) {
              const modifiedTemporalData = filterResponse.data.data.temporal_price_data.map(
                (variety) => {
                  return {
                    ...variety,
                    branches: variety.branches.map((branch) => {
                      return {
                        key: variety.variety_id + "_" + branch.branch_id,
                        branch_id: branch.branch_id,

                        variety_id: variety.variety_id,
                        branch: branch.state,
                        qc_model_comparison: branch.qc_model_comparison,
                        qc_prem_vs_nonprem: branch.qc_prem_vs_nonprem,
                        qc_state_trend: branch.qc_state_trend,
                        qc_variety_trend: branch.qc_variety_trend,
                        isRow: true,

                        feb: {
                          month_id: 2,
                          current: branch.current.feb,
                          planned: branch.planned.feb,
                          suggested: branch.suggested.feb,
                        },
                        march: {
                          month_id: 3,
                          current: branch.current.march,
                          planned: branch.planned.march,
                          suggested: branch.suggested.march,
                        },
                        april: {
                          month_id: 4,
                          current: branch.current.april,
                          planned: branch.planned.april,
                          suggested: branch.suggested.april,
                        },
                        may: {
                          month_id: 5,
                          current: branch.current.may,
                          planned: branch.planned.may,
                          suggested: branch.suggested.may,
                        },
                        june: {
                          month_id: 6,
                          current: branch.current.june,
                          planned: branch.planned.june,
                          suggested: branch.suggested.june,
                        },
                        post_season: {
                          month_id: 7,
                          current: branch.current.post_season,
                          planned: branch.planned.post_season,
                          suggested: branch.suggested.post_season,
                        },
                        remark: {
                          current: branch.current.remarks,
                          planned: branch.planned.remarks,
                          // suggested: branch.suggested.remarks,
                        },
                        is_scheduled: {
                          current: branch.current.is_scheduled,
                          planned: branch.planned.is_scheduled,
                          // suggested: branch.suggested.is_scheduled,
                        },
                        mpc_id: {
                          current: branch.current.mpc_id,
                          planned: branch.planned.mpc_id,
                          // suggested: branch.suggested.mpc_id,
                        },
                        action: {
                          isEditable: false,
                        },
                      };
                    }),
                  };
                }
              );


              this.setState({ temporalData: modifiedTemporalData });
            } else {
              message.warning("No temporal model data available.");
            }

            //#endregion
          } else {
            this.setSpinnerStatus(false);
            message.error("No Runs available");
          }
        } else {
          this.setSpinnerStatus(false);
          message.error("No Runs available");
        }
      })
      .catch((err) => {
        this.setSpinnerStatus(false);
        message.error(
          "Could not retrieve model run details. Please check your network connectivity."
        );
      });

    //#endregion
  }

  getYearList = async () => {
    this.setSpinnerStatus(true);
    console.log("year list called ",);
    API.get("/recommendations/monthly/year")
    .then((filterResponse) => {
      if (filterResponse.data.status) {
        this.setSpinnerStatus(false);
        
          this.setState({
            yearList: filterResponse.data.data,
            selectedYearValue:filterResponse.data.data[0].year_id,
          });
        console.log("year list ", this.state.yearList, filterResponse.data.data);
      
      } else {
        this.setSpinnerStatus(false);
      }
    })
    .catch((err) => {
      this.setSpinnerStatus(false);
      message.error(
        "Could not retrieve model year. Please check your network connectivity."
      );
    });
  }

  render() {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const handleYearSelect = (value, props) => {
      const paramss={month:this.state.selectedMonthID,run_cnt:this.state.selectedrunCount}
      this.setState(
        { selectedYearValue: value },
        () => getTemporalData(paramss,value)
      );
    };

    /**
     * @description Submit the overriden data for the selected model run.
     * @param {number} variety_id selected variety id.
     * @param {number} branch_id  selected branch id.
     * @memberof MonthlyPrice
     */
    const onTickButtonClicked = (variety_id, branch_id) => {
      let selectedRow = this.state.temporalData
        .find((variety) => variety.variety_id === variety_id)
        .branches.find((branch) => branch.branch_id === branch_id);

      //#region api to post the selected row. if successful, update the row and change edit status


      const dataToSend = {
        branch_id: branch_id,
        mpc_id: selectedRow.mpc_id.current,
        variety_id: variety_id,
        feb: selectedRow.feb.planned,
        march: selectedRow.march.planned,
        april: selectedRow.april.planned,
        may: selectedRow.may.planned,
        june: selectedRow.june.planned,
        post_season: selectedRow.post_season.planned,
        remarks: selectedRow.remark.planned,
      };


      const validatePost = () => {
        const varietyName = this.props.allVarietyInfo.find(
          (item) => item.variety_id === dataToSend.variety_id
        ).variety_name;


        if (!dataToSend.remarks || !dataToSend.remarks.trim()) {
          message.error("Please enter a remark to submit.");
          return false;
        }

        if (
          (dataToSend.feb !== 0 &&
            (dataToSend.feb <
              getVarietyMinPrice(
                this.props.allVarietyInfo,
                varietyName,
                true
              ) ||
              dataToSend.feb >
              getVarietyMaxPrice(
                this.props.allVarietyInfo,
                varietyName,
                true
              ))) ||
          (dataToSend.march !== 0 &&
            (dataToSend.march <
              getVarietyMinPrice(
                this.props.allVarietyInfo,
                varietyName,
                true
              ) ||
              dataToSend.march >
              getVarietyMaxPrice(
                this.props.allVarietyInfo,
                varietyName,
                true
              ))) ||
          (dataToSend.april !== 0 &&
            (dataToSend.april <
              getVarietyMinPrice(
                this.props.allVarietyInfo,
                varietyName,
                true
              ) ||
              dataToSend.april >
              getVarietyMaxPrice(
                this.props.allVarietyInfo,
                varietyName,
                true
              ))) ||
          (dataToSend.may !== 0 &&
            (dataToSend.may <
              getVarietyMinPrice(
                this.props.allVarietyInfo,
                varietyName,
                true
              ) ||
              dataToSend.may >
              getVarietyMaxPrice(
                this.props.allVarietyInfo,
                varietyName,
                true
              ))) ||
          (dataToSend.june !== 0 &&
            (dataToSend.june <
              getVarietyMinPrice(
                this.props.allVarietyInfo,
                varietyName,
                true
              ) ||
              dataToSend.june >
              getVarietyMaxPrice(
                this.props.allVarietyInfo,
                varietyName,
                true
              ))) ||
          (dataToSend.post_season !== 0 &&
            (dataToSend.post_season <
              getVarietyMinPrice(
                this.props.allVarietyInfo,
                varietyName,
                true
              ) ||
              dataToSend.post_season >
              getVarietyMaxPrice(
                this.props.allVarietyInfo,
                varietyName,
                true
              )))
        ) {
          message.error(
            `The price should either be 0 or between ${getVarietyMinPrice(
              this.props.allVarietyInfo,
              varietyName,
              true
            )} and ${getVarietyMaxPrice(
              this.props.allVarietyInfo,
              varietyName,
              true
            )}   `
          );
          return false;
        }

        return true;
      };

      if (validatePost()) {
        this.setSpinnerStatus(true);

        let modifiedDataToSend = dataToSend;

        let modified = {};

        Object.keys(modifiedDataToSend).map((key) => {
          switch (key) {
            case "feb":
              if (modifiedDataToSend[key] !== selectedRow[key].current) {
                modified = { ...modified, 2: modifiedDataToSend[key] };
              }
              break;
            case "march":
              if (modifiedDataToSend[key] !== selectedRow[key].current) {
                modified = { ...modified, 3: modifiedDataToSend[key] };
              }
              break;
            case "april":
              if (modifiedDataToSend[key] !== selectedRow[key].current) {
                modified = { ...modified, 4: modifiedDataToSend[key] };
              }
              break;
            case "may":
              if (modifiedDataToSend[key] !== selectedRow[key].current) {
                modified = { ...modified, 5: modifiedDataToSend[key] };
              }
              break;
            case "june":
              if (modifiedDataToSend[key] !== selectedRow[key].current) {
                modified = { ...modified, 6: modifiedDataToSend[key] };
              }
              break;
            case "post_season":
              if (modifiedDataToSend[key] !== selectedRow[key].current) {
                modified = { ...modified, 7: modifiedDataToSend[key] };
              }
              break;

            default:
              break;
          }
        });

        modifiedDataToSend = {
          branch: dataToSend.branch_id,
          run_month: this.state.selectedMonthID,
          remarks: dataToSend.remarks,
          variety: dataToSend.variety_id,
          modified: modified,
        };


        API.post("/recommendations/monthly/price", modifiedDataToSend)
          .then((postResponse) => {
            if (postResponse.data.status) {
              this.setSpinnerStatus(false);

              // save planned into current and make editable false.
              saveRow(
                variety_id,
                branch_id,
                postResponse.data.data.mpc_id,
                postResponse.data.data.remarks
              );

              message.success("Submitted Successfully.");
            } else {
              this.setSpinnerStatus(false);
              message.warning(postResponse.data.message);
            }
          })
          .catch((err) => {
            this.setSpinnerStatus(false);
            message.error(
              "Could not submit the row. Please check your network connectivity"
            );
          });
      }

      //#endregion
    };

    //change edit status of the row.
    /**
     * @description Change the edit status of a table row.
     * @param {number} variety_id selected variety id.
     * @param {number} branch_id  selected branch id.
     * @param {boolean} status the editable status of the current row.
     * @memberof MonthlyPrice
     */
    const changeEditStatus = (variety_id, branch_id, status) => {

      let tempTemporalData = this.state.temporalData;

      tempTemporalData = tempTemporalData.map((item) => {
        if (item.variety_id === variety_id) {
          return {
            ...item,
            branches: item.branches.map((branch) => {
              if (branch.branch_id === branch_id) {
                return {
                  ...branch,
                  action: { ...branch.action, isEditable: status },
                };
              }
              return branch;
            }),
          };
        }
        return item;
      });

      this.setState({ temporalData: tempTemporalData });
    };

    /**
     * @description Set the scheduled status for a selected model run
     * @param {boolean} status the status of sheduling to be set for the model run.
     * @memberof MonthlyPrice
     */
    const setScheduleStatus = (status) => {
      const modifiedTemporalData = this.state.temporalData.map((item) => {
        return {
          ...item,
          branches: item.branches.map((branch) => {
            return {
              ...branch,
              is_scheduled: { ...branch.is_scheduled, planned: status },
            };
          }),
        };
      });


      this.setState({ temporalData: modifiedTemporalData });
    };

    /**
     * @description reset the row from planned to current value and set edit to false.
     * @param {number} variety_id selected variety id.
     * @param {number} branch_id  selected branch id.
     * @memberof MonthlyPrice
     */
    const resetRow = (variety_id, branch_id) => {
      const tempData = this.state.temporalData;

      let resettedTemporalData = tempData.map((item) => {
        if (item.variety_id === variety_id) {
          return {
            ...item,
            branches: item.branches.map((branch) => {
              if (branch.branch_id === branch_id) {
                return {
                  ...branch,
                  feb: { ...branch.feb, planned: branch.feb.current },
                  march: {
                    ...branch.march,
                    planned: branch.march.current,
                  },
                  april: {
                    ...branch.april,
                    planned: branch.april.current,
                  },
                  may: { ...branch.may, planned: branch.may.current },
                  june: {
                    ...branch.june,
                    planned: branch.june.current,
                  },
                  post_season: {
                    ...branch.post_season,
                    planned: branch.post_season.current,
                  },
                  remark: { ...branch.remark, planned: branch.remark.current },
                  is_scheduled: {
                    ...branch.is_scheduled,
                    planned: branch.is_scheduled.current,
                  },
                  mpc_id: { ...branch.mpc_id, planned: branch.mpc_id.current },

                  action: { ...branch.action, isEditable: false },
                };
              }
              return branch;
            }),
          };
        }
        return item;
      });


      this.setState(
        (prevState) => ({ ...prevState, temporalData: resettedTemporalData }),
        () => console.log()
      );
    };

    /**
     * @description save the row to current from planned value and set edit to false.
     * @param {number} variety_id selected variety id.
     * @param {number} branch_id  selected branch id.
     * @memberof MonthlyPrice
     */
    const saveRow = (variety_id, branch_id, mpc_id, remark) => {
      const tempData = this.state.temporalData;

      let resettedTemporalData = tempData.map((item) => {
        if (item.variety_id === variety_id) {
          return {
            ...item,
            branches: item.branches.map((branch) => {
              if (branch.branch_id === branch_id) {
                return {
                  ...branch,
                  feb: { ...branch.feb, current: branch.feb.planned },
                  march: {
                    ...branch.march,
                    current: branch.march.planned,
                  },
                  april: {
                    ...branch.april,
                    current: branch.april.planned,
                  },
                  may: { ...branch.may, current: branch.may.planned },
                  june: {
                    ...branch.june,
                    current: branch.june.planned,
                  },
                  post_season: {
                    ...branch.post_season,
                    current: branch.post_season.planned,
                  },
                  remark: { ...branch.remark, current: remark },
                  is_scheduled: {
                    ...branch.is_scheduled,
                    current: branch.is_scheduled.planned,
                  },
                  mpc_id: { ...branch.mpc_id, current: mpc_id },

                  action: { ...branch.action, isEditable: false },
                };
              }
              return branch;
            }),
          };
        }
        return item;
      });


      this.setState(
        (prevState) => ({ ...prevState, temporalData: resettedTemporalData }),
        () => console.log()
      );
    };

    /**
     * @description store the planned value on onBlur into state.
     * @param {any} modifiedValue the changed value from the table
     * @param {number} variety_id selected variety id
     * @param {number} branch_id selected branch id
     * @param {string} month_name selected month name
     * @memberof MonthlyPrice
     */
    const onValueModified = (
      modifiedValue,
      variety_id,
      branch_id,
      month_name
    ) => {
      if (month_name) {
        // this a month value. change in the local state.
        // console.log(
        //   `value : ${modifiedValue} for variety_id : ${variety_id} for branch_id : ${branch_id} for month : ${month_name}`
        // );

        let tempTemporalData = this.state.temporalData;

        tempTemporalData = tempTemporalData.map((item) => {
          if (item.variety_id === variety_id) {
            return {
              ...item,
              branches: item.branches.map((branch) => {
                if (branch.branch_id === branch_id) {
                  return {
                    ...branch,
                    [month_name]: {
                      ...branch[month_name],
                      planned: modifiedValue ? parseInt(modifiedValue) : 0,
                    },
                  };
                }
                return branch;
              }),
            };
          }
          return item;
        });

        this.setState({ temporalData: tempTemporalData });
      } else {
        // this is a remark.change in the local state.
        // console.log(
        //   `Remark : ${modifiedValue} for variety_id : ${variety_id} for branch_id : ${branch_id} `
        // );

        let tempTemporalData = this.state.temporalData;

        tempTemporalData = tempTemporalData.map((item) => {
          if (item.variety_id === variety_id) {
            return {
              ...item,
              branches: item.branches.map((branch) => {
                if (branch.branch_id === branch_id) {
                  return {
                    ...branch,
                    remark: {
                      ...branch.remark,
                      planned: modifiedValue,
                    },
                  };
                }
                return branch;
              }),
            };
          }
          return item;
        });

        this.setState({ temporalData: tempTemporalData });
      }
    };

    //function to call api on select change.
    /**
     * @description Get the temporal monthly modal data for the selected model run.
     * @param {object} params parameters to be sent along with the get api.
     * @memberof MonthlyPrice
     */
    const getTemporalData = (params,year) => {
      this.setState({ selectedMonthID: params.month });
      this.setState({ selectedrunCount: params.run_cnt });
      this.setSpinnerStatus(true);

      //#region calling api to get the correntponding temporal data

      const temporalParams = {month:params.month,
        run_cnt:params.run_cnt,
        year:this.state.selectedYearValue};
      API.get("/recommendations/monthly/price", {

        params: temporalParams,
      })
        .then((temporalResponse) => {
          if (temporalResponse.data.status) {
            this.setSpinnerStatus(false);

            this.setState({
              filters: temporalResponse.data.data.filter !== undefined ? temporalResponse.data.data.filter : this.state.filters
            });
            if(temporalResponse.data.data.filter !== undefined && temporalResponse.data.data.filter.length !== 0){
              this.setState({
                selectedMonthID: temporalResponse.data.data.filter[0].month,
                selectedrunCount: temporalResponse.data.data.filter[0].run_cnt,
              });
            }

            this.setQCThresholds(temporalResponse.data.data.qc_threshold);

            if (temporalResponse.data.data.temporal_price_data.length !== 0) {
              const modifiedTemporalData = temporalResponse.data.data.temporal_price_data.map(
                (variety) => {
                  return {
                    ...variety,

                    branches: variety.branches.map((branch) => {
                      return {
                        key: variety.variety_id + "_" + branch.branch_id,
                        branch_id: branch.branch_id,
                        variety_id: variety.variety_id,
                        branch: branch.state,
                        qc_model_comparison: branch.qc_model_comparison,
                        qc_prem_vs_nonprem: branch.qc_prem_vs_nonprem,
                        qc_state_trend: branch.qc_state_trend,
                        qc_variety_trend: branch.qc_variety_trend,
                        isRow: true,

                        feb: {
                          month_id: 2,
                          current: branch.current.feb,
                          planned: branch.planned.feb,
                          suggested: branch.suggested.feb,
                        },
                        march: {
                          month_id: 3,
                          current: branch.current.march,
                          planned: branch.planned.march,
                          suggested: branch.suggested.march,
                        },
                        april: {
                          month_id: 4,
                          current: branch.current.april,
                          planned: branch.planned.april,
                          suggested: branch.suggested.april,
                        },
                        may: {
                          month_id: 5,
                          current: branch.current.may,
                          planned: branch.planned.may,
                          suggested: branch.suggested.may,
                        },
                        june: {
                          month_id: 6,
                          current: branch.current.june,
                          planned: branch.planned.june,
                          suggested: branch.suggested.june,
                        },
                        post_season: {
                          month_id: 7,
                          current: branch.current.post_season,
                          planned: branch.planned.post_season,
                          suggested: branch.suggested.post_season,
                        },
                        remark: {
                          current: branch.current.remarks,
                          planned: branch.planned.remarks,
                          // suggested: branch.suggested.remarks,
                        },
                        is_scheduled: {
                          current: branch.current.is_scheduled,
                          planned: branch.planned.is_scheduled,
                          // suggested: branch.suggested.is_scheduled,
                        },
                        mpc_id: {
                          current: branch.current.mpc_id,
                          planned: branch.planned.mpc_id,
                          // suggested: branch.suggested.mpc_id,
                        },
                        action: {
                          isEditable: false,
                        },
                      };
                    }),
                  };
                }
              );


              this.setState({ temporalData: modifiedTemporalData });
            } else {
              this.setState({ temporalData: [] });
              this.setSpinnerStatus(false);
              message.warning("No temporal model data available.");
            }
          } else {
            this.setSpinnerStatus(false);
            message.warning("No temporal model data available.");
          }
        })
        .catch((err) => {
          this.setSpinnerStatus(false);
          message.error(
            "Could not retrieve temporal model data. Please check your network connectivity."
          );
        });
      //#endregion
    };

    /**
     * @description Generate the values for the model run filter dropdown.
     * @param {number} monthCount
     * @param {number} runCount
     * @returns string to display in the filter dropdown
     * @memberof MonthlyPrice
     */
    const setFilterName = (monthCount, runCount) => {
      return months[monthCount - 1] + "-" + runCount;
    };

    /**
     * @description Create the objects to use as input to populate the filter dropdown.
     * @returns array of objects containing filter data
     * @memberof MonthlyPrice
     */
    const setFilters = () => {
      const modifiedFilters = this.state.filters.map((item) => {
        return {
          ...item,
          key: item.month + "_" + item.run_cnt,
          name: setFilterName(item.month, item.run_cnt),
        };
      });
      return modifiedFilters;
    };

    /**
     * @description Check if the current model run has been scheduled or not.
     * @returns true if the current model run has been scheduled.
     * @memberof MonthlyPrice
     */
    const isScheduled = () => {
      let schedulables = [];
      this.state.temporalData.map((item) => {
        item.branches.map((branch) => {
          schedulables.push(branch.is_scheduled.planned);
        });
      });

      return schedulables.includes(true);
    };

    /**
     * @description schedule button will be disabled if atleast one row is being edited or is_schedulable.
     * @returns true should the schedule button can be clicked.
     * @memberof MonthlyPrice
     */
    const isScheduleButtonEnabled = () => {
      let editables = [],
        schedulables = [];

      if (
        !canUserEdit(
          this.props.userData,
          null,
          FeatureCodes.MONTHLY_MODEL_FORECAST_EDIT
        )
      ) {
        return false;
      }

      this.state.temporalData.map((item) => {
        item.branches.map((branch) => {
          editables.push(branch.action.isEditable);
          schedulables.push(branch.is_scheduled.planned);
        });
      });

      return !editables.includes(true) && !schedulables.includes(true);
    };

    //this will open the modal
    /**
     * @description Open/Close the confirmation modal
     * @param {boolean} status visibility status on the confirmation modal.
     * @memberof MonthlyPrice
     */
    const onScheduleButtonClicked = (status) => {
      setModalVisibility(true);
    };

    const setModalVisibility = (status) => {
      this.setState({ isModalVisible: status });
    };

    /**
     * @description  submit the scheduling confirmation by calling the api.
     * @memberof MonthlyPrice
     */
    const onModalSubmit = () => {
      this.setSpinnerStatus(true);
      //#region api call for schedule api

      const dataToSend = {
        run_month: this.state.selectedMonthID,
      };

      API.put("/recommendations/monthly/price", dataToSend)
        .then((scheduleResponse) => {
          if (scheduleResponse.data.status) {
            // set all is_scheduled to true
            setScheduleStatus(true);

            message.success("The data has been scheduled successfully.");

            //#region
            API.get(`/recommendations/monthly/airflow_monthly_model_volume`)
              .then((airflowResponse) => {
                if (airflowResponse.data.status) {
                  this.setSpinnerStatus(false);
                  message.success("Model started running.");
                } else {
                  this.setSpinnerStatus(false);
                  message.warning("Error in initiating model run");
                }
              })
              .catch((err) => {
                this.setSpinnerStatus(false);
                message.error("Error in initiating model run");
              });
            //#endregion
          } else {
            this.setSpinnerStatus(false);
            message.error("Cannot schedule the modified values");
          }
        })
        .catch((err) => {
          this.setSpinnerStatus(false);
          message.error(
            "Cannot submit the schedule.Please check your network connectivity."
          );
        });

      //#endregion

      setModalVisibility(false);
    };

    return (
      <Spin spinning={this.state.isLoading}>
        <div className="monthly-price-container">
          <Modal
            className="temporal-modal"
            title={<div style={{ color: "white" }}>Confirmation</div>}
            visible={this.state.isModalVisible}
            onOk={() => onModalSubmit()}
            onCancel={() => setModalVisibility(false)}
            cancelButtonProps={{
              style: { backgroundColor: "#cbcbcb !important" },
            }}
          >
            <div style={{ color: "black" }}>
              This will freeze the price for this month and run the Monthly
              Volume (that will take approximately 5 mins to run). Do you want
              to proceed ?
              {/* You are about to submit the overrides to schedule for monthly
              volume model run. This will be the final submission. After this,
              you will not be able to make any further changes. Are you sure you
              want to submit ? */}
            </div>
          </Modal>

          <div className="top-container">
            {this.state.temporalData.length !== 0 && (
              <div
                className={`${isScheduleButtonEnabled()
                  ? "schedule-button"
                  : "schedule-button-disabled"
                  } `}
                onClick={() => onScheduleButtonClicked()}
              >
                <div style={{ margin: "4px 0 0 0 " }}>
                  {" "}
                  {`${isScheduled()
                    ? "Final Override Confirmed"
                    : "Final Override Confirmation"
                    }`}
                </div>
                {/* <div style={{ fontSize: "12px" }}>For monthly volume run</div> */}
              </div>
            )}

            <div style={{display:"flex"}}>
            <div className="temporal-select">
              <div className="temporal-select-title">Model Run</div>
              <CustomSelectTemporal
                isPrice={true}
                list={setFilters()}
                onSelect={getTemporalData}
                connectState
              //   defaultValue
              //   placeholder
              />
            </div>
            {/*} <div className="temporal-select" style={{marginLeft:"10px"}}>
            <div className="temporal-select-title">Model Year</div>
            <Select
            onChange={(value, props) =>
              handleYearSelect(value)
            }
            value={this.state.selectedYearValue}
          >
          {console.log("year list map ", this.state.yearList)}
            {this.state.yearList.map((item, idx) => (
              <Option value={item.year_id} key={item.year_id}>
                {item.year_id}
              </Option>
            ))}
          </Select>
            </div>*/}
           {/* <DatePicker className="yeardatepicker" format={"YYYY"} picker="year" />
            <RangePicker picker="year" 
                  format={"yyyy"} bordered={false} */}
          
        
        </div>
            </div>

          <div
            style={{ height: window.innerHeight - 210 }}
            className="table-list-container"
          >
            {this.state.temporalData.length !== 0 && (
              <TemporalExpandableTable
                isDisabled={
                  !canUserEdit(
                    this.props.userData,
                    null,
                    FeatureCodes.MONTHLY_MODEL_FORECAST_EDIT
                  )
                }
                isConstrained={true}
                isPrice={true}
                resetRow={resetRow}
                onTickButtonClicked={onTickButtonClicked}
                changeEditStatus={changeEditStatus}
                onValueModified={onValueModified}
                data={this.state.temporalData}
                title="Price (in ₹/MT)"
                selectedMonthID={this.state.selectedMonthID}
                qcThresholds={this.state.qcThresholds}
              // key={item.variety_id}
              />
            )}

            {/* <TemporalExpandableTable
            isConstrained={true}
            isPrice={true}
            resetRow={resetRow}
            onTickButtonClicked={onTickButtonClicked}
            changeEditStatus={changeEditStatus}
            onValueModified={onValueModified}
            data={this.state.temporalData}
            title="Price (in ₹/MT)"
            // key={item.variety_id}
          /> */}
          </div>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  allBranches: selectAllBranches,
  allVarietyInfo: selectAllVarietyInfo,

  userData: selectUserData,
});

export default connect(mapStateToProps, null)(MonthlyPrice);
