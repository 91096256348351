import React, { Component } from "react";
import "./market-analysis.styles.css";

import {
  Button,
  Modal,
  Empty,
  message,
  Spin,
  Input,
  Popover,
  Form,
  DatePicker,
  Progress,
} from "antd";
import { Line } from "react-chartjs-2";

import CustomSelectWeekly from "../../components/custom-select-weekly/custom-select-weekly.component";
import API, { baseURL } from "../../api";

import moment from "moment";
import Axios from "axios";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { canUserEdit } from "../../auth/utils";
import { FeatureCodes } from "../../auth/data";

import {
  selectDecisionBranches,
  selectDecisionRegions,
  selectSelectedBranchID,
  selectSelectedRegionID,
} from "../../redux/buying-decision/buying-decision.selectors";
import { selectUserData } from "../../redux/user/user.selectors";

import TickImage from "../../assets/tickIcon.svg";
import CloseImage from "../../assets/cross.svg";
import EditImage from "../../assets/edit-user.svg";
import DisabledEditImage from "../../assets/edit-user-disabled.svg";

const FileDownLoad = require("js-file-download");

const CustomButton = (props) => {
  const { src, onClick, disabled, isPencilImage } = props;
  return (
    <div
      className={`${
        disabled ? "no-pointer-events temporal-button " : "temporal-button"
      }`}
      style={{ borderColor: disabled ? "grey" : "#154479" }}
      onClick={() => onClick()}
    >
      <div style={{ margin: "5px", color: disabled ? "grey" : "#154479" }}>
        Edit
      </div>
      <img
        style={{ width: "inherit" }}
        src={isPencilImage && disabled ? DisabledEditImage : src}
        alt="button"
      />
    </div>
  );
};

class CompetitionAnalysisForm extends Component {
  state = {
    text: "",
  };
  render() {
    const {
      data,
      isEditable,
      setEditableStatus,
      postData,
      summary,
      onSummaryChanged,
    } = this.props;

    const handleSubmit = () => {
      //validations
      if (!summary.trim()) {
        message.warning("Kindly enter a valid text !!!");
        return;
      }

      //TODO: call post api
      postData(summary);
      setEditableStatus(false);
    };

    const handleChange = (e) => {
      onSummaryChanged(e.target.value);
    };

    return (
      <div className="competitor-form-container" onSubmit={handleSubmit}>
        <div style={{ width: "90%", margin: "5px 0px", fontWeight: "600" }}>
          Market Snapshot
        </div>
        <div>
          <Input.TextArea
            disabled={!isEditable}
            allowClear
            rows={10}
            value={summary}
            onChange={(e) => handleChange(e)}
          />
        </div>

        <Button
          disabled={!isEditable}
          className={`submit-button ${!isEditable ? "disabled-button" : ""}`}
          type="primary"
          //   htmlType="submit"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    );
  }
}

/**
 *
 * @description Parent component for displaying the weekly information tab
 * @component
 */
class CompetitionAnalysis extends Component {
  state = {
    isLoading: true,
    isEditable: false,
    isSpinnerOnProgress: false,
    isSpinnerOn: false,
    ProgressClicked: false,
    selectedDate: moment(new Date()),
    selectedBranchId: null,
    selectedRegionId: null,
    analysisData: null,
    regions: [],
  };
  // moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD")

  //#region assistive methods for  data api

  onSummaryChanged = (text) => {
    this.setState((prevState) => ({
      ...prevState,
      analysisData: {
        ...prevState.analysisData,
        summary: text,
      },
    }));
  };

  onDateSelected = (date) => {
    this.setState({
      selectedDate: date,
    });
    //TODO: call get api
    this.getData(
      moment(date, "YYYY-MM-DD").format("YYYY-MM-DD"),
      this.state.selectedBranchId,
      this.state.selectedRegionId
    );
  };

  onBranchSelected = (branch_id) => {
    this.setState({ selectedBranchId: branch_id });

    this.getRegions(branch_id);
  };

  getRegions = (branchId) => {
    this.setSpinnerStatus(true);
    const params = {
      table_type: "transaction",
      branch_id: branchId,
      mapping: "mapped",
    };
    // API.get(`/branches/${branchId}/regions/`, { headers: this.headers })
    API.get(`/regions`, { params: params })
      .then((regionResponse) => {
        this.setSpinnerStatus(false);
        this.setState({
          selectedRegionId: regionResponse.data.data.regions[0].region_id,
          regions: regionResponse.data.data.regions,
        });

        this.onRegionSelected(regionResponse.data.data.regions[0].region_id);
      })
      .catch((err) => {
        this.setSpinnerStatus(false);
      });
  };

  onRegionSelected = (region_id) => {
    this.setState({ selectedRegionId: region_id });
    this.getData(
      moment(this.state.selectedDate, "YYYY-MM-DD").format("YYYY-MM-DD"),
      this.state.selectedBranchId,
      region_id
    );
  };

  getData = (date, branch_id, region_id) => {
    this.setSpinnerStatus(true);
    this.setEditableStatus(false);
    API.get(
      `/competitor_analysis?analysis_date=${date}&branch_id=${branch_id}&region_id=${region_id}`
    )
      .then((analysisResponse) => {
        if (analysisResponse.data.status) {
          this.setState({ analysisData: analysisResponse.data.data });
          this.setSpinnerStatus(false);
        } else {
          this.setSpinnerStatus(false);
          message.warning(
            "Could not retrieve the market snapshot data. Please try again later."
          );
        }
      })
      .catch((err) => {
        this.setSpinnerStatus(false);
        message.error(
          "Could not retrieve the market snapshot data. Please check your network connectivity."
        );
      });
  };

  postData = (updatedText) => {
    this.setSpinnerStatus(true);
    let dataToSend = {
      ca_id: this.state.analysisData.ca_id,
      branch_id: this.state.selectedBranchId,
      region_id: this.state.selectedRegionId,
      analysis_date: moment(this.state.selectedDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      ),
      summary: updatedText,
    };

    API.post("/competitor_analysis", dataToSend)
      .then((postResponse) => {
        if (postResponse.data.status) {
          this.setSpinnerStatus(false);
          message.success("Analysis updated successfully.");
          let updatedData = {
            ...this.state.analysisData,
            ca_id: postResponse.data.data.ca_id,
            summary: updatedText,
          };

          this.setState({ analysisData: updatedData });
        } else {
          this.setSpinnerStatus(false);
          message.warning("Could not update the analysis. Please try again.");
        }
      })
      .catch((err) => {
        this.setSpinnerStatus(false);
        message.error(
          "Could not update the analysis. Please check your network connectivity"
        );
      });
  };

  /**
   * @function
   * @description Set the loading spinner visibility.
   * @param {boolean} status Spinner visibility status to be set
   * @memberof WeeklyVolume
   */
  setSpinnerStatus = (status) => {
    this.setState({ isLoading: status });
  };

  setEditableStatus = (status) => {
    this.setState({ isEditable: status });
  };
  //#endregion

  waitForFilterData() {
    if (this.props.branches.length !== 0 && this.props.regions.length !== 0) {
      // set the filters
      this.setState({
        selectedBranchId: parseInt(
          this.props.selectedBranchIDFromBuyingDecision
        ),
        selectedRegionId: parseInt(
          this.props.selectedRegionIDFromBuyingDecision
        ),
        regions: this.props.regions,
      });
      //get the data
      this.getData(
        moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD"),
        parseInt(this.props.selectedBranchIDFromBuyingDecision),
        parseInt(this.props.selectedRegionIDFromBuyingDecision)
      );
      this.setSpinnerStatus(false);
    } else {
      setTimeout(() => {
        this.waitForFilterData();
      }, 100);
    }
  }
  componentDidMount() {
    //#region API to get the filter data
    this.waitForFilterData();
    //#endregion
  }

  render() {
    const user = this.props.userData;
    //#region function definitions

    //#endregion

    return (
      <Spin spinning={this.state.isLoading}>
        <div className="weekly-volume-container">
          <Spin spinning={this.state.isSpinnerOn} tip="Loading...">
            <Spin
              spinning={this.state.isSpinnerOnProgress}
              tip="Please wait while the file is being Downloaded..."
            >
              {/* <div className="download-button-container">
                {this.state.ProgressClicked && (
                  <Progress
                    type="circle"
                    percent={this.state.downloaddata}
                    width={37}
                    strokeColor={{ "0%": "#108ee9", "100%": "#87d068" }}
                    style={{
                      paddingRight: "5px",
                    }}
                    trailColor="#003285"
                  />
                )}
                {
                  <Button
                    onClick={() => {
                      this.setState({ isSpinnerOnProgress: true });
                      this.setState({ downloaddata: 0 });
                      this.setState({ ProgressClicked: true });
                      // API.get(
                      // `/competitor_analysis?analysis_date=${moment(
                      //   this.state.selectedDate,
                      //   "YYYY-MM-DD"
                      // ).format("YYYY-MM-DD")}&branch_id=${this.state.selectedBranchId
                      // }&region_id=${this.state.selectedRegionId}&download={1}`,

                      // )
                      //   .then((fileresponse) => {

                      //     if (fileresponse.data.status) {
                      //#region file download
                      Axios({
                        url: `${baseURL}/competitor_analysis?analysis_date=${moment(
                          this.state.selectedDate,
                          "YYYY-MM-DD"
                        ).format("YYYY-MM-DD")}&branch_id=${
                          this.state.selectedBranchId
                        }&region_id=${
                          this.state.selectedRegionId
                        }&download={1}`,
                        method: "GET",
                        responseType: "blob",
                        onDownloadProgress: (progressEvent) => {
                          let progress = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                          );
                          this.setState({ downloaddata: progress });
                        },
                      })
                        .then((response) => {
                          if (response.status !== 204) {
                            FileDownLoad(
                              response.data,
                              `Competitor_Analysis_Report_${new Date().toLocaleDateString()}.xlsx`
                            );
                            this.setState({ isSpinnerOnProgress: false });
                            setTimeout(
                              function () {
                                this.setState({ ProgressClicked: false });
                              }.bind(this),
                              5000
                            );
                          } else {
                            message.warning("File has no data.");
                            this.setState({
                              isSpinnerOnProgress: false,
                              ProgressClicked: false,
                            });
                          }
                        })
                        .catch((err) => {
                          message.error("File cannot be downloaded");
                          this.setState({
                            isSpinnerOnProgress: false,
                            ProgressClicked: false,
                          });
                        });
                      //#endregion
                      //   } else {

                      //     message.warning("File has no data");
                      //     this.setState({ isSpinnerOnProgress: false, ProgressClicked:false });
                      //   }
                      // })
                      // .catch((err) => {
                      //   message.error("Error downloading file");
                      //   this.setState({ isSpinnerOnProgress: false, ProgressClicked:false });
                      // });
                    }}
                    type="primary"
                    shape="round"
                    icon="download"
                    size="large"
                  >
                    <div style={{ float: "right" }}>
                      <div style={{ marginTop: "-7px" }}>Download</div>
                      <div
                        style={{
                          fontSize: "11px",
                          lineHeight: "7px",
                          color: "#b4c8dc",
                        }}
                      >
                        .xlsx report
                      </div>
                    </div>
                  </Button>
                }
              </div> */}
              <div className="top-container">
                <div style={{ margin: "30px 0 0 0S" }} className=" filters">
                  <div className="temporal-select" style={{ height: "70px" }}>
                    <div className="temporal-select-title">Select Date</div>
                    <DatePicker
                      value={this.state.selectedDate}
                      disabledDate={(current) =>
                        current > moment().add(0, "days").endOf("day")
                      }
                      onChange={(e) => this.onDateSelected(e)}
                    />
                  </div>
                  <div className="temporal-select" style={{ height: "70px" }}>
                    <div className="temporal-select-title">Select Branch</div>
                    <CustomSelectWeekly
                      list={this.props.branches.map((item) => {
                        return {
                          name: item.territory_name,
                          id: item.territory_id,
                        };
                      })}
                      value={this.state.selectedBranchId}
                      onSelect={this.onBranchSelected}
                      placeholder="Select Branch"
                    />
                  </div>
                  <div className="temporal-select" style={{ height: "70px" }}>
                    <div className="temporal-select-title">Select Region</div>
                    <CustomSelectWeekly
                      list={this.state.regions.map((item) => {
                        return {
                          name: item.region_name,
                          id: item.region_id,
                        };
                      })}
                      value={this.state.selectedRegionId}
                      onSelect={this.onRegionSelected}
                      placeholder="Select Region"
                    />
                  </div>
                </div>
              </div>

              <>
                <div className="middle-container">
                  <div className="projection-container">
                    <div className="holder">
                      <div className="title">Enter The Market Snapshot</div>

                      {
                        <CustomButton
                          disabled={
                            this.state.isEditable ||
                            !canUserEdit(
                              user,
                              this.state.selectedBranchId,
                              FeatureCodes.COMPETITOR_ANALYSIS
                            )
                          } // disable the edit button if editing is currently happening.
                          isPencilImage={true}
                          src={EditImage}
                          onClick={() => this.setEditableStatus(true)}
                        />
                      }
                      {/* {renderUpdatedForm(
                    this.state.analysisData
                      ? this.state.analysisData.summary
                      : "",
                    this.state.analysisData,
                    this.state.isEditable,
                    this.setEditableStatus,
                    this.postData
                  )} */}
                      <CompetitionAnalysisForm
                        summary={
                          this.state.analysisData &&
                          this.state.analysisData.summary
                            ? this.state.analysisData.summary
                            : ""
                        }
                        data={this.state.analysisData}
                        isEditable={this.state.isEditable}
                        setEditableStatus={this.setEditableStatus}
                        postData={this.postData}
                        onSummaryChanged={this.onSummaryChanged}
                      />
                    </div>
                  </div>
                </div>
              </>
            </Spin>
          </Spin>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  branches: selectDecisionBranches,
  regions: selectDecisionRegions,
  userData: selectUserData,
  selectedBranchIDFromBuyingDecision: selectSelectedBranchID,
  selectedRegionIDFromBuyingDecision: selectSelectedRegionID,
});

export default connect(mapStateToProps, null)(CompetitionAnalysis);
