import React from "react";
import "./consolidated-cost-buildup.page";
import { Component } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUserData ,selectMSMessageRBAC} from "../../redux/user/user.selectors";
import { canUserEdit,getUserMSRBACMessage } from "../../auth/utils";
import { FeatureCodes } from "../../auth/data";
import {
  Table,
  Spin,
  Input,
  Select,
  Button,
  message,
  Form,
  Modal,
  DatePicker,
  Radio,
} from "antd";
import moment from "moment";
import CustomDropdownChooseRegion from "../../components/custom-dropdown-choose-region/custom-dropdown-choose-region.component";

import API from "../../api";

const { Search } = Input;
const { Option } = Select;
/**
 * @description Component for user management tab
 *
 * @component
 */
class ConsolidatedCostMaster extends Component {
  state = {
    isModalVisible: false,
    isAdd: false,
    currentRecord: null,
    userBranches: null,
    searchText: null,
    searchUserType: 0,
    consolidatedTableData: [],
    stateList: [],
    isSpinnerOnLoad: false,
    isSubmitted: false,
    newData: {
      Adhat: undefined,
      Buildup_Cost: undefined,
      Buildup_on_Qty: undefined,
      Commission: undefined,
      Cost_BuildUp_Mandi_ID: undefined,
      Dami: undefined,
      Financial_Year: "",
      Gunny_Bag_PerMT: undefined,
      HamaliPala_n_LdngPerMT: undefined,
      Krishi_Kalyan: null,
      Labour_Cost: undefined,
      Last_Updated_Date: "",
      Last_Updated_User_ID: "",
      Mandi_Tax: undefined,
      RDF: undefined,
      State: "",
      Sutli_PerMT: undefined,
      Trader_Margin: undefined,
      is_active: false,
      isEdit: false,
      krishi_kalyan_cess: undefined,
    },
    rowData: undefined,
  };
  componentDidMount() {
    this.setState({ isSpinnerOnLoad: true });
    this.getMasterConsolidatedCost();
    this.getStates();
  }

  getMasterConsolidatedCost = async () => {
    this.setState({ isSpinnerOnLoad: true });


    await API.get("/master_consolidated_cost_buildup")
      .then((varietyResponse) => {
        let modifiedData = varietyResponse.data.data.map((item, index) => {
          return {
            ...item,
            isEdit: false,
            key: index,
          };
        });
        this.setState(
          {
            consolidatedTableData: modifiedData,
          },
          () => this.setState({ isSpinnerOnLoad: false, isAdd: true })
        );
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };

  getStates = async () => {
    this.setState({ isSpinnerOnLoad: true });


    await API.get("/get_states")
      .then((hubList) => {
        this.setState({
          stateList: hubList.data.data,
          isSpinnerOnLoad: false,
        });
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };

  setTableData = () => {
    if (
      this.state.consolidatedTableData !== undefined ||
      this.state.consolidatedTableData.length >= 0
    ) {
      let userTableData = this.state.consolidatedTableData.map(
        (user, index) => {
          return {
            key: user.key,
            Adhat: user.Adhat,
            Buildup_Cost: user.Buildup_Cost,
            Buildup_on_Qty: user.Buildup_Cost,
            Commission: user.Commission,
            Cost_BuildUp_Mandi_ID: user.Cost_BuildUp_Mandi_ID,
            Dami: user.Dami,
            Financial_Year: user.Financial_Year,
            Gunny_Bag_PerMT: user.Gunny_Bag_PerMT,
            HamaliPala_n_LdngPerMT: user.HamaliPala_n_LdngPerMT,
            Krishi_Kalyan: user.Krishi_Kalyan,
            Labour_Cost: user.Labour_Cost,
            Last_Updated_Date: user.Last_Updated_Date,
            Last_Updated_User_ID: user.Last_Updated_User_ID,
            Mandi_Tax: user.Mandi_Tax,
            RDF: user.RDF,
            State: user.State,
            Sutli_PerMT: user.Sutli_PerMT,
            Trader_Margin: user.Trader_Margin,
            is_active: user.is_active,
            isEdit: user.isEdit,
            krishi_kalyan_cess: user.krishi_kalyan_cess,
          };
        }
      );

      let dataAfterSearch = userTableData;

      if (this.state.searchText) {
        dataAfterSearch = dataAfterSearch.filter(
          (item) =>
            item.State !== null &&
            item.State.toLowerCase().includes(
              this.state.searchText.toLowerCase()
            )
        );
      }

      return dataAfterSearch;
    } else {
      return this.state.consolidatedTableData;
    }
  };

  TableColumnHeader = (props) => {
    const { title, subtitle } = props;
    return (
      <div className="column-header-container">
        <div
          style={{
            color: "white",
            fontSize: "12px",
            whiteSpace: "nowrap",
            fontWeight: "normal",
            textAlign: "left",
          }}
        >
          {title}
        </div>
        <div
          style={{
            color: "#cbdfff",
            fontSize: "11px",
            fontWeight: "normal",
            textAlign: "left",
            // subtitle && title.length < subtitle.length ? "left" : "center"
          }}
        >
          {subtitle}
        </div>
      </div>
    );
  };

  createFilters = (label) => {
    let filterData = this.state.consolidatedTableData;
    //#region remove duplicate objects
    let uniqueFilterData = [];

    if (
      label === "is_active" &&
      this.state.consolidatedTableData &&
      this.state.consolidatedTableData.length > 0
    ) {
      uniqueFilterData = [
        { text: "Yes", value: 1 },
        { text: "No", value: 0 },
      ];
    } else {
      filterData.map((mainItem) =>
        uniqueFilterData.filter((item) => item[label] === mainItem[label])
          .length > 0
          ? null
          : uniqueFilterData.push(mainItem)
      );

      uniqueFilterData = uniqueFilterData.map((item) => {
        return {
          text: item[label],
          value: item[label],
        };
      });

      uniqueFilterData.sort((a, b) => a.text.localeCompare(b.text));
    }

    return uniqueFilterData;
  };

  submitTableRowData = (row) => {
    if (this.validate(row)) {
      this.setState({ isSubmitted: true });

      this.setState({ isSpinnerOnLoad: true });

      let params = {
        Adhat: row.Adhat === "" || row.Adhat === undefined ? null : row.Adhat,
        Commission:
          row.Commission === "" || row.Commission === undefined
            ? null
            : row.Commission,
        Cost_BuildUp_Mandi_ID: row.Cost_BuildUp_Mandi_ID,
        Dami: row.Dami,
        Gunny_Bag_PerMT:
          row.Gunny_Bag_PerMT === "" || row.Gunny_Bag_PerMT === undefined
            ? null
            : row.Gunny_Bag_PerMT,
        HamaliPala_n_LdngPerMT:
          row.HamaliPala_n_LdngPerMT === "" ||
            row.HamaliPala_n_LdngPerMT === undefined
            ? null
            : row.HamaliPala_n_LdngPerMT,
        Krishi_Kalyan: row.Krishi_Kalyan,
        Labour_Cost:
          row.Labour_Cost === "" || row.Labour_Cost === undefined
            ? null
            : row.Labour_Cost,
        Mandi_Tax:
          row.Mandi_Tax === "" || row.Mandi_Tax === undefined
            ? null
            : row.Mandi_Tax,
        RDF: row.RDF === "" || row.RDF === undefined ? null : row.RDF,
        State: row.State,
        Sutli_PerMT:
          row.Sutli_PerMT === "" || row.Sutli_PerMT === undefined
            ? null
            : row.Sutli_PerMT,
        Trader_Margin:
          row.Trader_Margin === "" || row.Trader_Margin === undefined
            ? null
            : row.Trader_Margin,
        krishi_kalyan_cess:
          row.krishi_kalyan_cess === "" || row.krishi_kalyan_cess === undefined
            ? null
            : row.krishi_kalyan_cess,
      };
      API.put("/master_consolidated_cost_buildup", params, {

      })
        .then((response) => {
          if (response.status === 200 && response.data.status) {
            message.success(response.data.message, 5);
            this.setModalVisibility(false);
            this.getMasterConsolidatedCost();
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
          } else if (!response.data.status) {
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
            message.error(response.data.message, 5);
            // this.setModalVisibility(false);
          } else {
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
            message.error("Please try again", 5);
          }
        })
        .catch((err) => {
          if (err) {
            if (err.response && err.response.status === 400)
              message.error("Something went wrong. Please try again later.", 5);
            else {
              message.error(
                "Something went wrong.. Please try again later.",
                5
              );
            }
          } else {
            message.error("Something went wrong... Please try again later.", 5);
          }
          this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
          // this.setModalVisibility(false);
        });
    }
  };

  validate = (rowData) => {
    var alphaNumericRegex = /^[a-zA-Z0-9]*$/;
    var numberRegex = /^[0-9.]+$/;

    if (
      rowData.State === null ||
      rowData.State === undefined ||
      rowData.State === ""
    ) {
      message.error(`Please select State !`, 5);
      return false;
    }
    // else if (
    //   rowData.Mandi_Tax === null ||
    //   rowData.Mandi_Tax === undefined ||
    //   rowData.Mandi_Tax === ""
    // ) {
    //   message.error(`Please enter Mandi tax!`, 5);
    //   return false;
    // }
    // else if (
    //   rowData.Dami === null ||
    //   rowData.Dami === undefined ||
    //   rowData.Dami === ""
    // ) {
    //   message.error(`Please enter Dami!`, 5);
    //   return false;
    // }
    // else if (
    //   rowData.Adhat === null ||
    //   rowData.Adhat === undefined ||
    //   rowData.Adhat === ""
    // ) {
    //   message.error(`Please enter Adhat!`, 5);
    //   return false;
    // }
    // else if (
    //   rowData.Commission === null ||
    //   rowData.Commission === undefined ||
    //   rowData.Commission === ""
    // ) {
    //   message.error(`Please enter Commission!`, 5);
    //   return false;
    // }
    // else if (
    //   rowData.Trader_Margin === null ||
    //   rowData.Trader_Margin === undefined ||
    //   rowData.Trader_Margin === ""
    // ) {
    //   message.error(`Please enter Trader margin!`, 5);
    //   return false;
    // }
    // else if (
    //   rowData.RDF === null ||
    //   rowData.RDF === undefined ||
    //   rowData.RDF === ""
    // ) {
    //   message.error(`Please enter RDF!`, 5);
    //   return false;
    // }
    // else if (
    //   rowData.Gunny_Bag_PerMT === null ||
    //   rowData.Gunny_Bag_PerMT === undefined ||
    //   rowData.Gunny_Bag_PerMT === ""
    // ) {
    //   message.error(`Please enter Gunny bag!`, 5);
    //   return false;
    // }
    // else if (
    //   rowData.Sutli_PerMT === null ||
    //   rowData.Sutli_PerMT === undefined ||
    //   rowData.Sutli_PerMT === ""
    // ) {
    //   message.error(`Please enter Sutli!`, 5);
    //   return false;
    // }
    // else if (
    //   rowData.HamaliPala_n_LdngPerMT === null ||
    //   rowData.HamaliPala_n_LdngPerMT === undefined ||
    //   rowData.HamaliPala_n_LdngPerMT === ""
    // ) {
    //   message.error(`Please enter HamaliPala landing!`, 5);
    //   return false;
    // }
    // else if (
    //   rowData.Labour_Cost === null ||
    //   rowData.Labour_Cost === undefined ||
    //   rowData.Labour_Cost === ""
    // ) {
    //   message.error(`Please enter Labour cost!`, 5);
    //   return false;
    // }
    // else if (rowData.Mandi_Min_Buying_MT < 0) {
    //   message.error(
    //     `Minimum buying price cannot be less than 0 for ${rowData.mandi_name},${rowData.hub_name}, ${rowData.region_name} and ${rowData.hub_region_name} !`,
    //     5
    //   );
    //   return false;
    // } else if (

    return true;
  };

  setModalVisibility = (status) => {
    if (status) {
      const nData = {
        Adhat: undefined,
        Buildup_Cost: undefined,
        Buildup_on_Qty: undefined,
        Commission: undefined,
        Cost_BuildUp_Mandi_ID: undefined,
        Dami: undefined,
        Financial_Year: "",
        Gunny_Bag_PerMT: undefined,
        HamaliPala_n_LdngPerMT: undefined,
        Krishi_Kalyan: null,
        Labour_Cost: undefined,
        Last_Updated_Date: "",
        Last_Updated_User_ID: "",
        Mandi_Tax: undefined,
        RDF: undefined,
        State: "",
        Sutli_PerMT: undefined,
        Trader_Margin: undefined,
        is_active: false,
        isEdit: false,
        krishi_kalyan_cess: undefined,
      };

      this.setState({ newData: nData });
    }

    this.setState({ isModalVisible: status });
  };

  handleDropdownChange = (value, row, target, index, optionlabel, isPopup) => {
    const specificstate = this.state.stateList.filter((item) => {
      if (item.state_id === value) {
        return item;
      }
    });

    let a;

    if (isPopup === false) {
      a = this.state.consolidatedTableData;

      if (target === "State") {
        a[row.key].State = optionlabel;
        a[row.key].param_data_value = value;
        row.State = optionlabel;
        row.param_data_value = value;
      }

      this.setState({ consolidatedTableData: a });
    } else {
      a = this.state.newData;

      if (target === "State") {
        a.State = optionlabel;
        a.param_data_value = value;
        row.State = optionlabel;
        row.param_data_value = value;
      }

      this.setState({ newData: a });
    }
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  handleChange = (e, row, index, name, isPopup) => {
    let a;
    var alphaNumericRegex = /^[a-zA-Z0-9]*$/;
    var numberRegex = /^[0-9.]+$/;
    if (isPopup === false) {
      a = this.state.consolidatedTableData;

      switch (name) {

        case "krishi_kalyan_cess":
          if (e.target.value === "") {
            a[row.key].krishi_kalyan_cess = "";
          } else if (e.target.value.match(numberRegex)) {
            a[row.key].krishi_kalyan_cess = e.target.value;
          }

          this.setState({ consolidatedTableData: a });
          return;
        case "Mandi_Tax":
          if (e.target.value === "") {
            a[row.key].Mandi_Tax = "";
          } else if (e.target.value.match(numberRegex)) {
            a[row.key].Mandi_Tax = e.target.value;
          }

          this.setState({ consolidatedTableData: a });
          return;
        case "Dami":
          if (e.target.value === "") {
            a[row.key].Dami = "";
          } else if (e.target.value.match(numberRegex)) {
            a[row.key].Dami = e.target.value;
          }

          this.setState({ consolidatedTableData: a });
          return;
        case "Adhat":
          if (e.target.value === "") {
            a[row.key].Adhat = "";
          } else if (e.target.value.match(numberRegex)) {
            a[row.key].Adhat = e.target.value;
          }

          this.setState({ consolidatedTableData: a });
          return;
        case "Commission":
          if (e.target.value === "") {
            a[row.key].Commission = "";
          } else if (e.target.value.match(numberRegex)) {
            a[row.key].Commission = e.target.value.toUpperCase();
          }

          this.setState({ consolidatedTableData: a });
          return;
        case "Trader_Margin":
          if (e.target.value === "") {
            a[row.key].Trader_Margin = "";
          } else if (e.target.value.match(numberRegex)) {
            a[row.key].Trader_Margin = e.target.value;
          }

          this.setState({ consolidatedTableData: a });
          return;
        case "RDF":
          if (e.target.value === "") {
            a[row.key].RDF = "";
          } else if (e.target.value.match(numberRegex)) {
            a[row.key].RDF = e.target.value;
          }

          this.setState({ consolidatedTableData: a });
          return;
        case "Gunny_Bag_PerMT":
          if (e.target.value === "") {
            a[row.key].Gunny_Bag_PerMT = "";
          } else if (e.target.value.match(numberRegex)) {
            a[row.key].Gunny_Bag_PerMT = e.target.value;
          }

          this.setState({ consolidatedTableData: a });
          return;
        case "Sutli_PerMT":
          if (e.target.value === "") {
            a[row.key].Sutli_PerMT = "";
          } else if (e.target.value.match(numberRegex)) {
            a[row.key].Sutli_PerMT = e.target.value;
          }

          this.setState({ consolidatedTableData: a });
          return;
        case "HamaliPala_n_LdngPerMT":
          if (e.target.value === "") {
            a[row.key].HamaliPala_n_LdngPerMT = "";
          } else if (e.target.value.match(numberRegex)) {
            a[row.key].HamaliPala_n_LdngPerMT = e.target.value;
          }

          this.setState({ consolidatedTableData: a });
          return;
        case "Labour_Cost":
          if (e.target.value === "") {
            a[row.key].Labour_Cost = "";
          } else if (e.target.value.match(numberRegex)) {
            a[row.key].Labour_Cost = e.target.value;
          }

          this.setState({ consolidatedTableData: a });
          return;
        default:
          return "";
      }
    } else {
      a = this.state.newData;

      switch (name) {
        case "krishi_kalyan_cess":
          if (e.target.value === "") {
            a.krishi_kalyan_cess = "";
          } else if (e.target.value.match(numberRegex)) {
            a.krishi_kalyan_cess = e.target.value;
          }

          this.setState({ newData: a });
          return;
        case "Mandi_Tax":
          if (e.target.value === "") {
            a.Mandi_Tax = "";
          } else if (e.target.value.match(numberRegex)) {
            a.Mandi_Tax = e.target.value;
          }

          this.setState({ newData: a });
          return;
        case "Dami":
          if (e.target.value === "") {
            a.Dami = "";
          } else if (e.target.value.match(numberRegex)) {
            a.Dami = e.target.value;
          }

          this.setState({ newData: a });
          return;
        case "Adhat":
          if (e.target.value === "") {
            a.Adhat = "";
          } else if (e.target.value.match(numberRegex)) {
            a.Adhat = e.target.value;
          }

          this.setState({ newData: a });
          return;
        case "Commission":
          if (e.target.value === "") {
            a.Commission = "";
          } else if (e.target.value.match(numberRegex)) {
            a.Commission = e.target.value.toUpperCase();
          }

          this.setState({ newData: a });
          return;
        case "Trader_Margin":
          if (e.target.value === "") {
            a.Trader_Margin = "";
          } else if (e.target.value.match(numberRegex)) {
            a.Trader_Margin = e.target.value;
          }

          this.setState({ newData: a });
          return;
        case "RDF":
          if (e.target.value === "") {
            a.RDF = "";
          } else if (e.target.value.match(numberRegex)) {
            a.RDF = e.target.value;
          }

          this.setState({ newData: a });
          return;
        case "Gunny_Bag_PerMT":
          if (e.target.value === "") {
            a.Gunny_Bag_PerMT = "";
          } else if (e.target.value.match(numberRegex)) {
            a.Gunny_Bag_PerMT = e.target.value;
          }

          this.setState({ newData: a });
          return;
        case "Sutli_PerMT":
          if (e.target.value === "") {
            a.Sutli_PerMT = "";
          } else if (e.target.value.match(numberRegex)) {
            a.Sutli_PerMT = e.target.value;
          }

          this.setState({ newData: a });
          return;
        case "HamaliPala_n_LdngPerMT":
          if (e.target.value === "") {
            a.HamaliPala_n_LdngPerMT = "";
          } else if (e.target.value.match(numberRegex)) {
            a.HamaliPala_n_LdngPerMT = e.target.value;
          }

          this.setState({ newData: a });
          return;
        case "Labour_Cost":
          if (e.target.value === "") {
            a.Labour_Cost = "";
          } else if (e.target.value.match(numberRegex)) {
            a.Labour_Cost = e.target.value;
          }

          this.setState({ newData: a });
          return;
        default:
          return "";
      }
      // this.setState({ newData: a });
    }
  };

  disabledDate = (current, rowData, text) => {
    return (
      current && current > moment(rowData.Start_Effective_Date, "YYYY-MM-DD")
    );
  };

  handleEditClick = (rowData, index) => {
    let tempData = this.state.consolidatedTableData.map((item) => {
      if (item.key === rowData.key) {
        return {
          ...item,
          isEdit: true,
        };
      } else {
        return {
          ...item,
          isEdit: false,
        };
      }
    });

    this.setState({ consolidatedTableData: tempData });

    this.setState({ rowData: rowData });
  };

  handleCancelClick = (rowData, index) => {
    let tempData = this.state.consolidatedTableData.map((item) => {
      if (item.key === rowData.key) {
        return this.state.rowData;
      } else {
        return item;
      }
    });
    this.setState({ consolidatedTableData: tempData });
  };

  render() {
    const userTableProps = {
      pagination: false,
      size: "default",
      bordered: true,
      scroll: { x: 200, y: window.innerHeight - 250 },
    };

    return (
      <div
        className="mandi-master-container"
        style={{ height: window.innerHeight - 85, marginTop: "-45px" }}
      >
        <div className="top-container">
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row-reverse",
              placeItems: "flex-end",
              marginRight: "25px",
              marginBottom: "10px",
            }}
          >
            <Search
              disabled={!this.state.isAdd}
              placeholder="Search"
              allowClear
              onChange={(e) => this.setState({ searchText: e.target.value })}
              style={{ width: "25%" }}
            />
            <Button
              className="add-master-button"
              style={{
                height: "25px",
                width: "70px",
                backgroundColor: "#0b133d",
                color: "white",
              }}
              disabled={!canUserEdit(
                this.props.userData,
                null,
                FeatureCodes.MS_COST_BUILDUP_MANDI_EDIT
              ) || !this.state.isAdd}
              onClick={() => this.setModalVisibility(true)}
            >
              &#10010; Add
            </Button>
          </div>
        </div>

        <div>
        <div
        style={{
          marginLeft: "25px",
          marginTop: "-8px",
          fontSize: "14px",
          color: "red",
          display: "flex",
          float: "left",
        }}
      > {getUserMSRBACMessage(this.props.messageAccess,FeatureCodes.MS_COST_BUILDUP_MANDI_EDIT)}
      </div>
        <div
          style={{
            marginRight: "20px",
            marginTop: "-8px",
            fontSize: "15px",
            color: "#0B133D",
            display: "flex",
            float: "right",
          }}
        >
          Total number of records:&nbsp;
          <b>
            {this.state.consolidatedTableData && this.setTableData().length}
          </b>
        </div>
        </div>

        <Spin
          spinning={this.state.isSpinnerOnLoad}
          size={"large"}
          tip="Loading..."
          style={{ alignSelf: "center", zIndex: 9999, marginTop: "20px" }}
        >
          <Modal
            className="add-master-modal"
            title={"Add Cost Build-up Mandi"}
            footer={false}
            // style={{
            //   textAlign: "center",
            //   height: "400px",
            // }}
            // visible={true}
            visible={this.state.isModalVisible}
            destroyOnClose={true}
            onCancel={this.handleCancel}
          >
            <div className="master-form-container">
              <Form
                layout="horizontal"
                //   onValuesChange={onFormLayoutChange}
                size={"small"}
              // onSubmit={submitTableRowData(this.state.newData)}
              >
                <div>
                  <div
                    className="checkbox-group1"
                    style={{
                      display: "inline-block",
                      width: "30%",
                      marginRight: 7,
                    }}
                  >
                    <Form.Item label="State">
                      <Select
                        className="remarks-select"
                        showSearch
                        optionFilterProp="children"
                        name="State"
                        defaultValue={this.state.newData.State}
                        value={this.state.newData.State}
                        onSelect={(value, option) =>
                          this.handleDropdownChange(
                            value,
                            this.state.newData,
                            "State",
                            -0,
                            option.props.children,
                            true
                          )
                        }
                      >
                        {this.state.stateList &&
                          this.state.stateList.length &&
                          this.state.stateList.map((hubItem, idx) => (
                            <Option
                              key={hubItem.state_id}
                              value={hubItem.state_id}
                            >
                              {hubItem.state_name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </div>

                  <div
                    className="checkbox-group1"
                    style={{
                      display: "inline-block",
                      width: "30%",
                      marginRight: 7,
                    }}
                  >
                    <Form.Item label="Krishi Kalyan">
                      <Input
                        style={{ marginRight: "2px" }}
                        maxLength={100}
                        value={this.state.newData.krishi_kalyan_cess}
                        name="krishi_kalyan_cess"
                        onChange={(e) =>
                          this.handleChange(
                            e,
                            this.state.newData,
                            -0,
                            "krishi_kalyan_cess",
                            true
                          )
                        }
                      />
                    </Form.Item>
                  </div>

                  <div
                    className="checkbox-group1"
                    style={{
                      display: "inline-block",
                      width: "30%",
                      marginRight: 7,
                    }}
                  >
                    <Form.Item label="Mandi Tax">
                      <Input
                        style={{ marginRight: "2px" }}
                        maxLength={100}
                        value={this.state.newData.Mandi_Tax}
                        name="Mandi_Tax"
                        onChange={(e) =>
                          this.handleChange(
                            e,
                            this.state.newData,
                            -0,
                            "Mandi_Tax",
                            true
                          )
                        }
                      />
                    </Form.Item>
                  </div>

                  <div
                    className="checkbox-group1"
                    style={{
                      display: "inline-block",
                      width: "30%",
                      marginRight: 7,
                    }}
                  >
                    <Form.Item label="Dami">
                      <Input
                        style={{ marginRight: "2px" }}
                        maxLength={100}
                        value={this.state.newData.Dami}
                        name="Dami"
                        onChange={(e) =>
                          this.handleChange(
                            e,
                            this.state.newData,
                            -0,
                            "Dami",
                            true
                          )
                        }
                      />
                    </Form.Item>
                  </div>

                  <div
                    className="checkbox-group1"
                    style={{
                      display: "inline-block",
                      width: "30%",
                      marginRight: 7,
                    }}
                  >
                    <Form.Item label="Adhat">
                      <Input
                        style={{ marginRight: "2px" }}
                        maxLength={100}
                        value={this.state.newData.Adhat}
                        name="Adhat"
                        onChange={(e) =>
                          this.handleChange(
                            e,
                            this.state.newData,
                            -0,
                            "Adhat",
                            true
                          )
                        }
                      />
                    </Form.Item>
                  </div>

                  <div
                    className="checkbox-group1"
                    style={{
                      display: "inline-block",
                      width: "30%",
                      marginRight: 7,
                    }}
                  >
                    <Form.Item label="Commission">
                      <Input
                        style={{ marginRight: "2px" }}
                        maxLength={100}
                        value={this.state.newData.Commission}
                        name="Commission"
                        onChange={(e) =>
                          this.handleChange(
                            e,
                            this.state.newData,
                            -0,
                            "Commission",
                            true
                          )
                        }
                      />
                    </Form.Item>
                  </div>

                  <div
                    className="checkbox-group1"
                    style={{
                      display: "inline-block",
                      width: "30%",
                      marginRight: 7,
                    }}
                  >
                    <Form.Item label="Trader Margin">
                      <Input
                        style={{ marginRight: "2px" }}
                        maxLength={100}
                        value={this.state.newData.Trader_Margin}
                        name="Trader_Margin"
                        onChange={(e) =>
                          this.handleChange(
                            e,
                            this.state.newData,
                            -0,
                            "Trader_Margin",
                            true
                          )
                        }
                      />
                    </Form.Item>
                  </div>

                  <div
                    className="checkbox-group1"
                    style={{
                      display: "inline-block",
                      width: "30%",
                      marginRight: 7,
                    }}
                  >
                    <Form.Item label="RDF">
                      <Input
                        style={{ marginRight: "2px" }}
                        maxLength={100}
                        value={this.state.newData.RDF}
                        name="RDF"
                        onChange={(e) =>
                          this.handleChange(
                            e,
                            this.state.newData,
                            -0,
                            "RDF",
                            true
                          )
                        }
                      />
                    </Form.Item>
                  </div>

                  <div
                    className="checkbox-group1"
                    style={{
                      display: "inline-block",
                      width: "30%",
                      marginRight: 7,
                    }}
                  >
                    <Form.Item label="Gunny Bag (Per MT)">
                      <Input
                        style={{ marginRight: "2px" }}
                        maxLength={100}
                        value={this.state.newData.Gunny_Bag_PerMT}
                        name="Gunny_Bag_PerMT"
                        onChange={(e) =>
                          this.handleChange(
                            e,
                            this.state.newData,
                            -0,
                            "Gunny_Bag_PerMT",
                            true
                          )
                        }
                      />
                    </Form.Item>
                  </div>

                  <div
                    className="checkbox-group1"
                    style={{
                      display: "inline-block",
                      width: "39%",
                      marginRight: 7,
                    }}
                  >
                    <Form.Item label="Sutli (Per MT)">
                      <Input
                        style={{ marginRight: "2px" }}
                        maxLength={100}
                        value={this.state.newData.Sutli_PerMT}
                        name="Sutli_PerMT"
                        onChange={(e) =>
                          this.handleChange(
                            e,
                            this.state.newData,
                            -0,
                            "Sutli_PerMT",
                            true
                          )
                        }
                      />
                    </Form.Item>
                  </div>

                  <div
                    className="checkbox-group1"
                    style={{
                      display: "inline-block",
                      width: "39%",
                      marginRight: 7,
                    }}
                  >
                    <Form.Item label="HamaliPala Landing (Per MT)">
                      <Input
                        style={{ marginRight: "2px" }}
                        maxLength={100}
                        value={this.state.newData.HamaliPala_n_LdngPerMT}
                        name="HamaliPala_n_LdngPerMT"
                        onChange={(e) =>
                          this.handleChange(
                            e,
                            this.state.newData,
                            -0,
                            "HamaliPala_n_LdngPerMT",
                            true
                          )
                        }
                      />
                    </Form.Item>
                  </div>

                  <div
                    className="checkbox-group1"
                    style={{
                      display: "inline-block",
                      width: "30%",
                      marginRight: 7,
                    }}
                  >
                    <Form.Item label="Labour Cost">
                      <Input
                        style={{ marginRight: "2px" }}
                        maxLength={100}
                        value={this.state.newData.Labour_Cost}
                        name="Labour_Cost"
                        onChange={(e) =>
                          this.handleChange(
                            e,
                            this.state.newData,
                            -0,
                            "Labour_Cost",
                            true
                          )
                        }
                      />
                    </Form.Item>
                  </div>
                </div>

                <div className="form-bottom-container">
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={this.state.isSubmitted}
                      onClick={() => {
                        // this.setState({isSubmitted:false})
                        this.submitTableRowData(this.state.newData);
                      }}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </Modal>

          <div
            className="table-container1"
            style={{ maxHeight: "450px", width: "99%", margin: "6px 0px" }}
          >
            <Table
              {...userTableProps}
              columns={[
                {
                  // fixed: true,
                  title: "State",
                  dataIndex: "State",
                  key: "State",
                  width: 180,
                  filters: this.createFilters("State"),
                  onFilter: (value, record) => record.State.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.State.localeCompare(b.State),
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div
                          style={{ marginLeft: "-15px", marginRight: "-10px" }}
                        >
                          <Select
                            className="remarks-select"
                            showSearch
                            optionFilterProp="children"
                            disabled={true}
                            name="State"
                            defaultValue={rowRecord.State}
                            value={rowRecord.State}
                            onSelect={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "State",
                                index,
                                option.props.children,
                                false
                              )
                            }
                          >
                            {this.state.stateList &&
                              this.state.stateList.length &&
                              this.state.stateList.map((hubItem, idx) => (
                                <Option
                                  key={hubItem.state_id}
                                  value={hubItem.state_id}
                                >
                                  {hubItem.state_name}
                                </Option>
                              ))}
                          </Select>
                        </div>
                      </>
                    );
                  },
                },
                {
                  key: "Mandi_Tax",
                  title: "Mandi Tax",
                  dataIndex: "Mandi_Tax",
                  width: 115,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.Mandi_Tax - b.Mandi_Tax,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            style={{ marginRight: "2px" }}
                            maxLength={100}
                            disabled={!rowRecord.isEdit}
                            value={rowRecord.Mandi_Tax}
                            name="Mandi_Tax"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "Mandi_Tax",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },
                {
                  key: "krishi_kalyan_cess",
                  title: "Krishi Kalyan",
                  dataIndex: "krishi_kalyan_cess",
                  width: 115,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.krishi_kalyan_cess - b.krishi_kalyan_cess,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            style={{ marginRight: "2px" }}
                            maxLength={100}
                            disabled={!rowRecord.isEdit}
                            value={rowRecord.krishi_kalyan_cess}
                            name="krishi_kalyan_cess"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "krishi_kalyan_cess",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },
                {
                  key: "Dami",
                  title: "Dami",
                  dataIndex: "Dami",
                  width: 100,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.Dami - b.Dami,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            style={{ marginRight: "2px" }}
                            disabled={!rowRecord.isEdit}
                            maxLength={100}
                            value={rowRecord.Dami}
                            name="Dami"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "Dami",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },

                {
                  key: "Adhat",
                  title: "Adhat",
                  dataIndex: "Adhat",
                  width: 100,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.Adhat - b.Adhat,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            style={{ marginRight: "2px" }}
                            disabled={!rowRecord.isEdit}
                            maxLength={100}
                            value={rowRecord.Adhat}
                            name="Adhat"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "Adhat",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },

                {
                  key: "Commission",
                  title: "Commission",
                  dataIndex: "Commission",
                  width: 120,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.Commission - b.Commission,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            style={{ marginRight: "2px" }}
                            disabled={!rowRecord.isEdit}
                            maxLength={100}
                            value={rowRecord.Commission}
                            name="Commission"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "Commission",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },

                {
                  key: "Trader_Margin",
                  title: "Trader Margin",
                  dataIndex: "Trader_Margin",
                  width: 100,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.Trader_Margin - b.Trader_Margin,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            style={{ marginRight: "2px" }}
                            disabled={!rowRecord.isEdit}
                            maxLength={100}
                            value={rowRecord.Trader_Margin}
                            name="Trader_Margin"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "Trader_Margin",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },

                {
                  key: "RDF",
                  title: "RDF",
                  dataIndex: "RDF",
                  width: 100,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.RDF - b.RDF,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            style={{ marginRight: "2px" }}
                            disabled={!rowRecord.isEdit}
                            maxLength={100}
                            value={rowRecord.RDF}
                            name="RDF"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "RDF",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },

                {
                  key: "Gunny_Bag_PerMT",
                  title: "Gunny Bag (Per MT)",
                  dataIndex: "Gunny_Bag_PerMT",
                  width: 100,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.Gunny_Bag_PerMT - b.Gunny_Bag_PerMT,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            style={{ marginRight: "2px" }}
                            disabled={!rowRecord.isEdit}
                            maxLength={100}
                            value={rowRecord.Gunny_Bag_PerMT}
                            name="Gunny_Bag_PerMT"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "Gunny_Bag_PerMT",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },

                {
                  key: "Sutli_PerMT",
                  title: "Sutli (Per MT)",
                  dataIndex: "Sutli_PerMT",
                  width: 100,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.Sutli_PerMT - b.Sutli_PerMT,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            style={{ marginRight: "2px" }}
                            disabled={!rowRecord.isEdit}
                            maxLength={100}
                            value={rowRecord.Sutli_PerMT}
                            name="Sutli_PerMT"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "Sutli_PerMT",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },

                {
                  key: "HamaliPala_n_LdngPerMT",
                  title: "HamaliPala Landing (Per MT)",
                  dataIndex: "HamaliPala_n_LdngPerMT",
                  width: 110,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) =>
                    a.HamaliPala_n_LdngPerMT - b.HamaliPala_n_LdngPerMT,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            style={{ marginRight: "2px" }}
                            disabled={!rowRecord.isEdit}
                            maxLength={100}
                            value={rowRecord.HamaliPala_n_LdngPerMT}
                            name="HamaliPala_n_LdngPerMT"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "HamaliPala_n_LdngPerMT",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },

                {
                  key: "Labour_Cost",
                  title: "Labour Cost (MT)",
                  dataIndex: "Labour_Cost",
                  width: 100,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.Labour_Cost - b.Labour_Cost,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            style={{ marginRight: "2px" }}
                            disabled={!rowRecord.isEdit}
                            maxLength={100}
                            value={rowRecord.Labour_Cost}
                            name="Labour_Cost"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "Labour_Cost",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },

                {
                  title: "Action",
                  dataIndex: "Submit",
                  key: "Submit",
                  width: 140,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ display: "inline-flex", width: "100%" }}>
                          <Button
                          className={!canUserEdit(
                            this.props.userData,
                            null,
                            FeatureCodes.MS_COST_BUILDUP_MANDI_EDIT
                          ) ? "disabledBtnClass" : "btnClass"}
                          disabled={!canUserEdit(
                            this.props.userData,
                            null,
                            FeatureCodes.MS_COST_BUILDUP_MANDI_EDIT
                          )}
                            type="primary"
                            size={"small"}
                            style={{
                              marginLeft: 5,
                              alignSelf: "center",
                              color: "white",
                              borderRadius: 15,
                              padding: "0px 0px 2px",
                              height: 31,
                              backgroundColor: "#20346a",
                            }}
                            block
                            htmlType="submit"
                            // disabled={this.state.isSubmitted}
                            onClick={() =>
                              rowRecord.isEdit
                                ? this.submitTableRowData(rowRecord)
                                : this.handleEditClick(rowRecord, index)
                            }
                          >
                            {rowRecord.isEdit ? "Submit" : "Edit"}
                          </Button>

                          <Button
                            className={
                              !rowRecord.isEdit
                                ? "disabledBtnClass"
                                : "btnClass"
                            }
                            type="primary"
                            size={"small"}
                            style={{
                              marginLeft: 5,
                              alignSelf: "center",
                              color: "white",
                              borderRadius: 15,
                              padding: "0px 0px 2px",
                              height: 31,
                              backgroundColor: "#20346a",
                            }}
                            block
                            htmlType="submit"
                            disabled={!rowRecord.isEdit}
                            onClick={() =>
                              this.handleCancelClick(rowRecord, index)
                            }
                          >
                            Cancel
                          </Button>
                        </div>
                      </>
                    );
                  },
                },
              ]}
              dataSource={this.setTableData()}
            />
          </div>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
  messageAccess: selectMSMessageRBAC,
});
export default connect(mapStateToProps)(ConsolidatedCostMaster);
//export default ConsolidatedCostMaster;
