export const MapActionTypes = {
  SET_CURRENT_SCREEN: "SET_CURRENT_SCREEN",

  SET_MAP_MANDI_SELECT_VALUES: "SET_MAP_MANDI_SELECT_VALUES",
  SET_MAP_FOR_SELECT_VALUES: "SET_MAP_FOR_SELECT_VALUES",

  SET_MANDI_REGION: "SET_MANDI_REGION",
  SET_MANDI_HUB: "SET_MANDI_HUB",
  SET_MANDI_MANDI: "SET_MANDI_MANDI",

  SET_FOR_REGION: "SET_FOR_REGION",
  SET_FOR_HUB: "SET_FOR_HUB",

  SET_FARMER_REGION: "SET_FARMER_REGION",
  SET_FARMER_HUB: "SET_FARMER_HUB"
};
