import React from "react";
import "./custom-select-temporal.styles.css";

import { Select } from "antd";

const { Option } = Select;
/**
 *
 * @description Dropdown component used in Monthly price and volume tabs.
 * @component
 * @property {boolean} isPrice True if used in monthly price component.
 * @property {boolean} isConstrained True if the tab is displaying data for monthly constained volume.
 * @property {boolean} connectState Enable the callback on select.
 * @property {string[]} list Dropdown items.
 * @property {function} onSelect Callback on selecting an item.
 * @property {string} placeholder Placeholder to display when no selection is made.
 * 
 */
class CustomSelectTemporal extends React.Component {
  state = {
    currentFilterIndex: 0,
  };
  render() {
    const {
      isPrice,
      isConstrained,
      list,
      onSelect,
      connectState,
      placeholder,
    } = this.props;


    /**
     * @description Handle the selection event.
     * @param {string} value concatenated string containing month and run count.
     * @memberof CustomSelectTemporal
     */
    const handleSelect = (value) => {
      let modifiedValue = {
        month: parseInt(value.split(",")[0]),
        run_cnt: parseInt(value.split(",")[1]),
      };
      if (!isPrice) {
        modifiedValue = { ...modifiedValue, type: isConstrained ? "C" : "U" };
      }

      ///////////////////////////////////////////////////////////

      let selectedFilterIndex = list.findIndex(
        (item) =>
          item.month === modifiedValue.month &&
          item.run_cnt === modifiedValue.run_cnt
      );


      this.setState({ currentFilterIndex: selectedFilterIndex });

      /////////////////////////////////////////////////////////////

      if (connectState) onSelect(modifiedValue);
    };

    return (
      <Select
        placeholder={placeholder}
        value={
          list.length !== 0
            ? list[this.state.currentFilterIndex].month +
            "," +
            list[this.state.currentFilterIndex].run_cnt
            : "Select Run Count"
        }
        onSelect={
          handleSelect ? (value) => handleSelect(value) : console.log("")
        }
      >
        {list.map((item, idx) => (
          <Option value={item.month + "," + item.run_cnt} key={item.key}>
            {item.model_run_label}
          </Option>
        ))}
      </Select>
    );
  }
}

export default CustomSelectTemporal;
