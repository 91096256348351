import React from "react";
import "./approvals.styles.css";
import { Component } from "react";
import {
  Table,
  Checkbox,
  Drawer,
  Button,
  Popover,
  Modal,
  Input,
  message,
} from "antd";
import CustomDropdownChooseRegion from "../custom-dropdown-choose-region/custom-dropdown-choose-region.component";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  selectUserData,
  selectAllBranches,
} from "../../redux/user/user.selectors";
import arrowGreen from "../../assets/green.svg";
import arrowRed from "../../assets/up.svg";
import API from "../../api";
import Approve1 from "../../assets/approval/approve1.png";
import Approve2 from "../../assets/approval/approve2.png";
import Reject1 from "../../assets/approval/reject1.png";
import Reject2 from "../../assets/approval/reject2.png";
import Pending from "../../assets/approval/pending.png";
import Approve1Reject1 from "../../assets/approval/approve1reject2.png";

const tableProps = {
  pagination: false,
  size: "default",
  bordered: true,
  scroll: { x: 200, y: window.innerHeight - 260 },
};

/**
 * @component
 * @description Reusable component to display table header.
 * @property {string} title Title of header
 * @property {string} subtitle Subtitle of header
 * @memberof Approvals
 */
const TableColumnHeader = (props) => {
  const { title, subtitle } = props;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          fontWeight: "400",
          color: "white",
          fontSize: "14px",
          whiteSpace: "nowrap",
          textAlign: "left",
        }}
      >
        {title}
      </div>
      <div
        style={{
          color: "#cbdfff",
          fontSize: "11px",
          fontWeight: "normal",
          textAlign: "left",
        }}
      >
        {subtitle}
      </div>
    </div>
  );
};

/**
 * @component
 * @description Reusable component to display approval status.
 * @property {object} rowRecord Modified row record
 * @property {object} user Current user details
 * @memberof Approvals
 */
const TableStatusComponent = (props) => {
  const { rowRecord, user } = props;
  var image;
  let count = (rowRecord.approval1 === null) + (rowRecord.approval2 === null);
  if (count === 2) {
    image = Pending;
  } else if (count === 1) {
    image = rowRecord.approval1 || rowRecord.approval2 ? Approve1 : Reject1;
  } else {
    if (rowRecord.approval1) {
      image = rowRecord.approval2 ? Approve2 : Approve1Reject1;
    } else {
      image = rowRecord.approval2 ? Approve1Reject1 : Reject2;
    }
  }
  return (
    <Popover
      overlayClassName="popover-appoval"
      content={
        <div>
          {rowRecord.approval1 === null && rowRecord.approval2 === null ? (
            <div>Pending</div>
          ) : (
            <div>
              <div className="line-one">
                {rowRecord.approval1 !== null
                  ? rowRecord.approval1 === true
                    ? "Approved by : "
                    : `Rejected by     : `
                  : ""}
                {rowRecord.approver1Name === user.userName
                  ? "You"
                  : rowRecord.approver1Name}
              </div>
              <div className="line-two">
                {rowRecord.approval2 !== null
                  ? rowRecord.approval2 === true
                    ? "Approved by : "
                    : `Rejected by     : `
                  : ""}
                {rowRecord.approver2Name === user.userName
                  ? "You"
                  : rowRecord.approver2Name}
              </div>
            </div>
          )}
        </div>
      }
      trigger="hover"
    >
      <div className="approval-status">
        <img className="approval-status-image" src={image} alt="" />
      </div>
    </Popover>
  );
};

/**
 * @component
 * @description Reusable component to display value and deviation.
 * @property {number} item Value
 * @property {number} diff Deviation
 * @property {number} percent Deviation percentage
 * @property {boolean} isQty True if component is used in quantity column
 * @memberof Approvals
 */
const TableColumnData = (props) => {
  // render: (row, r) => {
  const { item, diff, percent, isQty } = props;
  // console.log(item, diff, percent);

  return (
    <div>
      <div>{item}</div>
      <div
        className="new-bar"
        style={{
          backgroundColor: "#FFFFFF00",
          paddingLeft: "0px",
          width: "max-content",
          color: isQty ? "#a9a9a9" : diff < 0 ? "#5bc95a" : "#d53c3d",
        }}
      >
        <div style={{ paddingTop: "1px", paddingRight: "6px" }}>{`${diff} ${
          percent === 0 ? "" : `(${percent}%)`
        }`}</div>
        <div
          style={{
            paddingTop: "1px",

            transform: `${diff < 0 ? "rotate(180deg)" : ""}`,
          }}
        >
          {!isQty && (
            <img src={diff >= 0 ? arrowRed : arrowGreen} alt="" width="8px" />
          )}
        </div>
      </div>
    </div>
  );
};

/**
 *
 * @description Approvals tab contents in buying decision page.
 * @component
 * @extends {Component}
 */
class Approvals extends Component {
  state = {
    showArchived: true,
    selectedBranchName: "",
    selectedBranchID: "",
    userBranches: [],
    tableData: [],
    loading: true,
    selectAll: false,

    drawerVisible: false,
    noOfChanges: "",

    modalVisible: false,
    comments: "",
  };

  /**
   * @function
   * @description Get branches associated with the user.
   * @property {object} branches All branches
   * @property {object} user User details
   * @memberof Approvals
   */
  setUserBranches = () => {
    const { branches, user } = this.props;
    let branch = [];
    for (var i = 0; i < user.userTerritories.length; i++) {
      for (var j = 0; j < branches.length; j++) {
        if (user.userTerritories[i] === branches[j].territory_id) {
          branch.push(branches[j]);
        }
      }
    }
    this.handleBranchSelect(branch[0].territory_name, {
      key: branch[0].territory_id,
    });
    if (branch.length !== 0) {
      this.setState({
        selectedBranchName: branch[0].territory_name,
        selectedBranchID: branch[0].territory_id,
        userBranches: branch,
      });
    }
  };

  /**
   * @function
   * @description Wait for data to load into redux
   * @memberof Approvals
   */
  waitForBranchData() {
    // console.log("LoG: ", this.props.branches);
    if (this.props.branches.length !== 0) {
      //   console.log("ITERATION: ", 1);
      this.setUserBranches();
    } else {
      setTimeout(() => {
        this.waitForBranchData();
      }, 100);
    }
  }

  /**
   * @function
   * @description Handle branch selection and set table data.
   * @param {string} item Selected branch
   * @param {object} props Selected branch properties
   * @memberof Approvals
   */
  handleBranchSelect = (item, props) => {
    this.setState({ loading: true, noOfChanges: 0 });
    // console.log("HI", item, props);
    //Call API and set data here

    const params = {
      branch_id: props.key,
    };
    API.get("/approvals", {
      params: params,
    })
      .then((response) => {
        // console.log("APPROVAL RESPONSE: ", response.data);
        let count = 0;
        const dd = response.data.data.buying_decisions_list
          .map((item) => {
            // const dd = Data.data.buying_decisions_list.map((item) => {
            if (
              // item.planned_qty !== 0 &&
              item.lc_min !== 0 &&
              item.lc_max !== 0
            ) {
              return {
                key: count++,
                data: item,
                hubId: item.hub_id,
                hub: item.hub_name,
                source:
                  item.source_type === "mandi" ? "Mandi" : item.source_type,
                varietyId: item.variety_id,
                variety: item.variety_name,
                arrivals: Math.round(item.arrival_qty),
                modalPrice: Math.round(item.arrival_price),
                // planned qty
                plannedQty: Math.round(item.planned_qty),
                plannedQtyDiff: Math.round(item.planned_qty_deviation),
                plannedQtyPercent: Math.round(
                  item.planned_qty_deviation_rate * 100
                ),

                // LC min
                lcMin: Math.round(item.lc_min),
                lcMinDiff: Math.round(item.lc_min_deviation),
                lcMinPercent: (item.lc_min_deviation_rate * 100).toFixed(1),

                // LC max
                lcMax: Math.round(item.lc_max),
                lcMaxDiff: Math.round(item.lc_max_deviation),
                lcMaxPercent: (item.lc_max_deviation_rate * 100).toFixed(1),

                lcPlanned: Math.round(item.lc_planned),
                remarks: item.remark,
                action: false,
                //Approvals
                //1
                approval1: item.approval1,
                approver1Name: item.approver1_name,
                //2
                approval2: item.approval2,
                approver2Name: item.approver2_name,

                //Reference for suggested price
                suggestedPrice: item.suggested_price,
                suggestedQty: item.suggested_qty,

                //claims and division
                claimType: item.claim_type,
                division: item.division,
              };
            }
          })
          .filter((item) => item !== undefined);

        this.setState({
          selectedBranchID: props.key,
          selectedBranchName: item,
          tableData: dd,
          loading: false,
          drawerVisible: false,
          selectAll: false,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      });
  };

  componentDidMount() {
    //Waiting for getting branch data from redux
    this.waitForBranchData();
  }

  render() {
    const { user } = this.props;

    /**
     * @description Intermediate function to conditionally set data in the table
     * @memberof Approvals
     * @returns Table data
     */
    const setDataForTable = () => {
      const data = this.state.tableData;
      let newData = [];
      if (!this.state.showArchived) {
        newData = data.filter(
          (item) => item.approval1 === null || item.approval2 === null
        );
      } else {
        newData = data;
      }
      return newData;
    };

    /**
     *
     * @description Set table filters
     * @param {string} label Column name
     * @memberof Approvals
     * @returns Filtered data
     */
    const createFilters = (label) => {
      let filterData = this.state.tableData;
      //#region remove duplicate objects
      let uniqueFilterData = [];

      filterData !== 0 &&
        filterData.map((mainItem) =>
          uniqueFilterData.filter((item) => item[label] === mainItem[label])
            .length > 0
            ? null
            : uniqueFilterData.push(mainItem)
        );

      uniqueFilterData = uniqueFilterData.map((item) => {
        return {
          text: item[label],
          value: item[label],
        };
      });

      //#endregion

      return uniqueFilterData;
    };

    /**
     *
     * @description Post approval or rejection of buying plans.
     * @param {string} type approve/reject
     * @memberof Approvals
     */
    const postApprovalData = (type) => {
      this.setState({ loading: true });
      let dataToSend = {
        buying_approvals: [],
        status: type === "approve" ? true : false,
        comments: this.state.comments,
      };
      dataToSend.buying_approvals = this.state.tableData
        .map((item) => {
          if (item.action) {
            return {
              // branch_id: this.state.selectedBranchID,
              // hub_id: item.hubId,
              // source_type: item.source === "Mandi" ? "mandi" : item.source,
              // variety_id: item.varietyId,
              // division: item.division,
              bd_id: item.data.bd_id,
            };
          }
        })
        .filter((item) => item !== undefined);

      API.put("/approvals", dataToSend)
        .then((response) => {
          if (response.data.status) {
            this.setState({
              drawerVisible: false,
              selectAll: false,
              comments: "",
              modalVisible: false,
              noOfChanges: 0,
            });
            this.handleBranchSelect(this.state.selectedBranchName, {
              key: this.state.selectedBranchID,
            });
          } else {
            message.warning("Could not submit. Please try again.");
            this.setState({ loading: false });
          }
        })
        .catch((err) => {
          this.setState({ loading: false });
          message.error(
            "Could not submit. Please check your network connectivity."
          );
          // Modal.error({ content: err.response.data.message });
        });
    };

    return (
      <div
        className="approvals-container"
        style={{ height: window.innerHeight - 131 }}
      >
        {/* Modal to comeup when rejecting */}
        <Modal
          footer={false}
          onCancel={() => {
            this.setState({ modalVisible: false, comments: "" });
          }}
          loading={this.state.loading}
          title="Submit Rejection ?"
          visible={this.state.modalVisible}
          okText="Submit"
          maskClosable={false}
          width="300px"
          className="reject-comments-modal"
        >
          <div style={{ height: "130px" }}>
            <div>
              Comments:
              <Input.TextArea
                onChange={(e) => this.setState({ comments: e.target.value })}
                autoSize={{ minRows: 4, maxRows: 4 }}
                value={this.state.comments}
              />
            </div>
            <div className="modal-footer-container">
              <Button
                className="cancel-button-reject"
                onClick={() => {
                  this.setState({ modalVisible: false, comments: "" });
                }}
                disabled={this.state.loading}
              >
                Cancel
              </Button>
              <Button
                className="submit-button-reject"
                onClick={() =>
                  this.state.comments !== ""
                    ? postApprovalData("reject")
                    : message.warning("Cannot submit without comments")
                }
                disabled={this.state.loading}
              >
                Submit
              </Button>
            </div>
          </div>
        </Modal>
        {/* Drawer to comeup to submit */}
        <Drawer
          height="max-content"
          placement="bottom"
          closable={false}
          mask={false}
          visible={this.state.noOfChanges > 0}
        >
          <div className="drawer-container">
            <div className="drawer-text">
              {this.state.noOfChanges} Buying{" "}
              {this.state.noOfChanges <= 1 ? "plan" : "plans"} selected
            </div>
            <Button
              className="reject-button"
              onClick={() => {
                this.setState({ modalVisible: true });
              }}
              disabled={this.state.loading}
            >
              ⨉ Reject
            </Button>
            <Button
              className="approve-button"
              disabled={this.state.loading}
              onClick={() => postApprovalData("approve")}
            >
              ✓ Approve
            </Button>
          </div>
        </Drawer>

        <div className="approvals-top-container">
          <div></div>
          <div className="approval-branch-select">
            <h1>Branch&nbsp;</h1>{" "}
            <CustomDropdownChooseRegion
              key="1"
              selectType="branch"
              handleSelect={this.handleBranchSelect}
              placeholder={this.state.selectedBranchName}
              list={this.state.userBranches}
            />
          </div>

          <div style={{ marginTop: "13px" }}>
            <Checkbox
              checked={this.state.showArchived}
              disabled={this.state.loading}
              onChange={(e) =>
                this.setState({ showArchived: e.target.checked })
              }
            >
              Show archived
            </Checkbox>
          </div>
        </div>
        <div className="approvals-table-container">
          <Table
            {...tableProps}
            loading={this.state.loading}
            key="approval-table"
            columns={[
              {
                title: <TableColumnHeader title="Hub" />,
                key: "hub",
                dataIndex: "hub",
                width: 20,
                filters: createFilters("hub"),
                onFilter: (value, record) => record.hub.includes(value),
              },
              // {
              //   title: <TableColumnHeader title="Source" />,
              //   key: "source",
              //   dataIndex: "source",
              //   width: 20,
              //   filters: createFilters("source"),
              //   onFilter: (value, record) => record.source.includes(value),
              // },
              {
                title: <TableColumnHeader title="Variety" />,
                key: "variety",
                dataIndex: "variety",
                width: 20,
                filters: createFilters("variety"),
                onFilter: (value, record) => record.variety.includes(value),
              },
              {
                title: <TableColumnHeader title="Arrivals" subtitle="in MT" />,
                key: "arrivals",
                dataIndex: "arrivals",
                width: 20,
              },
              {
                title: (
                  <TableColumnHeader title="Modal Price" subtitle="in ₹/MT" />
                ),
                key: "modalPrice",
                dataIndex: "modalPrice",
                width: 20,
              },
              {
                title: (
                  <TableColumnHeader title="Planned Qty" subtitle="in MT" />
                ),
                key: "plannedQty",
                dataIndex: "plannedQty",
                width: 20,
                render: (item, r) => {
                  return (
                    <TableColumnData
                      percent={r.plannedQtyPercent}
                      item={item}
                      diff={r.plannedQtyDiff}
                      isQty
                    />
                  );
                },
              },
              {
                title: <TableColumnHeader title="Min Price" subtitle="in ₹/MT" />,
                key: "lcMin",
                dataIndex: "lcMin",
                width: 20,
                render: (item, r) => {
                  return (
                    <TableColumnData
                      percent={r.lcMinPercent}
                      item={item}
                      diff={r.lcMinDiff}
                    />
                  );
                },
              },
              {
                title: (
                  <TableColumnHeader title="PO Planned" subtitle="in ₹/MT" />
                ),
                key: "lcPlanned",
                dataIndex: "lcPlanned",
                width: 20,
              },
              {
                title: <TableColumnHeader title="Max Price" subtitle="in ₹/MT" />,
                key: "lcMax",
                dataIndex: "lcMax",
                width: 20,
                render: (item, r) => {
                  return (
                    <TableColumnData
                      percent={r.lcMaxPercent}
                      item={item}
                      diff={r.lcMaxDiff}
                    />
                  );
                },
              },

              {
                title: <TableColumnHeader title="Remarks" />,
                key: "remarks",
                dataIndex: "remarks",
                width: 40,
              },
              {
                title: <TableColumnHeader title="Claim Type" />,
                key: "claimType",
                dataIndex: "claimType",
                width: 20,
              },
              // {
              //   title: <TableColumnHeader title="Division" />,
              //   key: "division",
              //   dataIndex: "division",
              //   width: 20,
              // },
              {
                title: <TableColumnHeader title="Status" />,
                key: "status",
                dataIndex: "status",
                width: 20,
                render: (item, rowRecord) => (
                  <TableStatusComponent rowRecord={rowRecord} user={user} />
                ),
              },
              {
                title: (
                  <div
                    style={{
                      fontSize: "12px",
                      color: "white",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "100%",
                      paddingRight: "13px",
                    }}
                  >
                    <TableColumnHeader title="Action" />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        paddingRight: "6px",
                      }}
                    >
                      <Checkbox
                        width="10px"
                        height="10px"
                        checked={
                          this.state.selectAll && this.state.noOfChanges > 0
                        }
                        onChange={(e) => {
                          let count = 0;
                          let data = this.state.tableData;
                          data.map((item) => {
                            if (
                              (item.approval1 === null ||
                                item.approval2 === null) &&
                              !(
                                item.approval1 === false ||
                                item.approval2 === false
                              ) &&
                              user.userName !== item.approver1Name &&
                              user.userName !== item.approver2Name
                            ) {
                              item.action = e.target.checked;
                              count++;
                            }
                          });
                          this.setState({
                            tableData: data,
                            drawerVisible: e.target.checked,
                            noOfChanges: e.target.checked ? count : 0,
                            selectAll: e.target.checked,
                          });
                        }}
                      ></Checkbox>
                    </div>
                  </div>
                ),
                key: "action",
                dataIndex: "action",
                width: 20,
                render: (item, row) => {
                  return (
                    <div className="approval-table-checkbox">
                      <Checkbox
                        disabled={
                          (row.approval1 !== null && row.approval2 !== null) ||
                          row.approval1 === false ||
                          row.approval2 === false ||
                          user.userName === row.approver1Name ||
                          user.userName === row.approver2Name
                        }
                        checked={row.action}
                        onChange={(e) => {
                          let data = this.state.tableData;
                          let count = 0;
                          data.map((item) => {
                            if (item.key === row.key) {
                              item.action = e.target.checked;
                            }
                            item.action && count++;
                          });
                          this.setState({
                            tableData: data,
                            drawerVisible: count > 0,
                            noOfChanges: count,
                          });
                        }}
                      />
                    </div>
                  );
                },
              },
            ]}
            dataSource={setDataForTable()}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  branches: selectAllBranches,
  user: selectUserData,
});

export default connect(mapStateToProps, null)(Approvals);
