import { createSelector } from "reselect";

const selectRecommendation = state => state.recommendation;
// const selectDirectFacRecommendation = state => state.directFactRecommendation;
// const selectRakeRecommendation = state => state.rakeRecommendation;

export const selectSuggestedData = createSelector(
  [selectRecommendation],
  recommendation => recommendation.suggestedData
);
export const selectSuggestedHubData = createSelector(
  [selectRecommendation],
  recommendation => recommendation.suggestedHubData
);

export const selectSuggestedRakeData = createSelector(
  [selectRecommendation],
  rakeRecommendation => rakeRecommendation.suggestedRakeData
);

export const selectSuggestedDirectFactData = createSelector(
  [selectRecommendation],
  directFactRecommendation => directFactRecommendation.suggestedDirectFactData
);

export const selectSuggestedABDData = createSelector(
  [selectRecommendation],
  abdRecommendation => abdRecommendation.suggestedABDData
);

export const selectSohAbdData = createSelector(
  [selectRecommendation],
  sohAbdReccomendation => sohAbdReccomendation.sohAbdData
);