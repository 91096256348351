import React, { Component } from "react";
import "./master-screens.styles.css";

import CustomSelectSummary from "../../components/custom-select-summary/custom-select-summary.component";
import MasterScreenRender from "../../pages/master-screen-render/master-screen-render.page";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { setMasterScreenRBACMessage } from "../../redux/user/user.actions";
import API from "../../api";

import { selectUserData } from "../../redux/user/user.selectors";
/**
 * @component
 * @description The admin tab parent component.
 * Contains user management, alerts, user groups and news as sub-tabs
 */
class MasterTabs extends Component {
  state = {
    tabKey: 0,
    selectedFilterName: "",
    searchText: null,
    isSpinnerOnLoad: false,
  };
  componentDidMount() {
    this.setState({ isSpinnerOnLoad: true });
    this.getMSMessageLabel();
  }

  getMSMessageLabel = async () => {
    await API.get("/ms_rbac_label")
      .then((varietyResponse) => {
        console.log("MS message access label ,", varietyResponse.data.data);
        this.props.setMasterScreenRBACMessage(varietyResponse.data.data);
        this.setState({ isSpinnerOnLoad: false });
      })
      .catch((err) => {
        this.setState({ isSpinnerOnLoad: false });
      });
  };

  render() {
    const handleTypeSelect = (value) => {
      this.setState({ selectedFilterName: value });
    };

    const list = [
      { feature_code: "MACE", message: "message display" },
      { feature_code: "MAFBE", message: "message display" },
    ];

    return (
      <div className="Master-tabs">
        <div
          className="filter-container"
          style={{
            display: "flex",
            marginBottom: "13px",
          }}
        >
          <div className="summary-filters1" style={{}}>
            <div
              className="filter-sele"
              style={{ display: "flex", flexDirection: "row" }}
            >
              <div className="filter-select-title"></div>
              <span
                style={{
                  width: "180px",
                  fontSize: "15px",
                  marginTop: "5px",
                  fontWeight: "600",
                }}
              >
                Select Master&nbsp;
              </span>
              <CustomSelectSummary
                placeholder={
                  this.state.selectedFilterName === undefined ||
                  this.state.selectedFilterName === ""
                    ? "Acreage"
                    : this.state.selectedFilterName
                }
                connectState
                handleSelect={handleTypeSelect}
                // list={["Acreage","Annual factory Consumption Plan", "Cost Build Up Mandi",
                //  "Factory", "Hub", "Hub Factory Freight Mapping",
                // "Local Warehouse Charges", "Mandi", "Mandi hub freight","Mandi State District Hub",
                // "Warehouse Cost"]}
                list={[
                  "Acreage",
                  "All Farmer Buying",
                  "Annual factory Consumption Plan",
                  "Branch",
                  "Competitor",
                  "Cost Build Up Mandi",
                  "Cost Build Up Farmer",
                  "Cost Buildup Sahayak Bihar",
                  "Cost Buildup Sahayak Gujarat",
                  "Container DTD Freight",
                  "Destination Branch Map",
                  "Factory",
                  "Farmer Buying Percentile",
                  "Favorite Mandi",
                  "Hub",
                  "Hub Factory Freight Mapping",
                  "Holiday",
                  "Inflation Factor",
                  "Loading Unloading Costs",
                  "Local Warehouse Charges",
                  "Mandi",
                  "Mandi Hub Freight",
                  "Mandi State District Hub",
                  "PO Price Correction",
                  "Region",
                  "Role",
                  "Role Feature Mapping",
                  "Variety",
                  "Variety Region Mapping",
                  "Warehouse Cost",
                ]}
              />
            </div>
          </div>
        </div>

        <div>
          <MasterScreenRender
            selectedFilterName={
              this.state.selectedFilterName === undefined
                ? "Acreage"
                : this.state.selectedFilterName
            }
            messageAccess={list}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setMasterScreenRBACMessage: (data) =>
      dispatch(setMasterScreenRBACMessage(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MasterTabs);
