import React, { Component } from "react";
import "./buying-recommendation-tabs.styles";
// import { Doughnut, Bar, Line, Pie } from "react-chartjs-2";
import { Tabs, Icon } from "antd";

import MonthlyPrice from "../../components/monthly-price/monthly-price-component";
import MonthlyVolume from "../../components/monthly-volume/monthly-volume.component";
import WeeklyVolume from "../../pages/weekly-volume/weekly-volume.page";
import ProjectionReportsContainer from "../../pages/projection-reports-container/projection-reports-container.page";

// icons
import { ReactComponent as IconBuyingRecommendation } from "../../assets/buyingRecommendation.svg";
import { ReactComponent as IconMonthlyPrice } from "../../assets/monthlyPrice.svg";
import { ReactComponent as IconMonthlyVolume } from "../../assets/monthlyVolume.svg";
import { ReactComponent as IconMonthlySummary } from "../../assets/monthySummary.svg";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectUserData } from "../../redux/user/user.selectors";

import { canUserEdit } from "../../auth/utils";
import { FeatureCodes } from "../../auth/data";
import QCInputPage from "../../pages/qc-input/qc-input.component";
import QCRulesMonthlyTabs from "../../pages/qc-rules-monthly-tabs/qc-rules-monthly-tabs.component";
import TabularForecastPage from "../../pages/tabular-forecasts-page/tabular-forecasts-page.page";

const { TabPane } = Tabs;

/**
 * @component
 * @description The parent component tab displaying monthly and weekly predictions.
 * Contains Monthly price, Monthly volume and Weekly allocation as sub-tabs.
 */
class ForecastTabs extends Component {
  state = { tabKey: 0 };
  render() {
    return (
      <div className="report-tabs">
        <Tabs
          animated={false}
          className={`main-content 
        `}
          defaultActiveKey="1"
        >
          {canUserEdit(
            this.props.userData,
            null,
            FeatureCodes.MONTHLY_MODEL_FORECAST_VIEW
          ) ||
            canUserEdit(
              this.props.userData,
              null,
              FeatureCodes.MONTHLY_MODEL_FORECAST_EDIT
            ) ? (
            <TabPane
              tab={
                <div className="tab-title-container">
                  <div>
                    <IconMonthlyPrice className="tab-title-image" />
                  </div>
                  Monthly Price
                </div>
              }
              key="1"
            >
              <MonthlyPrice />
            </TabPane>
          ) : null}

          {canUserEdit(
            this.props.userData,
            null,
            FeatureCodes.MONTHLY_MODEL_FORECAST_VIEW
          ) ||
            canUserEdit(
              this.props.userData,
              null,
              FeatureCodes.MONTHLY_MODEL_FORECAST_EDIT
            ) ? (
            <TabPane
              tab={
                <div className="tab-title-container">
                  <div>
                    <IconMonthlyVolume className="tab-title-image" />
                  </div>
                  Monthly Volume
                </div>
              }
              key="2"
            >
              <MonthlyVolume />
            </TabPane>
          ) : null}
          {canUserEdit(
            this.props.userData,
            null,
            FeatureCodes.WEEKLY_MODEL_VIEW
          ) ||
            canUserEdit(
              this.props.userData,
              null,
              FeatureCodes.WEEKLY_MODEL_EDIT
            ) ? (
            <TabPane
              tab={
                <div className="tab-title-container">
                  <div>
                    <IconMonthlyVolume className="tab-title-image" />
                  </div>
                  Weekly
                </div>
              }
              key="3"
            >
              <WeeklyVolume />
            </TabPane>
          ) : null}

          {canUserEdit(
            this.props.userData,
            null,
            FeatureCodes.MONTHLY_CONSTRAINTS
          ) ? (
            <TabPane
              tab={
                <div className="tab-title-container">
                  <div>
                    <IconMonthlyPrice className="tab-title-image" />
                  </div>
                  Monthly Volume Constraints
                </div>
              }
              key="4"
            >
              <QCInputPage />
            </TabPane>
          ) : null}

          {canUserEdit(
            this.props.userData,
            null,
            FeatureCodes.MONTHLY_MODEL_FORECAST_VIEW
          ) ||
            canUserEdit(
              this.props.userData,
              null,
              FeatureCodes.MONTHLY_MODEL_FORECAST_EDIT
            ) ? (
            <TabPane
              tab={
                <div className="tab-title-container">
                  <div>
                    <IconMonthlyPrice className="tab-title-image" />
                  </div>
                  Projection Reports
                </div>
              }
              key="5"
            >
              <ProjectionReportsContainer />
            </TabPane>
          ) : null}
          {canUserEdit(
            this.props.userData,
            null,
            FeatureCodes.MONTHLY_MODEL_FORECAST_VIEW
          ) ||
            canUserEdit(
              this.props.userData,
              null,
              FeatureCodes.MONTHLY_MODEL_FORECAST_EDIT
            ) ? (
            <TabPane
              tab={
                <div className="tab-title-container">
                  <div>
                    <IconMonthlyPrice className="tab-title-image" />
                  </div>
                  Tabular Forecasts
                </div>
              }
              key="6"
            >
              <TabularForecastPage />
            </TabPane>
          ) : null}
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
});

export default connect(mapStateToProps, null)(ForecastTabs);
