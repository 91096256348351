import  React from 'react'
import { Component } from "react";
import "./state-district-market-variety.page"
import API from "../../api";
import { getStatesAPI } from "../../services/generic-apis";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUserData } from "../../redux/user/user.selectors";
import { canUserEdit } from "../../auth/utils";
import { FeatureCodes } from "../../auth/data";
import {
  Table,
  Spin,
  Input,
  Select,
  Button,
  message,
  Form,
  Modal,
  Radio,
} from "antd";
const { Search } = Input;
const { Option } = Select;
class StateDistrictMarketVarietyMaster extends Component {
    state = {
      isModalVisible: false,
      isAdd: false,
     
      stateList: [],
      districtList: [],
      stateDistrictMarketVarietyTableData: [],
      currentRecord: null,
      userBranches: null,
      searchText: null,
      searchUserType: 0,
      
      isActive: false,
      isSpinnerOnLoad: false,
      isSubmitted: false,
  
      newData: {
        variety_id: undefined,
        mandi_name:"",
        mandi_id:undefined,
        variety_name:"",
         is_active: true,
         state_id: "",
        state_name: "",
        district_id: undefined,
        
        grouped_variety_name:"",
        grouped_variety_id:undefined,
        district_name: "",
        isEdit: false,
      },
      rowData: undefined,
      selectedStateValue: undefined,
      selectedStateName: "",
      selectedStateList: [],
      selectedBranchValue: undefined,
    };
    componentDidMount() {
        this.setState({ isSpinnerOnLoad: true });
        getStatesAPI();
      }
      datum = [
        {state_name: 'ash', district_name:'muz', mandi_name:'sep',variety_name:'juar',grouped_variety:'liv'},
        {state_name: 'ah', district_name:'mua', mandi_name:'suy',variety_name:'juar',grouped_variety:'liv'},
        {state_name: 'sabh', district_name:'guz', mandi_name:'iep',variety_name:'juar',grouped_variety:'liv'},
        {state_name: 'mashh', district_name:'yuz', mandi_name:'jep',variety_name:'juar',grouped_variety:'liv'},
        {state_name: 'ath', district_name:'moz', mandi_name:'seui',variety_name:'juar',grouped_variety:'liv'}, 
      ];
      getStatesAPI = async () => {
        this.setState({ isSpinnerOnLoad: true });
        await API.get("/states")
      .then((state) => {
      
        let modifiedData = [...state.data.data];
        modifiedData.push({ state_id: null, state_name: "All" });
        this.setState(
          {
            selectedStateList: modifiedData,
         //   mandiBranchList: state.data.data,
            selectedStateValue: state.data.data[0].state_id,
            selectedStateName: state.data.data[0].state_name,
          },
      
          () => this.getMandiList()
        );
      })
      .catch((err) => {
        this.setState({ isSpinnerOnLoad: false });
        this.getStatesAPI();
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
      });
      };
      setModalVisibility = (status) => {
        if (status) {
          const nData = {
            mandi_id: null,
            mandi_name:undefined,
            state_id: undefined,
            state_name: "",
            
           district_name:"",
           district_id:null,
           variety_id:null,
           variety_name:"",
           grouped_variety_name:"",
           grouped_variety_id:null,
            isEdit:false,
           
            is_active: true,
          };
      
          this.setState({ newData: nData });
        }
        this.setState({ isModalVisible: status });
    };
    TableColumnHeader = (props) => {
        const { title, subtitle } = props;
        return (
          <div className="column-header-container">
            <div
              style={{
                color: "white",
                fontSize: "12px",
                whiteSpace: "nowrap",
                fontWeight: "normal",
                textAlign: "left",
              }}
            >
              {title}
            </div>
            <div
              style={{
                color: "#cbdfff",
                fontSize: "11px",
                fontWeight: "normal",
                textAlign: "left",
                
              }}
            >
              {subtitle}
            </div>
          </div>
        );
      };
      
      
      
      
      createFilters = (label) => {
        let filterData = this.stateDistrictMarketVarietyTableData;
        //#region remove duplicate objects
        let uniqueFilterData = [];
      
        filterData.map((mainItem) =>
        uniqueFilterData.filter((item) => item[label] === mainItem[label])
          .length > 0
          ? null
          : uniqueFilterData.push(mainItem)
      );
      
      uniqueFilterData = uniqueFilterData.map((item) => {
        return {
          text: item[label],
          value: item[label],
        };
      });
      
      
      uniqueFilterData.sort((a, b) => a.text.localeCompare(b.text));
      //#endregion
        return uniqueFilterData;
      };
      handleDropdownChange = (value, row, target, index, optionlabel, isPopup) => {
        let a;
      
        if (isPopup === false) {
          a = this.state.stateDistrictMarketVarietyTableData;
          if (target === "mandi_name") {
            a[row.key].mandi_name = optionlabel;
            a[row.key].mandi_id = value;
          }
          else if (target === "state_name") {
            a[row.key].state_id= value;
            a[row.key].state_name = optionlabel;
          }
          else if (target === "district_name") {
            a[row.key].district_id= value;
            a[row.key].district_name = optionlabel;
          }
          else if (target === "variety_name") {
            a[row.key].variety_id= value;
            a[row.key].variety_name = optionlabel;
          }
          else if (target === "grouped_variety") {
            a[row.key].grouped_variety_id= value;
            a[row.key].grouped_variety_name = optionlabel;
          }
          this.setState({ stateDistrictMasterTableData: a });
        } else {
          a = this.state.newData;
      
          if (target === "mandi_name") {
            a.mandi_name = optionlabel;
            a.mandi_id = value;
          }
          else if (target === "state_name") {
            a.state_id= value;
            a.state_name = optionlabel;
          }
          else if (target === "district_name") {
            a.district_id= value;
            a.district_name = optionlabel;
          }
          else if (target === "variety_name") {
            a.variety_id= value;
            a.variety_name = optionlabel;
          }
          else if (target === "grouped_variety") {
            a.grouped_variety_id= value;
            a.grouped_variety_name = optionlabel;
          }
          this.setState({ newData: a });
        }
      };
      handleChange = (e, row, index, name, isPopup) => {
        e.target.name = name;
    
        var alphaNumericRegex = /^[a-zA-Z0-9]*$/;
        var numberRegex = /^[0-9.]+$/;
        let a;
    
        if (isPopup === false) {
          a = this.state.stateDistrictMarketVarietyTableData;
    
          this.setState({ stateDistrictMarketVarietyTableData: a });
        } else {
          a = this.state.newData;
          this.setState({ newData: a });
        }
      };
      handleCancel = () => {
        this.setState({ isModalVisible: false });
      };
      
      handleEditChange = (row, index) => {
        console.log("user hub id is ", this.props.userData.state_id );//check this afterwards
        
        this.setState({ rowData: row });
        let tempData = this.state.stateDistrictMarketVarietyTableData.map((item) => {
      
          if (item.key === row.key) {
            return {
              ...item,
              isEdit: true
            }
          } else {
            return item;
          }
        })
        this.setState({ stateDistrictMarketVarietyTableData: tempData });
      
      };
      handleCancelClick = (rowData, index) => {
        let tempData = this.state.StateDistrictMarketVarietyMaster.map((item) => {
          if (item.key === rowData.key) {
            return this.state.rowData
          } else {
            return item;
          }
        })
        this.setState({ stateDistrictMarketVarietyTableData: tempData });
      };
      render(){
        const userTableProps = {
          pagination: false,
          size: "default",
          bordered: true,
          scroll: { x:200, y: window.innerHeight - 250 },
        };
        const handleStateSelect = (value, props) => {
          this.setState(
            { selectedStateName: props.key, selectedStateValue: value },
            () => this.getStatesAPI()
          );
        };
        return(
            <div // close left
            className="mandi-master-container"
            style={{
              height: window.innerHeight - 85,
              marginTop: "-45px",
            }}>
               <div className="top-container">
               <div className="hub-region-filter">
               <span
                        style={{
                          fontSize: "15px",
                          marginTop: "5px",
                          marginLeft: "68px",
                          fontWeight: "600",
                        }}
                      >
                        State&nbsp;
                      </span>{" "}
                      <Select
                        disabled={this.state.isAdd}
                        showSearch
                        optionFilterProp="children"
                        className={"select-master-region"}
                        mode="single"
                        placeholder="Select State"
                        value={this.state.selectedhubStateValue}
                        onChange={(value, props) =>
                          handleStateSelect(value, props)
                        }
                      >
                        {this.state.selectedStateList !== null && this.state.selectedStateList.length &&
                          this.state.selectedStateList.map((item, idx) => (
                  
                              <Option
                              value={item.state_id}
                              key={item.state_id}
                            >
                              {item.state_name}
                            </Option>
                          ))}
                      </Select>
          
                    </div>
          
          
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row-reverse",
              placeItems: "flex-end",
              marginRight: "25px",
              marginBottom: "10px",
            }}
          >
            <Search
              disabled={this.state.isAdd}
              placeholder="Search"
              allowClear
              onChange={(e) => this.setState({ searchText: e.target.value })}
              style={{ width: "25%" }}
            />
            <Button
              className="add-master-button"
              style={{
                height: "25px",
                width: "70px",
                backgroundColor: "#0b133d",
                color: "white",
              }}
              disabled={this.state.isAdd || !canUserEdit(
                this.props.userData,
                null,
                FeatureCodes.MASTER_SCREEN_OPS_EDIT
              )}
              onClick={() => this.setModalVisibility(true)}
            >
              &#10010; Add
            </Button>
          </div>
          </div>
          <div>
<div
style={{
  marginLeft: "25px",
  marginTop: "-8px",
  fontSize: "14px",
  color: "red",
  display: "flex",
  float: "left",
}}
>Only DHQ ops team has access to add/edit the Cost Build Up Master
</div>
<div
  style={{
    marginRight: "20px",
    marginTop: "-8px",
    fontSize: "15px",
    color: "#0B133D",
    display: "flex",
    float: "right",
  }}
>Total number of records:&nbsp;
  <b>{this.state.stateDistrictMarketVarietyTableData && this.datum.length}</b>
</div>
</div>

<Spin
  spinning={!this.state.isSpinnerOnLoad}
  size={"large"}
  tip="Loading..."
  style={{ alignSelf: "center", zIndex: 9999, marginTop: "20px" }}
>
  <Modal
    className="add-master-modal"
    title={"Add state market master"}
    onCancel={this.handleCancel}
    footer={false}
    visible={this.state.isModalVisible}
    destroyOnClose={true}
  >
    <div className="master-form-container">
      <Form
        layout="horizontal"
        //   onValuesChange={onFormLayoutChange}
        size={"small"}
      // onSubmit={submitTableRowData(this.state.newData)}
      >
        <div className="form-top-container1">
          <div
            className="checkbox-group1"
            style={{
              display: "inline-block",
              width: "60%",
              marginRight: 40,
            }}
          >
             <Form.Item label="State">
                      <Select
                        showSearch
                        optionFilterProp="children"
                        className="remarks-select"
                        name="state_name"
                        defaultValue={this.state.newData.state_name}
                        value={this.state.newData.state_name}
                        onSelect={(value, option) =>
                          this.handleDropdownChange(
                            value,
                            this.state.newData,
                            "state_name",
                            -0,
                            option.props.children,
                            true
                          )
                        }
                      >
                        {this.state.stateList &&
                          this.state.stateList.length &&
                          this.state.stateList.map((hubItem, idx) => (
                            <Option
                              key={hubItem.state_id}
                              value={hubItem.state_id}
                            >
                              {hubItem.state_name}
                            </Option>
                          ))}
                      </Select>

                    </Form.Item>
                  </div>


                  <div
                    className="checkbox-group1"
                    style={{
                      height: "45px",
                      display: "inline-block",
                      width: "60%",
                      marginRight: 40,
                    }}
                  >
                    <Form.Item label="District">
                    <Select
                        showSearch
                        optionFilterProp="children"
                        className="remarks-select"
                        name="district_name"
                        defaultValue={this.state.newData.district_name}
                        value={this.state.newData.district_name}
                        onSelect={(value, option) =>
                          this.handleDropdownChange(
                            value,
                            this.state.newData,
                            "district_name",
                            -0,
                            option.props.children,
                            true
                          )
                        }
                      >
                        {this.state.districtList &&
                          this.state.districtList.length &&
                          this.state.districtList.map((hubItem, idx) => (
                            <Option
                              key={hubItem.district_id}
                              value={hubItem.district_id}
                            >
                              {hubItem.district_name}
                            </Option>
                          ))}
                      </Select>

                      
                    </Form.Item>
                  </div>

                  <div
                    className="checkbox-group1"
                    style={{
                      height: "45px",
                      display: "inline-block",
                      width: "60%",
                      marginRight: 40,
                    }}
                  >
                    <Form.Item label="Mandi">
                    <Select
                        showSearch
                        optionFilterProp="children"
                        className="remarks-select"
                        name="mandi_name"
                        defaultValue={this.state.newData.mandi_name}
                        value={this.state.newData.mandi_name}
                        onSelect={(value, option) =>
                          this.handleDropdownChange(
                            value,
                            this.state.newData,
                            "state_name",
                            -0,
                            option.props.children,
                            true
                          )
                        }
                      >
                        {this.state.mandiList &&
                          this.state.mandiList.length &&
                          this.state.mandiList.map((hubItem, idx) => (
                            <Option
                              key={hubItem.mandi_id}
                              value={hubItem.mandi_id}
                            >
                              {hubItem.state_name}
                            </Option>
                          ))}
                      </Select>

                    </Form.Item>
                  </div>

                  <div
                    className="checkbox-group1"
                    style={{
                      height: "45px",
                      display: "inline-block",
                      width: "60%",
                      marginRight: 40,
                    }}
                  >
                    <Form.Item label="Variety">
                    <Select
                        showSearch
                        optionFilterProp="children"
                        className="remarks-select"
                        name="variety_name"
                        defaultValue={this.state.newData.variety_name}
                        value={this.state.newData.variety_name}
                        onSelect={(value, option) =>
                          this.handleDropdownChange(
                            value,
                            this.state.newData,
                            "variety_name",
                            -0,
                            option.props.children,
                            true
                          )
                        }
                      >
                        {this.state.varietyList &&
                          this.state.varietyList.length &&
                          this.state.varietyList.map((hubItem, idx) => (
                            <Option
                              key={hubItem.variety_id}
                              value={hubItem.variety_id}
                            >
                              {hubItem.variety_name}
                            </Option>
                          ))}
                      </Select>

                    </Form.Item>
                  </div>

                  <div
                    className="checkbox-group1"
                    style={{
                      height: "45px",
                      display: "inline-block",
                      width: "60%",
                      marginRight: 40,
                    }}
                  >
                    <Form.Item label="grouped_variety_name">
                    <Select
                        showSearch
                        optionFilterProp="children"
                        className="remarks-select"
                        name="grouped_variety_name"
                        defaultValue={this.state.newData.grouped_variety_name}
                        value={this.state.newData.grouped_variety_name}
                        onSelect={(value, option) =>
                          this.handleDropdownChange(
                            value,
                            this.state.newData,
                            "grouped_variety_name",
                            -0,
                            option.props.children,
                            true
                          )
                        }
                      >
                        {this.state.groupedvarietyList &&
                          this.state.groupedvarietyList.length &&
                          this.state.groupedvarietyList.map((hubItem, idx) => (
                            <Option
                              key={hubItem.grouped_variety_id}
                              value={hubItem.grouped_variety_id}
                            >
                              {hubItem.grouped_variety_name}
                            </Option>
                          ))}
                      </Select>

                    </Form.Item>
                  </div>

                  
                  </div>
                  <div className="form-bottom-container" style={{ marginTop: "10px" }}>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={this.state.isSubmitted}
                      onClick={() =>
                        this.submitTableRowData(this.state.newData, 0, true)
                      }
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </Modal>
          <div className="table-container1" style={{ maxHeight: "400px",  margin: "6px 0px" }}>
  <Table
   {...userTableProps}
   columns ={ [
  {
    title : 'state_name',
    dataIndex: 'state_name',
    key: 'state_id',
    width: 80,
   // filters: this.createFilters("Hub_State"),
    onFilter: (value, record) =>
      record.state_name.includes(value),
    sortDirections: ["descend", "ascend"],
    sorter: (a, b) => a.state_name.localeCompare(b.state_name),
    render: (record, rowRecord) => {
      return (
        <>
          <div
            style={{ marginLeft: "-15px", marginRight: "-10px" }}
          >
            <Select
              showSearch
              optionFilterProp="children"
              disabled={true}//{!rowRecord.isEdit}
              className="remarks-select"
              name="state_name"
              defaultValue={rowRecord.state_name}
              value={rowRecord.state_name}
              onSelect={(value, option) =>
                this.handleDropdownChange(
                  value,
                  rowRecord,
                  "state_name",
                  -0,
                  option.props.children,
                  false
                )
              }
            >
              {this.state.stateList &&
                this.state.stateList.length &&
                this.state.stateList.map((hubItem, idx) => (
                  <Option
                    key={hubItem.state_id}
                    value={hubItem.state_id}
                  >
                    {hubItem.state_name}
                  </Option>
                ))}
            </Select>
          </div>
        </>
      );
    },
  },
  {
    title: 'district_name',
    dataIndex: 'district_name',
    key: 'district_id',
    width: 150,
    sortDirections: ["descend", "ascend"],
    sorter: (a, b) => a.district_name - b.district_name,
    render: (record, rowRecord, index) => {
      return (
        <>
          <div style={{ marginLeft: "-20px" }}>
          <Select
              showSearch
              optionFilterProp="children"
              disabled={true}//{!rowRecord.isEdit}
              className="remarks-select"
              name="district_name"
              defaultValue={rowRecord.district_name}
              value={rowRecord.district_name}
              onSelect={(value, option) =>
                this.handleDropdownChange(
                  value,
                  rowRecord,
                  "district_name",
                  -0,
                  option.props.children,
                  false
                )
              }
            >
              {this.state.districtList &&
                this.state.districtList.length &&
                this.state.districtList.map((hubItem, idx) => (
                  <Option
                    key={hubItem.district_id}
                    value={hubItem.district_id}
                  >
                    {hubItem.district_name}
                  </Option>
                ))}
            </Select>
            
          </div>
        </>
      );
    },
  },
  {
    title: 'mandi_name',
    dataIndex: 'mandi_name',
    key: 'mandi_name',
    width: 150,
    sortDirections: ["descend", "ascend"],
    sorter: (a, b) => a.mandi_name - b.mandi_name,
    render: (record, rowRecord, index) => {
      return (
        <>
          <div style={{ marginLeft: "-20px" }}>
          <Select
              showSearch
              optionFilterProp="children"
              disabled={true}//{!rowRecord.isEdit}
              className="remarks-select"
              name="mandi_name"
              defaultValue={rowRecord.mandi_name}
              value={rowRecord.mandi_name}
              onSelect={(value, option) =>
                this.handleDropdownChange(
                  value,
                  rowRecord,
                  "mandi_name",
                  -0,
                  option.props.children,
                  false
                )
              }
            >
              {this.state.mandiList &&
                this.state.mandiList.length &&
                this.state.mandiList.map((hubItem, idx) => (
                  <Option
                    key={hubItem.mandi_id}
                    value={hubItem.mandi_id}
                  >
                    {hubItem.mandi_name}
                  </Option>
                ))}
            </Select>
            
          </div>
        </>
      );
    },
  },
  {
    title: 'variety_name',
    dataIndex: 'variety_name',
    key: 'variety_id',
    width: 80,
    sortDirections: ["descend", "ascend"],
    sorter: (a, b) => a.variety_name - b.variety_name,
    render: (record, rowRecord, index) => {
      return (
        <>
          <div style={{ marginLeft: "-20px" }}>
          <Select
              showSearch
              optionFilterProp="children"
              disabled={true}//{!rowRecord.isEdit}
              className="remarks-select"
              name="variety_name"
              defaultValue={rowRecord.variety_name}
              value={rowRecord.variety_name}
              onSelect={(value, option) =>
                this.handleDropdownChange(
                  value,
                  rowRecord,
                  "variety_name",
                  -0,
                  option.props.children,
                  false
                )
              }
            >
              {this.state.varietyList &&
                this.state.varietyList.length &&
                this.state.varietyList.map((hubItem, idx) => (
                  <Option
                    key={hubItem.variety_id}
                    value={hubItem.variety_id}
                  >
                    {hubItem.variety_name}
                  </Option>
                ))}
            </Select>
           
          </div>
        </>
      );
    },
  },
  {
    title: 'grouped_variety_name',
    dataIndex: 'grouped_variety_name',
    key: 'grouped_variety_id',
    width: 80,
    sortDirections: ["descend", "ascend"],
    sorter: (a, b) => a.grouped_variety_name- b.grouped_variety_name,
    render: (record, rowRecord, index) => {
      return (
        <>
          <div style={{ marginLeft: "-20px" }}>
          <Select
              showSearch
              optionFilterProp="children"
              disabled={true}//{!rowRecord.isEdit}
              className="remarks-select"
              name="grouped_variety_name"
              defaultValue={rowRecord.grouped_variety_name}
              value={rowRecord.grouped_variety_name}
              onSelect={(value, option) =>
                this.handleDropdownChange(
                  value,
                  rowRecord,
                  "grouped_variety_name",
                  -0,
                  option.props.children,
                  false
                )
              }
            >
              {this.state.groupedvarietyList &&
                this.state.groupedvarietyList.length &&
                this.state.groupedvarietyList.map((hubItem, idx) => (
                  <Option
                    key={hubItem.grouped_variety_id}
                    value={hubItem.grouped_variety_id}
                  >
                    {hubItem.grouped_variety_name}
                  </Option>
                ))}
            </Select>
            
          </div>
        </>
      );
    },
  },
  

  {
    title: "Action",
    dataIndex: "Submit",
    key: "Submit",
    width: 120,
    visibility: false,
    render: (record, rowRecord, index) => {
      return (
        <>
          <div style={{ display: "inline-flex", width: "100%" }}>
            <Button
            className={!canUserEdit(
              this.props.userData,
              null,
              FeatureCodes.MASTER_SCREEN_OPS_EDIT
            ) ? "disabledBtnClass" : "btnClass"}
              disabled={!canUserEdit(
                this.props.userData,
                null,
                FeatureCodes.MASTER_SCREEN_OPS_EDIT
              )}
              type="primary"
              size={"small"}
              style={{
                marginLeft: 5,
                alignSelf: "center",
                padding: "0px 0px 2px",
                width:70,
                height: 31,
                
                backgroundColor: "#20346a",
                color: "white",
                borderRadius: 15,
              }}
              block
              htmlType="submit"
              onClick={() =>
                rowRecord.isEdit
                  ? this.submitTableRowData(rowRecord, index, false)
                  : this.handleEditChange(rowRecord, index)
              }
            >
              {" "}
              {rowRecord.isEdit ? "Submit" : "Edit"}{" "}
            </Button>


            <Button
              className={!rowRecord.isEdit ? "disabledBtnClass" : "btnClass"}
              type="primary"
              size={"small"}
              style={{
                marginLeft: 5,
                alignSelf: "center",
                width:70,
                color: "white",
                borderRadius:15,
                padding: "0px 0px 2px",
                
                height: 31,
                backgroundColor: "#20346a",
              }}
              block
              htmlType="submit"
              disabled={!rowRecord.isEdit}
              onClick={() => this.handleCancelClick(rowRecord, index)
              }
            >
              Cancel
            </Button>

          </div>
        </>
      );
    },
  },
]
 }
 dataSource={this.datum}
 />
</div>



</Spin>

</div>
 );
}
}
const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
});

export default connect(mapStateToProps)(StateDistrictMarketVarietyMaster);


//export default CostBuildUpFarmer;





    
      