import React from "react";
import "./competitor-hover-table.styles.css";

import { Table } from "antd";
import { Component } from "react";

/**
 * @component
 * @description Custom made header component for competitor hover table component.
 * @property {string} title the column header title
 * @property {string} subtitle the additional information such as units for the table colum hrader
 * @memberof CompetitorHoverTable
 */
export const TableColumnHeader = (props) => {
  const { title, subtitle, marginTop, marginLeft } = props;

  return (
    <div
      style={{
        marginTop: marginTop,
        marginLeft: marginLeft,
        display: "flex",
        flexDirection: "column",
      }}
    // className="column-header-container"
    >
      <div
        style={{
          color: "white",
          fontSize: "12px",
          whiteSpace: "nowrap",
          textAlign: "left",
        }}
      >
        {title}
      </div>
      <div
        style={{
          color: "#cbdfff",
          fontSize: "white", //"11px",
          fontWeight: "normal",
          textAlign: "left",
          // textAlign: subtitle && title.length < 4 ? "left" : "center"
        }}
      >
        {subtitle}
      </div>
    </div>
  );
};

const competitorHoverTableProps = {
  pagination: false,
  size: "default",
  bordered: true,
};

/**
 * @component
 * @description The table component that displays multiple competitor information on hover.
 * @property {boolean} isMT whether the is unit tobe displayed is in metric tons.
 * @property {object[]} data the data to be displayed inside the table.
 */
class CompetitorHoverTable extends Component {
  render() {
    const { isMT, data, isBuyingDecision } = this.props;

    const setTableData = () => {
      const tableData = data.filter((item) => item.key);
      return tableData;
    };


    const getColumns = () => {
      if (isBuyingDecision) {
        return [
          {
            title: "Competitor Name",
            dataIndex: "competitor_name",
          },
          {
            title: "Mandi/Trader",
            dataIndex: "name",
          },
          {
            title: (
              <TableColumnHeader
                title="Price Min"
                subtitle={` ${isMT ? "₹/MT" : "₹/Qntl"}`}
              />
            ),
            dataIndex: "competitor_min_price",
          },
          {
            title: (
              <TableColumnHeader
                title="Price Max"
                subtitle={` ${isMT ? "₹/MT" : "₹/Qntl"}`}
              />
            ),
            dataIndex: "competitor_max_price",
          },
          {
            title: (
              <TableColumnHeader
                title="Purchase Plan"
                subtitle={` ${isMT ? "MT" : "Qntl"}`}
              />
            ),
            dataIndex: "purchase_plan",
            render: (purchasePlan, record) => {
              return purchasePlan ? purchasePlan : null;
            },
          },
        ]

      } else {
        return [
          {
            title: "Competitor Name",
            dataIndex: "competitor_name",
          },

          {
            title: (
              <TableColumnHeader
                title="Price Min"
                subtitle={` ${isMT ? "₹/MT" : "₹/Qntl"}`}
              />
            ),
            dataIndex: "competitor_min_price",
          },
          {
            title: (
              <TableColumnHeader
                title="Price Max"
                subtitle={` ${isMT ? "₹/MT" : "₹/Qntl"}`}
              />
            ),
            dataIndex: "competitor_max_price",
          },
          {
            title: (
              <TableColumnHeader
                title="Purchase Plan"
                subtitle={` ${isMT ? "MT" : "Qntl"}`}
              />
            ),
            dataIndex: "purchase_plan",
            render: (purchasePlan, record) => {
              return purchasePlan ? purchasePlan : null;
            },
          },
        ]

      }

    }

    return (
      <Table
        //   className="competitor=hover-table"
        columns={
          getColumns()
        }
        dataSource={setTableData()}
        {...competitorHoverTableProps}
      />
    );
  }
}

export default CompetitorHoverTable;
