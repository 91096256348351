import React from "react";
import "./cost-buildup-sahayak-bihar-master.styles.css";
import API from "../../api";
import { getStatesAPI } from "../../services/generic-apis";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUserData ,selectMSMessageRBAC} from "../../redux/user/user.selectors";
import { canUserEdit,getUserMSRBACMessage } from "../../auth/utils";
import { FeatureCodes } from "../../auth/data";

import { Component } from "react";
import { Table, Spin, Input, Select, Button, message, Form, Modal } from "antd";
const { Search } = Input;
const { Option } = Select;

class CostBuildupSahayakBiharMaster extends Component {
  state = {
    isModalVisible: false,
    isAdd: false,

    locationList: [],
    varietyList: [],
    variantList: [],
    mandi_cessList: [],
    gunnycostimpactList: [],
    commissionList: [],
    sahayakcommissionList: [],
    CostBuildupSahayakBiharTableData: [],
    currentRecord: null,
    userBranches: null,
    searchText: null,
    searchUserType: 0,
    isActive: false,
    isSpinnerOnLoad: false,
    isSubmitted: false,

    newData: {
        freight_cost: undefined,
        gunny_cost: undefined,
        labour_rate: undefined,
        territory_id: undefined,
        territory_name: "",
        territory_sap_code:"",
        sahayak_commission:undefined,
        sanyojak_commission:undefined,
      isEdit: false,
    },
    rowData: undefined,
    selectedStateValue: undefined,
    selectedStateName: "",
    selectedStateList: [],
  };

  componentDidMount() {
    this.setState({ isSpinnerOnLoad: true });
    this.getcostsahayak();
  }

  getcostsahayak = async () => {
    this.setState({ isSpinnerOnLoad: true });
    await API.get("/cost_buildup_sahayak_bihar")
      .then((resp) => {
        
        let modifiedData = resp.data.data.map((item, index) => {
          return {
            ...item,
            isEdit: false,
            key: index,
          };
        });
        this.setState(
          {
            CostBuildupSahayakBiharTableData: modifiedData,
          },
          () => this.setState({ isSpinnerOnLoad: false, isAdd: true })
        );
        //console.log("mandi table data ", this.state.mandiHubDistrictTableData);
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };


  setTableData = () => {
    if (
      this.state.CostBuildupSahayakBiharTableData !== undefined ||
      this.state.CostBuildupSahayakBiharTableData.length >= 0
    ) {
      const userTableData = this.state.CostBuildupSahayakBiharTableData.map(
        (user, index) => {
          return {
            key:user.key,
            freight_cost: user.freight_cost,
        gunny_cost: user.gunny_cost,
        labour_rate: user.labour_rate,
        territory_id: user.territory_id,
        territory_name: user.territory_name,
        territory_sap_code:user.territory_sap_code,
        sahayak_commission:user.sahayak_commission,
        sanyojak_commission:user.sanyojak_commission,
            isEdit: user.isEdit,
          };
        }
      );

      let dataAfterSearch = userTableData;

      if (this.state.searchText) {
        dataAfterSearch = dataAfterSearch.filter(
          (item) =>
            (item.territory_name !== null &&
              item.territory_name
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase()))
        );
      }
      return dataAfterSearch;
    } else {
      return this.state.CostBuildupSahayakBiharTableData;
    }
  };

  submitTableRowData = (row, index, isPopup) => {
    this.setState({ isSpinnerOnLoad: true, isSubmitted: true });
    let warningmessage = "";
     if (
      row.freight_cost === null ||
      row.freight_cost === undefined ||
      row.freight_cost === ""
    ) {
      warningmessage = "Please enter Freight Cost";
    } else if (
      row.gunny_cost === null ||
      row.gunny_cost === undefined ||
      row.gunny_cost === ""
    ) {
      warningmessage = "Please enter Gunny Cost";
    } else if (
      row.labour_rate === null ||
      row.labour_rate === undefined ||
      row.labour_rate === ""
    ) {
      warningmessage = "Please enter Labour Rate";
    }else if (
      row.sahayak_commission === null ||
      row.sahayak_commission === undefined ||
      row.sahayak_commission === ""
    ) {
      warningmessage = "Please enter Sahayak Commission";
    }else if (
      row.sanyojak_commission === null ||
      row.sanyojak_commission === undefined ||
      row.sanyojak_commission === ""
    ) {
      warningmessage = "Please enter Sanyojak Commission";
    }

    if (
      warningmessage === "" ||
      warningmessage === undefined ||
      warningmessage === null
    ) {
      this.setState({ isSpinnerOnLoad: true });
      this.setState({ isSubmitted: true });

      let data = {
        freight_cost: row.freight_cost,
        gunny_cost: row.gunny_cost,
        labour_rate: row.labour_rate,
        territory_id: row.territory_id,
        sahayak_commission:row.sahayak_commission,
        sanyojak_commission:row.sanyojak_commission,
      };

      // const headers = {
      //   "Content-Type": "application/json",
      //   Authorization: "Bearer " + localStorage.getItem("accessToken"),
      // };

      API.put("/cost_buildup_sahayak_bihar", data, {})

        .then((response) => {
          if (response.status === 200 && response.data.status) {
            message.info(response.data.message, 5);
            this.setModalVisibility(false);
            this.getcostsahayak();
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
          } else if (!response.data.status) {
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
            message.error(response.data.message, 5);
          } else {
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
            message.error("Please try again", 5);
          }
        })
        .catch((err) => {
          if (err) {
            if (err.response && err.response.status === 400)
              message.error("Something went wrong. Please try again later.", 5);
            else {
              message.error(
                "Something went wrong.. Please try again later.",
                5
              );
            }
          } else {
            message.error("Something went wrong... Please try again later.", 5);
          }
          this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
          // this.setModalVisibility(false);
        });
    } else {
      this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
      message.error(warningmessage, 5);
    }
  };

  setModalVisibility = (status) => {
    if (status) {
      const nData = {
        freight_cost: undefined,
        gunny_cost: undefined,
        labour_rate: undefined,
        territory_id: undefined,
        territory_name: "",
        territory_sap_code:"",
        sahayak_commission:undefined,
        sanyojak_commission:undefined,
        isEdit: false,
      };

      this.setState({ newData: nData });
    }

    this.setState({ isModalVisible: status });
  };

  TableColumnHeader = (props) => {
    const { title, subtitle } = props;
    return (
      <div className="column-header-container">
        <div
          style={{
            color: "white",
            fontSize: "12px",
            whiteSpace: "nowrap",
            fontWeight: "normal",
            textAlign: "left",
          }}
        >
          {title}
        </div>
        <div
          style={{
            color: "#cbdfff",
            fontSize: "11px",
            fontWeight: "normal",
            textAlign: "left",
          }}
        >
          {subtitle}
        </div>
      </div>
    );
  };

  createFilters = (label) => {
    let filterData = this.state.CostBuildupSahayakBiharTableData;

    //#region remove duplicate objects

    let uniqueFilterData = [];

    if (
      label === "is_active" &&
      this.state.CostBuildupSahayakBiharTableData &&
      this.state.CostBuildupSahayakBiharTableData.length > 0
    ) {
      uniqueFilterData = [
        { text: "Yes", value: 1 },
        { text: "No", value: 0 },
      ];
    } else {
      filterData.map((mainItem) =>
        uniqueFilterData.filter((item) => item[label] === mainItem[label])
          .length > 0
          ? null
          : uniqueFilterData.push(mainItem)
      );

      uniqueFilterData = uniqueFilterData.map((item) => {
        return {
          text: item[label],

          value: item[label],
        };
      });

      uniqueFilterData.sort((a, b) => a.text.localeCompare(b.text));
    }

    return uniqueFilterData;
  };

  handleChange = (e, row, index, name, isPopup) => {
    let a;
    var alphaNumericRegex = /^[a-zA-Z0-9]*$/;
    var numberRegex = /^[0-9.]+$/;
    if (isPopup === false) {
      a = this.state.CostBuildupSahayakBiharTableData;

      switch (name) {
        case "freight_cost":
          if (e.target.value === "") {
            a[row.key].freight_cost = "";
          } else if (e.target.value.match(numberRegex)) {
            a[row.key].freight_cost = e.target.value;
          }

          this.setState({ CostBuildupSahayakBiharTableData: a });
          return;

        case "gunny_cost":
          if (e.target.value === "") {
            a[row.key].gunny_cost = "";
          } else if (e.target.value.match(numberRegex)) {
            a[row.key].gunny_cost = e.target.value;
          }

          this.setState({ CostBuildupSahayakBiharTableDatat: a });
          return;

        case "labour_rate":
          if (e.target.value === "") {
            a[row.key].labour_rate = "";
          } else if (e.target.value.match(numberRegex)) {
            a[row.key].labour_rate = e.target.value;
          }

          this.setState({ CostBuildupSahayakBiharTableData: a });
          return;
          case "sanyojak_commission":
            if (e.target.value === "") {
              a[row.key].sanyojak_commission = "";
            } else if (e.target.value.match(numberRegex)) {
              a[row.key].sanyojak_commission = e.target.value;
            }
  
            this.setState({ CostBuildupSahayakBiharTableData: a });
            return;
            case "sahayak_commission":
              if (e.target.value === "") {
                a[row.key].sahayak_commission = "";
              } else if (e.target.value.match(numberRegex)) {
                a[row.key].sahayak_commission = e.target.value;
              }
    
              this.setState({ CostBuildupSahayakBiharTableData: a });
              return;
        default:
          return "";
      }
    } else {
      a = this.state.newData;

      switch (name) {
        case "freight_cost":
          if (e.target.value === "") {
            a.freight_cost = "";
          } else if (e.target.value.match(numberRegex)) {
            a.freight_cost = e.target.value;
          }

          this.setState({ newData: a });
          return;
        case "gunny_cost":
          if (e.target.value === "") {
            a.gunny_cost = "";
          } else if (e.target.value.match(numberRegex)) {
            a.gunny_cost = e.target.value;
          }

          this.setState({ newData: a });
          return;
        case "labour_rate":
          if (e.target.value === "") {
            a.labour_rate = "";
          } else if (e.target.value.match(numberRegex)) {
            a.labour_rate = e.target.value;
          }

          this.setState({ newData: a });
          return;
          case "sanyojak_commission":
            if (e.target.value === "") {
              a.sanyojak_commission = "";
            } else if (e.target.value.match(numberRegex)) {
              a.sanyojak_commission = e.target.value;
            }
  
            this.setState({ newData: a });
            return;
            case "sahayak_commission":
              if (e.target.value === "") {
                a.sahayak_commission = "";
              } else if (e.target.value.match(numberRegex)) {
                a.sahayak_commission = e.target.value;
              }
    
              this.setState({ newData: a });
              return;
        default:
          return "";
      }
      // this.setState({ newData: a });
    }
  };
  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  handleEditChange = (row, index) => {
    // console.log("user hub id is ", this.props.userData.hub_state_id );//check this afterwards

    this.setState({ rowData: row });
    let tempData = this.state.CostBuildupSahayakBiharTableData.map((item) => {
      if (item.territory_id === row.territory_id) {
        return {
          ...item,
          isEdit: true,
        };
      } else {
        return item;
      }
    });
    this.setState({ CostBuildupSahayakBiharTableData: tempData });
  };

  handleCancelClick = (rowData, index) => {
    let tempData = this.state.CostBuildupSahayakBiharTableData.map((item) => {
      if (item.territory_id === rowData.territory_id) {
        return this.state.rowData;
      } else {
        return item;
      }
    });
    this.setState({ CostBuildupSahayakBiharTableData: tempData });
  };
  render() {
    const userTableProps = {
      pagination: false,
      size: "default",
      bordered: true,
      scroll: { x: 200, y: window.innerHeight - 250 },
    };
   

    return (
      <div // close left
        className="mandi-master-container"
        style={{
          height: window.innerHeight - 85,
          marginTop: "-45px",
        }}
      >
        <div className="top-container">
          {/* <div className="hub-region-filter">
       <span
                style={{
                  fontSize: "15px",
                  marginTop: "5px",
                  marginLeft: "68px",
                  fontWeight: "600",
                }}
              >
                State&nbsp;
              </span>{" "}
              <Select
                disabled={this.state.isAdd}
                showSearch
                optionFilterProp="children"
                className={"select-master-region"}
                mode="single"
                placeholder="Select State"
                value={this.state.selectedhubStateValue}
                onChange={(value, props) =>
                  handleStateSelect(value, props)
                }
              >
                {this.state.selectedhubStateList !== null && this.state.selectedhubStateList.length &&
                  this.state.selectedhubStateList.map((item, idx) => (
          
                      <Option
                      value={item.hub_state_id}
                      key={item.Hub_State}
                    >
                      {item.Hub_State}
                    </Option>
                  ))}
              </Select>
  
            </div> */}

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row-reverse",
              placeItems: "flex-end",
              marginRight: "25px",
              marginBottom: "10px",
            }}
          >
            <Search
              disabled={!this.state.isAdd}
              placeholder="Search"
              allowClear
              onChange={(e) => this.setState({ searchText: e.target.value })}
              style={{ width: "25%" }}
            />
          </div>
        </div>

        <div>
          <div
            style={{
              marginLeft: "25px",
              marginTop: "-8px",
              fontSize: "14px",
              color: "red",
              display: "flex",
              float: "left",
            }}
          >
          {getUserMSRBACMessage(this.props.messageAccess,FeatureCodes.MS_COST_SAHAYAK_BIHAR_EDIT)}
          </div>
          <div
            style={{
              marginRight: "20px",
              marginTop: "-8px",
              fontSize: "15px",
              color: "#0B133D",
              display: "flex",
              float: "right",
            }}
          >
            Total number of records:&nbsp;
            <b>
              {this.state.CostBuildupSahayakBiharTableData &&
                this.setTableData().length}
            </b>
          </div>
        </div>

        <Spin
          spinning={this.state.isSpinnerOnLoad}
          size={"large"}
          tip="Loading..."
          style={{ alignSelf: "center", zIndex: 9999, marginTop: "20px" }}
        >
        
          <div
            className="table-container1"
            style={{ maxHeight: "400px", margin: "6px 10px" }}
          >
            <Table
              {...userTableProps}
              columns={[
                {
                    title: "Hub",
                    dataIndex: "territory_name",
                    key: "territory_name",
                    width: 90,
                    filters: this.createFilters("territory_name"),
                    onFilter: (value, record) =>
                      record.territory_name.includes(value),
                    sortDirections: ["descend", "ascend"],
                    sorter: (a, b) => a.territory_name.localeCompare(b.territory_name),
               
                  },
                {
                  title: "Freight Cost",
                  dataIndex: "freight_cost",
                  key: "freight_cost",
                  width: 120,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) =>
                    a.freight_cost - b.freight_cost,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            type="number"
                            disabled={!rowRecord.isEdit}
                            style={{ marginRight: "2px" }}
                            maxLength={100}
                            min="0"
                            value={rowRecord.freight_cost}
                            name="freight_cost"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "freight_cost",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },
                {
                    title: "Labour Rate",
                    dataIndex: "labour_rate",
                    key: "labour_rate",
                    width: 100,
                    sortDirections: ["descend", "ascend"],
                    sorter: (a, b) =>
                      a.labour_rate - b.labour_rate,
                    render: (record, rowRecord, index) => {
                      return (
                        <>
                          <div style={{ marginLeft: "-20px" }}>
                            <Input
                              type="number"
                              disabled={!rowRecord.isEdit}
                              style={{ marginRight: "2px" }}
                              maxLength={100}
                              min="0"
                              value={rowRecord.labour_rate}
                              name="labour_rate"
                              onChange={(e) =>
                                this.handleChange(
                                  e,
                                  rowRecord,
                                  index,
                                  "labour_rate",
                                  false
                                )
                              }
                            />
                          </div>
                        </>
                      );
                    },
                  },
                {
                  title: "Gunny Cost",
                  dataIndex: "gunny_cost",
                  key: "gunny_cost",
                  width: 120,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.gunny_cost - b.gunny_cost,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            type="number"
                            disabled={!rowRecord.isEdit}
                            style={{ marginRight: "2px" }}
                            maxLength={100}
                            min="0"
                            value={rowRecord.gunny_cost}
                            name="gunny_cost"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "gunny_cost",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },
                {
                  title: "Sanyojak Commission",
                  dataIndex: "sanyojak_commission",
                  key: "sanyojak_commission",
                  width: 100,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) =>
                    a.sanyojak_commission - b.sanyojak_commission,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            type="number"
                            disabled={!rowRecord.isEdit}
                            style={{ marginRight: "2px" }}
                            maxLength={100}
                            min="0"
                            value={rowRecord.sanyojak_commission}
                            name="sanyojak_commission"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "sanyojak_commission",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },
                {
                  title: "Sahayak Commission",
                  dataIndex: "sahayak_commission",
                  key: "sahayak_commission",
                  width: 150,

                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) =>
                    a.sahayak_commission.localeCompare(b.sahayak_commission),
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div
                          style={{ marginLeft: "-15px", marginRight: "-10px" }}
                        >
                          <Input
                            type="number"
                            disabled={!rowRecord.isEdit}
                            style={{ marginRight: "2px" }}
                            maxLength={100}
                            min="0"
                            value={rowRecord.sahayak_commission}
                            name="sahayak_commission"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "sahayak_commission",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },
                {
                  title: "Action",
                  dataIndex: "Submit",
                  key: "Submit",
                  width: 120,
                  visibility: false,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ display: "inline-flex", width: "100%" }}>
                          <Button
                            className={
                              !canUserEdit(
                                this.props.userData,
                                null,
                                FeatureCodes.MS_COST_SAHAYAK_BIHAR_EDIT
                              )
                                ? "disabledBtnClass"
                                : "btnClass"
                            }
                            disabled={
                              !canUserEdit(
                                this.props.userData,
                                null,
                                FeatureCodes.MS_COST_SAHAYAK_BIHAR_EDIT
                              )
                            }
                            type="primary"
                            size={"small"}
                            style={{
                              marginLeft: 5,
                              alignSelf: "center",
                              padding: "0px 0px 2px",
                              width: 70,
                              height: 31,

                              backgroundColor: "#20346a",
                              color: "white",
                              borderRadius: 15,
                            }}
                            block
                            htmlType="submit"
                            onClick={() =>
                              rowRecord.isEdit
                                ? this.submitTableRowData(
                                    rowRecord,
                                    index,
                                    false
                                  )
                                : this.handleEditChange(rowRecord, index)
                            }
                          >
                            {" "}
                            {rowRecord.isEdit ? "Submit" : "Edit"}{" "}
                          </Button>

                          <Button
                            className={
                              !rowRecord.isEdit
                                ? "disabledBtnClass"
                                : "btnClass"
                            }
                            type="primary"
                            size={"small"}
                            style={{
                              marginLeft: 5,
                              alignSelf: "center",
                              width: 70,
                              color: "white",
                              borderRadius: 15,
                              padding: "0px 0px 2px",

                              height: 31,
                              backgroundColor: "#20346a",
                            }}
                            block
                            htmlType="submit"
                            disabled={!rowRecord.isEdit}
                            onClick={() =>
                              this.handleCancelClick(rowRecord, index)
                            }
                          >
                            Cancel
                          </Button>
                        </div>
                      </>
                    );
                  },
                },
              ]}
              dataSource={this.setTableData()}
            />
          </div>
        </Spin>
      </div>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
  messageAccess: selectMSMessageRBAC,
});

export default connect(mapStateToProps)(CostBuildupSahayakBiharMaster);

//export default CostBuildUpFarmer;
