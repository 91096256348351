import React from "react";
import "./alert-item.styles.css";

import { Popover } from "antd";
import moment from "moment";

import POImage from "../../assets/buyingRecommendation.svg";

/**
 * @component
 * @description The alert item component that is displayed on the side menu (under alerts tab)
 * @property {string} type The type of alert.
 * @property {string} title The Heading of alert.
 * @property {string} description The alert description.
 * @property {dateTime} dateTime The datetime object representing the creation of alert.
 * @property {url} url The url for a news alert. null for non-news alerts.
 */
const AlertItem = (props) => {
  const { type, title, description, dateTime, url } = props;

  return url ? (
    <a target="_blank" rel="noopener noreferrer" href={url}>
      <div className="alert-container">
        <div className="alert-details">
          <img className="alert-image" src={POImage} alt="alert" />
          <div className="alert-header">
            <div className="alert-type">{type}</div>
            <div className="alert-title">{title}</div>
          </div>
          <Popover
            content={
              moment(dateTime, "DD-MM-YYYY").format("DD-MM-YYYY") +
              "    " +
              dateTime.toLocaleTimeString()
            }
            overlayClassName="alert-timestamp-hover"
          >
            <div className="alert-time">
              {moment(dateTime, "DD-MM-YYYY").format("DD-MM-YYYY")}
            </div>
          </Popover>
        </div>
        <div className="alert-description">{description}</div>
      </div>
    </a>
  ) : (
    <div className="alert-container">
      <div className="alert-details">
        <img className="alert-image" src={POImage} alt="alert" />
        <div className="alert-header">
          <div className="alert-type">{type}</div>
          <div className="alert-title">{title}</div>
        </div>
        <Popover
          content={
            moment(dateTime, "DD-MM-YYYY").format("DD-MM-YYYY") +
            "    " +
            dateTime.toLocaleTimeString()
          }
          overlayClassName="alert-timestamp-hover"
        >
          <div className="alert-time">
            {moment(dateTime, "DD-MM-YYYY").format("DD-MM-YYYY")}
          </div>
        </Popover>
      </div>
      <div className="alert-description">{description}</div>
    </div>
  );
};

export default AlertItem;
