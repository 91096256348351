import { SummaryActionTypes } from "./summary.types";

const INITIAL_STATE = {
  isModalVisible: false
};

const summaryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SummaryActionTypes.SET_SUMMARY_MODAL_VISIBILITY:
      return {
        ...state,
        isModalVisible: action.payload
      };
    default:
      return state;
  }
};

export default summaryReducer;
