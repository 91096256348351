import React from "react";
import "./temporal-expandable-table.styles.css";
import { Component } from "react";

import {
  Table,
  InputNumber,
  Input,
  Tooltip,
  Spin,
  Modal,
  message,
  Empty,
  Col,
  Row,
  Carousel,
  Popover,
} from "antd";

import EditImage from "../../assets/edit-user.svg";
import DisabledEditImage from "../../assets/edit-user-disabled.svg";
import TickImage from "../../assets/tickIcon.svg";
import CloseImage from "../../assets/cross.svg";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectAllVarietyInfo } from "../../redux/arrival-entry/arrival-entry.selectors";

import { getVarietyMaxPrice, getVarietyMinPrice } from "../../auth/utils";

import { getVarietiesWithStates } from "../../services/generic-apis";

import QCMonthlyVarietiesGraph from "../qc-monthly-varieties-graph/qc-monthly-varieties-graph.component";
import CustomSelectWeekly from "../custom-select-weekly/custom-select-weekly.component";
import API from "../../api";
import ModelRunGraph from "../model-run-visualization-graph/model-run-visualization-graph.component";

import infoImage from "../../assets/info.svg";
import PremiumNonPremiumGraph from "../prem-nprem-bar-graph/prem-nprem-bar-graph.component";

/**
 * @component
 * @description Custom button component used in monthly allocation table
 * @property {image} src the source image
 * @property {function} onClick the button click event to be fired.
 * @property {boolean} disabled set true to disable the button component.
 * @property {boolean} isPencilImage set true to display the edit image.
 * @memberof TemporalExpandableTable
 */
const CustomButton = (props) => {
  const { src, onClick, disabled, isPencilImage } = props;
  return (
    <div
      className={`${disabled ? "no-pointer-events temporal-button " : "temporal-button"
        }`}
      onClick={() => onClick()}
    >
      <img
        style={{ width: "inherit" }}
        src={isPencilImage && disabled ? DisabledEditImage : src}
        alt="button"
      />
    </div>
  );
};

/**
 * @component
 * @description The monthly allocation table component.
 * Used in both monthly price and monthly volume tabs.
 * @property {boolean} isConstrained whether the data displayed is constrained monthly volume
 * (only for monthly volume component).
 * @property {boolean} isPrice whether the table is used for displaying monthly price allocation.
 * @property {string} title the title of the table.
 * @property {function} onValueModified callack on changing the value in the input fields in the table.
 * @property {function} changeEditStatus function to enable/disable the input fields in the table.
 * @property {function} onTickButtonClicked callback on submit button click for a row.
 * @property {function} resetRow callback on cancel button click for a row.
 * @property {object[]} data the data to display in the table.
 */
class TemporalExpandableTable extends Component {
  state = {
    isLoading: false,
    isModalVisible: false,
    modalData: null,
    varieties: [],
    states: [],
    selectedVarietyID: null,
    selectedStateID: null,
  };

  setModalLoaderStatus = (status) => {
    this.setState({ isLoading: status });
  };

  setModalVisibility = (status) => {
    this.setState({ isModalVisible: status });
  };

  getVarietiesWithStates = async () => {
    this.setModalLoaderStatus(true);
    try {
      const filterResponse = await getVarietiesWithStates();
      if (filterResponse.data.status) {
        this.setModalLoaderStatus(false);
        console.log("filters :", filterResponse.data.data.qc_filter);
        this.setState({
          varieties: filterResponse.data.data.qc_filter,
          states: filterResponse.data.data.qc_filter[0].states,
          selectedVarietyID: filterResponse.data.data.qc_filter[0].variety_id,
          selectedStateID:
            filterResponse.data.data.qc_filter[0].states[0].branch_id,
        });
      } else {
        this.setModalLoaderStatus(false);
        message.error(
          "Could not retrieve the variety and state filter information."
        );
      }
    } catch (error) {
      this.setModalLoaderStatus(false);
      message.error(
        "Could not retrieve the variety and state filter information."
      );
    }
  };

  componentDidMount() {
    if (this.props.isPrice) {
      this.getVarietiesWithStates();
    }
  }

  render() {
    const {
      isConstrained,
      isPrice,
      title,
      data,
      onValueModified,
      changeEditStatus,
      onTickButtonClicked,
      resetRow,
      selectedMonthID,
      qcThresholds,
      isDisabled,
    } = this.props;

    const generateProperDatasetsForPremiumNonPremiumQCGraph = (
      datasets,
      legend
    ) => {
      let finalDatasets = [];
      //   labelList = [];

      // datasets.map((dataset) => {
      //   labelList.push(...dataset.state_variety);
      // });

      // console.log("label list before :", labelList);

      // labelList = Array.from(new Set(labelList.map((item) => item)));

      // console.log("label list :", labelList);

      const febInfo = datasets.find((item) => item.month === "Feb");
      const marchInfo = datasets.find((item) => item.month === "Mar");
      const aprilInfo = datasets.find((item) => item.month === "Apr");
      const mayInfo = datasets.find((item) => item.month === "May");
      const juneInfo = datasets.find((item) => item.month === "Jun");
      const psInfo = datasets.find((item) => item.month === "PS"); //FIXME: PS might change to post_season or similar from backend. Keep an eye out.

      legend.map((label) => {
        const dataset = {
          label: label,
          data: [
            getMonthGraphValue(febInfo, label),
            getMonthGraphValue(marchInfo, label),
            getMonthGraphValue(aprilInfo, label),
            getMonthGraphValue(mayInfo, label),
            getMonthGraphValue(juneInfo, label),
            getMonthGraphValue(psInfo, label),
          ],
        };
        finalDatasets.push(dataset);
      });

      console.log("final datasets : ", finalDatasets);
      return finalDatasets;

      // const nonPremiumSets = Array.from(
      //   new Set(nonPremiumDatasets.map((item) => item.label))
      // ).map((name) => {
      //   return nonPremiumDatasets.find((item) => item.label === name);
      // });
    };

    const getMonthGraphValue = (info, label) => {
      if (!info) {
        return null;
      }
      if (info.state_variety.find((item) => item === label) === undefined) {
        return null;
      } else {
        const labelIndex = info.state_variety.findIndex(
          (item) => item === label
        );
        return info.price_values[labelIndex];
      }
    };
    const convertDataForBarGraph = (dataFromJson) => {
      let modifiedData = [];

      dataFromJson.data_points.map((dataset) => {
        let modifiedDataset = {
          labels: [dataset.month],
          datasets: dataset.price_values.map((value, idx) => {
            return {
              label: dataset.state_variety[idx],
              data: [value],
            };
          }),

          // dataset.map((item, idx) => {
          //   return {
          //     label: item.state_variety[idx],
          //     data: [item.price_values[idx]],
          //   };
          // }),
        };
        modifiedData.push(modifiedDataset);
      });

      return modifiedData;
    };
    const dataForBarGraph = [
      {
        labels: ["Feb"],
        datasets: [
          {
            label: "Rajasthan-Lokwan",
            data: [300],
          },
          {
            label: "Rajasthan-MillQuality",
            data: [400],
          },
          {
            label: "Gujarat-Wraj",
            data: [600],
          },
          {
            label: "Rajasthan-Wraj",
            data: [700],
          },
          {
            label: "Gujarat-Dara",
            data: [800],
          },
        ],
      },
      {
        labels: ["March"],
        datasets: [
          {
            label: "Bihar-MillQuality",
            data: [500],
          },
          {
            label: "Bihar-Lokwan",
            data: [200],
          },
        ],
      },
      {
        labels: ["April"],
        datasets: [
          {
            label: "Rajasthan-Lokwan",
            data: [300],
          },
          {
            label: "Rajasthan-MillQuality",
            data: [400],
          },
          {
            label: "Gujarat-Wraj",
            data: [600],
          },
          {
            label: "Rajasthan-Wraj",
            data: [700],
          },
          {
            label: "Gujarat-Dara",
            data: [800],
          },
        ],
      },
      {
        labels: ["May"],
        datasets: [
          {
            label: "Rajasthan-Lokwan",
            data: [300],
          },
          {
            label: "Rajasthan-MillQuality",
            data: [400],
          },
          {
            label: "Gujarat-Wraj",
            data: [600],
          },
          {
            label: "Rajasthan-Wraj",
            data: [700],
          },
          {
            label: "Gujarat-Dara",
            data: [800],
          },
        ],
      },
      {
        labels: ["June"],
        datasets: [
          {
            label: "Rajasthan-Lokwan",
            data: [300],
          },
          {
            label: "Rajasthan-MillQuality",
            data: [400],
          },
          {
            label: "Gujarat-Wraj",
            data: [600],
          },
          {
            label: "Rajasthan-Wraj",
            data: [700],
          },
          {
            label: "Gujarat-Dara",
            data: [800],
          },
        ],
      },
      {
        labels: ["Post Season"],
        datasets: [
          {
            label: "Rajasthan-Lokwan",
            data: [300],
          },
          {
            label: "Rajasthan-MillQuality",
            data: [400],
          },
          {
            label: "Gujarat-Wraj",
            data: [600],
          },
          {
            label: "Rajasthan-Wraj",
            data: [700],
          },
          {
            label: "Gujarat-Dara",
            data: [800],
          },
        ],
      },
    ];

    const tempPNPData = {
      prem_nprem_trend: {
        data_points: [
          {
            month: "Feb",
            price_values: [
              19393,
              19925,
              21425,
              19253,
              19790,
              20028,
              20576,
              20779,
              21349,
            ],
            state_variety: [
              "Rajasthan_MILL QUALITY",
              "Maharashtra_MILL QUALITY",
              "Delhi_DARA",
              "Madhya Pradesh_W RAJ",
              "Rajasthan_W RAJ",
              "Gujarat_LOKWAN",
              "Rajasthan_LOKWAN",
              "Madhya Pradesh_LOKWAN",
              "Gujarat_W RAJ",
            ],
          },
          {
            month: "Mar",
            price_values: [
              17930,
              18269,
              18539,
              18795,
              19943,
              17875,
              18628,
              18666,
              18803,
              19497,
            ],
            state_variety: [
              "Uttar Pradesh_DARA",
              "Maharashtra_MILL QUALITY",
              "Rajasthan_MILL QUALITY",
              "Gujarat_MILL QUALITY",
              "Delhi_DARA",
              "Madhya Pradesh_W RAJ",
              "Rajasthan_W RAJ",
              "Madhya Pradesh_LOKWAN",
              "Gujarat_LOKWAN",
              "Rajasthan_LOKWAN",
            ],
          },
          {
            month: "Apr",
            price_values: [
              17846,
              17964,
              17978,
              18286,
              19694,
              17658,
              18237,
              18260,
              18799,
              19031,
              19223,
            ],
            state_variety: [
              "Rajasthan_MILL QUALITY",
              "Maharashtra_MILL QUALITY",
              "Uttar Pradesh_DARA",
              "Gujarat_MILL QUALITY",
              "Delhi_DARA",
              "Madhya Pradesh_W RAJ",
              "Rajasthan_LOKWAN",
              "Rajasthan_W RAJ",
              "Madhya Pradesh_LOKWAN",
              "Gujarat_LOKWAN",
              "Gujarat_W RAJ",
            ],
          },
          {
            month: "May",
            price_values: [
              18139,
              18912,
              19109,
              19599,
              21055,
              17961,
              18524,
              18912,
              19107,
              19683,
              20451,
            ],
            state_variety: [
              "Uttar Pradesh_DARA",
              "Rajasthan_MILL QUALITY",
              "Gujarat_MILL QUALITY",
              "Maharashtra_MILL QUALITY",
              "Delhi_DARA",
              "Madhya Pradesh_W RAJ",
              "Rajasthan_LOKWAN",
              "Madhya Pradesh_LOKWAN",
              "Rajasthan_W RAJ",
              "Gujarat_W RAJ",
              "Gujarat_LOKWAN",
            ],
          },
          {
            month: "Jun",
            price_values: [
              18035,
              18311,
              18395,
              19018,
              20653,
              17730,
              18343,
              18594,
              19395,
              19914,
            ],
            state_variety: [
              "Uttar Pradesh_DARA",
              "Maharashtra_MILL QUALITY",
              "Rajasthan_MILL QUALITY",
              "Gujarat_MILL QUALITY",
              "Delhi_DARA",
              "Madhya Pradesh_W RAJ",
              "Rajasthan_W RAJ",
              "Madhya Pradesh_LOKWAN",
              "Rajasthan_LOKWAN",
              "Gujarat_W RAJ",
            ],
          },
        ],
        legend: [
          "Rajasthan_MILL QUALITY",
          "Uttar Pradesh_DARA",
          "Maharashtra_MILL QUALITY",
          "Gujarat_MILL QUALITY",
          "Delhi_DARA",
          "Madhya Pradesh_W RAJ",
          "Rajasthan_LOKWAN",
          "Rajasthan_W RAJ",
          "Madhya Pradesh_LOKWAN",
          "Gujarat_LOKWAN",
          "Gujarat_W RAJ",
        ],
        qc_prem_nprem_trend: 0,
        x_axis_labels: ["Feb", "Mar", "Apr", "May", "Jun"],
      },
    };
    console.log("threshold : ", qcThresholds);

    const onQCScoreClicked = (variety_id, state_id) => {
      if (this.state.varieties.length === 0) {
        message.warning(
          "There was some trouble in retrieving state-variety information. Please refresh the page."
        );
        return;
      }

      const stateList = this.state.varieties.find(
        (item) => item.variety_id === variety_id
      ).states;
      this.setState({
        selectedVarietyID: variety_id,
        states: stateList,
        selectedStateID: state_id,
      });

      getModalData(variety_id, state_id);
    };

    const conditionalColumns =
      isPrice && qcThresholds
        ? [
          {
            title: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <div className="state-name">{"Premium VS Non-Premium"}</div>
              </div>
            ),
            width: 90,
            key: "qc_prem_vs_nonprem",
            dataIndex: "qc_prem_vs_nonprem",
            render: (qc_prem_vs_nonprem, record) => {
              console.log("record :", record);
              return qc_prem_vs_nonprem !== null ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <div
                    onClick={() =>
                      onQCScoreClicked(record.variety_id, record.branch_id)
                    }
                    className="circle box-shadow-bordered grow"
                    style={{
                      background:
                        qc_prem_vs_nonprem < qcThresholds.qc_prem_vs_nonprem
                          ? "#fe8c00"
                          : "#73de73",
                    }}
                  >
                    {/* {qc_prem_vs_nonprem} */}
                  </div>
                </div>
              ) : record.isRow ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <div
                    // onClick={() =>
                    //   onQCScoreClicked(record.variety_id, record.branch_id)
                    // }
                    className="circle box-shadow-bordered grow"
                    style={{
                      background: "grey",
                    }}
                  >
                    NA
                  </div>
                </div>
              ) : null;
            },
          },
          {
            title: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <div className="state-name">{"State Trend"}</div>
              </div>
            ),
            width: 80,
            key: "qc_state_trend",
            dataIndex: "qc_state_trend",
            render: (qc_state_trend, record) => {
              return qc_state_trend !== null ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <div
                    onClick={() =>
                      onQCScoreClicked(record.variety_id, record.branch_id)
                    }
                    className="circle box-shadow-bordered grow"
                    style={{
                      background:
                        qc_state_trend < qcThresholds.qc_state_trend
                          ? "#fe8c00"
                          : "#73de73",
                    }}
                  >
                    {/* {qc_state_trend} */}
                  </div>
                </div>
              ) : record.isRow ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <div
                    // onClick={() =>
                    //   onQCScoreClicked(record.variety_id, record.branch_id)
                    // }
                    className="circle box-shadow-bordered grow"
                    style={{
                      background: "grey",
                    }}
                  >
                    NA
                  </div>
                </div>
              ) : null;
            },
          },
          {
            title: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <div className="state-name">{"Variety Trend"}</div>
              </div>
            ),
            width: 80,
            key: "qc_variety_trend",
            dataIndex: "qc_variety_trend",
            render: (qc_variety_trend, record) => {
              return qc_variety_trend !== null ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <div
                    onClick={() =>
                      onQCScoreClicked(record.variety_id, record.branch_id)
                    }
                    className="circle box-shadow-bordered grow"
                    style={{
                      background:
                        qc_variety_trend < qcThresholds.qc_variety_trend
                          ? "#fe8c00"
                          : "#73de73",
                    }}
                  >
                    {/* {qc_variety_trend} */}
                  </div>
                </div>
              ) : record.isRow ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <div
                    // onClick={() =>
                    //   onQCScoreClicked(record.variety_id, record.branch_id)
                    // }
                    className="circle box-shadow-bordered grow"
                    style={{
                      background: "grey",
                    }}
                  >
                    NA
                  </div>
                </div>
              ) : null;
            },
          },
        ]
        : [];

    const modifiedConditionalColumns =
      isPrice && qcThresholds && selectedMonthID !== 2
        ? [
          ...conditionalColumns,
          {
            title: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <div className="state-name">{"Model Comparison"}</div>
              </div>
            ),
            width: 80,
            key: "qc_model_comparison",
            dataIndex: "qc_model_comparison",
            render: (qc_model_comparison, record) => {
              // console.log("record : ", record);
              return qc_model_comparison !== null ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <div
                    onClick={() =>
                      onQCScoreClicked(record.variety_id, record.branch_id)
                    }
                    className="circle box-shadow-bordered grow"
                    style={{
                      background:
                        qc_model_comparison < qcThresholds.qc_model_comparison
                          ? "#fe8c00"
                          : "#73de73",
                    }}
                  >
                    {/* {qc_model_comparison} */}
                  </div>
                </div>
              ) : record.isRow ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <div
                    // onClick={() =>
                    //   onQCScoreClicked(record.variety_id, record.branch_id)
                    // }
                    className="circle box-shadow-bordered grow"
                    style={{
                      background: "grey",
                    }}
                  >
                    NA
                  </div>
                </div>
              ) : null;
            },
          },
        ]
        : conditionalColumns;

    const finalConditionalColumns =
      isPrice && qcThresholds
        ? {
          title: (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  color: "#FFFFFF",
                  fontSize: "14px",
                  fontWeight: "400",
                  whiteSpace: "break",
                  // marginTop: "-6px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <div>QC Scores</div>
                  <Popover
                    content={
                      <div>
                        <div>
                          The QC scores are based on predicted values alone.
                        </div>
                        <div>Green represents the QC has passed.</div>
                        <div>
                          Amber denotes that the corresponding QC has failed.
                        </div>
                      </div>
                    }
                    trigger="hover"
                    placement="bottom"
                  >
                    <img
                      style={{
                        width: "10px",
                        height: "10px",
                        margin: "2px 0 5px 5px",
                      }}
                      src={infoImage}
                      alt="info"
                    />
                  </Popover>
                </div>
              </div>
            </div>
          ),
          children: [...modifiedConditionalColumns],
        }
        : { width: 0 };

    // console.log("data : ", data);

    const conditionalProps = isPrice
      ? {
        //uncomment the bottom snippet if the min max for price should be based on variety min max.
        // min: getVarietyMinPrice(
        //   this.props.allVarietyInfo,
        //   data.variety,
        //   true
        // ),
        // max: getVarietyMaxPrice(
        //   this.props.allVarietyInfo,
        //   data.variety,
        //   true
        // ),
        min: 0,
      }
      : {
        min: 0,
      };

    const tableProps = {
      pagination: false,
      // size: "default",
      size: "default",
      bordered: true,
      defaultExpandAllRows: true,
      scroll: { x: 200, y: window.innerHeight - 290 },
    };

    // TODO: 1 week gap required.
    /**
     * @description To know a column representing a month is in the future.
     * Only those columns shall be made editable.
     * @param {number} month_id month id representing a column
     * @returns {boolean} true if the month representing the column is is upcoming.
     * @memberof TemporalExpandableTable
     */
    const isNextMonth = (month_id) => {
      if (month_id === 7) {
        return true;
      } else return month_id >= new Date().getMonth() + 1;
    };

    /**
     * @description Rearrange the data in proper format and
     * assign to the table to be displayed.
     * @returns the table datasource array(of objects).
     * @memberof TemporalExpandableTable
     */
    const setTemporalTableData = () => {
      const tableData = this.props.data.map((variety) => {
        return {
          key: variety.variety_id,
          variety_id: variety.variety_id,
          variety_name: variety.variety,
          branch_id: "",
          branch: variety.variety,
          qc_model_comparison: null,
          qc_prem_vs_nonprem: null,
          qc_state_trend: null,
          qc_variety_trend: null,
          feb: {
            month_id: "",
            current: "",
            planned: "",
            suggested: "",
          },
          march: {
            month_id: "",
            current: "",
            planned: "",
            suggested: "",
          },
          april: {
            month_id: "",
            current: "",
            planned: "",
            suggested: "",
          },
          may: {
            month_id: "",
            current: "",
            planned: "",
            suggested: "",
          },
          june: {
            month_id: "",
            current: "",
            planned: "",
            suggested: "",
          },
          post_season: {
            month_id: "",
            current: "",
            planned: "",
            suggested: "",
          },
          remark: {
            current: "",
            planned: "",
          },
          is_scheduled: {
            current: "",
            planned: "",
          },
          mpc_id: {
            current: "",
            planned: "",
          },
          action: {
            isEditable: "",
          },
          isRow: false,

          children: variety.branches,
        };
      });
      return tableData;
    };

    const onVarietySelected = (value) => {
      const stateList = this.state.varieties.find(
        (item) => item.variety_id === value
      ).states;

      this.setState({
        selectedVarietyID: value,
        states: stateList,
        selectedStateID: stateList[0].branch_id,
      });

      getModalData(value, stateList[0].branch_id);
    };

    const onStateSelected = (value) => {
      this.setState({ selectedStateID: value });
      getModalData(this.state.selectedVarietyID, value);
    };

    const getModalData = (variety_id, state_id) => {
      this.setModalLoaderStatus(true);
      console.log(
        `Getting data for modal :- state id : ${state_id} | variety id : ${variety_id}`
      );

      API.get(
        `/recommendations/monthly/price/qc_rules?run_month=${selectedMonthID}&state=${state_id}&variety=${variety_id}`,
      )
        .then((modalDataResponse) => {
          if (modalDataResponse.data.status) {
            console.log("modal data response : ", modalDataResponse.data.data);
            let modifiedModalData = modalDataResponse.data.data;

            modifiedModalData = {
              ...modifiedModalData,
              prem_nprem_trend: {
                qc_prem_nprem_trend:
                  modalDataResponse.data.data.prem_nprem_trend
                    .qc_prem_nprem_trend,
                xLabel: "Premium vs Non-Premium Violations",
                labels: ["Feb", "Mar", "Apr", "May", "Jun", "Post Season"],
                // modalDataResponse.data.data.prem_nprem_trend.x_axis_labels,
                // datasets: generateProperDatasetsForPremiumNonPremiumQCGraph(
                //   modalDataResponse.data.data.prem_nprem_trend.data_points,
                //   modalDataResponse.data.data.prem_nprem_trend.legend
                // ),

                dataForGraph: convertDataForBarGraph(
                  modalDataResponse.data.data.prem_nprem_trend
                  // modalDataResponse.data.data.prem_nprem_trend
                ),
              },
            };
            // modifiedModalData = {
            //   ...modifiedModalData,
            //   prem_nprem_trend: {
            //     qc_prem_nprem_trend:
            //       modalDataResponse.data.data.prem_nprem_trend
            //         .qc_prem_nprem_trend,
            //     xLabel: "Premium vs Non-Premium Violations",
            //     labels: ["Feb", "Mar", "Apr", "May", "Jun", "Post Season"],
            //     // modalDataResponse.data.data.prem_nprem_trend.x_axis_labels,
            //     datasets: generateProperDatasetsForPremiumNonPremiumQCGraph(
            //       modalDataResponse.data.data.prem_nprem_trend.data_points,
            //       modalDataResponse.data.data.prem_nprem_trend.legend
            //     ),
            //   },
            // };
            console.log("modified modaldata : ", modifiedModalData);
            this.setState({ modalData: modifiedModalData });
            this.setModalVisibility(true);
            this.setModalLoaderStatus(false);
          } else {
            this.setModalLoaderStatus(false);
            message.warning("Unable to retrieve QC rule information");
          }
        })
        .catch((err) => {
          this.setModalLoaderStatus(false);
          message.error("Unable to retrieve QC rule information");
        });
      //#endregion
    };

    return (
      <Spin spinning={this.state.isLoading}>
        <Modal
          width={1300}
          className="alert-management-modal"
          title="QC Rules"
          visible={this.state.isModalVisible}
          destroyOnClose={true}
          onOk={() => {
            console.log("submitting...");
            this.setModalVisibility(false);
          }}
          onCancel={() => this.setModalVisibility(false)}
        >
          <Spin spinning={this.state.isLoading}>
            {/* Carousel */}

            <div className="qc-monthly-modal">
              {/* Filters */}
              <div className="qc-consolidated-container">
                <div
                  className="temporal-select center box-shadow-card"
                  style={{ height: "70px" }}
                >
                  <div className="temporal-select-title">Select Variety</div>
                  <CustomSelectWeekly
                    list={this.state.varieties.map((item) => {
                      return {
                        name: item.variety_name,
                        id: item.variety_id,
                      };
                    })}
                    value={this.state.selectedVarietyID}
                    onSelect={onVarietySelected}
                  //   placeholder={`Select ${setSelectText()}`}
                  />
                </div>

                <div
                  className="temporal-select center box-shadow-card"
                  style={{ height: "70px" }}
                >
                  <div className="temporal-select-title">Select State</div>
                  <CustomSelectWeekly
                    list={this.state.states.map((item) => {
                      return {
                        name: item.state,
                        id: item.branch_id,
                      };
                    })}
                    value={this.state.selectedStateID}
                    onSelect={onStateSelected}
                  //   placeholder={`Select ${setSelectText()}`}
                  />
                </div>

                {/* Consolidated Panel */}
                {this.state.modalData ? (
                  <div className="qc-consolidated-container box-shadow-card-pressed">
                    <div className="info-box-row  ">
                      <div className="title-generic">State Trend Score</div>
                      <div
                        className="circle box-shadow-bordered"
                        style={{
                          background:
                            this.state.modalData.state_trend.qc_state_trend !==
                              null
                              ? this.state.modalData.state_trend
                                .qc_state_trend < qcThresholds.qc_state_trend
                                ? "#fe8c00"
                                : "#73de73"
                              : "grey",
                        }}
                      >
                        {/* {this.state.modalData.state_trend.qc_state_trend
                          ? this.state.modalData.state_trend.qc_state_trend
                          : "NA"} */}
                      </div>
                    </div>

                    <div className="info-box-row ">
                      <div className="title-generic">Variety Trend Score</div>
                      <div
                        className="circle box-shadow-bordered"
                        style={{
                          background:
                            this.state.modalData.variety_trend
                              .qc_variety_trend !== null
                              ? this.state.modalData.variety_trend
                                .qc_variety_trend <
                                qcThresholds.qc_variety_trend
                                ? "#fe8c00"
                                : "#73de73"
                              : "grey",
                        }}
                      >
                        {/* {this.state.modalData.variety_trend.qc_variety_trend
                          ? this.state.modalData.variety_trend.qc_variety_trend
                          : "NA"} */}
                      </div>
                    </div>

                    <div className="info-box-row ">
                      <div className="title-generic">
                        Premium Vs Non-Premium Score
                      </div>
                      <div
                        className="circle box-shadow-bordered"
                        onClick={() =>
                          console.log("modaldata", this.state.modalData)
                        }
                        style={{
                          background:
                            this.state.modalData.prem_nprem_trend
                              .qc_prem_nprem_trend !== null
                              ? this.state.modalData.prem_nprem_trend
                                .qc_prem_nprem_trend <
                                qcThresholds.qc_prem_vs_nonprem
                                ? "#fe8c00"
                                : "#73de73"
                              : "grey",
                        }}
                      >
                        {/* {this.state.modalData.variety_trend.qc_variety_trend
                          ? this.state.modalData.variety_trend.qc_variety_trend
                          : "NA"} */}
                      </div>
                    </div>

                    {selectedMonthID === 2 ? null : (
                      <div className="info-box-row ">
                        <div className="title-generic">Model Run Score</div>
                        <div
                          className="circle box-shadow-bordered"
                          style={{
                            background:
                              this.state.modalData.model_trend
                                .qc_model_trend !== null
                                ? this.state.modalData.model_trend
                                  .qc_model_trend <
                                  qcThresholds.qc_model_trend
                                  ? "#fe8c00"
                                  : "#73de73"
                                : "grey",
                          }}
                        >
                          {/* {this.state.modalData.model_trend.qc_model_trend
                          ? this.state.modalData.model_trend.qc_model_trend
                          : "NA"} */}
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <Empty />
                )}
              </div>

              {/* Graphs */}
              {this.state.modalData ? (
                <>
                  <div className="graphs-row">
                    <div className="qc-info-container">
                      <QCMonthlyVarietiesGraph
                        qcScore={
                          this.state.modalData.state_trend.qc_state_trend
                        }
                        qcLimit={qcThresholds.qc_state_trend}
                        title={
                          this.state.states.length !== 0
                            ? `State Trend - ${this.state.states.find(
                              (item) =>
                                item.branch_id ===
                                this.state.selectedStateID
                            ).state
                            }`
                            : "State Trend"
                        }
                        width={500}
                        data={
                          this.state.modalData
                            ? this.state.modalData.state_trend
                            : null
                        }
                      />
                    </div>

                    <div className="qc-info-container">
                      <QCMonthlyVarietiesGraph
                        qcScore={
                          this.state.modalData.variety_trend.qc_variety_trend
                        }
                        qcLimit={qcThresholds.qc_variety_trend}
                        title={
                          this.state.varieties.length !== 0
                            ? `Variety Trend - ${this.state.varieties.find(
                              (item) =>
                                item.variety_id ===
                                this.state.selectedVarietyID
                            ).variety_name
                            }`
                            : "Variety Trend"
                        }
                        width={500}
                        data={
                          this.state.modalData
                            ? this.state.modalData.variety_trend
                            : null
                        }
                      />
                    </div>
                  </div>

                  {selectedMonthID === 2 ? null : (
                    <div className="graphs-row">
                      <div className="qc-info-container">
                        <QCMonthlyVarietiesGraph
                          qcScore={
                            this.state.modalData.model_trend.qc_model_trend
                          }
                          qcLimit={qcThresholds.qc_model_comparison}
                          title={
                            this.state.states.length !== 0 &&
                              this.state.varieties.length !== 0
                              ? `State-Variety Trend Across Model Runs - ${this.state.states.find(
                                (item) =>
                                  item.branch_id ===
                                  this.state.selectedStateID
                              ).state
                              } & ${this.state.varieties.find(
                                (item) =>
                                  item.variety_id ===
                                  this.state.selectedVarietyID
                              ).variety_name
                              }`
                              : "State-Variety Trend Across Model Runs"
                          }
                          width={500}
                          data={
                            this.state.modalData
                              ? this.state.modalData.model_trend
                              : null
                          }
                        />
                      </div>
                    </div>
                  )}

                  <div className="qc-info-container">
                    <Col span={20}>
                      <PremiumNonPremiumGraph
                        isQC
                        dataForBarGraph={
                          this.state.modalData.prem_nprem_trend.dataForGraph
                        }
                        // height={230}
                        width={200}
                        // maintainAspectRatio={true}
                        labels={this.state.modalData.prem_nprem_trend.labels}
                        datasets={
                          this.state.modalData.prem_nprem_trend.datasets
                        }
                        xLabel={this.state.modalData.prem_nprem_trend.xLabel}
                        yLabel="Ranking"
                      />
                    </Col>
                  </div>
                </>
              ) : (
                <Empty />
              )}
            </div>
          </Spin>
        </Modal>

        <Table
          className={`temporal-table-expanded`}
          //   loading={spinnerOn}
          rowClassName={(record, index) =>
            record.action.isEditable === "" ? "variety-row" : ""
          }
          pagination={false}
          columns={[
            {
              title: <div className="state-name">State</div>,
              key: "branch",
              dataIndex: "branch",
              width: 140,
            },

            {
              title: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      color: "#FFFFFF",
                      fontSize: "14px",
                      fontWeight: "400",
                      whiteSpace: "break",
                      // marginTop: "-6px",
                    }}
                  >
                    {title}
                  </div>
                </div>
              ),
              children: [
                {
                  title: (
                    <div
                      style={{
                        color: "#FFFFFF",
                        fontSize: "12px",
                        fontWeight: "400",
                        textAlign: "right",
                      }}
                    >
                      Feb
                    </div>
                  ),

                  key: "feb",
                  dataIndex: "feb",
                  width: 80,
                  render: (feb, record) => {
                    return record.action.isEditable !== "" ? (
                      <Tooltip
                        title={`Suggested: ${feb.suggested} ${isPrice ? "₹/MT" : "MT"
                          }`}
                      >
                        <InputNumber
                          {...conditionalProps}
                          className={
                            feb.planned !== feb.suggested
                              ? "modified-input"
                              : ""
                          }
                          onBlur={(e) =>
                            onValueModified(
                              e.target.value,
                              record.variety_id,
                              record.branch_id,
                              "feb"
                            )
                          }
                          disabled={
                            record.action.isEditable &&
                              isNextMonth(feb.month_id)
                              ? false
                              : true
                          }
                          value={feb.planned}
                        />
                      </Tooltip>
                    ) : null;
                  },
                },
                {
                  title: (
                    <div
                      style={{
                        color: "#FFFFFF",
                        fontSize: "12px",
                        fontWeight: "400",
                        textAlign: "right",
                      }}
                    >
                      March
                    </div>
                  ),
                  key: "march",
                  dataIndex: "march",
                  width: 80,
                  render: (march, record) => {
                    return record.action.isEditable !== "" ? (
                      <Tooltip
                        title={`Suggested: ${march.suggested} ${isPrice ? "₹/MT" : "MT"
                          }`}
                      >
                        <InputNumber
                          {...conditionalProps}
                          className={
                            march.planned !== march.suggested
                              ? "modified-input"
                              : ""
                          }
                          onBlur={(e) =>
                            onValueModified(
                              e.target.value,
                              record.variety_id,
                              record.branch_id,
                              "march"
                            )
                          }
                          disabled={
                            record.action.isEditable &&
                              isNextMonth(march.month_id)
                              ? false
                              : true
                          }
                          value={march.planned}
                        />
                      </Tooltip>
                    ) : null;
                  },
                },
                {
                  title: (
                    <div
                      style={{
                        color: "#FFFFFF",
                        fontSize: "12px",
                        fontWeight: "400",
                        textAlign: "right",
                      }}
                    >
                      April
                    </div>
                  ),
                  key: "april",
                  dataIndex: "april",
                  width: 80,
                  render: (april, record) => {
                    return record.action.isEditable !== "" ? (
                      <Tooltip
                        title={`Suggested: ${april.suggested} ${isPrice ? "₹/MT" : "MT"
                          }`}
                      >
                        <InputNumber
                          {...conditionalProps}
                          className={
                            april.planned !== april.suggested
                              ? "modified-input"
                              : ""
                          }
                          onBlur={(e) =>
                            onValueModified(
                              e.target.value,
                              record.variety_id,
                              record.branch_id,
                              "april"
                            )
                          }
                          disabled={
                            record.action.isEditable &&
                              isNextMonth(april.month_id)
                              ? false
                              : true
                          }
                          value={april.planned}
                        />
                      </Tooltip>
                    ) : null;
                  },
                },
                {
                  title: (
                    <div
                      style={{
                        color: "#FFFFFF",
                        fontSize: "12px",
                        fontWeight: "400",
                        textAlign: "right",
                      }}
                    >
                      May
                    </div>
                  ),
                  key: "may",
                  dataIndex: "may",
                  width: 80,
                  render: (may, record) => {
                    return record.action.isEditable !== "" ? (
                      <Tooltip
                        title={`Suggested: ${may.suggested} ${isPrice ? "₹/MT" : "MT"
                          }`}
                      >
                        <InputNumber
                          {...conditionalProps}
                          className={
                            may.planned !== may.suggested
                              ? "modified-input"
                              : ""
                          }
                          onBlur={(e) =>
                            onValueModified(
                              e.target.value,
                              record.variety_id,
                              record.branch_id,
                              "may"
                            )
                          }
                          disabled={
                            record.action.isEditable &&
                              isNextMonth(may.month_id)
                              ? false
                              : true
                          }
                          value={may.planned}
                        />
                      </Tooltip>
                    ) : null;
                  },
                },

                {
                  title: (
                    <div
                      style={{
                        color: "#FFFFFF",
                        fontSize: "12px",
                        fontWeight: "400",
                        textAlign: "right",
                      }}
                    >
                      June
                    </div>
                  ),
                  key: "june",
                  dataIndex: "june",
                  width: 80,
                  render: (june, record) => {
                    return record.action.isEditable !== "" ? (
                      <Tooltip
                        title={`Suggested: ${june.suggested} ${isPrice ? "₹/MT" : "MT"
                          }`}
                      >
                        <InputNumber
                          {...conditionalProps}
                          className={
                            june.planned !== june.suggested
                              ? "modified-input"
                              : ""
                          }
                          onBlur={(e) =>
                            onValueModified(
                              e.target.value,
                              record.variety_id,
                              record.branch_id,
                              "june"
                            )
                          }
                          disabled={
                            record.action.isEditable &&
                              isNextMonth(june.month_id)
                              ? false
                              : true
                          }
                          value={june.planned}
                        />
                      </Tooltip>
                    ) : null;
                  },
                },
                {
                  title: (
                    <div
                      style={{
                        color: "#FFFFFF",
                        fontSize: "12px",
                        fontWeight: "400",
                        textAlign: "right",
                      }}
                    >
                      Post Season
                    </div>
                  ),
                  key: "post_season",
                  dataIndex: "post_season",
                  width: 100,
                  render: (post_season, record) => {
                    return record.action.isEditable !== "" ? (
                      <Tooltip
                        title={`Suggested: ${post_season.suggested} ${isPrice ? "₹/MT" : "MT"
                          }`}
                      >
                        <InputNumber
                          {...conditionalProps}
                          className={
                            post_season.planned !== post_season.suggested
                              ? "modified-input"
                              : ""
                          }
                          onBlur={(e) =>
                            onValueModified(
                              e.target.value,
                              record.variety_id,
                              record.branch_id,
                              "post_season"
                            )
                          }
                          disabled={
                            record.action.isEditable &&
                              isNextMonth(post_season.month_id)
                              ? false
                              : true
                          }
                          value={post_season.planned}
                        />
                      </Tooltip>
                    ) : null;
                  },
                },
              ],
            },
            {
              title: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <div className="state-name">{"Override"}</div>
                </div>
              ),
              key: "action",
              dataIndex: "action",
              width: 85,
              render: (value, record) => {
                return record.action.isEditable !== "" ? (
                  <>
                    {record.action.isEditable ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          margin: "0 0 0 -41px",
                        }}
                      >
                        <CustomButton
                          isPencilImage={false}
                          src={TickImage}
                          onClick={() =>
                            onTickButtonClicked(
                              record.variety_id,
                              record.branch_id
                            )
                          }
                        />
                        <CustomButton
                          isPencilImage={false}
                          src={CloseImage}
                          onClick={() => {
                            resetRow(record.variety_id, record.branch_id);
                          }}
                        />
                      </div>
                    ) : (
                      <CustomButton
                        disabled={
                          isDisabled
                            ? true
                            : isPrice
                              ? record.is_scheduled.planned
                              : isConstrained
                                ? false
                                : true
                        }
                        isPencilImage={true}
                        src={EditImage}
                        onClick={() =>
                          changeEditStatus(
                            record.variety_id,
                            record.branch_id,
                            true
                          )
                        }
                      />
                    )}
                  </>
                ) : null;
              },
            },
            {
              title: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <div className="state-name">{"QC Log"}</div>
                </div>
              ),
              key: "remark",
              dataIndex: "remark",
              render: (remark, record) => {
                // console.log("record : ", record);
                return record.action.isEditable !== "" ? (
                  <Input.TextArea
                    allowClear
                    autoSize={{ minRows: 1, maxRows: 5 }}
                    key="remark"
                    onChange={(e) =>
                      onValueModified(
                        e.target.value,
                        record.variety_id,
                        record.branch_id,
                        null
                      )
                    }
                    disabled={record.action.isEditable ? false : true}
                    value={remark.planned}
                  />
                ) : null;
              },
            },
            { ...finalConditionalColumns },
          ]}
          dataSource={setTemporalTableData()}
          style={{ width: "99.3%" }}
          {...tableProps}
        />
      </Spin>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  allVarietyInfo: selectAllVarietyInfo,
});

export default connect(mapStateToProps, null)(TemporalExpandableTable);
