import React, { Component } from "react";
import "./arrival-category-list-item-for.styles.css";
import moment from "moment";

import priceIcon from "../../assets/Gr_Price.svg";
import quantityIcon from "../../assets/Gr_Quantity.svg";
import editIcon from "../../assets/edit.svg";
import closeIcon from "../../assets/close.svg";

import ArrivalCardValueSlider from "../arrival-card-value-slider/arrival-card-value-slider.component";
import ArrivalsCompetitorItem from "../arrivals-competitor-item/arrivals-competitor-item.component";
import QualityValueSlider from "../quality-value-slider/quality-value-slider.component";
import NumericInput from "../numeric-input/numeric-input.component";
import NewVarietySelect from "../new-variety-select/new-variety-select.component";

import { Card, message, Button, Modal, Spin, Input } from "antd";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectUserData } from "../../redux/user/user.selectors";

import {
  setBranches,
  setRegions,
  setHubs,
  setMandis,
  setBranchID,
  setRegionID,
  setHubID,
  setMandiID,
} from "../../redux/arrival-entry/arrival-entry.actions";
import {
  selectSummaryScreenVisibility,
  selectBranches,
  selectRegions,
  selectHubs,
  selectMandis,
  selectBranchID,
  selectRegionID,
  selectHubID,
  selectMandiID,
} from "../../redux/arrival-entry/arrival-entry.selectors";

import { selectCurrentArrivalScreen } from "../../redux/map/map.selectors";

import API from "../../api";

import { DeleteDataFromCard } from "../../pages/arrivals-entry/arrivals-entry.page";

const { TextArea } = Input;

/**
 * @description Single card item in Arrivals entry page for FOR.
 * @component
 * @extends {Component}
 */
class ArrivalCategoryListItemFOR extends Component {
  modalCardSliderProps = {
    image: priceIcon,
    title: "Landing Cost",
    unitText: "₹/Qntl",
  };
  quantityCardSliderProps = {
    image: quantityIcon,
    title: "Quoted Quantity",
    unitText: "Qntl",
  };
  state = {
    isEnable: true,
    cardSpin: false,
    main: {
      Price: "",
      MinPrice: "",
      MaxPrice: "",
      ArrivalsQuantity: "",
      Quality: "Average",
      Remarks: "",
    },
    competitor: [
      {
        competitor_bid_id:null,
        competitor_name: "",
        competitor_min_price: null,
        competitor_max_price: null,
        purchase_plan: null,
      },
      {
        competitor_bid_id:null,
        competitor_name: "",
        competitor_min_price: null,
        competitor_max_price: null,
        purchase_plan: null,
      },
      {
        competitor_bid_id:null,
        competitor_name: "",
        competitor_min_price: null,
        competitor_max_price: null,
        purchase_plan: null,
      },
      {
        competitor_bid_id:null,
        competitor_name: "",
        competitor_min_price: null,
        competitor_max_price: null,
        purchase_plan: null,
      },
      {
        competitor_bid_id:null,
        competitor_name: "",
        competitor_min_price: null,
        competitor_max_price: null,
        purchase_plan: null,
      },
    ],
    competitorsList: []
  };

  componentDidMount() {
    this.getCompetitorsData();
    let isData =
      this.props.cardData !== null
        ? this.props.cardData.length !== 0
          ? true
          : false
        : false;

    if (isData && this.state.isEnable) {
      var comp = this.state.competitor;

      if (this.props.cardData[0].competitor_bids.length !== 0) {
        for (
          var i = 0;
          i < this.props.cardData[0].competitor_bids.length;
          i++
        ) {
          comp[i].competitor_bid_id = this.props.cardData[0].competitor_bids[
            i
          ].competitor_bid_id;
          comp[i].competitor_name = this.props.cardData[0].competitor_bids[
            i
          ].competitor_name;
          comp[i].competitor_min_price = this.props.cardData[0].competitor_bids[
            i
          ].competitor_min_price;
          comp[i].competitor_max_price = this.props.cardData[0].competitor_bids[
            i
          ].competitor_max_price;
          comp[i].purchase_plan = this.props.cardData[0].competitor_bids[
            i
          ].purchase_plan;
        }
      }
      this.setState({ isEnable: false, competitor: comp });
      isData = false;
    }
  }

  getCompetitorsData = async () => {
    const COMPETITORS_DATA_ENDPOINT = `/master_competitor`
    await API.get(COMPETITORS_DATA_ENDPOINT, {

    })
      .then((CompetitorsData) => {
        if (CompetitorsData.data && CompetitorsData.data.data && CompetitorsData.data.data.length) {
          if (CompetitorsData.data.data) {
            this.setState({ competitorsList: CompetitorsData.data.data });
          }
        }
      })
      .catch((err) => {
      });
  }

  render() {
    const setSubmitModalVisibility = (status) => {
      this.setState({ isSubmitModalVisible: status });
    };
    const handleCancel = () => {
      this.setState({ isModalVisible: false });
      this.setState({ isSubmitModalVisible: false });
    };
    const {
      varietyName,
      varietyMinRange,
      varietyMaxRange,
      varietyID,
      branchID,
      regionID,
      hubID,
      mandiID,
      currentScreen,
      cardData,
      remainingVarietyList,
      onSelectNewVariety,
      getPreviousData,
    } = this.props;

    //#region  previous data handling

    const isPreviousDataPresent =
      cardData !== null ? (cardData.length !== 0 ? true : false) : false;
    // if (isPreviousDataPresent) console.log("card data mandi : ", cardData[0]);
    //#endregion

    /**
     * @description Function to handle submission click which validate and post arrival entry.
     * @memberof ArrivalCategoryListItemFOR
     */
    const success = () => {
      setSubmitModalVisibility(false);
      const getPriceType = (currentScreen) => {
        switch (currentScreen) {
          case "Mandi":
            return "Modal Price";
          case "FOR":
            return "TLC";
          case "Farmer":
            return "Naked Grain Price";
          default:
            break;
        }
      };

      const postData = {
        arrival_entries: [
          {
            crop_arrival_id:(this.props.cardData!== undefined && this.props.cardData!== null && this.props.cardData!== [] && this.props.cardData.length !== 0)?this.props.cardData[0].crop_arrival_id:null,
            branch_id: parseInt(branchID),
            region_id: parseInt(regionID),
            hub_id: parseInt(hubID),
            mandi_id: parseInt(mandiID),
            //Arrival date
            arrival_date: moment().format("YYYY-MM-DD"),
            arrival_type: currentScreen,
            variety_id: parseInt(varietyID),
            price_type: getPriceType(currentScreen),
            price: parseInt(this.state.main.Price),
            min_price: parseInt(this.state.main.MinPrice),
            max_price: parseInt(this.state.main.MaxPrice),
            arrivals_quantity: parseInt(this.state.main.ArrivalsQuantity),
            quality: this.state.main.Quality,
            good_quality: null,
            average_quality: null,
            poor_quality: null,
            remark: this.state.main.Remarks,
            competitor: this.state.competitor.map((item) => {
              if (
                item.competitor_name !== "" &&
                item.competitor_max_price != null &&
                item.competitor_min_price != null
              ) {
              return {
                competitor_bid_id: item.competitor_bid_id,
                comp_name: item.competitor_name,
                comp_min_price: item.competitor_min_price,
                comp_max_price: item.competitor_max_price,
                purchase_plan: item.purchase_plan,
              };
            }
            })
            .filter((item) => item !== undefined),
            // cardData[0].competitor_bids.map((item) => {
            //   return {
            //     comp_id: 1,
            //     comp_name: item.competitor_name,
            //     comp_min_price: item.competitor_min_price,
            //     comp_max_price: item.competitor_max_price,
            //     purchase_plan: item.purchase_plan,
            //   };
            // }),
            // competitor: [
            //   {
            //     // comp_id: 1,
            //     comp_name: this.state.competitor.name,
            //     comp_min_price: this.state.competitor.MinPrice,
            //     comp_max_price: this.state.competitor.MaxPrice,
            //     purchase_plan: this.state.competitor.PurchasePlan,
            //   },
            // ],
          },
        ],
      };

      const postHeaders = {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      };

      const checkForValidation = () => {

        if (isNaN(postData.arrival_entries[0].variety_id)) {
          Modal.error({ content: "Please select a variety !" });
          return false;
        }

        if (!this.state.main.Price) {
          Modal.error({ content: "Please enter the Landing cost !" });
          return false;
        }
        if (!this.state.main.MinPrice) {
          Modal.error({ content: "Please enter the minimum price !" });
          return false;
        }
        if (!this.state.main.MaxPrice) {
          Modal.error({ content: "Please enter the maximum price !" });
          return false;
        }

        if (this.state.main.MinPrice >= this.state.main.MaxPrice) {
          Modal.error({
            content: "Maximum price must be greater than minimum price !",
          });
          return false;
        }

        // if (!this.state.main.ArrivalsQuantity) {
        //   Modal.error({ content: "Please enter the quoted quantity !" });
        //   return false;
        // }

        if (!this.state.main.Quality) {
          Modal.error({
            content: "Please select the quality !",
          });
          return false;
        }

        // if (!this.state.competitor.name) {
        //   Modal.error({ content: "Please enter the competitor name !" });
        //   return false;
        // }

        // this.state.competitor.map((item) => {

        for (var i = 0; i < this.state.competitor.length; i++) {
          if (this.state.competitor[i].competitor_name !== "") {
            if (!this.state.competitor[i].competitor_min_price) {
              Modal.error({
                content: "Please enter the competitor minimum price !",
              });
              return false;
            }
            if (!this.state.competitor[i].competitor_max_price) {
              Modal.error({
                content: "Please enter the competitor maximum price !",
              });
              return false;
            }

            if (
              this.state.competitor[i].competitor_min_price >=
              this.state.competitor[i].competitor_max_price
            ) {
              Modal.error({
                content:
                  "Maximum competitor price must be greater than minimum price !",
              });
              return false;
            }
            // if (!this.state.competitor.PurchasePlan) {
            //   Modal.error({
            //     content: "Please enter the competitor purchase plan !"
            //   });
            //   return false;
            // }
          } else {
            if (
              this.state.competitor[i].competitor_min_price != null ||
              this.state.competitor[i].competitor_max_price != null ||
              this.state.competitor[i].purchase_plan != null
            ) {
              Modal.error({
                content: "Please enter the competitor name !",
              });
              return false;
            }
          }
        }

        // if (this.state.competitor.name) {
        //   if (!this.state.competitor.MinPrice) {
        //     Modal.error({
        //       content: "Please enter the competitor minimum price !",
        //     });
        //     return false;
        //   }
        //   if (!this.state.competitor.MaxPrice) {
        //     Modal.error({
        //       content: "Please enter the competitor maximum price !",
        //     });
        //     return false;
        //   }

        //   if (
        //     this.state.competitor.MinPrice >= this.state.competitor.MaxPrice
        //   ) {
        //     Modal.error({
        //       content:
        //         "Maximum competitor price must be greater than minimum price !",
        //     });
        //     return false;
        //   }
        //   // if (!this.state.competitor.PurchasePlan) {
        //   //   Modal.error({
        //   //     content: "Please enter the competitor purchase plan !"
        //   //   });
        //   //   return false;
        //   // }
        // }

        // if (
        //   this.state.competitor.MinPrice ||
        //   this.state.competitor.MaxPrice ||
        //   this.state.competitor.PurchasePlan
        // ) {
        //   if (
        //     this.state.competitor.name.length === 0 ||
        //     !this.state.competitor.name.trim()
        //   ) {
        //     Modal.error({
        //       content: "Please enter the competitor name !",
        //     });
        //     return false;
        //   }
        // }

        // if (this.state.competitor.MinPrice && this.state.competitor.MaxPrice) {
        //   if (this.state.competitor.MinPrice > this.state.competitor.MaxPrice) {
        //     Modal.error({
        //       content:
        //         "Maximum competitor price should be more than the competitor minimum price !",
        //     });
        //     return false;
        //   }
        // }

        return true;
      };

      if (checkForValidation()) {
        this.setState({ isEnable: false, cardSpin: true });
        API.post("/arrivals", postData)
          .then((response) => {
            if (response.data.status) {
              message.success("Data saved !", 2.5);
              // getPreviousData();
            } else {
              //message.error("Please try again");
              message.error(response.data.message,2.5);
              this.setState({ isEnable: true });
            }
            this.setState({ cardSpin: false });
          })
          .catch((err) => {
            message.error("Please try again");
            this.setState({ isEnable: true, cardSpin: false });
          });
      }
    };

    /**
     *
     * @description Change the value for [typeVariety]:{[namevariety] :'value'} in the parent state.
     * @param {string} typeVariety Parent object in state (main/competitor)
     * @param {string} nameVariety Key in parent object
     * @param {string} val Value of curesponding key
     * @param {string} comp_index Competitor index value if type variety is competitor
     * @memberof ArrivalCategoryListItemFOR
     */
    const setParentState = (typeVariety, nameVariety, val, comp_index) => {
      var competitor = this.state.competitor;
      if (comp_index != null) {
        competitor[comp_index] = {
          ...competitor[comp_index],
          [nameVariety]: val,
        };
        this.setState((prevState) => ({
          ...prevState,
          competitor: competitor,
        }));
      } else {
        this.setState((prevState) => ({
          ...prevState,

          [typeVariety]: { ...prevState[typeVariety], [nameVariety]: val },
        }));
      }
    };

    /**
     * @description Enable card item for editing.
     * @memberof ArrivalCategoryListItemFOR
     */
    const onEditClicked = () => {
      const data =
        cardData.length !== 0
          ? {
            main: {
              Price: cardData[0].price,
              MinPrice: cardData[0].min_price,
              MaxPrice: cardData[0].max_price,
              ArrivalsQuantity: cardData[0].quantity,
              Quality: cardData[0].quality,
              Remarks: cardData[0].remark,
            },
          }
          : this.state;

      this.setState((prevState) => ({
        ...prevState,
        isEnable: true,
        main: data.main,
      }));
    };

    /**
     * @description Clears the existing data from the card.
     * @memberof ArrivalCategoryListItemMandi
     */
    const onDeleteClicked = () => {
      setModalVisibility(false);
      // call delete data api, then
      DeleteDataFromCard(
        branchID,
        hubID,
        null,
        varietyID,
        false,
        true,
        getPreviousData,
        (status) => this.setState({ cardSpin: status })
      );
    };

    const setModalVisibility = (status) => {
      this.setState({ isModalVisible: status });
    };

    return (
      <>
        <Modal
          className="temporal-modal"
          title={<div style={{ color: "white" }}>Confirmation</div>}
          visible={this.state.isModalVisible}
          onOk={() => onDeleteClicked()}
          onCancel={() => setModalVisibility(false)}
          cancelButtonProps={{
            style: { backgroundColor: "#cbcbcb !important" },
          }}
        >
          <div style={{ color: "black" }}>
            You are about to clear the entries for this variety for today. Are
            you sure you want to submit ?
          </div>
        </Modal>
        {isPreviousDataPresent && (
          <Spin
            style={{ background: "#a5a5be0f" }}
            spinning={this.state.cardSpin}
          >
            <div>
              <div className="arrival-card-title">
                <div>{varietyName}</div>
                {this.state.isEnable ? null : (
                  <div className="button-container">
                    <div
                      className="edit-button"
                      style={{ color: "#d7443e" }}
                      onClick={() => setModalVisibility(true)}
                    >
                      <img alt="delete" className="edit-icon" src={closeIcon} />
                      Delete
                    </div>
                    <div
                      className="edit-button"
                      onClick={() => onEditClicked()}
                    >
                      <img alt="edit" className="edit-icon" src={editIcon} />
                      Edit
                    </div>
                  </div>
                )}
              </div>

              <div
                className={`arrival-card-container ${this.state.isEnable
                  ? "arrival-card-container"
                  : "arrival-card-container-disabled"
                  }`}
              >
                {varietyName && <Card>
                  <ArrivalCardValueSlider
                    minRange={varietyMinRange}
                    maxRange={varietyMaxRange}
                    defaultValue={
                      isPreviousDataPresent ? cardData[0].price : ""
                    }
                    typeVariety="main"
                    nameVariety="Price"
                    setParentState={setParentState}
                    {...this.modalCardSliderProps}
                  />
                  <div className="modal-price-min-max">
                    <div className="min-max-field-container">
                      <div className="super-text-left">Min</div>
                      <NumericInput
                        minRange={varietyMinRange}
                        maxRange={varietyMaxRange - 1}
                        defaultValue={
                          isPreviousDataPresent ? cardData[0].min_price : ""
                        }
                        unitText={"₹/Qntl"}
                        typeVariety="main"
                        nameVariety="MinPrice"
                        setParentState={setParentState}
                      />
                    </div>
                    <h4>to</h4>
                    <div className="min-max-field-container">
                      <div className="super-text-left">Max</div>
                      <NumericInput
                        minRange={varietyMinRange + 1}
                        maxRange={varietyMaxRange}
                        defaultValue={
                          isPreviousDataPresent ? cardData[0].max_price : ""
                        }
                        unitText={"₹/Qntl"}
                        typeVariety="main"
                        nameVariety="MaxPrice"
                        setParentState={setParentState}
                      />
                    </div>
                  </div>
                  <ArrivalCardValueSlider
                    defaultValue={
                      isPreviousDataPresent ? cardData[0].quantity : ""
                    }
                    typeVariety="main"
                    nameVariety="ArrivalsQuantity"
                    setParentState={setParentState}
                    {...this.quantityCardSliderProps}
                  />
                  <QualityValueSlider
                    quality={
                      isPreviousDataPresent ? cardData[0].quality : "Average"
                    }
                    qualityGood={
                      isPreviousDataPresent ? cardData[0].good_quality : 0
                    }
                    qualityAverage={
                      isPreviousDataPresent ? cardData[0].average_quality : 100
                    }
                    qualityPoor={
                      isPreviousDataPresent ? cardData[0].poor_quality : 0
                    }
                    typeVariety="main"
                    nameVariety="Quality"
                    setParentState={setParentState}
                    isDropdown
                  />
                  <div className="textarea-container">
                    Remarks
                    <TextArea
                      rows={4}
                      autoSize={{ minRows: 4, maxRows: 4 }}
                      showCount
                      maxLength={100}
                      placeholder="Enter your remarks..."
                      defaultValue={
                        isPreviousDataPresent ? cardData[0].remark : ""
                      }
                      onChange={(e) => {
                        e.persist();
                        this.setState((prevState) => ({
                          ...prevState,
                          main: {
                            ...prevState.main,
                            Remarks: e.target.value,
                          },
                        }));
                      }}
                    />
                  </div>
                  {/* <div className="remark-text-container">
          <div className="remark-title">+ ADD REMARK</div>
          <Input />
        </div> */}
                </Card>}

                {varietyName && <div className="bottom-container">
                  <Card>
                    <ArrivalsCompetitorItem
                      varietyMinRange={varietyMinRange}
                      varietyMaxRange={varietyMaxRange}
                      competitorData={
                        isPreviousDataPresent &&
                          cardData[0].competitor_bids.length !== 0
                          ? cardData[0].competitor_bids
                          : this.state.competitor
                      }
                      // competitorData={
                      //   isPreviousDataPresent &&
                      //   this.state.competitor.length !== 0
                      //     ? this.state.competitor
                      //     : null
                      // }
                      typeVariety="competitor"
                      nameVariety="Quality"
                      setParentState={setParentState}
                      competitorsList={this.state.competitorsList}
                    />
                  </Card>
                </div>}
                {(this.state.main.ArrivalsQuantity > 10000) ? <Button onClick={(e) => {
                  setSubmitModalVisibility(true);
                }}>SUBMIT</Button> : <Button onClick={success}>SUBMIT</Button>}
              </div>
            </div>
          </Spin>
        )}
        {!isPreviousDataPresent && (
          <Spin
            style={{ background: "#a5a5be0f" }}
            spinning={this.state.cardSpin}
          >
            <div>
              <div className="arrival-card-title">
                {varietyName ? (
                  <div>{varietyName}</div>
                ) : (
                  <NewVarietySelect
                    onSelectNewVariety={onSelectNewVariety}
                    list={remainingVarietyList}
                    tempVarietyID={varietyID}
                  />
                )}
                {this.state.isEnable ? null : (
                  <div className="button-container">
                    <div
                      className="edit-button"
                      style={{ color: "#d7443e" }}
                      onClick={() => setModalVisibility(true)}
                    >
                      <img alt="delete" className="edit-icon" src={closeIcon} />
                      Delete
                    </div>
                    <div
                      className="edit-button"
                      onClick={() => onEditClicked()}
                    >
                      <img alt="edit" className="edit-icon" src={editIcon} />
                      Edit
                    </div>
                  </div>
                )}
              </div>

              <div
                className={`arrival-card-container ${this.state.isEnable
                  ? "arrival-card-container"
                  : "arrival-card-container-disabled"
                  } `}
              >
                {varietyName && <Card>
                  <ArrivalCardValueSlider
                    minRange={varietyMinRange}
                    maxRange={varietyMaxRange}
                    defaultValue={""}
                    typeVariety="main"
                    nameVariety="Price"
                    setParentState={setParentState}
                    {...this.modalCardSliderProps}
                  />
                  <div className="modal-price-min-max">
                    <div className="min-max-field-container">
                      <div className="super-text-left">Min</div>
                      <NumericInput
                        minRange={varietyMinRange}
                        maxRange={varietyMaxRange - 1}
                        defaultValue={""}
                        unitText={"₹/Qntl"}
                        typeVariety="main"
                        nameVariety="MinPrice"
                        setParentState={setParentState}
                      />
                    </div>
                    <h4>to</h4>
                    <div className="min-max-field-container">
                      <div className="super-text-left">Max</div>
                      <NumericInput
                        minRange={varietyMinRange + 1}
                        maxRange={varietyMaxRange}
                        defaultValue={""}
                        unitText={"₹/Qntl"}
                        typeVariety="main"
                        nameVariety="MaxPrice"
                        setParentState={setParentState}
                      />
                    </div>
                  </div>
                  <ArrivalCardValueSlider
                    defaultValue={""}
                    typeVariety="main"
                    nameVariety="ArrivalsQuantity"
                    setParentState={setParentState}
                    {...this.quantityCardSliderProps}
                  />
                  <QualityValueSlider
                    quality={"Average"}
                    qualityGood={0}
                    qualityAverage={100}
                    qualityPoor={0}
                    typeVariety="main"
                    nameVariety="Quality"
                    setParentState={setParentState}
                    isDropdown
                  />
                  <div className="textarea-container">
                    Remarks
                    <TextArea
                      rows={4}
                      showCount
                      maxLength={100}
                      autoSize={{ minRows: 4, maxRows: 4 }}
                      placeholder="Enter your remarks..."
                      value={this.state.main.Remarks}
                      onChange={(e) => {
                        e.persist();
                        this.setState(
                          (prevState) => ({
                            ...prevState,
                            main: {
                              ...prevState.main,
                              Remarks: e.target.value,
                            },
                          }),
                          () =>
                            console.log("Remarks: ", this.state.main.Remarks)
                        );
                      }}
                    />
                  </div>
                  {/* <div className="remark-text-container">
          <div className="remark-title">+ ADD REMARK</div>
          <Input />
         </div> */}
                </Card>}

                <div className="bottom-container">
                  {varietyName && <Card>
                    <ArrivalsCompetitorItem
                      varietyMinRange={varietyMinRange}
                      varietyMaxRange={varietyMaxRange}
                      competitorData={null}
                      typeVariety="competitor"
                      nameVariety="Quality"
                      setParentState={setParentState}
                      competitorsList={this.state.competitorsList}
                    />
                  </Card>}
                </div>
                {varietyName && (this.state.main.ArrivalsQuantity > 10000) ? <Button onClick={(e) => {
                  setSubmitModalVisibility(true);
                }}>SUBMIT</Button> : varietyName && <Button onClick={success}>SUBMIT</Button>}
              </div>
            </div>
          </Spin>
        )}
        <Modal
          title="Confirm!"
          classname="submit-modal"
          visible={this.state.isSubmitModalVisible}
          footer={false}
          onCancel={handleCancel}
        // style={{ textAlign: "center"  , height:"200px", width: "400px"}}
        >
          {
            <>
              <p style={{ color: "#262626" }}>

                The Arrival Qty ({this.state.main.ArrivalsQuantity}) entered seems higher than usual. Are you ok to proceed?

              </p>
              <div className="btn-container-freeze" >
                <Button
                  onClick={success}
                  className="btn-container"
                  style={{
                    marginLeft: "15px",
                    padding: "2px 8px !important",
                    backgroundColor: "#20346a",
                    width: "fit-content !important",
                    borderRadius: "20px",
                    color: "white",
                    float: "right",
                  }}
                >
                  {" "}
                  Proceed{" "}
                </Button>
              </div>
            </>
          }
        </Modal>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setBranches: (branches) => dispatch(setBranches(branches)),
    setRegions: (regions) => dispatch(setRegions(regions)),
    setHubs: (hubs) => dispatch(setHubs(hubs)),
    setMandis: (mandis) => dispatch(setMandis(mandis)),
    setBranchID: (id) => dispatch(setBranchID(id)),
    setRegionID: (id) => dispatch(setRegionID(id)),
    setHubID: (id) => dispatch(setHubID(id)),
    setMandiID: (id) => dispatch(setMandiID(id)),
  };
};

const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
  currentScreen: selectCurrentArrivalScreen,
  summaryIsEnabled: selectSummaryScreenVisibility,
  branches: selectBranches,
  regions: selectRegions,
  hubs: selectHubs,
  mandis: selectMandis,
  branchID: selectBranchID,
  regionID: selectRegionID,
  hubID: selectHubID,
  mandiID: selectMandiID,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArrivalCategoryListItemFOR);
