import React from "react";
import "./variety-master.styles.css";
import { Component } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUserData ,selectMSMessageRBAC} from "../../redux/user/user.selectors";
import { canUserEdit,getUserMSRBACMessage } from "../../auth/utils";
import { FeatureCodes } from "../../auth/data";
import {
  Table,
  Spin,
  Input,
  Select,
  Button,
  message,
  Form,
  Modal,
  DatePicker,
  Radio,
} from "antd";
import moment from "moment";
import CustomDropdownChooseRegion from "../../components/custom-dropdown-choose-region/custom-dropdown-choose-region.component";

import API from "../../api";

const { Search } = Input;
const { Option } = Select;
/**
 * @description Component for user management tab
 *
 * @component
 */
class VarietyMaster extends Component {
  state = {
    isModalVisible: false,
    isAdd: false,
    currentRecord: null,
    userBranches: null,
    searchText: null,
    searchUserType: 0,
    varietyTableData: [],
    primaryHubList: [],
    hubRegionList: [],
    mandiRegionList: [],
    mandiBranchList: [],
    isSpinnerOnLoad: false,
    isSubmitted: false,
    newData: {
      variety_description: "",
      variety_id: undefined,
      variety_name: "",
      variety_price_max: undefined,
      variety_price_min: undefined,
      is_premium:false,
      isEdit: false,
    },
    rowData: undefined,
    selectedRegionValue: undefined,
    selectedRegionName: "",
    selectedBranchValue: undefined,
    selectedBranchname: "",
    selectedBranchList: [],
    selectedRegionList: [],
    recordCount: 0,
  };
  componentDidMount() {
    this.setState({ isSpinnerOnLoad: true });
    this.getMasterVariety();
  }

  getMasterVariety = async () => {
    this.setState({ isSpinnerOnLoad: true });


    const params = {
      // hub_id:
      //   this.state.selectedBranchValue === null
      //     ? null
      //     : [this.state.selectedBranchValue],
      region_id:
        this.state.selectedRegionValue === null
          ? null
          : [this.state.selectedRegionValue],
    };

    await API.get("/master_variety", { params: params })
      .then((varietyResponse) => {
        // if (varietyResponse.data.status && varietyResponse.data.data && varietyResponse.data.data.length) {

        let modifiedData = varietyResponse.data.data.map((item, index) => {
          return {
            ...item,
            isEdit: false,
            key: index,
          };
        });
        this.setState(
          {
            varietyTableData: modifiedData,
          },
          () => this.setState({ isSpinnerOnLoad: false, isAdd: true })
        );
        // } else {
        //   this.setState({
        //     varietyTableData: varietyResponse.data.data,
        //   }, () => this.setState({ isSpinnerOnLoad: false, isAdd: true })
        //   );
        // }
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });

  };

  setTableData = () => {
    if (
      this.state.varietyTableData !== undefined ||
      this.state.varietyTableData.length >= 0
    ) {
      let userTableData = this.state.varietyTableData.map((user, index) => {
        return {
          key: user.key,
          variety_description: user.variety_description,
          variety_id: user.variety_id,
          variety_name: user.variety_name,
          variety_price_max: user.variety_price_max,
          variety_price_min: user.variety_price_min,
          is_premium:user.is_premium,
          isEdit: user.isEdit,
        };
      });

      let dataAfterSearch = userTableData;

      if (this.state.searchText) {
        dataAfterSearch = dataAfterSearch.filter(
          (item) =>
            (item.variety_name !== null &&
              item.variety_name
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase())) ||
            (item.variety_description !== null &&
              item.variety_description
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase()))
        );
      }

      return dataAfterSearch;
    } else {
      return this.state.varietyTableData;
    }
  };

  TableColumnHeader = (props) => {
    const { title, subtitle } = props;
    return (
      <div className="column-header-container">
        <div
          style={{
            color: "white",
            fontSize: "12px",
            whiteSpace: "nowrap",
            fontWeight: "normal",
            textAlign: "left",
          }}
        >
          {title}
        </div>
        <div
          style={{
            color: "#cbdfff",
            fontSize: "11px",
            fontWeight: "normal",
            textAlign: "left",
            // subtitle && title.length < subtitle.length ? "left" : "center"
          }}
        >
          {subtitle}
        </div>
      </div>
    );
  };

  createFilters = (label) => {
    let filterData = this.state.varietyTableData;
    //#region remove duplicate objects
    let uniqueFilterData = [];

    if (label === "is_premium" && this.state.varietyTableData && this.state.varietyTableData.length > 0) {
      uniqueFilterData = [{ text: "Yes", value: 1 }, { text: "No", value: 0 }]
    }
    else {
      filterData.map((mainItem) =>
        uniqueFilterData.filter((item) => item[label] === mainItem[label])
          .length > 0
          ? null
          : uniqueFilterData.push(mainItem)
      );

      uniqueFilterData = uniqueFilterData.map((item) => {
        return {
          text: item[label],
          value: item[label],
        };

      });

      uniqueFilterData.sort((a, b) => a.text.localeCompare(b.text))
    }

    return uniqueFilterData;
  };

  submitTableRowData = (row) => {
    if (this.validate(row)) {
      this.setState({ isSubmitted: true });

      this.setState({ isSpinnerOnLoad: true });

      let params = {
        is_premium:row.is_premium,
        variety_id: row.variety_id,
        variety_name: row.variety_name,
        variety_description: row.variety_description,
        variety_price_min:
          row.variety_price_min === "" ||
            row.variety_price_min === undefined
            ? null
            : row.variety_price_min,
        variety_price_max:
          row.variety_price_max === "" || row.variety_price_max === undefined
            ? null
            : row.variety_price_max,
      };
      API.put("/master_variety", params, {

      })
        .then((response) => {
          if (response.status === 200 && response.data.status) {
            message.success(response.data.message, 5);
            this.setModalVisibility(false);
            this.getMasterVariety();
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
          } else if (!response.data.status) {
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
            message.error(response.data.message, 5);
            // this.setModalVisibility(false);
          } else {
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
            message.error("Please try again", 5);
          }
        })
        .catch((err) => {
          if (err) {
            if (err.response && err.response.status === 400)
              message.error("Something went wrong. Please try again later.", 5);
            else {
              message.error(
                "Something went wrong.. Please try again later.",
                5
              );
            }
          } else {
            message.error("Something went wrong... Please try again later.", 5);
          }
          this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
          // this.setModalVisibility(false);
        });
    }
  };
  validate = (rowData) => {

    // if (rowData.Mandi_Min_Buying_MT === null || rowData.Mandi_Min_Buying_MT === undefined || rowData.Mandi_Min_Buying_MT === "") {
    //   message.error(`Please enter the Minimum buying price for ${rowData.variety_name},${rowData.hub_name}, ${rowData.region_name} and ${rowData.hub_region_name} !`,5);
    //   return false;
    // }
    var alphaNumericRegex = /^[a-zA-Z0-9]*$/;
    var numberRegex = /^[0-9.]+$/;

    // if (!rowData.Mandi_Min_Buying_MT.match(numberRegex)) {
    //   message.error(`Please enter valid Minimum buying price for ${rowData.variety_name},${rowData.hub_name}, ${rowData.region_name} and ${rowData.hub_region_name} !`, 5);
    //   return false;
    // }

    if (
      rowData.variety_name === null ||
      rowData.variety_name === undefined ||
      rowData.variety_name === ""
    ) {
      message.error(`Please enter Variety Name !`, 5);
      return false;
    } else if (
      rowData.variety_description === null ||
      rowData.variety_description === undefined ||
      rowData.variety_description === ""
    ) {
      message.error(`Please enter Variety Description !`, 5);
      return false;
    } else if (rowData.variety_price_min < 0) {
      message.error(
        `Min price cannot be less than 0 for ${rowData.variety_name} !`,
        5
      );
      return false;
    }
    else if (rowData.variety_price_max < 0) {
      message.error(
        `Max price cannot be less than 0 for ${rowData.variety_name} !`,
        5
      );
      return false;
    } else if (rowData.variety_price_max >= 0 && rowData.variety_price_max <= rowData.variety_price_min) {
      message.error(
        `Max price cannot be less than min price for ${rowData.variety_name} !`,
        5
      );
      return false;
    }


    return true;
  };

  setModalVisibility = (status) => {
    if (status) {
      const nData = {
        variety_description: "",
        variety_id: undefined,
        variety_name: "",
        variety_price_max: undefined,
        variety_price_min: undefined,
        is_premium:false,
        isEdit: false,
      };

      this.setState({ newData: nData });
    }

    this.setState({ isModalVisible: status });
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  handleChange = (e, row, index, name, isPopup) => {
    // let name = name;
    let a;
    var alphaNumericRegex = /^[a-zA-Z0-9]*$/;
    var numberRegex = /^[0-9.]+$/;
    if (isPopup === false) {
      a = this.state.varietyTableData;

      switch (name) {
        case "variety_name":
          a[row.key].variety_name = e.target.value;
          this.setState({ varietyTableData: a });
          return;
        case "variety_description":
          a[row.key].variety_description = e.target.value;
          this.setState({ varietyTableData: a });
          return;
        case "variety_price_min":
          if (e.target.value === "") {
            a[row.key].variety_price_min = "";
          } else if (e.target.value.match(numberRegex)) {
            a[row.key].variety_price_min = e.target.value;
          }

          this.setState({ varietyTableData: a });
          return;
        case "variety_price_max":
          if (e.target.value === "") {
            a[row.key].variety_price_max = "";
          } else if (e.target.value.match(numberRegex)) {
            a[row.key].variety_price_max = e.target.value;
          }

          this.setState({ varietyTableData: a });
          return;
        default:
          return "";
      }
    } else {

      a = this.state.newData;



      switch (name) {
        case "variety_name":
          a.variety_name = e.target.value;
          this.setState({ newData: a });
          return;
        case "variety_description":
          a.variety_description = e.target.value;
          this.setState({ newData: a });
          return;
        case "variety_price_min":
          if (e.target.value === "") {
            a.variety_price_min = "";
          } else if (e.target.value.match(numberRegex)) {
            a.variety_price_min = e.target.value;
          }

          this.setState({ newData: a });
          return;
        case "variety_price_max":
          if (e.target.value === "") {
            a.variety_price_max = "";
          } else if (e.target.value.match(numberRegex)) {
            a.variety_price_max = e.target.value;
          }

          this.setState({ newData: a });
          return;
        default:
          return "";
      }
      // this.setState({ newData: a });
    }
  };

  disabledDate = (current, rowData, text) => {
    // Can not select days before today and today
    // return current && current > moment().endOf("day");
    // if (text !== "new")
    return (
      current && current > moment(rowData.Start_Effective_Date, "YYYY-MM-DD")
    );
    // else
    //   return current && current > newData.Start_Effective_Date;
  };

  handleEditClick = (rowData, index) => {

    // let temp = this.state.varietyTableData;

    // temp[rowData.key].isEdit = true;

    let tempData = this.state.varietyTableData.map((item) => {
      if (item.key === rowData.key) {
        return {
          ...item,
          isEdit: true
        }
      } else {
        return {
          ...item,
          isEdit: false
        };
      }
    })
    this.setState({ varietyTableData: tempData });
    // this.setState({ varietyTableData: temp });
    this.setState({ rowData: rowData });
  };

  handleCancelClick = (rowData, index) => {
    let tempData = this.state.varietyTableData.map((item) => {
      if (item.variety_id === rowData.variety_id) {
        return this.state.rowData;
      } else {
        return item;
      }
    });
    this.setState({ varietyTableData: tempData });
  };

  handleDropdownChange = (value, row, target, index, optionlabel, isPopup) => {
    let a;

    if (isPopup === false) {
      a = this.state.varietyTableData;

      if (target === "is_premium") {
        a[row.key].is_premium = value;
      } 
      this.setState({ varietyTableData: a });
    } else {
      a = this.state.newData;

      if (target === "is_premium") {
        a.is_premium = value;
      } 
      this.setState({ newData: a });
    }
  };

  render() {
    const handleBranchSelect = (value, props) => {
      this.setState(
        { selectedBranchName: props.key, selectedBranchValue: value },
        () => this.getMasterVariety()
      );
    };

    const handleRegionSelect = (value, props) => {
      this.setState(
        { selectedRegionName: props.key, selectedRegionValue: value },
        () => this.getMasterVariety()
      );
    };

    const userTableProps = {
      pagination: false,
      size: "default",
      bordered: true,
      scroll: { x: 200, y: window.innerHeight - 250 },
    };

    return (
      <div
        className="mandi-master-container"
        style={{ height: window.innerHeight - 85, marginTop: "-45px" }}
      >
        <div className="top-container">

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row-reverse",
              placeItems: "flex-end",
              marginRight: "25px",
              marginBottom: "10px",
            }}
          >
            <Search
              disabled={!this.state.isAdd}
              placeholder="Search"
              allowClear
              onChange={(e) => this.setState({ searchText: e.target.value })}
              style={{ width: "25%" }}
            />
            <Button
              className="add-master-button"
              style={{
                height: "25px",
                width: "70px",
                backgroundColor: "#0b133d",
                color: "white",
              }}
              disabled={!canUserEdit(
                this.props.userData,
                null,
                FeatureCodes.MS_VARIETY_EDIT
              ) || !this.state.isAdd}
              onClick={() => this.setModalVisibility(true)}
            >
              &#10010; Add
            </Button>
          </div>
        </div>

        <div>
        <div
        style={{
          marginLeft: "25px",
          marginTop: "-8px",
          fontSize: "14px",
          color: "red",
          display: "flex",
          float: "left",
        }}
      > {getUserMSRBACMessage(this.props.messageAccess,FeatureCodes.MS_VARIETY_EDIT)}
      </div>
        <div
          style={{
            marginRight: "20px",
            marginTop: "-8px",
            fontSize: "15px",
            color: "#0B133D",
            display: "flex",
            float: "right",
          }}
        >
          Total number of records:&nbsp;
          <b>{this.state.varietyTableData && this.setTableData().length}</b>
        </div>
        </div>

        <Spin
          spinning={this.state.isSpinnerOnLoad}
          size={"large"}
          tip="Loading..."
          style={{ alignSelf: "center", zIndex: 9999, marginTop: "20px" }}
        >
          <Modal
            className="add-master-modal"
            title={"Add Variety"}
            footer={false}
            // style={{
            //   textAlign: "center",
            //   height: "400px",
            // }}
            // visible={true}
            visible={this.state.isModalVisible}
            destroyOnClose={true}
            onCancel={this.handleCancel}
          >
            <div className="master-form-container">
              <Form
                layout="horizontal"
                //   onValuesChange={onFormLayoutChange}
                size={"small"}
              // onSubmit={submitTableRowData(this.state.newData)}
              >
                <div>
                  <div
                    className="checkbox-group1"
                    style={{
                      display: "inline-block",
                      width: "45%",
                      marginRight: 7,
                    }}
                  >
                    <Form.Item label="Variety:">
                      <Input
                        style={{ marginRight: "2px" }}
                        maxLength={100}
                        required
                        value={this.state.newData.variety_name}
                        name="variety_name"
                        onChange={(e) =>
                          this.handleChange(
                            e,
                            this.state.newData,
                            -0,
                            "variety_name",
                            true
                          )
                        }
                      />
                    </Form.Item>
                  </div>

                  <div
                    className="form-top-container"
                    style={{
                      display: "inline-block",
                      width: "52%",
                      marginRight: 7,
                    }}
                  >
                    <Form.Item label="Variety Description:">
                      <Input
                        style={{ marginRight: "2px" }}
                        maxLength={100}
                        required
                        value={this.state.newData.variety_description}
                        name="variety_description"
                        onChange={(e) =>
                          this.handleChange(
                            e,
                            this.state.newData,
                            -0,
                            "variety_description",
                            true
                          )
                        }
                      />
                    </Form.Item>
                  </div>
                </div>

                <hr />
                <div>
                  <div
                    className="checkbox-group1"
                    style={{
                      display: "inline-block",
                      width: "46%",
                      marginRight: 10,
                    }}
                  >
                    <Form.Item label="Min Price (Rs/Qntl)">
                      <Input
                        style={{ marginRight: "2px" }}
                        maxLength={100}
                        value={this.state.newData.variety_price_min}
                        name="variety_price_min"
                        onChange={(e) =>
                          this.handleChange(
                            e,
                            this.state.newData,
                            -0,
                            "variety_price_min"
                          )
                        }
                      />
                    </Form.Item>
                  </div>

                  <div
                    className="checkbox-group1"
                    style={{
                      display: "inline-block",
                      width: "46%",
                      marginRight: 10,
                    }}
                  >
                    <Form.Item label="Max Price (Rs/Qntl)">
                      <Input
                        style={{ marginRight: "2px" }}
                        maxLength={100}
                        value={this.state.newData.variety_price_max}
                        name="variety_price_max"
                        onChange={(e) =>
                          this.handleChange(
                            e,
                            this.state.newData,
                            -0,
                            "variety_price_max",
                            true
                          )
                        }
                      />
                    </Form.Item>
                  </div>
                </div>

                <hr/>
                <div
                className="form-top-container"
                style={{
                  display: "inline-block",
                  width: "41%",
                  marginRight: 7,
                }}
              >
                <Form.Item label="Premium">
                  <Radio.Group
                    name="is_premium"
                    value={this.state.newData.is_premium ? 1 : 0}
                    onChange={(e, option) =>
                      this.handleDropdownChange(
                        e.target.value,
                        this.state.newData,
                        "is_premium",
                        -0,
                        "",
                        true
                      )
                    }
                  >
                    <Radio value={1}>Yes</Radio>
                    <Radio value={0}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
            
                <div className="form-bottom-container">
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={this.state.isSubmitted}
                      onClick={() => {
                        // this.setState({isSubmitted:false})
                        this.submitTableRowData(this.state.newData);
                      }}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </Modal>

          <div
            className="table-container1"
            style={{ maxHeight: "450px", width: "99%", margin: "6px 0px" }}
          >
            <Table
              {...userTableProps}
              columns={[
                {
                  key: "variety_name",
                  title: "Variety",
                  dataIndex: "variety_name",
                  width: 155,
                  filters: this.createFilters("variety_name"),
                  onFilter: (value, record) =>
                    record.variety_name.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.variety_name.localeCompare(b.variety_name),
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            style={{ marginRight: "2px" }}
                            maxLength={100}
                            disabled={!rowRecord.isEdit}
                            // disabled={true}
                            value={rowRecord.variety_name}
                            name="variety_name"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "variety_name",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },
                {
                  key: "variety_description",
                  title: "Variety Description",
                  dataIndex: "variety_description",
                  width: 155,
                  // filters: this.createFilters("variety_description"),
                  // onFilter: (value, record) =>
                  //   record.variety_description.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.variety_description.localeCompare(b.variety_description),
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            style={{ marginRight: "2px" }}
                            maxLength={100}
                            disabled={!rowRecord.isEdit}
                            // disabled={true}
                            value={rowRecord.variety_description}
                            name="variety_description"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "variety_description",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },
                {
                  key: "variety_price_min",
                  title: "Min Price (Rs/Qntl)",
                  dataIndex: "variety_price_min",
                  width: 100,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) =>
                    a.variety_price_min - b.variety_price_min,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            style={{ marginRight: "2px" }}
                            disabled={!rowRecord.isEdit}
                            maxLength={100}
                            value={rowRecord.variety_price_min}
                            name="variety_price_min"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "variety_price_min",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },
                {
                  key: "variety_price_max",
                  title: "Max Price (Rs/Qntl)",
                  dataIndex: "variety_price_max",
                  width: 100,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.variety_price_max - b.variety_price_max,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            style={{ marginRight: "2px" }}
                            disabled={!rowRecord.isEdit}
                            maxLength={100}
                            value={rowRecord.variety_price_max}
                            name="variety_price_max"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "variety_price_max",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },
                {
                  key: "is_premium",
                  title: "Premium",
                  dataIndex: "is_premium",
                  width: 85,
                  //filters: this.createFilters("is_premium"),
                  // filters: [{ text: "Yes", value: 1 }, { text: "No", value: 0 }],
                 // onFilter: (value, record) =>
                   // record.is_premium === value,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-15px", marginRight: "-40px" }}>
                          <Select
                            showSearch
                            optionFilterProp="children"
                            disabled={!rowRecord.isEdit}
                            defaultValue={
                              rowRecord.is_premium === 1 ? "Yes" : "No"
                            }
                            value={
                              rowRecord.is_premium === 1 ? "Yes" : "No"
                            }
                            onChange={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "is_premium",
                                index,
                                option.props.children,
                                false
                              )
                            }
                            style={{ width: "70%" }}
                          >
                            <Option key={1} value={1}>
                              {"Yes"}
                            </Option>
                            <Option key={0} value={0}>
                              {"No"}
                            </Option>
                          </Select>
                        </div>
                      </>
                    );
                  },
                },
                {
                  title: "Action",
                  dataIndex: "Submit",
                  key: "Submit",
                  width: 140,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ display: "inline-flex", width: "100%" }}>
                          <Button
                          className={!canUserEdit(
                            this.props.userData,
                            null,
                            FeatureCodes.MS_VARIETY_EDIT
                          ) ? "disabledBtnClass" : "btnClass"}
                          disabled={!canUserEdit(
                            this.props.userData,
                            null,
                            FeatureCodes.MS_VARIETY_EDIT
                          )}
                            type="primary"
                            size={"small"}
                            style={{
                              marginLeft: 5,
                              alignSelf: "center",
                              color: "white",
                              borderRadius: 15,
                              padding: "0px 0px 2px",
                              height: 31,
                              backgroundColor: "#20346a",
                            }}
                            block
                            htmlType="submit"
                            // disabled={this.state.isSubmitted}
                            onClick={() =>
                              rowRecord.isEdit
                                ? this.submitTableRowData(rowRecord)
                                : this.handleEditClick(rowRecord, index)
                            }
                          >
                            {rowRecord.isEdit ? "Submit" : "Edit"}
                          </Button>

                          <Button
                            className={
                              !rowRecord.isEdit
                                ? "disabledBtnClass"
                                : "btnClass"
                            }
                            type="primary"
                            size={"small"}
                            style={{
                              marginLeft: 5,
                              alignSelf: "center",
                              color: "white",
                              borderRadius: 15,
                              padding: "0px 0px 2px",
                              height: 31,
                              backgroundColor: "#20346a",
                            }}
                            block
                            htmlType="submit"
                            disabled={!rowRecord.isEdit}
                            onClick={() =>
                              this.handleCancelClick(rowRecord, index)
                            }
                          >
                            Cancel
                          </Button>
                        </div>
                      </>
                    );
                  },
                },
              ]}
              dataSource={this.setTableData()}
            />
          </div>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
  messageAccess: selectMSMessageRBAC,
});
export default connect(mapStateToProps)(VarietyMaster);
//export default VarietyMaster;
