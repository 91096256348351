import React from "react";
import "./fixed-state-variety-projections.styles.css";
import { useEffect, useState } from "react";

import API from "../../api";

import { Spin, message, Empty } from "antd";

import CustomSelectWeekly from "../custom-select-weekly/custom-select-weekly.component";
import ProjectionGraph from "../projection-graph/projection-graph.component";

const FixedStateMultipleVarietyProjections = (props) => {
  const { fixedState } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState([]);
  const [selectedID, setSelectedID] = useState(undefined);
  const [projectionData, setProjectionData] = useState(undefined);

  const getStateFiltersAndData = () => {
    setIsLoading(true);

    API.get("/states")
      .then((filterResponse) => {
        if (filterResponse.data.status) {
          setFilters(filterResponse.data.data.states);
          setSelectedID(filterResponse.data.data.states[0].territory_id);



          getProjectionsData(filterResponse.data.data.states[0].territory_id);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          message.warning(
            "Could not retrieve state information.Please try again."
          );
        }
      })
      .catch((err) => {
        setIsLoading(false);
        message.error(
          "Could not retrieve state information. Please check your network connection."
        );
      });
  };
  const getVarietyFiltersAndData = () => {
    setIsLoading(true);


    API.get("/varieties")
      .then((varietyResponse) => {
        setFilters(varietyResponse.data.data);
        setSelectedID(varietyResponse.data.data[0].variety_id);
        getProjectionsData(varietyResponse.data.data[0].variety_id);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        message.error(
          "Could not retrieve variety information.Please check your network connectivity"
        );
      });
  };

  //componentDidMount Equivalent
  useEffect(() => {
    //#region API to get the filter data
    if (fixedState) {
      getStateFiltersAndData();
    } else {
      getVarietyFiltersAndData();
    }
    //#endregion
  }, []);

  const getProjectionsData = (id) => {
    setIsLoading(true);
    const urlProjectionData = fixedState
      ? `/history_prediction?branch_id=${id}`
      : `/history_prediction?variety_id=${id}`;



    API.get(urlProjectionData)
      .then((projectionResponse) => {
        if (projectionResponse.data.status) {
          setIsLoading(false);
          setProjectionData(projectionResponse.data.data);
        } else {
          setIsLoading(false);
          message.warning("Could not retrieve graph data. Please try again.");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        message.warning(
          "Could not retrieve graph data. Please check your network connectivity."
        );
      });
  };

  const onSelected = (id) => {
    setSelectedID(id);
    getProjectionsData(id);
  };


  return (
    <div className="fixed-projections-line-graph-container">
      <Spin spinning={isLoading}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <div
            className="temporal-select center box-shadow-card"
            style={{ height: "70px" }}
          >
            <div className="temporal-select-title">{`Select ${fixedState ? "State" : "Variety"
              }`}</div>
            <CustomSelectWeekly
              list={
                fixedState
                  ? filters.map((item) => {
                    return {
                      name: item.state,
                      id: item.territory_id,
                    };
                  })
                  : filters.map((item) => {
                    return {
                      name: item.variety_name,
                      id: item.variety_id,
                    };
                  })
              }
              value={selectedID}
              onSelect={onSelected}
              placeholder="Select State"
            />
          </div>
        </div>
        {projectionData ? (
          <>
            {/* <div className="projection-graphs"> */}
            <ProjectionGraph projectionData={projectionData} isYearDemarcated />
            <ProjectionGraph
              isVolume
              projectionData={projectionData}
              isYearDemarcated
            />
            {/* </div> */}
          </>
        ) : (
          <Empty description={false} />
        )}
      </Spin>
    </div>
  );
};

export default FixedStateMultipleVarietyProjections;
