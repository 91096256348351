import React from "react";
import "./farmer-buying-percentile-master.styles.css";
import { Component } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUserData ,selectMSMessageRBAC} from "../../redux/user/user.selectors";
import { canUserEdit,getUserMSRBACMessage } from "../../auth/utils";
import { FeatureCodes } from "../../auth/data";
// import CustomFilterMultiselect from "../../components/custom-multi-select/custom-multi-select.component";

import {
  Table,
  Spin,
  Input,
  Select,
  Button,
  message,
  Modal,
  Form,
  Radio,
} from "antd";

import CustomSelectSummary from "../../components/custom-select-summary/custom-select-summary.component";

import API from "../../api";

const { Search } = Input;
const { Option } = Select;

class FarmerBuyingPercentileMaster extends Component {
  state = {
    isModalVisible: false,
    isAdd: false,
    currentRecord: null,
    userBranches: null,
    searchText: null,
    searchUserType: 0,
    FarmerBuyingPercentileTableData: [],
    isSubmitted: false,
    primaryHubList: [],
    filteredHubList: [],
    hubRegionList: [],
    buyNoBuyList: [],
    mandiBranchList: [],
    isSpinnerOnLoad: false,
    newData: {
      fbp_pkid: undefined,
      variety_description: "",
      farmer_buying_percentile: undefined,
      variety_id: undefined,
      variety_name: "",
      territory_name: "",
      hub_id: undefined,
      is_active: true,
      isEdit: false,
    },
    rowData: undefined,
    selectedHubValue: undefined,
    selectedHubName: "",
    selectedHubList: [],
    factoryList: [],
    routeList: [],
    selectedVarietyValue: undefined,
    selectedVarietyName: "",
    selectedBranchList: [],
  };

  componentDidMount() {
    this.setState({ isSpinnerOnLoad: true });
    this.getHubList();
  }

  getMasterHub = async () => {
    this.setState({ isSpinnerOnLoad: true });

    const params = {
      variety_id:
        this.state.selectedVarietyValue === null ||
        this.state.selectedVarietyValue === undefined
          ? null
          : this.state.selectedVarietyValue,
    };

    await API.get("/farmer_buy_percentile", { params: params })
      .then((varietyResponse) => {
          let modifiedData = varietyResponse.data.data.map((item, index) => {
        return {
          ...item,
          isEdit: false,
          key: index,
        };
      });
        this.setState({
          FarmerBuyingPercentileTableData: modifiedData,
          isSpinnerOnLoad: false,
          isAdd: true,
        });
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };

  getHubList = async () => {
    this.setState({ isSpinnerOnLoad: true });

    await API.get("/list_hubs")
      .then((mandiBranch) => {
        // let modifiedData = [...mandiBranch.data.data];
        // modifiedData.push({ branch_id: null, branch_name: "All" });
        const filterhub = mandiBranch.data.data.filter(
          (hubItem) => hubItem.parent_id === this.state.selectedBranchValue
        );
        this.setState(
          {
            primaryHubList: mandiBranch.data.data,
            filteredHubList: filterhub,
            selectedHubValue: filterhub[0] ? filterhub[0].territory_id : 0,
            selectedHubName: filterhub[0] ? filterhub[0].territory_name : 0,
          },

          () => this.getvarietyList()
        );
      })
      .catch((err) => {
        this.setState({ isSpinnerOnLoad: false });
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
      });
  };

  getvarietyList = async () => {
    this.setState({ isSpinnerOnLoad: true });

    //TODO: change api
    await API.get("/list_variety")
      .then((roleData) => {
        this.setState(
          {
            varietyList: roleData.data.data,
            selectedVarietyValue: roleData.data.data[0].variety_id,
            selectedVarietyName: roleData.data.data[0].variety_name,
          },
          () => this.getMasterHub()
        );
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };

  setTableData = () => {
    if (
      this.state.FarmerBuyingPercentileTableData !== undefined &&
      this.state.FarmerBuyingPercentileTableData.length > 0
    ) {
      const userTableData = this.state.FarmerBuyingPercentileTableData.map(
        (user, index) => {
          return {
            key: user.key,
            fbp_pkid: user.fbp_pkid,
            variety_description: user.variety_description,
            farmer_buying_percentile: user.farmer_buying_percentile,
            variety_id: user.variety_id,
            variety_name: user.variety_name,
            territory_name: user.territory_name,
            hub_id: user.hub_id,
            is_active: user.is_active,
            isEdit: user.isEdit,
          };
        }
      );

      let dataAfterSearch = userTableData;

      if (this.state.searchText) {
        dataAfterSearch = dataAfterSearch.filter(
          (item) =>
            (item.territory_name !== null &&
              item.territory_name
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase())) ||
            (item.variety_name !== null &&
              item.variety_name
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase()))
        );
      }

      return dataAfterSearch;
    } else {
      return this.state.FarmerBuyingPercentileTableData;
    }
  };

  submitTableRowData = (row, isPopup) => {
    this.setState({ isSpinnerOnLoad: true, isSubmitted: true });
    let warningmessage = "";
    if (
      row.territory_name === null ||
      row.territory_name === undefined ||
      row.territory_name === ""
    ) {
      warningmessage = "Please Select hub";
    } else if (
      row.variety_name === null ||
      row.variety_name === undefined ||
      row.variety_name === ""
    ) {
      warningmessage = "Please select variety";
    } else if (
      row.farmer_buying_percentile === null ||
      row.farmer_buying_percentile === undefined ||
      row.farmer_buying_percentile === "" ||
      row.farmer_buying_percentile === 0
    ) {
      warningmessage = "Please enter Farmer Buying Percentile";
    }

    if (
      warningmessage === "" ||
      warningmessage === undefined ||
      warningmessage === null
    ) {
      this.setState({ isSubmitted: true });
      this.setState({ isSpinnerOnLoad: true });
      let data = {
        fbp_pkid: row.fbp_pkid,
        variety_description: row.variety_description,
        variety_id: row.variety_id,
        variety_name: row.variety_name,
        territory_name: row.territory_name,
        is_active: row.is_active,
        hub_id: row.hub_id,
        farmer_buying_percentile:
          row.farmer_buying_percentile === "" ||
          row.farmer_buying_percentile === undefined
            ? null
            : row.farmer_buying_percentile,
      };

      API.put("/farmer_buy_percentile", data, {})
        .then((response) => {
          if (response.status === 200 && response.data.status) {
            this.setModalVisibility(false);
            message.info(response.data.message, 5);
            this.getMasterHub();
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
          } else if (!response.data.status) {
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
            message.error(response.data.message, 5);
            // this.setModalVisibility(false);
          } else {
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
            message.error("Please try again", 5);
          }
        })
        .catch((err) => {
          if (err) {
            if (err.response && err.response.status === 400)
              message.error("Something went wrong. Please try again later.", 5);
            else {
              message.error(
                "Something went wrong.. Please try again later.",
                5
              );
            }
          } else {
            message.error("Something went wrong... Please try again later.", 5);
          }
          this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
          // this.setModalVisibility(false);
        });
    } else {
      this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
      message.error(warningmessage, 5);
    }
  };

  TableColumnHeader = (props) => {
    const { title, subtitle } = props;
    return (
      <div className="column-header-container">
        <div
          style={{
            color: "white",
            fontSize: "12px",
            whiteSpace: "nowrap",
            fontWeight: "normal",
            textAlign: "left",
          }}
        >
          {title}
        </div>
        <div
          style={{
            color: "#cbdfff",
            fontSize: "11px",
            fontWeight: "normal",
            textAlign: "left",
            // subtitle && title.length < subtitle.length ? "left" : "center"
          }}
        >
          {subtitle}
        </div>
      </div>
    );
  };

  createFilters = (label) => {
    let filterData = this.state.FarmerBuyingPercentileTableData;
    //#region remove duplicate objects
    let uniqueFilterData = [];

    if (
      label === "is_active" &&
      this.state.FarmerBuyingPercentileTableData &&
      this.state.FarmerBuyingPercentileTableData.length > 0
    ) {
      uniqueFilterData = [
        { text: "Yes", value: 1 },
        { text: "No", value: 0 },
      ];
    } else {
      filterData.map((mainItem) =>
        uniqueFilterData.filter((item) => item[label] === mainItem[label])
          .length > 0
          ? null
          : uniqueFilterData.push(mainItem)
      );

      uniqueFilterData = uniqueFilterData.map((item) => {
        return {
          text: item[label],
          value: item[label],
        };
      });

      uniqueFilterData.sort((a, b) => a.text.localeCompare(b.text));
    }

    return uniqueFilterData;
  };

  setModalVisibility = (status) => {
    if (status) {
      const nData = {
        fbp_pkid: undefined,
        variety_description: "",
        farmer_buying_percentile: undefined,
        variety_id: undefined,
        variety_name: "",
        territory_name: "",
        hub_id: undefined,
        is_active: true,
        isEdit: false,
      };

      this.setState({ newData: nData });
    }

    this.setState({ isModalVisible: status });
  };


  handleDropdownChange = (value, row, target, index, optionlabel, isPopup) => {
    let a;
    if (isPopup === false) {
      a = this.state.FarmerBuyingPercentileTableData;
      if (target === "is_active") {
        a[row.key].is_active = value;
      } else if (target === "variety_name") {
        a[row.key].variety_name = optionlabel;
        a[row.key].variety_id = value;
        a[row.key].variety_description = optionlabel;
      } else if (target === "territory_name") {
        a[row.key].hub_id = value;
        a[row.key].territory_name = optionlabel;
      }

      this.setState({ FarmerBuyingPercentileTableData: a });
    } else {
      a = this.state.newData;

      if (target === "is_active") {
        a.is_active = value;
      } else if (target === "variety_name") {
        a.variety_name = optionlabel;
        a.variety_id = value;
        a.variety_description = optionlabel;
      } else if (target === "territory_name") {
        a.hub_id = value;
        a.territory_name = optionlabel;
      }
      this.setState({ newData: a });
    }
  };

  handleChange = (e, row, index, name, isPopup) => {
    e.target.name = name;

    var alphaNumericRegex = /^[a-zA-Z0-9]*$/;
    var numberRegex = /^[0-9.]+$/;
    let a;
   
    if (isPopup === false) {
      a = this.state.FarmerBuyingPercentileTableData;

      if (e.target.name === "farmer_buying_percentile") {
        if (e.target.value === "") {
            a[row.key].farmer_buying_percentile = "";
          } else if (e.target.value.match(numberRegex)) {
            a[row.key].farmer_buying_percentile = e.target.value;
          }
      }
      this.setState({ FarmerBuyingPercentileTableData: a });
    } else {
      a = this.state.newData;

      if (e.target.name === "farmer_buying_percentile") {
        if (e.target.value === "") {
            a.farmer_buying_percentile = "";
          } else if (e.target.value.match(numberRegex)) {
            a.farmer_buying_percentile = e.target.value;
          }
      } 

      this.setState({ newData: a });
    }
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  handleEditChange = (row, index) => {
    this.setState({ rowData: row });
    let tempData = this.state.FarmerBuyingPercentileTableData.map((item) => {
      if (item.key === row.key) {
        return {
          ...item,
          isEdit: true,
        };
      } else {
        return item;
      }
    });
    this.setState({ FarmerBuyingPercentileTableData: tempData }, () => {
    });
  };

  handleCancelClick = (rowData, index) => {
    let tempData = this.state.FarmerBuyingPercentileTableData.map((item) => {
      if (item.key === rowData.key) {
        return this.state.rowData;
      } else {
        return item;
      }
    });
    this.setState({ FarmerBuyingPercentileTableData: tempData });
  };

  render() {
    const userTableProps = {
      pagination: false,
      size: "default",
      bordered: true,
      scroll: { x: 200, y: window.innerHeight - 300 },
    };

    const handleRegionSelect = (value, props) => {
      this.setState(
        { selectedVarietyName: props.key, selectedVarietyValue: value },
        () => this.getMasterHub()
      );
    };

    return (
      <div
        className="mandi-master-container"
        style={{ height: window.innerHeight - 85, marginTop: "-45px" }}
      >
        <div className="top-container">
          <div className="hub-region-filter">
            <span
              style={{
                fontSize: "15px",
                marginTop: "5px",
                fontWeight: "600",
                marginLeft: "50px",
              }}
            >
              Variety&nbsp;
            </span>{" "}
            <Select
              disabled={!this.state.isAdd}
              showSearch
              optionFilterProp="children"
              className={"select-master-region"}
              mode="single"
              placeholder="Select Variety"
              value={this.state.selectedVarietyValue}
              onChange={(value, props) => handleRegionSelect(value, props)}
            >
              {this.state.varietyList !== null &&
                this.state.varietyList !== undefined &&
                this.state.varietyList.length &&
                this.state.varietyList.map((item, idx) => (
                  <Option value={item.variety_id} key={item.variety_name}>
                    {item.variety_name}
                  </Option>
                ))}
            </Select>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row-reverse",
              placeItems: "flex-end",
              marginRight: "25px",
              marginBottom: "10px",
            }}
          >
            <Search
              disabled={!this.state.isAdd}
              placeholder="Search"
              allowClear
              onChange={(e) => this.setState({ searchText: e.target.value })}
              style={{ width: "45%" }}
            />
            <Button
              className="add-master-button"
              style={{
                height: "25px",
                width: "70px",
                backgroundColor: "#0b133d",
                color: "white",
              }}
              disabled={
                !canUserEdit(
                  this.props.userData,
                  null,
                  FeatureCodes.MS_FARMER_BUY_PERCENT_EDIT
                ) || !this.state.isAdd
              }
              onClick={() => this.setModalVisibility(true)}
            >
              &#10010; Add
            </Button>
          </div>
        </div>

        <div>
          <div
            style={{
              marginLeft: "25px",
              marginTop: "-8px",
              fontSize: "14px",
              color: "red",
              display: "flex",
              float: "left",
            }}
          >
          {getUserMSRBACMessage(this.props.messageAccess,FeatureCodes.MS_FARMER_BUY_PERCENT_EDIT)}
          </div>
          <div
            style={{
              marginRight: "20px",
              marginTop: "-8px",
              fontSize: "15px",
              color: "#0B133D",
              display: "flex",
              float: "right",
            }}
          >
            Total number of records:&nbsp;
            <b>
              {this.state.FarmerBuyingPercentileTableData &&
                this.setTableData().length}
            </b>
          </div>
        </div>

        <Spin
          spinning={this.state.isSpinnerOnLoad}
          size={"large"}
          tip="Loading..."
          style={{ alignSelf: "center", zIndex: 9999, marginTop: "20px" }}
        >
          <Modal
            className="add-master-modal"
            title={"Add Hub"}
            onCancel={this.handleCancel}
            footer={false}
            visible={this.state.isModalVisible}
            destroyOnClose={true}
          >
            <div className="master-form-container">
              <Form
                layout="horizontal"
                //   onValuesChange={onFormLayoutChange}
                size={"small"}
                // onSubmit={submitTableRowData(this.state.newData)}
              >
                <div className="form-top-container">
                  <div>
                    <div
                      className="checkbox-group1"
                      style={{
                        display: "inline-block",
                        width: "40%",
                        marginRight: 7,
                      }}
                    >
                      <Form.Item label="Hub">
                        <Select
                          showSearch
                          optionFilterProp="children"
                          className="remarks-select"
                          name="territory_name"
                          defaultValue={this.state.newData.territory_name}
                          value={this.state.newData.territory_name}
                          onSelect={(value, option) =>
                            this.handleDropdownChange(
                              value,
                              this.state.newData,
                              "territory_name",
                              -0,
                              option.props.children,
                              true
                            )
                          }
                        >
                          {this.state.primaryHubList &&
                            this.state.primaryHubList.length &&
                            this.state.primaryHubList.map((hubItem, idx) => (
                              <Option
                                key={hubItem.territory_id}
                                value={hubItem.territory_id}
                              >
                                {hubItem.territory_name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </div>

                    <div
                      className="checkbox-group1"
                      style={{
                        display: "inline-block",
                        width: "40%",
                        marginRight: 40,
                      }}
                    >
                      <Form.Item label="Variety">
                        <Select
                          showSearch
                          optionFilterProp="children"
                          className="remarks-select"
                          name="variety_name"
                          defaultValue={this.state.newData.variety_name}
                          value={this.state.newData.variety_name}
                          onSelect={(value, option) =>
                            this.handleDropdownChange(
                              value,
                              this.state.newData,
                              "variety_name",
                              -0,
                              option.props.children,
                              true
                            )
                          }
                        >
                          {this.state.varietyList &&
                            this.state.varietyList.length &&
                            this.state.varietyList.map((hubItem, idx) => (
                              <Option
                                key={hubItem.variety_id}
                                value={hubItem.variety_id}
                              >
                                {hubItem.variety_name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                  <hr />
                  <div
                    className="checkbox-group1"
                    style={{
                      display: "inline-block",
                      width: "40%",
                      marginRight: 7,
                    }}
                  >
                    <Form.Item label="Farmer Buying Percentile">
                      <Input
                        style={{ marginRight: "2px" }}
                        maxLength={100}
                        value={this.state.newData.farmer_buying_percentile}
                        name="farmer_buying_percentile"
                        onChange={(e) =>
                          this.handleChange(
                            e,
                            this.state.newData,
                            -0,
                            "farmer_buying_percentile",
                            true
                          )
                        }
                      />
                    </Form.Item>
                  </div>

                  <div
                    className="form-top-container"
                    style={{
                      display: "inline-block",
                      width: "33%",
                      marginRight: 7,
                    }}
                  >
                    <Form.Item label="Active">
                      <Radio.Group
                        name="is_active"
                        value={this.state.newData.is_active ? 1 : 0}
                        onChange={(e, option) =>
                          this.handleDropdownChange(
                            e.target.value,
                            this.state.newData,
                            "is_active",
                            -0,
                            "",
                            true
                          )
                        }
                      >
                        <Radio value={1}>Yes</Radio>
                        <Radio value={0}>No</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                </div>

                <div className="form-bottom-container">
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={this.state.isSubmitted}
                      onClick={() =>
                        this.submitTableRowData(this.state.newData)
                      }
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </Modal>

          <div
            className="table-container"
            style={{ maxHeight: "450px", margin: "6px 0px" }}
          >
            <Table
              {...userTableProps}
              columns={[
                {
                  title: "Hub",
                  dataIndex: "territory_name",
                  key: "territory_name",
                  width: 125,
                  filters: this.createFilters("territory_name"),
                  onFilter: (value, record) =>
                    record.territory_name.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) =>
                    a.territory_name.localeCompare(b.territory_name),
                  render: (record, rowRecord) => {
                    return (
                      <>
                        <div
                          style={{ marginLeft: "-15px", marginRight: "-10px" }}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            disabled={true}
                            className="remarks-select"
                            name="territory_name"
                            defaultValue={rowRecord.territory_name}
                            value={rowRecord.territory_name}
                            onSelect={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "territory_name",
                                -0,
                                option.props.children,
                                false
                              )
                            }
                          >
                            {this.state.primaryHubList &&
                              this.state.primaryHubList.length &&
                              this.state.primaryHubList.map((hubItem, idx) => (
                                <Option
                                  key={hubItem.territory_id}
                                  value={hubItem.territory_id}
                                >
                                  {hubItem.territory_name}
                                </Option>
                              ))}
                          </Select>
                        </div>
                      </>
                    );
                  },
                },
                {
                  title: "Variety",
                  dataIndex: "variety_name",
                  key: "variety_name",
                  width: 130,
                  filters: this.createFilters("variety_name"),
                  onFilter: (value, record) =>
                    record.variety_name.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) =>
                    a.variety_name.localeCompare(b.variety_name),
                  render: (record, rowRecord) => {
                    return (
                      <>
                        <div
                          style={{ marginLeft: "-15px", marginRight: "-10px" }}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            disabled={true}
                            className="remarks-select"
                            name="variety_name"
                            defaultValue={rowRecord.variety_name}
                            value={rowRecord.variety_name}
                            onSelect={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "variety_name",
                                -0,
                                option.props.children,
                                false
                              )
                            }
                          >
                            {this.state.varietyList &&
                              this.state.varietyList.length &&
                              this.state.varietyList.map((hubItem, idx) => (
                                <Option
                                  key={hubItem.variety_id}
                                  value={hubItem.variety_id}
                                >
                                  {hubItem.variety_name}
                                </Option>
                              ))}
                          </Select>
                        </div>
                      </>
                    );
                  },
                },
                {
                  key: "farmer_buying_percentile",
                  title: "Farmer Buying Percentile",
                  dataIndex: "farmer_buying_percentile",
                  width: 100,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) =>
                    a.farmer_buying_percentile - b.farmer_buying_percentile,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            disabled={!rowRecord.isEdit}
                            style={{ marginRight: "2px" }}
                            maxLength={100}
                            value={rowRecord.farmer_buying_percentile}
                            name="farmer_buying_percentile"
                            onChange={(e) =>
                                this.handleChange(
                                    e,
                                    rowRecord,
                                    index,
                                    "farmer_buying_percentile",
                                    false
                                  )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },
                {
                  key: "is_active",
                  title: "Active",
                  dataIndex: "is_active",
                  width: 85,
                  filters: this.createFilters("is_active"),
                  // filters: [{ text: "Yes", value: 1 }, { text: "No", value: 0 }],
                  onFilter: (value, record) => record.is_active === value,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div
                          style={{ marginLeft: "-15px", marginRight: "-40px" }}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            disabled={!rowRecord.isEdit}
                            defaultValue={
                              rowRecord.is_active === 1 ? "Yes" : "No"
                            }
                            value={rowRecord.is_active === 1 ? "Yes" : "No"}
                            onChange={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "is_active",
                                index,
                                option.props.children,
                                false
                              )
                            }
                            style={{ width: "70%" }}
                          >
                            <Option key={1} value={1}>
                              {"Yes"}
                            </Option>
                            <Option key={0} value={0}>
                              {"No"}
                            </Option>
                          </Select>
                        </div>
                      </>
                    );
                  },
                },

                {
                  title: "Action",
                  dataIndex: "Submit",
                  key: "Submit",
                  width: 130,
                  visibility: false,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ display: "inline-flex", width: "100%" }}>
                          <Button
                            className={
                              !canUserEdit(
                                this.props.userData,
                                null,
                                FeatureCodes.MS_FARMER_BUY_PERCENT_EDIT
                              )
                                ? "disabledBtnClass"
                                : "btnClass"
                            }
                            disabled={
                              !canUserEdit(
                                this.props.userData,
                                null,
                                FeatureCodes.MS_FARMER_BUY_PERCENT_EDIT
                              )
                            }
                            type="primary"
                            size={"small"}
                            style={{
                              marginLeft: 5,
                              alignSelf: "center",
                              padding: "0px 0px 2px",
                              height: 31,
                              backgroundColor: "#20346a",
                              color: "white",
                              borderRadius: 15,
                            }}
                            block
                            htmlType="submit"
                            onClick={() =>
                              rowRecord.isEdit
                                ? this.submitTableRowData(
                                    rowRecord,
                                    index,
                                    false
                                  )
                                : this.handleEditChange(rowRecord, index)
                            }
                          >
                            {" "}
                            {rowRecord.isEdit ? "Submit" : "Edit"}{" "}
                          </Button>

                          <Button
                            className={
                              !rowRecord.isEdit
                                ? "disabledBtnClass"
                                : "btnClass"
                            }
                            type="primary"
                            size={"small"}
                            style={{
                              marginLeft: 5,
                              alignSelf: "center",
                              color: "white",
                              borderRadius: 15,
                              padding: "0px 0px 2px",
                              height: 31,
                              backgroundColor: "#20346a",
                            }}
                            block
                            htmlType="submit"
                            disabled={!rowRecord.isEdit}
                            onClick={() =>
                              this.handleCancelClick(rowRecord, index)
                            }
                          >
                            Cancel
                          </Button>
                        </div>
                      </>
                    );
                  },
                },
              ]}
              dataSource={this.setTableData()}
            />
          </div>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
  messageAccess: selectMSMessageRBAC,
});

export default connect(mapStateToProps)(FarmerBuyingPercentileMaster);

// export default ContainerDTDFreightMaster;
