import React from "react";
import "./prem-nprem-bar-graph.styles.css";

import { Bar } from "react-chartjs-2";
import { getRandomColor } from "../../auth/utils";

const PremiumNonPremiumGraph = (props) => {
  const {
    dataForBarGraph,
    // datasets,
    xLabel,
    width,
    height,
    yLabel,
    isVolume,
    maintainAspectRatio,
    isQC,
    isPercentage,
  } = props;

  const PREM_NPREM_COLORS = {
    BLUE: "#2196f3",
    GREEN: "#73de73",
  };
  const COLOR_GREENS = [
    "#306f30",
    "#069005",
    "#09b008",
    "#0df00b",
    "#50f74f",
    "#6ff96e",
  ];
  const COLOR_BLUES = [
    "#094c72",
    "#055a8c",
    "#026aa7",
    "#0079bf",
    "#298fca",
    "#5ba4cf",
    "#8bbdd9",
    "#bcd9ea",
  ];

  let legendInformation = [];

  const barChartOptions = {
    responsive: false,
    spanGaps: true,
    maintainAspectRatio: maintainAspectRatio ? maintainAspectRatio : false,
    legend: {
      display: false,
    },

    label: true,
    intersect: false,
    mode: "nearest",
    backgroundColor: "white",
    titleFontColor: "#313131",
    borderColor: "#9d9d9d",
    borderWidth: 1,
    bodyFontColor: "#575757",
    displayColors: false,
    scales: {
      xAxes: [
        {
          id: "Time",
          stacked: true,
          ticks: {
            maxRotation: 0,
          },
          scaleLabel: {
            display: true,
            // labelString: "Time",
          },
        },
      ],
      yAxes: [
        {
          id: "visualization",
          stacked: true,
          ticks: { beginAtZero: true, display: false },
          scaleLabel: {
            display: true,
            labelString: yLabel ? yLabel : "Volume (MT)",
          },
        },
      ],
    },
  };

  const getQCColor = (label, greens, blues) => {
    console.log(greens, blues);
    let greenColors = greens;
    let blueColors = blues;

    if (
      label.toLowerCase().includes("dara") ||
      label.toLowerCase().includes("mill")
    ) {
      //   if (blueColors.length === 0) {
      //     blueColors = COLOR_BLUES;
      //   }
      let color = blueColors.pop();
      console.log("blue color for" + label, color);
      return color ? color : "grey"; //light blue
    } else {
      //   if (greenColors.length === 0) {
      //     greenColors = COLOR_GREENS;
      //   }
      let color = greenColors.pop();
      console.log("green color for" + label, color);
      return color ? color : "black"; //green
    }
  };

  const getData = () => {
    let data = [];
    dataForBarGraph.map((month) => {
      console.log("COLors :", COLOR_BLUES, COLOR_GREENS);
      let greens = [...COLOR_GREENS];
      let blues = [...COLOR_BLUES];
      data.push({
        labels: month.labels,
        datasets: month.datasets.map((item, idx) => {
          const currentColor = getQCColor(item.label, greens, blues);

          legendInformation.push({
            name: item.label,
            color: currentColor,
          });

          return {
            label: item.label,
            data: item.data,
            backgroundColor: currentColor,
          };
        }),
      });
    });

    console.log("final data fed to graph : ", data);
    return data;
  };

  const conditionalProps = maintainAspectRatio
    ? {}
    : {
        height: height ? height : 330,
        width: width ? width : 1000,
      };

  return (
    <div className="visualization-graph-container box-shadow-bordered">
      <div className="bar-graph ">
        <div className="bar-graph-title">
          {xLabel + `${isVolume ? " (in MT)" : ""}`}
        </div>

        <div className="legend-container">
          {legendInformation.map((legend) => (
            <div key={legend.name} className="legend-box">
              <div
                className="legend-fill"
                style={{
                  background: legend.color,
                }}
              />
              <div className="legend-title">{legend.name}</div>
            </div>
          ))}
        </div>
        <div className="graphs-list">
          {getData().map((graphData) => (
            <Bar
              id="projection-bar-chart"
              data={graphData}
              options={barChartOptions}
              {...conditionalProps}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PremiumNonPremiumGraph;
