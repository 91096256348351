import React from "react";
import "./recommendations-hover-table.styles.css";

import { Table } from "antd";
import { Component } from "react";

import { TableColumnHeader } from "../competitor-hover-table/competitor-hover-table.component";

const competitorHoverTableProps = {
  pagination: false,
  size: "default",
  bordered: true,
};

/**
 * @component
 * @description The table component that displays multiple competitor information on hover.
 * @property {boolean} isMT whether the is unit tobe displayed is in metric tons.
 * @property {object[]} data the data to be displayed inside the table.
 */
class RecommendationHoverTable extends Component {
  render() {
    const { isMT, record } = this.props;

    const setTableData = () => {
      const tableData = record.data.recommendations.map((item, idx) => {
        return {
          key: idx,
          name: item.name,
          type: item.type,
          quantity: item.quantity,
          min_price: item.min_price,
          max_price: item.max_price,
        };
      });
      return tableData;
    };

    return record.data.recommendations.length !== 0 ? (
      <Table
        //   className="competitor=hover-table"
        columns={[
          {
            title: "Name",
            dataIndex: "name",
          },
          {
            title: "Recommendation Type",
            dataIndex: "type",
          },
          {
            title: (
              <TableColumnHeader
                title="Suggested Quantity"
                subtitle={` ${isMT ? "MT" : "Qntl"}`}
              />
            ),
            dataIndex: "quantity",
            render: (quantity, record) => {
              return quantity ? <div>{quantity}</div> : 0;
            },
          },
          {
            title: (
              <TableColumnHeader
                title="Price Min"
                subtitle={` ${isMT ? "₹/MT" : "₹/Qntl"}`}
              />
            ),
            dataIndex: "min_price",
          },
          {
            title: (
              <TableColumnHeader
                title="Price Max"
                subtitle={` ${isMT ? "₹/MT" : "₹/Qntl"}`}
              />
            ),
            dataIndex: "max_price",
          },
        ]}
        dataSource={setTableData()}
        {...competitorHoverTableProps}
      />
    ) : null;
  }
}

export default RecommendationHoverTable;
