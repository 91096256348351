import React, { useState, useEffect } from "react";
import "./model-runs-graph-visualization.styles.css";

import CustomSelectWeekly from "../../components/custom-select-weekly/custom-select-weekly.component";
import ModelRunGraph from "../../components/model-run-visualization-graph/model-run-visualization-graph.component";

import { getVolumeVisualizationData } from "../../services/generic-apis";

import { getVarietiesWithStates } from "../../services/generic-apis";

import { Radio, Spin, Row, Col, message, Empty } from "antd";

const GraphicalVisualizationForModelRuns = (props) => {
  const { } = props;

  //#region hardcoded data
  const tempFilters = {
    model_runs: [
      { id: 2, name: "Feb" },
      { id: 3, name: "March" },
      { id: 4, name: "April" },
      { id: 5, name: "May" },
      { id: 6, name: "June" },
    ],
    qc_model_runs: [
      { id: 1, name: "Model QC" },
      { id: 3, name: "Business QC" },
    ],
    qc_months: [
      { id: 1, name: "Model QC" },
      { id: 3, name: "Business QC" },
    ],
    basis: [
      { id: 1, name: "Absolute" },
      { id: 2, name: "Percentage" },
    ],
  };

  const tempData = [
    {
      xLabel: "Predicted vs Actuals",
      labels: ["Feb", "Mar", "Apr", "May", "Jun", "Post Season"],
      datasets: [
        {
          label: "Feb Model Run",
          data: [120, 190, 300, 250, 150, 230],
        },
        {
          label: "March Model Run",
          data: [100, 390, 200, 120, 120, 220],
        },
        {
          label: "April Model Run",
          data: [110, 490, 100, 230, 320, 330],
        },
        {
          label: "May Model Run",
          data: [150, 130, 120, 220, 230, 320],
        },
        {
          label: "June Model Run",
          data: [170, 120, 230, 430, 350, 210],
        },
        {
          label: "Actual",
          data: [110, 150, null, null, null, null],
        },
      ],
    },
  ];
  const tempData1 = [
    {
      xLabel: "Model Runs Across Months",
      labels: ["Feb", "Mar", "Apr", "May", "Jun", "Post Season"],
      datasets: [
        {
          label: "Feb Model Run",
          data: [120, 190, 300, 250, 150, 230],
        },
        {
          label: "March Model Run",
          data: [100, 390, 200, 120, 120, 220],
        },
        {
          label: "April Model Run",
          data: [110, 490, 100, 230, 320, 330],
        },
        {
          label: "May Model Run",
          data: [150, 130, 120, 220, 230, 320],
        },
        {
          label: "June Model Run",
          data: [170, 120, 230, 430, 350, 210],
        },
      ],
    },
  ];
  const tempData2 = [
    {
      xLabel: "Monthly Predictions for Model Runs",
      labels: [
        "Feb Model Run",
        "Mar Model Run",
        "Apr Model Run",
        "May Model Run",
        "Jun Model Run",
      ],
      datasets: [
        {
          label: "February",
          data: [120, 190, 300, 250, 150],
        },
        {
          label: "March",
          data: [100, 390, 200, 120, 120],
        },
        {
          label: "April",
          data: [110, 490, 100, 230, 320],
        },
        {
          label: "May",
          data: [150, 130, 120, 220, 230],
        },
        {
          label: "June",
          data: [170, 120, 230, 430, 350],
        },
      ],
    },
  ];

  //#endregion
  const [isLoading, setIsLoading] = useState(false);
  const [dropdownID, setDropdownID] = useState(0);
  const [filterData, setFilterData] = useState(undefined);
  const [filters, setFilters] = useState([]);
  const [filterID, setFilterID] = useState(undefined);
  const [filterIDs, setFilterIDs] = useState([]);
  const [graphData, setGraphData] = useState(null);
  const [varieties, setVarieties] = useState([]);
  const [selectedVarietyID, setSelectedVarietyID] = useState(undefined);
  const [states, setStates] = useState([]);
  const [selectedStateID, setSelectedStateID] = useState(undefined);
  const [selectedBasisID, setSelectedBasisID] = useState(undefined);

  useEffect(() => {
    setFilterData(tempFilters);
    setFilters(tempFilters.model_runs);

    setFilterID(tempFilters.qc_model_runs[0].id);
    setFilterIDs([tempFilters.model_runs[0].id]);
    setSelectedBasisID(tempFilters.basis[0].id);

    getVarietiesAndStates();
  }, []);

  const getVarietiesAndStates = async () => {
    setIsLoading(true);

    try {
      const filterResponse = await getVarietiesWithStates();
      if (filterResponse.data.status) {
        setIsLoading(false);

        setVarieties(filterResponse.data.data.qc_filter);
        setStates(filterResponse.data.data.qc_filter[0].states);
        setSelectedVarietyID(filterResponse.data.data.qc_filter[0].variety_id);
        setSelectedStateID(
          filterResponse.data.data.qc_filter[0].states[0].branch_id
        );

        // get the initialData
        getData(
          0,
          tempFilters.qc_model_runs[0].id,
          [tempFilters.model_runs[0].id],
          filterResponse.data.data.qc_filter[0].states[0].branch_id,
          filterResponse.data.data.qc_filter[0].variety_id,
          tempFilters.basis[0].id
        );
      } else {
        setIsLoading(false);
        message.error(
          "Could not retrieve the variety and state filter information."
        );
      }
    } catch (error) {
      setIsLoading(false);
      message.error(
        "Could not retrieve the variety and state filter information."
      );
    }
  };

  // const onRadioButtonSelected = (value) => {
  //   setDropdownID(value);
  //   setFilterTypeAndGetData(value);
  // };

  const onBasisSelected = (value) => {
    setSelectedBasisID(value);

    getData(
      dropdownID,
      filterID,
      [...filterIDs],
      selectedStateID,
      selectedVarietyID,
      value
    );
  };

  const onVarietySelected = (value) => {
    const stateList = varieties.find((item) => item.variety_id === value)
      .states;

    setStates(stateList);
    setSelectedStateID(stateList[0].branch_id);
    setSelectedVarietyID(value);

    getData(
      dropdownID,
      filterID,
      [...filterIDs],
      selectedStateID,
      value,
      selectedBasisID
    );
  };

  const onStateSelected = (value) => {
    setSelectedStateID(value);
    getData(
      dropdownID,
      filterID,
      [...filterIDs],
      value,
      selectedVarietyID,
      selectedBasisID
    );
  };

  const onFilterSelected = (value) => {
    setFilterID(value);
    getData(
      dropdownID,
      value,
      [...filterIDs],
      selectedStateID,
      selectedVarietyID,
      selectedBasisID
    );
  };

  const onMultipleFilterSelected = (values) => {
    setFilterIDs([...values]);
    getData(
      dropdownID,
      filterID,
      [...values],
      selectedStateID,
      selectedVarietyID,
      selectedBasisID
    );
  };

  const getData = async (
    dropdown_id,
    filter_id,
    filter_id_list,
    state_id,
    variety_id,
    basis_id
  ) => {
    setIsLoading(true);


    const volumeVisualizationResponse = await getVolumeVisualizationData(
      filter_id,
      filter_id_list,
      state_id,
      variety_id,
      basis_id
    );

    if (volumeVisualizationResponse.data.status) {
      setIsLoading(false);
      setGraphData([volumeVisualizationResponse.data.data]);
    } else {
      setIsLoading(false);
      message.error("Could not retrieve monthly volume information");
    }
  };

  // const setFilterTypeAndGetData = (selectedDropdownID) => {
  //   switch (selectedDropdownID) {
  //     case 0:
  //       setFilters(filterData.model_runs);
  //       setFilterIDs([filterData.model_runs[0].id]);
  //       getData(selectedDropdownID, null, [filterData.model_runs[0].id]);
  //       break;
  //     case 1:
  //       setFilters(filterData.qc_model_runs);
  //       setFilterID(filterData.qc_model_runs[0].id);
  //       getData(selectedDropdownID, filterData.qc_model_runs[0].id);
  //       break;
  //     case 2:
  //       setFilters(filterData.qc_months);
  //       setFilterID(filterData.qc_months[0].id);
  //       getData(selectedDropdownID, filterData.qc_months[0].id);
  //       break;
  //     default:
  //       break;
  //   }
  // };

  const setSelectText = () => {
    switch (dropdownID) {
      case 0:
        return "Model Runs";
      case 1:
      case 2:
        return "QC";
      default:
        break;
    }
  };

  return (
    <Spin spinning={isLoading}>
      <div className="model-run-visualization-container">
        {/* <Radio.Group
          className="graph-radio-buttons"
          onChange={(e) => onRadioButtonSelected(e.target.value)}
          value={dropdownID}
        >
          <Radio value={0}>Actual vs Model Comparison</Radio>
          <Radio value={1}>Predictions Across Model Runs</Radio>
          <Radio value={2}>Month-wise Model Run Predictions</Radio>
        </Radio.Group> */}
        <div style={{ display: "flex" }}>
          <div
            className="temporal-select center box-shadow-card"
            style={{ height: "70px" }}
          >
            <div className="temporal-select-title">{`Model Runs`}</div>
            <CustomSelectWeekly
              isMultiple={true}
              list={tempFilters.model_runs.map((item) => {
                return {
                  name: item.name,
                  id: item.id,
                };
              })}
              values={filterIDs}
              onSelect={onMultipleFilterSelected}
              placeholder={`Select Model Runs`}
            />
          </div>
          <div
            className="temporal-select center box-shadow-card"
            style={{ height: "70px" }}
          >
            <div className="temporal-select-title">{`QC`}</div>
            <CustomSelectWeekly
              isMultiple={false}
              list={tempFilters.qc_model_runs.map((item) => {
                return {
                  name: item.name,
                  id: item.id,
                };
              })}
              value={filterID}
              onSelect={onFilterSelected}
              placeholder={`Select QC`}
            />
          </div>

          <div
            className="temporal-select center box-shadow-card"
            style={{ height: "70px" }}
          >
            <div className="temporal-select-title">Variety</div>
            <CustomSelectWeekly
              list={varieties.map((item) => {
                return {
                  name: item.variety_name,
                  id: item.variety_id,
                };
              })}
              value={selectedVarietyID}
              onSelect={onVarietySelected}
            //   placeholder={`Select ${setSelectText()}`}
            />
          </div>

          <div
            className="temporal-select center box-shadow-card"
            style={{ height: "70px" }}
          >
            <div className="temporal-select-title">State</div>
            <CustomSelectWeekly
              list={states.map((item) => {
                return {
                  name: item.state,
                  id: item.branch_id,
                };
              })}
              value={selectedStateID}
              onSelect={onStateSelected}
            //   placeholder={`Select ${setSelectText()}`}
            />
          </div>

          <div
            className="temporal-select center box-shadow-card"
            style={{ height: "70px" }}
          >
            <div className="temporal-select-title">{`Volume Basis`}</div>
            <CustomSelectWeekly
              isMultiple={false}
              list={tempFilters.basis.map((item) => {
                return {
                  name: item.name,
                  id: item.id,
                };
              })}
              value={selectedBasisID}
              onSelect={onBasisSelected}
              placeholder={`Select QC`}
            />
          </div>
        </div>
        <Row style={{ margin: "10px" }} gutter={10}>
          {graphData ? (
            graphData.map((item, idx) => (
              <Col key={dropdownID + "_" + idx} span={24}>
                <ModelRunGraph
                  // yLabel="Model Recommended (MT)"
                  isVolume
                  isPercentage={selectedBasisID === 2 ? true : false}
                  labels={item.labels}
                  datasets={item.datasets}
                  xLabel="Model Recommended vs Actuals" //{item.xLabel}
                />
              </Col>
            ))
          ) : (
            <Empty />
          )}
        </Row>
      </div>
    </Spin>
  );
};

export default GraphicalVisualizationForModelRuns;
