import { MapActionTypes } from "./map.types";

const INITIAL_STATE = {
  currentScreen: "Mandi",
  mandiStates: {
    currentRegion: "a",
    currentHub: "a",
    currentMandi: "a"
  },
  FORStates: {
    currentRegion: "b",
    currentHub: "b"
  },
  farmerStates: {
    currentRegion: "c",
    currentHub: "c"
  }
};

const mapReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MapActionTypes.SET_CURRENT_SCREEN:
      return {
        ...state,
        currentScreen: action.payload
      };
    case MapActionTypes.SET_MAP_MANDI_SELECT_VALUES:
      switch (action.payloadType) {
        case "currentRegion":
          state.mandiStates.currentRegion = action.payload;
          break;
        case "currentHub":
          state.mandiStates.currentHub = action.payload;
          break;
        default:
          break;
      }
      return state;
    case MapActionTypes.SET_MAP_FOR_SELECT_VALUES:
      switch (action.payloadType) {
        case "currentRegion":
          state.FORStates.currentRegion = action.payload;
          break;
        case "currentHub":
          state.FORStates.currentHub = action.payload;
          break;
        default:
          break;
      }
      return { ...state };

    case MapActionTypes.SET_MANDI_REGION:
      state.mandiStates.currentRegion = action.payload;
      return { ...state };

    case MapActionTypes.SET_MANDI_HUB:
      state.mandiStates.currentHub = action.payload;
      return { ...state };

    case MapActionTypes.SET_MANDI_MANDI:
      state.mandiStates.currentMandi = action.payload;
      return { ...state };

    case MapActionTypes.SET_FOR_REGION:
      state.FORStates.currentRegion = action.payload;
      return { ...state };

    case MapActionTypes.SET_FOR_HUB:
      state.FORStates.currentHub = action.payload;
      return { ...state };

    case MapActionTypes.SET_FARMER_REGION:
      state.farmerStates.currentRegion = action.payload;
      return { ...state };

    case MapActionTypes.SET_FARMER_HUB:
      state.farmerStates.currentHub = action.payload;
      return { ...state };

    default:
      return state;
  }
};

export default mapReducer;
