import { AlertsActionTypes } from "./alerts.types";

import { createSelector } from "reselect";

const selectAlerts = (state) => state.alerts;

export const selectAllAlerts = createSelector(
  [selectAlerts],
  (alerts) => alerts.allAlerts
);

export const selectUserGroupData = createSelector(
  [selectAlerts],
  (alerts) => alerts.userGroupData
);

export const selectAllNews = createSelector(
  [selectAlerts],
  (alerts) => alerts.allNews
);

export const selectInterestedWatchList = createSelector(
  [selectAlerts],
  (alerts) => alerts.interestedWatchlist
);

export const selectDisplayedAlerts = createSelector(
  [selectAlerts],
  (alerts) => alerts.displayedAlerts
);
export const selectDisplayedWatchlist = createSelector(
  [selectAlerts],
  (alerts) => alerts.displayedWatchlist
);
