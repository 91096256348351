import { createSelector } from "reselect";

const selectMap = state => state.map;

export const selectCurrentArrivalScreen = createSelector(
  [selectMap],
  map => map.currentScreen
);

export const selectMandiValues = createSelector(
  [selectMap],
  map => map.mandiStates
);

export const selectMandiRegion = createSelector(
  [selectMandiValues],
  mandiStates => mandiStates.currentRegion
);

export const selectMandiHub = createSelector(
  [selectMandiValues],
  mandiStates => mandiStates.currentHub
);

export const selectMandiMandi = createSelector(
  [selectMandiValues],
  mandiStates => mandiStates.currentMandi
);

export const selectFORValues = createSelector(
  [selectMap],
  map => map.FORStates
);

export const selectFORRegion = createSelector(
  [selectFORValues],
  FORStates => FORStates.currentRegion
);

export const selectFORHub = createSelector(
  [selectFORValues],
  FORStates => FORStates.currentHub
);

export const selectFarmerValues = createSelector(
  [selectMap],
  map => map.farmerStates
);

export const selectFarmerRegion = createSelector(
  [selectFarmerValues],
  farmerStates => farmerStates.currentRegion
);

export const selectFarmerHub = createSelector(
  [selectFarmerValues],
  farmerStates => farmerStates.currentHub
);
