import React, { Component } from "react";
import "./mandi-information-table.styles.css";
import moment from "moment";
import { Doughnut, Line } from "react-chartjs-2";

import infoImage from "../../assets/info.svg";

import {
  Table,
  InputNumber,
  Tooltip,
  message,
  Button,
  Select,
  Popover,
  Checkbox,
  Badge,
  Input,
} from "antd";

import ActionItem from "../action-item/action-item.component";
import CompetitorHoverTable from "../../components/competitor-hover-table/competitor-hover-table.component";
import RecommendationHoverTable from "../../components/recommendations-hover-table/recommendations-hover-table.component";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import {
  selectMandiRollUp,
  selectFORRollUp,
  selectFarmerRollUp,
  selectHubTableData,
  selectRemarks,
  selectSelectedBranchID,
  selectSelectedRegionID,
  selectSelectedVarietyID,
  selectSelectedHubID,
  selectHubDataPostFOR,
  selectDualRender,
  selectDecisionBranches,
  selectDecisionRegions,
} from "../../redux/buying-decision/buying-decision.selectors";
import { selectAllVarietyInfo } from "../../redux/arrival-entry/arrival-entry.selectors";

import { selectSuggestedData } from "../../redux/buying-recommendation/buying-recommendation.selectors";
import {
  setSuggestedData,
  addSuggestedData,
} from "../../redux/buying-recommendation/buying-recommendation.actions";
import {
  setHubTableData,
  setHubDataPostFOR,
  setDualRender,
  incrementDualRender,
} from "../../redux/buying-decision/buying-decision.actions";

import { selectUserData } from "../../redux/user/user.selectors";

import API from "../../api";
import { canUserEdit } from "../../auth/utils";
import { FeatureCodes } from "../../auth/data";

import Chart from "chart.js";
import { MapLayer } from "react-leaflet";
let combinedChart;

const { Option } = Select;

/**
 * @component
 * @description Reusable component to display table header.
 * @property {string} title Title of header
 * @property {string} subtitle Subtitle of header
 * @memberof Approvals
 */
const TableColumnHeader = (props) => {
  const { title, subtitle } = props;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          fontWeight: "400",
          color: "white",
          fontSize: "14px",
          whiteSpace: "nowrap",
          textAlign: "left",
        }}
      >
        {title}
      </div>
      <div
        style={{
          color: "#cbdfff",
          fontSize: "11px",
          fontWeight: "normal",
          textAlign: "left",
        }}
      >
        {subtitle}
      </div>
    </div>
  );
};

/**
 * @component
 * @description Table header component used in buying decision table.
 * @property {string} title column header title.
 * @property {string} subtitle column header subtitle. Used for displaying units.
 * @property {number} value value to be displayed.
 * @property {boolean} isPopup enable popup text.
 * @property {boolean} isPrice true if value is of price type.
 * @property {boolean} isHover display hover text having individual roll-ups.
 * @property {number} mandiValue mandi level roll-up.
 * @property {number} FORValue trader level roll-up.
 * @property {number} farmerValue farmer-level roll-up.
 * @memberof MandiInformationTable
 */
const TableNewHeader = (props) => {
  const {
    title,
    subtitle,
    value,
    isPopup,
    isPrice,
    isHover,
    mandiValue,
    FORValue,
    farmerValue,
  } = props;
  if (isHover) {
    return (
      <div className="col-container">
        <Popover
          content={
            <>
              <div>{`Mandi : ${mandiValue} ${isPrice ? "₹/MT" : "MT"}`}</div>
              <div>{`FOR : ${FORValue}  ${isPrice ? "₹/MT" : "MT"}`}</div>
              <div>{`Farmer : ${farmerValue}  ${isPrice ? "₹/MT" : "MT"}`}</div>
            </>
          }
          trigger="hover"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              color: "#BAD2F8",
              fontSize: "12px",
              whiteSpace: "break",
            }}
          >
            {title}
            {isPopup && (
              <Popover
                content={
                  <div>
                    The Arrival Total is the sum of all mandis under this region
                  </div>
                }
                trigger="hover"
                placement="bottom"
              >
                <img
                  style={{
                    width: "10px",
                    height: "10px",
                    margin: "2px 0 5px 5px",
                  }}
                  src={infoImage}
                  alt="info"
                />
              </Popover>
            )}
          </div>
          <div className="bottom-container">
            <h1>{value}</h1>
            <div
              style={{
                color: "#BAD2F8",
                fontSize: "12px",
                fontWeight: "normal",
                // marginLeft: "4px",
                marginTop: "0px",
              }}
            >
              {subtitle}
            </div>
          </div>
        </Popover>
      </div>
    );
  } else {
    //no hover text
    return (
      <div className="col-container">
        {isPopup && (
          <Popover
            content={
              <div>
                The Arrival Total is the sum of all mandis under this region
              </div>
            }
            trigger="hover"
          >
            <img
              style={{ width: "10px", height: "10px", margin: "0 0 5px 0px" }}
              src={infoImage}
              alt="info"
            />
          </Popover>
        )}
        <div
          style={{ color: "#BAD2F8", fontSize: "12px", whiteSpace: "break" }}
        >
          {title}
        </div>
        <div className="bottom-container">
          <h1>{value}</h1>
          <div
            style={{
              color: "#BAD2F8",
              fontSize: "12px",
              fontWeight: "normal",
              // marginLeft: "4px",
              marginTop: "0px",
            }}
          >
            {subtitle}
          </div>
        </div>
      </div>
    );
  }
};

//#endregion

const tableProps = {
  pagination: false,
  size: "default",
  bordered: true,
  scroll: { x: 200, y: window.innerHeight - 450 },
};

let modifiedHubData;

/**
 *
 * @component
 * @description Child component of buying decision planning page.
 */
class MandiInformationTable extends Component {
  state = {
    localHubDataForTable: [],
    currentSelectedHub: {},
    loadingOn: false,

    drawerVisible: false,
    noOfChanges: "",
    selectAll: false,
    checkAll: false,
    tableLength: 0,
  };
  // chartRef = React.createRef();

  //#region methods

  // const types used for region level table rollups.
  informationTypes = {
    YESTERDAY_MODAL_PRICE: "YESTERDAY_MODAL_PRICE",
    YESTERDAY_ARRIVALS: "YESTERDAY_ARRIVALS",
    YESTERDAY_TOTAL_BOUGHT: "YESTERDAY_TOTAL_BOUGHT",
    TODAY_MODAL_PRICE: "TODAY_MODAL_PRICE",
    TODAY_ARRIVALS: "TODAY_ARRIVALS",
    TOTAL_SUGGESTED_QUANTITY: "TOTAL_SUGGESTED_QUANTITY",
    TOTAL_LANDING_COST: "TOTAL_LANDING_COST",
  };

  /**
   * @function
   * @description function to calculate region level rollups.
   * @param {string} informationType the type of roll up to be calculated.
   * @memberof MandiInformationTable
   */
  calculateRegionLevelInformation = (informationType) => {
    if (this.props.hubTableData.region) {
      let hubData = this.props.hubTableData.region.hub_details;
      //yesterday data
      const yesterdayTotalArrivals =
        hubData &&
        hubData.reduce((acc, item) => acc + item.yesterday_arrival_qty, 0);

      const yesterdayTotalBoughtQuantity =
        hubData &&
        hubData.reduce((acc, item) => acc + item.yesterday_purchase_qty, 0);

      //today data
      const todayTotalArrivals =
        hubData &&
        hubData.reduce((acc, item) => acc + item.today_arrival_qty, 0);

      ////

      const totalSuggestedQuantity =
        hubData && hubData.reduce((acc, item) => acc + item.suggested_qty, 0);

      switch (informationType) {
        ////////////////////////////////////////////
        case this.informationTypes.YESTERDAY_MODAL_PRICE:
          const yesterdayTotalPrice = hubData
            ? hubData.reduce(
                (acc, item) =>
                  acc + item.yesterday_price * item.yesterday_arrival_qty,
                0
              )
            : 0;

          return yesterdayTotalArrivals === 0
            ? 0
            : Math.round(yesterdayTotalPrice / yesterdayTotalArrivals);

        case this.informationTypes.YESTERDAY_ARRIVALS:
          return Math.round(yesterdayTotalArrivals);
        case this.informationTypes.YESTERDAY_TOTAL_BOUGHT:
          return yesterdayTotalBoughtQuantity === 0
            ? 0
            : Math.round(yesterdayTotalBoughtQuantity);

        /////////////////////////////////////////

        // case this.informationTypes.TODAY_MODAL_PRICE:
        //   const todayTotalPrice = hubData.reduce(
        //     (acc, item) => acc + item.today_price * item.today_arrival_qty,
        //     0
        //   );

        //   return todayTotalArrivals === 0
        //     ? 0
        //     : Math.round(todayTotalPrice / todayTotalArrivals);

        // case this.informationTypes.TODAY_ARRIVALS:
        //   return Math.round(todayTotalArrivals);
        /////////////////////////////////////////////////////////////

        case this.informationTypes.TOTAL_SUGGESTED_QUANTITY:
          return Math.round(totalSuggestedQuantity);

        case this.informationTypes.TOTAL_LANDING_COST:
          const totalLandingCostSum = hubData.reduce(
            (acc, item) => acc + item.suggested_price * item.suggested_qty,
            0
          );

          return totalSuggestedQuantity === 0
            ? 0
            : Math.round(totalLandingCostSum / totalSuggestedQuantity);
        default:
          return "N/A";
      }
    } else return "N/A";
  };

  // failsafe to wait until hubtable data is available for rendering.
  // called inside componentDidMount.
  /**
   * @function
   * @description failsafe to wait until hubtable data is available for rendering.
   * @memberof MandiInformationTable
   */
  waitForHubData() {
    if (this.props.hubTableData.length !== 0) {
      setHubDataPostFOR(
        this.props.hubTableData.region &&
          this.props.hubTableData.region.hub_details
            .map((item, idx) => {
              if (
                this.props.hubTableData.region.hub_details[idx].source_type !==
                "mandi"
              ) {
                return {
                  hub_id: item.hub_id,
                  buying_decision_id: item.buying_decision_id,
                  temp_bd_id: item.temp_bd_id,
                  region_id: item.region_id,
                  variety_id: item.variety_id,
                  revised_qty: item.revised_qty,
                  revised_buy_min_price: item.revised_buy_min_price,
                  revised_buy_max_price: item.revised_buy_max_price,
                  remark_id: item.remark_id ? item.remark_id : null,
                  source_type: item.source_type,
                  claim_type: item.claim_type,
                  division: item.division,
                  hub_name: item.hub_name,
                  variety_name: item.variety_name,
                };
              }
            })
            .filter((item) => item !== undefined)
      );
      // if (this.chartRef.current) {
      //   this.buildChart();
      // }
    } else {
      setTimeout(() => {
        this.waitForHubData();
      }, 100);
    }
  }

  componentDidUpdate() {
    // if (this.chartRef.current) {
    //   for (let i = 0; i < 9; i++) {
    // this.buildChart(i);
    // this.setHubDataForTable();
    //   console.log("ref -----> ");
    // }
  }

  //#endregion

  componentDidMount() {
    this.waitForHubData();
  }

  init = 0;

  getDaysLabel = (dateArray) => {
    let list = [];
    list.push(moment().subtract(15, "day").format("MMM-DD"));
    // for (let i = 14; i > 0; i--) {
    for (let i = 0; i < dateArray.length + 1; i++) {
      // add + 1 to the lenght when you removed the x axis for better appeareance
      // list.push(moment().subtract(i, "day").format("MMM DD"));
      // list.push(moment().subtract(i, "day").format("DD"));
      list.push(moment(dateArray[i]).format("MMM-DD"));
    }
    // console.log("list------------> ", list)
    return list;
  };

  getMonth = (month) => {
    // console.log("------------> ", month)
    return "May";
  };
  // buildChart = () => {
  //   const myChartRef = this.chartRef.current && this.chartRef.current.getContext("2d");
  //   // var chartID = "myChart" + index;
  //   // const myChartRef = this.chartRef.current && document.getElementById(chartID).getContext('2d');;
  //   // const { data, options } = this.props;

  //   // console.log("this.chartRef.current", this.chartRef.current, myChartRef, this.getDaysLabel());

  //   if (typeof combinedChart !== "undefined") combinedChart.destroy();

  //   combinedChart = new Chart(myChartRef, {
  //     type: 'line',
  //     data: {
  //       labels: this.getDaysLabel(),
  //       datasets: [{
  //         label: 'Price',
  //         data: [18000, 18400, 19500, 19090, 18970, 17890, 20010, 20001, 20012, 17400, 17090, 18970, 17890, 20010, 20001],
  //         backgroundColor: [
  //           'rgba(255, 99, 132, 0.2)',
  //           'rgba(54, 162, 235, 0.2)',
  //           'rgba(30,144,255, 0.2)',
  //           'rgba(75, 192, 192, 0.2)',
  //           'rgba(153, 102, 255, 0.2)',
  //           'rgba(255, 159, 64, 0.2)',
  //           'rgba(128,0,0,0.2)',
  //           'rgba(255,99,71,0.2)',
  //           'rgba(255,127,80, 0.2)',
  //           'rgba(0,206,209, 0.2)',
  //           'rgba(0,0,205, 0.2)',
  //           'rgba(139,0,139, 0.2)',
  //           'rgba(0,191,255, 0.2)',
  //           'rgba(0,100,0, 0.2)',
  //           'rgba(34,139,34, 0.2)',
  //         ],
  //         borderColor: [
  //           'rgba(255, 99, 132, 1)',
  //           'rgba(54, 162, 235, 1)',
  //           'rgba(30,144,255, 1)',
  //           'rgba(75, 192, 192, 1)',
  //           'rgba(153, 102, 255, 1)',
  //           'rgba(255, 159, 64, 1)',
  //           'rgba(128,0,0,1)',
  //           'rgba(255,99,71,1)',
  //           'rgba(255,127,80, 1)',
  //           'rgba(0,206,209, 1)',
  //           'rgba(0,0,205, 1)',
  //           'rgba(139,0,139, 1)',
  //           'rgba(0,191,255, 1)',
  //           'rgba(0,100,0, 1)',
  //           'rgba(34,139,34, 1)',
  //         ],
  //         borderWidth: 1
  //       }]
  //     },
  //     options: {
  //       responsive: true,
  //       legend: {
  //         display: false,
  //       },
  //       tooltips: {
  //         // bodyAlign: "center",
  //         intersect: false,
  //         // enabled: false,
  //         titleFontSize: 12,
  //         titleAlign: "center",

  //         // bodyAlign: "left",

  //         callbacks: {
  //           title: function (tooltipItem, data) {
  //             console.log("tooltip Item --------> ", tooltipItem, data);
  //             let tooltipLabel = tooltipItem[0].label + "  " + tooltipItem[0].value + " ₹/MT";
  //             return tooltipLabel;
  //           },
  //           label: function (tooltipItem, data) {
  //             return "";
  //           },
  //         },
  //         mode: "index",
  //         backgroundColor: "white",
  //         titleFontColor: "#313131",
  //         borderColor: "#9d9d9d",
  //         borderWidth: 1,
  //         bodyFontColor: "#575757",
  //         displayColors: false,
  //       },
  //       hover: {
  //         mode: "nearest",
  //         intersect: true,
  //       },
  //       elements: {
  //         line: {
  //           tension: 0.1,
  //         },
  //       },
  //       scales: {
  //         y: {
  //           beginAtZero: true
  //         }
  //       }
  //     }
  //   });
  // };

  render() {
    // console.log("Selected hub", this.state.currentSelectedHub);
    const ConfigOptions = {
      maxCount: 1,
    };
    message.config(ConfigOptions);

    this.state.checkAll =
      this.props.hubTableData &&
      this.props.hubTableData.region &&
      this.props.hubTableData.region.hub_details.length !== 0 &&
      (this.props.hubTableData &&
        this.props.hubTableData.region &&
        this.props.hubTableData.region.hub_details &&
        this.props.hubTableData.region.hub_details.length &&
        this.props.hubTableData.region.hub_details.filter(
          (obj) => obj.checked === true
        ).length) ===
        (this.props.hubTableData &&
          this.props.hubTableData.region &&
          this.props.hubTableData.region.hub_details.length);

    const sampleData = {
      data: {
        division: "ABD",
        recommendations: [
          {
            name: "A",
            type: "trader",
            quantity: 1000,
            min_price: 1200,
            max_price: 2200,
          },
          {
            name: "B",
            type: "olakka",
            quantity: 3000,
            min_price: 3200,
            max_price: 4200,
          },
        ],
      },
    };

    // console.log("Rendering mandiinfo table", this.props.hubTableData);
    const {
      userData,
      hubTableData,
      remarksList,
      selectedBranchID,
      selectedRegionID,
      selectedVarietyID,
      setHubTableData,
      setRemarkHubRowFORPost,
      setHubDataPostFOR,
    } = this.props;

    // console.log("MANDIDATALOCAL :", this.state.mandiDataLocal);

    let hubData;

    const user = userData;

    const allRemarks = remarksList.length !== 0 ? remarksList : [];

    const PRICE_TYPES = {
      MANDI_LEVEL: "MANDI_LEVEL",
      FOR_LEVEL: "FOR_LEVEL",
      FARMER_LEVEL: "FARMER_LEVEL",
      COMBINED: "COMBINED",
    };

    //#region all functions in use

    const getTotalQuantity = (label) => {
      let totalQuantity = 0;

      if (
        this.props.hubTableData.length !== 0 &&
        this.props.hubTableData.region &&
        this.props.hubTableData.region.hub_details.length !== 0
      ) {
        totalQuantity = this.props.hubTableData.region.hub_details.reduce(
          (acc, item) => acc + item[label],
          0
        );
      }
      return parseInt(totalQuantity);
    };

    const getYesterdayModalPrice = () => {
      let modalPrice = 0;
      let totalPurchaseQuantity = 0;

      if (
        this.props.hubTableData.length !== 0 &&
        this.props.hubTableData.region &&
        this.props.hubTableData.region.hub_details.length !== 0
      ) {
        totalPurchaseQuantity = getTotalQuantity("yesterday_purchase_qty");

        modalPrice =
          totalPurchaseQuantity === 0
            ? 0
            : this.props.hubTableData.region.hub_details.reduce(
                (acc, item) =>
                  acc + item.yesterday_purchase_qty * item.yesterday_price,
                0
              ) / totalPurchaseQuantity;
      }
      return parseInt(modalPrice);
    };

    const setModalLC = (isMin) => {
      let minModalLC = 0;
      let maxModalLC = 0;

      if (
        this.props.hubTableData.length !== 0 &&
        this.props.hubTableData.region &&
        this.props.hubTableData.region.hub_details.length !== 0
      ) {
        let totalRevisedQuantity =
          this.props.hubTableData.region.hub_details.reduce(
            (acc, item) => acc + item.revised_qty,
            0
          );

        minModalLC =
          totalRevisedQuantity === 0
            ? 0
            : this.props.hubTableData.region.hub_details.reduce(
                (acc, item) =>
                  acc + item.revised_qty * item.revised_buy_min_price,
                0
              ) / totalRevisedQuantity;
        maxModalLC =
          totalRevisedQuantity === 0
            ? 0
            : this.props.hubTableData.region.hub_details.reduce(
                (acc, item) =>
                  acc + item.revised_qty * item.revised_buy_max_price,
                0
              ) / totalRevisedQuantity;

        // console.log("total quantity", maxModalLC);

        if (isMin) {
          return parseInt(minModalLC);
        } else {
          return parseInt(maxModalLC);
        }
      }

      return 0;
    };

    //#region functions to set min and max LC at region level
    /**
     * @description Get the minimum range of landing cost
     * @param {string} priceType type of price (Mandi/Trader/Farmer/All)
     * @returns {number} minimum landing cost limit
     * @memberof MandiInformationTable
     */
    const setMinimumLC = (priceType) => {
      // console.log("92534956349536943583");
      let combinedMinPrice = Infinity,
        mandisMinPrice = Infinity,
        FORMinPrice = Infinity,
        farmerMinPrice = Infinity;

      if (
        this.props.hubTableData.length !== 0 &&
        this.props.hubTableData.region &&
        this.props.hubTableData.region.hub_details.length !== 0
      ) {
        this.props.hubTableData.region.hub_details.map((item) => {
          if (
            item.revised_buy_min_price < combinedMinPrice &&
            item.revised_buy_min_price !== 0
          ) {
            combinedMinPrice = item.revised_buy_min_price;
          }
          if (
            item.revised_buy_min_price < mandisMinPrice &&
            item.source_type === "mandi" &&
            item.revised_buy_min_price !== 0
          ) {
            mandisMinPrice = item.revised_buy_min_price;
          }
          if (
            item.revised_buy_min_price < FORMinPrice &&
            item.source_type === "trader" &&
            item.revised_buy_min_price !== 0
          ) {
            FORMinPrice = item.revised_buy_min_price;
          }
          if (
            item.revised_buy_min_price < farmerMinPrice &&
            item.source_type === "farmer" &&
            item.revised_buy_min_price !== 0
          ) {
            farmerMinPrice = item.revised_buy_min_price;
          }
        });

        switch (priceType) {
          case PRICE_TYPES.MANDI_LEVEL:
            return mandisMinPrice === Infinity ? 0 : Math.round(mandisMinPrice);
          case PRICE_TYPES.FOR_LEVEL:
            return FORMinPrice === Infinity ? 0 : Math.round(FORMinPrice);
          case PRICE_TYPES.FARMER_LEVEL:
            return farmerMinPrice === Infinity ? 0 : Math.round(farmerMinPrice);
          case PRICE_TYPES.COMBINED:
            return combinedMinPrice === Infinity
              ? 0
              : Math.round(combinedMinPrice);

          default:
            break;
        }
      } else {
        return 0;
      }
    };

    /**
     * @description Get the maximum range of landing cost
     * @param {string} priceType type of price (Mandi/Trader/Farmer/All)
     * @returns {number} maximum landing cost limit
     * @memberof MandiInformationTable
     */
    const setMaximumLC = (priceType) => {
      let combinedMaxPrice = 0,
        mandisMaxPrice = 0,
        FORMaxPrice = 0,
        farmerMaxPrice = 0;

      if (
        this.props.hubTableData.length !== 0 &&
        this.props.hubTableData.region &&
        this.props.hubTableData.region.hub_details.length !== 0
      ) {
        this.props.hubTableData.region.hub_details.map((item) => {
          if (item.revised_buy_max_price > combinedMaxPrice) {
            combinedMaxPrice = item.revised_buy_max_price;
          }
          if (
            item.revised_buy_max_price > mandisMaxPrice &&
            item.source_type === "mandi"
          ) {
            mandisMaxPrice = item.revised_buy_max_price;
          }
          if (
            item.revised_buy_max_price > FORMaxPrice &&
            item.source_type === "trader"
          ) {
            FORMaxPrice = item.revised_buy_max_price;
          }
          if (
            item.revised_buy_max_price > farmerMaxPrice &&
            item.source_type === "farmer"
          ) {
            farmerMaxPrice = item.revised_buy_max_price;
          }
        });

        switch (priceType) {
          case PRICE_TYPES.MANDI_LEVEL:
            return Math.round(mandisMaxPrice);
          case PRICE_TYPES.FOR_LEVEL:
            return Math.round(FORMaxPrice);
          case PRICE_TYPES.FARMER_LEVEL:
            return Math.round(farmerMaxPrice);
          case PRICE_TYPES.COMBINED:
            return Math.round(combinedMaxPrice);

          default:
            break;
        }
      } else {
        return 0;
      }
    };
    //#endregion

    // TODO: maybe needed in the future. use to set the min and max ranges for landing cost.
    //       verify whether to multiply by 10 to factor for quintals to MT - multiplied by 10.
    const getVarietyMinPrice = () => {
      const minPrice = this.props.allVarietyInfo.find(
        (item) => item.variety_id === this.props.selectedVarietyID
      ).variety_price_min;
      // console.log("variety min price : ", minPrice);
      return minPrice * 10;
    };
    const getVarietyMaxPrice = () => {
      const maxPrice = this.props.allVarietyInfo.find(
        (item) => item.variety_id === this.props.selectedVarietyID
      ).variety_price_max;
      // console.log("variety max price : ", maxPrice);
      return maxPrice * 10;
    };

    if (hubTableData) {
      if (hubTableData.region) {
        this.hubdata = [];
        hubData = hubTableData.region.hub_details;

        //save all FOR hubs into redux store
        modifiedHubData =
          hubTableData.region.hub_details &&
          hubTableData.region.hub_details
            .map((item, idx) => {
              if (
                this.props.hubTableData.region.hub_details[idx].source_type !==
                "mandi"
              ) {
                return {
                  hub_id: item.hub_id,
                  buying_decision_id: item.buying_decision_id,
                  temp_bd_id: item.temp_bd_id,
                  region_id: item.region_id,
                  variety_id: item.variety_id,
                  revised_qty: item.revised_qty,
                  revised_buy_min_price: item.revised_buy_min_price,
                  revised_buy_max_price: item.revised_buy_max_price,
                  remark_id: item.remark_id ? item.remark_id : null,
                  source_type: item.source_type,
                  claim_type: item.claim_type,
                  division: item.division,
                  hub_name: item.hub_name,
                  variety_name: item.variety_name,
                };
              }
            })
            .filter((item) => item !== undefined);

        if (this.init < 2) {
          //rendering twice for updating data
          setHubDataPostFOR(modifiedHubData);
          this.init++;
        }
      }
    }

    const skipped = (ctx, value) => {
      // console.log("Skipped CTX =>", ctx, "value", ctx.datasets, ctx.skipped)
      // ctx.p0.skip || ctx.p1.skip ? value : undefined;
    };
    const down = (ctx, value) => {
      // console.log("DOWn CTX =>", ctx, "value", ctx.p1)
      // ctx.p0.parsed.y > ctx.p1.parsed.y ? value : undefined;
    };

    //Default chart to show while loading actual data
    const defaultTrendGraphData = (priceList, date_list) => {
      // console.log("Price List ====> ", priceList, "Date List ====>", date_list);
      // priceList = [25.56, 25.79, 25.85, 25.99, 26.79, 26.21, 26.89, 26, 26, 26.5, 26.6, 26.1];
      // Prod Price List
      // priceList = [18.22, null, 18.42, 18.38, null, 18.44, 18.38, 18.46, 18.28, 18.37, null, 18.36];
      // priceList = [18.21,null,18.43,18.37,null,18.5,18.46,18.57,18.46,18.45,null,18.45];
      // priceList = [17.89,17.89,18.06,18.16,null,18.19,18.11,18.13,18.14,18.14, null, 18.14];
      // priceList = [18.7,18.7,18.78,18.82,null,18.88,18.94,18.95,null,18.97,null,19.0];
      // priceList = [22.7, null, 22.6,23,null,null,null,null,null,23.2,null,null];
      // priceList = [22.7, 22.9, 22.79,22.95,null,22.97,23.08,23.22,23.26,23.31,null,23.36];

      let modifiedPriceList = [];
      modifiedPriceList.push(null);
      for (let i = 0; i < priceList.length; i++) {
        modifiedPriceList.push(priceList[i]);
      }

      let minValList = modifiedPriceList.filter(function (val) {
        return val !== null;
      });
      // console.log("--------<>>>>>-------------", minValList);
      let minVal = Math.min(...minValList);
      let maxVal = Math.max(...modifiedPriceList);

      modifiedPriceList.push(null);
      modifiedPriceList.push(minVal - 0.01);
      modifiedPriceList.push(maxVal + 0.01);
      // modifiedPriceList = [22.56, 25.79, 23.55, 25.79, 21.79, 23.21, 20.79, 21.21, 23.8, 21.5, 23.5, 24.1];
      // console.log("Modified Price List", minVal, maxVal, modifiedPriceList);
      const defaultTrendGraph = {
        datasets: [
          {
            fill: false,
            // data: priceList,
            // data: [null, 25.56, 25.79, 25.85, 25.99, 26.79, 26.21, 26.89, 26, 26, 26.5, 26.6, 26.1, null, 25, 27],
            // data: [null, 25.56, 25.79, 25.85, 25.99, 21.79, 21.21, 21.89, 21, 22, 22.5, 22.6, 23.1],
            data: modifiedPriceList,
            backgroundColor: "rgba(255, 99, 132, 1)",
            borderWidth: 0.6,
            borderColor: "rgba(255, 99, 132, 1)",
          },
        ],
        labels:
          date_list && date_list.length ? this.getDaysLabel(date_list) : [],
        // labels: [],
      };
      return defaultTrendGraph;
    };

    const optionsTrend = {
      responsive: true,
      legend: {
        display: false,
      },
      fill: false,
      // fill: true,
      tooltips: {
        // bodyAlign: "center",
        intersect: false,
        // enabled: false,
        titleFontSize: 12,
        titleAlign: "center",

        // bodyAlign: "left",

        callbacks: {
          title: function (tooltipItem, data) {
            // console.log("tooltip Item --------> ", tooltipItem, data);
            let tooltipLabel =
              tooltipItem[0].label + "  " + tooltipItem[0].value + "k ₹/MT";
            return tooltipLabel;
          },
          label: function (tooltipItem, data) {
            return "";
          },
        },
        mode: "index",
        backgroundColor: "white",
        titleFontColor: "#313131",
        borderColor: "#9d9d9d",
        borderWidth: 1,
        bodyFontColor: "#575757",
        displayColors: false,
      },
      hover: {
        mode: "nearest",
        intersect: true,
      },
      elements: {
        line: {
          tension: 0,
        },
      },
      scales: {
        xAxes: [
          {
            display: false, // uncomment to remove the x axis
            gridLines: {
              display: false,
              drawBorder: false,
            },
          },
        ],
        yAxes: [
          {
            type: "linear",
            display: false,
            gridLines: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              beginAtZero: false,
              // stepSize: 0.10,
              // min: 14,
              // max: 30,
              // fontSize: 13,
            },
            // marginTop:10
          },
        ],
      },
    };

    /**
     * @description function to set the hub level table data
     * @memberof MandiInformationTable
     */
    const setHubDataForTable = () => {
      // console.log(
      //   hubTableData, "setting table data- hub level ************************ : ",
      //   hubData
      // );
      // if (hubDataSent[0].competitor_bids === undefined) {
      //   this.setState({ localHubDataForTable: hubDataSent });
      //   // setLocalHubDataForTable(hubDataSent)
      // } else {
      // if (this.chartRef.current) {
      console.log(hubData);
      const tableData =
        hubData &&
        hubData.map((hubItem, idx) => {
          const modifiedKey =
            // hubItem.source_type +
            // "_" +
            hubItem.hub_id +
            "_" +
            hubItem.claim_type +
            "_" +
            hubItem.division +
            "_" +
            hubItem.variety_id +
            "_" +
            hubItem.region_id;

          // console.log("-----> HUB DATA", hubItem, "------>  HUB Index ", idx);
          return {
            key: modifiedKey,
            buying_decision_id: hubItem.buying_decision_id,
            temp_bd_id: hubItem.temp_bd_id,
            data: hubItem,
            hub: hubItem.hub_name,
            variety: hubItem.variety_name,
            regionID: hubItem.region_id,
            varietyID: hubItem.variety_id,
            //Yesterday's data
            modalPriceYesterday: Math.round(hubItem.yesterday_price),
            // arrivalsYesterday: Math.round(hubItem.yesterday_arrival_qty),
            qtyBoughtYesterday:
              hubItem.yesterday_purchase_qty !== null
                ? Math.round(hubItem.yesterday_purchase_qty)
                : "0",
            price_list: hubItem.price_list,
            date_list: hubItem.date_list,

            //Today's data
            graphData: (
              <div style={{ margin: "0px auto" }}>
                {hubItem.price_list.length &&
                hubItem.date_list.length &&
                hubItem.price_list.reduce((a, b) => a + b, 0) ? (
                  <Line
                    id="trendChart"
                    // data={() => defaultTrendGraphData([18000, 18400, 19500, 19090, 18970, 17890, 20010, 20001, 20012, 17400, 17590, 18970, 17890, 20010])}
                    data={() =>
                      defaultTrendGraphData(
                        hubItem.price_list,
                        hubItem.date_list
                      )
                    }
                    height={75}
                    width={120}
                    options={optionsTrend}
                  />
                ) : (
                  "No Trending Price"
                )}
              </div>
            ),

            // modalPriceToday: Math.round(hubItem.today_price),
            // arrivalsToday:
            //   hubItem.today_arrival_qty !== null
            //     ? Math.round(hubItem.today_arrival_qty)
            //     : "0",
            totalSupply: hubItem.total_supply,
            suggestedQty: hubItem.suggested_qty
              ? Math.round(hubItem.suggested_qty)
              : 0,
            revisedQty: Math.round(hubItem.revised_qty),

            srp: hubItem.suggested_price
              ? Math.round(hubItem.suggested_price)
              : 0,
            minPlanned: Math.round(hubItem.revised_buy_min_price),
            maxPlanned: Math.round(hubItem.revised_buy_max_price),

            remarkId: Math.round(hubItem.remark_id),
            manualRemark: hubItem.manual_remark,
            actions: { hubID: hubItem.hub_id },
            checked: hubItem.checked !== undefined ? hubItem.checked : false,
            edited: hubItem.edited,

            approvals: {
              approval1: hubItem.approval1,
              approval2: hubItem.approval2,
              approver1Name: hubItem.approver1_name,
              approver2Name: hubItem.approver2_name,
              comment1: hubItem.comment1,
              comment2: hubItem.comment2,
            },

            mandiBuying: {
              mandiData: hubItem.mandi_list,
              isMandi: hubItem.source_type === "mandi" ? true : false,
              sourceType: hubItem.source_type,
            },
            // competitors: hubItem.competitor_bids.map((competitor) => {
            //   return {
            //     key: competitor.competitor_bid_id,
            //     competitor_name: competitor.competitor_name,
            //     competitor_min_price: competitor.competitor_min_price,
            //     competitor_max_price: competitor.competitor_max_price,
            //     purchase_plan: competitor.purchase_plan,
            //     name: competitor.mandi_name
            //   };
            // }),
          };
        });
      // console.log("Renderin table DAta ------->", tableData && tableData.length);
      // tableData && tableData.length && this.setState({ tableLength: tableData.length });
      return tableData;
      // this.setState({ localHubDataForTable: tableData });
      // }
      // }
      // else {
      //   setInterval(() => {
      //     console.log("---->");
      //     setHubDataForTable();
      //   }, 500)
      // }
    };

    // hubData && hubData.length ? setHubDataForTable(hubData) : ""

    //#endregion

    return (
      <div className="table-container">
        <div className="hub-table">
          <Table
            key={selectedVarietyID + "_" + selectedRegionID}
            {...tableProps}
            columns={[
              {
                title: "Hub",
                key: "hub1",
                width: 100,
                // fixed: 'left',
                children: [
                  {
                    title: "",
                    key: "hub2",
                    width: 100,
                    // fixed: 'left',
                    children: [
                      {
                        title: <div style={{ fontSize: "14px" }}>Summary</div>,
                        dataIndex: "hub",
                        key: "hub3",
                        width: 100,
                        // fixed: 'left',
                      },
                    ],
                  },
                ],
              },
              {
                title: "Variety",
                key: "variety1",
                width: 100,
                // fixed: 'left',
                children: [
                  {
                    title: "",
                    key: "variety2",
                    width: 100,
                    // fixed: 'left',
                    children: [
                      {
                        // title: <div style={{ fontSize: "14px" }}></div>,
                        dataIndex: "variety",
                        key: "variety3",
                        width: 100,
                        // fixed: 'left',
                      },
                    ],
                  },
                ],
              },
              {
                title: "Previous Day",
                children: [
                  {
                    title: (
                      <TableNewHeader
                        isPrice
                        title="Purchase Price"
                        subtitle={"₹/MT"}
                      />
                    ),
                    key: "modalPriceYesterday2",
                    width: 80,
                    children: [
                      {
                        title: (
                          <TableNewHeader
                            isPrice
                            value={Math.round(getYesterdayModalPrice())
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          />
                        ),
                        dataIndex: "modalPriceYesterday",
                        key: "modalPriceYesterday_value",
                        width: 80,
                      },
                    ],
                  },

                  {
                    title: (
                      <TableNewHeader title="Qty Bought" subtitle={"MT"} />
                    ),
                    key: "qtyBoughtYesterday",
                    width: 80,
                    children: [
                      {
                        title: (
                          <TableNewHeader
                            value={Math.round(
                              getTotalQuantity("yesterday_purchase_qty")
                            )
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          />
                        ),
                        dataIndex: "qtyBoughtYesterday",
                        key: "qtyBoughtYesterday_value",
                        width: 80,
                      },
                    ],
                  },
                ],
              },

              {
                title: "Mandi Arrival Trend",
                children: [
                  // {
                  //   title: (
                  //     <TableNewHeader
                  //       isPrice
                  //       title="Modal Price"
                  //       subtitle={"₹/MT"}
                  //     />
                  //   ),
                  //   key: "modalPriceToday",
                  //   children: [
                  //     {
                  //       title: (
                  //         <TableNewHeader
                  //           isPrice
                  //           value={Math.round(this.props.FORRollUp.today_price)
                  //             .toString()
                  //             .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  //         />
                  //       ),
                  //       dataIndex: "modalPriceToday",
                  //       key: "modalPriceToday_value",
                  //       width: 85,
                  //     },
                  //   ],
                  // },
                  // {
                  //   title: <TableNewHeader title="Arrivals" subtitle={"MT"} />,

                  //   key: "arrivalsToday",
                  //   children: [
                  //     {
                  //       title: (
                  //         <TableNewHeader
                  //           value={Math.round(
                  //             this.props.FORRollUp.today_arrival_qty
                  //           )
                  //             .toString()
                  //             .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  //         />
                  //       ),
                  //       dataIndex: "arrivalsToday",
                  //       key: "arrivalsToday_value",
                  //       width: 70,
                  //     },
                  //   ],
                  // },
                  {
                    // title: (
                    //   <TableNewHeader isPrice title="Trending Price" /> //subtitle="₹/MT"
                    // ),
                    key: "price",
                    width: 150,

                    children: [
                      {
                        // title: (
                        //   <TableNewHeader
                        //     isPrice
                        //     value={setModalLC(true)
                        //       .toString()
                        //       .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        //   />
                        // ),
                        dataIndex: "graphData", //price
                        key: "price_value",
                        width: 150,
                        // render: (quantity, record) => {
                        //   console.log("Checking rendor of table =======> ", this.chartRef.current);
                        //   // this.chartRef.current && this.buildChart();
                        //   return (
                        //     // <div
                        //     //   className={`quantity-container ${record.mandiBuying.isMandi ||
                        //     //     !canUserEdit(
                        //     //       user,
                        //     //       selectedBranchID,
                        //     //       FeatureCodes.BUYING_DECISION_SUBMIT
                        //     //     )
                        //     //     ? "disable-field"
                        //     //     : ""
                        //     //     }`}
                        //     // >
                        //     <div style={{ margin: "10px auto" }}>
                        //       <canvas
                        //         className="chartjs-render-monitor"
                        //         style={{
                        //           display: "flex",
                        //           width: "200px !important",
                        //           height: "120px !important",
                        //         }}
                        //         id={`myChart`}
                        //         ref={this.chartRef}
                        //       />
                        //     </div>
                        //     // </div>
                        //   );
                        // },
                      },
                    ],
                  },

                  // {
                  //   title: <TableNewHeader title="Competitors" />,

                  //   key: "competitor",
                  //   children: [
                  //     {
                  //       title: "",
                  //       dataIndex: "competitors",
                  //       key: "competitor_value",
                  //       width: 90,
                  //       render: (competitors, record) => {
                  //         // console.log("RECORD:", record);
                  //         return record.competitors.length !== 0 ? (
                  //           <Popover
                  //             overlayClassName="competitor-hover-container"
                  //             content={
                  //               <CompetitorHoverTable
                  //                 isMT
                  //                 data={record.competitors}
                  //                 isBuyingDecision
                  //               />
                  //             }
                  //             title="Competitors"
                  //           >
                  //             {record.competitors.filter((item) => item.key)
                  //               .length ? (
                  //                 <Badge
                  //                   count={record.competitors.filter((item) => item.key).length}
                  //                   style={{
                  //                     backgroundColor: "#fff",
                  //                     color: "#202020",
                  //                     boxShadow: "0 0 0 1px #d9d9d9 inset",
                  //                     cursor: "pointer",
                  //                   }}
                  //                 />
                  //               ) : null}
                  //           </Popover>
                  //         ) : null;
                  //       },
                  //     },
                  //   ],
                  // },
                ],
              },
              {
                title: "Total Supply",
                key: "totalSupply",
                width: 100,
                children: [
                  {
                    title: "",
                    key: "totalSupply2",
                    width: 100,
                    children: [
                      {
                        // title: <div style={{ fontSize: "14px" }}></div>,
                        dataIndex: "totalSupply",
                        key: "totalSupply3",
                        width: 100,
                      },
                    ],
                  },
                ],
              },
              {
                title: "Buying Plan",
                children: [
                  {
                    title: <TableNewHeader title="Claim Type" />,
                    key: "mandiBuying",
                    width: 90,

                    children: [
                      {
                        title: <TableNewHeader value="" />,
                        dataIndex: "mandiBuying",
                        key: "mandiBuying_value",
                        width: 90,
                        render: (record, rowRecord) => {
                          return (
                            <div>
                              <div>{rowRecord.data.claim_type}</div>
                            </div>
                          );

                          // if (record.sourceType === "mandi")
                          //   return (
                          //     <div className="mandi-button" onClick={() => {}}>
                          //       <div className="mandi-button-container">M</div>
                          //     </div>
                          //   );

                          // if (record.sourceType === "trader")
                          //   return (
                          //     <div className="for-button">
                          //       <div className="for-button-container">T</div>
                          //     </div>
                          //   );

                          // if (record.sourceType === "farmer")
                          //   return (
                          //     <div className="for-button">
                          //       <div className="for-button-container">F</div>
                          //     </div>
                          //   );
                        },
                      },
                    ],
                  },
                  // {
                  //   title: <TableNewHeader title="Division" />,

                  //   key: "srp",
                  //   width: 80,
                  //   children: [
                  //     {
                  //       title: <TableNewHeader title="" />,
                  //       dataIndex: "srp",
                  //       key: "srp_value",
                  //       width: 80,
                  //       render: (record, rowRecord) => {
                  //         return (
                  //           <div>
                  //             <div>{rowRecord.data.division}</div>
                  //           </div>
                  //         );
                  //       },
                  //     },
                  //   ],
                  // },
                  {
                    title: <TableNewHeader title="Quantity" subtitle="MT" />,
                    key: "suggestedQty",
                    width: 80,

                    children: [
                      {
                        title: (
                          <TableNewHeader
                            value={Math.round(getTotalQuantity("revised_qty"))
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          />
                        ),
                        dataIndex: "suggestedQty",
                        key: "suggestedQty_value",
                        width: 80,
                        render: (suggestedQty, record) => {
                          const conditionalProps = record.mandiBuying.isMandi
                            ? {
                                value:
                                  record.revisedQty >= 0
                                    ? record.revisedQty
                                    : suggestedQty,
                              }
                            : null;
                          return (
                            <div
                              className={`quantity-container ${
                                record.mandiBuying.isMandi ||
                                !canUserEdit(
                                  user,
                                  selectedBranchID,
                                  FeatureCodes.BUYING_DECISION_SUBMIT
                                )
                                  ? "disable-field"
                                  : ""
                              }`}
                            >
                              <Tooltip title={`Suggested: ${suggestedQty} MT`}>
                                <InputNumber
                                  disabled={
                                    record.mandiBuying.isMandi ||
                                    !canUserEdit(
                                      user,
                                      selectedBranchID,
                                      FeatureCodes.BUYING_DECISION_SUBMIT
                                    )
                                  }
                                  formatter={(value) =>
                                    `${value}`.replace(
                                      /\B(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )
                                  }
                                  onChange={(value) => {
                                    this.props.setQuantityHubRowFORPost(
                                      record.actions.hubID,
                                      value,
                                      null,
                                      record.mandiBuying.sourceType,
                                      record
                                    );
                                  }}
                                  min={0}
                                  placeholder={`${
                                    record.revisedQty >= 0
                                      ? record.revisedQty
                                      : suggestedQty
                                  }`}
                                  {...conditionalProps}
                                  defaultValue={
                                    record.revisedQty >= 0
                                      ? record.revisedQty
                                      : suggestedQty
                                  }
                                />
                              </Tooltip>
                            </div>
                          );
                        },
                      },
                    ],
                  },
                  {
                    title: (
                      <TableNewHeader
                        isPrice
                        title="Min Price"
                        subtitle="₹/MT"
                      />
                    ),
                    key: "minPlanned",
                    width: 80,

                    children: [
                      {
                        title: (
                          <TableNewHeader
                            isPrice
                            value={setModalLC(true)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          />
                        ),
                        dataIndex: "minPlanned",
                        key: "minPlanned_value",
                        width: 80,
                        render: (quantity, record) => {
                          const conditionalProps = record.mandiBuying.isMandi
                            ? {
                                value: quantity,
                              }
                            : null;
                          return (
                            <div
                              onClick={() => console.log("record : ", record)}
                              className={`quantity-container ${
                                record.mandiBuying.isMandi ||
                                !canUserEdit(
                                  user,
                                  selectedBranchID,
                                  FeatureCodes.BUYING_DECISION_SUBMIT
                                )
                                  ? "disable-field"
                                  : ""
                              }`}
                            >
                              <InputNumber
                                disabled={
                                  record.mandiBuying.isMandi ||
                                  !canUserEdit(
                                    user,
                                    selectedBranchID,
                                    FeatureCodes.BUYING_DECISION_SUBMIT
                                  )
                                }
                                formatter={(value) =>
                                  `${value}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )
                                }
                                onChange={(value) => {
                                  this.props.setTLCHubRowFORPost(
                                    record.actions.hubID,
                                    value,
                                    true,
                                    record.mandiBuying.sourceType,
                                    record
                                  );
                                }}
                                min={0}
                                {...conditionalProps}
                                defaultValue={record.minPlanned}
                              />
                            </div>
                          );
                        },
                      },
                    ],
                  },

                  {
                    title: (
                      <TableNewHeader
                        isPrice
                        title="Max Price"
                        subtitle="₹/MT"
                      />
                    ),
                    dataIndex: "maxPlanned",
                    key: "maxPlanned",
                    width: 80,

                    children: [
                      {
                        title: (
                          <TableNewHeader
                            isPrice
                            value={setModalLC(false)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          />
                        ),
                        dataIndex: "maxPlanned",
                        key: "maxPlanned_value",
                        width: 80,
                        render: (quantity, record) => {
                          const conditionalProps = record.mandiBuying.isMandi
                            ? {
                                value: quantity,
                              }
                            : null;
                          return (
                            <div
                              className={`quantity-container ${
                                record.mandiBuying.isMandi ||
                                !canUserEdit(
                                  user,
                                  selectedBranchID,
                                  FeatureCodes.BUYING_DECISION_SUBMIT
                                )
                                  ? "disable-field"
                                  : ""
                              }`}
                            >
                              <InputNumber
                                disabled={
                                  record.mandiBuying.isMandi ||
                                  !canUserEdit(
                                    user,
                                    selectedBranchID,
                                    FeatureCodes.BUYING_DECISION_SUBMIT
                                  )
                                }
                                formatter={(value) =>
                                  `${value}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )
                                }
                                onChange={(value) => {
                                  this.props.setTLCHubRowFORPost(
                                    record.actions.hubID,
                                    value,
                                    false,
                                    record.mandiBuying.sourceType,
                                    record
                                  );
                                }}
                                min={0}
                                {...conditionalProps}
                                defaultValue={record.maxPlanned}
                              />
                            </div>
                          );
                        },
                      },
                    ],
                  },
                  {
                    title: <TableNewHeader title="Remarks" />,
                    key: "remarks",
                    width: 270,

                    children: [
                      {
                        title: <TableNewHeader title="" />,
                        dataIndex: "remarks",
                        key: "remarks_value",
                        width: 270,
                        render: (remarks, record) => (
                          <div
                            className={`select-remarks ${
                              record.mandiBuying.isMandi ||
                              !canUserEdit(
                                user,
                                selectedBranchID,
                                FeatureCodes.BUYING_DECISION_SUBMIT
                              )
                                ? "disable-field"
                                : ""
                            }`}
                          >
                            {!record.mandiBuying.isMandi ? (
                              <Select
                                className="remarks-select"
                                disabled={
                                  record.mandiBuying.isMandi ||
                                  !canUserEdit(
                                    user,
                                    selectedBranchID,
                                    FeatureCodes.BUYING_DECISION_SUBMIT
                                  )
                                }
                                defaultValue={
                                  record.remarkId
                                    ? allRemarks.find(
                                        (item) =>
                                          item.remark_id === record.remarkId
                                      ).remark
                                    : "Add a remark"
                                }
                                onSelect={(value) =>
                                  setRemarkHubRowFORPost(
                                    record.actions.hubID,
                                    value,
                                    record.mandiBuying.sourceType,
                                    record
                                  )
                                }
                              >
                                {allRemarks.map((remarkItem, idx) => (
                                  <Option
                                    key={remarkItem.remark_id}
                                    value={remarkItem.remark_id}
                                  >
                                    {remarkItem.remark}
                                  </Option>
                                ))}
                              </Select>
                            ) : null}
                            {record.remarkId === 13 ? (
                              <Input
                                name="manualRemark"
                                disabled={
                                  record.mandiBuying.isMandi ||
                                  !canUserEdit(
                                    user,
                                    selectedBranchID,
                                    FeatureCodes.BUYING_DECISION_SUBMIT
                                  )
                                }
                                onChange={(e) =>
                                  setRemarkHubRowFORPost(
                                    record.actions.hubID,
                                    e.target.value,
                                    record.mandiBuying.sourceType,
                                    record,
                                    true
                                  )
                                }
                                maxLength={30}
                                value={record.manualRemark}
                              />
                            ) : null}
                          </div>
                        ),
                      },
                    ],
                  },
                ],
              },
              {
                title: "Submit",
                dataIndex: "actions",
                key: "actions",
                width: 85,
                render: (actions, record) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ whiteSpace: "nowrap" }}>
                        <div>
                          {!record.mandiBuying.isMandi && (
                            <ActionItem
                              canUserSubmit={canUserEdit(
                                user,
                                selectedBranchID,
                                FeatureCodes.BUYING_DECISION_SUBMIT
                              )}
                              postBuyingDecisionHub={
                                this.props.postBuyingDecisionHub
                              }
                              rowRecord={record}
                              postHubRowId={record.actions.hubID}
                              sourceType={record.mandiBuying.sourceType}
                              srp={record.srp}
                            />
                          )}
                        </div>
                      </div>

                      <Popover
                        overlayClassName="competitor-hover-container"
                        content={
                          <RecommendationHoverTable isMT record={record} />
                        }
                        title={
                          record.data.recommendations.length !== 0
                            ? "Recommendations"
                            : "No Recommendations"
                        }
                      >
                        <img
                          style={{
                            width: "10px",
                            height: "10px",
                            margin: "2px 0 5px 5px",
                          }}
                          src={infoImage}
                          alt="recommendations"
                        />
                      </Popover>
                    </div>
                  );
                },
              },
              {
                title: (
                  <div
                    style={{
                      fontSize: "12px",
                      color: "white",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "100%",
                      paddingRight: "13px",
                    }}
                  >
                    <TableColumnHeader title="Action" />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        paddingLeft: "6px",
                      }}
                    >
                      {/* {console.log("-----------> Check All", this.props.hubTableData && this.props.hubTableData.region && this.props.hubTableData.region.hub_details && this.props.hubTableData.region.hub_details.length && this.props.hubTableData.region.hub_details.filter((obj) => obj.checked === true).length, "===>",
                        (this.props.hubTableData && this.props.hubTableData.region && this.props.hubTableData.region.hub_details.length && (this.props.hubTableData && this.props.hubTableData.region && this.props.hubTableData.region.hub_details && this.props.hubTableData.region.hub_details.length && this.props.hubTableData.region.hub_details.filter((obj) => obj.checked === true).length) === (this.props.hubTableData && this.props.hubTableData.region && this.props.hubTableData.region.hub_details.length)),
                        "===>",
                        this.props.hubTableData && this.props.hubTableData.region && this.props.hubTableData.region.hub_details.length,
                        "==> checkAll",
                        this.state.checkAll)} */}
                      <Checkbox
                        width="10px"
                        height="10px"
                        checked={this.state.checkAll}
                        // { this.state.selectAll && this.state.noOfChanges > 0  }
                        // {this.props.hubTableData && this.props.hubTableData.region && this.props.hubTableData.region.hub_details && this.props.hubTableData.region.hub_details.length && this.props.hubTableData.region.hub_details.filter((obj) => obj.checked === true).length == this.props.hubTableData && this.props.hubTableData.region && this.props.hubTableData.region.hub_details.length}
                        // { this.state.selectAll && this.state.noOfChanges > 0  }
                        onChange={(e) => {
                          let count = 0;
                          // let data = setHubDataForTable();
                          const modifiedData =
                            this.props.hubTableData &&
                            this.props.hubTableData.region &&
                            this.props.hubTableData.region.hub_details.length
                              ? this.props.hubTableData.region.hub_details.map(
                                  (item) => {
                                    if (
                                      item.edited ||
                                      (item.version === 0 &&
                                        item.revisedQty !== 0)
                                      //   &&
                                      // user.userName !== item.approver1Name &&
                                      // user.userName !== item.approver2Name
                                    ) {
                                      count++;
                                      return {
                                        ...item,
                                        checked: e.target.checked,
                                      };
                                    } else {
                                      return item;
                                    }
                                  }
                                )
                              : [];
                          // console.log("Key check --ALLLLLLLLL---> modifiedData", modifiedData);
                          // setHubDataForTable(data)
                          const data = {
                            region: {
                              hub_details: modifiedData,
                            },
                          };

                          setHubTableData(data);
                          this.setState({
                            localHubDataForTable: modifiedData,
                            drawerVisible: e.target.checked,
                            noOfChanges: e.target.checked ? count : 0,
                            selectAll: e.target.checked,
                          });
                        }}
                      ></Checkbox>
                    </div>
                  </div>
                ),
                key: "action",
                dataIndex: "action",
                width: 85,
                render: (item, row) => {
                  // console.log('approval row :', row)
                  return (
                    <div className="approval-table-checkbox">
                      <Checkbox
                        disabled={
                          !row.edited &&
                          !(
                            (
                              row.edited === false &&
                              row.data.version === 0 &&
                              row.revisedQty !== 0
                            )
                            //  || (row.approval1 !== null && row.approval2 !== null) || (row.approval1 === false || row.approval2 === false) || (row.approval1 !== null && user.userName === row.approver1Name) || (row.approval2 !== null && user.userName === row.approver2Name) )
                          )
                        }
                        checked={row.checked}
                        onChange={(e) => {
                          // let data = setHubDataForTable();
                          let count = 0;
                          const modifiedData =
                            this.props.hubTableData.region.hub_details.map(
                              (item, index) => {
                                // console.log("Key check --OUTTTTTTTTTT--->", item.temp_bd_id, " item.key", item.temp_bd_id === row.temp_bd_id, "row.temp_bd_id", row.temp_bd_id);

                                if (item.temp_bd_id === row.temp_bd_id) {
                                  // console.log("Key check ----->", item.temp_bd_id, " item.temp_bd_id", item.temp_bd_id === row.temp_bd_id, "row.temp_bd_id", row.temp_bd_id);
                                  item.checked && count++;
                                  return {
                                    ...item,
                                    checked: e.target.checked,
                                  };
                                } else {
                                  return { ...item };
                                }
                              }
                            );
                          // setHubDataForTable(data)
                          const data = {
                            region: {
                              hub_details: modifiedData,
                            },
                          };

                          setHubTableData(data);
                          // console.log("Key check -----> modifiedData", modifiedData);
                          this.setState({
                            localHubDataForTable: modifiedData,
                            drawerVisible: count > 0,
                            noOfChanges: count,
                          });
                        }}
                      />
                    </div>
                  );
                },
              },
            ]}
            dataSource={setHubDataForTable()}
            footer={
              () =>
                setHubDataForTable()?.length !== 0 && (
                  // <div style={{ display: "flex", justifyContent: "flex-end", width: "99%" }} >
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <Button
                      className={"submit-btn"}
                      // onClick={() => <ActionItem
                      //   canUserSubmit={canUserEdit(
                      //     user,
                      //     selectedBranchID,
                      //     FeatureCodes.BUYING_DECISION_SUBMIT
                      //   )}
                      //   key="submitAll"
                      //   // this.props.handleSubmitClick(this.props.hubTableData)}
                      //   postBuyingDecisionHub={this.props.handleSubmitClick}
                      //   rowRecord={this.props.hubTableData}
                      //   // postHubRowId={record.actions.hubID}
                      //   // sourceType={record.mandiBuying.sourceType}
                      //   // srp={record.srp}
                      // />
                      // }
                      onClick={() =>
                        this.props.handleSubmitClick(this.props.hubTableData)
                      }
                    >
                      Submit
                    </Button>
                  </div>
                )
              // </div>
            }
            // dataSource={this.state.localHubDataForTable && this.state.localHubDataForTable.length && this.state.localHubDataForTable[0].competitor_bid_id === undefined ? this.state.localHubDataForTable : setHubDataForTable()}
            // dataSource={this.state.localHubDataForTable}
          />
          {/* {console.log("====================> Data", this.state.localHubDataForTable)} */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
  allVarietyInfo: selectAllVarietyInfo,

  mandiRollUp: selectMandiRollUp,
  FORRollUp: selectFORRollUp,
  farmerRollUp: selectFarmerRollUp,
  hubTableData: selectHubTableData,
  remarksList: selectRemarks,
  decisionBranches: selectDecisionBranches,
  decisionRegions: selectDecisionRegions,
  selectedBranchID: selectSelectedBranchID,
  selectedRegionID: selectSelectedRegionID,
  selectedVarietyID: selectSelectedVarietyID,
  selectedHubID: selectSelectedHubID,
  hubDataPostFOR: selectHubDataPostFOR,
  dualRender: selectDualRender,

  suggestedData: selectSuggestedData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setHubTableData: (data) => dispatch(setHubTableData(data)),

    addSuggestedData: (data) => dispatch(addSuggestedData(data)),
    setHubDataPostFOR: (data) => dispatch(setHubDataPostFOR(data)),
    setDualRender: (value) => dispatch(setDualRender(value)),
    incrementDualRender: () => dispatch(incrementDualRender()),
    setSuggestedData: (data) => dispatch(setSuggestedData(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MandiInformationTable);
