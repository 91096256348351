import React from "react";
import "./factory-master.styles.css";
import { Component } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUserData,selectMSMessageRBAC } from "../../redux/user/user.selectors";
import { canUserEdit,getUserMSRBACMessage } from "../../auth/utils";
import { FeatureCodes } from "../../auth/data";
import {
  Table,
  Spin,
  Input,
  Select,
  Button,
  message,
  Form,
  Modal,
  Radio,
} from "antd";

import API from "../../api";
const { Search } = Input;
const { Option } = Select;
/**
 * @description Component for user management tab
 *
 * @component
 */
class FactoryMaster extends Component {
  state = {
    isModalVisible: false,
    isAdd: false,
    currentRecord: null,
    userBranches: null,
    searchText: null,
    searchUserType: 0,
    FactoryTableData: [],
    stateList: [],
    mandiRegionList: [],
    hubList: [],
    hubRegionList: [],
    hubBranchList: [],
    isSpinnerOnLoad: false,
    isSubmitted: false,
    newData: {
      Hub_unloading_capacity: undefined,
      branch_name: "",
      factory_unloading_capacity_apr_jun: undefined,
      factory_unloading_capacity_jul_mar: undefined,
      hub_id: undefined,
      hub_name: "",
      hub_region: "",
      is_active: true,
      parent_id: undefined,
      region_id: undefined,
      state: "",
      storage_capacity: undefined,
      territory_latitude: undefined,
      territory_longitude: undefined,
      territory_name: "",
      territory_sap_code: undefined,
      territory_type_id: undefined,
      state_id: undefined,
      isEdit: false,
    },
    rowData: undefined,
    selectedStateValue: undefined,
    selectedStateName: "",
    selectedStateList: [],
  };
  componentDidMount() {
    this.setState({ isSpinnerOnLoad: true });
    this.getFactoryMaster();
    this.getStates();
  }

  getFactoryMaster = async () => {
    this.setState({ isSpinnerOnLoad: true });



    await API.get("/master_factory")
      .then((varietyResponse) => {

        varietyResponse.data.data.map((dataDetailItem, index) => {
          dataDetailItem.key = index;
          dataDetailItem.isEdit = false;
        });
        this.setState(
          {
            // FactoryTableData:[],
            FactoryTableData: varietyResponse.data.data,
          },
          () => this.setState({ isSpinnerOnLoad: false, isAdd: true })
        );

      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400) message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };

  getStates = async () => {
    if (this.state.stateList === undefined || this.state.stateList === null || this.state.stateList.length === 0) {
      this.setState({ isSpinnerOnLoad: true });


      await API.get("/get_states")
        .then((stateLis) => {
          // let modifiedData = [...stateList.data.data];
          // modifiedData.push({ param_data_value: null, param_display_value: "All" });

          this.setState({
            //   selectedStateList: modifiedData,
            stateList: stateLis.data.data,
            //   selectedStateValue: stateList.data.data[0].param_data_value,
            //   selectedStateName: stateList.data.data[0].param_display_value,
          });
        })
        .catch((err) => {
          if (err) {
            if (err.response && err.response.status === 400)
              message.error("Something went wrong. Please try again later.", 5);
            else {
              message.error("Something went wrong.. Please try again later.", 5);
            }
          } else {
            message.error("Something went wrong... Please try again later.", 5);
          }
          this.setState({ isSpinnerOnLoad: false });
        });
    }
  };

  setTableData = () => {
    if (this.state.FactoryTableData !== undefined || this.state.FactoryTableData.length >= 0) {
      const userTableData = this.state.FactoryTableData.map((user, index) => {
        return {
          key: user.key,
          Hub_unloading_capacity: user.Hub_unloading_capacity,
          branch_name: user.branch_name,
          factory_unloading_capacity_apr_jun: user.factory_unloading_capacity_apr_jun,
          factory_unloading_capacity_jul_mar: user.factory_unloading_capacity_jul_mar,
          hub_id: user.hub_id,
          hub_name: user.hub_name,
          hub_region: user.hub_region,
          is_active: user.is_active,
          parent_id: user.parent_id,
          region_id: user.region_id,
          state: user.state,
          storage_capacity: user.storage_capacity,
          territory_latitude: user.territory_latitude,
          territory_longitude: user.territory_longitude,
          territory_name: user.territory_name,
          territory_sap_code: user.territory_sap_code,
          territory_type_id: user.territory_type_id,
          isEdit: user.isEdit,
        };
      });

      let dataAfterSearch = userTableData;

      if (this.state.searchText) {
        dataAfterSearch = dataAfterSearch.filter(
          (item) =>
            (item.territory_name !== null &&
              item.territory_name
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase())) ||
            (item.state !== null &&
              item.state
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase()))
          // (item.hub_name !== null &&
          //   item.hub_name
          //     .toLowerCase()
          //     .includes(this.state.searchText.toLowerCase())) 
        );
      }

      return dataAfterSearch;
    }
    else {
      return this.state.FactoryTableData;
    }
  };

  submitTableRowData = (row, index, isPopup) => {
    this.setState({ isSpinnerOnLoad: true, isSubmitted: true });
    let warningmessage = "";
    if (row.territory_name === null || row.territory_name === undefined || row.territory_name === "") {
      warningmessage = "Please enter factory";
    }
    else if (row.state === null || row.state === undefined || row.state === "") {
      warningmessage = "Please select state";
    }

    if (
      warningmessage === "" ||
      warningmessage === undefined ||
      warningmessage === null
    ) {
      this.setState({ isSpinnerOnLoad: true });
      this.setState({ isSubmitted: true })
      let data = {
        pk_mmh_id: row.pk_mmh_id,
        Hub_unloading_capacity: row.Hub_unloading_capacity === "" || row.Hub_unloading_capacity === undefined
          ? null
          : row.Hub_unloading_capacity,
        branch_name: row.branch_name,
        factory_unloading_capacity_apr_jun: row.factory_unloading_capacity_apr_jun === "" || row.factory_unloading_capacity_apr_jun === undefined
          ? null
          : row.factory_unloading_capacity_apr_jun,
        factory_unloading_capacity_jul_mar: row.factory_unloading_capacity_jul_mar === "" || row.factory_unloading_capacity_jul_mar === undefined
          ? null
          : row.factory_unloading_capacity_jul_mar,
        hub_id: row.hub_id,
        hub_name: row.hub_name,
        hub_region: row.hub_region,
        is_active: row.is_active ? 1 : 0,
        parent_id: row.parent_id,
        region_id: row.region_id,
        state: row.state,
        state_id: row.state_id,
        storage_capacity: row.storage_capacity === "" || row.storage_capacity === undefined
          ? null
          : row.storage_capacity,
        territory_latitude: row.territory_latitude === "" || row.territory_latitude === undefined
          ? null
          : row.territory_latitude,
        territory_longitude: row.territory_longitude === "" || row.territory_longitude === undefined
          ? null
          : row.territory_longitude,
        territory_name: row.territory_name,
        territory_sap_code: row.territory_sap_code === "" || row.territory_sap_code === undefined
          ? null
          : row.territory_sap_code,
        territory_type_id: row.territory_type_id,
      };



      API.put("/master_factory", data, {

      })
        .then((response) => {
          if (response.status === 200 && response.data.status) {
            message.info(response.data.message, 5);
            this.setModalVisibility(false);
            this.getFactoryMaster();
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
          } else if (!response.data.status) {
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
            message.error(response.data.message, 5);
            // this.setModalVisibility(false);
          } else {
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
            message.error("Please try again", 5);
          }
        })
        .catch((err) => {
          if (err) {
            if (err.response && err.response.status === 400) message.error("Something went wrong. Please try again later.", 5);
            else {
              message.error("Something went wrong.. Please try again later.", 5);
            }
          } else {
            message.error("Something went wrong... Please try again later.", 5);
          }
          this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
          // this.setModalVisibility(false);
        });
    } else {
      this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
      message.error(warningmessage, 5);
    }
  };

  setModalVisibility = (status) => {
    if (status) {
      const nData = {
        Hub_unloading_capacity: undefined,
        branch_name: "",
        factory_unloading_capacity_apr_jun: undefined,
        factory_unloading_capacity_jul_mar: undefined,
        hub_id: undefined,
        hub_name: "",
        hub_region: "",
        is_active: true,
        parent_id: undefined,
        region_id: undefined,
        state: "",
        storage_capacity: undefined,
        territory_latitude: undefined,
        territory_longitude: undefined,
        territory_name: "",
        territory_sap_code: undefined,
        territory_type_id: undefined,
        state_id: undefined,
        isEdit: false,

      };

      this.setState({ newData: nData });
    }

    this.setState({ isModalVisible: status });
  };

  TableColumnHeader = (props) => {
    const { title, subtitle } = props;
    return (
      <div className="column-header-container">
        <div
          style={{
            color: "white",
            fontSize: "12px",
            whiteSpace: "nowrap",
            fontWeight: "normal",
            textAlign: "left",
          }}
        >
          {title}
        </div>
        <div
          style={{
            color: "#cbdfff",
            fontSize: "11px",
            fontWeight: "normal",
            textAlign: "left",
            // subtitle && title.length < subtitle.length ? "left" : "center"
          }}
        >
          {subtitle}
        </div>
      </div>
    );
  };

  createFilters = (label) => {
    let filterData = this.state.FactoryTableData;
    //#region remove duplicate objects
    let uniqueFilterData = [];

    if (label === "is_active" && this.state.FactoryTableData && this.state.FactoryTableData.length > 0) {
      uniqueFilterData = [{ text: "Yes", value: 1 }, { text: "No", value: 0 }]
    }
    else {
      filterData.map((mainItem) =>
        uniqueFilterData.filter((item) => item[label] === mainItem[label])
          .length > 0
          ? null
          : uniqueFilterData.push(mainItem)
      );

      uniqueFilterData = uniqueFilterData.map((item) => {
        return {
          text: item[label],
          value: item[label],
        };

      });

      uniqueFilterData.sort((a, b) => a.text.localeCompare(b.text))
    }

    return uniqueFilterData;
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  handleEditChange = (row, index) => {
    this.setState({ rowData: row });
    let tempData = this.state.FactoryTableData.map((item) => {

      if (item.key === row.key) {
        return {
          ...item,
          isEdit: true
        }
      } else {
        return item;
      }
    })
    this.setState({ FactoryTableData: tempData });
  };

  handleCancelClick = (rowData, index) => {
    let tempData = this.state.FactoryTableData.map((item) => {
      if (item.key === rowData.key) {
        return this.state.rowData
      } else {
        return item;
      }
    })
    this.setState({ FactoryTableData: tempData });
  };


  handleChange = (e, row, index, name, isPopup) => {

    let a;
    var alphaNumericRegex = /^[a-zA-Z0-9]*$/;
    var numberRegex = /^[0-9.]+$/;
    if (isPopup === false) {
      a = this.state.FactoryTableData;

      switch (name) {
        case "territory_name":
          a[row.key].territory_name = e.target.value;
          this.setState({ FactoryTableData: a });
          return;
        case "storage_capacity":
          if (e.target.value === "") {
            a[row.key].storage_capacity = "";
          } else if (e.target.value.match(numberRegex)) {
            a[row.key].storage_capacity = e.target.value;
          }

          this.setState({ FactoryTableData: a });
          return;
        case "factory_unloading_capacity_jul_mar":
          if (e.target.value === "") {
            a[row.key].factory_unloading_capacity_jul_mar = "";
          } else if (e.target.value.match(numberRegex)) {
            a[row.key].factory_unloading_capacity_jul_mar = e.target.value;
          }

          this.setState({ FactoryTableData: a });
          return;
        case "factory_unloading_capacity_apr_jun":
          if (e.target.value === "") {
            a[row.key].factory_unloading_capacity_apr_jun = "";
          } else if (e.target.value.match(numberRegex)) {
            a[row.key].factory_unloading_capacity_apr_jun = e.target.value;
          }

          this.setState({ FactoryTableData: a });
          return;
        case "territory_sap_code":
          if (e.target.value === "") {
            a[row.key].territory_sap_code = "";
          } else if (e.target.value.match(alphaNumericRegex)) {
            a[row.key].territory_sap_code = e.target.value.toUpperCase();
          }

          this.setState({ FactoryTableData: a });
          return;
        case "territory_latitude":
          if (e.target.value === "") {
            a[row.key].territory_latitude = "";
          } else if (e.target.value.match(numberRegex)) {
            a[row.key].territory_latitude = e.target.value;
          }

          this.setState({ FactoryTableData: a });
          return;
        case "territory_longitude":
          if (e.target.value === "") {
            a[row.key].territory_longitude = "";
          } else if (e.target.value.match(numberRegex)) {
            a[row.key].territory_longitude = e.target.value;
          }

          this.setState({ FactoryTableData: a });
          return;
        default:
          return "";
      }

    } else {
      a = this.state.newData;

      switch (name) {
        case "territory_name":
          a.territory_name = e.target.value;
          this.setState({ newData: a });
          return;
        case "storage_capacity":
          if (e.target.value === "") {
            a.storage_capacity = "";
          } else if (e.target.value.match(numberRegex)) {
            a.storage_capacity = e.target.value;
          }

          this.setState({ newData: a });
          return;
        case "factory_unloading_capacity_jul_mar":
          if (e.target.value === "") {
            a.factory_unloading_capacity_jul_mar = "";
          } else if (e.target.value.match(numberRegex)) {
            a.factory_unloading_capacity_jul_mar = e.target.value;
          }

          this.setState({ newData: a });
          return;
        case "factory_unloading_capacity_apr_jun":
          if (e.target.value === "") {
            a.factory_unloading_capacity_apr_jun = "";
          } else if (e.target.value.match(numberRegex)) {
            a.factory_unloading_capacity_apr_jun = e.target.value;
          }

          this.setState({ newData: a });
          return;
        case "territory_sap_code":
          if (e.target.value === "") {
            a.territory_sap_code = "";
          } else if (e.target.value.match(alphaNumericRegex)) {
            a.territory_sap_code = e.target.value.toUpperCase();
          }

          this.setState({ newData: a });
          return;
        case "territory_latitude":
          if (e.target.value === "") {
            a.territory_latitude = "";
          } else if (e.target.value.match(numberRegex)) {
            a.territory_latitude = e.target.value;
          }

          this.setState({ newData: a });
          return;
        case "territory_longitude":
          if (e.target.value === "") {
            a.territory_longitude = "";
          } else if (e.target.value.match(numberRegex)) {
            a.territory_longitude = e.target.value;
          }

          this.setState({ newData: a });
          return;
        default:
          return "";
      }
    }
  };

  handleDropdownChange = (value, row, target, index, optionlabel, isPopup) => {

    let a;

    if (isPopup === false) {
      a = this.state.FactoryTableData;

      if (target === "is_active") {
        a[row.key].is_active = Boolean(value);
      }
      else if (target === "state") {
        a[row.key].state = optionlabel;
        a[row.key].state_id = value;
      }

      this.setState({ FactoryTableData: a });
    } else {
      a = this.state.newData;

      if (target === "is_active") {
        a.is_active = Boolean(value);
      }
      else if (target === "state") {
        a.state = optionlabel;
        a.state_id = value;
      }

      this.setState({ newData: a });
    }
  };

  render() {
    const userTableProps = {
      pagination: false,
      size: "default",
      bordered: true,
      scroll: { x: 200, y: window.innerHeight - 300 },
    };

    const handleStateSelect = (value, props) => {
      this.setState(
        { selectedStateName: props.key, selectedStateValue: value },
        () => this.getFactoryMaster()
      );
    };

    return (
      <div
        className="mandi-master-container"
        style={{
          height: window.innerHeight - 85,
          marginTop: "-45px",
        }}
      >
        <div className="top-container">
          {/*
      <div className="hub-region-filter">
            <span
              style={{
                fontSize: "15px",
                marginTop: "5px",
                fontWeight: "600",
              }}
            >
              &nbsp;
            </span>{" "}
            <Select
              disabled={!this.state.isAdd}
              showSearch
              optionFilterProp="children"
              className={"select-master-region"}
              mode="single"
              placeholder="Select State"
              value={this.state.selectedstateValue}
              onChange={(value, props) => handleStateSelect(value, props)}
            >
              {this.state.selectedStateList !== null &&
                this.state.selectedStateList.length &&
                this.state.selectedStateList.map((item, idx) => (
                  <Option value={item.state_id} key={item.state_name}>
                    {item.state_name}
                  </Option>
                ))}
            </Select>
          </div>
                */}

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row-reverse",
              placeItems: "flex-end",
              marginRight: "25px",
              marginBottom: "10px",
            }}
          >
            <Search
              disabled={!this.state.isAdd}
              placeholder="Search"
              allowClear
              onChange={(e) => this.setState({ searchText: e.target.value })}
              style={{ width: "25%" }}
            />
            <Button
              className="add-master-button"
              style={{
                height: "25px",
                width: "70px",
                backgroundColor: "#0b133d",
                color: "white",
              }}
              disabled={!canUserEdit(
                this.props.userData,
                null,
                FeatureCodes.MS_FACOTRY_EDIT
              ) || !this.state.isAdd}
              onClick={() => this.setModalVisibility(true)}
            >
              &#10010; Add
            </Button>
          </div>
        </div>

        <div style={{ color: "red", marginLeft: "20px", marginTop: "-10px" }}>Please prefix the factory name with factory code, for e.g. FA10 - Varanasi</div>

        <div>
        <div
        style={{
          marginLeft: "25px",
          marginTop: "-8px",
          fontSize: "14px",
          color: "red",
          display: "flex",
          float: "left",
        }}
      > {getUserMSRBACMessage(this.props.messageAccess,FeatureCodes.MS_FACOTRY_EDIT)}
      </div>
        <div
          style={{
            marginRight: "20px",
            marginTop: "-20px",
            fontSize: "15px",
            color: "#0B133D",
            display: "flex",
            float: "right",
          }}
        >Total number of records:&nbsp;
          <b>{this.state.FactoryTableData && this.setTableData().length}</b>
        </div>
        </div>

        <Spin
          spinning={this.state.isSpinnerOnLoad}
          size={"large"}
          tip="Loading..."
          style={{ alignSelf: "center", zIndex: 9999, marginTop: "20px" }}
        >
          <Modal
            className="add-master-modal"
            title={"Add Factory"}
            onCancel={this.handleCancel}
            footer={false}
            visible={this.state.isModalVisible}
            destroyOnClose={true}
          >
            <div className="master-form-container">
              <Form
                layout="horizontal"
                //   onValuesChange={onFormLayoutChange}
                size={"small"}
              // onSubmit={submitTableRowData(this.state.newData)}
              >
                <div className="form-top-container1">
                  <div>
                    <div
                      className="checkbox-group1"
                      style={{
                        display: "inline-block",
                        width: "45%",
                        marginRight: 40,
                      }}
                    >
                      <Form.Item label="Factory">
                        <Input
                          style={{ marginRight: "2px" }}
                          maxLength={100}
                          min="0"
                          value={this.state.newData.territory_name}
                          name="territory_name"
                          onChange={(e) =>
                            this.handleChange(
                              e,
                              this.state.newData,
                              -0,
                              "territory_name",
                              true
                            )
                          }
                        />
                      </Form.Item>
                    </div>

                    <div
                      className="form-top-container"
                      style={{
                        display: "inline-block",
                        width: "44%",
                        marginRight: 7,
                      }}
                    >
                      <Form.Item label="State">
                        <Select
                          // className="remarks-select"
                          showSearch
                          optionFilterProp="children"
                          name="state"
                          required
                          value={this.state.newData.state}
                          onSelect={(value, option) =>
                            this.handleDropdownChange(
                              value,
                              this.state.newData,
                              "state",
                              -0,
                              option.props.children,
                              true
                            )
                          }
                        >
                          {this.state.stateList &&
                            this.state.stateList.length &&
                            this.state.stateList.map((hubItem, idx) => (
                              <Option
                                key={hubItem.state_id}
                                value={hubItem.state_id}
                              >
                                {hubItem.state_name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </div>

                    <span style={{ color: "red" }}>Please prefix the factory name with factory code, for e.g. FA10-Varanasi</span>

                  </div>
                  <hr />

                  <div>
                    <div
                      className="checkbox-group1"
                      style={{
                        display: "inline-block",
                        width: "41%",
                        marginRight: 40,
                      }}
                    >
                      <Form.Item label="Storage Capacity(MT)">
                        <Input
                          style={{ marginRight: "2px" }}
                          maxLength={100}
                          min="0"
                          value={this.state.newData.storage_capacity}
                          name="storage_capacity"
                          onChange={(e) =>
                            this.handleChange(
                              e,
                              this.state.newData,
                              -0,
                              "storage_capacity",
                              true
                            )
                          }
                        />
                      </Form.Item>
                    </div>


                    <div
                      className="checkbox-group1"
                      style={{
                        display: "inline-block",
                        width: "41%",
                        marginRight: 40,
                      }}
                    >
                      <Form.Item label="Unloading Capacity July-March(MT)">
                        <Input
                          style={{ marginRight: "2px" }}
                          maxLength={100}
                          min="0"
                          value={this.state.newData.factory_unloading_capacity_jul_mar}
                          name="factory_unloading_capacity_jul_mar"
                          onChange={(e) =>
                            this.handleChange(
                              e,
                              this.state.newData,
                              -0,
                              "factory_unloading_capacity_jul_mar",
                              true
                            )
                          }
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div>
                    <div
                      className="checkbox-group1"
                      style={{
                        display: "inline-block",
                        width: "41%",
                        marginRight: 40,
                      }}
                    >
                      <Form.Item label="Unloading Capacity April-June(MT)">
                        <Input
                          style={{ marginRight: "2px" }}
                          maxLength={100}
                          min="0"
                          value={this.state.newData.factory_unloading_capacity_apr_jun}
                          name="factory_unloading_capacity_apr_jun"
                          onChange={(e) =>
                            this.handleChange(
                              e,
                              this.state.newData,
                              -0,
                              "factory_unloading_capacity_apr_jun",
                              true
                            )
                          }
                        />
                      </Form.Item>
                    </div>


                    <div
                      className="checkbox-group1"
                      style={{
                        display: "inline-block",
                        width: "41%",
                        marginRight: 40,
                      }}
                    >
                      <Form.Item label="Sap Code">
                        <Input
                          style={{ marginRight: "2px" }}
                          maxLength={100}
                          min="0"
                          value={this.state.newData.territory_sap_code}
                          name="territory_sap_code"
                          onChange={(e) =>
                            this.handleChange(
                              e,
                              this.state.newData,
                              -0,
                              "territory_sap_code",
                              true
                            )
                          }
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div>
                    <div
                      className="checkbox-group1"
                      style={{
                        display: "inline-block",
                        width: "41%",
                        marginRight: 40,
                      }}
                    >
                      <Form.Item label="Latitude">
                        <Input
                          style={{ marginRight: "2px" }}
                          maxLength={100}
                          min="0"
                          value={this.state.newData.territory_latitude}
                          name="territory_latitude"
                          onChange={(e) =>
                            this.handleChange(
                              e,
                              this.state.newData,
                              -0,
                              "territory_latitude",
                              true
                            )
                          }
                        />
                      </Form.Item>
                    </div>

                    <div
                      className="checkbox-group1"
                      style={{
                        display: "inline-block",
                        width: "41%",
                        marginRight: 40,
                      }}
                    >
                      <Form.Item label="Longitude">
                        <Input
                          style={{ marginRight: "2px" }}
                          maxLength={100}
                          min="0"
                          value={this.state.newData.territory_longitude}
                          name="territory_longitude"
                          onChange={(e) =>
                            this.handleChange(
                              e,
                              this.state.newData,
                              -0,
                              "territory_longitude",
                              true
                            )
                          }
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div>
                    <div
                      className="form-top-container"
                      style={{
                        display: "inline-block",
                        width: "44%",
                        marginRight: 7,
                      }}
                    >
                      <Form.Item label="Active">
                        <Radio.Group
                          name="is_active"
                          value={this.state.newData.is_active ? 1 : 0}
                          onChange={(e, option) =>
                            this.handleDropdownChange(
                              e.target.value,
                              this.state.newData,
                              "is_active",
                              -0,
                              "",
                              true
                            )
                          }
                        >
                          <Radio value={1}>Yes</Radio>
                          <Radio value={0}>No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </div>
                  </div>
                </div>

                <div className="form-bottom-container">
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={this.state.isSubmitted}
                      onClick={() =>
                        this.submitTableRowData(this.state.newData, 0, true)
                      }
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </Modal>


          <div className="table-container1" style={{ maxHeight: "450px", width: "99%", margin: "6px 0px" }}>
            <Table
              {...userTableProps}
              columns={[
                {
                  key: "territory_name",
                  title: "Factory",
                  dataIndex: "territory_name",
                  width: 110,
                  filters: this.createFilters("territory_name"),
                  onFilter: (value, record) => record.territory_name.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.territory_name.localeCompare(b.territory_name),
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            disabled={!rowRecord.isEdit}
                            style={{ marginRight: "2px" }}
                            maxLength={100}
                            min="0"
                            value={rowRecord.territory_name}
                            name="territory_name"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "territory_name",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },
                {
                  title: "State",
                  dataIndex: "state",
                  key: "state",
                  width: 110,
                  // filters: this.createFilters("state"),
                  // onFilter: (value, record) =>
                  //   record.state.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.state.localeCompare(b.state),
                  render: (record, rowRecord) => {
                    return (
                      <>
                        <div
                          style={{ marginLeft: "-15px", marginRight: "-10px" }}
                        >
                          <Select
                            // className="remarks-select"
                            showSearch
                            disabled={!rowRecord.isEdit}
                            optionFilterProp="children"
                            name="state"
                            required
                            value={rowRecord.state}
                            onSelect={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "state",
                                -0,
                                option.props.children,
                                false
                              )
                            }
                          >
                            {this.state.stateList &&
                              this.state.stateList.length &&
                              this.state.stateList.map((hubItem, idx) => (
                                <Option
                                  key={hubItem.state_id}
                                  value={hubItem.state_id}
                                >
                                  {hubItem.state_name}
                                </Option>
                              ))}
                          </Select>

                        </div>
                      </>
                    );
                  },
                },
                {
                  key: "storage_capacity",
                  title: "Factory Storage Capacity (MT)",
                  dataIndex: "storage_capacity",
                  width: 80,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.storage_capacity.localeCompare(b.storage_capacity),
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            disabled={!rowRecord.isEdit}
                            style={{ marginRight: "2px" }}
                            maxLength={100}
                            min="0"
                            value={rowRecord.storage_capacity}
                            name="storage_capacity"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "storage_capacity",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },
                {
                  key: "factory_unloading_capacity_jul_mar",
                  title: "Factory Unloading Capacity July to Mar (MT)",
                  dataIndex: "factory_unloading_capacity_jul_mar",
                  width: 80,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.factory_unloading_capacity_jul_mar - b.factory_unloading_capacity_jul_mar,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            disabled={!rowRecord.isEdit}
                            style={{ marginRight: "2px" }}
                            maxLength={100}
                            min="0"
                            value={rowRecord.factory_unloading_capacity_jul_mar}
                            name="factory_unloading_capacity_jul_mar"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "factory_unloading_capacity_jul_mar",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },
                {
                  key: "factory_unloading_capacity_apr_jun",
                  title: "Factory Unloading Capacity April to June (MT)",
                  dataIndex: "factory_unloading_capacity_apr_jun",
                  width: 80,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.factory_unloading_capacity_apr_jun - b.factory_unloading_capacity_apr_jun,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            disabled={!rowRecord.isEdit}
                            style={{ marginRight: "2px" }}
                            maxLength={100}
                            min="0"
                            value={rowRecord.factory_unloading_capacity_apr_jun}
                            name="factory_unloading_capacity_apr_jun"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "factory_unloading_capacity_apr_jun",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },
                {
                  key: "territory_sap_code",
                  title: "SAP Code",
                  dataIndex: "territory_sap_code",
                  width: 70,
                  // sortDirections: ["descend", "ascend"],
                  // sorter: (a, b) => a.territory_sap_code.toLowerCase() - b.territory_sap_code.toLowerCase(),
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            style={{ marginRight: "2px" }}
                            disabled={!rowRecord.isEdit}
                            maxLength={100}
                            value={rowRecord.territory_sap_code}
                            name="territory_sap_code"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "territory_sap_code",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },
                {
                  key: "territory_latitude",
                  title: "Latitude",
                  dataIndex: "territory_latitude",
                  width: 80,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.territory_latitude - b.territory_latitude,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            style={{ marginRight: "2px" }}
                            disabled={!rowRecord.isEdit}
                            maxLength={100}
                            value={rowRecord.territory_latitude}
                            name="territory_latitude"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "territory_latitude",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },
                {
                  key: "territory_longitude",
                  title: "Longitude",
                  dataIndex: "territory_longitude",
                  width: 80,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.territory_longitude - b.territory_longitude,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            style={{ marginRight: "2px" }}
                            disabled={!rowRecord.isEdit}
                            maxLength={100}
                            value={rowRecord.territory_longitude}
                            name="territory_longitude"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "territory_longitude",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },
                {
                  key: "is_active",
                  title: "Active",
                  dataIndex: "is_active",
                  filters: this.createFilters("is_active"),
                  width: 60,
                  // filters: [{ text: "Yes", value: 1 }, { text: "No", value: 0 }],
                  onFilter: (value, record) =>
                    record.is_active === Boolean(value),
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-15px", marginRight: "-40px" }}>
                          <Select
                            showSearch
                            optionFilterProp="children"
                            disabled={!rowRecord.isEdit}
                            defaultValue={
                              rowRecord.is_active === true ? "Yes" : "No"
                            }
                            value={
                              rowRecord.is_active === true ? "Yes" : "No"
                            }
                            onChange={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "is_active",
                                index,
                                option.props.children,
                                false
                              )
                            }
                            style={{ width: "70%" }}
                          >
                            <Option key={1} value={1}>
                              {"Yes"}
                            </Option>
                            <Option key={0} value={0}>
                              {"No"}
                            </Option>
                          </Select>
                        </div>
                      </>
                    );
                  },
                },

                {
                  title: "Action",
                  dataIndex: "Submit",
                  key: "Submit",
                  width: 120,
                  visibility: false,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ display: "inline-flex", width: "100%" }}>
                          <Button
                          className={this.props.userData.roleID !== 12 ? "disabledBtnClass" : "btnClass"}
                          disabled={this.props.userData.roleID !== 12}
                            type="primary"
                            size={"small"}
                            style={{
                              marginLeft: 5,
                              alignSelf: "center",
                              padding: "0px 0px 2px",
                              height: 31,
                              backgroundColor: "#20346a",
                              color: "white",
                              borderRadius: 15,
                            }}
                            block
                            htmlType="submit"
                            onClick={() =>
                              rowRecord.isEdit
                                ? this.submitTableRowData(rowRecord, index, false)
                                : this.handleEditChange(rowRecord, index)
                            }
                          >
                            {" "}
                            {rowRecord.isEdit ? "Submit" : "Edit"}{" "}
                          </Button>


                          <Button
                            className={!rowRecord.isEdit ? "disabledBtnClass" : "btnClass"}
                            type="primary"
                            size={"small"}
                            style={{
                              marginLeft: 5,
                              alignSelf: "center",
                              color: "white",
                              borderRadius: 15,
                              padding: "0px 0px 2px",
                              height: 31,
                              backgroundColor: "#20346a",
                            }}
                            block
                            htmlType="submit"
                            disabled={!rowRecord.isEdit}
                            onClick={() => this.handleCancelClick(rowRecord, index)
                            }
                          >
                            Cancel
                          </Button>

                        </div>
                      </>
                    );
                  },
                },
              ]}
              dataSource={this.setTableData()}
            />
          </div>

        </Spin>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
  messageAccess: selectMSMessageRBAC,
});
export default connect(mapStateToProps)(FactoryMaster);
//export default FactoryMaster;
