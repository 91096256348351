import React from "react";
import "./po-price-correction.styles.css";
import { Component } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUserData ,selectMSMessageRBAC} from "../../redux/user/user.selectors";
import { canUserEdit,getUserMSRBACMessage } from "../../auth/utils";
import { FeatureCodes } from "../../auth/data";
import {
  Table,
  Spin,
  Input,
  Select,
  Button,
  message,
  Form,
  Modal,
  DatePicker,
  Radio,
} from "antd";
import moment from "moment";
import CustomDropdownChooseRegion from "../../components/custom-dropdown-choose-region/custom-dropdown-choose-region.component";

import API from "../../api";

const { Search } = Input;
const { Option } = Select;
/**
 * @description Component for user manFagement tab
 *
 * @component
 */
class POCorrectionMaster extends Component {
  state = {
    isModalVisible: false,
    isAdd: false,
    currentRecord: null,
    userBranches: null,
    searchText: null,
    searchUserType: 0,
    poCorrectionTableData: [],
    stateList: [],
    isSpinnerOnLoad: false,
    isSubmitted: false,
    newData: {
      Corrected_Price: undefined,
      Financial_Year: "",
      PO_Price_Corrected_ID: undefined,
      State: "",
      isEdit: false,
    },
    rowData: undefined,
  };
  componentDidMount() {
    this.setState({ isSpinnerOnLoad: true });
    this.getMasterPoCorrection();
    this.getStates();
  }

  getMasterPoCorrection = async () => {
    this.setState({ isSpinnerOnLoad: true });

    await API.get("/master_po_correction")
      .then((varietyResponse) => {
        let modifiedData = varietyResponse.data.data.map((item, index) => {
          return {
            ...item,
            isEdit: false,
            key: index,
          };
        });
        this.setState(
          {
            poCorrectionTableData: modifiedData,
          },
          () => this.setState({ isSpinnerOnLoad: false, isAdd: true })
        );
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };

  getStates = async () => {
    this.setState({ isSpinnerOnLoad: true });


    await API.get("/get_states")
      .then((hubList) => {
        this.setState({
          stateList: hubList.data.data,
          isSpinnerOnLoad: false,
        });
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };

  setTableData = () => {
    if (
      this.state.poCorrectionTableData !== undefined ||
      this.state.poCorrectionTableData.length >= 0
    ) {
      let userTableData = this.state.poCorrectionTableData.map(
        (user, index) => {
          return {

            key: user.key,
            Corrected_Price: user.Corrected_Price,
            Financial_Year: user.Financial_Year,
            PO_Price_Corrected_ID: user.PO_Price_Corrected_ID,
            State: user.State,
            isEdit: user.isEdit,
          };
        }
      );

      let dataAfterSearch = userTableData;

      if (this.state.searchText) {
        dataAfterSearch = dataAfterSearch.filter(
          (item) =>
            item.State !== null &&
            item.State.toLowerCase().includes(
              this.state.searchText.toLowerCase()
            )
        );
      }

      return dataAfterSearch;
    } else {
      return this.state.poCorrectionTableData;
    }
  };

  TableColumnHeader = (props) => {
    const { title, subtitle } = props;
    return (
      <div className="column-header-container">
        <div
          style={{
            color: "white",
            fontSize: "12px",
            whiteSpace: "nowrap",
            fontWeight: "normal",
            textAlign: "left",
          }}
        >
          {title}
        </div>
        <div
          style={{
            color: "#cbdfff",
            fontSize: "11px",
            fontWeight: "normal",
            textAlign: "left",
            // subtitle && title.length < subtitle.length ? "left" : "center"
          }}
        >
          {subtitle}
        </div>
      </div>
    );
  };

  createFilters = (label) => {
    let filterData = this.state.poCorrectionTableData;
    //#region remove duplicate objects
    let uniqueFilterData = [];

    if (
      label === "is_active" &&
      this.state.poCorrectionTableData &&
      this.state.poCorrectionTableData.length > 0
    ) {
      uniqueFilterData = [
        { text: "Yes", value: 1 },
        { text: "No", value: 0 },
      ];
    } else {
      filterData.map((mainItem) =>
        uniqueFilterData.filter((item) => item[label] === mainItem[label])
          .length > 0
          ? null
          : uniqueFilterData.push(mainItem)
      );

      uniqueFilterData = uniqueFilterData.map((item) => {
        return {
          text: item[label],
          value: item[label],
        };
      });

      uniqueFilterData.sort((a, b) => a.text.localeCompare(b.text));
    }

    return uniqueFilterData;
  };

  submitTableRowData = (row) => {
    if (this.validate(row)) {
      this.setState({ isSubmitted: true });

      this.setState({ isSpinnerOnLoad: true });

      let params = {
        PO_Price_Corrected_ID: row.PO_Price_Corrected_ID,
        State: row.State,
        Corrected_Price:
          row.Corrected_Price === "" || row.Corrected_Price === undefined
            ? null
            : row.Corrected_Price,
      };
      API.put("/master_po_correction", params, {

      })
        .then((response) => {
          if (response.status === 200 && response.data.status) {
            message.success(response.data.message, 5);
            this.setModalVisibility(false);
            this.getMasterPoCorrection();
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
          } else if (!response.data.status) {
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
            message.error(response.data.message, 5);
            // this.setModalVisibility(false);
          } else {
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
            message.error("Please try again", 5);
          }
        })
        .catch((err) => {
          if (err) {
            if (err.response && err.response.status === 400)
              message.error("Something went wrong. Please try again later.", 5);
            else {
              message.error(
                "Something went wrong.. Please try again later.",
                5
              );
            }
          } else {
            message.error("Something went wrong... Please try again later.", 5);
          }
          this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
          // this.setModalVisibility(false);
        });
    }
  };

  validate = (rowData) => {
    var alphaNumericRegex = /^[a-zA-Z0-9]*$/;
    var numberRegex = /^[0-9.]+$/;

    if (
      rowData.State === null ||
      rowData.State === undefined ||
      rowData.State === ""
    ) {
      message.error(`Please select State !`, 5);
      return false;
    }
    // else if (rowData.Mandi_Min_Buying_MT < 0) {
    //   message.error(
    //     `Minimum buying price cannot be less than 0 for ${rowData.mandi_name},${rowData.hub_name}, ${rowData.region_name} and ${rowData.hub_region_name} !`,
    //     5
    //   );
    //   return false;
    // } else if (

    return true;
  };

  setModalVisibility = (status) => {
    if (status) {
      const nData = {
        Corrected_Price: undefined,
        Financial_Year: "",
        PO_Price_Corrected_ID: undefined,
        State: "",
        isEdit: false,
      };

      this.setState({ newData: nData });
    }

    this.setState({ isModalVisible: status });
  };

  handleDropdownChange = (value, row, target, index, optionlabel, isPopup) => {
    const specificstate = this.state.stateList.filter((item) => {
      if (item.state_id === value) {
        return item;
      }
    });

    let a;

    if (isPopup === false) {
      a = this.state.poCorrectionTableData;

      if (target === "State") {
        a[row.key].State = optionlabel;
        a[row.key].param_data_value = value;
        row.State = optionlabel;
        row.param_data_value = value;
      }

      this.setState({ poCorrectionTableData: a });
    } else {
      a = this.state.newData;

      if (target === "State") {
        a.State = optionlabel;
        a.param_data_value = value;
        row.State = optionlabel;
        row.param_data_value = value;
      }

      this.setState({ newData: a });
    }
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  handleChange = (e, row, index, name, isPopup) => {
    let a;
    var alphaNumericRegex = /^[a-zA-Z0-9]*$/;
    var numberRegex = /^[0-9.]+$/;
    if (isPopup === false) {
      a = this.state.poCorrectionTableData;

      switch (name) {
        case "Corrected_Price":
          if (e.target.value === "") {
            a[row.key].Corrected_Price = "";
          } else if (e.target.value.match(numberRegex)) {
            a[row.key].Corrected_Price = e.target.value;
          }

          this.setState({ poCorrectionTableData: a });
          return;
        default:
          return "";
      }
    } else {
      a = this.state.newData;

      switch (name) {
        case "Corrected_Price":
          if (e.target.value === "") {
            a.Corrected_Price = "";
          } else if (e.target.value.match(numberRegex)) {
            a.Corrected_Price = e.target.value;
          }

          this.setState({ newData: a });
          return;
        default:
          return "";
      }
      // this.setState({ newData: a });
    }
  };

  disabledDate = (current, rowData, text) => {
    return (
      current && current > moment(rowData.Start_Effective_Date, "YYYY-MM-DD")
    );
  };

  handleEditClick = (rowData, index) => {
    let tempData = this.state.poCorrectionTableData.map((item) => {
      if (item.key === rowData.key) {
        return {
          ...item,
          isEdit: true,
        };
      } else {
        return {
          ...item,
          isEdit: false,
        };
      }
    });

    this.setState({ poCorrectionTableData: tempData });

    this.setState({ rowData: rowData });
  };

  handleCancelClick = (rowData, index) => {
    let tempData = this.state.poCorrectionTableData.map((item) => {
      if (item.key === rowData.key) {
        return this.state.rowData;
      } else {
        return item;
      }
    });
    this.setState({ poCorrectionTableData: tempData });
  };

  render() {
    const userTableProps = {
      pagination: false,
      size: "default",
      bordered: true,
      scroll: { x: 200, y: window.innerHeight - 250 },
    };

    return (
      <div
        className="mandi-master-container"
        style={{ height: window.innerHeight - 85, marginTop: "-45px" }}
      >
        <div className="top-container">
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row-reverse",
              placeItems: "flex-end",
              marginRight: "25px",
              marginBottom: "10px",
            }}
          >
            <Search
              disabled={!this.state.isAdd}
              placeholder="Search"
              allowClear
              onChange={(e) => this.setState({ searchText: e.target.value })}
              style={{ width: "25%" }}
            />
            <Button
              className="add-master-button"
              style={{
                height: "25px",
                width: "70px",
                backgroundColor: "#0b133d",
                color: "white",
              }}
              disabled={!canUserEdit(
                this.props.userData,
                null,
                FeatureCodes.MS_PO_PRICE_CORREC_EDIT
              ) || !this.state.isAdd}
              onClick={() => this.setModalVisibility(true)}
            >
              &#10010; Add
            </Button>
          </div>
        </div>

        <div>
        <div
        style={{
          marginLeft: "25px",
          marginTop: "-8px",
          fontSize: "14px",
          color: "red",
          display: "flex",
          float: "left",
        }}
      > {getUserMSRBACMessage(this.props.messageAccess,FeatureCodes.MS_PO_PRICE_CORREC_EDIT)}
      </div>
        <div
          style={{
            marginRight: "20px",
            marginTop: "-8px",
            fontSize: "15px",
            color: "#0B133D",
            display: "flex",
            float: "right",
          }}
        >
          Total number of records:&nbsp;
          <b>
            {this.state.poCorrectionTableData && this.setTableData().length}
          </b>
        </div>
</div>

        <Spin
          spinning={this.state.isSpinnerOnLoad}
          size={"large"}
          tip="Loading..."
          style={{ alignSelf: "center", zIndex: 9999, marginTop: "20px" }}
        >
          <Modal
            className="add-master-modal"
            title={"Add PO Price Correction"}
            footer={false}
            // style={{
            //   textAlign: "center",
            //   height: "400px",
            // }}
            // visible={true}
            visible={this.state.isModalVisible}
            destroyOnClose={true}
            onCancel={this.handleCancel}
          >
            <div className="master-form-container">
              <Form
                layout="horizontal"
                //   onValuesChange={onFormLayoutChange}
                size={"small"}
              // onSubmit={submitTableRowData(this.state.newData)}
              >
                <div>
                  <div
                    className="checkbox-group1"
                    style={{
                      display: "inline-block",
                      width: "60%",
                      marginRight: 7,
                    }}
                  >
                    <Form.Item label="State">
                      <Select
                        className="remarks-select"
                        showSearch
                        optionFilterProp="children"
                        name="State"
                        defaultValue={this.state.newData.State}
                        value={this.state.newData.State}
                        onSelect={(value, option) =>
                          this.handleDropdownChange(
                            value,
                            this.state.newData,
                            "State",
                            -0,
                            option.props.children,
                            true
                          )
                        }
                      >
                        {this.state.stateList &&
                          this.state.stateList.length &&
                          this.state.stateList.map((hubItem, idx) => (
                            <Option
                              key={hubItem.state_id}
                              value={hubItem.state_id}
                            >
                              {hubItem.state_name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </div>

                  <div
                    className="checkbox-group1"
                    style={{
                      display: "inline-block",
                      width: "60%",
                      marginRight: 7,
                    }}
                  >
                    <Form.Item label="Corrected Price">
                      <Input
                        style={{ marginRight: "2px" }}
                        maxLength={100}
                        required
                        value={this.state.newData.Corrected_Price}
                        name="Corrected_Price"
                        onChange={(e) =>
                          this.handleChange(
                            e,
                            this.state.newData,
                            -0,
                            "Corrected_Price",
                            true
                          )
                        }
                      />
                    </Form.Item>
                  </div>
                </div>

                <div className="form-bottom-container">
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={this.state.isSubmitted}
                      onClick={() => {
                        // this.setState({isSubmitted:false})
                        this.submitTableRowData(this.state.newData);
                      }}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </Modal>

          <div
            className="table-container1"
            style={{ maxHeight: "450px", width: "99%", margin: "6px 0px" }}
          >
            <Table
              {...userTableProps}
              columns={[
                {
                  title: "State",
                  dataIndex: "State",
                  key: "State",
                  width: 90,
                  filters: this.createFilters("State"),
                  onFilter: (value, record) => record.State.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.State.localeCompare(b.State),
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div
                          style={{ marginLeft: "-15px", marginRight: "-10px" }}
                        >
                          <Select
                            className="remarks-select"
                            showSearch
                            optionFilterProp="children"
                            disabled={true}
                            name="State"
                            defaultValue={rowRecord.State}
                            value={rowRecord.State}
                            onSelect={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "State",
                                index,
                                option.props.children,
                                false
                              )
                            }
                          >
                            {this.state.stateList &&
                              this.state.stateList.length &&
                              this.state.stateList.map((hubItem, idx) => (
                                <Option
                                  key={hubItem.state_id}
                                  value={hubItem.state_id}
                                >
                                  {hubItem.state_name}
                                </Option>
                              ))}
                          </Select>
                        </div>
                      </>
                    );
                  },
                },
                {
                  key: "Corrected_Price",
                  title: "Corrected Price",
                  dataIndex: "Corrected_Price",
                  width: 125,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.Corrected_Price - b.Corrected_Price,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            style={{ marginRight: "2px" }}
                            maxLength={100}
                            disabled={!rowRecord.isEdit}
                            value={rowRecord.Corrected_Price}
                            name="Corrected_Price"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "Corrected_Price",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },

                {
                  title: "Action",
                  dataIndex: "Submit",
                  key: "Submit",
                  width: 140,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ display: "inline-flex", width: "100%" }}>
                          <Button
                          className={!canUserEdit(
                            this.props.userData,
                            null,
                            FeatureCodes.MS_PO_PRICE_CORREC_EDIT
                          )? "disabledBtnClass" : "btnClass"}
                          disabled={!canUserEdit(
                            this.props.userData,
                            null,
                            FeatureCodes.MS_PO_PRICE_CORREC_EDIT
                          )}
                            type="primary"
                            size={"small"}
                            style={{
                              marginLeft: 5,
                              alignSelf: "center",
                              color: "white",
                              borderRadius: 15,
                              padding: "0px 0px 2px",
                              height: 31,
                              backgroundColor: "#20346a",
                            }}
                            block
                            htmlType="submit"
                            // disabled={this.state.isSubmitted}
                            onClick={() =>
                              rowRecord.isEdit
                                ? this.submitTableRowData(rowRecord)
                                : this.handleEditClick(rowRecord, index)
                            }
                          >
                            {rowRecord.isEdit ? "Submit" : "Edit"}
                          </Button>

                          <Button
                            className={
                              !rowRecord.isEdit
                                ? "disabledBtnClass"
                                : "btnClass"
                            }
                            type="primary"
                            size={"small"}
                            style={{
                              marginLeft: 5,
                              alignSelf: "center",
                              color: "white",
                              borderRadius: 15,
                              padding: "0px 0px 2px",
                              height: 31,
                              backgroundColor: "#20346a",
                            }}
                            block
                            htmlType="submit"
                            disabled={!rowRecord.isEdit}
                            onClick={() =>
                              this.handleCancelClick(rowRecord, index)
                            }
                          >
                            Cancel
                          </Button>
                        </div>
                      </>
                    );
                  },
                },
              ]}
              dataSource={this.setTableData()}
            />
          </div>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
  messageAccess: selectMSMessageRBAC,
});
export default connect(mapStateToProps)(POCorrectionMaster);
//export default POCorrectionMaster;
