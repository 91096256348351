import API from "../api";
import api from "../api";
import { message } from "antd";
import Axios from "axios";

export const getVarietiesAPI = async () => {
  return API.get("/varieties");
};

export const getStatesAPI = async () => {
  return API.get("/states");
};

export const getVarietiesWithStates = async () => {
  return API.get("/recommendations/monthly/price/qc_rules_filter");
};

// Volume visualization bar graph

export const getVolumeVisualizationData = async (
  source_type,
  model_run_list,
  branch_id,
  variety_id,
  basis_id
) => {
  const params = {
    info_source_type: source_type,
    model_runs: model_run_list,
    branch_id: branch_id,
    variety_id: variety_id,
    basis_id: basis_id,
  };
  return API.get(`/actual_vs_models`, { params: params });
};

//Tabular forecast apis

// Tab - I
export const getVolumeBuyingPlanState = async (run_month, qc_type) => {
  const params = {
    model_run: run_month,
    info_source_type: qc_type,
  };

  return Axios.all([
    API.get(`/dr_volume_buying_plan_state`, {
      params: params
    }),
    API.get(`/dr_buying_price_plan_state`, {
      params: params
    }),
  ]);
};

//Tab - II
export const getBuyingQuantities = async (run_month, qc_type) => {
  const params = {
    model_run: run_month,
    info_source_type: qc_type,
  };

  return Axios.all([
    API.get(`/dr_volume_buying_plan_state_variety`, {
      params: params
    }),
    API.get(`/dr_buying_percent_statewise`, {
      params: params
    }),
  ]);
};

//Tab - III
export const getVolumeBuyingPlanStateFactory = async (run_month, qc_type) => {
  const paramsForMajorTable = {
    model_run: run_month,
    info_source_type: qc_type,
  };
  const paramsForOtherTables = {
    info_source_type: qc_type,
  };

  return Axios.all([
    API.get(`/dr_volume_buying_plan_state_factory`, {
      params: paramsForMajorTable
    }),
    API.get(`/dr_indicative_freight`, {
      params: paramsForOtherTables
    }),
    API.get(`/dr_landed_cost`, {
      params: paramsForOtherTables
    }),
  ]);
};

// Weekly -graphs API

export const getWeeklyGraphData = async (state_id, variety_id, week_run) => {
  const weeklyGraphParams = {
    state_id,
    variety_id,
    week_run,
  };
  return API.get(`/recommendations/weekly/qc_rules`, {
    params: weeklyGraphParams,
  });
};
