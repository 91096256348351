import React, { Component } from "react";
import "./projection-graph.styles.css";

import { Line } from "react-chartjs-2";

import { getRandomColor } from "../../auth/utils";

class ProjectionGraph extends Component {
  render() {
    const { isVolume, projectionData, isYearDemarcated } = this.props;

    console.log("projection data :", projectionData);

    let legendInformation = [];

    const chartOptions = {
      responsive: false,
      spanGaps: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            let dataset = data["datasets"][tooltipItem.datasetIndex];
            let value = dataset["data"][tooltipItem["index"]];
            if (tooltipItem.datasetIndex % 2 !== 0) {
              return `${dataset.label.replace("extrapolated", "")} - ${value} ${
                isVolume ? "MT" : "₹/MT"
              }`;
            }
          },
        },
        label: true,
        intersect: false,
        mode: "nearest",
        backgroundColor: "white",
        titleFontColor: "#313131",
        borderColor: "#9d9d9d",
        borderWidth: 1,
        bodyFontColor: "#575757",
        displayColors: false,
      },
      elements: {
        line: {
          tension: 0,
        },
      },
      label: true,
      intersect: false,
      mode: "nearest",
      backgroundColor: "white",
      titleFontColor: "#313131",
      borderColor: "#9d9d9d",
      borderWidth: 1,
      bodyFontColor: "#575757",
      displayColors: false,
      scales: {
        xAxes: isYearDemarcated
          ? [
              {
                id: "xAxis1",
                type: "category",

                ticks: {
                  callback: function (label) {
                    var month = label.split("-")[0];
                    var year = label.split("-")[1];
                    return month;
                  },
                },
              },
              {
                id: "xAxis2",
                type: "category",
                gridLines: {
                  drawOnChartArea: true, // false=> only want the grid lines for one axis to show up
                },
                ticks: {
                  callback: function (label) {
                    var month = label.split("-")[0];
                    var year = label.split("-")[1];
                    if (month === "Feb") {
                      return "20" + year;
                    } else {
                      return "";
                    }
                  },
                },
                scaleLabel: {
                  display: true,
                  labelString: "Time",
                },
              },
            ]
          : [
              {
                id: "Time",
                ticks: {
                  maxRotation: 0,
                },
                scaleLabel: {
                  display: true,
                  labelString: "Time",
                },
              },
            ],
        yAxes: [
          {
            id: "price/volume",
            //   ticks: { beginAtZero: true },
            scaleLabel: {
              display: true,
              labelString: isVolume
                ? "Projected Volume (MT)"
                : "Projected Price (₹/MT)",
            },
          },
        ],
      },
    };

    const tempData = {
      data_points: [
        {
          name: "V1",
          price_values_actual: [
            10,
            20,
            30,
            400,
            500,
            300,
            200,
            200,
            100,
            150,
            2000,
            3000,
            300,
            5000,
            6000,
            300,
            4000,
            4444,
            5555,
          ],
          price_values_predicted: [
            10,
            20,
            30,
            400,
            500,
            300,
            200,
            200,
            100,
            150,
            2000,
            3000,
            300,
            5000,
            6000,
            300,
            4000,
            4444,
            5555,
            5555,
          ],
          volume_values_actual: [
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            192100,
            193100,
            null,
            195100,
            196100,
            null,
            null,
            204100,
            205100,
          ],
          volume_values_predicted: [
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            192100,
            193100,
            null,
            195100,
            196100,
            null,
            null,
            204100,
            205100,
            206100,
          ],
        },
      ],
      x_axis_labels: [
        "Feb_2017",
        "Mar_2017",
        "Apr_2017",
        "May_2017",
        "Jun_2017",
        "Feb_2018",
        "Mar_2018",
        "Apr_2018",
        "May_2018",
        "Jun_2018",
        "Feb_2019",
        "Mar_2019",
        "Apr_2019",
        "May_2019",
        "Jun_2019",
        "Feb_2020",
        "Mar_2020",
        "Apr_2020",
        "May_2020",
        "Jun_2020",
      ],
    };

    const getPriceDataSets = () => {
      legendInformation = [];
      let dataSets = [];

      projectionData.data_points.map((item, idx) => {
        const isNotValidForGraph = isVolume
          ? item.volume_values_actual.every((item) => item === null)
          : item.price_values_actual.every((item) => item === null);

        if (isNotValidForGraph) {
          // Do nothing.
        } else {
          let lineColor = getRandomColor(idx);
          legendInformation.push({
            name: item.name,
            color: lineColor,
          });

          let pairSet = [
            {
              type: "line",
              label: item.name,
              borderColor: lineColor,
              borderWidth: 2,
              fill: false,
              data: isVolume
                ? item.volume_values_actual
                : item.price_values_actual,
            },
            {
              type: "line",
              label: item.name + " extrapolated",
              borderColor: lineColor,
              borderWidth: 2,
              fill: false,
              borderDash: [5, 5],
              data: isVolume
                ? item.volume_values_predicted
                : item.price_values_predicted,
            },
          ];
          dataSets.push(...pairSet);
        }
      });
      return dataSets;
    };

    const chartData = {
      labels: projectionData.x_axis_labels,
      datasets: getPriceDataSets(),
    };

    return (
      <div className="fixed-state-variety-projection-container">
        <div className="projection-graph-container">
          <div
            onClick={() => {
              console.log("chartdata", chartData);
            }}
            className="graph-info"
          >
            <div className="graph-title">{`Projected Trend Graph ${
              isVolume ? "- Volume" : "- Price"
            }`}</div>
            <div className="legend-container">
              {legendInformation.map((legend) => (
                <div key={legend.name} className="legend-box">
                  <div
                    className="legend-fill"
                    style={{
                      background: legend.color,
                    }}
                  />
                  <div className="legend-title">{legend.name}</div>
                </div>
              ))}
            </div>
            <div className="graph">
              {/* <div>
                {isVolume ? "Projected Volume (MT)" : "Projected Price (₹/MT)"}
              </div> */}
              <Line
                id="projection-line-chart"
                height={230}
                width={1200}
                data={chartData}
                options={chartOptions}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectionGraph;
