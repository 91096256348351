import React from "react";
import "./user-management-form.styles.css";
import { Component } from "react";


import {
  Form,
  Radio,
  Input,
  Select,
  Switch,
  InputNumber,
  Button,
  message,
} from "antd";

import API from "../../api";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { setAllUsers, setUserRoleData, setReportData } from "../../redux/user/user.actions";
import {
  selectallReportData,
  selectUserRoleData,
} from "../../redux/user/user.selectors";

const { Option } = Select;
/**
 * @component
 * @description the form component handling add/editing user information.
 * @property {object[]} branchList list of all the branches.
 * @property {object[]} reportList list of all the repports.
 * @property {object[]} roleList list of all the user roles.
 * @property {boolean} isAdd set true to add a new user, false to edit an existing user.
 * @property {object} currentRecord the current selected user record.
 * @property {string[]} currentBranches the branches assigned to the selected user.
 * @property {function} setModalVisibility fucntion to enable/disable the visibility of the form modal.
 */
class UserForm extends Component {
  state = { isSubmitButtonClicked: false };
  componentDidMount() {
    this.getUserRoleData();
    this.getReportData();
  }
  getUserRoleData = async () => {

    const ROLE_DATA_ENDPOINT = `/user_role`
    await API.get(ROLE_DATA_ENDPOINT, {

    })
      .then((userRoleResponse) => {


        if (userRoleResponse.data) {


          let roletypes = [];
          if (userRoleResponse.data.data) {
            userRoleResponse.data.data.map((v) => {
              roletypes.push({ id: v.role_id, label: v.role_name })
            })
          }
          this.props.setUserRoleData(roletypes);


        }
      })
      .catch((err) => {
        console.log("error getting buying decision response : ERROR : ", err);

      });

  }
  getReportData = async () => {

    const REPORT_DATA_ENDPOINT = `/reports`
    await API.get(REPORT_DATA_ENDPOINT, {

    })
      .then((reportResponse) => {
        if (reportResponse.data.status) {
          const allReports = reportResponse.data.data.map(
            (report) => {
              return {
                param_display_value: report.param_display_value,
                param_data_value: report.param_data_value,
                param_type: report.param_type,
              };
            }
          );
          this.props.setReportData(allReports);
        } else {
          console.log("error getting Reports");
        }
      })
      .catch((err) => {
        console.log("Error getting all reports : ERROR :", err);
      });
  }
  render() {
    const {
      branchList,
      roleList,
      isAdd,
      currentRecord,
      currentBranches,
      setModalVisibility,
      availableRoles,
      availableReports,
      reportList
    } = this.props;
    var currentReports = [];
    if (currentRecord && currentRecord.rep_competition_info) {
      currentReports.push("rep_competition_info")
    }
    if (currentRecord && currentRecord.rep_war_room) {
      currentReports.push("rep_war_room")
    }
    if (currentRecord && currentRecord.rep_user_adoption) {
      currentReports.push("rep_user_adoption")
    }


    const { getFieldDecorator } = this.props.form;

    /**
     *
     * @description Generate array of objects of user branches to send to the backend.
     * @param {string[]} branches the selected branch names.
     * @returns array of objects representing the selected branches.
     * @memberof UserForm
     */
    const setTerritories = (branches) => {
      const selectedTerritories = branchList.filter((item) =>
        branches.includes(item.territory_name)
      );
      return selectedTerritories;
    };
    const setReports = (reports, report) => {
      if (reports.includes(report)) {
        return 1;
      }

      return 0;
    };

    /**
     * @description Generate object storing role information for sending to the backend.
     * @param {number} roleID selected roll id.
     * @returns object representing the role.
     * @memberof UserForm
     */
    const setRole = (roleID) => {

      const { availableRoles, currentRecord } = this.props
      let roles = currentRecord && currentRecord.role ? {
        role_name: currentRecord.role.role_name,
        role_id: currentRecord.role.role_id
      } : {};
      availableRoles.map((v) => {
        if (v.id === roleID) {
          roles = {
            role_id: v.id,
            role_name: v.label,
          }
        }
      })

      return roles;

    };

    /**
     * @description Handle the add/edit user form submission.
     * @param {event} e form submission event containing values for passing to the api.
     * @memberof UserForm
     */
    const handleSubmit = (e) => {
      const alphaNumericRegex = /^[a-zA-Z0-9]*$/;
      e.preventDefault();
      this.props.form.validateFields((err, values) => {
        
        if (!err) {

          const userHeader = {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          };

          //#region api to create or update user

          const validate = () => {
            if (values.phone.toString().length !== 10) {
              message.error("Please enter a 10 digit phone number to submit.");
              return false;
            } else if  (values.employeeID.toString().match(alphaNumericRegex)=== null){
              message.error("Please avoid entering spaces and use only alphanumeric");
              return false;
            }
            return true;
        }
          

            
          

          if (validate()) {
            this.setState({ isSubmitButtonClicked: true });

            if (isAdd) {
              //#region api to add user
              const postData = {
                name: values.fullName,
                user_name: values.employeeID.toString(),
                contact_number: values.phone,
                is_active: values.isEnabled,
                email: values.emailID,
                territories: setTerritories(values.branches),
                role: setRole(this.state.roleType),
                rep_user_adoption: setReports(values.reports, "rep_user_adoption"),
                rep_competition_info: setReports(values.reports, "rep_competition_info"),
                rep_war_room: setReports(values.reports, "rep_war_room")
              };

              API.post(`/users`, postData)
                .then((statusResponse) => {
                  if (statusResponse.data.status) {
                    console.log("submitted successfully", statusResponse);

                    //#region cache modified user record to redux

                    let modifiedUserList = [
                      ...this.props.allUsers,
                      statusResponse.data.data,
                    ];

                    // modifiedUserList.push(statusResponse.data.data);

                    this.props.setAllUsers(modifiedUserList);

                    //#endregion

                    setModalVisibility(false, false, null);
                    this.props.form.resetFields();
                    this.setState({ isSubmitButtonClicked: false });
                  } else {
                    this.setState({ isSubmitButtonClicked: false });
                    console.log("error submitting....");
                    message.error(
                      "Could not update user at the moment. Please try again later."
                    );
                  }
                })
                .catch((err) => {
                  this.setState({ isSubmitButtonClicked: false });
                  console.log("error submitting....", err.response);
                  if (err.response && err.response.status === 400) {
                    message.error(
                      "Employee ID or Email ID already Exist"
                    );
                  }
                  else {
                    message.error(
                      "Could not update user at the moment. Please try again later.", err
                    );
                  }
                });

              //#endregion
            } else {
              //#region api to update user
              const putData = {
                name: values.fullName,
                user_name: values.employeeID.toString(),
                contact_number: values.phone,
                is_active: values.isEnabled,
                email: values.emailID,
                territories: setTerritories(values.branches),
                role: setRole(this.state.roleType),
                rep_user_adoption: setReports(values.reports, "rep_user_adoption"),
                rep_competition_info: setReports(values.reports, "rep_competition_info"),
                rep_war_room: setReports(values.reports, "rep_war_room")
              };

              API.put(`/users/${currentRecord.user_id}`, putData)
                .then((statusResponse) => {
                  if (statusResponse.data.status) {
                    console.log("submitted successfully", statusResponse);

                    //#region cache modified user record to redux

                    let modifiedUserList = this.props.allUsers;
                    modifiedUserList = modifiedUserList.map((item) => {
                      if (item.user_id === currentRecord.user_id) {
                        return {
                          ...item,
                          name: values.fullName,
                          user_name: values.employeeID,
                          contact_number: values.phone,
                          is_active: values.isEnabled,
                          email: values.emailID,
                          territories: setTerritories(values.branches),
                          role: setRole(values.roles),
                          rep_user_adoption: setReports(values.reports, "rep_user_adoption"),
                          rep_competition_info: setReports(values.reports, "rep_competition_info"),
                          rep_war_room: setReports(values.reports, "rep_war_room")
                        };
                      }
                      return item;
                    });

                    this.props.setAllUsers(modifiedUserList);

                    //#endregion

                    setModalVisibility(false, false, null);
                    this.props.form.resetFields();
                    this.setState({ isSubmitButtonClicked: false });
                  } else {
                    this.setState({ isSubmitButtonClicked: false });
                    console.log("error submitting....");
                    message.error(
                      "Could not update user at the moment. Please try again later."
                    );
                  }
                })
                .catch((err) => {
                  this.setState({ isSubmitButtonClicked: false });
                  console.log("error submitting....", err.response);
                  if (err.response && err.response.status === 400) {
                    message.error(
                      "Email ID already Exist"
                    );
                  }
                  else {
                    message.error(
                      "Could not update user at the moment. Please try again later.", err
                    );
                  }
                });
              //#endregion
            }
          }

          //#endregion
        } else {
          console.log("Invalid entry");
        }
      });
    };

    return (
      <div className="user-form-container">

        <Form
          layout="horizontal"
          //   onValuesChange={onFormLayoutChange}
          size={"small"}
          onSubmit={handleSubmit}
        >
          <div className="form-top-container">
            <div className="form-top-left">
              <Form.Item label="Full Name">
                {getFieldDecorator("fullName", {
                  initialValue:
                    currentRecord !== null ? currentRecord.name : "",
                  rules: [
                    {
                      required: true,
                      message: "Please enter users full name!",
                    },
                  ],
                })(<Input disabled={this.state.isSubmitButtonClicked} />)}
              </Form.Item>
              <Form.Item label="Employee ID">
                {getFieldDecorator("employeeID", {
                  initialValue: currentRecord ? currentRecord.user_name : "",
                  rules: [
                    {
                      required: true,
                      message: "Please enter user employee ID!",
                    },
                    {
                     // required:true,
                    //  type:"regexp",
                       pattern: new RegExp("^[a-zA-Z0-9]*$"),
                       message: "Employee ID does not accept spaces and special characters!",
                       }
                  //  { validator:this.validate,
                  //  }
                  ],
                })(
                  <Input
                    disabled={!isAdd || this.state.isSubmitButtonClicked}
                  />
                )}
              </Form.Item>
              <Form.Item label="Email ID">
                {getFieldDecorator("emailID", {
                  initialValue: currentRecord ? currentRecord.email : "",
                  rules: [
                    { required: true, message: "Please enter user email id!" },
                  ],
                })(
                  <Input
                    disabled={this.state.isSubmitButtonClicked}
                    type="email"
                  />
                )}
              </Form.Item>
              <Form.Item label="Phone">
                {getFieldDecorator("phone", {
                  initialValue: currentRecord
                    ? currentRecord.contact_number
                    : null,
                  rules: [
                    {
                      required: true,
                      message: "Please enter a 10 digit mobile number!",
                    },
                  ],
                })(<Input type="number" disabled={this.state.isSubmitButtonClicked} />)}
              </Form.Item>


              <div className="form-top-right">
                <Form.Item label="Branches">
                  {getFieldDecorator("branches", {
                    initialValue: currentBranches ? currentBranches : [],
                    rules: [
                      {
                        required: true,
                        message: "Please enter branch/branches !",
                      },
                    ],
                  })(

                    <Select
                      disabled={this.state.isSubmitButtonClicked}
                      showArrow
                      mode="multiple"
                      placeholder="Select branches"
                      style={{
                        marginLeft: "10px",
                        width: "100%"
                      }}
                    >
                      {branchList.map((branch, idx) => (
                        <Select.Option
                          key={branch.territory_id}
                          value={branch.territory_name}
                        >
                          {branch.territory_name}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item label="Reports">
                  {getFieldDecorator("reports", {
                    initialValue: currentReports ? currentReports : [],
                    rules: [
                      {
                        required: false,
                        message: "Please enter report/reports !",
                      },
                    ],
                  })(
                    <Select
                      // disabled={this.state.isSubmitButtonClicked}
                      showArrow
                      mode="multiple"
                      placeholder="Select Reports"
                      style={{
                        marginLeft: "10px",
                        width: "100%"
                      }}
                    >
                      {reportList.map((report, idx) => (
                        <Select.Option
                          key={report.param_data_value}
                          value={report.param_display_value}
                        >
                          {report.param_display_value}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item label="Choose Role">
                  {getFieldDecorator("roles", {
                    initialValue: (currentRecord && currentRecord.role)
                      ? currentRecord.role.role_name
                      : null,
                    rules: [
                      {
                        required: true,
                        message: "Please select Role !",
                      },
                     
                    ],
                  })(
                    <Select
                      showSearch
                      showArrow={true}
                      placeholder="Select role"
                      onSelect={(value) => this.setState({ roleType: value })}
                      style={
                        {
                          marginLeft: "10px",
                          width: "100%"

                        }}
                    >
                      {availableRoles.map((item, idx) => (
                        <Option value={item.id} key={item.id}>
                          {item.label}
                        </Option>
                      ))}
                    </Select>)}
                </Form.Item>


              </div>
            </div>
          </div>

          <div className="form-bottom-container">
            <div>
              {!isAdd && (
                <Form.Item>
                  {getFieldDecorator("isEnabled", {
                    valuePropName: "checked",
                    initialValue: currentRecord
                      ? currentRecord.is_active
                      : true,
                  })(
                    <Switch
                      disabled={this.state.isSubmitButtonClicked}
                      onChange={(value) => console.log(value)}
                      checkedChildren="&nbsp;&nbsp;Active"
                      unCheckedChildren="Inactive"
                    />
                  )}
                </Form.Item>
              )}
            </div>

            <Form.Item>
              <Button
                disabled={this.state.isSubmitButtonClicked}
                type="primary"
                htmlType="submit"
              >
                Submit
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  availableRoles: selectUserRoleData,
  availableReports: selectallReportData


});
const UserManagementForm = Form.create({ name: "user_management_form" })(
  UserForm
);

const mapDispatchToProps = (dispatch) => {
  return {
    setAllUsers: (data) => dispatch(setAllUsers(data)),
    setUserRoleData: (data) => dispatch(setUserRoleData(data)),
    setReportData: (data) => dispatch(setReportData(data)),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserManagementForm);
