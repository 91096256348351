import React from "react";
import "./hub-factory-frieght-master.styles.css";
import { Component } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUserData ,selectMSMessageRBAC} from "../../redux/user/user.selectors";
import { canUserEdit,getUserMSRBACMessage } from "../../auth/utils";
import { FeatureCodes } from "../../auth/data";
// import CustomFilterMultiselect from "../../components/custom-multi-select/custom-multi-select.component";

import {
  Table,
  Spin,
  Input,
  Select,
  Button,
  message,
  Modal,
  Form,
  Radio,
} from "antd";

import CustomSelectSummary from "../../components/custom-select-summary/custom-select-summary.component";

import API from "../../api";

const { Search } = Input;
const { Option } = Select;

class HubFactoryFrieghtMaster extends Component {
  state = {
    isModalVisible: false,
    isAdd: false,
    currentRecord: null,
    userBranches: null,
    searchText: null,
    searchUserType: 0,
    hubFactoryTableData: [],
    isSubmitted: false,
    primaryHubList: [],
    filteredHubList: [],
    hubRegionList: [],
    buyNoBuyList: [],
    mandiBranchList: [],
    isSpinnerOnLoad: false,
    newData: {
      is_active: true,
      Factory_Buying: undefined,
      Factory_Name: "",
      H_F_Freight_PerMT: undefined,
      Hub_Factory_Freight_ID: undefined,
      Hub_Name: "",
      Route: "",
      branch_id: undefined,
      branch_name: "",
      branch_state: "",
      factory_id: undefined,
      hub_id: undefined,
      distance:undefined,
      isEdit: false,
    },
    rowData: undefined,
    selectedHubValue: undefined,
    selectedHubName: "",
    selectedHubList: [],
    factoryList: [],
    routeList: [],
    selectedBranchValue: undefined,
    selectedBranchname: "",
    selectedBranchList: [],
  };

  componentDidMount() {
    this.setState({ isSpinnerOnLoad: true });
    this.getbranchList();
    // this.getHubList();
  }

  getMasterHub = async () => {
    this.setState({ isSpinnerOnLoad: true });

    const params = {
      hub_id: this.state.selectedHubValue === null || this.state.selectedHubValue === undefined
        ? null
        : [this.state.selectedHubValue],
      branch_id: this.state.selectedBranchValue === null || this.state.selectedBranchValue === undefined
        ? null
        : [this.state.selectedBranchValue],
    };

    await API.get("/hub_factory_freight", { params: params })
      .then((varietyResponse) => {
        varietyResponse.data.data.map((dataDetailItem, index) => {
          dataDetailItem.isEdit = false;
          dataDetailItem.key = index;
        });
        this.setState({
          hubFactoryTableData: varietyResponse.data.data,
          isSpinnerOnLoad: false,
          isAdd: true,
        });

      })
      .catch((err) => {
        if (err) {

          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });

  };

  getRouteList = async () => {
    this.setState({ isSpinnerOnLoad: true });

    await API.get("/get_routes")
      .then((mandiBranch) => {

        this.setState(
          {
            routeList: [...mandiBranch.data.data],
          },

          () => this.getMasterHub()
        );
      })
      .catch((err) => {
        this.setState({ isSpinnerOnLoad: false });
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
      });
  };

  getFactoryList = async () => {


    await API.get("/list_factory")
      .then((hubRegion) => {
        let modifiedData = [...hubRegion.data.data];
        modifiedData.push({ factory_id: null, factory_name: "All" });

        this.setState(
          {
            // selectedRegionList:modifiedData,
            factoryList: hubRegion.data.data,
            // selectedRegionValue: hubRegion.data.data[0].region_id,
            // selectedRegionName: hubRegion.data.data[0].region_name
          },
          () => this.getRouteList()
        );
      })
      .catch((err) => {
        this.setState({ isSpinnerOnLoad: false });
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
      });
  };

  getbranchList = async () => {
    this.setState({ isSpinnerOnLoad: true });


    await API.get("/list_branch")
      .then((mandiBranch) => {

        let modifiedData = [...mandiBranch.data.data];
        modifiedData.push({ branch_id: null, branch_name: "All" });
        this.setState(
          {
            selectedHubList: modifiedData,
            selectedBranchList: mandiBranch.data.data,
            selectedBranchValue: mandiBranch.data.data[0].branch_id,
            selectedBranchName: mandiBranch.data.data[0].branch_name,
          },

          () => this.getHubList()
        );
      })
      .catch((err) => {
        this.setState({ isSpinnerOnLoad: false });
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
      });
  };

  getHubList = async () => {
    this.setState({ isSpinnerOnLoad: true });


    await API.get("/list_hubs")
      .then((mandiBranch) => {
        // let modifiedData = [...mandiBranch.data.data];
        // modifiedData.push({ branch_id: null, branch_name: "All" });
        const filterhub = mandiBranch.data.data.filter((hubItem) => hubItem.parent_id === this.state.selectedBranchValue);
        this.setState(
          {
            primaryHubList: mandiBranch.data.data,
            filteredHubList: filterhub,
            selectedHubValue: filterhub[0] ? filterhub[0].territory_id : 0,
            selectedHubName: filterhub[0] ? filterhub[0].territory_name : 0,
          },

          () => this.getFactoryList()
        );
      })
      .catch((err) => {
        this.setState({ isSpinnerOnLoad: false });
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
      });
  };

  setTableData = () => {
    if (
      this.state.hubFactoryTableData !== undefined &&
      this.state.hubFactoryTableData.length > 0
    ) {

      const userTableData = this.state.hubFactoryTableData.map((user, index) => {
        return {
          key: user.key,
          is_active: user.is_active,
          Factory_Buying: user.Factory_Buying,
          Factory_Name: user.Factory_Name,
          distance:user.distance,
          H_F_Freight_PerMT: user.H_F_Freight_PerMT,
          Hub_Factory_Freight_ID: user.Hub_Factory_Freight_ID,
          Hub_Name: user.Hub_Name,
          Route: user.Route,
          branch_id: user.branch_id,
          branch_name: user.branch_name,
          branch_state: user.branch_state,
          factory_id: user.factory_id,
          hub_id: user.hub_id,
          isEdit: user.isEdit,
        
        };
      });


      let dataAfterSearch = userTableData;

      if (this.state.searchText) {
        dataAfterSearch = dataAfterSearch.filter(
          (item) =>
            (item.Hub_Name !== null &&
              item.Hub_Name
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase())) ||
            (item.Factory_Name !== null &&
              item.Factory_Name
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase())) ||
            (item.Route !== null &&
              item.Route
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase()))
        );
      }

      return dataAfterSearch;
    } else {
      return this.state.hubFactoryTableData;
    }
  };



  submitTableRowData = (row, isPopup) => {
    this.setState({ isSpinnerOnLoad: true, isSubmitted: true });
    let warningmessage = "";
    if (
      row.Hub_Name === null ||
      row.Hub_Name === undefined ||
      row.Hub_Name === ""
    ) {
      warningmessage = "Please Select hub";
    } else if (
      row.Factory_Name === null ||
      row.Factory_Name === undefined ||
      row.Factory_Name === ""
    ) {
      warningmessage = "Please select Factory";
    } else if (
      row.Route === null ||
      row.Route === undefined ||
      row.Route === ""
    ) {
      warningmessage = "Please select Route";
    } else if (
      row.H_F_Freight_PerMT === null ||
      row.H_F_Freight_PerMT === undefined ||
      row.H_F_Freight_PerMT === "" ||
      row.H_F_Freight_PerMT === 0
    ) {
      warningmessage = "Please enter Hub Factory Freight(MT)";
    } else if (
      row.distance === null ||
      row.distance === undefined ||
      row.distance === "" ||
      row.distance === 0
    ) {
      warningmessage = "Please enter Distance(kms)";
    }

    if (
      warningmessage === "" ||
      warningmessage === undefined ||
      warningmessage === null
    ) {
      this.setState({ isSubmitted: true });
      this.setState({ isSpinnerOnLoad: true });
      let data = {
        is_active: row.is_active ? 1 : 0,
        Hub_Factory_Freight_ID: row.Hub_Factory_Freight_ID,
        Route: row.Route,
        factory_id : row.factory_id,
        hub_id : row.hub_id,
        H_F_Freight_PerMT: row.H_F_Freight_PerMT === "" || row.H_F_Freight_PerMT === undefined
          ? null
          : row.H_F_Freight_PerMT,
          distance:row.distance,
      };

      API.put("/hub_factory_freight", data, {

      })
        .then((response) => {

          if (response.status === 200 && response.data.status) {
            this.setModalVisibility(false);
            message.info(response.data.message, 5);
            this.getMasterHub();
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
          } else if (!response.data.status) {
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
            message.error(response.data.message, 5);
            // this.setModalVisibility(false);
          } else {
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
            message.error("Please try again", 5);
          }
        })
        .catch((err) => {
          if (err) {
            if (err.response && err.response.status === 400)
              message.error("Something went wrong. Please try again later.", 5);
            else {
              message.error(
                "Something went wrong.. Please try again later.",
                5
              );
            }
          } else {
            message.error("Something went wrong... Please try again later.", 5);
          }
          this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
          // this.setModalVisibility(false);
        });
    } else {
      this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
      message.error(warningmessage, 5);
    }
  };

  TableColumnHeader = (props) => {
    const { title, subtitle } = props;
    return (
      <div className="column-header-container">
        <div
          style={{
            color: "white",
            fontSize: "12px",
            whiteSpace: "nowrap",
            fontWeight: "normal",
            textAlign: "left",
          }}
        >
          {title}
        </div>
        <div
          style={{
            color: "#cbdfff",
            fontSize: "11px",
            fontWeight: "normal",
            textAlign: "left",
            // subtitle && title.length < subtitle.length ? "left" : "center"
          }}
        >
          {subtitle}
        </div>
      </div>
    );
  };

  createFilters = (label) => {
    let filterData = this.state.hubFactoryTableData;
    //#region remove duplicate objects
    let uniqueFilterData = [];
    if (label === "is_active" && this.state.hubFactoryTableData && this.state.hubFactoryTableData.length > 0) {
      uniqueFilterData = [{ text: "Yes", value: 1 }, { text: "No", value: 0 }]
    }
    else{
    filterData.map((mainItem) =>
      uniqueFilterData.filter((item) => item[label] === mainItem[label])
        .length > 0
        ? null
        : uniqueFilterData.push(mainItem)
    );

    uniqueFilterData = uniqueFilterData.map((item) => {
      return {
        text: item[label],
        value: item[label],
      };
    });
    uniqueFilterData.sort((a, b) => a.text.localeCompare(b.text))
  }

    return uniqueFilterData;
  };

  setModalVisibility = (status) => {
    if (status) {
      const nData = {
        is_active: true,
        Factory_Buying: undefined,
        Factory_Name: "",
        distance:undefined,
        H_F_Freight_PerMT: undefined,
        Hub_Factory_Freight_ID: undefined,
        Hub_Name: "",
        Route: "",
        branch_id: undefined,
        branch_name: "",
        branch_state: "",
        factory_id: undefined,
        hub_id: undefined,
        isEdit: false,
      };

      this.setState({ newData: nData });
    }

    this.setState({ isModalVisible: status });
  };

  handleChange = (e, row, index, name, isPopup) => {
    e.target.name = name;

    var alphaNumericRegex = /^[a-zA-Z0-9]*$/;
    var numberRegex = /^[0-9.]+$/;
    let a;

    if (isPopup === false) {
      a = this.state.hubFactoryTableData;

      if (e.target.name === "H_F_Freight_PerMT") {
        if (e.target.value === "") {
          a[row.key].H_F_Freight_PerMT = "";
        } else if (e.target.value.match(numberRegex)) {
          a[row.key].H_F_Freight_PerMT = e.target.value;
        }
      }

      if (e.target.name === "distance") {
        if (e.target.value === "") {
          a[row.key].distance = "";
        } else if (e.target.value.match(numberRegex)) {
          a[row.key].distance = e.target.value;
        }
      }

      if (e.target.name === "Hub_Name") {
        a[row.key].Hub_Name = e.target.value;
      }

      this.setState({ hubFactoryTableData: a });
    } else {
      a = this.state.newData;

      if (e.target.name === "H_F_Freight_PerMT") {
        if (e.target.value === "") {
          a.H_F_Freight_PerMT = "";
        } else if (e.target.value.match(numberRegex)) {
          a.H_F_Freight_PerMT = e.target.value;
        }
      } 
      
      if (e.target.name === "distance") {
        if (e.target.value === "") {
          alphaNumericRegex.distance = "";
        } else if (e.target.value.match(numberRegex)) {
          a.distance = e.target.value;
        }
      }

      if (e.target.name === "Hub_Name") {
        a.Hub_Name = e.target.value;
      }

      this.setState({ newData: a });
    }
  };

  handleDropdownChange = (value, row, target, index, optionlabel, isPopup) => {


    let branchLabel, branchid;
    if (target === "hub_region") {
    }

    let a;
    if (isPopup === false) {
      a = this.state.hubFactoryTableData;
      if (target === "is_active") {
        a[row.key].is_active = Boolean(value);
      }

      else if (target === "Factory_Name") {
        a[row.key].Factory_Name = optionlabel;
        a[row.key].factory_id = value;
      } else if (target === "Hub_Name") {
        a[row.key].hub_id = value;
        a[row.key].Hub_Name = optionlabel;
      } else if (target === "Route") {
        a[row.key].Route = optionlabel;
        // a[row.key].route_id = value;
      }

      this.setState({ hubFactoryTableData: a });

    } else {
      a = this.state.newData;
      if (target === "is_active") {
        a.is_active = Boolean(value);
      }else if (target === "Factory_Name") {
        a.Factory_Name = optionlabel;
        a.factory_id = value;
      } else if (target === "Hub_Name") {
        a.hub_id = value;
        a.Hub_Name = optionlabel;
      } else if (target === "Route") {
        a.Route = optionlabel;
        // a.route_id = value;
      }
      this.setState({ newData: a });
    }
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  handleEditChange = (row, index) => {
    //     if(this.canUserEdit(row,row.branch_id))
    // {
      console.log("edit change ", row,this.state.hubFactoryTableData);
        this.setState({ rowData: row });
        let tempData = this.state.hubFactoryTableData.map((item) => {
          if (item.Hub_Factory_Freight_ID === row.Hub_Factory_Freight_ID) {
            return {
              ...item,
              isEdit: true,
            };
          } else {
            return item;
          }
        });
      
        this.setState({ hubFactoryTableData: tempData });
      
        // setTableData();
      // }
      // else{
      //   message.info("User can edit only their respective branches",1);
      // }
      };

  handleCancelClick = (rowData, index) => {
    let tempData = this.state.hubFactoryTableData.map((item) => {
      if (item.Hub_Factory_Freight_ID === rowData.Hub_Factory_Freight_ID) {
        return this.state.rowData;
      } else {
        return item;
      }
    });
    this.setState({ hubFactoryTableData: tempData });

    // let a = this.state.hubFactoryTableData;
    // a[index]= this.state.rowData;
    // a[index].isEdit = false;
    // this.setState({ hubFactoryTableData: a });
  };

  render() {
    const userTableProps = {
      pagination: false,
      size: "default",
      bordered: true,
      scroll: { x: 200, y: window.innerHeight - 250 },
    };

    const handleRegionSelect = (value, props) => {
      const filterHub = this.state.primaryHubList.filter((hubItem) => hubItem.parent_id === value);
      this.setState(
        {
          selectedBranchName: props.key,
          selectedBranchValue: value,
          filteredHubList: filterHub,
          selectedHubName: filterHub[0] ? filterHub[0].territory_name : null,
          selectedHubValue: filterHub[0] ? filterHub[0].territory_id : null
        },
        () => this.getMasterHub()
      );
    };

    const handleHubSelect = (value, props) => {
      this.setState(
        { selectedHubName: props.key, selectedHubValue: value },
        () => this.getMasterHub()
      );
    };


    return (
      <div
        className="hub-master-container"
        style={{
          height: window.innerHeight - 85,
          marginTop: "-45px",
        }}
      >
        <div className="top-container">
          <div className="hub-region-filter">
            <span
              style={{
                fontSize: "15px",
                marginTop: "5px",
                fontWeight: "600",
                marginLeft: "50px",
              }}
            >
              Branch&nbsp;
            </span>{" "}
            <Select

              showSearch
              optionFilterProp="children"
              className={"select-master-region"}
              mode="single"
              placeholder="Select Branch"
              value={this.state.selectedBranchValue}
              onChange={(value, props) =>
                handleRegionSelect(value, props)
              }
            >
              {this.state.selectedBranchList !== null && this.state.selectedBranchList.length &&
                this.state.selectedBranchList.map((item, idx) => (
                  <Option
                    value={item.branch_id}
                    key={item.branch_id}
                  >
                    {item.branch_name}
                  </Option>
                ))}
            </Select>
            <span
              style={{
                fontSize: "15px",
                marginTop: "5px",
                fontWeight: "600",
                marginLeft: "30px",
              }}
            >
              Hub&nbsp;
            </span>{" "}
            <Select

              showSearch
              optionFilterProp="children"
              className={"select-master-region"}
              mode="single"
              placeholder="Select Branch"
              value={this.state.selectedHubValue}
              onChange={(value, props) =>
                handleHubSelect(value, props)
              }
            >
              {this.state.filteredHubList !== null && this.state.filteredHubList.length &&
                this.state.filteredHubList.map((item, idx) => (
                  <Option
                    value={item.territory_id}
                    key={item.territory_id}
                  >
                    {item.territory_name}
                  </Option>
                ))}
            </Select>


          </div>




          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row-reverse",
              placeItems: "flex-end",
              marginRight: "25px",
              marginBottom: "10px",
            }}
          >
            <Search
              disabled={!this.state.isAdd}
              placeholder="Search"
              allowClear
              onChange={(e) => this.setState({ searchText: e.target.value })}
              style={{ width: "45%" }}
            />
            <Button
              className="add-master-button"
              style={{
                height: "25px",
                width: "70px",
                backgroundColor: "#0b133d",
                color: "white",
              }}
              disabled={!canUserEdit(
                this.props.userData,
                null,
                FeatureCodes.MS_HUB_FACTORY_FRIEGHT_EDIT
              ) || !this.state.isAdd}
              onClick={() => this.setModalVisibility(true)}
            >
              &#10010; Add
            </Button>
          </div>
        </div>

        <div>
        <div
        style={{
          marginLeft: "25px",
          marginTop: "-8px",
          fontSize: "14px",
          color: "red",
          display: "flex",
          float: "left",
        }}
      > {getUserMSRBACMessage(this.props.messageAccess,FeatureCodes.MS_HUB_FACTORY_FRIEGHT_EDIT)}
      </div>
        <div
          style={{
            marginRight: "20px",
            marginTop: "-8px",
            fontSize: "15px",
            color: "#0B133D",
            display: "flex",
            float: "right",
          }}
        >Total number of records:&nbsp;
          <b>{this.state.hubFactoryTableData && this.setTableData().length}</b>
        </div>
        </div>

        <Spin
          spinning={this.state.isSpinnerOnLoad}
          size={"large"}
          tip="Loading..."
          style={{ alignSelf: "center", zIndex: 9999, marginTop: "20px" }}
        >
          <Modal
            className="add-master-modal"
            title={"Add Hub"}
            onCancel={this.handleCancel}
            footer={false}
            visible={this.state.isModalVisible}
            destroyOnClose={true}
          >
            <div className="master-form-container">
              <Form
                layout="horizontal"
                //   onValuesChange={onFormLayoutChange}
                size={"small"}
              // onSubmit={submitTableRowData(this.state.newData)}
              >
                <div className="form-top-container">
                  <div>
                    <div
                      className="checkbox-group1"
                      style={{
                        display: "inline-block",
                        width: "30%",
                        marginRight: 7,
                      }}
                    >
                      <Form.Item label="Hub">
                        <Select
                          showSearch
                          optionFilterProp="children"
                          className="remarks-select"
                          name="Hub_Name"
                          defaultValue={this.state.newData.Hub_Name}
                          value={this.state.newData.Hub_Name}
                          onSelect={(value, option) =>
                            this.handleDropdownChange(
                              value,
                              this.state.newData,
                              "Hub_Name",
                              -0,
                              option.props.children,
                              true
                            )
                          }
                        >
                          {this.state.primaryHubList &&
                            this.state.primaryHubList.length &&
                            this.state.primaryHubList.map((hubItem, idx) => (
                              <Option
                                key={hubItem.territory_id}
                                value={hubItem.territory_id}
                              >
                                {hubItem.territory_name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </div>

                    <div
                      className="form-top-container"
                      style={{
                        display: "inline-block",
                        width: "33%",
                        marginRight: 7,
                      }}
                    >
                      <Form.Item label="Factory">
                        <Select
                          showSearch
                          optionFilterProp="children"
                          className="remarks-select"
                          name="Factory_Name"
                          defaultValue={this.state.newData.Factory_Name}
                          value={this.state.newData.Factory_Name}
                          onSelect={(value, option) =>
                            this.handleDropdownChange(
                              value,
                              this.state.newData,
                              "Factory_Name",
                              -0,
                              option.props.children,
                              true
                            )
                          }
                        >
                          {this.state.factoryList &&
                            this.state.factoryList.length &&
                            this.state.factoryList.map((hubItem, idx) => (
                              <Option
                                key={hubItem.factory_id}
                                value={hubItem.factory_id}
                              >
                                {hubItem.factory_name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </div>

                    <div
                      className="form-top-container"
                      style={{
                        display: "inline-block",
                        width: "30%",
                        marginRight: 7,
                      }}
                    >
                      <Form.Item label="Route">
                        <Select
                          showSearch
                          optionFilterProp="children"
                          className="remarks-select"
                          name="Route"
                          defaultValue={this.state.newData.Route}
                          value={this.state.newData.Route}
                          onSelect={(value, option) =>
                            this.handleDropdownChange(
                              value,
                              this.state.newData,
                              "Route",
                              -0,
                              option.props.children,
                              true
                            )
                          }
                        >
                          {this.state.routeList &&
                            this.state.routeList.length &&
                            this.state.routeList.map((hubItem, idx) => (
                              <Option
                                key={hubItem.param_data_value}
                                value={hubItem.param_data_value}
                              >
                                {hubItem.param_display_value}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </div>

                  </div>
                  <hr />
                  <div
                    className="checkbox-group1"
                    style={{
                      display: "inline-block",
                      width: "42%",
                      marginRight: 7,
                    }}
                  >
                    <Form.Item label="Hub Factory Freight(MT)">
                      <Input
                        style={{ marginRight: "2px" }}
                        maxLength={100}
                        value={this.state.newData.H_F_Freight_PerMT}
                        name="H_F_Freight_PerMT"
                        onChange={(e) =>
                          this.handleChange(
                            e,
                            this.state.newData,
                            -0,
                            "H_F_Freight_PerMT",
                            true
                          )
                        }
                      />
                    </Form.Item>
                  </div>

                  <div
                  className="checkbox-group1"
                  style={{
                    display: "inline-block",
                    width: "42%",
                    marginRight: 7,
                  }}
                >
                  <Form.Item label="Distance(kms)">
                    <Input
                      style={{ marginRight: "2px" }}
                      maxLength={100}
                      value={this.state.newData.distance}
                      name="distance"
                      onChange={(e) =>
                        this.handleChange(
                          e,
                          this.state.newData,
                          -0,
                          "distance",
                          true
                        )
                      }
                    />
                  </Form.Item>
                </div>
                <div
                      className="form-top-container"
                      style={{
                        display: "inline-block",
                        width: "40%",
                        marginRight: 7,
                      }}
                    >
                      <Form.Item label="Active">
                        <Radio.Group
                          name="is_active"
                          value={this.state.newData.is_active ? 1 : 0}
                          onChange={(e, option) =>
                            this.handleDropdownChange(
                              e.target.value,
                              this.state.newData,
                              "is_active",
                              -0,
                              "",
                              true
                            )
                          }
                        >
                          <Radio value={1}>Yes</Radio>
                          <Radio value={0}>No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </div>
                </div>

                <div className="form-bottom-container">
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={this.state.isSubmitted}
                      onClick={() =>
                        this.submitTableRowData(this.state.newData)
                      }
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </Modal>
          <div
            className="table-container"
            style={{ maxHeight: "450px", margin: "6px 0px" }}
          >
            <Table
              {...userTableProps}
              columns={[
                {
                  title: "Hub",
                  dataIndex: "Hub_Name",
                  key: "Hub_Name",
                  width: 125,
                  // filters: this.createFilters("Hub_Name"),
                  // onFilter: (value, record) =>
                  //   record.Hub_Name.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.Hub_Name.localeCompare(b.Hub_Name),
                  render: (record, rowRecord) => {
                    return (
                      <>
                        <div
                          style={{ marginLeft: "-15px", marginRight: "-10px" }}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            disabled={!rowRecord.isEdit}
                            className="remarks-select"
                            name="Hub_Name"
                            defaultValue={rowRecord.Hub_Name}
                            value={rowRecord.Hub_Name}
                            onSelect={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "Hub_Name",
                                -0,
                                option.props.children,
                                false
                              )
                            }
                          >
                            {this.state.primaryHubList &&
                              this.state.primaryHubList.length &&
                              this.state.primaryHubList.map((hubItem, idx) => (
                                <Option
                                  key={hubItem.territory_id}
                                  value={hubItem.territory_id}
                                >
                                  {hubItem.territory_name}
                                </Option>
                              ))}
                          </Select>
                        </div>
                      </>
                    );
                  },
                },
                {
                  title: "Factory",
                  dataIndex: "Factory_Name",
                  key: "Factory_Name",
                  width: 125,
                  filters: this.createFilters("Factory_Name"),
                  onFilter: (value, record) =>
                    record.Factory_Name.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.Factory_Name.localeCompare(b.Factory_Name),
                  render: (record, rowRecord) => {
                    return (
                      <>
                        <div
                          style={{ marginLeft: "-15px", marginRight: "-10px" }}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            disabled={!rowRecord.isEdit}
                            className="remarks-select"
                            name="Factory_Name"
                            defaultValue={rowRecord.Factory_Name}
                            value={rowRecord.Factory_Name}
                            onSelect={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "Factory_Name",
                                -0,
                                option.props.children,
                                false
                              )
                            }
                          >
                            {this.state.factoryList &&
                              this.state.factoryList.length &&
                              this.state.factoryList.map((hubItem, idx) => (
                                <Option
                                  key={hubItem.factory_id}
                                  value={hubItem.factory_id}
                                >
                                  {hubItem.factory_name}
                                </Option>
                              ))}
                          </Select>
                        </div>
                      </>
                    );
                  },
                },
                {
                  title: "Route",
                  dataIndex: "Route",
                  key: "Route",
                  width: 125,
                  filters: this.createFilters("Route"),
                  onFilter: (value, record) =>
                    record.Route.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.Route.localeCompare(b.Route),
                  render: (record, rowRecord) => {
                    
                    return (
                      <>
                        <div
                          style={{ marginLeft: "-15px", marginRight: "-10px" }}
                        >
                          <Select
                            showSearch
                            disabled={!rowRecord.isEdit}
                            optionFilterProp="children"
                            className="remarks-select"
                            name="Route"
                            defaultValue={rowRecord.Route}
                            value={rowRecord.Route}
                            onSelect={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "Route",
                                -0,
                                option.props.children,
                                false
                              )
                            }
                          >
                            {this.state.routeList &&
                              this.state.routeList.length &&
                              this.state.routeList.map((hubItem, idx) => (
                                <Option
                                  key={hubItem.param_data_value}
                                  value={hubItem.param_data_value}
                                >
                                  {hubItem.param_display_value}
                                </Option>
                              ))}
                          </Select>
                        </div>
                      </>
                    );
                  },
                },
                {
                  key: "H_F_Freight_PerMT",
                  title: "Hub Factory Freight(MT)",
                  dataIndex: "H_F_Freight_PerMT",
                  width: 100,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.H_F_Freight_PerMT - b.H_F_Freight_PerMT,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            disabled={!rowRecord.isEdit}
                            style={{ marginRight: "2px" }}
                            maxLength={100}
                            value={rowRecord.H_F_Freight_PerMT}
                            name="H_F_Freight_PerMT"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "H_F_Freight_PerMT",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },
                {
                  key: "distance",
                  title: "Distance(kms)",
                  dataIndex: "distance",
                  width: 100,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.distance - b.distance,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            disabled={!rowRecord.isEdit}
                            style={{ marginRight: "2px" }}
                            maxLength={100}
                            value={rowRecord.distance}
                            name="distance"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "distance",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },

                {
                  key: "is_active",
                  title: "Active",
                  dataIndex: "is_active",
                  width: 50,
                  filters: this.createFilters("is_active"),
                  // filters: [{ text: "Yes", value: 1 }, { text: "No", value: 0 }],
                  onFilter: (value, record) =>
                    record.is_active === Boolean(value),
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-15px", marginRight: "-40px" }}>
                          <Select
                            showSearch
                            optionFilterProp="children"
                            disabled={!rowRecord.isEdit}
                            defaultValue={
                              rowRecord.is_active === true ? "Yes" : "No"
                            }
                            value={
                              rowRecord.is_active === true ? "Yes" : "No"
                            }
                            onChange={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "is_active",
                                index,
                                option.props.children,
                                false
                              )
                            }
                            style={{ width: "70%" }}
                          >
                            <Option key={1} value={1}>
                              {"Yes"}
                            </Option>
                            <Option key={0} value={0}>
                              {"No"}
                            </Option>
                          </Select>
                        </div>
                      </>
                    );
                  },
                },
                {
                  title: "Action",
                  dataIndex: "Submit",
                  key: "Submit",
                  width: 140,

                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ display: "inline-flex", width: "100%" }}>
                          <Button
                          className={!canUserEdit(
                            this.props.userData,
                            null,
                            FeatureCodes.MS_HUB_FACTORY_FRIEGHT_EDIT
                          ) ? "disabledBtnClass" : "btnClass"}
                          disabled={!canUserEdit(
                            this.props.userData,
                            null,
                            FeatureCodes.MS_HUB_FACTORY_FRIEGHT_EDIT
                          )}
                            type="primary"
                            size={"small"}
                            style={{
                              marginLeft: 5,
                              alignSelf: "center",
                              color: "white",
                              borderRadius: 15,
                              padding: "0px 0px 2px",
                              height: 31,
                              backgroundColor: "#20346a",
                            }}
                            block
                            htmlType="submit"
                            onClick={() =>

                              rowRecord.isEdit
                                ? this.submitTableRowData(
                                  rowRecord,
                                  index,
                                  false
                                )
                                : this.handleEditChange(rowRecord, index)
                            }
                          >
                            {" "}
                            {rowRecord.isEdit ? "Submit" : "Edit"}{" "}
                          </Button>

                          <Button
                            className={
                              !rowRecord.isEdit
                                ? "disabledBtnClass"
                                : "btnClass"
                            }
                            type="primary"
                            size={"small"}
                            style={{
                              marginLeft: 5,
                              alignSelf: "center",
                              color: "white",
                              borderRadius: 15,
                              padding: "0px 0px 2px",
                              height: 31,
                              backgroundColor: "#20346a",
                            }}
                            block
                            htmlType="submit"
                            disabled={!rowRecord.isEdit}
                            onClick={() =>
                              this.handleCancelClick(rowRecord, index)
                            }
                          >
                            Cancel
                          </Button>
                        </div>
                      </>
                    );
                  },
                },
              ]}
              dataSource={this.setTableData()}
            />
          </div>


        </Spin>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
  messageAccess: selectMSMessageRBAC,
});
export default connect(mapStateToProps)(HubFactoryFrieghtMaster);
//export default HubFactoryFrieghtMaster;
