import React from "react";
import "./map-context-menu.styles.css";

import API from "../../api";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import {
  selectMandis,
  selectHubs,
  selectBranches,
  selectArrivalData,
  selectBranchID,
  selectHubID,
} from "../../redux/arrival-entry/arrival-entry.selectors";
import { selectUserData } from "../../redux/user/user.selectors";

import {
  setMandis,
  setHubs,
  setBranches,
  setArrivalData,
} from "../../redux/arrival-entry/arrival-entry.actions";
import { setCurrentArrivalScreen } from "../../redux/map/map.actions";

import { Menu, Dropdown, message } from "antd";
import { Component } from "react";

import { canUserEdit } from "../../auth/utils";
import { FeatureCodes } from "../../auth/data";

const { Item } = Menu;

/**
 * @component
 * @description The right click menu popup component on the map marker.
 * @property {object} mandi the selected mandi record.
 * @property {string} mandiStatus the selected mandi status.
 * @property {object[]} mandis the current list of mandis in the selected hub.
 */
class MapContextMenu extends Component {

 

  render() {
    const {
      children,
      mandi,
      mandiStatus,
      currentArrivalScreen,
      mandis,
      setMandis,
      setCurrentArrivalScreen,
      getTerritories,
    } = this.props;

    const user = this.props.userData;

    /**
     * @description Change the mandi status.
     * @param {nuber} mandiID selected mandi id
     * @param {string} status mandi status to be changed.
     * @memberof MapContextMenu
     */
    const changeMandiStatus = (ID, typeID, status) => {
      //#region  api call for close mandi


      if (status === "closed") {
        const putData = {
          territory_id: ID,           // mandi or hud id
          territory_type_id: typeID,  // mandi 7 / hub 3
          status: status,
        };

        // API.post(`/changestatus/mandi/${mandiID}`, putData, {
        API.post(`/change_territory_status`, putData,
        )
          .then((statusUpdateResponse) => {

            if (statusUpdateResponse.data.status) {
              //#region update the redux store

              //#region modify current mandi and save to redux
              if (currentArrivalScreen === "Mandi") {

                let modifiedMandis = this.props.mandis.map((item) => {
                  if (item.mandi_id === mandi.mandi_id) {
                    return { ...item, mandi_status: status };
                  } else return item;
                });

                setMandis(modifiedMandis);
                //#endregion

                //#region modify mandis in the hub item. here we can't use the modifiedmandis from above
                // since that may only contain priority mandis if checked.
                let selectedHub = this.props.hubs.find(
                  (item) => item.hub_id === this.props.hubID
                );

                let modifiedAllMandiList = selectedHub.mandi_list.map((item) => {
                  if (item.mandi_id === mandi.mandi_id) {
                    return { ...item, mandi_status: status };
                  } else return item;
                });
                //this is the modified hub item.
                selectedHub.mandi_list = modifiedAllMandiList;

                let selectedHubIndex = this.props.hubs.findIndex(
                  (item) => item.hub_id === this.props.hubID
                );

                let modifiedHubs = this.props.hubs;
                modifiedHubs.splice(selectedHubIndex, 1, selectedHub);

                this.props.setHubs(modifiedHubs);

                //#endregion

                //#region add modified hubs to the selected branch.

                let selectedBranch = this.props.branches.find(
                  (item) => item.branch_id === this.props.branchID
                );
                selectedBranch.hub_list = modifiedHubs;

                let selectedBranchIndex = this.props.branches.findIndex(
                  (item) => item.branch_id === this.props.branchID
                );
                let modifiedBranches = this.props.branches;
                modifiedBranches.splice(selectedBranchIndex, 1, selectedBranch);
                this.props.setBranches(modifiedBranches);

                //#endregion

                //#region add modified branches to arrivalData.

                let modifiedArrivalData = this.props.arrivalData;
                modifiedArrivalData.branch_list = modifiedBranches;
                this.props.setArrivalData(modifiedArrivalData);

                //#endregion

                //#endregion
              }
              else {
                // let modifiedHubsData = this.props.hubs.map((item) => {
                //   if (item.hub_id === mandi.hub_id) {
                //     return { ...item, hub_status: status };
                //   } else return item;
                // });

                // setHubs(modifiedHubsData);

                //#endregion

                //#region modify mandis in the hub item. here we can't use the modifiedmandis from above
                // since that may only contain priority mandis if checked.
                let modifiedAllHubList = this.props.hubs.map((item) => {
                  // (item) => item.hub_id == this.props.hubID
                  if (item.hub_id === mandi.hub_id) {
                    return { ...item, hub_status: status };
                  } else return item;
                });

                // let modifiedAllMandiList = selectedHub.mandi_list.map((item) => {
                //   if (item.mandi_id === mandi.mandi_id) {
                //     return { ...item, mandi_status: status };
                //   } else return item;
                // });
                //this is the modified hub item.
                // selectedHub.mandi_list = modifiedAllMandiList;

                let selectedHubIndex = this.props.hubs.findIndex(
                  (item) => item.hub_id === mandi.hub_id
                );
                // console.log("Close ======> Modified dAta", modifiedAllHubList, selectedHubIndex, status);

                // let modifiedHubs = this.props.hubs;
                // modifiedHubs.splice(selectedHubIndex, 1, modifiedAllHubList);

                this.props.setHubs(modifiedAllHubList);

                //#endregion

                //#region add modified hubs to the selected branch.

                let selectedBranch = this.props.branches.find(
                  (item) => item.branch_id === this.props.branchID
                );
                selectedBranch.hub_list = modifiedAllHubList;

                let selectedBranchIndex = this.props.branches.findIndex(
                  (item) => item.branch_id === this.props.branchID
                );
                let modifiedBranches = this.props.branches;
                modifiedBranches.splice(selectedBranchIndex, 1, selectedBranch);
                this.props.setBranches(modifiedBranches);

                //#endregion

                //#region add modified branches to arrivalData.

                let modifiedArrivalData = this.props.arrivalData;
                modifiedArrivalData.branch_list = modifiedBranches;
                this.props.setArrivalData(modifiedArrivalData);

                //#endregion

                //#endregion
              }
              //
            } else {
              message.warning(
                "This mandi cannot be closed as arrival data has already been entered. Please refresh the application"
              );
            }
          })
          .catch((err) => {
          });
      } else if (status === "no arrivals") {
        const putData = {
          territory_id: ID,
          territory_type_id: typeID,
          status: status,
        };
        // API.post(`/changestatus/mandi/${mandiID}`, putData, {
        API.post(`/change_territory_status`, putData)
          .then((statusUpdateResponse) => {

            if (statusUpdateResponse.data.status) {
              //update the redux store
              if (currentArrivalScreen === "Mandi") {

                let selectedMandi = mandis.filter(
                  (item) => item.mandi_id === mandi.mandi_id
                );

                selectedMandi[0].mandi_status = status;

                let selectedMandiIndex = mandis.findIndex(
                  (item) => item.mandi_id === mandi.mandi_id
                );

                let modifiedMandis = mandis;
                modifiedMandis.splice(selectedMandiIndex, 1, selectedMandi[0]);
                setMandis(modifiedMandis);

                //add mandis to redux hub and arrival data.

                //#region add modified mandis to the hub item.
                let selectedHub = this.props.hubs.find(
                  (item) => item.hub_id === this.props.hubID
                );
                let modifiedAllMandiList = selectedHub.mandi_list.map((item) => {
                  if (item.mandi_id === mandi.mandi_id) {
                    return selectedMandi[0];
                  } else return item;
                });

                //this is the modified hub item.
                selectedHub.mandi_list = modifiedAllMandiList;

                let selectedHubIndex = this.props.hubs.findIndex(
                  (item) => item.hub_id === this.props.hubID
                );

                let modifiedHubs = this.props.hubs;
                modifiedHubs.splice(selectedHubIndex, 1, selectedHub);

                this.props.setHubs(modifiedHubs);

                //#endregion

                //#region add modified hubs to the selected branch.

                let selectedBranch = this.props.branches.find(
                  (item) => item.branch_id === this.props.branchID
                );
                selectedBranch.hub_list = modifiedHubs;

                let selectedBranchIndex = this.props.branches.findIndex(
                  (item) => item.branch_id === this.props.branchID
                );
                let modifiedBranches = this.props.branches;
                modifiedBranches.splice(selectedBranchIndex, 1, selectedBranch);
                this.props.setBranches(modifiedBranches);

                //#endregion

                //#region add modified branches to arrivalData.

                let modifiedArrivalData = this.props.arrivalData;
                modifiedArrivalData.branch_list = modifiedBranches;
                this.props.setArrivalData(modifiedArrivalData);

                //#endregion

                // FIXME: force changes. find an optimized way
                setCurrentArrivalScreen("FOR");
                setCurrentArrivalScreen("Mandi");
                //
              }
              else {

                // let modifiedHubsData = this.props.hubs.map((item) => {
                //   if (item.hub_id === mandi.hub_id) {
                //     return { ...item, hub_status: status };
                //   } else return item;
                // });

                // setHubs(modifiedHubsData);

                //#endregion

                //#region modify mandis in the hub item. here we can't use the modifiedmandis from above
                // since that may only contain priority mandis if checked.
                let modifiedAllHubList = this.props.hubs.map((item) => {
                  // (item) => item.hub_id == this.props.hubID
                  // console.log("No Arrivals -----> ", item.hub_id == mandi.hub_id, "item.hub_id", item.hub_id, "mandi.hub_id", mandi.hub_id, status);
                  if (item.hub_id === mandi.hub_id) {
                    return { ...item, hub_status: status };
                  } else return item;
                });

                // let modifiedAllMandiList = selectedHub.mandi_list.map((item) => {
                //   if (item.mandi_id === mandi.mandi_id) {
                //     return { ...item, mandi_status: status };
                //   } else return item;
                // });
                //this is the modified hub item.
                // selectedHub.mandi_list = modifiedAllMandiList;

                let selectedHubIndex = this.props.hubs.findIndex(
                  (item) => item.hub_id === mandi.hub_id
                );

                // console.log("No Arrivals -----> Modified hub Data --> ", modifiedAllHubList, selectedHubIndex, status);
                // let modifiedHubs = this.props.hubs;
                // modifiedHubs.splice(selectedHubIndex, 1, modifiedAllHubList);

                this.props.setHubs(modifiedAllHubList);

                //#endregion

                //#region add modified hubs to the selected branch.

                let selectedBranch = this.props.branches.find(
                  (item) => item.branch_id === this.props.branchID
                );
                selectedBranch.hub_list = modifiedAllHubList;

                let selectedBranchIndex = this.props.branches.findIndex(
                  (item) => item.branch_id === this.props.branchID
                );
                let modifiedBranches = this.props.branches;
                modifiedBranches.splice(selectedBranchIndex, 1, selectedBranch);
                this.props.setBranches(modifiedBranches);

                //#endregion

                //#region add modified branches to arrivalData.

                let modifiedArrivalData = this.props.arrivalData;
                modifiedArrivalData.branch_list = modifiedBranches;
                this.props.setArrivalData(modifiedArrivalData);

                //#endregion

                //#endregion
              }
            } else {
              message.warning(
                "This mandi/hub cannot be marked as No Arrivals as arrival data has already been entered. Please refresh the application"
              );
            }
          })
          .catch((err) => {
          });
      } else if (status === "open") {
        const putData = {
          territory_id: ID,
          territory_type_id: typeID,
          status: status,
        };
        // API.delete(`/changestatus/mandi/${mandiID}`, {
        API.delete("/change_territory_status", {

          data: putData
        })
          .then((statusUpdateResponse) => {
            if (statusUpdateResponse.data.status) {
              //update the redux store
              if (currentArrivalScreen === "Mandi") {
                let selectedMandi = mandis.filter(
                  (item) => item.mandi_id === mandi.mandi_id
                );

                selectedMandi[0].mandi_status = status;

                let selectedMandiIndex = mandis.findIndex(
                  (item) => item.mandi_id === mandi.mandi_id
                );

                let modifiedMandis = mandis;
                modifiedMandis.splice(selectedMandiIndex, 1, selectedMandi[0]);
                setMandis(modifiedMandis);

                //add mandis to redux hub and arrival data.

                //#region add modified mandis to the hub item.
                let selectedHub = this.props.hubs.find(
                  (item) => item.hub_id === this.props.hubID
                );
                let modifiedAllMandiList = selectedHub.mandi_list.map((item) => {
                  if (item.mandi_id === mandi.mandi_id) {
                    return selectedMandi[0];
                  } else return item;
                });

                //this is the modified hub item.
                selectedHub.mandi_list = modifiedAllMandiList;

                let selectedHubIndex = this.props.hubs.findIndex(
                  (item) => item.hub_id === this.props.hubID
                );

                let modifiedHubs = this.props.hubs;
                modifiedHubs.splice(selectedHubIndex, 1, selectedHub);

                this.props.setHubs(modifiedHubs);

                //#endregion

                //#region add modified hubs to the selected branch.

                let selectedBranch = this.props.branches.find(
                  (item) => item.branch_id === this.props.branchID
                );
                selectedBranch.hub_list = modifiedHubs;

                let selectedBranchIndex = this.props.branches.findIndex(
                  (item) => item.branch_id === this.props.branchID
                );
                let modifiedBranches = this.props.branches;
                modifiedBranches.splice(selectedBranchIndex, 1, selectedBranch);
                this.props.setBranches(modifiedBranches);

                //#endregion

                //#region add modified branches to arrivalData.

                let modifiedArrivalData = this.props.arrivalData;
                modifiedArrivalData.branch_list = modifiedBranches;
                this.props.setArrivalData(modifiedArrivalData);

                //#endregion

                // FIXME: force changes. find an optimized way
                setCurrentArrivalScreen("FOR");
                setCurrentArrivalScreen("Mandi");
                //
              }
              else {

                // let modifiedHubsData = this.props.hubs.map((item) => {
                //   if (item.hub_id === mandi.hub_id) {
                //     return { ...item, hub_status: status };
                //   } else return item;
                // });
                // console.log("Modified hub Data --> ", modifiedHubsData);
                // setHubs(modifiedHubsData);

                //#endregion

                //#region modify mandis in the hub item. here we can't use the modifiedmandis from above
                // since that may only contain priority mandis if checked.
                let modifiedAllHubList = this.props.hubs.map((item) => {
                  // (item) => item.hub_id == this.props.hubID
                  // console.log("No Arrivals -----> ", item.hub_id == mandi.hub_id, "item.hub_id", item.hub_id, "mandi.hub_id", mandi.hub_id, status);
                  if (item.hub_id === mandi.hub_id) {
                    return { ...item, hub_status: status };
                  } else return item;
                });

                // let modifiedAllMandiList = selectedHub.mandi_list.map((item) => {
                //   if (item.mandi_id === mandi.mandi_id) {
                //     return { ...item, mandi_status: status };
                //   } else return item;
                // });
                //this is the modified hub item.
                // selectedHub.mandi_list = modifiedAllMandiList;

                let selectedHubIndex = this.props.hubs.findIndex(
                  (item) => item.hub_id === mandi.hub_id
                );
                // console.log("Open -----> Modified hub Data --> ", modifiedAllHubList, selectedHubIndex, status);

                // let modifiedHubs = this.props.hubs;
                // modifiedHubs.splice(selectedHubIndex, 1, modifiedAllHubList);

                this.props.setHubs(modifiedAllHubList);

                //#endregion

                //#region add modified hubs to the selected branch.

                let selectedBranch = this.props.branches.find(
                  (item) => item.branch_id === this.props.branchID
                );
                selectedBranch.hub_list = modifiedAllHubList;

                let selectedBranchIndex = this.props.branches.findIndex(
                  (item) => item.branch_id === this.props.branchID
                );
                let modifiedBranches = this.props.branches;
                modifiedBranches.splice(selectedBranchIndex, 1, selectedBranch);
                this.props.setBranches(modifiedBranches);

                //#endregion

                //#region add modified branches to arrivalData.

                let modifiedArrivalData = this.props.arrivalData;
                modifiedArrivalData.branch_list = modifiedBranches;
                this.props.setArrivalData(modifiedArrivalData);

                //#endregion

                //#endregion
              }
            }
          })
          .catch((err) => {
          });
      }
//getTerritories();
      //#endregion
    };

    return (
      <div className="map-context-menu-container">
        {mandiStatus === "open" ? (
          <Dropdown
            disabled={
              canUserEdit(
                user,
                this.props.branchID,
                FeatureCodes.ARRIVALS_CLOSE_MANDI
              )
                ? false
                : true
            }
            overlay={
              <Menu>
                {currentArrivalScreen === "Mandi" && <Item
                  onClick={() => {
                    // console.log("MANDI : ", mandi);
                    changeMandiStatus(currentArrivalScreen === "Mandi" ? mandi.mandi_id : mandi.hub_id, currentArrivalScreen === "Mandi" ? 7 : 3, "closed");
                  }}
                  key="1"
                >
                  Close Mandi
                </Item>}
                {/* {mandiStatus !== "no arrivals" &&  */}
                <Item
                  onClick={() => {
                    // console.log("MANDI : ", mandi);
                    changeMandiStatus(currentArrivalScreen === "Mandi" ? mandi.mandi_id : mandi.hub_id, currentArrivalScreen === "Mandi" ? 7 : 3, "no arrivals");
                  }}
                  key="2"
                >
                  No Arrivals
                </Item>
                {/* } */}
              </Menu>
            }
            trigger={["contextMenu"]}
            placement="topLeft"
          >
            {children}
          </Dropdown>
        ) : null}

        {mandiStatus === "closed" || mandiStatus === "no arrivals" ? (
          <Dropdown
            disabled={
              canUserEdit(
                user,
                this.props.branchID,
                FeatureCodes.ARRIVALS_CLOSE_MANDI
              )
                ? false
                : true
            }
            overlay={
              <Menu>
                <Item
                  onClick={() => {
                    // console.log("MANDI : ", mandi);
                    changeMandiStatus(currentArrivalScreen === "Mandi" ? mandi.mandi_id : mandi.hub_id, currentArrivalScreen === "Mandi" ? 7 : 3, "open");
                  }}
                  key="1"
                >
                  {currentArrivalScreen === "Mandi" ? " Open Mandi" : "Open Hub"}
                </Item>
              </Menu>
            }
            trigger={["contextMenu"]}
            placement="topLeft"
          >
            {children}
          </Dropdown>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  arrivalData: selectArrivalData,
  branches: selectBranches,
  hubs: selectHubs,
  mandis: selectMandis,
  userData: selectUserData,
  branchID: selectBranchID,
  hubID: selectHubID,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setArrivalData: (data) => dispatch(setArrivalData(data)),
    setMandis: (data) => dispatch(setMandis(data)),
    setHubs: (data) => dispatch(setHubs(data)),
    setBranches: (data) => dispatch(setBranches(data)),
    setCurrentArrivalScreen: (screen) =>
      dispatch(setCurrentArrivalScreen(screen)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MapContextMenu);
