import React, { useState } from "react";
import "./dashboard-map-marker.styles.css";

import { createPortal } from "react-dom";
import { DivIcon, marker } from "leaflet";
import * as RL from "react-leaflet";
import { MapLayer } from "react-leaflet";
import { difference } from "lodash";

import unfilledFactory from "../../assets/factory_red.svg";
import filledFactory from "../../assets/factory_green.svg";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import {
  selectBranchID,
  selectRegionID,
  selectHubID,
  selectMandiID,
} from "../../redux/arrival-entry/arrival-entry.selectors";

import { Badge, Icon, Dropdown, Menu } from "antd";
import { Marker, Popup, Tooltip } from "react-leaflet";

import closedImage from "../../assets/close.svg";
import tickImage from "../../assets/tick.svg";

import { CustomMarker } from "../map-marker-item/map-marker.component";
import Item from "antd/lib/list/Item";
import { MarkerF, InfoWindowF } from "@react-google-maps/api";

//////////////////////////

export const setColor = (branchName, entryStatus) => {
  let color = "";

  //handle color for each branch
  switch (branchName) {
    case "Bhopal":
      color = "#ff031a";
      break;
    case "Patna":
      color = "#da00ff";
      break;
    case "Delhi":
      color = "#0027ff";
      break;
    case "Ahmedabad":
      color = "#33F104";
      break;
    case "Jaipur":
      color = "#550101";
      break;
    case "Lucknow":
      color = "#FF6905";
      break;
    case "Nagpur":
      color = "#673ab7";
      break;
    case "Punjab":
      color = "#7D9308";
      break;
    case "Haryana":
      color = "#F9CF25";
      break;
    default:
      color = "#DFC57B";
  }

  //handle opacity
  switch (entryStatus) {
    case 1: //filled
      return color;
    case 0: //unfilled
      return color + "77"; //"87";

    default:
      return color;
  }
};

/**
 * @component
 * @description the mandi marker component in the dashboad map.
 * @property {latlong} position the latitude and longitude of the mandi
 * @property {number} mandiNumber the number to display on the marker
 * @property {string} mandiStatus the territory status of the mandi
 * @property {string} mandiName the name of the mandi
 *
 */
const DashboardMapMarker = (props) => {
  const {
    position,
    mandiNumber,
    status,
    entryStatus,
    name,
    branchName,
    isFactory,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  return (
    // <CustomMarker className="map-marker-item" position={position}>
    //   <Tooltip className="dashboard-map-marker-tooltip">
    //     <div style={{ fontWeight: "bold" }}>{`${name}`}</div>
    //     {isFactory === 111000 ? (
    //       <div>{`Factory`}</div>
    //     ) : (
    //       <div>{`${branchName}`}</div>
    //     )}
    //     <div style={{ fontStyle: "italic" }}>
    //       {`${
    //         status === "closed"
    //           ? "closed"
    //           : entryStatus === 1
    //           ? "filled"
    //           : "unfilled"
    //       }`}
    //     </div>
    //   </Tooltip>
    //   {isFactory === 111000 ? (
    //     <img
    //       className="dashboard-map-marker-factory"
    //       alt="factory"
    //       src={entryStatus === 0 ? unfilledFactory : filledFactory}
    //     />
    //   ) : (
    //     <div
    //       style={{
    //         background: `${
    //           status === "closed" ? "grey" : setColor(branchName, entryStatus)
    //         }`,
    //       }}
    //       className="dashboard-map-marker"
    //     />
    //   )}
    // </CustomMarker>
    <>
      <MarkerF
        position={position}
        icon={
          isFactory === 111000
            ? {
                url: entryStatus === 0 ? unfilledFactory : filledFactory,
                scaledSize: new window.google.maps.Size(15, 15),
              }
            : {
                path: window.google.maps.SymbolPath.CIRCLE,
                fillColor:
                  status === "closed"
                    ? "grey"
                    : setColor(branchName, entryStatus),
                fillOpacity: 1,
                strokeWeight: 0.5,
                scale: 6,
              }
        }
        onMouseOver={() => {
          setIsOpen(true);
        }}
        onMouseOut={() => {
          setIsOpen(false);
        }}
      />
      {isOpen && (
        <InfoWindowF
          position={position}
          options={{ pixelOffset: new window.google.maps.Size(0, -10) }}
        >
          <>
            <div style={{ fontWeight: "bold" }}>{`${name}`}</div>
            {isFactory === 111000 ? (
              <div>Factory</div>
            ) : (
              <div>{`${branchName}`}</div>
            )}
            <div style={{ fontStyle: "italic" }}>
              {`${
                status === "closed"
                  ? "closed"
                  : entryStatus === 1
                  ? "filled"
                  : "unfilled"
              }`}
            </div>
          </>
        </InfoWindowF>
      )}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  branchID: selectBranchID,
  regionID: selectRegionID,
  hubID: selectHubID,
  mandiID: selectMandiID,
});

export default connect(mapStateToProps)(DashboardMapMarker);
