import React, { Component } from "react";
import "./combined-chart.styles.css";
import Chart from "chart.js";
let combinedChart;

/**
 *
 * @description Custom chart made to display bar and line chart together.
 * @component
 * @extends {Component}
 */
class CombinedChart extends Component {
  compareProps;
  constructor(props) {
    super(props);
    this.state = {
      height: window.innerHeight - 350,
      width: window.innerWidth - 50,
    };
  }
  chartRef = React.createRef();

  componentDidMount() {
    this.buildChart();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.buildChart();
    }
  }

  /**
   * @function
   * @description Function to create a chart.
   * @memberof CombinedChart
   */
  buildChart = () => {
    const myChartRef = this.chartRef.current.getContext("2d");
    const { data, options } = this.props;

    if (typeof combinedChart !== "undefined") combinedChart.destroy();

    combinedChart = new Chart(myChartRef, {
      type: "bar",
      data: data,
      options: options,
    });
  };

  render() {
    return (
      <div style={{ position: "relative", height: "50vh", width: "95vw" }}>
        <canvas
          className="chartjs-render-monitor"
          style={{
            display: "flex",
            width: `${this.state.width}px`,
            height: `${this.state.height}px`,
          }}
          id="myChart"
          ref={this.chartRef}
        />
      </div>
    );
  }
}

export default CombinedChart;
