import { ArrivalActionTypes } from "./arrival-entry.types";

const INITIAL_STATE = {
  arrivalData: null,
  summaryVisibility: false,
  allMandis: [],
  branches: [],
  regions: [],
  hubs: [],
  mandis: [],
  varieties: [],
  remainingVarietyList: [],
  hubVarieties: [],
  remainingHubVarietyList: [],
  allVarietyInfo: [],
  branchID: null,
  regionID: null,
  hubID: null,
  mandiID: null,
};

const arrivalEntryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ArrivalActionTypes.SET_SUMMARY_VISIBILITY:
      return {
        ...state,
        summaryVisibility: action.payload,
      };

    case ArrivalActionTypes.SET_ARRIVAL_DATA:
      return {
        ...state,
        arrivalData: action.payload,
      };

    case ArrivalActionTypes.SET_ALL_MANDIS:
      return { ...state, allMandis: action.payload };

    case ArrivalActionTypes.SET_BRANCHES:
      return { ...state, branches: action.payload };
    case ArrivalActionTypes.SET_REGIONS:
      return { ...state, regions: action.payload };
    case ArrivalActionTypes.SET_HUBS:
      return { ...state, hubs: action.payload };
    case ArrivalActionTypes.SET_MANDIS:
      return { ...state, mandis: action.payload };

    case ArrivalActionTypes.SET_VARIETIES:
      return { ...state, varieties: action.payload };
    case ArrivalActionTypes.ADD_VARIETIES:
      const varieties = [...state.varieties, action.payload];
      return { ...state, varieties: varieties };
    case ArrivalActionTypes.SET_REMAINING_VARIETY_LIST:
      return {
        ...state,
        remainingVarietyList: action.payload,
      };

    case ArrivalActionTypes.SET_HUB_VARIETIES:
      return { ...state, hubVarieties: action.payload };
    case ArrivalActionTypes.ADD_HUB_VARIETIES:
      const hubVarieties = [...state.hubVarieties, action.payload];
      return { ...state, hubVarieties: hubVarieties };
    case ArrivalActionTypes.SET_REMAINING_HUB_VARIETY_LIST:
      return { ...state, remainingHubVarietyList: action.payload };

    case ArrivalActionTypes.SAVE_ALL_VARIETY_INFO:
      return { ...state, allVarietyInfo: action.payload };

    case ArrivalActionTypes.SET_BRANCH_ID:
      return { ...state, branchID: action.payload };
    case ArrivalActionTypes.SET_REGION_ID:
      return { ...state, regionID: action.payload };
    case ArrivalActionTypes.SET_HUB_ID:
      return { ...state, hubID: action.payload };
    case ArrivalActionTypes.SET_MANDI_ID:
      return { ...state, mandiID: action.payload };

    default:
      return state;
  }
};

export default arrivalEntryReducer;
