import React from "react";
import "./add-hub-form.styles.css";
import { Component } from "react";

import {
  Form,
  Radio,
  Input,
  Select,
  Switch,
  InputNumber,
  Button,
  message,
  Checkbox,
} from "antd";

import API from "../../api";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import {
  setAvailableMandis,
  setHubTableData,
  addNewHubs,
  addnewsHubsPostFOR,
} from "../../redux/buying-decision/buying-decision.actions";
import { selectAvailableMandis } from "../../redux/buying-decision/buying-decision.selectors";

import { setAllUsers } from "../../redux/user/user.actions";

/**
 * @description Form component for adding a new hub to the buying decision planning table.
 * Can add a trader/farmer hub or a hub containing multiple mandis.
 * @component
 */
class HubForm extends Component {
  state = {
    isSubmitted: false,
  };

  render() {
    const {
      currentHubs,
      newHubs,
      setModalVisibility,
      currentVarietyId,
      selectedRegions,
      selectedVarieties,
      selectedRegionID,
      selectedBranchID,
      spinnerOn
    } = this.props;

    const claimTypes = ["Claim", "No Claim"];
    const divisions = ["FBD"];
    // const divisions = ["FBD", "ABD", "NCDEX"];

    const getDivisionID = (divisionName) => {
      switch (divisionName) {
        case "ABD":
          return 1;
        case "FBD":
          return 2;
        case "NCDEX":
          return 3;
        default:
          return 1;
      }
    };
    const getClaimID = (claimName) => {
      switch (claimName) {
        case "Claim":
          return 1;
        case "No Claim":
          return 2;
        default:
          return 1;
      }
    };

    /**
     *
     * @description Save the selected hub(s) with/without mandis into redux store and display the same in the main table.
     * @param {object} values the submitted values from the add hub form component.
     * @memberof HubForm
     */
    const generateHubsAndSaveToRedux = async (values) => {
      spinnerOn(true);
      this.setState({ isSubmitted: true });

      let hubList = []; // this needs to be an array.



      // get selected hub id.
      const selectedHubId = this.props.newHubs.find(
        (item) => item.hub_name === values.selectedHub
      ).hub_id;

      const selectedHub = this.props.newHubs.find(
        (item) => item.hub_name === values.selectedHub
      );


      const params = {
        branch_id: selectedBranchID,
        region_id: selectedRegions,
        variety_id: selectedVarieties,
        claim_type: getClaimID(values.selectedClaim),
        division: getDivisionID(values.selectedDivision),
        hub_id: selectedHubId
      };

      const hubToAdd = await API.get(`/buying_decision_planned/non_recommended_hub_details`,
        // const hubToAdd = await API.get(`/branches/${selectedBranchID}/regions/${selectedRegionID}/variety/${currentVarietyId}/hub/${selectedHubId}/claim_type/${getClaimID(values.selectedClaim)}/division/${getDivisionID(values.selectedDivision)}/details`,
        { params: params }).then(hubResponse => {
          if (hubResponse.data.status) {
            spinnerOn(false);
            // message.success('Hub added successfully.')

            //   if (hub.hub_id == selectedHubId &&
            //     hub.claim_type == getClaimID(values.selectedClaim) &&
            //     selectedVarieties.find((variety_id) => variety_id == hub.variety_id) &&
            //     // hub.variety_id === claimType &&
            //     hub.division == getDivisionID(values.selectedDivision)) {
            //       selectedVarieties.find((variety_id) => variety_id == hub.variety_id) &&
            //       // hub.variety_id === claimType &&
            //       hub.division == getDivisionID(values.selectedDivision), "hub.hub_id == selectedHubId ", hub.hub_id == selectedHubId , "hub.claim_type == getClaimID(values.selectedClaim) ", hub.claim_type == getClaimID(values.selectedClaim) , "selectedVarieties.find((variety_id) => variety_id == hub.variety_id)", selectedVarieties.find((variety_id) => variety_id == hub.variety_id), "hub.division == getDivisionID(values.selectedDivision)", hub.division == getDivisionID(values.selectedDivision) );
            //     return hub
            //   }
            // }
            // ))

            return hubResponse.data.data.hub_list
            // .filter((hub) =>
            //   hub.hub_id !== selectedHubId &&
            //   hub.claim_type == claimType &&
            //   selectedVarieties.find((variety_id) => variety_id !== hub.variety_id) &&
            //   // hub.variety_id === claimType &&
            //   hub.division === division)
            // // return hubResponse.data.data.hub_list[0]

          } else {
            spinnerOn(false);
            message.warning('Could not add hub. Please try again.');
            return null;
          }

        }).catch(err => {
          spinnerOn(false);
          message.error("Could not add hub. Please check your network connectivity.")
          return null;
        })

      if (!hubToAdd) {
        setModalVisibility(false)
        return;
      }


      hubList = hubToAdd.map((hub) => {
        return {
          buying_decision_id: null,
          temp_bd_id: hub.temp_bd_id,
          hub_id: selectedHubId,
          hub_name: values.selectedHub,
          remark_id: null,
          revised_buy_max_price: 0,
          revised_buy_min_price: 0,
          revised_qty: 0,
          source_type: "trader",
          suggested_price: 0,
          suggested_qty: 0,
          total_supply: hub.total_supply,
          // today_arrival_qty: hub.today_arrival_qty,
          // today_price: hub.today_price,
          // yesterday_arrival_qty: hub.yesterday_arrival_qty,
          yesterday_price: hub.yesterday_price,
          yesterday_purchase_qty: hub.yesterday_purchase_qty,
          edited: true,
          approval1: null,
          approval2: null,
          approver1_name: null,
          approver2_name: null,
          comment1: null,
          comment2: null,
          variety_name: hub.variety_name,
          variety_id: hub.variety_id,
          // competitor_bids: hub.competitor_bids,
          price: hub.price,
          price_list: hub.price_list,
          date_list: hub.date_list,
          claim_type: values.selectedClaim,
          division: values.selectedDivision,
          recommendations: [],
          version: 0,
          region_id: hub.region_id
        }
      })

      // const DupHubList = hubList.filter((hub) => {
      //   return currentHubs.map((dupHub) =>
      //     dupHub.hub_id === selectedHubId &&
      //     !(hub.claim_type !== dupHub.claim_type) &&
      //     hub.variety_id == dupHub.variety_id &&
      //     !(hub.division !== dupHub.division))
      //   // selectedVarieties.find((variety_id) => variety_id !== hub.variety_id) &&
      //   // return hubResponse.data.data.hub_list[0]
      // })


      let modifiedAddHubs = [...hubList];
      let duplicatedHubs = [];
      modifiedAddHubs.map((addedHub) => {
        currentHubs.map((existingHub) => {
          //   !(addedHub.claim_type !== existingHub.claim_type),
          //   addedHub.variety_id == existingHub.variety_id,
          //   !(addedHub.division !== existingHub.division), "ALLLLLLLLLLLLLLLLLLLLLLLLLLL", addedHub.hub_id === existingHub.hub_id &&
          //   !(addedHub.claim_type !== existingHub.claim_type) &&
          //   addedHub.variety_id == existingHub.variety_id &&
          // !(addedHub.division !== existingHub.division));

          if (
            addedHub.hub_id === existingHub.hub_id &&
            !(addedHub.claim_type !== existingHub.claim_type) &&
            addedHub.variety_id === existingHub.variety_id &&
            !(addedHub.division !== existingHub.division)

            // addedHub.name === existingHub.name &&
            //   addedHub.value === existingHub.value
          ) {
            duplicatedHubs.push(addedHub);
          }
        });
      });

      if (duplicatedHubs && hubToAdd && duplicatedHubs.length === hubToAdd.length) {
        message.info('No hubs added, as they are already exist.', 3)
      } else {
        message.success('Hub(s) added successfully.', 3)
      }
      const hubListResults = hubList.filter(({ hub_id: hd1, claim_type: ct1, division: d1, variety_id: vt1 }) => !duplicatedHubs.some(({ claim_type, hub_id, division, variety_id }) => hub_id === hd1 && variety_id === vt1));


      // let combinedArray = [...currentHubs, ...hubList];

      // let modifiedArrayTable = Array.from(
      //   new Set(combinedArray.map((item) => item))
      // ).map((hub_id, claim_type, variety_id, division) => {
      //   return combinedArray.find((item) => item.hub_id === hub_id && !(item.claim_type !== claim_type) && item.variety_id !== variety_id && !(item.division !== division));
      // });


      //#region add hubTableData and hubFORdatain redux

      const forData = hubListResults
        .map((item, idx) => {
          if (item.source_type !== "mandi") {
            return {
              hub_id: item.hub_id,
              buying_decision_id: item.buying_decision_id,
              revised_qty: item.revised_qty,
              revised_buy_min_price: item.revised_buy_min_price,
              revised_buy_max_price: item.revised_buy_max_price,
              remark_id: item.remark_id ? item.remark_id : null,
              source_type: "trader",
              division: item.division,
              claim_type: item.claim_type,
              region_id: item.region_id,
              variety_id: item.variety_id
            };
          }
        })
        .filter((item) => item !== undefined);

      this.props.addnewsHubsPostFOR(forData);

      // this.props.addNewHubs(hubList); //old
      this.props.addNewHubs(hubListResults);

      //#endregion

      setModalVisibility(false);
    };

    const { getFieldDecorator } = this.props.form;

    /**
     * @description gets called upon selecting a hub.
     * @param {string} hubName name of the hub
     * @memberof HubForm
     */
    const onHubSelected = (hubName) => {
      const selectedHubId = this.props.newHubs.find(
        (item) => item.hub_name === hubName
      ).hub_id;
    };

    /**
     * @description Handle the validation and submission of the add hub form component.
     * @param {event} e the form submission event
     * @memberof HubForm
     */
    const handleSubmit = (e) => {
      e.preventDefault();
      this.props.form.validateFields((err, values) => {
        if (!err) {
          if (
            validateHubToBeAdded(
              values.selectedHub,
              values.selectedClaim,
              values.selectedDivision
            )
          ) {
            generateHubsAndSaveToRedux(values);
          }
        } else {
        }
      });
    };

    const validateHubToBeAdded = (selectedHub, claimType, division) => {
      const selectedHubId = this.props.newHubs.find(
        (item) => item.hub_name === selectedHub
      ).hub_id;
      const duplicateHub = currentHubs.find(
        (hub) =>
          hub.hub_id === selectedHubId &&
          hub.claim_type === claimType &&
          // selectedVarieties.find((variety_id) => variety_id === hub.variety_id) &&
          // hub.variety_id === claimType &&
          hub.division === division
      );


      // if (duplicateHub) {
      //   message.warning(
      //     "This claim - division combination already exists for the selected hub !"
      //   );
      //   return false;
      // }

      return true;
    };

    return (
      <div className="hub-form-container">
        <Form
          layout="horizontal"
          //   onValuesChange={onFormLayoutChange}
          size={"small"}
          onSubmit={handleSubmit}
        >
          <div className="form-top-container">
            <Form.Item label="Select Hub">
              {getFieldDecorator("selectedHub", {
                // initialValue: "Select Hub",
                rules: [
                  {
                    required: true,
                    message: "Please select the hub !",
                  },
                ],
              })(
                <Select
                  disabled={this.state.isSubmitted}
                  showSearch
                  showArrow
                  //  mode="multiple"
                  placeholder="Select hub"
                  onSelect={(value) => onHubSelected(value)}
                >
                  {newHubs.map((hub, idx) => (
                    <Select.Option key={hub.hub_id} value={hub.hub_name}>
                      {hub.hub_name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>

            <div className="checkbox-group">
              <Form.Item label="Select Claim Type">
                {getFieldDecorator("selectedClaim", {
                  rules: [
                    {
                      required: true,
                      message: "Please select the claim type !",
                    },
                  ],
                })(
                  <Radio.Group
                    disabled={this.state.isSubmitted}
                    options={claimTypes}
                    onChange={(value) => console.log(value)}
                  />
                )}
              </Form.Item>
            </div>

            <div className="checkbox-group">
              <Form.Item label="Select Division">
                {getFieldDecorator("selectedDivision", {
                  rules: [
                    {
                      required: true,
                      message: "Please select the division !",
                    },
                  ],
                })(
                  <Radio.Group
                    disabled={this.state.isSubmitted}
                    options={divisions}
                    onChange={(value) => console.log(value)}
                  />
                )}
              </Form.Item>
            </div>
          </div>

          <div className="form-bottom-container">
            <Form.Item>
              <Button
                disabled={this.state.isSubmitted}
                type="primary"
                htmlType="submit"
              >
                Submit
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    );
  }
}

const AddHubForm = Form.create({ name: "add_hub_form" })(HubForm);

const mapDispatchToProps = (dispatch) => {
  return {
    addnewsHubsPostFOR: (hubs) => dispatch(addnewsHubsPostFOR(hubs)),
    addNewHubs: (hubs) => dispatch(addNewHubs(hubs)),
    setAvailableMandis: (mandis) => dispatch(setAvailableMandis(mandis)),
    setHubTableData: (data) => dispatch(setHubTableData(data)),
    setAllUsers: (data) => dispatch(setAllUsers(data)),
  };
};

const mapStateToProps = createStructuredSelector({
  availableMandis: selectAvailableMandis,

});

export default connect(mapStateToProps, mapDispatchToProps)(AddHubForm);
