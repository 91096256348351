import React from "react";
import "./alerts.styles.css";
import { Component } from "react";

import { Modal, Button, Input, Select, message, Spin } from "antd";

import { InfinityTable as Table } from "antd-table-infinity";

import editUserImage from "../../assets/edit-user.svg";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import {
  selectAllUsers,
  selectAllBranches,
} from "../../redux/user/user.selectors";

import { setAlerts } from "../../redux/alerts/alerts.actions";
import {
  selectAllAlerts,
  selectUserGroupData,
} from "../../redux/alerts/alerts.selectors";

import AlertsManagementForm from "../../components/alert-management-form/alert-management-form.component";

import API from "../../api";

import moment from "moment";

const { Search } = Input;
const { Option } = Select;

/**
 * @description Alerts tab component.
 * Creates and edit alerts.
 * @component
 */
class Alerts extends Component {
  state = {
    isTableLoading: false,
    isModalVisible: false,
    isAdd: false,
    currentRecord: null,
    userBranches: null,
  };

  componentDidMount() {
    //#region api to get all alerts


    API.get("/user/alerts")
      .then((alertsResponse) => {
        if (alertsResponse.data.status) {
          this.props.setAlerts(alertsResponse.data.data);
        } else {
          message.warning("Could not retrieve alerts. Please try again");
        }
      })
      .catch((err) => {
        message.error(
          "Could not retieve alerts. Please check your network connectivity."
        );
      });

    ////#endregion
  }

  render() {
    const targetGroupList = this.props.userGroupData.map((group) => {
      return {
        id: group.group_id,
        name: group.group_name,
      };
    });

    const alertTypeList = [
      { name: "Price Alert", id: 1 },
      { name: "Purchase Required", id: 2 },
    ];

    const alertBehaviourList = [
      { label: "Priority", value: 1 },
      { label: "System", value: 2 },
      { label: "General", value: 3 },
    ];
    const communicationModeList = [
      { label: "Email", value: 1 },
      { label: "SMS", value: 2 },
      { label: "System", value: 3 },
    ];

    const alertsTableProps = {
      rowKey: "key", // Important, otherwise scrolling page will be a problem
      pageSize: 100,
      debug: true,
      pagination: false,
      size: "default",
      bordered: true,
      scroll: { x: 200, y: window.innerHeight - 214 },
    };

    // set the user table data

    /**
     * @description Gets the alerts data from the redux store and assigns as the data source for the alerts table .
     * @memberof Alerts
     */
    const setAlertsTableData = () => {
      const AlertsTableData = this.props.allAlerts.map((alert) => {
        return {
          key: alert.user_alert_id,
          allData: alert,
          date: new Date(alert.date_posted),
          expiryDate: new Date(alert.expiry_date),
          alertType: alertTypeList.find(
            (item) => item.id === alert.alert_type_id
          ), // { name: "Price Alert", id: 1 },
          alertBehaviour: alertBehaviourList.find(
            (item) => item.value === alert.alert_behaviour_id
          ), // { label: "Priority", value: 1 },
          targetGroup: alert.target_groups.map((group) => {
            return {
              id: group.group_id,
              name: group.group_name,
            };
          }),
          communicationMode: alert.communication_modes.map(
            (communicationMode) => {
              return {
                value: communicationMode.comm_id,
                label: communicationMode.comm_name,
              };
            }
          ),

          description: alert.description,
          actions: {
            canEdit: true,
            // role_id: user.role.role_id,
            // isActive: user.is_active,
          },
        };
      });


      return AlertsTableData;
    };

    // set the edit/add  modal visibility
    /**
     *@description Enable/Disable the add/edit alerts modal visibility.
     * @param {boolean} status Visibility state of the popup modal.
     * @param {boolean} isAdd true: Display 'Add Alert' ; false: Display 'Edit Alert'.
     * @param {Object} record current alert object, null is passed if it's a new alert.
     * @memberof Alerts
     */
    const setModalVisibility = (status, isAdd, record) => {
      if (targetGroupList.length !== 0) {
        this.setState((prevState) => ({
          ...prevState,
          currentRecord: record,
          isAdd: isAdd,
          isModalVisible: status,
        }));
      } else {
        message.warning(
          "The user group list is empty or is being loaded. Please wait until the list loads or add a group if the list is empty.",
          4
        );
      }
    };

    /**
     * @description Component to display during data fetch for the table.
     * The table we use is a modified antd table which supports infinity scroll and continuous API calls to fetch.
     * Currently the auto-fetch functionality is disabled, but is present for scalablity.
     * @component
     * @classdesc Child component of Alerts
     * @returns a spinner overlay.
     * @memberof Alerts
     */
    const loadMoreContent = () => {
      return (
        <div
          style={{
            textAlign: "center",
            paddingTop: 40,
            paddingBottom: 40,
            border: "1px solid #e8e8e8",
          }}
        >
          <Spin tip="Loading..." />
        </div>
      );
    };


    /**
     *@description Get more alerts from the backend and add to redux by despatching setAlerts to store.
     *Currently the function is disabled. Present for scalablity in the future.
     *@memberof Alerts
     */
    const GetMoreAlerts = () => {
      // call the api to get more data and add to redux

      this.setState({ isTableLoading: true });
      this.setState({ isTableLoading: false });

    };

    return (
      <div
        className="alert-management-container"
        style={{ height: window.innerHeight - 85, overflow: "scroll" }}
      >
        <Modal
          width={750}
          className="alert-management-modal"
          title={this.state.isAdd ? "Add Alert" : "Edit Alert"}
          visible={this.state.isModalVisible}
          destroyOnClose={true}
          onOk={() => {
            setModalVisibility(false, false, null);
          }}
          onCancel={() => setModalVisibility(false, false, null)}
        >
          <AlertsManagementForm
            allAlerts={this.props.allAlerts}
            setModalVisibility={setModalVisibility}
            currentRecord={this.state.currentRecord}
            currentTargetGroup={
              this.state.currentRecord
                ? this.state.currentRecord.targetGroup.map((item) => item.name)
                : null
            }
            isAdd={this.state.isAdd}
            alertTypeList={alertTypeList}
            targetGroupList={targetGroupList}
            alertBehaviourList={alertBehaviourList}
            communicationModeList={communicationModeList}
            setAlerts={this.props.setAlerts}
          />
        </Modal>

        <div className="top-container">
          <Button
            onClick={() => setModalVisibility(true, true, null)}
            className="add-alert-button"
          >
            + Add Alert
          </Button>
        </div>

        <div className="table-container">
          <Table
            loading={this.state.isTableLoading}
            loadingIndicator={loadMoreContent()}
            onFetch={() => GetMoreAlerts()}
            {...alertsTableProps}
            columns={[
              {
                key: "date",
                title: "Date Posted",
                dataIndex: "date",
                width: 100,
                render: (date, record) =>
                  moment(date, "DD-MM-YYYY").format("DD-MM-YYYY"),
              },
              {
                key: "expiryDate",
                title: "Expiry Date",
                dataIndex: "expiryDate",
                width: 100,
                render: (expiryDate, record) =>
                  moment(expiryDate, "DD-MM-YYYY").format("DD-MM-YYYY"),
              },
              {
                key: "alertType",
                title: "Alert Type",
                dataIndex: "alertType",
                width: 120,
                render: (alertType, record) => alertType.name,
              },
              {
                key: "alertBehaviour",
                title: "Alert Behaviour",
                dataIndex: "alertBehaviour",
                width: 120,
                render: (alertBehaviour, record) => alertBehaviour.label,
              },
              {
                key: "targetGroup",
                title: "Target Group",
                dataIndex: "targetGroup",
                width: 160,
                render: (targetGroups) =>
                  targetGroups
                    .map((targetGroup) => targetGroup.name)
                    .toString(),
              },
              {
                key: "communicationMode",
                title: "Communication Mode",
                dataIndex: "communicationMode",
                width: 160,
                render: (communicationModes) =>
                  communicationModes.map((mode) => mode.label).toString(),
              },
              {
                key: "description",
                title: "Description",
                dataIndex: "description",
              },
              {
                key: "actions",
                title: "Actions",
                dataIndex: "actions",
                width: 100,
                render: (actions, record) => (
                  <div className="alert-action-container">
                    <Button
                      onClick={() => setModalVisibility(true, false, record)}
                      className="edit-button"
                    >
                      <img
                        style={{
                          width: "15px",
                          height: "15px",
                          margin: "4px 5px 0 -6px",
                        }}
                        alt="edit"
                        src={editUserImage}
                      />
                      <h5 style={{ margin: "3px 0 0 0" }}>Edit</h5>
                    </Button>
                  </div>
                ),
              },
            ]}
            dataSource={setAlertsTableData()}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  allAlerts: selectAllAlerts,
  userGroupData: selectUserGroupData,
  allUsers: selectAllUsers,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setAlerts: (data) => dispatch(setAlerts(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Alerts);
