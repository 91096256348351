import React, { useReducer } from "react";
import "./container-DTD-freight-master.styles.css";
import { Component } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUserData,selectMSMessageRBAC } from "../../redux/user/user.selectors";
import { canUserEdit,getUserMSRBACMessage } from "../../auth/utils";
import { FeatureCodes } from "../../auth/data";
import {
  Table,
  Spin,
  Input,
  Select,
  Button,
  message,
  Form,
  Modal,
  Radio,
} from "antd";

import API from "../../api";
const { Search } = Input;
const { Option } = Select;
/**
 * @description Component for user management tab
 *
 * @component
 */
class ContainerDTDFreightMaster extends Component {
  state = {
    isModalVisible: false,
    isAdd: false,
    currentRecord: null,
    userBranches: null,
    searchText: null,
    searchUserType: 0,
    plantCodeList: [],
    mandiRegionList: [],
    plantList: [],
    FirstMileLocList:[],
    locationList: [],
    cargoList: [],
    isSpinnerOnLoad: false,
    isSubmitted: false,
    newData: {
      is_active: true,
      cargo:"",   
      plant_code: "",
      plant_code_id: undefined,
      plant: "",
      plant_id:undefined,
      first_mile_location: undefined,
      first_location_id :undefined,
      last_mile_location: undefined,
      last_location_id : "",
      rate_as_per_contract: undefined,
      rate_28_mt_loadability: undefined,
      loading_rate_including_dala_charge: undefined,
      unloading_charges : undefined,
      mamool_cost : undefined,
      isEdit: false,
    },
    rowData: undefined,
    selectedRegionValue: undefined,
    selectedRegionName: "",
    selectedBranchValue: undefined,
    selectedBranchName: "",
    selectedBranchList: [],
    selectedRegionList: [],
    testvalue: undefined,
    ContainerTableData: [],
   items: ['jack', 'lucy'],
  };
  componentDidMount() {
    this.setState({ isSpinnerOnLoad: true });
    this.getCargoList();
  }

  getCargoList = async () => {
    this.setState({ isSpinnerOnLoad: true });
   
    //TODO: change api
    await API.get("/CDTD_cargo")
      .then((roleData) => {
        
       roleData.data.data.map((dataDetailItem, index) => {
          dataDetailItem.key = index;
        });
        console.log("cargo ",  roleData.data.data);
        this.setState(
          {
            cargoList: roleData.data.data,
          },
          () => this.getPlantCodeList()
        );
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400) message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };

  getPlantCodeList = async () => {
   
   
    await API.get("/CDTD_plant_code")
      .then((roleData) => {
        
       
        this.setState(
          {
            plantCodeList: roleData.data.data,
          },
          () => this.getFirstMileList()
        );
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400) message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };

  getFirstMileList = async () => {
    this.setState({ isSpinnerOnLoad: true });
    
    await API.get("/CDTD_fm_location")
      .then((hubList) => {
       hubList.data.data.map((dataDetailItem, index) => {
          dataDetailItem.key = index;
        });
        this.setState({
          FirstMileLocList:hubList.data.data,
         
        },
          () => this.getLocationList()
        );
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };

  getLocationList = async () => {
    this.setState({ isSpinnerOnLoad: true });
   
    await API.get("/CDTD_lm_location")
      .then((hubList) => {
        hubList.data.data.map((dataDetailItem, index) => {
          dataDetailItem.key = index;
        });
        this.setState({
            locationList: hubList.data.data,
        },
          () => this.getContainerDTDFreightMaster()
        );
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400)
            message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };

  getContainerDTDFreightMaster = async () => {
    this.setState({ isSpinnerOnLoad: true });
    
    await API.get("/container_dtd_freight")
      .then((varietyResponse) => {

        varietyResponse.data.data.map((dataDetailItem, index) => {
          dataDetailItem.key = index;
          dataDetailItem.isEdit = false;
        });
        this.setState(
          {
            ContainerTableData: varietyResponse.data.data,
            // InflationMasterTableData:[],
          },
          () => this.setState({ isSpinnerOnLoad: false, isAdd: true })
        );

      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.status === 400) message.error("Something went wrong. Please try again later.", 5);
          else {
            message.error("Something went wrong.. Please try again later.", 5);
          }
        } else {
          message.error("Something went wrong... Please try again later.", 5);
        }
        this.setState({ isSpinnerOnLoad: false });
      });
  };

  setTableData = () => {
    if (this.state.ContainerTableData !== undefined || this.state.ContainerTableData.length >= 0) {
      const userTableData = this.state.ContainerTableData.map((user, index) => {
        return {
          key: user.key,
          cdf_pk_id:user.cdf_pk_id,
          cargo:user.cargo,   
          plant_code: user.plant_code,
          plant: user.plant,
          first_mile_location: user.first_mile_location,
          last_mile_location: user.last_mile_location,
          plant_code_id: user.plant_code_id,
          plant_id:user.plant_id,
          first_location_id :user.first_location_id,
          last_location_id : user.last_location_id,
          rate_as_per_contract: user.rate_as_per_contract,
          rate_28_mt_loadability: user.rate_28_mt_loadability,
          loading_rate_including_dala_charge: user.loading_rate_including_dala_charge,
          unloading_charges :user.unloading_charges,
          mamool_cost : user.mamool_cost,
          is_active : user.is_active,
          isEdit: user.isEdit,
        };
      });

      let dataAfterSearch = userTableData;

      if (this.state.searchText) {
        dataAfterSearch = dataAfterSearch.filter(
          (item) =>
            (item.plant !== null &&
              item.plant
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase())) ||
            (item.plant_code !== null &&
              item.plant_code
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase()))
          // (item.hub_name !== null &&
          //   item.hub_name
          //     .toLowerCase()
          //     .includes(this.state.searchText.toLowerCase())) 
        );
      }

      return dataAfterSearch;
    }
    else {
      return this.state.ContainerTableData;
    }
  };

  submitTableRowData = (row, isPopup) => {
    this.setState({ isSpinnerOnLoad: true, isSubmitted: true });
    let warningmessage = "";
    if (row.cargo === null || row.cargo === undefined || row.cargo === "") {
      warningmessage = "Please enter Cargo";
    } else if (row.plant_code === null || row.plant_code === undefined || row.plant_code === "") {
      warningmessage = "Please select Plant Code";
    } else if (row.plant === null || row.plant === undefined || row.plant === "") {
      warningmessage = "Please select Plant";
    }
     else if (row.first_mile_location === null || row.first_mile_location === undefined || row.first_mile_location === "") {
        warningmessage = "Please select First Mile Location";
    } else if (row.last_mile_location === null || row.last_mile_location === undefined || row.last_mile_location === "") {
        warningmessage = "Please select Last Mile Location";
    } else if (row.rate_as_per_contract === null || row.rate_as_per_contract === undefined ||
      row.rate_as_per_contract === "" || row.rate_as_per_contract === 0) {
      warningmessage = "Please enter Rate as per Contract";
    } else if (row.rate_28_mt_loadability === null || row.rate_28_mt_loadability === undefined ||
        row.rate_28_mt_loadability === "" || row.rate_28_mt_loadability === 0) {
        warningmessage = "Please enter Rate 28 mt Loadability";
      } else if (row.loading_rate_including_dala_charge === null || row.loading_rate_including_dala_charge === undefined ||
        row.loading_rate_including_dala_charge === "" || row.loading_rate_including_dala_charge === 0) {
        warningmessage = "Please enter Loading Rate including Dala charges";
      } else if (row.unloading_charges === null || row.unloading_charges === undefined ||
        row.unloading_charges === "" || row.unloading_charges === 0) {
        warningmessage = "Please enter Unloading Charges";
      } else if (row.mamool_cost === null || row.mamool_cost === undefined ||
        row.mamool_cost === "" || row.mamool_cost === 0) {
        warningmessage = "Please enter Mamool Cost";
        }

    if (
      warningmessage === "" ||
      warningmessage === undefined ||
      warningmessage === null
    ) {
      this.setState({ isSubmitted: true });
      this.setState({ isSpinnerOnLoad: true });
      let data = {
        cargo:row.cargo,   
        cdf_pk_id:row.cdf_pk_id,
        plant_code: row.plant_code,
        plant: row.plant,
        first_mile_location: row.first_mile_location,
        last_mile_location: row.last_mile_location,
        rate_as_per_contract: row.rate_as_per_contract,
        rate_28_mt_loadability: row.rate_28_mt_loadability,
        loading_rate_including_dala_charge: row.loading_rate_including_dala_charge,
        unloading_charges :row.unloading_charges,
        mamool_cost : row.mamool_cost,
        is_active : row.is_active === true?1:0,
      };


      API.put("/container_dtd_freight", data, {

      })
        .then((response) => {

          if (response.status === 200 && response.data.status) {
            this.setModalVisibility(false);
            message.info(response.data.message, 5);
            this.getContainerDTDFreightMaster();
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
          } else if (!response.data.status) {
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
            message.error(response.data.message, 5);
            // this.setModalVisibility(false);
          } else {
            this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
            message.error("Please try again", 5);
          }
        })
        .catch((err) => {
          if (err) {
            if (err.response && err.response.status === 400)
              message.error("Something went wrong. Please try again later.", 5);
            else {
              message.error(
                "Something went wrong.. Please try again later.",
                5
              );
            }
          } else {
            message.error("Something went wrong... Please try again later.", 5);
          }
          this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
          // this.setModalVisibility(false);
        });
    } else {
      this.setState({ isSpinnerOnLoad: false, isSubmitted: false });
      message.error(warningmessage, 5);
    }
  };

  setModalVisibility = (status) => {
    if (status) {
      const nData = {
        is_active: true,
        cargo:"",   
        plant_code: "",
        plant_code_id: undefined,
        plant: "",
        plant_id:undefined,
        first_mile_location:undefined,
        first_location_id :undefined,
        last_mile_location: undefined,
        last_location_id : "",
        rate_as_per_contract: undefined,
        rate_28_mt_loadability: undefined,
        loading_rate_including_dala_charge: undefined,
        unloading_charges : undefined,
        mamool_cost : undefined,
        isEdit: false,
      };

      this.setState({ newData: nData });
    }

    this.setState({ isModalVisible: status });
  };

  TableColumnHeader = (props) => {
    const { title, subtitle } = props;
    return (
      <div className="column-header-container">
        <div
          style={{
            color: "white",
            fontSize: "12px",
            whiteSpace: "nowrap",
            fontWeight: "normal",
            textAlign: "left",
          }}
        >
          {title}
        </div>
        <div
          style={{
            color: "#cbdfff",
            fontSize: "11px",
            fontWeight: "normal",
            textAlign: "left",
            // subtitle && title.length < subtitle.length ? "left" : "center"
          }}
        >
          {subtitle}
        </div>
      </div>
    );
  };

  createFilters = (label) => {
    let filterData = this.state.ContainerTableData;
    //#region remove duplicate objects
    let uniqueFilterData = [];

    if (label === "is_active" && this.state.ContainerTableData && this.state.ContainerTableData.length > 0) {
      uniqueFilterData = [{ text: "Yes", value: 1 }, { text: "No", value: 0 }]
    }
    else {
      filterData.map((mainItem) =>
        uniqueFilterData.filter((item) => item[label] === mainItem[label])
          .length > 0
          ? null
          : uniqueFilterData.push(mainItem)
      );

      uniqueFilterData = uniqueFilterData.map((item) => {
        return {
          text: item[label],
          value: item[label],
        };

      });

      uniqueFilterData.sort((a, b) => a.text.localeCompare(b.text))
    }

    return uniqueFilterData;
  };

  handleDropdownChange = (value, row, target, index, optionlabel, isPopup) => {
    console.log("dropdown ", value,row,target,index,optionlabel,isPopup);
    let a;

    if (isPopup === false) {
      a = this.state.ContainerTableData;

      if (target === "is_active") {
        a[row.key].is_active = Boolean(value);
      } else if (target === "cargo") {
        a[row.key].cargo = value;
      } else if (target === "plant_code") {
        a[row.key].plant = optionlabel[2];
        a[row.key].plant_code = value;
      } else if (target === "plant") {
        a[row.key].plant = optionlabel;
        a[row.key].plant_id = value;
      } else if (target === "first_mile_location") {
        a[row.key].first_mile_location = optionlabel;
        a[row.key].first_location_id = value;
      } else if (target === "last_mile_location") {
        a[row.key].last_mile_location = optionlabel;
        a[row.key].last_location_id = value;
      }
      this.setState({ ContainerTableData: a });
    } else {
      a = this.state.newData;

      if (target === "is_active") {
        a.is_active = Boolean(value);
      } else if (target === "cargo") {
        a.cargo = value;
      }  else if (target === "plant_code") {
        a.plant = optionlabel[2];
        a.plant_code = value;
      } else if (target === "plant") {
        a.plant = optionlabel;
        a.plant_id = value;
      } else if (target === "first_mile_location") {
        a.first_mile_location = optionlabel;
        a.first_location_id = value;
      }else if (target === "last_mile_location") {
        a.last_mile_location = optionlabel;
        a.last_location_id = value;
      }
      this.setState({ newData: a });
    }
  };

  handleChange = (e, row, index, name, isPopup) => {

    let a;
    var alphaNumericRegex = /^[a-zA-Z0-9]*$/;
    var numberRegex = /^[0-9.]+$/;
    if (isPopup === false) {
      a = this.state.ContainerTableData;

      switch (name) {
        case "cargo":
          a[row.key].cargo = e.target.value;
          this.setState({ ContainerTableData: a });
          return;
        case "rate_as_per_contract":
          if (e.target.value === "") {
            a[row.key].rate_as_per_contract = "";
          } else if (e.target.value.match(alphaNumericRegex)) {
            a[row.key].rate_as_per_contract = e.target.value.toUpperCase();
          }

          this.setState({ ContainerTableData: a });
          return;
        case "rate_28_mt_loadability":
          if (e.target.value === "") {
            a[row.key].rate_28_mt_loadability = "";
          } else if (e.target.value.match(numberRegex)) {
            a[row.key].rate_28_mt_loadability = e.target.value;
          }

          this.setState({ ContainerTableData: a });
          return;
        case "loading_rate_including_dala_charge":
          if (e.target.value === "") {
            a[row.key].loading_rate_including_dala_charge = "";
          } else if (e.target.value.match(numberRegex)) {
            a[row.key].loading_rate_including_dala_charge = e.target.value;
          }

          this.setState({ ContainerTableData: a });
          return;
        case "unloading_charges":
          if (e.target.value === "") {
            a[row.key].unloading_charges = "";
          } else if (e.target.value.match(numberRegex)) {
            a[row.key].unloading_charges = e.target.value;
          }

          this.setState({ ContainerTableData: a });
          return;
          case "mamool_cost":
            if (e.target.value === "") {
              a[row.key].mamool_cost = "";
            } else if (e.target.value.match(numberRegex)) {
              a[row.key].mamool_cost = e.target.value;
            }
  
            this.setState({ ContainerTableData: a });
            return;
        default:
          return "";
      }

    } else {
      a = this.state.newData;

      switch (name) {
        case "cargo":
          a.territory_name = e.target.value;
          this.setState({ newData: a });
          return;
        case "rate_as_per_contract":
          if (e.target.value === "") {
            a.rate_as_per_contract = "";
          } else if (e.target.value.match(alphaNumericRegex)) {
            a.rate_as_per_contract = e.target.value.toUpperCase();
          }

          this.setState({ newData: a });
          return;
        case "rate_28_mt_loadability":
          if (e.target.value === "") {
            a.rate_28_mt_loadability = "";
          } else if (e.target.value.match(numberRegex)) {
            a.rate_28_mt_loadability = e.target.value;
          }

          this.setState({ newData: a });
          return;
        case "loading_rate_including_dala_charge":
          if (e.target.value === "") {
            a.loading_rate_including_dala_charge = "";
          } else if (e.target.value.match(numberRegex)) {
            a.loading_rate_including_dala_charge = e.target.value;
          }

          this.setState({ newData: a });
          return;
          case "unloading_charges":
            if (e.target.value === "") {
              a.unloading_charges = "";
            } else if (e.target.value.match(numberRegex)) {
              a.unloading_charges = e.target.value;
            }
  
            this.setState({ newData: a });
            return;
            case "mamool_cost":
              if (e.target.value === "") {
                a.mamool_cost = "";
              } else if (e.target.value.match(numberRegex)) {
                a.mamool_cost = e.target.value;
              }
    
              this.setState({ newData: a });
              return;
        default:
          return "";
      }
    }
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  handleEditChange = (row, index) => {
    console.log("user role id is ", this.props.userData.roleID );
    
    this.setState({ rowData: row });
    let tempData = this.state.ContainerTableData.map((item) => {

      if (item.key === row.key) {
        return {
          ...item,
          isEdit: true
        }
      } else {
        return item;
      }
    })
    this.setState({ ContainerTableData: tempData });
  
  };

  handleCancelClick = (rowData, index) => {
    let tempData = this.state.ContainerTableData.map((item) => {
      if (item.key === rowData.key) {
        return this.state.rowData
      } else {
        return item;
      }
    })
    this.setState({ ContainerTableData: tempData });
  };

 
  render() {
    const userTableProps = {
      pagination: false,
      size: "default",
      bordered: true,
      scroll: { x: 200, y: window.innerHeight - 300 },
    };
   
    return (
      <div
        className="mandi-master-container"
        style={{   height: window.innerHeight - 85, marginTop: "-45px" }}
      
      >
        <div className="top-container">

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row-reverse",
              placeItems: "flex-end",
              marginRight: "25px",
              marginBottom: "10px",
            }}
          >
            <Search
              disabled={!this.state.isAdd}
              placeholder="Search"
              allowClear
              onChange={(e) => this.setState({ searchText: e.target.value })}
              style={{ width: "25%" }}
            />
            <Button
              className="add-master-button"
              style={{
                height: "25px",
                width: "70px",
                backgroundColor: "#0b133d",
                color: "white",
              }}
              disabled={!this.state.isAdd || !canUserEdit(
                this.props.userData,
                null,
                FeatureCodes.MS_CONTAINER_DTD_F_EDIT
              )}
              onClick={() => this.setModalVisibility(true)}
            >
              &#10010; Add
            </Button>
          </div>
        </div>

        <div>
        <div
        style={{
          marginLeft: "25px",
          marginTop: "-8px",
          fontSize: "14px",
          color: "red",
          display: "flex",
          float: "left",
        }}
      > {getUserMSRBACMessage(this.props.messageAccess,FeatureCodes.MS_CONTAINER_DTD_F_EDIT)}
      </div>
        <div
          style={{
            marginRight: "20px",
            marginTop: "-8px",
            fontSize: "15px",
            color: "#0B133D",
            display: "flex",
            float: "right",
          }}
        >Total number of records:&nbsp;
          <b>{this.state.ContainerTableData && this.setTableData().length}</b>
        </div>
        </div>
        
        <Spin
          spinning={this.state.isSpinnerOnLoad}
          size={"large"}
          tip="Loading..."
          style={{ alignSelf: "center", zIndex: 9999, marginTop: "20px" }}
        >
          <Modal
            className="add-master-modal"
            title={"Add Container DTD Freight"}
            onCancel={this.handleCancel}
            footer={false}
            visible={this.state.isModalVisible}
            destroyOnClose={true}
          >
            <div className="master-form-container">
              <Form
                layout="horizontal"
                //   onValuesChange={onFormLayoutChange}
                size={"small"}
              // onSubmit={submitTableRowData(this.state.newData)}
              >
                <div className="form-top-container1">
                <div
                    className="checkbox-group1"
                    style={{
                      display: "inline-block",
                      width: "41%",
                      marginRight: 40,
                    }}
                  >
                    <Form.Item label="Cargo">
                      <Select
                        showSearch
                        optionFilterProp="children"
                        className="remarks-select"
                        name="cargo"
                        defaultValue={this.state.newData.cargo}
                        value={this.state.newData.cargo}
                        onSelect={(value, option) =>
                          this.handleDropdownChange(
                            value,
                            this.state.newData,
                            "cargo",
                            -0,
                            option.props.children,
                            true
                          )
                        }
                      >
                        {this.state.cargoList &&
                          this.state.cargoList.length &&
                          this.state.cargoList.map((hubItem, idx) => (
                            <Option
                              key={hubItem.key}
                              value={hubItem.cargo_0}
                            >
                              {hubItem.cargo_0}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </div>

                  <div
                    className="checkbox-group1"
                    style={{
                      display: "inline-block",
                      width: "41%",
                      marginRight: 40,
                    }}
                  >
                    <Form.Item label="Plant Code">
                      <Select
                        showSearch
                        optionFilterProp="children"
                        className="remarks-select"
                        name="plant_code"
                        defaultValue={this.state.newData.plant_code}
                        value={this.state.newData.plant_code}
                        onSelect={(value, option) =>
                          this.handleDropdownChange(
                            value,
                            this.state.newData,
                            "plant_code",
                            -0,
                            option.props.children,
                            true
                          )
                        }
                      >
                        {this.state.plantCodeList &&
                          this.state.plantCodeList.length &&
                          this.state.plantCodeList.map((hubItem, idx) => (
                            <Option
                              key={hubItem.plant_code}
                              value={hubItem.plant_code}
                            >
                              {hubItem.plant_code} - {hubItem.plant}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </div>

                
                  <div
                    className="checkbox-group1"
                    style={{
                      display: "inline-block",
                      width: "41%",
                      marginRight: 40,
                    }}
                  >
                    <Form.Item label="First Mile Location">
                    <div>
                   
                      <Select
                        showSearch
                        mode="tags"
                        maxTagCount={1}
                        
                        name="first_mile_location"
                        defaultValue={this.state.newData.first_mile_location}
                        value={this.state.newData.first_mile_location}
                        onSelect={(value, option) =>
                          this.handleDropdownChange(
                            value,
                            this.state.newData,
                            "first_mile_location",
                            -0,
                            option.props.children,
                            true
                          )
                        }
                      >
                        {this.state.FirstMileLocList &&
                          this.state.FirstMileLocList.length &&
                          this.state.FirstMileLocList.map((hubItem, idx) => (
                            <Option
                            
                              key={hubItem.key}
                              value={hubItem.fm_location}
                            >
                              {hubItem.fm_location}
                            </Option>
                          ))}
                      </Select>
</div>
                    </Form.Item>
                  </div>

                  <div
                    className="checkbox-group1"
                    style={{
                      height: "45px",
                      display: "inline-block",
                      width: "41%",
                      marginRight: 40,
                    }}
                  >
                    <Form.Item label="Last Mile Location">
                      <Select
                        showSearch
                        mode="tags"
                        maxTagCount={1}
                        name="last_mile_location"
                        defaultValue={this.state.newData.last_mile_location}
                        value={this.state.newData.last_mile_location}
                        onSelect={(value, option) =>
                          this.handleDropdownChange(
                            value,
                            this.state.newData,
                            "last_mile_location",
                            -0,
                            option.props.children,
                            true
                          )
                        }
                      >
                        {this.state.locationList &&
                          this.state.locationList.length &&
                          this.state.locationList.map((hubItem, idx) => (
                            <Option
                              key={hubItem.varietylm_location_id}
                              value={hubItem.lm_location}
                            >
                              {hubItem.lm_location}
                            </Option>
                          ))}
                      </Select>

                    </Form.Item>
                  </div>

                  
                  <div
                    className="checkbox-group1"
                    style={{
                      height: "45px",
                      display: "inline-block",
                      width: "41%",
                      marginRight: 40,
                    }}
                  >
                    <Form.Item label="Rate as per Contract">
                      <Input
                        type="number"
                        style={{ marginRight: "2px" }}
                        maxLength={100}
                        min="0"
                        value={this.state.newData.rate_as_per_contract}
                        name="rate_as_per_contract"
                        onChange={(e) =>
                          this.handleChange(
                            e,
                            this.state.newData,
                            0,
                            "rate_as_per_contract",
                            true
                          )
                        }
                      />
                    </Form.Item>
                  </div>


                  <div
                    className="checkbox-group1"
                    style={{
                      height: "45px",
                      display: "inline-block",
                      width: "41%",
                      marginRight: 40,
                    }}
                  >
                    <Form.Item label="Rate 28 Mt Loadability">
                      <Input
                        type="number"
                        style={{ marginRight: "2px" }}
                        maxLength={100}
                        min="0"
                        value={this.state.newData.rate_28_mt_loadability}
                        name="rate_28_mt_loadability"
                        onChange={(e) =>
                          this.handleChange(
                            e,
                            this.state.newData,
                            0,
                            "rate_28_mt_loadability",
                            true
                          )
                        }
                      />
                    </Form.Item>
                  </div>


                  <div
                    className="checkbox-group1"
                    style={{
                      height: "45px",
                      display: "inline-block",
                      width: "41%",
                      marginRight: 40,
                    }}
                  >
                    <Form.Item label="Loading Rate including Dala Charges">
                      <Input
                        type="number"
                        style={{ marginRight: "2px" }}
                        maxLength={100}
                        min="0"
                        value={this.state.newData.loading_rate_including_dala_charge}
                        name="loading_rate_including_dala_charge"
                        onChange={(e) =>
                          this.handleChange(
                            e,
                            this.state.newData,
                            0,
                            "loading_rate_including_dala_charge",
                            true
                          )
                        }
                      />
                    </Form.Item>
                  </div>

                  <div
                  className="checkbox-group1"
                  style={{
                    height: "45px",
                    display: "inline-block",
                    width: "41%",
                    marginRight: 40,
                  }}
                >
                  <Form.Item label="Unloading Charges">
                    <Input
                      type="number"
                      style={{ marginRight: "2px" }}
                      maxLength={100}
                      min="0"
                      value={this.state.newData.unloading_charges}
                      name="unloading_charges"
                      onChange={(e) =>
                        this.handleChange(
                          e,
                          this.state.newData,
                          0,
                          "unloading_charges",
                          true
                        )
                      }
                    />
                  </Form.Item>
                </div>


                  <div
                    className="checkbox-group1"
                    style={{
                      height: "45px",
                      display: "inline-block",
                      width: "41%",
                      marginRight: 40,
                    }}
                  >
                    <Form.Item label="Mamool Cost">
                      <Input
                        type="number"
                        style={{ marginRight: "2px" }}
                        maxLength={100}
                        min="0"
                        value={this.state.newData.mamool_cost}
                        name="mamool_cost"
                        onChange={(e) =>
                          this.handleChange(
                            e,
                            this.state.newData,
                            0,
                            "mamool_cost",
                            true
                          )
                        }
                      />
                    </Form.Item>
                  </div>

                  <div
                  className="form-top-container"
                  style={{
                    display: "inline-block",
                    width: "41%",
                    marginRight: 7,
                  }}
                >
                  <Form.Item label="Active">
                    <Radio.Group
                      name="is_active"
                      value={this.state.newData.is_active ? 1 : 0}
                      onChange={(e, option) =>
                        this.handleDropdownChange(
                          e.target.value,
                          this.state.newData,
                          "is_active",
                          -0,
                          "",
                          true
                        )
                      }
                    >
                      <Radio value={1}>Yes</Radio>
                      <Radio value={0}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </div>
              
                </div>

                <div className="form-bottom-container" style={{ marginTop: "10px" }}>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={this.state.isSubmitted}
                      onClick={() =>
                        this.submitTableRowData(this.state.newData, 0, true)
                      }
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </Modal>

          <div
          className="table-container"
          style={{ maxHeight: "450px", margin: "6px 0px" }}
        >
          <Table
            {...userTableProps}
            columns={[
              {
                  title: "Cargo",
                  // fixed:true,
                  dataIndex: "cargo",
                  key: "cargo",
                  width: 125,
                  filters: this.createFilters("cargo"),
                  onFilter: (value, record) =>
                    record.cargo.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.cargo.localeCompare(b.cargo),
                  render: (record, rowRecord) => {
                    return (
                      <>
                        <div
                          style={{ marginLeft: "-15px", marginRight: "-10px" }}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            disabled={true}//{!rowRecord.isEdit}
                            className="remarks-select"
                            name="cargo"
                            defaultValue={rowRecord.cargo}
                            value={rowRecord.cargo}
                            onSelect={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "cargo",
                                -0,
                                option.props.children,
                                false
                              )
                            }
                          >
                            {this.state.cargoList &&
                              this.state.cargoList.length &&
                              this.state.cargoList.map((hubItem, idx) => (
                                <Option
                                  key={hubItem.key}
                                  value={hubItem.cargo_0}
                                >
                                  {hubItem.cargo_0}
                                </Option>
                              ))}
                          </Select>
                        </div>
                      </>
                    );
                  },
                },
              {
                title: "Plant Code",
                dataIndex: "plant_code",
                key: "plant_code",
                width: 100,
                filters: this.createFilters("plant_code"),
                onFilter: (value, record) =>
                  record.plant_code.includes(value),
                sortDirections: ["descend", "ascend"],
                sorter: (a, b) => a.plant_code.localeCompare(b.plant_code),
                render: (record, rowRecord) => {
                  return (
                    <>
                      <div
                        style={{ marginLeft: "-15px", marginRight: "-10px" }}
                      >
                        <Select
                          showSearch
                          optionFilterProp="children"
                          disabled={true}//{!rowRecord.isEdit}
                          className="remarks-select"
                          name="plant_code"
                          defaultValue={rowRecord.plant_code}
                          value={rowRecord.plant_code}
                          onSelect={(value, option) =>
                            this.handleDropdownChange(
                              value,
                              rowRecord,
                              "plant_code",
                              -0,
                              option.props.children,
                              false
                            )
                          }
                        >
                        {this.state.plantCodeList &&
                          this.state.plantCodeList.length &&
                          this.state.plantCodeList.map((hubItem, idx) => (
                            <Option
                              key={hubItem.plant_code}
                              value={hubItem.plant_code}
                            >
                              {hubItem.plant_code}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </>
                  );
                },
              },
              {
                title: "Plant",
                dataIndex: "plant",
                key: "plant",
                width: 155,
                filters: this.createFilters("plant"),
                onFilter: (value, record) =>
                  record.plant.includes(value),
                sortDirections: ["descend", "ascend"],
                sorter: (a, b) => a.plant.localeCompare(b.plant),
                render: (record, rowRecord) => {
                  return (
                    <>
                      <div
                        style={{ marginLeft: "-15px", marginRight: "-10px" }}
                      >
                        <Select
                          showSearch
                          optionFilterProp="children"
                          disabled={true}//{!rowRecord.isEdit}
                          className="remarks-select"
                          name="plant"
                          defaultValue={rowRecord.plant}
                          value={rowRecord.plant}
                          onSelect={(value, option) =>
                            this.handleDropdownChange(
                              value,
                              rowRecord,
                              "plant",
                              -0,
                              option.props.children,
                              false
                            )
                          }
                        >
                          {this.state.plantCodeList &&
                            this.state.plantCodeList.length &&
                            this.state.plantCodeList.map((hubItem, idx) => (
                              <Option
                                key={hubItem.plant_code}
                                value={hubItem.plant_code}
                              >
                                {hubItem.plant}
                              </Option>
                            ))}
                        </Select>
                      </div>
                    </>
                  );
                },
              },
              {
                title: "First Mile Location",
                dataIndex: "first_mile_location",
                key: "first_mile_location",
                width: 140,
                filters: this.createFilters("first_mile_location"),
                onFilter: (value, record) =>
                  record.first_mile_location.includes(value),
                sortDirections: ["descend", "ascend"],
                sorter: (a, b) => a.first_mile_location.localeCompare(b.first_mile_location),
                render: (record, rowRecord) => {
                  return (
                    <>
                      <div
                        style={{ marginLeft: "-15px", marginRight: "-10px" }}
                      >
                        <Select
                          showSearch
                          optionFilterProp="children"
                          disabled={true}//{!rowRecord.isEdit}
                          className="remarks-select"
                          name="first_mile_location"
                          defaultValue={rowRecord.first_mile_location}
                          value={rowRecord.first_mile_location}
                          onSelect={(value, option) =>
                            this.handleDropdownChange(
                              value,
                              rowRecord,
                              "first_mile_location",
                              -0,
                              option.props.children,
                              false
                            )
                          }
                        >
                        {this.state.locationList &&
                          this.state.locationList.length &&
                          this.state.locationList.map((hubItem, idx) => (
                            <Option
                              key={hubItem.key}
                              value={hubItem.fm_location}
                            >
                              {hubItem.fm_location}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </>
                  );
                },
              },
              {
                  title: "Last Mile Location",
                  dataIndex: "last_mile_location",
                  key: "last_mile_location",
                  width: 250,
                  filters: this.createFilters("last_mile_location"),
                  onFilter: (value, record) =>
                    record.last_mile_location.includes(value),
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.last_mile_location.localeCompare(b.last_mile_location),
                  render: (record, rowRecord) => {
                    return (
                      <>
                        <div
                          style={{ marginLeft: "-15px", marginRight: "-10px" }}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            disabled={true}//{!rowRecord.isEdit}
                            className="remarks-select"
                            name="last_mile_location"
                            defaultValue={rowRecord.last_mile_location}
                            value={rowRecord.last_mile_location}
                            onSelect={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "last_mile_location",
                                -0,
                                option.props.children,
                                false
                              )
                            }
                          >
                            {this.state.locationList &&
                              this.state.locationList.length &&
                              this.state.locationList.map((hubItem, idx) => (
                                <Option
                                  key={hubItem.key}
                                  value={hubItem.lm_location}
                                >
                                  {hubItem.lm_location}
                                </Option>
                              ))}
                          </Select>
                        </div>
                      </>
                    );
                  },
                },
                {
                  key: "rate_as_per_contract",
                  title: "Rate as per Contract",
                  dataIndex: "rate_as_per_contract",
                  width: 120,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.rate_as_per_contract - b.rate_as_per_contract,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            type="number"
                            disabled={!rowRecord.isEdit}
                            style={{ marginRight: "2px" }}
                            maxLength={100}
                            min="0"
                            value={rowRecord.rate_as_per_contract}
                            name="rate_as_per_contract"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "rate_as_per_contract",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },
              {
                key: "rate_28_mt_loadability",
                title: "Rate 28 Mt loadability",
                dataIndex: "rate_28_mt_loadability",
                width: 120,
                sortDirections: ["descend", "ascend"],
                sorter: (a, b) => a.rate_28_mt_loadability - b.rate_28_mt_loadability,
                render: (record, rowRecord, index) => {
                  return (
                    <>
                      <div style={{ marginLeft: "-20px" }}>
                        <Input
                          type="number"
                          disabled={!rowRecord.isEdit}
                          style={{ marginRight: "2px" }}
                          maxLength={100}
                          min="0"
                          value={rowRecord.rate_28_mt_loadability}
                          name="rate_28_mt_loadability"
                          onChange={(e) =>
                            this.handleChange(
                              e,
                              rowRecord,
                              index,
                              "rate_28_mt_loadability",
                              false
                            )
                          }
                        />
                      </div>
                    </>
                  );
                },
              },
              {
                  key: "loading_rate_including_dala_charge",
                  title: "Loading Rate Including Dala Charges",
                  dataIndex: "loading_rate_including_dala_charge",
                  width: 120,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.loading_rate_including_dala_charge - b.loading_rate_including_dala_charge,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            type="number"
                            disabled={!rowRecord.isEdit}
                            style={{ marginRight: "2px" }}
                            maxLength={100}
                            min="0"
                            value={rowRecord.loading_rate_including_dala_charge}
                            name="loading_rate_including_dala_charge"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "loading_rate_including_dala_charge",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },
                {
                  key: "unloading_charges",
                  title: "Unloading charges",
                  dataIndex: "unloading_charges",
                  width: 120,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.unloading_charges - b.unloading_charges,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            type="number"
                            disabled={!rowRecord.isEdit}
                            style={{ marginRight: "2px" }}
                            maxLength={100}
                            min="0"
                            value={rowRecord.unloading_charges}
                            name="unloading_charges"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "unloading_charges",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },
                {
                  key: "mamool_cost",
                  title: "Mamool Cost",
                  dataIndex: "mamool_cost",
                  width: 120,
                  sortDirections: ["descend", "ascend"],
                  sorter: (a, b) => a.mamool_cost - b.mamool_cost,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-20px" }}>
                          <Input
                            type="number"
                            disabled={!rowRecord.isEdit}
                            style={{ marginRight: "2px" }}
                            maxLength={100}
                            min="0"
                            value={rowRecord.mamool_cost}
                            name="mamool_cost"
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                rowRecord,
                                index,
                                "mamool_cost",
                                false
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  },
                },
                {
                  key: "is_active",
                  title: "Active",
                  dataIndex: "is_active",
                  width: 85,
                  filters: this.createFilters("is_active"),
                  // filters: [{ text: "Yes", value: 1 }, { text: "No", value: 0 }],
                  onFilter: (value, record) =>
                    record.is_active === value,
                  render: (record, rowRecord, index) => {
                    return (
                      <>
                        <div style={{ marginLeft: "-15px", marginRight: "-40px" }}>
                          <Select
                            showSearch
                            optionFilterProp="children"
                            disabled={!rowRecord.isEdit}
                            defaultValue={
                              rowRecord.is_active === 1 ? "Yes" : "No"
                            }
                            value={
                              rowRecord.is_active === 1 ? "Yes" : "No"
                            }
                            onChange={(value, option) =>
                              this.handleDropdownChange(
                                value,
                                rowRecord,
                                "is_active",
                                index,
                                option.props.children,
                                false
                              )
                            }
                            style={{ width: "70%" }}
                          >
                            <Option key={1} value={1}>
                              {"Yes"}
                            </Option>
                            <Option key={0} value={0}>
                              {"No"}
                            </Option>
                          </Select>
                        </div>
                      </>
                    );
                  },
                },

              {
                title: "Action",
                dataIndex: "Submit",
                key: "Submit",
                width: 130,
                visibility: false,
                render: (record, rowRecord, index) => {
                  return (
                    <>
                      <div style={{ display: "inline-flex", width: "100%" }}>
                        <Button
                        className={!canUserEdit(
                          this.props.userData,
                          null,
                          FeatureCodes.MS_CONTAINER_DTD_F_EDIT
                        ) ? "disabledBtnClass" : "btnClass"}
                          disabled={!canUserEdit(
                            this.props.userData,
                            null,
                            FeatureCodes.MS_CONTAINER_DTD_F_EDIT
                          )}
                          type="primary"
                          size={"small"}
                          style={{
                            marginLeft: 5,
                            alignSelf: "center",
                            padding: "0px 0px 2px",
                            height: 31,
                            backgroundColor: "#20346a",
                            color: "white",
                            borderRadius: 15,
                          }}
                          block
                          htmlType="submit"
                          onClick={() =>
                            rowRecord.isEdit
                              ? this.submitTableRowData(rowRecord, index, false)
                              : this.handleEditChange(rowRecord, index)
                          }
                        >
                          {" "}
                          {rowRecord.isEdit ? "Submit" : "Edit"}{" "}
                        </Button>


                        <Button
                          className={!rowRecord.isEdit ? "disabledBtnClass" : "btnClass"}
                          type="primary"
                          size={"small"}
                          style={{
                            marginLeft: 5,
                            alignSelf: "center",
                            color: "white",
                            borderRadius: 15,
                            padding: "0px 0px 2px",
                            height: 31,
                            backgroundColor: "#20346a",
                          }}
                          block
                          htmlType="submit"
                          disabled={!rowRecord.isEdit}
                          onClick={() => this.handleCancelClick(rowRecord, index)
                          }
                        >
                          Cancel
                        </Button>

                      </div>
                    </>
                  );
                },
              },
            ]}
            dataSource={this.setTableData()}
          />
        </div>

        </Spin>
       
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
  messageAccess: selectMSMessageRBAC,
});

export default connect(mapStateToProps)(ContainerDTDFreightMaster);

// export default ContainerDTDFreightMaster;
