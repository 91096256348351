import { UserActionTypes } from "./user.types";

const INITIAL_STATE = {
  accessToken: null,
  refreshToken: null,
  userType: null,
  userName: null,
  isLogged: false,
  userRoleID: null,
  userTerritories: [],
  locationFeatures: [],
  nonLocationFeatures: [],
  userFeatures: [],
  allUsers: [],
  allBranches: [],
  userRoleData: [],
  reportData: [],
  masterScreenRBACMsg:[],
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.USER_LOGGED_STATUS:
      return {
        ...state,
        isLogged: action.payload,
      };

    case UserActionTypes.SET_ACCESS_TOKEN:
      return { ...state, accessToken: action.payload };

    case UserActionTypes.SET_REFRESH_TOKEN:
      return { ...state, refreshToken: action.payload };

    case UserActionTypes.SET_USER_RBAC_DETAILS:
      return {
        ...state,
        userRoleID: action.roleID,
        userName: action.userName,
        userTerritories: action.userTerritories,
        locationFeatures: action.locationFeatures,
        nonLocationFeatures: action.nonLocationFeatures,
        userFeatures: action.userFeatures,
      };

      case UserActionTypes.SET_USER_RBAC_MS_MESSAGE:
      return {
        ...state,
        masterScreenRBACMsg: action.payload,
      };

    case UserActionTypes.SET_ALL_USERS:
      return {
        ...state,
        allUsers: action.payload,
      };

    case UserActionTypes.SET_ALL_BRANCHES:
      return {
        ...state,
        allBranches: action.payload,
      };
    case UserActionTypes.SET_USER_ROLE:
      return {
        ...state,
        userRoleData: action.payload,
      };
    case UserActionTypes.SET_REPORT:
      console.log("Ivide ethi*************************************************8");
      return {
        ...state,
        reportData: action.payload,
      };


    default:
      return state;
  }
};

export default userReducer;
