import React from "react";
import "./numeric-input.styles.css";

import { InputNumber } from "antd";

/**
 * @description Custom numeric input component used in arrivals entry card component.
 * @component
 * @property {string} unitText Unit
 * @property {function} setParentState Callback to change the value for [typeVariety]:{[namevariety] :'value'} in the parent state
 * @property {string} typeVariety Parent object in state (Used in setParentState)
 * @property {string} nameVariety Key in parent object (Used in setParentState)
 * @property {string} placeHolder Placeholder
 * @property {number} minRange Minimum value which can be entered in InputNumber
 * @property {number} maxRange Maximum value which can be entered in InputNumber
 * @property {string} qualityName Good Average or Poor
 * @property {function} setQualityStates Callback to set state of quality in parent state
 * @property {number} defaultValue Defaut value
 * @property {number} index Index of competitor if multiple competitor exixts(Used in setParentState)
 */
const NumericInput = (props) => {
  const {
    unitText,
    setParentState,
    typeVariety,
    nameVariety,
    placeHolder,
    minRange,
    maxRange,
    qualityName,
    setQualityStates,
    defaultValue,
    index,
  } = props;

  return (
    <div className="input-field-container">
      <InputNumber
        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        min={minRange ? minRange : 0}
        max={maxRange ? maxRange : Infinity}
        placeholder={placeHolder}
        onChange={(value) => {
          setParentState
            ? setParentState(typeVariety, nameVariety, value, index)
            : console.log("");
          if (setQualityStates) {
            setQualityStates(qualityName.toLowerCase(), value);
          }
        }}
        defaultValue={defaultValue}
      />
      <div className="right-side-element">{unitText}</div>
    </div>
  );
};

export default NumericInput;
