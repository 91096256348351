import "./chart-consolidated.styles.css";

import React, { Component } from "react";
import "../../pages/summary/summary.styles.css";
import moment from "moment";

import { DatePicker, Table, Button, message, Spin, Progress } from "antd";

import API, { baseURL } from "../../api";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { setAuditModalVisibility } from "../../redux/summary/summary.actions";
import { selectIsModalVisible } from "../../redux/summary/summary.selectors";

import { setSummaryVisibility } from "../../redux/arrival-entry/arrival-entry.actions";

import CustomSelectSummary from "../../components/custom-select-summary/custom-select-summary.component";

import Axios from "axios";

const FileDownLoad = require("js-file-download");

const { RangePicker } = DatePicker;

/**
 * @component
 * @description Reusable component to display table header.
 * @property {string} title Header title
 * @property {string} subtitle Header subtitle
 * @property {string} value Header value
 * @memberof ConsolidatedChart
 */
const TableNewHeader = (props) => {
  const { title, subtitle, value } = props;
  return (
    <div className="col-container">
      <div style={{ color: "#bad2f8", fontSize: "12px", fontWeight: "normal" }}>
        {title}
      </div>
      <div className="bottom-container">
        <h1>{value}</h1>
        <div
          style={{
            color: "#bad2f8",
            fontSize: "12px",
            fontWeight: "normal",
            marginTop: "0px",
          }}
        >
          {subtitle}
        </div>
      </div>
    </div>
  );
};

//#region Summary Table Props
const tableProps = {
  pagination: false,
  size: "default",
  bordered: true,
  scroll: { x: 200, y: window.innerHeight - 330 },
};
//#endregion
/**
 *
 * @description Consolidated report container in Report page.
 * @component
 * @extends {React.Component}
 */
class ConsolidatedChart extends React.Component {
  state = {
    selectedBranch: "",
    selectedVariety: "",
    selectedClaimType: "",
    selectedDivision: "",
    dropdownData: {
      branches: [],
      varieties: [],
    },
    lastClicked: "",
    selectedClaimTypeVal: "",
    selectedDivisionVal: "",
    selectedBranchVal: "",
    selectedVarietyVal: "",
    startDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    spinnerOn: true,
    isSpinnerOn: false,
    isSpinnerOnProgress: false,
    ProgressClicked: false,
    tableData: [],
    sumArrivalQty: 0,
    sumRecommendedQty: 0,
    sumPlannedQty: 0,
    sumPOQty: 0,
    sumArrivalPrice: 0,
    sumRecommendedPrice: 0,
    sumPlannedPrice: 0,
    sumPOPrice: 0,
  };

  /**
   * @function
   * @description Handle date filter selection.
   * @param {dateRange} dates
   * @param {string[]} dateStrings Array of strings consisting of selected date range.
   * @memberof ConsolidatedChart
   */
  onChange = (dates, dateStrings) => {
    this.setState(
      (prevState) => ({ startDate: dateStrings[0], endDate: dateStrings[1] }),
      () =>
        this.setVarietyTableData(
          this.state.selectedClaimType,
          this.state.selectedDivision,
          this.state.selectedBranch,
          this.state.selectedVariety,
          this.state.startDate,
          this.state.endDate
        )
    );
  };

  /**
   * @function
   * @description Retieve and store consolidated table data from API.
   * @param {string} claimType Claim type (Claim/No Claim)
   * @param {string} division Division (ABD/FBD/NCDEX)
   * @param {string} branchId Branch ID
   * @param {string} hubId Hub ID
   * @param {string} varietyId Variety ID
   * @param {string} startDate Start date of date range
   * @param {string} endDate End date of date range
   * @memberof ConsolidatedChart
   */
  setVarietyTableData(
    claimType,
    division,
    branchId,
    varietyId,
    startDate,
    endDate
  ) {
    this.setState({ spinnerOn: true });

    const tableParams = {
      claim_type: claimType,
      division: division,
      branch_id: branchId,
      variety_id: varietyId,
      start_date: startDate,
      end_date: endDate,
    };

    API.get("/reports/consolidated_report", {
      params: tableParams,
    }).then((response) => {
      if (response.data.status) {
        let sumArrivalQtyData = 0.0,
          sumRecommendedQtyData = 0.0,
          sumPlannedQtyData = 0.0,
          sumPOQtyData = 0.0,
          arrivalPriceData = 0.0,
          recommendedPriceData = 0.0,
          plannedPriceData = 0.0,
          poPriceData = 0.0;
        const data =
          response.data.data.result_list &&
          response.data.data.result_list.map((item) => {
            sumArrivalQtyData += item.arrival_quantity
              ? item.arrival_quantity
              : 0;
            sumRecommendedQtyData += item.recommended_quantity
              ? item.recommended_quantity
              : 0;
            sumPlannedQtyData += item.planned_quantity
              ? item.planned_quantity
              : 0;
            sumPOQtyData += item.po_quantity ? item.po_quantity : 0;
            arrivalPriceData =
              arrivalPriceData +
              (item.arrival_price ? item.arrival_price : 0) *
                item.arrival_quantity;
            recommendedPriceData =
              recommendedPriceData +
              (item.recommended_price ? item.recommended_price : 0) *
                item.recommended_quantity;
            plannedPriceData =
              plannedPriceData +
              (item.planned_price ? item.planned_price : 0) *
                item.planned_quantity;
            poPriceData =
              poPriceData +
              (item.po_price ? item.po_price : 0) * item.po_quantity;

            return {
              key: item.territory_id,
              territoryName: item.territory,
              arrivalQuantity: item.arrival_quantity
                ? // ? Number(item.arrival_quantity.toFixed(2))
                  Math.round(item.arrival_quantity)
                : 0,
              recommendedQuantity: item.recommended_quantity
                ? // ? Number(item.recommended_quantity.toFixed(2))
                  Math.round(item.recommended_quantity)
                : 0,
              plannedQuantity: item.planned_quantity
                ? Math.round(item.planned_quantity)
                : 0,
              poQuantity: item.po_quantity ? Math.round(item.po_quantity) : 0,
              arrivalPrice: item.arrival_price
                ? Math.round(item.arrival_price)
                : 0,
              recommendedPrice: item.recommended_price
                ? Math.round(item.recommended_price)
                : 0,
              plannedPrice: item.planned_price
                ? Math.round(item.planned_price)
                : 0,
              poPrice: item.po_price ? Math.round(item.po_price) : 0,
            };
          });
        this.setState((prevState) => ({
          ...prevState,
          tableData: data,
          sumArrivalQty: Math.round(sumArrivalQtyData),
          sumRecommendedQty: Math.round(sumRecommendedQtyData),
          sumPlannedQty: Math.round(sumPlannedQtyData),
          sumPOQty: Math.round(sumPOQtyData),
          sumArrivalPrice:
            response.data.data.result_list.length !== 0 ||
            arrivalPriceData !== 0
              ? Math.round(
                  arrivalPriceData /
                    (sumArrivalQtyData !== 0 ? sumArrivalQtyData : 1)
                )
              : 0,
          sumRecommendedPrice:
            response.data.data.result_list.length !== 0 ||
            recommendedPriceData !== 0
              ? Math.round(
                  recommendedPriceData /
                    (sumRecommendedQtyData !== 0 ? sumRecommendedQtyData : 1)
                )
              : 0,
          sumPlannedPrice:
            response.data.data.result_list.length !== 0 ||
            plannedPriceData !== 0
              ? Math.round(
                  plannedPriceData /
                    (sumPlannedQtyData !== 0 ? sumPlannedQtyData : 1)
                )
              : 0,
          sumPOPrice:
            response.data.data.result_list.length !== 0 || poPriceData !== 0
              ? Math.round(
                  poPriceData / (sumPOQtyData !== 0 ? sumPOQtyData : 1)
                )
              : 0,
          spinnerOn: false,
        }));
      } else {
        //if data is not present
        const data = [];
        this.setState((prevState) => ({
          ...prevState,
          tableData: data,
          sumArrivalQty: 0,
          sumRecommendedQty: 0,
          sumPlannedQty: 0,
          sumPOQty: 0,
          sumArrivalPrice: 0,
          sumRecommendedPrice: 0,
          sumPlannedPrice: 0,
          sumPOPrice: 0,
          spinnerOn: false,
        }));
      }
    });
  }

  componentDidMount() {
    //first initialization branch details
    //#region

    const params = {
      table_type: "reports",
    };
    API.get("/branches", { params: params }).then((response) => {
      // console.log("The response Initial", response.data.data);
      API.get("/varieties").then((varietyResponse) => {
        // console.log("Variety response", varietyResponse.data.data);

        this.setState(
          (prevState) => ({
            ...prevState,
            dropdownData: {
              branches: response.data.data.branches,
              regions: null,
              hubs: null,
              mandis: null,
              varieties: varietyResponse.data.data.filter(
                (value, index, array) =>
                  array.findIndex(
                    (obj) => obj.variety_id === value.variety_id
                  ) === index
              ),
            },
            isSpinnerOnTable: false,
          }),
          () =>
            this.setVarietyTableData(
              this.state.selectedClaimType,
              this.state.selectedDivision,
              this.state.selectedBranch,
              this.state.selectedVariety,
              this.state.startDate,
              this.state.endDate
            )
        );
      });

      //For table data
    });

    //#endregion
  }

  render() {
    //type handling
    //#region
    /**
     *
     * @description Handle type filter selection.
     * @param {string} value Selected arrival type.
     * @memberof EfficiencyReport
     */
    const handleClaimTypeSelect = (value, props) => {
      this.setState(
        (prevState) => ({
          ...prevState,
          selectedClaimTypeVal: value,
          selectedClaimType: props.key,
        }),
        () => {
          this.setVarietyTableData(
            this.state.selectedClaimType,
            this.state.selectedDivision,
            this.state.selectedBranch,
            this.state.selectedVariety,
            this.state.startDate,
            this.state.endDate
          );
        }
      );
    };
    //#endregion

    //division handling
    //#region
    /**
     *
     * @description Handle division filter selection.
     * @param {string} value Selected division.
     * @memberof EfficiencyReport
     */
    const handleDivisionSelect = (value, props) => {
      this.setState(
        (prevState) => ({
          ...prevState,
          selectedDivisionVal: value,
          selectedDivision: props.key,
        }),
        () => {
          this.setVarietyTableData(
            this.state.selectedClaimType,
            this.state.selectedDivision,
            this.state.selectedBranch,
            this.state.selectedVariety,
            this.state.startDate,
            this.state.endDate
          );
        }
      );
    };
    //#endregion

    //variety handling
    //#region
    //filtering variety data to name and key
    let varietyId, varietyName;
    if (this.state.dropdownData.varieties) {
      varietyId = this.state.dropdownData.varieties.map(
        (item) => item.variety_id
      );
      varietyName = this.state.dropdownData.varieties.map(
        (item) => item.variety_name
      );
    }

    /**
     *
     * @description Handle variety filter selection.
     * @param {string} value Selected variety value.
     * @param {object} props Selected variety object (key and value).
     * @memberof ConsolidatedChart
     */
    const handleVarietySelect = (value, props) => {
      this.setState(
        (prevState) => ({
          ...prevState,
          selectedVarietyVal: value,
          selectedVariety: value === "All" ? "" : props.key,
        }),
        () =>
          this.setVarietyTableData(
            this.state.selectedClaimType,
            this.state.selectedDivision,
            this.state.selectedBranch,
            this.state.selectedVarietyVal === "All" ? null : props.key,
            this.state.startDate,
            this.state.endDate
          )
      );
    };
    //#endregion

    //branch handling
    //#region
    //filtering branch data to name and key
    let branchId, branchName;
    if (this.state.dropdownData.branches) {
      branchId = this.state.dropdownData.branches.map(
        (item) => item.territory_id
      );
      branchName = this.state.dropdownData.branches.map(
        (item) => item.territory_name
      );
    }

    /**
     *
     * @description Handle branch filter selection.
     * @param {string} value Selected branch value.
     * @param {object} props Selected branch object (key and value).
     * @memberof ConsolidatedChart
     */
    const handleBranchSelect = (value, props) => {
      API.get(
        `/hubs?table_type=transaction&mapping=mapped&branch_id=${props.key}`
      ).then((response) => {
        API.get(`/regions?table_type=transaction&branch_id=${props.key}`).then(
          (regionResponse) => {
            this.setState(
              (prevState) => ({
                ...prevState,
                selectedBranch: value === "All" ? "" : props.key,
                selectedRegion: "",
                selectedHub: "",
                dropdownData: {
                  ...prevState.dropdownData,
                  regions:
                    value === "All" ? null : regionResponse.data.data.regions,
                  hubs: value === "All" ? null : response.data.data.hubs,
                  mandis: null,
                },
                lastClicked: value === "All" ? "BranchAll" : "Branch",
                selectedBranchVal: value,
                selectedRegionVal: this.state.defaultRegion,
                selectedHubVal: this.state.defaultHub,
                selectedMandiVal: this.state.defaultMandi,
              }),
              () =>
                this.setVarietyTableData(
                  this.state.selectedClaimType,
                  this.state.selectedDivision,
                  value === "All" ? "" : props.key,
                  this.state.selectedVariety,
                  this.state.startDate,
                  this.state.endDate
                )
            );
          }
        );
      });
    };
    //#endregion

    return (
      <div
        className="consolidated-container"
        style={{ height: window.innerHeight - 131, backgroundColor: "#f7f9fb" }}
      >
        <Spin spinning={this.state.isSpinnerOn} tip="Loading...">
          <Spin
            spinning={this.state.isSpinnerOnProgress}
            tip="Please wait while the file is being Downloaded..."
          >
            <div className="summary">
              <div className="download-button-container">
                {this.state.ProgressClicked && (
                  <Progress
                    type="circle"
                    percent={this.state.downloaddata}
                    width={37}
                    strokeColor={{ "0%": "#108ee9", "100%": "#87d068" }}
                    style={{
                      paddingRight: "5px",
                    }}
                    trailColor="#003285"
                  />
                )}
                {
                  <Button
                    onClick={() => {
                      this.setState({ isSpinnerOnProgress: true });
                      this.setState({ downloaddata: 0 });
                      this.setState({ ProgressClicked: true });
                      const downloadParams = {
                        download: true,
                        start_date: this.state.startDate,
                        end_date: this.state.endDate,
                        claim_type: this.state.selectedClaimType,
                        division: this.state.selectedDivision,
                        branch_id: this.state.selectedBranch,
                        variety_id: this.state.selectedVariety,
                      };

                      // API.get(`/reports/consolidated_report`, {

                      //   params: downloadParams,
                      // })
                      //   .then((fileresponse) => {
                      //     if (fileresponse.data.status) {

                      //#region file download
                      Axios({
                        url: `${baseURL}/reports/consolidated_report`,
                        method: "GET",
                        params: downloadParams,
                        responseType: "blob",
                        onDownloadProgress: (progressEvent) => {
                          let progress = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                          );
                          this.setState({ downloaddata: progress });
                        },
                      })
                        .then((response) => {
                          if (response.status !== 204) {
                            FileDownLoad(
                              response.data,
                              `Consolidated_Report_${new Date().toLocaleDateString()}.xlsx`
                            );
                            //this.setState({isSpinnerOn:false});
                            this.setState({ isSpinnerOnProgress: false });
                            setTimeout(
                              function () {
                                this.setState({ ProgressClicked: false });
                              }.bind(this),
                              5000
                            );
                          } else {
                            message.warning("File has no data.");
                            this.setState({
                              isSpinnerOnProgress: false,
                              ProgressClicked: false,
                            });
                          }
                        })
                        .catch((err) => {
                          message.error("File cannot be downloaded");
                          this.setState({
                            isSpinnerOnProgress: false,
                            ProgressClicked: false,
                          });
                        });
                      //#endregion
                      //       } else {
                      //         message.warning("File has no data");
                      //         this.setState({isSpinnerOnProgress:false ,ProgressClicked:false});
                      //       }
                      //     })
                      //     .catch((err) => {
                      //       message.error("Error downloading file");
                      //       this.setState({isSpinnerOnProgress:false ,ProgressClicked:false});
                      //     });
                    }}
                    type="primary"
                    shape="round"
                    icon="download"
                    size="large"
                  >
                    <div style={{ float: "right" }}>
                      <div style={{ marginTop: "-7px" }}>Download</div>
                      <div
                        style={{
                          fontSize: "11px",
                          lineHeight: "7px",
                          color: "#b4c8dc",
                        }}
                      >
                        .xlsx report
                      </div>
                    </div>
                  </Button>
                }
              </div>

              <div
                className="filter-container"
                style={{ display: "flex", justifyContent: "space-around" }}
              >
                <div
                  className="summary-filters"
                  style={{
                    width: "975px",
                  }}
                >
                  <div className="filter-select">
                    <div className="filter-select-title">Date Range</div>
                    <RangePicker
                      defaultValue={[moment().subtract(7, "days"), moment()]}
                      ranges={{
                        Today: [moment(), moment()],
                        Yesterday: [
                          moment().subtract(1, "days"),
                          moment().subtract(1, "days"),
                        ],
                        "Past 7 days": [moment().subtract(7, "days"), moment()],
                        "This Month": [moment().startOf("month"), moment()],
                        "Last Month": [
                          moment()
                            .startOf("month")
                            .subtract(1, "days")
                            .startOf("month"),
                          moment().startOf("month").subtract(1, "days"),
                        ],
                      }}
                      onChange={this.onChange}
                    />
                  </div>

                  <div className="filter-select">
                    <div className="filter-select-title">Variety</div>
                    <CustomSelectSummary
                      placeholder={
                        this.state.selectedVarietyVal === ""
                          ? "Select Variety"
                          : this.state.selectedVarietyVal
                      }
                      allEnabled
                      disabled={false}
                      list={varietyName}
                      keyList={varietyId}
                      handleSelect={handleVarietySelect}
                    />
                  </div>
                  <div className="filter-select">
                    <div className="filter-select-title">Branch</div>
                    <CustomSelectSummary
                      placeholder={
                        this.state.lastClicked === ""
                          ? "Select Branch"
                          : this.state.lastClicked === "BranchAll"
                          ? "All"
                          : this.state.selectedBranchVal
                      }
                      allEnabled
                      disabled={this.state.dropdownData.branches == null}
                      list={branchName}
                      keyList={branchId}
                      handleSelect={handleBranchSelect}
                    />
                  </div>

                  <div className="filter-select">
                    <div className="filter-select-title">Claim Type</div>
                    <CustomSelectSummary
                      //onSelect={setBuyerType}
                      connectState
                      placeholder={
                        this.state.selectedClaimTypeVal === ""
                          ? "Select Claim Type"
                          : this.state.selectedClaimTypeVal
                      }
                      handleSelect={handleClaimTypeSelect}
                      list={["All", "Claim", "No Claim"]}
                      keyList={["", 1, 2]}
                    />
                  </div>
                  <div className="filter-select">
                    <div className="filter-select-title">Division</div>
                    <CustomSelectSummary
                      connectState
                      placeholder={
                        this.state.selectedDivisionVal === ""
                          ? "Select Division"
                          : this.state.selectedDivisionVal
                      }
                      handleSelect={handleDivisionSelect}
                      list={["All", "ABD", "FBD", "NCDEX"]}
                      keyList={["", 1, 2, 3]}
                    />
                  </div>
                </div>
              </div>
            </div>

            <VarietyTable
              spinnerOn={this.state.spinnerOn}
              selectedBranchVal={this.state.selectedBranchVal}
              selectedVarietyVal={this.state.selectedVarietyVal}
              lastClicked={this.state.lastClicked}
              data={this.state.tableData}
              sumArrivalQty={this.state.sumArrivalQty}
              sumRecommendedQty={this.state.sumRecommendedQty}
              sumPlannedQty={this.state.sumPlannedQty}
              sumPOQty={this.state.sumPOQty}
              sumArrivalPrice={this.state.sumArrivalPrice}
              sumRecommendedPrice={this.state.sumRecommendedPrice}
              sumPlannedPrice={this.state.sumPlannedPrice}
              sumPOPrice={this.state.sumPOPrice}
            />
          </Spin>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  isModalVisible: selectIsModalVisible,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setAuditModalVisibility: (visibilityState) =>
      dispatch(setAuditModalVisibility(visibilityState)),
    setSummaryVisibility: (isEnabled) =>
      dispatch(setSummaryVisibility(isEnabled)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConsolidatedChart);

/**
 * @component
 * @description Table design to display report
 * @extends {Component}
 * @memberof ConsolidatedChart
 */
class VarietyTable extends Component {
  render() {
    const {
      selectedBranchVal,
      selectedVarietyVal,
      data,
      sumArrivalQty,
      sumRecommendedQty,
      sumPlannedQty,
      sumPOQty,
      sumArrivalPrice,
      sumRecommendedPrice,
      sumPlannedPrice,
      sumPOPrice,
      spinnerOn,
    } = this.props;

    /**
     *
     * @description Display title according to the selection in filters
     * @param {string} lastClicked Name of latest filter applied.
     * @returns {string} Header title
     * @memberof ConsolidatedChart.VarietyTable
     */
    const setSelectedType = (lastClicked) => {
      switch (lastClicked) {
        case "BranchAll":
          if (selectedBranchVal === "All") return `Branchwise Data`;
          return "Branchwise Data";
        case "Branch":
          return `Hubwise Data of ${selectedBranchVal}`;
        default:
          return;
      }
    };

    return (
      <div className="variety-consolidated-table">
        <Table
          loading={spinnerOn}
          pagination={false}
          columns={[
            {
              title:
                selectedVarietyVal === "All"
                  ? "All Variety"
                  : selectedVarietyVal !== ""
                  ? selectedVarietyVal
                  : "All Variety",
              key: "territoryName1",
              children: [
                {
                  title:
                    this.props.lastClicked === ""
                      ? "Branchwise Data"
                      : setSelectedType(this.props.lastClicked),
                  key: "territoryName2",
                  width: 100,
                  children: [
                    {
                      title: "",
                      dataIndex: "territoryName",
                      key: "territoryName",
                      width: 100,
                    },
                  ],
                },
              ],
            },

            {
              title: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      color: "#FFFFFF",
                      fontSize: "15px",
                      fontWeight: "400",
                      whiteSpace: "break",
                      marginTop: "-6px",
                    }}
                  >
                    {"Volume"}
                  </div>
                </div>
              ),
              children: [
                {
                  title: <TableNewHeader title="Arrivals" subtitle={"MT"} />,
                  key: "arrivalQuantity1",
                  width: 80,
                  children: [
                    {
                      title: <TableNewHeader value={sumArrivalQty} />,
                      dataIndex: "arrivalQuantity",
                      key: "arrivalQuantity",
                      width: 80,
                    },
                  ],
                },
                {
                  title: <TableNewHeader title="Recommended" subtitle={"MT"} />,
                  key: "recommendedQuantity1",
                  width: 80,
                  children: [
                    {
                      title: <TableNewHeader value={sumRecommendedQty} />,
                      dataIndex: "recommendedQuantity",
                      key: "recommendedQuantity",
                      width: 80,
                    },
                  ],
                },
                {
                  title: <TableNewHeader title="Planned" subtitle={"MT"} />,
                  key: "plannedQuantity1",
                  width: 80,
                  children: [
                    {
                      title: <TableNewHeader value={sumPlannedQty} />,
                      dataIndex: "plannedQuantity",
                      key: "plannedQuantity",
                      width: 80,
                    },
                  ],
                },
                {
                  title: <TableNewHeader title="Purchased" subtitle={"MT"} />,
                  key: "poQuantity1",
                  width: 80,
                  children: [
                    {
                      title: <TableNewHeader value={sumPOQty} />,
                      dataIndex: "poQuantity",
                      key: "poQuantity",
                      width: 80,
                    },
                  ],
                },
              ],
            },
            {
              title: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      color: "#FFFFFF",
                      fontSize: "15px",
                      fontWeight: "400",
                      whiteSpace: "break",
                      marginTop: "-6px",
                    }}
                  >
                    {"Price"}
                  </div>
                </div>
              ),
              children: [
                {
                  title: <TableNewHeader title="Arrivals" subtitle={"₹/MT"} />,
                  key: "arrivalPrice",
                  width: 80,
                  children: [
                    {
                      title: <TableNewHeader value={sumArrivalPrice} />,
                      dataIndex: "arrivalPrice",
                      key: "arrivalPrice",
                      width: 80,
                    },
                  ],
                },
                {
                  title: (
                    <TableNewHeader title="Recommended" subtitle={"₹/MT"} />
                  ),
                  key: "recommendedPrice1",
                  width: 80,
                  children: [
                    {
                      title: <TableNewHeader value={sumRecommendedPrice} />,
                      dataIndex: "recommendedPrice",
                      key: "recommendedPrice",
                      width: 80,
                    },
                  ],
                },
                {
                  title: <TableNewHeader title="Planned" subtitle={"₹/MT"} />,
                  key: "plannedPrice",
                  width: 80,
                  children: [
                    {
                      title: <TableNewHeader value={sumPlannedPrice} />,
                      dataIndex: "plannedPrice",
                      key: "plannedPrice",
                      width: 80,
                    },
                  ],
                },
                {
                  title: <TableNewHeader title="Purchased" subtitle={"₹/MT"} />,
                  key: "poPrice",
                  width: 80,
                  children: [
                    {
                      title: <TableNewHeader value={sumPOPrice} />,
                      dataIndex: "poPrice",
                      key: "poPrice",
                      width: 80,
                    },
                  ],
                },
              ],
            },
          ]}
          dataSource={data}
          style={{ width: "99.3%" }}
          {...tableProps}
        />
      </div>
    );
  }
}
