export const UserActionTypes = {
  USER_LOGGED_STATUS: "USER_LOGGED_STATUS",
  SET_USER_RBAC_DETAILS: "SET_USER_RBAC_DETAILS",
  SET_ALL_USERS: "SET_ALL_USERS",
  SET_ALL_BRANCHES: "SET_ALL_BRANCHES",
  SET_ACCESS_TOKEN: "SET_ACCESS_TOKEN",
  SET_REFRESH_TOKEN: "SET_REFRESH_TOKEN",
  SET_USER_ROLE: "SET_USER_ROLE",
  SET_REPORT: "SET_REPORT",
  SET_USER_RBAC_MS_MESSAGE:"SET_USER_RBAC_MS_MESSAGE",
};
