import React from "react";
import "./user-management.styles.css";
import { Component } from "react";

import { Table, Modal, Switch, Button, Input, Select, message } from "antd";
import editUserImage from "../../assets/edit-user.svg";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { setAllUsers } from "../../redux/user/user.actions";
import {
  selectAllUsers,
  selectAllBranches,
  selectUserRoleData,
  selectallReportData
} from "../../redux/user/user.selectors";

import UserManagementForm from "../../components/user-management-form/user-management-form.component";

import API from "../../api";

const { Search } = Input;
const { Option } = Select;

/**
 * @description Component for user management tab
 *
 * @component
 */
class UserManagement extends Component {

  state = {
    isModalVisible: false,
    isAdd: false,
    currentRecord: null,
    userBranches: null,
    searchText: null,
    searchUserType: 0,
  };
  componentDidMount() {
    this.getUserRoleData()
  }
  getUserRoleData = async () => {
    // this.setActualFORData([]);
    // this.spinnerOn(true);
    // this.setState({ hubData: [] })

    //#region api call for getting buying decision data
    // console.log("This Selected rEgions ACtaulLLLLLLLLLLLLLLLLLLLLLLLLL", this.state.selectedRegionsData, this.state.selectedRegionsData.length, "============> ", this.state.selectedVarietiesData, this.state.selectedVarietiesData.length);

    const ROLE_DATA_ENDPOINT = `/user_role`
    await API.get(ROLE_DATA_ENDPOINT, {


    })
      .then((userRoleResponse) => {

        // console.log("Actual -----------------> user role data ", userRoleResponse.data.data);

        if (userRoleResponse.data) {


          this.props.setUserRoleData(userRoleResponse.data.data);


        }
      })
      .catch((err) => {

      });

  }
  render() {
    // for search
    const userList = [
      { id: 0, label: "All users" },
      { id: 1, label: "Assistant Trading Manager" },
      { id: 2, label: "Trading Manager" },
      { id: 3, label: "Branch Manager" },
      { id: 4, label: "DHQ" },
      { id: 5, label: "Admin" },
      { id: 7, label: "Finance Manager" },
    ];

    //for roles in modal
    const roleList = [
      { label: "Finance Manager", value: 7 },
      { label: "Admin", value: 5 },
      { label: "DHQ", value: 4 },
      { label: "Branch Manager", value: 3 },
      { label: "Trading Manager", value: 2 },
      { label: "Assistant Trading Manager", value: 1 },
    ];

    const userTableProps = {
      pagination: false,
      size: "default",
      bordered: true,
      scroll: { x: 200, y: window.innerHeight - 214 },
    };

    // set the user table data
    /**
     * @function
     * @description load the user data into the table. Also handles the search functionality.
     * @memberof UserManagement
     */
    const setUserTableData = () => {
      const userTableData = this.props.allUsers.map((user) => {
        return {
          key: user.user_id,
          userData: user,
          name: user.name,
          employeeID: user.user_name,
          email: user.email,
          phone: user.contact_number,
          branches: user.territories.map((item) => item.territory_name),
          role: user.role.role_name,
          actions: {
            canEdit: true,
            role_id: user.role.role_id,
            isActive: user.is_active,
          },
        };
      });

      let dataAfterSearch = userTableData;

      if (this.state.searchText) {
        dataAfterSearch = dataAfterSearch.filter(
          (item) =>
            item.name !== null &&
            item.name
              .toLowerCase()
              .includes(this.state.searchText.toLowerCase())
        );
      }

      if (this.state.searchUserType !== 0) {
        dataAfterSearch = dataAfterSearch.filter(
          (item) => item.actions.role_id === this.state.searchUserType
        );
      }


      return dataAfterSearch;
    };

    /**
     * @function
     * @description call put api to change active status of user
     * @param {boolean} status status to be set for the user : active/enactive
     * @param {Object} userRecord current selected user record
     * @memberof UserManagement
     */
    const setUserStatus = (status, userRecord) => {
      const userHeader = {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      };
      const postData = {
        name: userRecord.name,
        user_name: userRecord.user_name.toString(),
        contact_number: userRecord.contact_number,
        is_active: status,
        email: userRecord.email,
        territories: userRecord.territories,
        role: userRecord.role,
      };

      API.put(`users/${userRecord.user_id}`, postData
      )
        .then((statusResponse) => {
          if (statusResponse.data.status) {

            //#region cache modified user record to redux

            const modifiedUserRecord = { ...userRecord, is_active: status };

            let modifiedUserList = this.props.allUsers;
            modifiedUserList = modifiedUserList.map((item) => {
              if (item.user_id === userRecord.user_id) {
                return {
                  ...item,
                  is_active: status,
                };
              }
              return item;
            });

            this.props.setAllUsers(modifiedUserList);

            //#endregion
          } else {
            message.error(
              "Could not change status at the moment. Please try again later."
            );
          }
        })
        .catch((err) => {
          message.error(
            "Could not change status at the moment. Please try again later."
          );
        });
    };

    // set the edit/add user modal visibility
    /**
     * @function
     * @description set the add/edit popup modal visibility
     * @param {boolean} status required visibility state of the modal
     * @param {boolean} isAdd true : add user , false : edit user
     * @param {Object} record current selected user record. null if creating a new user.
     * @memberof UserManagement
     */
    const setModalVisibility = (status, isAdd, record) => {
      this.setState((prevState) => ({
        ...prevState,
        currentRecord: record,
        isAdd: isAdd,
        isModalVisible: status,
        userBranches: record ? record.branches : null,
      }));
    };

    return (
      <div
        className="user-management-container"
        style={{ height: window.innerHeight - 85, overflow: "scroll" }}
      >
        <Modal
          className="user-management-modal"
          title={this.state.isAdd ? "Add User" : "Edit User"}
          visible={this.state.isModalVisible}
          destroyOnClose={true}
          onOk={() => {
            setModalVisibility(false, false, null);
          }}
          onCancel={() => setModalVisibility(false, false, null)}
        >
          <UserManagementForm
            allUsers={this.props.allUsers}
            setModalVisibility={setModalVisibility}
            currentRecord={this.state.currentRecord}
            currentBranches={
              this.state.currentRecord
                ? this.state.currentRecord.territories.map(
                  (item) => item.territory_name
                )
                : null
            }
            isAdd={this.state.isAdd}
            branchList={this.props.allBranches}
            reportList={this.props.allReports}

            roleList={roleList}
          />
        </Modal>

        <div className="top-container">
          <div style={{ width: "100%" }}>
            <Search
              placeholder="Search by Username"
              onChange={(e) => this.setState({ searchText: e.target.value })}
              // onSearch={value => console.log(value)}
              style={{ width: "30%" }}
            />
            <Select
              className="select-user-type"
              showSearch
              showArrow={true}
              defaultValue={userList[0].id}
              onSelect={(value) => this.setState({ searchUserType: value })}
            >
              {userList.map((item, idx) => (
                <Option value={item.id} key={item.id}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </div>
          <Button
            onClick={() => setModalVisibility(true, true, null)}
            className="add-user-button"
          >
            + Add User
          </Button>
        </div>

        <div className="table-container" >
          <Table
            {...userTableProps}
            columns={[
              {
                key: "name",
                title: "Name",
                dataIndex: "name",
              },
              {
                key: "employeeID",
                title: "Employee ID",
                dataIndex: "employeeID",
              },
              {
                key: "email",
                title: "Email Id",
                dataIndex: "email",
              },
              {
                key: "phone",
                title: "Phone",
                dataIndex: "phone",
              },
              {
                key: "branches",
                title: "Branches",
                dataIndex: "branches",
                render: (branches) => branches.toString(),
              },
              {
                key: "role",
                title: "Role",
                dataIndex: "role",
              },
              {
                key: "actions",
                title: "Actions",
                dataIndex: "actions",
                width: 170,
                render: (actions, record) => (
                  <div className="user-action-container">
                    <Button
                      onClick={() =>
                        setModalVisibility(true, false, record.userData)
                      }
                      className="edit-button"
                    >
                      <img
                        style={{
                          width: "15px",
                          height: "15px",
                          margin: "4px 5px 0 -6px",
                        }}
                        alt="edit"
                        src={editUserImage}
                      />
                      <h5 style={{ margin: "3px 0 0 0" }}>Edit</h5>
                    </Button>
                    <Switch
                      // value={actions.isActive}
                      checked={actions.isActive}
                      onChange={(checked) =>
                        setUserStatus(checked, record.userData)
                      }
                      checkedChildren="&nbsp;&nbsp;Active"
                      unCheckedChildren="Inactive"
                    />
                  </div>
                ),
              },
            ]}
            dataSource={setUserTableData()}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  allUsers: selectAllUsers,
  allBranches: selectAllBranches,
  roleTypes: selectUserRoleData,
  allReports: selectallReportData
});

const mapDispatchToProps = (dispatch) => {
  return {
    setAllUsers: (data) => dispatch(setAllUsers(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
