import React from "react";
import "./custom-select-summary.styles.css";

import { Select } from "antd";

const { Option } = Select;
/**
 *
 * @description Dropdown component used in Summary filters.
 * @component
 * @property {boolean} disabled True if disabled
 * @property {string[]} list Dropdown items
 * @property {function} handleSelect Callback on selecting an item
 * @property {string} placeholder Placeholder to display when no selection is made
 * @property {string[]} keyList Dropdown items keys
 */
class CustomSelectSummary extends React.Component {
  constructor({ disabled,  isVarietalSubstitution, list, handleSelect, placeholder, keyList }) {
    super();
    this.state = { disabled, isVarietalSubstitution,list, handleSelect, placeholder, keyList };
  }

  componentWillReceiveProps({
    disabled,
    list,
    handleSelect,
    placeholder,
    keyList,
    allEnabled,
    isVarietalSubstitution,
  }) {
    this.setState({
      disabled,
      list,
      handleSelect,
      placeholder,
      keyList,
      allEnabled,
      isVarietalSubstitution,
    });
  }

  render() {
    const {
      disabled,
      list,
      handleSelect,
      placeholder,
      keyList,
      allEnabled,
      isVarietalSubstitution,
    } = this.state;

    return (
      <Select
        showSearch
        className={isVarietalSubstitution&&disabled ? "custom-varietal-select select-disabled" : 
                isVarietalSubstitution ? "custom-varietal-select" :disabled ? "select-disabled" :  ""}
        value={placeholder}
        onSelect={
          handleSelect
            ? (value, props) => handleSelect(value, props)
            : console.log("")
        }
      >
        {allEnabled ? (
          <Option value="All" key={0}>
            All
          </Option>
        ) : null}
        {list
          ? list.map((item, idx) => (
              <Option value={item} key={keyList ? keyList[idx] : idx}>
                {item}
              </Option>
            ))
          : null}
      </Select>
    );
  }
}

export default CustomSelectSummary;
